<template>
  <div id="posReading" class="reading">
    <div class="posReading__tabs">
      <Tabs :tabs="tabs" :activeTabIndex="activeTabIndex"></Tabs>
    </div>
    <div class="posReading__right">
      <BatchReading v-if="activeTabIndex === 0" ref="batchReading"></BatchReading>
      <PosTipDeclaration v-if="activeTabIndex === 1"></PosTipDeclaration>
      <PosCashDrawer v-if="activeTabIndex === 2"></PosCashDrawer>
    </div>
    <CloseBatchErrorModal></CloseBatchErrorModal>
    <SwitchBatchModal @switch-batch-report-target="getBatchReadingFor"></SwitchBatchModal>
    <CloseBatchModal @close-batch="closeCurrentBatch"></CloseBatchModal>
  </div>
</template>

<script>
  import PosCashDrawer from "@/components/pos/PosCashDrawer.vue";
  import PosTipDeclaration from "@/components/pos/PosTipDeclaration.vue";
  import Tabs from "@/components/tools/Tabs.vue";
  import moment from "moment";
  import BatchReading from "@/components/pos/BatchReading.vue";
  import CloseBatchErrorModal from "@/components/pos/CloseBatchErrorModal";
  import SwitchBatchModal from "@/components/pos/SwitchBatchModal.vue";
  import CloseBatchModal from "@/components/pos/CloseBatchModal";

  export default {
    name: "posReading",

    components: { Tabs, PosTipDeclaration, PosCashDrawer, BatchReading, CloseBatchErrorModal, SwitchBatchModal, CloseBatchModal },

    data() {
      return {
        title: "Readings",
        moment,
        activeTabIndex: 0
      };
    },

    computed: {
      tabs() {
        return [{
          name: this.$t("pos.readings.financial_report"),
          action: () => { this.activeTabIndex = 0; }
        }, {
          name: this.$t("pos.readings.cash_tip"),
          action: () => { this.activeTabIndex = 1; },
          disabled: !this.$store.state.employee
        }, {
          name: this.$t("pos.readings.cash_drawer"),
          action: () => { this.activeTabIndex = 2; },
          disabled: !this.$store.state.employee
        }];
      }
    },

    methods: {
      getBatchReadingFor(target) {
        if (this.$refs.batchReading) {
          this.$refs.batchReading.switchBatchReportTarget(target);
        }
      },
      closeCurrentBatch() {
        if (this.$refs.batchReading) {
          this.$refs.batchReading.closeCurrentBatch();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posReading {
    padding: 30px;
    display: flex;
    flex-direction: row;
  }

  .posReading__tabs {
    height: 100%;
  }

  .posReading__right {
    flex-grow: 1;
    margin-left: 20px;
    padding-left: 30px;
    border-left: 2px dashed var(--border-color-5);
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .reading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>

<style lang="scss">
  #iShopFoodApp #posReading {
    .panel {
      border-radius: 40px;
      width: 1092px;
      display: flex;
      flex-direction: column;

      & > .title {
        padding-left:40px;
        padding-top: 40px;
        padding-right: 40px;
        font-size: 22px;
        font-weight: 900;

        .titleIcon {
          margin-right: 20px;
        }
      }

      & > .content {
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;

        & > .container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: 20px 40px 40px 40px;
        }

        &:not(.noTitle):before {
          left: 40px;
          right: 40px;
        }
      }
    }
  }
</style>