<template>
  <div class="localeTopRight" v-if="branch && !branch.isEnglishLocaleOnly()" :class="{inline, text}">
    <div v-show="$ts.state.locale === 'en' || displayAll" class="locale"
         @click="$ts.state.locale = 'fr'" :class="{active: $ts.state.locale === 'fr'}">
      <div class="locale-text" v-if="text">{{short ? 'FR' : $t('fr')}}</div>
      <svgicon src="icons/btn-francais.svg" class="locale-icon" :height="24" :width="24" v-if="!text"></svgicon>
    </div>
    <div class="separator" v-if="displayAll"></div>
    <div v-show="this.$ts.state.locale === 'fr' || displayAll" class="locale"
         @click="$ts.state.locale = 'en'" :class="{active: $ts.state.locale === 'en'}">
      <div class="locale-text" v-if="text">{{short ? 'EN' : $t('en')}}</div>
      <svgicon src="icons/btn-english.svg" class="locale-icon" :height="24" :width="24" v-if="!text"></svgicon>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FloatingLocaleSwitcher",

    props: {
      inline: Boolean,
      displayAll: Boolean,
      short: Boolean,
      text: Boolean
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .localeTopRight {
    position: fixed;
    right: 15px;
    top: 30px;
    display: flex;
    z-index: 1;
    font-size: 14px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    .separator {
      position: relative;

      &:after {
        content: "|";
        opacity: 0.25;
        font-size: 12px;
        display: inline-block;
      }
    }
    .locale {
      cursor: pointer;
      &.selected {
        text-decoration: underline;
      }
    }

    .locale-text {
      font-weight: bold;
      font-size: 14px;
      padding: 15px;
    }

    &.text {
      .locale:last-child {
        .locale-text {
          padding-right: 10px;
        }
      }
      .active {
        font-weight: bold;
      }
      .locale:not(.active) .locale-text {
        font-weight: normal;
      }
    }

    &.inline {
      position: initial;
      transform: initial;
      top: initial;
      z-index: initial;
      right: initial;
    }
  }
  #iShopFoodApp.xs {
    .locale-icon {
      display: block;
    }
  }
</style>
