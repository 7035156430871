//@ts-check
import Store from "@/store";
import { ActionContext } from "vuex";

export default {

  /**
   * Get a single node from the inventory tree. If no argument is passed, the whole inventory is returned.
   * If an argument is passed, the inventory is navigated to find the item with the given ids, formatted as 99-99-99.
   * @param {ActionContext} context
   * @param {string} ids
   */
  getItem(context, ids) {
    return new Promise(async (resolve) => {
      while (!context.state.inventory || !context.state.inventory.getRoot()) {
        await window.wait(1000);
      }
      let root = context.state.inventory.getRoot();
      if (ids && String(ids).length > 0) {
        let split = String(ids).split(/[\/-]/);
        for (let i = 0; i < split.length; i++) {
          if (typeof root.length != "undefined") {
            root = root.filter(r => r.id == split[i])[0];
          } else if (typeof root.items != "undefined") {
            root = root.items.filter(r => r.id == split[i])[0];
          } else {
            resolve(null);
            return;
          }
          if (!root) {
            break;
          }
        }
      }
      resolve(root);
    });
  },

  /**
   * Get a specific item from inventory tree, without using the full navigation ids. Searches for item in all categories.
   * @param {ActionContext} context
   * @param {number} id
   */
  getFlatItem(context, id) {
    return new Promise(async (resolve) => {
      while (!context.state.inventory || !context.state.inventory.getRoot()) {
        await window.wait(1000);
      }
      let root = context.state.inventory.getRoot();
      for (let category of root) {
        for (let item of category.items) {
          if (item.id === id) {
            resolve(item);
            return;
          }
        }
      }
      resolve(null);
    });
  },

  searchItem(context, id) {
    if (String(id).indexOf("-") > -1) {
      return context.dispatch("getItem", id);
    }
    let deepSearch = function (root) {
      if (root.id == id) {
        return root;
      }
      if (root.items) {
        for (let item of root.items) {
          let foundItem = deepSearch(item);
          if (foundItem) {
            return foundItem;
          }
        }
      }
    };
    return new Promise(async (resolve) => {
      while (!context.state.inventory.getRoot()) {
        await window.wait(1000);
      }
      for (let item of context.state.inventory.getRoot()) {
        if (item.id == id) {
          resolve(item);
          return;
        }
        let foundItem = deepSearch(item);
        if (foundItem) {
          resolve(foundItem);
          return;
        } else {
          /** Look through hidden items **/
          for (let hiddenProduct of context.state.inventory.hidden) {
            if (hiddenProduct.id == id) {
              resolve(hiddenProduct);
              return;
            }
          }
        }
      }
      resolve(null);
    });
  },

  /**
   * Get a single node from the inventory hash map from given item id. Return them item if found or null if not
   * @param {ActionContext} context
   * @param {string} id
   */
  getItemById(context, id) {
    return new Promise(async (resolve) => {
      if (id && context.state.inventory && context.state.inventory.inventoryMap) {
        let item = context.state.inventory.inventoryMap.get(parseInt(id));
        if (item) {
          resolve(item);
        }
      }
      resolve(null);
    });
  },

  /**
   * @param {ActionContext} context
   * @param {'top'|'bottom'} location
   * @returns {Promise<{id: number, customData: {name: {fr: string, en: string}}}[]>}
   */
  getCartFunctions(context, location) {
    return new Promise((resolve) => {
      if (!context.state.employee || !context.state.inventory) {
        resolve([]);
      }

      let roleId = context.state.employee.getPosRoleId();
      let revenueCenter = context.state.inventory.getRevenueCenterByRef(context.state.currentRevenueCenterRef);
      if (roleId && revenueCenter) {
        let functions = location === "top"
          ? revenueCenter.getCartTopFunctionsForRoleId(roleId)
          : revenueCenter.getCartBottomFunctionsForRoleId(roleId);
         functions = functions.map(f => {
          let fn = context.state.posConfiguration.getFunctionById(f.id);
          if (f.customData?.name && window.translateObject(f.customData.name)) { //Override fn name with custom name if defined
            return {
              name: f.customData.name,
              id: f.id
            };
          }
          return fn;
        });
        resolve(functions);
      }

      resolve([]);
    });
  }

};