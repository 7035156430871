<template>
  <textarea class="search-bar__textarea" :id="'search-bar__textarea' + uuid" :value="value" v-if="textarea"
            :placeholder="placeholder" @click="showCustomKeyboard" onfocus="blur()"></textarea>
  <input class="search-bar__input" :id="'search-bar__input' + uuid" :value="value" v-else
         :type="type" :placeholder="placeholder" @click="showCustomKeyboard" onfocus="blur()">
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "PosInput",

    props: {
      type: String,
      value: String,
      textarea: Boolean,
      closeKeyboardOnInactivity: Boolean,
      placeholder: String
    },

    data() {
      return {
        uuid: null
      };
    },

    mounted() {
      this.uuid = window.generateUUID();
      this.$nextTick(() => {
        EventBus.$on("keyboard-enter", (args) => {
          if (args.reference === this.uuid) {
            //trigger PosInput's v-model input event manually which will pass the value here implicitly as the :value prop
            this.$emit("input", args.value);
          }
        });
      });

    },

    beforeDestroy() {
      EventBus.$off("keyboard-enter");
    },

    methods: {
      showCustomKeyboard() {
        EventBus.$emit("show-keyboard", {
          reference: this.uuid,
          closeOnInactivity: this.closeKeyboardOnInactivity,
          value: this.value
        });
      }
    }
  };
</script>

<style lang="scss">

</style>