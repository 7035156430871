<template>
  <div class="levels-container">

    <p class="level-description" v-if="description">{{description}}</p>
    <div class="levels">
      <div class="level" v-for="(level, levelIndex) of levels"
           :class="{checked: level.points <= userLevel.points, last: levelIndex + 1 === levels.length}">
        <div class="icon">
          <img :src="$store.state.urlFileServer + level.icon.url" v-if="level.icon">
        </div>
        <div class="middle">
          <div class="name">{{$to(level.name)}}</div>
          <div class="description">{{$to(level.description)}}</div>
        </div>
        <div class="status" :class="{checked: level.points <= userLevel.points}">
          <svgicon src="icons/rank.svg" :height="24" :width="24"></svgicon>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {

    name: "levels",

    computed: {
      branchLoyalty() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.loyaltyConfig;
      },
      levels() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        if (this.branchLoyalty.type === "ishopfood") {
          return this.branchLoyalty.data.levels;
        }
        return [];
      },
      description() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        if (this.branchLoyalty.type === "ishopfood") {
          return this.branchLoyalty.data.description;
        }
      },
      points() {
        if (!this.$user.loyalty) {
          return 0;
        }
        return this.$user.loyalty.getTotalPoints();
      },
      userLevel() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.getLevelForPoints(this.points);
      }
    }
  }
</script>

<style lang="scss" scoped>

  .levels {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
  }

  .level-description {
    padding: 30px 30px 0 30px;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  .levels-container {
    overflow-y: auto;
  }

  .desktop {
    .level-description {
      padding: 0;
    }
    .levels {
      padding: 0;
      padding-top: 15px;
    }
  }

  .level {
    display: flex;
    flex-grow: 1;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 0px rgba(2,3,2,0.25);
    margin-bottom: 15px;
  }

  .icon {
    background-color: rgb(242,242,242);
    min-height: 80px;
    width: 80px;
    flex-shrink: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .middle {
    flex-grow: 1;
    padding: 20px;

    .name {
      font-weight: bold;
      margin-bottom: 2px;
    }
    .description {
      font-size: 12px;
      color: rgb(128,128,128);
    }
  }

  .status {
    margin: 20px;
    display: flex;
    align-items: center;
    fill: rgb(229,229,229);
    &.checked {
      fill: #fc6650;
    }
  }

</style>