<template>
  <div class="asyncImage" :style="style" @click="$emit('click')">
    <div v-if="!finished" class="whileLoading" :style="whileLoadingStyle" :class="{loaded}"></div>
    <img :src="image" @load="imageLoaded" @error="imageError" class="toLoad" v-if="!loaded && imageValid">
  </div>
</template>

<script>

  let cache = {};

  export default {

    name: "asyncimage",

    props: {
      fallback: String,
      image: String,
    },

    data() {
      return {
        loaded: false,
        finished: false,
        imageToShow: this.fallback
      };
    },

    mounted() {
      if (cache[this.image]) {
        this.imageToShow = this.image;
      } else {
        this.imageToShow = this.fallback;
      }
    },

    computed: {
      imageValid() {
        return this.image && !this.image.endsWith("null");
      },
      whileLoadingStyle() {
        return this.imageValid ? {'background-image': 'url(' + this.image + ')'} : {};
      },
      style() {
        return this.imageToShow ? {'background-image': 'url(' + this.imageToShow + ')'} : {};
      }
    },

    watch: {
      image(newValue) {
        if (cache[this.image]) {
          this.imageToShow = this.image;
        } else {
          this.imageToShow = this.fallback;
        }
        this.loaded = false;
        this.finished = false;
      }
    },

    methods: {
      imageLoaded() {
        this.loaded = true;
        setTimeout(() => {
          this.imageToShow = this.image;
          cache[this.image] = true;
          this.finished = true;
        }, 500);
      },
      imageError() {
        this.loaded = true;
        this.finished = true;
      }
    }

  }

</script>

<style lang="scss" scoped>

  .asyncImage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-clip: padding-box;
  }

  .toLoad {
    width: 1px;
    height: 1px;
    opacity: 0;
    pointer-events: none;
  }

  .whileLoading {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    opacity: 0;
    transition: opacity 0.5s ease;
    &.loaded {
      opacity: 1;
    }
  }

  #iShopFoodApp.pos .whileLoading {
    background-color: transparent;
  }

</style>
