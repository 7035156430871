import { Constant } from "@/util/Constant";
import Detail from "@/models/pos/DetailModel";
import Table from "@/models/pos/TableModel";
import Sale from "@/models/SaleModel";
import moment from "moment-timezone";

export default class OrderOverview {

    /**
     * @param {SaleDefinition|TableDefinition} definition
     */
    constructor(definition) {
        this.rawData = definition;
        this.appName = definition.saleData ? definition.saleData.appName : definition.appName;
        this.details = definition.details.map(d => new Detail(d, true));
        this.id = definition.id;
        this.kioskNumber = definition.kioskNumber || null;
        this.state = definition.state || definition.status;// should it be processingStatus? external sales/table are always gonna be closed
        this.employee = definition.employee || null;
        this.method = definition.deliveryMethod || "inPlace";
        this.startedAt = definition.startedAt;
        this.invoices = definition.invoices || [];
        this.contact = definition.contact;
        this.closedAt = definition.closedAt || definition.completedAt;
        this.inAdvanceFor = definition.inAdvanceFor;
        this.table = this.isPosSale() ? new Table(definition) : null;
        this.subTotal = definition.subTotal || 0;
        this.tipTotal = definition.tip || definition.tipTotal || 0;
        this.total = definition.total || 0;
        this.taxes = definition.taxes;
        this.payments = definition.payments || [];
        this.promotions = definition.promotions || [];
        this.deliveryData = null;

        if (definition.deliveryData && Object.keys(definition.deliveryData).length > 0) {
            this.deliveryData = definition.deliveryData;
        }

        if (this.startedAt) {
            this.startedAt = moment(this.startedAt, Constant.API_DATE_FORMAT);
        }
        if (this.closedAt) {
            this.closedAt = moment(this.closedAt, Constant.API_DATE_FORMAT);
        }
        if (this.inAdvanceFor) {
            this.inAdvanceFor = moment(this.inAdvanceFor, Constant.API_DATE_FORMAT);
        }

        if (this.isUberSale() && definition.externalPlatformData && definition.externalPlatformData.data && definition.externalPlatformData.data.eater) {
            this.contact.firstName = definition.externalPlatformData.data.eater.first_name;
            this.contact.lastName = definition.externalPlatformData.data.eater.last_name;
            this.contact.phone = definition.externalPlatformData.data.eater.phone;
        }
    }

    /**
     * @returns {number}
     */
    getOrderId() {
        return this.kioskNumber || this.id;
    }

    /**
     * @returns {{id: number}|null}
     */
    getEmployee() {
        return this.employee;
    }

    /**
     * @returns {string}
     */
    getEmployeeDisplayName() {
        if (this.employee) {
            if (this.employee.publicName) {
                return this.employee.publicName;
            }
            return (this.employee.firstName ? this.employee.firstName.substring(0, 1) + ". " : "") + this.employee.lastName;
        }
        return "";
    }

    /**
     * @returns {string}
     */
    getAppName() {
        return this.appName;
    }

    /**
     * @returns {string[]}
     */
    getActiveInvoiceNumbers() {
        return this.invoices.filter(i => !i.void && i.number).map(i => i.number);
    }

    /**
     * @returns {boolean}
     */
    isRefunded() {
        return !!this.getRefundedInvoice();
    }

    /**
     *  @returns {object|null}
     */
    getRefundedInvoice() {
        return this.invoices.find(i => !i.void && i.number && i.total < 0);
    }

    /**
     * @returns {boolean}
     */
    isPosSale() {
        return this.appName === Constant.POS_APP_NAME;
    }

    /**
     * @returns {boolean}
     */
    isUberSale() {
        return this.appName === Constant.UBER_APP_NAME;
    }

    /**
     * @returns {string}
     */
    getMethod() {
        return this.method;
    }

    /**
     * @returns {SaleDefinition|TableDefinition}
     */
    getRawData() {
        return this.rawData;
    }

    /**
     * @returns {boolean}
     */
    isClosed() {
        return this.state ? this.state === Constant.STATE_CLOSED : true;
    }

    /**
     * @returns {boolean}
     */
    isOpen() {
        return !!this.state && this.state === Constant.STATE_OPEN;
    }

    /**
     * @returns {boolean}
     */
    isDeliveryOrTakeout() {
        return this.isDelivery() || this.isTakeout();
    }

    /**
     * @returns {boolean}
     */
    isDelivery() {
        return this.method === 'delivery';
    }

    /**
     * @returns {boolean}
     */
    isTakeout() {
        return this.method === 'takeout';
    }

    /**
     * @returns {string}
     */
    getFullContactName() {
        if (this.contact) {
            return this.contact.firstName + " " + this.contact.lastName;
        }
        return "";
    }

    /**
     * @returns {string}
     */
    getFullAddress() {
        if (this.deliveryData) {
            return this.deliveryData.fullAddress;
        }
        return "";
    }

    /**
     * @returns {string}
     */
    getContactPhone() {
        if (this.contact) {
            if (this.isUberSale()) {//uber already formatted
                return this.contact.phone;
            }

            //TODO this is repeated in pos cart and dispatch and will change according to locale eventually
            return this.contact.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
        return "";
    }

    /**
     * @returns {string}
     */
    getContactEmail() {
        if (this.contact) {
            return this.contact.email;
        }
        return "";
    }

    /**
     * @returns {boolean}
     */
    isContactMethodEmail() {
        return this.contact?.method === "email";
    }

    /**
     * @returns {string|null}
     */
    getStartDateAsString() {
        if (this.startedAt) {//try catch or valdiate its a date?
            return this.startedAt.format("lll");
        }
        return null;
    }

    /**
     * @returns {string|null}
     */
    getCloseDateAsString() {
        if (this.closedAt) {
            return this.closedAt.format("lll");
        }
        return null;
    }

    /**
     * @returns {string|null}
     */
    getInAdvanceForDateAsString() {
        if (this.inAdvanceFor) {
            return this.inAdvanceFor.format("lll");
        }
        return null;
    }

    /**
     * @returns {[]}
     */
    getSeats() {
        let seats = [];

        for (let detail of this.details) {
            if (detail.seat && seats.indexOf(detail.seat) === -1) {
                seats.push(detail.seat);
            }
        }

        return Array.from(new Set(seats)).sort();
    }

    /**
     * @returns {{seat: number, details: Detail[]}[]}
     */
    getGroupedDetailsBySeats() {
        let detailsBySeats = [];
        let details = this.getGroupedDetails();
        let seats = this.getSeats();

        for (let seat of seats) {
            detailsBySeats.push({
                seat: seat,
                details: details.filter(d => d.seat === seat)
            });
        }

        return detailsBySeats;
    }

    /**
     * @returns {Detail[]}
     */
    getGroupedDetails() {
        if (this.isPosSale()) {
            return this.table.getGroupedDetails();
        } else {
            let groupedDetails = [];
            let lastParentDetailIndex = null;
            let modifierTypes = ["combo", "modifier"];
            if (this.isUberSale()) {
                modifierTypes.push("variant");
            }

            for (let i = 0; i < this.details.length; i++) {
                let detail = this.details[i].clone();
                if (modifierTypes.indexOf(detail.type) > -1) { //Is a modifier
                    if (lastParentDetailIndex !== null && groupedDetails[lastParentDetailIndex]) {
                        groupedDetails[lastParentDetailIndex].details.push(detail);
                    }
                } else { //Is a root item
                    detail.details = [];
                    groupedDetails.push(detail);
                    lastParentDetailIndex = groupedDetails.length - 1;
                }
            }
            return groupedDetails;
        }
    }

    /**
     * @returns {{}}
     */
    getTotal() {
        if (this.isPosSale()) {
            return this.table.getTotal();
        } else {
            let taxes = {};
            if (this.taxes) {
                for (let tax of this.taxes) {
                    taxes[tax.name] = tax.total;
                }
            }

            return {
                subTotal: this.subTotal,
                taxes: taxes,
                tip: this.tipTotal,
                total: this.total
            };
        }
    }

    /**
     * @returns {boolean}
     */
    hasCashPayment() {
        return !!this.payments.find(p => p.method === "cash");
    }
}