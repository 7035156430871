// @ts-check
import CompanySettings from "@/models/CompanySettingsModel";
import { Format } from "@/util/Format";
import moment from "moment";
import GeocodingHelper from "../lib/geocodingHelper";
import EventBus from "@/lib/eventBus.js";
import Store from "../store";
import { LocalizeStore } from "@/lib/localize";
import PromotionModal from "./PromotionModalModel";
import { MediaLibrary } from "@/util/MediaLibrary";

/**
 * @typedef Address
 * @property {string} address
 * @property {string} zipCode
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {number} latitude
 * @property {number} longitude
 */

/**
 * @typedef CompanyDescription
 * @property {{fr: string, en: string}} long
 * @property {{fr: string, en: string}} longTitle
 * @property {{fr: string, en: string}} short
 */

/**
 * @typedef SocialNetwork
 * @property {string} facebook
 * @property {string} twitter
 * @property {string} instagram
 * @property {string} website
 */

/**
 * @typedef AdditionalInformation
 * @property {CompanyDescription} description
 * @property {string} price_range
 * @property {{fr: string, en: string}} productType
 * @property {SocialNetwork} social
 * @property {{title: {fr: string, en: string}, url: string}[]} customMenuExternalLinks
 */

/**
 * @typedef OnlineOrdering
 * @property {boolean} activated
 * @property {OrderMethod} default
 * @property {OrderMethod} takeout
 * @property {OrderMethod} delivery
 * @property {OrderMethod} inPlace
 * @property {OrderMethod} [hotel]
 * @property {OrderMethod} [catering]
 */

/**
 * @typedef OrderMethod
 * @property {boolean} activated
 * @property {Hours} hours
 * @property {InAdvance} inAdvance
 * @property {{averageDelay: number, tipsActivated: boolean, tipOptions: string, commentBoxActivated: boolean}} general
 * @property {DeliveryZone[]} zones
 * @property {boolean} zonesDisabled
 * @property {string[]} tables
 * @property {boolean} todayDisabled
 */

/**
 * @typedef DeliveryZone
 * @property {string[]} zipCodes
 * @property {Coord[]} polygons
 * @property {{amount: number, taxIncluded: boolean, minimumPrice: number}} fees
 */

/**
* @typedef {Object} Coord
* @property {number} lat
* @property {number} lng
*/

/**
 * @typedef EmergencyKiosk
 * @property {Boolean} emergencyCloseKioskManually
 * @property {{fr: string }} emergencyCloseKioskManuallyMessage
 */

/**
 * @typedef Hours
 * @property {HoursObject} monday
 * @property {HoursObject} thuesday
 * @property {HoursObject} wednesday
 * @property {HoursObject} thursday
 * @property {HoursObject} friday
 * @property {HoursObject} saturday
 * @property {HoursObject} sunday
 */

/**
 * @typedef HoursObject
 * @property {boolean} open
 * @property {{to: string, from: string}[]} hours
 */

/**
 * @typedef InAdvance
 * @property {boolean} activated
 * @property {boolean} ASAP
 * @property {boolean} outsideHours
 */

/**
 * @typedef Timezone
 * @property {string} timezone
 */

/**
 * @typedef TaxDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {string} [taxNumber]
 * @property {string} uniqueName
 * @property {number} value
 */

/**
 * @typedef CompanyDefinition
 * @property {number} id
 * @property {number} inventory
 * @property {string} name
 * @property {string} nameCanonical
 * @property {Address} address
 * @property {string} phoneNumber
 * @property {string} currency
 * @property {string} email
 * @property {{url: string}} image
 * @property {AdditionalInformation} information
 * @property {OnlineOrdering} onlineOrdering
 * @property {{processor: string, methods?: string[], username?: string, password?: string, pk?: string}[]} payments
 * @property {Timezone} now
 * @property {Boolean} emergencyClose
 * @property {{fr: string, en: string}} emergencyCloseMessage
 * @property {TaxDefinition[]} taxes
 * @property {string} locale
 * @property {string} hourFormat
 * @property {boolean} dataCandyActivated
 * @property {string} country
 * @property {string} shipper
 * @property {boolean} freeBeesActivated
 * @property {boolean} loyaltyActivated
 * @property {array} thirdPartyDeliveryPlatforms
 * @property {EmergencyKiosk} emergencyKiosk
 */

export default class Company {

  constructor() {
    this.id = -1;
    this.inventory = null;
    this.name = null;
    this.nameCanonical = null;
    this.address = null;
    this.phoneNumber = null;
    this.currency = null;
    this.iso2country = null;
    this.iso3country = null;
    this.email = null;
    this.image = null;
    this.bypassPosMode = false;
    this.information = null;
    this.onlineOrdering = null;
    this.payments = null;
    this.offlinePaymentMethods = null;
    this.timezone = null;
    this.distanceFromUser = null;
    this.emergencyClose = null;
    this.emergencyCloseMessage = null;
    this.settings = null;
    this.externalLinks = /** @type {CompanyDefinition["information"]["customMenuExternalLinks"]} */ ([]);
    this.locale = null;
    this.languages = [];
    this.dataCandyActivated = false;
    this.freeBeesActivated = false;
    this.loyaltyActivated = false;
    this.menuDisplay = null;
    this.payAtTheTable = null;
    this.intervalStats = null;
    this.thirdPartyDeliveryPlatforms = null;
    this.promotionModals = [];
    this.enableManualLoyaltyCards = false;
    this.kioskStyle = null;
    this.posStyle = null;
    this.shipperData = null;
    this.kioskEmergency = { emergencyCloseKioskManually: null, emergencyCloseKioskManuallyMessage: {fr: null} };
    this.barCodeSettings = { totalLenght: 0, ignoreLeadingZero: false, priceStartIndex: 0, priceLength: 0};
    this.shipper = null;
    this.doorDashFees = 0;
    this.showItemsWhenPriceIsZero = false;
    this.activatePaymentOnly = false;
    this.smsActivated = false;
    this.images = {};
    this.slideshow = [];
  }

  /**
   * @param {CompanyDefinition} definition
   */
   load(definition) {
    this.id = definition.id;
    this.inventory = definition.inventory;
    this.name = definition.name;
    this.address = definition.address;
    this.iso2country = definition.country;
    this.iso3country = getCountryISO3(definition.country);
    this.phoneNumber = definition.phoneNumber;
    this.currency = definition.currency;
    this.email = definition.email;
    this.bypassPosMode = definition.bypassPosMode;
    this.notifyCustomerOnBypassPos = definition.bypassPosOptions ? definition.bypassPosOptions.notifyCustomer : false;
    this.image = definition.image ? definition.image.url : null;
    this.images = definition.images || {};
    this.information = definition.information;
    this.onlineOrdering = definition.onlineOrdering;
    this.shipper = definition.shipper;
    this.languages = definition.languages || [];
    this.payAtTheTable = definition.payAtTheTable;
    this.promotionModals = definition.promotionModals.map(m => new PromotionModal(m));
    this.menuDisplay = definition.menuDisplay;
    this.activatePaymentOnly = definition.activatePaymentOnly;
    this.thirdPartyDeliveryPlatforms = definition.thirdPartyDeliveryPlatforms;
    this.barCodeSettings = definition.barCodeSettings || { totalLenght: 0, ignoreLeadingZero: false, priceStartIndex: 0, priceLength: 0};
    this.kioskStyle = definition.kioskCssData;
    this.posStyle = definition.posCssData;
    this.settings = new CompanySettings(definition.settings);
    if (definition.kiosk && definition.kiosk.emergency) {
      this.kioskEmergency = definition.kiosk.emergency;
    }
    this.payments = definition.payments.filter(p => !!p) || [];
    if (this.payments.length == 0) {
      this.payments.push({
        processor: "none",
        methods: ["none"]
      });
    }
    this.offlinePaymentMethods = definition.offlinePaymentMethods;
    this.timezone = definition.now.timezone;
    this.blockOrdersOutsideTimezone = definition.blockOrdersOutsideTimezone;
    this.emergencyClose = definition.emergencyClose;
    this.emergencyCloseMessage = definition.emergencyCloseMessage;
    this.enableManualLoyaltyCards = definition.enableManualLoyaltyCards;
    this.showItemsWhenPriceIsZero = definition.showItemsWhenZero;
    this.smsActivated = definition.smsActivated;
    this.taxes = definition.taxes;
    for (let tax of this.taxes) {
      tax.value /= 100;
    }
    this.shipperData = definition.shipperData;
    this.nameCanonical = definition.nameCanonical;
    this.externalLinks = definition.information ? definition.information.customMenuExternalLinks || [] : [];
    this.locale = definition.locale;
    this.hourFormat = definition.hourFormat || "24";
    this.dataCandyActivated = definition.dataCandyActivated;
    this.freeBeesActivated = definition.freeBeesActivated;
    this.loyaltyActivated = definition.loyaltyActivated;
    this.orderRequirementsActivated = definition.orderRequirementsActivated;
    this.orderRequirements = [];
    this.slideshow = [];

    this.formatImagesForSlideshow();

    // Format zip code for canada
    if (this.address.zipCode) {
      this.address.zipCode = Format.formatPostalCode(this.address.zipCode);
    }

    // Format open hours
    let methods = this.getOrderMethodsAvailable();
    let days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    for (let method of methods) {
      if (method === "digital") {
        continue;
      }
      //keep raw hours as a reference, prettier for formatting
      this.onlineOrdering[method].rawHours = JSON.parse(JSON.stringify(this.onlineOrdering[method].hours));

      for (let i = 0; i < days.length; i++) {
        let currentDay = days[i];
        let previousDay = days[i - 1 < 0 ? days.length - 1 : i - 1];
        // Check yesterday if hours went into today

        if (!this.onlineOrdering[method].hours || !this.onlineOrdering[method].hours[currentDay]) {
          continue;
        }

        let currentHours = this.onlineOrdering[method].hours[currentDay];
        let previousHours = this.onlineOrdering[method].hours[previousDay];
        for (let hour of previousHours.hours) {
          let parsed = parseHour(hour.to);
          if (parsed.hour >= 24) {
            parsed.hour -= 24;
            currentHours.hours.push({ from: "0:00", to: parsed.toString() })
          }
        }
        // Eliminate duplicates
        for (let j = currentHours.hours.length - 1; j >= 0; j--) {
          for (let k = currentHours.hours.length - 1; k >= 0; k--) {
            if (j == k) {
              continue;
            }
            let parsed1From = parseHour(currentHours.hours[j].from);
            let parsed2From = parseHour(currentHours.hours[k].from);
            let parsed1To = parseHour(currentHours.hours[j].to);
            let parsed2To = parseHour(currentHours.hours[k].to);
            if (parsed1From.hour == parsed2From.hour && parsed1From.minute == parsed2From.minute &&
              parsed1To.hour == parsed2To.hour && parsed1To.minute == parsed2To.minute) {
              currentHours.hours.splice(k, 1);
            }
            break;
          }
        }
        // Order hours by starting time
        currentHours.hours.sort((a, b) => {
          let parsedA = parseHour(a.from);
          let parsedB = parseHour(b.from);
          if (parsedA.hour == parsedB.hour) {
            return parsedA.minute - parsedB.minute;
          }
          return parsedA.hour - parsedB.hour;
        })
      }
    }

    // Make sure that taxes exist
    this.validateTaxes();
  }

  setIntervalStats(stats) {
    this.intervalStats = stats;
    if (typeof this.intervalStats.orders.length !== "undefined") {
      this.intervalStats.orders = null;
    }
  }

  formatImagesForSlideshow() {
    this.slideshow = [];

    for (let key of Object.keys(this.images)) {
      let image = this.images[key];
      if (image.tags && image.tags.indexOf("slideshow") > -1) {
        image.url = Store.state.urlFileServer + image.url;
        this.slideshow.push(image);
      }
    }

    this.slideshow.sort((a, b) => a.priority - b.priority);
  }

  /**
   * @returns {String[]}
   */
  getSlideshowUrls() {
    return this.slideshow.map(s => s.url);
  }

  /**
   * @returns {boolean}
   */
  hasTaxes() {
    return this.taxes.length > 0;
  }

  validateTaxes() {
    const taxesRequired = ["CA"];
    if (!this.hasTaxes() && taxesRequired.indexOf(this.iso2country) > -1) {
      this.emergencyClose = true;
      this.emergencyCloseMessage = {
        fr: "Cette succursale n'est présentement pas disponible pour la commande en ligne.",
        en: "This location is currently unavailable for online ordering."
      };
      console.error("MISSING TAXES", "Taxes not configured for company", this.nameCanonical);
    }
  }

  /**
   * @param {string} hour
   */
  isIntervalAvailable(hour) {
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    if (!this.intervalStats || !this.intervalStats.orders || !entity.method) {
      return true;
    }
    let formatted = moment(hour, "HH:mm").format("YYYY-MM-DD HH:mm");
    let amount = this.intervalStats.orders[formatted] || 0;
    let max = parseInt(this.onlineOrdering[entity.method].inAdvance.maxPerInterval || 0);
    return max == 0 || amount < max;
  }

  /**
   * @param {string} method
   */
  getOpenDays(method) {
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    method = method || entity.method || "takeout";
    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    let opened = days.map(d => {
      return this.onlineOrdering[method].hours[d].hours.length > 0;
    });
    return opened;
  }

  hasPaymentAfterPickupEnabled() {
    return this.onlineOrdering && this.onlineOrdering.default && this.onlineOrdering.default.activatePaymentOnly;
  }

  /**
   * @returns {function} function to check disabled days
   */
  getFunctionToCheckIfDayDisabled() {
    return (day, year, month) => {
      let date = moment({
        year,
        month: month - 1,
        date: day
      });
      if (this.isTodayDisabled() && moment().format("YYYY-MM-DD") == date.format("YYYY-MM-DD")) {
        return true;
      }
      if (Store.state.currentBranch && Store.state.currentBranch.isShutdownScheduledForDay(date, this.id)) {
        return true;
      }
      let openDays = this.getOpenDays();
      return !openDays[date.day()];
    };
  }

  getOpenDatetimeForMethod(method) {
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    let date = entity.date.format("YYYY-MM-DD");
    let hours = this.getAvailableOpenHours(method);
    if (hours[0] === "asap") {
      hours.shift();
    }
    let finishesNextDay = false;
    for (let i = 0; i < hours.length - 1; i++) {
      // If next hour is < current hour, then day has changed because hours should always be equal or go up
      if (Number.parseInt(hours[i + 1].substr(0, 2)) < Number.parseInt(hours[i].substr(0, 2))) {
        finishesNextDay = true;
        break;
      }
    }
    if (hours.length > 0) {
      let orderDate = entity.date.clone();
      let toDate = finishesNextDay ? orderDate.add(1, "days").format("YYYY-MM-DD") : date;
      return {
        from: date + " " + hours[0] + ":00",
        to: toDate + " " + hours[hours.length - 1] + ":00"
      };
    } else {
      return null;
    }
  }

  getInAdvanceConfiguration(method) {
    if (!this.onlineOrdering || !this.onlineOrdering[method]) {
      return {
        activated: false
      };
    }
    return this.onlineOrdering[method].inAdvance;
  }

  getOpenHoursForMethod(method) {
    if (!this.onlineOrdering || !this.onlineOrdering[method]) {
      return;
    }
    return this.onlineOrdering[method].hours;
  }

  getRawOpenHoursForMethod(method) {
    if (!this.onlineOrdering || !this.onlineOrdering[method]) {
      return;
    }
    return this.onlineOrdering[method].rawHours;
  }

  getGeolocation() {
    if (!this.address) {
      return;
    }
    return {
      latitude: this.address.latitude,
      longitude: this.address.longitude
    };
  }

  /**
   * @param {string} method
   */
  isTodayDisabled(method) {
    method = method || Store.state.order.method;
    if (!method) {
      return false;
    }
    let result = this.onlineOrdering[method].inAdvance.todayDisabled;
    if (typeof result !== "boolean") {
      return method == "catering";
    }
    return result;
  }

  /**
   * @param {string} [method]
   */
  isFutureDateEnabled(method) {
    method = method || Store.state.order.method;
    if (!method || method === "digital") { //TBD si jamais cest activé pour le digital
      return false;
    }
    let result = this.onlineOrdering[method].inAdvance.futureDate;
    if (typeof result !== "boolean") {
      result = method == "catering";
    }
    return result && this.hasInAdvance(method);
  }

  /**
   * @param {string} [method]
   * @param {int} [forDay]
   */
  getOpenHours(method, forDay) {
    if (typeof forDay === "undefined") {
      forDay = moment().day();
    }
    method = method || Store.state.order.method || "takeout";
    if (!this.onlineOrdering || !this.onlineOrdering[method] || !this.onlineOrdering[method].hours) {
      return null;
    }
    let data;

    if (Store.state.order.branding) {
      data = Store.state.order.branding.getOpenHoursForDay(forDay, method);
      data.averageDelay = Store.state.order.branding.getAverageDelay(method);
      //TODO should a branding have also interval, asap & inAdvance values? TBD there could be a mismatch between the branding's hour and desired intervals
    } else {
      let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      let day = days[forDay];
      data = this.onlineOrdering[method].hours[day];
      /* Assign delay */
      let deliveryDelay = this.getDeliveryZoneAverageDelay(method);
      if (deliveryDelay) {
        data.averageDelay = deliveryDelay;
      } else {
        data.averageDelay = this.onlineOrdering[method].general.averageDelay;
      }
    }

    data.inAdvance = this.onlineOrdering[method].inAdvance.activated || false;
    data.asap = this.onlineOrdering[method].inAdvance.activated && this.onlineOrdering[method].inAdvance.ASAP;
    data.interval = parseInt(this.onlineOrdering[method].inAdvance.intervalInMinutes) || 15;
    return data;
  }

  getDeliveryZoneAverageDelay(method) {
    if (method !== "delivery") {
      return;
    }
    if (Store.state.user.getDefaultAddress()) {
      let zone = this.isInDeliveryZone(Store.state.user.getDefaultAddress());
      if (zone && zone.averageDelay) {
        return zone.averageDelay;
      }
    }
  }

  /**
   * @param {string} [method]
   */
  getNextOpenHours(method) {
    // TODO - looper les méthodes si on en recoit pas
    let hours = this.getOpenHours(method);
    if (!hours) {
      return null;
    }
    let currentTime = parseHour(moment().format("HH:mm"));
    for (let hour of hours.hours) {
      let parsedTo = parseHour(hour.to);
      if (!currentTime.isAfter(parsedTo)) {
        return hour;
      }
    }
    return null;
  }

  /**
   * @param {moment.Moment} time
   */
  isInOpenHours(time, methodToCheck = null) {
    let oneOpened = false;
    let toCheck = methodToCheck ? [methodToCheck] : this.getOrderMethodsAvailable();
    if (methodToCheck === "digital") {
      return true;
    }
    for (let method of toCheck) {
      let openHours = this.getOpenHours(method);
      if (!openHours) {
        continue;
      }
      for (let range of openHours.hours) {
        let parsedTo = parseHour(range.to);
        /*
        FIX : bug with hour change every 6 months :)
        let nextDay = false;
        if (parsedTo.hour >= 24) {
          parsedTo.hour -= 24;
          nextDay = true;
        }
        let to = moment(parsedTo.hour + ":" + parsedTo.minute, "HH:mm");
        if (nextDay) {
          to.add(1, "days");
        } */
        let to = moment("00:00:00", "HH:mm:ss").add(parsedTo.hour, "hours").add(parsedTo.minute, "minutes");
        if (time.isAfter(moment(range.from, "HH:mm")) && time.isBefore(to)) {
          oneOpened = true;
          break;
        }
      }
    }
    return oneOpened;
  }

  /**
   * @param {string} [method]
   * @returns {String[]}
   */
  getAvailableOpenHours(method) {
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    method = method || entity.method || "takeout";

    let forDay = entity.date ? moment(entity.date).tz(this.timezone).day() : null;
    let companyHours = this.getOpenHours(method, forDay);
    if (!companyHours) {
      return [];
    }
    let hours = [];
    for (let i = 0; i < companyHours.hours.length; i++) {
      let canChangeDate = method == "catering" || this.isFutureDateEnabled();
      if (companyHours.inAdvance || canChangeDate) {
        let from;
        let to;
        let startHour;
        // Create default start & end time for the day
        if (canChangeDate) {
          let date = entity.date;
          if (i === 0) {
            startHour = moment.tz(this.timezone).set({
              year: date.year(),
              month: date.month(),
              date: date.date(),
              hour: parseInt(companyHours.hours[i].from.split(":")[0]),
              minute: parseInt(companyHours.hours[i].from.split(":")[1])
            });
          }
          from = moment.tz(this.timezone).set({
            year: date.year(),
            month: date.month(),
            date: date.date(),
            hour: parseInt(companyHours.hours[i].from.split(":")[0]),
            minute: parseInt(companyHours.hours[i].from.split(":")[1])
          });
          to = moment.tz(this.timezone).set({
            year: date.year(),
            month: date.month(),
            date: date.date(),
            hour: 0,
            minute: 0
          });
        } else {
          startHour = moment(companyHours.hours[0].from, "HH:mm");
          from = moment(companyHours.hours[i].from, "HH:mm");
          to = moment("00:00", "HH:mm");
        }

        // Adjust end time to take into consideration delay and interval
        to.add({
          hours: parseInt(companyHours.hours[i].to.split(":")[0]),
          minutes: parseInt(companyHours.hours[i].to.split(":")[1]) + parseInt(companyHours.averageDelay) + parseInt(companyHours.interval)
        });

        // Make sure the start time is not before allowed time
        while (from.isBefore(to)) {
          let now = canChangeDate ? moment.tz(this.timezone) : moment();
          /* Reference hour is :
          *  - Either start hour of the day or current time, if start hour has passed
          *  - If no start hour is defined, then reference hour would be now */
          let referenceHour = (!startHour || now.isAfter(startHour)) ? now : startHour.clone();
          let referenceHourPlusDelay = referenceHour.add(companyHours.averageDelay, "minutes");
          if (from.isSameOrAfter(referenceHourPlusDelay)) { // only add valid hours
            let hour = from.tz(this.timezone).format("HH:mm");
            hours.push(hour);
          }
          from.add(companyHours.interval, "minutes");
        }
      }
      let parsedTo = parseHour(companyHours.hours[i].to);
      let to = moment("00:00:00", "HH:mm:ss").add(parsedTo.hour, "hours").add(parsedTo.minute, "minutes");
      if (moment().isBetween(moment(companyHours.hours[i].from, "HH:mm"), to)) {
        if (companyHours.inAdvance || method == "catering") {
          if (companyHours.asap && hours.indexOf("asap") == -1) {
            hours.unshift("asap");
          }
          if (parseHour(companyHours.hours[i].from).hour == 0) {
            break;
          }
        } else if (hours.indexOf("delay")) {
          hours.unshift("delay");
        }
      }
    }
    return hours;
  }

  /**
   *
   * @returns {null|number}
   */
  getFixedDeliveryFeeForDoorDash() {
    if (this.isDoorDashActivated() && this.shipperData && this.shipperData.deliveryFees && this.shipperData.deliveryFees.activated) {
      let amount = parseFloat(this.shipperData.deliveryFees.amount);
      if (Number.isNaN(amount)) { // Invalid value
        return null;
      }
      return amount;
    }
    return null;
  }

  /**
   *
   * @returns {boolean}
   */
  areDoorDashDeliveryFeesNonTaxable() {
    return this.isDoorDashActivated() && this.shipperData && this.shipperData.deliveryFees && this.shipperData.deliveryFees.nonTaxable;
  }

  /**
   * @returns {{amount: number, taxIncluded?: boolean, minimumPrice?: number}}
   */
  getDeliveryFees() {
    if (Store.state.order.method != "delivery") {
      return { amount: 0 };
    }
    if (this.isDoorDashActivated()) {
      let fixedDeliveryFee = this.getFixedDeliveryFeeForDoorDash();
      if (fixedDeliveryFee !== null) {
        return { amount: fixedDeliveryFee, taxIncluded: this.areDoorDashDeliveryFeesNonTaxable() };
      } else {
        if (this.areDoorDashDeliveryFeesNonTaxable()) {
          //If DD fees are non-taxable then the fees received from DD DO NOT include tax in and WONT be taxed
          return { amount: this.doorDashFees, taxIncluded: true };
        } else {
          //Fee is taxable & DD sends it tax-in, we remove them from fee to tax it properly here and in the POS
          let feeTaxes = this.taxes.reduce((sum, tax) => sum + tax.value, 1);//start sum at 1
          let feeWithoutTaxes = this.doorDashFees / feeTaxes;
          return { amount: feeWithoutTaxes, taxIncluded: false };
        }
      }
    }
    let zone = null;
    if (Store.state.user.getDefaultAddress()) {
      zone = this.isInDeliveryZone(Store.state.user.getDefaultAddress());
    }
    if (!zone || !zone.fees) {
      return { amount: 0, taxIncluded: false };
    }
    zone.fees.amount = parseFloat(String(zone.fees.amount));
    return zone.fees; // TODO use appropriate zone ?
  }

  /**
   * @param {string} method
   * @returns {{amount: number, taxIncluded: boolean}}
   */
  getServiceFees(method) {
    if (!method || !this.onlineOrdering[method]) {
      return null;
    }
    let fees = this.onlineOrdering[method].general.serviceFees;
    fees.amount = parseFloat(fees.amount);
    return fees;
  }

  /**
   * @param {string} method
   */
  getMinimumOrderPrice(method) {
    if (!method || !this.onlineOrdering[method]) {
      return null;
    }
    let minimum = 0;
    if (Store.state.order.method == "delivery") {
      let zone = this.isInDeliveryZone(Store.state.user.getDefaultAddress());
      if (zone && zone.fees && zone.fees.minimumPrice) {
        minimum = parseFloat(zone.fees.minimumPrice);
      }
    }
    if (parseFloat(this.onlineOrdering[method].general.minimumPrice)) {
      minimum = Math.max(minimum, parseFloat(this.onlineOrdering[method].general.minimumPrice));
    }
    return minimum;
  }

  /**
   * @param {string} method
   */
  getTipsEnabled(method) {
    if (!method || !this.onlineOrdering[method]) {
      return null;
    }
    return parseFloat(this.onlineOrdering[method].general.tipsActivated);
  }

  async isInDeliveryZoneWithDoorDash(address, method) {
    if (this.isDoorDashActivated() && method === "delivery") {
      let response = CONFIG.pos ? Store.state.table.getDoorDashEstimate(this) : await Store.state.order.getDoorDashEstimate(this);

      if (!response || (response.field_errors && response.field_errors.length > 0)) {
        return false;
      } else {
        return true;
      }
    }
    return true; //Anything if not delivery or with doordash will return 'true' to be accepted as a valid company
  }

  isInDeliveryZone(address) {
    if (!address || !this.onlineOrdering || !this.onlineOrdering.delivery || !this.onlineOrdering.delivery.activated) {
      return null;
    }
    //TODO this is bad, return either null or an object, what does true mean?
    if (this.hasZonesDisabled() || this.isDoorDashActivated()) { //DoorDash will return true because DoorDash delivery validation is not performed within this method. See 'isInDeliveryZoneWithDoorDash'.
      return true;
    }
    if (!this.onlineOrdering.delivery.zones) {
      return null;
    }

    //Todo this should be in another function
    for (let zone of this.onlineOrdering.delivery.zones) {
      let zipCodes = zone.zipCodes;
      if (zipCodes && zipCodes.length > 0 && address.postalCode) {
        // TODO - checker si c'est encore necessaire
        if (typeof zipCodes === "string") {
          zipCodes = zipCodes.split(",");
        }
        zipCodes = zipCodes.map(z => z.toLowerCase().replace(/ /g, "")).filter(z => z.length > 0);
        let clientZipcode = address.postalCode.toLowerCase().replace(/ /g, "");
        for (let code of zipCodes) {
          if (clientZipcode.startsWith(code)) {
            address.zoneName = zone.name ? zone.name : "";
            return zone;
          }
        }
      }
      let polygons = zone.polygons;
      if (typeof polygons === "string" && polygons.length > 0) {
        try {
          polygons = JSON.parse(polygons);
        } catch (e) {
          console.log('error parsing polygons', polygons);
          polygons = null;
        }
      }
      if (polygons && address.latitude && address.longitude) {
        let inPolygon = GeocodingHelper.coordInPolygon({ lat: address.latitude, lng: address.longitude }, polygons);
        if (inPolygon) {
          address.zoneName = zone.name ? zone.name : "";
          return zone;
        }
      }
    }
    return null;
  }

  hasZonesDisabled() {
    return this.onlineOrdering && this.onlineOrdering.delivery && this.onlineOrdering.delivery.zonesDisabled;
  }

  hasDeliveryZones() {
    return this.onlineOrdering && this.onlineOrdering.delivery && this.onlineOrdering.delivery.zones && this.onlineOrdering.delivery.zones.length > 0;
  }

  hasZones() {
    if (this.isDoorDashActivated()) {
      return true;
    }

    if (this.hasZonesDisabled() || !this.hasDeliveryZones()) {
      return false;
    }
    for (let zone of this.onlineOrdering.delivery.zones) {
      if (zone.polygons && zone.polygons.length > 0) {
        return true;
      }
    }
    return false
  }

  /**
   * @param {string} zip
   */
  isZipCodeInZone(zip) {
    for (let zone of this.onlineOrdering.delivery.zones) {
      let zipCodes = zone.zipCodes;
      if (zipCodes && zipCodes.length > 0 && zip) {
        // TODO - checker si c'est encore necessaire
        if (typeof zipCodes === "string") {
          zipCodes = zipCodes.split(",");
        }
        zipCodes = zipCodes.map(z => z.toLowerCase().replace(" ", "")).filter(z => z.length > 0);
        let clientZipcode = zip.toLowerCase().replace(" ", "");
        for (let code of zipCodes) {
          if (clientZipcode.startsWith(code)) {
            return zone;
          }
        }
      }
      return false;
    }
  }

  /**
    *
   * @param {string} method
   * @returns {boolean}
   */
  hasOrderMethod(method) {
    if (!this.onlineOrdering) {
      return false;
    }
    return this.onlineOrdering[method] && this.onlineOrdering[method].activated && !this.onlineOrdering[method].hidden;
  }

  getValitorCardBrands() {
    let valitorPaymentConfig = (this.payments || []).find(p => p.processor === "valitor");
    if (valitorPaymentConfig && valitorPaymentConfig.cards) {
      let cards = Object.keys(valitorPaymentConfig.cards);
      return cards.filter(c => !!valitorPaymentConfig.cards[c]);
    }
    return [];
  }

  /**
   * @returns {string[]} Available order methods
   */
  getOrderMethodsAvailable() {
    if (!this.onlineOrdering) {
      return [];
    }
    if (Store.state.ticketMode) {
      return ["digital"];
    }
    let methods = [];
    if (this.onlineOrdering.delivery && this.onlineOrdering.delivery.activated) { methods.push("delivery"); }
    if (this.onlineOrdering.takeout && this.onlineOrdering.takeout.activated) { methods.push("takeout"); }
    if (this.onlineOrdering.inPlace && this.onlineOrdering.inPlace.activated) { methods.push("inPlace"); }
    if (this.onlineOrdering.hotel && this.onlineOrdering.hotel.activated) { methods.push("hotel"); }
    if (this.onlineOrdering.catering && this.onlineOrdering.catering.activated) { methods.push("catering"); }
    return methods;
  }

  getOpenOrderMethods() {
    let methods = this.getOrderMethodsAvailable();
    return methods.filter(method => (this.getOnlineOrderingStartHour(method) || this.isFutureDateEnabled(method)));
  }

  /**
   * @param {string} processor
   */
  getPaymentMethod(processor) {
    if (!this.payments) {
      return null;
    }
    return this.payments.filter(p => p.processor == processor)[0];
  }

  getFirstOnlinePaymentMethod() {
    if (this.payments.length > 0) {
      return this.payments[0].processor;
    }
  };

  getPaymentMethods() {
    let methods = [];
    for (let payment of this.payments) {
      if (payment.processor == "none") {
        if (payment.methods.length == 0) {
          methods.push("none");
        } else {
          methods = methods.concat(payment.methods);
        }
      } else {
        methods.push(payment.processor);
      }
    }

    if (this.hasOfflinePaymentMethods()) {
      if (methods.includes("none")) {
        methods = methods.filter(m => m != "none");
      }
      methods = methods.concat(this.getOfflinePaymentMethods());
    }

    return methods.filter((value, index) => methods.indexOf(value) == index);
  }

  isOfflinePaymentMethod(method) {
    return this.getOfflinePaymentMethods().indexOf(method) > -1;
  }

  getOfflinePaymentMethods() {
    if ((this.isDoorDashActivated() && Store.state.order.method === "delivery") || Store.state.order.paymentOnly) {
      return [];
    }
    return (this.offlinePaymentMethods || []);
  }

  hasOfflinePaymentMethods() {
    return this.getOfflinePaymentMethods().length > 0;
  }

  isDoorDashActivated() {
    return this.shipper && this.shipper === "doordash";
  }

  /**
   * @param {string} method
   */
  hasPaymentMethod(method) {
    return this.getPaymentMethods().indexOf(method.toLowerCase()) > -1;
  }

  /**
   * @returns {boolean}
   */
  hasSocialInformation() {
    return this.information && ((this.information.social.facebook && this.information.social.facebook.length > 0) ||
      (this.information.social.website && this.information.social.website.length > 0) ||
      (this.information.social.instagram && this.information.social.instagram.length > 0) ||
      (this.information.social.twitter && this.information.social.twitter.length > 0));
  }

  showEnterMessageForMethod(method) {
    if (CONFIG.kiosk || Store.state.ticketMode) {
      return;
    }
    let enterMessage = this.getEnterMessage(method);
    let enterMessageType = this.getEnterMessageType(method);
    let openMethods = this.getOpenOrderMethods();
    if (!enterMessage || enterMessageType !== 'popup' || openMethods.indexOf(method) === -1) {
      return;
    }
    showAffirmation(window.translate("message"), window.translateObject(enterMessage));
  }

  getEnterMessage(method) {
    if (!this.onlineOrdering || !this.onlineOrdering[method] || !this.onlineOrdering[method].onEnterMessage) {
      return null;
    }
    return this.onlineOrdering[method].onEnterMessage.message;
  }

  getEnterMessageType(method) {
    if (!this.onlineOrdering || !this.onlineOrdering[method] || !this.onlineOrdering[method].onEnterMessage) {
      return null;
    }
    return this.onlineOrdering[method].onEnterMessage.type;
  }

  /**
   * @returns {boolean}
   */
  shouldHideKioskCategoryImages() {
    return CONFIG.kiosk && this.kioskStyle && this.kioskStyle.hideCategoryImages;
  }

  /**
   * @returns {boolean}
   */
  shouldHideKioskOutOfStockProducts() {
    return CONFIG.kiosk && this.kioskStyle && this.kioskStyle.hideOutOfStockProducts;
  }

  isCurrentMethodClosed(method) {
    if (this.emergencyClose) {
      return true;
    }
    if (CONFIG.kiosk) {
      return false;
    }
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    method = method || entity.method;
    if (this.hasInAdvance(method)) {
      if (this.getAvailableOpenHours(method).filter(h => h != "asap").length > 0) {
        return false;
      }
      return true;
    }
    return !this.isInOpenHours(moment(), method) && method != "catering";
  }

  /**
   * @returns {boolean}
   */
  isClosed() {
    if (this.emergencyClose) {
      return true;
    }
    if (CONFIG.kiosk) {
      return this.kioskEmergency && this.kioskEmergency.emergencyCloseKioskManually;
    }

    if (Store.state.ticketMode) {
      return false;
    }

    if (Store.state.ticketMode) {
      return false;
    }

    if (this.hasInAdvance()) {
      let oneOpened = false;
      for (let method of this.getOrderMethodsAvailable()) {
        if (this.getAvailableOpenHours(method).filter(h => h != "asap").length > 0) {
          oneOpened = true;
        }
      }
      return !oneOpened;
    }

    return !this.isInOpenHours(moment()) && Store.state.order.method != "catering";
  }

  /**
   * @returns {string}
   */
  getClosedMessage() {
    return this.emergencyClose
      ? window.translateObject(this.emergencyCloseMessage)
      : "";
  }

  getKioskClosedMessage() {
    return (this.kioskEmergency && this.kioskEmergency.emergencyCloseKioskManuallyMessage)
      ? window.translateObject(this.kioskEmergency.emergencyCloseKioskManuallyMessage)
      : null;
  }

  /**
   * @param {string} methodToCheck
   */
  hasInAdvance(methodToCheck = null) {
    let methods = this.getOrderMethodsAvailable();
    for (let method of methods) {
      if ((!methodToCheck || methodToCheck == method) && this.getOpenHours(method) && this.getOpenHours(method).inAdvance) {
        return true;
      }
    }
    return false;
  }

  hasTipEnabled(method) {
    if (this.isDoorDashActivated() && method === "delivery") {
      return true;
    }
    if (Store.state.qrPaymentMode && this.payAtTheTable) {
      return this.payAtTheTable.tipsActivated;
    }
    if (!this.onlineOrdering || !this.onlineOrdering[method] || !this.onlineOrdering[method].general) {
      return false;
    }
    return !!this.onlineOrdering[method].general.tipsActivated;
  }

  hasEWalletActivated() {
    return Store.state.currentBranch.eWalletActivated;
  }

  async getDigitalMenuUrlForLocale(locale, embedPdfForMobileViewing) {
    if (!this.payAtTheTable || !this.payAtTheTable.digitalMenu) {
      return;
    }
    let digitalMenuConfig = this.payAtTheTable.digitalMenu[locale];
    if (digitalMenuConfig && digitalMenuConfig.getFromUrl) {
      return digitalMenuConfig.fileUrl;
    } else if (digitalMenuConfig && digitalMenuConfig.fileId) {
      let file = await store.dispatch("getBranchMedia", { imageId: digitalMenuConfig.fileId });
      if (file && file.url) {
        let url = CONFIG.urlFileServer + file.url;
        if (embedPdfForMobileViewing) {
          url = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + url;
        }
        return url;
      }
    }
    return null;
  }

  hasDigitalMenuUrlForLocale(locale) {
    if (!this.payAtTheTable || !this.payAtTheTable.digitalMenu) {
      return;
    }
    let digitalMenuConfig = this.payAtTheTable.digitalMenu[locale];
    let fileUrl = digitalMenuConfig.getFromUrl ? digitalMenuConfig.fileUrl : digitalMenuConfig.fileId;
    return !!fileUrl;
  }

  hasFreeBeesActivated() {
    return this.freeBeesActivated;
  }

  hasLoyaltyActivated() {
    return this.dataCandyActivated || this.freeBeesActivated || Store.state.currentBranch.eWalletActivated;
  }

  isDataCandyOrFreeBeesEnabled() {
    return this.dataCandyActivated || this.freeBeesActivated;
  }

  isDataCandyEnabled() {
    return this.dataCandyActivated;
  }

  hasCommentsActivated() {
    if (!this.onlineOrdering || !this.onlineOrdering.default) {
      return false;
    }
    return !!this.onlineOrdering.default.general.commentBoxActivated;
  }

  hasQRPaymentEnabled() {
    return this.payAtTheTable && this.payAtTheTable.activated;
  }

  disableMultiCheckSelectionForQrPayment() {
    return this.payAtTheTable && this.payAtTheTable.disableMultiCheckSelection;
  }

  scanCheckOnlyForQrPayment() {
    return this.payAtTheTable && this.payAtTheTable.scanCheckOnly;
  }

  getQRPayment() {
    return this.payAtTheTable;
  }

  phoneNotRequired() {
    if (!this.onlineOrdering || !this.onlineOrdering.default) {
      return false;
    }
    return this.onlineOrdering.default.phoneNotRequired;
  }

  /**
   * @param {string} tableNumber
   * @returns {boolean}
   */
  validateTable(tableNumber) {
    return this.onlineOrdering.inPlace.tables.length > 0
      ? this.onlineOrdering.inPlace.tables.indexOf(tableNumber) > -1
      : true;
  }

  /**
   * @param {string} roomNumber
   * @returns {boolean}
   */
  validateRoom(roomNumber) {
    if (!roomNumber) {
      return false;
    }
    return this.onlineOrdering.hotel.rooms.length > 0
      ? this.onlineOrdering.hotel.rooms.indexOf(roomNumber) > -1
      : true;
  }

  getQuestions() {
    return this.onlineOrdering.catering.questions;
  }

  /**
   * @returns {number[]}
   */
  getTipOptions() {
    //TODO refactor and merge these duplicates
    if (!(this.isDoorDashActivated() && Store.state.order.method === "delivery")) {
      if (Store.state.qrPaymentMode && this.payAtTheTable) {
        let options = this.payAtTheTable.tipOptions.split(",").map(o => parseInt(o)).filter(o => !!o);
        if (options.length > 0) {
          return options;
        }
      }
      if (this.onlineOrdering.default.general.tipOptions) {
        let options = this.onlineOrdering.default.general.tipOptions.split(",").map(o => parseInt(o)).filter(o => !!o);
        if (options.length > 0) {
          return options;
        }
      }
    }
    return [10, 12, 15, 20];
  }

  shouldHideNoneTipOption() {
    if (this.isDoorDashActivated() && Store.state.order.method === "delivery") {
      return true;
    }
    if (Store.state.qrPaymentMode && this.payAtTheTable) {
      return this.payAtTheTable.hideNoneTipOption;
    }
    return this.onlineOrdering && this.onlineOrdering.default && this.onlineOrdering.default.hideNoneTipOption;
  }

  shouldHideFixedAmountTipOption() {
    return this.onlineOrdering && this.onlineOrdering.default && this.onlineOrdering.default.hideFixedAmountTipOption;
  }

  shouldNotifyCustomerOnPosFailureWhenPosBypass() {
    return this.bypassPosMode && this.notifyCustomerOnBypassPos;
  }

  shouldApplyTipOnTotal() {
    return Store.state.qrPaymentMode && this.payAtTheTable && this.payAtTheTable.tipOnTotal;
  }

  isSmsActivatedForCustomers() {
    return this.onlineOrdering && this.smsActivated && this.onlineOrdering.default.notifications.activateCustomerNotificationsBySms;
  }

  getPreselectedTipValue() {
    if (this.isDoorDashActivated() && Store.state.order.method === "delivery") {
      return 20;
    }
    if (Store.state.qrPaymentMode && this.payAtTheTable) {
      return this.payAtTheTable.defaultTipAmount;
    }
    return this.onlineOrdering.default.defaultTipAmount;
  }

  hasThirdPartyDelivery() {
    return this.thirdPartyDeliveryPlatforms && this.thirdPartyDeliveryPlatforms.length > 0;
  }

  hasOrderMethodActivated(method) {
    return !!this.onlineOrdering[method] && this.onlineOrdering[method].activated;
  }

  calculateDistanceFromUser(latitude, longitude) {
    let geolocation = this.getGeolocation();
    if (!geolocation) {
      console.log("no geoloc");
      return;
    }
    let distance = window.calculateDistance(latitude, longitude, geolocation.latitude, geolocation.longitude);
    this.distanceFromUser = distance;
  }

  /**
   * @param {Number} number
   * @returns {string}
   * @private
   */
  _formatHour(number) {
    return this._leftPad(String(Math.floor(number / 4)), 2) + ":" + this._leftPad(String((number % 4) * 15), 2);
  }

  /**
   * @param {String} str
   * @param {Number} length
   * @param {String|null} pad
   * @returns {string}
   * @private
   */
  _leftPad(str, length, pad = "0") {
    return pad.repeat(Math.max(length - String(str).length, 0)) + String(str);
  }

  getActivePromotionModal() {
    return this.promotionModals.find(p => p.active);
  }

  displayPromotionModal() {
    let DELAY = 3 * 1000;
    let modal = this.getActivePromotionModal();
    if (modal) {
      setTimeout(() => {
        EventBus.$emit("show-promotion-modal", modal);
      }, DELAY);
    }
  }

  displayRecommenderModal() {
    let DELAY = 2 * 1000;
    if (this.getActivePromotionModal()) {
      return;
    }
    setTimeout(() => {
      EventBus.$emit("show-recommendations");
    }, DELAY);
  }

  getOnlineOrderingStartHour(method = Store.state.order.method) {
    let hours = this.getNextOpenHours(method);
    if (!hours) {
      return null;
    }
    return moment(hours.from, "HH:mm");
  }

  checkIfClosedOrInAdvance() {
    if (Store.state.displayMode) {
      return "display-mode";
    }

    // 1. if emergency close
    if (this.emergencyClose) {
      this.showEmergencyCloseMessage();
      return "emergency-close";
    }

    //Order type must be check availability
    if (!Store.state.order.method) {
      return "no-method";
    }

    let openMethods = this.getOpenOrderMethods();

    //2. In advance is enabled and hours are available for today
    let start = this.getOnlineOrderingStartHour();
    if (this.hasInAdvance(Store.state.order.method) && start) {
      if (moment().isBefore(start)) { //current time is before order time
        EventBus.$emit("show-order-closed-modal");
        return "in-advance-with-start";
      }
      //4. current time is valid to order for selected method
      return "can-order";
    }

    //2a. In advance is NOT enabled but hours are available later for today
    if (!this.hasInAdvance(Store.state.order.method) && start) {
      if (moment().isBefore(start)) { //current time is before order time
        let modalContent = {
          title: window.translate("inAdvance.method_closed").replace("{METHOD}", window.translate(Store.state.order.method)),
          text: window.translate("inAdvance.closed").replace("{HOUR}", start.format("HH:mm"))
        };
        EventBus.$emit("show-order-closed-modal", modalContent);
        return "closed-until-start";
      }
      //4. current time is valid to order for selected method
      return "can-order";
    }

    //3. Current time is after order time or current order method is closed
    if (!start || this.isCurrentMethodClosed()) {
      let response;
      let modalContent = {
        title : window.translate("inAdvance.method_closed").replace("{METHOD}", window.translate(Store.state.order.method)),
        text: window.translate("inAdvance.method_closed_description").replace("{METHOD}", window.translate(Store.state.order.method)) + "<br><br>"
      };
      let moreMethodsAvailable = openMethods.filter(method => method !== Store.state.order.method).length >= 1;

      //3a. Future date is possible
      if (this.isFutureDateEnabled()) {
        if (moreMethodsAvailable) { //More methods are available
          modalContent.text += window.translate("inAdvance.date_and_methods");
          response = "after-time-future-date-more-methods";
        } else { //Only current method is available in a future date
          modalContent.text += window.translate("inAdvance.date_only");
          response = "after-time-future-date-different-day";
        }
      //3b. Future date is not possible
      } else {
        if (moreMethodsAvailable) { //More methods are available
          modalContent.text += window.translate("inAdvance.method_only");
          response = "after-time-more-methods";
        } else { //All methods are unavailable
          modalContent.text += window.translate("inAdvance.no_date_no_method");
          response = "after-time-all-methods-closed";
        }
      }
      EventBus.$emit("show-order-closed-modal", modalContent);
      return response;
    }

    //4. else nothing happens and current time is valid to order for selected method
    return "can-order";
  }

  showEmergencyCloseMessage() {
    if (CONFIG.kiosk || CONFIG.pos) {
      return;
    }
    let hourForToday = this.getOnlineOrderingStartHour();
    let message = this.getClosedMessage() ? Format.escapeHtml(this.getClosedMessage()) : window.translate("inAdvance.closed_no_hour");
    if (hourForToday && !this.getClosedMessage()) {
      message = window.translate("inAdvance.closed").replace("{HOUR}", hourForToday.format("HH:mm"));
    }
    showAffirmation(window.translate("emergency_close_title"), message, null, "inactive");
  }

  /**
   * @param {boolean} askForConfirmation
   * @return {boolean}
   */
  async checkIfOrderDateIsInTheFuture(askForConfirmation) {
    let date = Store.state.order.date;
    let differenceInDays = moment().diff(date, "days");
    let isFutureDate = differenceInDays < 0 || (differenceInDays === 0 && date.day() === moment().add(1, "day").day());
    let dateFormat = LocalizeStore.state.locale === "en" ? "MMMM D" : "D MMMM";
    let response;

    if (isFutureDate) {
      if (askForConfirmation) {
        response = await showConfirmation(window.translate("inAdvance.future_date_title"),
          window.translate("inAdvance.future_date_confirmation").replace("{DATE}", date.format(dateFormat)));
      } else {
        await showAffirmation(window.translate("inAdvance.future_date_title"),
          window.translate("inAdvance.future_date").replace("{DATE}", date.format(dateFormat)));
        response = true;
      }
    } else {
      response = true;
    }

    return response;
  }

  formatHour(hour) {
    if (hour == "asap") {
      return window.translate("order.asap");
    }
    let h = parseInt(hour.substring(0, 2));
    let m = parseInt(hour.substring(3));
    if (h <= 24) {
      h = h % 24;
    }
    let parsed = moment().set({
      hour: h,
      minutes: m
    });
    return this.formatMomentHour(parsed);
  }

  formatMomentHour(hour) {
    if (!hour.isValid()) {
      return null;
    }

    if (this.hourFormat == "12") {
      return hour.format("hh:mm A");
    } else {
      return hour.format("HH:mm");
    }
  }

  /**
   * @param {Number} branchId
   */
  transferGeocodingLogs(branchId) {
    if (!this.onlineOrdering || !this.onlineOrdering.delivery || !this.onlineOrdering.delivery.activated) {
      return;
    }
    //Fetch logs for current day and current branch
    let logs = getConfiguration("pendingGeocodingLogs", sessionStorage) || [];
    let activeBranchLogs = logs.filter(l => l.branch === branchId && l.date === getTodayStringDate());

    // Combine logs to reduce API requests
    let combinedLogs = [];
    for (let log of activeBranchLogs) {
      let existingLog = combinedLogs.find(l => l.service === log.service && l.type == log.type);
      if (existingLog) {
        existingLog.increment += log.increment;
      } else {
        combinedLogs.push(log);
      }
    }

    //construct logs for API call
    combinedLogs = combinedLogs.map(l => {return {
      service: l.service,
      type: l.type,
      company: this.id,
      increment: l.increment,
      removeFromBranch: true
      }
    });

    // Send API call for transfer from branch logs to company logs
    for (let log of combinedLogs) {
      Store.dispatch("logGeocoding", log);
    }

    // Reset logs
    setConfiguration("pendingGeocodingLogs", [], sessionStorage);
  }

  validateClientTimeZone() {
    if (!this.blockOrdersOutsideTimezone) {
      return true;
    }
    let deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let companyTimezoneOffset = window.getTimezoneOffset(this.timezone);
    let deviceTimezoneOffset = window.getTimezoneOffset(deviceTimezone);
    if (companyTimezoneOffset != deviceTimezoneOffset) {
      console.log(`INVALID TIMEZONE : device has timezone ${deviceTimezone}(${deviceTimezoneOffset}) and company has timezone ${this.timezone}(${companyTimezoneOffset})`);
      this.emergencyClose = true;
      this.emergencyCloseMessage = {
        fr: "Vous n'êtes pas dans le même fuseau horaire que ce restaurant. Veuillez valider la configuration de votre appareil ou sélectionner une succursale dans votre secteur.",
        en: "You are not in the same timezone as this restaurant. Please check your device configuration or select a location closer to your area."
      };
      return false;
    }
    return true;
  }

  updateEmergencySetting(definition) {
    this.emergencyClose = definition.emergencyClose;
    this.emergencyCloseMessage = definition.emergencyCloseMessage;
    if (definition.kiosk && definition.kiosk.emergency) {
      this.kioskEmergency = definition.kiosk.emergency
    }
  }

  isUsBased() {
    if (this.iso2country) {
      return this.iso2country.toLowerCase() === "us"
    }
    return false;
  }

  getId() {
    return this.id;
  }

  getRevenueCenterRefForMethod(method) {
    if (!this.settings) {
      return;
    }
    return this.settings.getRevenueCenterRefForMethod(method);
  }

  getPosStandbyImageUrl() {
    if (this.posStyle && this.posStyle.secondaryDisplay && this.posStyle.secondaryDisplay.standbyImage) {
      let url = MediaLibrary.getMediaURLfromId(this.posStyle.secondaryDisplay.standbyImage);
      if (url) {
        return url;
      }
    }
    return window.DEFAULT_POS_STANDBY_SCREEN_URL;
  }

  getPosOrderingBannerUrl() {
    if (this.posStyle && this.posStyle.secondaryDisplay && this.posStyle.secondaryDisplay.orderingBanner) {
      let url = MediaLibrary.getMediaURLfromId(this.posStyle.secondaryDisplay.orderingBanner);
      if (url) {
        return url;
      }
    }
    return window.DEFAULT_POS_ORDERING_BANNER_URL;
  }

  /**
   * @return {boolean}
   */
  isPriceEncodedInUpcActive() {
    return this.barCodeSettings && this.barCodeSettings.totalLenght && this.barCodeSettings.priceLength && this.barCodeSettings.priceStartIndex
      && this.barCodeSettings.priceStartIndex > 0 && this.barCodeSettings.totalLenght > (parseInt(this.barCodeSettings.priceStartIndex) + parseInt(this.barCodeSettings.priceLength));
  }

  /**
   * @param {string} upc
   * @return {string}
   */
  getSkuFromPriceEncodedUpc(upc) {
    if (!upc) {
      return "";
    }
    return upc.slice(0, parseInt(this.barCodeSettings.priceStartIndex));
  }

  /**
   * @param {string} upc
   * @return {number | null}
   */
  getPriceFromPriceEncodedUpc(upc) {
    if (!upc) {
      let priceString = upc.slice(parseInt(this.barCodeSettings.priceStartIndex), parseInt(this.barCodeSettings.priceStartIndex) + parseInt(this.barCodeSettings.priceLength));
      if (priceString) {
        return parseInt(priceString) / 100;
      }
    }
    return null;
  }

}
