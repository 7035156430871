<template>
  <div id="posPayment">
    <PaymentInvoiceCart :table="table" @submitPayment="showReceiptModal" v-if="!isLeftHandedMode"></PaymentInvoiceCart>

    <div class="payment__left-container">
      <div class="payment__content">

        <template v-if="currentPanel === 'payment'">
          <div class="payment__methods">
            <div class="method" v-for="(method, methodIndex) of paymentMethods" :key="'payment-method-' + methodIndex"
                 @click="payWith(method)" v-wave :class="{disabled: isOffline}">
              <div class="method__plus">
                <svgicon src="icons/plus.svg" :height="25" :width="25"></svgicon>
              </div>
              <div class="method__image">
                <img class="image" :src="paymentURL(method)" :alt="method" :class="{method__svg: shouldBeSvg(method)}">
              </div>
              <div class="method__name">
                {{$t('pos_payment.' + method)}}
              </div>
            </div>
          </div>
        </template>
        <div class="content__promotions" v-if="currentPanel === 'promotions' && promotions.length > 0">
          <Promotion v-for="promotion of promotions" :key="`promotion-${promotion.id}`" shadow :promotion="promotion" @click="applyPromotion(promotion)"></Promotion>
        </div>

      </div>

      <Tabs class="payment__tabs" :active-tab-index="activeTabIndex" :tabs="tabs" :class="{'payment__tabs--left': isLeftHandedMode}"></Tabs>
    </div>

    <PaymentInvoiceCart :table="table" @submitPayment="showReceiptModal" class="left" v-if="isLeftHandedMode"></PaymentInvoiceCart>
  </div>
</template>

<script>
  import { CashDrawer } from "@/util/pos/CashDrawer";
  import EventBus from "@/lib/eventBus";
  import PaymentInvoiceCart from "@/components/pos/PaymentInvoiceCart";
  import Promotion from "@/components/promotions/Promotion.vue";
  import Tabs from "@/components/tools/Tabs.vue";
  import { Util } from "@/util/Util";
  import { mapActions } from "vuex";

  export default {
    name: "Payment",

    components: { Tabs, Promotion, PaymentInvoiceCart },

    data() {
      return {
        activeTabIndex: 0,
        currentPanel: "payment",
        tabs: [{
          name: "pos_payment.payment",
          action: index => this.setActivePanel(index, "payment")
        }, {
          name: "pos_payment.discounts",
          action: index => this.setActivePanel(index, "promotions")
        }, {
          name: "pos_payment.others",
          action: index => this.setActivePanel(index, "others"),
          disabled: true
        }]
      };
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      seats() {
        return this.table ? [this.table.getNonEmptySeats()] : []; //TODO use table.seatsByInvoice
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      terminalNumber() {
        return this.posConfiguration.getTerminalNumber();
      },
      paymentMethods() {
        if (!this.company) {
          return [];
        }
        return this.company.getPaymentMethods();
      },
      total() {
        return this.table.getTotal();
      },
      promotions() {
        return this.table.promotionVisitor.getSalePromotions();
      },
      isDarkMode() {
        return this.$store.state.posConfiguration.darkTheme;
      },
      isOffline() {
        return this.$store.state.offline;
      },
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      }
    },

    mounted() {
      if (!this.table) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
        return;
      }
      if (this.table.shouldGenerateInvoices(this.seats) && this.table.getActiveInvoiceNumbers().length > 0) {
        this.table.removePayments();
      }
    },

    methods: {
      ...mapActions(["initializeNewTable"]),

      paymentURL(method) {
        if (this.isDarkMode) {
          if (method === "cash" || method === "payfacto") {
            return "icons/Payment-" + method + "-" + this.$store.state.posConfiguration.getColorName() + ".svg";
          }
        }
        return "/img/payment-method/" + method + ".png";
      },

      shouldBeSvg(method) {
        if (this.isDarkMode) {
          if (method === "cash" || method === "payfacto") {
            return true;
          }
        }
        return false;
      },

      setActivePanel(tabIndex, panel) {
        this.activeTabIndex = tabIndex;
        this.currentPanel = panel;
      },

      getPaymentName(payment) {
        if (payment.method === "online") {
          return payment.processor;
        }
        return payment.method;
      },

      async showReceiptModal() {
        EventBus.$emit("showReceiptModal", async () => {
          this.newOrder();
          await CashDrawer.update();
          if (CashDrawer.isOverMaximum) {
            showAffirmation(this.$t("warning"), this.$t("cash_drawer.too_full"), null, "warning");
          }
        });
      },

      async newOrder() {
        if (this.table.isDispatch) {
          this.table.isDispatch = true;
          this.$user.clearOnBehalfOf(true);
        }
        this.$router.push(this.$router.generate("/pos/dashboard"));
        /* TODO workstation configuration for this
        showSpinner();
        let response = await this.initializeNewTable({ method: this.table.method });
        hideSpinner();
        if (response.success) {
          if (wasDispatch) {
            this.table.isDispatch = true;
            this.$user.clearOnBehalfOf(true);
            this.$router.push(`/${this.branch.nameCanonical}/pos/dispatch`);
          } else {
            this.$router.push(this.$router.generate("/menu"));
          }
        } else {
          this.showPaymentError(response.error);
        }*/
      },

      async payWith(paymentMethod) {
        switch (paymentMethod) {
          case "cash":
            this.payWithCash();
            break;
          case "payfacto":
            this.payWithPayfacto();
            break;
        }
      },

      async payWithCash() {
        let response = await Util.Modal.calculator({
          title: this.$t("pos_payment.enter_payment_amount"),
          isFloat: true,
          cashMode: true,
          expectedAmount: this.total.totalRemaining,
          allowNegativeValues: false
        });

        if (response.target !== "enter") {
          return;
        }
        let paymentAmount = response.value;
        if (paymentAmount === 0) {
          paymentAmount = this.total.totalRemaining;
        }
        if (paymentAmount > 0) {
          this.table.addOfflinePayment("cash", paymentAmount, []);
        }
      },

      payWithPayfacto() {
        let paymentAmount = this.total.totalRemaining;
        if (this.terminalNumber && paymentAmount > 0) {
          this.payfactoPayment = this.table.addPayfactoPaxPayment(this.terminalNumber, paymentAmount, []);
          this.showReceiptModal();
        }
      },

      applyPromotion(promotion) {
        this.table.promotionVisitor.applyPromotion(promotion.id);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posPayment {
    display: flex;
    flex-direction: row;

    .payment__left-container {
      display: flex;
      padding: 30px;
      flex-grow: 1;
    }

    .payment__invoice {
      width: 767px;
      padding: 30px;
      flex-shrink: 0;
      border-left: 1px dashed var(--border-color);
      display: flex;
      flex-direction: column;
    }

    .payment__tabs {
      padding-left: 20px;
      border-left: 1px dashed var(--border-color);
      &.payment__tabs--left {
        order: -1;
        padding-right: 20px;
        border-right: 1px dashed var(--border-color);
        border-left: none;
        padding-left: 0;
      }
    }

    .payment__content {
      flex-grow: 1;
      padding-left: 20px;
    }

    .payment__methods {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: -10px -10px;
      overflow-y: auto;
      height: calc(100% - 77px);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .method {
      position: relative;
      background-color: var(--background-color-2);
      border-radius: 20px;
      cursor: pointer;
      padding: 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
      margin: 10px;
      width: 284px;
      &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      }
    }
    .method__svg {
      transform: scale(70%)
    }
    .method__svg_large {
      transform: scale(1.5);
    }
    .method__image {
      height: 175px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background-color-6);
      border-radius: 16px;

      .image {
        max-height: 130px;
        width: auto;
      }
    }
    .method__name {
      font-weight: 900;
      color: var(--theme-color);
      font-size: 22px;
      padding-top: 15px;
      padding-left: 10px;
      line-height: 1;
      padding-bottom: 5px;
    }
    .method__plus {
      position: absolute;
      padding: 8px;
      opacity: 1;
      border-radius: 100%;
      top: 20px;
      right: 20px;
      display: flex;
      fill: white;
      background-color: var(--primary-color);
    }

    .search-bar {
      width: 100%;
      position: relative;

      .search-bar__icon {
        position: absolute;
        left: 15px;
        top: 15px;
        fill: #d9d9d9;
      }

      .search-bar__input {
        margin: 0 0 20px 0;
        width: 100%;
        padding: 15px 15px 15px 60px;
        background-color: white;
        font-size: 20px;
        font-weight: 500;
        color: #1a1a1a;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        font-family: inherit;

        &::-webkit-input-placeholder {
          color: #bfbfbf;
          font-weight: 500;
        }
      }
    }

    .invoice__scroll {
      overflow-y: auto;
      flex-grow: 1;
    }

    .invoice__info {
      .info__image {
        border-radius: 10px;
        background-color: var(--background-color-6);
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #dbdbdb;
        width: 100px;
        margin-right: 20px;
        flex-shrink: 0;

        &.promotion {
          padding: 16px;
        }

        .image {
          height: auto;
          max-height: 25px;
          width: auto;
          max-width: 65px;
        }
      }
      .info__content {
        flex-grow: 1;
        margin-right: 20px;

        .content__inner {
          display: flex;
          justify-content: space-between;
        }
        .content__type {
          color: var(--theme-color-2);
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 4px;
          display: block;
        }
        .content__name, .content__value {
          font-weight: 900;
          font-size: 26px;
          color: var(--theme-color);
        }
      }
      .info__action {
        .btn {
          height: 56px;
          width: 56px;
          padding: 10px;
        }
      }
    }

    .invoice__top, .invoice__info, .invoice__footer {
      background-color: var(--background-color-2);
      display: flex;
      border-radius: 20px;
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
      padding: 10px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .invoice__footer {
      padding: 20px;
    }

    .invoice__footer-holder {
      padding-top: 20px;
      border-top: 1px solid var(--border-color);
      margin-top: 20px;
      flex-shrink: 0;
    }

    .invoice__footer {
      flex-direction: column;
    }

    .footer__bottom {
      display: flex;
      justify-content: space-between;

      .btn--action {
        min-width: 143px;
        min-height: 52px;
        flex-grow: 1;
        flex-basis: 0;
        font-weight: 500;
        font-family: inherit;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .footer__top {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed var(--border-color-4);

      .btn {
        min-width: 200px;
        font-family: inherit;
        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        padding: 15px 30px;
        .svgicon {
          margin-right: 30px;
        }
      }

      .total {
        flex-grow: 1;
        border-radius: 10px;
        padding: 11px 15px;
        border: 1px solid var(--border-color-4);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 10px;

        .total__amount {
          font-weight: 900;
          font-size: 32px;
          display: flex;
          line-height: 1;
        }

        .total__label {
          color: var(--theme-color-2);
          font-size: 20px;
          font-weight: 500;
        }

        .amount__value {
          color: var(--theme-color);
        }

        .sign {
          color: #bfbfbf;

          &.before {
            margin-right: 5px;
          }
          &.after {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .content__promotions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(284px, 1fr));
    grid-gap: 20px;
  }
</style>