<template>
  <div class="scroll-arrows" :class="{hide: scrollAtBeginning && scrollAtEnd}">
    <div class="arrow-container arrow__top" @click="scrollUp" @contextmenu="scrollUp" :class="{disabled: scrollAtBeginning}">
      <div class="scroll-arrow" v-wave :class="{disabled: scrollAtBeginning}">
        <svgicon src="icons/triangle-arrow-top.svg" :height="20" :width="32"></svgicon>
      </div>
    </div>
    <div class="arrow-container arrow__bottom" @click="scrollDown" @contextmenu="scrollDown" :class="{disabled: scrollAtEnd}">
      <div class="scroll-arrow" v-wave :class="{disabled: scrollAtEnd}">
        <svgicon src="icons/triangle-arrow-bottom.svg" :height="20" :width="32"></svgicon>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "scrollArrows",

    props: {
      scrollContainerElementId: String,
      scrollSize: Number
    },

    data() {
      return {
        scrollOffset: this.scrollSize || 300, //px
        scrollAtBeginning: true,
        scrollAtEnd: true
      }
    },

    mounted() {
      let container = this.getScrollContainer();
      if (this.$kiosk && container) {
        container.addEventListener("scroll", this.handleScroll);
      }
      setTimeout(() => {
        this.handleScroll();
      }, 300);
      if (this.$kiosk) {
        EventBus.$on("kiosk-cart-toggle", () => {
          this.handleScroll();
        })
      }
    },

    beforeDestroy() {
      let container = this.getScrollContainer();
      if (this.$kiosk && container) {
        container.removeEventListener("scroll", this.handleScroll);
      }
      if (this.$kiosk) {
        EventBus.$off("kiosk-cart-toggle");
      }
    },

    computed: {
    },

    methods: {
      getScrollContainer() {
        if (!this.scrollContainerElementId) {
          return null;
        }
        return document.getElementById(this.scrollContainerElementId);
      },
      handleScroll() {
        let container = this.getScrollContainer();
        if (container) {
          this.scrollAtBeginning = isElementAtBeginningOfVerticalScroll(container);
          this.scrollAtEnd = isElementAtEndOfVerticalScroll(container);
        }
      },
      scrollUp() {
        let container = this.getScrollContainer();
        if (container) {
          container.scrollTop -= this.scrollOffset;
        }
      },
      scrollDown() {
        let container = this.getScrollContainer();
        if (container) {
          container.scrollTop += this.scrollOffset;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scroll-arrows {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    transition: all 300ms linear;
    opacity: 1;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  #iShopFoodApp .vertical__AIRxTOUCH.AIRxTOUCH__32 .scroll-arrows {
    position: fixed;
    top: 50%;
    right: 20px;
    bottom: unset;
    transform: translateY(50%);

    .scroll-arrow {
      height: 90px;
      width: 90px;
    }
  }

  .scroll-arrow {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: var(--primary-color);
    fill: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    z-index: 1;
    opacity: 1;
    position: relative;

    &:before {
      content: "";
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      border-radius: 100%;
      opacity: 0;
      transition: opacity 100ms linear;
    }

    &.disabled {
      pointer-events: none;
      &:before {
        opacity: 0.5;
      }
    }
  }
  $icon-offset: 6px;
  .arrow__top {
    padding-bottom: 7px;

    .svgicon {
      margin-bottom: $icon-offset;
    }
  }
  .arrow__bottom {
    padding-top: 8px;

    .svgicon {
      margin-top: $icon-offset;
    }
  }
</style>
