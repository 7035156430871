import { Money } from "@/util/Money";
import { Modal } from "@/util/Modal";

/** Class for useful functions and libs */
class Util {
    constructor() {
        this.Money = Money;
        this.Modal = Modal;
    }

    parseResponseError(e) {
        let error;
        if (e.response) {
            try {
                error = JSON.parse(e.response);
            } catch (e) {
                error = e.response;
            }
        } else {
            error = e;
        }
        return error;
    };

    removeDiacritics(string) {
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    copy(data) {
        return JSON.parse(JSON.stringify(data));
    }

    /**
     * Wait delay in MS
     * @param {number} delay
     * @returns {Promise<null>}
     */
    async wait(delay) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, delay);
        });
    }
}

let util = new Util();
export { util as Util };