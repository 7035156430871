<template>
  <div id="itemTransferModal" class="modal item-transfer-modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-header">
        <svgicon src="icons/item-transfer.svg" :height="20" :width="20"></svgicon>
        <div class="header__text">{{$t('pos_item_transfer_modal.title')}}</div>
      </div>

      <div class="item-transfer-modal__description">
        {{$t('pos_item_transfer_modal.description')}}
      </div>

      <div class="item-transfer-modal__body">
        <div class="item-transfer-modal__item-info" v-if="detail">
          <div class="section_title">{{$t('pos_seats.selected_item')}}</div>
          <PosCartItem :detail="detail" no-actions></PosCartItem>
        </div>
        <div class="item-transfer-modal__seats_table">
          <div class="table__input">
            <label class="input__label">{{$t('pos_item_transfer_modal.table_number')}}</label>
            <PosInput type="text" v-model="table"></PosInput>
          </div>
          <SeatsSelector :seatsPool="seats" :selection="localTransferSeat" class="seats" @update:selection="localTransferSeat = $event"></SeatsSelector>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="transferItem">
          <svgicon src="icons/item-transfer.svg" :height="24" :width="24"></svgicon>
          {{$t('pos_item_transfer_modal.action')}}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import SeatsSelector from "@/components/pos/SeatsSelector";
  import EventBus from "@/lib/eventBus";
  import PosCartItem from "@/components/pos/PosCartItem";
  import PosInput from "@/components/pos/PosInput";

  export default {
    name: "ItemTransferModal",

    components: { SeatsSelector, PosCartItem, PosInput },

    data() {
      return {
        show: false,
        localTransferSeat: 0,
        currentSeatBuffer: 1,
        table: null,
        detail: null
      };
    },

    mounted() {
      EventBus.$on("show-item-transfer", (currentSeat, detail) => {
        if (!currentSeat || !detail) {
          return;//TODO we should prob get the seat number from a global var or something.
          //TODO otherwise maybe it should be a prop, this popup should be in the cart and we should teleport it to the bdy. TBD
        }
        this.localTransferSeat = currentSeat;
        this.currentSeatBuffer = currentSeat;
        this.detail = detail;
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-item-transfer");
    },

    computed: {
      seats() {
        //TODO Seats: we will need to get this from somewhere probably or compute them from invoices according to context. for now they will be hardcoded
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
      }
    },

    methods: {
      transferItem() {
        //TODO
        this.show = false;
      },
      back() {
        this.show = false;
        //TODO reset values if applicable
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #itemTransferModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 1425px;

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
      }
    }

    .item-transfer-modal__body {
      display: flex;
    }

    .item-transfer-modal__description {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      font-size: 20px;
      font-weight: 700;
    }

    .section_title {
      padding-bottom: 15px;
      font-weight: 500;
      color: var(--theme-color-2);
    }

    .item-transfer-modal__item-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-right: 80px
    }

    .item-transfer-modal__seats_table {
      width: 745px;
      .table__input {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-bottom: 20px;
        .input__label {
          font-size: 18px;
          font-weight: 500;
          color: var(--theme-color-2);
          margin-bottom: 6px;
        }
        input {
          border-radius: 12px;
          border: 1px solid var(--background-color-4);
          padding: 10px 15px;
          font-size: 40px;
          line-height: 1;
          font-weight: 500;
          color: var(--theme-color);
          font-family: inherit;
        }
      }
      .seats {
        max-height: 500px;
        height: 350px;
        width: 690px;
        overflow-y: scroll;
        padding-right: 10px;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>