<template>
  <div class="breadcrumb" :class="{mobile: !isDesktop, brandingPage: inventory.isBrandingActivated() && !$order.branding, pos: $pos}">
    <template v-if="ticketMode">
      <a href="javascript:void(0)" class="root">{{$t('menu.tickets')}}</a>
    </template>
    <template v-if="!ticketMode">
      <template v-if="isDesktop">
        <template v-if="$kiosk && inventory.isBrandingActivated() && !$order.branding">
          <a href="javascript:void(0)" class="root">{{$t('branding.choose_brand')}}</a>
        </template>
        <template v-else>
          <router-link :to="{name: 'menu'}" class="root" v-if="shouldDisplayMenuLink"><svgicon src="icons/inventory.svg" :height="24" :width="24" v-if="$pos"></svgicon>{{$t('menu.title')}}</router-link>
          <router-link :to="{name: 'menuWithId', params: {id: category.id}}" class="item" v-if="shouldDisplayCategoryLink">{{$to(category.name)}}</router-link>
          <router-link :to="{name: 'item', params: {id: $route.params.id}}" class="item" v-if="item && category">{{$to(item.name)}}</router-link>
          <router-link :to="$route.path" class="item" v-if="item && category && isCrossSell">{{$t('pos.header.cross_sell')}}</router-link>
        </template>
      </template>
      <template v-if="!isDesktop">
          <router-link :to="{name: 'menu'}" v-if="!category">{{$t('menu.title')}}</router-link>
          <router-link :to="{name: 'menuWithId', params: {id: category.id}}" v-if="category && !item">{{$to(category.name)}}</router-link>
          <router-link :to="{name: 'item', params: {id: $route.params.id}}" v-if="item && category">{{$to(item.name)}}</router-link>
          <router-link :to="$route.path" v-if="item && category && isCrossSell">{{$t('pos.header.cross_sell')}}</router-link>
      </template>
    </template>
  </div>
</template>

<script>

  import { mapActions } from "vuex";

  export default {
    name: "breadcrumb",

    data() {
      return {
        category: null,
        item: null
      }
    },

    mounted() {
      this.initParams();
    },

    computed: {
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      ticketMode() {
        return this.$store.state.ticketMode;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      shouldDisplayMenuLink() {
        if (this.$kiosk || !this.isDesktop) {
          return !this.category;
        }
        return true;
      },
      shouldDisplayCategoryLink() {
        if (this.$kiosk || !this.isDesktop) {
          return this.category && !this.item;
        }
        return this.category
      },
      isCrossSell() {
        return this.$route.name === "crossSell";
      },
      itemCategoryId() {
        return this.category.id + "-" + this.item.id;
      }
    },

    watch: {
      "$route": function(to, from) {
        this.initParams();
      }
    },

    methods: {
      ...mapActions({
        getItemAction: "getItem"
      }),
      initParams() {
        this.category = null;
        this.item = null;
        if (this.$route.params.id) {
          let params = String(this.$route.params.id).split("-");
          this.loadCategory(params[0]);
          if (params[1]) {
            this.loadItem();
          }
        }
      },
      async loadCategory(id) {
        this.category = await this.getItemAction(id);
      },
      async loadItem() {
        this.item = await this.getItemAction(this.$route.params.id);
      }
    }

  }
</script>

<style lang="scss" scoped>
  .breadcrumb {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    a {
      color: white;
      font-size: inherit;
      text-decoration: none;
      cursor: pointer;
    }

    .root {
      font-weight: bold;
    }
    .item {
      margin-left: 40px;
      position: relative;

      &::before {
        content: "";
        background-color: #fc6650;
        height: 20px;
        width: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
      }
    }
    &.pos {
      a {
        color: var(--primary-color);
        background-color: var(--header-button-background-color);
        border-radius: 12px;
        height: 100%;
        padding: 14px;
        display: flex;
        align-items: center;

        .svgicon {
          margin-right: 10px;
          fill: var(--primary-color);
        }
      }
      .root {
        font-weight: normal;
      }

      .item {
        font-size: 20px;
        &.router-link-exact-active.router-link-active {
          color: #ffffff;
          background-color: var(--primary-color);
          font-weight: bold;
          max-width: 230px;
          line-height: 1.1;
        }
        &::before{
          background-color: var(--border-color-2);
          width: 1px;
        }
      }
    }
  }
  .mobile {
    justify-content: center;
    font-size: 16px;
    margin-left: 0;
    font-weight: normal;
  }
</style>
