<template>
  <div id="offlineBar" :class="{show: isOffline}">
    <svgicon src="icons/wifi-offline.svg" :width="26" :height="20"></svgicon>
    <div class="offline__text">{{$t('offline.bar')}}</div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "OfflineBar",

    data() {
      return {
        show: true
      }
    },

    computed: {
      isOffline() {
        return this.$store.state.offline;
      }
    },

    beforeDestroy() {
    }
  }
</script>

<style lang="scss" scoped>
  #offlineBar {
    background-color: #ef4244;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    fill: white;
    width: 100%;
    height: 0;
    transition: all 300ms ease-in-out;
    z-index: 10;
    overflow: hidden;
    pointer-events: none;

    &.show {
      height: 35px;
    }

    .offline__text {
      font-weight: bold;
      color: white;
      font-size: 16px;
      margin-left: 20px;
    }
  }
</style>