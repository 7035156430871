import moment from "moment-timezone";
import { Constant } from "@/util/Constant";

/**
 * @typedef BatchDefinition
 * @property {number} id
 * @property {boolean} closed
 * @property {{id: number, name: String, nameCanonical: string}} company
 * @property {{id: number, firstName: String, lastName: string, publicName: string}} employee
 * @property {{id: number, name: String}} workstation
 * @property {string} start
 * @property {number} total
 * @property {number} subTotal
 * @property {number} tipTotal
 * @property {number} serviceFees
 * @property {{count: number, currency: string, overpaidAmount: number, settledAmount: number, type: string}[]} settlementByType
 * @property {number} cashRounding
 * @property {number} surchargeFees
 * @property {Object} taxes
 * @property {???} closedAt
 * @property {???} closedBy
 * @property {Object} invoiceByService
 */
export default class BatchModel {

  /**
   * @param {BatchDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.closed = definition.closed;
    this.company = definition.company;
    this.employee = definition.employee;
    this.workstation = definition.workstation;
    this.start = definition.start;
    this.total = definition.total;
    this.subTotal = definition.subTotal;
    this.tipTotal = definition.tipTotal;
    this.serviceFees = definition.serviceFees;
    this.surchargeFees = definition.surchargeFees;
    this.cashRounding = definition.cashRounding;
    this.salesDataByPaymentMethod = definition.settlementByType || [];
    this.salesDataByService = definition.invoiceByService || [];
    this.closedBy = definition.closedBy;
    this.closedAt = definition.closedAt;
  }

  getId() {
    return this.id;
  }

  getEmployeeName() {
    return this.employee?.publicName;
  }

  getEmployeeId() {
    return this.employee?.id;
  }

  getFormattedStartDate() {
    if (this.start) {
      try {
        return moment(this.start, Constant.API_DATE_FORMAT).format(Constant.API_DATE_ONLY_FORMAT);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    return null;
  }

  getFormattedCloseDate() {
    if (this.closedAt) {
      try {
       return moment(this.closedAt, Constant.API_DATE_FORMAT).format(Constant.API_DATE_ONLY_FORMAT);
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    return null;
  }

  getClosedBy() {
    return this.closedBy;
  }

  getTipTotal() {
    return this.tipTotal;
  }

  getWorkstationNumber() {
    return this.workstation?.id;
  }

  getWorkstationName() {
    return this.workstation?.name;
  }

  getSalesDataByPaymentMethod() {
    return this.salesDataByPaymentMethod;
  }

  getTotal() {
    return this.total;
  }

  getSubTotal() {
    return this.subTotal;
  }

  getServiceFees() {
    return this.serviceFees;
  }

  getSurchargeFees() {
    return this.surchargeFees;
  }

  getCashRounding() {
    return this.cashRounding;
  }

  getSalesDataByService() {
    return this.salesDataByService;
  }
}