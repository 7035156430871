<template>
  <div class="row" v-if="company">
    <input type="text" :value="entity.date.format(momentDateFormat)" class="dummy" disabled
           v-if="entity.method != 'catering' && !company.isFutureDateEnabled(entity.method)">

    <datetime :zone="company.timezone" :value-zone="company.timezone"
              v-if="entity.method == 'catering' || company.isFutureDateEnabled(entity.method)"
              v-model="date" :format="dateFormat" :min-datetime="minDate"
              :disableDay="company.getFunctionToCheckIfDayDisabled()" :week-start="7" :input-class="['select']"></datetime>
    <div class="select-holder">
      <select v-model="entity.hour" v-if="inAdvanceEnabled && hasOpenHours">
        <option :value="null" selected hidden>{{$t('order_options.choose_hour')}}...</option>
        <option :value="hour" v-for="hour of openHours" :key="hour" :disabled="!company.isIntervalAvailable(hour)">{{ formatHour(hour) }}</option>
      </select>
    </div>

    <input type="text" :value="$t('order.delay_of') + ' ' + averageDelay + ' min.'"
           v-if="(!inAdvanceEnabled || !hasOpenHours) && averageDelay > 0" disabled>
    <input type="text" :value="$t('order.no_hour_available')"
           v-if="(!inAdvanceEnabled && averageDelay === 0) || (inAdvanceEnabled && openHours.length == 0)" disabled>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    name: "orderDateTime",

    data() {
      return {
        date: ""
      };
    },

    computed: {
      entity() {
        if (this.$pos) {
          return this.$store.state.table;
        }
        return this.$store.state.order;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      momentDateFormat() {
        if (this.$ts.locale === "en") {
          return "MMMM D, y";
        }
        return "D MMMM y";
      },
      dateFormat() {
        if (this.$ts.locale === "en") {
          return "MMMM d, y";
        }
        return "d MMMM y";
      },
      minDate() {
        if (this.company && this.company.isTodayDisabled()) {
          return moment().add({ day: 1 }).toISOString();
        }
        return moment().toISOString();
      },
      openHours() {
        if (!this.company) {
          return [];
        }
        return this.company.getAvailableOpenHours(this.entity.method);
      }
    },

    mounted() {
      this.date = (this.entity.date || moment()).toISOString();
    },

    props: ["inAdvanceEnabled", "hasOpenHours", "averageDelay"],

    watch: {
      "$order.date": function(newValue) {
        if (this.date !== newValue.toISOString()) {
          this.date = newValue.toISOString();
        }
      },
      "$store.state.table.date": function(newValue) {
        if (this.date !== newValue.toISOString()) {
          this.date = newValue.toISOString();
        }
      },
      date: function(newValue) {
        let value = moment(newValue);
        let date = moment(this.entity.date).set({
          year: value.get("year"),
          month: value.get("month"),
          date: value.get("date")
        });
        if (this.$pos) {
          this.$store.state.table.date = date;
        } else {
          this.$order.date = date;
        }
      }
    },

    methods: {
      //TODO refactor this duplicated function (5x)
      formatHour(hour) {
        if (hour === "asap") {
          return this.$t("order.asap");
        }
        if (this.company.hourFormat === "12") {
          let parsed = moment(hour, "HH:mm");
          if (!parsed.isValid()) {
            return null;
          }
          return parsed.format("hh:mm A");
        }
        return hour;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .row {
    display: flex;
    input, select, .vdatetime, .select-holder {
      height: 44px;
      flex-grow: 1;
      flex-basis: 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    button {
      margin-right: 4px;
      box-shadow: none !important;
      border: 1px solid #e6e6e6;
      background-color: transparent !important;
    }
  }

  .dummy:disabled {
    opacity: 1 !important;
  }
  #iShopFoodApp.pos {
    .select-holder {
      height: inherit;
      position: relative;
      display: flex;
      select {
        background-image: none;
      }
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: var(--primary-color);
        clip-path: polygon(11% 19%, 0 30%, 50% 82%, 100% 30%, 89% 19%, 50% 60%);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
    }
  }

</style>
