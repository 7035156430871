<template>
  <div :class="{mobile: $store.getters.isMobileOrTablet}">
    <ul class="steps" v-if="!$store.getters.isMobileOrTablet">
      <li class="step" v-if="firstStepVisible">
        <svgicon v-if="firstStepOn" class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <svgicon v-if="!firstStepOn" class="step-off" src="icons/step-off.svg" :height="32" :width="32"></svgicon>
      </li>
      <li class="step">
        <svgicon v-if="secondStepOn" class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <svgicon v-if="!secondStepOn" class="step-off" src="icons/step-off.svg" :height="32" :width="32"></svgicon>
      </li>
      <li class="step">
        <svgicon v-if="thirdStepOn" class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <svgicon v-if="!thirdStepOn" class="step-off" src="icons/step-off.svg" :height="32" :width="32"></svgicon>
      </li>
    </ul>

    <ul class="steps" v-if="$store.getters.isMobileOrTablet">
      <li class="step active" v-if="firstStepVisible">
        <svgicon class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <div class="title">{{$t('steps.options')}}</div>
      </li>
      <li class="dot" v-for="n in 3" v-if="firstStepVisible" :class="{active: firstStepOn}"></li>
      <li class="step" :class="{active: firstStepOn}">
        <svgicon v-if="firstStepOn" class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <svgicon v-if="!firstStepOn" class="step-off" src="icons/step-off.svg" :height="32" :width="32"></svgicon>
        <div class="title">{{$t('steps.contact')}}</div>
      </li>
      <li class="dot" v-for="n in 3" :class="{active: secondStepOn}"></li>
      <li class="step" :class="{active: secondStepOn}">
        <svgicon v-if="secondStepOn" class="step-on" src="icons/step-on.svg" :height="32" :width="32"></svgicon>
        <svgicon v-if="!secondStepOn" class="step-off" src="icons/step-off.svg" :height="32" :width="32"></svgicon>
        <div class="title">{{$t('steps.confirmation')}}</div>
      </li>
    </ul>
  </div>

</template>

<script>
  export default {
    name: "steps",

    computed: {
      firstStepVisible() {
        return this.$store.state.inventory && this.$store.state.inventory.hasSupplements();
      },
      firstPageActive() {
        return this.$route.name === 'paymentOptions';
      },
      secondPageActive() {
        return this.$route.name === 'paymentContact';
      },
      thirdPageActive() {
        return this.$route.name === 'paymentConfirmation';
      },
      firstStepOn() {
        return this.secondPageActive || this.thirdPageActive || this.fourthPageActive;
      },
      secondStepOn() {
        return this.thirdPageActive || this.fourthPageActive;
      },
      thirdStepOn() {
        return this.fourthPageActive;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile {
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    .steps {
      justify-content: center;
      align-items: center;
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
        min-width: 70px;

        .step-on {
          fill: #fc6650;
        }
        .step-off {
          fill: #cdcdcd;
        }

        &.active {
          .title {
            color: rgb(128,128,128);
          }
        }
      }
      .dot {
        width: 5px;
        height: 5px;
        background-color: #cdcdcd;
        border-radius: 10px;
        margin: 3px;

        &.active {
          background-color: #fc6650;
        }
      }
    }
  }
  .steps {
    display: flex;
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    padding: 15px 0;

    .step-on {
      fill: #ed2937;
    }
    .step-off {
      fill: rgb(128,128,128);
    }
    .title {
      color: #cdcdcd;
      font-size: 12px;
    }
  }
</style>