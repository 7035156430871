<template>
  <div id="item-basic" :class="{
    xs: isMobile,
    quickAdd: quickAdd,
    pos: $pos,
    interactive: interactiveMode,
    'button-mode': modifiersMode === 'button',
    addMargin: $store.state.screenSize === 'xs' && branch.style.horizontalLogoInMobileHeader && quickAdd}">

    <PageTitle class="menuHeader mobile" v-if="mobileQuickAdd" @close="$emit('close')" quickAdd>
      <svgicon class="icon" :src="icon || 'icons/menu.svg'" :height="20" :width="15"
               v-if="!$store.state.quickMode"></svgicon>
      {{ $to(item.getName()) }}
    </PageTitle>

    <div class="overflow-mobile" :class="{'enabled': isMobile, 'view-only': $store.state.displayMode}">
      <Panel class="presentationPanel" :compact="desktopOrTabletQuickAdd">
        <div class="presentation">
          <AsyncImage class="image" :fallback="$store.state.defaultItemImage" :image="image"
                      v-if="!noImage"></AsyncImage>
          <div class="right">
            <div class="top">
              <div class="nameDescriptionContainer">
                <div class="name">
                  <div class="name__left">{{ $to(item.getName()) }}</div>
                  <div class="name__right" v-if="$pos">
                    <div class="name__label" v-for="tag in item.getPublicTags()" :style="{backgroundColor: tag.color}" :key="`tag-${tag.id}`">{{ $to(tag.name) }}</div>
                  </div>
                </div>
                <label v-if="$pos">{{ $t('description') }}</label>
                <div class="description"
                     v-if="$to(item.getDescription()) || shouldDisplayResaleItemWarning || productNotInRequirement">
                  {{ $to(item.getDescription()) }}
                  <span class="resale" v-if="shouldDisplayResaleItemWarning">
                    {{ $t('item.resale_warning') }}
                  </span>
                  <span class="requirement" v-if="productNotInRequirement">
                    {{ $t('item.requirement_warning') }}
                  </span>
                </div>
                <template v-if="$pos && hasCrossSell">
                  <div class="cross-sell__link" @click="goToCrossSell">
                    <svgicon src="icons/full-arrow-right.svg" :height="16" :width="16"></svgicon>
                    {{ $t('item.go_to_cross-sell') }}
                  </div>
                </template>
              </div>
              <template v-if="shouldShowDisplayItemButton">
                <button class="btn btn-primary btn--display-item" v-if="isDisplayItemButtonVisible" @click="displayItemOnCustomerDisplay">
                  <svgicon class="display-item__icon" src="icons/eye-open.svg" :height="30" :width="30"></svgicon>{{ $t("display_item_text") }}
                </button>
                <button class="btn btn-primary  btn--display-item" v-if="!isDisplayItemButtonVisible" @click="hideItemOnCustomerDisplay">
                  <svgicon  class="display-item__icon" src="icons/eye-closed.svg" :height="30" :width="30"></svgicon>{{ $t("hide_item_text") }}
                </button>
              </template>
              <div class="priceContainer">
                <div class="price" v-if="item.variants.length == 0 || item.selectedVariant">
                  <span class="sign before"
                        v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{ $ts.getters.getSymbol }}</span>
                  <div class="integer">{{ Math.floor(item.getTotalPrice($order.method).subTotal) }}</div>
                  <div class="cents">{{
                      (item.getTotalPrice($order.method).subTotal % 1).toFixed(2).replace("0.", "")
                    }}
                  </div>
                  <span class="sign after"
                        v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{ $ts.getters.getSymbol }}</span>
                </div>
              </div>
            </div>
            <hr v-show="!$store.state.displayMode && !$kiosk">
            <div class="bottom" v-show="!$store.state.displayMode && !$kiosk">
              <div class="flex-grow" v-if="item.quantifiable && !item.appliedPromotion.promotion">
                {{ $t('item.quantity') }}
              </div>
              <QuantitySelector :large="$kiosk" :value="item.quantity" @changed="setQuantity($event)" :min="1" :max="99"
                                v-if="item.quantifiable && !item.appliedPromotion.promotion"></QuantitySelector>
              <div class="flex-grow" v-if="!item.quantifiable || item.appliedPromotion.promotion">
                {{ $t("order.only_one") }}
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <div class="hr" v-if="desktopOrTabletQuickAdd"></div>

      <div ref="panels" class="overflow-desktop" :class="{'enabled': !isMobile}">
        <!-- Variants -->
        <Panel class="panel-selectors movable variant-panel scrollable" ref="panel-variants"
               :compact="desktopOrTabletQuickAdd" :style="panelCss"
               :title="$t('item.variant') + (item.selectedVariant ? ' : ' + $to(item.selectedVariant.name) : '')"
               v-if="item.getVariants($order.method).length > 0" :selected="!!item.selectedVariant">
          <ErrorBox boxId="errorBoxVariants" type="variant" :visible="errorBoxVariantsVisible"
                    @click="errorBoxVariantsVisible = false"></ErrorBox>
          <VariantSelector :item="item" :variant="variant" v-for="variant of item.getVariants($order.method)" :key="variant.id"
                           @select="selectVariant()"></VariantSelector>
        </Panel>

        <!-- Choice groups -->
        <Panel class="panel-selectors movable scrollable" :ref="'panel-choice-group' + choiceGroup.id" v-for="choiceGroup of item.getChoiceGroups()"
               :key="'panel-choice-group-' + choiceGroup.id" :style="panelCss"
               :compact="desktopOrTabletQuickAdd" :title="$to(choiceGroup.name)">
          <template v-for="choice of choiceGroup.getChoices()">
            <div class="subtitle" :key="`choice-subtitle-${choiceGroup.id}-${choice.id}`">{{ $to(choice.name) }}</div>
            <ErrorBox :boxId="'errorBoxChoice'+choice.id" type="choice" :visible="errorBoxVisible[choice.id]"
                      @click="$emit('clearInvalidChoice')" :key="choice.id"></ErrorBox>
            <div class="scrollable-items" :key="'choice-scrollable-items-' + choice.id">
              <ChoiceSelector :choice="item" :group="choice" :key="item.id" v-for="item of choice.items"
                              @select="selectChoice($event)"></ChoiceSelector>
            </div>
          </template>
        </Panel>

        <!-- Choices -->
        <template v-if="interactiveMode" v-for="choice of item.getChoices()">
          <!-- choice items -->
          <Panel class="panel-selectors movable scrollable" :ref="'panel-choice-' + choice.id" :style="panelCss"
                 :key="'panel-choice-items-' + choice.id" :compact="desktopOrTabletQuickAdd"
                 :title="($to(choice.name) || $t('combo_choice')) + (choice.selected ? ' : ' + $to(choice.selected.name) : '')">
            <ErrorBox :boxId="'errorBoxChoice'+choice.id" type="choice" :visible="errorBoxVisible[choice.id]"
                      @click="$emit('clearInvalidChoice')"></ErrorBox>
            <div class="scrollable-items">
              <ChoiceSelector :choice="item" :group="choice" v-for="item of choice.items"
                              :key="`item-choice-${choice.id}-${item.id}`"
                              @select="selectChoice($event)"></ChoiceSelector>
            </div>
          </Panel>

          <!-- modifier groups of choice items -->
          <template v-for="item of choice.items">
            <Panel class="panel-selectors movable scrollable" :ref="'panel-choice-' + choice.id" :style="panelCss"
                   :key="'panel-choice-items-modifier-groups' + choice.id + modifierGroup.id" :compact="desktopOrTabletQuickAdd"
                   v-for="modifierGroup of item.modifierGroups" v-if="item.quantity > 0 && choice.selected == item"
                   :title="getSubmodifierGroupTitle(modifierGroup.description)">
              <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
              <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
              <div class="scrollable-items modifier-group-items">
                <template v-if="$pos">
                  <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                </template>
                <ExtraSelector :extra="modifier" v-for="modifier of modifierGroup.modifiers"
                               :key="modifierGroup.id + '-' + modifier.id" @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
                <template v-if="!$pos">
                  <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                </template>
                <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
              </div>
            </Panel>
          </template>

          <!-- choices of selected choice -->
          <template v-for="subchoice of (choice.selected || dummyChoice).getChoices()">
            <!-- subchoices -->
            <Panel class="panel-selectors movable scrollable" :style="panelCss" :key="'subchoice-' + choice.id + subchoice.id"
                   :ref="'panel-choice-' + choice.id" :title="$to(subchoice.name)">
              <div class="scrollable-items">
                <ChoiceSelector v-for="subitem of subchoice.items"
                                :key="'subitem-' + subitem.id + choice.id + subchoice.id"
                                :choice="subitem" :group="subchoice" @select="selectChoice($event)"></ChoiceSelector>
              </div>
            </Panel>

            <!-- items of subchoice -->
            <template v-for="subitem of subchoice.items">
              <Panel class="panel-selectors movable scrollable" v-for="modifierGroup of subitem.modifierGroups"
                     v-if="item.quantity > 0 && subchoice.selected == subitem" :style="panelCss" :ref="'panel-choice-' + choice.id"
                     :key="'modifier-group-sub-item-' + modifierGroup.id + subchoice.id + choice.id + subitem.id"
                     :title="getSubmodifierGroupTitle(modifierGroup.description)">
                <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
                <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
                <div class="scrollable-items  modifier-group-items">
                  <template v-if="$pos">
                    <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                  </template>
                  <ExtraSelector :extra="modifier" v-for="modifier of modifierGroup.modifiers"
                                 :key="modifierGroup.id + '-' + modifier.id" @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
                  <template v-if="!$pos">
                    <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                  </template>
                  <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
                </div>
              </Panel>
            </template>

          </template>

          <!-- choice modifier groups -->
          <Panel class="panel-selectors movable scrollable" v-for="modifierGroup of choice.modifierGroups"
                 :key="'choice-modifier-group' + modifierGroup.id + choice.id" :style="panelCss"
                 :ref="'panel-choice-' + choice.id" :title="getSubmodifierGroupTitle(modifierGroup.description)">
            <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
            <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
            <div class="scrollable-items modifier-group-items">
              <template v-if="$pos">
                <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
              </template>
              <ExtraSelector :extra="modifier" v-for="modifier of modifierGroup.modifiers"
                             :key="modifierGroup.id + '-' + modifier.id" @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
              <template v-if="!$pos">
                <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
              </template>
              <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
            </div>
          </Panel>

        </template>

        <!-- Choices -->
        <Panel v-if="!interactiveMode" class="panel-selectors movable" :ref="'panel-choice-' + choice.id"
               v-for="choice of item.getChoices()" :style="panelCss"
               :key="'panel-choice-' + choice.id" :selected="!!choice.selected" :compact="desktopOrTabletQuickAdd"
               :title="($to(choice.name) || $t('combo_choice')) + (choice.selected ? ' : ' + $to(choice.selected.name) : '')">
          <ErrorBox :boxId="'errorBoxChoice'+choice.id" type="choice" :visible="errorBoxVisible[choice.id]"
                    @click="$emit('clearInvalidChoice')"></ErrorBox>

          <ChoiceSelector :choice="item" :group="choice" :key="item.id" v-for="item of choice.items"
                          @select="selectChoice($event)"></ChoiceSelector>

          <template v-for="item of choice.items">
            <!-- Modifier groups of choice items -->
            <div class="scrollable" :style="panelCss" :key="'key-' + modifierGroup.id"
                 v-for="modifierGroup of item.modifierGroups" v-if="item.quantity > 0 && choice.selected == item">
              <div class="subchoice-title" :key="modifierGroup.id">
                {{ getSubmodifierGroupTitle(modifierGroup.description) }}
              </div>
              <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
              <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
              <div class="scrollable-items ">
                <template v-if="$pos">
                  <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                </template>
                <ExtraSelector :extra="modifier" v-for="modifier of modifierGroup.modifiers"
                               :key="modifierGroup.id + '-' + modifier.id" @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
                <template v-if="!$pos">
                  <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
                </template>
                <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
              </div>
            </div>
          </template>

          <hr v-if="choice.selected && choice.selected.getChoices().length > 0">

          <template v-for="subchoice of (choice.selected || dummyChoice).getChoices()">
            <div class="subchoice-title" :key="subchoice.id">{{ $to(subchoice.name) }}</div>
            <ChoiceSelector v-for="subitem of subchoice.items" :choice="subitem" :group="subchoice"
                            :key="subitem.id"></ChoiceSelector>
          </template>
          <template v-for="modifierGroup of choice.modifierGroups">
            <div class="subchoice-title" :key="modifierGroup.id">
              {{ getSubmodifierGroupTitle(modifierGroup.description) }}
            </div>
            <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
            <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
            <template v-if="$pos">
              <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
            </template>
            <ExtraSelector :extra="modifier" v-for="modifier of modifierGroup.modifiers"
                           :key="modifierGroup.id + '-' + modifier.id" @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
            <template v-if="!$pos">
              <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
            </template>
            <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
          </template>
        </Panel>

        <!-- Modifier groups -->
        <Panel class="panel-selectors movable scrollable panel-modifier-group" :ref="'panel-modifier-group-' + modifierGroup.id"
               v-for="modifierGroup of item.getModifierGroups()"
               :key="'panel-modifier-group-' + modifierGroup.id" :style="panelCss"
               :compact="desktopOrTabletQuickAdd" :title="getModifierGroupTitle(modifierGroup.description)">
          <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
          <ResaleItemWarning :modifiers="modifierGroup.modifiers"></ResaleItemWarning>
          <template v-if="$pos">
            <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
          </template>
          <ExtraSelector :extra="modifier" :key="modifier.id" v-for="modifier of modifierGroup.modifiers.filter(m => !m.removal)"
                         @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
          <template v-if="!$pos">
            <RefusedModifier :modifier-group="modifierGroup" @click="selectedRefusedModifier(modifierGroup)"></RefusedModifier>
          </template>
          <NextModifier :modifier-group="modifierGroup" @click="resumeInteraction"></NextModifier>
          <template v-if="modifierGroup.modifiers.filter(m => m.removal).length > 0">
            <hr>
            <ExtraSelector :extra="modifier" :key="modifier.id"
                           v-for="modifier of modifierGroup.modifiers.filter(m => m.removal)"
                           @changed="selectModifier($event, modifierGroup)"></ExtraSelector>
          </template>
        </Panel>

        <!-- Modifiers -->
        <Panel class="panel-selectors movable scrollable panel-modifier-group" :title="$t('item.extras')" icon="icons/extras.svg" :style="panelCss"
               :compact="desktopOrTabletQuickAdd" v-if="modifiers.length > 0" ref="panel-modifiers">
          <ResaleItemWarning :modifiers="modifiers"></ResaleItemWarning>
          <template v-if="$pos">
            <ExtraSelector :extra="item.getDummyModifier()" refused-modifier v-if="forceModifierInteraction"
                           :parent="item.selectedVariant ? item.selectVariant : item" @click="selectedRefusedModifier(item)"></ExtraSelector>
          </template>
          <ExtraSelector :extra="modifier" :key="'extra-' + modifier.id" v-for="modifier of modifiers" @changed="selectModifier($event)"></ExtraSelector>
          <template v-if="!$pos">
            <ExtraSelector :extra="item.getDummyModifier()" refused-modifier v-if="forceModifierInteraction"
                           :parent="item.selectedVariant ? item.selectVariant : item" @click="selectedRefusedModifier(item)"></ExtraSelector>
          </template>
          <NextModifier @click="resumeInteraction"></NextModifier>
        </Panel>

        <!-- selected modifier choices -->
        <template v-for="selectedModifier of modifiers.filter(m => m.quantity > 0)">
          <Panel class="panel-selectors movable scrollable panel-modifier-group" ref="panel-modifiers"
                 v-for="(choice, choiceIndex) of selectedModifier.getChoices()"
                 :key="'modifier-choice-' + choice.id + selectedModifier.id + choiceIndex" :title="$to(choice.name)">
            <div class="scrollable-items">
              <ChoiceSelector v-for="(item, itemIndex) of choice.items"
                              :key="'modifier-choice-item-' + item.id + choice.id + selectedModifier.id + itemIndex"
                              :choice="item" :group="choice" @select="selectChoice($event)"></ChoiceSelector>
            </div>
          </Panel>
        </template>

        <!-- Removal modifiers -->
        <Panel class="panel-selectors scrollable removals" :title="$t('item.personalize')" icon="icons/ingredients.svg"
               :style="panelCss"
               :compact="desktopOrTabletQuickAdd" v-if="removals.length > 0" ref="panel-removals">
          <ResaleItemWarning :modifiers="removals"></ResaleItemWarning>
          <template v-if="$pos">
            <ExtraSelector :extra="item.getDummyModifier()" refused-modifier v-if="forceModifierInteraction"
                           :parent="item.selectedVariant ? item.selectVariant : item" @click="selectRefusedRemoval()"></ExtraSelector>
          </template>
          <RemovalSelector :removal="removal" v-for="removal of removals" :key="'removal-' + removal.id" @changed="selectModifier($event)"></RemovalSelector>
          <template v-if="!$pos">
            <ExtraSelector :extra="item.getDummyModifier()" refused-modifier v-if="forceModifierInteraction"
                           :parent="item.selectedVariant ? item.selectVariant : item" @click="selectRefusedRemoval()"></ExtraSelector>
          </template>
        </Panel>
      </div>
    </div>

    <div class="filler">
      <div class="item__not-custom" v-if="!item.isCustomizable() && $pos">
        <Illustration large src="icons/extra.svg"></Illustration>
        <div class="not-custom__text">
          {{ $t('item.no_custom_required') }}
        </div>
      </div>
    </div>

    <!-- Item Footer -->

    <div class="pos__item-footer" v-if="$pos">
      <div class="custom-quantity">
        <label>{{ $t('quantity') }}</label>
        <div class="custom">
          <div class="fake-input">{{ item.quantity }}</div>
        </div>
      </div>
      <div class="pos__price" v-if="item.variants.length == 0 || item.selectedVariant">
        <label>{{ $t('item.total_price') }}</label>
        <div class="fake-input input__price">
          <span class="sign before"
                v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{ $ts.getters.getSymbol }}</span>
          <div class="amount">{{ item.getTotalPrice($order.method).subTotal.toFixed(2) }}</div>
          <span class="sign after"
                v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{ $ts.getters.getSymbol }}</span>
        </div>
      </div>
      <div class="filler"></div>
      <div class="pos__actions" v-show="shouldDisplayAddToCartButton" :class="{hidden: $pos && !edit && posConfiguration.quickAdd}">
        <button class="btn btn-primary" @click="addToTable"
                :class="{disabled: (!interactionCompleted) || isBrandingUnavailable}">
          {{$t(edit ? 'item.edit' : 'item.add_to_order')}}
        </button>
        <button class="btn btn-primary" v-if="hasCrossSell && !edit" @click="addToTableAndCrossSell"
                :class="{disabled: (!interactionCompleted) || isBrandingUnavailable}">
          {{ $t('item.add_to_order_cross-sell') }}
        </button>
      </div>
    </div>

    <div class="buttonContainer" v-if="!$pos"
         :class="{withQuantity: kioskAddtoCartWithQuantity, horizontal: $kiosk && kioskConfiguration && kioskConfiguration.isHorizontal()}">

      <template v-if="kioskAddtoCartWithQuantity">
        <div class="quantity-holder">
          <div class="quantity-holder--left">
            <div class="quantity-holder--title">{{ $t('item.quantity') }}</div>
            <div class="quantity-holder--description">{{ $t('item.quantity_description') }}</div>
          </div>
          <QuantitySelector large :value="item.quantity" @changed="setQuantity($event)" :min="1"
                            :max="99"></QuantitySelector>
        </div>
      </template>

      <button type="button" class="btn btn-secondary" v-if="desktopOrTabletQuickAdd" @click="$emit('close')">{{$t("back")}}</button>
      <button type="button" class="btnAddOrderItem back btn btn-primary"
              v-if="(!isMobileOrTablet || !showMobileFooter) && $store.state.displayMode" @click="back">
        {{ $t("back") }}
      </button>
      <template v-if="desktopOrTabletQuickAdd">
        <div class="footer-price-container">
          <div class="price" v-if="item.variants.length == 0 || item.selectedVariant">
            {{ $tc(item.getTotalPrice($order.method).subTotal.toFixed(2)) }}
          </div>
        </div>
      </template>
      <button type="button" class="btnAddOrderItem btn btn-primary" v-if="!$store.state.displayMode"
              v-show="shouldDisplayAddToCartButton" @contextmenu="contextMenuAdd"
              :class="{disabled: (company && company.isCurrentMethodClosed() && interactionCompleted) || isBrandingUnavailable}"
              @click="add">

        <template v-if="mobileQuickAdd && (item.variants.length == 0 || item.selectedVariant)">
          <div class="priceContainer">
            <div class="title">{{ $t('item.your_price') }}</div>
            <div class="price">
              <span class="sign before"
                    v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{ $ts.getters.getSymbol }}</span>
              <div class="integer">{{ Math.floor(item.getTotalPrice($order.method).subTotal) }}</div>
              <div class="cents">{{ (item.getTotalPrice($order.method).subTotal % 1).toFixed(2).replace("0.", "") }}</div>
              <span class="sign after"
                    v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{ $ts.getters.getSymbol }}</span>
            </div>
          </div>
          <div class="pipe"></div>
        </template>

        <template v-if="!interactionCompleted">
          {{ $t('next') }}
        </template>
        <template v-if="interactionCompleted">
          <template v-if="company && company.isCurrentMethodClosed() && company.getClosedMessage()">
            {{ company.getClosedMessage() }}
          </template>
          <template v-if="company && company.isCurrentMethodClosed() && !company.getClosedMessage()">
            {{ $t('emergency_close_title') }}
          </template>
          <template v-if="company && !company.isCurrentMethodClosed() && !edit">{{ $t('item.add') }}</template>
          <template v-if="company && !company.isCurrentMethodClosed() && edit">{{ $t('item.edit') }}</template>
        </template>

      </button>
    </div>

    <ScrollArrows ref="scrollArrows" v-if="$kiosk" scrollContainerElementId="item-basic"
                  :scrollSize="500"></ScrollArrows>

  </div>
</template>

<script>
  import Illustration from "@/components/modal/Illustration";
  import ExtraSelector from "@/components/tools/ItemExtraSelector.vue";
  import ChoiceSelector from "@/components/tools/ItemChoiceSelector.vue";
  import QuantitySelector from "@/components/tools/QuantitySelector.vue";
  import RemovalSelector from "@/components/tools/ItemRemovalSelector.vue";
  import VariantSelector from "@/components/tools/ItemVariantSelector.vue";
  import ModifierGroupRequirements from "@/components/menu/ModifierGroupRequirements.vue";
  import ResaleItemWarning from "@/components/menu/ResaleItemWarning";
  import ScrollArrows from "@/components/tools/ScrollArrows";
  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import AsyncImage from "@/components/tools/AsyncImage.vue";
  import EventBus from "@/lib/eventBus";
  import { Util } from "@/util/Util";
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";
  import { PromotionManager } from "../../PromotionManager";
  import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
  import RefusedModifier from "@/components/menu/RefusedModifier";
  import NextModifier from "@/components/menu/NextModifier";

  export default {

    name: "item-basic",

    components: {
      Illustration,
      ExtraSelector,
      ChoiceSelector,
      QuantitySelector,
      RefusedModifier,
      RemovalSelector,
      NextModifier,
      VariantSelector,
      ErrorBox,
      AsyncImage,
      ModifierGroupRequirements,
      ScrollArrows,
      ResaleItemWarning
    },

    props: {
      item: Object,
      realItem: Object,
      edit: Boolean,
      invalidChoiceId: Number,
      quickAdd: Boolean
    },

    data() {
      return {
        errorBoxVisible: {},
        errorBoxVariantsVisible: false,
        interactionCompleted: false,
        scrollAtEnd: false,
        lastTarget: null,
        dummyChoice: {
          getChoices: function () {
            return [];
          }
        },
        isDisplayItemButtonVisible: true,
        window
      };
    },

    mounted() {
      this.setQuantity(this.item.quantity);
      this.$el.addEventListener("click", (e) => {
        this.lastTarget = e.target;
      });
      if (this.$kiosk) {
        this.$el.addEventListener("contextmenu", (e) => {
          this.lastTarget = e.target;
        });
      }
      this.applyFlow();
      if (!this.edit) {
        this.initAirxTouchDisplayLayout();
      } else {
        setTimeout(() => {
          this.initAirxTouchDisplayLayout();
        }, 800);
      }
      this.$nextTick(() => {
        this.displayNextPanel(true);
      });
      if (this.$kiosk) {
        EventBus.$on("kiosk-cart-toggle", () => {
          this.initAirxTouchDisplayLayout();
        });
      }
      if (this.table?.promotionVisitor) {
        this.table.promotionVisitor.applyAllPromotionsOnItem(this.item);
      }
    },

    beforeDestroy() {
      this.$el.removeEventListener("click", (e) => {
        this.lastTarget = e.target;
      });
      if (this.$kiosk) {
        EventBus.$off("kiosk-cart-toggle");
      }
    },

    created() {
      this.showInvalidChoice();
    },

    watch: {
      invalidChoiceId() {
        this.showInvalidChoice();
      },
      "$order.method"() {
        this.item.reset(this.interactiveMode);
        this.$nextTick(() => {
          this.displayNextPanel(true);
        });
      },
      item: {
        handler() {
          if (this.table?.promotionVisitor) {
            this.table.promotionVisitor.applyAllPromotionsOnItem(this.item);
          }
        },
        deep: true
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      details() {
        return this.$store.state.table.getGroupedDetails();
      },
      company() {
        return this.$store.state.currentCompany;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      kioskAddtoCartWithQuantity() {
        return this.$kiosk && this.item.quantifiable && !this.item.appliedPromotion.promotion && this.shouldDisplayAddToCartButton;
      },
      productNotInRequirement() {
        if (!this.company.orderRequirementsActivated || this.company.orderRequirements.length === 0) {
          return false;
        }
        return this.company.orderRequirements.indexOf(this.item.id) === -1;
      },
      isBrandingUnavailable() {
        return !!(this.$order.branding && !this.$order.branding.isAvailable(this.$order.date, this.$order.hour, this.$order.method));
      },
      modifiersMode() {
        if (this.$kiosk) {
          return "button";
        }
        if (this.$pos) {
          return "button"; //TODO use config
        }
        if (!this.branch || !this.branch.style) {
          return "list";
        }
        return this.branch.style.modifiersMode || "list";
      },
      showMobileFooter() {
        if (!this.branch || !this.branch.style) {
          return false;
        }
        return this.branch.style.showMobileFooter;
      },
      shouldDisplayResaleItemWarning() {
        return (this.branch.isiShopFoodGiftCardEnabledForCurrentCompany() || this.branch.isiShopFoodLoyaltyProgram()) && this.item.isResaleItem();
      },
      interactiveMode() { //This duplicate is bothering me
        if (this.$pos && this.edit) {
          return false;
        }
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.interactiveMode;
      },
      forceModifierInteraction() { //This duplicate is bothering me
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.interactiveMode) {
          return false;
        }
        return this.$store.state.currentBranch.style.forceModifierInteraction;
      },
      shouldDisplayAddToCartButton() {
        if (!this.interactiveMode || !this.forceModifierInteraction) {
          return true; // not interactive or no forced interaction
        }
        if (!this.interactionCompleted || (this.item.variants.length > 0 && !this.item.selectedVariant)) {
          return false; // variant isnt selected yet
        }
        if (this.item.selectedVariant && this.item.selectedVariant.getAllChoices().length > 0 && this.item.selectedVariant.getSelectedChoices().length == 0) {
          return false; // select variant has choices and nothing is selected yet
        }
        return this.interactionCompleted;
      },
      isMobile() {
        return this.$store.state.screenSize === "xs";
      },
      isMobileOrTablet() {
        return this.$store.state.screenSize === "xs" || this.$store.state.screenSize === "md";
      },
      mobileQuickAdd() {
        return this.quickAdd && this.isMobile;
      },
      desktopOrTabletQuickAdd() {
        return this.quickAdd && !this.isMobile;
      },
      hasChoicesOrVariants() {
        return this.item.getChoices().length > 0 || this.item.getChoiceGroups().length > 0 || this.item.variants.length > 0 || this.modifiers.length > 0;
      },
      modifiers() {
        return this.item.getModifiers().filter(m => !m.removal);
      },
      removals() {
        return this.item.getModifiers().filter(m => m.removal);
      },
      image() {
        if (this.item.image) {
          return this.$store.state.urlFileServer + this.item.image;
        }
        return this.$store.state.defaultItemImage;
      },
      panelCss() {
        return "display:none;";
      },
      hasCrossSell() {
        return this.item.hasCrossSell();
      },
      table() {
        return this.$store.state.table;
      },
      shouldShowDisplayItemButton() {
        return this.$pos && this.branch && this.branch.isCordovaDefined()
      }
    },

    methods: {
      selectedRefusedModifier(modifierParent) {
        if (modifierParent.getModifiers) {
          for (let modifier of modifierParent.getModifiers()) {
            modifier.setQuantity(0);
          }
        } else {
          for (let modifier of modifierParent.modifiers) {
            modifier.setQuantity(0);
          }
        }
        this.$nextTick(() => {
          this.displayNextPanel();
        });
      },
      selectRefusedRemoval() {
        for (let removal of this.removals) {
          removal.setQuantity(0);
        }
        this.$nextTick(() => {
          this.displayNextPanel();
        });
      },

      contextMenuAdd() {
        if (this.$kiosk) {
          this.add();
        }
      },
      goToCrossSell() {
        this.$emit("goToCrossSell");
      },
      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            window.airxtouch__submitLayout([
              GetElementBounds("item-basic", 0, AIRxTouchDefaultLayoutSetting.DragDrop) // Scroll area : Whole page
            ]);
          });
        }
      },
      displayItemOnCustomerDisplay() {
        CustomerScreenLoader.switchToMenuItem(this.item);
        this.isDisplayItemButtonVisible = false;
      },
      showStandbyOrMenuComplete() {
        if (!this.$store.state.table || !this.$store.state.table.hasItems()) {
          CustomerScreenLoader.switchToStandby(this.company.getPosStandbyImageUrl());
        } else {
          CustomerScreenLoader.switchToMenuComplete(this.$store.state.table, this.company.getPosOrderingBannerUrl());
        }
      },
      hideItemOnCustomerDisplay() {
        this.showStandbyOrMenuComplete();
        this.isDisplayItemButtonVisible = true;
      },
      getInteractionCompleted() {
        if (!this.interactiveMode) {
          return true;
        }

        let panels = Array.from(document.getElementsByClassName("scrollable")).filter(p => p.style.display === "none");
        let allPanelsDisplayed = panels.length === 0;
        let noInvalidModifierGroups = document.querySelectorAll(".invalid.modifier-group-requirements").length === 0;
        let allChoicesValid = this.item.areAllChoicesValid();
        let allModifierGroupsValid = true;
        let noNextButtonDisplayed = true;

        if (this.$pos) { //On POS, there should be always at least one modifier selected in a modifier group
          // check all 'modifier-group-items' + 'panel-modifier-group'
          let modifierGroups = [
            ...document.getElementsByClassName("modifier-group-items"),
            ...document.getElementsByClassName("panel-modifier-group")];
          for (let i = 0; i < modifierGroups.length; i++) {
            let hasOneModifierSelected = modifierGroups[i].querySelector(".item-extra-selector.selected");
            if (!hasOneModifierSelected) {
              allModifierGroupsValid = false;
              break;
            }
          }
        }

        //Make sure that no continue button is displayed
        if (this.forceModifierInteraction) {
          let nextButtons = Array.from(document.getElementsByClassName("nextModifier"));
          noNextButtonDisplayed = nextButtons.filter(b => !!b.offsetParent && !b.classList.contains("fadeOut")).length === 0;
        }

        return allPanelsDisplayed && noInvalidModifierGroups && allChoicesValid && allModifierGroupsValid && noNextButtonDisplayed;
      },
      applyFlow() {
        if (!this.item.hasFlowItemsConfigured()) {
          return;
        }
        if (this.item.getFlowItems().length > 0) {
          let index = 0;
          for (let flowItem of this.item.getFlowItems()) {
            let panelName = flowItem.type == "modifiers" ? "panel-" + flowItem.type : "panel-" + flowItem.type + "-" + flowItem.id;
            let panels = this.$refs[panelName];
            if (!panels) {
              continue;
            }
            if (!Array.isArray(panels)) {
              panels = [panels];
            }
            for (let panel of panels) {
              if (index > 0 || this.item.variants.length > 0) {
                let previousPanel = document.getElementsByClassName("movable")[index];
                previousPanel.after(panel.$el);
              } else if (index === 0 && this.item.variants.length === 0) {
                let parent = document.getElementsByClassName("overflow-desktop")[0];
                parent.prepend(panel.$el);
              }
              index++;
            }
          }
        } else {
          for (let panelName in this.$refs) {
            let panelNames = ["choice", "modifier"];
            let match = panelNames.find(name => panelName.indexOf(name) > -1);
            let panel = this.$refs[panelName];
            if (panel && panel.length > 0) {
              panel = panel[0];
            }
            if (match && panel && panel.$el) {
              let parent = document.getElementsByClassName("overflow-desktop")[0];
              parent.append(panel.$el);
            }
          }
        }
      },
      add(crossSell = false) {
        if (!this.interactionCompleted) {
          this.displayNextPanel();
          return;
        }
        if (this.item.variants.length > 0 && !this.item.selectedVariant) {
          this.errorBoxVariantsVisible = true;
          return;
        }
        this.$emit("add", crossSell);
      },
      addToTable() {
        this.add();
      },
      addToTableAndCrossSell() {
        this.add(true);
      },
      back() {
        window.history.back();
      },
      isOdd(list) {
        return list.length % 2 !== 0;
      },
      async changeItemQuantity() {
        let response = await Util.Modal.calculator({
          title: this.$t("item.choose_quantity"),
          maxValue: 99,
          allowNegativeValues: false
        });
        if (response.target !== "enter") {
          return;
        }
        let quantity = response.value;
        if (quantity === 0) {
          quantity = 1;
        }
        this.setQuantity(quantity);
      },
      showInvalidChoice() {
        for (let choice of this.item.getChoices()) {
          this.errorBoxVisible[choice.id] = this.invalidChoiceId === choice.id;
          this.$forceUpdate();
        }
      },
      getModifierGroupTitle(description) {
        if (description && description[this.$ts.state.locale]) {
          return this.$to(description);
        } else {
          return this.$t("item.personalize");
        }
      },
      getSubmodifierGroupTitle(description) {
        if (description && description[this.$ts.state.locale]) {
          return this.$to(description);
        } else {
          return this.$t("item.modifiers_extras");
        }
      },
      getClosestPanelFromLastTarget() {
        if (this.lastTarget) {
          return this.lastTarget.closest(".scrollable");
        }
      },
      resumeInteraction() {
        this.displayNextPanel(null, null, true);
      },
      hideFuturePanels(panels) {
        let referenceIndex = -1;
        for (let i = 0; i < panels.length; i++) {
          if (panels[i].style.display !== "block") {
            referenceIndex = i;
          } else if (referenceIndex > -1) {
            // Hide all future panels
            panels[i].style.display = "none";
            panels[i].style.opacity = "0";
            // Revert back 'continue' buttons
            let nextButtons = panels[i].getElementsByClassName("nextModifier");
            if (nextButtons && nextButtons.length > 0) {
              for (let button of nextButtons) {
                button.classList.remove("fadeOut");
              }
            }
          }
        }
      },
      displayNextPanel(first, skipAnimation, resumeInteraction) {
        if (this.interactiveMode && (this.hasChoicesOrVariants || this.forceModifierInteraction) && !this.edit) {
          let panels = document.getElementsByClassName("scrollable");
          this.hideFuturePanels(panels);
          setTimeout(() => {
            let next = null;
            let lastClickedPanel = this.getClosestPanelFromLastTarget();
            if (lastClickedPanel) {
              let pauseInteractionModifier;
              let scrollable = lastClickedPanel.getElementsByClassName("scrollable-items");
              if (scrollable && scrollable.length > 0) {
                pauseInteractionModifier = scrollable[0].getElementsByClassName("nextModifier");
              } else {
                pauseInteractionModifier = lastClickedPanel.getElementsByClassName("nextModifier");
              }
              if (pauseInteractionModifier.length > 0) {
                if (!resumeInteraction) {
                  return;
                }
              }
              let childPanels = Array.from(lastClickedPanel.getElementsByClassName("scrollable"));
              let hiddenChildPanels = childPanels.filter(p => p.style && p.style.display === "none");
              if (hiddenChildPanels.length > 0) {
                next = hiddenChildPanels[0];
              } else {
                if (lastClickedPanel.nextElementSibling &&
                  lastClickedPanel.nextElementSibling.style && lastClickedPanel.nextElementSibling.style.display !== "none") {
                  return;
                }
              }
            }

            if (!next) {
              for (let panel of panels) {
                if (panel.style.display === "none") {
                  next = panel;
                  break;
                }
              }
            }

            if (next) {
              next.style.display = "block";
              if (!first && !skipAnimation) {
                next.scrollIntoView({ behavior: "smooth" });
              }
              setTimeout(() => {
                next.style.opacity = "1";
              }, 200);
            } else {
              let button = document.getElementsByClassName("btnAddOrderItem")[0];
              if (button) {
                button.scrollIntoView({ behavior: "smooth" });
              }
            }
          }, 0);
        } else {
          let panels = document.querySelectorAll(".movable, .scrollable");
          for (let panel of panels) {
            panel.style.display = "block";
            setTimeout(() => {
              panel.style.opacity = "1";
            }, 200);
          }
        }
        // After display panel actions
        this.$nextTick(() => {
          this.interactionCompleted = this.getInteractionCompleted();

          if (this.$pos && this.shouldDisplayAddToCartButton && this.posConfiguration.quickAdd && !this.edit) {
            if (this.hasCrossSell) {
              this.addToTableAndCrossSell();
            } else {
              this.addToTable();
            }
          }
        });
      },
      selectVariant() {
        this.$forceUpdate();
        setTimeout(() => {
          this.applyFlow();
          this.$nextTick(() => {
            this.displayNextPanel();
          });
        }, 0);
      },
      selectModifier(component, modifierGroup) {
        this.lastTarget = component.$el;
        setTimeout(() => {
          if (modifierGroup && !modifierGroup.validateQuantitySelected().valid) {
            return;
          }
          this.applyFlow();
          this.$nextTick(() => {
            this.displayNextPanel();
          });
        }, 0);
      },
      selectChoice({ choice, skipAnimation }) {
        setTimeout(() => {
          this.applyFlow();
          this.$nextTick(() => {
            this.displayNextPanel(false, skipAnimation);
          });
        }, 0);
      },
      setQuantity(quantity) {
        this.item.quantity = quantity;
        PromotionManager.calculate();
      }
    }
  };

</script>

<style lang="scss">

#iShopFoodApp.kiosk #item-basic {
  overflow-y: auto;
  position: static;
  scroll-behavior: smooth;
}

#iShopFoodApp.pos {
  .custom-quantity {
    border-right: 1px solid var(--border-color-4);

    .fake-input {
      color: var(--theme-color);
      border: 1px solid var(--border-color-4);
    }
  }
}

#iShopFoodApp.pos #item-basic {
  overflow-y: hidden;
  overflow-x: hidden;

  .presentation .right .top .nameDescriptionContainer .name {
    color: var(--theme-color);
  }

  .item__not-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .illustration {
      fill: #e6e6e6;
      &.large .circle, &.large .dash {
        background-color: var(--illustration-background-color-2);
        .svgicon {
          fill: var(--illustration-front-color-2);
        }
      }
    }

    .not-custom__text {
      color: #d9d9d9;
      font-weight: bold;
      font-size: 24px;
      margin-top: 20px;
      text-align: center;
      max-width: 290px;
    }
  }

  .pos__item-footer {
    display: flex;
    width: 100%;
    padding-top: 20px;
    border-top: 1px dashed var(--border-color-4);
    margin-top: 20px;

    .pos__actions {
      display: flex;
      align-items: flex-end;

      &.hidden {
          pointer-events: none;
          opacity: 0;
          display: none;
        }
      .btn {
        height: 56px;
        font-size: 20px;
        font-weight: bold;
        padding: 15px 20px;
      }

      .icon {
        margin-right: 10px;
      }

      .btn:not(:last-child) {
        margin-right: 10px;
      }
    }

    .pos__quantity, .pos__price, .custom-quantity {
      label {
        font-size: 18px;
        color: var(--theme-color-2);
        margin-bottom: 7px;
        display: block;
      }
    }

    .pos__quantity {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #e6e6e6;
    }

    .pos__price {
      .fake-input {
        display: flex;
        font-weight: bold;
        align-items: center;
        padding: 10px;
        border: 1px solid var(--border-color-4);
        border-radius: 10px;
        font-size: 32px;
        line-height: 1;
        height: 56px;

        .sign {
          color: var(--primary-color);

          &.before {
            margin-right: 20px;
          }

          &.after {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .overflow-mobile {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .presentationPanel {
      box-shadow: none;
      border-radius: 0;

      .content > .container {
        padding: 0;
      }
    }

    .panel.panel-selectors {
      box-shadow: none;

      &:not(:first-child) {
        & > .title {
          padding-top: 0;
        }
      }

      & > .title {
        padding: 15px 0;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        color: var(--theme-color-2);
        position: relative;
        pointer-events: none;

        span {
          z-index: 1;
          background-color: var(--background-color-2);
          padding: 0 10px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background-color: var(--border-color-4);
        }

        .titleIcon {
          display: none;
        }
      }

        .selector.button {
          width: 155px;
          padding: 10px;
          margin: 6px;
          border: none;
          position: relative;
          border-radius: 16px;

          &.selected .check {
            height: 30px;
            width: 30px;
            top: 10px;
            right: 10px;

            .quantity {
              font-size: 20px;
              font-weight: 900;
            }
          }

        &.selected:after {
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          border: 5px solid var(--primary-color);
          position: absolute;
          border-radius: 16px;
        }

        .name {
          font-size: 20px;
          line-height: 1.1;
          font-weight: 900;
        }

        .price span {
          font-size: 18px;
          font-weight: 500;
        }

        .info {
          padding: 10px 5px 20px 5px;
        }

        .image {
          border-radius: 10px;
          margin: 0;
          height: 100px;
          width: 100%;
        }
      }

        & > .content > .container {
          padding: 15px 0 10px 0;
        }

      & > .content:before {
        display: none;
      }

      .panel-arrow-icon, .flex-grow {
        display: none;
      }
    }

    .presentation {
      .image {
        border-radius: 16px;
        margin: 0;
        width: 180px;
        height: 118px;
        min-height: auto;
        flex-grow: 0;
        flex-basis: initial;
        flex-shrink: 0;
      }

      .right .top .nameDescriptionContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          font-size: 26px;
          font-weight: 900;
          margin-bottom: 15px;
          margin-top: 0;
          display: flex;
          align-items: center;
          width: 100%;
          .name__left {
            flex-grow: 1;
          }
          .name__right {
            display: flex;
            .name__label {
              height: 40px;
              background-color: var(--primary-color);
              display: flex;
              align-items: center;
              font-size: 16px;
              color: white;
              padding: 0 20px;
              margin-left: 10px;
              border-radius: 20px;
            }
          }
        }

        label {
          color: var(--theme-color-2);
          font-weight: 500;
          font-size: 18px;
        }

        .description {
          font-size: 20px;
          font-weight: 500;
          color: var(--theme-color-3);
        }
      }

      .cross-sell__link {
        display: flex;
        align-items: center;
        line-height: 1;
        color: var(--primary-color) !important;
        fill: var(--primary-color) !important;
        font-weight: bold;
        padding-top: 20px;
        padding-bottom: 10px;

        .svgicon {
          margin-right: 10px;
        }
      }

      .priceContainer, hr, .bottom {
        display: none !important;
      }
    }
  }

  .overflow-desktop {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-right: 20px;
  }
}

#iShopFoodApp #item-basic {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;

  &.interactive.quickAdd {
    .panel-selectors {
      opacity: 0;
      transition: opacity 500ms ease;
    }
  }

  &.button-mode {
    .panel-selectors .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 20px;
    }

    .scrollable {
      width: 100%;
    }

    .scrollable-items {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &.quickAdd {

    &.xs {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .presentationPanel {
        border-bottom: none;
      }

      .presentation {
        .image {
          width: 100%;
          max-width: 100% !important;
          border-radius: 0 !important;
          height: 200px !important;
        }

        .right {
          padding-bottom: 0;

          .bottom {
            margin-top: 10px;
            font-size: 14px;
          }
        }
      }

      .buttonContainer {
        .btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .pipe {
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          width: 1px;
          height: 50px;
          margin: 0 20px;
        }

        .priceContainer {
          line-height: 1;

          .title {
            text-transform: uppercase;
            font-size: 10px;
            white-space: nowrap;
            font-weight: 300;
          }

          .price {
            display: flex;
            align-items: flex-start;

            .integer, .cents {
              padding-right: 2px;
              font-weight: 900;
            }

            .integer {
              font-size: 30px;
            }

            .cents, .sign {
              font-size: 16px;
            }

            .sign {
              font-weight: normal;
            }
          }
        }
      }
    }

    &:not(.xs) {

      &.button-mode {
        .panel-selectors .title {
          padding-top: 0;
        }
      }

      .presentationPanel {
        border-bottom: 0;
        margin-bottom: 0;
      }

      .name {
        margin-top: 0 !important;
      }
    }

    .overflow-mobile {
      &:not(.enabled) {
        max-height: 750px;
        display: flex;
        flex-direction: column;
      }

      &.enabled {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .overflow-desktop.enabled {
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 20px;
      padding: 20px 20px;
    }

    .hr {
      width: calc(100% - 40px);
      border-bottom: 2px dashed #e5e5e5;
      margin: 20px 0 20px 20px;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;

      .btn {
        width: auto;
        font-size: 16px;
        padding: 10px 20px;
      }
    }
  }

  &:not(.quickAdd):not(.pos):after {
    content: "";
    display: block;
    height: 40px;
    flex-shrink: 0;
  }

  &.xs {
    padding: 0;

    .container {
      padding: 0;
    }

    .presentation {
      flex-direction: column;

      .image, .right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        margin: 0;
      }

      .image {
        height: 250px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .right {
        padding-bottom: 20px;

        hr {
          margin: 20px;
        }

        .top {
          padding: 20px 20px 0 20px;

          .nameDescriptionContainer {
            .name {
              margin-top: 0;
              font-size: 18px;
            }

            .basePrice {
              display: block;
              font-size: 12px;
              line-height: 1;
              margin-bottom: 4px;
            }

            .description {
              font-size: 12px;
              color: rgb(128, 128, 128);
            }
          }

          .priceContainer {
            min-width: 110px;

            .price {
              .integer {
                font-size: 40px;
              }

              .cents, .sign {
                padding-top: 4px;
                font-size: 24px;
              }

              .sign {
                &.before {
                  padding-top: 0;
                  font-size: 40px;
                }

                &.after {
                  padding-top: 4px;
                  font-size: 24px;
                }
              }
            }

            .basePrice {
              display: none;
            }
          }
        }

        .bottom {
          padding: 0 20px 0 20px;
        }
      }
    }

    &.button-mode {
      .item-selector {
        width: auto;
      }

      .item-selector {
        width: calc(50% - 10px);
        margin: 10px auto;

        &:nth-last-of-type(2), &:nth-last-of-type(3) {
          margin-bottom: 20px;
        }

        &:nth-of-type(2), &:nth-of-type(3) {
          margin-top: 20px;
        }

        .selector {
          margin-bottom: 0;
          margin-top: 0;
          margin-left: auto;
          margin-right: auto;
          height: 100%;
        }
      }
    }

    .item-selector {
      width: 100%;

      &:last-of-type {
        margin-bottom: 20px;
      }

      &:first-of-type {
        margin-top: 20px;
      }

      .selector {
        margin-bottom: 0;
      }
    }
  }

  .error-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .filler {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .basePrice {
    font-size: 11px;
    color: #bfbfbf;
    text-transform: uppercase;
    margin-top: 5px;

    .amount {
      font-weight: bold;
    }
  }

  .overflow-mobile.view-only, .btnAddOrderItem.back {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .presentation {
    display: flex;

    .image, .right {
      flex-grow: 1;
      flex-basis: 0;
    }

    .image {
      margin: -20px 0 -20px -20px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-size: cover;
      background-position: center;
      min-height: 300px;
    }

    .right {
      &:not(:first-child) {
        margin-left: 20px;
      }

      .top {
        display: flex;

        .nameDescriptionContainer {
          flex-grow: 1;

          .name {
            font-weight: bold;
            font-size: 24px;
            color: #1a1a1a;
            margin-top: 30px;
          }

          .basePrice {
            display: none;
          }

          .description {
            color: #404040;
            max-height: 135px;
            font-size: 16px;
            margin-bottom: 4px;
            margin-top: 4px;
            overflow: auto;
            white-space: pre-line;

            .resale, .requirement {
              margin-top: 10px;
              font-size: 14px;
              font-style: italic;
              display: block;
            }
          }
        }

        .priceContainer {
          min-width: 150px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 500;

          .price {
            display: flex;
            line-height: 1;
            font-family: "Segoe UI Black", sans-serif;

            .integer {
              font-size: 60px;
              color: #ed2937;
            }

            .cents {
              font-size: 35px;
              padding-top: 4px;
              color: #ed2937;
            }

            .sign {
              color: #fc6650;

              &.before {
                padding-top: 0;
                font-size: 60px;
              }

              &.after {
                padding-top: 4px;
                font-size: 35px;
              }
            }
          }
        }
      }

      hr {
        border-top: 1px solid #e6e6e6;
        border-bottom: none;
        margin: 50px 0 20px;
      }

      .bottom {
        display: flex;
        align-items: center;

        .flex-grow {
          flex-grow: 1;
          font-weight: 500;
        }
      }
    }
  }

  .panel-selectors {
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .subtitle {
      padding: 0;
      width: 100%;
      margin: 0px 10px 20px;
      font-size: 18px;
      font-weight: bold;
    }

    hr {
      display: block;
      width: 100%;
    }
  }

  &.button-mode {
    .item-selector {
      width: auto;
    }
  }

  &:not(.button-mode) {
    .item-selector {
      display: inline-block;
    }

    .scrollable-items {
      width: 100%;
    }
  }

  .item-selector {
    width: 50%;
  }

  .selector {
    flex-grow: 1;
    flex-basis: 0;
    margin: 8px 10px;
  }
}

.buttonContainer {
  flex-shrink: 0;
}

#iShopFoodApp .modifier-choice.scrollable {
  width: 100%;
}

#iShopFoodApp .subchoice-title {
  width: 100%;
  padding: 20px 20px 10px 20px;
  font-weight: bold;
}

#iShopFoodApp.xs {
  #item .page-content {
    padding: 0;

    &:after {
      height: 0 !important;
    }
  }

  #item-basic:after {
    height: 0 !important;
  }

  #item-basic .presentation .image {
    border-radius: 0;
  }

  #item-basic .error-box {
    margin: 15px;
  }

  #item-basic .btnAddOrderItem {
    border-radius: 0 !important;
  }

  #item-basic .panel-selectors .container {
    padding-bottom: 0;
  }

  .presentationPanel {
    border-top: none;
  }

  #item-basic .presentation .image {
    min-height: auto;
  }
}

#iShopFoodApp .footer-price-container {
  flex-grow: 1;

  .price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    height: 100%;
    color: var(--primary-color) !important;
    font-weight: bold;
    margin-right: 15px;
  }
}

#iShopFoodApp.xs #item-basic .presentation .right .top .nameDescriptionContainer .description {
  max-height: none;
}

#iShopFoodApp.kiosk {
  #item-basic {
    background-color: #f5f5f5;
  }

  #item-basic .btnAddOrderItem {
    padding: 23px 40px;
  }

  #item-basic:after {
    content: none;
  }

  #item-basic .buttonContainer.withQuantity {
    background-color: white;
    display: flex;
    justify-content: space-between;
    margin: 0 80px 20px 80px;
    border-radius: 20px;

    &.horizontal {
      .quantity-holder {
        align-items: center;
      }

      .quantity-holder--title {
        margin-bottom: 5px;
      }
    }

    & > .quantity-holder {
      padding: 20px 20px 20px 30px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-right: 20px;
      position: relative;

      &:after {
        content: "";
        height: 70px;
        width: 1px;
        display: block;
        background-color: #e6e6e6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .quantity-holder--title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 6px;
      }

      .quantity-holder--description {
        opacity: 0.7;
        padding-right: 40px;
        line-height: 1.1;
      }
    }

    .btnAddOrderItem {
      margin: 20px 30px 20px 0;
      max-width: 45%;
      height: 70px;
    }
  }

  #item-basic .panel.presentationPanel {
    border-radius: 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

    .container {
      padding: 40px;
    }

    .right {
      margin-left: 40px;
      display: flex;
      align-items: center;

      .top {
        display: flex;
        align-items: center;
        width: 100%;

        .nameDescriptionContainer {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .name {
        font-size: 36px;
        font-weight: bold;
        margin-top: 0;
      }

      .description {
        margin-top: 20px;
        font-size: 18px;
      }

      hr {
        border-top: 2px dashed #e6e6e6;
      }

      .bottom {
        font-size: 20px;
      }
    }

    .image {
      border-radius: 20px;
      margin: 0;
      max-width: 300px;
      min-height: 200px;
    }
  }

  #item-basic .panel.panel-selectors {
    margin: 60px 80px 60px 80px;
    width: auto !important;
    position: relative;

    .selector.button {
      width: 142px;
      border-radius: 20px;

      .image {
        width: 126px;
        margin-left: 5px;
        margin-top: 5px;
        background-size: cover;
        height: 120px;
        border-radius: 15px;
      }
    }

    & > .content:before {
      border-bottom: 2px dashed #e6e6e6;
    }

    $triangle-size: 30px;
    $left-offset: calc(-#{$triangle-size} - 20px);
    $triangle-narrow: calc(#{$triangle-size} * 2);

    &.closed {
      .checkmark {
        display: none;
      }

      &:before {
        content: none;
      }
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      left: $left-offset;
      top: 70px;
      position: absolute;
      border-top: $triangle-size solid transparent;
      border-right: $triangle-narrow solid #fff;
      border-bottom: $triangle-size solid transparent;
    }
  }

  .btnAddOrderItem {
    background-color: #80d445 !important;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    max-width: 460px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    text-transform: uppercase;
  }
}

.custom-quantity {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .custom {
    display: flex;
  }

  .btn {
    height: 56px;
  }

  .fake-input {
    border: 1px solid #e6e6e6;
    height: 56px;
    border-radius: 12px;
    font-size: 32px;
    font-weight: 900;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    margin-right: 10px;
    width: 100px;
  }

}
</style>

<style lang="scss" scoped>

#iShopFoodApp .buttonContainer {
  .btnAddOrderItem {
    width: 100%;
    font-size: 20px;
    padding: 18px 16px;
  }
}

#iShopFoodApp.xs .buttonContainer {
  .btnAddOrderItem {
    font-size: 16px;
  }
}

#iShopFoodApp #item-basic.quickAdd:not(.xs) .buttonContainer {
  .btnAddOrderItem {
    font-weight: bold;
    padding: 15px 30px;
  }
}

.btn--display-item {
  align-self: flex-start;
  font-weight: bold;
  border-radius: 10px;
}

.display-item__icon {
  margin-right: 16px;
}
</style>
