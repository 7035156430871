<template>
  <div id="invoiceCreationModal" class="invoice-creation-modal modal" :class="{ show: show }">
    <div class="modal-content">
      <div class="modal-header">
        <svgicon src="icons/invoice.svg" :height="24" :width="20"></svgicon>
        <div class="header__text">{{ $t("pos_invoice_creation_modal.title") }}</div>
      </div>

      <div class="invoice-creation-modal__description">
        {{ $t("pos_invoice_creation_modal.description") }}
      </div>

      <div class="invoice-creation-modal__invoice">
        <label class="input__label">{{ $t("pos_invoice_creation_modal.invoice_number") }}</label>
        <input type="text" :value="currentInvoiceNumber" readonly disabled>
      </div>

      <SeatsSelector :seats="seats" :selection="selectedSeats" :disabledSeats="disabledSeats" class="seats"
                     @update:selection="selectedSeats = $event"/>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="close">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"/>
          {{ $t("back") }}
        </button>
        <button class="btn btn-primary" @click="createInvoice">
          <svgicon src="icons/check.svg" :height="25" :width="25"/>
          {{ $t("pos_invoice_creation_modal.action") }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import SeatsSelector from "@/components/pos/SeatsSelector";

  export default {
    name: "InvoiceCreationModal",

    components: { SeatsSelector },

    data() {
      return {
        show: false,
        callback: null,
        selectedSeats: [],
        invoiceIndex: 1,
        // The `invoiceNumber` property is set to the value `12345`. It is used as the initial value for the input field in
        // the template. The comment `//this is order number + index of invoice, validate in data invoice creation
        // process**` suggests that this value is a placeholder and should be replaced with the actual order number plus
        // the index of the invoice. It also indicates that validation should be implemented in the data invoice creation
        // process to ensure that the invoice number is valid.
        invoiceNumber: "12345"//this is order number + index of invoice, validate in data invoice creation process**
      };
    },

    mounted() {
      EventBus.$on("show-invoice-creation", (callback) => {
        this.callback = callback;
        if (this.availableSeats.length === 0) {
          this.executeCallback();
        } else {
          this.show = true;
        }
      });
    },

    beforeDestroy() {
      EventBus.$off("show-invoice-creation");
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      seats() {
        return this.table ? this.table.getNonEmptySeats() : [];
      },
      availableSeats() {
        return this.seats.filter(s => this.disabledSeats.indexOf(s) === -1);
      },
      disabledSeats() {
        return this.table ? this.table.seatsByInvoice.flat() : [];
      },
      currentInvoiceNumber() {
        return `${this.invoiceNumber}-${this.invoiceIndex}`;
      }
    },

    methods: {
      createInvoice() {
        if (this.selectedSeats.length === 0) { //if no seat is selected, will auto select all available seats
          this.selectedSeats = this.availableSeats;
        }
        this.table.seatsByInvoice.push(this.selectedSeats); //assign all selected seats as an invoice
        if (this.availableSeats.length === 0) { //no seat remaining
          this.executeCallback();
          this.close();
        } else { //Go through next invoice selection
          this.invoiceIndex++;
          this.selectedSeats = [];
        }
      },
      executeCallback() {
        if (this.callback && typeof this.callback === "function") {
          this.callback();
        }
      },
      close() {
        this.show = false;
        this.selectedSeats = [];
        this.invoiceIndex = 1;
        this.callback = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #invoiceCreationModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 940px;

    .invoice-creation-modal__description {
      padding: 30px 0;
      font-size: 20px;
      font-weight: 700;
    }

    .invoice-creation-modal__invoice {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-bottom: 20px;

      .input__label {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color-2);
        margin-bottom: 6px;
      }

      input {
        border-radius: 12px;
        border: 1px solid var(--background-color-4);
        padding: 10px 15px;
        font-size: 40px;
        line-height: 1;
        font-weight: 500;
        color: var(--theme-color);
        font-family: inherit;
      }
    }

    .seats {
      max-height: 350px;
      height: 350px;
      overflow-y: scroll;
      padding-right: 10px;
    }

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }

      .header__text {
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>