
class Cryptography {

  /**
   * Returns a hash code from a string
   * @param  {String} str The string to hash.
   * @return {Number}    A 32bit integer
   * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
   */
  generateHashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  /**
   *
   * @param {string }text
   * @param {string} method
   * @returns {Promise<string>}
   * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
   */
  async encryptTextToHashArray(text, method = "SHA-256") {
    const messageBuffer = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest(method, messageBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  }

}

let cryptography = new Cryptography();
export { cryptography as Cryptography };