//@ts-check
import { AppliedPromotion } from "@/PromotionManager";
import Modifier from "./ModifierModel";
import Store from "@/store";

/**
 * @typedef CrossSellDefinition
 * @property {number} item
 * @property {string} link
 * @property {link: {fr: string, en: string}} text
 */

/**
 * @typedef KitchenDefinition
 * @property {description: {fr: string, en: string}} text
 * @property {recipe: {fr: string, en: string}} text
 * @property {ingredient: {fr: string, en: string}} text
 * @property {allergens: {fr: string, en: string}} text
 */

/**
 * @typedef ItemDefinition
 * @property {number} id
 * @property {number} version
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {string} image
 * @property {boolean} taxable
 * @property {{method: string, unitPrice: number}[]} priceSchemes
 * @property {boolean} promotionsApplicable
 * @property {any} moreData
 * @property {number[]} choices
 * @property {boolean} outOfStock
 * @property {boolean} quantifiable
 * @property {boolean} availableForScanOnly
 * @property {number} [minimumCatering]
 * @property {boolean} hideInCart
 * @property {CrossSellDefinition} crossSells
 * @property {KitchenDefinition} kitchen
 * @property {boolean} priceEncodedInUpc
 */

export default class Item {

  /**
   * @param {ItemDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.externalId = definition.externalId;
    this.version = definition.version;
    this.name = definition.name;
    this.upc = definition.upc;
    this.availableForScanOnly = definition.availableForScanOnly;
    this.description = definition.description;
    this.image = /** @type {string} */ (null);
    this.priceSchemes = definition.priceSchemes.map(p => {
      p.unitPrice = parseFloat(String(p.unitPrice));
      return p;
    });
    this.flowItems = definition.flow ? definition.flow.items : [];
    this.modifiers = /** @type {Modifier[]} */ ([]);
    this.modifierGroups = /** @type {ModifierGroup[]} */ ([]);
    this.choices = /** @type {Choice[]} */ ([]);
    this.choiceGroups = /** @type {ChoiceGroup[]} */ ([]);
    this.variants = /** @type {Item[]} */ ([]);
    this.selectedVariant = /** @type {Item} */ (null);
    this.quantity = 1;
    this.taxable = definition.taxable;
    this.tags = /** @type {Tag[]} */ ([]);

    this.appliedPromotion = new AppliedPromotion();
    this.appliedPromotions = null;

    this.promotionable = !!definition.promotionsApplicable;
    this.outOfStock = !!definition.outOfStock;
    this.fromCategoryId = /** @type {number} */ (null);
    this.parent = /** @type {Item} */ (definition.parent || null);
    this.quantifiable = typeof definition.quantifiable === "undefined" ? true : definition.quantifiable;
    this.categories = /** @type {Category[]} */ ([]);
    this.minimumCatering = definition.minimumCatering || 0;
    this.minimum = definition.minimum;
    this.maximum = definition.maximum;
    this.hideInCart = definition.hideInCart || false;
    this.branding = null;
    this.crossSells = definition.crossSells || [];
    this.revenueCenter = definition.revenueCenter;
    this.kitchen = definition.kitchen;
    this.priceEncodedInUpc = definition.priceEncodedInUpc || false;
    this.priceOverride = null;

    this.dummyModifier = new Modifier({
      id: null,
      name: {
        fr: "Aucun",
        en: "None"
      },
      priceSchemes: [],
      quantifiable: false
    });
    this.dummyModifier.image = "img/no_thank_you.png";
  }

  reset(interactiveMode) {
    // Choices
    for (let choice of this.choices) {
      if (interactiveMode) {
        choice.selected = null;
      } else {
        choice.selectDefault();
      }
      // Subchoices
      for (let item of choice.items) {
        for (let subchoice of item.choices) {
          if (interactiveMode) {
            subchoice.selected = null;
          } else {
            subchoice.selectDefault();
          }
        }
      }
    }
    for (let choiceGroup of this.choiceGroups) {
      for (let choice of choiceGroup.choices) {
        if (interactiveMode) {
          choice.selected = null;
        } else {
          choice.selectDefault();
        }
      }
    }
    // Modifiers
    for (let modifier of this.modifiers) {
      modifier.setQuantity(0);
    }
    for (let modifierGroup of this.modifierGroups) {
      for (let modifier of modifierGroup.modifiers) {
        modifier.setQuantity(0);
      }
    }
    // Variants
    for (let variant of this.variants) {
      variant.reset(interactiveMode);
    }
    this.selectedVariant = null;

    // Base item
    this.quantity = 1;

    // Price override
    this.priceOverride = null;
  }

  getId() {
    return (this.selectedVariant || this).id;
  }

  getTags() {
    return (this.selectedVariant || this).tags;
  }

  getPublicTags() {
    return (this.selectedVariant || this).tags.filter(t => t.public);
  }

  getParentId() {
    return (this.selectedVariant || this).parent?.id || null;
  }

  getDummyModifier() {
    return (this.selectedVariant || this).dummyModifier;
  }

  isResaleItem() {
    if (this.selectedVariant) {
      return this.selectedVariant.isResaleItem();
    }
    return !!this.externalId && this.externalId.toLowerCase().indexOf("r") === 0;
  }

  isAvailableForScanOnly() {
    if (this.variants.length === 0) {
      return this.availableForScanOnly;
    }
    //Product will be hidden only if all variants are scan only
    //TODO not sure about variants
    for (let variant of this.variants) {
      if (!variant.availableForScanOnly) {
        return false;
      }
    }
    return true;
  }

  isTaxable() {
    return this.taxable;
  }

  getFlowItems() {
    return (this.selectedVariant || this).flowItems;
  }

  hasFlowItemsConfigured() {
    let variantWithFlowItems = this.variants.find(v => v.flowItems.length > 0);
    return this.flowItems.length > 0 || !!variantWithFlowItems;
  }

  getName() {
    if (this.selectedVariant) {
      let toReturn = {};
      if (this.name.fr && this.selectedVariant.name.fr) {
        toReturn.fr = this.name.fr + " - " + this.selectedVariant.name.fr;
      }
      if (this.name.en && this.selectedVariant.name.en) {
        toReturn.en = this.name.en + " - " + this.selectedVariant.name.en;
      }
      return toReturn;
    }
    return this.name;
  }

  /**
   * @returns {string}
   */
  getKitchenName() {
    if (this.selectedVariant && this.selectedVariant.kitchen && this.selectedVariant.kitchen.description &&
      window.translateObject(this.selectedVariant.kitchen.description)) {
      return window.translateObject(this.kitchen.description);
    }
    return this.kitchen && this.kitchen.description && window.translateObject(this.kitchen.description)
      ? window.translateObject(this.kitchen.description)
      : "";
  }

  getDescription() {
    if (this.selectedVariant && (this.selectedVariant.description.fr || this.selectedVariant.description.en)) {
      return this.selectedVariant.description;
    }
    return this.description;
  }

  getImage() {
    if (this.parent && !this.selectedVariant && !this.image) {
      return this.parent.image;
    }
    return (this.selectedVariant || this).image;
  }

  /**
   * @param {string} [method]
   * @returns {number}
   */
  getUnitPrice(method) {
    return this.getPrice(method);
  }

  /**
   * Returns the base price of the item for a given method
   * @param {string} [method]
   * @returns {number} Base price of the item
   */
  getPrice(method) {
    if (CONFIG.kiosk && this.priceOverride) {
      return this.priceOverride;
    }

    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    if (!entity) {
      return 0;
    }
    method = method || entity.method || "takeout";

    if (this.selectedVariant) {
      if (CONFIG.pos) { /* POS */
        return this.selectedVariant.getRevenueCenterPriceForRef(Store.state.currentRevenueCenterRef);
      } else { /* OO/Kiosk */
        let priceScheme = this.selectedVariant.priceSchemes.find(p => p.method === method);
        return priceScheme ? priceScheme.unitPrice : 0;
      }
    } else {
      return this.getLowestPrice(method);
    }
  }

  /**
   * @param {string} ref
   * @returns {number}
   */
  getRevenueCenterPriceForRef(ref) {
    if (ref && this.revenueCenter && this.revenueCenter.prices &&
      this.revenueCenter.prices[ref] && this.revenueCenter.prices[ref].unitPrice) {
      return parseFloat(this.revenueCenter.prices[ref].unitPrice);
    }
    return 0;
  }

  getLowestPrice(method) {
    if (CONFIG.kiosk && this.priceOverride) {
      return this.priceOverride;
    }

    if (this.variants.length > 0) {
      let variantPricesAboveZero = this.variants.map(v => v.getPrice(method)).filter(p => p > 0);
      return variantPricesAboveZero.length === 0 ? 0 : Math.min(...variantPricesAboveZero);
    } else {
      if (CONFIG.pos) { /* POS */
        return this.getRevenueCenterPriceForRef(Store.state.currentRevenueCenterRef);
      } else { /* OO/Kiosk */
        let priceScheme = this.priceSchemes.find(p => p.method === method);
        return priceScheme ? priceScheme.unitPrice : 0;
      }
    }
  }

  /**
   * Overrides the base price of the item
   * @param {number} [price]
   * @returns {number} Base price of the item
   */
  setPriceOverride(price) {
    this.priceOverride = price;
  }

  /**
   * Returns the price of the item considering quantity and modifiers
   * @param {string} [method]
   */
  getTotalPrice(method) {

    method = method || Store.state.order.method || "takeout"; //TODO should remove store and default value

    let totalTaxable = 0;
    let totalNonTaxable = 0;
    let totalNotApplicableForLoyaltyOrGiftCard = 0;

    if (this.taxable) {
      totalTaxable += this.getPrice(method);
    } else {
      totalNonTaxable += this.getPrice(method);
    }

    if (this.isResaleItem() || !this.isTaxable()) {
      totalNotApplicableForLoyaltyOrGiftCard += this.getPrice(method);
    }

    // TODO - Hardcodé
    let applyOnModifiers = true;
    if (applyOnModifiers) {
      let selectedModifiers = this.getSelectedModifiers();
      for (let modifier of selectedModifiers) {
        if (modifier.taxable) {
          totalTaxable += modifier.getPrice(method) * modifier.quantity;
        } else {
          totalNonTaxable += modifier.getPrice(method) * modifier.quantity;
        }

        if (modifier.isResaleItem()) {
          totalNotApplicableForLoyaltyOrGiftCard += modifier.getPrice(method) * modifier.quantity;
        }

        for (let choice of modifier.getChoices()) {
          let choiceItem = choice.selected;
          if (!choiceItem) {
            continue;
          }
          if (choiceItem.taxable) {
            totalTaxable += choiceItem.getTotalPrice(method).subTotal * choiceItem.quantity;
          } else {
            totalNonTaxable += choiceItem.getTotalPrice(method).subTotal * choiceItem.quantity;
          }
        }
      }
      let selectedChoices = this.getSelectedChoices();
      for (let choice of selectedChoices) {
        if (choice.taxable) {
          totalTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
        } else {
          totalNonTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
        }
      }
      for (let choice of this.getChoices()) {
        for (let modifierGroup of choice.modifierGroups) {
          for (let modifier of modifierGroup.modifiers) {
            if (modifier.taxable) {
              totalTaxable += modifier.getPrice(method) * modifier.quantity;
            } else {
              totalNonTaxable += modifier.getPrice(method) * modifier.quantity;
            }
            if (modifier.isResaleItem()) {
              totalNotApplicableForLoyaltyOrGiftCard += modifier.getPrice(method) * modifier.quantity;
            }
          }
        }
      }
    }

    if (!applyOnModifiers) {
      let selectedModifiers = this.getSelectedModifiers();
      for (let modifier of selectedModifiers) {
        if (modifier.taxable) {
          totalTaxable += modifier.getPrice(method) * modifier.quantity;
        } else {
          totalNonTaxable += modifier.getPrice(method) * modifier.quantity;
        }
        if (modifier.isResaleItem()) {
          totalNotApplicableForLoyaltyOrGiftCard += modifier.getPrice(method) * modifier.quantity;
        }
      }
      let selectedChoices = this.getSelectedChoices();
      for (let choice of selectedChoices) {
        if (choice.taxable) {
          totalTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
        } else {
          totalNonTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
        }
      }
    }

    totalTaxable *= this.quantity;
    totalNonTaxable *= this.quantity;
    totalNotApplicableForLoyaltyOrGiftCard *= this.quantity;

    let subTotalBeforePromotion = parseFloat((totalTaxable + totalNonTaxable).toFixed(2));
    let appliedPromotions = (this.selectedVariant || this).appliedPromotions;
    if (appliedPromotions && appliedPromotions.length > 0) {
      let toRemoveTotal = appliedPromotions.reduce((p, c) => p + c.amount, 0);
      toRemoveTotal = Math.min(toRemoveTotal, totalTaxable);
      totalTaxable -= toRemoveTotal * this.quantity;
    } else if (this.appliedPromotion.value != 0) {
      let toRemoveTotal = Math.min(totalTaxable, this.appliedPromotion.value);
      toRemoveTotal = Math.min(toRemoveTotal, totalTaxable);
      totalTaxable -= toRemoveTotal;
      totalNonTaxable -= Math.min(this.appliedPromotion.value - toRemoveTotal, totalNonTaxable);
    }

    let taxes = {};
    if (Store.state.currentCompany) {
      for (let tax of Store.state.currentCompany.taxes) {
        taxes[tax.uniqueName] = totalTaxable * tax.value;
      }
    }
    return {
      subTotalBeforePromotion: subTotalBeforePromotion,
      totalNotApplicableForLoyaltyOrGiftCard: totalNotApplicableForLoyaltyOrGiftCard,
      subTotal: parseFloat((totalTaxable + totalNonTaxable).toFixed(2)),
      taxes,
      totalTaxable,
      totalNonTaxable
    };

  }

  getModifiers() {
    if (this.variants.length > 0 && !this.selectedVariant) {
      return [];
    }
    return (this.selectedVariant || this).modifiers;
  }

  getModifierGroups() {
    if (this.variants.length > 0 && !this.selectedVariant) {
      return [];
    }
    return (this.selectedVariant || this).modifierGroups;
  }

  getCompiledModifiers() {
    let modifiers = this.getModifiers();
    for (let group of this.getModifierGroups()) {
      modifiers = [...modifiers, ...group.modifiers];
    }
    for (let choice of this.getChoices()) {
      for (let group of choice.modifierGroups) {
        modifiers = [...modifiers, ...group.modifiers];
      }
    }
    for (let choiceGroup of this.getChoiceGroups()) {
      for (let choice of choiceGroup.getChoices()) {
        for (let group of choice.modifierGroups) {
          modifiers = [...modifiers, ...group.modifiers];
        }
      }
    }
    return modifiers;
  }

  getInvalidModifierGroups() {
    let modifierGroups = [...this.getModifierGroups()];
    for (let choice of this.getChoices()) {
      if (choice.modifierGroups) {
        modifierGroups = [...modifierGroups, ...choice.modifierGroups];
      }
      if (choice.selected) {
        modifierGroups = [...modifierGroups, ...choice.selected.modifierGroups];
      }
    }
    for (let choiceGroup of this.getChoiceGroups()) {
      for (let choice of choiceGroup.getChoices()) {
        if (choice.selected) {
          modifierGroups = [...modifierGroups, ...choice.selected.modifierGroups];
        }
      }
    }
    return modifierGroups.filter(g => g ? !g.validateQuantitySelected().valid : false);
  }

  /**
   * Returns all modifiers that have a quantity of at least 1
   * @returns {Modifier[]} Selected modifiers
   */
  getSelectedModifiers() {
    let toReturn = this.getModifiers().filter(m => m.quantity > 0);
    for (let modifierGroup of this.getModifierGroups()) {
      let array = modifierGroup.modifiers.filter(m => m.quantity > 0);
      toReturn = toReturn.concat(array);
    }
    return toReturn;
  }

  getChoices() {
    if (this.variants.length > 0 && !this.selectedVariant) {
      return [];
    }
    let choices = (this.selectedVariant || this).choices;
    let method = Store.state.order.method || "takeout";
    return choices.filter(c => c.isAvailableForMethod(method));
  }

  getChoiceGroups() {
    if (this.variants.length > 0 && !this.selectedVariant) {
      return [];
    }
    return (this.selectedVariant || this).choiceGroups;
  }

  getSelectedChoices() {
    let toReturn = [];
    for (let choice of this.getChoices()) {
      toReturn.push(choice.selected);
    }
    for (let choiceGroup of this.getChoiceGroups()) {
      for (let choice of choiceGroup.getChoices()) {
        toReturn.push(choice.selected);
      }
    }
    toReturn = toReturn.filter(i => !!i);
    return toReturn;
  }

  getAllChoices() {
    let toReturn = [...this.getChoices()];
    for (let choiceGroup of this.getChoiceGroups()) {
      toReturn = [...toReturn, ...choiceGroup.getChoices()];
    }
    return toReturn;
  }

  areAllChoicesValid() {
    for (let choice of this.getAllChoices()) {
      if (!choice.selected) {
        return false;
      }
    }
    return true;
  }

  getMinimum() {
    if (Store.state.order.method === "catering") {
      return (this.selectedVariant || this).minimumCatering;
    }
    if (this.minimum) {
      return this.minimum;
    }
    return 1;
  }

  getMaximum() {
    if (this.maximum) {
      return this.maximum;
    }
  }

  isHidden() {
    return this.categories.length == 0;
  }

  /**
   * @param {string} method
   * @returns {Item[]}
   */
  getVariants(method) {
    let showZero = Store.state.currentCompany.showItemsWhenPriceIsZero;
    if (showZero) {
      return this.variants;
    }
    return this.variants.filter(v => v.getPrice(method) > 0);
  }

  /**
   * @param {string} type
   * @param {string} method
   * @return {boolean}
   */
  shouldBeDisplayedAs(type, method) {
    let showZero = Store.state.currentCompany.showItemsWhenPriceIsZero;
    let isScanOnly = CONFIG.kiosk && Store.state.kioskConfiguration && Store.state.kioskConfiguration.hasScan() && this.availableForScanOnly;
    return type === "item" && !isScanOnly && (showZero || (!showZero && this.getPrice(method) > 0));
  }

  /**
   * @returns {boolean}
   */
  isCustomizable() {
    return this.getModifiers().length > 0 ||
      this.getModifierGroups().length > 0 ||
      this.getAllChoices().length > 0 || // Choice and Choice groups
      this.variants.length > 0;
  }

  /**
   * @returns {boolean}
   */
  isCustomizableExcludingVariants() {
    return this.getModifiers().length > 0 ||
      this.getModifierGroups().length > 0 ||
      this.getAllChoices().length > 0;
  }

  getAllAppliedPromotions() {
    let promos = [];
    if (this.appliedPromotion.promotion) {
      promos.push(this.appliedPromotion);
    }
    for (let choice of this.getSelectedChoices()) {
      if (choice.appliedPromotion.promotion) {
        promos.push(choice.appliedPromotion);
      }
    }
    return promos;
  }

  hasTagFromList(tags) {
    if (this.selectedVariant) {
      return this.selectedVariant.tags.some(t => tags.indexOf(t.id) > -1);
    }
    return this.tags.some(t => tags.indexOf(t.id) > -1);
  }

  /**
   * @param {Item} item
   * @return {boolean}
   */
  isIdenticalByValue(item) {
    if (item.id !== this.id) {
      return false;
    }
    if (!this.isCustomizable()) {
      return true;
    }
    if (this.selectedVariant && item.selectedVariant) {
      return this.selectedVariant.isIdenticalByValue(item.selectedVariant);
    }

    if (JSON.stringify(this.getSelectedModifiers()) !== JSON.stringify(item.getSelectedModifiers())) {
      return false;
    }

    if (JSON.stringify(this.getSelectedChoices()) !== JSON.stringify(item.getSelectedChoices())) {
      return false;
    }

    return true;
  }

  /**
   * @return {Item} Clone of the item
   */
  clone() {
    let newItem = new Item(this.definition);
    newItem.image = this.image;
    // newItem.categories = this.categories;
    newItem.modifiers = this.modifiers.map(m => m.clone());
    newItem.modifierGroups = this.modifierGroups.map(m => m.clone());
    newItem.choices = this.choices.map(c => c.clone());
    newItem.choiceGroups = this.choiceGroups.map(c => c.clone());
    newItem.variants = this.variants.map(v => v.clone());
    newItem.tags = [...this.tags];
    if (this.selectedVariant) {
      newItem.selectedVariant = this.selectedVariant.clone();
    }
    newItem.quantity = this.quantity;
    newItem.appliedPromotion = this.appliedPromotion.clone();
    newItem.parent = this.parent;
    newItem.categories = [...this.categories];
    newItem.branding = this.branding;
    newItem.priceEncodedInUpc = this.priceEncodedInUpc;
    newItem.priceOverride = this.priceOverride;
    return newItem;
  }

  toDto() {
    return {
      id: this.id,
      variant: this.selectedVariant ? this.selectedVariant.id : null,
      quantity: this.quantity,
      branding: this.branding,
      modifiers: this.getModifiers().map(m => m.toDto()),
      modifierGroups: this.getModifierGroups().map(mg => mg.toDto()),
      choices: this.getChoices().map(c => c.toDto()),
      choiceGroups: this.getChoiceGroups().map(cg => cg.toDto())
    };
  }

  fromDto(dto) {
    this.quantity = dto.quantity;
    this.branding = dto.branding;
    let toModify = /** @type {Item} */ (this);
    if (dto.variant) {
      let variant = this.variants.find(v => v.id == dto.variant);
      this.selectedVariant = variant;
      toModify = variant;
    }
    for (let choice of dto.choices) {
      let choiceToEdit = toModify.choices.find(c => c.id == choice.id);
      choiceToEdit.fromDto(choice);
    }
    // TODO - Choicegroup
    for (let modifier of dto.modifiers) {
      let modifierToEdit = toModify.modifiers.find(m => m.id == modifier.id);
      modifierToEdit.setQuantity(modifier.quantity);

      for (let i = 0; i < modifier.choices.length; i++) {
        let choice = modifier.choices[i];
        let choiceToEdit = modifierToEdit.choices.find(c => c.id === choice.id && !c.selected);
        choiceToEdit.fromDto(choice);
      }
    }
    for (let modifierGroup of dto.modifierGroups) {
      let toEdit = toModify.modifierGroups.find(g => g.id == modifierGroup.id);
      for (let modifier of toEdit.modifiers) {
        let source = modifierGroup.modifiers.find(g => g.id == modifier.id);
        if (source && source.quantity) {
          modifier.setQuantity(source.quantity);
        }
      }
    }
  }

  getCrossSells() {
    return this.crossSells;
  }

  hasCrossSell() {
    return this.crossSells.length > 0;
  }

  getKitchenField(field) {
    if (this.selectedVariant && this.selectedVariant.kitchen && this.selectedVariant.kitchen[field] &&
      (this.selectedVariant.kitchen[field].fr || this.selectedVariant.kitchen[field].en)) {
      return this.selectedVariant.kitchen[field];
    } else if (this.kitchen && this.kitchen[field]) {
      return this.kitchen[field];
    }

    return {fr: "", en: ""};
  }

  getKitchenDescription() {
    return this.getKitchenField("description");
  }

  getKitchenRecipe() {
    return this.getKitchenField("recipe");
  }

  getKitchenIngredients() {
    return this.getKitchenField("ingredient");//singular
  }

  getKitchenAllergens() {
    return this.getKitchenField("allergens");
  }

  /**
   * Adds positionIndex according to display order for item modifier, modifier groups, choices and choice groups
   */
  applyFlowOrderToCurrentSelection() {
    if (!this.hasFlowItemsConfigured()) {
      return;
    }

    let flowItems = this.getFlowItems();
    let choices = this.getAllChoices();
    let choiceGroups = this.getChoiceGroups();
    let modifiers = this.getModifiers();
    let modifierGroups = this.getModifierGroups();

    const getFlowPositionIndex = (type, id) => {
      let found = flowItems.find(i => i.type === type && i.id === id);
      if (found) {
        return flowItems.indexOf(found);
      }
      return null;
    };
    for (let choice of choices) {
      choice.positionIndex = getFlowPositionIndex("choice", choice.id);
    }
    for (let choiceGroup of choiceGroups) {
      let choiceIndex = getFlowPositionIndex("choice-group", choiceGroup.id);
      if (choiceIndex !== null) {
        for (let choice of choiceGroup.choices) {
          choice.positionIndex = choiceIndex;
        }
      }
    }
    let modifierIndex = getFlowPositionIndex("modifiers", null);
    if (modifierIndex !== null) {
      for (let modifier of modifiers) {
        modifier.positionIndex = modifierIndex;
      }
    }
    for (let modifierGroup of modifierGroups) {
      let modifierIndex = getFlowPositionIndex("modifier-group", modifierGroup.id);
      if (modifierIndex !== null) {
        for (let modifier of modifierGroup.modifiers) {
          modifier.positionIndex = modifierIndex;
        }
      }
    }
  }

  isPriceEncodedInUpc() {
    return this.priceEncodedInUpc;
  }

}
