<template>
  <div id="valitorCardLogos" v-if="company && cards.length > 0">
    <img src="img/brand/visa.png" class="card visa" v-if="cards.indexOf('visa') > -1" />
    <img src="img/brand/mastercard.png" class="card mastercard" v-if="cards.indexOf('mastercard') > -1" />
    <img src="img/brand/applePay.png" class="card applePay" v-if="cards.indexOf('applePay') > -1" />
    <img src="img/brand/amex.png" class="card amex" v-if="cards.indexOf('amex') > -1" />
  </div>
</template>

<script>
  export default {
    name: "ValitorCardLogos",

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      cards() {
        if (!this.company) {
          return [];
        }
        return this.company.getValitorCardBrands();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #valitorCardLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;

    .card {
      height: 35px;
      width: auto;
      margin: 12px 5px;
      border-radius: 3px;
      background-color: white;

      &.amex {
        width: 35px;
        height: 35px;
      }
    }
  }
</style>