<template>
  <div id="companies">

    <MenuHeader :text="$t('navigation.branches')" icon="icons/branches.svg"></MenuHeader>

    <div class="page-content">
      <div class="list">

        <div class="item"
          :class="{selected: company == $store.state.currentCompany}"
          v-for="company of companies.filter(c => c.id != -1)" :key="company.id"
          @click="selectCompany(company)">
          <div class="image" :style="{'background-image': 'url(' + getCompanyImage(company) + ')'}"></div>
          <div class="bottom">
            <div class="name">{{ company.name }}</div>
            <div class="business-hours" v-if="company.address">{{ company.address.address }}, {{ company.address.city }}</div>
            <FavoriteCompany :company="company" only-favorite disable-update></FavoriteCompany>
          </div>
        </div>

      </div>
    </div>

    <div id="modalComment" class="modal" :class="{show: showChangeModal}">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/alert.svg" :height="24" :width="24"></svgicon>
          {{ $t("order.change_company") }}
        </div>
        <div class="modal-body">
          <Illustration src="icons/empty.svg"></Illustration>
          <div class="text">{{ $t("order.change_company_question") }}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showChangeModal = false">{{ $t("no") }}</button>
          <button type="button" class="btn btn-primary" @click="setCompany(changeTo)">{{ $t("yes") }}</button>
        </div>
      </div>
    </div>

    <HistoryBack></HistoryBack>

  </div>
</template>

<script>

  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import { mapActions } from 'vuex';
  import Illustration from "@/components/modal/Illustration.vue";
  import FavoriteCompany from "@/components/FavoriteCompany.vue";

  export default {

    components: { MenuHeader, Illustration, FavoriteCompany },

    data() {
      return {
        showChangeModal: false,
        changeTo: null
      };
    },

    mounted() {
      this.$store.commit("showNavigationCart", true);
    },

    computed: {
      companies() {
        if (!this.$store.state.currentBranch) {
          return [];
        }
        return this.$store.state.currentBranch.companies;
      }
    },

    methods: {
      ...mapActions({
        setCurrentCompanyAction: "setCurrentCompany",
      }),
      selectCompany(company) {
        if (this.$order.hasItems()) {
          this.showChangeModal = true;
          this.changeTo = company;
        } else {
          this.setCompany(company);
        }
      },
      setCompany(company) {
        this.setCurrentCompanyAction(company);
        this.$order.reset();
        this.$router.push(this.$router.generate("/menu"));
      },
      getCompanyImage(company) {
        if (company && company.image) {
          return this.$store.state.urlFileServer + company.image;
        } else {
          return "img/default.jpg";
        }
      }
    }

  }

</script>

<style lang="scss" scoped>

  .list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      background-color: white;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
      margin: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      width: 300px;
      cursor: pointer;
      .image {
        height: 200px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .bottom {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        .name {
          font-size: 20px;
          font-weight: bold;
        }
        .description {
          font-size: 16px;
          color: #808080;
        }
      }
    }
  }

  #modalComment {
    .text {
      text-align: center;
    }
    .modal-footer {
      display: flex;
      justify-content: center !important;
      button {
        margin: 0 10px;
      }
    }
    .modal-header {
      .svgicon {
        fill: #ed2937;
        margin-right: 8px;
      }
    }
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
    }

    .list {
      padding: 5px 10px;

      .item .bottom {
        padding: 15px;

        .name {
          font-size: 16px;
        }
        .description {
          font-size: 12px;
        }
      }

    }
  }

</style>
