import { Constant } from "@/util/Constant";
import Store from "../store";

class PermissionManager {

  constructor() {
    this.permissionMap = new Map();
    for (let permission of Constant.PERMISSIONS) { //Init map w/ permissions as keys and an array as value to hold eligible roles id
      this.permissionMap.set(permission, []);
    }
  }

  async setRolesPermissions(roles) {
    for (let role of roles) {
      if (role && role.whitelist) {
        for (const permission of Object.keys(role.whitelist)) { //for each role's permission, add role id to the value in the map
          let rolesForPermission = this.permissionMap.get(permission);
          if (Array.isArray(rolesForPermission)) {
            if (!rolesForPermission.includes(role.id)) {
              rolesForPermission.push(role.id);
              this.permissionMap.set(permission, rolesForPermission);
            }
          } else {
            console.log("Unexpected action added in PM");
            this.permissionMap.set(permission, [role.id]);
          }
        }
      }
    }
  }

  hasPermission(action) {
    let roles = this.permissionMap.get(action);
    if (roles && Store.state.employee) {
      let currentEmployeeRole = Store.state.employee.getPosRoleId();
      return roles.includes(currentEmployeeRole);
    }
    return false;
  }

}

let permissionManager = new PermissionManager();
export { permissionManager as PermissionManager };