<template>
    <div class="radio" :class="{active: val === value, disabled: disabled}">
        <input :id="'radio-button' + uuid" type="radio" :value="val" :checked="val === value" @change="$emit('input', val)" :disabled="disabled">
        <label :for="'radio-button' + uuid"></label>
        <div class="text" v-if="hasSlot" @click="$emit('input', val)">
            <slot />
        </div>
    </div>
</template>

<script>
  export default {
    name: "radio",

    props: {
      value: null,
      val: null,
      activeColor: String,
      borderColor: String,
      backgroundColor: String,
      circleMargin: String,
      inactiveColor: String,
      disabled: Boolean,
      size: String
    },

    data() {
      return {
        uuid: null
      };
    },

    computed: {
      hasSlot() {
        return Object.keys(this.$slots).length > 0;
      },
      isDarkTheme() {
        return this.$store.state.posConfiguration && this.$store.state.posConfiguration.darkTheme;
      }
    },

    watch: {
      "$store.state.posConfiguration.darkTheme"() {
        this.setBackgroundColor()
      }
    },

    methods: {
      setBackgroundColor() {
        this.$el.style.setProperty("--radio--background-color", this.inactiveColor || this.isDarkTheme ? "#262626" : "#ffffff");
      }
    },

    mounted() {
      this.uuid = window.generateUUID();
      this.$el.style.setProperty("--radio--size", this.size || "30px");
      this.$el.style.setProperty("--radio--circle-margin", this.circleMargin || "8px");
      this.$el.style.setProperty("--radio--active-color", this.activeColor || this.$store.state.primaryColor);
      this.setBackgroundColor();
      this.$el.style.setProperty("--radio--border-color", this.borderColor || "#e6e6e6");
    }
  }
</script>

<style lang="scss" scoped>
    $size: var(--radio--size);
    $inner-circle: calc(#{$size} - var(--radio--circle-margin));

    .radio {
        position: relative;
        display: flex;
        align-items: center;

        .text {
            padding-left: 15px;
            cursor: pointer;
        }

        &.active {
            label::before {
                background-color: var(--radio--active-color);
                transform: translateX(-50%) translateY(-50%) scale(1);
            }
        }

      &.disabled {
        cursor: initial;
        pointer-events: none;
        label::before {
          opacity: 0.5;
        }
      }

        label {
            width: $size;
            margin-left: 0 !important;

            &::before {
                content: "" !important;
                margin-left: auto;
                margin-right: auto;
                width: $inner-circle !important;
                height: $inner-circle !important;
                top: 50% !important;
                left: 50% !important;
                transform: translateX(-50%) translateY(-50%) scale(0);
                transition: all 250ms ease-in-out;
                display: block;
                border-radius: 100%;
                z-index: 1;
                position: absolute;
            }

            &::after {
                content: "" !important;
                left: 50% !important;
                width: $size !important;
                height: $size !important;
                background-color: var(--radio--background-color);
                box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
            }
        }
    }
</style>
