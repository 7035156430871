import Accounts from "./views/loyalty/Accounts.vue";
import Barcode from "./views/loyalty/Barcode.vue";
import Companies from "./views/Companies.vue";
import Company from "./views/Company.vue";
import CookiesPolicy from "@/views/CookiesPolicy";
import Dispatch from "./views/Dispatch.vue";
import GiftCardPurchase from "./views/giftCard/Purchase.vue";
import GiftCardView from "./views/giftCard/View.vue";
import History from "./views/History.vue";
import Home from "./views/Home.vue";
import Hub from "./views/loyalty/Hub.vue";
import Item from "./views/Item.vue";
import KioskAdmin from "./views/kiosk/Admin.vue";
import KioskCallingSystem from "./views/kiosk/CallingSystem.vue";
import KioskConfiguration from "./views/kiosk/Configuration.vue";
import KioskConfirmation from "./views/kiosk/Confirmation.vue";
import KioskMethod from "./views/kiosk/Method.vue";
import KioskPayment from "./views/kiosk/Payment.vue";
import KioskSaleWarning from "./views/kiosk/SaleWarning.vue";
import KioskStandby from "./views/kiosk/Standby.vue";
import KioskThankYou from "./views/kiosk/ThankYou.vue";
import KioskTip from "./views/kiosk/Tip.vue";
import Log from "./views/pos/Log.vue";
import Login from "./views/account/Login.vue";
import Loyalty from "./views/LoyaltyCards.vue";
import LoyaltyCards from "./views/loyalty/Cards.vue";
import LoyaltyLevels from "./views/loyalty/Levels";
import Menu from "./views/Menu.vue";
import NewPassword from "./views/account/NewPassword.vue";
import NotFound from "./views/404.vue";
import OrderCompany from "./views/orderConfiguration/OrderCompany.vue";
import OrderLocation from "./views/orderConfiguration/OrderLocation.vue";
import OrderType from "./views/orderConfiguration/OrderType.vue";
import PaymentComment from "./views/payment/PaymentComment.vue";
import PaymentConfirmation from "./views/payment/PaymentConfirmation.vue";
import PaymentContact from "./views/payment/PaymentContact.vue";
import PaymentOptions from "./views/payment/PaymentOptions.vue";
import PosConfiguration from "@/views/pos/Configuration.vue";
import PosCrossSell from "@/views/pos/CrossSell.vue";
import PosDashboard from "@/views/pos/Dashboard.vue";
import PosDispatch from "./views/PosDispatch.vue";
import PosLoading from "@/views/pos/Loading.vue";
import PosOffline from "@/views/pos/Offline.vue";
import PosOnboarding from "@/views/pos/Onboarding.vue";
import PosOrderOverview from "@/views/pos/OrderOverview.vue";
import PosPayment from "@/views/pos/Payment.vue";
import PosPunch from "@/views/pos/Punch.vue";
import PosReading from "@/components/pos/PosReading.vue";
import PosStatusScreen from "@/views/pos/StatusScreen.vue";
import Profile from "./views/account/Profile.vue";
import Promotions from "./views/Promotions.vue";
import Register from "./views/account/Register.vue";
import RegisterConfirmation from "./views/account/RegisterConfirmation.vue";
import Reload from "./views/loyalty/Reload.vue";
import ResetPassword from "./views/account/ResetPassword.vue";
import Router from "vue-router";
import Tickets from "@/views/Tickets.vue";
import TransactionHistory from "./views/loyalty/TransactionHistory.vue";
import Tutorial from "./views/Tutorial.vue";
import Vue from "vue";
import { page } from "vue-analytics";
import qrPaymentConfirmation from "./views/qrPayment/Confirmation.vue";
import qrPaymentError from "./views/qrPayment/Error.vue";
import qrPaymentHome from "./views/qrPayment/Home.vue";
import qrPaymentInvoices from "./views/qrPayment/Invoices.vue";
import qrPaymentMenuDisplay from "./views/qrPayment/MenuDisplay.vue";
import qrPaymentPayment from "./views/qrPayment/Payment.vue";
import qrPaymentScan from "./views/qrPayment/Scan.vue";

Vue.use(Router);

let router = new Router({
  routes: [{
    path: "/",
    name: "root",
    beforeEnter: (to, _, next) => {
      if (CONFIG.kiosk || CONFIG.pos) {
        next(router.generateFirstPage());
      } else if (to.path === "/" && CONFIG.shopLock) {
        next("/" + CONFIG.shopLock);
      } else {
        next("/404");
      }
    }
  }, {
    path: "/:branch/login",
    name: "loginNoCompany",
    component: Login
  }, {
    path: "/:branch/:company/login",
    name: "login",
    component: Login
  }, {
    path: "/:branch/register",
    name: "registerNoCompany",
    component: Register
  }, {
    path: "/:branch/:company/register",
    name: "register",
    component: Register
  }, {
    path: "/:branch/resetPassword",
    name: "resetPasswordNoCompany",
    component: ResetPassword
  }, {
    path: "/:branch/:company/resetPassword",
    name: "resetPassword",
    component: ResetPassword
  }, {
    path: "/:branch/register-confirmation",
    name: "registerConfirmationNoCompany",
    component: RegisterConfirmation
  }, {
    path: "/:branch/:company/register-confirmation",
    name: "registerConfirmation",
    component: RegisterConfirmation
  }, {
    path: "/:branch/newPassword",
    name: "newPasswordNoCompany",
    component: NewPassword
  }, {
    path: "/:branch/:company/newPassword",
    name: "newPassword",
    component: NewPassword
  }, {
    path: "/:branch/:company/menu",
    name: "menu",
    component: Menu
  }, {
    path: "/:branch/:company/tickets",
    name: "tickets",
    component: Tickets
  }, {
    path: "/:branch/menu",
    name: "menuNoCompany",
    component: Menu
  }, {
    path: "/:branch/:company/view-menu",
    name: "view-menu",
    component: Menu
  }, {
    path: "/:branch/:company/menu/:id",
    name: "menuWithId",
    component: Menu
  }, {
    path: "/:branch/:company/menu/promo/:id",
    name: "menuWithPromoId",
    component: Menu
  }, {
    path: "/:branch/:company/itemEdit/:index",
    name: "itemEdit",
    component: Item
  }, {
    path: "/:branch/:company/item/:id",
    name: "item",
    component: Item
  }, {
    path: "/:branch/:company/history",
    name: "history",
    component: History
  }, {
    path: "/:branch/:company/company",
    name: "company",
    component: Company
  }, {
    path: "/:branch/orderType",
    name: "orderTypeNoCompany",
    component: OrderType
  }, {
    path: "/:branch/:company/orderType",
    name: "orderType",
    component: OrderType
  }, {
    path: "/:branch/orderCompany",
    name: "orderCompanyNoCompany",
    component: OrderCompany
  }, {
    path: "/:branch/:company/orderCompany",
    name: "orderCompany",
    component: OrderCompany
  }, {
    path: "/:branch/orderLocation",
    name: "orderLocationNoCompany",
    component: OrderLocation
  }, {
    path: "/:branch/:company/orderLocation",
    name: "orderLocation",
    component: OrderLocation
  }, {
    path: "/:branch/:company/paymentOptions",
    name: "paymentOptions",
    component: PaymentOptions
  }, {
    path: "/:branch/:company/paymentContact",
    name: "paymentContact",
    component: PaymentContact
  }, {
    path: "/:branch/:company/paymentConfirmation",
    name: "paymentConfirmation",
    component: PaymentConfirmation
  }, {
    path: "/:branch/:company/paymentComment",
    name: "paymentComment",
    component: PaymentComment
  }, {
    path: "/:branch/:company/companies",
    name: "companies",
    component: Companies
  }, {
    path: "/404",
    name: "notFound",
    component: NotFound
  }, {
    path: "/:branch/:company/profile",
    name: "profile",
    component: Profile
  }, {
    path: "/:branch/:company/loyalty",
    name: "loyalty",
    component: Loyalty
  }, {
    path: "/:branch/:company/promotions",
    name: "promotions",
    component: Promotions
  }, {
    path: "/:branch/:company/hub",
    name: "hub",
    component: Hub
  }, {
    path: "/:branch/:company/accounts",
    name: "accounts",
    component: Accounts
  }, {
    path: "/:branch/:company/cards",
    name: "loyaltyCards",
    component: LoyaltyCards
  }, {
    path: "/:branch/:company/transactionHistory",
    name: "transactionHistory",
    component: TransactionHistory
  }, {
    path: "/:branch/:company/barcode",
    name: "barcode",
    component: Barcode
  }, {
    path: "/:branch/:company/reload",
    name: "reload",
    component: Reload
  }, {
    path: "/:branch/:company/loyalty-levels",
    name: "loyaltyLevels",
    component: LoyaltyLevels
  }, {
    path: "/:branch/tutorial",
    name: "tutorial",
    component: Tutorial
  }, {
    path: "/:branch/dispatch",
    name: "dispatch",
    component: Dispatch
  }, {
    path: "/:branch/pos/dispatch",
    name: "posDispatch",
    component: PosDispatch
  }, {
    path: "/:branch/:company/pos/dispatch",
    name: "posDispatchCompany",
    component: PosDispatch
  }, {
    path: "/:branch/gift-card/purchase",
    name: "giftCardPurchase",
    component: GiftCardPurchase
  }, {
    path: "/:branch/gift-card/print/:token",
    name: "giftCardPrint",
    component: GiftCardPurchase
  }, {
    path: "/:branch/gift-card/view/:token",
    name: "giftCardView",
    component: GiftCardView
  }, {
    path: "/:branch/:company/tutorial",
    name: "tutorialWithCompany",
    component: Tutorial
  }, {
    path: "/:branch/:company/home",
    name: "home",
    component: Home
  }, {
    path: "/:branch/:company/table",
    name: "qrPaymentHome",
    component: qrPaymentHome
  }, {
    path: "/:branch/:company/table/invoices",
    name: "qrPaymentInvoices",
    component: qrPaymentInvoices
  }, {
    path: "/:branch/:company/table/scan",
    name: "qrPaymentScan",
    component: qrPaymentScan
  }, {
    path: "/:branch/:company/table/payment",
    name: "qrPaymentPayment",
    component: qrPaymentPayment
  }, {
    path: "/:branch/:company/table/menu-display",
    name: "qrPaymentMenuDisplay",
    component: qrPaymentMenuDisplay
  }, {
    path: "/:branch/:company/table/confirmation",
    name: "qrPaymentConfirmation",
    component: qrPaymentConfirmation
  }, {
    path: "/:branch/:company/table/payment-not-completed",
    name: "qrPaymentError",
    component: qrPaymentError
  }, {
    path: "/:branch/:company/table/check-not-available",
    name: "qrPaymentUnavailable",
    component: qrPaymentError
  }, {
    path: "/:branch/:company/table/check-reload",
    name: "qrPaymentCheckReload",
    component: qrPaymentError
  }, {
    path: "/:branch/:company/cookies-policy",
    name: "cookiesPolicy",
    component: CookiesPolicy
  }, {
    path: "/cookies-policy",
    name: "cookiesPolicyNoBranchNoCompany",
    component: CookiesPolicy
  }, {
    path: "/:branch/cookies-policy",
    name: "cookiesPolicyNoCompany",
    component: CookiesPolicy
  }, {
    path: "/:branch/:company/kiosk/standby",
    name: "kioskStandby",
    component: KioskStandby
  }, {
    path: "/kiosk",
    name: "kioskOnly",
    component: KioskConfiguration
  }, {
    path: "/kiosk/configuration",
    name: "kioskConfigurationNoBranch",
    component: KioskConfiguration
  }, {
    path: "/:branch/:company/kiosk/confirmation",
    name: "kioskConfirmation",
    component: KioskConfirmation
  }, {
    path: "/:branch/:company/kiosk/payment",
    name: "kioskPayment",
    component: KioskPayment
  }, {
    path: "/:branch/:company/kiosk/calling-system",
    name: "kioskCallingSystem",
    component: KioskCallingSystem
  }, {
    path: "/:branch/:company/kiosk/admin",
    name: "kioskAdmin",
    component: KioskAdmin
  }, {
    path: "/:branch/:company/kiosk/thank-you",
    name: "kioskThankYou",
    component: KioskThankYou
  }, {
    path: "/:branch/:company/kiosk/sale-warning",
    name: "kioskSaleWarning",
    component: KioskSaleWarning
  }, {
    path: "/:branch/:company/kiosk/configuration",
    name: "kioskConfiguration",
    component: KioskConfiguration
  }, {
    path: "/log",
    name: "log",
    component: Log
  }, {
    path: "/pos/configuration",
    name: "posConfigurationNoBranch", //TODO onboarding
    component: PosConfiguration
  }, {
    path: "/pos/onboarding",
    name: "posOnboarding",
    component: PosOnboarding
  }, {
    path: "/pos/loading",
    name: "posLoading",
    component: PosLoading
  }, {
    path: "/:branch/:company/pos/reading",
    name: "posReading",
    component: PosReading
  },
  {
    path: "/pos/offline",
    name: "posOffline",
    component: PosOffline
  }, {
    path: "/:branch/:company/pos/punch",
    name: "posPunch",
    component: PosPunch
  }, {
    path: "/:branch/:company/pos/loading",
    name: "posLoadingBranchCompany",
    component: PosLoading
  }, {
    path: "/:branch/:company/pos/configuration",
    name: "posConfiguration",
    component: PosConfiguration
  }, {
    path: "/:branch/:company/kiosk/method",
    name: "kioskMethod",
    component: KioskMethod
  }, {
    path: "/:branch/:company/pos/dashboard",
    name: "posDashboard",
    component: PosDashboard
  }, {
    path: "/:branch/:company/pos/payment",
    name: "posPayment",
    component: PosPayment
  }, {
    path: "/:branch/:company/pos/status",
    name: "posStatusScreen",
    component: PosStatusScreen
  }, {
    path: "/:branch/:company/cross-sell/:id",
    name: "crossSell",
    component: PosCrossSell
  }, {
    path: "/:branch/:company/pos/order-overview",
    name: "posOrderOverview",
    component: PosOrderOverview
  }, {
    path: "/:branch/:company/kiosk/tip",
    name: "kioskTip",
    component: KioskTip
  }, {
    path: "/:branch",
    name: "onlybranch",
    redirect: e => {
      return "/" + e.params.branch + "/orderType";
    }
  }]
});

router.beforeEach((to, _, next) => {
  page({
    page: to.path,
    title: to.name,
    location: location.href
  });
  next();
});

export default router;
