<template>
  <Panel class="panel_num_pad" :title="title" :icon="icon">
    <div class="panel_num_pad__content">
      <div class="panel__left">
        <slot/>
      </div>
      <div class="panel__right">
        <div class="keys">
          <div class="keys__numbers">
            <div class="key__row" v-for="(row, rowIndex) of keys" :key="'row-' + rowIndex">
              <div v-wave class="key" v-for="(key, keyIndex) of row" :key="'key' + rowIndex + keyIndex" @click="addKey(key)">{{key}}</div>
            </div>
            <div class="key__row">
              <div v-wave class="key" @click="addKey('00')" @contextmenu="addKey('00')">00</div>
              <div v-wave class="key double-width" @click="addKey('0')" @contextmenu="addKey('0')">0</div>
            </div>
          </div>
          <div class="keys__actions">
            <div v-wave class="key key--action" @click="eraseOne" @contextmenu="eraseOne">
              <svgicon src="icons/erase-big.svg" :height="30" :width="40"></svgicon>
            </div>
            <div v-wave class="key key--action" @click="eraseAll" @contextmenu="eraseAll">
              {{$t('pos.clear')}}
            </div>
            <div v-wave class="key key--action key--primary double-height" @click="confirm" @contextmenu="confirm">
              {{$t('pos.enter')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel_num_pad__footer" v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </Panel>
</template>

<script>
  export default {
    name: "NumPadPanel",

    props: {
      value: String,
      title: String,
      icon: String
    },

    data() {
      return {
        keys: [["7", "8", "9"], ["4", "5", "6"], ["1", "2", "3"]]
      };
    },

    computed: {
      hasFooter() {
        return !!this.$slots.footer;
      }
    },

    methods: {
      addKey(key) {
        this.$emit("update:value", this.value + key);
      },
      eraseOne() {
        if (this.value === "") {
          return;
        }
        this.$emit("update:value", this.value.slice(0, -1));
      },
      eraseAll() {
        this.$emit("update:value", "");
      },
      confirm() {
        this.$emit("onEnter");
      }
    }
  };
</script>

<style lang="scss" scoped>
.panel_num_pad {
  .btn {
    height: 56px;
    font-size: 20px;
    font-weight: 500;
    padding: 20px;
    font-family: inherit;
    .svgicon {
      margin-right: 15px;
    }
  }
}

.keys {
  display: flex;
  padding-left: 40px;

  .keys__numbers {
    border-right: 1px solid var(--background-color-4);
    margin-right: 10px;

    .key {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .key__row {
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  $key-size: 115px;

  .keys__actions {
    .key:not(:last-child) {
      margin-right: 0;
    }
  }

  .key {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 20px;
    height: $key-size;
    width: $key-size;
    color: var(--primary-color);
    fill: var(--primary-color);
    font-size: 32px;
    font-weight: 500;
    background-color: var(--background-color-3);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.key--primary {
      background-color: var(--primary-color);
      color: white;
    }

    &.key--action {
      width: calc(#{$key-size} + #{$key-size} + 4px);
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &.double-height {
      height: calc(#{$key-size} + #{$key-size} + 8px);
    }

    &.double-width {
      width: calc(#{$key-size} + #{$key-size} + 8px);
    }
  }
}
</style>

<style lang="scss">
#iShopFoodApp.pos .panel_num_pad {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 30px;

  &.panel {
    border-radius: 40px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .panel_num_pad__content {
    display: flex;
    align-self: center;
  }

  & > .title {
    padding: 40px 40px 20px 40px;
    font-size: 22px;
    line-height: 1;

    .titleIcon {
      margin-right: 15px;
    }
  }

  & > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    & > .container {
      flex-grow: 1;
      display: flex;
      background-color: transparent;
      padding: 40px;
      flex-direction: column;
    }
  }

  &.panel {
    & > .title {
      padding: 40px 40px 20px 40px;
      font-size: 22px;
      line-height: 1;

      .titleIcon {
        margin-right: 15px;
      }
    }

    & > .content > .container {
      padding: 40px;
    }
  }
  .panel_num_pad__footer {
    margin-top: 40px;
    border-top: 1px dashed var(--background-color-4);
    padding-top: 20px;
    display: flex;
  }
}
</style>