<template>
  <div class="item-variant-selector item-selector">
    <Modifier type="variant" :selector="variant" @click="selectVariant" :item="item" :promo="hasPromotions"></Modifier>
    <PromotionCorner v-if="hasPromotions" :offset="$pos ? 6 : 0"></PromotionCorner>
  </div>
</template>

<script>

  import Modifier from "@/components/menu/Modifier.vue";
  import PromotionCorner from "../pos/PromotionCorner.vue";
  import { PromotionManager } from "../../PromotionManager";

  export default {

    name: "itemVariantSelector",

    components: { Modifier, PromotionCorner },

    props: {
      variant: Object,
      item: Object
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      hasPromotions() {
        return this.table?.promotionVisitor.getPromotionsForItem(this.variant).length > 0;
      }
    },

    methods: {
      selectVariant() {
        if (this.item.selectedVariant === this.variant) {
          return;
        }
        if (this.$kiosk) {
          this.variant.reset(true);
        }
        this.item.selectedVariant = this.variant;
        this.$emit("select");
        PromotionManager.calculate();
      }
    }

  };

</script>

<style lang="scss" scoped>
  .item-variant-selector {
    position: relative;
  }
</style>

<style lang="scss">
  .item-variant-selector > .promotion-corner {
    top: 6px;
    left: 6px;
  }
</style>