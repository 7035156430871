<template>
  <div class="pagination">
    <div class="pagination__left">
      <template v-if="pageCount > 1">
        <Button icon="icons/arrow-left-full.svg" @click="pageDown"></Button>
        <Button v-for="i in pageCount" :key="`pagination-${i}`" :text="i.toString()" :class="{ active: page === i }"
          @click="page = i"></Button>
        <Button icon="icons/arrow-right-full.svg" @click="pageUp"></Button>
      </template>
    </div>
    <div class="pagination__right">
      <Button :text="$t('back')" @click="$emit('back')"></Button>
    </div>
  </div>
</template>

<script>
  import Button from "./Button.vue";

  export default {
    name: "Pagination",
    components: { Button },
    props: {
      pageCount: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        page: 1
      };
    },
    watch: {
      page: function (newPage) {
        this.$emit("page", newPage);
      }
    },
    methods: {
      pageDown() {
        if (this.page > 1) {
          this.page--;
        }
      },
      pageUp() {
        if (this.page < this.pageCount) {
          this.page++;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.pagination {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.button.active {
  background-color: var(--primary-color);
  color: white;
}

.pagination__left {
  flex-grow: 1;
  display: flex;

  .button {
    margin-right: 10px;
  }
}
</style>