<template>
  <Modal id="modalpromotions" ref="modal">
    <ModalHeader :title="$t('promotion.activePromotions')" icon="icons/marketing.svg" @close="hide"></ModalHeader>
    <div class="modalpromotions__container" v-if="hasAnyPromotions">
      <Tabs class="modalpromotions__tabs" :tabs="tabs" :active-tab-index="activeTab"></Tabs>
      <div class="modalpromotions__promotions">
        <Promotion v-for="promo of promotions" :key="`promotion-modal-${promo.id}`" :promotion="promo" image shadow
          @click="goToPromo(promo)" :active="activeTab === TAB_ORDER && promo.applied" :checkmark="activeTab === TAB_ORDER"></Promotion>
        <div class="none" v-if="promotions.length == 0">{{ $t("promotion.no_promotion_available") }}</div>
      </div>
    </div>
    <div class="modalpromotions__none" v-else>{{ $t("promotion.no_promotion_available") }}</div>
    <ModalFooter>
      <ModalFooterPagination @back="hide" :pageCount="1" @page="changePage($event)"></ModalFooterPagination>
    </ModalFooter>
  </Modal>
</template>

<script>
  import Modal from "../modal/Modal.vue";
  import ModalFooter from "../modal/ModalFooter.vue";
  import ModalFooterPagination from "../modal/Pagination.vue";
  import ModalHeader from "../modal/ModalHeader.vue";
  import Promotion from "./Promotion.vue";
  import Tabs from "@/components/tools/Tabs.vue";
  import { defineComponent } from "@vue/runtime-core";

  const TAB_ITEMS = 0;
  const TAB_ORDER = 1;
  const TAB_ACCESS = 2;

  export default defineComponent({
    name: "ModalPromotions",
    components: { Tabs, Modal, ModalHeader, ModalFooterPagination, ModalFooter, Promotion },
    data() {
      return {
        TAB_ITEMS,
        TAB_ORDER,
        activeTab: TAB_ITEMS,
        tabs: [{
          name: "promotion.item_promotions",
          action: () => { this.activeTab = TAB_ITEMS; }
        }, {
          name: "promotion.order_promotions",
          action: () => { this.activeTab = TAB_ORDER; }
        }, {
          name: "promotion.product_access",
          action: () => { this.activeTab = TAB_ACCESS; }
        }]
      };
    },
    computed: {
      table() {
        return this.$store.state.table;
      },
      promotions() {
        if (!this.table) {
          return [];
        }
        if (this.activeTab === TAB_ITEMS) {
          return this.table.promotionVisitor.getItemPromotions();
        }
        if (this.activeTab === TAB_ORDER) {
          return this.table.promotionVisitor.getSalePromotions();
        }
        return this.table.promotionVisitor.getHiddenPromotions();
      },
      hasAnyPromotions() {
        if (!this.table) {
          return false;
        }
        return this.table.promotionVisitor.getSalePromotions().length > 0 ||
          this.table.promotionVisitor.getItemPromotions().length > 0 ||
          this.table.promotionVisitor.getHiddenPromotions().length > 0;
      }
    },
    methods: {
      show() {
        this.$refs.modal.show();
      },
      hide() {
        this.$refs.modal.hide();
      },
      /**
       * @param {Number} page
       */
      changePage(page) {
        // TODO - Load next page
        console.log(page);
      },
      goToPromo(promo) {
        if (this.activeTab === TAB_ITEMS || this.activeTab === TAB_ACCESS) {
          this.hide();
          this.$router.push({ name: "menuWithPromoId", params: { id: promo.id } });
        } else if (this.activeTab === TAB_ORDER) {
          if (promo.applied) {
            this.table.promotionVisitor.unapplyPromotion(promo.id);
          } else {
            this.table.promotionVisitor.applyPromotion(promo.id);
          }
        }
      }
    }
  });
</script>

<style lang="scss" scoped>
  .modalpromotions__promotions {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    margin: -15px;
    padding: 5px;

    & > div {
      margin: 10px;
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .modalpromotions__container {
    display: flex;

    .modalpromotions__tabs {
      padding-right: 20px;
      border-right: 1px dashed var(--border-color);
      margin-right: 20px;
    }

    .none {
      font-size: 20px;
      font-weight: bold;
      padding: 40px 0;
      text-align: center;
      color: #dbdbdb;
    }
  }

  .modalpromotions__none {
    font-size: 18px;
    font-weight: 500;
  }
</style>

<style lang="scss">
  #modalpromotions {
    .newmodal__body {
      max-width: 1020px;
      width: 100%;
    }

    .promotions__promotion {
      max-width: 343px;
    }
  }
</style>