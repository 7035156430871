<template>
  <div class="empty-section" :class="{smaller}">
    <img src="@/assets/img/empty.png" v-if="!isDarkTheme">
    <img src="@/assets/img/empty-dark.png" v-if="isDarkTheme">
    <div class="empty__title" v-html="title" v-if="!hideTitle"></div>
    <div class="empty__text" v-html="text" v-if="!hideText"></div>
  </div>
</template>

<script>
  export default {
    name: "Empty",

    props: {
      text: String,
      title: String,
      hideTitle: Boolean,
      hideText: Boolean,
      smaller: Boolean,
      button: Object
    },

    computed: {
      isDarkTheme() {
        return this.$store.state.posConfiguration && this.$store.state.posConfiguration.darkTheme;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .smaller {
    img {
      max-width: 300px;
    }
  }
  .empty-section {
    align-self: center;
    justify-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .empty__title {
    font-size: 40px;
    font-weight: bold;
    color: var(--theme-color);
    padding-top: 40px;
  }
  .empty__text {
    font-size: 16px;
    color: var(--theme-color-2);
    max-width: 450px;
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
  }
  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
</style>