import { render, staticRenderFns } from "./KioskLocaleSwitch.vue?vue&type=template&id=6ed67206&scoped=true&"
import script from "./KioskLocaleSwitch.vue?vue&type=script&lang=js&"
export * from "./KioskLocaleSwitch.vue?vue&type=script&lang=js&"
import style0 from "./KioskLocaleSwitch.vue?vue&type=style&index=0&id=6ed67206&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed67206",
  null
  
)

export default component.exports