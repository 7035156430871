<template>
    <div class="order-steps" :class="{mobile: $store.getters.isMobile}">
        <ul class="dots">
            <li class="dot" :class="{active: firstPageActive}"></li>
            <li class="dot" :class="{active: secondPageActive}"></li>
            <li class="dot" :class="{active: thirdPageActive}"></li>
        </ul>
    </div>
</template>

<script>
    export default {
      name: "orderSteps",

      computed: {
        firstPageActive() {
          return this.$route.name === 'orderType';
        },
        secondPageActive() {
          return this.$route.name === 'orderLocation';
        },
        thirdPageActive() {
          return this.$route.name === 'orderCompany';
        }
      }
    }
</script>

<style lang="scss" scoped>
    .order-steps {
        display: none;

        &.mobile {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
        }
    }
    .dots {
        display: flex;
        flex-shrink: 0;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .dot {
        width: 10px;
        height: 10px;
        background-color: rgb(217,217,217);
        border-radius: 10px;
        margin: 15px 5px 15px 5px;
        flex-shrink: 0;

        &.active {
            background-color: #fc6650;
        }
    }
</style>