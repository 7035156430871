<template>
  <div id="kioskCallingSystem">

    <div class="calling-system__title" v-html="title || $t('calling_system.default_title')"></div>
    <div class="calling-system__subtitle" v-html="subtitle || $t('calling_system.default_subtitle')"></div>

    <div class="calling-system__holder">
      <div class="content">
        <div class="input">
          <input type="text" v-model="value" readonly>
        </div>
        <div class="numbers">
          <div class="number-row" v-for="(row, rowIndex) of numbers" :key="`r${rowIndex}`">
            <button :id="'btn-' + number" class="btn btn-primary" v-for="number of row" :key="`n${number}`"
                    @click="addNumber(number)">
              {{ number }}
            </button>
          </div>
          <div class="number-row">
            <button id="btn-0" class="btn btn-primary" @click="addNumber(0)">0</button>
            <button id="btn-clear" class="btn btn-primary" @click="removeLastNumber">
              <svgicon src="icons/remove.svg" :height="40" :width="40"></svgicon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="calling-system__actions">
      <button class="btn btn-secondary" @click="back" @contextmenu="back">
        <svgicon src="icons/back-full.svg" :height="20" :width="20"></svgicon>
        {{ $t("back") }}
      </button>
      <button class="btn btn-primary" @click="confirm" @contextmenu="confirm">
        {{ $t("continue") }}
      </button>
    </div>

  </div>
</template>

<script>
  export default {
    name: "KioskCallingSystem",

    data() {
      return {
        value: "",
        numbers: [[7, 8, 9], [4, 5, 6], [1, 2, 3]]
      };
    },

    mounted() {
      if (this.$order.items.length === 0) {
        this.back();
      }
      if (!this.isEnabled) {
        this.confirm();
      }
      if (this.$order.customerReferenceNumber) {
        this.value = this.$order.customerReferenceNumber;
      }
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      isEnabled() {
        return this.kioskConfiguration && this.kioskConfiguration.hasCallingSystemForMethod(this.$order.method);
      },
      subtitle() {
        return this.$t(this.kioskConfiguration.callingSystem.subtitle);
      },
      title() {
        return this.$t(this.kioskConfiguration.callingSystem.title);
      },
      isRequired() {
        return this.kioskConfiguration.callingSystem.required;
      }
    },

    methods: {
      addNumber(number) {
        this.value += number.toString();
      },
      removeLastNumber() {
        this.value = this.value.slice(0, -1);
      },
      back() {
        this.$router.push(this.$router.generate("/kiosk/confirmation"));
      },
      confirm() {
        if (!this.value && this.isRequired) {
          showAffirmation(this.$t("calling_system.mandatory_title"), this.$t("calling_system.mandatory_description"));
          return;
        }
        this.$order.setCustomerReferenceNumber(this.value);
        this.$router.push(this.$router.generate("/kiosk/payment"));
      }
    }

  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #kioskCallingSystem {
  display: flex;
  align-items: center;
  justify-content: center;

  $confirmation-width: 840px;

  .calling-system__title {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    padding: 0 150px 10px 150px;
  }

  .calling-system__subtitle {
    font-size: 24px;
    text-align: center;
    padding: 0 150px 100px 150px;
  }

  .calling-system__holder {
    width: 100%;
    display: flex;
    justify-content: center;

    .content {
      background-color: white;
      padding: 40px;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .calling-system__actions {
    display: flex;
    width: 100%;
    max-width: $confirmation-width;
    justify-content: space-between;
    padding-top: 100px;

    .btn {
      width: 250px;
    }

    .btn-secondary {
      background-color: white;
      fill: var(--primary-color);

      .svgicon {
        margin-right: 15px;
      }
    }

    .btn-primary {
      fill: white;

      .svgicon {
        margin-right: 15px;
      }
    }
  }

  .input input {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    width: 380px;
    padding: 20px;
    border-radius: 12px;
  }

  .number-row {
    display: flex;
    justify-content: center;

    .btn {
      margin: 5px;
      width: 120px;
      font-size: 34px;
      font-family: "Segoe UI Black", sans-serif;
      fill: white !important;
    }

    #btn-0 {
      width: 250px;
    }
  }
}
</style>
