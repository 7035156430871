<template>
  <div id="printInvoiceSelectionModal" class="modal" :class="{show: show}">
    <div class="modal-content" v-if="order">

      <div class="modal-header">
        <svgicon src="icons/print-filled.svg" :height="24" :width="24"></svgicon>
        <div class="header__text">{{ $t("invoice_print.title") }}</div>
      </div>

      <div class="modal__inner">
        <p>{{ $t("invoice_print.description") }}</p>
        <div class="invoices">
          <div v-wave v-for="invoice of order.invoices" class="invoice" :key="'pos-print-invoice' + invoice.number"
               :class="{selected: selectedInvoice === invoice}" @click="selectedInvoice = invoice">
            <svgicon :src="selectedInvoice === invoice ? 'icons/check-circle-full.svg' : 'icons/circle-empty.svg'"
                     :height="24" :width="24"></svgicon>
            <div class="invoice__text">
              <div class="invoice__number">#{{ invoice.number }}</div>
              <div class="separator" v-if="getDate(invoice)"></div>
              <div class="invoice__date">{{ getDate(invoice) }}</div>
            </div>
            <Pill :min-width="140" :icon-height="20" :icon-width="20"
                  :icon-src="getInvoiceType(invoice).icon">{{ getInvoiceType(invoice).title }}
            </Pill>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" v-wave @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{ $t("back") }}
        </button>
        <button class="btn btn-primary" v-wave @click="confirm" :class="{disabled: !selectedInvoice}">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{ $t("confirm") }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
  import { Constant } from "@/util/Constant";
  import EventBus from "@/lib/eventBus";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import Pill from "@/components/tools/Pill";
  import moment from "moment";

  export default {
    name: "printInvoiceSelectionModal",

    components: { Pill },

    data() {
      return {
        order: null,
        selectedInvoice: null,
        show: false
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      }
    },

    mounted() {
      EventBus.$on("print-invoice-selection-modal", (order) => {
        this.order = order;

        if (this.order.invoices.length === 0) {
          return;
        }

        if (this.order.invoices.length === 1) {
          this.print(this.order.invoices[0].number);
          return;
        }

        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("print-invoice-selection-modal");
    },

    methods: {
      back() {
        this.show = false;
        this.selectedInvoice = null;
      },
      confirm() {
        if (!this.selectedInvoice) {
          return;
        }
        this.print(this.selectedInvoice.number);
      },
      getDate(invoice) {
        let date = invoice.paidAt || invoice.voidAt;
        if (!date) {
          return "";
        }
        return moment(date, Constant.API_DATE_FORMAT).format("LLL");
      },
      getInvoiceType(invoice) {
        if (invoice.void) {
          return {
            title: this.$t("invoice_print.void"),
            icon: "icons/void.svg"
          };
        }
        if (invoice.total < 0) {
          return {
            title: this.$t("invoice_print.refund"),
            icon: "icons/refund.svg"
          };
        }
        return {
          title: this.$t("invoice_print.purchase"),
          icon: "icons/invoice.svg"
        };
      },
      async print(invoiceNumber) {
        await IshopPrinter.load({
          sale: this.order.getRawData(),
          company: this.company,
          configuration: this.posConfiguration,
          invoiceNumber: invoiceNumber,
          isSuccess: true,
          duplicata: true
        }).printSale();
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #printInvoiceSelectionModal.modal {
  z-index: 9;

  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 800px;

    .modal__inner {
      margin-bottom: 30px;
    }
  }
  .modal-header {
    .svgicon {
      margin-right: 20px;
      fill: var(--primary-color);
    }

    .header__text {
      font-weight: 900;
      font-size: 22px;
      color: #1a1a1a;
    }
  }

  p {
    margin-bottom: 35px;
    font-size: 20px;
    font-weight: 600;
  }

  .modal-footer {
    padding-top: 20px;
    border-top: 1px dashed #e6e6e6;
    margin-top: 20px;

    .btn {
      font-size: 20px;
      font-weight: 500;
      padding: 15px 25px;
      width: auto;
      line-height: 1;
      font-family: 'Roboto', sans-serif;

      .svgicon {
        margin-right: 15px;
      }
    }
  }
}

.invoice {
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 20px;
  border: 2px solid var(--border-color-2);
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .svgicon {
    fill: var(--primary-color);
    margin-right: 16px;
  }

  .invoice__text {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .invoice__date {
    color: var(--theme-color-2);
    font-size: 18px;
    font-weight: normal;
  }

  .separator {
    height: 18px;
    background-color: var(--border-color-2);
    width: 2px;
    margin: 0 15px;
  }
}

</style>