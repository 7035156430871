<template>
  <div id="posMethodDropdown">
    <select class="method-dropdown" v-model="method">
      <!--<option value="inPlace">{{$t('pos_method.inPlace')}}</option>-->
      <option value="takeout">{{$t('pos_method.takeout')}}</option>
      <option value="dispatch">{{$t('pos_method.phone')}}</option>
    </select>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import moment from "moment-timezone";

  export default {
    name: "posMethodDropdown",

    data() {
      return {
        method: "",
        returnToDispatchModal: false
      };
    },

    mounted() {
      this.method = this.isDispatch ? "dispatch" : this.table.method;
      EventBus.$on("change-pos-dropdown-method", (method) => {
        this.returnToDispatchModal = true;
        this.$store.state.table.setMethod(method);
        this.method = method;
      });
    },

    beforeDestroy() {
      EventBus.$off("change-pos-dropdown-method");
    },

    watch: {
      "table.method"(newMethod) {
        if (newMethod === this.method) {
          return;
        }
        this.method = this.isDispatch ? "dispatch" : newMethod;
      },
      async "method"(newMethod) {
        if ((!this.isDispatch && (newMethod === this.table.method)) || (this.isDispatch && newMethod === "dispatch")) {
          this.returnToDispatchModal = false;
          return;
        }
        if (this.isDispatch && newMethod !== "dispatch") {
          //Leaving dispatch
          let confirm = await showConfirmation(this.$t("warning"), this.$t("dispatch.leave_warning"), this.$t("continue"), this.$t("cancel"));
          if (!confirm) {
            this.method = "dispatch";
            if (this.returnToDispatchModal) {
              this.returnToDispatchModal = false;
              EventBus.$emit("show-dispatch-modal");
            }
            return;
          }
          this.leaveDispatch();
          this.$store.state.table.setMethod(newMethod);
        } else if (!this.isDispatch && newMethod === "dispatch") {
          //Initialize dispatch
          this.$store.state.table.isDispatch = true;
          this.$user.setAsDispatchForPOS();
          EventBus.$emit("show-dispatch-modal");
        } else {
          //Regular method switch
          this.$store.state.table.setMethod(newMethod);
          EventBus.$emit("select-first-category");
        }
      }
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      isDispatch() {
        return this.table && this.table.isDispatch;
      }
    },

    methods: {
      leaveDispatch() {
        this.$store.state.table.isDispatch = false;
        this.$store.state.table.hour = null;
        this.$store.state.table.date = moment();
        this.$user.clearDispatchForPOS();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posMethodDropdown {
    position: relative;
    &:not(:first-child) {
      margin-left: 20px;
    }
    .method-dropdown {
      padding: 10px 60px 10px 20px;
      height: 56px;
      border-radius: 12px;
      background-image: none;
    }
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: var(--primary-color);
      clip-path: polygon(11% 19%, 0 30%, 50% 82%, 100% 30%, 89% 19%, 50% 60%);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;

    }
  }
</style>