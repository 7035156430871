import { Constant } from "@/util/Constant";
import { IshopPrinterExternalSale } from "./models/IshopPrinterExternalSale";
import { IshopPrinterPosSale } from "./models/IshopPrinterPosSale";
import PrintFormatter from "@/lib/printer/printFormatter";

class IshopPrinter {

    constructor() {
        this._printer = null;
        this._company = /** @type {Company} */ null;
        this._isSuccess = /** @type {Boolean} */ true;
        this._duplicata = /** @type {Boolean} */ false;
        this._configuration = /** @type {PosConfiguration|KioskConfiguration} */ null;
        this._sale = /** @type {Sale} */ null;
        this._invoiceNumber = null;
        this._printerCols = 48;
        this.loaded = false;
    }

    /**
     * @param {{sale: Object, duplicata: boolean?, isSuccess: boolean?, configuration: PosConfiguration|KioskConfiguration, company: Company?}} options
     * @returns {IshopPrinter}
     */
    load(options) {
        if (!options.sale) {
            console.log("no sale", options);
            resolve();
        }
        let printerName = options.configuration ? options.configuration.getMainPrinterName() : null;
        this._company = options.company;
        this._isSuccess = options.isSuccess;
        this._duplicata = typeof options.duplicata === "boolean" ? options.duplicata : false;
        this._configuration = options.configuration;
        this._invoiceNumber = options.invoiceNumber;
        this._printerCols = options.configuration.getPrinterCols();
        this._printer = new PrintFormatter({
            printerName: printerName,
            xposUrl: options.configuration.getXposUrl()
        });
        this._rawSale = options.sale;
        this._sale = _isPOSSale(options.sale) ? new IshopPrinterPosSale(this, options.sale) : new IshopPrinterExternalSale(this, options.sale);

        this.loaded = true;

        return this;
    }

    /**
     * @param {boolean?} newDetailsOnly
     * @returns {Promise}
     */
    async printDetailsToKitchen(newDetailsOnly = false) {
        return new Promise(async (resolve, reject) => {
            if (!this.loaded) {
                reject();
            }
            let saleDetailsByPrinterName = this._sale.getSaleDetailsByPrinterName(this._configuration, newDetailsOnly);
            for (let printerName of Object.keys(saleDetailsByPrinterName)) {
                this._printer = new PrintFormatter({
                    printerName: printerName,
                    xposUrl: this._configuration.getXposUrl()
                });
                this._sale.setPrinter(this._printer);
                this._sale.printKitchenHeader();
                this._sale.printKitchenDetails(saleDetailsByPrinterName[printerName]);
                await this._printer.print();
            }

            this.loaded = false;
            resolve();
        });
    }

    /**
     * @returns {Promise}
     */
    async printSale(sendToPrinter = true) {
        return new Promise(async (resolve, reject) => {
            if (!this.loaded) {
                reject();
            }
            if (this._sale.getAppName() === Constant.POS_APP_NAME) {
                if (this._invoiceNumber) {
                    let invoice = this._sale.getInvoiceByNumber(this._invoiceNumber);
                    if (invoice) {
                        await this._sale.print(invoice, this, sendToPrinter);
                    } else {
                        console.log("invoice not found with id", this._invoiceNumber);
                    }
                } else {
                    for (let invoice of this._sale.getInvoices()) {
                        await this._sale.print(invoice, this, sendToPrinter);
                    }
                }
            } else { //TODO external sale invoice specific print for refunds
                await this._sale.print(null, this);
            }

            if (sendToPrinter) {
                this.loaded = false;
            }

            resolve();
        });
    }

    getReceipt() {
        return this._sale.printer._str;
    }

    getRaw() {
        return this._sale.printer._raw;
    }

    getSale() {
        return this._sale;
    }

}

/**
 * @param sale
 * @return {boolean}
 * @private
 */
const _isPOSSale = function (sale) {
    return sale.appName === Constant.POS_APP_NAME;
};

let ishopPrinter = new IshopPrinter();

export { ishopPrinter as IshopPrinter };