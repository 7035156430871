<template>
  <div class="progress inner" :class="{desktop: isDesktop}">

    <Illustration class="illustration" custom-slot for-hub large>
      <vue-easy-pie-chart
        :percent="percent"
        scale-color=""
        :bar-color="primaryColor"
        :line-width="15"
        :size="195"
      ></vue-easy-pie-chart>
      <div class="content">
        <div class="account-amount">
          <div class="title">{{$t('loyalty.my_balance')}}</div>
          <div class="balance">
            <div class="amount">{{ Math.floor($user.loyalty.getBalance()) }}</div>
            <div class="cents">{{ leftPad(Math.round($user.loyalty.getBalance() % 1 * 100), 2, "0") }}<span class="dollar">$</span></div>
          </div>
        </div>

          <div class="hr"></div>
          <div class="rank-status">
            <div class="title">{{nextUserLevel && userLevel && nextUserLevel === userLevel ? $t('loyalty.current_rank') : $t('loyalty.next_rank')}}</div>
            <div class="rank">
              <div class="current">{{points}}</div>
              <div class="slash" v-if="nextUserLevel && userLevel && nextUserLevel !== userLevel">/</div>
              <div class="next-level" v-if="nextUserLevel && userLevel && nextUserLevel !== userLevel">{{nextUserLevel.points}}</div>
            </div>
          </div>
      </div>
    </Illustration>

    <div class="buttons" v-if="!isDesktop">
      <router-link :to="{name: 'menu'}" class="btn btn-primary">
        {{ $t("payment.online_order") }}
      </router-link>
      <router-link :to="{name: 'loyaltyLevels'}" class="btn btn-primary">
        {{ $t("loyalty.view_levels") }}
      </router-link>
      <router-link :to="{name: 'promotions'}" class="btn btn-primary" v-if="PromotionManager.hasPromotion()">
        {{ $t("navigation.promos") }}
      </router-link>
      <router-link :to="{name: 'reload'}" class="btn btn-primary" v-if="isRechargeEnabled && userCanManage">
        {{ $t("loyalty.reload") }}
      </router-link>
      <router-link :to="{name: 'accounts'}" class="btn btn-primary" v-if="isUserManagementEnabled && userCanManage">
        {{ $t("loyalty.management") }}
      </router-link>
      <router-link :to="{name: 'profile'}" class="btn btn-primary">
        {{ $t("navigation.profile") }}
      </router-link>
    </div>

    <Levels v-if="isDesktop"></Levels>

  </div>
</template>

<script>
  import Illustration from "../modal/Illustration.vue";
  import Levels from "../loyalty/Levels.vue";
  import { PromotionManager } from "../../PromotionManager";
  import VueEasyPieChart from 'vue-easy-pie-chart';
  import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'

  export default {

    name: "progressHub",

    components: { Illustration, VueEasyPieChart, Levels },

    props: {
      primaryColor: String,
      gradientColor: String,
      isDesktop: Boolean,
      isRechargeEnabled: Boolean,
      userCanManage: Boolean,
      isUserManagementEnabled: Boolean,
      leftPad: Function
    },

    data() {
      return {
        PromotionManager
      }
    },

    computed: {
      points() {
        if (!this.$user.loyalty) {
          return 0;
        }
        return this.$user.loyalty.getTotalPoints();
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      userLevel() {
        if (!this.branch) {
          return;
        }
        return this.branch.getLevelForPoints(this.points);
      },
      nextUserLevel() {
        if (!this.branch) {
          return;
        }
        return this.branch.getNextLevelForPoints(this.points);
      },
      percent() {
        if (!this.$user.loyalty || !this.nextUserLevel) {
          return 0;
        }
        let nextLevel = this.nextUserLevel.points;
        let value = Math.round((this.$user.loyalty.getTotalPoints() / nextLevel) * 100);
        if (value > 100) {
          return 100;
        }
        return value;
      },
    }

  }
</script>

<style lang="scss" scoped>
  .progress {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.desktop {
      flex-direction: row;
      flex-grow: initial;
      background-color: #f7f7f7;
      border-radius: 20px;
      min-height: 320px;
      box-shadow: 0px 2px 5px 0 rgba(2, 3, 2, 0.25);
      padding: 40px 40px 40px 70px;
      width: 100%;
      max-width: 750px;

      .buttons, .levels-container {
        margin-left: 70px;
        border-left: 1px solid #dfdfdf;
        padding-left: 40px;
      }
      .content {
        top: 35px;
      }
    }
  }

  .content {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .account-amount {
    .title {
      text-transform: uppercase;
      text-align: center;
      font-size: 10px;
      color: rgb(191,191,191);
    }
    .balance {
      font-family: 'Segoe UI Black', sans-serif;
      color: #ed2937;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .amount {
        font-size: 56px;
        line-height: 1;
      }
      .cents {
        font-size: 30px;
        line-height: 1;
        margin-top: 4px;
        margin-left: 2px;
      }
      .dollar {
        color: #fc6650;
      }
    }
  }
  .hr {
    width: 120px;
    height: 1px;
    background-color: rgb(230,230,230);
    margin: 4px 0 8px 0;
  }
  .rank-status {
    .title {
      text-transform: uppercase;
      text-align: center;
      font-size: 10px;
      color: rgb(191,191,191);
    }
    .rank {
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      font-family: 'Segoe UI Black', sans-serif;
      font-size: 16px;

      .current {
        margin-right: 3px;
        color: #ed2937;
      }
      .slash {
        margin-right: 3px;
        color: rgb(191,191,191);
      }
      .next-level {
        color: #fc6650;
      }
    }
  }

  #iShopFoodApp .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    .btn {
      margin-bottom: 20px;
      padding: 10px 20px;
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp .vue-easy-pie-chart {
    margin-top: 4px;

    .inner-text {
      display: none;
    }
  }
</style>