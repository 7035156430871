<template>
  <div class="fileUploader">
    <img :src="imagePath">
    <input type="file" class="txtFile" :id="'txtFile-' + uniqueKey" @change="loadImage($event)">
    <label :for="'txtFile-' + uniqueKey" v-if="!imageData && !image" style="font-size: 40px !important" class="add">+</label>
    <div class="remove" v-if="imageData || image" @click="remove" style="padding: 0 !important">+</div>
  </div>
</template>

<script>

  export default {

    name: "fileUploader",

    props: {
      uniqueKey: String,
      image: String
    },

    data() {
      return {
        imageData: null
      };
    },

    computed: {
      imagePath() {
        if (this.imageData) {
          return this.imageData;
        }
        if (this.image) {
          return this.$store.state.urlFileServer + this.image;
        }
        return null;
      }
    },

    methods: {
      loadImage(e) {
        let input = e.target;
        if (input.files && input.files.length == 1) {
          let reader = new FileReader();
          let split = input.files[0].name.split(".");
          let extension = split[split.length - 1];
          showSpinner();
          reader.onload = async (data) => {
            this.imageData = await this.compressImage(data.target.result, input.files[0]);
            hideSpinner();
            this.$emit("upload", {
              name: input.files[0].name,
              type: input.files[0].type,
              extension: extension,
              data: this.imageData
            });
          };
          reader.readAsDataURL(input.files[0]);
        }
      },
      remove() {
        this.imageData = null;
        this.$emit("upload", null);
        if (document.getElementById("txtFile-" + this.uniqueKey)) {
          document.getElementById("txtFile-" + this.uniqueKey).value = "";
        }
      },
      async compressImage(buffer, original) {
        let log = true;
        return new Promise((resolve, reject) => {
          let length = buffer.length / 1000 / 1000;
          let mebiLength = buffer.length / 1024 / 1024;
          if (length >= 2) {
            if (log) {
              console.log("Compressing image of size " + mebiLength.toFixed(2) + "Mb");
            }
            let image = new Image();
            image.src = buffer;
            image.onload = async () => {
              if (log) {
                console.log("Image loaded");
              }
              let targetMultiplier = 0.98;
              let originalLength = original.size / 1000 / 1000;
              let targetWidth;
              let targetHeight;
              if (originalLength > 10) {
                targetWidth = Math.floor(image.width * Math.pow(2 / originalLength, 1 / 2));
                targetHeight = Math.floor(image.height * Math.pow(2 / originalLength, 1 / 2));
              } else {
                targetWidth = image.width;
                targetHeight = image.height;
              }
              let targetQuality = 0.8;
              let canvas = document.createElement("canvas");
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, targetWidth, targetHeight);
              if (log) {
                console.log("Image drawn");
              }
              let canvasToBlob = /** @returns {Promise<Blob>} */ () => {
                return new Promise(blobResolve => {
                  canvas.toBlob(blob => {
                    let newLength = blob.size / 1000 / 1000;
                    let newMebiLength = blob.size / 1024 / 1024;
                    if (log) {
                      console.log("Compressed image is now " + newMebiLength.toFixed(2) + "Mb");
                    }
                    blobResolve(blob);
                  }, "image/jpeg", targetQuality);
                })
              }
              while (true) {
                let result = await canvasToBlob();
                let resultLength = result.size / 1000 / 1000;
                if (resultLength < 2) {
                  let reader = new FileReader();
                  reader.onload = function() {
                    resolve(reader.result);
                  }
                  reader.readAsDataURL(result);
                  break;
                }
                if (log) {
                  console.log("Image still too big, retrying");
                }
                targetWidth = Math.floor(targetWidth * Math.min(Math.pow(2 / resultLength, 1 / 2), targetMultiplier));
                targetHeight = Math.floor(targetHeight * Math.min(Math.pow(2 / resultLength, 1 / 2), targetMultiplier));
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
              }
              // resolve(buffer);
            }
          } else {
            resolve(buffer);
          }
        });
      }
    }

  }

</script>

<style lang="scss" scoped>

  .fileUploader {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 140px;
  }

  .title {
    font-size: 10px;
    color: #d5dde0;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  .txtFile {
    display: none;
    & + label, & + .remove {
      width: 40px;
      height: 40px;
      background-color: #bfc5c8;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white !important;
      cursor: pointer;
      font-size: 40px;
      line-height: 1;
      padding-bottom: 4px;
    }
  }

  img {
    align-self: center;
    height: 120px;
    max-width:100%;
    max-height:100%;
  }

  .remove {
    padding: 0;
    transform: rotateZ(45deg);
  }

  .add {
    padding: 0 !important;
    text-align: center;
  }

</style>
