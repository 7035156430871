<template>
  <div class="pos-cart-item" style="opacity: 0;" :class="{void: isVoid, noActions: noActions, selected: isSelected && !selectedModifier}"
       @click="onClick">

    <AsyncImage class="cart-item__image" :fallback="$store.state.defaultItemImage" :image="image" v-if="!noImage"></AsyncImage>

    <div class="cart-item__content" :class="{promoPadding: hasPromotion}">
      <div class="name_state">
        <div class="name">
          <div class="cart-item__remark-icon" v-if="isOpenRemark && !noActions">
            <svgicon src="icons/remark.svg" :height="24" :width="21"></svgicon>
          </div>
          {{ detail.name }}
        </div>
        <div class="state">
          <pill :text="$t('item.new')" :iconHeight="21" :iconWidth="21" v-if="isNew && !isVoid"></pill>
          <pill :text="$t('void.void')" iconSrc="icons/void.svg" :iconHeight="21" :iconWidth="21" v-if="isVoid"></pill>
          <pill :text="$t('refund_item.refund')" iconSrc="icons/refund.svg" :iconHeight="21" :iconWidth="21" v-if="isRefund"></pill>
        </div>
      </div>
      <template v-if="!detail.isOpenRemark()">
        <div class="modifiers">
          <div class="modifier" v-for="(detail, detailIndex) of subDetails" :key="'modifier-' + uuid + detailIndex">
            <div class="modifier__name">
              {{ detail.name }}
              <template v-if="detail.quantity > 1">(x{{ detail.quantity }})</template>
            </div>
            <div class="modifier__price" v-if="detail.getPrice() > 0">
              + {{ $tc(detail.getPrice()) }}
            </div>
          </div>
          <div class="promotion" v-for="promotion of detail.appliedPromotions" :key="`promo-${uuid}-${promotion.id}`">
            {{ $to(promotion.name) }} ({{ `${$t("discount")} -${formatPromotion(promotion)}` }})
          </div>
        </div>
        <div class="total">
          <div class="total__quantity">
            <div class="quantity__label">{{ $t("quantity") }} :</div>
            <div class="quantity__amount">{{ detail.quantity }}</div>
            <div class="quantity__split" v-if="detail.isSplit()">/ {{ detail.getSplitQuantity() }}</div>
          </div>
          <div class="total__price">
            <div class="striked" v-if="hasPromotion">{{ $tc(detail.getUnitPrice() * detail.quantity) }}</div>
            {{ $tc(detail.getTotalPrice()) }}
          </div>
        </div>
        <div class="remarks" v-if="subRemarks && subRemarks.length > 0 ">
          <div class="remark" v-for="(detail, detailIndex) of subRemarks" :key="'remark-' + uuid + detailIndex"
               :class="{selected: selectedModifier && selectedModifier.getId() == detail.getId()}"
               @click.stop="onClick($event, detail)">
            <div class="remark__icon" v-if="detail.isOpenRemark() && !noActions">
              <svgicon src="icons/remark.svg" :height="24" :width="21"></svgicon>
            </div>
            <div class="remark__name">
              {{ detail.name }}
            </div>
          </div>
        </div>
      </template>

    </div>

    <PromotionCorner v-if="hasPromotion && !detail.isOpenRemark()" :offset="-5"></PromotionCorner>

  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage";
  import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
  import Detail from "@/models/pos/DetailModel";
  import EventBus from "@/lib/eventBus";
  import Pill from "@/components/tools/Pill.vue";
  import PromotionCorner from "./PromotionCorner.vue";
  import { Util } from "@/util/Util";
  import { Constant } from "@/util/Constant";

  export default {
    name: "posCartItem",

    components: { AsyncImage, Pill, PromotionCorner },

    props: {
      detail: Detail,
      selectedDetail: Object,
      noActions: Boolean
    },

    data() {
      return {
        uuid: null,
        item: null,
        selectedModifier: null
      };
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      subDetails() {
        return (this.detail.details || []).filter(d => (!d.isOpenRemark() || this.noActions));
      },
      subRemarks() {
        if (this.noActions) {
          return [];
        }
        return (this.detail.details || []).filter(d => d.isOpenRemark());
      },
      table() {
        return this.$store.state.table;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      quantityBuffer() {
        return this.$store.state.quantityBuffer;
      },
      isSelected() {
        return this.selectedDetail === this;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      image() {
        if (this.item && this.item.image) {
          return this.$store.state.urlFileServer + this.item.image;
        }
        return null;
      },
      isVoid() {
        return this.detail.isVoid();
      },
      isNew() {
        return this.detail.isNew();
      },
      isRefund() {
        return this.detail.isRefund();
      },
      isSplit() {
        return this.detail.isSplit();
      },
      canShowVoidDetail() {
        let routeName = this.$route.name;
        let detailId = this.detail.id.toString();
        let queryDetailId = this.$route.params.index;
        return routeName !== "itemEdit" || (routeName === "itemEdit" && queryDetailId !== detailId);
      },
      hasCrossSell() {
        return this.item && this.item.hasCrossSell();
      },
      hasPromotion() {
        return this.detail.appliedPromotions.length > 0;
      },
      formatPromotion() {
        return promotion => {
          return `${promotion.value}${promotion.type === "percent" ? "%" : "$"}`;
        };
      },
      itemHasKitchenIngredientsOrAllergens() {
        if (!this.item) {
          return false;
        }
        return !!this.$to(this.item.getKitchenIngredients()) || !!this.$to(this.item.getKitchenAllergens());
      },
      itemHasKitchenRecipe() {
        if (!this.item || !this.item.getKitchenRecipe) {
          return false;
        }
        return this.$to(this.item.getKitchenRecipe());
      },
      company() {
        return this.$store.state.currentCompany;
      },
      isOpenRemark() {
        return this.detail && this.detail.isOpenRemark();
      },
      hasSubSelection() {
        return !!this.selectedModifier;
      }
    },

    mounted() {
      this.uuid = window.generateUUID();
      this.item = this.detail.getInventoryItem() || this.inventory.search(this.detail.itemId);
      this.$nextTick(() => {
        this.$el.style.opacity = "1";
        this.$el.style.transform = "translateX(0)";
      });
    },

    methods: {
      holdDetail() {
        EventBus.$emit("hold-detail", this.detail);
      },
      voidDetail() {
        if (this.detail.isNew()) {
          if (this.selectedModifier) {
            this.table.removeDetailAndRelatives(this.selectedModifier);
          } else {
            this.table.removeDetailAndRelatives(this.detail);
          }
          this.clearModifierSelection();
        } else {
          EventBus.$emit("void-detail", this.detail);
        }
        if (this.table.details.length > 0) {
          CustomerScreenLoader.switchToMenuComplete(this.table, this.company.getPosOrderingBannerUrl());
        } else {
          CustomerScreenLoader.switchToStandby(this.company.getPosStandbyImageUrl());
        }
      },
      editDetail() {
        if (!this.detail.inventoryItem) {
          return;
        }
        this.$router.push(this.$router.generate("/itemEdit/" + this.detail.id));
      },
      refund() {
        EventBus.$emit("pos-item-refund-modal", this.detail);
      },
      goToCrossSell() {
        if (this.hasCrossSell) {
          this.$router.push(this.$router.generate("/cross-sell/" + this.item.id));
        }
      },
      onClick(event, targetModifier) {
        if (this.noActions) {
          return;
        }
        let quantity = parseInt(this.quantityBuffer || 0);
        if (quantity > 0) {
          if (!this.isNew) {
            let detail = this.table.getDetail(this.detail.id);
            detail.setQuantity(quantity);
            this.$store.state.quantityBuffer = null;
          }

        } else if (!this.detail.isVoid()) {
          if (targetModifier && targetModifier.isOpenRemark()) {
            if (this.isSelected) {
              if (!this.selectedModifier || targetModifier.getId() !== this.selectedModifier.getId()) {
                this.selectedModifier = targetModifier;
                return;
              }
            }
            this.selectedModifier = targetModifier;
          } else if (this.isSelected && this.selectedModifier) {
            this.selectedModifier = null;
            return;
          }
          this.$emit("click", this);
        }
      },
      async changePrice() {
        let calculatorResponse = await Util.Modal.calculator({
          title: this.$t("pos_actions.change_unit_price_title"),
          isFloat: true,
          allowNegativeValues: false
        });

        if (calculatorResponse.target !== "enter") {
          return;
        }

        let detail = this.table.getDetail(this.detail.id);
        if (detail) {
          detail.overwriteUnitPrice(calculatorResponse.value);
        }
      },
      async changeDescription() {
        let keyboardResponse = await Util.Modal.keyboard(
          this.$t("pos_actions.change_description_title"),
          this.selectedDetail.name
        );

        if (keyboardResponse.target !== "enter") {
          return;
        }

        let detail = this.table.getDetail(this.detail.id);
        if (detail) {
          detail.overwriteName(keyboardResponse.value);
        }
      },
      setSeat(seat) {
        let detail = this.table.getGroupedDetail(this.detail.id);
        if (detail) {
          detail.seat = seat;
          detail.details.forEach(d => {
            d.seat = seat;
          });
        }
      },
      getBaseDetail() {
        return this.detail;
      },
      getDetail() {
        return this.table.getDetail(this.detail.id);
      },
      showIngredientModal() {
        if (!this.item) {
          return;
        }

        let modalContent = [
          {title: "ingredients", body: this.item.getKitchenIngredients()},
          {title: "contains", body: this.item.getKitchenAllergens()}
        ];

        let modalParams = {
          title: "ingredients",
          icon: "icons/pos-ingredients.svg",
          texts: modalContent
        };
        EventBus.$emit("show-text-display-modal", modalParams);
      },
      showRecipeModal() {
        if (!this.item) {
          return;
        }
        let modalContent = [{title: "", body: this.item.getKitchenRecipe()}];
        let modalParams = {
          title: "recipe",
          icon: "icons/pos-recipe.svg",
          texts: modalContent
        };
        EventBus.$emit("show-text-display-modal", modalParams);
      },
      async addOrEditOpenRemark() {
        if (this.isOpenRemark) { // if current cart item is an open remark, we edit it
          this.editOpenRemark(this.detail);
          return;
        }

        if (this.selectedModifier) { // if we have a modifier selection, we edit it
          this.editOpenRemark(this.selectedModifier);
          return;
        }

        //Otherwise we add a new one as a modifier
        let remark = await this.showOpenRemarkModal();
        if (remark) {
          this.table.addOpenRemark(remark, this.detail.seat, this.detail.id);
        }
      },
      async editOpenRemark(remarkDetail) {
        if (!remarkDetail) {
          return;
        }

        let detail = this.table.getDetail(remarkDetail.getId());
        if (detail) {
          let remark = await this.showOpenRemarkModal(detail.getName());
          if (remark) {
            detail.setRemark(remark);
          }
        }
      },
      async showOpenRemarkModal(placeholder) {
        let keyboardResponse = await Util.Modal.keyboard(
          this.$t("pos_actions.open_remark_title"),
          placeholder,
          Constant.MAX_NUM_CHAR_FOR_RECEIPT
        );

        if (keyboardResponse.target !== "enter") {
          return;
        }

        return keyboardResponse.value?.trim();
      },
      clearModifierSelection() {
        this.selectedModifier = null;
      },
      splitOrUnsplit() {
        if (this.isSplit) {
          this.table.unsplitDetail(this.detail);
          return;
        }
        EventBus.$emit("show-split-item", this.$store.state.currentSeat, this.detail);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .pos-cart-item {
    background-color: var(--background-color-2);
    border-radius: 22px;
    padding: 5px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    transition: opacity,transform 350ms ease-out;
    border: 5px solid var(--background-color-2);

    &.selected {
      position: relative;
      border: 5px solid var(--primary-color);
    }

    .cart-item__image {
      height: 100px;
      width: 100px;
      border-radius: 16px;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;
    }

    .cart-item__remark-icon {
      fill: var(--primary-color);
      padding-right: 18px;
    }

    .name {
      display: flex;
    }

    &.void {
      .cart-item__image {
        filter: grayscale(1);
        opacity: 0.3;
      }
      .name_state .name, .modifiers, .total {
        opacity: 0.3;
      }
    }

    .cart-item__content {
      padding: 12px 12px 12px 20px;
      flex-grow: 1;

      &:first-child {
        padding-left: 10px;
      }

      &.promoPadding {
        padding-left: 30px;
      }

      .name_state {
        font-weight: 900;
        color: var(--theme-color);
        font-size: 21px;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px
      }
    }

    .modifiers {
      margin-top: 15px;

      .modifier {
        display: flex;
        justify-content: space-between;
        color: #808080;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .promotion {
      font-weight: 600;
      color: var(--primary-color);
    }

    .total {
      border-top: 1px solid var(--border-color-4);
      margin-top: 10px;
      padding-top: 10px;
      display: flex;
    }

    .total__quantity {
      display: flex;
      align-items: flex-end;

      .quantity__label {
        color: var(--theme-color-2);
        font-size: 18px;
        font-weight: 500;
        margin-right: 4px;
      }
      .quantity__amount, .quantity__split {
        color: var(--theme-color);
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
      .quantity__split {
        font-weight: 500;
        color: var(--theme-color-2);
      }
    }

    .total__price {
      line-height: 1;
      font-size: 24px;
      font-weight: 900;
      color: var(--theme-color);
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      .striked {
        margin-right: 16px;
        position: relative;
        &:after {
          content: "";
          width: calc(100% + 16px);
          height: 3px;
          top: calc(50% - 2px);
          left: -8px;
          transform: rotateZ(-15deg);
          position: absolute;
          border-radius: 1px;
          background-color: var(--primary-color);
        }
      }
    }

    .btn.action {
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    .cart-item__actions {
      position: relative;
    }

    .remarks {
      margin-top: 10px;
      border-top: 2px dashed var(--border-color-4);

      .remark {
        display: flex;
        position: relative;
        height: 56px;
        box-shadow: 0px 1px 3px #0000001A;
        border-radius: 12px;
        color: #808080;
        font-weight: 500;
        font-size: 18px;
        align-items: center;
        padding: 16px 18px;
        margin-top: 10px;

        .remark__icon {
          fill: var(--primary-color);
          padding-right: 18px;
        }
        &.selected:after {
          content: "";
          position: absolute;
          border: 5px solid var(--primary-color);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 10px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .cart-item__context {
    position: fixed;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 15px 0;
    z-index: 9999;

    .context__arrow {
      position: absolute;
      top: -7px;
      right: 20px;
      background-color: white;
      width: 14px;
      height: 14px;
      transform: rotateZ(45deg);
      border-bottom-right-radius: 100%;
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.07);
    }
    .context__item {
      padding: 15px 30px;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
</style>
