<template>
  <div id="paymentOptions">

    <PageTitle v-if="!$kiosk">
      <svgicon class="icon" src="icons/facturation.svg" :height="24" :width="24"></svgicon>
      <template v-if="!$store.getters.isMobileOrTablet">{{$t('payment.submit_order')}}</template>
      <template v-if="$store.getters.isMobileOrTablet">{{$t('payment.finish_order')}}</template>
    </PageTitle>

    <div class="kiosk-title" v-if="$kiosk">
      {{$t('kiosk.complete_order')}}
    </div>

    <div class="page-content">

      <div class="card" v-if="!$store.getters.isMobileOrTablet && !$kiosk">
       <div class="container">
          <div class="title">{{$t('payment.finish_order')}}</div>
          <div class="text">{{$t('payment.complete_order')}}</div>
       </div>
       <Steps></Steps>
      </div>

      <Steps v-if="$store.getters.isMobileOrTablet"></Steps>

      <Panel :title="$t('payment.supplements')" icon="icons/ustensils.svg" :collapsible="!$kiosk">
        <div class="options">

          <div class="option" v-for="(supplement, supplementIndex) of supplements" :key="supplementIndex" :class="{full: supplement.getChoices().length > 0}">
            <div class="holder" v-if="supplement.getChoices().length === 0">
              <div class="left">
                <div class="option-name">{{$to(supplement.name)}}</div>
                <div class="option-description">{{$to(supplement.description)}}</div>
                <div class="option-price"  v-if="supplement.getPrice() > 0">
                   + {{$tc(((supplement.quantity || 1) * supplement.getPrice()).toFixed(2))}}
                </div>
              </div>
              <QuantitySelector :value="supplement.quantity" @changed="supplement.quantity = $event" :min="0" :large="$kiosk"
                :checkbox="!supplement.quantifiable"></QuantitySelector>
            </div>
            <div class="supplement-choice" v-for="choice of supplement.getChoices()" :key="'choice-' + choice.id + supplement.id">
              <div class="subtitle">{{ $to(choice.name) }}</div>
              <ErrorBox :boxId="'errorBoxChoice'+choice.id" type="choice" :visible="errorBoxVisible[choice.id]"
                        @click="$emit('clearInvalidChoice')" :key="choice.id"></ErrorBox>
              <div class="scrollable-items">
                <ChoiceSelector no-wave :choice="item" :group="choice" :key="item.id" v-for="item of choice.items"></ChoiceSelector>
              </div>

              <template v-for="item of choice.items">
                <!-- Modifier groups of choice items -->
                <div class="scrollable" :key="'key-' + modifierGroup.id"
                     v-for="modifierGroup of item.modifierGroups" v-if="item.quantity > 0 && choice.selected == item">
                  <br>
                  <div class="subtitle small" :key="modifierGroup.id">{{ $t("item.choose_below") }}</div>
                  <ModifierGroupRequirements :modifierGroup="modifierGroup"></ModifierGroupRequirements>
                  <div class="scrollable-items">
                    <ExtraSelector no-wave :extra="modifier" v-for="modifier of modifierGroup.modifiers" no-popup
                                   :key="modifierGroup.id + '-' + modifier.id"></ExtraSelector>
                  </div>
                </div>
              </template>

            </div>
          </div>
        </div>
      </Panel>

      <div class="filler"></div>

      <div class="buttons">
        <button type="button" class="btn back" @click="back" @contextmenu="contextMenuBack" :class="{'btn-secondary': $kiosk}">{{$t('back')}}</button>
        <button type="button" class="btn next" @click="submit" @contextmenu="contextMenuSubmit" :class="{'btn-primary': $kiosk}">{{$t($kiosk ? 'continue' : 'next')}}</button>
      </div>

    </div>

  </div>
</template>

<script>

  import QuantitySelector from "@/components/tools/QuantitySelector.vue";
  import ExtraSelector from "@/components/tools/ItemExtraSelector.vue";
  import ChoiceSelector from "@/components/tools/ItemChoiceSelector.vue";
  import ModifierGroupRequirements from "@/components/menu/ModifierGroupRequirements.vue";
  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import ScrollArrows from "@/components/tools/ScrollArrows.vue";
  import Steps from "@/components/payment/Steps.vue";

  export default {

    name: "paymentOptions",

    components: { QuantitySelector, Steps, ExtraSelector, ChoiceSelector, ModifierGroupRequirements, ErrorBox, ScrollArrows },

    data() {
      return {
        supplements: null,
        errorBoxVisible: {},
      }
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      }
    },

    mounted() {
      if (!this.$kiosk) {
        if (!this.$order.hasItems() || !this.$user.token || this.$store.state.currentCompany.isCurrentMethodClosed()) {
          this.$router.push(this.$router.generate("/menu"));
          return;
        }
        if (!this.$user) {
          this.$router.push(this.$router.generate("/login?redirect=paymentOptions"));
          return;
        }

        this.$store.commit("showNavigationCart", true);
      } else {
        if (!this.$order.hasItems() || !this.$order.method) {
          this.$router.push(this.$router.generate("/menu"));
          return;
        }
      }

      this.supplements = [];
      for (let supplement of this.$store.state.inventory.supplements) {
        this.supplements.push(supplement.clone());
      }

      this.rectifySupplementsQty();
    },

    methods: {
      back() {
        this.$router.push(this.$router.generate("/menu"));
      },
      contextMenuBack() {
        if (this.$kiosk) {
          this.back();
        }
      },
      contextMenuSubmit() {
        if (this.$kiosk) {
          this.submit();
        }
      },
      rectifySupplementsQty() {
        this.$order.resetSupplements();
        for (let supplement of this.supplements) {
          if (supplement.getChoices().length > 0) {
            if (supplement.getChoices().find(c => !!c.selected)) {
              supplement.quantity = 1;
            } else {
              supplement.quantity = 0;
            }
          }
        }
      },
      addSupplementsToOrder() {
        for(let supplement of this.supplements.filter(s => s.quantity > 0 )) {
          this.$order.addSupplement(supplement);
        }
      },
      submit() {
        if (!this.validateSupplements()) {
          return;
        }

        this.rectifySupplementsQty();
        this.addSupplementsToOrder();
        let route;
        if (this.$kiosk) {
          if (this.kioskConfiguration && this.kioskConfiguration.isTipEnabled()) {
            route = "/kiosk/tip";
          } else {
            route = "/kiosk/confirmation";
          }
        } else {
          route = "/paymentContact";
        }
        this.$router.push(this.$router.generate(route));
      },

      validateSupplements() {
        let invalidModifierGroups = this.getSupplementsInvalidModifierGroups();
        if (invalidModifierGroups.length > 0) {
          let invalidModifierGroup = invalidModifierGroups[0];
          let validation = invalidModifierGroup.validateQuantitySelected();
          let errorMessage = this.$t(validation.error)
            .replace("{NUMBER}", validation.number)
            .replace("{MIN}", validation.min)
            .replace("{MAX}", validation.max);
          let element = document.querySelectorAll(".invalid#modifierGroupRequirements-" + invalidModifierGroup.id)[0];
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          showAffirmation(this.$to(invalidModifierGroup.description), errorMessage);
          return false;
        }
        return true;
      },

      getSupplementsInvalidModifierGroups() {
        if (!this.supplements) {
          return [];
        }

        let invalidSupplementsModifiers = [];
        for (let supplement of this.supplements) {
          invalidSupplementsModifiers = [...invalidSupplementsModifiers, ...supplement.getChoicesInvalidModifierGroups()];
        }
        return invalidSupplementsModifiers;
      }
    }
  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    display: flex;
    flex-direction: column;
  }

  .filler {
    flex-grow: 1;
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    .container {
      flex-grow: 1;
    }

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
    }
    .text {
      color: #808080;
    }
  }

  .options {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .option {
      display: flex;
      padding: 16px 0;
      width: 50%;

      &.full {
        width: 100%;
      }

      .supplement-choice {
        width: 100%;

        .subtitle {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #e6e6e6;
          padding-left: 10px;

          &.small {
            font-size: 15px;
          }
        }
      }

      .holder {
        border: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        padding: 10px;
        flex-grow: 1;
        border-radius: 5px;
        margin: 5px;

        .option-name {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
        }
        .option-description {
          color: #808080;
        }
        .option-price {
          margin-top: 2px;
        }
      }

      img {
        width: 80px;
        height: 80px;
      }

      .left {
        padding: 0 20px;
        flex-grow: 1;

        .option-name {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
        }
        .option-description {
          color: #808080;
        }
      }

      .option-name {
        font-size: 18px;
        font-weight: 500;
        color: #1a1a1a;
      }
      .option-description {
        color: #808080;
      }
    }

  }

  #iShopFoodApp .buttons {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .btn {
      flex-grow: 1;
      flex-basis: 0;
      padding: 20px;
      font-size: 20px;
    }
    .back {
      background-color: white;
      color: #ed2937;
      margin-right: 20px;
    }
    .next {
      background-color: #ed2937;
      color: white;
      margin-left: 20px;
    }
    .disable {
      color: lighten(#ed2937, 35%);
      cursor: initial;
      &:hover {
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  #iShopFoodApp.xs {
    .options .option {
      width: 100%;

      .holder:nth-child(odd) {
        margin-right: 0;
      }
    }
    .buttons {
      span {
        display: none;
      }
      .back, .next {
        margin: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 1;
      }
    }
    .page-content {
      padding: 0;

      &:after {
        content: none;
      }
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp #paymentOptions {
    .quantity-selector {
      width: auto;
    }
    .scrollable-items {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 10px;

      .item-selector {
        width: calc(50% - 10px);
        margin: 5px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;

        .selector {
          display: flex;
          align-items: center;
          padding: 0 10px;

          .wave-zone {
            padding: 0 20px;
          }
        }
      }
    }
  }

  #iShopFoodApp.kiosk #paymentOptions {
    .kiosk-title {
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 40px;
      font-size: 40px;
      font-weight: bold;
    }
    .options {
      max-height: 710px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .subtitle {
      font-size: 20px;
    }
    .panel {
      max-width: 840px;
      min-width: 840px;
      margin-left: auto;
      margin-right: auto;

      .title {
        font-size: 28px;

        .svgicon {
          fill: var(--primary-color) !important;
          margin-right: 20px;
        }
      }
    }
    .holder {
      border-radius: 20px;
      padding: 15px;
      margin: 10px 20px;
      border: 1px solid transparent;
      box-shadow: 0px 2px 8px 0px rgba(2, 3, 2, 0.10);
    }
    .item-selector {
      border-radius: 20px;
      padding: 15px;
      margin: 10px 20px;
      width: calc(50% - 40px);
      border: 1px solid transparent;
      box-shadow: 0px 2px 8px 0px rgba(2, 3, 2, 0.10);

      .input {
        label:after {
          color: var(--primary-color);
        }
      }

      &.selected {
        border: 1px solid var(--primary-color);
      }
    }
    .filler {
      flex-grow: 0;
      height: 100px;
    }
    .buttons {
      width: 840px;
      margin-right: auto;
      margin-left: auto;
      .btn {
        flex-grow: 0;
        width: 250px;
        min-width: 250px;
      }
    }
  }
</style>
