<template>
  <span class="formatted-price">
    <span class="dollar before" v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{$ts.getters.getSymbol}}</span>
    <span class="number">{{ number[0] }}</span>
    <span class="decimal">.{{ number[1] }}</span>
    <span class="dollar after" v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{$ts.getters.getSymbol}}</span>
  </span>
</template>

<script>

  export default {

    name: "Price",

    props: {
      value: Number
    },

    computed: {
      number() {
        if (!this.value) {
          return ["0", "00"];
        }
        return this.value.toFixed(2).split('.');
      }
    }

  }

</script>
