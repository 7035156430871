<template>
    <div id="giftCardView" :class="{firstStep: !giftCard}">
        <FloatingLocaleSwitcher v-if="isMobile"></FloatingLocaleSwitcher>
        <Panel class="email-panel" icon="icons/gift.svg" :leftImage="modalImage"
               :title="$t('gift_card.branch_title').replace('{BRANCH}', branch.name)">

            <template v-if="!giftCard">
                <div class="content">
                    <div class="background" :style="{backgroundImage: 'url(\'' + background + '\')'}"></div>
                    <div class="logo-card" v-if="logo">
                        <img :src="logo">
                    </div>
                    <div class="description">{{$t('gift_card.enter_email')}}</div>
                    <div class="group">
                        <input type="text" v-model="email" :placeholder="$t('email')">
                    </div>
                </div>
                <div class="footer">
                    <div class="locale" v-if="!$store.getters.isMobileOrTablet && $store.state.inventory && !$store.state.inventory.isEnglishLocaleOnly()">
                        <div class="switcher" :class="{selected: $ts.state.locale == 'fr'}" @click="setLocale('fr')">{{ $t("fr") }}</div>
                        <div class="switcher" :class="{selected: $ts.state.locale == 'en'}" @click="setLocale('en')">{{ $t("en") }}</div>
                    </div>
                    <button class="btn btn-primary" @click="verifyEmail" @keyup.enter="verifyEmail">{{$t('send')}}</button>
                </div>
            </template>

            <template v-if="giftCard">
                <div class="content">
                    <Panel icon="icons/smiley.svg" ref="balance-panel" class="subpanel with-card" flat :title="$t('gift_card.active')" collapsible>
                        <div class="left">
                          <div class="balance-card" :style="{backgroundImage: 'url(\'' + getTemplateImage(giftCard.template) + '\')'}">
                            <template v-if="!getTemplateImage(giftCard.template)">
                              <div class="amount">
                                <template v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{$ts.getters.getSymbol}}</template>{{giftCard.balance.toFixed(2).split(".")[0]}}<span class="cents">{{giftCard.balance.toFixed(2).split(".")[1]}}<template v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{$ts.getters.getSymbol}}</template></span>
                              </div>
                              <div class="initial-balance">
                                {{$t('gift_card.initial_balance')}}: {{$tc(giftCard.initialBalance.toFixed(2))}}
                              </div>
                            </template>
                          </div>
                          <div class="number">
                            {{$t('gift_card.number')}}: {{giftCard.number}}
                            <template v-if="getTemplateImage(giftCard.template)">
                              <br>
                              {{$t('gift_card.initial_balance')}}: {{$tc(giftCard.initialBalance.toFixed(2))}}
                            </template>
                          </div>
                        </div>
                        <div class="right">
                            <div class="info">
                                <label>{{$t('gift_card.from')}}</label>
                                <div class="value">{{giftCard.from.name}}</div>
                            </div>
                            <div class="info">
                                <label>{{$t('gift_card.to')}}</label>
                                <div class="value">{{giftCard.to.name}}</div>
                            </div>
                            <div class="info" v-if="giftCard.from.note">
                                <label>{{$t('gift_card.message')}}</label>
                                <div class="value">{{giftCard.from.note}}</div>
                            </div>
                          <template v-if="getTemplateImage(giftCard.template)">
                            <div class="info">
                              <label>{{$t('balance')}}</label>
                              <div class="amount">{{$tc(giftCard.balance.toFixed(2))}}</div>
                            </div>
                          </template>
                        </div>
                    </Panel>
                    <Panel icon="icons/order.svg" ref="order-panel" class="subpanel" flat :title="$t('gift_card.order_online')" collapsible>
                        <p>{{$t('gift_card.order_online_details')}}</p>
                        <div class="button-holder">
                            <button class="btn btn-primary"  @click="goToOnlineOrdering">{{$t('gift_card.order')}}</button>
                        </div>
                    </Panel>
                    <!--<Panel icon="icons/barcode.svg" class="subpanel" flat :title="$t('gift_card.use_physical')" collapsible>
                        <p>{{$t('gift_card.physical_details')}}</p>
                        <div class="button-holder" v-if="!barCodeGenerated">
                            <button class="btn btn-primary" @click="showBarCode">{{$t('gift_card.get_codebar')}}</button>
                        </div>
                        <canvas v-if="barCodeGenerated" class="barCode"></canvas>
                    </Panel>-->
                </div>
                <div class="footer">
                    <div class="locale" v-if="!$store.getters.isMobileOrTablet && $store.state.inventory && !$store.state.inventory.isEnglishLocaleOnly()">
                        <div class="switcher" :class="{selected: $ts.state.locale == 'fr'}" @click="setLocale('fr')">{{ $t("fr") }}</div>
                        <div class="switcher" :class="{selected: $ts.state.locale == 'en'}" @click="setLocale('en')">{{ $t("en") }}</div>
                    </div>
                    <div class="terms" v-if="hasTerms" @click="showGiftCardTerms">{{$t('terms')}}</div>
                </div>
            </template>

        </Panel>
    </div>
</template>

<script>
  import Store from "@/store";
  import Axios from "axios/index";
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";
  import Barcode from "jsbarcode";

  export default {
    name: "GiftCardView",

    components: { FloatingLocaleSwitcher },

    data() {
      return {
        email: "",
        barCodeGenerated: false,
        templates: [],
        giftCard: null
      }
    },

    async mounted() {
      this.$store.commit("showNavigationCart", false);

      let interval = setInterval(async () => {
        if (this.branch) {
          clearInterval(interval);
          showSpinner();
          await this.loadTemplates();
          hideSpinner();
        }
      }, 100);
    },

    computed: {
      token() {
        return this.$route.params.token;
      },
      isMobile() {
        return this.$store.state.screenSize == 'xs' || this.$store.state.screenSize == 'sm';
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      modalImage() {
        if (!this.branch || !this.branch.modalOrderTypeImage) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.modalOrderTypeImage.url;
      },
      background() {
        if (!this.branch || !this.branch.hubImage) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.hubImage.url;
      },
      hasTerms() {
        return this.branch.getGiftCardTerms() && this.branch.getGiftCardTerms().terms;
      },
      logo() {
        if (!this.branch || !this.branch.navigationLogo) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.navigationLogo.url;
      }
    },

    methods: {
      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },

      getTemplateImage(templateId) {
        let template = this.templates.find(t => t.id === templateId);
        if (template && template.image && template.image.url) {
          return this.$store.state.urlFileServer + template.image.url;
        }
      },

      goToOnlineOrdering() {
        setConfiguration("gift-card", this.giftCard.number, sessionStorage);
        this.$router.push(this.$router.generate('/orderType'));
      },

      async loadTemplates() {
        showSpinner();
        try {
          let result = await Axios.get(this.$store.getters.urlServer + "/api/gift_card_templates?branch=" + this.branch.id);
          if (result) {
            this.templates = result.data.data.filter(t => t.active);
          }
        } catch (e) {
          console.log("error", e);
        }
        hideSpinner();
      },

      showBarCode() {
        this.barCodeGenerated = true;
        if (this.$refs['order-panel']) {
          this.$refs['order-panel'].close();
        }
        if (this.$refs['balance-panel']) {
          this.$refs['balance-panel'].close();
        }
        this.$nextTick(() => {
            Barcode(document.getElementsByClassName("barCode")[0], String(this.giftCard.number), {
              width: 2,
              height: 70,
              displayValue: true,
              background: "transparent",
              lineColor: "#ffffff"
            });
        });
      },

      showGiftCardTerms() {
        if (!this.hasTerms) {
          return;
        }
        showAffirmation(this.$t("terms"), this.$to(this.branch.getGiftCardTerms().terms)
          + "<br><br>"
          + this.$to(this.branch.getGiftCardTerms().advancedTerms), false, false, false, "terms");
      },

      async verifyEmail() {
        if (!this.email) {
          return;
        }
        showSpinner();
        try {
          let response = await Axios.get(`${this.$store.getters.urlServer}/api/gift_cards/find?token=${this.token}&email=${this.email}`);
          if (response.data && response.status === 200) {
            hideSpinner();
            this.giftCard = response.data;
          } else {
            hideSpinner();
            showAffirmation(this.$t("oops"), this.$t("gift_card.not_found"));
          }
        } catch (e) {
          hideSpinner();
          showAffirmation(this.$t("oops"), this.$t("gift_card.not_found"));
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
    #giftCardView {
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(.firstStep) .email-panel .content {
            justify-content: flex-start;
        }
    }
    #iShopFoodApp .email-panel {
        width: 100%;
        max-width: 860px;
        height: 840px;

        .content {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow-y: auto;

            input {
                width: 280px;
            }
        }
        .description {
            margin-bottom: 60px;
            max-width: 300px;
            text-align: center;
        }
        .footer {
            flex-shrink: 0;
            border-top: 1px dashed #4d4d4d;
            padding-top: 20px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btn {
                padding: 8px 30px;
            }

            .terms {
                color: var(--primary-color) !important;
                cursor: pointer;
            }

            .locale {
                display: flex;

                .switcher {
                    cursor: pointer;
                    &.selected {
                        font-weight: bold;
                    }
                }

                .switcher:not(:last-child) {
                    &:after {
                        content: "|";
                        display: inline-block;
                        margin: 0 8px;
                        opacity: 0.6;
                        font-weight: normal !important;
                        color: var(--primary-color);
                    }
                }
            }
        }
    }

    #iShopFoodApp .subpanel {
        width: 100%;

        .button-holder {
            padding-top: 30px;
            display: flex;

            .btn {
                width: 200px;
                border-radius: 4px;
            }
        }

        .info {
            margin-bottom: 5px;

            label {
                font-size: 12px;
                opacity: 0.7;
            }
            .amount {
              font-weight: 900;
              font-family: 'Segoe UI Black', sans-serif !important;
              font-size: 24px;
              line-height: 1;
              color: white !important;
            }
        }
        .number {
            font-size: 12px;
            text-align: center;
            margin-top: 5px;
        }
        .left {
            margin-right: 40px;
        }

        canvas {
            width: 280px;
            height: auto;
            margin-top: 20px;
        }
    }

    .logo-card {
        width: 300px;
        height: 200px;
        box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.12);
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #393939;
        border-radius: 20px;
        margin-bottom: 60px;

        img {
            height: 100%;
            width: auto;
        }
    }

    #iShopFoodApp .balance-card {
        box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        width: 180px;
        height: 120px;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        background: linear-gradient(to top left, var(--primary-color), #8b171a);
        background-position: center;
        background-size: cover;
        position: relative;

        .initial-balance {
            position: absolute;
            bottom: 8px;
            font-size: 12px;
            font-weight: 300;
            white-space: nowrap;
            color: white !important;
            left: 50%;
            transform: translateX(-50%);
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
            opacity: 0.6;
        }

        .amount {
            font-size: 36px;
            display: flex;
            align-items: flex-start;
        }
        .cents {
            font-size: 22px;
        }

        .amount, .cents {
            font-weight: 900;
            line-height: 1;
            color: white !important;
            font-family: 'Segoe UI Black' !important;
        }

    }
</style>

<style lang="scss">
    #iShopFoodApp #giftCardView {
        .email-panel {
            & > .content {
                height: calc(100% - 60px);
                display: flex;
                .container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .subpanel.with-card > .content .container {
            flex-direction: row;
        }
        .subpanel > .content .container {
            padding-left: 50px;
            padding-bottom :0;

        }
    }

    @media screen and (max-width: 720px) {
        #iShopFoodApp #giftCardView .email-panel {
            background-color: #2e2e2e !important;

            .content {
                justify-content: flex-start !important;
            }
        }
        #iShopFoodApp #giftCardView .subpanel {
            p {
                padding: 0 20px;
            }
            .button-holder {
                padding: 20px;
            }
            &.with-card > .content .container {
                flex-direction: column;
                align-items: center;

                .left {
                    margin-right: 0;
                    margin-bottom: 20px;
                    margin-top: 20px;
                }
                .right {
                    width: 100%;
                    padding: 0 20px 20px 20px;
                }
            }
            & > .content {
                &::before {
                    content: none;
                }
            }
            canvas {
                width: 270px;
                margin: 10px auto;
            }
        }
        #iShopFoodApp #giftCardView .email-panel {
            height: 100%;
            border: 0 !important;

            & > .title {
                font-size: 15px;

                .titleIcon {
                    display: none;
                }
            }

            & > .content .container {
                padding: 0 !important;
            }

            .background {
                height: 45%;
                flex-shrink: 0;
                background-position: center;
                background-size: cover;
                width: 100%;
                z-index: 0;
            }
            .logo-card {
                margin-top: -30%;
                z-index: 2;
                width: 250px;
                height: 150px;
                padding: 25px;
                margin-bottom: 40px;
            }
            .description {
                margin-bottom: 40px;
                font-size: 15px;
            }
            .footer {
                justify-content: center;
                padding-bottom: 10px;
                padding-top: 10px;
                margin-top: 0;
                border-top: 0;

                .btn {
                    width: 180px;
                    font-size: 14px;
                    border-radius: 4px;
                }
            }
        }
    }
</style>
