<template>
  <div class="here-autocomplete" :class="{open: suggestions.length > 0 && showSuggestions}" @click="close">
    <input id="autocomplete" class="input" name="address" type="text" @keyup="autocompleteListener" :placeholder="placeholder || $t('placeholder_address')">
    <div class="suggestions" v-if="suggestions.length > 0 && showSuggestions">
      <div class="suggestion" :key="suggestionIndex" v-for="(suggestion, suggestionIndex) of suggestions" @click="selectSuggestion(suggestion)">
        <svgicon src="icons/location.svg" :height="16" :width="16"></svgicon>
        {{suggestion.address.label}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hereAutocomplete",

    props: {
      placeholder: String,
      prefill: Object
    },

    data() {
      return {
        AUTOCOMPLETE_URL: "https://autosuggest.search.hereapi.com/v1/autocomplete",
        GEOCODE_URL: "https://lookup.search.hereapi.com/v1/lookup",
        suggestionAjaxRequest: new XMLHttpRequest(),
        geocodeAjaxRequest: new XMLHttpRequest(),
        query: "",
        suggestions: [],
        showSuggestions: false,
        position: null
      }
    },

    mounted() {
      this.suggestionAjaxRequest.addEventListener("load", this.onAutoCompleteSuccess);
      this.suggestionAjaxRequest.addEventListener("error", this.onAutoCompleteFailed);
      this.suggestionAjaxRequest.responseType = "json";

      this.geocodeAjaxRequest.addEventListener("load", this.onGeocodeSuccess);
      this.geocodeAjaxRequest.addEventListener("error", this.onGeocodeFailed);
      this.geocodeAjaxRequest.responseType = "json";

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          if (position) {
            this.position = position.coords.latitude + "," + position.coords.longitude;
          }
        });
      }

      if (this.prefillValue) {
        document.getElementById("autocomplete").value = this.prefillValue;
      }
    },

    methods: {
      autocompleteListener(event) {
        let value = document.getElementById("autocomplete").value;

        if (value !== this.query && value.length > 2) {
          let params = "?q=" + encodeURIComponent(value) +
            "&limit=5" +
            "&apiKey=" + CONFIG.hereApiKey;
          if (this.position) {
            params += "&at=" + this.position;
          }
          if (this.countries) {
            params += "&in=countryCode:" + this.countries;
          }
          this.suggestionAjaxRequest.open("GET", this.AUTOCOMPLETE_URL + params);
          this.suggestionAjaxRequest.send();
        }
        if (value.length < 3) {
          this.suggestions = [];
        }
        this.query = value;
      },

      getLocation(locationId) {
        let params = "?id=" + encodeURIComponent(locationId) + "&apikey=" + CONFIG.hereApiKey;
        this.geocodeAjaxRequest.open("GET", this.GEOCODE_URL + params);
        this.geocodeAjaxRequest.send();
      },

      onAutoCompleteSuccess() {
        this.suggestions = this.suggestionAjaxRequest.response.items;
        this.showSuggestions = true;
      },

      onGeocodeSuccess() {
        let location = this.geocodeAjaxRequest.response;
        document.getElementById("autocomplete").value = location.address.label;
        this.$emit("changed", location);
      },

      onAutoCompleteFailed(response) {
        console.log("autocomplete failed", response);
      },

      onGeocodeFailed(response) {
        console.log("geocode failed", response);
      },

      selectSuggestion(suggestion) {
        this.getLocation(suggestion.id);
      },

      close(event) {
        if (!(event.target instanceof HTMLInputElement)) {
          this.showSuggestions = false;
        }
      }
    },

    computed: {
      prefillValue() {
        if (!this.prefill) {
          return;
        }
        return (this.prefill.fullAddress ? this.prefill.fullAddress + ", " : "") +
          (this.prefill.city ? this.prefill.city + ", " : "") +
          (this.prefill.postalCode ? this.prefill.postalCode : "");
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      countries() {
        if (!this.branch || this.branch.getISO3Countries().length === 0) {
          return;
        }
        return this.branch.getISO3Countries().join(",");
      }
    }
  }
</script>

<style lang="scss">
  #iShopFoodApp {
    &.pos .here-autocomplete {
      .suggestions {
        box-shadow: none;
        border: 1px solid var(--border-color-5);
        background-color: var(--background-color-3) !important;
      }
      .suggestion {
        color: var(--theme-color) !important;
        background-color: var(--background-color-3) !important;
        padding: 12px;

        .svgicon svg {
          fill: var(--primary-color) !important;
        }
      }
    }

    .here-autocomplete {
      width: 100%;
      display: flex;
      position: relative;

      &.open {
        input {
          border-bottom: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &:before {
          content: "";
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }

    input {
      flex-grow: 1;
    }

    .suggestions {
      position: absolute;
      top: 100%;
      background-color: white;
      color: black !important;
      z-index: 1;
      width: 100%;
      border: 1px solid #d9d9d9;
      box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px 0;
    }

    .suggestion {
      padding: 8px 10px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: black !important;
      .svgicon {
        margin-right: 10px;
        flex-shrink: 0;
        svg {
          fill: #888 !important;
        }
      }
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
</style>
