<template>
  <PageTitle class="menuHeader" :show-back-button="showBackButton" v-if="!$kiosk">

    <svgicon class="icon" :src="icon || 'icons/menu.svg'" :height="20" :width="15" v-if="!$store.state.quickMode"></svgicon>

    <template v-if="!$store.state.quickMode || $store.getters.isMobileOrTablet">
      <Breadcrumb v-if="!text"></Breadcrumb>
      <template v-if="text"> {{ text }}</template>
    </template>

    <div class="filler" v-if="(text || $store.state.quickMode) && branch"
         :class="{logo: $store.state.quickMode && !$store.getters.isMobileOrTablet && horizontalLogo}"></div>

    <div class="input" v-if="!$store.state.displayMode && !$store.state.ticketMode">
      <label v-if="$store.state.quickMode && !$store.getters.isMobileOrTablet">{{ $t("title.orderType") }}</label>
      <div class="method select" @click="showMethodTooltip" ref="methodTooltipParent">
        <svgicon class="select-icon" src="icons/delivery.svg" :height="17" :width="16"></svgicon>
        <span class="selected">{{ $t(orderMethod) || $t('payment.order_type') }}</span>
        <svgicon class="arrow" src="icons/arrow.svg" :height="6" :width="10"></svgicon>
      </div>
    </div>

    <template v-if="company && orderMethod && !$store.getters.isMobileOrTablet && !$store.state.displayMode && !$store.state.ticketMode">

      <template v-if="!$order.paymentOnly">

        <div class="input" v-if="orderMethod == 'catering' || company.isFutureDateEnabled()">
          <label v-if="$store.state.quickMode">{{ $t("date") }}</label>
          <div class="date select">
            <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
            <div class="selected">
              <datetime :zone="company.timezone" :value-zone="company.timezone"
                        v-model="date" :format="dateFormat" :min-datetime="minDate"
                        :disableDay="company.getFunctionToCheckIfDayDisabled()" :week-start="7" :input-class="['select']"></datetime>
            </div>
            <svgicon class="arrow" src="icons/arrow.svg" :height="6" :width="10"></svgicon>
          </div>
        </div>

        <template v-if="!company.isCurrentMethodClosed()">
          <div class="input" v-if="inAdvanceEnabled && hasOpenHours">
            <label v-if="$store.state.quickMode">{{ $t("hour") }}</label>
            <div class="time select" @click="showHourTooltip" ref="hourTooltipParent">
              <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
              <span class="selected">{{ formatHour(orderHour) || '––:––' }}</span>
              <svgicon class="arrow" src="icons/arrow.svg" :height="6" :width="10"></svgicon>
            </div>
          </div>

          <div class="input" v-if="(!inAdvanceEnabled || !hasOpenHours) && averageDelay > 0 && orderMethod !== 'inPlace' && orderMethod !== 'hotel'">
            <label v-if="$store.state.quickMode">{{ $t("hour") }}</label>
            <div class="delay select fake">
              <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
              <span class="selected">{{ $t("order.delay_of") }} {{ averageDelay }} min.</span>
            </div>
          </div>

          <div class="input" v-if="orderMethod === 'inPlace' && $order.tableNumber">
            <label v-if="$store.state.quickMode">{{ $t("table") }}</label>
            <div class="delay select fake">
              <svgicon class="select-icon" src="icons/table.svg" :height="16" :width="19"></svgicon>
              <span class="selected">{{ $order.tableNumber }}</span>
            </div>
          </div>

          <div class="input" v-if="orderMethod === 'hotel' && $order.roomNumber">
            <label v-if="$store.state.quickMode">{{ $t("room") }}</label>
            <div class="delay select fake">
              <svgicon class="select-icon" src="icons/door.svg" :height="16" :width="19"></svgicon>
              <span class="selected">{{ $order.roomNumber }}</span>
            </div>
          </div>
        </template>

        <template v-if="company.isCurrentMethodClosed() && $order.method">
          <div class="input">
            <label v-if="$store.state.quickMode">{{ $t("hour") }}</label>
            <div class="delay select fake">
              <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
              <span class="selected">{{ $t("inAdvance.currently_closed") }}</span>
            </div>
          </div>
        </template>

      </template>


      <div class="input" v-if="$store.state.quickMode">
        <label>{{ $t("payment.branch") }}</label>
        <div class="company select fake">
          <svgicon class="select-icon" src="icons/branches.svg" :height="16" :width="16"></svgicon>
          <div class="selected">{{ company.name }}</div>
          <div class="edit" @click="showOrderOptionsModal">{{ $t("edit") }}</div>
        </div>
      </div>

    </template>

  </PageTitle>
  <KioskHeader v-else></KioskHeader>
</template>

<script>

  import Breadcrumb from "@/components/menu/Breadcrumb.vue";
  import EventBus from "../../lib/eventBus";
  import { PromotionManager } from "../../PromotionManager";
  import KioskHeader from "@/components/kiosk/KioskHeader.vue";
  import moment from "moment";

  export default {
    name: "menuHeader",

    components: { Breadcrumb, KioskHeader },

    props: ["text", "icon", "showBackButton"],

    data() {
      return {
        date: this.$order.date.toISOString()
      };
    },

    watch: {
      "$order.date": function(newValue) {
        if (this.date != newValue.toISOString()) {
          this.date = newValue.toISOString();
        }
      },
      "$order.branding"() {
        this.$forceUpdate();
      },
      date: function(newValue) {
        let value = moment(newValue);
        this.$order.date = moment(this.$order.date).set({
          year: value.get("year"),
          month: value.get("month"),
          date: value.get("date")
        });
        PromotionManager.calculate();
      }
    },

    computed: {
      orderMethod() {
        return this.$order.method;
      },
      orderHour() {
        return this.$order.hour;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      horizontalLogo() {
        if (!this.branch || !this.branch.horizontalLogo) {
          return;
        }
        if (this.$order.branding && this.$order.branding.horizontalLogo) {
          return this.$store.state.urlFileServer + this.$order.branding.horizontalLogo.url;
        }
        if (this.$order.branding && this.$order.branding.logo) {
          return this.$store.state.urlFileServer + this.$order.branding.logo.url;
        }
        return this.$store.state.urlFileServer + this.branch.horizontalLogo.url;
      },
      inAdvanceEnabled() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return false;
        }
        return this.company.getOpenHours(this.orderMethod).inAdvance || this.orderMethod == "catering";
      },
      averageDelay() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return 0;
        }
        return this.company.getOpenHours(this.orderMethod).averageDelay;
      },
      hasOpenHours() {
        return this.company.getAvailableOpenHours(this.orderMethod).filter(o => o != "delay").length > 0;
      },
      screenSize() {
        return this.$store.getters.getScreenSize;
      },
      hasOrderMethod() {
        return (method) => {
          if (!this.company) {
            return false;
          }
          return this.company.hasOrderMethod(method);
        }
      },
      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d";
        }
        return "d MMMM";
      },
      minDate() {
        if (this.company.isTodayDisabled()) {
          return moment().add({ day: 1 }).toISOString();
        }
        return moment().toISOString();
      }
    },

    methods: {
      // TODO this is duplicate. Should be moved. This behaviour is in too many places (orderType, menuHeader, Cart, panelLocation, orderOptionsModal)
      async setOrderMethod(method) {
        if (!this.hasOrderMethod(method) && this.company.hasThirdPartyDelivery()) {
          EventBus.$emit("show-third-party-delivery-modal", this.company);
          return;
        }
        for (let item of this.$order.items) {
          if (item.getPrice(method) == 0) {
            let result = await showConfirmation(this.$t("warning"), this.$t("order.reset_change_method"));
            if (result) {
              break;
            } else {
              return;
            }
          }
        }
        this.$order.setMethod(method);
        if (method == "delivery" && !this.company.isCurrentMethodClosed()
                && !this.$user.getDefaultAddress().isEmpty() && !this.company.isInDeliveryZone(this.$user.getDefaultAddress())) {
          EventBus.$emit("showDeliveryError");

        } else if (method == "delivery" && this.$store.state.quickMode) {
          EventBus.$emit("order-options-modal");
        }
      },
      setOrderHour(hour) {
        this.$order.setHour(hour);
      },
      //TODO refactor this duplicated function (5x)
      formatHour(hour) {
        if (hour == "asap") {
          return this.$t("order.asap");
        }
        if (this.$store.state.currentCompany.hourFormat == "12") {
          let parsed = moment(hour, "HH:mm");
          if (!parsed.isValid()) {
            return null;
          }
          return parsed.format("hh:mm A");
        }
        return hour;
      },
      showMethodTooltip() {
        EventBus.$emit("tooltip", {
          parent: this.$refs.methodTooltipParent,
          items: [{
            tr: "method.takeout_short",
            onclick: () => { this.setOrderMethod("takeout") },
            disabled: this.orderMethod == "takeout",
            hidden: !this.hasOrderMethod("takeout")
          }, {
            tr: "method.delivery_short",
            onclick: () => { this.setOrderMethod("delivery"); },
            disabled: this.orderMethod == "delivery",
            hidden: !this.hasOrderMethod("delivery") && !this.company.hasThirdPartyDelivery()
          }, {
            tr: "method.inPlace_short",
            onclick: () => { this.setOrderMethod("inPlace") },
            disabled: this.orderMethod == "inPlace",
            hidden: !this.hasOrderMethod("inPlace")
          }, {
            tr: "method.catering_short",
            onclick: () => { this.setOrderMethod("catering") },
            disabled: this.orderMethod == "catering",
            hidden: !this.hasOrderMethod("catering")
          }, {
            tr: "method.hotel_short",
            onclick: () => { this.setOrderMethod("hotel") },
            disabled: this.orderMethod == "hotel",
            hidden: !this.hasOrderMethod("hotel")
          }]
        });
      },
      showHourTooltip() {
        let items = [];
        for (let hour of this.company.getAvailableOpenHours(this.orderMethod)) {
          items.push({
            text: this.formatHour(hour),
            onclick: () => { this.setOrderHour(hour) },
            disabled: hour == this.$order.hour || !this.company.isIntervalAvailable(hour)
          });
        }
        EventBus.$emit("tooltip", {
          parent: this.$refs.hourTooltipParent,
          items
        });
      },
      showOrderOptionsModal() {
        EventBus.$emit("order-options-modal");
      }
    }

  }

</script>

<style lang="scss" scoped>

  .select {
    font-weight: normal;
  }

  .arrow {
    position: absolute;
    right: 8px;
  }

  .filler {
    flex-grow: 1;

    &.logo {
      height: 40px;
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  #iShopFoodApp .mobile.pageTitle {

    .filler {
      display: none;
    }

    .method, .icon {
      display: none;
      margin-right: 0;
    }
  }

  .select .selected {
    color: white;
  }

  #iShopFoodApp .edit {
    color: var(--primary-color) !important;
    margin-left: 10px;
    font-weight: normal;
    cursor: pointer;
  }

  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .input label {
    font-size: 14px;
    padding: 0 6px 0 16px;
    margin-bottom: 6px;
    font-weight: normal;
    color: white;
  }

</style>

<style lang="scss">

  #iShopFoodApp .mobile.pageTitle {
    .flex-grow {
      justify-content: center;
    }
  }

  #iShopFoodApp .menuHeader .vdatetime {
    color: inherit !important;
  }

  #iShopFoodApp .menuHeader .vdatetime input {
    border: none !important;
    background-color: transparent;
    color: inherit !important;
    font-weight: normal;
    padding: 0;
    line-height: 1;
    width: 178px;
    padding: 10px 28px 10px 36px;
    margin-left: -26px;
    height: 36px;
    cursor: pointer;
    font-size: 16px;
  }

  #iShopFoodApp .date.select {
    padding: 0;
    padding-left: 10px;

    .selected {
      padding: 0;
    }
    .arrow, .select-icon {
      pointer-events: none;
      z-index: 1;
    }
  }

</style>
