<template>
  <div class="pos-order order" v-if="order" @click="onClick" :class="{'order--active': active, readonly}">
    <div class="order__header">
      <div class="order__icon">
        <svgicon class="icon--fill" src="icons/facture.svg" :height="25" :width="25"></svgicon>
      </div>
      <div class="order__descriptor">
        <div class="order__id">
          <template>#{{order.getOrderId()}}</template>
        </div>
        <div class="order__status">
          <template v-if="order.isOpen()">{{ $t("pos_order_overview.tabs.in_progress") }}</template>
          <template v-else-if="order.isRefunded()">{{ $t("pos_order_overview.refunded") }}</template>
          <template v-else>{{ $t("pos_order_overview.completed") }}</template>
        </div>
      </div>
    </div>
    <div class="order__details" >
      <div class="detail" v-if="order.getAppName()">
        <span class="detail__title">{{$t('pos_order_overview.method')}}</span>
        <span class="detail__value">{{$t(`origin.${order.getAppName()}_short`)}} - {{$t(`pos_method.${order.getMethod()}`)}}</span>
      </div>
      <div class="detail" v-if="order.getEmployee()">
        <span class="detail__title">{{$t('pos_order_overview.employee')}}</span>
        <span class="detail__value">{{order.getEmployeeDisplayName()}}</span>
      </div>
      <div class="detail" v-if="!order.isPosSale()">
        <span class="detail__title">{{$t('pos_order_overview.order_id')}}</span>
        <span class="detail__value">#{{order.getOrderId()}}</span>
      </div>
      <div class="detail">
        <span class="detail__title">{{$t('pos_order_overview.date')}}</span>
        <span class="detail__value">{{date}}</span>
      </div>
      <template v-if="moreInfo">
        <div class="detail">
          <span class="detail__title">{{$t('pos_order_overview.items')}}</span>
          <span class="detail__value">{{size}}</span>
        </div>
        <div class="detail" v-if="order.getActiveInvoiceNumbers()">
          <span class="detail__title">{{$t('pos_order_overview.invoices')}}</span>
          <span class="detail__value">{{order.getActiveInvoiceNumbers().map(i => '#' + i).join(', ')}}</span>
        </div>
        <div class="detail" v-if="order.getTotal()">
          <span class="detail__title">{{$t('total.total')}}</span>
          <span class="detail__value">{{$tc(order.getTotal().total)}}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  export default {
    name: "posOrder",

    props: {
      order: Object,
      readonly: Boolean,
      moreInfo: Boolean,
      active: Boolean
    },

    data() {
      return {
        date: null
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      size() {
        return this.order.getGroupedDetails().length;
      }
    },

    mounted() {
      if (this.order) {
        this.initDateTime();
      }
    },

    methods: {
      initDateTime() {
        this.date = this.order.isClosed() ? this.order.getCloseDateAsString() : this.order.getStartDateAsString();
      },
      onClick() {
        if (this.readonly) {
          return;
        }
        this.$emit("setCurrentOrder");
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .pos-order {
    width: 305px;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--background-color-2);
    border: solid 5px var(--black-to-white);
    display: flex;
    flex-direction: column;
    margin: 20px 20px 0 0;
    font-weight: bold;

    &.readonly {
      height: auto;
      width: 100%;
      border-color: transparent;
      background-color: var(--background-color-5);

      .order__header .order__icon .icon--fill {
        fill: #dbdbdb;
      }
    }

    &.order--active {
      border-color: var(--primary-color);

      .order__header .order__icon {
        background-color: var(--primary-color);

        .icon--fill {
          fill: #f5f5f5;
        }
      }
    }

    .order__header {
      display: flex;
      padding: 5px 5px 0 5px;

      .order__icon {
        width: 56px;
        height: 56px;
        border-radius: 10px;
        background-color: var(--background-color-6);
        display: flex;
        align-items: center;
        justify-content: center;

        & > .svgicon {
          fill: var(--primary-color);
        }
      }

      .order__descriptor {
        padding-left: 20px;

        .order__id {
          color: var(--theme-color);
          font-size: 26px;
        }

        .order__status {
          font-size: 16px;
          color: var(--primary-color);
          text-transform: capitalize;
        }
      }
    }

    .order__details {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding: 0 15px 15px 15px;
      text-align: end;

      .detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 10px;

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--border-color-4);
          padding-bottom: 6px;
        }

        .detail__title {
          color: var(--theme-color-2);
          padding-right: 10px;
        }

        .detail__value {
          color: var(--theme-color);
          font-size: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>