<template>
  <div id="authenticationModal" class="modal" :class="{show: show}" @click.self="show = false">
    <div class="content modal-content">

      <div class="close" @click="show = false">&times;</div>

      <Login embed v-if="page === 'login'" @register="page = 'register'" @close="show = false" @reset="page = 'reset'"></Login>
      <Register embed v-if="page === 'register'" @back="page = 'login'" @close="show = false"></Register>
      <ResetPassword embed v-if="page === 'reset'" @back="page = 'login'"></ResetPassword>
    </div>
  </div>
</template>

<script>
  import Login from "@/views/account/Login.vue";
  import Register from "@/views/account/Register.vue";
  import ResetPassword from "@/views/account/ResetPassword.vue";
  import EventBus from "../lib/eventBus";

  export default {
    name: "authenticationModal",

    components: { Login, Register, ResetPassword },

    data() {
      return {
        show: false,
        page: "login"
      }
    },

    mounted() {
      EventBus.$on("show-authentication-modal", () => {
        this.show = true;
        this.page = "login"
      });
    },

    beforeDestroy() {
      EventBus.$off("show-authentication-modal");
    },

    computed: {

    },

    methods: {
      parentClick() {
        console.log("parent click");
      }
    }
  }
</script>

<style lang="scss">
  #iShopFoodApp #authenticationModal {
    z-index: 998;

    .modal-content {
      max-width: 520px;
      padding: 6px;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
      z-index: 2;
      padding: 28px 25px;
      line-height: 0.5;
      cursor: pointer;
      font-weight: 300;
      transition: color ease-in-out 0.3s;

      &:hover {
        color: var(--primary-color) !important;
      }
    }
    #login, #register, #resetPassword {
      .hub .left {
        display: none;
      }
      .hub .right {
        .page-content {
          padding: 0;
        }
        .panel {
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp #authenticationModal {
      .modal-content {
        width: 100%;
        max-width: none;
        margin: 0;
        border-radius: 0;

        #login, #register, #resetPassword {
          .panel {
            border: none;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: transparent;

            .content {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .container {
                width: 100%;
              }
            }
          }

          .pageTitle {
            display: none;
          }
        }
      }
    }
  }
</style>
