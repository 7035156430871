<template>
  <div id="recommenderModal" class="modal" :class="{show: show && itemsToShow.length > 0, smaller: !modalImage}">
    <div class="content modal-content">
      <div class="left" v-if="modalImage">
        <AsyncImage class="leftImage" :image="modalImage"></AsyncImage>
      </div>
      <div class="right">

        <div class="big-title" v-if="!$store.getters.isMobileOrTablet">
          <svgicon src="icons/smiley.svg" :width=20 :height=20></svgicon>
          {{ $t("order.we_suggest") }}...
        </div>

        <div class="logo-holder big-title" v-if="$store.getters.isMobileOrTablet" :class="{grow: !$order.method}">
          <div class="logo"></div>
        </div>

        <div class="recommender-description" v-if="$store.getters.isMobileOrTablet">
          {{ $t("order.we_suggest") }} :
        </div>

        <div class="items">
          <div class="item" v-for="(item, itemIndex) of itemsToShow" @click="selectItem(item)" :key="mode + itemIndex">
            <div class="image" v-if="item.image" :class="[backgroundSize]">
              <AsyncImage :image="CONFIG.urlFileServer + item.image"></AsyncImage>
            </div>
            <div class="middle">
              <div class="name">{{ $to(item.name) }}</div>
              <v-clamp class="description" :max-lines="2">{{ $to(item.description) }}</v-clamp>
            </div>
            <div class="priceContainer">
              <div class="price">
                <div class="round">
                  <template v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{$ts.getters.getSymbol}}</template>{{ Math.floor(item.getPrice()) }}
                </div>
                <div class="cents">
                  {{ leftPad(Math.round(item.getPrice() % 1 * 100).toString(), 2) }}<template v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{$ts.getters.getSymbol}}</template>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons">
          <div class="grow"></div>
          <div class="btn btn-primary flat" @click="close">
            {{ $t("order.no_thanks") }}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

  import AsyncImage from "@/components/tools/AsyncImage";
  import { mapActions } from 'vuex';
  import EventBus from '../lib/eventBus';
  import Axios from 'axios';
  import Vue from "vue";

  export default {

    name: "recommenderModal",

    components: { AsyncImage },

    data() {
      return {
        show: false,
        items: [],
        itemsEnd: [],
        mode: "start",
        alreadyShowedStart: false,
        loading: true,
        toShow: false,
        CONFIG
      };
    },

    beforeDestroy() {
      EventBus.$off("show-recommendations");
      EventBus.$off("show-end-recommendations");
      EventBus.$off("reload-recommendations");
    },

    mounted() {
      EventBus.$on("show-recommendations", () => {
        displayAfterVisibleModalsAreClosed(() => {
          if (this.alreadyShowedStart) {
            return;
          }
          if (this.loading) {
            this.toShow = true;
          }
          if (this.items.length > 0) {
            this.mode = "start";
            this.alreadyShowedStart = true;
            this.show = true;
          }
        });
      });
      EventBus.$on("show-end-recommendations", () => {
        if (this.itemsEnd.length > 0) {
          for (let itemEnd of this.itemsEnd) {
            for (let cartItem of this.$order.items) {
              if (cartItem.id == itemEnd.id) {
                return;
              }
            }
          }
          this.mode = "end";
          this.show = true;
        }
      });
      EventBus.$on("reload-recommendations", () => {
        this.getRecommendations();
      });
      let interval = setInterval(() => {
        if (this.$store.state.inventory.getRoot().length > 0) {
          this.getRecommendations();
          clearInterval(interval);
        }
      }, 100);
    },

    computed: {
      modalImage() {
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.modalOrderTypeImage) {
          return null;
        }
        return CONFIG.urlFileServer + this.$store.state.currentBranch.modalOrderTypeImage.url;
      },
      backgroundSize() {
        if (!this.$store.state.currentBranch) {
          return null;
        }
        return this.$store.state.currentBranch.style.itemImageBackgroundSize;
      },
      itemsToShow() {
        if (this.mode == "start") {
          return this.items.filter(i => !i.outOfStock);
        }
        return this.itemsEnd.filter(i => !i.outOfStock);
      }
    },

    watch: {
      "$store.state.inventory.root": function(newValue) {
        if (newValue.length == 0) {
          return;
        }
        if (this.$user.token) {
          this.loadUserRecommendations();
        } else {
          this.loadUnloggedRecommendations();
        }
      }
    },

    methods: {
      ...mapActions({
        getRecommendationsAction: "recommendations",
        searchItem: "searchItem"
      }),
      async getRecommendations() {
        this.items = [];
        this.itemsEnd = [];
        if (this.$user.token) {
          this.loadUserRecommendations();
        } else {
          this.loadUnloggedRecommendations();
        }
      },
      close() {
        this.show = false;
      },
      selectItem(item) {
        this.$router.push(this.$router.generate("/item/" + item.id));
        this.close();
      },
      leftPad(str, length) {
        return "0".repeat(Math.max(0, length - str.length)) + str;
      },
      async loadUserRecommendations() {
        let recommendations = await Axios.get(this.$store.getters.urlServer + "/api/me/recommendations", {
          params: {
            company: this.$route.params.company,
            event: "onStart"
          },
          headers: {
            Authorization: "Bearer " + this.$user.token
          }
        });
        let ids = recommendations.data.recommendations;
        this.items = [];
        for (let id of ids) {
          let item = await this.searchItem(id);
          if (item) {
            this.items.push(item);
          }
        }
        if (this.toShow && this.items.length > 0) {
          this.mode = "start";
          this.show = true;
          this.toShow = false;
        }
        let endRecommendations = await Axios.get(this.$store.getters.urlServer + "/api/me/recommendations", {
          params: {
            company: this.$route.params.company,
            event: "onEnd"
          },
          headers: {
            Authorization: "Bearer " + this.$user.token
          }
        });
        let idsEnd = endRecommendations.data.recommendations;
        this.itemsEnd = [];
        for (let id of idsEnd) {
          let item = await this.searchItem(id);
          if (item) {
            this.itemsEnd.push(item);
          }
        }
        this.loading = false;
      },
      async loadUnloggedRecommendations() {
        let recommendations = await Axios.get(this.$store.getters.urlServer
          + "/api/branches/" + this.$route.params.branch
          + "/companies/" + this.$route.params.company + "/recommendations", {
          params: {
            event: "onStart"
          }
        });
        let ids = recommendations.data.recommendations;
        this.items = [];
        for (let id of ids) {
          let item = await this.searchItem(id);
          if (item) {
            this.items.push(item);
          }
        }
        if (this.toShow && this.items.length > 0) {
          this.mode = "start";
          this.show = true;
          this.toShow = false;
        }
        let endRecommendations = await Axios.get(this.$store.getters.urlServer
          + "/api/branches/" + this.$route.params.branch
          + "/companies/" + this.$route.params.company + "/recommendations", {
          params: {
            event: "onEnd"
          }
        });
        let idsEnd = endRecommendations.data.recommendations;
        this.itemsEnd = [];
        for (let id of idsEnd) {
          let item = await this.searchItem(id);
          if (item) {
            this.itemsEnd.push(item);
          }
        }
        this.loading = false;
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp #recommenderModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;

    &.modal.smaller {
      .content {
        width: 600px;
      }
    }
  }

  #iShopFoodApp .modal .content {
    display: flex;
    border-radius: 10px;
    height: 770px;
    width: 900px;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    .left {
      width: 300px;
      flex-shrink: 0;
      background-size: cover;
      background-position: right;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .leftImage {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 100%;
      }
    }
    .right {
      padding: 20px;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .big-title {
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .svgicon {
        fill: var(--primary-color);
        margin-right: 12px;
      }
    }
  }

  #iShopFoodApp .items {
    overflow-y: auto;
    flex-grow: 1;
    margin-right: -10px;
    padding-right: 10px;

    .item {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      display: flex;
      position: relative;
      min-height: 120px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 40px;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          border-bottom: 2px dashed #e6e6e6;
          top: calc(100% + 20px);
        }
      }
      .image {
        width: 150px;
        flex-shrink: 0;
        .asyncImage {
          height: 100%;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
      .middle {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .description {
        color: #808080 !important;
      }
    }
  }

  #iShopFoodApp .priceContainer {
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 0;
    .price {
      display: flex;
      .round {
        font-size: 40px;
        font-weight: 900;
        color: var(--primary-color) !important;
      }
      .cents {
        font-size: 20px;
        padding-top: 2px;
        font-weight: bold;
        color: var(--primary-color) !important;
      }
    }
  }

  .buttons {
    display: flex;
    margin-top: 20px;
    flex-shrink: 0;
    .grow {
      flex-grow: 1;
    }
  }

  .recommender-description {
    margin-top: 5px;
  }

  @media (max-width: 600px) {
    #iShopFoodApp .modal .content.modal-content {
      border-radius: 0;
      width: 100%;
      height: 100%;
      margin: 0;

      .right {
      }
      .left {
        display: none;
      }
      .right {
        overflow-y: auto;
        margin-bottom: 35px;
        height: 100%;
      }

      .big-title {
        justify-content: center;
        padding: 20px;
        font-size: 16px;
        margin: -20px -20px 10px;
        position: relative;
        flex-shrink: 0;
        .svgicon {
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }

      .buttons .btn {
        width: 100%;
        border-radius: 0;
      }

      .section .btn {
        flex-grow: 1;
      }

      .section {
        @supports (-webkit-touch-callout: none) {
          flex-shrink: 0;
        }
      }
      .shops:not(.selected) {
        @supports (-webkit-touch-callout: none) {
          min-height: 200px;
          max-height: 200px;
        }
      }

      .buttons {
        margin-top: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .close {
        display: none;
      }

      .item {
        .image {
          width: 100px;
        }
        .priceContainer {
          display: none;
        }
      }

      .items {
        padding: 0 20px;
        margin: 20px -20px 20px;
      }
      .item {
        min-height: 80px;
        &:after {
          display: none;
        }
        margin-bottom: 10px;
        .name {
          font-size: 15px;
        }
        .description {
          font-size: 12px;
        }
      }
      .item .middle {
        padding: 10px;
      }

    }
  }

</style>

<style lang="scss">

  #iShopFoodApp .item .image.contain {
    .asyncImage, .whileLoading {
      background-size: contain;
    }
  }

</style>
