<template>
  <div class="qr-payment__invoice collapsible collapse--closed">
    <template v-if="currentInvoice">

      <div class="invoice__title">
        <div class="invoice__check" :class="{'invoice__check--selected': currentInvoice.selected, 'invoice__check--paid': currentInvoice.isPaid()}">
          <input :id="'check__input' + currentInvoice.id" type="checkbox" :checked="currentInvoice.selected" class="check__input" @change="toggleSelect"
                 :disabled="invoice.isPaid() ? 'disabled' : false" >
          <label class="check__label invoice--number" :for="'check__input' + invoice.id">
            {{ $t('qr_payment.invoice') }} #{{invoice.id}}
            <span class="paid" v-if="invoice.isPaid()">{{$t('qr_payment.paid')}}</span>
          </label>
          <span class="check--paid-icon ion-ios-checkmark" v-if="invoice.isPaid()"></span>
        </div>
        <div class="collapse--toggle" @click="toggleOpen">
          <template v-if="!currentInvoice.open">{{$t('qr_payment.details')}}</template>
          <template v-if="currentInvoice.open">{{$t('qr_payment.close')}}</template>
        </div>
      </div>
      <div class="invoice__details collapse__content" :class="{'collapse__content--visible': currentInvoice.open, 'collapse__content--hidden': !currentInvoice.open}">
        <div class="invoice-detail__seat" v-for="seat of invoice.seats" :key="'seat-' + seat.id">
          <div class="seat__id">
            <span class="seat-id__background">{{$t('qr_payment.seat')}} #{{seat.id}}</span></div>
          <div class="invoice-detail__items invoice-detail__items--spacer">
            <div class="invoice-detail__item" v-for="(item, itemIndex) of seat.items" :key="'item-' + itemIndex + '-' + seat.id + '-' + invoice.id">
              <div class="item__info">
                <div class="item__name">
                  {{item.name}}
                </div>
              </div>
              <div class="item__modifiers" v-if="item.items.length > 0">
                <div class="modifier__info" v-for="modifier of item.items" :key="'modifier-' + modifier.id + '-' + 'item.id' + '-' + seat.id + '-' + invoice.id">
                  <div class="modifier__name">
                    {{modifier.name}} <template v-if="modifier.quantity > 1">({{modifier.quantity}})</template>
                  </div>
                  <div class="modifier__price">
                    + {{$tc((modifier.unitPrice * modifier.quantity).toFixed(2))}}
                  </div>
                </div>
              </div>
              <div class="item__info">
                <div class="item__qty">
                  {{ $t("item.quantity") }}: <span class="bold">{{item.quantity}}</span>
                </div>
                <div class="item__price">
                  {{$tc((item.unitPrice * item.quantity).toFixed(2))}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr--dashed margin-top">

        <div class="invoice-details__total">
          <div class="invoice-total__info info__promotion" v-if="invoice.promotionTotal < 0">
            <div class="total-info__label">{{$t('total.promotions')}}</div>
            <div class="total-info__value">{{$tc(invoice.promotionTotal.toFixed(2))}}</div>
          </div>
          <div class="invoice-total__info info__subtotal">
            <div class="total-info__label">{{$t('total.subtotal')}}</div>
            <div class="total-info__value">{{$tc(invoice.subTotal.toFixed(2))}}</div>
          </div>
          <div class="invoice-total__info info__tax" v-for="(tax, taxIndex) of invoice.taxes" :key="'tax-' + taxIndex">
            <div class="total-info__label">{{tax.name}}</div>
            <div class="total-info__value">{{$tc(tax.total.toFixed(2))}}</div>
          </div>
          <div class="invoice-total__info info__service" v-if="invoice.serviceFees > 0">
            <div class="total-info__label">{{$t('total.service_fees')}}</div>
            <div class="total-info__value">{{$tc(invoice.serviceFees.toFixed(2))}}</div>
          </div>
          <div class="invoice-total__info info__tip" v-if="invoice.tipTotal > 0">
            <div class="total-info__label">{{$t('total.tip')}}</div>
            <div class="total-info__value">{{$tc(invoice.tipTotal.toFixed(2))}}</div>
          </div>
          <div class="invoice-total__info info__total">
            <div class="total-info__label">{{$t('total.total')}}</div>
            <div class="total-info__value">{{$tc(invoice.totalToPay.toFixed(2))}}</div>
          </div>
        </div>

      </div>

    </template>
  </div>
</template>

<script>
  export default {
    name: "qrPaymentInvoice",

    props: {
      invoice: Object
    },

    computed: {
      currentInvoice() {
        return this.invoice;
      },
      company() {
        return this.$store.state.currentCompany;
      }
    },

    methods: {
      toggleOpen() {
        this.$emit("open");
      },
      toggleSelect() {
        this.$emit("select");
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    input[type=checkbox] + label:before, input[type=checkbox] + label:after{
      color: #d9d9d9;
      font-weight: normal;
    }
    .qr-payment__invoice {
      background-color: white;
      margin: 12px 15px 0 15px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 15px;

      &:last-child {
        margin-bottom: 12px;
      }

      .invoice__title {
        display: flex;
        justify-content: space-between;
        padding: 15px;
      }

      .collapse__content {
        overflow: hidden;
        transition: max-height 400ms ease-in-out;

        &.collapse__content--visible {
          max-height: 700px;
          overflow: auto;
          animation: hide-scroll 1.2s backwards;
        }

        &.collapse__content--hidden {
          max-height: 0;
          overflow: hidden;
        }

        @keyframes hide-scroll {
          from, to { overflow: hidden; }
        }
      }

      .invoice-detail__seat {
        padding: 0 16px;
        color: #808080;

        &:not(:first-child) {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 16px;
        }
      }

      .seat__id {
        font-weight: 500;
        text-align: center;
        margin-bottom: -9px;
        .seat-id__background {
          color: rgb(191,191,191) !important;
          font-size: 12px;
          background-color: #ffffff;
          padding: 0 10px;
        }
      }

      .invoice-detail__items--spacer {
        border-top: 1px solid #e6e6e6;
      }

      .invoice-detail__item {
        padding: 15px 0;
        &:not(:first-child) {
          border-top: 1px solid #e6e6e6;
        }

        &:last-child {
          padding-bottom: 0px;
        }
      }

      .item__name {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 3px;
      }

      .modifier__name {
        font-size: 12px;
      }

      .item__info, .modifier__info {
        display: flex;
        justify-content: space-between;
      }

      .modifier__info {
        .modifier__name, .modifier__price {
          color: #808080;
        }
      }

      .item__info {
        font-size: 14px;
        align-items: baseline;
        .item__qty {
          font-size: 12px;
          color: #808080;
        }
        .bold {
          font-weight: bold;
        }
      }

      .modifier__price {
        font-size: 14px;
      }

      .item__price {
        font-size: 16px;
        font-weight: bold;
      }

      .collapse--toggle {
        font-size: 14px;
        color: var(--primary-color) !important;
      }

      .check__label {
        font-size: 15px;
        font-weight: bold;
      }

      .invoice__check--selected {
        input[type=checkbox] + label:before, input[type=checkbox] + label:after{
          color: var(--primary-color) !important;
        }
        .check__label {
          color: var(--primary-color) !important;
        }
      }

      .margin-top {
        margin-top: 15px;
      }

      hr.hr--dashed {
        border-top: 2px dashed #e6e6e6;
      }

      hr {
        margin: 10px 0;
      }

      .invoice-details__total {
        color: #808080;
        padding: 4px 16px 0 16px;
      }

      .invoice-total__info {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        &:last-child {
          padding-bottom: 16px;
        }
      }

      .info__tax, .info__service, .info__tip, .info__promotion {
        .total-info__label, .total-info__value {
          color: #808080;
        }
      }

      .info__subtotal, .info__total {
        font-weight: bold;
        .total-info__label, .total-info__value {
          color: #1a1a1a;
        }
      }

      .info__total {
        align-items: baseline;
        .total-info__value {
          font-size: 16px;
        }
      }

      .invoice__check {
        position: relative;

        .check--paid-icon {
          position: absolute;
          top: -8px;
          left: 6px;
          font-size: 30px;
          color: #56b656 !important;
        }
      }

      .invoice__check--paid {
        input[type=checkbox] + label:before {
          color: #56b656 !important;
        }
      }

      .paid {
        opacity: 0.6;
        font-weight: normal;

        &::before {
          content: "|";
          opacity: 0.6;
          padding: 0 12px;
        }
      }
    }
  }
</style>
