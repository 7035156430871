<template>
  <div id="kioskPayment">

    <div class="method--how">
      {{ $t('kiosk_payment.how') }}
    </div>

    <div class="methods">
      <div class="method--type method__cash" @click="payWith('cash')" @contextmenu="payWith('cash')"
        v-if="kioskConfiguration.hasCashPayment()">
        <div class="type--icon">
          <svgicon src="icons/cash.svg" :height="200" :width="200"></svgicon>
        </div>
        <div class="type--name">{{ $t('kiosk_payment.cash') }}</div>
      </div>
      <div class="method--type method__payfactoPax" @click="payWith(kioskConfiguration.PAYFACTO_PAX)"
        @contextmenu="payWith(kioskConfiguration.PAYFACTO_PAX)" v-if="kioskConfiguration.hasPayfactoPaxPayment()">
        <div class="type--icon">
          <svgicon src="icons/credit-card.svg" :height="200" :width="200"></svgicon>
        </div>
        <div class="type--name">{{ $t('kiosk_payment.credit_card') }}</div>
      </div>
      <div class="method--type method__globalFlex" @click="payWith(kioskConfiguration.GLOBAL_FLEX)"
        @contextmenu="payWith(kioskConfiguration.GLOBAL_FLEX)" v-if="kioskConfiguration.hasGlobalFlexPayment()">
        <div class="type--icon">
          <svgicon src="icons/credit-card.svg" :height="200" :width="200"></svgicon>
        </div>
        <div class="type--name">{{ $t('kiosk_payment.credit_card') }}</div>
      </div>
      <div class="method--type method__ampUSA" @click="payWith(kioskConfiguration.AMP_USA)"
           @contextmenu="payWith(kioskConfiguration.AMP_USA)" v-if="kioskConfiguration.hasAmpUSAPayment()">
        <div class="type--icon">
          <svgicon src="icons/credit-card.svg" :height="200" :width="200"></svgicon>
        </div>
        <div class="type--name">{{ $t('kiosk_payment.credit_card') }}</div>
      </div>
      <div class="method--type method__itc" @click="showItcPayment"
           @contextmenu="showItcPayment" v-if="kioskConfiguration.hasItcPayment()">
        <div class="type--icon">
          <svgicon src="icons/card-phone.svg" :height="200" :width="200"></svgicon>
        </div>
        <div class="type--name">{{ $t('kiosk_configuration.account') }}</div>
      </div>
    </div>

    <div class="method--actions">
      <button class="btn btn-primary" @click="back" @contextmenu="back">{{ $t('back') }}</button>
    </div>

    <div id="modalNumPadITC" class="modal kiosk" :class="{show: showItcModal}">
      <div class="modal-content">
        <div class="modal-header">{{isItcPaymentValidated ? $t('payment.payment') : $t('kiosk_configuration.account')}}</div>
        <div class="modal-body">
          <div class="content">
            <template v-if="!isItcPaymentValidated">
              <div class="infos">
                <div> {{$t('kiosk_payment.enter_account_id_description')}} </div>
              </div>
              <div class="input">
                <input ref="accountModalInput" type="text" readonly v-model="itcCardNumber">
              </div>
              <div class="numbers">
                <div class="number-row">
                  <div class="btn btn-primary" @click="addAccountNum(7)" @contextmenu="addAccountNum(7)">7</div>
                  <div class="btn btn-primary" @click="addAccountNum(8)" @contextmenu="addAccountNum(8)">8</div>
                  <div class="btn btn-primary" @click="addAccountNum(9)" @contextmenu="addAccountNum(9)">9</div>
                </div>
                <div class="number-row">
                  <div class="btn btn-primary" @click="addAccountNum(4)" @contextmenu="addAccountNum(4)">4</div>
                  <div class="btn btn-primary" @click="addAccountNum(5)" @contextmenu="addAccountNum(5)">5</div>
                  <div class="btn btn-primary" @click="addAccountNum(6)" @contextmenu="addAccountNum(6)">6</div>
                </div>
                <div class="number-row">
                  <div class="btn btn-primary" @click="addAccountNum(1)" @contextmenu="addAccountNum(1)">1</div>
                  <div class="btn btn-primary" @click="addAccountNum(2)" @contextmenu="addAccountNum(2)">2</div>
                  <div class="btn btn-primary" @click="addAccountNum(3)" @contextmenu="addAccountNum(3)">3</div>
                </div>
                <div class="number-row">
                  <div class="btn btn-primary wide" @click="addAccountNum(0)" @contextmenu="addAccountNum(0)">0</div>
                  <div class="btn btn-primary" @click="removeAccountNum" @contextmenu="removeAccountNum">
                    <svgicon src="icons/remove.svg" :height="40" :width="40"></svgicon>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="isItcPaymentValidated">
              <div class="itc-confirmation-warning-subtext">{{ $t('kiosk_payment.account_proceed_to_payment') }}</div>
              <div class="itc-confirmation-values"><div class="bold">{{ $t('kiosk_payment.card_number') }}:</div> <div>{{ itcCardNumber }}</div></div>
              <div class="itc-confirmation-values"><div class="bold">{{ $t('kiosk_payment.account_name') }}:</div> <div>{{ itcAccountName }}</div></div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modalQuitAndClean" @contextmenu="modalQuitAndClean">{{ $t('back') }}</button>
          <template v-if="!isItcPaymentValidated">
            <button type="button" class="btn btn-primary" @click="prepareItcForPaymentConfirmation" @contextmenu="prepareItcForPaymentConfirmation">
              {{ $t('confirm') }}
            </button>
          </template>
          <template v-if="isItcPaymentValidated">
            <button type="button" class="btn btn-primary" @click="confirmItcPayment" @contextmenu="confirmItcPayment">
              {{ $t('confirm') }}
            </button>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Flex from "../../lib/payment/flex";
  import { AmpUSA } from "@/lib/payment/amp-usa";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import { PastReceipts } from "@/util/kiosk/PastReceipts";
  import { PaymentProcessor } from "@/PaymentProcessor";

  export default {
    name: "kioskPayment",

    data() {
      return {
        payfactoPayment: null,
        showItcModal: false,
        itcCardNumber: "",
        isItcPaymentValidated: false,
        itcAccountName: "",
        isItcPayment: false,
        scanString: "",
        scanTimeout: null,
        lastScanTimestamp: 0
      };
    },

    mounted() {
      this.setupKioskScan();
    },

    beforeDestroy() {
      if (this.kioskConfiguration && this.company && this.company.hasPaymentMethod("itc")) {
        document.removeEventListener("keypress", this.scanProduct);
        clearTimeout(this.scanTimeout);
      }
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      isCallingSystemEnabled() {
        return this.kioskConfiguration && this.kioskConfiguration.hasCallingSystemForMethod(this.$order.method);
      }
    },

    methods: {
      async payWith(paymentMethod) {
        this.isItcPayment = false;
        await window.logToFile("PAYMENT METHOD SELECTED: " + paymentMethod, this.$order.getUniqueId());
        let success;
        switch (paymentMethod) {
          case "cash":
            this.$order.setPaymentMethod("cash");
            break;
          case this.kioskConfiguration.AMP_USA:
            success = await this.payWithAmp();
            hideSpinner();
            if (!success) {
              return;
            }
            break;
          case this.kioskConfiguration.PAYFACTO_PAX:
            this.payfactoPayment = this.$order.addPayfactoPaxPayment(this.kioskConfiguration.terminalData.terminalNumber);
            break;
          case this.kioskConfiguration.GLOBAL_FLEX:
            success = await this.payWithFlex();
            if (!success) {
              return;
            }
            break;
          case this.kioskConfiguration.ITC:
            success = PaymentProcessor.getItcCredentials();
            this.modalQuitAndClean();
            if (!success) {
              return;
            }
            this.isItcPayment = true;
            this.$order.addItcPayment(success);
            break;
        }
        this.sendOrder();
      },

      back() {
        if (this.isCallingSystemEnabled) {
          this.$router.push(this.$router.generate("/kiosk/calling-system"));
        } else {
          this.$router.push(this.$router.generate("/kiosk/confirmation"));
        }
      },

      payWithAmp() {
        showSpinner(this.$t("kiosk_payment.pay"));
        return new Promise((resolve) => {
          let amount = this.$order.getOrderTotal().totalRemaining;

          // Payment callback function
          let onAmpCharge = (data) => {
            let receiptData;
            try {
              receiptData = AmpUSA.formatChargeResponseForReceipt(data);
            } catch (e) {
              console.log("error formating amp receipt", e);
              receiptData = [];
            }
            this.$order.addAmpPaymentAndReceiptForKiosk(receiptData);
            window.logToFile(data, this.$order.getUniqueId());
          };

          // Error callback function
          let onAmpError = async (error) => {
            let receiptData;
            try {
              receiptData = AmpUSA.formatChargeResponseForReceipt(error.response);
            } catch (e) {
              console.log("error formating amp receipt", e);
              receiptData = [];
            }
            window.logToFile(error, this.$order.getUniqueId());
            this.$order.setTpvReceipt(receiptData);
            hideSpinner();
            // Will ask to retry payment
            let response = await showConfirmation(this.$t("kiosk_payment.payment_error"), this.$t("kiosk_payment.payment_error_message"));
            if (response) {
              return await this.payWithAmp();
            }
          };

          // Initialize payment with terminal
          AmpUSA.init(this.kioskConfiguration.getAmpTerminalSerialNumber(), this.kioskConfiguration.getAmpAuthCode())
            .then(() => AmpUSA.charge(amount)) // Try to pay
            .then((data) => onAmpCharge(data))
            .then(() => resolve(true))
            .catch(async (error) => {
              window.logToFile(error, this.$order.getUniqueId());
              if (error.response && error.response === "timeout") {
                AmpUSA.getChargeTransactionResult() // Try to get transaction result of previous transaction
                  .then((data) => onAmpCharge(data))
                  .then(() => resolve(true))
                  .catch(async (error) => {
                    console.log("error", error);
                    if (error.response && error.response === "unreachable") {
                      let message = "WARNING cannot connect to AMP " + new Date().toLocaleString();
                      window.logToFile(message, this.$order.getUniqueId());
                    }
                    resolve(await onAmpError(error));
                  });
              } else {
                resolve(await onAmpError(error));
              }
            });
        });
      },

      async payWithFlex() {
        try {
          showSpinner(this.$t("kiosk_payment.pay"));
          let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
          await tpv.connect();
          let response = await tpv.getStatus();
          if (response && response.response && response.response.responseCode !== tpv._RESPONSE_CODE.SUCCESS) {
            console.error("FLEX TERMINAL UNAVAILABLE");
            hideSpinner();
            await showAffirmation(this.$t("warning"), this.$t("kiosk.terminal_unavailable"));
            return false;
          } else {
            setConfiguration("flex-unavailable", false);
          }
          await tpv.disconnect();
          await tpv.connect();
          let paymentResponse = await tpv.charge(this.$order.getOrderTotal().totalRemaining);
          if (paymentResponse.type === "SUCCESS") {
            let method = paymentResponse.response.cardType.toLowerCase();
            let validMethods = ["visa", "mastercard", "amex", "interac"];
            if (validMethods.indexOf(method) === -1) {
              method = "credit_card";
            }
            this.$order.setPaymentMethod(method);
            paymentResponse.response.tpvType = this.kioskConfiguration.GLOBAL_FLEX;
            this.$order.setTpvReceipt(paymentResponse.response);
            hideSpinner();
          } else {
            hideSpinner();
            let response = await showConfirmation(this.$t("kiosk_payment.payment_error"), this.$t("kiosk_payment.payment_error_message"));
            if (response) {
              return await this.payWithFlex();
            } else {
              return false;
            }
          }
          return true;
        } catch (e) {
          hideSpinner();
          console.error("FLEX TERMINAL PAYMENT ERROR", e);
          let response = await showConfirmation(this.$t("kiosk_payment.payment_error"), this.$t("kiosk_payment.payment_error_message"));
          if (response) {
            return await this.payWithFlex();
          } else {
            return false;
          }
        }
      },

      async sendOrder() {
        //TODO huge sad duplicate of confirmation.vue
        let text = this.payfactoPayment ? this.$t("kiosk_payment.pay") : null;
        showSpinner(text);
        await window.logToFile("SENDING SALE TO API", this.$order.getUniqueId());
        let response = await this.$order.sendSale();
        this.kioskConfiguration.incrementTransactionNumber();
        await window.logToFile("SALE");
        await window.logToFile(response);

        if (response.success) {
          let sale = response.response;
          await IshopPrinter.load({
            sale: sale,
            company: this.company,
            configuration: this.kioskConfiguration,
            isSuccess: true
          }).printSale();
          if (this.payfactoPayment) {
            this.kioskConfiguration.saveCurrentPayfactoBatchNumber(sale);
            this.payfactoPayment = null;
            if (sale.posFailed) { /* PayFacto POS error */
              await window.logToFile("SALE POS FAILED", this.$order.getUniqueId());
              hideSpinner();
              this.$router.push(this.$router.generate("/kiosk/sale-warning"));
              return;
            }
          }
          hideSpinner();
          /* post-sale analytics */
          this.$ga.event("sale-confirmation", this.company.nameCanonical, this.$user.email);
          this.$ga.ecommerce.addTransaction({
            id: sale.id,
            affiliation: sale.company,
            revenue: sale.total,
            currency: sale.currency
          });
          for (let detail of sale.details) {
            this.$ga.ecommerce.addItem({
              id: sale.id,
              sku: detail.id,
              name: detail.name,
              quantity: detail.quantity,
              price: detail.unitPrice
            });
          }
          this.$ga.ecommerce.send();
          if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("Purchase", {
              value: parseFloat(this.total.subTotal.toFixed(2)),
              currency: "CAD"
            });
          }
          // Redirect after sale
          this.$router.push(this.$router.generate("/kiosk/thank-you"));
          setConfiguration("last-sale-id", sale.kioskNumber, sessionStorage);
        } else {
          await window.logToFile("SALE ERROR STATUS" + response.status, this.$order.getUniqueId());
          if (response.status === 409) {
            console.warn("Duplicated sale submitted. No action taken.");
            hideSpinner();
            return;
          }
          this.$order.onlinePayments = [];
          this.payfactoPayment = null;
          this.$order.paymentMethod = null;
          let saleData = {};
          if (response.response && response.response.sale) {
            try {
              if (typeof response.response.sale !== "object") {
                saleData = JSON.parse(response.response.sale);
              } else {
                saleData = response.response.sale;
              }
            } catch (e) {
              console.log(e, response);
              console.log("error parsing response or printing sale");
            }
          }
          if (response.response && response.response.response) {
            try {
              if (typeof response.response.response !== "object") {
                saleData = JSON.parse(response.response.response);
              } else {
                saleData = response.response.sale;
              }
            } catch (e) {
              console.log(e, response);
              console.log("error parsing response or printing sale");
            }
          }
          let iShopPrinter = IshopPrinter.load({
            sale: saleData.sale ? saleData.sale : saleData,
            company: this.company,
            configuration: this.kioskConfiguration,
            isSuccess: false
          });
          await iShopPrinter.printSale();

          hideSpinner();

          if (response.status >= 400 && response.status < 500) {
            let parsed = response;
            if (response.response && response.response.key) {
              parsed = response.response;
            } else if (response.response && response.response.response) {
              try {
                parsed = JSON.parse(response.response.response);
              } catch (e) {
                parsed = {};
                console.log("error parsing sale");
                console.log(e);
              }
            }
            if (parsed.sale && parsed.sale.tpvReceipt) { //TPV receipt data will be defined if a successful payment transaction was processed with amp or flex
              try {
                let receiptData = JSON.parse(parsed.sale.tpvReceipt);
                if (receiptData.tpvType && (receiptData.tpvType === this.kioskConfiguration.GLOBAL_FLEX || receiptData.tpvType === this.kioskConfiguration.AMP_USA)) {
                  this.$router.push(this.$router.generate("/kiosk/sale-warning"));
                  return;
                }
              } catch (e) {
                console.log("error parsing sale");
                console.log(e);
              }
            }

            if (parsed.key === "soft_sale_error.unmapped_item_exception") {
              if (parsed.params.id) {
                let item = this.$store.state.inventory.search(parsed.params.id);
                let result = await showConfirmation(this.$t("error.title"), this.$t("order.item_out_of_stock").replace("{NAME}", this.$to(item.name)) + "<br><br>" + this.$t("error.preauthorize"), null, null);
                if (result) {
                  this.$order.items = this.$order.items.filter(i => i.id !== parsed.params.id && i.getId() !== parsed.params.id);
                  if (this.$order.items.length === 0) {
                    this.$router.push(this.$router.generate("/menu"));
                    return;
                  }
                  for (let item of this.$order.items) {
                    for (let choice of item.getChoices()) {
                      if (choice.selected.id === parsed.params.id) {
                        choice.selected = null;
                        this.$router.push(this.$router.generate("/itemEdit/" + this.$order.items.indexOf(item)));
                      }
                    }
                    for (let choiceGroup of item.getChoiceGroups()) {
                      for (let choice of choiceGroup.getChoices()) {
                        if (choice.selected.id === parsed.params.id) {
                          choice.selected = null;
                          this.$router.push(this.$router.generate("/itemEdit/" + this.$order.items.indexOf(item)));
                        }
                      }
                    }
                    for (let modifier of item.getModifiers()) {
                      if (modifier.id === parsed.params.id) {
                        modifier.quantity = 0;
                      }
                    }
                    for (let modifierGroup of item.getModifierGroups()) {
                      for (let modifier of modifierGroup.modifiers) {
                        if (modifier.id === parsed.params.id) {
                          modifier.quantity = 0;
                        }
                      }
                    }
                  }
                  if (!this.$order.validateMinimumAmount()) {
                    this.$router.push(this.$router.generate("/menu"));
                  }
                }
              } else {
                if (this.company.getPaymentMethod("paysafe")) {
                  showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_paysafe"));
                } else {
                  showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_error_kiosk"));
                }
              }
            } else {
              if (this.company.getPaymentMethod("paysafe")) {
                showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_paysafe"));
              } else {
                showAffirmation(this.$t("error.title"), this.$t("error." + parsed.key));
              }
            }
          } else {
            if (this.isItcPayment) {
              showAffirmation(this.$t("error.title"), this.$t("kiosk_payment.account_payment_error"));
              return;
            }
            let errorMessage = "";
            if (iShopPrinter.getSale()) {
              errorMessage = iShopPrinter.getSale().getPaymentErrorMessage();
            }
            if (errorMessage) {
              showAffirmation(this.$t("error.title"), this.$t(errorMessage));
            } else {
              showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_error_kiosk"));
            }
          }
        }
      },

      showItcPayment() {
        this.showItcModal = true;
      },
      async prepareItcForPaymentConfirmation() {
        let cardNumber = this.itcCardNumber.replace(/[^a-zA-Z0-9]/g, "");
        if (cardNumber == "") {
          showAffirmation(this.$t("error.title"), this.$t("invalid_form"));
          return;
        }
        let accountName = await PaymentProcessor.getItcBalanceFromAccount(cardNumber);

        if (!accountName) {
          this.modalQuit();
          return;
        }
        //Payment is valid, show confirmation modal to proceed to payment.
        this.itcAccountName = accountName;
        this.showItcPaymentConfirmation();
      },
      showItcPaymentConfirmation() {
        //Seamlessly fake a modal switch to account confirmation
        this.showItcModal = false;
        setTimeout(() => {
          this.isItcPaymentValidated = true;
          this.showItcModal = true;
        }, 300);
      },
      confirmItcPayment() {
        if (this.isItcPaymentValidated && this.itcCardNumber) {
          this.payWith("itc");
        }
      },
      modalQuit() {
        this.showItcModal = false;
        setTimeout(() => {
          this.clearItcModalData();
        }, 300);
      },
      modalQuitAndClean() {
        this.modalQuit();
        PaymentProcessor.clearItcAccountInformations();
      },
      clearItcModalData() {
        this.isItcPaymentValidated = false;
        this.itcCardNumber = "";
        this.itcAccountName = "";
      },
      addAccountNum(num) {
        this.itcCardNumber += num.toString();
      },
      removeAccountNum() {
        if (this.itcCardNumber && this.itcCardNumber.length > 0) {
          this.itcCardNumber = this.itcCardNumber.slice(0, -1);
        }
      },
      setupKioskScan() {
        if (!this.kioskConfiguration || !this.company || !this.company.hasPaymentMethod("itc")) {
          return;
        }
        document.addEventListener("keypress", this.scanProduct);
      },
      scanProduct(e) {
        const DELAY = this.kioskConfiguration.getScannerDelay(); //ms
        let time = (new Date()).getTime();
        if (time > this.lastScanTimestamp + DELAY) {
          this.scanString = "";
        }
        this.lastScanTimestamp = time;
        if (e.key.length === 1) {
          this.scanString += e.key;
        }
        if (this.scanTimeout) {
          clearTimeout(this.scanTimeout);
        }
        this.scanTimeout = setTimeout(() => {
          this.itcCardNumber = this.scanString;
          this.prepareItcForPaymentConfirmation();
        }, DELAY);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #kioskPayment {
    align-items: center;
    justify-content: center;
    position: relative;

    .methods {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .method--how {
      font-size: 45px;
      text-align: center;
      font-weight: bold;
      padding: 0 150px 150px 150px;
    }

    .method--type {
      $size: 360px;
      background-color: white;
      border-radius: 20px;
      fill: var(--primary-color);
      width: $size;
      height: $size;
      margin: 40px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);

      .type--icon {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .type--name {
        padding: 20px 40px;
        text-align: center;
        font-size: 26px;
        text-transform: uppercase;
        color: var(--primary-color);
        font-family: 'Segoe UI Black', sans-serif;
      }
    }

    .method--actions {
      padding-top: 150px;
    }

    #modalNumPadITC {
      .modal-content {
        border-radius: 20px;
        padding: 40px;
        .content {
          text-align: center;
          font-size: 20px;
        }
      }

      .modal-header {
        font-family: 'Segoe UI Black';
        color: var(--primary-color);
        text-transform: uppercase;
        text-align: center;
        font-size: 28px;
        justify-content: center;
        padding-bottom: 20px;
      }

      .input {
        margin-bottom: 15px;
        input {
          font-size: 36px;
          text-align: center;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 1;
        }
      }
      .modal-footer {
        margin: 30px 0 0 0;
        border-top: 1px solid #e6e6e6;
        padding-top: 30px;

        .btn {
          padding: 20px !important;
        }
      }
      .number-row {
        display: flex;
        justify-content: center;

        .btn {
          margin: 5px;
          width: 120px;
          font-size: 34px;
          font-family: "Segoe UI Black", sans-serif;
          fill: white;
          &.wide {
            width: 250px;
          }
        }
      }
      .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon_container {
          padding: 15px;
          border: none !important;
          background-color: transparent !important;
        }
      }
      .bold {
        font-weight: bold;
      }
      .itc-confirmation-warning {
        font-weight: bold;
        font-size: 42px;
        padding-bottom: 20px;
      }
      .itc-confirmation-warning-subtext {
        font-size: 22px;
        padding-bottom: 30px;
      }
      .itc-confirmation-values {
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
