import Branding from "./BrandingModel";
import Category from "./CategoryModel";
import Choice from "./ChoiceModel";
import ChoiceGroup from "./ChoiceGroupModel";
import { Constant } from "@/util/Constant";
import Item from "./ItemModel";
import { LocalizeStore } from "../lib/localize";
import Modifier from "./ModifierModel";
import ModifierGroup from "./ModifierGroupModel";
import { PromotionManager } from "../PromotionManager";
import Store from "../store";
import Supplement from "./SupplementModel";
import Tag from "./TagModel";
import Ticket from "@/models/TicketModel";
import { Util } from "@/util/Util";

/**
 * @typedef InventoryDefinition
 * @property {number} id
 * @property {Category[]} root
 * @property {Item[]} hidden
 * @property {Supplement[]} supplements
 * @property {String[]} locales
 * @property {Tag[]} tags
 * @property {Branding[]} brandings
 * @property {boolean} allowCrossBrandOrdering
 * @property {boolean} brandingActivated
 */

export default class Inventory {

  /**
   * @param {InventoryDefinition} [definition]
   */
  constructor(definition) {
    this.reset();
    if (definition) {
      this.load(definition);
    }
  }

  reset() {
    this.definition = null;
    this.id = null;
    this.root = /** @type {Category[]} */ ([]);
    this.hidden = /** @type {Item[]} **/ ([]);
    this.allItems = /** @type {Item[]} **/ ([]);
    this.supplements = [];
    this.tags = [];
    this.locales = [];
    this.lastInventoryLoad = null;
    this.currency = null;
    this.brandings = /** @type {Branding[]} */ ([]);
    this.lastUpdateTimestamp = null;
    this.brandingActivated = false;
    this.allowCrossBrandOrdering = false;
    this.tickets = [];
    this.revenueCenters = /** @type {RevenueCenter[]} */ ([]);
    this.inventoryMap = new Map();
    this.inventoryRawData = new Map();
  }

  /**
   * @param {InventoryDefinition} [definition]
   */
  load(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.root = definition.root || [];
    this.hidden = definition.hidden || [];
    this.allItems = definition.allItems || [];
    this.supplements = definition.supplements || [];
    this.locales = definition.locales || [];
    this.tags = definition.tags || [];
    this.brandings = definition.brandings || [];
    this.allowCrossBrandOrdering = definition.allowCrossBrandOrdering;
    this.brandingActivated = definition.brandingActivated;
    this.currency = definition.currency;
    this.lastUpdateTimestamp = new Date();
    this.tickets = definition.tickets || [];
    this.revenueCenters = definition.revenueCenters || [];
    this.createHashInventory();
  }

  /**
   * @param {number|string} inventoryId
   * @param {[]} entities
   * @returns {Promise<[]>}
   */
  async inventoryEntityFetch(inventoryId, entities) {
    let calls = [];
    let catalog = CONFIG.kiosk ? "kiosk" : "online";
    entities = entities.filter(e => Constant.INVENTORY_ENTITIES.indexOf(e) > -1);
    for (let entity of entities) {
      let endpoint = entity;
      if (entity === Constant.CATEGORIES_ENTITY && !CONFIG.pos) {
        endpoint += "?catalog=" + catalog;
      }
      if (entity === Constant.INVENTORY_ENTITY) {
        calls.push(Store.dispatch("request", {
          method: "GET",
          url: "/api/inventory/" + inventoryId
        }));
      } else {
        calls.push(Store.dispatch("request", {
          method: "GET",
          url: "/api/inventory/" + inventoryId + "/" + endpoint
        }));
      }
    }
    let allData = await Promise.all(calls);
    let response = [];
    for (let i = 0; i < allData.length; i++) {
      response.push({
        data: allData[i],
        entity: entities[i]
      });
    }
    return response;
  }

  updateRawInventoryData(newData) {
    for (let i = 0; i < newData.length; i++) {
      this.inventoryRawData.set(newData[i].entity, newData[i].data);
    }
  }

  async fetch(inventoryId, options) {

    /**
     * TODO this fetch function now takes too long to load the menu, something is wrong here
     */

    if (inventoryId == this.lastInventoryLoad && !options?.forceRefresh) {
      return;
    }

    this.lastInventoryLoad = inventoryId;
    if (!options?.executeInBackground) {
      showSpinner();
    }

    // Load raw data
    let entities = options.entities || Constant.INVENTORY_ENTITIES;
    this.inventoryEntityFetch(inventoryId, entities)
      .then(response => {

        this.updateRawInventoryData(response);

        this.root = /** @type {Category[]} */ ([]);

        let itemsData = this.inventoryRawData.get(Constant.ITEMS_ENTITY);
        let categoriesData = this.inventoryRawData.get(Constant.CATEGORIES_ENTITY);
        let modifiersData = this.inventoryRawData.get(Constant.MODIFIERS_ENTITY);
        let modifierGroupsData = this.inventoryRawData.get(Constant.MODIFIER_GROUPS_ENTITY);
        let choiceItemsData = this.inventoryRawData.get(Constant.CHOICE_ITEMS_ENTITY);
        let choicesData = this.inventoryRawData.get(Constant.CHOICES_ENTITY);
        let choiceGroupsData = this.inventoryRawData.get(Constant.CHOICE_GROUPS_ENTITY);
        let supplementsData = this.inventoryRawData.get(Constant.SUPPLEMENTS_ENTITY);
        let imagesData = this.inventoryRawData.get(Constant.IMAGES_ENTITY);
        let variantsData = this.inventoryRawData.get(Constant.VARIANTS_ENTITY);
        let brandingsData = this.inventoryRawData.get(Constant.BRANDINGS_ENTITY);
        let orderRequirements = this.inventoryRawData.get(Constant.ORDER_REQUIREMENTS_ENTITY);
        let conditionsData = this.inventoryRawData.get(Constant.CONDITIONS_ENTITY);
        let inventoryData = this.inventoryRawData.get(Constant.INVENTORY_ENTITY);
        let tagsData = this.inventoryRawData.get(Constant.TAGS_ENTITY);

        if (!options.persist) {
          this.inventoryRawData.clear();
        }

        let images = {};
        for (let image of imagesData) {
          images[image.id] = image.url;
        }
        let tags = {};
        for (let tag of tagsData) {
          tags[tag.id] = new Tag().load(tag);
        }

        /* Process raw data */

        let modifiers = /** @type {Modifier[]} */ (modifiersData.map(m => {
          let newModifier = new Modifier(m);
          if (m.image) {
            newModifier.image = images[m.image];
          }
          return newModifier;
        }));

        let modifierGroups = /** @type {ModifierGroup[]} */ (modifierGroupsData.map(m => {
          let newModifierGroup = new ModifierGroup(m);
          newModifierGroup.modifiers = modifiers.filter(o => m.modifiers.indexOf(o.id) > -1);
          return newModifierGroup;
        }));

        let choiceItems = /** @type {Item[]} */ (choiceItemsData.map(i => {
          let newChoiceItem = new Item(i);
          if (i.image) {
            newChoiceItem.image = images[i.image];
          }
          // Link tags
          if (i.tags && i.tags.length > 0) {
            for (let tag of i.tags) {
              if (!tags[tag]) {
                tags[tag] = new Tag().load({ id: tag });
              }
              newChoiceItem.tags.push(tags[tag]);
              tags[tag].choiceItems.push(newChoiceItem);
            }
          }

          // Link modifier groups
          newChoiceItem.modifierGroups = modifierGroups.filter(m => i.modifierGroups.indexOf(m.id) > -1).map(m => m.clone());

          return newChoiceItem;
        }));

        let choices = /** @type {Choice[]} */ (choicesData.map(c => {
          let newChoice = new Choice(c);
          for (let item of choiceItems) {
            if (c.slaveItems.indexOf(item.id) > -1) {
              newChoice.items.push(item);
            }
            if (item.definition.choices.indexOf(newChoice.id) > -1) {
              item.choices.push(newChoice);
            }
          }
          newChoice.selectDefault();

          // Link modifier groups
          newChoice.modifierGroups = modifierGroups.filter(m => c.modifierGroups.indexOf(m.id) > -1).map(m => m.clone());

          //Link choices to modifiers
          for (let modifier of modifiers) {
            let index = modifier.choices.indexOf(newChoice.id);
            if (index > -1) {
              modifier.choices[index] = newChoice;
            }
          }

          return newChoice;
        }));

        // Link subchoices
        for (let i = 0; i < choiceItems.length; i++) {
          choiceItems[i].choices = choices.filter(c => choiceItemsData[i].choices.indexOf(c.id) > -1);
        }
        // Clone items to fix promotions
        for (let choice of choices) {
          choice.items = choice.items.map(i => i.clone());
        }

        let choiceGroups = /** @type {ChoiceGroup[]} */ (choiceGroupsData.map(c => {
          let newChoiceGroup = new ChoiceGroup(c);
          newChoiceGroup.choices = choices.filter(o => c.itemChoices.indexOf(o.id) > -1).map(c => c.clone());
          return newChoiceGroup;
        }));

        let supplements = /** @type {Supplement[]} */ (supplementsData.map(s => {
          let supplement = new Supplement(s);
          if (s.image) {
            supplement.image = images[s.image];
          }
          // Link choices
          supplement.choices = choices.filter(c => s.choices.indexOf(c.id) > -1);
          return supplement;
        }));

        let variants = /** */ (variantsData.map(v => {
          let variant = new Item(v);
          if (v.image) {
            variant.image = images[v.image];
          }

          // Link modifiers
          variant.modifiers = modifiers.filter(m => v.modifiers.indexOf(m.id) > -1);
          // Link modifier groups
          variant.modifierGroups = modifierGroups.filter(m => v.modifierGroups.indexOf(m.id) > -1);
          // Link choices
          variant.choices = choices.filter(c => v.choices.indexOf(c.id) > -1);
          // Link choice groups
          variant.choiceGroups = choiceGroups.filter(c => v.choiceGroups.indexOf(c.id) > -1);

          // Link tags
          if (v.tags.length > 0) {
            for (let tag of v.tags) {
              if (!tags[tag]) {
                tags[tag] = new Tag().load({ id: tag });
              }
              variant.tags.push(tags[tag]);
              tags[tag].items.push(variant);
            }
          }

          return variant;
        }));

        let items = /** @type {Item[]} */ (itemsData.map(i => {

          let newItem = new Item(i);
          if (i.image) {
            newItem.image = images[i.image];
          }

          // Link categories
          // if (!i.deleted) {
          //   for (let j = 0; j < categoriesData.length; j++) {
          //     if (categoriesData[j].items.indexOf(newItem.id) > -1) {
          //       categories[j].addItem(newItem);
          //       // newItem.categories.push(categories[j]);
          //     }
          //   }
          // }
          // Link modifiers
          newItem.modifiers = modifiers.filter(m => i.modifiers.indexOf(m.id) > -1);
          // Link modifier groups
          newItem.modifierGroups = modifierGroups.filter(m => i.modifierGroups.indexOf(m.id) > -1);
          // Link choices
          newItem.choices = choices.filter(c => i.choices.indexOf(c.id) > -1);
          // Link choice groups
          newItem.choiceGroups = choiceGroups.filter(c => i.choiceGroups.indexOf(c.id) > -1);
          // Link variants
          let newItemVariants = variants.filter(v => i.variants.indexOf(v.id) > -1);
          for (let newItemVariant of newItemVariants) {
            newItemVariant.parent = newItem;
          }
          newItem.variants = newItemVariants;
          // Link tags
          if (i.tags.length > 0) {
            for (let tag of i.tags) {
              if (!tags[tag]) {
                tags[tag] = new Tag().load({ id: tag });
              }
              newItem.tags.push(tags[tag]);
              tags[tag].items.push(newItem);
            }
          }

          return newItem;

        }));
        this.allItems = items;

        let categories = /** @type {Category[]} */ (categoriesData.map(c => {
          if (c.availability) {
            c.availability = conditionsData.find(a => a.id === c.availability);
          }
          let newCategory = new Category(c);
          if (c.image) {
            newCategory.image = images[c.image];
          }
          // Link products
          let productsToAdd = items.filter(i => c.items.indexOf(i.id) > -1)
            .sort((a, b) => c.items.indexOf(a.id) - c.items.indexOf(b.id));
          for (let item of productsToAdd) {
            newCategory.addItem(item);
            item.categories.push(newCategory);
          }
          return newCategory;
        }));

        let hiddenProducts = items.filter(i => i.categories.length === 0);

        let brandings = [];
        if (inventoryData.brandingActivated) {
          brandings = brandingsData.map(b => {
            let branding = new Branding(b);
            let brandingCategories = [];
            for (let category of branding.categories) {
              let foundCategory = categories.find(c => c.id === category);
              if (foundCategory) {
                brandingCategories.push(foundCategory);
              }
            }
            branding.categories = brandingCategories;
            return branding;
          });
        }

        Store.state.inventory = new Inventory({
          id: inventoryId,
          locales: inventoryData.languages,
          root: categories.sort((a, b) => a.priority - b.priority),
          hidden: hiddenProducts,
          allItems: items,
          brandings: brandings,
          brandingActivated: inventoryData.brandingActivated,
          allowCrossBrandOrdering: inventoryData.allowCrossBrandOrdering,
          currency: inventoryData.currency,
          supplements,
          revenueCenters: this.revenueCenters,
          tags: Object.values(tags)
        });

        if (Store.state.currentCompany.orderRequirementsActivated && orderRequirements.length > 0) {
          Store.state.currentCompany.orderRequirements = orderRequirements[0].items;
        }

        LocalizeStore.commit("setCurrency", inventoryData.currency);
        Util.Money.setCurrency(inventoryData.currency);

        // Load promotions
        PromotionManager.load();

        if (!options?.executeInBackground) {
          hideSpinner();
        }

        return { success: true };

      }).catch((e) => {
        console.log("inventory fetch error", e);
        hideSpinner();
        return { success: false };
      });
  }

  async fetchTickets(inventoryId) {
    try {
      let ticketsData = await Store.dispatch("request", {
        method: "GET",
        url: "/api/inventory/" + inventoryId + "/custom_items?class=aims&augment=image"
      });
      let tickets = ticketsData.map(t => {
        return new Ticket(t);
      });

      Store.state.inventory = new Inventory({
        id: inventoryId,
        tickets: tickets
      });
      Store.state.inventory.lastUpdateTimestamp = new Date();

      return { success: true };
    } catch (e) {
      console.log("inventory fetch error", e);
      return { success: false };
    }
  }

  /**
   * @param {RevenueCenter[]} revenueCenters
   */
  setRevenueCenters(revenueCenters) {
    this.revenueCenters = revenueCenters;
  }

  /**
   * @param {string} ref
   * @returns {RevenueCenter|undefined}
   */
  getRevenueCenterByRef(ref) {
    if (!ref) {
      return;
    }
    return this.revenueCenters.find(r => r.ref === ref);
  }

  /**
   * @returns {Category[]}
   */
  getRoot() {
    let revenueCenter = this.getRevenueCenterByRef(Store.state.currentRevenueCenterRef);
    if (this.revenueCenters.length > 0 && revenueCenter) {
      let root = [];
      for (let rootCategory of this.root) {
        let catalogCategory = revenueCenter.getCatalogCategoryById(rootCategory.id);
        if (catalogCategory && catalogCategory.items.length > 0) { //Only showing non-empty categories from catalog
          let category = rootCategory.clone();
          let items = [];
          for (let item of category.items) {
            if (catalogCategory.items.find(id => id === item.id)) { //Only showing items existing in catalog category
              items.push(item);
            }
          }
          category.items = items;
          root.push(category);
        }
      }
      return root;
    }
    return this.root || [];
  }

  /**
   * Returns all items in inventory, including hidden ones, in a flat array.
   * @returns {Item[]}
   */
  getAllItems() {
    return this.allItems || [];
  }

  /**
   * @returns {boolean}
   */
  hasSupplements() {
    return this.supplements.length > 0;
  }

  isEnglishLocaleOnly() {
    return this.locales.indexOf("en") > -1 && this.locales.length === 1;
  }

  /**
   * @return {boolean}
   */
  isBrandingActivated() {
    return this.brandingActivated && this.brandings.length > 0;
  }

  getBranding(brandingId) {
    return this.brandings.find(b => b.id === brandingId);
  }

  /**
   * @param {Category[]} root
   */
  setRoot(root) {
    this.root = root;
  }

  /**
   * @param {Supplement[]} supplements
   */
  setSupplements(supplements) {
    this.supplements = supplements;
  }

  /**
   * @param {number} categoryId
   * @returns {Category|null}
   */
  getCategory(categoryId) {
    return this.root.find(c => c.id === categoryId);
  }

  searchWithUPC(upc, parsedUpc) {
    if (!parsedUpc) {
      parsedUpc = "000";
    }
    upc = upc.replace(/[^a-zA-Z0-9]/g, "");

    for (let category of this.root) {
      for (let item of category.items) {

        if ((!item.priceEncodedInUpc && item.upc === upc) || (item.priceEncodedInUpc && item.upc === parsedUpc)) {
          return item.clone();
        }
        for (let variant of item.variants) {
          if ((!variant.priceEncodedInUpc && variant.upc === upc) || (variant.priceEncodedInUpc && variant.upc === parsedUpc)) {
            if (variant.parent) {
              let foundItem = variant.parent.clone();
              foundItem.selectedVariant = variant;
              return foundItem;
            }
          }
        }
      }
    }
  }

  /**
   * @param {string} searchString
   */
  searchByName(searchString) {
    let items = [];
    searchString = Util.removeDiacritics(searchString).toLowerCase();
    for (let category of this.root) {
      // Items
      for (let item of category.items) {
        let itemName = Util.removeDiacritics(window.translate(item.name)).toLowerCase();
        let itemMatch = itemName.indexOf(searchString) > -1;
        if (itemMatch && !items.find(i => i.id === item.id)) {
          items.push(item);
          // Variants
        } else if (!itemMatch) {
          for (let variant of item.variants) {
            let variantName = Util.removeDiacritics(window.translate(variant.name)).toLowerCase();
            let variantMatch = variantName.indexOf(searchString) > -1;
            if (variantMatch && !items.find(i => i.id === item.id)) {
              items.push(item);
            }
          }
        }
      }
    }
    return items;
  }

  /**
   * @param {number} id
   * @param {Category[]} root
   * @returns {Item|Modifier|Choice}
   */
  search(id, root = null) {
    if (!root) {
      root = this.root;
    }
    for (let category of this.root) {
      for (let item of category.items) {
        if (item.id == id) {
          return item.clone();
        }
        for (let variant of item.variants) {
          if (variant.id == id) {
            return variant;
          }
        }
        for (let combo of item.getChoices()) {
          for (let item of combo.items) {
            if (item.id == id) {
              return item;
            }
          }
        }
        for (let comboGroup of item.getChoiceGroups()) {
          for (let combo of comboGroup.choices) {
            for (let item of combo.items) {
              if (item.id == id) {
                return item;
              }
            }
          }
        }
        for (let modifier of item.getModifiers()) {
          if (modifier.id == id) {
            return modifier;
          }
        }
        for (let modifierGroup of item.getModifierGroups()) {
          for (let modifier of modifierGroup.modifiers) {
            if (modifier.id == id) {
              return modifier;
            }
          }
        }
      }
    }
    return null;
  }

  /**
   * create a hash map of id->item from inventory tree, no.
   */
  createHashInventory() {
    for (let category of this.root) {
      for (let item of category.items) {
        this.inventoryMap.set(item.id, item);
      }
    }
  }

  getItemById(id) {
    if (id && this.inventoryMap) {
      let item = this.inventoryMap.get(parseInt(id));
      if (item) {
        return item;
      }
    }
    return null;
  }

}
