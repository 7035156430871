<template>
  <div id="qr-payment__invoices">
    <template v-if="$store.getters.isMobileOrTablet">

      <Header @back="back" :hide-back-button="scanCheckOnly"></Header>

      <div class="invoices-holder" v-if="sale">
        <div class="btn-select-all" v-if="sale.invoices.length > 1 && !disableMultiCheckSelection">
          <button class="btn btn-primary" @click="selectAllInvoices">
            {{ $t("qr_payment.invoices.select_all") }}
          </button>
        </div>
        <Invoice v-for="(invoice,invoiceIndex) of sale.invoices" :ref="'invoice-' + invoiceIndex" :key="'invoice-' + invoiceIndex"
                 :invoice="invoice" @open="invoice.toggleOpen()" @select="selectInvoice(invoice)"></Invoice>
      </div>

      <div class="invoices-total">
        <div class="total-info__label">
          {{$t('loyalty.total_to_pay')}}
        </div>
        <div class="total-info__amount">
          {{ $tc(selectedInvoicesTotal.toFixed(2)) }}
        </div>
      </div>

      <div class="buttons">
        <button class="btn btn-primary button--pay" :class="{'button__disabled': selectedInvoices.length === 0}"
                @click="displayConfirmationModal">
          <template v-if="selectedInvoices.length === 0">
            <template v-if="shouldDisplaySingleCheckTranslations">
              {{$t('qr_payment.invoices.please_select_single_invoice_to_pay')}}
            </template>
            <template v-if="!shouldDisplaySingleCheckTranslations">
              {{$t('qr_payment.invoices.please_select_invoice_to_pay')}}
            </template>
          </template>
          <template v-if="selectedInvoices.length == 1">
            {{$t('qr_payment.invoices.pay_invoice')}}
          </template>
          <template v-if="selectedInvoices.length > 1">
            {{$t('qr_payment.invoices.pay_invoices')}}
          </template>
        </button>
      </div>

      <div id="invoices__confirmation-modal" class="modal" :class="{show: showConfirmationModal}">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-body__description">
              <template v-if="shouldDisplaySingleCheckTranslations">
                {{$t('qr_payment.invoices.confirmation_single')}}
              </template>
              <template v-if="!shouldDisplaySingleCheckTranslations">
                {{$t('qr_payment.invoices.confirmation')}}
              </template>
            </div>
            <div class="modal-body__invoices">
              <div class="modal-body__invoice" v-for="invoice of selectedInvoices" :key="'selected-invoice-' + invoice.id">
                {{$t('qr_payment.invoice')}} #{{invoice.id}}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary button--back" @click="showConfirmationModal = false">{{ $t('back') }}</button>
            <button type="button" class="btn btn-primary button--confirm" @click="confirm">{{ $t('confirm') }}</button>
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <DesktopNotSupported></DesktopNotSupported>
    </template>
  </div>
</template>

<script>
  import DesktopNotSupported from "@/components/qrPayment/DesktopNotSupported.vue";
  import Header from "@/components/qrPayment/Header.vue";
  import Invoice from "@/components/qrPayment/Invoice.vue";

  export default {
    name: "qrPaymentInvoices",

    components: { DesktopNotSupported, Header, Invoice },

    data() {
      return {
        showConfirmationModal: false
      };
    },

    mounted() {
      if (!this.$order || !this.$order.tableNumber) {
        this.back();
        return;
      }

      //When coming back to this page, will reselect past selected invoices
      for (let invoice of this.$order.invoices) {
        let found = this.sale.getInvoiceFromId(invoice.id);
        if (found) {
          found.selected = true;
        }
      }
    },

    computed: {
      sale() {
        return this.$store.state.sale;
      },
      selectedInvoicesTotal() {
        return this.sale ? this.sale.getSelectedInvoicesTotal() : 0;
      },
      selectedInvoices() {
        return this.sale ? this.sale.getSelectedInvoices() : [];
      },
      company() {
        return this.$store.state.currentCompany;
      },
      scanCheckOnly() {
        return this.company && this.company.scanCheckOnlyForQrPayment();
      },
      invoices() {
        return this.sale.invoices;
      },
      disableMultiCheckSelection() {
        return this.company && this.company.disableMultiCheckSelectionForQrPayment();
      },
      shouldDisplaySingleCheckTranslations() {
        return !this.invoices || this.invoices.length < 2;
      }
    },

    methods: {
      back() {
        this.redirect("/table");
      },
      confirm() {
        this.$order.invoices = [...this.selectedInvoices];
        this.redirect("/table/payment");
      },
      redirect(url) {
        this.$router.push({ path: this.$router.generate(url), query: this.$route.query });
      },
      selectInvoice(invoice) {
        invoice.toggleSelected();
        if (invoice.selected && this.disableMultiCheckSelection) {
          let otherInvoices = this.invoices.filter(i => i.id !== invoice.id);
          for (let invoice of otherInvoices) {
            invoice.unselect();
          }
        }
      },
      selectAllInvoices() {
        this.sale.invoices.forEach(invoice => {
          invoice.selected = true;
        });
      },
      displayConfirmationModal() {
        if (this.selectedInvoices.length < 1) {
          return;
        }
        this.showConfirmationModal = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    .invoices-holder {
      overflow: auto;
      flex-grow: 1;
      background-color: #f6f6f6;
    }
    .btn-select-all {
      margin: 12px 15px 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #invoices__confirmation-modal {
      .modal-content {
        padding: 40px;
        border-radius: 10px;
        max-width: 310px;
      }
      .modal-body {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
      }
      .modal-body__invoices {
        overflow-y: auto;
        font-size: 16px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-grow: 1;
        align-items: center;
        margin-top: 15px;
        max-height: 50vh;
      }
      .modal-body__invoice {
        font-weight: bold;
        width: 100%;
        text-align: center;
        &:not(:first-child) {
          padding-top: 15px;
        }
      }
      .modal-body__description {
        text-align: center;
      }
      .modal-footer {
        border-top: 2px dashed #e6e6e6;
        padding-top: 30px;
        margin-top: 25px;
      }
      .button--back {
        padding: 10px 30px;
      }
      .button--confirm {
        padding: 10px 30px;
      }
    }

    .invoices-total {
      background-color: white;
      padding: 12px 30px 8px 30px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e6e6e6;
      border-bottom: 2px dashed #e6e6e6;
      font-weight: bold;
      font-size: 14px;

      .total-info__amount {
        font-size: 18px;
      }
    }

    .buttons {
      background-color: white;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 20px;
    }
    .button--pay {
      text-align: center;
      font-size: 15px;
      border-radius: 10px;
      padding: 20px 20px;
      flex-grow: 1;
      flex-basis: 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.button__disabled {
        opacity: 0.8;
      }
    }
  }
</style>
