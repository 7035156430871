import EventBus from "@/lib/eventBus";

/**
 * @typedef CalculatorParametersDefinition
 * @parameter {function} callback
 * @parameter {string} title
 * @parameter {number?} defaultValue
 * @parameter {boolean?} hiddenValue
 * @parameter {number?} minValue
 * @parameter {number?} maxValue
 * @parameter {boolean?} allowNegativeValues
 * @parameter {boolean?} isFloat
 */

class Modal {

    /**
     * @param {CalculatorParametersDefinition} parameters
     * @returns {Promise<number>}
     */
    calculator(parameters) {
        return new Promise((resolve) => {
            EventBus.$emit("show-calculator", {
                callback: (response) => {
                    resolve(response);
                },
                parameters
            });
        });
    };

    /**
     * @param title
     * @param defaultValue
     * @param maximumCharacterCount
     * @returns {Promise<string>}
     */
    keyboard(title, defaultValue, maximumCharacterCount = null) {
        return new Promise(resolve => {
            EventBus.$emit("show-keyboard", {
                title,
                value: defaultValue,
                maximumCharacterCount,
                callback: value => {
                    resolve(value);
                }
            });
        });
    };

}

let modal = new Modal();
export { modal as Modal };