<template>
  <div id="releaseNotes">

    <div class="release" v-for="(release, releaseIndex) of notes" :key="releaseIndex">

      <div class="column release__header">
        <div class="release__version column__left">{{ release.getVersion() }}</div>
        <div class="release__date column__right">
          <div class="date--main">{{ release.getReleaseDateAsFormattedString() }}</div>
          <div class="date--since">{{ $t("pos_configuration.days_ago").replace("{DAYS}", release.getRangeFromReleaseToNow()) }}</div>
        </div>
      </div>

      <div class="column release__description" v-if="$t(release.getDescriptionNew())">
        <div class="release__title column__left">{{ $t("pos_configuration.release_notes_new") }}</div>
        <div class="release__content column__right">{{ $t(release.getDescriptionNew()) }}</div>
      </div>

      <div class="column release__description" v-if="$t(release.getDescriptionImproved())">
        <div class="release__title column__left">{{ $t("pos_configuration.release_notes_improved") }}</div>
        <div class="release__content column__right">{{ $t(release.getDescriptionImproved()) }}</div>
      </div>

      <div class="column release__description" v-if="$t(release.getDescriptionFixed())">
        <div class="release__title column__left">{{ $t("pos_configuration.release_notes_fixed") }}</div>
        <div class="release__content column__right">{{ $t(release.getDescriptionFixed()) }}</div>
      </div>

    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import ReleaseNote from "../../models/pos/ReleaseNote";

  export default {
    name: "releaseNotes",

    props: {
      appName: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        notes: [],
      }
    },

    async mounted() {
      showSpinner();
      let notesHistory = await this.getAppVersions({appName: this.appName});
      if (notesHistory && notesHistory.success && notesHistory.data && CONFIG.platform) {
        this.notes = [];
        for (let releaseNote of notesHistory.data.platform[CONFIG.platform]) {
          this.notes.push(new ReleaseNote(releaseNote));
        }
      }
      hideSpinner();
    },

    methods: {
      ...mapActions(["getAppVersions"])
    }
  }
</script>

<style lang="scss" scoped >
  #releaseNotes {

    .release:not(:last-of-type) {
      border-bottom: 2px dashed var(--border-color-4);
    }
    .column {
      display: flex;
      padding: 0 0 20px 0;
      &.release__header {
        padding-top: 20px;
      }
    }
    .column__left {
      display: flex;
      width: 115px;
      margin-right: 30px;
      color: var(--primary-color);
      font-weight: bold;
      justify-content: flex-end;
      align-items: flex-start;
      flex-shrink: 0;
      font-size: 18px;
      &.release__version {
        justify-content: center;
        align-items: center;
      }
    }
    .column__right {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      font-size: 20px;
      .date--main {
        font-size: 22px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .date--since {
        color: var(--theme-color-2);
        font-size: 18px;
      }
    }

    .release__version {
      background-color: var(--primary-color);
      border-radius: 12px;
      color: white;
      height: 56px;
      font-size: 18px;
    }

    .release__content {
      white-space: pre-wrap;
    }
  }
</style>