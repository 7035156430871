<template>
  <div id="history-back" @click="historyBack" :class="{visible: isIOS && !showMobileFooter && !$kiosk && !hasBranding && !$pos}">
    <svgicon src="icons/back.svg" :height="24" :width="24"></svgicon>
  </div>
</template>
<script>
  export default {
    name: "history-back",

    computed: {
      isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      },
      showMobileFooter() {
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.style) {
          return false;
        }
        return this.$store.state.currentBranch.style.showMobileFooter;
      },
      hasBranding() {
        return !!this.$order.branding;
      }
    },

    methods: {
      historyBack() {
        window.history.back();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #history-back {
    display: none;
    &.visible {
      z-index: 2;
      position: absolute;
      display: initial;
      bottom: 10px;
      right: 10px;
      background-color: #ed2937;
      padding: 15px;
      border-radius: 30px;
      fill: white;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px 0;
    }
  }
</style>
