//@ts-check
import Modifier from "./ModifierModel";

/**
 * @typedef ModifierGroupDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} description
 * @property {number[]} modifiers
 */

export default class ModifierGroup {

  /**
   * @param {ModifierGroupDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.min = definition.min;
    this.max = definition.max;
    this.description = definition.description;
    this.modifiers = /** @type {Modifier[]} */ ([]);

    this.dummyModifier = new Modifier({
      id: null,
      name: {
        fr: "Aucun",
        en: "None"
      },
      priceSchemes: [],
      quantifiable: false
    });
    this.dummyModifier.image = "img/no_thank_you.png";
  }

  clone() {
    let newModifierGroup = new ModifierGroup(this.definition);
    newModifierGroup.modifiers = this.modifiers.map(m => m.clone());
    return newModifierGroup;
  }

  toDto() {
    return {
      id: this.id,
      modifiers: this.modifiers.map(m => m.toDto())
    };
  }

  quantitySelected() {
    return this.modifiers.reduce((total, modifier) => total + modifier.quantity, 0);
  }

  exactQuantityRequired() {
    return this.min && this.min > 0 && this.max && this.max > 0 && this.min === this.max ? this.min : null;
  }

  minimumRequired() {
    return this.min && this.min > 0 ? this.min : null;
  }

  maximumAllowed() {
    return this.max && this.max > 0 ? this.max : null;
  }

  hasQuantityRequirements() {
    return this.exactQuantityRequired() || this.minimumRequired() || this.maximumAllowed();
  }

  validateQuantitySelected() {
    let quantitySelected = this.quantitySelected();

    if (this.exactQuantityRequired() && quantitySelected !== this.exactQuantityRequired()) {
      return { valid: false, error: "modifier.exact_quantity_no_match", number: this.exactQuantityRequired() };
    }
    if (this.minimumRequired() && this.maximumAllowed() && (quantitySelected < this.minimumRequired() || quantitySelected > this.maximumAllowed())) {
      return { valid: false, error: "modifier.between_quantity", min: this.minimumRequired(), max: this.maximumAllowed() };
    }
    if (this.minimumRequired() && quantitySelected < this.minimumRequired()) {
      return { valid: false, error: "modifier.under_minimum", number: this.minimumRequired() };
    }
    if (this.maximumAllowed() && quantitySelected > this.maximumAllowed()) {
      return { valid: false, error: "modifier.over_maximum", number: this.maximumAllowed() };
    }

    return { valid: true };
  }

  fromDto(dto) {
    this.id = dto.id;
    for (let i = 0; i < this.modifiers.length; i++) {
      this.modifiers[i].fromDto(dto.modifiers[i]);
    }
  }

}
