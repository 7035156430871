<template>
  <div class="panel" :class="{
    xs: $store.state.screenSize == 'xs',
     kiosk: $kiosk,
     selected: selected && isCollapsed,
     closed: isCollapsed,
     locked: locked,
     flat: flat,
     compact: compact,
     hasLeftImage: !!leftImage}">
    <div class="checkmark" v-show="selected && isCollapsed">
      <svgicon class="checkmarkIcon" src="icons/check-circle.svg" :height="20" :width="20"></svgicon>
    </div>
    <div class="leftImage" v-if="leftImage" :style="{backgroundImage: 'url(\'' + leftImage + '\') !important'}"></div>
    <div class="title" :class="{collapsible}" @click="collapse" v-if="title">
      <svgicon class="titleIcon" :src="icon" :height="20" :width="20" v-if="icon"></svgicon>
      <span v-html="title"></span>
      <div class="flex-grow"></div>
      <svgicon class="panel-arrow-icon" src="icons/arrow.svg" :height="20" :width="20" v-show="collapsible && isCollapsed"></svgicon>
      <svgicon class="panel-arrow-icon" src="icons/arrow-up.svg" :height="20" :width="20" v-show="collapsible && !isCollapsed"></svgicon>
    </div>
    <div class="content" :class="{noTitle: !title, collapsed: isCollapsed}">
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  export default {

    name: "panel",

    props: {
      title: String,
      icon: String,
      leftImage: String,
      compact: Boolean,
      flat: Boolean,
      locked: Boolean,
      collapsible: Boolean,
      collapsed: Boolean,
      selected: Boolean
    },

    data() {
      return {
        isCollapsed: this.collapsed
      };
    },

    methods: {
      collapse() {
        if (typeof this.collapsible === "undefined" || !this.collapsible) {
          return;
        }
        this.isCollapsed = !this.isCollapsed;
      },
      open() {
        this.isCollapsed = false;
      },
      close() {
        this.isCollapsed = true;
      }
    }

  }

</script>

<style lang="scss" scoped>

#iShopFoodApp.pos {
  .panel {
    background-color: var(--background-color-2);
    &>.content {
      &:not(.noTitle):before {
        border-bottom: 1px solid var(--background-color-4);
      }

    }
  }
}

  #iShopFoodApp .panel {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    flex-shrink: 0;
    position: relative;
    .container {
      border-radius: 10px;
    }

    &.kiosk {
      border-radius: 20px;
      margin-bottom: 30px;
      box-shadow: none;

      & > .title {
        padding: 30px 30px 16px 30px;
        font-size: 20px;
      }

      & > .content {
        .container {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    &.locked {
      & > .content {
        position: relative;
        pointer-events: none;

        * {
          pointer-events: none;
        }

        &::after {
          content: "";
          position: absolute;
          background-color: rgba(255,255,255,0.5);
          pointer-events: none;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }

    &.flat {
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    &.compact {
      background-color: transparent !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 10px;

      &.xs {
        > .title {
          font-size: 16px;
        }
        .container {
          padding-top: 0;
        }
      }

      > .title {
        font-size: 18px;
        padding: 16px 0;
      }

      & > .content {
        &:not(.noTitle):before {
          content: none;
        }
      }
    }

    &.xs:not(.compact) {
      box-shadow: none;
      border-top: 1px solid rgb(230,230,230);
      border-bottom: 1px solid rgb(230,230,230);
      border-radius: 0;
      &:not(:first-of-type) {
        border-top: 1px solid #e6e6e6;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid #e6e6e6;
      }
      .title {
        font-size: 16px;

        .svgicon:not(.titleIcon) {
          margin-left: 10px;
        }
      }
    }

    &.selected {
      margin-left: 40px;
    }

  }

  .panel > .title {
    padding: 20px 20px 16px 20px;
    display: flex;
    align-items: center;
    fill: var(--primary-color);
    font-size: 18px;
    font-weight: 700;
    &.collapsible {
      cursor: pointer;
    }
    .titleIcon {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .flex-grow {
      flex-grow: 1;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .panel > .content {
    overflow: hidden;
    position: relative;
    &:not(.noTitle):before {
      content: "";
      position: absolute;
      top: 0;
      height: 0;
      left: 20px;
      right: 20px;
      border-bottom: 1px solid #e6e6e6;
    }
    &.collapsed {
      display: none;
    }
    .container {
      padding: 15px;
    }
  }

  .checkmark {
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
  }

  .hasLeftImage {
    position: relative;
  }

  .leftImage {
    float: left;
    height: 100%;
    width: 290px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #iShopFoodApp.xs {
    .leftImage {
      display: none;
    }
    .checkmark {
      display: none;
    }
    .panel {
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      &.selected {
        margin-left: 0;
      }
    }
  }

</style>
