<template>
  <div class="externalLink" @click="accessWebsite">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "ExternalLink",

    props: {
      url: {
        type: String,
        required: true
      },
      target: {
        type: String,
        default: typeof cordova !== "undefined" ? "_blank" : "_system"
      },
      // NO SPACE IN THE OPTIONS OR IT WONT WORK
      options: {
        type: String,
        default: "location=no,usewkwebview=yes,toolbar=yes"
      }
    },

    methods: {
      accessWebsite() {
        if (!this.url) {
          return;
        }
        window.openExternalWebsite(this.url, this.target, this.options);
      }
    }
  }
</script>
