import { PromotionManager } from "../PromotionManager";
/**
 * @typedef PromotionModalDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} modalTitle
 * @property {{fr: string, en: string}} promotionTitle
 * @property {{fr: string, en: string}} description
 * @property {number[]} promotions
 * @property {{imagePosition: string}} configuration
 * @property {any} image
 * @property {boolean} active
 */

export default class PromotionModal {

  /**
   * @param {PromotionModalDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.active = definition.active;
    this.description = definition.description;
    this.modalTitle = definition.modalTitle;
    this.promotionTitle = definition.promotionTitle;
    this.configuration = definition.configuration;
    this.image = definition.image;
    this.links = definition.links;
    this.promotions = definition.promotions;
  }

}
