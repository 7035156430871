/**
 * @typedef RevenueCenterDefinition
 * @property {number} id
 * @property {string} ref
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {RevenueCenterLayoutDefinition[]} layouts
 * @property {RevenueCenterCatalogDefinition} catalog
 */

/**
 * @typedef RevenueCenterLayoutDefinition
 * @property {number} id
 * @property {string} name
 * @property {RevenueCenterLayoutSectionDefinition} cartBot
 * @property {RevenueCenterLayoutSectionDefinition} cartTop
 * @property {RevenueCenterLayoutSectionDefinition} paymentBot
 * @property {{id: number, name: string}[]} roles
 */

/**
 * @typedef RevenueCenterLayoutSectionDefinition
 * @property {number} cols
 * @property {number} rows
 * @property {LayoutFunctionDefinition[]} list
 */

/**
 * @typedef LayoutFunctionDefinition
 * @property {number} id
 * @property {{name: {fr: string, en: string}}} customData
 */

/**
 * @typedef RevenueCenterCatalogDefinition
 * @property {number} id
 * @property {{id: number, items: number[]}[]} categories
 */

export default class RevenueCenter {

  /**
   * @param {RevenueCenterDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.catalog = definition.catalog;
    this.description = definition.description;
    this.ref = definition.ref;
    this.layouts = definition.layouts || [];
    this.name = definition.name;
  }

  /**
   * @param {number} roleId
   * @returns {LayoutFunctionDefinition[]}
   */
  getCartTopFunctionsForRoleId(roleId) {
    let layout = this.getLayoutForRoleId(roleId);
    if (layout) {
      return layout.cartTop.list || [];
    }
    return [];
  }

  /**
   * @param {number} roleId
   * @returns {LayoutFunctionDefinition[]}
   */
  getCartBottomFunctionsForRoleId(roleId) {
    let layout = this.getLayoutForRoleId(roleId);
    if (layout) {
      return layout.cartBot.list || [];
    }
    return [];
  }

  /**
   * @param roleId
   * @returns {RevenueCenterLayoutDefinition}
   */
  getLayoutForRoleId(roleId) {
    return this.layouts.find(l => !!l.roles.find(r => r.id === roleId));
  }

  /**
   * @returns {{id: number, items: number[]}[]|[]}
   */
  getCatalogCategories() {
    return this.catalog ? this.catalog.categories : [];
  }

  /**
   * @param {number} categoryId
   * @returns {id: number, items: number[]|undefined}
   */
  getCatalogCategoryById(categoryId) {
    let categories = this.getCatalogCategories();
    return categories.find(c => c.id === categoryId);
  }

}