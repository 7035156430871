<template>
  <div id="iShopFoodApp" class="iShopFoodApp"
       :style="{zoom: $pos && $store.state.zoom ? $store.state.zoom : 1}"
       :class="{
    'old-safari': isOldSafari,
    xs: $store.state.screenSize == 'xs',
    kiosk: $kiosk,
    pos: $pos,
    'qr-payment': $store.state.qrPaymentMode,
    dark: posConfiguration && posConfiguration.darkTheme,
    'no-animation': posConfiguration && !posConfiguration.animated}">

    <template v-if="$pos">
      <OfflineBar></OfflineBar>
    </template>

    <template v-if="!$kiosk && !$pos">
      <div class="navigationContainer" :class="{
        quick: $store.state.quickMode,
        show: showQuickNavigation,
        md: $store.state.screenSize == 'md',
        initialHide: !$store.state.initialLoading
      }"
        @click="showQuickNavigation = false">
        <Navigation v-if="$store.state.showNavigationCart"></Navigation>
      </div>
      <MobileNavigation></MobileNavigation>
      <SlideOverlay></SlideOverlay>
    </template>

    <template v-if="$pos">
      <div class="pos-container__sidebar">
        <PosNavigation></PosNavigation>
        <div class="pos-container__header">
          <PosHeader></PosHeader>
          <div class="pos-container__main">
            <template v-if="shouldDisplayPosCart && !isPosCartLeftHandedMode">
              <PosCart></PosCart>
            </template>
            <router-view class="content routerViewContent md" />
            <template v-if="shouldDisplayPosCart && isPosCartLeftHandedMode">
              <PosCart></PosCart>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!$pos">
      <router-view class="content routerViewContent" :class="{
      sm: $store.state.screenSize == 'sm' || $store.state.screenSize == 'xs',
      md: $store.state.screenSize == 'md',
      vertical: kioskConfiguration && kioskConfiguration.isVertical(),
      vertical__AIRxTOUCH: isVerticalAirxTouch,
      AIRxTOUCH__32: isVerticalAirxTouch && kioskConfiguration.isAIRxTOUCH32Inch(),
      horizontal:  kioskConfiguration && kioskConfiguration.isHorizontal(),
      mobileMenuVisible: $store.state.mobileMenuShown,
      mobileCartVisible: $store.state.mobileCartShown,
      quickMode: $store.state.quickMode,
      initialHide: !$store.state.initialLoading && !$kiosk  && !$pos,
      progressBarFooter: canDisplayFooter && showProgressBar && showMobileFooter,
      progressBar: canDisplayFooter && showProgressBar && !showMobileFooter,
      mobileFooter: canDisplayFooter && !showProgressBar && showMobileFooter,
      mobilePadding: !canDisplayFooter || (!showProgressBar && !showMobileFooter)
    }" />
    </template>

    <template v-if="!$kiosk && !$pos">
      <transition name="slide-fade">
        <Footer :show-progress-bar="showProgressBar" :show-footer="showMobileFooter" v-if="canDisplayFooter && (showProgressBar || showMobileFooter)"></Footer>
      </transition>
      <transition name="slide-fade">
        <div class="bottomPad" v-if="$store.getters.isMobileOrTablet && (!canDisplayFooter || (!showProgressBar && !showMobileFooter)) && isAllowedPageForBottomNotchSpace"></div>
      </transition>

      <Cart v-if="$store.state.showNavigationCart && !$store.state.displayMode"></Cart>
      <MobileCart v-if="$store.state.showNavigationCart" :class="{initialHide: !$store.state.initialLoading}"></MobileCart>
    </template>

    <template v-if="$kiosk && kioskConfiguration && kioskConfiguration.isHorizontal()">
      <KioskCart v-if="shouldDisplayKioskCart"></KioskCart>
    </template>

    <div id="modalConfirmation" class="modal" :class="{kiosk: $kiosk, pos: $pos}">
      <div class="modal-content">
        <div class="modal-header"></div>
        <div class="modal-body">
          <Illustration class="illustrationcontinue hidden" src="icons/continue.svg"></Illustration>
          <Illustration class="illustrationcancel hidden" src="icons/cart-full.svg"></Illustration>
          <div class="content"></div>
        </div>
        <div class="modal-footer">
          <button type="button" v-wave class="btn btn-secondary modal-no">{{ $t('no') }}</button>
          <button type="button" v-wave class="btn btn-primary modal-yes">{{ $t('yes') }}</button>
        </div>
      </div>
    </div>

    <div id="modalAffirmation" class="modal" :class="{kiosk: $kiosk, pos: $pos}">
      <div class="modal-content">
        <svgicon class="success hidden" src="icons/check-circle.svg" :width="25" :height="25"></svgicon>
        <svgicon class="danger hidden" src="icons/check-circle.svg" :width="25" :height="25"></svgicon>
        <div class="modal-header"></div>
        <div class="modal-body">
          <Illustration class="illustrationwarning hidden" src="icons/exclamation-point.svg" small></Illustration>
          <Illustration class="illustrationinactive hidden" src="icons/inactive.svg"></Illustration>
          <Illustration class="illustrationinadvance hidden" src="icons/inadvance.svg"></Illustration>
          <Illustration class="illustrationdiscount hidden" src="icons/discount.svg"></Illustration>
          <Illustration class="illustrationhello hidden" src="icons/hello.svg"></Illustration>
          <Illustration class="illustrationlost hidden" src="icons/lost.svg"></Illustration>
          <div class="content"></div>
        </div>
        <div class="modal-footer">
          <button type="button" v-wave class="btn btn-secondary modal-ok">{{ $t('ok') }}</button>
        </div>
      </div>
    </div>

    <div id="modalNumPad" class="modal" :class="{kiosk: $kiosk, pos: $pos}">
      <div class="modal-content">
        <div class="modal-header">{{$t('enter_password')}}</div>
        <div class="modal-body">
          <div class="content">
            <div class="input">
              <input id="txtNumPad" type="password" readonly>
            </div>
            <div class="numbers">
              <div class="number-row">
                <button id="btn-7" class="btn btn-primary">7</button>
                <button id="btn-8" class="btn btn-primary">8</button>
                <button id="btn-9" class="btn btn-primary">9</button>
              </div>
              <div class="number-row">
                <button id="btn-4" class="btn btn-primary">4</button>
                <button id="btn-5" class="btn btn-primary">5</button>
                <button id="btn-6" class="btn btn-primary">6</button>
              </div>
              <div class="number-row">
                <button id="btn-1" class="btn btn-primary">1</button>
                <button id="btn-2" class="btn btn-primary">2</button>
                <button id="btn-3" class="btn btn-primary">3</button>
              </div>
              <div class="number-row">
                <button id="btn-0" class="btn btn-primary">0</button>
                <button id="btn-clear" class="btn btn-primary">
                  <svgicon src="icons/remove.svg" :height="40" :width="40"></svgicon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary modal-no">{{ $t('back') }}</button>
          <button type="button" class="btn btn-primary modal-yes">{{ $t('confirm') }}</button>
        </div>
      </div>
    </div>

    <div id="modalUpdate" class="modal">
      <div class="modal-content">
        <div class="modal-header"></div>
        <div class="modal-body">
          <Illustration class="illustrationUpdate" src="icons/update.svg"></Illustration>
          <div class="content"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary modal-update">{{ $t('update') }}</button>
        </div>
      </div>
    </div>

    <div id="modalDeliveryError" class="modal" :class="{show: showErrorModal}">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/alert.svg" :height="24" :width="24"></svgicon>
          {{ $t("order.no_delivery") }}
        </div>
        <div class="modal-body">
          <Illustration src="icons/area.svg"></Illustration>
          <div class="text" v-if="!hasTakeout">{{ $t("order.no_delivery_available_1") }} {{ company ? company.phoneNumber : "" }} {{ $t("order.no_delivery_available_2") }}</div>
          <div class="text" v-if="hasTakeout">{{ $t("order.no_delivery_available") }}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-if="!hasTakeout" @click="showErrorModal = false">{{ $t("ok") }}</button>
          <button type="button" class="btn btn-secondary" v-if="hasTakeout" @click="stayInDelivery">{{ $t("order.stay_in_delivery") }}</button>
          <button type="button" class="btn btn-secondary" v-if="hasTakeout" @click="setMethod('takeout')">{{ $t("order.change_to_takeout") }}</button>
        </div>
      </div>
    </div>

    <div id="spinner">
      <div class="img-inner"></div>
      <div class="img"></div>
      <div class="text hidden"></div>
    </div>

    <div class="toasts"></div>

    <TooltipMenu id="globalTooltip" :visible="tooltip.visible" :parent="tooltip.parent">
      <div class="item" :class="{disabled: item.disabled}"
        v-for="item of tooltip.items.filter(i => !i.hidden && (!i.condition || i.condition()))" :key="item.text"
        @click="callTooltipItemEvent(item)">{{ item.tr ? $t(item.tr) : item.text }}</div>
    </TooltipMenu>

    <template v-if="!$kiosk && !$pos && !isQrPayment"><!-- online ordering -->
      <OrderOptionsModal ref="orderOptionsModal"></OrderOptionsModal>
      <AuthenticationModal></AuthenticationModal>
      <OrderClosedModal></OrderClosedModal>
      <MenuDisplayLocationsModal></MenuDisplayLocationsModal>
      <ThirdPartyDeliveryModal></ThirdPartyDeliveryModal>
      <RecommenderModal></RecommenderModal>
    </template>

    <template v-if="!$pos && !isQrPayment"><!-- kiosk & online ordering -->
      <PromotionModal></PromotionModal>
      <BrandingSwitchWarningModal></BrandingSwitchWarningModal>
      <CookieBanner></CookieBanner>
    </template>

    <template v-if="$kiosk || $pos"><!-- kiosk or pos -->
      <ModifierQuantityModal></ModifierQuantityModal>
    </template>

    <template v-if="$kiosk"><!-- kiosk -->
      <KioskUpdateModal></KioskUpdateModal>
    </template>

    <template v-if="$pos"> <!-- pos -->
      <PosCalculator></PosCalculator>
      <VoidModal></VoidModal>
      <HoldModal></HoldModal>
      <PosKeyboard></PosKeyboard>
      <IntroModal></IntroModal>
      <PosInactivityChecker></PosInactivityChecker>
      <PosDispatchModal></PosDispatchModal>
      <TextDisplayModal></TextDisplayModal>
      <ItemRefundModal></ItemRefundModal>
      <PosReceiptModal></PosReceiptModal>
      <OrderRefundModal></OrderRefundModal>
      <PrintInvoiceSelectionModal></PrintInvoiceSelectionModal>
      <SeatSelectionModal></SeatSelectionModal>
      <SplitItemModal></SplitItemModal>
      <ItemTransferModal></ItemTransferModal>
      <InvoiceCreationModal></InvoiceCreationModal>
      <TableModal></TableModal>
      <SeatsModal></SeatsModal>
    </template>

    <template v-if="isQrPayment"> <!-- qr payment (web only) -->
      <AuthenticationModal></AuthenticationModal>
      <CookieBanner></CookieBanner>
    </template>
  </div>
</template>

<script>

  import Axios from "axios";
  import Cart from "@/components/cart/Cart.vue";
  import Navigation from "@/components/Navigation.vue";
  import MobileNavigation from "@/components/MobileNavigation.vue";
  import MobileCart from "@/components/cart/MobileCart.vue";
  import { mapActions } from "vuex";
  import EventBus from "@/lib/eventBus.js";
  import TooltipMenu from "@/components/tools/TooltipMenu.vue";
  import Illustration from "@/components/modal/Illustration.vue";
  import moment from "moment";
  import { PromotionManager } from "./PromotionManager";
  import OrderOptionsModal from "@/components/OrderOptionsModal.vue";
  import RecommenderModal from "@/components/RecommenderModal.vue";
  import MenuDisplayLocationsModal from "@/components/MenuDisplayLocationsModal.vue";
  import Footer from "@/components/Footer.vue";
  import SlideOverlay from "@/components/SlideOverlay.vue";
  import ThirdPartyDeliveryModal from "@/components/ThirdPartyDeliveryModal.vue";
  import OrderClosedModal from "@/components/OrderClosedModal.vue";
  import PromotionModal from "@/components/PromotionModal.vue";
  import AuthenticationModal from "@/components/AuthenticationModal.vue";
  import CookieBanner from "@/components/CookieBanner.vue";
  import onlineOrderingStyleOverride from "@/lib/branding/online-ordering-style-override";
  import kioskStyleOverride from "@/lib/branding/kiosk-style-override";
  import BrandingSwitchWarningModal from "@/components/BrandingSwitchWarningModal.vue";
  import KioskUpdateModal from "@/components/kiosk/KioskUpdateModal";
  import KioskCart from "@/components/kiosk/KioskCart.vue";
  import PosCart from "@/components/pos/PosCart.vue";
  import PosCalculator from "@/components/pos/calculator/Calculator.vue";
  import VoidModal from "@/components/pos/VoidModal";
  import HoldModal from "@/components/pos/HoldModal";
  import OfflineBar from "@/components/pos/OfflineBar";
  import PosHeader from "@/components/pos/PosHeader";
  import PosNavigation from "@/components/pos/PosNavigation";
  import PosKeyboard from "@/components/pos/PosKeyboard";
  import PosDispatchModal from "@/components/PosDispatchModal";
  import OrderRefundModal from "@/components/pos/OrderRefundModal";
  import ModifierQuantityModal from "@/components/ModifierQuantityModal";
  import IntroModal from "@/components/pos/IntroModal";
  import IshopRequestSocket from "@/lib/IshopRequestWebSocket";
  import PosInactivityChecker from "@/components/pos/PosInactivityChecker.vue";
  import ItemRefundModal from "@/components/pos/ItemRefundModal";
  import { StyleGenerator } from "@/util/StyleGenerator";
  import { NetworkMonitor } from "@/networkMonitor";
  import { PermissionManager } from "@/util/PermissionManager";
  import TextDisplayModal from "@/components/pos/TextDisplayModal.vue";
  import PosReceiptModal from "@/components/pos/PosReceiptModal.vue";
  import PrintInvoiceSelectionModal from "@/components/pos/printInvoiceSelectionModal";
  import {Constant} from "@/util/Constant";
  import SeatSelectionModal from "@/components/pos/SeatSelectionModal";
  import SplitItemModal from "@/components/pos/SplitItemModal";
  import ItemTransferModal from "@/components/pos/ItemTransferModal";
  import InvoiceCreationModal from "@/components/pos/InvoiceCreationModal";
  import TableModal from "@/components/pos/TableModal";
  import SeatsModal from "@/components/pos/SeatsModal";

  export default {

    name: "App",

    components: {
      Cart,
      Navigation,
      MobileNavigation,
      MobileCart,
      TooltipMenu,
      Illustration,
      Footer,
      SlideOverlay,
      OrderOptionsModal,
      RecommenderModal,
      MenuDisplayLocationsModal,
      ThirdPartyDeliveryModal,
      OrderClosedModal,
      PromotionModal,
      AuthenticationModal,
      BrandingSwitchWarningModal,
      KioskUpdateModal,
      CookieBanner,
      KioskCart,
      PosCart,
      PosCalculator,
      VoidModal,
      HoldModal,
      OfflineBar,
      PosHeader,
      PosNavigation,
      PosKeyboard,
      ModifierQuantityModal,
      IntroModal,
      PosInactivityChecker,
      ItemRefundModal,
      PosDispatchModal,
      TextDisplayModal,
      PosReceiptModal,
      OrderRefundModal,
      PrintInvoiceSelectionModal,
      SeatSelectionModal,
      SplitItemModal,
      ItemTransferModal,
      InvoiceCreationModal,
      TableModal,
      SeatsModal
    },

    data() {
      return {
        tooltip: {
          parent: null,
          visible: false,
          isOldSafari: false,
          items: []
        },
        showErrorModal: false,
        documentTitleOverride: null,
        maximumSecondsOfInactivityBeforeWarning: 60, // seconds
        inactivityCountdownInWarningPopup: 30, // seconds
        inactivityInterval: null,
        showQuickNavigation: false,
        posSocket: null
      };
    },

    async beforeCreate() {
      let token = getConfiguration("token") || getConfiguration("token", sessionStorage);
      if (token) {
        this.$user.token = token;
        let response = await this.$user.update();
        if (response) {
          if (this.$store.state.currentCompany) {
            this.$ga.event("login", this.$store.state.currentCompany.nameCanonical, this.$user.email);
          }
        } else {
          this.$user.reset();
          setConfiguration("token", null);
          setConfiguration("token", null, sessionStorage);
        }
      }
      try {
        let version = await Axios.get("./version.txt");
        if (version) {
          CONFIG.version = version.data;
          this.$store.state.version = version.data;
        }
      } catch (e) {
        console.warn("Could not read version file");
      }
    },

    async beforeMount() {
      if (getConfiguration("locale")) {
        this.$ts.commit("setLocale", getConfiguration("locale"));
      }
      if (window.__GLOBAL_ISHOP_WIDGET) {
        // delete window.__GLOBAL_ISHOP_WIDGET;
        this.$store.state.widget = true;
      } else {
        let htmlStyle = document.createElement("style");
        htmlStyle.rel = "stylesheet";
        htmlStyle.innerHTML = "body,html{margin:0;padding:0;width:100%;height:100%;overflow:hidden;position:relative}";
        document.head.appendChild(htmlStyle);
      }
      this.checkForOldSafari();
      EventBus.$on("showDeliveryError", () => {
        this.showErrorModal = true;
      });
      if (!store.state.qrPaymentMode && !this.$pos && !this.$kiosk) {
        this.requestUserLocation();
      }
    },

    mounted() {
      this.styleToCss();
      EventBus.$on("tooltip", e => {
        this.tooltip.parent = e.parent;
        this.tooltip.items = e.items;
        this.tooltip.visible = true;
      });
      EventBus.$on("close-tooltip", e => {
        this.tooltip.visible = false;
      });
      EventBus.$on("document.title", override => {
        this.documentTitleOverride = override;
        this.setWindowTitle();
      });
      EventBus.$on("compute-style", () => {
        this.styleToCss();
      });
      EventBus.$on("show-quick-navigation", this.toggleShowQuickNavigation.bind(this));
      this.setWindowTitle();
      this.checkForInactivityOnKiosk();
      this.startStreamForPOS();
    },

    beforeDestroy() {
      EventBus.$off("document.title");
      EventBus.$off("close-tooltip");
      EventBus.$off("tooltip");
      EventBus.$off("compute-style");
      clearInterval(this.inactivityInterval);
      this.clearStreamForPOS();
      NetworkMonitor.destroy();
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      user() {
        return this.$user;
      },
      kioskConfiguration() {
        if (!this.$kiosk) {
          return;
        }
        return this.$store.state.kioskConfiguration;
      },
      posConfiguration() {
        if (!this.$pos) {
          return;
        }
        return this.$store.state.posConfiguration;
      },
      shouldDisplayPosCart() {
        let route = this.$route.name;
        let safeRoutes = this.$store.getters.menuRouteNames;
        return this.$pos && safeRoutes.indexOf(route) > -1;
      },
      isPosCartLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      style() {
        if (!this.branch) {
          return null;
        }
        if (this.$kiosk) {
          if (!this.company) {
            return null;
          }
          return this.company.kioskStyle;
        }
        if (this.$pos) {
          if (!this.company) {
            return null;
          }
          return this.company.posStyle;
        }
        return this.branch.style;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      shouldDisplayKioskCart() {
        let safeRoutes = this.$store.getters.menuRouteNames;
        return (!this.inventory.isBrandingActivated() || this.$order.branding) && safeRoutes.indexOf(this.$route.name) > -1;
      },
      backgroundImage() {
        if (!this.branch) {
          return null;
        }
        if (this.company && this.company.images && this.company.images.kioskBackgroundImage) {
          return this.company.images.kioskBackgroundImage;
        }
        return this.branch.backgroundImage;
      },
      navigationLogo() {
        if (!this.branch) {
          return null;
        }
        if (this.$order.branding && this.$order.branding.logo) {
          return this.$order.branding.logo;
        }
        if (this.style.companyLogoOverride && this.company && this.company.image) {
          return { url: this.company.image };
        }
        return this.$store.state.currentBranch.navigationLogo;
      },
      horizontalLogo() {
        if (!this.$store.state.currentBranch) {
          return null;
        }
        if (this.$order.branding && this.$order.branding.horizontalLogo) {
          return this.$order.branding.horizontalLogo;
        }
        if (this.$order.branding && this.$order.branding.logo) {
          return this.$order.branding.logo;
        }
        if (this.style.companyHorizontalLogoOverride && this.company && this.company.image) {
          return { url: this.company.image };
        }
        if (this.style.companyLogoOverride && this.company && this.company.image) {
          return { url: this.company.image };
        }
        return this.$store.state.currentBranch.horizontalLogo;
      },
      navigationLogoBackground() {
        if (!this.$store.state.currentBranch || (this.style.companyLogoOverride && this.company && this.company.image)) {
          return null;
        }
        return this.$store.state.currentBranch.navigationLogoBackground;
      },
      hubImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.hubImage;
      },
      mobileMenuLogo() {
        if (!this.$store.state.currentBranch) {
          return null;
        }
        if (this.$order.branding && this.$order.branding.logo) {
          return this.$order.branding.logo;
        }
        if (this.style.companyLogoOverride && this.company && this.company.image) {
          return { url: this.company.image };
        }
        return this.$store.state.currentBranch.mobileMenuLogo;
      },
      defaultItemImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.defaultItemImage;
      },
      spinnerAnimatedImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.spinnerAnimatedImage;
      },
      spinnerStaticImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.spinnerStaticImage;
      },
      takeoutImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.takeoutImage;
      },
      takeoutBackgroundImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.takeoutBackgroundImage;
      },
      hotelImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.hotelImage;
      },
      hotelBackgroundImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.hotelBackgroundImage;
      },
      cateringImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.cateringImage;
      },
      cateringBackgroundImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.cateringBackgroundImage;
      },
      deliveryImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.deliveryImage;
      },
      deliveryBackgroundImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.deliveryBackgroundImage;
      },
      inPlaceImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.inPlaceImage;
      },
      inPlaceBackgroundImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.inPlaceBackgroundImage;
      },
      modalOrderTypeImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.modalOrderTypeImage;
      },
      hasTakeout() {
        if (!this.branch) {
          return false;
        }
        return this.branch.getOrderMethodsAvailable().indexOf("takeout") > -1;
      },
      showMobileFooter() {
        if (!this.branch || !this.style) {
          return false;
        }
        return this.style.showMobileFooter;
      },
      showProgressBar() {
        if (!this.branch || !this.style || !this.style.fidelity || !this.$user.token || !this.isRankEnabled) {
          return false;
        }
        return this.style.fidelity.displayFooterProgressBar;
      },
      canDisplayFooter() {
        return !this.$kiosk && !this.isDesktop && this.isAllowedPageForFooter && !this.$store.state.qrPaymentMode;
      },
      isRankEnabled() {
        if (!this.branch || !this.branch.loyaltyConfig) {
          return false;
        }
        return this.branch.loyaltyConfig.data.rankEnabled;
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      isQrPayment() {
        return this.$store.state.qrPaymentMode;
      },
      isAllowedPageForFooter() {
        let blacklist = [
          "giftCardPurchase",
          "giftCardView",
          "giftCardPrint",
          "tutorial",
          "tutorialWithCompany",
          "newPassword",
          "newPasswordNoCompany",
          "registerConfirmation",
          "registerConfirmationNoCompany",
          "orderType",
          "orderTypeNoCompany",
          "orderCompanyNoCompany",
          "orderCompany",
          "orderLocation",
          "orderLocationNoCompany"
        ];
        return blacklist.indexOf(this.$route.name) === -1;
      },
      isAllowedPageForBottomNotchSpace() {
        let blacklist = [
          "tutorial",
          "tutorialWithCompany"
        ];
        return blacklist.indexOf(this.$route.name) === -1;
      },
      permissionManager() {
        return PermissionManager;
      },
      isVerticalAirxTouch() {
        return this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH()
      }
    },

    watch: {
      style() {
        this.styleToCss();
      },
      "$order.branding"() {
        this.styleToCss();
      },
      "$store.state.posConfiguration.darkTheme": function () {
        this.styleToCss();
      },
      "$user.token": function() {
        if (!this.$store.state.currentCompany) {
          return;
        }
        PromotionManager.load(true);
      },
      "$store.state.updateRequired": function(to, from) {
        if (to && (to != from)) {
          console.log("you should update");
          hideSpinner();
          window.showForceUpdate(this.$t("update.title"), this.$t("update.body"), this.$t("update.action"), this.$store.state.updateUrl);
        }
      },
      "$store.state.currentBranch": function(to, from) {
        if (!to || (from && from.id != to.id)) {
          this.$store.state.inventory.reset();
          this.$order.reset();
          this.$user.reset();
          PromotionManager.reset();
          this.$store.state.currentCompany = null;
        }
        this.setWindowTitle();
      },
      "$store.state.displayMode": function(newValue) {
        if (!newValue) {
          this.checkPromoModal();
          if (this.$store.state.quickMode) {
            EventBus.$emit("order-options-modal");
          } else {
            this.checkIsClosedOrInAdvance();
          }
        }
      },
      "$store.state.currentCompany": function(to, from) {
        if (to) {
          if (this.$ga) {
            this.$ga.event("new-customer", to.nameCanonical);
          }
          if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("Initial Menu Display", { company: to.nameCanonical });
          }
          if (this.isQrPayment) {
            return;
          }
          let orderOptionsModalPanel = this.$refs.orderOptionsModal;
          // TODO refactor les conditions qui check deux fois almost la meme chose et yeeter le failsafe checkedIfClosedOrInAdvance qui empeche qu'on call deux fois checkIf...
          if (this.$router.currentRoute.name !== "dispatch") {
            let checkedIfClosedOrInAdvance = false;
            if (!this.$store.state.displayMode && from) {
              this.checkPromoModal();
              if (to.id !== from.id) {
                this.checkIsClosedOrInAdvance();
                checkedIfClosedOrInAdvance = true;
              }
            }

            let orderOptionsModalIsVisibleWithACompany = orderOptionsModalPanel && orderOptionsModalPanel.currentCompany && orderOptionsModalPanel.show;
            let companyHasChanged = !from || (to.id !== from.id);
            if (!checkedIfClosedOrInAdvance) {
              if ((!this.$store.state.quickMode && !from) || companyHasChanged || (this.$store.state.quickMode && from && orderOptionsModalIsVisibleWithACompany)) {
                this.checkIsClosedOrInAdvance();
              }
            }
          }

          this.styleToCss();
          this.$order.clear();

          if (!from && to && this.$router.currentRoute.name !== "dispatch" && this.$order.method) {
            this.company.showEnterMessageForMethod(this.$order.method);
          }
        }

        if (this.$order.method === "catering" || (this.company && this.company.isFutureDateEnabled())) {
          this.$order.date = this.getNextAvailableCateringDate();
        }

        if (!this.$kiosk && !this.$pos && this.company) {
          this.company.validateClientTimeZone();
        }

        if (this.$pos) {
          this.startStreamForPOS();
        }
      },
      "$store.state.order.date": function(to, from) {
        if (!this.company || (from && to.format("D MMMM") == from.format("D MMMM"))) {
          return;
        }
        this.$order.validateAddedItems();
        this.company.checkIfOrderDateIsInTheFuture(false);
      },
      "$store.state.order.method": function(to, from) {
        if (this.isQrPayment) {
          return;
        }
        this.$nextTick(() => {
          if (to) {
            let orderOptionsModalPanel = this.$refs.orderOptionsModal;
            if (!this.$store.state.displayMode && from && orderOptionsModalPanel && orderOptionsModalPanel.currentCompany) {
              this.checkIsClosedOrInAdvance();
            }
            let orderOptionsModalIsVisible = orderOptionsModalPanel && orderOptionsModalPanel.show;
            if (this.$router.currentRoute.name !== "dispatch" && this.company && !orderOptionsModalIsVisible) {
              this.company.showEnterMessageForMethod(to);
            }
          }
        });
        if (to == "catering" || (this.company && this.company.isFutureDateEnabled())) {
          this.$order.date = this.getNextAvailableCateringDate();
          for (let item of this.$order.items) {
            if (item.quantity < item.getMinimum()) {
              item.quantity = item.getMinimum();
            }
          }
        } else {
          this.$order.date = moment();
        }
        if (!this.$store.state.quickMode && !from && !this.$order.hour) {
          let hours = this.company.getAvailableOpenHours(to);
          if (hours.length && hours.length > 0) {
            this.$order.setHour(hours[0]);
          }
        }
        PromotionManager.calculate();
      },
      $route: function() {
        this.documentTitleOverride = null;
        this.setWindowTitle();
      },
      "$ts.state.locale": function() {
        this.setWindowTitle();
      },
      "$order.items": function() {
        PromotionManager.calculate();
      }
    },

    methods: {

      ...mapActions({
        loyaltyBalanceAction: "loyaltyBalance",
        processExternalPosSale: "processExternalPosSale"
      }),

      setWindowTitle() {
        if (this.$kiosk) {
          return;
        }
        let str = "";
        if (this.$store.state.currentBranch) {
          str += this.$store.state.currentBranch.name + " - ";
        }
        if (this.$route.matched[0]) {
          let view = this.$route.matched[0].components.default;
          if (this.documentTitleOverride) {
            str += this.$to(this.documentTitleOverride);
          } else {
            str += this.$t("title." + this.$route.name);
          }
        }
        document.title = str;
      },

      requestUserLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.$store.state.userCurrentPosition = position.coords;
          }, (error) => {
            console.warn("User geolocation position : permission rejected", error);
          }, {
            timeout: 10000
          });
        }
      },

      clearStreamForPOS() {
        if (this.posSocket) {
          this.posSocket.closeSocket();
        }
      },

      startStreamForPOS() {
        if (!this.$pos || !this.company || !this.branch) {
          return;
        }
        this.clearStreamForPOS();
        this.posSocket = new IshopRequestSocket({
          url: CONFIG.urlStreamServer,
          debugConsole: false,
          client: {
            company: this.company.nameCanonical,
            branch: this.branch.nameCanonical,
            context: "pos"
          }
        });
        this.posSocket.on("order.create", saleData => this.processExternalPosSale({ saleData: saleData }));
      },

      checkForInactivityOnKiosk() {
        if (!this.$kiosk) {
          return;
        }
        // Listen for clicks anywhere in the app
        document.addEventListener("click", (e) => {
          window.lastClickTimestamp = new Date();
        });
        document.addEventListener("contextmenu", (e) => {
          window.lastClickTimestamp = new Date();
        });
        document.addEventListener("touchstart", (e) => {
          window.lastClickTimestamp = new Date();
        });
        document.addEventListener("touchmove", (e) => {
          window.lastClickTimestamp = new Date();
        });
        // Check for inactivity every second
        this.inactivityInterval = setInterval(() => {
          let affirmationModal = document.getElementById("modalAffirmation");
          let isVisible = affirmationModal.classList.contains("show") && affirmationModal.classList.contains("inactivity");
          if (isVisible || !window.lastClickTimestamp) { // should do nothing if timestamp is not yet defined or inactivity popup is already visible
            return;
          }
          // Check delay in seconds between last click and current time
          let secondsSinceLastClick = (new Date() - window.lastClickTimestamp) / 1000;
          if (secondsSinceLastClick >= this.maximumSecondsOfInactivityBeforeWarning) {
            //Make sure no modal is visible before display
            let confirmationModal = document.getElementById("modalConfirmation");
            confirmationModal.classList.remove("show");
            affirmationModal.classList.remove("show");
            this.showInactivityPopup();
          }
        }, 1000);
      },

      async showInactivityPopup() {
        let countdown = this.inactivityCountdownInWarningPopup - 1;
        // If inactivity occurs in one of those pages, should ignore and reset inactivity
        let safeRouteNames = ["kioskStandby", "kioskThankYou", "kioskConfiguration", "kioskConfigurationNoBranch", "kioskAdmin", "kioskPayment"];
        if (safeRouteNames.indexOf(this.$route.name) > -1) {
          window.lastClickTimestamp = null;
          return;
        }
        if (this.$route.name === "kioskMethod") {
          this.returnToStandby();
          return;
        }
        // set interval to display countdown value in modal and catch inactivity if countdown is down to 0
        let interval = setInterval(() => {
          if (countdown <= 0) {
            clearInterval(interval);
            this.returnToStandby();
          }
          let number = document.getElementById("countdownNumber");
          if (number) {
            number.innerHTML = countdown;
            countdown--;
          }
        }, 1000);
        // display modal for inactivity
        this.hideModals();
        await showAffirmation(this.$t("kiosk_inactivity.title"), this.$t("kiosk_inactivity.number"), this.$t("close"), "hello", false, "inactivity");
        clearInterval(interval);
      },

      returnToStandby() {
        // Reset order & configs
        window.lastClickTimestamp = null;
        window.logToFile("SALE CANCELLED BY INACTIVITY", this.$order.getUniqueId());
        this.$order.reset();
        PromotionManager.reset();
        // Make sure to close everything
        document.getElementById("modalAffirmation").classList.remove("show");
        this.hideModals();
        // Redirect
        this.$router.push(this.$router.generate("/kiosk/standby"));
      },

      hideModals() {
        EventBus.$emit("close-tooltip");
        EventBus.$emit("hide-promotion-modal");
        EventBus.$emit("hide-modifier-quantity-modal");
      },

      stayInDelivery() {
        this.showErrorModal = false;
        if (this.$store.state.quickMode) {
          EventBus.$emit("order-options-modal");
        }
      },

      checkPromoModal() {
        if (this.$kiosk || this.isQrPayment) {
          return;
        }
        if (this.$store.state.currentCompany && this.$store.state.currentCompany.onlineOrdering &&
          this.$store.state.currentCompany.onlineOrdering.default.general.promoPopup) {
          showAffirmation(this.$t("title.promotions"), this.$t("promotion.have_promo"), null, "discount");
        }
      },

      checkIsClosedOrInAdvance() {
        if (!this.company || this.$route.name === "view-menu" || this.$store.state.ticketMode || this.isQrPayment) {
          return;
        }
        this.company.checkIfClosedOrInAdvance();
      },

      checkForOldSafari() {
        let navigator = getNavigator();
        this.isOldSafari = (navigator.name == "Safari" && parseInt(navigator.version) < 11 && typeof cordova !== "undefined");
      },

      styleToCss() {
        if (this.$pos) {
          //TODO set primary color from style config
          let color = this.$store.state.posConfiguration.primaryColor;
          this.$store.state.posConfiguration.setPrimaryColorProperties();
          this.$store.state.primaryButtonColor = color;
          this.$store.state.primaryColor = color;
          document.getElementById("iShopFoodApp").style.setProperty("--primary-color", color);
          if (this.posConfiguration && this.posConfiguration.darkTheme) {
            StyleGenerator.setDarkThemeProperties();
          } else {
            StyleGenerator.setLightThemeProperties();
          }
        }

        if (!this.style || !this.style.selectors || this.style.selectors.length === 0) {
          StyleGenerator.setCustomCss("");
          return;
        }

        StyleGenerator.removeProperty("--primary-color");
        for (let selector of this.style.selectors) {
          if (selector.selector.indexOf(".pageTitle") > -1 && selector.attribute == "background-color" && selector.value) {
            this.$store.state.primaryColor = selector.value;
            StyleGenerator.setStatusBarColor(selector.value);
          } else if (selector.selector.filter(s => s.indexOf(".btn-primary") > 1).length > 0 && selector.attribute == "background-color") {
            if (!StyleGenerator.getProperty("--primary-color")) {
              if (this.$order.branding) {
                this.$store.state.primaryButtonColor = this.$order.branding.color;
                StyleGenerator.setProperty("--primary-color", this.$order.branding.color);
              } else {
                this.$store.state.primaryButtonColor = selector.value;
                StyleGenerator.setProperty("--primary-color", selector.value);
              }
            }
          } else if (selector.selector.filter(s => s.indexOf(".btn-primary") > 1).length > 0 && selector.attribute == "color") {
            this.$store.state.primaryButtonTextColor = selector.value;
            StyleGenerator.setProperty("--primary-text-color", selector.value);
          } else if (selector.selector.filter(s => s.indexOf(".btn-secondary") > 1).length > 0 && selector.attribute == "color") {
            this.$store.state.secondaryButtonTextColor = selector.value;
          }
        }

        let css = "";

        // Compile simple css
        for (let selector of this.style.selectors.filter(s => !!s.value)) {
          css += selector.selector.map(s => s.startsWith("#iShopFoodApp") ? s : "#iShopFoodApp " + s).join(",") + "{";
          css += selector.attribute + ":" + StyleGenerator.formatCssValue(selector.attribute, selector.value) + " !important;";
          css += "}";
        }

        // Branding colors override
        if (this.$order.branding) {
          StyleGenerator.setProperty("--branding-primary-color", this.$order.branding.color);
          if (this.$kiosk) {
            css += kioskStyleOverride;
          } else {
            css += onlineOrderingStyleOverride;
          }
        }

        // Compile images
        if (this.backgroundImage && this.backgroundImage.url) {
          css += "#iShopFoodApp > .content, #iShopFoodApp > #cart, #iShopFoodApp > #navigation { background-image: url(" +
            this.getStyleImageUrl(this.backgroundImage.url) +
            ") !important; }";
        } else {
          css += "#iShopFoodApp > .content, #iShopFoodApp > #cart, #iShopFoodApp > #navigation { background-image: none !important; }";
        }
        if (this.mobileMenuLogo && this.mobileMenuLogo.url) {
          css += "#iShopFoodApp #mobileNavigation .logo { background-image: url(" +
            this.getStyleImageUrl(this.mobileMenuLogo.url) +
            ") !important; }";
        }
        if (this.navigationLogoBackground && this.navigationLogoBackground.url) {
          css += "#iShopFoodApp #navigation .shop .logo, #iShopFoodApp #company .branding-panel .branding__logo, #iShopFoodApp #brandingAboutModal .modal__body .branding__logo { background-image: url(" +
            this.getStyleImageUrl(this.navigationLogoBackground.url) +
            ") !important; }";
          if (this.navigationLogo && this.navigationLogo.url) {
            css += "#iShopFoodApp #navigation .shop .logo .inner-logo { background-image: url(" +
              this.getStyleImageUrl(this.navigationLogo.url) +
              ") !important; }";
          }
        }
        if (this.hubImage && this.hubImage.url) {
          css += "#iShopFoodApp .hub .left { background-image: url(" +
            this.getStyleImageUrl(this.hubImage.url) +
            ") !important; }";
        }
        if (this.defaultItemImage && this.defaultItemImage.url) {
          this.$store.state.defaultItemImage = this.getStyleImageUrl(this.defaultItemImage.url);
          let element = document.createElement("img");
          element.src = this.getStyleImageUrl(this.defaultItemImage.url);
          document.getElementById("preload").appendChild(element);
        }
        if ((this.takeoutImage && this.takeoutImage.url) || (this.takeoutBackgroundImage && this.takeoutBackgroundImage.url)) {
          css += "#iShopFoodApp #orderType .takeout .img,  #iShopFoodApp #orderOptionsModal .takeout .image { background-image: url(" +
            this.getStyleImageUrl(this.takeoutImage ? this.takeoutImage.url : null) +
            "), url(" +
            this.getStyleImageUrl(this.takeoutBackgroundImage ? this.takeoutBackgroundImage.url : null) +
            ") !important; }";
        }
        if ((this.deliveryImage && this.deliveryImage.url) || (this.deliveryBackgroundImage && this.deliveryBackgroundImage.url)) {
          css += "#iShopFoodApp #orderType .delivery .img,  #iShopFoodApp #orderOptionsModal .delivery .image { background-image: url(" +
            this.getStyleImageUrl(this.deliveryImage ? this.deliveryImage.url : null) +
            "), url(" +
            this.getStyleImageUrl(this.deliveryBackgroundImage ? this.deliveryBackgroundImage.url : null) +
            ") !important; }";
        }
        if ((this.inPlaceImage && this.inPlaceImage.url) || (this.inPlaceBackgroundImage && this.inPlaceBackgroundImage.url)) {
          css += "#iShopFoodApp #orderType .inPlace .img, #iShopFoodApp #orderOptionsModal .inPlace .image { background-image: url(" +
            this.getStyleImageUrl(this.inPlaceImage ? this.inPlaceImage.url : null) +
            "), url(" + this.getStyleImageUrl(this.inPlaceBackgroundImage ? this.inPlaceBackgroundImage.url : null) +
            ") !important; }";
        }
        if ((this.cateringImage && this.cateringImage.url) || (this.cateringBackgroundImage && this.cateringBackgroundImage.url)) {
          css += "#iShopFoodApp #orderType .catering .img, #iShopFoodApp #orderOptionsModal .catering .image { background-image: url(" +
            this.getStyleImageUrl(this.cateringImage ? this.cateringImage.url : null) +
            "), url(" + this.getStyleImageUrl(this.cateringBackgroundImage ? this.cateringBackgroundImage.url : null) +
            ") !important; }";
        }
        if ((this.hotelImage && this.hotelImage.url) || (this.hotelBackgroundImage && this.hotelBackgroundImage.url)) {
          css += "#iShopFoodApp #orderType .hotel .img, #iShopFoodApp #orderOptionsModal .hotel .image { background-image: url(" +
            this.getStyleImageUrl(this.hotelImage ? this.hotelImage.url : null) +
            "), url(" + this.getStyleImageUrl(this.hotelBackgroundImage ? this.hotelBackgroundImage.url : null) +
            ") !important; }";
        }

        if (this.horizontalLogo && this.horizontalLogo.url) {
          css += "#iShopFoodApp .pageTitle .logo, #iShopFoodApp #orderOptionsModal .logo, #iShopFoodApp #recommenderModal .logo { background-image: url(" +
            this.getStyleImageUrl(this.horizontalLogo.url) +
            ") !important; }";
        }

        if (this.spinnerAnimatedImage && this.spinnerAnimatedImage.url) {
          css += "#iShopFoodApp #spinner .img { background-image: url(" +
            this.getStyleImageUrl(this.spinnerAnimatedImage.url) +
            ") !important; }";
        }

        if (this.spinnerStaticImage && this.spinnerStaticImage.url) {
          css += "#iShopFoodApp #spinner .img-inner { background-image: url(" +
            this.getStyleImageUrl(this.spinnerStaticImage.url) +
            ") !important; }";
        }

        // Add custom CSS
        // TODO - watch out pour le scoping à l'app pour le widget
        if (this.style.customCss) {
          css += this.style.customCss;
        }

        if (this.style.styleMode) {
          this.$store.state.currentBranch.styleMode = this.style.styleMode || "classic";
        }

        this.$store.state.currentBranch.quickAdd = this.style.styleMode === "quick" && this.style.quickAdd && !this.$kiosk;
        this.$store.state.currentBranch.interactiveMode = this.style.interactiveMode;

        if (this.style.mobileProductsPerRow) {
          this.$store.state.mobileProductsPerRow = this.style.mobileProductsPerRow || 1;
        }

        this.$store.state.showCategoriesDescription = !!this.style.showCategoriesDescription;

        if (this.style.itemImageBackgroundSize) {
          css += "#iShopFoodApp #menu .items .item .asyncImage, " +
            "#iShopFoodApp #menu .category .asyncImage, " +
            "#iShopFoodApp #menu .items .item .asyncImage .whileLoading, " +
            "#iShopFoodApp #menu .category .asyncImage .whileLoading { background-size: " +
            this.style.itemImageBackgroundSize + "; }";
        }

        StyleGenerator.setCustomCss(css);
      },

      callTooltipItemEvent(item) {
        if (item.onclick) {
          item.onclick();
        }
        this.tooltip.visible = false;
      },

      setMethod(method) {
        this.$order.setMethod(method);
        this.showErrorModal = false;
      },

      getStyleImageUrl(url) {
        if (CONFIG.app) {
          return url;
        } else {
          return this.$store.state.urlFileServer + url;
        }
      },

      getNextAvailableCateringDate() {
        let date = moment();
        if (!this.$store.state.currentCompany) {
          return date;
        }
        if (this.$store.state.currentCompany.isTodayDisabled()) {
          date = date.add({ days: 1 });
        }
        let openDays = this.$store.state.currentCompany.getOpenDays();
        let max = 60;
        while (!openDays[date.day()] && max > 0) {
          date.add({ days: 1 });
          max--;
        }
        if (max == 0) {
          return moment().add({ days: 1 });
        }
        return date;
      },

      toggleShowQuickNavigation() {
        this.showQuickNavigation = !this.showQuickNavigation;
      }
    }

  };

</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    overflow: hidden;
    touch-action: manipulation;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #404040;
    box-sizing: border-box;
    min-height: 0;
    position: relative;
    height: 100%;
    display: flex;
    transition: padding-top 300ms ease-in-out;
    padding-top: 0;
    &.kiosk {
      user-select: none;
     > .content {
       background-image: none;
       background-color: #f3f3f3;
     }
    }
    &.pos {
      flex-wrap: wrap;
      user-select: none;
      flex-direction: column;
      > .content {
        background-image: none;
        background-color: #f5f5f5;
      }
    }
    & > .content, &.pos .pos-container__main > .content  {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      transition: transform 0.3s ease-in-out, opacity 0.5s ease-out;
      background-color: white;
      background-image: url("./assets/img/bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

      &.progressBarFooter {
        margin-bottom: 105px;
      }
      &.progressBar {
        margin-bottom: 40px;
      }
      &.mobileFooter {
        margin-bottom: 65px;
      }
    }
    .slide-fade-leave-active {
      transition: opacity 300ms, visibility 300ms
    }
    .slide-fade-leave-to {
      opacity: 0;
      visibility: hidden;
    }
    &.pos .pos-container__main > .content {
      background-image: none;
      background-color: var(--background-color) !important;
    }
  }

  #modalDeliveryError {
    .text {
      text-align: center;
    }
    .modal-footer {
      display: flex;
      justify-content: center !important;
      button {
        margin: 0 10px;
      }
    }
    .modal-header {
      .svgicon {
        fill: #ed2937;
        margin-right: 8px;
      }
    }
  }

  .navigationContainer {
    transition: opacity 0.5s ease-out;
    & > div {
      height: 100%;
    }
    &.sm:not(.quick), &.md:not(.quick) {
      display: none;
    }
    &.quick {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      z-index: 900;
      pointer-events: none;
      transition: left 1s ease, transform 0.5s ease;
      transform: skewX(-5deg);
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 10000px;
        top: 0;
        bottom: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &.show {
        left: 0%;
        opacity: 1;
        pointer-events: all;
        transition: left 0.3s ease -0.1s;
        transform: skewX(0deg);
        &:before {
          opacity: 1;
        }
      }
    }
  }

  .pos-container__sidebar, .pos-container__header, .pos-container__main {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
  .pos-container__sidebar {
    flex-grow: 1;
    position: relative;
  }

  .pos-container__header {
    flex-direction: column;
  }
  .pos-container__main {
    flex-shrink: 1 !important;
    overflow: hidden;
  }
</style>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity 450ms ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  #iShopFoodApp.pos.no-animation {
    #posCalculator *,
    #holdModal *,
    #posIntroModal,
    #offlineBar,
    #posCart *,
    .pos-cart-item,
    #posHeader *,
    #posKeyboard,
    #posKeyboard *,
    #posNavigation,
    #voidModal *,
    #pos-inactivity *,
    #pos-inactivity,
    #menu *,
    #category-links *,
    .modal.pos,
    .modal.pos *,
    #posConfiguration label::after,
    #posConfiguration label::before {
      transition: none !important;
    }
  }
</style>
