import Axios from "axios";
import moment from "moment-timezone";
import Store from "./store";
import EventBus from "./lib/eventBus.js";
import { LocalizeStore } from "./lib/localize";

class PaymentProcessor {

  constructor() {
    this.paymentMethod = null;
    this.amount = null;
    this.readyForPayment = false;
    this.saveCard = false;
    this.savedCardID = null;

    /* PAYSAFE */
    this.paysafeInstance = null;
    this.paysafePayment = null;
    this.paysafeError = null;

    /* PAYFACTO */
    this.payfactoPayment = null;
    this.payFactoToken = null;
    this.payFactoSHCReadyForPayment = false;
    this.payFactoSHC = null;
    this.usePayFactoSHC = false;

    /* NMI */
    this.nmiPayment = null;
    this.threeDSecureInterface = null;
    this.nmiPaymentChallengeInProgress = false;

    /* CYBERSOURCE */
    this.cybersourcePayment = null;
    this.cybersourceContext = null;
    this.cybersourceMicroform = null;

    /* GLOBAL */
    this.globalInitialized = false;
    this.globalRawData = null;
    this.globalPayment = null;

    /* ITC */
    this.itcPayment = null;
    this.itcCredentials = null;
    this.itcAccountInformations = null;

    /* Valitor */
    this.valitorInFocus = false;
    this.valitorPayment = null;

    /* FORM FIELDS */
    this.cardBillingState = "";
    this.cardBillingAddress = null;
    this.cardBillingCity = null;
    this.cardFirstName = null;
    this.cardLastName = null;
    this.cardBillingPostalCode = null;
    this.cardCountry = "";
    this.cardholderName = null;

    this.ONLINE_PAYMENT_METHODS = [
      "payfacto",
      "paysafe",
      "cybersource",
      "global",
      "itc",
      "valitor",
      "nmi"
    ];
  }

  reset() {
    this.paymentMethod = null;
    this.amount = null;
    this.saveCard = false;
    this.savedCardID = null;

    /* PAYSAFE */
    this.paysafeInstance = null;
    this.paysafePayment = null;
    this.paysafeError = null;

    /* PAYFACTO */
    this.payfactoPayment = null;
    this.payFactoToken = null;
    this.payFactoSHC = null;
    this.payFactoSHCReadyForPayment = false;
    this.usePayFactoSHC = false;

    /* NMI */
    this.nmiPayment = null;
    this.threeDSecureInterface = null;
    this.nmiPaymentChallengeInProgress = false;

    /* GLOBAL */
    this.globalInitialized = false;
    this.globalRawData = null;
    this.globalPayment = null;

    /* CYBERSOURCE */
    this.cybersourcePayment = null;
    this.cybersourceContext = null;
    this.cybersourceMicroform = null;

    /* Valitor */
    this.valitorInFocus = false;
    this.valitorPayment = null;

    /* ITC */
    this.itcPayment = null;
    this.itcCredentials = null;
    this.itcAccountInformations = null;

    /* FORM FIELDS */
    this.cardBillingState = "";
    this.cardBillingAddress = null;
    this.cardBillingCity = null;
    this.cardFirstName = null;
    this.cardLastName = null;
    this.cardNumber = null;
    this.cardBillingPostalCode = null;
    this.cardCountry = "";
    this.cardholderName = null;

    this.readyForPayment = false;
  }

  /**
   * @param {string} paymentMethod
   */
  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  /**
   * @param {number} amount
   */
  setAmount(amount) {
    this.amount = amount;
  }

  removePayment() {
    this.paymentMethod = "";
    let order = Store.state.order;

    if (this.paysafePayment) {
      order.removePayment(this.paysafePayment);
      this.initializePaysafe();
    } else if (this.payfactoPayment) {
      order.removePayment(this.payfactoPayment);
      this.payfactoPayment = null;
      this.payFactoToken = null;
      this.payFactoSHCReadyForPayment = false;
    } else if (this.cybersourcePayment) {
      order.removePayment(this.cybersourcePayment);
      this.cybersourcePayment = null;
    } else if (this.globalPayment) {
      order.removePayment(this.globalPayment);
      this.globalPayment = null;
      this.globalRawData = null;
      this.globalInitialized = false;
    } else if (this.itcPayment) {
      order.removePayment(this.itcPayment);
      this.itcPayment = null;
      this.itcCredentials = null;
      this.itcAccountInformations = null;
    }
    if (this.nmiPayment) {
      order.removePayment(this.nmiPayment);
      this.nmiPayment = null;
    }
  }

  getPaymentData() {
    if (this.paysafePayment) {
      return this.paysafePayment;
    }
    if (this.payfactoPayment) {
      return this.payfactoPayment;
    }
    if (this.globalPayment) {
      return this.globalPayment;
    }
    if (this.cybersourcePayment) {
      return this.cybersourcePayment;
    }
    if (this.itcPayment) {
      return this.itcPayment;
    }
    if (this.nmiPayment) {
      return this.nmiPayment;
    }
  }

  /**
   * @param {string} paymentMethod
   * @return {boolean}
   */
  isPaymentMethod(paymentMethod) {
    return this.paymentMethod == paymentMethod;
  }

  /**
   * @return {boolean}
   */
  isOnlinePaymentMethod() {
    return this.paymentMethod && this.ONLINE_PAYMENT_METHODS.indexOf(this.paymentMethod) > -1;
  }

  /**
   * @param paymentMethod
   * @return {Object|null}
   */
  getPaymentMethodInfo(paymentMethod) {
    let company = Store.state.currentCompany;

    if (!company) {
      let branch = Store.state.currentBranch;
      if (branch) {
        return branch.getPaymentMethod(paymentMethod);
      }
    }
    return company.getPaymentMethod(paymentMethod);
  }

  /**
   * @param {string} field
   * @return {boolean}
   */
  isFieldRequired(field) {
    switch (field) {
      case "cardholderName": {
        return this.paysafeBillingInformationRequired();
      }
      case "cardBillingPostalCode": {
        return this.paysafeBillingInformationRequired() ||
          this.isCybersouceBillingInformationRequired() ||
          this.isPaymentMethod("global") ||
          this.nmiBillingInformationRequired();
      }
      case "cardCountry": {
        return this.paysafeBillingInformationRequired() ||
          this.isCybersouceBillingInformationRequired() ||
          this.isPaymentMethod("global") ||
          this.nmiBillingInformationRequired();
      }
      case "cardBillingAddress": {
        return this.isCybersouceBillingInformationRequired() ||
          this.isPaymentMethod("global") ||
          this.nmiBillingInformationRequired();
      }
      case "cardBillingCity": {
        return this.isCybersouceBillingInformationRequired() ||
          this.isPaymentMethod("global") ||
          this.nmiBillingInformationRequired();
      }
      case "cardBillingState": {
        return this.isCybersouceBillingInformationRequired() ||
          this.isPaymentMethod("global") ||
          this.nmiBillingInformationRequired();
      }
      case "cardFirstName": {
        return this.isCybersouceBillingInformationRequired() ||
          this.nmiBillingInformationRequired();
      }
      case "cardLastName": {
        return this.isCybersouceBillingInformationRequired() ||
          this.nmiBillingInformationRequired();
      }
      case "itcCredentials": {
        return this.isPaymentMethod("itc");
      }
      default: {
        return false;
      }
    }
  }

  /**
   * @return {boolean}
   */
  shouldHideDefaultOrderButton() {
    let totalRemaining = Store.state.order.getOrderTotal().totalRemaining;
    return (this.isPaymentMethod("global") ||
        this.isPaymentMethod("valitor") ||
        (this.isPaymentMethod("nmi") && !(this.saveCardEnabled() && this.savedCardID)) ||
        (this.isPaymentMethod("itc") && !this.isItcReadyForPayment()))
      && totalRemaining > 0;
  }

  /**
   * @return {boolean}
   */
  isItcReadyForPayment() {
    return !!this.itcCredentials && !!this.itcAccountInformations;
  }

  /**
   * @return {boolean}
   */
  clearItcAccountInformations() {
    this.itcCredentials = null;
    this.itcAccountInformations = null;
  }

  /**
   * @return {boolean}
   */
  paysafeBillingInformationRequired() {
    let paysafeInfos = this.getPaymentMethodInfo("paysafe");
    if (!paysafeInfos || !this.isPaymentMethod("paysafe")) {
      return false;
    }
    return paysafeInfos.enableAVSCheck || paysafeInfos.enable3DSecure;
  }

  isCybersouceBillingInformationRequired() {
    return this.isPaymentMethod("cybersource") && !this.savedCardID;
  }

  nmiBillingInformationRequired() {
    let nmiInfos = this.getPaymentMethodInfo("nmi");
    if (!nmiInfos || !this.isPaymentMethod("nmi")) {
      return false;
    }
    if (this.saveCardEnabled() && this.savedCardID) {
      return false;
    }
    return nmiInfos.enable3DSecure;
  }

  saveCardEnabled() {
    if (Store.state.user.isAnonymous() || Store.state.user.isDispatchUser()) {
      return false;
    }
    let config = this.getPaymentMethodInfo(this.paymentMethod);
    return config && config.saveEnabled;
  }

  is3DSecureEnabled(paymentMethod) {
    let config = this.getPaymentMethodInfo(paymentMethod || this.paymentMethod);
    return config && config.enable3DSecure;
  }

  isGooglePayEnabled(paymentMethod) {
    let config = this.getPaymentMethodInfo(paymentMethod);
    return config && config.googlePayEnabled;
  }

  isApplePayEnabled(paymentMethod) {
    let config = this.getPaymentMethodInfo(paymentMethod);
    return config && config.applePayEnabled && window.ApplePaySession;
  }

  getAvailableBillingTokens() {
    let config = this.getPaymentMethodInfo(this.paymentMethod);
    if (!config) {
      return [];
    }
    return Store.state.user.billingTokens.filter(t => t.account == config.account);
  }

  changeSavedCardID(id) {
    if (this.savedCardID === id) {
      this.savedCardID = null;
    } else {
      this.savedCardID = id;
    }
  }

  initializePaymentMethod() {
    let company = Store.state.currentCompany;
    let entity = company || Store.state.currentBranch;
    if (company) {
      Store.state.order.company = company; //TODO remove completely order.company, use store.state.currentCompany instead
    }
    let user = Store.state.user;

    if (entity.hasPaymentMethod("paysafe")) {
      this.initializePaysafe();
    } else if (entity.hasPaymentMethod("cybersource")) {
      this.initializeCybersource();
    } else if (entity.hasPaymentMethod("itc")) {
      this.clearItcAccountInformations();
    } else if (entity.hasPaymentMethod("valitor")) {
      this.initializeValitor();
    }
    if (entity.hasPaymentMethod("payfacto")) {
      this.initializePayFactoSHC();
    }
    if (entity.hasPaymentMethod("nmi")) {
      this.initializeNmi();
    }

    this.readyForPayment = false;
    this.globalInitialized = false;
  }

  initializeValitor() {
    this.valitorInFocus = false;
    this.valitorPayment = null;
  }

  async challengeNmiBillingTokenWith3ds() {
    let paymentInfo = this.getPaymentMethodInfo("nmi");
    let order = Store.state.order;
    let company = Store.state.currentCompany;
    let info = order.getOrderTotal();
    let branch = Store.state.currentBranch;
    let currency = company ? company.currency : branch.getCurrencyFromFirstCompany();
    let gateway, threeDS;

    if (!paymentInfo || !(paymentInfo.enable3DSecure && this.saveCardEnabled() && this.savedCardID)) {
      return;
    }

    await window.loadScript({
      type: "javascript",
      url: "https://secure.nmi.com/js/v1/Gateway.js"
    });
    if (typeof Gateway === "undefined") {
      console.log("Could not load Gateway Script for NMI 3ds challenge");
      return;
    }
    try {
      gateway = Gateway.create(paymentInfo.checkoutKey);
      threeDS = gateway.get3DSecure();

      let tokens = this.getAvailableBillingTokens();
      let tokenData = tokens.find(t => t.id === this.savedCardID);
      if (!tokenData || !tokenData.data || !tokenData.data.customerVaultId) {
        return;
      }
      let customerVaultId = tokenData.data.customerVaultId;

      let options = {
        customerVaultId: customerVaultId,
        currency: currency.toUpperCase(),
        amount: info.totalRemaining.toFixed(2)
      };
      this.reset3dsForNmi();
      this.threeDSecureInterface = threeDS.createUI(options);
      this.threeDSecureInterface.start("#threeDSMountPoint--SavedCard");
      this.threeDSecureInterface.on("complete", (response) => { // Success, happy path
        this.reset3dsForNmi();
        let data = {
          billingToken: this.savedCardID,
          ...options,
          ...response
        };
        this.nmiPayment = order.addNmiPayment(data, true);
        setTimeout(() => {
          hideSpinner(true);
          this._submit();
        }, 1);
      });
      this.addDefaultListenersToNmi3ds();

      gateway.on("error", (e) => {
        hideSpinner(true);
        console.log("NMI Gateway error", e);
      });
    } catch (e) {
      console.log("could not intialize Gateway.js", e);
    }
  }

  reset3dsForNmi() {
    if (this.threeDSecureInterface && this.threeDSecureInterface.isMounted()) {
      this.threeDSecureInterface.unmount();
    }
    this.nmiPaymentChallengeInProgress = false;
  }

  addDefaultListenersToNmi3ds() {
    this.threeDSecureInterface.on("challenge", () => { //Started 3DS challenge; Iframe loaded to DOM
      hideSpinner(true);
      console.log("NMI on 3DS challenge");
      this.nmiPaymentChallengeInProgress = true;
    });
    this.threeDSecureInterface.on("error", (e) => { //Generic NMI error handling
      this.reset3dsForNmi();
      hideSpinner(true);
      console.log("NMI Gateway error", e);
      showAffirmation(window.translate("error.title"), e.message);
    });
    this.threeDSecureInterface.on("failure", (e) => { // Failed 3DS
      this.reset3dsForNmi();
      hideSpinner(true);
      console.log("NMI 3DS verification failed", e);
      showAffirmation(window.translate("payment.verification_failed"), e.message);
    });
  }

  isNmiFormValid() {
    let validCardNumber = document.querySelector("#txtCardNumber .CollectJSValid") !== null;
    let validExpiration = document.querySelector("#txtCardExpiry .CollectJSValid") !== null;
    let validCvv = document.querySelector("#txtCardCVV .CollectJSValid") !== null;
    let invalidCvv = document.querySelector("#txtCardCVV .CollectJSInvalid") !== null;
    let blankOrUnsavedCvv = !validCvv && !invalidCvv;
    return validCardNumber && validExpiration && !blankOrUnsavedCvv;
  }

  async initializeNmi() {
    let paymentInfo = this.getPaymentMethodInfo("nmi");
    let order = Store.state.order;
    let user = Store.state.user;
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let currency = company ? company.currency : branch.getCurrencyFromFirstCompany();
    let country = company ? company.iso2country : branch.getCountryFromFirstCompany();
    if (!paymentInfo) {
      return;
    }

    this.nmiPaymentChallengeInProgress = false;
    let gateway, threeDS, kount, sessionId;

    await window.loadScript({
      type: "javascript",
      url: "https://secure.nmi.com/token/Collect.js",
      attributes: [{ name: "data-tokenization-key", value: paymentInfo.tokenizationKey }]
    });
    if (paymentInfo.enable3DSecure || paymentInfo.enableKount) {
      await window.loadScript({
        type: "javascript",
        url: "https://secure.nmi.com/js/v1/Gateway.js"
      });
      if (typeof Gateway === "undefined") {
        console.log("Could not load Gateway Script for NMI Payment");
        return;
      }
      try {
        gateway = Gateway.create(paymentInfo.checkoutKey);
        if (paymentInfo.enable3DSecure) {
          threeDS = gateway.get3DSecure();
        }
        if (paymentInfo.enableKount) {
          kount = gateway.getKount();
          sessionId = await kount.createSession();
        }
        gateway.on("error", (e) => {
          hideSpinner(true);
          console.log("NMI Gateway error", e);
        });
      } catch (e) {
        console.log("could not intialize Gateway.js", e);
      }
    }
    if (typeof CollectJS === "undefined") {
      console.log("Could not load CollectJS Script for NMI Payment");
      return;
    }
    let googlePayConfig = { buttonType: "plain" };
    let applePayConfig = { type: "plain", style: { height: "40px" } };
    let info = order.getOrderTotal();
    let total = info.totalRemaining.toFixed(2);
    if (paymentInfo.enable3DSecure && threeDS) {
      googlePayConfig = {
        billingAddressRequired: true,
          billingAddressParameters: {
          format: "FULL"
        },
        buttonType: "plain"
      };

      try { //Clear the lineItems otherwise collectjs will concat the new and old array if we call configure again, bloat
        CollectJS.config.fields.applePay.lineItems = [];
      } catch (e) {
        console.log(e);
      }
      applePayConfig = {
        requiredBillingContactFields: ["postalAddress", "name"],
        lineItems: [{ label: (company ? company.name : branch.name), amount: total }],
        type: "plain",
        style: { height: "40px" }
      };
    }

    CollectJS.configure({
      paymentSelector: "#btnPayNmi",
      variant: "inline",
      googleFont: "Roboto:500",
      customCss: {
        "border-width": "0px",
        "padding": "0px",
        "font-family": "Roboto",
        "font-size": "16px",
        "height": "auto"
      },
      invalidCss: {
        "background-color": "#ffebeb"
      },
      fields: {
        ccnumber: { selector: "#txtCardNumber" },
        ccexp: { selector: "#txtCardExpiry", placeholder: "MM / YY" },
        cvv: { selector: "#txtCardCVV" },
        googlePay: this.isGooglePayEnabled("nmi") ? googlePayConfig : {},
        applePay: this.isApplePayEnabled("nmi") ? applePayConfig : {}
      },
      price: info.totalRemaining.toFixed(2),
      country: country,
      currency: currency,
      validationCallback: (field, status, message) => {
        //console.log("validation NMI", field, status, message);
      },
      callback: (message) => {
        showSpinner();
        if (paymentInfo.enable3DSecure && threeDS) { // 3DS
          let preOptions = {
            city: this.cardBillingCity,
            state: this.cardBillingState,
            address1: this.cardBillingAddress,
            country: this.cardCountry,
            firstName: this.cardFirstName,
            lastName: this.cardLastName,
            postalCode: this.cardBillingPostalCode
          };
          if (message.tokenType == "googlePay" || message.tokenType == "applePay") {
            preOptions = {
              city: message.wallet.billingInfo.city,
              state: message.wallet.billingInfo.state,
              address1: message.wallet.billingInfo.address1,
              country: message.wallet.billingInfo.country,
              firstName: message.wallet.billingInfo.firstName,
              lastName: message.wallet.billingInfo.lastName,
              postalCode: message.wallet.billingInfo.postalCode
            };
          }
          const options = {
            paymentToken: message.token,
            currency: currency,
            amount: total,
            email: user.email,
            phone: user.phoneNumber,
            ...preOptions
          };
          try {
            this.reset3dsForNmi();
            this.threeDSecureInterface = threeDS.createUI(options);
            this.threeDSecureInterface.start("#threeDSMountPoint");
            this.threeDSecureInterface.on("complete", (response) => { // Success, happy path
              this.reset3dsForNmi();
              if (sessionId) {
                options.transactionSessionId = sessionId;
              }
              let data = {
                ...options,
                ...response,
                save: this.saveCard
              };
              this.nmiPayment = order.addNmiPayment(data, true);
              setTimeout(() => {
                this._submit();
                hideSpinner(true);
              }, 1);
            });
            this.addDefaultListenersToNmi3ds();
          } catch (e) {
            hideSpinner(true);
            this.nmiPaymentChallengeInProgress = false;
            console.log("failed 3DS", e);
          }
        } else { // Not 3DS
          let data = {
            token: message.token,
            save: this.saveCard
          };
          if (paymentInfo.enableKount && sessionId) {
            data.transactionSessionId = sessionId;
          }
          this.nmiPayment = order.addNmiPayment(data, false);
          setTimeout(() => {
            this._submit();
            hideSpinner(true);
          }, 1);
        }
      }
    });
  }

  //TODO compatibility on branch level
  initializeGlobal() {
    setTimeout(async () => {
      showSpinner();
      let order = Store.state.order;
      let company = Store.state.currentCompany;
      let branch = Store.state.currentBranch;
      let info = order.getOrderTotal();
      let user = Store.state.user;
      let iframeElementID = "globalIframe";

      let global;
      try {
        global = await Axios.post(Store.getters.urlServer + "/api/me/global_init", {
          company: company ? company.nameCanonical : undefined,
          branch: branch ? branch.nameCanonical : undefined,
          amount: info.totalRemaining,
          currency: "CAD",
          customerEmail: user.email,
          customerPhoneNumber: user.phoneNumber,
          addressesMatch: true,
          billing: {
            addr1: this.cardBillingAddress,
            addr2: "",
            addr3: "",
            city: this.cardBillingCity,
            state: this.cardBillingState,
            postalCode: this.cardBillingPostalCode,
            country: this.cardCountry
          }
        }, {
          headers: {
            Authorization: "Bearer " + user.token
          }
        });
      } catch (e) {
        console.log("global init error", e);
        hideSpinner();
        return;
      }

      if (global) {
        this.globalInitialized = true;
        this.globalRawData = global.data;
        this.globalResponseOrigin = global.data.tmp.baseUrl;
        delete this.globalRawData.tmp;

        RealexHpp.setHppUrl(this.globalResponseOrigin);
        RealexHpp.embedded.init("globalReady", iframeElementID, "javascript:void(0)", this.globalRawData);

        window.addEventListener("message", (event) => {
          if (this.globalResponseOrigin.indexOf(event.origin) > -1) {
            let data = JSON.parse(event.data);
            if (data.iframe) { // IFRAME SIZE CHANGES
              document.getElementById(iframeElementID).style.height = "calc(" + data.iframe.height + " + 50px)";
            } else if (data.ORDER_ID) { // PAYMENT IS COMPLETE
              showSpinner();
              this.globalPayment = order.addGlobalPayment(data);
              setTimeout(() => {
                this._submit();
                hideSpinner();
              }, 500);
            }
          }
        }, false);

        setTimeout(() => {
          document.getElementById("globalReady").click();
        }, 100);
      }
      hideSpinner();
    }, 0);
  }

  initializePaysafe() {
    let paysafeInfos = this.getPaymentMethodInfo("paysafe");
    let key = btoa(paysafeInfos.username + ":" + paysafeInfos.password);
    let environment = paysafeInfos.isLiveMode ? "LIVE" : "TEST";
    let options = {
      environment: environment,
      fields: {
        cardNumber: {
          selector: "#txtCardNumber",
          placeholder: translate("payment.card_number")
        },
        expiryDate: {
          selector: "#txtCardExpiry",
          placeholder: translate("payment.expiration_date")
        },
        cvv: {
          selector: "#txtCardCVV",
          placeholder: translate("payment.cvv"),
          optional: false
        }
      }
    };
    paysafe.fields.setup(key, options, (instance, error) => {
      if (instance) {
        this.paysafeInstance = instance;
        instance.fields("cvv cardNumber expiryDate").on("Valid Invalid", (instance, event) => {
          this.paysafeError = "";
          if (event.type == "Invalid") {
            this.paysafeError = translate("error.card." + event.target.fieldName);
          }
        });
      } else {
        console.log(error);
      }
    });
  }

  async initializePayFactoSHC() {
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let entity = company || branch;
    let user = Store.state.user;
    let infos = entity.getPaymentMethod("payfacto");
    this.usePayFactoSHC = (infos.initLib === "SHC");
    if (!this.usePayFactoSHC) {
      return;
    }
    showSpinner();
    await this._loadAndReturnPayfactoLibs(infos);
    try {
      let url = user.token ? "/api/me/payfacto_init" : "/api/anon/payfacto_init";
      let getToken = await Axios.get(Store.getters.urlServer + url, {
        params: {
          company: company ? company.nameCanonical : undefined,
          branch: branch ? branch.nameCanonical : undefined,
          language: LocalizeStore.state.locale,
          context: Store.state.qrPaymentMode ? "qrPay" : undefined
        },
        headers: user.token ? {
          Authorization: "Bearer " + user.token
        } : undefined
      });
      if (!getToken.data.secureId) {
        hideSpinner();
        return;
      }
      setTimeout(() => {
        this.mountPayFactoSHC(getToken.data);
      }, 0);
    } catch (e) {
      console.log("payfacto init error", e);
    }
    hideSpinner();
  }

  reinitializePayfactoSHC() {
    if (this.paymentMethod === "payfacto" && this.usePayFactoSHC && this.payFactoSHC) {
      let form = document.getElementById("shc-fields");
      let isFormEmpty = form && form.childNodes.length === 0;
      if (isFormEmpty) {
        this.payFactoSHC = null;
        this.initializePayFactoSHC();
      }
    }
  }

  /**
   * SecureFields init
   * @returns {Promise<void>}
   */
  async initializePayfactoSecureFields() {
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let entity = company || branch;
    let user = Store.state.user;

    this.destroyPayfacto();
    showSpinner();
    let infos = entity.getPaymentMethod("payfacto");
    let libs = await this._loadAndReturnPayfactoLibs(infos);
    if (infos.initLib === "SHC") {
      return;
    }
    try {
      let url = user.token ? "/api/me/payfacto_init" : "/api/anon/payfacto_init";
      let getToken = await Axios.get(Store.getters.urlServer + url, {
        params: {
          company: company ? company.nameCanonical : undefined,
          branch: branch ? branch.nameCanonical : undefined,
          context: Store.state.qrPaymentMode ? "qrPay" : undefined
        },
        headers: user.token ? {
          Authorization: "Bearer " + user.token
        } : undefined
      });
      let token = getToken.data.secureID;
      if (!token) {
        hideSpinner();
        return;
      }
      let response = await PayFacto.init(token, {});

    } catch (e) {
      console.log("payfacto init error", e);
      hideSpinner();
      return;
    }
    PayFacto.setGlobalConfiguration({
      apiUrl: libs.api + "/v1",
      iframeUrl: libs.iframe,
      lang: LocalizeStore.state.locale
    });
    hideSpinner();
  }

  mountPayFactoSHC(token) {
    if (typeof shc === "undefined") {
      console.log("could not load payfacto Secure Hosted Checkout lib");
    }
    let info = Store.state.order.getOrderTotal();
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let currency = company ? company.currency : branch.getCurrencyFromFirstCompany();
    /* Load custom stylesheet */
    let styleElement = document.getElementById("shc-style");
    if (!styleElement) {
      styleElement = document.createElement("style");
      styleElement.setAttribute("id", "shc-style");
      styleElement.setAttribute("type", "text/css");
      styleElement.innerText =
        "#shc-fields iframe { min-height: 315px !important; height: 315px !important; }" +
        "#shc-pay-button { display: none; }" + //Hide payment button from payment form
        "#shc>#target { padding: 30px 15px 15px 15px; background-color: white; }" + //form layout
        ".confidante-field-label { box-shadow: none; border-radius: 5px; border: 1px solid #d9d9d9; }" + //Labels (Kind of input layout)
        ".confidante-field-input { border-radius: 5px; } " + //Inputs (additional input layout)
        ".confidante-field-img>svg { fill: #bcbcbc; }"; //Icons
      document.body.appendChild(styleElement);
    }
    /* Mount SHC */
    let options = {};
    if (this.is3DSecureEnabled("payfacto")) {
      options = {
        purchaseAmount: Math.round(info.totalRemaining * 100),
        purchaseCurrency: currency,
        purchaseDate: new Date().toISOString()
      };
    }
    this.payFactoToken = null;
    this.payFactoSHCReadyForPayment = false;
    this.payFactoSHC = shc(token, (data) => { //PayFacto Submit callback
      //Reset spinner stack
      hideSpinner(true);
      if (data.success) { //Success
        this.payFactoToken = data.token;
        this._submit();
      } else { //Error
        console.log("payfacto SHC error", data);
        showAffirmation(translate("error.title"), translate("payment.paysafe_error"));
        this.paymentMethod = null;
        let paymentButton = document.getElementById("btnPay");
        if (paymentButton) {
          paymentButton.classList.remove("disabled");
        }
      }
    }, options);
    this.payFactoSHC.on("shc-ready-to-submit", (status) => {
      this.payFactoSHCReadyForPayment = status.isReady;
    });
    this.payFactoSHC.on("shc-3ds-challenge", () => {
      hideSpinner();
      //Resize payment iframe to contain 3ds challenge
      let iframe = document.querySelectorAll("#shc-fields iframe")[0];
      if (iframe) {
        iframe.style.cssText = "min-height: 650px !important";
        iframe.scrollIntoView({ behavior: "smooth" });
      }
      let paymentButton = document.getElementById("btnPay");
      if (paymentButton) {
        paymentButton.classList.add("disabled");
      }
    });
    this.payFactoSHC.on("shc-3ds", () => {
      showSpinner();
    });
  }

  sendSubmitForPayFactoSHC() {
    if (!this.payFactoSHC || !this.usePayFactoSHC) {
      return;
    }
    if (this.saveCardEnabled() && this.savedCardID) {
      this._submit();
    } else {
      this.payFactoSHC.sendSubmit();
    }
  }

  async _loadAndReturnPayfactoLibs(infos) {
    /* Secure Hosted Checkout */
    if (this.usePayFactoSHC) {
      if (infos.isLiveMode) {
        //Default value libs
        let prodLibs = {
          api: "https://api.payfacto.cloud",
          js: "https://form.payfacto.cloud/secure-hosted-checkout/v1/shc.js"
        };
        //Override libs
        if (infos.prod) {
          if (infos.prod.api) {
            prodLibs.api = infos.prod.api;
          }
          if (infos.prod.js) {
            prodLibs.js = infos.prod.js;
          }
        }
        //Load libs to document
        await window.loadScripts([
          {
            type: "javascript",
            active: true,
            url: prodLibs.js
          }
        ]);
        return prodLibs;
      } else {
        //Default value libs
        let devLibs = {
          api: "https://test.api.payfacto.cloud",
          js: "https://form.gateway.staging.payfacto.cloud/secure-hosted-checkout/v1/shc.js"
        };
        //Override libs
        if (infos.dev) {
          if (infos.dev.api) {
            devLibs.api = infos.dev.api;
          }
          if (infos.dev.js) {
            devLibs.js = infos.dev.js;
          }
        }
        //Load libs to document
        await window.loadScripts([
          {
            type: "javascript",
            active: true,
            url: devLibs.js
          }
        ]);
        return devLibs;
      }
    }

    /* SecureFields */
    if (infos.isLiveMode) {
      //Default value libs
      let prodLibs = {
        api: "https://api.payfacto.cloud",
        iframe: "https://form.payfacto.cloud/SecureFields",
        js: "https://form.payfacto.cloud/securefields/1.6.0/payfacto-secure-fields.js",
        css: "https://form.payfacto.cloud/securefields/1.6.0/payfacto-secure-fields.css"
      };
      //Override libs
      if (infos.prod) {
        if (infos.prod.api) {
          prodLibs.api = infos.prod.api;
        }
        if (infos.prod.iframe) {
          prodLibs.iframe = infos.prod.iframe;
        }
        if (infos.prod.css) {
          prodLibs.css = infos.prod.css;
        }
        if (infos.prod.js) {
          prodLibs.js = infos.prod.js;
        }
      }
      //Load libs to document
      await window.loadScripts([
        {
          type: "javascript",
          active: true,
          url: prodLibs.js
        },
        {
          type: "stylesheet",
          active: true,
          url: prodLibs.css
        }
      ]);
      //return loaded lib URLs
      return prodLibs;
    } else {
      //Default value libs
      let devLibs = {
        api: "https://test.api.payfacto.cloud",
        iframe: "https://test.form.payfacto.cloud/SecureFields",
        js: "https://test.form.payfacto.cloud/securefields/1.6.0/payfacto-secure-fields.js",
        css: "https://test.form.payfacto.cloud/securefields/1.6.0/payfacto-secure-fields.css"
      };
      //Override libs
      if (infos.dev) {
        if (infos.dev.api) {
          devLibs.api = infos.dev.api;
        }
        if (infos.dev.iframe) {
          devLibs.iframe = infos.dev.iframe;
        }
        if (infos.dev.css) {
          devLibs.css = infos.dev.css;
        }
        if (infos.dev.js) {
          devLibs.js = infos.dev.js;
        }
      }
      //Load libs to document
      await window.loadScripts([
        {
          type: "javascript",
          active: true,
          url: devLibs.js
        },
        {
          type: "stylesheet",
          active: true,
          url: devLibs.css
        }
      ]);
      //return loaded lib URLs
      return devLibs;
    }
  }

  async initializeCybersource() {
    let infoRecipientAccount = this.getPaymentMethodInfo("cybersource");

    let order = Store.state.order;
    let user = Store.state.user;
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    showSpinner();

    let info = order.getOrderTotal();
    if (info.totalRemaining === 0) {
      console.log("no init cybersource when total is null");
      hideSpinner();
      return;
    }
    let cybersource = await Axios.get(Store.getters.urlServer + "/api/me/cybersource_init", {
      params: {
        company: company ? company.nameCanonical : undefined,
        branch: branch ? branch.nameCanonical : undefined,
        amount: info.totalRemaining,
        currency: "usd",
        locale: LocalizeStore.state.locale,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
        targetOrigin: infoRecipientAccount.targetOrigin ? infoRecipientAccount.targetOrigin : CONFIG.urlFrontend
      },
      headers: {
        Authorization: "Bearer " + user.token
      }
    });
    let captureContext = cybersource.data.captureContext;

    // custom style
    let myStyles = {
      input: {
        "font-size": "14px",
        "font-family": "Roboto, sans-serif",
        color: "#1a1a1a"
      },
      ":disabled": { cursor: "not-allowed" },
      valid: { color: "#3c763d" },
      invalid: { color: "#a94442" }
    };

    // setup
    let flex = new Flex(captureContext);
    let microform = flex.microform({ styles: myStyles });
    this.cybersourceMicroform = microform;
    let number = microform.createField("number", { placeholder: "Enter card number" });
    let securityCode = microform.createField("securityCode", { placeholder: "•••" });
    number.load("#number-container");
    securityCode.load("#securityCode-container");
    hideSpinner();
  }

  submitNmiWithSavedCard() {
    let paymentInfo = this.getPaymentMethodInfo("nmi");
    if (!paymentInfo) {
      return;
    }
    if (this.saveCardEnabled() && this.savedCardID) {
      if (paymentInfo.enable3DSecure) { //Save card with 3ds
        this.challengeNmiBillingTokenWith3ds();
      } else { //Save card without 3ds
        this.nmiPayment = Store.state.order.addNmiPayment({
          billingToken: this.savedCardID
        }, false);
        this._submit();
      }
    }
  }

  payWithCybersource() {
    return new Promise((resolve, reject) => {
      let expMonth = document.querySelector("#expMonth");
      let expYear = document.querySelector("#expYear");
      let errorsOutput = document.querySelector("#errors-output");
      let options = {
        expirationMonth: expMonth.value,
        expirationYear: expYear.value
      };
      this.cybersourceMicroform.createToken(options, function (err, token) {
        if (err) {
          errorsOutput.textContent = err.message;
          reject(err);
        } else {
          resolve(JSON.stringify(token));
        }
      });
    });
  }

  payWithPaysafe() {
    return new Promise((resolve, reject) => {
      let info = Store.state.order.getOrderTotal();
      let user = Store.state.user;
      let paysafeInfos = this.getPaymentMethodInfo("paysafe");

      if (!paysafeInfos) {
        reject("not paysafe");
      }

      if (info.totalRemaining == 0) {
        reject("total remaining at 0");
      }

      let parameters = {
        threeDS: paysafeInfos.enable3DSecure ? {
          amount: info.totalRemaining * 100,
          currency: "CAD",
          accountId: parseInt(paysafeInfos.accountNumber),
          useThreeDSecureVersion2: false,
          authenticationPurpose: "PAYMENT_TRANSACTION"
        } : undefined,
        profile: {
          phone: user.phoneNumber,
          email: user.email
        },
        vault: {
          holderName: this.cardholderName
        },
        billingAddress: {
          country: this.cardCountry,
          zip: this.cardBillingPostalCode
        }
      };
      let billingInformation = this.paysafeBillingInformationRequired ? parameters : undefined;
      this.paysafeInstance.tokenize(billingInformation, (instance, error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result.token);
        }
      });
    });
  }

  async processPayment() {
    let order = Store.state.order;
    let user = Store.state.user;
    let company = Store.state.currentCompany;
    let info = order.getOrderTotal();

    showSpinner();
    let paymentProcessed = false;
    setTimeout(() => {
      if (!paymentProcessed) {
        showSpinner(translate("spinner.5_seconds"));
        hideSpinner();
      }
    }, 5 * 1000);
    setTimeout(() => {
      if (!paymentProcessed) {
        showSpinner(translate("spinner.15_seconds"));
        hideSpinner();
      }
    }, 15 * 1000);
    setTimeout(() => {
      if (!paymentProcessed) {
        showSpinner(translate("spinner.30_seconds"));
        hideSpinner();
      }
    }, 30 * 1000);

    if (info.totalRemaining === 0) {
      paymentProcessed = true;
      return true;
    }

    switch (this.paymentMethod) {

      case "paysafe": {
        let paysafeToken;
        try {
          paysafeToken = await this.payWithPaysafe();
        } catch (e) {
          console.log("paysafe error", e);
          showAffirmation(translate("error.title"), translate("payment.paysafe_error"));
          this._openPaymentPanel();
          paymentProcessed = true;
          hideSpinner();
          return false;
        }
        paymentProcessed = true;
        this.paysafePayment = order.addPaysafePayment(paysafeToken);
        return true;
      }

      case "itc": {
        this.itcPayment = order.addItcPayment(this.itcCredentials);
        paymentProcessed = true;
        return true;
      }

      case "cybersource": {
        if (this.saveCardEnabled() && this.savedCardID) {
          this.cybersourcePayment = Store.state.order.addCybersourcePayment(null, null, {
            card: this.savedCardID
          });
        } else {
          let token;
          try {
            token = await this.payWithCybersource();
          } catch (e) {
            console.log("cybersource payment error", e);
            showAffirmation(translate("error.title"), translate("payment.paysafe_error"));
            paymentProcessed = true;
            hideSpinner();
            return false;
          }
          paymentProcessed = true;
          this.cybersourcePayment = order.addCybersourcePayment(token, {
            state: this.cardBillingState,
            country: this.cardCountry,
            zip: this.cardBillingPostalCode,
            firstName: this.cardFirstName,
            lastName: this.cardLastName,
            city: this.cardBillingCity,
            address: this.cardBillingAddress
          }, {
            save: this.saveCard
          });
        }
        return true;
      }

      case "payfacto": {
        if (this.usePayFactoSHC) { //SHC
          if (this.payFactoToken && !this.payfactoPayment) {
            this.payfactoPayment = Store.state.order.addPayfactoPayment({
              token: this.payFactoToken,
              save: this.saveCard
            });
            paymentProcessed = true;
            return true;
          } else if (this.saveCardEnabled() && this.savedCardID) { //SHC + SaveCard
            this.payfactoPayment = order.addPayfactoPayment({ card: this.savedCardID });
            paymentProcessed = true;
            return true;
          } else { //Invalid scenario
            paymentProcessed = true;
            showAffirmation(translate("error.title"), translate("payment.paysafe_error"));
            return false;
          }
        } else {
          //SecureFields, payment is pre-processed
          paymentProcessed = true;
          return true;
        }
      }

      case null:
      case "none":
      case "global":
      case "nmi":
      case "": {
        paymentProcessed = true;
        return true;
      }

      default: {
        let OFFLINE_METHODS = ["cash", "interac", "mastercard", "visa", "amex"];
        if ((company && company.isOfflinePaymentMethod(this.paymentMethod)) || (user.isDispatchUser() && OFFLINE_METHODS.indexOf(this.paymentMethod) > -1)) {
          paymentProcessed = true;
          return true;
        }
      }
    }
  }

  destroyPayfacto() {
    document.getElementById("threeDSecureClientCallbackUrl")?.remove();
    document.getElementById("monUrl")?.remove();
    document.getElementById("authUrl")?.remove();

    if (typeof PayFacto !== "undefined" && PayFacto.initialized) {
      PayFacto.initialized = false;
      PayFacto.secureID = null;
    }
  }

  async showPayfactoModal() {
    await this.initializePayfactoSecureFields();
    let order = Store.state.order;
    let info = order.getOrderTotal();

    if (info.totalRemaining === 0) {
      this._submit();
      return;
    }
    if (this.saveCardEnabled() && this.savedCardID) {
      this.payfactoPayment = order.addPayfactoPayment({ card: this.savedCardID });
      this._submit();
      return;
    }

    if ((typeof PayFacto === "undefined" || !PayFacto.initialized) && !this.usePayFactoSHC) {
      console.log("error payfacto");
      showAffirmation(translate("error.title"), translate("payment.payfacto_error"));
      return;
    }
    let options = {
      id: "payfacto-secure-fields-modal",
      permanent: this.saveCard,
      purchaseAmount: this.is3DSecureEnabled() ? Math.round((info.totalRemaining * 100)).toString() : undefined,
      purchaseCurrency: this.is3DSecureEnabled() ? "CAD" : undefined,
      purchaseDate: this.is3DSecureEnabled() ? moment().format() : undefined,
      header: {
        title: translate("payment.payment_information")
      },
      fields: {
        all: {
          title: {
            floating: false
          }
        }
      },
      autoClose: true
    };
    let executed = false;
    let modal = PayFacto.modal((promise) => {
      promise.then((response) => {
        if (executed || this.payfactoPayment) {
          return;
        }
        executed = true;
        this.payfactoPayment = order.addPayfactoPayment({
          token: response.token,
          save: this.saveCard
        });
        this._submit();
      }).catch((e) => {
        console.log("error payfacto", e);
        switch (e.returnCode) {
          case "8110":
            showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("error.card.ExpiryDate"));
            break;
          case "5060":
            showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("error.session_expired_inactivity"));
            break;
          default:
            showAffirmation(translate("error.oops"), translate("error.qr_pay_submit_sale"));
            break;
        }
      });
    }, options);
  }

  async showPayfactoSuccessFeedback(sale) {
    let payfactoPayment = sale.payments.find(p => p.processor === "payfacto");
    if (!payfactoPayment) {
      return;
    }
    let info = payfactoPayment.data.moreInfo;
    await showAffirmation(translate("payment.success"),
      translate("payment.completed") + "<br><br>" +
      "<b>" + info.returnCode.replace(" ", "") + " - " + translate("success") + "</b><br>" +
      translate("payment.transaction_number") + ": #" + info.transactionNumber + "<br>" +
      translate("payment.authorization_number") + ": #" + info.authorizationNumber + "<br>",
      null, null, true
    );
  }

  showValitorSuccessFeedback(sale) {
    let valitorPayment = sale.payments.find(p => p.processor === "valitor");
    if (!valitorPayment || (valitorPayment && !valitorPayment.data)) {
      return;
    }
    let info = valitorPayment.data;
    showAffirmation(translate("payment.success"),
      translate("payment.completed") + "<br><br>" +
      translate("payment.transaction_number") + ": #" + info.TransactionNumber + "<br>" +
      translate("payment.authorization_number") + ": #" + info.AuthorizationNumber + "<br>",
      null, null, true
    );
  }

  //TODO compatibility on branch level
  async payWithValitor({ $iFrameRef }) {
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let entity = company || branch;
    let infos = entity.getPaymentMethod("valitor");
    if (!infos) {
      return;
    }
    let order = Store.state.order;
    let info = order.getOrderTotal();
    let entityCurrency = company ? company.currency : branch.getCurrencyFromFirstCompany();
    let currency = infos.isLiveMode ? entityCurrency : "ISK"; //TODO branch level currency?
    let total = infos.isLiveMode ? info.totalRemaining : info.totalRemaining * 100;

    let iFrameRequestData = {
      company: company ? company.nameCanonical : undefined,
      branch: branch ? branch.nameCanonical : undefined,
      language: order.locale,
      currency: currency,
      items: [{
        name: `Order from ${company ? company.nameCanonical : branch.nameCanonical}`,
        unitPrice: total,
        quantity: 1
      }]
    };
    let iFrameRequestOptions = {
      headers: {
        Authorization: "Bearer " + Store.state.user.token
      }
    };

    /* Get iFrame data */
    let iFrameData = {};
    try {
      showSpinner();
      iFrameData = await Axios.post(Store.getters.urlServer + "/api/me/generate_valitor_iframe_data", iFrameRequestData, iFrameRequestOptions);
    } catch (e) {
      console.log("Valitor iframe data generation error", e);
      hideSpinner();
      return;
    }

    /* IFrame handling */
    let iFrameUrl = iFrameData.data.url;
    let reference = iFrameData.data.reference;
    let checkStatusApi = iFrameData.data.checkStatusApi;
    let iFrameStatus = null;

    if (!iFrameUrl) {
      hideSpinner();
      return;
    }

    this.valitorInFocus = true;
    $iFrameRef.setAttribute("src", iFrameUrl);
    $iFrameRef.onload = (async () => {
      $iFrameRef.scrollIntoView({ behavior: "smooth" });
      hideSpinner();
      let location = $iFrameRef.contentWindow.location;
      if (location && location.pathname) { /* Location.pathname is only accessible on same-domain iframe URL */
        let path = location.pathname;
        /* Transaction Success */
        if (path.indexOf("valitor-success") > -1) {
          $iFrameRef.onload = undefined;

          showSpinner();
          let response = await Axios.get(`${checkStatusApi}/api/valitor_payment_check/${reference}`);
          hideSpinner();
          if (!response.data.paid) {
            console.log("Valitor, payment not received by API");
            window.showAffirmation(window.translate("valitor.payment_unsuccessful"), window.translate("valitor.payment_unsuccessful_description"), null, null, null, "center");
          } else {
            this.valitorPayment = order.addValitorPayment({
              reference,
              currency
            });
            this._submit();
          }
          this.valitorInFocus = false;
          this.readyForPayment = false;
          /* Transaction Cancelled OR Transaction expired */
        } else if (path.indexOf("valitor-cancelled") > -1 || path.indexOf("valitor-session-expired") > -1) {
          $iFrameRef.onload = undefined;
          this.valitorInFocus = false;
          this.readyForPayment = false;
        }
      }
    });
  }

  async getItcBalance() {
    showSpinner();
    this.clearItcAccountInformations();

    let response;
    let user = Store.state.user;
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let order = Store.state.order;
    let info = order.getOrderTotal();

    try {
      response = await Axios.get(Store.getters.urlServer + "/api/itc/get_balance", {
        headers: {
          Authorization: "Bearer " + user.token
        },
        params: {
          company: company ? company.nameCanonical : undefined,
          branch: branch ? branch.nameCanonical : undefined,
          credentials: user.username,
          amount: 0,
          locale: LocalizeStore.state.locale
        }
      });
      hideSpinner();
      if (response.data.authorized) {
        this.itcCredentials = user.username;
        this.itcAccountInformations = response.data;
      } else {
        let message = window.translate("payment.itc_account_not_found") + " " + window.translate(("payment.select_alternative_payment"));
        message += "<br><br>";
        message += "<div class='itc-item'><b>" + window.translate("email") + ":</b> " + user.username + "</div>";
        showAffirmation(window.translate("payment_method.itc"), message);
      }
    } catch (e) {
      console.log("itc balance error", e);
      hideSpinner();
      let message = window.translate("payment.itc_account_not_found");
      message += "<br><br>";
      message += "<div class='itc-item'><b>" + window.translate("email") + ":</b> " + user.username + "</div>";
      showAffirmation(window.translate("payment_method.itc"), message);
    }
  }

  async getItcBalanceFromAccount(cardNumber) {
    showSpinner();
    this.clearItcAccountInformations();

    let response;
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let kioskConfig = Store.state.kioskConfiguration;

    try {
      response = await Axios.get(Store.getters.urlServer + "/api/itc/get_balance", {
        headers: {
          "api-key": kioskConfig.getApiKey()
        },
        params: {
          company: company ? company.nameCanonical : undefined,
          branch: branch ? branch.nameCanonical : undefined,
          deviceId: kioskConfig.getItcDeviceId(),
          credentials: cardNumber,
          amount: 0,
          locale: LocalizeStore.state.locale
        }
      });
      hideSpinner();
      if (response.data.authorized) {
        this.itcCredentials = cardNumber;
        let itcDeviceId = kioskConfig.getItcDeviceId();
        if (itcDeviceId) {
          this.itcCredentials += "|" + itcDeviceId;
        }
        this.itcAccountInformations = response.data;
        let name = "N/A";
        if (this.itcAccountInformations.clientInformation) {
          name = this.itcAccountInformations.clientInformation.FirstName + " " + this.itcAccountInformations.clientInformation.LastName;
        }
        return name;
      } else {
        let message = window.translate("payment.itc_account_not_found_card") + " " + window.translate(("payment.select_alternative_payment"));
        message += "<br><br>";
        message += "<div class='itc-item'><b>" + window.translate("card") + ":</b> " + cardNumber + "</div>";
        showAffirmation(window.translate("payment_method.itc"), message);
        return "";
      }
    } catch (e) {
      console.log("itc balance error", e);
      hideSpinner();
      let message = window.translate("payment.itc_account_not_found_card");
      message += "<br><br>";
      message += "<div class='itc-item'><b>" + window.translate("card") + ":</b> " + cardNumber + "</div>";
      showAffirmation(window.translate("payment_method.itc"), message);
      return "";
    }
  }

  getItcCredentials() {
    return this.itcCredentials;
  }

  /**
   * @private
   */
  _submit() {
    EventBus.$emit("submit-payment");
  }

  /**
   * @private
   */
  _openPaymentPanel() {
    EventBus.$emit("open-payment-panel");
  }

}

let paymentProcessor = new PaymentProcessor();
export { paymentProcessor as PaymentProcessor };
