<template>
  <div id="posHeader" class="pos-header" v-if="isPresent">
    <div class="overlay" :class="{ visible: showOverlay }"></div>
    <div class="pos-header__content">
      <div class="pos-header__padder" :class="{ visible: visible }"></div>
      <div class="button-container button-container__route button-container--left" :class="{ visible: visible }"
           v-if="!isMenu">
        <svgicon src="icons/dashboard.svg" :height="25" :width="25"/>
        {{ $t("title." + $route.name) }}
      </div>

      <div class="button-container button-container__cart button-container__cart--left" :class="{ visible: visible }"
           v-if="!isLeftHandedMode && isMenu">
        <div class="button-container button-container__cart">
          <svgicon src="icons/orders.svg" :height="25" :width="25"/>
          {{ $t("pos.header.order") }}
        </div>
        <PosMethodDropdown v-if="table && table.method !== 'inPlace'" class="margin-right"/>
      </div>

      <div class="dashboard__filler" v-if="isDashboard"></div>

      <Breadcrumb v-if="isMenu"/>

      <div class="button-container button-container--center" v-if="isMenu"
           :class="{ 'button-container--border-right': isLeftHandedMode }">
        <SearchBar :place-holder="$t('search_product')"/>
        <button class="btn pos-header__item" v-wave @click="showPromotionsModal" :class="{ disabled: isOffline }">
          <svgicon src="icons/promotion.svg" :height="25" :width="25"/>
          {{ $t("pos.header.promotions") }}
        </button>
      </div>
      <div class="button-container button-container--center"
           :class="isLeftHandedMode ? 'button-container--border-right' : 'button-container--border-left'"
           v-if="isOrderOverview">
        <!--      <SearchBar :place-holder="$t('search_order')" wide></SearchBar>-->
      </div>
      <div class="button-container button-container__cart" :class="{ hasItems: hasItems || !isMenuAndNotItem }"
           v-if="isLeftHandedMode && isMenu && table">
        <svgicon src="icons/facture.svg" :height="25" :width="25"/>
        {{ $t("pos.header.new_order") }}
        <div class="separator"></div>
        {{ $t("table") }} {{ $store.state.table.tableId }}
        <div class="grow"></div>
        <PosMethodDropdown v-if="table && table.method !== 'inPlace'"/>
      </div>
      <div class="button-container button-container__dashboard" v-if="isDashboard">
        <svgicon src="icons/orders.svg" :height="25" :width="25"/>
        {{ $t("pos.header.latest_orders") }}
      </div>
      <div class="button-container button-container__order-overview" v-if="isOrderOverview">
        <!--      <template v-if="currentTable">-->
        <!--        <svgicon src="icons/facture.svg" :height="25" :width="25"></svgicon>-->
        <!--        {{"#" + currentTable.getOrderId()}}-->
        <!--      </template>-->
      </div>
      <div class="button-container--end" :class="{ grow: !isMenu && !isDashboard && !isOrderOverview }">
        <button class="btn pos-header__notifications" v-wave style="opacity: 0; pointer-events: none;">
          <!-- hidden for now -->
          <svgicon src="icons/notification-bell.svg" :height="25" :width="24"/>
        </button>
        <button class="btn pos-header__printer" v-wave @click="showPrinterConfigurationModal"
                :class="{ disabled: isOffline || !permissionManager.hasPermission(Constant.P_SETTING_EDIT_PRINTER) }">
          <span class="pill" v-if="printQueueSize > 0">{{ printQueueSize }}</span>
          <svgicon src="icons/print-filled.svg" :height="25" :width="24"/>
        </button>
      </div>

    </div>
    <div class="button-container--absolute">
      <button class="btn pos-header__toggle-visibility" @click="togglePosNavVisibility()">
        <svgicon src="icons/pos-nav-hid.svg" :height="24" :width="24"/>
      </button>
    </div>
    <ModalPromotions ref="modalPromotions"/>
    <PrinterConfigurationModal/>
  </div>
</template>

<script>
  import Breadcrumb from "@/components/menu/Breadcrumb.vue";
  import EventBus from "@/lib/eventBus";
  import ModalPromotions from "@/components/promotions/ModalPromotions";
  import PosMethodDropdown from "@/components/pos/posMethodDropdown";
  import PrinterConfigurationModal from "@/components/PrinterConfigurationModal";
  import SearchBar from "@/components/pos/SearchBar";
  import { PermissionManager } from "@/util/PermissionManager";
  import { Constant } from "@/util/Constant";

  export default {
    name: "posHeader",

    components: {
      Breadcrumb,
      SearchBar,
      PrinterConfigurationModal,
      ModalPromotions,
      PosMethodDropdown
    },

    data() {
      return {
        visible: true,
        Constant,
        showOverlay: false
      };
    },

    mounted() {
      EventBus.$on("hide-pos-navigation", () => {
        this.visible = true;
      });
      EventBus.$on("toggle-pos-header-overlay", (status) => {
        this.showOverlay = status;
      });
    },

    beforeDestroy() {
      EventBus.$off("hide-pos-navigation");
      EventBus.$off("toggle-pos-header-overlay");
    },

    computed: {
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      printQueueSize() {
        const statusToPrint = ["pending", "error"];
        return this.$store.state.externalPosSaleQueue
            .filter(p => statusToPrint.indexOf(p.print.status) > -1)
            .length;
      },
      table() {
        return this.$store.state.table;
      },
      //TO-DO  find another name for this
      isMenu() {
        let route = this.$route.name;
        let safeRoutes = this.$store.getters.menuRouteNames;
        return safeRoutes.indexOf(route) > -1;
      },
      isMenuAndNotItem() {
        let route = this.$route.name;
        let safeRoutes = ["menu", "menuWithId"];
        return safeRoutes.indexOf(route) > -1;
      },
      hasItems() {
        return this.table && this.table.getDetails().length > 0;
      },
      isOrderOverview() {
        return this.$route.name === "posOrderOverview";
      },
      isDashboard() {
        return this.$route.name === "posDashboard";
      },
      isPresent() {
        let present = this.$route.name === "posLoading" ||
            this.$route.name === "posOnboarding" ||
            this.$route.name === "posOffline";
        return !present;
      },
      isOffline() {
        return this.$store.state.offline;
      },
      permissionManager() {
        return PermissionManager;
      }
    },

    methods: {
      togglePosNavVisibility() {
        EventBus.$emit("show-pos-navigation");
        this.visible = false;
      },
      showPrinterConfigurationModal() {
        EventBus.$emit("show-printer-configuration-modal");
      },
      showPromotionsModal() {
        this.$refs.modalPromotions.show();
      }
    }
  };
</script>

<style lang="scss" scoped>
#posHeader {
  height: 96px;
  min-height: 96px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: var(--header-background-color);
  z-index: 2;
  pointer-events: all;

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    right: 0;
    height: 96px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, z-index 0.3s ease-in-out;
    z-index: 0;

    &.visible {
      opacity: 1;
      z-index: 20;
    }
  }

  .btn {
    font-size: 20px;
  }

  .pos-header__content {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      background-color: var(--border-color-2);
      width: 100%;
    }
  }
}

.dashboard__filler {
  flex-grow: 1;
  border-right: 1px solid var(--border-color-2);
  height: 56px;
  margin-right: 20px;
}

.button-container--absolute {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 54px;
  border-right: 1px solid var(--border-color-2);
}

.pos-header__toggle-visibility {
  width: 56px;
  height: 54px;
  color: black;
  z-index: 2;
  margin-right: 20px;

  & > .svgicon {
    fill: var(--primary-color);
  }
}

.pos-header__padder {
  width: 0;
  z-index: 0;
  pointer-events: all;
  transition: 125ms ease-in width;

  &.visible {
    width: 100px;
    transition: 100ms ease-in width 120ms; //second value is delay, half+10 of nav animation
  }
}

#iShopFoodApp .button-container--center {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  &.button-container--border-left {
    border-left: 1px solid var(--border-color-2);
    margin-left: 10px;
  }

  &.button-container--border-right {
    border-right: 1px solid var(--border-color-2);
    margin-right: 20px;
  }

  .pos-header__item {
    color: var(--primary-color);
    background-color: var(--header-button-background-color);
    height: 54px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 12px;
    justify-content: flex-start;

    & > .svgicon {
      fill: var(--primary-color);
      margin-right: 20px
    }
  }
}

.button-container__cart, .button-container__dashboard, .button-container__order-overview, .button-container__route {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 600px;
  font-size: 22px;
  font-weight: bold;
  color: var(--theme-color);

  & > .svgicon {
    fill: var(--primary-color);
    margin-right: 20px;
  }

  &.button-container__cart--left {
    border-right: 1px solid var(--border-color-2);
    margin-right: 20px;
    min-width: 648px;
    width: 748px;
    transition: 125ms ease-in width;

    &.visible {
      width: 648px;
      transition: 100ms ease-in width 120ms;
    }
  }

  &.button-container--left {
    margin-right: 20px;
    width: 649px;
    transition: 125ms ease-in width;

    &.visible {
      width: 549px;
      transition: 100ms ease-in width 120ms;
    }
  }
}

.button-container__cart {
  .separator {
    width: 1px;
    height: 24px;
    background-color: var(--border-color-2);
    margin: 0 10px;
  }

  .grow {
    flex-grow: 1;
  }

  &.hasItems {
    color: var(--theme-color);
  }

  &:not(.hasItems) {
    color: var(--theme-color-disabled);
  }
}

.button-container.button-container__order-overview, .button-container.button-container__dashboard {
  width: 532px;
}

.button-container > .svgicon {
  fill: var(--header-icon-color);
}

.button-container--end {
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;

  &.grow {
    flex-grow: 1;
  }

  .pill {
    position: absolute;
    right: -6px;
    top: -6px;
    padding: 2px 5px;
    background-color: var(--primary-color);
    border-radius: 15px;
    font-size: 13px;
    font-weight: 900;
    font-family: 'Segoe UI Black', sans-serif;
    line-height: 1;
    color: #404040;
  }

  .btn {
    position: relative;

    &:not(:last-child) {
      margin-right: 15px;
    }

    .svgicon {
      fill: var(--primary-color);
    }
  }
}

.pos-header .btn {
  background-color: var(--header-button-background-color);
}

.margin-right {
  margin-right: 20px;
}
</style>
