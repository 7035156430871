<template>
  <div id="textDisplayModal" class="modal" :class="{show: visible}">
    <div class="modal-content" id="textDisplayModalContent">
      <div class="modal__title">
        <div class="title__icon">
          <svgicon :src="icon" v-if="icon" :height="24" :width="24"></svgicon>
        </div>
        <div class="title__text">{{ $t(title) }}</div>
      </div>
      <div class="modal__body">
        <div class="body__section" v-for="(text, textIndex) of texts" :key="textIndex">
          <div class="section__title" v-if="text.title && $t(text.title)">
            {{ $t(text.title) }}
          </div>
          <div class="section__body" v-if="text.body && $to(text.body)">
            {{ $to(text.body) }}
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <button class="footer__button" v-wave @click="hide">
          <svgicon src="icons/back.svg" :height="22" :width="22"></svgicon>
          {{ $t("back") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "textDisplayModal",

    data() {
      return {
        visible: false,
        ready: false,
        title: {fr: "", en: ""},
        icon: '',
        texts: [] //{title: {fr: "", en: ""}, body: {fr: "", en: ""}}
      };
    },

    mounted() {
      EventBus.$on("show-text-display-modal", (param) => {
        this.load(param);
        this.show();
      });
      EventBus.$on("hide-text-display-modal", () => {
        this.hide();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-text-display-modal");
      EventBus.$off("hide-text-display-modal");
      this.removeOutsideModalClickListener();
    },

    computed: {
      isVisible() {
        return this.visible && this.ready;
      }
    },

    methods: {
      hide() {
        this.visible = false;
        this.removeOutsideModalClickListener();
      },
      show() {
        this.visible = true;
      },
      load(modalContent) {
        this.clear();
        this.title = modalContent.title;
        this.icon = modalContent.icon;
        this.texts = modalContent.texts || [];
        this.ready = true;
        this.addOutsideModalClickListener();
      },
      clear() {
        this.ready = false;
        this.title = {fr: "", en: ""};
        this.icon = '';
        this.texts = [];
      },

      addOutsideModalClickListener() {
        window.addEventListener('click', this.outsideModalClickListener,true);
      },
      removeOutsideModalClickListener() {
        window.removeEventListener('click', this.outsideModalClickListener, true);
      },
      outsideModalClickListener(event) {
        let modal = document.getElementById("textDisplayModalContent");
        if (!modal.contains(event.target)) {
          this.hide();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #textDisplayModal.modal {
    z-index: 9;

    .modal-content {
      max-width: 35%;
      max-height: 80%;
      border-radius: 40px;
      padding: 40px;
      display: flex;
      flex-direction: column;

      .modal__title {
        font-size: 22px;
        font-weight: 900;
        border-bottom: 1px solid var(--border-color-4);
        padding-bottom: 15px;
        margin-bottom: 40px;
        display: flex;

        .title__icon {
          margin-right: 20px;
          fill: var(--primary-color);
        }
      }

      .modal__body {
        flex-grow: 1;
        overflow-y: scroll;

        .body__section {
          .section__title {
            font-size: 21px;
            font-weight: 900;
            margin-bottom: 15px;
            color: var(--theme-color-3);
          }

          .section__body {
            font-size: 21px;
            font-weight: 400;
            margin-bottom: 20px;
            white-space: pre-wrap;
          }
        }
      }

      .modal__footer {
        text-align: center;
        border-top: 1px dashed var(--border-color-4);
        padding-top: 20px;

        .footer__button {
          font-size: 20px;
          padding: 15px 22px;
          background-color: var(--background-color-3);
          border: none;
          border-radius: 12px;
          color: var(--primary-color);
          cursor: pointer;
          display: flex;

          .svgicon {
            margin-right: 10px;
            fill: var(--primary-color);
          }
        }
      }
    }
  }
</style>