<template>
  <div id="quickAddModal" class="modal" :class="{show, xs: $store.state.screenSize === 'xs', simple: item && !item.isCustomizable()}" @click="close">
    <div class="content modal-content">

      <div class="overflow">
        <Item ref="quickAddItem" v-if="item" :itemId="item.id" @close="$emit('close')"></Item>
      </div>

    </div>
  </div>
</template>

<script>
  import Item from "../../views/Item.vue";

  export default {
    name: "QuickAddModal",

    components: { Item },

    props: {
      item: Object,
      show: Boolean
    },

    methods: {
      close(e) {
        if (e.target === e.currentTarget) {
          this.$emit("close");
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #quickAddModal.modal {
    .modal-content {
      max-width: 880px;
    }

    &.xs .overflow, &.simple.xs .overflow {
      min-height: 100vh;
    }

    &.simple {
      .overflow {
        min-height: auto;
      }
    }

    .overflow {
      min-height: 660px;
      display: flex;
    }

    &.xs {
      .modal-content {
        margin: 0;
        padding: 0;
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #quickAddModal {

    #item.quickAdd {
      flex-grow: 1;
      display: flex;
    }

    #item-basic.quickAdd {
      .overflow-desktop.enabled {
        padding-top: 0;
        padding-bottom: 0;
      }
      .presentation {
        align-items: center;

        .priceContainer {
          display: none;
        }

        .image {
          height: 120px;
          max-width: 180px;
          min-height: auto;
          border-radius: 10px;
          margin: initial;
        }
      }
      .right {
        width: 100%;

        hr {
          display: none;
        }
      }
      .panel-selectors {
        .container {
          padding-left: 0;
          padding-right: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
</style>
