<template>
  <div id="home">

    <MenuHeader :text="$t('navigation.home')"></MenuHeader>

    <div class="background" :style="{backgroundImage: 'url(\'' + $store.state.urlFileServer + backgroundImage +  '\''}">

      <div class="top" :class="{left: alignment === 'left', right: alignment === 'right', image: !!backgroundImage}">
        <div class="title">{{$to(title)}}</div>
        <div class="split"></div>
        <div class="subtitle">{{$to(subtitle)}}</div>
      </div>

      <div class="buttons">
        <router-link :to="{name: 'menu'}" class="button">
          <svgicon src="icons/comptoir.svg" :height="40" :width="40"></svgicon>
          <div class="text">{{$t('cart.order')}}</div>
        </router-link>
        <router-link :to="{name: 'promotions'}" class="button" v-if="PromotionManager.hasPromotion()">
          <svgicon src="icons/promo.svg" :height="40" :width="40"></svgicon>
          <div class="text">{{$t('navigation.promos')}}</div>
        </router-link>
        <router-link :to="{name: 'hub'}" class="button" v-if="$user.token">
          <svgicon src="icons/profile.svg" :height="40" :width="40"></svgicon>
          <div class="text">{{$t('loyalty.my_account')}}</div>
        </router-link>
        <router-link :to="{name: 'login'}" class="button" v-if="!$user.token">
          <svgicon src="icons/profile.svg" :height="40" :width="40"></svgicon>
          <div class="text">{{$t('login.login')}}</div>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>
  import MenuHeader from "../components/menu/MenuHeader.vue";
  import { PromotionManager } from "../PromotionManager";

  export default {
    name: "home",

    components: { MenuHeader },

    data() {
      return {
        PromotionManager
      }
    },

    mounted() {
      this.$store.commit("showNavigationCart", true);
    },

    methods: {

    },

    computed: {
      alignment() {
        if (!this.branch) {
          return;
        }
        return this.branch.style.homePageAlignment;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      title() {
        if (!this.branch) {
          return;
        }
        return this.branch.homePage.title;
      },
      subtitle() {
        if (!this.branch) {
          return;
        }
        return this.branch.homePage.subtitle;
      },
      backgroundImage() {
        if (!this.branch) {
          return;
        }
        if (!this.branch.homePage.backgroundImage) {
          return;
        }
        return this.branch.homePage.backgroundImage.url;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .background {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    background-size: cover;
    background-position: center;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: #ed2937;
    text-decoration: none;
    color :#ed2937;
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    padding: 20px 10px;
    font-size: 12px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
    .svgicon {
      margin-bottom: 10px;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &.image {
      .title {
        text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
      }
      .subtitle {
        text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
      }
    }

    &.left {
      align-items: flex-start;
      text-align: left;
    }
    
    &.right {
      align-items: flex-end;
      text-align: right;
    }
  }

  .title {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-family: 'Segoe UI Black', sans-serif;
    line-height: 1;
    padding-bottom: 15px;
  }

  .split {
    width: 75px;
    height: 5px;
    background-color: #ed2937;
    margin-bottom: 15px;
  }

  .subtitle {
    color: white;
  }
</style>