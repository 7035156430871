<template>
  <div id="company">

    <PageTitle :show-back-button="!canDisplayFooter">
      <svgicon class="icon" src="icons/business.svg" :height="20" :width="20"></svgicon>
      {{$t("company.title")}}
    </PageTitle>

    <div class="page-content">

      <div class="card">
        <div class="card-left" v-if="company && company.image">
          <div class="img" :style="{'background-image': 'url(' + $store.state.urlFileServer + company.image + ')'}"></div>
        </div>
        <div class="card-right">
          <div class="card-big-title">
            <div class="top">
              <div class="headline" v-if="branch">{{branch.name}}</div>
              <div class="subtitle" v-if="company">{{company.name}}</div>
            </div>
          </div>
          <div class="card-content more-info" v-if="company">
            <div class="small-title" v-if="company.information && $to(company.information.description.short)">{{$t("company.our_mission")}}</div>
            <div class="text" v-if="company.information && $to(company.information.description.short)">{{ $to(company.information.description.short) }}</div>
            <div class="small-title products" v-if="company.information && $to(company.information.productType)">{{$t("company.our_products")}}</div>
            <div class="text products" v-if="company.information && $to(company.information.productType)">{{ $to(company.information.productType) }}</div>
            <div class="small-title price-range" v-if="company.information && company.information.price_range">{{$t("company.price_range")}}</div>
            <div class="text price-range" v-if="company.information && company.information.price_range">{{company.information.price_range}}</div>
            <FavoriteCompany :company="company"></FavoriteCompany>
          </div>
        </div>
      </div>

      <Panel :title="$t('company.about_us')" icon="icons/info.svg" v-if="company && company.information && company.information.description
        && $to(company.information.description.longTitle) && $to(company.information.description.long)" collapsible>
        <div class="content-title">{{ $to(company.information.description.longTitle) }}</div>
        <div class="text" v-html="$to(company.information.description.long).replace('\n','<br><br>')"></div>
      </Panel>

      <template v-if="inventory.isBrandingActivated()">
        <Panel class="branding-panel" v-for="(branding, brandingIndex) of brandings" :key="'branding-' + brandingIndex"
               :title="$to(branding.name)" icon="icons/business.svg" collapsible>
          <div class="branding-container">
            <div class="branding__logo">
              <img class="logo" :src="getImage(branding.logo)" alt="Logo">
              <div class="inner-logo"></div>
            </div>
            <div class="branding__description">
              <div class="branding__name">{{$to(branding.name)}}</div>
              <div class="branding__availability" :class="{
                open: isBrandingAvailable(branding),
                openLater: !isBrandingAvailable(branding) && getBrandingStartHour(branding),
                closed: !isBrandingAvailable(branding) && !getBrandingStartHour(branding)
              }">
                <template v-if="isBrandingAvailable(branding)">
                  {{$t('branding.open')}}
                  <template v-if="branding.getAverageDelay($order.method)">
                    <span class="dash">|</span> {{$t('branding.delay_of')}} {{branding.getAverageDelay($order.method)}} {{$t('branding.minutes')}}
                  </template>
                </template>
                <template v-if="!isBrandingAvailable(branding)">
                  <template v-if="getBrandingStartHour(branding)">{{$t('branding.available_at')}} {{getBrandingStartHour(branding)}}</template>
                  <template v-if="!getBrandingStartHour(branding)">{{$t('branding.closed')}}</template>
                </template>
              </div>
              <div class="branding__mission" v-if="$to(branding.shortDescription)">
                <label class="description__title">{{$t('company.our_mission')}}</label>
                <div class="description__content">{{$to(branding.shortDescription)}}</div>
              </div>
              <div class="branding__about">
                <div class="about__title">
                  <div class="title__text">{{$t('branding.about')}}</div>
                </div>
                <div class="about__description">
                  {{$to(branding.description)}}
                </div>
              </div>
              <div class="branding__hours">
                <div class="hours__title">
                  <div class="title__text">{{$t('company.open_hours')}}</div>
                </div>
                <div class="hours__content">
                  <div class="left">
                    <div class="hour" v-for="day of daysLeft" :key="'hour-' + day" :class="{active: getOrderDay === day}">
                      <div class="name">{{$t('day.' + day)}}</div>
                      <div class="ranges">
                        <div class="range" v-for="range of getBrandingHoursForDay(branding, day)">{{parseHour(range.from)}} {{$t('to')}} {{parseHour(range.to)}}</div>
                        <div class="range" v-if="getBrandingHoursForDay(branding, day).length === 0">{{$t('branding.closed')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="hour" v-for="day of daysRight" :key="'hour-' + day" :class="{active: getOrderDay === day}">
                      <div class="name">{{$t('day.' + day)}}</div>
                      <div class="ranges">
                        <div class="range" v-for="range of getBrandingHoursForDay(branding, day)">{{parseHour(range.from)}} {{$t('to')}} {{parseHour(range.to)}}</div>
                        <div class="range" v-if="getBrandingHoursForDay(branding, day).length === 0">{{$t('branding.closed')}}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <br>
              <button class="btnSetBranding btn flat btn-primary" @click="setBranding(branding)">{{$t('branding.order_this')}}</button>
            </div>
          </div>
        </Panel>
      </template>

      <Panel class="last" :title="$t('company.open_hours_coordinates')" icon="icons/map-hours.svg" v-if="company" collapsible>
        <div class="collapse-content split">
          <div class="collapse-left">
            <div class="small-title">{{$t("company.coordinates")}}</div>
            <div class="contact-information" v-if="company.address">
              <p>{{company.address.address}}</p>
              <p>{{company.address.city}} ({{company.address.state}}) {{company.address.zipCode}}</p>
              <a v-if="company.phoneNumber" :href="'tel:' + company.phoneNumber">{{company.phoneNumber}}</a>
              <a v-if="company.email" :href="'mailto:' + company.email">{{company.email}}</a>
              <a v-if="company.information && company.information.social.website" :href="company.information.social.website" target="_blank">{{company.information.social.website}}</a>
            </div>
            <div id="google-map" v-if="branch && branch.enableMapOnCompanyDetails() && company.address && company.address.latitude && company.address.longitude"></div>
          </div>
          <div class="collapse-right">
            <div class="small-title">{{$t("company.open_hours")}}</div>
            <table class="business-hours" v-if="company && company.information">
              <tr v-for="(day, dayIndex) of company.information.hours" :key="dayIndex" :class="{today: isToday(dayIndex)}">
                <td class="day">{{ $t("day." + day.name) }}</td>
                <td class="range" v-for="(range, rangeIndex) of day.hours" :key="rangeIndex">{{ company.formatHour(range.from) }} {{ $t("to") }} {{ company.formatHour(range.to) }}</td>
                <div class="range" v-if="day.hours.length == 0">{{ $t("company.closed") }}</div>
              </tr>
            </table>
          </div>
        </div>
      </Panel>

    </div>

    <div class="version" v-if="CONFIG && CONFIG.version && !$store.getters.isMobileOrTablet">Version {{CONFIG.version}}</div>
  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import FavoriteCompany from "@/components/FavoriteCompany.vue";
  import moment from "moment";

  export default {
    name: "company",

    mounted() {
      this.$store.commit("showNavigationCart", true);
      this.showMap();
    },

    data() {
      return {
        CONFIG,
        daysLeft: ["monday","tuesday","wednesday","thursday"],
        daysRight: ["friday", "saturday", "sunday"]
      }
    },

    components: { FavoriteCompany },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      brandings() {
        return this.inventory.brandings;
      },
      getOrderDay() {
        let day = (this.$order.date || moment()).day();
        let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        return days[day];
      },
      canDisplayFooter() {
        return !!(!this.isDesktop && !this.isTutorial && this.$store.state.currentCompany);
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      isTutorial() {
        return this.$route.name === "tutorial";
      }
    },

    watch: {
      company() {
        this.showMap();
      }
    },

    methods: {
      getImage(image) {
        return image ? this.$store.state.urlFileServer + image.url : null;
      },
      setBranding(branding) {
        this.$order.setBranding(branding);
        this.$router.push(this.$router.generate("/menu"));
      },
      getBrandingHoursForDay(branding, day) {
        if (!branding || !branding.config || !branding.config[this.$order.method]) {
          return [];
        }
        let methodConfig = branding.config[this.$order.method];
        return methodConfig.hours[day].hours;
      },
      parseHour(hour) {
        if (!this.company) {
          return;
        }
        let hourParts = hour.split(":");
        if (parseInt(hourParts[0]) > 24) {
          hour = "23:59";
        }
        return this.company.formatHour(hour);
      },
      isBrandingAvailable(branding) {
        return branding.isAvailable(this.$order.date, this.$order.hour, this.$order.method);
      },
      getBrandingStartHour(branding) {
        return branding.getStartHourForDay(this.$order.date, this.$order.hour, this.$order.method);
      },
      showMap() {
        if (this.company && this.company.address.latitude && this.company.address.longitude && this.branch && this.branch.enableMapOnCompanyDetails()) {
          setTimeout(() => {
            let map = new google.maps.Map(document.getElementById("google-map"), {
              center: { lat: this.company.address.latitude, lng: this.company.address.longitude },
              zoom: 12
            });
            var marker = new google.maps.Marker({
              position: { lat: this.company.address.latitude, lng: this.company.address.longitude },
              map: map,
              icon: "/img/marker.png"
            });
          });
        }
      },
      isToday(day) {
        let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        return days[new Date().getDay()] === day;
      }
    }
  }
</script>

<style lang="scss" scoped>

  .small-title {
    font-size: 14px;
    color: #808080;
  }

  .text {
    font-size: 16px;
    &:not(:last-of-type) {
      padding-bottom: 20px;
    }
  }

  ul {
    margin: 0;
    padding: 8px;
    list-style: none;
  }

  p {
    margin: 0;
  }

  a {
    display: block;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
  }

  #iShopFoodApp .branding-container {
    .branding__name {
      font-size: 24px;
      font-weight: bold;
    }

    .branding__availability {
      margin-bottom: 10px;
      font-size: 14px;

      &.open {
        color: #8bc540 !important;
      }
      &.openLater {
        color: #feb055 !important;
      }
      &.closed {
        color: #ef4244 !important;
      }

      .dash {
        opacity: 0.4;
        margin: 0 2px;
      }
    }
  }

  .card {
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);

    .card-left {
      flex-shrink: 0;
    }
    .card-right {
      flex-grow: 1;
    }
  }

  .card-left {
    padding: 20px;
    .img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      min-width: 280px;
      min-height: 190px;
    }
  }

  .card-right {
    padding: 20px;
    .card-big-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
      .headline {
        font-size: 32px;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 4px;
      }
      .subtitle {
        font-size: 16px;
        color: #a6a6a6;
        display: block;
      }
    }
  }

  .panel .content {
    .content-title {
      font-size: 20px;
      padding: 0 0 12px;
      font-weight: 500;
    }
    .text {
      font-size: 16px;
      text-align: justify;
    }
  }

  .collapse-left {
    padding-right: 20px;

    #google-map {
      margin-top: 16px;
      width: 100%;
      height: 140px;
      background-color: #ccc;
      border-radius: 5px;
    }
  }
  .collapse-right {
    padding-left: 20px;
    margin-left:20px;
    .business-hours {
      width: 100%;
      border-spacing: 0px;
      tr.today td{
        background-color: #f5f5f5;
        font-weight: bold;
      }
      td {
        padding: 5px 3px;
      }
      .range {
        text-align: right;
      }
    }
  }

  .split {
    display: flex;
    padding-top: 16px;
    .collapse-left, .collapse-right {
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 0 10px 0;
      padding: 0 10px;
    }
  }

  .panel.last {
    margin-bottom: 0;
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
      &:after {
        height: 0;
      }
    }

    .card {
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid #e6e6e6;

      .card-right {
        flex-basis: auto;
      }

      .card-left {
        min-height: 200px;
        flex-shrink: 0;
      }
    }

    .card, .split {
      flex-direction: column;
    }
    .collapse-right {
      margin:20px 0 0 0;
    }
  }

  .version {
    text-align: center;
    padding: 20px;
    opacity: 0.6;
  }

  .branding-container {
    display: flex;
    align-items: flex-start;
    margin: 5px;

    .branding__logo {
      border-radius: 10px;
      width: 280px;
      height: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      flex-shrink: 0;
      overflow: hidden;

      .logo {
        height: 140px;
        width: auto;
      }
    }

    .branding__description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .description__content {
        flex-grow: 1;
      }
      .description__title {
        font-size: 14px;
        display: block;
        opacity: 0.7;
      }
    }

    .branding__about, .branding__hours {
      padding-bottom: 20px;
      width: 100%;
      border-bottom: 1px dashed #e6e6e6;
    }

    .hours__title, .about__title {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-weight: bold;
    }

    .branding__mission {
      border-bottom: 1px dashed #e6e6e6;
      width: 100%;
      padding-bottom: 20px;
    }

    .about__description, .hours__content {
      margin-top: 10px;
    }

    .hours__content {
      display: flex;

      .left, .right {
        flex-basis: 0;
        flex-grow: 1;
      }
      .left {
        margin-right: 30px;
      }

      .hour {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &.active {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .version {
      display: none;
    }
    .branding-container {
      flex-direction: column;

      .branding__description {
        width: 100%;
      }

      .branding__hours {
        .hours__content {
          flex-direction: column;
          .left {
            margin-right: 0;
          }
        }
      }

      .branding__logo {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
      .btn {
        width: 100%;
      }
    }
  }

</style>
