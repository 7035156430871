<template>
  <div id="styleList" class="items">

    <template v-for="item of items">

      <ListItem v-if="item.shouldBeDisplayedAs('item', $order.method) || item.shouldBeDisplayedAs('category', $order.method)"
        :item="item" :key="item.id" @selected="selectItem(item)"></ListItem>

    </template>

  </div>
</template>

<script>

import Category from "@/components/menu/Category.vue";
import ListItem from "@/components/menu/ListItem.vue";

export default {

  name: "styleList",

  components: { Category, ListItem },

  props: ["items"],

  methods: {
    selectItem(item) {
      this.$emit("selectItem", item);
    },
  }

}

</script>

<style lang="scss" scoped>

  #styleList {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
  }

</style>