<template>
  <div id="registerConfirmation">

    <PageTitle>
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      <div class="title">
        {{ $t('register.confirmation.title') }}
      </div>
    </PageTitle>

    <div class="page-content">
      <Panel :title="$t('register.confirmation.title')" icon="icons/lock.svg" v-if="!confirmed">
        <div class="description">{{ $t('register.confirmation.description') }}</div>
        <div class="buttons">
          <button type="button" class="btn btn-primary" @click="confirm">{{ $t('register.confirmation.confirm') }}</button>
        </div>
      </Panel>

      <Panel :title="$t('register.confirmation.confirmed')" icon="icons/success.svg" class="email-confirmation" v-if="confirmed">
        <div class="description">{{ $t('register.confirmation.confirmed_description') }}</div>
        <div class="buttons">
          <button type="button" class="btn btn-primary" @click="redirectToMenu">{{ $t('register.confirmation.redirect_to_menu') }}</button>
        </div>
      </Panel>
    </div>

  </div>
</template>

<script>

  import { mapActions } from 'vuex';
  import FloatingLocaleSwitcher from "../../components/tools/FloatingLocaleSwitcher";

  export default {
    name: "registerConfirmation",

    components: { FloatingLocaleSwitcher },

    data() {
      return {
        confirmed: false
      }
    },

    computed: {
      token() {
        return this.$route.query.token;
      }
    },

    mounted() {
      this.$store.commit("showNavigationCart", false);
    },

    methods: {
      ...mapActions(["validateUser"]),

      async confirm() {
        if (!this.token) {
          return;
        }
        showSpinner();
        let response = await this.validateUser(this.token);
        hideSpinner();
        if (response && response.success) {
          this.confirmed = true;
        } else {
          showAffirmation(this.$t("register.confirmation.error"),this.$t("register.confirmation.error_description"));
        }
      },

      redirectToMenu() {
        this.$router.push("/" + this.$route.params.branch);
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 0;
    display: flex;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .panel {
    width: 520px;
    max-width: 100%;
    margin: auto !important;
  }

  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .description {
    margin-bottom: 20px;
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      &:after {
        height: 0;
      }
    }
  }

</style>
<style lang="scss">
  #iShopFoodApp #registerConfirmation {
    .toggleMenu {
      display: none;
    }

    .panel.email-confirmation .titleIcon {
      fill: #80D445 !important;
    }
  }

</style>