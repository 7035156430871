<template>
  <div id="menuDisplayLocationsModal" class="modal" :class="{show}">
    <div class="content modal-content">

      <div class="title">{{$t("title.orderCompany")}}</div>

      <div class="companies">
        <div class="company" v-for="company of companies" @click="selectCompany(company)">
            <div class="icon">
              <svgicon src="icons/branches.svg" :width="30" :height="30"></svgicon>
            </div>
            <div class="right">
              <div class="name">{{ company.name }}</div>
              <div class="address" v-if="company.address">{{ company.address.address }}, {{ company.address.city }}</div>
              <div class="address" v-if="!company.address">&nbsp;</div>
            </div>
            <div class="continue">
              <svgicon src="icons/arrow-right.svg" :width="20" :height="30"></svgicon>
            </div>
        </div>
      </div>


      <div class="buttons">
        <div class="btn btn-primary flat" @click="show = false">
          {{ $t("back") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapActions } from 'vuex';
  import EventBus from '../lib/eventBus';

  export default {
    name: "menuDisplayLocationModal",

    mounted() {
      EventBus.$on("menu-display-locations-modal", () => {
        this.show = true;
      });
    },

    data() {
      return {
        show: false
      }
    },

    watch: {
      show: function(val) {
        if (val && this.companies.length === 1) {
          this.selectCompany(this.companies[0]);
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      companies() {
        if (!this.branch) {
          this.show = false;
          return [];
        }
        return this.branch.getCompaniesWithMenuDisplay();
      }
    },

    methods: {

      ...mapActions({
        setCurrentCompany: "setCurrentCompany",
      }),

      selectCompany(company) {
        this.show = false;
        this.branch.selectCompanyAsDisplayMode(company);
        this.$router.push(this.$router.generate("/menu"));
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #menuDisplayLocationsModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    fill: var(--primary-color);
  }

  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    line-height: 1;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
  }

  .companies {
    max-height: calc(100vh - 200px);
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -10px;
  }

  .company {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #f2f2f2;
    margin-right: 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .right {
    flex-grow: 1;
  }

  .name {
    font-weight: bold;
  }
  .address {
    font-size: 14px;
    margin-top: 2px;
  }

  .continue {
    margin-left: 10px;
  }

  .icon {
    margin-right: 15px;
  }
</style>
