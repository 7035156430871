/* eslint-disable */

require("./jquery.min.js");
require("./jquery.signalR.min.js");

let airxtouch__connection;
let airxtouch__hubProxy;
let airxtouch__currentConfig = {
    fullLayout: 5000,
    layout: null,
    hoverInteraction: false,
    interactionMode: 0,
    appId: null,
    tabId: -1
};
window.airxtouch__timeout = null;
window.airxtouch__appId = null;

window.airxtouch__getAppId = () => {
    if (!getConfiguration("kiosk-app-id")) {
        setConfiguration("kiosk-app-id", "ishop-kiosk-v3-" + window.generateUUID());
    }
    return getConfiguration("kiosk-app-id");
}

window.airxtouch__clearTimeout = () => {
    clearTimeout(window.airxtouch__timeout);
}

// SIGNALR CONNECTION
window.airxtouch__startSignalRConnection = () => {
    console.log("Attempting SignalR start");
    let appId = window.airxtouch__getAppId();

    airxtouch__connection = $.hubConnection("http://localhost:13000", { qs: { ApplicationId: appId } });
    airxtouch__hubProxy = airxtouch__connection.createHubProxy("AIRxTouchHub");

    airxtouch__hubProxy.on("AirPointCommand", (x, y, z, tick) => {
        console.log("AirPointCommand");
    });

    airxtouch__connection.error(err => console.log("signalr error", err));
    airxtouch__connection.starting(() => console.log("signalr starting"));
    airxtouch__connection.stateChanged((state) => {
        // console.log("signalr stateChanged",state);
        if (state.newState === 2) {
            console.log("stopping auto reconnect");
            window.airxtouch__stopSignalRConnection();
            window.airxtouch__startSignalRConnection();
        } else if (state.newState === 1) {
            window.airxtouch__setConfigs();
        }
    });
    airxtouch__connection.disconnected(() => console.log("signalr disconnected"));
    airxtouch__connection.reconnecting(() => console.log("signalr reconnecting"));
    airxtouch__connection.reconnected(() => console.log("signalr reconnected"));

    airxtouch__connection.start()
        .done(() => {
            console.log("Now connected, connection ID=" + airxtouch__connection.id);
            // console.log(hubProxy);
            // invoke("SwitchInteractionMode", 1);
        })
        .fail((err) => {
            console.log("SignalR connect error", err);
            clearTimeout(window.airxtouch__timeout);
            window.airxtouch__timeout = setTimeout(() => window.airxtouch__startSignalRConnection(), 10000)
        });
};

window.airxtouch__stopSignalRConnection = () => {
    if (airxtouch__connection) {
        airxtouch__connection.stop();
    }
}

window.airxtouch__setConfigs = () => {
    let firstMethod = "ConfigureLayoutSettings";
    let firstData = airxtouch__currentConfig.layout;
    if (!airxtouch__currentConfig.layout) {
        firstMethod = "ConfigureFullLayoutSetting";
        firstData = typeof (airxtouch__currentConfig.fullLayout) !== "undefined" ? airxtouch__currentConfig.fullLayout : 80;
    }
    console.log("setting configs", airxtouch__currentConfig);
    window.airxtouch__invoke(firstMethod, firstData, () => {
        window.airxtouch__invoke("SwitchInteractionMode", airxtouch__currentConfig.interactionMode, () => {
            window.airxtouch__invoke("EnableHoverInteraction", airxtouch__currentConfig.hoverInteraction);
        });
    });
}

// const retryLimit = 10;
window.airxtouch__invoke = (methodName, params, callback, iteration = 0) => {
    // console.log("invoke", methodName, params);
    // let error = false;
    if (airxtouch__connection && airxtouch__connection.state === 1) {
        airxtouch__hubProxy.invoke(methodName, params)
            .always(() => {
                if (callback) { callback() }
            })
    }
};

// DRIVER COMMUNICATION
window.airxtouch__getLayoutValue = (layoutCode) => {
    switch (layoutCode) {
        /** Ideal for drawing or painting interactions */
        case "Drawing" : return airxtouch__currentConfig.fullLayout = 0;
        /** Ideal for small container with scrolling */
        case "ScrollBar" : return airxtouch__currentConfig.fullLayout = 20;
        /** Ideal for large container with scrolling */
        case "ScrollView" : return airxtouch__currentConfig.fullLayout = 40;
        /** Ideal for drag and drop interactions */
        case "DragDrop" : return airxtouch__currentConfig.fullLayout = 80;
        /** Ideal for basic click interaction - prevent all drag and drop or scroll interaction */
        case "DirectClick" : return airxtouch__currentConfig.fullLayout = 5000;

        default: return airxtouch__currentConfig.fullLayout = 80;
    }
}

window.airxtouch__submitLayout = (layout) => {
  let proxy = document.getElementById("airxtouch-proxy");
  proxy.setAttribute("data-layout", JSON.stringify(layout));
  window.airxtouch__invoke("ConfigureLayoutSettings", layout);
};

window.airxtouch__initializeProxy = () => {
  let div = document.createElement("div");
  div.id = "airxtouch-proxy";
  div.style.display = "none";
  document.body.appendChild(div);
};
