<template>
  <div class="invoiceCartTotal">
    <div class="invoice__content">
      <div class="icon-holder">
        <svgicon class="icon" src="icons/invoice.svg" :height="48" :width="38"></svgicon>
      </div>
      <div class="details invoice__details" :class="{'details--no-padding': noPadding}" v-if="total">
        <div class="detail" v-for="(promotion, promotionIndex) of (promotions || [])" :key="'promo' + promotionIndex">
          <label class="detail__label">{{ promotion.name }}</label>
          <div class="detail__value">{{ $tc(promotion.total) }}</div>
        </div>
        <div class="detail">
          <label class="detail__label">{{$t('total.subtotal')}}</label>
          <div class="detail__value">{{$tc(total.subTotal)}}</div>
        </div>
        <div class="detail" v-for="tax of Object.keys(total.taxes)" :key="'detail-tax-' + tax">
          <label class="detail__label">{{ tax }}</label>
          <div class="detail__value">{{$tc(total.taxes[tax])}}</div>
        </div>
        <div class="detail" v-if="total.cashRounding">
          <div class="detail__label">{{ $t("total.cash_rounding") }}</div>
          <div class="detail__value">{{ $tc(total.cashRounding) }}</div>
        </div>
<!--        <div class="detail" v-if="total.serviceFees">-->
<!--          <label class="detail__label">{{$t('total.service_fees')}}</label>-->
<!--          <div class="detail__value">{{$tc(total.serviceFees)}}</div>-->
<!--        </div>-->
        <div class="detail total">
          <label class="detail__label">{{$t('total.total')}}</label>
          <div class="detail__value">{{$tc(total.total)}}</div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "InvoiceCartTotal",

    props: {
      promotions: Array,
      total: Object,
      noPadding: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  .invoiceCartTotal {
    background-color: var(--background-color-2);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;

    .invoice__content {
      display: flex;
    }

    .icon-holder {
      border-radius: 10px;
      height: 100px;
      background-color: var(--background-color-6);
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #dbdbdb;
      width: 100px;
      margin-right: 20px;
      flex-shrink:  0;
    }

    .details {
      flex-grow: 1;
      padding-right: 75px;

      &.details--no-padding {
        padding-right: 20px;
      }

      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }

        &.total {
          .detail__label, .detail__value {
            color: var(--theme-color);
            font-weight: 900;
            font-size: 26px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--border-color-4);
        }

        .detail__label {
          color: var(--theme-color-2);
          font-size: 16px;
          font-weight: 500;
        }

        .detail__value {
          font-weight: 900;
          color: var(--theme-color);
          font-size: 20px;
        }
      }
    }
  }
</style>