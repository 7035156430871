<template>
  <div id="dispatch">

    <PageTitle>
      <svgicon class="icon" src="icons/business.svg" :height="20" :width="20"></svgicon>
      {{branch ? branch.name : ''}}
    </PageTitle>

    <div class="page-content">

      <Panel :title="$t('dispatch.title')" icon="icons/dispatch.svg">

        <div class="section order-method">
          <svgicon src="icons/comptoir.svg" :height="20" :width="20"></svgicon>
          <div class="content">
            <div class="title">{{ $t("order.choose_order_type") }} :</div>
            <OrderMethod no-image></OrderMethod>
          </div>
        </div>

        <div class="section order-location">
          <svgicon src="icons/profile.svg" :height="20" :width="20"></svgicon>
          <div class="content">
            <div class="title">{{ $t("dispatch.customer_information") }} :</div>
            <div class="columns">
              <div class="column">
                <div class="input">
                  <label>{{$t('payment.phone')}}</label>
                  <div class="group">
                    <input type="text" placeholder="999-999-9999" v-model="$user.phoneNumber" :class="{invalid: $validate.error('$user.phoneNumber')}"
                           @keyup.enter="searchCustomer">
                    <button class="btn btn-primary" @click="searchCustomer">{{$t('dispatch.search_customer')}}</button>
                  </div>
                  <template v-if="searched && !newCustomer">
                    <div class="result" :class="{found: $user.onBehalfOf}">
                      <svgicon :src="'icons/' + ($user.onBehalfOf ? 'success.svg' : 'error-circle.svg')" :height="20" :width="20"></svgicon>
                      {{$t('dispatch.' + ($user.onBehalfOf ? 'customer_found' : 'customer_not_found'))}}
                    </div>
                  </template>
                </div>
              </div>
              <div class="column"></div>
            </div>
            <div class="columns">
              <div class="column">
                <template v-if="searched">
                  <div class="input">
                    <label>{{$t('payment.first_name')}}</label>
                    <input type="text" :placeholder="$t('payment.first_name')" v-model="$user.firstName" :class="{invalid: $validate.error('$user.firstName')}">
                  </div>
                  <div class="input">
                    <label>{{$t('payment.last_name')}}</label>
                    <input type="text" :placeholder="$t('payment.last_name')" v-model="$user.lastName" :class="{invalid: $validate.error('$user.lastName')}">
                  </div>
                  <div class="input location" v-if="$order.method == 'delivery'">
                    <UserLocation :prefillAddress="shouldPrefillAddress" @changed="checkCompanyList" type="order-type"></UserLocation>
                  </div>
                </template>
              </div>
              <div class="column">
                <template v-if="searched">
                  <div class="input">
                    <label>{{$t('payment.email')}}</label>
                    <input type="text" :placeholder="$t('payment.email')" v-model="$user.email">
                  </div>
                  <div class="input">
                    <label>{{$t('dispatch.user_remark')}}</label>
                    <input type="text" :placeholder="$t('dispatch.user_remark')" v-model="$user.remark">
                  </div>
                </template>
              </div>
            </div>
            <div class="columns" v-if="searched && !$user.getDefaultAddress().isNew() && $order.method == 'delivery'">
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <div class="input apt">
                      <label>{{$t('payment.apt')}}</label>
                      <input type="text" :placeholder="$t('payment.apt')" v-model="$user.getDefaultAddress().app">
                    </div>
                  </div>
                  <div class="column">
                    <div class="input">
                      <label>{{$t('payment.delivery_note')}}</label>
                      <input type="text" :placeholder="$t('payment.delivery_note')" v-model="$user.getDefaultAddress().note">
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                &nbsp;
              </div>
            </div>
          </div>
        </div>

        <div class="section order-company" v-if="searched && $order.method && userHasAddressInfo">
          <svgicon src="icons/location.svg" :height="20" :width="20"></svgicon>
          <div class="content">
            <div class="title">{{ $t("dispatch.choose_company") }} :</div>
            <OrderCompaniesList ref="companiesList" :current-company="currentCompany" :companies="companies" @selected="setCompany($event)"></OrderCompaniesList>
            <a class="changeLink" @click="chooseManually">
              {{ $t("dispatch.choose_manually") }}
            </a>
          </div>
        </div>

        <div class="section order-datetime" v-if="searched && $order.method && currentCompany && inAdvanceEnabled && userHasAddressInfo">
          <svgicon src="icons/location.svg" :height="20" :width="20"></svgicon>
          <div class="content">
            <div class="title" v-if="$order.method == 'catering'">{{$t('order_options.select_datetime')}} :</div>
            <div class="title" v-if="$order.method == 'delivery'">{{$t('order_options.schedule_delivery')}} :</div>
            <div class="title" v-if="$order.method != 'catering' && $order.method != 'delivery' && inAdvanceEnabled && hasOpenHours">{{$t('order_options.select_hour')}} :</div>
            <div class="title" v-if="$order.method != 'catering' && $order.method != 'delivery' && (!inAdvanceEnabled || !hasOpenHours) && averageDelay > 0">{{$t('order_options.no_select_hour')}} :</div>
            <div class="columns">
              <div class="column">
                <OrderDateTime :inAdvanceEnabled="inAdvanceEnabled" :hasOpenHours="hasOpenHours" :averageDelay="averageDelay"></OrderDateTime>
              </div>
              <div class="column"></div>
            </div>
          </div>
        </div>

        <div class="section button" v-if="searched && $order.method && currentCompany && userHasAddressInfo">
          <button class="btn btn-primary" @click="confirm">{{$t('confirm')}}</button>
        </div>
      </Panel>

    </div>

    <DispatchCustomerModal v-if="customers.length > 0" :customers="customers"
                           @new="selectCustomer()"
                           @selected="selectCustomer($event)"
                           @back="cancelSearch()"></DispatchCustomerModal>

  </div>
</template>

<script>
  import DispatchCustomerModal from "@/components/DispatchCustomerModal.vue";
  import OrderCompaniesList from "@/components/orderOptions/OrderCompaniesList.vue";
  import OrderDateTime from "@/components/orderOptions/OrderDateTime.vue";
  import OrderMethod from "@/components/orderOptions/OrderMethod.vue";
  import UserLocation from "@/components/orderOptions/UserLocation.vue";
  import { mapActions } from "vuex";

  export default {

    name: "dispatch",

    components: { OrderMethod, UserLocation, OrderCompaniesList, OrderDateTime, DispatchCustomerModal },

    mounted() {
      if (!this.$user.token) {
        this.$router.push(this.$router.generate("/login?redirect=dispatch"));
        return;
      }
      if (this.branch && !this.branch.dispatch) {
        this.$router.push(this.$router.generate(this.company ? "/menu" : "/orderType"));
        return;
      }
      if (this.$user.onBehalfOf) {
        this.searched = true;
        this.currentCompany = this.company;
      }
      this.$store.commit("showNavigationCart", false);
    },

    data() {
      return {
        searched: false,
        customer: null,
        newCustomer: false,
        currentCompany: null,
        showAllCompanies: false,
        companies: [],
        customers: []
      };
    },

    validate: {
      "$user.phoneNumber": {
        required: true,
        phone: true
      },
      "$user.firstName": {
        required: true
      },
      "$user.lastName": {
        required: true
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      user() {
        return this.$user;
      },
      formattedPhoneNumber() {
        return this.$user.phoneNumber.replace(/\D/g, "");
      },
      userHasAddressInfo() {
        if (this.$order.method !== "delivery") {
          return true;
        }
        return !this.$user.getDefaultAddress().isNew();
      },
      inAdvanceEnabled() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return false;
        }
        return this.company.getOpenHours(this.orderMethod).inAdvance || this.orderMethod === "catering";
      },
      averageDelay() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return 0;
        }
        return this.company.getOpenHours(this.orderMethod).averageDelay;
      },
      hasOpenHours() {
        return this.company && this.company.getAvailableOpenHours(this.orderMethod).filter(o => o !== "delay").length > 0;
      },
      shouldPrefillAddress() {
        return !!this.searched && !!this.$user.onBehalfOf;
      }
    },

    watch: {
      "$store.state.currentCompany"(newValue, oldValue) {
        if (oldValue) {
          this.currentCompany = newValue;
        }
      }
    },

    methods: {
      ...mapActions({
        findDispatchCustomerByPhoneNumber: "findDispatchCustomerByPhoneNumber",
        createDispatchCustomer: "createDispatchCustomer",
        updateDispatchCustomer: "updateDispatchCustomer",
        setCurrentCompany: "setCurrentCompany"
      }),

      setCompany(company, force) {
        if (this.currentCompany === company && !force) {
          this.currentCompany = null;
        } else {
          this.currentCompany = company;
          this.setCurrentCompany(company);
        }
      },

      async getCompanies() {
        if (!this.branch) {
          this.companies = [];
          return;
        }
        if (this.showAllCompanies) {
          this.companies = this.branch.getCompaniesForMethod(this.$order.method);
          return;
        }
        let companies = this.branch.getCompaniesForMethod(this.$order.method, this.$user.getDefaultAddress());
        companies = await this.$order.filterCompaniesForDoorDashDelivery(companies);
        this.companies = companies;
      },

      async chooseManually() {
        this.currentCompany = null;
        this.showAllCompanies = true;
        showSpinner();
        await this.getCompanies();
        hideSpinner();
      },

      async checkCompanyList() {
        this.showAllCompanies = false;
        showSpinner();
        await this.getCompanies();
        hideSpinner();
        this.$forceUpdate();
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.companies.length === 1) {
              this.setCompany(this.companies[0], true);
            } else if (this.companies.length === 0) {
              this.setCompany(null);
            }
          }, 0);
        });
      },

      async searchCustomer() {
        let phone = this.formattedPhoneNumber;
        if (!phone || phone.length < 7) {
          return;
        }
        showSpinner();
        this.searched = false;
        this.$user.clearOnBehalfOf();
        this.$user.phoneNumber = phone;
        this.newCustomer = false;
        let response = await this.findDispatchCustomerByPhoneNumber(phone);
        hideSpinner();
        if (response) {
          this.customers = response;
        } else {
          this.searched = true;
        }
      },
      async confirm() {
        if (!this.$validate.valid()) {
          return;
        }
        this.$user.phoneNumber = this.formattedPhoneNumber;
        showSpinner();
        let response = this.$user.onBehalfOf
          ? await this.updateDispatchCustomer({ id: this.$user.onBehalfOf, data: this.$user.toDto() })
          : await this.createDispatchCustomer(this.$user.toDto());
        hideSpinner();
        if (response) {
          this.$user.loadOnBehalfOf(response);
          this.$router.push(this.$router.generate("/menu"));
        }
      },
      cancelSearch() {
        this.$user.phoneNumber = null;
        this.customers = [];
      },
      selectCustomer(customer) {
        this.searched = true;
        this.customers = [];
        if (customer) {
          this.newCustomer = false;
          this.$user.loadOnBehalfOf(customer);
          this.checkCompanyList();
        } else {
          this.newCustomer = true;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>

  #dispatch .panel {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }

  .section {
    display: flex;

    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #e6e6e6;
    }

    &.button {
      display: flex;
      justify-content: flex-end;
    }

    .svgicon {
      fill: #bfbfbf;
      margin-right: 20px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 15px;
      color: #808080;
    }
  }

  .group {
    display: flex;

    input {
      flex-grow: 1;
    }

    .btn {
      margin-left: 10px;
    }
  }

  .content {
    width: 100%;
  }

  .columns {
    display: flex;
    width: 100%;

    .column {
      flex-grow: 1;
      flex-basis: 0;
    }

    .column:not(:last-child) {
      margin-right: 15px;
    }
  }

  .result {
    margin: 10px 0;
    font-size :13px;
    color: #ff2e51;
    display: flex;
    align-items: center;

    .svgicon {
      fill: #ff2e51;
      flex-shrink: 0;
      margin-right: 10px;
    }

    &.found {
      color: #2ab152;

      .svgicon {
        fill: #2ab152;
      }
    }
  }

  #iShopFoodApp .changeLink {
    display: flex;
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
    color: var(--primary-color) !important;
  }

  .input.location {
    padding-bottom: 0;
    max-width: 800px;
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp .page-content {
      padding: 0;

      &:after {
        content: none;
      }
    }
  }
</style>

<style lang="scss">
  #dispatch .panel > .content {
    overflow: visible;
  }
  #dispatch .vdatetime-input.select {
    width: 100%;
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp #dispatch {
      .types {
        flex-wrap: wrap;
        padding: 15px 5px;
        margin: -16px;
        .type {
          min-height: 55px;
          margin: 6px;
        }
      }
      .columns {
        flex-direction: column;
        .column {
          width: 100%;
          margin: 0;
        }
      }
      .input {
        width: 100%;
      }
      .group {
        flex-direction: column;
        width: 100%;
        .btn, input {
          width: 100%;
        }
        .btn {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
</style>
