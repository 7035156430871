<template>
  <div id="dispatch" @click="hideResults">

    <Panel :title="$t('dispatch.customer_information')" icon="icons/user.svg" v-if="!editing">

      <!-- Search input -->
      <div class="flex">
        <div class="input-container">
          <PosInput type="text" :placeholder="$t('search')" v-model="search" @input="submitSearch" closeKeyboardOnInactivity></PosInput>
          <div class="dispatch__noResult" v-if="noResult">
            <div class="grow">{{ $t("dispatch.no_result_found") }}</div>
            <button v-wave @click="createNewCustomer" :class="{disabled: isOffline}">{{ $t("dispatch.new_customer") }}</button>
          </div>
          <div class="dispatch__results" v-if="showResults">
            <div class="results__result" v-for="user of foundUsers" :key="`result-${user.id}`" v-wave @click="selectCustomer(user)">
              <div class="grow">{{ user.firstName }} {{ user.lastName }}, {{ formatPhoneNumber(user.contact.phoneNumber) }}</div>
              <button>{{ $t("select") }}</button>
            </div>
            <div class="results__result">
              <div class="grow"></div>
              <button v-wave @click="createNewCustomer" :class="{disabled: isOffline}">{{ $t("dispatch.new_customer") }}</button>
            </div>
          </div>
        </div>
        <button @click="submitSearch" v-wave :class="{disabled: isOffline}">{{ $t("search") }}</button>
      </div>

      <div class="dispatch__separator"></div>

      <!-- Base view -->
      <div class="dispatch__information" v-if="mode === 'none'">
        <Illustration class="modal__illustration" src="icons/silence.svg"></Illustration>
        <div class="information__text">{{ $t("dispatch.search_for_existing_1") }}<br />{{
        $t("dispatch.search_for_existing_2") }}</div>
      </div>

      <!-- New customer form -->
      <template v-if="mode === 'new'">

        <!-- Contact info -->
        <div class="dispatch__section border">
          <div class="section__left">
            <div class="section__icon">
              <svgicon src="icons/contacts.svg" :width="40" :height="40"></svgicon>
            </div>
          </div>
          <div class="section__right">
            <div class="section__title">{{ $t("dispatch.enter_contact_info") }}</div>
            <div class="flex margin">
              <div class="input-group grow">
                <label>{{ $t("register.firstName") }}</label>
                <PosInput type="text" size="6" v-model="firstName"></PosInput>
              </div>
              <div class="input-group grow">
                <label>{{ $t("register.lastName") }}</label>
                <PosInput type="text" size="6" v-model="lastName"></PosInput>
              </div>
              <div class="input-group grow">
                <label>{{ $t("register.phoneNumber") }}</label>
                <PosInput type="text" size="6" v-model="phoneNumber"></PosInput>
              </div>
            </div>
            <div class="flex">
              <div class="input-group grow">
                <label>{{ $t("dispatch.user_remark_short") }}</label>
<!--                <input type="text" v-model="remark">-->
                <PosInput type="text" v-model="remark"></PosInput>
              </div>
            </div>
          </div>
        </div>

        <!-- Address -->
        <div class="dispatch__section">
          <div class="section__left">
            <div class="section__icon">
              <svgicon src="icons/address-pos.svg" :width="40" :height="40"></svgicon>
            </div>
          </div>
          <div class="section__right">
            <UserLocation type="order-type" @changed="userLocationChanged"
                          :dispatch="true" :no-button="!$user.getDefaultAddress().isNew()"></UserLocation>
          </div>
        </div>

        <div class="grow"></div>

        <div class="dispatch__bottom">
          <button class="primary" v-wave @click="createCustomer" :class="{disabled: isOffline}">
            <svgicon src="icons/save.svg"></svgicon>
            {{ $t("dispatch.save_customer") }}
          </button>
        </div>

      </template>

      <!-- Existing customer -->
      <template v-if="mode === 'show'">

        <!-- Contact info -->
        <div class="dispatch__section border noshrink">
          <div class="section__left">
            <div class="section__icon">
              <svgicon src="icons/contacts.svg" :width="40" :height="40"></svgicon>
            </div>
          </div>
          <div class="section__right">
            <div class="section__title">{{ $t("payment.contact_information") }}</div>
            <div class="flex margin">
              <div class="input-group grow">
                <label>{{ $t("register.firstName") }}</label>
                <div class="text">{{ $user.firstName }}</div>
              </div>
              <div class="input-group grow">
                <label>{{ $t("register.lastName") }}</label>
                <div class="text">{{ $user.lastName }}</div>
              </div>
              <div class="input-group grow">
                <label>{{ $t("register.phoneNumber") }}</label>
                <div class="text">{{ formatPhoneNumber($user.phoneNumber) }}</div>
              </div>
            </div>
            <div class="flex" v-if="$user.remark">
              <div class="input-group">
                <label>{{ $t("dispatch.user_remark_short") }}</label>
                <div class="text">{{ $user.remark }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Address list -->
        <div class="dispatch__section grow">
          <div class="section__left">
            <div class="section__icon">
              <svgicon src="icons/address-pos.svg" :width="40" :height="40"></svgicon>
            </div>
          </div>
          <div class="section__right flex" :class="{ disabled: method === 'takeout' }">
            <UserLocation @changed="userLocationChanged" type="order-type"
                          :dispatch="true" :no-button="!$user.getDefaultAddress().isNew()"></UserLocation>
          </div>
        </div>

        <div class="dispatch__bottom">
          <button v-wave @click="goToEdit">
            <svgicon src="icons/pencil-full.svg" :width="24" :height="24"></svgicon>
            {{ $t("dispatch.modify_customer") }}
          </button>
        </div>

      </template>

    </Panel>

    <!-- Right panel -->
    <Panel :title="$t('payment.order_information')" icon="icons/invoice.svg" v-if="!editing">

      <!-- Order type -->
      <div class="dispatch__section border">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/orderType.svg" :width="40" :height="40"></svgicon>
          </div>
        </div>
        <div class="section__right">
          <div class="section__title">{{ $t("payment.order_type") }}</div>
          <div class="section__order-types">
            <div class="order-types__type" v-wave @click="setMethod('takeout')"
              :class="{selected: method === 'takeout'}">
              <div class="type__icon">
                <svgicon src="icons/takeout-order.svg" :width="64" :height="64"></svgicon>
              </div>
              <div class="type__name">{{ $t("method.takeout_short") }}</div>
              <div class="type__check" v-if="method === 'takeout'">
                <svgicon src="icons/check-circle-full.svg" :width="24" :height="24"></svgicon>
              </div>
            </div>
            <div class="order-types__type" v-wave @click="setMethod('delivery')"
              :class="{selected: method === 'delivery'}">
              <div class="type__icon">
                <svgicon src="icons/delivery-order.svg" :width="64" :height="64"></svgicon>
              </div>
              <div class="type__name">{{ $t("method.delivery_short") }}</div>
              <div class="type__check" v-if="method === 'delivery'">
                <svgicon src="icons/check-circle-full.svg" :width="24" :height="24"></svgicon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Reception time -->
      <div class="dispatch__section" v-if="table && table.method" :class="{border: table.method === 'delivery' && $user.onBehalfOf && !$user.getDefaultAddress().isNew()}">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/timer.svg" :width="40" :height="40"></svgicon>
          </div>
        </div>
        <div class="section__right">
          <div class="section__title">{{ $t("order.reception_" + table.method) }}</div>
          <div class="flex margin grow">
            <OrderDateTime :inAdvanceEnabled="inAdvanceEnabled" :hasOpenHours="hasOpenHours" :averageDelay="averageDelay"></OrderDateTime>
          </div>
          <div class="flex">
            <div class="input-group grow">
              <label>{{ $t("dispatch.order_note") }}</label>
              <textarea type="text"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="dispatch__section" v-if="table && table.method === 'delivery' && $user.onBehalfOf && !$user.getDefaultAddress().isNew()">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/check-circle-full.svg" :width="40" :height="40" class="success" v-if="inDeliveryZone"></svgicon>
            <svgicon src="icons/tip-danger.svg" :width="40" :height="40" class="warning" v-if="!inDeliveryZone"></svgicon>
          </div>
        </div>
        <div class="section__right">
          <div class="section__title">{{ $t("dispatch.address_status") }}</div>
          <div class="text">{{ $t(inDeliveryZone ? "dispatch.in_delivery_zone" : "dispatch.not_delivery_zone") }}</div>
        </div>
      </div>

      <!-- Branch -->
      <!--<div class="dispatch__section" v-if="currentCompany">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/branch-pos.svg" :width="40" :height="40"></svgicon>
          </div>
        </div>
        <div class="section__right">
          <div class="section__title">{{ $t("payment.branch") }}</div>
          <div class="section__branch-name">{{ currentCompany.name }}</div>
          <div class="section__branch-info">{{ formatAddress(currentCompany) }}</div>
          <button v-wave @click="switchBranch" :class="{disabled: isOffline}">
            <svgicon src="icons/switch.svg" :width="24" :height="24"></svgicon>
            {{ $t("dispatch.switch_branch") }}
          </button>
        </div>
      </div>-->

      <div class="grow"></div>

      <div class="dispatch__bottom">
        <button class="primary" v-wave :class="{disabled: !$user.getFullName() || !method || isOffline || isClosed}" @click="startOrder">
          <svgicon src="icons/new-order-pos.svg"></svgicon>
          {{ $t(isClosed ? "dispatch.closed" : (embed ? "dispatch.go_to_order" : "dispatch.start_order")) }}
        </button>
      </div>

    </Panel>

    <!-- Customer edit form -->
    <Panel title="$t('dispatch.customer_information')" icon="icons/invoice.svg" v-if="editing">

      <!-- Contact info -->
      <div class="dispatch__section border noshrink">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/contacts.svg" :width="40" :height="40"></svgicon>
          </div>
        </div>
        <div class="section__right">
          <div class="section__title">{{ $t("payment.contact_information") }}</div>
          <div class="flex">
            <div class="input-group">
              <label>{{ $t("register.firstName") }}</label>
              <input type="text" v-model="firstName">
            </div>
            <div class="input-group">
              <label>{{ $t("register.lastName") }}</label>
              <input type="text" v-model="lastName">
            </div>
            <div class="input-group">
              <label>{{ $t("register.phoneNumber") }}</label>
              <input type="text" v-model="phoneNumber">
            </div>
            <div class="input-group grow">
              <label>{{ $t("dispatch.user_remark_short") }}</label>
              <input type="text" v-model="remark">
            </div>
          </div>
        </div>
      </div>

      <!-- Address -->
      <div class="dispatch__section">
        <div class="section__left">
          <div class="section__icon">
            <svgicon src="icons/address-pos.svg" :width="40" :height="40"></svgicon>
          </div>
        </div>
        <div class="section__right flex">
          <div class="section__address-panels">
            <UserLocation @changed="userLocationChanged" :allow-delete="true" type="order-type"
                          :always-show-all="true" :dispatch="true" :no-button="!$user.getDefaultAddress().isNew()"></UserLocation>
          </div>
        </div>
      </div>

      <div class="grow"></div>

      <div class="dispatch__bottom">
        <button v-wave @click="cancelEdit">
          <svgicon src="icons/back-full.svg"></svgicon>
          {{ $t("back") }}
        </button>
        <div class="grow"></div>
        <button v-wave class="margin" @click="updateCustomer" :class="{disabled: isOffline}">
          <svgicon src="icons/save.svg"></svgicon>
          {{ $t("dispatch.save_changes") }}
        </button>
        <button class="primary" v-wave @click="updateCustomer(true)" :class="{disabled: isOffline}">
          <svgicon src="icons/save.svg"></svgicon>
          {{ $t("dispatch.save_close") }}
        </button>
      </div>

    </Panel>

    <!-- Branch switch modal -->
    <Modal ref="modal" v-if="currentCompany">

      <ModalHeader title="Switch branch" icon="icons/branch.svg" @close="hideModal"></ModalHeader>

      <div class="input-group">
        <label>{{ $t("dispatch.active_branch") }}</label>
      </div>
      <div class="modal__company selected">
        <div class="company__left">
          <svgicon src="icons/circle-empty.svg" :width="24" :height="24" v-if="false"></svgicon>
          <svgicon src="icons/check-circle-full.svg" :width="24" :height="24" v-if="true"></svgicon>
        </div>
        <div class="company__right">
          <div class="company__name">{{ currentCompany.name }} <span v-if="currentCompany.distanceFromUser">({{ formatDistance(currentCompany.distanceFromUser) }})</span></div>
          <div class="company__address">{{ formatAddress(currentCompany) }}</div>
        </div>
      </div>

      <div class="input-group">
        <label>{{ $t("dispatch.select_another_branch") }}</label>
      </div>
      <div class="modal__company" v-for="company of otherCompanies" :key="`company-${company.id}`" @click="setCompany(company)">
        <div class="company__left">
          <svgicon src="icons/circle-empty.svg" :width="24" :height="24" v-if="false"></svgicon>
          <svgicon src="icons/check-circle-full.svg" :width="24" :height="24" v-if="true"></svgicon>
        </div>
        <div class="company__right" :class="{disabled: method === 'delivery' && !company.isInDeliveryZone($user.getDefaultAddress())}">
          <div class="company__name">{{ company.name }} <span v-if="company.distanceFromUser">({{ formatDistance(company.distanceFromUser) }})</span></div>
          <div class="company__address">{{ formatAddress(company) }}</div>
        </div>
      </div>

      <ModalFooter>
        <Button v-wave @click="hideModal">
          <svgicon src="icons/back-full.svg" :width="24" :height="24"></svgicon>
          {{ $t("back") }}
        </Button>
        <div class="grow"></div>
        <Button class="primary" v-wave @click="hideModal">
          <svgicon src="icons/check-thick.svg" :width="24" :height="24"></svgicon>
          {{ $t("confirm") }}
        </Button>
      </ModalFooter>

    </Modal>

  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import Address from "@/models/AddressModel";
  import Illustration from "@/components/modal/Illustration.vue";
  import Modal from "@/components/modal/Modal.vue";
  import ModalFooter from "@/components/modal/ModalFooter.vue";
  import ModalHeader from "@/components/modal/ModalHeader.vue";
  import OrderDateTime from "@/components/orderOptions/OrderDateTime.vue";
  import PosInput from "@/components/pos/PosInput";
  import { Format } from "@/util/Format";
  import { Validation } from "@/util/Validation";
  import UserLocation from "../components/orderOptions/UserLocation.vue";
  import { defineComponent } from "@vue/runtime-core";
  import { mapActions } from "vuex";

  export default defineComponent({

    name: "Dispatch",

    components: { Illustration, Modal, ModalHeader, ModalFooter, UserLocation, OrderDateTime, PosInput },

    data() {
      return {
        noResult: false,
        showResults: false,
        mode: this.$user.getFullName() ? "show" : "none",
        editing: false,
        inDeliveryZone: true,
        search: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        remark: "",
        foundUsers: /** @type {User[]} */ ([])
      };
    },

    props: {
      embed: Boolean
    },

    computed: {
      currentBranch() {
        return this.$store.state.currentBranch;
      },
      currentCompany() {
        return this.$store.state.currentCompany;
      },
      method() {
        if (!this.table) {
          return;
        }
        return this.table.method;
      },
      table() {
        return this.$store.state.table;
      },
      otherCompanies() {
        return this.currentBranch.companies
          .filter(company => company.id !== this.currentCompany.id)
          .sort((c1, c2) => {
            return (c1.distanceFromUser || 1000000) - (c2.distanceFromUser || 1000000);
          });
      },
      inAdvanceEnabled() {
        if (!this.currentCompany || !this.currentCompany.getOpenHours(this.method)) {
          return false;
        }
        return this.currentCompany.getOpenHours(this.method).inAdvance || this.method === "catering";
      },
      averageDelay() {
        if (!this.currentCompany || !this.currentCompany.getOpenHours(this.method)) {
          return 0;
        }
        return this.currentCompany.getOpenHours(this.method).averageDelay;
      },
      hasOpenHours() {
        return this.currentCompany && this.currentCompany.getAvailableOpenHours(this.method).filter(o => o !== "delay").length > 0;
      },
      isOffline() {
        return this.$store.state.offline;
      },
      isClosed() {
        return this.currentCompany.isCurrentMethodClosed(this.method);
      }
    },

    mounted() {
      if (!this.$store.state.table || !this.$store.state.employee) {
        this.$router.push(this.$router.generateFirstPage());
      }
      if (this.embed && this.validateUserAddressForDelivery(true)) {
        this.userLocationChanged(true);
      }
    },

    methods: {
      ...mapActions({
        findDispatchCustomer: "findDispatchCustomer",
        setCurrentCompany: "setCurrentCompany",
        createDispatchCustomer: "createDispatchCustomer",
        updateDispatchCustomer: "updateDispatchCustomer"
      }),

      async submitSearch() {
        if (!this.search) {
          toast({ message: this.$t("dispatch.search_empty"), type: "warning", title: this.$t("search") });
          return;
        }
        let response = await this.findDispatchCustomer(this.search);
        if (response.length === 0) {
          this.noResult = true;
        } else {
          this.foundUsers = response;
          this.showResults = true;
        }
      },

      goToEdit() {
        this.firstName = this.$user.firstName;
        this.lastName = this.$user.lastName;
        this.phoneNumber = this.$user.phoneNumber;
        this.remark = this.$user.remark;
        this.editing = true;
      },

      cancelEdit() {
        this.firstName = "";
        this.lastName = "";
        this.phoneNumber = "";
        this.remark = "";
        this.editing = false;
      },

      createNewCustomer() {
        this.noResult = false;
        this.showResults = false;
        this.mode = "new";
        this.$user.reset();
      },

      hideResults() {
        this.noResult = false;
        this.showResults = false;
      },

      validateCustomerInformation(skipWarning) {
        if (!this.firstName || !this.lastName || !this.phoneNumber) {
          if (!skipWarning) {
            toast({
              message: this.$t("dispatch.invalid_edit_customer"),
              title: this.$t("warning"),
              type: "warning"
            });
          }
          return false;
        }
        if (!Validation.validatePhoneNumber(this.phoneNumber)) {
          if (!skipWarning) {
            toast({
              message: this.$t("dispatch.invalid_phone_number"),
              title: this.$t("warning"),
              type: "warning"
            });
          }
          return false;
        }
        return true;
      },

      validateUserAddressForDelivery(skipWarning) {
        console.trace("Validating address", this.table.method);
        if (this.$user.getDefaultAddress().isNew() && this.table.method === "delivery") {
          if (!skipWarning) {
            toast({
              message: this.$t("dispatch.delivery_address_required"),
              title: this.$t("warning"),
              type: "warning"
            });
          }
          return false;
        }
        return true;
      },

      async updateCustomer(endEdit = false) {
        if (!this.validateCustomerInformation()) {
          return;
        }
        this.$user.firstName = this.firstName;
        this.$user.lastName = this.lastName;
        this.$user.phoneNumber = this.phoneNumber;
        this.$user.remark = this.remark;
        await this.updateDispatchCustomer({ id: this.$user.onBehalfOf || this.$user.id, data: this.$user.toDto() });
        if (endEdit) {
          this.editing = false;
        }
      },

      async userLocationChanged(skipWarning) {
        /*this.inDeliveryZone = (this.currentCompany.isDoorDashActivated()
          ? (await this.currentCompany.isInDeliveryZoneWithDoorDash(this.$user.getDefaultAddress(), this.table.method))
          : this.currentCompany.isInDeliveryZone(this.$user.getDefaultAddress()))*/ //TODO doordash support
        if (this.table.method !== "delivery") {
          return;
        }
        this.inDeliveryZone = this.currentCompany.isInDeliveryZone(this.$user.getDefaultAddress());
        if (!this.inDeliveryZone && !skipWarning) {
          showAffirmation(this.$t("warning"),
                          this.$t("dispatch.not_in_delivery_zone").replace("{ADDRESS}", this.$user.getDefaultAddress().getFormattedAddressWithApt()),
                          this.$t("dispatch.i_understand"), "warning", false, "center", true);
        }
      },

      selectCustomer(customer) {
        this.noResult = false;
        this.showResults = false;
        this.mode = "show";
        this.$user.loadOnBehalfOf(customer);
        this.userLocationChanged();
      },

      async createCustomer() {
        if (!this.validateCustomerInformation()) {
          return;
        }
        this.$user.firstName = this.firstName;
        this.$user.lastName = this.lastName;
        this.$user.phoneNumber = this.phoneNumber;
        this.$user.remark = this.remark;

        await this.createDispatchCustomer(this.$user.toDto());
        this.mode = "show";
      },

      switchBranch() {
        this.$refs.modal.show();
      },

      hideModal() {
        this.$refs.modal.hide();
      },

      setMethod(method) {
        this.table.setMethod(method);
      },

      setCompany(company) {
        this.setCurrentCompany(company);
        this.hideModal();
      },

      isValidDispatchConfig() {
        if (!this.validateCustomerInformation(true)) {
          return false;
        }
        if (!this.validateUserAddressForDelivery(true)) {
          return false;
        }
        return true;
      },

      startOrder() {
        if (!this.validateUserAddressForDelivery()) {
          return;
        }
        this.table.loadPromotions();
        if (this.embed) {
          EventBus.$emit("hide-dispatch-modal");
        } else {
          this.$router.push(this.$router.generate("/menu"));
        }
      },

      formatAddress(company) {
        return `${company.address.address}, ${company.address.city} (${company.address.state}) ${company.address.zipCode}`;
      },

      formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) {
          return "";
        }
        return Format.formatPhoneNumber(phoneNumber);
      },

      formatDistance(distance) {
        return distance.toFixed(2) + " km";
      }

    }

  });
</script>

<style lang="scss" scoped>
#iShopFoodApp #dispatch {
  padding: 30px;
  display: flex;
  flex-direction: row;

  .panel:first-child:not(:last-child) {
    margin-right: 20px;
  }
}

.grow {
  flex-grow: 1;
  flex-basis: 0;
}

.noshrink {
  flex-shrink: 0;
}

.disabled {
  opacity: 0.3;
  pointer-events: none !important;
}

input {
  padding: 16px 16px !important;
  border-radius: 12px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  border: 1px solid #d9d9d9;

  &:focus {
    border-color: var(--primary-color) !important;
  }
}

#iShopFoodApp textarea {
  border-radius: 12px !important;
  padding: 16px 10px !important;
  font-family: Roboto !important;
  background-color: transparent;
  color: var(--theme-color);
  border-color: var(--border-color-5);
}

button {
  height: 56px;
  background-color: var(--background-color-3);
  border: none;
  border-radius: 12px;
  padding: 0 20px;
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  fill: var(--primary-color);

  .svgicon {
    margin-right: 16px;
  }

  &.primary {
    background-color: var(--primary-color);
    color: white;
    fill: white;
  }

  &.margin {
    margin-right: 10px;
  }
}

.flex {
  display: flex;

  &.margin {
    margin-bottom: 20px;
  }

  &.grow>* {
    flex-grow: 1;
  }

  &>*:not(:last-child) {
    margin-right: 10px;
  }

  .input-container {
    flex-grow: 1;
    position: relative;

    input {
      width: 100%;
    }
  }
}

.dispatch__separator {
  border-bottom: 1px dashed var(--background-color-4);
  margin: 20px 0;
}

.dispatch__noResult,
.dispatch__results {
  background-color: var(--background-color-5);
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  top: calc(100% + 5px);
  z-index: 1;
  max-height: 800px;
  overflow-y: auto;
}

.dispatch__noResult {
  padding: 16px;
  display: flex;
  align-items: center;

  &:not(button) {
    color: #BFBFBF;
    font-weight: 500;
    font-size: 20px;
  }
}

.dispatch__results {
  .results__result {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 20px;
    position: relative;

    &:not(:last-child)::after {
      content: "";
      left: 16px;
      right: 16px;
      bottom: 0px;
      height: 1px;
      background-color: var(--background-color-4);
      position: absolute;
    }
  }
}

.dispatch__section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  &.grow {
    flex-grow: 1;
  }

  &.border {
    border-bottom: 1px dashed var(--background-color-4);
  }

  .section__right {
    flex-grow: 1;
    margin-left: 20px;

    &.flex {
      display: flex;
      flex-direction: column;
    }

    .section__title {
      font-weight: bold;
      color: var(--primary-color);
      margin-bottom: 12px;
      font-size: 18px;
    }
  }

  .section__branch-name {
    font-size: 22px;
    font-weight: 900;
  }

  .section__branch-info {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.section__icon {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: var(--background-color-3);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #DBDBDB;

  .success {
    fill: var(--primary-color);
  }
  .warning {
    fill: var(--warning-color);
  }
}

.text {
  font-size: 18px;
  font-weight: bold;
}

.dispatch__bottom {
  display: flex;
  justify-content: flex-end;
  border-top: 1px dashed var(--background-color-4);
  padding-top: 20px;
}

.dispatch__information {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .illustration {
    margin-bottom: 40px;
  }

  .information__text {
    font-size: 22px;
    font-weight: 900;
    color: #BFBFBF;
    text-align: center;
  }
}

.section__order-types {
  display: flex;
  color: var(--primary-color);

  .order-types__type {
    padding: 10px;
    flex-grow: 1;
    border: 1px solid var(--border-color-5);
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.selected {
      border-color: var(--primary-color);
      border-width: 4px;
      padding: 7px;

      .type__check {
        top: 13px;
        right: 13px;
      }
    }

    .type__icon {
      width: 100px;
      height: 100px;
      background-color: var(--background-color-3);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: var(--primary-color);
    }

    .type__name {
      flex-grow: 1;
      text-align: center;
      font-size: 20px;
    }

    .type__check {
      position: absolute;
      top: 16px;
      right: 16px;
      fill: var(--primary-color);
    }
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    margin-right: 20px;
  }

  .svgicon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    fill: var(--primary-color);
    pointer-events: none;
  }

  label {
    color: #808080;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .text {
    font-size: 22px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.modal__company {
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  align-items: center;

  &.selected {
    border-color: var(--primary-color);
    border-width: 4px;
    padding: 13px;
  }

  .company__left {
    margin-right: 16px;
    fill: var(--primary-color);
  }

  .company__name {
    font-size: 20px;
    font-weight: 900;
  }

  .company__address {
    font-weight: 500;
  }
}

.section__address-panels {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;

  .address-panels__panel {
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 20px;
  }

  .panel__actions {
    display: flex;

    .panel__main-address {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      flex-grow: 1;
      fill: var(--primary-color);

      .svgicon {
        margin-right: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
#iShopFoodApp #dispatch {
  .panel {
    border-radius: 40px;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    &>.title {
      font-size: 22px;

      .svgicon {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .title {
      padding: 40px 40px 20px;
    }

    .content {
      flex-grow: 1;
      overflow: hidden;

      &>.container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .content {
      &:before {
        left: 40px;
        right: 40px;
      }
    }

    .container {
      background-color: transparent;
      padding: 20px 40px 40px;
    }
  }

  .user-addresses > .title {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0 0 12px 0;
    padding: 0 !important;
  }

  .location.detailed {
    margin-right: -20px;
  }

  .location.detailed label {
    font-weight: 500;
    margin: 0 0 12px 0;
    padding: 0 !important;
  }

  .location.detailed .input {
    margin: 0 20px 20px 0;

    .input {
      margin: 0;
    }
  }

  .button-holder {
    padding-right: 10px;
  }

  .location .btn {
    height: 56px;
    border-radius: 12px;
    padding: 0 20px;
    background-color: var(--background-color-3) !important;
    font-size: 20px;

    &.btn-primary {
      background-color: var(--primary-color) !important;
    }
  }

  .location .right .btn:not(:last-of-type) {
    margin-right: 20px;
  }

  input,
  .user-address,
  select {
    border-radius: 12px;
  }

  input,
  select {
    height: 56px;
  }
}
</style>