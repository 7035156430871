let APP_NAME = "iShop-Kiosk";
let VERSION_FILE = "version.txt";

window.update = () => {
  if (typeof nw == "undefined") {
    console.log("NOT IN NW");
    return;
  }
  _updateGetVersionNumber()
    .then(_updateFetchLastVersionNumber)
    .then(_updateDownload)
    .then(_updateApply)
    .catch((e) => {
      hideSpinner();
      _updateError(e);
    });
};

let _updateGetVersionNumber = () => {
  return new Promise(function (resolve, reject) {
    let fs = nw.require("fs");
    let currentVersion = "0.0.0";
    if (fs.existsSync(VERSION_FILE)) {
      currentVersion = fs.readFileSync(VERSION_FILE, "utf8");
    }
    resolve(currentVersion);
  });
};

let _updateFetchLastVersionNumber = function (currentVersion) {
  return new Promise((resolve, reject) => {
    let http = nw.require("http");
    let compareVersions = require("compare-versions");
    http.get(CONFIG.urlUpdate + "/" + VERSION_FILE, function (response) {
      let newVersion = "";

      response.on("data", (chunk) => {
        newVersion += chunk;
      });

      response.on("end", () => {
        let updateAvailable = compareVersions.compare(newVersion, currentVersion, ">");
        console.log("new version", newVersion);
        console.log("current version", currentVersion);
        console.log("update available", updateAvailable);
        if (updateAvailable) {
          showConfirmation(window.translate("download.update"), window.translate("download.update_text"))
            .then(() => {
              resolve(newVersion);
            });
        } else {
          showAffirmation(window.translate("download.last_version"), window.translate("download.you_have_last_version"));
        }
      }).on("error", (e) => {
        reject(e);
      });
    }).on("error", (e) => {
      reject(e);
    });
  });
};

let _updateDownload = (newVersion) => {
  return new Promise((resolve, reject) => {
    let http = nw.require("http");
    let fs = nw.require("fs");

    http.get(CONFIG.urlUpdate + "/zip/" + APP_NAME + "-" + newVersion + ".zip", (response) => {

      console.log(response);

      let totalLength = parseInt(response.headers["content-length"]);
      if (totalLength <= 100) {
        showAffirmation(window.translate("error.title"), window.translate("download.cannot_be_downloaded"));
        return;
      }
      let downloaded = 0;
      let file = fs.createWriteStream("update.zip");

      document.getElementById("modalUpdateDownload").classList.add("show");
      document.querySelector("#modalUpdateDownload .modal-header").innerHTML = window.translate("download.downloading") + "...";
      document.querySelector("#modalUpdateDownload .current").innerHTML = "0.00";
      document.querySelector("#modalUpdateDownload .total").innerHTML = (totalLength / 1000000).toFixed(2);
      document.querySelector("#modalUpdateDownload .progressBar .inner").style.width = "0";

      response
        .on("data", (chunk) => {
          downloaded += chunk.length;
          document.querySelector("#modalUpdateDownload .current").innerHTML = (downloaded / 1000000).toFixed(2);
          document.querySelector("#modalUpdateDownload .progressBar .inner").style.width = (downloaded / totalLength * 100) + "%";
          file.write(chunk);
        })
        .on("end", () => {
          file.end();
          resolve();
        })
        .on("error", (e) => {
          reject(e);
        });
    })
      .on("error", (e) => {
        reject(e);
      });

  });
};

let _updateApply = () => {
  return new Promise((resolve, reject) => {
    let unzip = nw.require("unzipper");
    let fs = nw.require("fs");

    document.querySelector("#modalUpdateDownload .modal-header").innerHTML = window.translate("download.installing") + "...";
    fs.createReadStream("update.zip")
      .pipe(unzip.Extract({ path: "update" }))
      .on("close", () => {
        fs.unlink("update.zip", err => {
          console.log(err);
        });
        document.getElementById("modalUpdateDownload").classList.remove("show");
        showAffirmation(window.translate("download.done"), window.translate("download.update_done"))
          .then(() => {
            let child_process = nw.require("child_process");
            let child = child_process.spawn("update.bat", [], {
              detached: true,
              shell: true
            });
            child.unref();
            nw.App.quit();
          });

        //TODO: Paste locale files here

        resolve();
      })
      .on("error", (e) => {
        reject(e);
      });
  });
};

let _updateError = (e) => {
  console.log(e);
  document.getElementById("modalUpdateDownload").classList.remove("show");
  showAffirmation(window.translate("error.title"), window.translate("download.error"));
};
