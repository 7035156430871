<template>
  <div id="tutorial">

    <FloatingLocaleSwitcher></FloatingLocaleSwitcher>

    <div class="background" :class="{cover: coverBackgroundImage}" :style="{backgroundImage: (coverBackgroundImage ? backgroundImage : '')}">

      <template v-if="coverBackgroundImage">

        <div class="logo" v-if="logo">
          <div class="logoImage" :style="{backgroundImage: 'url(' + logo + ')'}"></div>
        </div>

        <Slider class="cover-bottom" dots @finished="finished" v-if="pages" :size="pages.length + 1">
          <div class="page" v-for="page of pages">
            <Illustration v-if="page.image" image white :src="$store.state.urlFileServer + page.image.url"></Illustration>
            <div class="title" v-html="$to(page.title)"></div>
            <div class="split"></div>
            <div class="description" v-html="$to(page.description)"></div>
          </div>

          <div class="continue"></div>

        </Slider>

      </template>

      <template v-if="!coverBackgroundImage">

        <Slider class="normal-slider" dots @finished="finished" v-if="pages" :size="pages.length + 1">
          <div class="page" v-for="page of pages" :class="{fullWidthImages}">
            <Illustration v-if="page.image && !fullWidthImages" image larger shadow white :src="$store.state.urlFileServer + page.image.url"></Illustration>

            <div class="full-width-image" v-if="page.image && fullWidthImages" :style="{backgroundImage: 'url(\'' + $store.state.urlFileServer + page.image.url +  '\''}"></div>

            <div class="normal-bottom">
              <div class="title" v-html="$to(page.title)"></div>
              <div class="split"></div>
              <div class="description" v-html="$to(page.description)"></div>
            </div>
          </div>

          <div class="continue"></div>

        </Slider>

      </template>

    </div>

  </div>
</template>

<script>
  import Slider from "../components/tools/Slider.vue";
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";
  import Illustration from "../components/modal/Illustration.vue";
  import { mapActions } from 'vuex';
  import EventBus from '../lib/eventBus';

  export default {
    name: "Tutorial",

    components: { Slider, Illustration, FloatingLocaleSwitcher },

    beforeMount() {
      this.$store.commit("showNavigationCart", false);

      if (!this.branch || !this.branch.shouldDisplayTutorial()) {
        this.finished();
      }
    },

    computed: {
      branch() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch;
      },
      backgroundImage() {
        if (!this.branch || !this.branch.tutorial || !this.branch.tutorial.activated || !this.branch.tutorial.backgroundImage) {
          return;
        }
        return "url('" + this.$store.state.urlFileServer + this.branch.tutorial.backgroundImage.url + "')";
      },
      logo() {
        if (!this.branch || !this.branch.tutorial || !this.branch.tutorial.activated || !this.branch.tutorial.logoImage) {
          return;
        }
        return this.$store.state.urlFileServer + this.branch.tutorial.logoImage.url;
      },
      coverBackgroundImage() {
        if (!this.branch) {
          return false;
        }
        return this.branch.style.tutorial.displayCoverBackgroundImage;
      },
      fullWidthImages() {
        if (!this.branch || this.coverBackgroundImage) {
          return false;
        }
        return this.branch.style.tutorial.fullWidthImages;
      },
      pages() {
        if (!this.branch || !this.branch.tutorial || !this.branch.tutorial.activated) {
          return [];
        }
        return this.branch.tutorial.pages;
      },
      user() {
        return this.$user;
      }
    },

    methods: {

      ...mapActions({
        setCurrentCompany: "setCurrentCompany"
      }),

      finished() {
        setConfiguration("tutorial-completed", true);
        if (this.$store.state.quickMode) {
          this.$router.push(this.$router.generateFirstPage());
          this.setCurrentCompany(this.branch.companies[0]);
          EventBus.$emit("order-options-modal");
        } else {
          this.$router.push(this.$router.generateFirstPage("orderType"));
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .background {
    flex-grow: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    &.cover {
      .logo {
        flex-grow: 1;
        overflow: hidden;
      }
      .slider {
        flex-shrink: 0;
        height: auto;
      }
    }
  }
  .illustration {
    padding-top: 40px;
  }
  .logo {
    padding: 30px 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    .logoImage {
      flex-grow: 1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      max-width: calc(100% - 80px);
      max-height: 200px;
    }
  }

  .split {
    height: 5px;
    width: 100px;
    background-color: #fc6650;
    margin: 30px 0;
  }

  .cover-bottom {
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    color: white;

    .page {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
    }

    .illustration {
      margin-bottom: 0;
      padding-top: 20px;
    }

    .title {
      margin-top: 20px;
      padding: 0 30px;
      font-weight: bold;
      font-size: 20px;
    }
    .description {
      padding: 0 30px;
      margin-bottom: 10px;
    }
  }
  .normal-slider {
    flex-grow: 1;
  }
  .normal-slider .page {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;

    &.fullWidthImages {
      justify-content: initial;

      .normal-bottom {
        flex-grow: 1;
        justify-content: center;
      }
    }

    .normal-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 30px 60px 30px;
      .description, .title {
        text-align: center;
      }
    }

    .title {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .full-width-image {
    width: 100%;
    height: 45%;
    background-size: cover;
    background-position: center;
  }
</style>

<style lang="scss">
  #tutorial {
    .illustration {
      flex-grow: 1;
      max-height: 50%;
      padding: 40px 16px 0 16px;
    }
  }
</style>