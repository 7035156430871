<template>
  <div id="kioskAdmin">

    <div class="admin--title">{{ $t('kiosk_admin.admin') }}</div>
    <div class="admin--subtitle">{{ $t('kiosk_admin.available_actions') }}</div>

    <div class="admin--action" @click="fetchInventory">
      <div class="action--icon">
        <svgicon src="icons/refresh.svg" :height="120" :width="120"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.fetch_inventory') }}
      </div>
    </div>

    <div class="admin--action" @click="accessConfiguration">
      <div class="action--icon">
        <svgicon src="icons/settings-thin.svg" :height="110" :width="110"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.access_config') }}
      </div>
    </div>

    <div class="admin--action" @click="closeBatch" :class="{ 'action__disabled': !canCloseBatch }">
      <div class="action--icon">
        <svgicon src="icons/receipt.svg" :height="110" :width="120"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.close_batch') }}
      </div>
    </div>

    <div class="admin--action" :class="{ action__disabled: !isNwDefined || !hasAnyPastReceipts }" @click="showPrintModal = true">
      <div class="action--icon">
        <svgicon src="icons/print.svg" :height="110" :width="120"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.print_last_transaction') }}
      </div>
    </div>

    <div class="admin--action" @click="checkForUpdate" :class="{ 'action__disabled': !isNwDefined }">
      <div class="action--icon">
        <svgicon src="icons/download.svg" :height="110" :width="110"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.update') }}
      </div>
    </div>

    <div class="admin--action" @click="reboot">
      <div class="action--icon">
        <svgicon src="icons/reboot.svg" :height="120" :width="120"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.reboot') }}
      </div>
    </div>

    <div class="admin--action" @click="shutDown">
      <div class="action--icon">
        <svgicon src="icons/power-off.svg" :height="105" :width="105"></svgicon>
      </div>
      <div class="action--description">
        {{ $t('kiosk_admin.close') }}
      </div>
    </div>

    <div class="admin--back">
      <button class="btn btn-secondary" @click="back">{{ $t('back') }}</button>
    </div>

    <div class="printModal" v-show="showPrintModal">
      <div class="printModal__body">
        <div class="printModal__header">{{ $t("kiosk_admin.print_past_receipts") }}</div>
        <select v-model="selectedReceiptFileName" @change="loadSelectedReceipt">
          <option selected disabled :value="null">{{ $t("kiosk_admin.select_a_receipt") }}</option>
          <option v-for="receipt in PastReceipts.receipts" :key="receipt" :value="receipt">
            {{ capitalize(moment(receipt.substring(0, 19), "YYYY-MM-DD_HH-mm-ss").format("LLLL")) }}
          </option>
        </select>
        <div class="printModal__receipt">
          <pre v-if="selectedReceipt">{{ selectedReceipt.receiptRaw.join("\r\n") }}</pre>
        </div>
        <div class="printModal__footer">
          <button class="btn btn-secondary" @click="showPrintModal = false">{{ $t("cancel") }}</button>
          <div class="grow"></div>
          <button class="btn btn-primary" :class="{disabled: !selectedReceipt}" @click="printPastReceipt">{{ $t("pos.print") }}</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";
  import Flex from "@/lib/payment/flex";
  import { AmpUSA } from "@/lib/payment/amp-usa";
  import { PastReceipts } from "@/util/kiosk/PastReceipts";
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {

    name: "KioskAdmin",

    data() {
      return {
        showPrintModal: false,
        selectedReceiptFileName: null,
        selectedReceipt: null,
        PastReceipts,
        moment
      };
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      isNwDefined() {
        return window.isExecutable();
      },
      canCloseBatch() {
        let allowedCloseBatch = [
          this.kioskConfiguration.GLOBAL_FLEX,
          this.kioskConfiguration.PAYFACTO_PAX,
          this.kioskConfiguration.AMP_USA
        ];
        return this.kioskConfiguration && (allowedCloseBatch.indexOf(this.kioskConfiguration.terminalPayment) > -1);
      },
      hasAnyPastReceipts() {
        return PastReceipts.receipts.length > 0;
      }
    },

    mounted() {
      this.initAirxTouchDisplayLayout();
      PastReceipts.loadReceipts();
    },

    methods: {

      ...mapActions(["tpvCloseBatch"]),

      async fetchInventory() {
        let response;
        if (this.$store.state.ticketMode) {
          response = await this.inventory.fetchTickets(this.company.nameCanonical);
        } else {
          response = await this.inventory.fetch(this.company.nameCanonical, {
            forceRefresh: true
          });
        }
        if (response.success) {
          showAffirmation(this.$t("kiosk_admin.fetch_completed"), this.$t("kiosk_admin.fetch_completed_description"));
        } else {
          showAffirmation(this.$t("error.title"), this.$t("kiosk_admin.fetch_failed"));
        }
      },

      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            window.airxtouch__submitLayout([
              GetElementBounds("kioskAdmin", 0, AIRxTouchDefaultLayoutSetting.DirectClick) // Click area : Whole page
            ]);
          });
        }
      },

      closeBatch() {
        switch (this.kioskConfiguration.terminalPayment) {
          case this.kioskConfiguration.PAYFACTO_PAX:
            this.closeBatchWithPayfacto();
            break;
          case this.kioskConfiguration.GLOBAL_FLEX:
            this.closeBatchWithFlex();
            break;
          case this.kioskConfiguration.AMP_USA:
            this.closeBatchWithAmp();
            break;
          default:
            break;
        }
      },

      loadSelectedReceipt() {
        this.selectedReceipt = PastReceipts.loadReceipt(this.selectedReceiptFileName);
      },

      printPastReceipt() {
        PastReceipts.printReceipt(this.selectedReceipt);
      },

      async printLastTransactionWithFlex() {
        showSpinner();
        try {
          let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
          await tpv.connect();
          let lastTransactionData = await tpv.printLastTransaction();
          window.printTpvAdminReceipt(lastTransactionData.response.receipt);
          showAffirmation(this.$t("success"), this.$t("flexGlobal.print_last_success"));
          hideSpinner();
        } catch (e) {
          hideSpinner();
          console.error("ERROR FLEX PRINT LAST TRANSACTION", e);
          window.logToFile("ERROR FLEX PRINT LAST TRANSACTION");
          window.logToFile(e);
          showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
        }
      },

      async closeBatchWithFlex() {
        showSpinner();
        try {
          let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
          await tpv.connect();
          let closeBatchData = await tpv.closeBatch();
          window.printTpvAdminReceipt(closeBatchData.response.adminReceipt);
          hideSpinner();
          showAffirmation(this.$t("success"), this.$t("flexGlobal.batch_success"));
        } catch (e) {
          hideSpinner();
          console.error("ERROR FLEX CLOSE BATCH", e);
          window.logToFile("ERROR FLEX CLOSE BATCH");
          window.logToFile(e);
          if (e.response.responseCode === tpv._RESPONSE_CODE.BATCH_EMPTY) {
            showAffirmation(this.$t("error.title"), this.$t("flexGlobal.batch_empty"));
          } else {
            showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
          }
        }
      },

      closeBatchWithAmp() {
        showSpinner();
        AmpUSA.init(this.kioskConfiguration.getAmpTerminalSerialNumber(), this.kioskConfiguration.getAmpAuthCode())
          .then(() => AmpUSA.closeBatch())
          .then((data) => AmpUSA.printSettlementReceipt(data))
          .then(() => hideSpinner())
          .catch((error) => {
            hideSpinner();
            window.logToFile(error);
            console.log(error);
            showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
          });
      },

      async closeBatchWithPayfacto() {
        if (!this.company) {
          return;
        }
        let batchNumber = this.kioskConfiguration.getPayfactoBatchNumber();
        if (!batchNumber) {
          showAffirmation(this.$t("error.title"), this.$t("payfactoPax.batch_num_missing"));
          return;
        }
        showSpinner();
        try {
          let response = await this.tpvCloseBatch({
            company: this.company.nameCanonical,
            processor: "payfacto",
            terminal: this.kioskConfiguration.terminalData.terminalNumber,
            batchNumber: batchNumber
          });
          hideSpinner();
          if (response && response.operationStatus === "00") {
            showAffirmation(this.$t("success"), this.$t("flexGlobal.batch_success"));
            let receiptData = [
              "BATCH : " + batchNumber,
              "RESPONSE CODE : " + response.operationStatus,
              "TOTAL AMOUNT : " + (parseInt(response.totalAmount) / 100).toFixed(2)];
            window.printTpvAdminReceipt(receiptData);
          } else {
            console.log("error close batch payfacto", response);
            window.logToFile("ERROR PAYFACTO CLOSE BATCH");
            window.logToFile(response);
            showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
          }
        } catch (e) {
          hideSpinner();
          console.log("error close batch payfacto", e);
          window.logToFile("ERROR PAYFACTO CLOSE BATCH");
          window.logToFile(e);
          showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
        }
      },

      back() {
        this.$router.push(this.$router.generate("/kiosk/standby"));
      },

      reboot() {
        window.location.reload();
      },

      shutDown() {
        window.close();
      },

      checkForUpdate() {
        window.update();
      },

      accessConfiguration() {
        this.$router.push(this.$router.generate("/kiosk/configuration"));
      },

      capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    }

  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .horizontal#kioskAdmin {
    padding-bottom: 100px;

    .admin--subtitle {
      margin-bottom: 50px;
    }

    .admin--action {
      margin: 25px 50px;
    }
  }

  #iShopFoodApp #kioskAdmin {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    &.vertical__AIRxTOUCH.AIRxTOUCH__32 {
      .admin--title {
        padding-top: 100px;
      }
      .admin--subtitle {
        margin-bottom: 60px;
      }
      .admin--action {
        margin: 20px 40px;
      }
    }

    &.vertical__AIRxTOUCH:not(.AIRxTOUCH__32) {
      .printModal__body {
        margin-top: 600px;
      }
      .admin--title {
        padding-top: 200px;
      }

      .admin--subtitle {
        padding-bottom: 250px;
      }

      .action--icon {
        display: none;
      }

      .admin--action {
        height: 150px;
      }
    }

    .admin--back {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      bottom: 60px;
    }

    .admin--title,
    .admin--subtitle {
      width: 100%;
      min-width: 100%;
      flex-shrink: 0;
      margin: 0 50px;
      text-align: center;
    }

    .admin--title {
      text-transform: uppercase;
      color: var(--primary-color);
      font-size: 38px;
      font-family: "Segoe UI Black", sans-serif;
    }

    .admin--subtitle {
      margin-bottom: 60px;
      margin-top: 15px;
      font-size: 26px;
      font-weight: bold;
      max-width: 750px;
    }

    .admin--action {
      background-color: var(--primary-color);
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      fill: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 290px;
      height: 290px;
      border-radius: 20px;
      margin: 35px 40px;
      padding: 10px;

      &.action__disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .action--icon {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action--description {
      color: white;
      font-size: 30px;
      text-align: center;
    }

    .printModal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;

      .printModal__body {
        background-color: white;
        padding: 40px;
        border-radius: 20px;
      }

      .printModal__header {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        color: var(--primary-color);
        border-bottom: 1px solid #ccc;
      }

      select {
        width: 100%;
      }

      .printModal__receipt {
        margin-top: 20px;
        padding-right: 20px;
        white-space: pre-wrap;
        height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .printModal__footer {
        margin-top: 20px;
        display: flex;

        .grow {
          flex-grow: 1;
          min-width: 20px;
        }
      }
    }
  }
</style>
