<template>
  <div id="configuration">
    <img class="configuration--logo" :src="logo">

    <template v-if="kioskConfiguration">

      <Panel :title="$t('kiosk_configuration.kiosk')" collapsible>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.name') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.name_description') }}</div>
          </div>
          <div class="input">
            <input type="text" v-model="kioskConfiguration.name">
          </div>
        </div>

        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.number') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.number_description') }}</div>
          </div>
          <div class="input">
            <input type="text" v-model="kioskConfiguration.number">
          </div>
        </div>

        <!--<div class="configuration--setting setting__input">
            <div class="setting__info">
                <div class="info--title">{{$t('kiosk_configuration.management_password')}}</div>
                <div class="info--description">{{$t('kiosk_configuration.management_password_description')}}</div>
            </div>
            <div class="input">
              <input type="text" v-model="kioskConfiguration.managementPassword">
            </div>
        </div>-->

        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.admin_password') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.admin_password_description') }}</div>
          </div>
          <div class="input input__icon">
            <input :type="showAdminPassword ? 'text' : 'password'" v-model="kioskConfiguration.adminPassword">
            <div class="icon" @click="showAdminPassword = !showAdminPassword">
              <svgicon :src="showAdminPassword ? 'icons/eye-hide.svg' : 'icons/eye-show.svg'" :height="20"
                       :width="20"></svgicon>
            </div>
          </div>
        </div>

        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.display') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.display_description') }}</div>
          </div>
          <div class="input">
            <div class="input__radio">
              <Radio v-model="kioskConfiguration.display" val="vertical">{{ $t('kiosk_configuration.vertical') }}
              </Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="kioskConfiguration.display" val="horizontal">{{ $t('kiosk_configuration.horizontal') }}
              </Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="kioskConfiguration.display" val="vertical_airxtouch">
                {{ $t('kiosk_configuration.vertical_airxtouch') }}
              </Radio>
            </div>
          </div>
        </div>

        <div class="configuration--setting setting__radios" v-if="kioskConfiguration.isVerticalAIRxTOUCH()">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.display_airxtouch') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.display_airxtouch_description') }}</div>
          </div>
          <div class="input">
            <div class="input__radio">
              <Radio v-model="kioskConfiguration.airxtouchSize" :val="Constant.AIRXTOUCH_SIZE_55">
                {{ $t('kiosk_configuration.airxtouch_55') }}
              </Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="kioskConfiguration.airxtouchSize" :val="Constant.AIRXTOUCH_SIZE_32">
                {{ $t('kiosk_configuration.airxtouch_32') }}
              </Radio>
            </div>
          </div>
        </div>
      </Panel>

      <Panel :title="$t('kiosk_configuration.company')" collapsible>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.branch_canonical') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.branch_canonical_description') }}</div>
          </div>
          <div class="input">
            <input type="text" v-model="kioskConfiguration.branchCanonical">
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t('kiosk_configuration.company_canonical') }}</div>
            <div class="info--description">{{ $t('kiosk_configuration.company_canonical_description') }}</div>
          </div>
          <div class="input">
            <input type="text" v-model="kioskConfiguration.companyCanonical">
          </div>
        </div>
        <div class="setting__action split">
          <div class="left setting__endpoint">
            <svgicon class="endpoint--icon" src="icons/info.svg" :height="24" :width="24"></svgicon>
            <span class="endpoint--title">{{ $t('kiosk_configuration.api_endpoint') }}:</span>
            <span class="endpoint--value">{{ urlServer }}</span>
          </div>
          <div class="right">
            <template v-if="!validBranchAndCompany">
              <button class="btn btn-primary" @click="loadBranchAndCompany">
                {{ $t('kiosk_configuration.validate_branch_and_company') }}
              </button>
            </template>
            <template v-if="validBranchAndCompany">
              <div class="valid">
                <svgicon src="icons/check-circle.svg" :height="24" :width="24"></svgicon>
                {{ $t('kiosk_configuration.branch_company_valid') }}
              </div>
            </template>
          </div>
        </div>
      </Panel>

      <template v-if="validBranchAndCompany">

        <Panel :title="$t('kiosk_configuration.access')" collapsible>
          <div class="configuration--setting setting__input">
            <div class="setting__info">
              <div class="info--title">{{ $t('kiosk_configuration.access_api_key') }}</div>
              <div class="info--description">{{ $t('kiosk_configuration.api_key_description') }}</div>
            </div>
            <div class="input input__icon">
              <input :type="showApiKey ? 'text' : 'password'" v-model="kioskConfiguration.apiKey" @input="apiKeyCheck">
              <div class="icon" @click="showApiKey = !showApiKey">
                <svgicon :src="showApiKey ? 'icons/eye-hide.svg' : 'icons/eye-show.svg'" :height="20"
                         :width="20"></svgicon>
              </div>
            </div>
          </div>
          <template v-if="apiKeyValid">
            <div class="valid right">
              <svgicon src="icons/check-circle.svg" :height="24" :width="24"></svgicon>
              {{ $t('kiosk_configuration.branch_company_valid') }}
            </div>
          </template>
          <template v-if="!apiKeyValid">
            <div class="invalid">
              <svgicon src="icons/error-circle.svg" :height="24" :width="24"></svgicon>
              {{ $t('kiosk_configuration.configuration_invalid') }}
            </div>
          </template>
        </Panel>

        <template v-if="apiKeyValid">
          <Panel :title="$t('kiosk_configuration.methods')" collapsible>
            <div class="configuration--setting setting__switch" :class="{locked: kioskConfiguration.methods.ticket}">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.in_place') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.in_place_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.methods.inPlace"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch" :class="{locked: kioskConfiguration.methods.ticket}">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.takeout') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.takeout_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.methods.takeout"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch" :class="{locked: kioskConfiguration.methods.ticket}">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.grabngo') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.grabngo_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.methods.grabngo"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch" :class="{locked: kioskConfiguration.methods.ticket}">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.scan') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.scan_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.methods.scan"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch" v-if="branchHasTicketMode">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.ticket') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.ticket_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.methods.ticket"></ToggleSwitch>
              </div>
            </div>
          </Panel>

          <Panel :title="$t('kiosk_configuration.payment.title')" collapsible>
            <div class="configuration--setting setting__switch">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.tip') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.tip_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.tip"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.cash') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.cash_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.offlinePayment.cash"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__switch" v-if="company.hasPaymentMethod('itc')">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.account') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.account_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.itcPayment"></ToggleSwitch>
              </div>
            </div>
            <div class="configuration--setting setting__radios">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.terminal') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.terminal_description') }}</div>
              </div>
              <div class="input input--wrap">
                <div class="input__radio">
                  <Radio v-model="kioskConfiguration.terminalPayment" :val="kioskConfiguration.PAYFACTO_PAX">
                    {{ $t('kiosk_configuration.payment.payfactoPax') }}
                  </Radio>
                </div>
                <div class="input__radio">
                  <Radio v-model="kioskConfiguration.terminalPayment" :val="kioskConfiguration.GLOBAL_FLEX">
                    {{ $t('kiosk_configuration.payment.globalFlex') }}
                  </Radio>
                </div>
                <div class="input__radio">
                  <Radio v-model="kioskConfiguration.terminalPayment" :val="kioskConfiguration.AMP_USA">
                    {{ $t('ampUSA.title') }}
                  </Radio>
                </div>
                <div class="input__radio">
                  <Radio v-model="kioskConfiguration.terminalPayment" :val="false">
                    {{ $t('kiosk_configuration.payment.none') }}
                  </Radio>
                </div>
              </div>
            </div>
          </Panel>

          <Panel :title="$t('kiosk_configuration.tip_configuration')" collapsible
                 v-if="kioskConfiguration.isTipEnabled()">
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.tip_values') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.tip_values_description') }}</div>
              </div>
              <br>
              <div class="input__split">
                <div class="input__split--left">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">{{ $t('kiosk_configuration.tip_1') }}</div>
                    </div>
                    <div class="input">
                      <input type="number" min="1" max="100" v-model="kioskConfiguration.tipConfiguration.values[0]">
                    </div>
                  </div>
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">{{ $t('kiosk_configuration.tip_3') }}</div>
                    </div>
                    <div class="input">
                      <input type="number" min="1" max="100" v-model="kioskConfiguration.tipConfiguration.values[2]">
                    </div>
                  </div>
                </div>
                <div class="input__split--right">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">{{ $t('kiosk_configuration.tip_2') }}</div>
                    </div>
                    <div class="input">
                      <input type="number" min="1" max="100" v-model="kioskConfiguration.tipConfiguration.values[1]">
                    </div>
                  </div>
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">{{ $t('kiosk_configuration.tip_4') }}</div>
                    </div>
                    <div class="input">
                      <input type="number" min="1" max="100" v-model="kioskConfiguration.tipConfiguration.values[3]">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel :title="$t('payfactoPax.configuration')" collapsible
                 v-if="kioskConfiguration.hasPayfactoPaxPayment() && kioskConfiguration.terminalData">
            <TipBox danger v-if="!company.hasPaymentMethod('payfacto')"
                    :title="$t('payfactoPax.payfacto_not_configured')">
              <span v-html="$t('payfactoPax.payfacto_not_configured_description')"></span>
            </TipBox>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('payfactoPax.terminal_number') }}</div>
                <div class="info--description">{{ $t('payfactoPax.terminal_number_description') }}</div>
              </div>
              <div class="input">
                <input type="text" v-model="kioskConfiguration.terminalData.terminalNumber">
              </div>
            </div>
          </Panel>

          <Panel :title="$t('flexGlobal.configuration')" collapsible
                 v-if="kioskConfiguration.hasGlobalFlexPayment() && kioskConfiguration.terminalData">
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('flexGlobal.ip') }}</div>
                <div class="info--description" v-html="$t('flexGlobal.ip_description')"></div>
              </div>
              <div class="input">
                <input type="text" v-model="kioskConfiguration.terminalData.ip">
              </div>
            </div>
            <div class="configuration--setting setting__select">
              <div class="setting__info">
                <div class="info--title">{{ $t('flexGlobal.auto_close_batch') }}</div>
                <div class="info--description">{{ $t('flexGlobal.auto_close_batch_description') }}</div>
              </div>
              <div class="input">
                <select v-model="kioskConfiguration.terminalData.autoCloseBatch">
                  <option :value="null">{{ $t('flexGlobal.none') }}</option>
                  <option :value="(n - 1)" v-for="n in 24" :key="`autoclosebatch-${n}`">{{ (n - 1) }}:00</option>
                </select>
              </div>
            </div>
            <div class="configuration--setting">
              <div class="setting__info">
                <div class="info--title">{{ $t('flexGlobal.authorize') }}</div>
                <div class="info--description" v-html="$t('flexGlobal.authorize_description')"></div>
              </div>
            </div>
            <div class="setting__action">
              <button class="btn btn-primary" @click="syncGlobalFlexTerminal">
                {{ $t('flexGlobal.sync') }}
              </button>
            </div>
          </Panel>

          <Panel :title="$t('ampUSA.configuration')" collapsible
                 v-if="kioskConfiguration.hasAmpUSAPayment() && kioskConfiguration.terminalData">
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('ampUSA.terminal_serial_number') }}</div>
                <div class="info--description" v-html="$t('ampUSA.terminal_serial_number_description')"></div>
              </div>
              <div class="input">
                <input type="text" v-model="kioskConfiguration.terminalData.terminalSerialNumber">
              </div>
            </div>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('ampUSA.authentication_code') }}</div>
                <div class="info--description" v-html="$t('ampUSA.authentication_code_description')"></div>
              </div>
              <div class="input">
                <input type="text" v-model="kioskConfiguration.terminalData.authenticationCode">
              </div>
            </div>
            <div class="setting__action">
              <button class="btn btn-primary" @click="initAmpUSA">
                {{ $t('ampUSA.check_connexion') }}
              </button>
            </div>
          </Panel>

          <Panel :title="$t('kiosk_configuration.account')" collapsible
                 v-if="kioskConfiguration.hasItcPayment()">
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.account_device_id') }}</div>
                <div class="info--description" v-html="$t('kiosk_configuration.account_device_id_description')"></div>
              </div>
              <div class="input">
                <input type="text" v-model="kioskConfiguration.itcDeviceId">
              </div>
            </div>
          </Panel>

          <Panel :title="$t('kiosk_configuration.printing')" collapsible>
            <TipBox warning v-if="printers.length === 0">
              {{ $t('kiosk_configuration.no_printers') }}
            </TipBox>
            <div class="configuration--setting setting__switch">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.enable_print') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.enable_print_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.printer.activated"></ToggleSwitch>
              </div>
            </div>
            <template v-if="kioskConfiguration.printer.activated && printers.length >= 0">
              <div class="configuration--setting setting__select">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.printer_name') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.printer_name_description') }}</div>
                </div>
                <div class="input">
                  <select v-model="kioskConfiguration.printer.name">
                    <option value="" disabled hidden>{{ $t('choose') }}...</option>
                    <option :value="printer.name" v-for="printer of printers" :key="`printer-${printer.name}`">
                      {{ printer.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="configuration--setting setting__input">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.receipt_header') }}</div>
                  <div class="info--description" v-html="$t('kiosk_configuration.receipt_header_description')"></div>
                </div>
                <div class="input">
                  <input type="text" :placeholder="$t('kiosk_configuration.text_line_1')"
                         v-model="kioskConfiguration.receipt.headerLine1">
                  <br><br>
                  <input type="text" :placeholder="$t('kiosk_configuration.text_line_2')"
                         v-model="kioskConfiguration.receipt.headerLine2">
                  <br><br>
                  <input type="text" :placeholder="$t('kiosk_configuration.text_line_3')"
                         v-model="kioskConfiguration.receipt.headerLine3">
                </div>
              </div>
              <div class="configuration--setting setting__input">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.receipt_footer') }}</div>
                  <div class="info--description" v-html="$t('kiosk_configuration.receipt_footer_description')"></div>
                </div>
                <div class="input">
                  <input type="text" :placeholder="$t('kiosk_configuration.text_line_1')"
                         v-model="kioskConfiguration.receipt.footerLine1">
                  <br><br>
                  <input type="text" :placeholder="$t('kiosk_configuration.text_line_2')"
                         v-model="kioskConfiguration.receipt.footerLine2">
                </div>
              </div>
              <div class="configuration--setting setting__input">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.printer_cols') }}</div>
                  <div class="info--description" v-html="$t('kiosk_configuration.printer_cols_description')"></div>
                </div>
                <div class="input">
                  <input type="number" min="1" v-model="kioskConfiguration.printer.cols">
                </div>
              </div>
              <div class="setting__action">
                <button class="btn btn-primary" @click="printTestColumns">
                  {{ $t('kiosk_configuration.print_test_columns') }}
                </button>
              </div>
            </template>
          </Panel>

          <Panel :title="$t('kiosk_configuration.calling_system')" collapsible>
            <div class="configuration--setting setting__switch">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.enable_calling_system') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.enable_calling_system_description') }}</div>
              </div>
              <div class="input">
                <ToggleSwitch v-model="kioskConfiguration.callingSystem.activated"></ToggleSwitch>
              </div>
            </div>
            <template v-if="kioskConfiguration.callingSystem.activated">
              <div class="configuration--setting setting__switches">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.calling_system_order_methods') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.calling_system_order_methods_description') }}</div>
                </div>
                <div class="input">
                  <div class="input__switch" v-if="kioskConfiguration.hasInPlace()">
                    <ToggleSwitch v-model="kioskConfiguration.callingSystem.methods.inPlace">{{ $t("kiosk_method.inPlace") }}</ToggleSwitch>
                  </div>
                  <div class="input__switch" v-if="kioskConfiguration.hasTakeout()">
                    <ToggleSwitch v-model="kioskConfiguration.callingSystem.methods.takeout">{{ $t("kiosk_method.takeout") }}</ToggleSwitch>
                  </div>
                </div>
              </div>
              <div class="configuration--setting setting__switch">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.calling_system_number_mandatory') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.calling_system_number_mandatory_description') }}</div>
                </div>
                <div class="input">
                  <ToggleSwitch v-model="kioskConfiguration.callingSystem.required"></ToggleSwitch>
                </div>
              </div>
              <div class="configuration--setting setting__input">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.calling_system_title') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.calling_system_title_description') }}</div>
                </div>
                <br>
                <div class="input__split">
                  <div class="input__split--left">
                    <div class="setting__input">
                      <div class="setting__info">
                        <div class="info--description">FR</div>
                      </div>
                      <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.calling_system_title') + ' (fr)'"
                                v-model="kioskConfiguration.callingSystem.title.fr"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="input__split--right">
                    <div class="setting__input">
                      <div class="setting__info">
                        <div class="info--description">EN</div>
                      </div>
                      <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.calling_system_title') + ' (en)'"
                                v-model="kioskConfiguration.callingSystem.title.en"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="configuration--setting setting__input">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.calling_system_subtitle') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.calling_system_subtitle_description') }}</div>
                </div>
                <br>
                <div class="input__split">
                  <div class="input__split--left">
                    <div class="setting__input">
                      <div class="setting__info">
                        <div class="info--description">FR</div>
                      </div>
                      <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.calling_system_subtitle') + ' (fr)'"
                                v-model="kioskConfiguration.callingSystem.subtitle.fr"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="input__split--right">
                    <div class="setting__input">
                      <div class="setting__info">
                        <div class="info--description">EN</div>
                      </div>
                      <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.calling_system_subtitle') + ' (en)'"
                                v-model="kioskConfiguration.callingSystem.subtitle.en"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Panel>

          <Panel :title="$t('kiosk_configuration.start_page')" collapsible>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.start_title') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.start_title_description') }}</div>
              </div>
              <br>
              <div class="input__split">
                <div class="input__split--left">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">FR</div>
                    </div>
                    <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.start_title') + ' (fr)'"
                                v-model="kioskConfiguration.start.title.fr"></textarea>
                    </div>
                  </div>
                </div>
                <div class="input__split--right">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">EN</div>
                    </div>
                    <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.start_title') + ' (en)'"
                                v-model="kioskConfiguration.start.title.en"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.start_subtitle') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.start_subtitle_description') }}</div>
              </div>
              <br>
              <div class="input__split">
                <div class="input__split--left">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">FR</div>
                    </div>
                    <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.start_subtitle') + ' (fr)'"
                                v-model="kioskConfiguration.start.subtitle.fr"></textarea>
                    </div>
                  </div>
                </div>
                <div class="input__split--right">
                  <div class="setting__input">
                    <div class="setting__info">
                      <div class="info--description">EN</div>
                    </div>
                    <div class="input">
                      <textarea :placeholder="$t('kiosk_configuration.start_subtitle') + ' (en)'"
                                v-model="kioskConfiguration.start.subtitle.en"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel :title="$t('kiosk_configuration.more')" collapsible>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.minimum_transaction_number') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.minimum_transaction_number_description') }}
                </div>
              </div>
              <div class="input">
                <input type="number" min="1" v-model="kioskConfiguration.minimumTransactionNumber">
              </div>
            </div>
            <div class="configuration--setting setting__input">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.maximum_transaction_number') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.maximum_transaction_number_description') }}
                </div>
              </div>
              <div class="input">
                <input type="number" min="1" v-model="kioskConfiguration.maximumTransactionNumber">
              </div>
            </div>
            <div class="configuration--setting setting__input"
                 v-if="kioskConfiguration.methods.grabngo || kioskConfiguration.methods.scan || kioskConfiguration.hasItcPayment()">
              <div class="setting__info">
                <div class="info--title">{{ $t('grabngo.scan_delay') }}</div>
                <div class="info--description">{{ $t('grabngo.scan_delay_description') }}</div>
              </div>
              <div class="input">
                <input type="number" v-model="kioskConfiguration.scannerDelay">
              </div>
            </div>
            <!--<div class="configuration--setting setting__switch">
                <div class="setting__info">
                    <div class="info--title">{{$t('kiosk_configuration.no_quantity_product')}}</div>
                    <div class="info--description">{{$t('kiosk_configuration.no_quantity_product_description')}}</div>
                </div>
                <div class="input">
                    <ToggleSwitch v-model="kioskConfiguration.noQuantityProduct"></ToggleSwitch>
                </div>
            </div>
            <div class="configuration--setting setting__switch">
                <div class="setting__info">
                    <div class="info--title">{{$t('kiosk_configuration.no_quantity_modifier')}}</div>
                    <div class="info--description">{{$t('kiosk_configuration.no_quantity_modifier_description')}}</div>
                </div>
                <div class="input">
                    <ToggleSwitch v-model="kioskConfiguration.noQuantityModifier"></ToggleSwitch>
                </div>
            </div>-->
            <div class="configuration--setting setting__switches">
              <div class="setting__info">
                <div class="info--title">{{ $t('kiosk_configuration.locale') }}</div>
                <div class="info--description">{{ $t('kiosk_configuration.locale_description') }}</div>
              </div>
              <div class="input">
                <div class="input__switch">
                  <ToggleSwitch v-model="kioskConfiguration.locales.en">English</ToggleSwitch>
                </div>
                <div class="input__switch">
                  <ToggleSwitch v-model="kioskConfiguration.locales.fr">Français</ToggleSwitch>
                </div>
              </div>
              <div class="configuration--setting setting__select" v-if="kioskConfiguration.hasMultipleLocales()">
                <div class="setting__info">
                  <div class="info--title">{{ $t('kiosk_configuration.default_locale') }}</div>
                  <div class="info--description">{{ $t('kiosk_configuration.default_locale_description') }}</div>
                </div>
                <div class="input">
                  <select v-model="kioskConfiguration.locales.default">
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                  </select>
                </div>
              </div>
            </div>
          </Panel>

          <div class="configuration--actions">
            <button class="btn btn-secondary" v-if="kioskConfiguration.getLocalKioskConfiguration()" @click="cancel">
              {{ $t('kiosk_configuration.cancel') }}
            </button>
            <div class="filler"></div>
            <button class="btn btn-primary" @click="save">{{ $t('kiosk_configuration.save') }}</button>
          </div>
        </template>

      </template>

    </template>

  </div>
</template>

<script>
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";
  import { AmpUSA } from "@/lib/payment/amp-usa";
  import Flex from "@/lib/payment/flex";
  import KioskConfiguration from "@/models/KioskConfigurationModel";
  import PrintFormatter from "@/lib/printer/printFormatter";
  import Radio from "@/components/tools/Radio.vue";
  import TipBox from "@/components/tools/TipBox.vue";
  import ToggleSwitch from "@/components/tools/ToggleSwitch.vue";
  import { mapActions } from "vuex";
  import { Constant } from "@/util/Constant.js";

  export default {
    name: "Configuration",

    components: { ToggleSwitch, Radio, TipBox },

    data() {
      return {
        branchAndCompanyValidationRequired: false,
        apiKeyValid: false,
        apiKeyValidationTimeout: null,
        showAdminPassword: false,
        showApiKey: false,
        printers: [],
        Constant
      };
    },

    mounted() {
      this.initialize();
      this.initAirxTouchDisplayLayout();
      this.apiKeyCheck();
      this.fetchPrinters();
    },

    watch: {
      "kioskConfiguration.locales": {
        deep: true,
        handler: function() {
          this.setLocale();
        }
      },
      "kioskConfiguration.methods": {
        deep: true,
        handler: function() {
          if (this.kioskConfiguration.isTicket()) {
            this.kioskConfiguration.disableAllMethodsNotTicket();
          }
        }
      },
      "kioskConfiguration.terminalPayment"(newValue) {
        switch (newValue) {
          case false:
            this.kioskConfiguration.terminalData = null;
            break;
          case this.kioskConfiguration.AMP_USA: {
            this.kioskConfiguration.terminalData = {
              terminalSerialNumber: null,
              authenticationCode: null
            };
            break;
          }
          case this.kioskConfiguration.GLOBAL_FLEX:
            this.kioskConfiguration.terminalData = {
              ip: "",
              autoCloseBatch: null
            };
            break;
          case this.kioskConfiguration.PAYFACTO_PAX:
            this.kioskConfiguration.terminalData = {
              terminalNumber: ""
            };
            break;
        }
      },
      "kioskConfiguration.branchCanonical"() {
        this.branchAndCompanyValidationRequired = true;
      },
      "kioskConfiguration.companyCanonical"() {
        this.branchAndCompanyValidationRequired = true;
      }
    },

    computed: {
      validBranchAndCompany() {
        return !this.branchAndCompanyValidationRequired && this.$store.state.currentBranch && this.company;
      },
      urlServer() {
        let baseURL = this.$store.getters.urlServer;
        let url = baseURL.replace("https://", "").replace("http://", "");
        return url.split(".")[0];
      },
      branchHasTicketMode() {
        return this.branch && this.branch.areTicketsActivated();
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      logo() {
        if (this.company && this.company.images && this.company.images.kioskLogo) {
          return CONFIG.urlFileServer + this.company.images.kioskLogo.url;
        }
        return "img/kiosk/logo.png";
      }
    },

    beforeDestroy() {
      clearTimeout(this.apiKeyValidationTimeout);
    },

    methods: {
      ...mapActions(["getBranch", "validateApiKey"]),

      initialize() {
        if (!this.$store.state.kioskConfiguration) {
          this.$store.state.kioskConfiguration = new KioskConfiguration();
        }
      },

      async fetchPrinters() {
        showSpinner();
        let printerResponse = await new PrintFormatter().getPrinters();
        hideSpinner();
        if (!printerResponse.success) {
          toast({ message: this.$t("pos.printing.connect_error"), type: "error", title: this.$t("error.title") });
        }
        this.printers = printerResponse.printers;
      },

      apiKeyCheck() {
        if (this.apiKeyValidationTimeout) {
          clearTimeout(this.apiKeyValidationTimeout);
        }
        if (!this.kioskConfiguration.apiKey) {
          this.apiKeyValid = false;
          return;
        }
        if (!this.branch) {
          return;
        }
        this.apiKeyValidationTimeout = setTimeout(async () => {
          showSpinner();
          this.apiKeyValid = await this.validateApiKey({ apiKey: this.kioskConfiguration.apiKey, branchId: this.branch.id });
          hideSpinner();
        }, 400);
      },

      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            window.airxtouch__submitLayout([
              GetElementBounds("configuration", 0, AIRxTouchDefaultLayoutSetting.DragDrop) // Click area : Whole page
            ]);
          });
        }
      },

      printTestColumns() {
        new PrintFormatter({ printerName: this.kioskConfiguration.printer.name }).printTestColumns();
      },

      initAmpUSA() {
        showSpinner();
        AmpUSA.init(this.kioskConfiguration.getAmpTerminalSerialNumber(), this.kioskConfiguration.getAmpAuthCode())
          .then(() => AmpUSA.flushTransaction())
          .then(() => AmpUSA.initializeDisplay())
          .then(() => {
            hideSpinner();
            showAffirmation(this.$t("success"), this.$t("ampUSA.connection_to_terminal_success"));
          })
          .catch((e) => {
            console.log(e);
            logToFile(e);
            hideSpinner();
            showAffirmation(this.$t("error.title"), this.$t("ampUSA.connection_to_terminal_fail"));
          });
      },

      async syncGlobalFlexTerminal() {
        showSpinner();
        let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
        try {
          await tpv.connect();
          await tpv.initTerminal();
          hideSpinner();
        } catch (e) {
          console.error("TPV FLEX ERROR", e);
          hideSpinner();
          if (e.data) {
            switch (e.data.type) {
              case "CANNOT_CONNECT":
                showAffirmation(this.$t("error.title"), this.$t("flexGlobal.cannot_connect"));
                break;
              case "BUSY":
                showAffirmation(this.$t("success"), this.$t("flexGlobal.success"));
                break;
              default:
                showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
                break;
            }
          } else if (e.type === "DISCONNECTED") {
            showAffirmation(this.$t("error.title"), this.$t("flexGlobal.error"));
          }
        }
      },

      async loadBranchAndCompany() {
        this.kioskConfiguration.clearApiKey();
        this.apiKeyValid = false;
        let branch = await this.getBranch({
          id: this.kioskConfiguration.branchCanonical,
          company: this.kioskConfiguration.companyCanonical,
          force: true
        });
        if (branch) {
          this.kioskConfiguration.methods.ticket = false;
          this.branchAndCompanyValidationRequired = false;
        } else {
          showAffirmation(this.$t("kiosk_configuration.branch_company_invalid"), this.$t("kiosk_configuration.branch_company_invalid_description"));
        }
      },

      setLocale() {
        this.kioskConfiguration.validateDefaultLocale();
        let locale = this.kioskConfiguration.getDefaultLocale();
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },

      save() {
        if (!this.kioskConfiguration.validate()) {
          return;
        }
        this.kioskConfiguration.stripHTML();
        if (this.kioskConfiguration.isTicket()) {
          this.$store.state.ticketMode = true;
          this.inventory.fetchTickets(this.company.nameCanonical);
        }
        window.resize();
        this.kioskConfiguration.matchTransactionNumberToMinimumConfigured();
        this.setLocale();
        this.kioskConfiguration.setLocalKioskConfiguration();
        this.$router.push(this.$router.generate("/kiosk/standby"));
      },

      cancel() {
        if (!this.kioskConfiguration.getLocalKioskConfiguration()) {
          return;
        }
        this.kioskConfiguration.loadFromLocalKioskConfiguration();
        this.$router.push(this.$router.generate("/kiosk/admin"));
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #configuration {
    background-color: #f5f5f5;
    background-image: none;
    padding: 60px;
    align-items: center;
    overflow-y: auto;

    &.vertical__AIRxTOUCH {
      padding-top: 300px;

      &.AIRxTOUCH__32 {
        padding-top: 150px;
        .configuration--logo {
          padding-bottom: 150px;
        }
      }

      .configuration--logo {
        padding-bottom: 230px;
      }
    }

    .configuration--logo {
      width: auto;
      max-width: 300px;
      margin-bottom: 60px;
    }

    .valid, .invalid {
      display: flex;
      align-items: center;
      font-weight: bold;

      .svgicon {
        margin-right: 10px;
      }
    }

    .invalid {
      fill: #b41c28;
      color: #b41c28;
      justify-content: flex-end;
    }

    .valid {
      fill: #2a9e45;
      color: #2a9e45;

      &.right {
        justify-content: flex-end;
      }
    }

    .info--title {
      font-weight: bold;
      padding-bottom: 2px;
      font-size: 18px;
    }

    .setting__action {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;

      &.split {
        justify-content: space-between;
      }

      .btn {
        font-size: 16px;
        padding: 20px 25px;
      }
    }

    .configuration--actions {
      display: flex;
      width: 100%;
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 40px;
      flex-shrink: 0;

      .filler {
        flex-grow: 1;
      }

      .btn {
        font-size: 18px;
        padding: 20px 25px;

        &.btn-secondary {
          background-color: white;
        }
      }
    }

    .configuration--setting {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 2px dashed #e6e6e6;
        margin-bottom: 20px;
      }

      &.locked {
        position: relative;
        pointer-events: none;
        &:after {
          content: "";
          background-color: rgba(255, 255, 255, 0.5);
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          position: absolute;
        }
      }

      .input__icon {
        position: relative;

        input {
          padding-right: 60px;
        }

        .icon {
          position: absolute;
          padding: 10px;
          fill: #1a1a1a;
          right: 5px;
          top: 15px;
        }
      }

      &.setting__radios, &.setting__switches {
        .input {
          display: flex;

          &.input--wrap {
            flex-wrap: wrap;

            .input__radio {
              width: 33%;
              margin-bottom: 15px;
            }
          }
        }

        .input__radio, .input__switch {
          padding-right: 40px;
        }
      }

      &.setting__switch {
        flex-direction: row;
        align-items: center;

        .setting__info {
          flex-grow: 1;
          padding-right: 60px;
        }
      }
    }

    .setting__endpoint {
      display: flex;
      align-items: center;
    }
    .endpoint--icon {
      margin-right: 10px;
      fill: #888;
    }
    .endpoint--title {
      color: #888;
      padding-right: 8px;
    }
    .endpoint--value {
      color: #888;
      font-weight: bold;
    }

    .input__split {
      display: flex;

      .input__split--right, .input__split--left {
        flex-grow: 1;
        flex-basis: 0;
      }
      .input__split--left {
        margin-right: 40px;
      }
    }

    .panel {
      width: 100%;
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
    }

    input[type=radio] + label {
      padding-left: 40px;
      white-space: nowrap;
      width: auto;
      padding-top: 10px;

      &::after {
        left: 20px;
      }
    }
  }
</style>
