<template>
  <div class="loyalty-levels">

    <MenuHeader :text="$t('loyalty.levels')" icon="icons/wallet.svg"></MenuHeader>

    <Levels></Levels>

  </div>
</template>

<script>
  import MenuHeader from "../../components/menu/MenuHeader.vue";
  import Levels from "../../components/loyalty/Levels.vue";

  export default {

    name: "loyaltyLevels",

    components: { MenuHeader, Levels },

    mounted() {
      this.$store.commit("showNavigationCart", true);
    }

  }
</script>