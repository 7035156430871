<template>
  <div id="qr-payment__menu-display" >
    <div class="menu-display__infos" v-if="loading || error">
      <div class="dots" v-if="loading"></div>
      <div class="menu-display__error" v-if="error">
        <svgicon src="icons/warning.svg" :height="50" :width="50"></svgicon>
        <div class="error__title">{{$t('qr_payment.home.error_fetch_menu')}}</div>
      </div>
    </div>

    <iframe id="menu-display__iframe" :src="digitalMenuURL" v-if="!loading && !error && digitalMenuURL"></iframe>

    <div class="menu-display__footer">
      <button class="btn btn-primary" @click="back">{{$t('back')}}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "qrPaymentMenuDisplay",

    async mounted() {
      let menuUrl = await this.getDigitalMenuURL();

      this.loading = false;
      if (!menuUrl) {
        this.error = true;
      } else {
        this.digitalMenuURL = menuUrl;
      }
    },

    data() {
      return {
        loading: true,
        error: false,
        digitalMenuURL: ""
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      }
    },

    methods: {
      back() {
        let url = "/table";
        if (this.$route.query.number) {
          url += "?number=" + this.$route.query.number;
        }
        this.$router.push(this.$router.generate(url));
      },
      async getDigitalMenuURL() {
        if (!this.company) {
          return null;
        }
        return await this.company.getDigitalMenuUrlForLocale(this.$ts.state.locale, true);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #qr-payment__menu-display {
    display: flex;
    flex-direction: column;
    height: 100%;
    iframe {
      border: none;
      flex-grow: 1;
    }
    .menu-display__infos, .menu-display__error {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .error__title {
      padding: 20px;
      font-weight: bold;
    }
    .svgicon {
      fill: #e31818 !important;
    }
  }

  #iShopFoodApp .menu-display__footer {
    padding: 20px 20px 20px 20px;

    .btn {
      width: 100%;
      padding: 20px;
    }
  }

  /**
* ==============================================
* Dot Floating
* https://codepen.io/nzbin/pen/GGrXbp
* ==============================================
*/
  $dots-color: #ccc;
  .dots {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
  }

  .dots::before, .dots::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dots::before {
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloatingBefore 3s infinite ease-in-out;
  }

  .dots::after {
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
  }

  @keyframes dotFloating {
    0% {
      left: calc(-50% - 5px);
    }
    75% {
      left: calc(50% + 105px);
    }
    100% {
      left: calc(50% + 105px);
    }
  }

  @keyframes dotFloatingBefore {
    0% {
      left: -50px;
    }
    50% {
      left: -12px;
    }
    75% {
      left: -50px;
    }
    100% {
      left: -50px;
    }
  }

  @keyframes dotFloatingAfter {
    0% {
      left: -100px;
    }
    50% {
      left: -24px;
    }
    75% {
      left: -100px;
    }
    100% {
      left: -100px;
    }
  }
</style>