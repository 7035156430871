<template>
  <div id="voidModal" class="modal" :class="{show: show}">
    <div class="modal-content" v-if="detail">

      <div class="modal-header">
        <svgicon src="icons/void.svg" :height="24" :width="24"></svgicon>
        <div class="header__text">{{$t('void.title')}}</div>
      </div>

      <div class="modal__item">
        <AsyncImage class="item__image" :fallback="$store.state.defaultItemImage" :image="image"></AsyncImage>
        <div class="item__description">
          <label class="description__label">{{$t('title.item')}}</label>
          <div class="description__name">{{detail.name}}</div>
        </div>
      </div>

      <div class="modal__reasons">
        <label class="reasons__label">{{$t('void.reasons')}}</label>
        <div class="reasons">
          <div class="reason" v-for="(reason, reasonIndex) of voidReasons" :key="'reason-' + reasonIndex">
            <input :id="'radio--reason' + reasonIndex" type="radio" v-model="selectedVoidReason" :value="reasonIndex">
            <label :for="'radio--reason' + reasonIndex">{{$to(reason)}}</label>
          </div>
          <div class="reason">
            <input id="radio--reason-other" type="radio" v-model="selectedVoidReason" :value="-1">
            <label for="radio--reason-other">{{$t('other')}}</label>
          </div>
        </div>
      </div>
      
      <div class="modal__custom-reason">
        <label class="custom-reason__label">{{$t('void.other_reasons')}}</label>
        <textarea class="custom-reason__text" v-model="customVoidReason" :placeholder="$t('void.enter_reason')"></textarea>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="voidItem">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{$t('confirm')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import AsyncImage from "@/components/tools/AsyncImage";

  export default {
    name: "VoidModal",

    components: { AsyncImage },

    data() {
      return {
        item: null,
        detail: null,
        inventoryItem: null,
        voidReasons: [
          {fr: "Erreur de l'employé", en: "Employee mistake"},
          {fr: "Erreur en cuisine", en: "Kitchen mistake"},
          {fr: "Changement du client", en: "Customer change"},
          {fr: "Problème de qualité", en: "Quality issue"},
        ],
        customVoidReason: "",
        selectedVoidReason: null,
        show: false
      }
    },

    mounted() {
      EventBus.$on("void-detail", (detail) => {
        this.detail = detail;
        this.item = this.inventory.search(this.detail.itemId);
        this.reset();

        this.$nextTick(() => {
          this.show = true;
        });
      });
    },

    beforeDestroy() {
      EventBus.$off("void-detail");
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      image() {
        if (this.item && this.item.image) {
          return this.$store.state.urlFileServer + this.item.image;
        }
      }
    },

    methods: {
      voidItem() {
        this.$store.state.table.voidDetailAndRelatives(this.detail);
        this.show = false;
      },
      reset() {
        this.selectedVoidReason = "";
        this.customVoidReason = "";
      },
      back() {
        this.show = false;
        this.reset();
      }
    }
  }
</script>

<style lang="scss" scoped>

#iShopFoodApp #voidModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 580px;

    .modal__item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .item__image {
        width: 180px;
        height: 120px;
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .item__description {
        .description__label {
          margin-bottom: 5px;
        }
        .description__name {
          font-size: 26px;
          font-weight: 900;
          color: #1a1a1a;
        }
      }
    }

    .reasons__label, .custom-reason__label, .description__label {
      font-weight: 500;
      color: #808080;
      font-size: 16px;
    }

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-weight: 900;
        font-size: 22px;
        color: #1a1a1a;
      }
    }

    .modal__reasons {
      margin-bottom: 30px;
      .reasons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .reason {
          margin: 10px;
          border: 1px solid #e6e6e6;
          border-radius: 12px;
          display: flex;
          align-items: center;
          font-weight: 500;
          padding: 10px 12px;
          width: 240px;

          input[type=radio] + label {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 40px;

            &:after {
              left: 15px;
            }
          }
        }
      }
    }

    .modal__custom-reason {
      display: flex;
      flex-direction: column;
      .custom-reason__text {
        font-family: inherit;
        margin-top: 10px;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        height: 100px;
        font-weight: 500;
        padding: 20px;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>