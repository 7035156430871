<template>
  <div id="splitItemModal" class="modal split-item-modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-header">
        <svgicon src="icons/split.svg" :height="20" :width="20"></svgicon>
        <div class="header__text">{{$t('pos_split_item_modal.title')}}</div>
      </div>

      <div class="split-item-modal__description">
        {{$t('pos_split_item_modal.description')}}
      </div>

      <div class="split-item-modal__body">
        <div class="split-item-modal__item-info" v-if="detail">
          <div class="section_title">{{$t('pos_seats.selected_item')}}</div>
          <PosCartItem :detail="detail" no-actions></PosCartItem>
        </div>
        <div class="split-item-modal__seats">
          <div class="section_title">{{$t('pos_seats.title')}}</div>
          <SeatsSelector :seats="seats" :selection="localSplitSeats" class="seats" :disabledSeats="[currentSeatBuffer]"
                         @update:selection="localSplitSeats = $event" @addSeat="addSeat"></SeatsSelector>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="splitDetails">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{$t('confirm')}}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import SeatsSelector from "@/components/pos/SeatsSelector";
  import EventBus from "@/lib/eventBus";
  import PosCartItem from "@/components/pos/PosCartItem";

  export default {
    name: "SplitItemModal",

    components: { SeatsSelector, PosCartItem },

    data() {
      return {
        show: false,
        localSplitSeats: [],
        currentSeatBuffer: 1,
        detail: null,
        seats: []
      };
    },

    mounted() {
      EventBus.$on("show-split-item", (currentSeat, detail) => {
        if (!currentSeat || !detail) {
          return;
        }
        this.localSplitSeats = [currentSeat];
        this.currentSeatBuffer = currentSeat;
        this.detail = detail;
        this.seats = this.getAllSeats();
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-split-item");
    },

    computed: {
      table() {
        return this.$store.state.table;
      }
    },

    methods: {
      getAllSeats() {
        return this.table?.getAllSeats() || [];
      },
      splitDetails() {
        this.table.splitDetail(this.detail, this.localSplitSeats);
        this.show = false;
      },
      addSeat() {
        let lastSeat = this.seats.length || null;
        if (lastSeat !== null) {
          this.seats.push(lastSeat + 1);
        }
      },
      back() {
        this.show = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #splitItemModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 1425px;

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
      }
    }

    .split-item-modal__body {
      display: flex;
    }

    .split-item-modal__description {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      font-size: 20px;
      font-weight: 700;
    }

    .section_title {
      padding-bottom: 15px;
      font-weight: 500;
      color: var(--theme-color-2);
    }

    .split-item-modal__item-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-right: 80px
    }

    .split-item-modal__seats {
      width: 745px;
      .seats {
        max-height: 500px;
        height: 500px;
        width: 690px;
        overflow-y: scroll;
        padding-right: 10px;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>