<template>
  <div ref="element">
    <div class="simplebar-wrapper">
      <div class="simplebar-height-auto-observer-wrapper">
        <div class="simplebar-height-auto-observer" />
      </div>
      <div class="simplebar-mask">
        <div class="simplebar-offset">
          <div
              class="simplebar-content-wrapper"
              ref="scrollElement"
              v-on="{
              ...($listeners.scroll && {
                scroll: $listeners.scroll,
              })
            }"
          >
            <div class="simplebar-content" ref="contentElement">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="simplebar-placeholder" />
    </div>
    <div class="simplebar-track simplebar-horizontal">
      <div class="simplebar-scrollbar" />
    </div>
    <div class="simplebar-track simplebar-vertical">
      <div class="simplebar-scrollbar" />
    </div>
  </div>
</template>

<script>
  import SimpleBar from 'simplebar';
  export default {
    name: 'scrollbar',
    data() {
      return {
        bar: null
      }
    },
    mounted () {
      this.bar = new SimpleBar(this.$refs.element, {
        autoHide: false,
        scrollbarMaxSize: 44,
        scrollbarMinSize: 44,
        clickOnTrack: true,
        clickOnTrackSpeed: 100
      });
    },
    computed: {
      scrollElement () {
        return this.$refs.scrollElement;
      },
      contentElement () {
        return this.$refs.contentElement;
      }
    }
  }
</script>

<style lang="scss">
   $size: 44px;

  .simplebar-track.simplebar-vertical {
    width: 70px !important;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 20px;
      border-radius: 10px;
      background-color: #ebebeb;
      left: 12px;
    }
  }
  .simplebar-scrollbar {
    height: $size;
    width: $size;
    background: var(--primary-color) url(../../assets/icons/scroll.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;

    &:before {
      content: none !important;
    }
  }
</style>