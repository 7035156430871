<template>
  <transition name="fade">
    <div class="cookie-banner" v-if="show">
      <div class="cookie-banner--container">
        <div class="left">
          <div class="cookie-banner--text">{{$t('cookies.description')}}</div>
          <router-link class="cookie-banner--link" :target="isCordovaDefined ? '_self' : '_blank'"
                       :to="{name: $store.state.currentCompany ? 'cookiesPolicy' : 'cookiesPolicyNoCompany'}">
            {{ $t('cookies.view_policy') }}
          </router-link>
        </div>
        <button class="btn btn-primary" @click="cookieConsent">{{$t('cookies.ok')}}</button>
      </div>
    </div>
  </transition>
</template>

<script>
  import ExternalLink from "@/components/tools/ExternalLink.vue";

  export default {
    name: 'cookieBanner',

    components: { ExternalLink },

    data() {
      return {
        show: false
      }
    },

    computed: {
      url() {
        return this.$router.resolve(this.$router.generate('/cookies-policy')).href;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      isCordovaDefined() {
        return typeof cordova !== "undefined";
      }
    },

    mounted() {
      if (getConfiguration("cookie-consent") || this.$kiosk) {
        return;
      }

      let interval = setInterval(async () => {
        if (!this.branch) {
          return;
        }
        clearInterval(interval);

        if (this.branch.shouldHideCookiesPolicyBanner()) {
          return;
        }

        setTimeout(() => {
          this.show = true;
        }, 2000);
      }, 100);

    },

    methods: {
      cookieConsent() {
        setConfiguration("cookie-consent", true);
        this.show = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .cookie-banner {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    left: 0;
    right: 0;
    bottom: 0;

    .cookie-banner--container {
      display: flex;
      padding: 20px 15px;
      align-items: center;
      max-width: 1100px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .cookie-banner--link {
      display: block;
      margin-top: 2px;
      cursor: pointer;
      text-decoration: underline;
    }

    .btn {
      border-radius: 6px;
      padding: 10px 30px;
    }

    .left {
      padding-right: 15px;
      flex-grow: 1;
    }

    * {
      color: white !important;
    }

    @media screen and (max-width: 500px) {
      font-size: 14px;

      .cookie-banner--link {
        margin-top: 5px;
      }

      .btn {
        font-size: 14px;
      }
    }
  }
</style>
