<template>
  <div :id="boxId" class="error-box" v-show="visible" :class="{visible: visible, alwaysVisible: alwaysVisible}" @click="$emit('click')">
    <template v-if="hasSlot">
      <span><slot /></span>
    </template>
    <template v-else>
      <span v-if="type === 'choice'">{{$t('invalid_choice')}}</span>
      <span v-if="type !== 'choice'">{{$t('invalid_form')}}</span>
    </template>
  </div>
</template>

<script>
  export default {

    name: "errorBox",

    props: {
      visible: Boolean,
      alwaysVisible: Boolean,
      boxId: String,
      type: String
    },

    computed: {
      hasSlot() {
        return Object.keys(this.$slots).length > 0;
      }
    },


  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .error-box {
    padding: 15px 10px;
    background-color: #ff3f56;
    font-weight: 600;
    cursor: pointer;
    padding-right: 30px;
    position: relative;
    border-radius: 5px;
    font-size: 14px !important;

    &.alwaysVisible {
      pointer-events: none;
      padding-right: 10px;

      &:after {
        content: none;
      }
    }

    span {
      color: white;
    }

    &::after {
      content: "✕";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      line-height: 1;
      color: white;
    }
  }
</style>
