<template>
  <div id="orderLocation">

    <PageTitle>
      <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
      <template>{{$t('payment.submit_order')}}</template>
    </PageTitle>

    <FloatingLocaleSwitcher></FloatingLocaleSwitcher>

    <div class="hub">

      <div class="left"></div>

      <div class="right">
        <div class="page-content">

          <CompleteStepBar icon="icons/livraison.svg" :text="$t('method.' + $order.method + '_long')" v-if="!$store.getters.isMobile" @click="backToType"
                           :disabled="$store.state.currentBranch && $store.state.currentBranch.getOrderMethodsAvailable().length <= 1"></CompleteStepBar>

          <div class="order-card">
            <div class="card-header">
              <svgicon src="icons/location.svg" :height="42" :width="42"></svgicon>
              <div class="card-big-title">{{$t("order.location")}}</div>
              <hr class="mobile">
            </div>
            <div class="card-content">
              <div class="container order-location">
                <div class="text" v-if="branch && branch.hasZones()">
                  <template v-if="branch && branch.hasZones()">{{ $t("order.location_text") }}</template>
                  <template v-if="branch && !branch.hasZones()">{{ $t("order.location_text_zip") }}</template>
                </div>

                <div class="form">
                  <UserLocation type="order-type" :showCustomCompanyButton="true" prefillAddress
                                @changed="userLocationChanged" @custom-company="toCompany"></UserLocation>
                </div>

                <div class="login">
                  <hr class="mobile">
                  <template v-if="!$user.token">
                    <p class="text">{{$t('login.have_an_account')}}</p>
                    <button class="btn btn-secondary flat" @click="login">{{$t('login.login')}}</button>
                  </template>
                  <template v-if="$user.token">
                    <button class="btn btn-secondary flat" @click="logout">{{$t('navigation.logout')}}</button>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons" v-if="$store.getters.isMobile">
            <button type="button" class="btn btn-secondary" @click="backToType">{{$t('back')}}</button>
          </div>
        </div>
      </div>

    </div>

    <HistoryBack></HistoryBack>

  </div>
</template>

<script>
  import CompleteStepBar from "@/components/order/CompleteStepBar.vue";
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";
  import UserLocation from "@/components/orderOptions/UserLocation.vue";
  import EventBus from "@/lib/eventBus.js";

  export default {
    name: "orderLocation",

    components: { CompleteStepBar, FloatingLocaleSwitcher, UserLocation },

    data() {
      return {
        zipcode: "",
        skipAddressOrZip: false
      };
    },

    async mounted() {
      if (!this.$order.method && this.branch && this.branch.getOrderMethodsAvailable().length > 1) {
        this.$router.push(this.$router.generate("/orderType"));
        return;
      }
      if (this.branch && this.branch.deliveryOnlyViaThirdParty(this.$order.method)) {
        this.skipAddressOrZip = true;
        this.toCompany();
      }
      setTimeout(() => {
        if (this.$store.state.quickMode) {
          this.$router.replace(this.$router.generate("/menu"));
        }
      }, 0);
      this.$store.commit("showNavigationCart", false);
      if (this.$store.state.currentBranch) {
        this.$ga.event("new-user-location", this.$store.state.currentBranch.nameCanonical);
      }
    },

    watch: {
      "$order.method": function(newValue) {
        if (newValue !== "delivery") {
          this.$router.replace(this.$router.generate("/orderCompany"));
        }
      },
      branch(newBranch) {
        if (!this.$order.method && newBranch && newBranch.getOrderMethodsAvailable().length > 1) {
          this.$router.push(this.$router.generate("/orderType"));
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      hasDelivery() {
        if (!this.branch) {
          return false;
        }
        return this.branch.getOrderMethodsAvailable().indexOf("delivery") > -1;
      },
      branchLogo() {
        if (!this.branch || !this.branch.navigationLogo) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.navigationLogo.url;
      }
    },

    methods: {
      async getCompanies() {
        if (!this.branch) {
          return [];
        }
        let companies = this.branch.getCompaniesForMethod(this.$order.method, this.$user.getDefaultAddress());
        companies = await this.$order.filterCompaniesForDoorDashDelivery(companies);
        return companies;
      },
      async submit() {
        if (!this.$user.getDefaultAddress().fullAddress) {
          return;
        }
        showSpinner();
        let companies = await this.getCompanies();
        hideSpinner();
        if (companies.length === 0) {
          if (this.hasDelivery) {
            // EventBus.$emit("showDeliveryError"); // TODO - Calling it
            if (!this.branch.hasZones()) {
              showAffirmation(this.$t("error.title"), this.$t("order.location_not_found_zipcode"));
            } else {
              showAffirmation(this.$t("error.title"), this.$t("order.location_not_found"));
            }
            this.$ga.event("location-error", this.$store.state.currentBranch.nameCanonical, this.$user.getDefaultAddress().getGeocodingInformations());
          }
        } else {
          this.$router.push(this.$router.generate("/orderCompany"));
        }
      },
      login() {
        EventBus.$emit("show-authentication-modal");
      },
      logout() {
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
      },
      backToType() {
        this.$order.setMethod(null);
        this.$router.push(this.$router.generate("/orderType"));
      },
      toCompany() {
        this.$router.push(this.$router.generate("/orderCompany?all=true"));
      },
      userLocationChanged() {
        this.$nextTick(() => {
          this.submit();
        });
      }
    }

  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    height: 100%;
  }

  .buttons {
    display: flex;
    flex-shrink: 0;
    .btn {
      flex-grow: 1;
      //flex-basis: 0;
      //flex-shrink:0;
      padding: 16px;
    }
  }

  .form {
    width: 100%;
    max-width: 600px;
    margin: 45px auto 50px auto;
    text-align: left;
    display: flex;
    flex-direction: column;

    .label {
      display: block;
      text-align: left;
      font-size: 14px;
      color: #808080;
      padding-left: 12px;
      padding-bottom: 4px;
    }
    .input {
      width: 100%;
    }
    input:focus {
      outline-color: #fc6650;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #bfbfbf;
      font-size: 18px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #bfbfbf;
      font-size: 18px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #bfbfbf;
      font-size: 18px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #bfbfbf;
      font-size: 18px;
    }
  }

  .order-step-complete-bar, .order-card {
    width: 100%;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }

  .card-content {
    .text {
      text-align: center;
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 50px;
    }
  }

  .card-big-title {
    text-align: center;
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
      display: flex;
      flex-direction: column;

      &:after {
        display: none;
      }
    }
    .pageTitle {
      display: inherit;
    }
    .card-content .text {
      padding-top: 0px;
      text-align: center;
    }
    .form {
      margin:0;
      margin-top: 20px;
    }
    .order-card {
      border-radius: 0;
      box-shadow: none;
      flex-grow: 1;
      margin: 0;
    }
    #history-back {
      display: none;
    }
  }

   #iShopFoodApp .pageTitle {
    display: none;
    .logo {
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .input-row {
    margin-bottom: 30px;
  }

  hr.mobile {
    display: block;
    height: 3px;
    width: 50px;
    border: 0;
    border-top: 3px solid #fc6650;
    margin: 20px auto;
    padding: 0;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .ml-1 {
    margin-left: 1em;
  }

  #iShopFoodApp.xs .login {
    hr.mobile {
      margin-top: 40px;
    }
  }

  #iShopFoodApp .login {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;

    hr.mobile {
      display: block !important;
      margin-top: 0;
      border-width: 3px;
    }

    .text {
      margin-bottom: 15px;
      margin-top: 15px;
      text-align: center;
      padding: 0;
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp #orderLocation .toggleMenu {
    user-select: none;
    visibility: hidden;
    cursor: initial;
  }
</style>
