<template>
  <Panel :locked="locked" class="panel-location" :title="$t('payment.order_information')" icon="icons/location.svg" ref="locationInnerPanel" collapsible>
    <ErrorBox boxId="errorBoxOrder" :visible="errorBoxOrderVisible" @click="errorBoxOrderVisible = false"></ErrorBox>
    <div class="form">
      <div class="left">

        <div class="input">
          <label>{{$t('payment.order_type')}}</label>
          <select :value="$order.method" @change="setOrderMethod" :class="{invalid: $validate.error('$order.method')}">
            <option value="" disabled hidden>{{$t('choose')}}...</option>
            <option value="takeout" v-if="hasOrderMethod('takeout')">{{$t('method.takeout_short')}}</option>
            <option value="delivery" v-if="hasOrderMethod('delivery') || (company && company.hasThirdPartyDelivery())">{{$t('method.delivery_short')}}</option>
            <option value="inPlace" v-if="hasOrderMethod('inPlace')">{{$t('method.inPlace_short')}}</option>
            <option value="catering" v-if="hasOrderMethod('catering')">{{$t('method.catering_short')}}</option>
            <option value="hotel" v-if="hasOrderMethod('hotel')">{{$t('method.hotel_short')}}</option>
          </select>
        </div>

        <template v-if="hasPaymentAfterPickupEnabled">
          <div id="selectPaymentOnly" class="select payment-only">{{$t('order_options.please_select_option')}}</div>
          <div class="radios payment-only">
            <input id="radioPaymentOnly__true" type="radio" v-model="$order.paymentOnly" :value="true">
            <label for="radioPaymentOnly__true">{{$t('order_options.payment_only_true')}}</label>
            <input id="radioPaymentOnly__false" type="radio" v-model="$order.paymentOnly" :value="false">
            <label for="radioPaymentOnly__false">{{$t('order_options.payment_only_false')}}</label>
          </div>
        </template>

        <template v-if="shouldAskForReceptionTime">
          <div class="input" v-if="company && ($order.method == 'catering' || company.isFutureDateEnabled())">
            <label>Date</label>
            <datetime :zone="company.timezone" :value-zone="company.timezone"
                      v-model="date" :format="dateFormat" :min-datetime="minDate"
                      :disableDay="company.getFunctionToCheckIfDayDisabled()" :week-start="7" :input-class="['select']"></datetime>
          </div>

          <div class="input" v-if="(inAdvanceEnabled || $order.method == 'catering') && company && $order.method">
            <label v-if="$order.method == 'delivery' || $order.method == 'hotel'">{{$t('payment.delivery_hour')}}</label>
            <label v-if="$order.method == 'takeout'">{{$t('payment.takeout_hour')}}</label>
            <label v-if="$order.method == 'catering'">{{$t('payment.takeout_hour')}}</label>
            <select :value="$order.hour" @change="setOrderHour" :class="{invalid: $validate.error('$order.hour')}"
              v-if="company.getAvailableOpenHours($order.method).length > 0">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option v-for="hour of company.getAvailableOpenHours($order.method)" :value="hour" :key="hour" :disabled="!company.isIntervalAvailable(hour)">
                {{ company.formatHour(hour) }}
              </option>
            </select>
            <input type="text" disabled :value="$t('order.no_hour_available')" :class="{invalid: $validate.error('$order.hour')}"
              v-if="company.getAvailableOpenHours($order.method).length == 0">
          </div>

          <div class="input" v-if="!inAdvanceEnabled && averageDelay > 0">
            <label>{{$t('order.average_delay')}}</label>
            <input type="text" :value="averageDelay + ' minutes'" readonly>
          </div>
        </template>

        <!-- Delivery -->
        <div v-show="$order.method === 'delivery'">
          <UserLocation :no-button="!$user.token || !$user.getDefaultAddress().isNew()" prefillAddress type="payment" :validate="$validate"
                        @changed="userLocationChanged"
                        :forceDisableAutocomplete="manualAddress || !shouldEnableAutocomplete"></UserLocation>
          <div class="manual" v-show="shouldEnableAutocomplete && $user.shouldCreateAddress()">
            <input id="chkManual" type="checkbox" v-model="manualAddress">
            <label for="chkManual">{{ $t("payment.not_use_autocomplete") }}</label>
          </div>
        </div>

        <div v-show="$order.method === 'inPlace'">
          <div class="input">
            <label>{{ $t("payment.table_number") }}</label>
            <input id="txtTable" type="text" v-model="$order.tableNumber" :class="{invalid: $validate.error('$order.tableNumber')}">
          </div>
        </div>

        <div v-show="$order.method === 'hotel'">
          <div class="input">
            <label>{{ $t("payment.room_number") }}</label>
            <input id="txtRoom" type="text" v-model="$order.roomNumber" :class="{invalid: $validate.error('$order.roomNumber')}">
          </div>
        </div>

      </div>
      <div class="right">
        <div class="input" v-show="$order.method === 'delivery'">
          <label>{{$t('payment.delivery_note')}}</label>
          <input type="text" v-model="$user.getDefaultAddress().note" :placeholder="$t('payment.delivery_note')">
        </div>
        <div class="input">
          <label>{{$t('payment.branch')}}</label>
          <div class="branch" v-if="company">
            <div class="address-info">{{company.name}}</div>
            <div class="address-info">{{company.address.address}}</div>
            <div class="address-info">{{company.address.city}} ({{company.address.state}})</div>
            <div class="address-info">{{company.address.zipCode}}</div>
          </div>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>

  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import Store from "@/store";
  import moment from "moment";
  import Address from "../../models/AddressModel";
  import { mapActions } from "vuex";
  import GeocodingHelper from "../../lib/geocodingHelper";
  import EventBus from "@/lib/eventBus.js";
  import FavoriteCompany from "@/components/FavoriteCompany.vue";
  import UserLocation from "@/components/orderOptions/UserLocation.vue";

  export default {

    props: {
      errorBoxOrderVisible: Boolean,
      locked: Boolean
    },

    components: { ErrorBox, FavoriteCompany, UserLocation },

    data() {
      return {
        manualAddress: false,
        $order: this.$store.state.order,
        formattedAddress: this.$user.getDefaultAddress().getFormattedAddress(),
        date: this.$order.date.toISOString()
      };
    },

    mounted() {
      if (this.shouldEnableAutocomplete) {
        this.manualAddress = false;
      }
    },

    validate: {
      "$order.date": {
        required: true,
        condition: function() {
          return this.$order.method === "catering";
        }
      },
      "$order.method": {
        required: true
      },
      "$order.hour": {
        required: true,
        condition: function() {
          return this.shouldAskForReceptionTime && (this.$order.method === "catering" || this.inAdvanceEnabled);
        }
      },
      "$order.tableNumber": {
        required: true,
        condition: function() {
          return this.$order.method === "inPlace";
        }
      },
      "$order.roomNumber": {
        required: true,
        condition: function() {
          return this.$order.method === "hotel";
        }
      }
    },

    watch: {
      "$order.date": function(newValue) {
        if (this.date != newValue.toISOString()) {
          this.date = newValue.toISOString();
        }
      },
      date: function(newValue) {
        let value = moment(newValue);
        this.$order.date = moment(this.$order.date).set({
          year: value.get("year"),
          month: value.get("month"),
          date: value.get("date")
        });
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },

      shouldEnableAutocomplete() {
        return this.branch && this.branch.autocompleteOnPayment;
      },

      shouldAskForReceptionTime() {
        return (typeof this.$order.paymentOnly === "boolean" && !this.$order.paymentOnly) || !this.hasPaymentAfterPickupEnabled;
      },

      hasPaymentAfterPickupEnabled() {
        if (this.$order.method !== "takeout") {
          return false;
        }
        return this.company && this.company.hasPaymentAfterPickupEnabled();
      },

      company() {
        return this.$store.state.currentCompany;
      },

      inAdvanceEnabled() {
        if (!this.company) {
          return false;
        }
        return this.company.getOpenHours(this.$order.method) && this.company.getOpenHours(this.$order.method).inAdvance;
      },

      averageDelay() {
        if (!this.company) {
          return 0;
        }
        return this.company.getOpenHours(this.$order.method) && this.company.getOpenHours(this.$order.method).averageDelay;
      },

      hasOrderMethod() {
        return (method) => {
          if (!this.$store.state.currentCompany) {
            return false;
          }
          return this.$store.state.currentCompany.hasOrderMethod(method);
        }
      },

      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d";
        }
        return "d MMMM";
      },
      minDate() {
        if (this.company.isTodayDisabled()) {
          return moment().add({ day: 1 }).toISOString();
        }
        return moment().toISOString();
      }
    },
    methods: {

      ...mapActions({
        setCurrentCompanyAction: "setCurrentCompany"
      }),

      async checkDoorDashZone() {
        if (this.company.isDoorDashActivated()) {
          showSpinner();
          await this.$order.getDoorDashEstimate(this.company);
          hideSpinner();
        }
      },

      userLocationChanged() {
        this.checkDoorDashZone();
      },


      setOrderHour(e) {
        this.$order.setHour(e.target.value);
      },

      // TODO this is duplicate. Should be moved. This behaviour is in too many places (orderLocation/orderType, menuHeader, Cart, panelLocation, orderOptionsModal)
      async setOrderMethod(e) {
        let method = e.target.value;

        if (!this.hasOrderMethod(method) && this.company.hasThirdPartyDelivery()) {
          EventBus.$emit("show-third-party-delivery-modal", this.company);
          e.target.value = this.$order.method; // reset back to previous method
          return;
        }

        for (let item of this.$order.items) {
          if (item.getPrice(method) == 0) {
            let result = await showConfirmation(this.$t("warning"), this.$t("order.reset_change_method"));
            if (result) {
              break;
            } else {
              this.$forceUpdate();
              return;
            }
          }
        }
        this.$order.setMethod(method);
      },

      open() {
        this.$refs.locationInnerPanel.open();
      },

      async submit() {
        // Validate user address
        if (this.$order.method === "delivery" && (this.$user.getDefaultAddress().isNew() || this.$user.getDefaultAddress().isEmpty())) {
          showAffirmation(this.$t("error.user.confirm_address"), this.$t("error.user.confirm_delivery_address"));
          document.getElementsByClassName("panel-location")[0].scrollIntoView({ behavior: "smooth" });
          return false;
        }

        //Validate table
        if (this.$order.method === "inPlace" && !this.$store.state.currentCompany.validateTable(this.$order.tableNumber)) {
          showAffirmation(this.$t('error.title'), this.$t('payment.invalid_table'));
          return;
        }

        //Validate room
        if (this.$order.method === "hotel" && !this.$store.state.currentCompany.validateRoom(this.$order.roomNumber)) {
          showAffirmation(this.$t('error.title'), this.$t('payment.invalid_room'));
          return;
        }

        // Validate form
        if (!this.$validate.valid()) {
          return false;
        }
        
        // Validate if not in delivery zone
        if (this.$order.method === "delivery") {
          let isCurrentCompanyInDeliveryZone = (this.company.isDoorDashActivated() ? (await this.$order.isInDeliveryZoneWithDoorDash(this.company)) : this.company.isInDeliveryZone(this.$user.getDefaultAddress()));
          if (!isCurrentCompanyInDeliveryZone) {
            // Validate delivery zones of other companies
            for (let company of this.$store.state.currentBranch.companies) {
              if (company !== this.company && company.hasOrderMethod("delivery")) {
                let isInCompanyDeliveryZone = (company.isDoorDashActivated() ? (await this.$order.isInDeliveryZoneWithDoorDash(company)) : company.isInDeliveryZone(this.$user.getDefaultAddress()))
                if (isInCompanyDeliveryZone) {
                  let result = await showConfirmation(this.$t("error.title"), this.$t("error.out_of_zone_suggestion").replace("{NAME}", company.name));
                  if (result) {
                    this.setCurrentCompanyAction(company);
                    this.$router.push(this.$router.generate("/menu"));
                  }
                  return false;
                }
              }
            }
            this.$ga.event("location-error", this.$store.state.currentCompany.nameCanonical, this.$user.getDefaultAddress().getGeocodingInformations());
            if (this.$store.state.currentCompany.hasZones()) {
              showAffirmation(this.$t("error.title"), this.$t("order.location_not_found"));
              return false;
            } else {
              let response = await showAffirmation(this.$t("error.title"), this.$t("order.location_not_found_zipcode"));
              if (response) {
                this.$store.state.currentCompany = null;
                this.$router.push(this.$router.generate("/orderLocation"));
              }
            }
            return false;
          }
        }
        return true;
      }
    }
  }

</script>

<style lang="scss">

  #iShopFoodApp {
    .select.payment-only {
      padding-bottom: 15px;
      padding-top: 15px;
      font-size: 16px;
    }
    .radios.payment-only {
      margin-bottom: 10px;
      label {
        padding-left: 30px;
        padding-top: 9px;
        font-size: 16px;
        width: 100%;

        &::after {
          left: 10px;
        }
      }
    }
  }

  .xs .form {
    flex-direction: column;
    .left {
      padding-right: 0;
    }
  }

  #paymentcontact .panel.xs {
    .row.location {
      .input.grow {
        margin: 0;
      }
      .input {
        width: 100%;

        input {
          width: 100%;
          max-width: initial !important;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    .left, .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
    }
    .left {
      padding-right: 20px;
    }
  }

  .input {
    padding: 12px 0;

    label {
      display: block;
      font-size: 14px;
      color: #808080;
      padding-bottom: 8px;
      padding-left: 8px;
    }
    input, select, textarea {
      width: 100%;
      font-family: Roboto, sans-serif;
    }
    .address-info {
      padding: 4px 0;
    }
  }
</style>

<style lang="scss">
  #paymentcontact {
    .panel-location {
      .content {
        overflow: visible;
      }
    }
  }
  #iShopFoodApp .panel-location {
    .row.location {
      .user-addresses {
        margin-bottom: 5px !important;
        &:not(.all) {
          .user-address {
            margin-bottom: 0 !important;
          }
        }
      }
      .button-holder {
        margin-top: 0 !important;
        margin-bottom: 10px;
        .right {
          flex-direction: row;
          justify-content: space-between;

          &.no-back {
            justify-content: flex-end;
          }
        }
      }
    }
  }
</style>
