/**
 * @typedef {Object} Coord
 * @property {number} lat
 * @property {number} lng
 */

// UP-TO-DATE DOCUMENTATION : https://www.developer.here.com/documentation/maps/3.1.19.2/dev_guide/topics/geocoding.html
let GeocodingHelper = {

  init: function() {

    if (typeof H === "undefined" || !H.service) return;

    this.platform = new H.service.Platform({
      apiKey: CONFIG.hereApiKey,
      useHTTPS: true
    });

    // Ajout de la fonction de collision
    // https://stackoverflow.com/questions/21138769/check-if-lat-long-is-within-a-nokia-here-map-polygon
    H.geo.Polygon.prototype.containsCoordinate = function(latitude, longitude) {

      if (!this.getBoundingBox().containsLatLng(latitude, longitude)) {1
        return false;
      }

      var inPoly = false;
      var path = this.getExterior();

      for (var i = 0; i < path.getPointCount(); i++) {
        var vertex1 = path.extractPoint(i);
        var vertex2 = path.extractPoint(i == 0 ? path.getPointCount() - 1 : i - 1);
        // ¯\_(ツ)_/¯
        if (((vertex1.lng < longitude && vertex2.lng >= longitude) || (vertex2.lng < longitude && vertex1.lng >= longitude)) &&
          (vertex1.lat + (longitude - vertex1.lng) / (vertex2.lng - vertex1.lng) * (vertex2.lat - vertex1.lat) < latitude)) {
          inPoly = !inPoly;
        }
      }

      return inPoly;

    };

  },

  /**
   * @param {Coord} coord
   * @param {Coord[]} polygon
   */
  coordInPolygon: function(coord, polygon) {

    if (polygon.length == 0) {
      return false;
    }
    var lineString = [];
    for (var i = 0; i < polygon.length; i++) {
      lineString.push(polygon[i].lat);
      lineString.push(polygon[i].lng);
      lineString.push(0);
    }

    try {
      var colPolygon = new H.geo.Polygon(new H.geo.LineString(lineString));
      return colPolygon.containsCoordinate(coord.lat, coord.lng);
    } catch (e) {
      console.log("error here api line string");
      return false;
    }

  },

  geocodeWithGoogle: function(address) {
    return new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (results.length == 0) {
          reject(status);
        }
        resolve(results[0]);
      });
    });
  },

  geocodeWithHere: function(address) {
    return new Promise(async (resolve) => {
      let lat = null;
      let lng = null;
      let response;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          lat = position.coords.latitude;
          lng = position.coords.longitude;
          response = await this._geocode(address, lat, lng);
          resolve(response);
        }, async () => {
          response = await this._geocode(address, null, null);
          resolve(response);
        }, {
          timeout: 10000
        });
      } else {
        response = await this._geocode(address, null, null);
        resolve(response);
      }
    });
  },

  _geocode: function(address, lat, lng) {
    return new Promise((resolve) => {
      let params = { q: address };
      if (lat && lng) {
        params.at = `${lat},${lng},150`;
      }
      this.platform.getSearchService().geocode(params, function(result) {
        if (result.items && result.items.length > 0) {
          resolve(result.items[0]);
        } else {
          resolve(null);
        }
      }, function(e) {
        resolve(null);
      });

    });
  }

}

export default GeocodingHelper;
