<template>
  <Panel :locked="locked" :title="$t('payment.order_comments')" icon="icons/comments.svg" v-if="company && company.hasCommentsActivated()">
    <p style="margin-bottom: 10px">{{ $t("payment.comments_text") }}</p>
    <div class="input">
      <label for="" class="commentLabel">
        {{ $t("payment.comments") }}
        <div class="grow"></div>
        <div class="value" :class="{bad: $order.comment.length > 500}">{{ $order.comment.length }}/500</div>
      </label>
      <textarea name="" id="" v-model="$order.comment" :class="{invalid: validate.error('$order.comment')}"></textarea>
    </div>
  </Panel>
</template>

<script>
  export default {
    name: "PanelComment",

    props: {
      validate: Object,
      locked: Boolean
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .commentLabel {
    display: flex;
    .grow {
      flex-grow: 1;
    }
    .value {
      color: inherit;
      &.bad {
        color: red !important;
      }
    }
  }
</style>
