<template>
  <div id="qr-payment__payment">
    <template v-if="$store.getters.isMobileOrTablet">

      <Header @back="back" :class="{locked: shouldLockPanels}"></Header>

      <div class="payment__container">
        <div class="payment-total__holder">
          <div class="payment-total__info info__invoice">
            <div class="total-info__label">{{$t('qr_payment.invoice')}}</div>
            <div class="total-info__value"><template v-for="invoice of invoices">#{{invoice.id}} </template></div>
          </div>

          <div class="payment-total__info info__email" v-if="step === 2">
            <div class="total-info__label">{{$t('email')}}</div>
            <div class="total-info__value">{{ user.email }}</div>
          </div>

          <hr class="hr--dashed">

          <div class="payment-total__info info__promotion" v-if="total.promotionTotal < 0">
            <div class="total-info__label">{{$t('total.promotions')}}</div>
            <div class="total-info__value">{{$tc(total.promotionTotal.toFixed(2))}}</div>
          </div>
          <div class="payment-total__info info__subtotal">
            <div class="total-info__label">{{$t('total.subtotal')}}</div>
            <div class="total-info__value">{{$tc(total.subTotal.toFixed(2))}}</div>
          </div>
          <hr>
          <div class="payment-total__info info__tax" v-for="(tax, taxName) of total.taxes" :key="'tax-' + taxName">
            <div class="total-info__label">{{tax.name}}</div>
            <div class="total-info__value">{{$tc((tax.total || 0).toFixed(2))}}</div>
          </div>
          <div class="payment-total__info info__service" v-if="total.serviceFees > 0">
            <div class="total-info__label">{{$t('total.service_fees')}}</div>
            <div class="total-info__value">{{$tc(total.serviceFees.toFixed(2))}}</div>
          </div>
          <div class="payment-total__info info__tip" v-if="total.tip > 0">
            <div class="total-info__label">{{$t('total.tip')}}</div>
            <div class="total-info__value">{{$tc(total.tip.toFixed(2))}}</div>
          </div>
          <hr>
          <div class="payment-total__info info__total">
            <div class="total-info__label">{{$t('loyalty.total_to_pay')}}</div>
            <div class="total-info__value">{{$tc((total.totalToPay || 0).toFixed(2))}}</div>
          </div>
        </div>

        <div class="payment-form__holder" v-show="step === 1">
          <div class="form__title">
            <svgicon class="form-title__icon" src="icons/info.svg" :height="20" :width="20"></svgicon>
            <div class="form-title__text">{{canTip ? $t('qr_payment.tip_and_email') : $t('qr_payment.your_information')}}</div>
          </div>

          <template v-if="canTip">
            <Tip :enabled="true" :class="{locked: shouldLockPanels}"></Tip>
            <hr class="hr--dashed">
          </template>

          <div class="form__email" :class="{locked: shouldLockPanels}">
            <div class="email__warning">
              <svgicon src="icons/info.svg" :height="16" :width="16"></svgicon>
              {{$t('qr_payment.email_tip')}}
            </div>
            <label for="txtUserEmail" class="input-group__label">{{$t('email')}}</label>
            <input id="txtUserEmail" type="text" class="input-group__input" v-model="user.email" :placeholder="$t('email_example')">
          </div>

          <div class="form__button-container">
            <button class="btn btn-primary button--pay" @click="displayConfirmationModal">
              {{$t('qr_payment.ready_to_pay')}}
            </button>
          </div>
          <div id="paymentStep__confirmation-modal" class="modal" :class="{show: showConfirmationModal}">
            <div class="modal-content">
              <div class="modal-body">
                <div class="modal-body__description">
                  <template >
                    {{$t('qr_payment.payment_step_disclaimer')}}
                  </template>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary button--back" @click="showConfirmationModal = false">{{ $t('back') }}</button>
                <button type="button" class="btn btn-primary button--confirm" @click="goToPaymentStep">{{ $t('confirm') }}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-form__holder" v-show="step === 2">
          <div class="form__title">
            <svgicon class="form-title__icon" src="icons/card-wallet.svg" :height="20" :width="20"></svgicon>
            <div class="form-title__text">{{$t('payment.payment')}}</div>
          </div>

          <PaymentForm ref="paymentForm" :validate="$validate" @trigger-validation="validatePayment($event)" hidePaymentMethodSelection></PaymentForm>

          <!-- Valitor start -->
          <button class="btn btn-primary btn-continue" @click="continueToValitorPayment"
                  v-if="PaymentProcessor.isPaymentMethod('valitor') && !PaymentProcessor.readyForPayment && !PaymentProcessor.valitorPayment">
            {{$t('loyalty.continue_to_payment')}}
          </button>
          <div class="valitor-payment" v-if="hasPaymentMethod('valitor')"
                 v-show="PaymentProcessor.isPaymentMethod('valitor') && PaymentProcessor.valitorInFocus && PaymentProcessor.readyForPayment">
            <iframe id="iframeValitor" class="valitor-iframe" ref="valitorIframe"></iframe>
          </div>
          <!-- Valitor end -->

          <div class="form__button-container">
            <button class="btn btn-primary button--pay" v-if="!PaymentProcessor.shouldHideDefaultOrderButton()" @click="onCompleteClick">
              {{$t('qr_payment.pay')}}
            </button>
          </div>

          <div class="form__button-container">
            <button class="btn btn-secondary button--pay" @click="step = 1">
              {{$t('back')}}
            </button>
          </div>

        </div>
      </div>

    </template>
    <template v-else>
      <DesktopNotSupported></DesktopNotSupported>
    </template>
  </div>
</template>

<script>
  import Header from "@/components/qrPayment/Header.vue";
  import DesktopNotSupported from "@/components/qrPayment/DesktopNotSupported.vue";
  import PaymentForm from "@/components/payment/PaymentForm.vue";
  import Tip from "@/components/payment/Tip.vue";
  import { mapActions } from "vuex";
  import { Util } from "@/util/Util";
  import { PaymentProcessor } from "../../PaymentProcessor";
  import EventBus from "../../lib/eventBus.js";

  export default {
    name: "qrPaymentPayment",

    components: { Header, DesktopNotSupported, PaymentForm, Tip },

    data() {
      return {
        step: 1,
        paymentAmount: null,
        showConfirmationModal: false,
        PaymentProcessor
      };
    },

    validate: {
      "PaymentProcessor.cardholderName": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardholderName"); }
      },
      "PaymentProcessor.cardBillingPostalCode": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingPostalCode"); }
      },
      "PaymentProcessor.cardCountry": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardCountry"); }
      },
      "PaymentProcessor.cardBillingAddress": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingAddress"); }
      },
      "PaymentProcessor.cardBillingCity": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingCity"); }
      },
      "PaymentProcessor.cardBillingState": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingState"); }
      },
      "PaymentProcessor.cardFirstName": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardFirstName"); }
      },
      "PaymentProcessor.cardLastName": {
        required: true,
        condition: function () { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardLastName"); }
      },
      "PaymentProcessor.cardNumber": {
        required: true,
        regex: /[0-9 ]+/,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardNumber"); }
      },
      "PaymentProcessor.cvv": {
        required: true,
        regex: /[0-9]+/,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cvv"); }
      },
      "PaymentProcessor.expMonth": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("expMonth"); }
      },
      "PaymentProcessor.expYear": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("expYear"); }
      }
    },

    computed: {
      total() {
        return this.$order.getOrderTotal();
      },
      company() {
        return this.$store.state.currentCompany;
      },
      invoices() {
        return this.$order.invoices;
      },
      tableNumber() {
        return this.$route.query.number || this.$route.query.NUMBER || this.$order.tableNumber || null;
      },
      invoiceNumber() {
        return this.$route.query.invoice || this.$route.query.INVOICE || null;
      },
      shouldHideNoneTipOption() {
        return this.company && this.company.shouldHideNoneTipOption();
      },
      canTip() {
        return this.company && this.company.hasTipEnabled();
      },
      user() {
        return this.$store.state.user;
      },
      paymentMethods() {
        if (!this.company) {
          return [];
        }
        return this.company.getPaymentMethods();
      },
      shouldLockPanels() {
        return PaymentProcessor.isPaymentMethod("valitor") && PaymentProcessor.valitorInFocus;
      },
      hasPaymentMethod() {
        return (method) => {
          return this.paymentMethods.indexOf(method) > -1;
        };
      }
    },

    mounted() {
      if (!this.$order.tableNumber || this.invoices.length === 0) {
        this.goToInvoices();
      }
      this.paymentAmount = this.total.totalRemaining;
      this.forceFirstOnlinePaymentMethod();
      PaymentProcessor.initializePaymentMethod();
      EventBus.$on("submit-payment", () => {
        this.submit();
      });
    },

    beforeDestroy() {
      PaymentProcessor.destroyPayfacto();
      EventBus.$off("submit-payment");
    },

    watch: {
      "user.email"(value) {
        this.user.email = value.replace(/ +/g, "");
      },
      "PaymentProcessor.paymentMethod": function(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.$nextTick(() => {
            this.forceFirstOnlinePaymentMethod();
          });
        }
      }
    },

    methods: {
      ...mapActions(["getQrPaymentInvoice", "getQrPaymentInvoices"]),

      back() {
        if (this.step === 2) {
          this.step = 1;
        } else {
          this.goToInvoices();
        }
      },
      goToInvoices() {
        this.$router.push({ path: this.$router.generate("/table/invoices"), query: this.$route.query });
      },
      goToHome() {
        this.$router.push({ path: this.$router.generate("/table"), query: this.$route.query });
      },
      goToErrorPage() {
        this.$router.push(this.$router.generate("/table/payment-not-completed"));
      },
      goToCheckUnavailableErrorPage() {
        this.$router.push(this.$router.generate("/table/check-not-available"));
      },
      goToCheckReloadErrorPage() {
        this.$router.push({ path: this.$router.generate("/table/check-reload"), query: this.$route.query });
      },
      async goToPaymentStep() {
        let valid = await this.validateInvoices();
        if (!valid) {
          return;
        }
        this.showConfirmationModal = false;
        this.step = 2;
        this.$nextTick(() => {
          if (PaymentProcessor.isPaymentMethod("nmi")) {
            PaymentProcessor.initializeNmi();
          }
        });
      },
      sameInvoiceAmount(total1, total2) {
        let amount1 = parseFloat(total1 || 0);
        let amount2 = parseFloat(total2 || 0);
        return amount1 === amount2;
      },
      isPaymentTotalEnoughForInvoicesTotal(invoices) {
        let paymentTotal = parseFloat(this.total.total + 0.01); //Add 0.01 to avoid rounding issues
        let invoiceTotal = parseFloat(invoices.reduce((sum, invoice) => sum + invoice.total, 0).toFixed(2));
        return paymentTotal >= invoiceTotal;
      },
      async validateSelectedInvoicesWithPaymentTotal(invoices) {
        showSpinner();
        let response = await this.getQrPaymentInvoices({
          company: this.company.nameCanonical,
          table: this.tableNumber,
          invoices: invoices.map(invoice => invoice.id)
        });
        hideSpinner();
        if (!response || !response.success || !response.invoices) {
          this.goToCheckUnavailableErrorPage();
          return false; //invalid
        }

        //TODO: Only update invoice id if current id is a seat number (<100)
        let index = 0;
        for (let finalInvoice of response.invoices) {
          this.invoices[index].id = finalInvoice.id;
          index++;
        }

        if (this.invoices.length !== response.invoices.length) {
          this.goToCheckReloadErrorPage();
        }

        if (!this.isPaymentTotalEnoughForInvoicesTotal(response.invoices)) {
          this.goToCheckReloadErrorPage();
          return false; //invalid
        }
        return true; //valid
      },
      async validateInvoice(invoiceToValidate, invoiceNumber) {
        showSpinner();
        let response = await this.getQrPaymentInvoice({
          company: this.company.nameCanonical,
          table: this.tableNumber,
          invoice: invoiceNumber
        });
        hideSpinner();
        if (!response || !response.success) {
          if (response.status && response.status === 405) {
            return true; //consider it to be valid, operation is not supported on Veloce POS
          }
          this.goToCheckUnavailableErrorPage();
          return false; //invalid
        }
        let responseInvoice = response.invoice?.invoices?.Invoice;
        if (!responseInvoice || !responseInvoice.total) {
          this.goToCheckUnavailableErrorPage();
          return false; //invalid
        }
        if (!this.sameInvoiceAmount(invoiceToValidate.total, responseInvoice.total)) {
          this.goToCheckReloadErrorPage();
          return false; //invalid
        }
        return true; //valid
      },
      async validateInvoices() {
        if (this.invoiceNumber && this.invoices.length > 0) { //single invoice scenario TODO: Still supported?
          return this.validateInvoice(this.invoices[0], this.invoiceNumber);
        } else { //multiple invoices scenario
          return this.validateSelectedInvoicesWithPaymentTotal(this.invoices);
        }
      },
      async continueToValitorPayment() {
        if (!this.validateEmail()) {
          showAffirmation(this.$t("qr_payment.payment.invalid_email_title"), this.$t("qr_payment.payment.invalid_email_text"));
          return;
        }
        await this.validatePayment();
        if (PaymentProcessor.readyForPayment) {
          PaymentProcessor.payWithValitor({ $iFrameRef: this.$refs.valitorIframe });
        }
      },
      forceFirstOnlinePaymentMethod() {
        if (!this.company) {
          return;
        }
        PaymentProcessor.paymentMethod = this.company.getFirstOnlinePaymentMethod();
      },
      async validatePayment(callback) {
        PaymentProcessor.readyForPayment = await this.validateReadyForPayment();
        if (callback && typeof callback === "function") {
          callback();
        }
      },
      async onCompleteClick() {
        if (!this.validateEmail()) {
          showAffirmation(this.$t("qr_payment.payment.invalid_email_title"), this.$t("qr_payment.payment.invalid_email_text"));
          return;
        }

        if (PaymentProcessor.isPaymentMethod("payfacto")) {
          if (await this.validateReadyForPayment()) {
            if (PaymentProcessor.usePayFactoSHC) {
              PaymentProcessor.sendSubmitForPayFactoSHC();
            } else {
              PaymentProcessor.showPayfactoModal();
            }
          }
        } else if (PaymentProcessor.isPaymentMethod("nmi") && PaymentProcessor.saveCardEnabled() && PaymentProcessor.savedCardID) {
          if (await this.validateReadyForPayment()) {
            PaymentProcessor.submitNmiWithSavedCard();
          }
        } else {
          this.submit();
        }
      },
      async validateReadyForPayment() {
        if ((this.$order.tip.amount == null || this.$order.tip.amount == "") && this.canTip && this.shouldHideNoneTipOption) {
          await showAffirmation(this.$t("payment.tip"), this.$t("payment.tip_missing"));
          return false;
        }
        if (!this.validateEmail()) {
          showAffirmation(this.$t("qr_payment.payment.invalid_email_title"), this.$t("qr_payment.payment.invalid_email_text"));
          return false;
        }

        if (PaymentProcessor.isPaymentMethod("payfacto") && !PaymentProcessor.savedCardID
            && PaymentProcessor.usePayFactoSHC && !PaymentProcessor.payFactoSHCReadyForPayment) {
          showAffirmation(this.$t("payment.payment"), this.$t("invalid_form"));
          return false;
        }

        if (!this.$validate.valid()) {
          this.$validate.validateAllOnce();
          if (this.$refs.paymentForm) {
            this.$refs.paymentForm.$forceUpdate();
          }
          this.$forceUpdate();
          return false;
        }
        return true;
      },
      async submit() {
        if (await this.validateReadyForPayment()) {
          let response = await PaymentProcessor.processPayment();
          if (!response) {
            hideSpinner();
            showAffirmation(this.$t("error.oops"), this.$t("payment.qr_pay_submit_sale"));
            return;
          }

          if (PaymentProcessor.paymentMethod || this.$order.getOrderTotal().totalRemaining === 0) {
            let response;
            if (this.$order.getTableNumber() === DEMO_TABLE) {
              response = { success: true };
            } else {
              response = await this.$order.sendInvoicePayment();
            }

            if (response.success) {
              hideSpinner();
              // Redirect after success
              this.$router.push(this.$router.generate("/table/confirmation"));
            } else {
              if (response.status == 409) {
                console.warn("Duplicated invoice submitted. No action taken.");
                hideSpinner();
                return;
              }

              hideSpinner();
              PaymentProcessor.removePayment();
              let responseData = Util.parseResponseError(response.response);
              if (responseData && responseData.key && responseData.key === "cannot_process_payment") {
                //Pre-auth failed, can try payment again
                showAffirmation(this.$t("error.oops"), this.$t("qr_payment.payment.pre_auth_failed"));
              } else {
                //Redirect after error
                this.goToErrorPage();
              }
            }
          } else {
            hideSpinner();
          }
        }
      },
      validateEmail() {
        if (this.user.email) {
          return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.user.email)
        }
        return false;
      },
      displayConfirmationModal() {
        if (!this.validateEmail()) {
          showAffirmation(this.$t("qr_payment.payment.invalid_email_title"), this.$t("qr_payment.payment.invalid_email_text"));
          return;
        }
        this.showConfirmationModal = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    .payment-total__holder {
      background-color: white;
      border-bottom: 1px solid #e6e6e6;
      padding: 15px;
      margin-bottom: 15px;
    }
    .payment__container {
      overflow: auto;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    hr {
      margin: 10px 0;
    }
    .payment-total__info {
      display: flex;
      justify-content: space-between;
    }
    .info__tax, .info__service, .info__tip, .info__promotion, .info__email {
      .total-info__label, .total-info__value {
        color: #808080;
      }
    }
    .info__subtotal, .info__total {
      .total-info__label, .total-info__value {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .info__email {
      border-top: 2px dashed #e6e6e6;
      padding-top: 10px;
      margin-top: 10px;
    }
    .info__invoice {
      .total-info__label, .total-info__value {
        color: var(--primary-color) !important;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .hr--dashed {
      border-top: 2px dashed #e6e6e6;
    }
    .button--pay {
      background-color: var(--primary-color);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 14px 20px;
      flex-shrink: 0;
      font-size: 20px;
    }
    .payment-form__holder {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      padding: 15px;
      /*flex-grow: 1;*/
    }
    .form__title {
      display: flex;
      align-items: center;
      fill: var(--primary-color);
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 15px;
      margin-bottom: 20px;
    }
    .form-title__icon {
      margin-right: 15px;
    }
    .form-title__text {
      font-weight: bold;
      font-size: 18px;
    }
    .form__button-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
      padding-top: 10px;
    }
    .form__email {
      padding: 10px 0;
    }
    .input-group__label {
      display: block;
      color: #808080;
      font-size: 14px;
      padding: 0 0 8px 8px;
    }
    .input-group__input {
      border-radius: 4px;
      background-color: transparent;
      margin-bottom: 8px;
      font-size: 16px;
      letter-spacing: 1px;
      width: 100%;
      max-height: 40px;
    }
  }
  $height: 1600px;
  .valitor-payment {
    height: $height;
  }
  .valitor-iframe {
    width: 100% !important;
    height: $height !important;
    border: 1px solid #f2f2f2;
    background-color: white !important;
  }
  .locked {
    opacity: 0.6;
    pointer-events: none;
  }

  .email__warning {
    margin: 0 0 25px 0;
    padding-left: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    opacity: 0.8;

    .svgicon {
      flex-shrink: 0;
      fill: var(--primary-color);
      margin-right: 10px;
    }
  }

  #paymentStep__confirmation-modal {
    .modal-content {
      padding: 40px;
      border-radius: 10px;
      max-width: 310px;
    }
    .modal-body {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
    }
    .modal-body__description {
      text-align: center;
    }
    .modal-footer {
      border-top: 2px dashed #e6e6e6;
      padding-top: 30px;
      margin-top: 25px;
    }
    .button--back {
      padding: 10px 30px;
    }
    .button--confirm {
      padding: 10px 30px;
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #qr-payment__payment {
    .payment-form__holder form.payment .online-payment {
      display: none;
    }
    .tip-holder .input {
      padding-top: 0;
    }
    .amount-input:before {
      bottom: 24px !important;
    }
  }
</style>
