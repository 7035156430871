<template>
  <div class="modifier-group-requirements" :id="'modifierGroupRequirements-' + modifierGroup.id"
       :class="{invalid: modifierGroup && !modifierGroup.validateQuantitySelected().valid}"
       v-show="modifierGroup && modifierGroup.hasQuantityRequirements()">
    <template v-if="modifierGroup.exactQuantityRequired()">
      <span class="requirement">
        <svgicon src="icons/info.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() !== modifierGroup.exactQuantityRequired()"></svgicon>
        <svgicon class="check" src="icons/check-circle.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() === modifierGroup.exactQuantityRequired()"></svgicon>
        {{$t('modifier.exact_quantity_required')}} {{modifierGroup.exactQuantityRequired()}}
      </span>
    </template>
    <template v-else>
      <template v-if="modifierGroup.minimumRequired()">
        <span class="requirement">
          <svgicon src="icons/info.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() < modifierGroup.minimumRequired()"></svgicon>
          <svgicon class="check" src="icons/check-circle.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() >= modifierGroup.minimumRequired()"></svgicon>
          {{$t('modifier.minimum_quantity_required')}} {{modifierGroup.minimumRequired()}}
        </span>
      </template>
      <template v-if="modifierGroup.maximumAllowed()">
        <span class="requirement">
          <svgicon src="icons/info.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() > modifierGroup.maximumAllowed()"></svgicon>
          <svgicon class="check" src="icons/check-circle.svg" :height="14" :width="14" v-if="modifierGroup.quantitySelected() <= modifierGroup.maximumAllowed()"></svgicon>
          {{$t('modifier.maximum_quantity_allowed')}} {{modifierGroup.maximumAllowed()}}
        </span>
      </template>
    </template>
    <span class="current" v-if="!modifierGroup.validateQuantitySelected().valid && modifierGroup.quantitySelected() > 0">
      {{$t('modifier.currently_selected')}}: {{ modifierGroup.quantitySelected()}}
    </span>
  </div>
</template>

<script>
  export default {
    name: "modifierGroupInfo",

    props: {
      modifierGroup: Object
    }

  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.pos .modifier-group-requirements {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 10px;
    padding-top: 0;
    font-size: 18px;

    .requirement .svgicon {
      margin-right: 10px;
    }

    .requirement:not(:last-child) {
      $spacing: 12px;
      padding: 0 $spacing 0 0;

      &:after {
        content: "";
        height: 14px;
        width: 1px;
        background-color: #ccc;
        display: block;
        margin-left: $spacing;
      }
    }
  }
  #iShopFoodApp.kiosk .modifier-group-requirements {
    font-size: 18px;
    font-weight: bold;
  }

  #iShopFoodApp .modifier-group-requirements {
    text-align: center;
    font-size: 14px;
    font-style: italic;
    width: 100%;
    padding: 15px 0;

    .requirement {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;

      .svgicon {
        margin-right: 5px;
        flex-shrink: 0;
        &.check {
          fill: #228439;
        }
        &:not(.check) {
          fill: #ed2937;
        }
      }

    }

    .current {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp .modifier-group-requirements {
      padding-top: 30px;
    }
  }
</style>
