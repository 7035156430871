/**
 * @param {string} name
 * @throws Error
 */
export const throwUnimplementedMethodError = (name) => {
  throw new Error(`Abstract method '${name}' must be implemented.`);
};

/**
 * @param {string} name
 * @throws Error
 */
export const throwAbstractClassError = (name) => {
  throw new Error(`Abstract class '${name}' cannot be instantiated.`);
};