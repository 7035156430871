<template>
  <div id="promoModal" class="modal" :class="{show: show}">
    <div id="promotionModal" v-if="modal" class="modal-content"
         :class="{
             mobile: $store.getters.isMobile,
             imageTop: modal.configuration.imagePosition === 'top',
             imageLeft: modal.configuration.imagePosition === 'left',
             }">
      <div class="close" @click="close">
        <svgicon src="icons/close.svg" :width=20 :height=20></svgicon>
      </div>
      <div class="image" v-if="modal.image" :style="{backgroundImage: imagePath(modal.image) }">&nbsp;</div>
      <div class="content">
        <div class="modal-header">
          <svgicon src="icons/promo.svg" :height="18" :width="16"></svgicon>
          <template v-if="modal.modalTitle.fr || modal.modalTitle.en">{{ $to(modal.modalTitle) }}</template>
          <template v-else>{{$t("promotion.enjoy")}}</template>
        </div>
        <div class="content-title" v-if="modal.promotionTitle.fr || modal.promotionTitle.en">
          {{ $to(modal.promotionTitle) }}
        </div>
        <div class="content-description" v-if="modal.description.fr || modal.description.en">
          {{ $to(modal.description) }}
        </div>
        <div class="promos">
          <div class="promo" v-for="(promo, promoIndex) in promotions" @click="goToPromotion(promo)" :key="'promo' + promoIndex">
            <div class="promo-image" v-if="promo.image" :style="{backgroundImage: imagePath(promo.image) }"></div>
            <div class="promo-content">
              <div class="promo-name">{{$to(promo.name)}}</div>
              <div class="promo-description">{{$to(promo.description)}}</div>
            </div>
            <div class="view-promo">
              <svgicon src="icons/arrow-right.svg" :height="22" :width="22"></svgicon>
            </div>
          </div>
          <div class="promo" v-for="(link, linkIndex) in links" :key="'link' + linkIndex" v-if="links" @click="goToLink(link)">
            <div class="promo-content">
              <div class="promo-name">{{$to(link.title)}}</div>
              <div class="promo-description">{{$to(link.description)}}</div>
            </div>
            <div class="view-promo">
              <svgicon src="icons/arrow-right.svg" :height="22" :width="22"></svgicon>
            </div>
          </div>
        </div>
        <div class="back-to-menu">
          <button class="btn btn-secondary" @click="close">{{$t("back_to_menu")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "../lib/eventBus";
  import { PromotionManager } from "../PromotionManager";

  export default {
    name: "promotionModal",

    beforeDestroy() {
      EventBus.$off("show-promotion-modal");
      EventBus.$off("hide-promotion-modal");
    },

    mounted() {
      EventBus.$on("show-promotion-modal", (modal) => {
        if (this.$store.state.promotionModalDisplayed) {
          this.show = false;
          return;
        }
        if (this.$kiosk) {
          let safeRoutes = this.$store.getters.menuRouteNames;
          if (safeRoutes.indexOf(this.$route.name) === -1) {
            this.show = false;
            return;
          }
        }
        this.$store.state.promotionModalDisplayed = true;

        displayAfterVisibleModalsAreClosed(() => {
          this.modal = modal;
          this.$store.state.promotionModalDisplayed = true;
          this.show = true;
        });
      });

      EventBus.$on("hide-promotion-modal", () => {
        this.show = false;
      });
    },

    data() {
      return {
        modal: null,
        show: false,
        PromotionManager
      }
    },

    computed: {
      links() {
        if (!this.modal) {
          return [];
        }
        let links = this.modal.links;
        if (this.$kiosk) {
          links = links.filter(l => l.isInternal);
        }
        return links;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      promotions() {
        if (!this.modal) {
          return [];
        }
        let data = [];
        for (let promotionId of this.modal.promotions) {
          let promotion = PromotionManager.getPromotionWithId(promotionId);
          if (promotion) {
            data.push(promotion);
          }
        }
        return data;
      }
    },

    methods: {
      imagePath(image) {
        return "url('" + this.$store.state.urlFileServer + image.url + "')";
      },

      goToPromotion(promo) {
        if (this.$kiosk) {
          return;
        }
        this.$router.push(this.$router.generate("/promotions"));
        this.close();
      },

      setBranding(brandingId) {
        if (brandingId) {
          let branding = this.inventory.getBranding(brandingId);
          if (branding) {
            this.$order.setBranding(branding);
          }
        }
      },

      goToLink(link) {
        if (link.isInternal) {
          let path;
          /* redirect to item */
          if (link.url === "/item/" && link.item && link.item.id) {
            path = "/item/" + link.item.id;

            /* check if correct branding */
            if (this.inventory.isBrandingActivated()) {
              let item = this.inventory.search(link.item.id);
              if (item) {
                let itemFirstCategory = item.categories[0];
                this.setBranding(itemFirstCategory ? itemFirstCategory.branding : null);
              }
            }

          /* redirect to category */
          } else if (link.url === "/menu/" && link.category && link.category.id) {
            /* check if correct branding */
            if (this.inventory.isBrandingActivated()) {
              let category = this.inventory.getCategory(link.category.id);
              if (category) {
                this.setBranding(category.branding);
              }
            }

            /* quick mode */
            if (this.$store.state.quickMode) {
              if (this.$router.currentRoute.name === "menu") { // if already on menu page, then only open collapse and no redirect
                EventBus.$emit("open-category", link.category.id);
                this.close();
                return;
              } else { // if not already on menu page, redirect to menu page and then open collapse
                path = "/menu";
                setTimeout(() => {
                  EventBus.$emit("open-category", link.category.id);
                }, 500);
              }
            /* not quick mode */
            } else {
              path = link.url + link.category.id;
            }
          /* any other internal link */
          } else {
            path = link.url;
          }
          this.$router.push(this.$router.generate(path));
          this.close();
        /* external link */
        } else {
          if (this.$kiosk) {
            return;
          }
          if (link.url.indexOf("http") === -1) {
            link.url = "http://" + link.url;
          }
          window.openExternalWebsite(link.url);
        }
      },

      close() {
        this.show = false;
        setTimeout(() => {
          this.modal = null;
        }, 300);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .modal #promotionModal {
    max-height: calc(100% - 50px);
  }
  #iShopFoodApp .modal #promotionModal.modal-content {
    display: flex;
    width: 100%;
    padding: 0;

    &.mobile {
      margin: 10px;
      &.imageTop .image {
        height: 200px;
      }
      &.imageLeft {
        .image {
          width: 100px;
        }
        .modal-header {
          display: none;
        }
        .content-title {
          padding-right: 30px;
        }
      }
      .content-title {
        font-size: 17px;
        line-height: 1.1;
      }
      .content-description {
        font-size: 14px;
      }
      .back-to-menu {
        .btn {
          font-size: 14px;
          padding: 8px 15px;
        }
      }
      .promo {
        min-height: 55px;
        .view-promo {
          width: 30px;
        }
        .promo-content {
          padding: 5px 0 5px 10px;

          .promo-name {
            font-size: 15px;
          }
          .promo-description {
            font-size: 13px;
          }
        }
      }
    }

    &.imageLeft {
      max-width: 700px;

      .image {
        width: 200px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    &.imageTop {
      flex-direction: column;
      max-width: 500px;

      .image {
        height: 25vh;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .modal-header {
        display: none;
      }
    }

    .image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      flex-shrink: 0;
    }

    .content-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .content-description {
      font-size: 18px;
      margin-bottom: 15px;
      flex-grow: 1;
    }

    .promotions {
      border-top: 1px dashed #ccc;
    }

    .content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;

      .svgicon {
        margin-right: 10px;
        fill: #ccc;
      }
    }

    .back-to-menu {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px dashed #ccc;
    }

  }

  #iShopFoodApp.kiosk .modal #promotionModal.modal-content {
    border-radius: 20px;
    max-width: 800px;

    .modal-header {
      display: none;
    }

    .close {
      display: none;
    }

    .content-title {
      font-size: 32px;
    }
    .content-description {
      font-size: 16px;
      margin-bottom: 40px;
    }

    &.imageLeft .image {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 250px;
    }

    &.imageTop .image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .content {
      padding: 40px;
    }

    .back-to-menu {
      padding-top: 40px;
      margin-top: 40px;
      justify-content: center;
    }

    .promo {
      border-radius: 20px;
      margin-right: 10px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .promo-content {
        padding: 40px;
      }

      .promo-name {
        font-size: 18px;
      }
      .promo-description {
        font-size: 16px;
      }

      .view-promo {
        display: none;
      }
    }
  }

  .promos {
    overflow-y: auto;
    max-height: 400px;
  }

  .promo {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 80px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .promo-content {
      padding: 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .promo-name {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .promo-description {
      font-size: 14px;
      color: #5c5c5c;
    }

    .promo-image {
      width: 80px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }

    .view-promo {
      fill: var(--primary-color);
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    .svgicon {
      fill: #bfbfbf;
    }
  }

  .view-promo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
  }
</style>
