<template>

  <div class="pageTitle" :class="{desktop: isDesktop, quick: $store.state.quickMode,
  subHeader: horizontalLogo && showHorizontalLogoInMobileHeader, mobile: $store.getters.isMobileOrTablet}">

    <template v-if="quickAdd">
      <div class="toggleMenu">
        <svgicon src="icons/back.svg" :width=24 :height=24 @click="$emit('close')"></svgicon>
      </div>
    </template>

    <template v-if="!quickAdd">
      <svgicon class="toggleMenu navToggle" src="icons/nav.svg" :width=60 :height=60
               v-if="$store.state.quickMode && isDesktop" @click="showQuickNavigation"></svgicon>
      <div class="toggleMenu" v-show="(!showBackButton || isDesktop) && showNonQuickToggle" @click="toggleMobileMenu">
        <svgicon src="icons/nav.svg" :width="24" :height="24"></svgicon>
      </div>
      <div class="toggleMenu" v-show="showBackButton && !isDesktop" @click="back">
        <svgicon src="icons/back.svg" :width="24" :height="24"></svgicon>
      </div>
    </template>

    <template v-if="!showHorizontalLogoInMobileHeader || !horizontalLogo">
      <div class="flex-grow slot-space">
        <slot></slot>
      </div>
    </template>
    <template v-if="horizontalLogo && showHorizontalLogoInMobileHeader">
      <div class="flex-grow slot-space">
        <img class="mobile-logo" :src="horizontalLogo">
      </div>
    </template>

    <div class="toggleMenu" :class="{invisible: !$store.state.showNavigationCart || isDesktop || $store.state.displayMode}" @click="toggleMobileCart">
      <svgicon src="icons/bill.svg" :width="24" :height="24"></svgicon>
      <div id="itemCountPill" ref="pill" v-if="orderItemCount > 0">{{orderItemCount}}</div>
    </div>

    <div class="mobile-sub-header" v-if="horizontalLogo && showHorizontalLogoInMobileHeader">
      <div class="flex-grow">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>

  import EventBus from "@/lib/eventBus.js";

  export default {

    props: {
      quickAdd: Boolean,
      showBackButton: Boolean
    },

    watch: {
      orderItemCount(newValue, oldValue) {
        if (newValue !== oldValue && this.$refs.pill) {
          this.$refs.pill.classList.add("poof");
          setTimeout(() => {
            if (this.$refs.pill) {
              this.$refs.pill.classList.remove("poof");
            }
          }, 500);
        }
      },
      "$order.branding"() {
        this.$forceUpdate();
      }
    },

    methods: {
      back() {
        window.history.back();
      },
      toggleMobileMenu() {
        this.$store.state.mobileMenuShown = !this.$store.state.mobileMenuShown;
      },
      toggleMobileCart() {
        this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
      },
      showQuickNavigation() {
        EventBus.$emit("show-quick-navigation");
      }
    },

    computed: {
      orderItemCount() {
        return this.$order.getItemCount();
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      horizontalLogo() {
        if (!this.branch || !this.branch.horizontalLogo) {
          return;
        }
        if (this.$order.branding && this.$order.branding.horizontalLogo) {
          return this.$store.state.urlFileServer + this.$order.branding.horizontalLogo.url;
        }
        if (this.$order.branding && this.$order.branding.logo) {
          return this.$store.state.urlFileServer + this.$order.branding.logo.url;
        }
        return this.$store.state.urlFileServer + this.branch.horizontalLogo.url;
      },
      showHorizontalLogoInMobileHeader() {
        return !this.quickAdd && this.$store.state.screenSize === "xs" && this.branch && this.branch.style && this.branch.style.horizontalLogoInMobileHeader;
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      showNonQuickToggle() {
        if (this.$store.state.quickMode) {
          return !this.isDesktop;
        }
        return this.$store.state.screenSize != "lg";
      }
    }

  }

</script>

<style lang="scss" scoped>

  .pageTitle {
    height: 60px;
    background-color: #ed2937;
    color: white;
    fill: white;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-weight: 500;
    flex-shrink: 0;
    font-size: 16px;
    text-align: center;
    position: relative;
    &.quick.desktop {
      height: 100px;
    }

    &.quick {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 0px;
        height: calc(100% - 40px);
      }
    }
  }

  .toggleMenu {
    padding: 18px;
    cursor: pointer;
    fill: rgb(237,134,115);
    position: relative;
  }

  .icon {
    fill: white;
    margin-right: 12px;
  }

  .select {
    background-color: #c7222d;
    fill: #fc6650;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 10px 28px 10px 10px;
    border-radius: 5px;
    margin-left: 10px;
    // min-width: 140px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;

    .selected {
      flex-grow: 1;
      padding-left: 10px;
    }
    &.fake {
      padding-right: 10px;
      width: calc(100% - 10px);
      cursor: auto;
    }
  }

  .flex-grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
    color: white;
  }

  #itemCountPill {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 13px;
    min-width: 20px;
    min-height: 20px;
    font-weight: bold;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 10px;
    color: #ed2937;
    background-color: #ed8673;
    //box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 3px 0;
  }

  #iShopFoodApp.xs {
    .pageTitle {
      justify-content: space-between;
      padding: 0;
      font-weight: normal;

      .flex-grow {
        justify-content: center;
      }
    }
    .icon {
      display: none;
    }
  }

  .navToggle {
    padding: 20px;
    margin: -20px;
    cursor: pointer;
    position: relative;
    margin-right: 58px;
    &:after {
      top: 0;
      bottom: 0;
      left: 80px;
      content: "";
      position: absolute;
      width: 0;
    }
  }

  .invisible {
    opacity: 0;
    pointer-events: none;
  }

  .mobile-logo {
    max-width: calc(100% - 100px);
    margin-top: 2px;
    max-height: 30px;
  }

  #iShopFoodApp.xs .pageTitle.subHeader {
    margin-bottom: 30px;

    .mobile-sub-header {
      background-color: #fc6650;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;

      .breadcrumb.mobile a {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
</style>

<style lang="scss">

  #iShopFoodApp .pageTitle.quick .flex-grow {
    & > .svgicon {
      display: none;
    }
  }

</style>
