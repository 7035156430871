<template>
  <div class="modifierQuantityModal modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-body" v-if="modifier">
        <AsyncImage class="modifier--image" v-if="modifier.image" :fallback="$store.state.defaultItemImage" :image="$store.state.urlFileServer + modifier.image"></AsyncImage>
        <div class="modifier--content">
          <div class="modifier--name">{{$to(modifier.name)}}</div>
          <v-clamp class="modifier--description" :max-lines="2">{{ $to(modifier.description) }}</v-clamp>
          <div class="modifier--price" v-if="ref">{{ ref.pricePrefix }} {{ $tc(ref.selectorPrice) }}</div>
          <label class="modifier--quantity-label" v-if="$pos">{{ $t('quantity') }}</label>
          <QuantitySelector :key="modifier.quantity" large ref="modalQuantitySelector" :value="modifier.quantity" @changed="quantityChanged($event)"></QuantitySelector>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary flat" @click="cancelQuantityChange" @contextmenu="cancelQuantityChange">{{$t('cancel')}}</button>
        <button class="btn btn-primary flat" @click="applyQuantityChange" @contextmenu="applyQuantityChange">{{$t('apply')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage";
  import QuantitySelector from "@/components/tools/QuantitySelector";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "ModifierQuantityModal",

    components: { AsyncImage, QuantitySelector },

    data() {
      return {
        modifier: null,
        ref: null,
        uuid: null,
        previousQuantity: 0,
        show: false
      };
    },

    mounted() {
      EventBus.$on("show-modifier-quantity-modal", ({modifier, ref, uuid}) => {
        this.modifier = modifier;
        this.ref = ref;
        this.uuid = uuid;
        this.previousQuantity = modifier.quantity;
        if (this.modifier.quantity == 0) { // Auto initialize to 1 to remove a click for the user. This is done after 'previousQuantity' is set so that 'cancel' will revert to 0
          this.modifier.quantity = 1;
        }
        this.$nextTick(() => {
          this.show = true;
        });
      });
      EventBus.$on("hide-modifier-quantity-modal", () => {
        this.cancelQuantityChange();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-modifier-quantity-modal");
      EventBus.$off("hide-modifier-quantity-modal");
    },

    methods: {
      applyQuantityChange() {
        EventBus.$emit("modifier-quantity-modal-changed", { quantity: this.modifier.quantity, uuid: this.uuid });
        this.show = false;
      },
      quantityChanged(quantity) {
        this.modifier.quantity = quantity;
      },
      cancelQuantityChange() {
        if (this.modifier) {
          this.modifier.quantity = this.previousQuantity;
        }
        this.previousQuantity = 0;
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
#iShopFoodApp.pos .modifierQuantityModal {
  .modal-content {
    border-radius: 25px;
    padding: 25px;
    max-width: 460px;

    .modal-footer {
      padding-top: 20px;
      margin-top: 20px;

      .btn {
        width: auto;
        height: 44px;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .modifier--name {
    font-size: 20px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .modifier--price {
    font-weight: bold;
    font-size: 16px;
  }
  .modifier--quantity-label {
    margin-bottom: 7px;
    font-size: 14px;
    color: #808080;
    display: block;
  }
  .modifier--image {
    width: 150px;
    height: 150px;
    border-radius: 15px;
  }
}
#iShopFoodApp .modifierQuantityModal {
  .modal-content {
    border-radius: 20px;
    padding: 40px;
    max-width: 525px;

    .modal-footer {
      padding-top: 40px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 40px;
      .btn {
        padding: 23px 40px;
        width: 48%;
      }
    }
    .modal-body {
      display: flex;
      align-items: center;
    }
  }
  .modifier--image {
    height: 160px;
    width: 160px;
    flex-shrink: 0;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    margin-right: 40px;
  }
  .modifier--content {
    .modifier--name {
      font-size: 24px;
      font-weight: bold;
    }
    .modifier--description {
      font-size: 18px;
      color: #808080;
      margin-bottom: 5px;
    }
    .modifier--price {
      color: var(--primary-color) !important;
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
