import moment from "moment-timezone";
import Company from "./CompanyModel";
import Store from "../store.js";
import { LocalizeStore } from "@/lib/localize";
/**
 * @typedef BranchDefinition
 * @property {number} id
 * @property {string} name
 * @property {string} nameCanonical
 * @property {number[]} companies
 * @property {any} backgroundImage
 * @property {any} navigationLogo
 * @property {any} horizontalLogo
 * @property {any} navigationLogoBackground
 * @property {any} mobileMenuLogo
 * @property {any} modalOrderTypeImage
 * @property {any} hubImage
 * @property {any} mapMarkerImage
 * @property {any} defaultItemImage
 * @property {any} takeoutImage
 * @property {any} takeoutBackgroundImage
 * @property {any} deliveryImage
 * @property {any} deliveryBackgroundImage
 * @property {any} inPlaceImage
 * @property {any} inPlaceBackgroundImage
 * @property {any} cateringImage
 * @property {any} cateringBackgroundImage
 * @property {any} hotelImage
 * @property {any} hotelBackgroundImage
 * @property {any} spinnerAnimatedImage
 * @property {any} spinnerStaticImage
 * @property {any} homePage
 * @property {any} tutorial
 * @property {any} cssData
 * @property {boolean} eWalletActivated
 * @property {object} customLocales
 * @property {object} payments
 * @property {boolean} autocompleteOnOrderType
 * @property {boolean} autocompleteOnPayment
 * @property {boolean} messagingConsent
 * @property {boolean} aimsVouchersActivated
 */

export default class Branch {

  /**
   * @param {BranchDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.name = definition.name;
    this.nameCanonical = definition.nameCanonical;
    this.companies = /** @type {Company[]} */ ([]);
    this.aimsVouchersActivated = definition.aimsVouchersActivated;
    this.backgroundImage = definition.backgroundImage;
    this.navigationLogo = definition.navigationLogo;
    this.navigationLogoBackground = definition.navigationLogoBackground;
    this.modalOrderTypeImage = definition.modalOrderTypeImage;
    this.mobileMenuLogo = definition.mobileMenuLogo;
    this.horizontalLogo = definition.horizontalLogo;
    this.defaultItemImage = definition.defaultItemImage;
    this.takeoutImage = definition.takeoutImage;
    this.taxes = definition.taxes || [];
    this.autocompleteOnOrderType = definition.autocompleteOnOrderType;
    this.autocompleteOnPayment = definition.autocompleteOnPayment;
    this.takeoutBackgroundImage = definition.takeoutBackgroundImage;
    this.deliveryImage = definition.deliveryImage;
    this.deliveryBackgroundImage = definition.deliveryBackgroundImage;
    this.inPlaceImage = definition.inPlaceImage;
    this.inPlaceBackgroundImage = definition.inPlaceBackgroundImage;
    this.cateringImage = definition.cateringImage;
    this.cateringBackgroundImage = definition.cateringBackgroundImage;
    this.hotelImage = definition.hotelImage;
    this.hotelBackgroundImage = definition.hotelBackgroundImage;
    this.payAtTheTable = {
      activated: definition.payAtTheTable ? definition.payAtTheTable.activated : false,
      logo: definition.payAtTheTable ? definition.payAtTheTable.logo : null,
      backgroundImage: definition.payAtTheTable ? definition.payAtTheTable.backgroundImage : null,
    };
    this.spinnerAnimatedImage = definition.spinnerAnimatedImage;
    this.spinnerStaticImage = definition.spinnerStaticImage;
    this.extendedProfileFields = definition.extendedProfileFields || [];
    this.hubImage = definition.hubImage;
    this.homePage = definition.homePage;
    this.tutorial = definition.tutorial;
    this.mapMarkerImage = definition.mapMarkerImage;
    this.mobileOrderTypeImage = definition.mobileOrderTypeImage;
    this.style = definition.cssData;
    this.shutdownSchedules = definition.shutdownSchedules || [];
    this.onlineOrdering = definition.onlineOrdering;
    this.paymentMethods = definition.payments;
    this.eWalletActivated = definition.eWalletActivated;
    this.messagingConsent = definition.messagingConsent;
    this.loyaltyConfig = definition.loyaltyConfig; // TODO this could be made into a class with a model. Move a bunch of functions into that class.
    this.analytics = definition.analytics;
    this.userManagementEnabled = definition.userManagement;
    this.styleMode = null;
    this.quickAdd = false;
    this.interactiveMode = false;
    this.dispatch = definition.dispatch;
    this.customLocales = definition.customLocales;
    this.hubAlreadyAccessed = false;
    this.emailValidationRequired = definition.emailValidationRequired;
    this.locale = definition.locale;

    this.checkIfIgnoreLoyaltyConfigs();
  }

  /**
   * @param {string} method
   * @param {boolean} includeThirdParty
   */
  hasOrderMethod(method, includeThirdParty) {
    for (let company of this.companies) {
      if (company.hasOrderMethod(method)) {
        return true;
      } else if (includeThirdParty && method === "delivery" && company.hasThirdPartyDelivery()) {
        return true;
      }
    }
    return false;
  }

  deliveryOnlyViaThirdParty(method) {
      if (method == "delivery" && !this.hasOrderMethod(method, false) && this.hasOrderMethod(method, true)) {
        return true;
      }
      return false;
  }

  getOrderMethodsAvailable() {
    let methods = [];
    for (let company of this.companies) {
      methods = methods.concat(company.getOrderMethodsAvailable());
    }
    return methods.filter((v, i) => methods.indexOf(v) === i);
  }

  hasAnonymousLogin() {
    return !!this.onlineOrdering.default.general.anonymousLogin;
  }

  isEmailValidationRequired() {
    return this.emailValidationRequired;
  }

  isReorderFromHistoryActivated() {
    return this.onlineOrdering && this.onlineOrdering.default && this.onlineOrdering.default.reorderFromHistoryActivated;
  }

  getAboutText() {
    if (!this.onlineOrdering || !this.shouldDisplayAboutOnlineOrdering()) {
      return;
    }
    return this.onlineOrdering.about;
  }

  shouldDisplayAboutOnlineOrdering() {
    return this.style && this.style.displayAboutOnlineOrdering;
  }

  shouldForceQuickAddModal() {
    return this.style && this.style.forceQuickAdd;
  }

  shouldHideCookiesPolicyBanner() {
    return this.onlineOrdering && this.onlineOrdering.hideCookiesPolicyBanner;
  }

  hasZones() {
    for (let company of this.companies) {
      if (company.hasZones()) {
        return true;
      }
    }
    return false;
  }

  enableMapOnCompanyDetails() {
    if (!this.onlineOrdering.default.geocode) {
      return false;
    }
    return this.onlineOrdering.default.geocode.enableMapOnCompanyDetails;
  }

  enableMapOnCompanySelection() {
    if (!this.onlineOrdering.default.geocode) {
      return false;
    }
    return this.onlineOrdering.default.geocode.enableMapOnCompanySelection;
  }

  getMapsService() {
    if (!this.onlineOrdering.default.geocode) {
      return "google";
    }
    return this.onlineOrdering.default.geocode.mapsService;
  }

  getGeocodeService() {
    if (!this.onlineOrdering.default.geocode) {
      return "google";
    }
    return this.onlineOrdering.default.geocode.geocodeService;
  }

  getAutocompleteService() {
    if (!this.onlineOrdering.default.geocode) {
      return "here";
    }
    return this.onlineOrdering.default.geocode.autocompleteService;
  }

  getLevelForPoints(points) {
    if (this.loyaltyConfig.type !== "ishopfood") {
      return;
    }
    let levels = this.loyaltyConfig.data.levels;
    if (levels.length === 1) {
      return levels[0];
    }
    for (let i = 1; i < levels.length; i++) {
      if (points < levels[i].points) {
        return levels[i - 1];
      }
    }
    return levels[levels.length - 1];
  }

  getNextLevelForPoints(points) {
    let currentLevel = this.getLevelForPoints(points);
    let levels = this.loyaltyConfig.data.levels;
    let index = levels.indexOf(currentLevel);
    if (index === levels.length - 1) {
      return currentLevel;
    } else {
      return levels[index + 1];
    }
  }

  getRechargePaymentSettings(type) {
    if (!this.isiShopFoodLoyaltyProgram() || !this.loyaltyConfig.data.rechargeEnabled || !this.loyaltyConfig.data.recharge ||
      this.loyaltyConfig.data.recharge.type !== type) {
      return;
    }
    return this.loyaltyConfig.data.recharge;
  }

  isRechargeEnabled() {
    return this.isiShopFoodLoyaltyProgram() && this.loyaltyConfig.data.rechargeEnabled;
  }

  areTicketsActivated() {
    return this.aimsVouchersActivated && !CONFIG.pos;
  }

  isiShopFoodGiftCardEnabled() {
    return this.isiShopFoodLoyaltyProgram() && this.loyaltyConfig.data.giftCardActivated;
  }

  isiShopFoodGiftCardEnabledForCurrentCompany() {
    let company = Store.state.currentCompany;
    if (!company || !this.loyaltyConfig || !this.loyaltyConfig.data || !this.loyaltyConfig.data.giftCard) {
        return false;
    }
    let allowedCompanies = this.loyaltyConfig.data.giftCard.companies || [];
    return this.isiShopFoodLoyaltyProgram() && this.loyaltyConfig.data.giftCardActivated && allowedCompanies.indexOf(company.id) > -1;
  }

  getGiftCardTerms() {
    if (!this.isiShopFoodGiftCardEnabled()) {
      return null;
    }
    let giftCard = this.loyaltyConfig.data.giftCard;
    return {
      terms: giftCard.terms,
      advancedTerms: giftCard.advancedTerms
    };
  }

  /**
   * @param {moment} date
   * @param company
   */
  isShutdownScheduledForDay(date, company) {
    for (let schedule of this.shutdownSchedules) {
      if (schedule.companies.find(c => c.id === company)) {
        let start = moment(schedule.start);
        start.set({ hours: 0, minutes: 0 });
        let end = moment(schedule.end);
        end.set({ hours: 0, minutes: 0 });
        if (date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * @param {string} processor
   */
  getPaymentMethod(processor) {
    return this.getPaymentMethods().find(p => p.processor == processor);
  }

  /**
   * @param {string} method
   */
  hasPaymentMethod(method) {
    return !!this.getPaymentMethod(method);
  }

  getFirstPaymentMethod() {
    if (!this.paymentMethods || this.paymentMethods.length === 0) {
      return null;
    }
    return this.paymentMethods[0];
  }

  getPaymentMethods() {
    return this.paymentMethods || [];
  }

  isCordovaDefined() {
    return typeof cordova !== "undefined";
  }

  checkIfIgnoreLoyaltyConfigs() {
    if (this.shouldBypassAndDisableLoyaltyProgram()) {
      this.eWalletActivated = false;
      this.style.showMobileFooter = false;
      this.loyaltyConfig.data.rechargeEnabled = false;
      this.loyaltyConfig.data.eWalletActivated = false;
      this.loyaltyConfig.activated = false;
      this.style.displayFooterProgressBar = false;
    }
  }

  shouldBypassAndDisableLoyaltyProgram() {
    // loyalty program should be hidden if not on cordova
    return !this.isCordovaDefined() &&
      !!this.loyaltyConfig && this.loyaltyConfig.type === "ishopfood" && this.loyaltyConfig.data.activatedOnlyOnMobileApp;
  }

  shouldApplyLoyaltyPaymentOnSubtotal() {
    // loyalty payment should be apply as discount
    return !!this.loyaltyConfig && this.loyaltyConfig.type === "ishopfood" && this.loyaltyConfig.data.applyLoyaltyPaymentOnSubtotal;
  }

  shouldApplyLoyaltyPaymentAutomatically() {
    // loyalty payment
    return !!this.loyaltyConfig && this.loyaltyConfig.type === "ishopfood" && this.loyaltyConfig.data.applyLoyaltyPaymentAutomatically;
  }

  isiShopFoodLoyaltyProgram() {
    return !!this.loyaltyConfig && this.loyaltyConfig.type === "ishopfood" && !!this.loyaltyConfig.data;
  }

  shouldDisplayTutorial() {
    return this.canDisplayTutorial() && !getConfiguration("tutorial-completed");
  }

  hasAutoRechargeEnabled() {
    return !!this.loyaltyConfig && !!this.loyaltyConfig.data && this.loyaltyConfig.data.autoRechargeEnabled;
  }

  canDisplayTutorial() {
    return this.tutorial && this.tutorial.activated && this.isCordovaDefined();
  }

  shouldLaunchAppWithHub() {
    return this.isiShopFoodLoyaltyProgram() && this.loyaltyConfig.data.launchAppWithHub && this.isCordovaDefined() && !this.hubAlreadyAccessed;
  }

  shouldDisplayHomePage() {
    return this.homePage && this.homePage.activated && this.isCordovaDefined();
  }

  shouldReplaceCompaniesLinkByOrderTypeLink() {
    return this.style && this.style.styleMode === "classic" && this.style.replaceCompaniesLinkByOrderTypeLink;
  }

  hasCompanySearchActivated() {
    if (!this.onlineOrdering.default.geocode) {
      return false;
    }
    return this.onlineOrdering.default.geocode.companySearchActivated;
  }

  getFacebookPixelAnalytics() {
    if (this.analytics && this.analytics.length > 0) {
      return this.analytics.find(a => a.type === "pixel");
    }
  }

  getCompaniesWithMenuDisplay() {
    return this.companies.filter(c => c.menuDisplay.activated && c.menuDisplay.fromOrderMethod);
  }

  getCompanyByCanonical(canonical) {
    return this.companies.find(c => c.nameCanonical === canonical);
  }

  getFavoriteCompanyForUser(user) {
    return user.token && user.favoriteCompany && this.getCompanyByCanonical(user.favoriteCompany)
      ? this.getCompanyByCanonical(user.favoriteCompany)
      : null;
  }

  enableFavoriteCompany() {
    return this.onlineOrdering && this.onlineOrdering.enableFavoriteCompany;
  }

  getExtendedRegisterField(field) {
    return this.extendedProfileFields.find(f => f.name === field && f.visibleOnRegister);
  }

  selectOnlyAvailableCompanyWithMenuDisplayIfApplicable() {
    let companies = this.getCompaniesWithMenuDisplay();
    if (companies.length !== 1 || !this.allCompaniesHaveMenuDisplay()) {
      return;
    }
    let company = companies[0];
    if (company.getOrderMethodsAvailable().length > 1) {
      return;
    }
    this.selectCompanyAsDisplayMode(company);
  }

  allCompaniesHaveMenuDisplay() {
   return this.getCompaniesWithMenuDisplay().length === this.companies.length;
  }

  selectCompanyAsDisplayMode(company) {
    Store.state.displayMode = true;
    Store.dispatch("setCurrentCompany", company);
    Store.state.order.setMethod(company.menuDisplay.fromOrderMethod);
  }

  setDistanceFromUserForCompanies(position) {
    let userPosition = position || Store.state.userCurrentPosition;
    if (userPosition) {
      for (let company of this.companies) {
        company.calculateDistanceFromUser(userPosition.latitude || userPosition.lat, userPosition.longitude || userPosition.lng);
      }
    }
  }

  getFirstNonEmergencyClosedCompany() {
    let filtered = this.companies.filter(c => !c.emergencyClose);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return this.companies[0];
  }

  getCurrencyFromFirstCompany() {
    if (this.companies.length === 0) {
      return;
    }
    return this.companies[0].currency;
  }

  getCountryFromFirstCompany() {
    if (this.companies.length === 0) {
      return;
    }
    return this.companies[0].iso2country;
  }

  getCompaniesForMethod(method, address) {
    let filtered;

    switch (method) {
      case "delivery": {
        filtered = this.companies.filter(c => c.hasOrderMethodActivated(method) || c.hasThirdPartyDelivery());

        if (address && (address.getFormattedAddress().length > 0 || !!address.postalCode)) {
          filtered = filtered.filter(c => c.isInDeliveryZone(address));
        }
        break;
      }
      default: {
        filtered = this.companies.filter(c => c.hasOrderMethodActivated(method));
        break;
      }
    }

    if (Store.state.userCurrentPosition) {
      filtered = [...filtered];
      filtered.sort((companyA, companyB) => {
        if (companyA.distanceFromUser === 0) {
          return 1;
        }
        if (companyA.distanceFromUser < companyB.distanceFromUser) {
          return -1;
        }
        if (companyA.distanceFromUser > companyB.distanceFromUser) {
          return 1;
        }
        return 0;
      })
    }
    return filtered;
  }

  //TODO refactor this to getCompaniesLocales
  getLocales() {
    let locales = [];
    for (let company of this.companies) {
      locales = [...locales, ...company.languages];
    }
    return Array.from(new Set(locales));
  }

  getLocale() {
    return this.locale;
  }

  setDefaultLocale() {
    let companiesLocales = this.getLocales();

    if (getConfiguration("locale")) {
      LocalizeStore.commit("setLocale", getConfiguration("locale"));
    } else if (this.getLocale()) {
      LocalizeStore.commit("setLocale", this.getLocale());
    } else if (companiesLocales.length === 1) {
      LocalizeStore.commit("setLocale", companiesLocales[0]);
    }
  }

  isEnglishLocaleOnly() {
    let locales = this.getLocales();
    return locales.indexOf("en") > -1 && locales.length === 1;
  }

  getISO3Countries() {
    let countries = this.companies.map(c => c.iso3country);
    return Array.from(new Set(countries));
  }

  async logGeocoding({service, type, source}) {
    let data = {
      service: service,
      type: type
    };
    let persist = true;
    // If log is happening from profile page or payment page, then company info should be valid and used for log
    if (Store.state.currentCompany && (source === "profile" || source === "payment")) {
      data.company = Store.state.currentCompany.id;
      persist = false;
    }
    let entry = await Store.dispatch("logGeocoding", data);
    if (persist) {
      let logs = getConfiguration("pendingGeocodingLogs", sessionStorage) || [];
      entry.date = getTodayStringDate();
      logs.push(entry);
      setConfiguration("pendingGeocodingLogs", logs, sessionStorage);
    }
  }

  setHubAccessed(value = true) {
    this.hubAlreadyAccessed = value;
  }

}
