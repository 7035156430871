<template>
  <div id="grabngoMessage">
    <div class="message-container">
      <Illustration src="icons/scan-barcode.svg"></Illustration>
      <div class="message--title" v-html="$t('grabngo.title')"></div>
      <div class="message--description">{{$t('grabngo.description')}}</div>
    </div>
  </div>
</template>

<script>
  import Illustration from "@/components/modal/Illustration";
  export default {
    name: "GrabngoMessage",

    components: { Illustration }
  }
</script>

<style lang="scss" scoped>
#grabngoMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#iShopFoodApp {
  .message-container {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0 rgba(1, 2, 2, 0.15);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    justify-content: center;
  }
  .message--title {
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    border-bottom: 1px dashed #e6e6e6;
    line-height: 1.1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  .message--description {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
}
</style>
