<template>
  <div id="rootContainer" class="categories inobounce-skip" :class="{
      kiosk: $kiosk,
      pos: $pos,
      shouldHideCategoryImages: company && company.shouldHideKioskCategoryImages(),
      noImage: noImage}" v-if="shouldBeVisible">
    <div class="top-arrow" v-if="$kiosk && !isAirxTouch" @click="scrollToTop"
         @contextmenu="scrollToTop" v-wave="!scrollAtBeginning" :class="{disabled: scrollAtBeginning}">
      <svgicon src="icons/triangle-arrow-top.svg" :height="34" :width="66"></svgicon>
    </div>

    <div id="category-links" class="links">
      <router-link :to="{name: 'menuWithId', params: {id: category.id}}" class="category" v-wave
                   :key="'category-' + categoryIndex"
                   @contextmenu.native="contextMenu(category.id)"
                   @click.native="categoryChanged" v-for="(category, categoryIndex) of nonEmptyRootCategories">
        <AsyncImage class="image" :fallback="$store.state.defaultItemImage" v-if="!noImage"
                    :image="$store.state.urlFileServer + category.image"></AsyncImage>
        <v-clamp :max-lines="2" class="name">{{ $to(category.name) }}</v-clamp>
      </router-link>
    </div>

    <div class="bottom-arrow" v-if="$kiosk && !isAirxTouch" @click="scrollToBottom" @contextmenu="scrollToBottom"
         v-wave="!scrollAtEnd" :class="{disabled: scrollAtEnd}">
      <svgicon src="icons/triangle-arrow-bottom.svg" :height="34" :width="66"></svgicon>
    </div>

    <div class="airxtouch--arrows" v-if="$kiosk && isAirxTouch">
      <div class="top-arrow" @click="scrollToTop" @contextmenu="scrollToTop" v-wave="!scrollAtBeginning"
           :class="{disabled: scrollAtBeginning}">
        <svgicon src="icons/triangle-arrow-top.svg" :height="30" :width="60"></svgicon>
      </div>
      <div class="bottom-arrow" @click="scrollToBottom" @contextmenu="scrollToBottom" v-wave="!scrollAtEnd"
           :class="{disabled: scrollAtEnd}">
        <svgicon src="icons/triangle-arrow-bottom.svg" :height="30" :width="60"></svgicon>
      </div>
    </div>
  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "categories",

    props: {
      itemPage: Boolean
    },

    data() {
      return {
        scrollAtBeginning: true,
        scrollAtEnd: true
      };
    },

    components: { AsyncImage },

    mounted() {
      if (!this.itemPage && this.$route.name !== "menuWithId" && this.$route.name !== "menuWithPromoId") {
        this.triggerClickOnFirstCategoryForKioskAndPos();
      } else {
        this.scrollToActiveCategory();
      }
      EventBus.$on("select-first-category", () => {
        this.triggerClickOnFirstCategoryForKioskAndPos();
      });
      if (this.isKioskOrPos) {
        EventBus.$on("kiosk-cart-toggle", () => {
          this.handleScroll();
        });
      }
      if (this.isKioskOrPos && document.getElementById("category-links")) {
        document.getElementById("category-links").addEventListener("scroll", this.handleScroll);
      }
      this.handleScroll();
    },

    beforeDestroy() {
      EventBus.$off("select-first-category");
      if (this.isKioskOrPos && document.getElementById("category-links")) {
        document.getElementById("category-links").removeEventListener("scroll", this.handleScroll);
      }
      if (this.isKioskOrPos) {
        EventBus.$off("kiosk-cart-toggle");
      }
    },

    watch: {
      "$route.name"() {
        if (this.$route.name === "menu" && this.$pos) {
          this.triggerClickOnFirstCategoryForKioskAndPos();
        }
      }
    },

    computed: {
      root() {
        if (!this.$store.state.inventory) {
          return [];
        }
        let root = this.$store.state.inventory.getRoot();
        if (this.$order.branding) {
          root = root.filter(c => c.branding === this.$order.branding.id);
        }
        return root;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayCategoryImages;
      },
      isKioskOrPos() {
        return this.$kiosk || this.$pos;
      },
      isAirxTouch() {
        return this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH();
      },
      shouldBeVisible() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (this.inventory && this.inventory.isBrandingActivated() && !this.$order.branding) {
          return false;
        }
        return !this.$store.state.quickMode && this.$store.getters.isMobileOrTablet && this.root;
      },
      nonEmptyRootCategories() {
        return this.root.filter(c => c.isAvailable(this.$order.date, this.$order.hour) && c.shouldBeDisplayedAs("category", this.$order.method));
      }
    },

    methods: {
      categoryChanged() {
        this.$emit("changed");
        EventBus.$emit("kiosk-category-changed");
        EventBus.$emit("pos-category-changed");
        this.scrollToActiveCategory();
      },
      scrollToActiveCategory() {
        this.$nextTick(() => {
          setTimeout(() => {
            let active = document.getElementsByClassName("category router-link-active");
            let activeCategory = active.length > 0 ? active[0] : null;
            if (activeCategory) {
              let options = this.$kiosk ? { behavior: "smooth", block: "center", inline: "center" } : { behavior: "smooth" };
              activeCategory.scrollIntoView(options);
            }
          }, 0);
        });
      },
      triggerClickOnFirstCategoryForKioskAndPos() {
        if (this.isKioskOrPos && document.getElementsByClassName("category")[0]) {
          document.getElementsByClassName("category")[0].click();
        }
      },
      handleScroll() {
        let div = document.getElementById("category-links");
        if (div) {
          this.scrollAtBeginning = isElementAtBeginningOfVerticalScroll(div);
          this.scrollAtEnd = isElementAtEndOfVerticalScroll(div);
        }
      },
      contextMenu(id) {
        if (this.$kiosk || this.$pos) {
          this.$emit("changed");
          this.$router.push(this.$router.generate("/menu/" + id));
        }
      },
      scrollToTop() {
        let div = document.getElementById("category-links");
        div.scrollTop = div.scrollTop - div.offsetHeight + 40;
      },
      scrollToBottom() {
        let div = document.getElementById("category-links");
        div.scrollTop = div.scrollTop + div.offsetHeight - 40;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .categories:not(.kiosk):not(.pos) {
        flex-shrink: 0;
        background-color: white;
        border-bottom: 1px solid rgb(230,230,230);

        #category-links {
            width: 100%;
            display: flex;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .category {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            text-decoration: none;
            font-size: 18px;
            padding: 10px 5px;
            color: inherit;

            .name {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                margin-top: 8px;
                width: 80px;
                text-align: center;
            }

            &.router-link-active {
                font-weight: 500;
                .image {
                    border: 2px solid #ed2937;
                }
            }

            .image {
                height: 50px;
                width: 50px;
                border-radius: 100px;
                background-color: #ccc;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 2px solid transparent;
                flex-shrink: 0;
                overflow: hidden;
            }
        }
    }

    #iShopFoodApp .categories.pos.noImage {
      .links .category {
        min-height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .categories.pos {
      border-right: 2px dashed var(--border-color);
      .links .category:after .name {
        background-color: var(--background-color-2);
      }
      .links .category .name {
        background-color: var(--background-color-2) !important;
      }
    }

    .categories.kiosk {
      border-right: 2px dashed #dbdbdb;
      .links .category:after .name {
        background-color: white;
      }
      &.shouldHideCategoryImages {
        .links .category {
          min-height: initial !important;
          height: auto;
          overflow: hidden;

          .image {
            display: none !important;
          }

          .name {
            min-height: 74px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .categories.kiosk, .categories.pos {
        height: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;

      #category-links {
        overflow-x: hidden;
      }

      #category-links {
        overflow-x: hidden;
      }

       .airxtouch--arrows {
         display: flex;
         flex-shrink: 0;
         border-top: 1px solid #dbdbdb;

         .top-arrow, .bottom-arrow {
           flex-grow: 1;
           height: 100px;
           &:before, &:after {
             content: none;
           }
         }
       }

        .links {
            flex-grow: 1;
            overflow-y: auto;
            scroll-behavior: smooth;

            .category {
                min-height: 165px;
                margin: 15px 30px;
                display: block;
                border-radius: 20px;
                text-decoration: none;
                box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
                position: relative;
                color: inherit;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 20px;
                    border: 3px solid transparent;
                    transition: border-color 250ms ease-in-out;
                }

                &.router-link-active:after {
                    border-color: #fc6650;
                }

                .image {
                    height: 120px;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
                .name {
                    background-color: white;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding: 15px;
                    font-weight: bold;
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
        .top-arrow:after, .bottom-arrow:before {
            content: "";
            width:calc(100% - 40px);
            height: 1px;
            background-color: #dbdbdb;
            position: absolute;
        }
        .top-arrow:after {
            bottom: 0;
        }
        .bottom-arrow:before {
            top: 0;
        }
    }

    .categories.kiosk {
      width: 240px;

      .links .category {
        width: 180px;
      }
      .top-arrow, .bottom-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #fc6650;
        padding: 23px;
        position: relative;
        transition: opacity 100ms linear;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }

    #iShopFoodApp .categories.pos {
      .links {
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }
      }
      .links .category {
        width: 160px;
        padding: 10px;
        background-color: var(--background-color-2);
        min-height: initial;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .image {
          height: 93px;
          margin-bottom: 6px;
          border-radius: 12px;
        }

        .name {
          padding: 0 5px;
          font-size: 20px;
          font-weight: 500;
        }

        &:after {
          border-width: 5px;
        }

        &.router-link-active {
          &:after {
            border-color: var(--primary-color);
          }
          .name {
            font-weight: bold;
            color: var(--primary-color);
          }

        }
      }
      .top-arrow {
        padding: 0 20px 20px 20px;
      }
      .bottom-arrow {
        padding: 20px 20px 0 20px;
      }
      .top-arrow, .bottom-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        fill: var(--primary-color);
        position: relative;
        transition: opacity 100ms linear;

        .arrow__inner {
          background-color: #ebebeb;
          display: flex;
          align-items: center;
          width: 150px;
          justify-content: center;
          padding: 15px;
          border-radius: 12px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
</style>

<style lang="scss">
    #rootContainer .name span {
        text-align: center;
    }
</style>
