<template>
    <div id="kioskHeader">
        <Slideshow ref="slideshow" class="header--banner" :urls="slideshowUrls"></Slideshow>
        <div class="header--info">
            <div class="info--button">
                <button class="btn btn-secondary" v-wave @click="back" @contextmenu="back">
                    <svgicon src="icons/back-full.svg" :height="18" :width="18"></svgicon>
                    {{$t('back')}}
                </button>
            </div>
            <div class="info--content">
                <Breadcrumb></Breadcrumb>
            </div>
            <KioskLocaleSwitch></KioskLocaleSwitch>
        </div>
    </div>
</template>

<script>
  import Breadcrumb from "@/components/menu/Breadcrumb.vue";
  import Slideshow from "@/components/tools/Slideshow.vue";
  import KioskLocaleSwitch from "@/components/kiosk/KioskLocaleSwitch";

  export default {
    name: "kioskHeader",

    components: { Breadcrumb, KioskLocaleSwitch, Slideshow },

    computed: {
      banner() {
        if (this.$order.branding && this.$order.branding.bannerImage) {
          return CONFIG.urlFileServer + this.$order.branding.bannerImage.url;
        }
        if (this.company && this.company.images && this.company.images.kioskBannerImage) {
          return CONFIG.urlFileServer + this.company.images.kioskBannerImage.url;
        }
        return "";
      },
      slideshowUrls() {
        let urls = [];
        /* Selected by display priority */
        if (this.$order.branding) { // 1. Branding slideshow
          urls = this.$order.branding.getSlideshowUrls();
          if (urls.length === 0) { //2. Branding banner
            urls.push(this.banner);
          }
        } else if (this.company && this.company.getSlideshowUrls().length > 0) { // 3. Company slideshow
          urls = this.company.getSlideshowUrls();
        } else { // 4. Company banner
          urls.push(this.banner);
        }
        return urls;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      locale() {
        return this.$ts.state.locale;
      }
    },

    watch: {
      "$order.branding"() {
        if (this.$refs.slideshow) {
          this.$refs.slideshow.$forceUpdate();
        }
      }
    },

    methods: {
      back() {
        if (this.$store.state.ticketMode && this.$route.name.toLowerCase() === "tickets") {
          this.$router.push(this.$router.generate("/kiosk/standby"));
          return;
        }
        if (this.$route.name.toLowerCase().indexOf("menu") > -1) {
          if (this.$store.state.inventory.brandingActivated && this.$order.branding) {
            this.$order.resetBranding();
            this.$router.replace(this.$router.generate("/menu"));
          } else {
            if (this.kioskConfiguration) {
                let methods = this.kioskConfiguration.getMethodsActivated();
                if (methods.length === 1) {
                  this.$router.push(this.$router.generate("/kiosk/standby"));
                  return;
                }
            }
            this.$router.push(this.$router.generate("/kiosk/method"));
          }
        } else {
          window.history.back();
        }
      },
      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },
      toggleLocale() {
        if (this.locale === "fr") {
          this.setLocale("en");
        } else if (this.locale === "en") {
          this.setLocale("fr");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .vertical__AIRxTOUCH {
    &.AIRxTOUCH__32 #kioskHeader .header--banner {
      height: 20vh;
    }
    #kioskHeader .header--banner {
      height: 40vh;
    }
  }

  #iShopFoodApp .horizontal #kioskHeader {
    .header--banner {
      display: none;
    }
  }

  #iShopFoodApp .horizontal #kioskHeader {
      height: 80px;

      .info--button .btn {
          padding: 10px 30px;
      }
  }

  #iShopFoodApp #kioskHeader {
    position: relative;

    .header--banner {
      height: 200px;
    }

    .header--info {
      background-color: #fff;
      padding: 15px 24px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
    }

    .info--content {
      flex-grow: 1;
    }

    .info--button {
      display: flex;
      align-items: center;

      .btn {
        box-shadow: none;
        fill: #fc6650;
        color: #fc6650;
        padding: 23px 30px;
        width: 190px;
      }

      .svgicon {
        margin-right: 15px;
      }

      &::after {
        content: "";
        height: 40px;
        width: 1px;
        background-color: #e6e6e6;
        margin: 0 24px;
      }
    }
  }
</style>

<style lang="scss">
    #iShopFoodApp #kioskHeader .breadcrumb {
        width: 100%;
        text-align: left;
        display: block;

        &.brandingPage {
          a {
            max-width: none;
          }
        }

        a {
            pointer-events: none;
            margin: 0 !important;
            color: #1a1a1a;
            font-size: 32px;
            font-family: 'Segoe UI Black', sans-serif;
            text-transform: uppercase;
            white-space: nowrap;
            line-height: 1;
            max-width: calc(100vw - 500px);
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }
</style>
