<template>
  <div class="svgicon" :class="{scale: !!width || !!height}" :style="{
    width: width + 'px',
    height: height + 'px'
  }" @click="$emit('click')"></div>
</template>

<script>

  //@ts-check
  import Store from "../../store";

  let svgcache = new Map();

  export default {

    props: {
      src: String,
      width: Number,
      height: Number
    },

    watch: {
      src() {
        this.load();
      }
    },

    mounted() {
      this.load();
    },

    methods: {
      async load() {
        let svg;
        if (!this.src) {
          return;
        }
        if (svgcache.get(this.src)) {
          svg = svgcache.get(this.src);
        } else {
          if (Store.state.widget) {
            svg = await fetch(CONFIG.svgUrlPrefix + this.src).then(r => r.text());
          } else {
            svg = await request("GET", (CONFIG.svgUrlPrefix || "") + this.src);
          }
          svgcache.set(this.src, svg);
        }
        if (svg.indexOf("<html>") == -1) {
          this.$el.innerHTML = svg;
        }
      }
    }

  }

</script>

<style lang="scss">
  #iShopFoodApp .svgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    &.scale svg {
      width: 100%;
      height: 100%;
    }
  }
</style>
