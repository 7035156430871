<template>
  <div id="posOffline">
    <div class="pos-navigation__banner">
      <div class="pos-navigation__circle"></div>
      <svgicon class="pos-navigation__icon" src="icons/no-wifi.svg" :width="190" :height="150"></svgicon>
      <div class="pos-navigation__pill"></div>
      <div class="pos-navigation__pill clockwise"></div>
      <div class="pos-navigation__pill counterclockwise"></div>
      <div class="pos-navigation__pill left"></div>
      <div class="pos-navigation__pill left clockwise"></div>
      <div class="pos-navigation__pill left counterclockwise"></div>
    </div>
    <div class="pos-navigation__title">{{ $t("pos.offline.title") }}</div>
    <div class="pos-navigation__separator"></div>
    <div class="pos-navigation__text">{{ $t("pos.offline.description") }}</div>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: "posOffline",
  data() {
    return {
      interval: null,
      statusTimeout: 5000
    };
  },
  mounted() {
    this.interval = setInterval(this.ping.bind(this), this.statusTimeout);
    this.ping();
  },
  methods: {
    async ping() {
      try {
        let res = await Axios.get(this.$store.getters.urlServer + "/api/status", {
          timeout: this.statusTimeout
        });
        if (res.status !== 200) {
          return;
        }
      } catch (e) {
        return;
      }
      clearInterval(this.interval);
      this.$router.push("/pos/loading");
    }
  }
}
</script>

<style lang="scss" scoped>
#iShopFoodApp #posOffline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white !important;
}

.pos-navigation__title {
  font-size: 48px;
  font-family: 'Segoe UI Black', sans-serif;
  color: var(--primary-color);
  margin-bottom: 22px;
}

.pos-navigation__separator {
  width: 580px;
  height: 1px;
  background-color: #e6e6e6;
  margin-bottom: 20px;
}

.pos-navigation__text {
  font-size: 20px;
  font-weight: 500;
  max-width: 520px;
  text-align: center;
  line-height: 1.5;
}

.pos-navigation__banner {
  margin-bottom: 80px;
  position: relative;
  height: 278px;

  .pos-navigation__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 278px;
    height: 278px;
    border-radius: 50%;
    background-color: #f5f5f5;
    transform-origin: center;
    animation: breath 5s ease-in-out infinite;

    @keyframes breath {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }

      40% {
        transform: translate(-50%, -50%) scale(1);
      }

      50% {
        transform: translate(-50%, -50%) scale(1.1);
      }

      60% {
        transform: translate(-50%, -50%) scale(1);
      }

      100% {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .pos-navigation__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: var(--primary-color);
  }

  .pos-navigation__pill {
    width: 36px;
    height: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) translate(182px);
    animation: pillRight 5s ease infinite;
    $slide: 40px;

    @keyframes pillRight {
      0% {
        transform: translate(-50%, -50%) translate(182px);
      }

      40% {
        transform: translate(-50%, -50%) translate(182px);
      }

      50% {
        transform: translate(-50%, -50%) translate(182px + $slide);
      }

      60% {
        transform: translate(-50%, -50%) translate(182px);
      }

      100% {
        transform: translate(-50%, -50%) translate(182px);
      }
    }

    &.clockwise {
      transform: translate(-50%, -50%) rotate(30deg) translate(182px);
      animation: pillRightClockwise 5s ease infinite;

      @keyframes pillRightClockwise {
        0% {
          transform: translate(-50%, -50%) rotate(30deg) translate(182px);
        }

        40% {
          transform: translate(-50%, -50%) rotate(30deg) translate(182px);
        }

        50% {
          transform: translate(-50%, -50%) rotate(30deg) translate(182px + $slide);
        }

        60% {
          transform: translate(-50%, -50%) rotate(30deg) translate(182px);
        }

        100% {
          transform: translate(-50%, -50%) rotate(30deg) translate(182px);
        }
      }
    }

    &.counterclockwise {
      transform: translate(-50%, -50%) rotate(-30deg) translate(182px);
      animation: pillRightCounterclockwise 5s ease infinite;

      @keyframes pillRightCounterclockwise {
        0% {
          transform: translate(-50%, -50%) rotate(-30deg) translate(182px);
        }

        40% {
          transform: translate(-50%, -50%) rotate(-30deg) translate(182px);
        }

        50% {
          transform: translate(-50%, -50%) rotate(-30deg) translate(182px + $slide);
        }

        60% {
          transform: translate(-50%, -50%) rotate(-30deg) translate(182px);
        }

        100% {
          transform: translate(-50%, -50%) rotate(-30deg) translate(182px);
        }
      }
    }

    &.left {
      transform: translate(-50%, -50%) rotate(180deg) translate(182px);
      animation: pillLeft 5s ease infinite;

      @keyframes pillLeft {
        0% {
          transform: translate(-50%, -50%) rotate(180deg) translate(182px);
        }

        40% {
          transform: translate(-50%, -50%) rotate(180deg) translate(182px);
        }

        50% {
          transform: translate(-50%, -50%) rotate(180deg) translate(182px + $slide);
        }

        60% {
          transform: translate(-50%, -50%) rotate(180deg) translate(182px);
        }

        100% {
          transform: translate(-50%, -50%) rotate(180deg) translate(182px);
        }
      }
    }

    &.left.clockwise {
      transform: translate(-50%, -50%) rotate(210deg) translate(182px);
      animation: pillLeftClockwise 5s ease infinite;

      @keyframes pillLeftClockwise {
        0% {
          transform: translate(-50%, -50%) rotate(210deg) translate(182px);
        }

        40% {
          transform: translate(-50%, -50%) rotate(210deg) translate(182px);
        }

        50% {
          transform: translate(-50%, -50%) rotate(210deg) translate(182px + $slide);
        }

        60% {
          transform: translate(-50%, -50%) rotate(210deg) translate(182px);
        }

        100% {
          transform: translate(-50%, -50%) rotate(210deg) translate(182px);
        }
      }
    }

    &.left.counterclockwise {
      transform: translate(-50%, -50%) rotate(150deg) translate(182px);
      animation: pillLeftCounterclockwise 5s ease infinite;

      @keyframes pillLeftCounterclockwise {
        0% {
          transform: translate(-50%, -50%) rotate(150deg) translate(182px);
        }

        40% {
          transform: translate(-50%, -50%) rotate(150deg) translate(182px);
        }

        50% {
          transform: translate(-50%, -50%) rotate(150deg) translate(182px + $slide);
        }

        60% {
          transform: translate(-50%, -50%) rotate(150deg) translate(182px);
        }

        100% {
          transform: translate(-50%, -50%) rotate(150deg) translate(182px);
        }
      }
    }
  }
}
</style>