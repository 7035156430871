import { Money } from "@/util/Money";

class IshopPrinterPayment {

  constructor(sale) {
    this.printer = sale.printer;
    this.sale = sale;
    this.printerCols = sale.printerCols;
    this.isSuccess = sale.isSuccess;
  }

  /**
   * @param {{data: *, processor: String}} payment
   */
  print(payment) {
    if (!payment || !payment.processor) {
      return;
    }

    switch (payment.processor) {
      case "itc":
        if (this.isSuccess) {
          _printProcessorItc(this.printer, payment);
        }
        break;
      case "payfacto":
        if (payment.data && payment.data.preAuthReceipt) {
          _printProcessorPayfacto(this.printer, payment.data.preAuthReceipt, "payfactoPax.preAuth", this.sale.currency, this.sale.getLanguage());
        }
        if (payment.data && payment.data.completionReceipt) {
          this.printer.newLine()
            .addLine(null, rightPad("", this.printerCols, "-"))
            .newLine();
          _printProcessorPayfacto(this.printer, payment.data.completionReceipt, "payfactoPax.completion", this.sale.currency, this.sale.getLanguage());
        }
        if (payment.data && payment.data.purchaseReceipt) {
          _printProcessorPayfacto(this.printer, payment.data.purchaseReceipt, "payfactoPax.purchase", this.sale.currency, this.sale.getLanguage());
        }
        break;
      case "none":
        if (this.sale.getTpvReceipt()) {
          _printTpvReceipt(this.printer, this.sale.getTpvReceipt());
        }
        break;
      default:
        console.log(payment.processor + " not supported as a payment method");
        break;
    }
  }

  /**
   * @returns {boolean}
   */
  saleHasPayFactoPaymentReceipt() {
    if (!this.sale || !this.sale.sale || !this.sale.sale.payments) {
      return false;
    }
    for (let payment of this.sale.sale.payments) {
      if (payment.processor === "payfacto") {
        if (payment.data && (payment.data.completionReceipt || payment.data.purchaseReceipt)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * @param {string} code
   * @returns {boolean}
   */
  saleHasPayFactoPaymentWithReturnCode(code) {
    if (!this.sale || !this.sale.sale || !this.sale.sale.payments) {
      return false;
    }
    for (let payment of this.sale.sale.payments) {
      if (_paymentHasPayFactoReturnCode(payment, code)) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {{data: *, processor: String}} payment
   * @return {string}
   */
  getPaymentLabel(payment) {
    if (payment && payment.processor) {
      switch (payment.processor) {
        case "payfacto":
          if (payment.data && payment.data.completionReceipt) {
            let cardType = payment.data.completionReceipt.cardType.trim();
            if (cardType) {
              return window.translate("payfactoPax.card_type." + cardType);
            }
          }
          if (payment.data && payment.data.purchaseReceipt) {
            let cardType = payment.data.purchaseReceipt.cardType.trim();
            if (cardType) {
              return window.translate("payfactoPax.card_type." + cardType);
            }
          }
          break;
        case "none":
          if (this.sale.tpvReceipt) {
            return window.translate("payfactoPax.card");
          } else if (this.isSuccess) {
            return window.translate(payment.method);
          }
          break;
        default:
          console.log(payment.processor + " not supported as a payment method");
          break;
      }
    }

    return window.translate("payment.payment");
  }

  getPaymentErrorMessage() {
    if (this.saleHasPayFactoPaymentWithReturnCode("00")) { //SUCCESS
      return "error.submit_sale_error_payment_success";
    } else if (this.saleHasPayFactoPaymentWithReturnCode("P3")) { //TERMINAL COMMUNICATION ERROR
      return "error.submit_sale_error_payment_p3";
    } else if (this.saleHasPayFactoPaymentReceipt()) { //GENERIC PAYMENT ERROR
      return "error.submit_sale_error_payment";
    } else { //POSSIBLE SALE ERROR
      return "error.submit_sale_error_kiosk";
    }
  }

}

/**
 * @param {*} printer
 * @param {*} receipt
 * @param {string} transactionTypeTranslationKey
 * @param {string} currency
 * @param {string} language
 * @private
 */
const _printProcessorPayfacto = function(printer, receipt, transactionTypeTranslationKey, currency, language) {
  if (receipt) {
    let isApproved = receipt.returnCode.trim() === "00";
    const signatureRequiredCodes = ["03", "05", "43", "1E", "5E"];
    let signatureCheck = receipt.cvmResults;
    //let isSignatureRequired = isApproved && (signatureCheck == null || signatureRequiredCodes.find(c => signatureCheck.indexOf(c) === 0));
    let isSignatureRequired = false; // Signature not required on unattended devices
    let DEFAULT_PRINTER_COLS = getConfiguration("printer-cols");

    printer.addLine(window.translate("payfactoPax.terminal_id"), null, receipt.bankTerminalNumber)
      .addLine(window.translate("payfactoPax.terminal_invoice_num"), null, receipt.terminalInvoiceNumber)
      .addLine(window.translate("payfactoPax.host_sequence_num"), null, receipt.sequenceNumber)
      .addLine(window.translate("payfactoPax.bill_number"), null, receipt.invoiceNumber)
      .addLine(window.translate("payfactoPax.return_code"), null, receipt.returnCode)
      .addLine(window.translate("payfactoPax.card"), null, (receipt.cardNumber || "").trim());

    let cardType = receipt.cardType.trim();
    let trxType = receipt.trxType.trim();
    if (trxType || cardType) {
      printer.addLine((trxType ? window.translate("payfactoPax.transaction_type." + trxType) : "") + (cardType ? " / " + window.translate("payfactoPax.card_type." + cardType) : ""));
    }

    let trxDate = receipt.trxDate.trim();
    let trxTime = receipt.trxTime.trim();
    if (trxDate || trxTime) {
      printer.addLine(_formatPayfactoDate(trxDate), null, _formatPayfactoTime(trxTime));
    }

    printer.newLine()
      .setFontSize(2)
      .addLine(null, window.translate(transactionTypeTranslationKey))
      .setFontSize(1)
      .addLine(window.translate("payfactoPax.total"), null, _formatPayfactoAmount(receipt.amount, currency, language));

    if (receipt.tipAmount && parseInt(receipt.tipAmount) > 0) {
      printer.addLine(window.translate("payfactoPax.tip"), null, _formatPayfactoAmount(receipt.tipAmount, currency, language));
    }

    printer.newLine();

    if (isApproved) {
      printer.addLine(window.translate("payfactoPax.auth") + " #" + (receipt.authorizationNumber || "").trim(),
        null, window.translate("payfactoPax.batch") + " #" + receipt.batchNumber);
    }

    let trxMethod = (receipt.trxMethod || "").trim();
    printer.addLine("HTS:", null, receipt.timeStamp)
      .addLine(null, null, (trxMethod ? window.translate("payfactoPax.transaction_method." + trxMethod) : ""))
      .newLine()
      .addLine(null, (receipt.receiptDisp || "").trim())
      .newLine()
      .addLine("AID:", null, (receipt.emvAID || "").trim())
      .addLine("TVR:", null, (receipt.emvTVR || "").trim())
      .addLine("TSI:", null, (receipt.emvTSI || "").trim())
      .addLine(null, null, (receipt.emvTC || "").trim());

    if (receipt.emvLabel) {
      printer.addLine(receipt.emvLabel);
    }

    if (isSignatureRequired) {
      printer.newLine()
        .addLine(null, window.translate("payfactoPax.sign"))
        .newLine()
        .addLine(null, "X" + rightPad("", DEFAULT_PRINTER_COLS - 1, "_"))
        .addLine(null, window.translate("payfactoPax.signature"));
    }

    printer.newLine();
  }
};

/**
 * @param printer
 * @param payment
 * @private
 */
const _printOfflinePayment = function(printer, payment) {
  printer.addLine(window.translate(payment.method).toUpperCase(), null, Money.roundForDisplay(payment.amount));
};

/**
 * @param payment
 * @param {string} returnCode
 * @returns {boolean}
 * @private
 */
const _paymentHasPayFactoReturnCode = function(payment, returnCode) {
  if (payment.processor === "payfacto") {
    let code = "";
    if (payment.data && payment.data.completionReceipt) {
      code = payment.data.completionReceipt.returnCode.trim();
    } else if (payment.data && payment.data.purchaseReceipt) {
      code = payment.data.purchaseReceipt.returnCode.trim();
    }
    if (code === returnCode) {
      return true;
    }
  }
  return false;
};

/**
 * @param printer
 * @param receiptData
 * @private
 */
const _printTpvReceipt = function(printer, receiptData) {
  let data = JSON.parse(receiptData);

  if (data.tpvType === "globalFlex" || data.tpvType === "ampUSA") {
    _printTpvReceiptLines(printer, data);
  }
};

/**
 * @param printer
 * @param data
 * @returns {*}
 * @private
 */
const _printTpvReceiptLines = function(printer, data) {
  let receipt = data.receipt;
  for (let i = 0; i < receipt.length; i++) {
    printer.addLine(null, rightPad(receipt[i], 32, " "));
  }
  return printer;
};

/**
 * @param printer
 * @param payment
 * @private
 */
const _printProcessorItc = function(printer, payment) {
  printer.addLine("Payment method:", null, "ITC");
};

/**
 * @param {string} amount
 * @returns {string}
 * @private
 */
const _formatPayfactoAmount = function(amount, currency, language) {
  let intAmount = parseInt(amount);
  let floatAmount = intAmount / 100;
  return Money.roundForDisplayWithCurrencySymbol(floatAmount, "CAD", language);
};

/**
 * @param {string} date
 * @returns {string}
 * @private
 */
const _formatPayfactoDate = function(date) {
  if (!date) {
    return "";
  }
  return date.substr(0, 2) + "/" + date.substr(2, 2) + "/" + date.substr(4, 4);
};

/**
 * @param {string} time
 * @returns {string}
 * @private
 */
const _formatPayfactoTime = function(time) {
  if (!time) {
    return "";
  }
  return time.substr(0, 2) + ":" + time.substr(2, 2) + ":" + time.substr(4, 2);
};

export { IshopPrinterPayment };