<template>
  <div id="switchBatchModal" class="modal" :class="{show: visible}">
    <Panel class="switch-batch" :title="$t('pos.readings.switch_report')" icon="icons/switch-arrow.svg">
      <div class="switch-batch__description">
        {{ $t('pos.readings.switch_report_description') }}
      </div>
      <div class="switch-batch__content">
        <div class="switch-batch__tabs">
          <div class="tab" :class="{selected: activeTabIndex === tab.id}" @click="selectTab(tab.id)" v-for="tab of tabs" :key="tab.id">
            {{ $t('pos.readings.' + tab.name) }}
            <svgicon src="icons/arrow-right.svg" :height="30" :width="20"></svgicon>
          </div>
        </div>
        <div class="switch-batch__body">
          <div class="switch-batch__search-bar">
            <svgicon class="search-bar__icon" src="icons/search.svg" :height="25" :width="25"></svgicon>
            <PosInput type="text" id="batchSearch" class="search__input input__batch-search" v-model="searchString" :placeholder="$t('pos.readings.search_' + tabName)"></PosInput>
          </div>
          <div class="switch-batch__results">
            <template v-if="activeTabIndex === 0">
              <div class="result" v-for="employee of filteredEmployees" :key="employee.id" @click="setSelection(employee.pin)"
                   :class="{ selected: selection.id === employee.pin }" v-if="filteredEmployees.length > 0">
                <div class="result__icon-container">
                  <svgicon src="icons/badge.svg" :height="38" :width="38"></svgicon>
                </div>
                <div class="result__content">
                  <div class="content__title">
                    <span class="title">{{ $t('pos.readings.employee') }}</span>
                    <div class="check">
                      <svgicon src="/icons/check-thick.svg" :width="14" :height="14"></svgicon>
                    </div>
                  </div>
                  {{ employee.getFullName()}}
                </div>
              </div>
              <div class="results__none" v-if="filteredEmployees.length === 0"> {{ $t('pos.readings.no_result_found') }} </div>
            </template>
            <template v-if="activeTabIndex === 1">
              <div class="result" v-for="workstation of filteredWorkstations" :key="workstation.id" @click="setSelection(workstation.id)"
                   :class="{ selected: selection.id === workstation.id }" v-if="filteredWorkstations.length > 0">
                <div class="result__icon-container">
                  <svgicon src="icons/workstation.svg" :height="38" :width="38"></svgicon>
                </div>
                <div class="result__content">
                  <div class="content__title">
                    <span class="title">{{ $t('pos.readings.workstation') }}</span>
                    <div class="check">
                      <svgicon src="/icons/check-thick.svg" :width="14" :height="14"></svgicon>
                    </div>
                  </div>
                  {{ workstation.name }}
                </div>
              </div>
              <div class="results__none" v-if="filteredWorkstations.length === 0"> {{ $t('pos.readings.no_result_found') }} </div>
            </template>
            <div class="result--spacer"></div>
          </div>
        </div>
      </div>
      <div class="switch-batch__footer">
        <button class="btn btn-secondary" @click="hide">
          <svgicon class="icon" src="/icons/back-full.svg" :height="24" :width="18"></svgicon>
          {{ $t("back") }}
        </button>
        <button class="btn btn-primary" :class="{ disabled: !hasActiveSelection }" @click="confirm">
          <svgicon class="icon" src="/icons/check-thick.svg" :height="24" :width="24"></svgicon>
          {{ $t('confirm') }}
        </button>
      </div>
    </Panel>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import PosInput from "@/components/pos/PosInput";
  import { mapActions } from "vuex";
  export default {
    name: "SwitchBatchModal",

    components: { PosInput },

    data() {
      return {
        visible: false,
        searchString: "",
        tabs: [
          { name: "employees", id: 0, apiDataKey: "employee" },
          { name: "workstations", id: 1, apiDataKey: "workstation" }
        ],
        activeTabIndex: 0,
        workstations: [],
        selection: { type: null, id: null }
      };
    },

    async mounted() {
      EventBus.$on("show-switch-batch-modal", () => {
        this.show();
      });
      let response = await this.getWorkstations({ page: -1, perPage: undefined });
      if (response && response.success) {
        this.workstations = response.data.data;
      }
    },

    beforeDestroy() {
      EventBus.$off("show-switch-batch-modal");
    },

    computed: {
      employees() {
        return this.$store.state.employees;
      },
      filteredEmployees() {
        if (this.searchString) {
          return this.employees.filter(e => e.getFullName().includes(this.searchString));
        }
        return this.employees;
      },
      tabName() {
        return this.tabs[this.activeTabIndex]?.name;
      },
      tabApiDataKey() {
        return this.tabs[this.activeTabIndex]?.apiDataKey;
      },
      filteredWorkstations() {
        if (this.searchString) {
          return this.workstations.filter(w => w.name.includes(this.searchString));
        }
        return this.workstations;
      },
      hasActiveSelection() {
        return !!(this.selection && this.selection.id && this.selection.type);
      }
    },

    methods: {
      ...mapActions(["getWorkstations"]),

      show() {
        EventBus.$emit("toggle-pos-header-overlay", true);
        this.setSelection(null);
        this.searchString = "";
        this.visible = true;
      },
      hide() {
        EventBus.$emit("toggle-pos-header-overlay", false);
        this.visible = false;
      },
      selectTab(id) {
        if (this.activeTabIndex === id) {
          return;
        }
        this.searchString = "";
        this.activeTabIndex = id;
      },
      setSelection(selection) {
        this.selection = { type: this.tabApiDataKey, id: selection };
      },
      confirm() {
        if (!this.hasActiveSelection || !this.tabApiDataKey) {
          toast({ message: this.$t("pos.readings.selection_error"), type: "error", title: this.$t("error.title") });
          return;
        }
        let data = {};
        data[this.selection.type] = this.selection.id;
        this.$emit("switch-batch-report-target", data);
        this.hide();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #switchBatchModal {
    font-weight: 500;
    .switch-batch {
      display: flex;
      flex-direction: column;
    }

    .switch-batch__description {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .switch-batch__content {
      display: flex;
    }

    .switch-batch__tabs {
      padding-right: 40px;

      .tab {
        box-sizing: border-box;
        display: flex;
        font-size: 18px;
        box-shadow: 0px 1px 6px #0000001A;
        border-radius: 12px;
        border: 4px solid var(--background-color-2);
        height: 56px;
        margin-bottom: 12px;
        padding: 12px;
        fill: var(--illustration-front-color-2);
        align-items: center;
        justify-content: space-between;
        color: var(--theme-color-3);
        &.selected {
          color: var(--primary-color);
          border-color: var(--primary-color);
          fill: var(--primary-color);
        }
        &.disabled {
          pointer-events: none;
          cursor: default;
          opacity: 0.7;
          box-shadow: none;
        }
        .svgicon {
          margin-left: 20px;
        }
      }
    }

    .switch-batch__body {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .switch-batch__search-bar {
      position: relative;
      margin-bottom: 20px;
      .search__input {
        padding: 14px 15px 14px 60px;
        height: 56px;
        width: 100%
      }

      .search-bar__icon {
        position: absolute;
        left: 15px;
        top: 15px;
        fill: #d9d9d9;
      }
    }

    .switch-batch__results {
      display: flex;
      height: 444px;
      flex-wrap: wrap;
      overflow-y: scroll;
      align-content: flex-start;
    }

    .results__none {
      width: 100%;
      display: flex;
      padding-top: 20px;
      font-size: 20px;
      font-weight: bold;
      justify-content: center;
    }

    .result {
      display: flex;
      height: 96px;
      padding: 6px;
      box-shadow: 0px 1px 6px #0000001A;
      border: 4px solid var(--background-color-2);
      border-radius: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      flex-grow: 1;
      color: var(--theme-color-3);
      flex-basis: calc(50% - 40px);
      font-size: 20px;

      .result__icon-container {
        display: flex;
        fill: var(--illustration-front-color);
        background-color: var(--background-color-3);
        border-radius: 16px;
        height: 76px;
        width: 76px;
        justify-content: center;
        align-items: center;
      }

      .result__content {
        padding: 6px 6px 6px 20px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .content__title {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          color: var(--theme-color-2);
          padding-bottom: 2px;
          height: 50%;

          .check {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            border-radius: 100%;
            border: 2px solid var(--primary-color);
            fill: var(--background-color-2);
          }
          .title {
            align-self: end;
          }
        }
      }
      &.selected {
        border-color: var(--primary-color);

        .result__icon-container {
          fill: #ffffff;
          background-color: var(--primary-color);
          border-radius: 16px;
          height: 76px;
          width: 76px;
        }
        .content__title .check {
          background-color: var(--primary-color);
        }
      }
    }
    .result--spacer {
      display: flex;
      min-width: calc(50%);
      flex-grow: 1;
      height: 0;
    }

    .switch-batch__footer {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-4);
      margin-top: 40px;

      .btn {
        font-size: 20px;
        border-radius: 12px;
        padding: 15px;
        .svgicon {
          margin-right: 16px;
        }
      }
    }
  }
</style>