<template>
    <div id="standby">

        <div class="standby--image" @click="next" @contextmenu="contextMenu" :style="{backgroundImage: 'url(\'' + standbyImage +  '\''}"></div>

        <div class="standby--warning" v-if="isCompanyClosed">
          <div class="message">{{$t('standby.unavailable')}}</div>
          <div class="sub-message" v-if="company.getKioskClosedMessage()">{{company.getKioskClosedMessage()}}</div>
        </div>

        <div class="standby--footer" v-if="!kioskConfiguration.isVerticalAIRxTOUCH()">
            <div class="footer--message" @click="next">
                <div class="message" v-if="!isCompanyClosed">{{$t('standby.touch_to_start')}}</div>
            </div>
            <div class="footer--logo">
                <div class="filler"></div>
                <div class="logo--holder" @click="checkSecretCount">
                    <img class="configuration--logo" :src="logo">
                </div>
            </div>
        </div>

      <template v-if="kioskConfiguration.isVerticalAIRxTOUCH()">
        <div class="standby--swipe-section" @click="next" @contextmenu="contextMenu" v-if="!isCompanyClosed">
          <div class="icon">
            <svgicon class="screen" src="icons/no-touch_screen.svg" :height="100" :width="100"></svgicon>
            <svgicon class="hand" src="icons/no-touch_hand.svg" :height="100" :width="100"></svgicon>
            <svgicon class="top" src="icons/no-touch_top.svg" :height="100" :width="100"></svgicon>
            <svgicon class="bottom" src="icons/no-touch_bottom.svg" :height="100" :width="100"></svgicon>
            <div class="active-zone"></div>
            <div class="active-zone__description">
              <svgicon class="zone--icon zone--icon__left" src="icons/length.svg" :width="12" :height="5"></svgicon>
              <span class="zone--text">8&thinsp;cm</span>
              <svgicon class="zone--icon zone--icon__right" src="icons/length.svg" :width="12" :height="5"></svgicon>
            </div>
          </div>
          <div class="swipe-right">
            <div class="top" v-html="startTitle ? startTitle : $t('standby.start_beginner')"></div>
            <div class="bottom" v-html="startSubtitle"></div>
          </div>
        </div>
        <div class="secret-hit-box" @click="checkSecretCount"></div>
      </template>

    </div>
</template>

<script>
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";
  import Flex from "../../lib/payment/flex";
  import { PromotionManager } from "@/PromotionManager";
  import { mapActions } from "vuex";
  import {Constant} from "@/util/Constant";

  export default {
    name: "standby",

    data() {
      return {
        secretCount: 0,
        secretCountTimer: null,
        secretCountTimerInterval: null,
        secretCountTimerDelay: 500, // ms
        secretCountRequired: 6,
        secretCountTimeout: 10 * 1000, // ms
        inventoryRefreshInterval: null,
        inventoryRefreshDelay: 500,
        maximumMinutesBetweenInventoryUpdates: 5,
        terminalVerificationInterval: null,
        terminalVerificationDelay: 60 * 1000, // ms
        autoCloseBatchInterval: null,
        autoCloseBatchSkip: 0,
        autoCloseBatchDelay: 30 * 1000, // ms
        standbyViewLimit: 15 //max standby view limit before refreshing app
      };
    },

    mounted() {
      setConfiguration("last-sale-id", null, sessionStorage);
      this.setDefaultLocale();
      this.$order.resetBranding();
      this.$order.reset();
      this.$order.setHour(Constant.ORDER_HOUR_ASAP);

      this.incrementAndValidateStandbyViewStack(); //Temporary until nw.js upgrade

      this.kioskConfiguration.deactivateGrabngo();
      this.$store.state.promotionModalDisplayed = false;
      this.startInventoryRefreshIntervalForKiosk();
      this.startTerminalVerificationIntervalForKiosk();
      this.startAutoCloseBatchInterval();
      this.initAirxTouchDisplayLayout();

      PromotionManager.load();// We need to reset this
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      startSubtitle() {
        return this.$t(this.kioskConfiguration.start.subtitle);
      },
      startTitle() {
        return this.$t(this.kioskConfiguration.start.title);
      },
      terminalUnavailable() {
        return this.$kiosk && this.kioskConfiguration.isFlexPaymentUnavailable();
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      maxSecretCountIntervalIterations() {
        return this.secretCountTimeout / this.secretCountTimerDelay;
      },
      standbyImage() {
        if (this.company && this.company.images && this.company.images.kioskStandbyImage) {
          return CONFIG.urlFileServer + this.company.images.kioskStandbyImage.url;
        }
        return "";
      },
      logo() {
        if (this.company && this.company.images && this.company.images.kioskLogo) {
          return CONFIG.urlFileServer + this.company.images.kioskLogo.url;
        }
        return "img/kiosk/logo.png";
      },
      isCompanyClosed() {
        return this.company && this.company.isClosed();
      }
    },

    beforeDestroy() {
      clearInterval(this.secretCountTimerInterval);
      clearInterval(this.inventoryRefreshInterval);
      clearInterval(this.terminalVerificationInterval);
      clearInterval(this.autoCloseBatchInterval);
    },

    methods: {
      ...mapActions({
        loginGuestAction: "loginGuest",
        getCompanyDefinition: "getCompanyDefinition",
        getBranch: "getBranch"
      }),

      incrementAndValidateStandbyViewStack() {
        window.standbyViewStack++;
        if (window.standbyViewStack > this.standbyViewLimit) {
          window.location.reload();
        }
      },

      setDefaultLocale() {
        if (!this.kioskConfiguration) {
          return;
        }
        let locale = this.kioskConfiguration.getDefaultLocale();
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },

      startAutoCloseBatchInterval() {
        if (!this.$kiosk) {
          return;
        }
        this.autoCloseBatchInterval = setInterval(() => {
          let autoCloseBatchHour = this.kioskConfiguration.getAutoCloseBatchHour();
          if (!autoCloseBatchHour) {
            return;
          }
          if (this.autoCloseBatchSkip > 0) {
            this.autoCloseBatchSkip--;
          } else {
            let currentTime = new Date();
            if (currentTime.getHours() === autoCloseBatchHour && (currentTime.getMinutes() === 0 || currentTime.getMinutes() === 1)) {
              console.log("AUTO CLOSE BATCH");
              window.logToFile("AUTO CLOSE BATCH");
              if (this.kioskConfiguration.hasGlobalFlexPayment()) {
                this.closeBatchWithFlex();
              }
              this.autoCloseBatchSkip = 10;
            }
          }
        }, this.autoCloseBatchDelay);
      },

      async closeBatchWithFlex() {
        try {
          let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
          await tpv.connect();
          let closeBatchData = await tpv.closeBatch();
          window.printTpvAdminReceipt(closeBatchData.response.adminReceipt);
          console.log(this.$t("success"), this.$t("flexGlobal.batch_success"));
        } catch (e) {
          console.error("ERROR FLEX CLOSE BATCH", e);
          window.logToFile("ERROR FLEX CLOSE BATCH");
          window.logToFile(e);
          if (e.response.responseCode === tpv._RESPONSE_CODE.BATCH_EMPTY) {
            console.error(this.$t("error.title"), this.$t("flexGlobal.batch_empty"));
          } else {
            console.error(this.$t("error.title"), this.$t("flexGlobal.error"));
          }
        }
      },

      startTerminalVerificationIntervalForKiosk() {
        if (!this.$kiosk) {
          return;
        }
        this.terminalVerificationInterval = setInterval(() => {
          this.verifyTerminal();
        }, this.terminalVerificationDelay);
        this.verifyTerminal();
      },

      startInventoryRefreshIntervalForKiosk() {
        if (!this.$kiosk) {
          return;
        }
        this.inventoryRefreshInterval = setInterval(() => {
          this.refreshInventory();
        }, this.inventoryRefreshDelay);
        this.refreshInventory();
      },

      async verifyTerminal() {
        if (!this.kioskConfiguration) {
          return;
        }
        switch (this.kioskConfiguration.terminalPayment) {
          case this.kioskConfiguration.GLOBAL_FLEX:
            try {
              let tpv = new Flex(this.kioskConfiguration.terminalData.ip);
              await tpv.connect();
              let response = await tpv.getStatus();
              if (response && response.response && response.response.responseCode !== tpv._RESPONSE_CODE.SUCCESS) {
                console.error("FLEX TERMINAL UNAVAILABLE");
                window.logToFile("FLEX TERMINAL UNAVAILABLE");
                window.logToFile(response);
                setConfiguration("flex-unavailable", true);
              } else {
                setConfiguration("flex-unavailable", false);
              }
            } catch (e) {
              console.error("FLEX TERMINAL UNAVAILABLE", e);
              window.logToFile("FLEX TERMINAL UNAVAILABLE");
              window.logToFile(e);
              setConfiguration("flex-unavailable", true);
            }
            break;
          case this.kioskConfiguration.PAYFACTO_PAX:
          default:
            break;
        }
      },

      refreshInventory() {
        let minutesSinceLastUpdate = (new Date() - this.inventory.lastUpdateTimestamp) / (1000 * 60);
        if (minutesSinceLastUpdate >= this.maximumMinutesBetweenInventoryUpdates) {
          this.inventory.lastUpdateTimestamp = new Date(); //Temporary set updateTimeStamp to avoid firing twice the update during next interval
          if (this.company && this.branch) {
            this.getBranch({
              id: this.branch.nameCanonical,
              company: this.company.nameCanonical,
              force: true,
              executeInBackground: true,
              KeepCurrent: true
            });
          }
        }
      },

      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            window.airxtouch__submitLayout([
              GetElementBounds("standby", 0, AIRxTouchDefaultLayoutSetting.DirectClick) // Click area : Whole page
            ]);
          });
        }
      },

      contextMenu(e) {
        e.preventDefault();
        this.next();
      },

      async next() {
        window.logToFile("STARTING NEW SALE", this.$order.getUniqueId());
        if (this.isCompanyClosed) {
          return;
        }
        if (this.terminalUnavailable) {
          await showAffirmation(this.$t("warning"), this.$t("kiosk.terminal_unavailable"));
        }
        if (this.kioskConfiguration.isTicket()) {
          this.$router.push(this.$router.generate("/tickets"));
        } else {
          this.$router.push(this.$router.generate("/kiosk/method"));
        }
      },

      async checkSecretCount() {
        this.secretCount++;

        if (this.secretCount >= 6) {
          clearInterval(this.secretCountTimerInterval);
          this.secretCountTimer = 0;
          this.secretCount = 0;

          if (this.kioskConfiguration.adminPassword) {
            let password = await window.showNumPad(this.$t("kiosk.password_required"));
            if (password !== this.kioskConfiguration.adminPassword) {
              return;
            }
          }
          this.$router.push(this.$router.generate("/kiosk/admin"));
        } else {
          // refresh timer
          clearInterval(this.secretCountTimerInterval);
          this.secretCountTimer = 0;
          this.secretCountTimerInterval = setInterval(() => {
            this.secretCountTimer++;
            if (this.secretCountTimer >= this.maxSecretCountIntervalIterations) {
              this.secretCountTimer = 0;
              this.secretCount = 0;
              clearInterval(this.secretCountTimerInterval);
            }
          }, this.secretCountTimerDelay);
        }
      }

    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .horizontal#standby {
    .footer--message {
      height: 150px;
    }
    .footer--logo {
      height: 75px;
    }
    .standby--warning {
      bottom: 75px;
    }
  }
    #iShopFoodApp #standby {
        display: flex;
        flex-direction: column;
        position: relative;

        .standby--image {
            flex-grow: 1;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

      .footer--message, .standby--warning {
        height: 270px;
        background-color: #fafafa;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .message {
          font-size: 48px;
          font-weight: 900;
          color: #bfbfbf;
          text-transform: uppercase;
          font-family: "Segoe UI Black", "Roboto", sans-serif;
          flex-basis: 100%
        }
        .sub-message {
          font-size: 30px;
          font-weight: 900;
          color: #a7a7a7;
          font-family: "Roboto", sans-serif;
          flex-basis: 100%;
          padding: 0 40px 10px 40px;
        }
      }

      .standby--warning {
        position: absolute;
        bottom: 120px;
        .message {
          font-size: 42px;
        }
      }

        .standby--swipe-section {
          position: absolute;
          color: black;
          bottom: 250px;
          margin-left: 50%;
          transform: translateX(-50%);
          width: auto;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          white-space: nowrap;
          border-radius: 30px;
          padding: 40px 60px;

          .active-zone {
            position: absolute;
            right: 0;
            width: 55px;
            height: 100px;
            z-index: 0;
            background-color: var(--primary-color);
            opacity: 0.15;
          }

          .icon .active-zone__description {
            position: absolute;
            bottom: -20px;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 55px;

            .zone--text {
              font-size: 11px;
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              color: var(--primary-color);
            }

            .svgicon {
              position: initial;
              fill: var(--primary-color);

              &.zone--icon__right {
                transform: rotate(180deg);
              }
            }
          }

          .icon {
            position: relative;
            margin-right: 40px;
            flex-shrink: 0;
            width: 100px;
            height: 100px;

            .svgicon {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              fill: black;
              z-index: 1;

              &.top, &.bottom {
                opacity: 0;
                fill: var(--primary-color);
                animation: appear 3s infinite;
              }
              &.screen {
                animation: color 3s infinite;
              }
              &.hand {
                animation: back-and-forth 3s infinite;
              }
              &.top {
                transform-origin: top right;
                transform: rotate(90deg) translateX(50px) translateY(5px);
              }
              &.bottom {
                transform-origin: bottom right;
                transform: rotate(270deg) translateX(50px) translateY(-5px);
              }
            }
          }

          .svgicon {
            fill: white;
          }

          .swipe-right {
            text-align: left;
          }

          .bottom {
            font-size: 32px;
            font-family: 'GT America Condensed', sans-serif;
          }

          .top {
            margin-bottom: 8px;
            font-size: 56px;
            font-family: 'Vanguard CF', sans-serif;
            text-transform: uppercase;
            font-weight: 900;
          }
        }

        .secret-hit-box {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 125px;
          height: 125px;
        }
        .footer--logo {
            height: 120px;
            background-color: #f5f5f5;
            display: flex;
            padding: 0 40px;
            align-items: center;

            img {
                height: 45px;
                width: auto;
            }

            .filler {
                flex-grow: 1;
            }
        }
    }
</style>

<style lang="scss">
@keyframes back-and-forth {
  0% {
    transform: translateX(-45px);
  }
  30% {
    transform: translateX(-45px);
  }
  47% {
    transform: translateX(-15px);
  }
  70% {
    transform: translateX(-45px);
  }
  100% {
    transform: translateX(-45px);
  }
}
@keyframes color {
  0% {
    fill: black;
  }
  46% {
    fill: black;
  }
  60% {
    fill: var(--primary-color);
  }
  75% {
    fill: black;
  }
  100% {
    fill: black;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
