<template>
  <div id="posDispatchModal" class="modal" :class="{show: visible}" @click="cancelDispatchModal($event)">
    <div class="modal-content">
      <div class="modal__header">

      </div>
      <div class="modal__body">
        <PosDispatch ref="dispatch" v-if="$store.state.table && visible" embed></PosDispatch>
      </div>
      <div class="modal__footer">
        <div class="grow"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import PosDispatch from "@/views/PosDispatch";
  import moment from "moment-timezone";

  export default {
    name: "posDispatchModal",

    components: { PosDispatch },

    data() {
      return {
        visible: false,
        previousMethod: null
      };
    },

    computed: {
      table() {
        return this.$store.state.table;
      }
    },

    mounted() {
      EventBus.$on("show-dispatch-modal", () => {
        this.previousMethod = this.table.method;
        this.show();
      });
      EventBus.$on("hide-dispatch-modal", () => {
        this.hide();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-dispatch-modal");
      EventBus.$off("hide-dispatch-modal");
    },

    methods: {
      hide() {
        this.visible = false;
      },
      show() {
        this.visible = true;
      },
      cancelDispatchModal(e) {
        if (e.target !== this.$el) {
          return;
        }
        if (!this.$refs.dispatch.isValidDispatchConfig()) {
          EventBus.$emit("change-pos-dropdown-method", this.previousMethod); //Will leave dispatch mode
        }
        this.hide();
      }
    }
  };
</script>

<style lang="scss">
  #iShopFoodApp #posDispatchModal.modal {
    z-index: 9;
    .modal-content {
      width: 85%;
      max-width: 85%;
      max-height: 90%;
      border-radius: 40px;
      padding: 40px;
      display: flex;
      flex-direction: column;

      .modal__body {
        flex-grow: 1;
      }
    }
    .grow {
      flex-grow: 1;
    }

    #dispatch {
      padding: 0;
      height: 100%;

      .panel {
        padding: 0;
        box-shadow: none;
        border-radius: 0;

        &:first-child {
          padding-right: 40px;
        }
        &:last-child {
          padding-left: 40px;
          &:not(:first-child) {
            border-left: 1px dashed var(--background-color-4);
          }
        }
        &:first-child:last-child {
          padding: 0;
        }

        .content:before {
          left: 0;
          right: 0;
        }

        .title {
          padding: 0 0 20px 0;
        }
        .container {
          padding: 40px 0 0 0;
        }
      }
    }
  }
</style>