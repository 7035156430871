<template>
  <div id="qr-payment__confirmation">
    <template v-if="$store.getters.isMobileOrTablet">
      <Header showLogo hideBackButton></Header>

      <div class="confirmation-icon__holder">
        <svgicon class="confirmation-icon__background" src="icons/bg-illustration.svg" :height="300" :width="300"></svgicon>
        <svgicon class="confirmation-icon__foreground" src="icons/presenting-invoice.svg" :height="140" :width="140"></svgicon>
      </div>

      <div class="confirmation__animated">
        <div class="confirmation-message__holder">
          <div class="message__title">{{ $t("qr_payment.table_paid") }}</div>
          <div class="message__hr"></div>
          <div class="message__description">{{ $t("qr_payment.thank_you_description") }}
          </div>
        </div>

        <div class="confirmation-button__holder" :class="{invisible: scanCheckOnly}">
          <div class="btn btn-secondary button--back" @click="leave">
            {{ $t("qr_payment.close") }}
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <DesktopNotSupported></DesktopNotSupported>
    </template>
  </div>
</template>

<script>
  import DesktopNotSupported from "@/components/qrPayment/DesktopNotSupported.vue";
  import Header from "@/components/qrPayment/Header.vue";
  import { PaymentProcessor } from "@/PaymentProcessor";

  export default {
    name: "qrPaymentConfirmation",

    components: { DesktopNotSupported, Header },

    mounted() {
      this.tableNumber = this.$order.tableNumber;
      this.sendTableNumberAsQuery = this.$order.isTableNumberFromQrCode();
      this.$order.reset();
      PaymentProcessor.removePayment();
    },

    data() {
      return {
        tableNumber: null,
        sendTableNumberAsQuery: null
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      scanCheckOnly() {
        return this.company && this.company.scanCheckOnlyForQrPayment();
      },
    },

    methods: {
      leave() {
        if (this.scanCheckOnly) {
          return;
        }
        let url = "/table?reload=1";
        if (this.tableNumber && this.sendTableNumberAsQuery) {
          url += "&number=" + this.tableNumber;
        }
        this.$router.push(this.$router.generate(url));
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp {

    #qr-payment__confirmation {
      background-color: white;
    }

    .confirmation-icon__holder {
      position: relative;
      flex-grow: 1;
    }
    .confirmation-icon__background, .confirmation-icon__foreground {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    .confirmation-icon__background {
      fill: #f5f5f5;
    }
    .confirmation__animated {
      animation: fade-in-from-bottom 2s ease both;
      transition: opacity 1s ease;
    }
    .confirmation-icon__foreground {
      fill: var(--primary-color);
      animation: scale-from-center 1.25s cubic-bezier(0.12, 0.76, 0.56, 1) both;
    }
    .message__title {
      text-align: center;
      font-weight: 500;
      padding: 0 30px;
      font-size: 24px;
    }
    .message--hr {
      margin: 30px auto;
      width: 150px;
      height: 6px;
      background-color: var(--primary-color);
      border-radius: 6px;
    }
    .message__description {
      text-align: center;
      margin-bottom: 45px;
      padding: 0 45px;
    }
    .confirmation-button__holder {
      display: flex;
      align-items: center;
      justify-content: center;

      &.invisible {
        opacity: 0;
        padding-top: 25px;
        pointer-events: none;

        * {
          pointer-events: none;
        }
      }
    }
    .button--back {
      padding: 15px 60px;
      margin-bottom: 60px;
    }
    @keyframes scale-from-center {
      0% {
        transform: scale(0.5);
        transform-origin: 50% 100%;
      }
      50% {
        transform: scale(1.05);
        transform-origin: 50% 100%;
      }
      100% {
        transform: scale(1);
        transform-origin: 50% 100%;
      }
    }
    @keyframes fade-in-from-bottom {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      40% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
</style>
