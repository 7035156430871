<template>
  <div class="pill" :style="{minWidth: (minWidth ? minWidth + 'px' : undefined), backgroundColor: (backgroundColor || undefined) }">
    <svgicon class="pill__icon" :src="iconSrc" :height="iconHeight" :width="iconWidth" v-if="iconSrc"></svgicon>
    <span class="pill__text">
      {{text}}<template v-if="!text"><slot></slot></template>
    </span>

  </div>
</template>

<script>
  export default {
    name: "Pill",

    props: {
      text: String,
      iconSrc: String,
      iconWidth: Number,
      minWidth: Number,
      backgroundColor: String,
      iconHeight: Number,
    },
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .pill {
    display: flex;
    //flex-grow: 1;
    background-color: var(--primary-color);
    color: #ffffff;
    fill: #ffffff;
    border-radius: 20px;
    height: 35px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    .pill__icon {
      margin-right: 10px;
    }
  }

</style>