//@ts-check

import Store from "@/store";

/**
 * @typedef SupplementDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {boolean} quantifiable
 */

export default class Supplement {

  /**
   * @param {SupplementDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.name = definition.name;
    this.description = definition.description;
    this.image = /** @type {string} */ (null);
    this.quantity = 0;
    this.quantifiable = definition.quantifiable;
    this.choices = /** @type {Choice[]} */ ([]);
    this.taxable = definition.taxable;
    this.kitchen = definition.kitchen;
    this.revenueCenter = definition.revenueCenter;
    this.priceSchemes = definition.priceSchemes.map(p => {
      p.unitPrice = parseFloat(String(p.unitPrice));
      return p;
    });
  }

  /**
   * @returns {Supplement} Clone of the supplement
   */
  clone() {
    let newSupplement = new Supplement(this.definition);
    newSupplement.image = this.image;
    newSupplement.quantity = this.quantity;
    newSupplement.choices = this.choices.map(c => c.clone());
    return newSupplement;
  }

  getChoices() {
    let choices = this.choices;
    let method = Store.state.order.method || "takeout";
    return choices.filter(c => c.isAvailableForMethod(method));
  }

  getName() {
    return this.name;
  }

  getId() {
    return this.id;
  }

  hasFlowItemsConfigured() {
    return false;
  }

  getTotalPrice(method) {
    method = method || Store.state.order.method || "takeout";

    let totalTaxable = 0;
    let totalNonTaxable = 0;

    if (this.taxable) {
      totalTaxable += this.getPrice(method);
    } else {
      totalNonTaxable += this.getPrice(method);
    }

    for (let choice of this.getSelectedChoices()) {
      if (choice.taxable) {
        totalTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
      } else {
        totalNonTaxable += choice.getTotalPrice(method).subTotal * choice.quantity;
      }
    }

    let taxes = {};
    if (Store.state.currentCompany) {
      for (let tax of Store.state.currentCompany.taxes) {
        taxes[tax.uniqueName] = totalTaxable * tax.value;
      }
    }
    let subTotal = parseFloat((totalTaxable + totalNonTaxable).toFixed(2));

    return {
      subTotalBeforePromotion: subTotal,
      subTotal,
      taxes,
      totalTaxable,
      totalNonTaxable
    };
  }

  getPrice(method) {
    let entity = CONFIG.pos ? Store.state.table : Store.state.order;
    method = method || entity.method || "takeout";
    if (!entity) {
      return 0;
    }
    if (CONFIG.pos) { /* POS */
      return this.getRevenueCenterPriceForRef(Store.state.currentRevenueCenterRef);
    } else { /* OO/Kiosk */
      let priceScheme = this.priceSchemes.find(p => p.method === method);
      return priceScheme ? priceScheme.unitPrice : 0;
    }
  }

  /**
   * @param {string} ref
   * @returns {number}
   */
  getRevenueCenterPriceForRef(ref) {
    if (ref && this.revenueCenter && this.revenueCenter.prices &&
      this.revenueCenter.prices[ref] && this.revenueCenter.prices[ref].unitPrice) {
      return parseFloat(this.revenueCenter.prices[ref].unitPrice);
    }
    return 0;
  }

  /**
   * @returns {string}
   */
  getKitchenName() {
    return this.kitchen && this.kitchen.description ? window.translateObject(this.kitchen.description) : "";
  }

  getSelectedModifiers() {
    return [];
  }

  getAllAppliedPromotions() {
    return [];
  }

  getSelectedChoices() {
    let toReturn = [];
    for (let choice of this.getChoices()) {
      toReturn.push(choice.selected);
    }
    toReturn = toReturn.filter(i => !!i);
    return toReturn;
  }

  getAllChoices() {
    return this.getChoices();
  }

  getChoicesInvalidModifierGroups() {
    let modifierGroups = [];
    for (let choice of this.getChoices()) {
      if (choice.selected) {
        modifierGroups = [...modifierGroups, ...choice.selected.getInvalidModifierGroups()];
      }
    }
    return modifierGroups;
  }
}
