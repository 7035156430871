<template>
  <div class="quantity-selector" @click="stopEventPropagation" :class="{large: large}">
    <template v-if="!checkbox">
      <div class="minus" @click="minus" @contextmenu="contextMenuMinus">
        <svgicon src="icons/minus.svg" :height="large ? 20 : 12" :width="large ? 20 : 12"></svgicon>
      </div>
      <input type="text" placeholder="00" v-model="num" readonly />
      <div class="add" @click="add" @contextmenu="contextMenuAdd">
        <svgicon src="icons/add.svg" :height="large ? 20 : 12" :width="large ? 20 : 12"></svgicon>
      </div>
    </template>
    <template v-else>
      <input id="chkQuantity" type="checkbox" v-model="hasOne">
      <label for="chkQuantity" @click.prevent="toggle"></label>
    </template>
  </div>
</template>

<script>
  export default {
    name: "quantitySelector",

    props: {
      value: Number,
      min: Number,
      max: Number,
      large: Boolean,
      checkbox: Boolean
    },

    data() {
      return {
        num: this.value || this.min || 0
      }
    },

    mounted() {
      this.initialize();
    },

    computed: {
      hasOne() {
        return this.num > 0;
      }
    },

    methods: {
      stopEventPropagation(e) {
        e.stopPropagation();
      },
      contextMenuMinus() {
        if (this.$kiosk) {
          this.minus();
        }
      },
      contextMenuAdd() {
        if (this.$kiosk) {
          this.add();
        }
      },
      initialize() {
        this.num =  this.value || this.min || 0;
        if (this.value < this.min) {
          this.num = this.min;
        }
      },
      minus() {
        if (this.num > (this.min || 0)) {
          this.num--;
        }
      },
      add() {
        if (this.num < (this.max || 99)) {
          this.num++;
        }
      },
      toggle() {
        this.num = this.hasOne ? 0 : 1;
      }
    },

    watch: {
      value: function() {
        this.initialize();
      },
      num: function(newVal, oldVal) {
        let num = String(newVal).replace(/\D+/g, "");
        if (num !== this.num) {
          this.num = num;
          return;
        }
        if (this.num > (this.max || 99)) {
          this.num = (this.max || 99);
        }
        this.$emit("changed", parseInt(this.num));
      },
      min: function() {
        if (this.num < this.min) {
          this.num = this.min;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.pos .quantity-selector {
    display: flex;
    border: none;
    width: auto !important;

    input {
      width: 110px !important;
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      margin: 0 10px;
      height: 44px !important;
      font-weight: bold;
      font-size: 28px;
      color: #1a1a1a;
    }

    .minus, .add {
      position: initial;
      fill: white !important;
      background-color: var(--primary-color);
      border-radius: 10px;
      width: 44px !important;
      height: 44px;
      padding: 0 !important;
      flex-shrink: 0;
      z-index: initial;
    }
  }
  #iShopFoodApp .quantity-selector {
    position: relative;
    fill: #ed2937;
    width: 100px;

    &.large {
      width: 170px;

      input {
        width: 170px;
        font-size: 24px;
        height: 71px;
        border-radius: 10px;
      }
      .minus, .add {
        padding: 25px;
      }
    }

    input {
      width: 100px;
      text-align: center;
    }

    .minus, .add {
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      user-select: none;
    }
    .minus {
      left: 0;
    }
    .add {
      right: 0;
    }

  }

</style>
