import { IshopPrinterSale } from "./IshopPrinterSale";
import { Money } from "@/util/Money";

class IshopPrinterExternalSale extends IshopPrinterSale {

  getPayments(invoice) {
    return this.sale.payments;
  }

  getCompletedDate() {
    return this.sale.completedAt;
  }

  /**
   * @param {boolean} includeVoid
   * @returns {*|*[]}
   */
  getDetails(includeVoid) {
    return this.sale?.details || [];
  }

  getAppName() {
    return this.sale.appName;
  }

  getLanguage() {
    return this.sale.language;
  }

  getGlobalPromotions() {
    return this.sale?.promotions || [];
  }

  getId() {
    return this.sale.kioskNumber || this.sale.id;
  }

  printTransactionNumber() {
    let context = this.getContextFromAppName();
    if (!context) {
      return;
    }

    this.printer.newLine();
    if (this.getUniqueId()) {
      this.printer.addLine(null, this.getUniqueId());
    }
    this.printer.addLine(null, new Date(this.getCompletedDate()).toLocaleString());

    this.printer.newLine()
      .setFontSize(2)
      .addLine(null, window.translate(`kiosk_receipt.order_${context}`).toUpperCase() + " #" + (this.getId()))
      .setFontSize(1);

    if (context === "kiosk") {
      let kioskName = this.configuration?.getName();
      if (kioskName) {
        this.printer.addLine(window.translate("origin.ishopv3-kiosk_short").toUpperCase(), null, kioskName);
      }
    }
    if (this.getTableNumber()) {
      this.printer.addLine(window.translate("payment.table_number").toUpperCase(), null, this.getTableNumber());
    }
    this.printer.newLine();

    if (this.sale.invoices && this.sale.invoices.length > 0) {
      this.printer.addLine(null, window.translate("pos_receipt.check").toUpperCase() + " #" + (this.sale.invoices[0].number))
        .newLine();
    }
  }

  printSaleDetail(detail) {
    let config = this.configuration?.receiptConfiguration;
    let limitProductDescription = config && config.limitProductDescription;
    let hideFreeModifiers = config && config.hideFreeModifiers;
    let name = limitProductDescription ? detail.name.substring(0, this.maxProductNameSize) : detail.name;
    let unitPrice = detail.unitPrice;

    if (this.isRefund()) {
      unitPrice = -(unitPrice);
    }

    switch (detail.type) {
      case "variant":
      case "item":
      case "supplement":
        this.printer.addLine(detail.quantity + "x " + name, null, Money.roundForDisplay((unitPrice * detail.quantity), this.sale.currency));
        break;
      case "combo":
        if (!hideFreeModifiers || (hideFreeModifiers && unitPrice !== 0)) {
          this.printer.addLine("   " + name, null, Money.roundForDisplay(unitPrice, this.sale.currency));
        }
        break;
      case "modifier":
        if (!hideFreeModifiers || (hideFreeModifiers && unitPrice !== 0)) {
          this.printer.addLine("   +" + name + " x" + detail.quantity, null, Money.roundForDisplay((detail.quantity * unitPrice), this.sale.currency));
        }
        break;
    }
  }

  printDetailLineForKitchen(detail) {
    let name = detail.kitchenName ? (window.translateObject(detail.kitchenName) || detail.name) : detail.name;
    switch (detail.type) {
      case "variant":
      case "item":
      case "supplement":
        this.printer.addLine(detail.quantity + "  " + name);
        break;
      case "combo":
      case "modifier":
        this.printer.addLine("   " + detail.quantity + "  " + name);
        break;
    }
  }

  /**
   * @param {PosConfiguration} configuration
   * @param configuration
   * @return {*}
   */
  getSaleDetailsByPrinterName(configuration) {
    let details = this.getDetails();
    let detailsByPrinterName = {};
    let lastPrinterNames = [];
    const modifierTypes = ["combo", "modifier"];

    for (let i = 0; i < details.length; i++) {
      let detail = details[i];
      if (modifierTypes.indexOf(detail.type) > -1) { //Is a modifier
        for (let printerName of lastPrinterNames) {
          if (detailsByPrinterName[printerName]) {
            detailsByPrinterName[printerName].push(detail);
          }
        }
      } else { //Is a root item
        lastPrinterNames = configuration.getKitchenPrinterNamesForItemId(detail.item);
        for (let printerName of lastPrinterNames) {
          if (!detailsByPrinterName[printerName]) {
            detailsByPrinterName[printerName] = [detail];
          } else {
            detailsByPrinterName[printerName].push(detail);
          }
        }
      }
    }
    return detailsByPrinterName;
  }

}

export { IshopPrinterExternalSale };