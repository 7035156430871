
/**
 * @typedef TransactionDefinition
 * @property {number} id
 * @property {Object} account
 * @property {Object} sale
 * @property {CreatedByDefinition} createdBy
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {number} points
 * @property {'sale'|'adjust'} type
 * @property {number} money
 * @property {boolean} pending
 * @property {boolean} refused
 * @property {boolean} cancelled
 * @property {Object} moreData
 */

/**
 * @typedef CreatedByDefinition
 * @property {number} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} userName
 * @property {string} email
 * @property {number} branch
 * @property {string} company
 */

export default class Transaction {

  /**
   * @param {TransactionDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.account = definition.account;
    this.sale = definition.sale;
    this.createdBy = definition.createdBy;
    this.createdAt = definition.createdAt;
    this.updatedAt = definition.updatedAt;
    this.points = definition.points;
    this.type = definition.type;
    this.money = definition.money;
    this.pending = definition.pending;
    this.refused = definition.refused;
    this.cancelled = definition.cancelled;
    this.moreData = definition.moreData;
    this.codes = definition.codes;
  }
}
