<template>
  <div id="standalonePaymentForm" class="form">
    <!-- payment amount -->
    <div class="input amount-input" v-if="!hideAmount">
      <label for="amount">{{ $t("loyalty.amount_reload") }}</label>
      <input id="amount" type="number" class="amount" v-model="amount">
    </div>

    <!-- PAYSAFE -->
    <template v-if="paymentInfo && paymentInfo.processor == 'paysafe'">
      <div class="input">
        <label>{{$t('payment.card_number')}}</label>
        <div class="field" id="txtCardNumber"></div>
      </div>
      <div class="input">
        <label>{{$t('payment.expiration_date')}}</label>
        <div class="field" id="txtCardExpiry"></div>
      </div>
      <div class="input">
        <label for="txtCardCVV">CCV</label>
        <div class="field" id="txtCardCVV"></div>
      </div>

      <slot></slot>

      <label class="paysafeLabel">
        <svgicon :src="$to(securePaymentIcon)" :width="110" :height="22" style="margin-right: 4px;"></svgicon>
        <div class="powered-by">{{$t('payment.powered_by')}}</div>
        <svgicon class="paysafe-icon" src="icons/paysafe.svg" :width="70" :height="22"></svgicon>
      </label>
      <div class="paysafeError" v-show="paysafeError">{{ paysafeError }}</div>
    </template>

    <!-- PAYFACTO -->
    <template v-if="paymentInfo && paymentInfo.processor == 'payfacto'">
      <!-- save card -->
      <template v-if="saveCardEnabled && !anonymous">
        <div class="input">
          <label>{{$t('loyalty.saved_cards')}}</label>
          <div class="token-card" v-for="card of availableTokens" :key="'card' + card.id">
            <div class="check">
              <input :id="'radioCard' + card.id" type="radio" :checked="savedCardID == card.id" @click="changeSavedCardID(card.id)">
              <label :for="'radioCard' + card.id"></label>
            </div>
            <div class="number" @click="changeSavedCardID(card.id)">
              <label>{{$t('payment.card_number')}}</label>
              <div class="value">
                **** **** **** {{card.last4}}
              </div>
            </div>
            <div class="exp" @click="changeSavedCardID(card.id)">
              <label>{{$t('payment.expiration_date')}}</label>
              <div class="value">
                {{(card.exp || "").substring(0, 7)}}
              </div>
            </div>
            <div class="delete icon" @click="deleteToken(card)">
              <svgicon src="icons/trash.svg" :height="15" :width="15"></svgicon>
            </div>
          </div>
          <div class="token-card" @click="changeSavedCardID(null)">
            <div class="check">
              <input id="radioCardNone" type="radio" :checked="savedCardID == null">
              <label for="radioCardNone"></label>
            </div>
            <div class="number">
              <div class="value">
                {{$t('loyalty.new_card')}}
              </div>
            </div>
          </div>
        </div>
        <div class="input save-card" v-if="!savedCardID">
          <input id="chkSaveCard" type="checkbox" v-model="saveCard">
          <label for="chkSaveCard">{{ $t('payment.save_card') }}</label>
        </div>
      </template>

      <slot></slot>

      <label class="payfactoLabel">
        <svgicon :src="$to(securePaymentIcon)" :width="110" :height="22" style="margin-right: 4px;"></svgicon>
        <div class="powered-by">{{$t('payment.powered_by')}}</div>
        <div class="logo">
          <img src="@/assets/img/payfacto.png" alt="PayFacto">
        </div>
        <br><br>
      </label>
    </template>
  </div>
</template>

<script>
  import Axios from "axios";
  import { PaymentProcessor } from "@/PaymentProcessor";

  export default {
    name: "standalonePaymentForm",

    props: {
      defaultAmount: Number,
      hideAmount: Boolean,
      anonymous: Boolean,
      paymentAmount: Number
    },

    mounted() {
      this.initializeSavedCard();
      this.initializePaymentMethod();
    },

    watch: {
      paymentAmount() {
        this.amount = this.paymentAmount;
      }
    },

    data() {
      return {
        amount: this.defaultAmount || 50,
        paysafeInstance: null,
        paysafeError: null,
        payfactoInstance: null,
        payfactoPayment: null,
        saveCard: false,
        availableTokens: [],
        savedCardID: null,
        securePaymentIcon: {fr: 'icons/secure-fr.svg', en: 'icons/secure-en.svg' }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      paymentInfo() {
        if (!this.branch) {
          return null;
        }
        return this.branch.getFirstPaymentMethod();
      },
      saveCardEnabled() {
        if (!this.paymentInfo) {
          return false;
        }
        return this.paymentInfo.saveEnabled;
      }
    },

    methods: {
      initializePaymentMethod() {
        if (!this.paymentInfo || !this.company) {
          return false;
        }

        switch (this.paymentInfo.processor) {
        case "paysafe":
          this.initPaysafe();
          break;
        default:
        }
      },

      async deleteToken(card) {
        let confirm = await showConfirmation(this.$t("warning"),this.$t("loyalty.remove_card_confirm").replace("{CARD}", card.last4));
        if (!confirm) {
          return;
        }
        showSpinner();
        let response = await this.$user.deleteBillingToken(card.id);
        hideSpinner();
        if (response) {
          this.initializeSavedCard();
          this.$emit("deletedToken");
          showAffirmation(this.$t("success"), this.$t("loyalty.remove_card_success"));
        }
      },

      async initializeSavedCard() {
        if (!this.$user || !this.paymentInfo) {
          return;
        }
        this.availableTokens = this.$user.getSavedBillingTokensForAccount(this.paymentInfo.account);
        this.savedCardID = null;
      },

      changeSavedCardID(id) {
        if (this.savedCardID == id) {
          this.savedCardID = null;
        } else {
          this.savedCardID = id;
        }
        this.$emit("savedCardChanged", this.savedCardID);
      },

      getAmount() {
        return this.amount;
      },

      getSaveCard() {
        return this.saveCard;
      },

      getSavedCardID() {
        return this.savedCardID;
      },

      async processPayment() {
        if (!this.paymentInfo) {
          return;
        }

        switch (this.paymentInfo.processor) {
        case "paysafe":
          return this.payWithPaysafe();
        case "payfacto":
          return this.payWithPayfacto();
        }
      },

      getProcessor() {
        if (!this.paymentInfo) {
          return;
        }
        return this.paymentInfo.processor;
      },

      async initPayfacto() {
        this.destroyPayfacto();
        showSpinner();
        let libs = await PaymentProcessor._loadAndReturnPayfactoLibs(this.paymentInfo);
        try {
          let url = this.anonymous ? "/api/anon/payfacto_init" : "/api/me/payfacto_init";
          let getToken = await Axios.get(this.$store.getters.urlServer + url, {
            params: {
              branch: this.branch.nameCanonical
            },
            headers: this.anonymous ? undefined : {
              Authorization: "Bearer " + this.$user.token
            }
          });
          let token = getToken.data.secureID;
          if (!token) {
            hideSpinner();
            return;
          }
          let response = await PayFacto.init(token, { });

        } catch (e) {
          console.log("payfacto init error", e);
          hideSpinner();
          return;
        }
        PayFacto.setGlobalConfiguration({
          apiUrl: libs.api + "/v1",
          iframeUrl: libs.iframe,
          lang: this.$ts.state.locale
        });
        hideSpinner();
      },

      initPaysafe() {
        let key = btoa(this.paymentInfo.username + ":" + this.paymentInfo.password);
        let environment = this.paymentInfo.isLiveMode ? "LIVE" : "TEST";
        let options = {
          environment: environment,
          fields: {
            cardNumber: {
              selector: "#txtCardNumber",
              placeholder: this.$t("payment.card_number")
            },
            expiryDate: {
              selector: "#txtCardExpiry",
              placeholder: this.$t("payment.expiration_date")
            },
            cvv: {
              selector: "#txtCardCVV",
              placeholder: this.$t("payment.cvv"),
              optional: false
            }
          }
        };
        paysafe.fields.setup(key, options, (instance, error) => {
          if (instance) {
            this.paysafeInstance = instance;
            instance.fields("cvv cardNumber expiryDate").on("Valid Invalid", (instance, event) => {
              this.paysafeError = "";
              if (event.type === "Invalid") {
                this.paysafeError = this.$t("error.card." + event.target.fieldName);
              }
            });
          } else {
            console.log(error);
          }
        });
      },

      async showPayfactoModal() {
        await this.initPayfacto();
        if (this.saveCardEnabled && this.savedCardID) {
          this.payfactoPayment = { card: this.savedCardID };
          this._send();
          return;
        }

        if (!PayFacto.initialized) {
          console.log("error payfacto", this.payfactoInstance);
          showAffirmation(translate("error.title"), translate("payment.payfacto_error"));
          return;
        }
        let options = {
          id: "payfacto-secure-fields-modal",
          permanent: this.saveCard,
          header: {
            title: translate("payment.payment_information")
          },
          fields: {
            all: {
              title: {
                floating: false
              }
            }
          },
          autoClose: true
        };
        let executed = false;
        let modal = PayFacto.modal((promise) => {
          promise.then((response) => {
            if (executed || this.payfactoPayment) {
              console.log("failed");
              return;
            }
            executed = true;
            this.payfactoPayment = { token: response.token };
            this._send();
          }).catch((e) => {
            console.log("error payfacto", e);
            switch (e.returnCode) {
            case "8110":
              showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("error.card.ExpiryDate"));
              break;
            case "5060":
              showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("error.session_expired_inactivity"));
              break;
            case "8118":
              showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("error.declined_by_bank"));
              break;
            default:
              showAffirmation(translate("error.title"), translate("error.title") + " " + e.returnCode + " - " + translate("payment.payfacto_error"));
              break;
            }
          });
        }, options);
      },

      payWithPaysafe() {
        return new Promise((resolve, reject) => {
          this.paysafeInstance.tokenize((instance, error, result) => {
            if (error) {
              reject(error);
            } else {
              resolve({ token: result.token });
            }
          });
        });
      },

      payWithPayfacto() {
        return this.payfactoPayment;
      },

      destroyPayfacto() {
        if (typeof PayFacto !== "undefined" && PayFacto.initialized) {
          PayFacto.initialized = false;
          PayFacto.secureID = null;
        }
      },

      _send() {
        this.$emit("submit");
      }
    }
  }
</script>

<style lang="scss">
  #standalonePaymentForm {
    label {
      display: block;
      font-size: 14px;
      color: #808080;
      padding-bottom: 8px;
      padding-left: 8px;
    }
    .input {
      width: 100%;

      & {
        padding: 12px 0 0;
      }

      label {
        display: block;
        font-size: 14px;
        color: #808080;
        padding-bottom: 8px;
        padding-left: 8px;
      }

      #txtCardNumber, #txtCardExpiry, #txtCardCVV {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 10px;
        height: 44px;
      }
    }
  }

</style>

<style lang="scss">
  #standalonePaymentForm .paysafeLabel, #standalonePaymentForm .payfactoLabel {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;

    .powered-by {
      flex-grow: 1;
      text-align: right;
      padding-right: 6px;
      font-size: 14px;
      opacity: 0.7;
    }
  }

  #iShopFoodApp .save-card {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  #iShopFoodApp .token-card {
    padding: 5px 0px 5px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .content {
      display: flex;
      flex-grow: 1;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .delete.icon {
      fill: var(--primary-color);
      padding: 15px;
    }

    label {
      padding-left: 0;
      padding-bottom: 0;
      font-size: 12px;
    }

    .exp, .number {
      padding-top: 2px;
      .value {
        font-size: 16px;
      }
    }

    .number {
      flex-grow: 1;
      margin-left: 10px;
      .value {
        font-weight: bold;
      }
    }

    .new .value {
      font-weight: bold;
      font-size: 16px;
    }
  }

  #standalonePaymentForm .payfactoLabel {
    .svgicon {
      float: left;
    }
    .logo {
      background-color: black;
      padding: 7px 15px 2px 15px;
      max-width: 110px;
      float: right;
      border-radius: 5px;
    }
    .powered-by {
      margin-bottom: 2px;
    }
    img {
      max-width: 100%;
    }
  }

  #standalonePaymentForm .secured {
    margin-top: 20px;
  }
</style>
