var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.showBarcode)?[_c('div',{staticClass:"bigcircle",style:({
        'background': _vm.isDesktop
          ? 'linear-gradient(to bottom left, ' + _vm.gradientColor + ', ' + _vm.primaryColor + ' 45%)'
          : 'linear-gradient(to bottom left, ' + _vm.gradientColor + ', ' + _vm.primaryColor + ' 75%)'
          })}),_c('HeaderClip',[_c('div',{staticClass:"circleSpacer"},[_c('div',{staticClass:"balance"},[_c('div',{staticClass:"amount"},[_vm._v(_vm._s(Math.floor(_vm.$user.loyalty.getBalance())))]),_c('div',{staticClass:"cents"},[_vm._v(_vm._s(_vm.leftPad(Math.round(_vm.$user.loyalty.getBalance() % 1 * 100), 2, "0"))+"$")])]),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t("loyalty.my_balance")))])])]),_c('div',{staticClass:"pay",style:({'color': _vm.primaryColor}),on:{"click":function($event){_vm.showBarcode = true}}},[_c('div',{staticClass:"circle",style:({'background-color': _vm.payColor})},[_c('svgicon',{attrs:{"src":"icons/card-phone.svg","width":60,"height":60}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.pay_with_account"))+" ")]),_c('hr'),_c('div',{staticClass:"buttons"},[(_vm.isRechargeEnabled && _vm.userCanManage)?_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'reload'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/reload-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.reload"))+" ")]):_vm._e(),_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'menu'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/order-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.order"))+" ")]),_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'transactionHistory'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/history-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.history"))+" ")]),(_vm.isUserManagementEnabled && _vm.userCanManage)?_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'accounts'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/manage-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.management"))+" ")]):_vm._e(),(_vm.enableManualLoyaltyCards)?_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'loyaltyCards'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/card-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("loyalty.cards"))+" ")]):_vm._e(),_c('router-link',{staticClass:"button",style:({'color': _vm.primaryColor}),attrs:{"to":{name: 'profile'}}},[_c('div',{staticClass:"circle",style:({
            'background': 'linear-gradient(to bottom left, white, ' + _vm.primaryColor + ' 80%)'
            })},[_c('svgicon',{attrs:{"src":"icons/account-circle.svg","width":64,"height":64}})],1),_vm._v(" "+_vm._s(_vm.$t("navigation.profile"))+" ")])],1)]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBarcode),expression:"showBarcode"}],staticClass:"bigcircle",style:({
      'background': _vm.isDesktop
        ? 'linear-gradient(to bottom left, ' + _vm.gradientColor + ', ' + _vm.primaryColor + ' 45%)'
        : 'linear-gradient(to bottom left, ' + _vm.gradientColor + ', ' + _vm.primaryColor + ' 75%)'
        })}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBarcode),expression:"showBarcode"}],staticClass:"barcode"},[_c('HeaderClip',[_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t("loyalty.scan_description"))+" ")])]),_c('div',{staticClass:"bottom"},[_c('canvas',{attrs:{"id":"barcode"}}),_c('button',{staticClass:"back",style:({'background-color': _vm.primaryColor}),on:{"click":function($event){_vm.showBarcode = false}}},[_c('svgicon',{attrs:{"src":"icons/back.svg","height":25,"width":25}})],1)])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }