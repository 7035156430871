/**
 * @typedef QrPayInvoiceDefinition
 * @property {String} id
 * @property {Object[]} seats
 * @property {String} total
 * @property {String} subTotal
 * @property {String} tipTotal
 * @property {String} serviceFees
 * @property {Object[]} taxes
 * @property {Boolean} selected
 * @property {Boolean} open
 * @property {Object[]} promotions
 * @property {Object[]} payments
 */

export default class QrPayInvoice {

  /**
   * @param {QrPayInvoiceDefinition} definition
   */
  constructor(definition, seats) {
    this.seats = definition.seats || [];
    this.id = definition.id;
    this.subTotal = parseFloat(definition.subTotal || 0);
    this.total = parseFloat(definition.total || 0);
    this.tipTotal = parseFloat(definition.tipTotal || 0);
    this.serviceFees = parseFloat(definition.serviceFees || 0);
    this.taxes = [];
    this.promotions = definition.promotions || [];
    this.payments = definition.payments || [];
    this.promotionTotal = 0;
    this.totalToPay = parseFloat(definition.total || 0) + parseFloat(definition.serviceFees || 0) + parseFloat(definition.tipTotal || 0);

    this.selected = false;
    this.open = true;
    if (definition.taxes) {
      this.taxes = definition.taxes.map(t => (
        {
          name: t.name,
          total: parseFloat(t.total || 0)
        }));
    }
    this.seats = this.seats.map(seatId => seats.find(seat => seatId === seat.id))

    for (let promotion of this.promotions) {
      this.promotionTotal -= promotion.total;
    }
  }

  getTotalRemaining() {
    let totalPaid = 0;
    for (let payment of this.payments) {
      totalPaid += parseFloat(payment.amount || 0);
    }
    return this.totalToPay - totalPaid;
  }

  isPaid() {
    return this.getTotalRemaining() <= 0;
  }

  toggleOpen() {
    this.open = !this.open;
  }

  toggleSelected() {
    this.selected = !this.selected;
  }

  unselect() {
    this.selected = false;
  }

  toDto() {
    return {
      seats:  this.seats,
      id: this.id,
      subTotal: this.subTotal,
      total: this.total,
      tipTotal: this.tipTotal,
      serviceFees: this.serviceFees,
      taxes: this.taxes,
      promotions: this.promotions,
      payments: this.payments,
      promotionTotal: this.promotionTotal,
      totalToPay: this.totalToPay
    }
  }
}
