import { Configuration } from "@/util/Configuration";
import store from "../../store";

class CashDrawer {

  constructor() {
    /** @private */
    this._default = 200;
    /** @private */
    this._maximum = 300;
    /** @private */
    this._drawerId = null;
    /** @private */
    this._expectedState = null;
    setTimeout(() => {
      this._loadFromConfiguration();
      this._loadState();
    }, 0);
  }

  /**
   * Gets the default value for the cash drawer at the start of the day
   * @returns {number} The default value
   */
  get default() {
    return this._default;
  }

  /**
   * Sets the default value for the cash drawer at the start of the day
   * @param {number} value The default value
   */
  set default(value) {
    if (isNaN(value)) {
      throw new Error("The default value must be a number");
    }
    if (value <= 0) {
      throw new Error("The default value must be greater than zero");
    }
    this._default = value;
    Configuration.set("cashDrawerDefault", value);
  }

  /**
   * Gets the maximum value for the cash drawer before showing warnings
   * @returns {number} The maximum value
   */
  get maximum() {
    return this._maximum;
  }

  /**
   * Sets the maximum value for the cash drawer before showing warnings
   * @param {number} value The maximum value
   */
  set maximum(value) {
    if (isNaN(value)) {
      throw new Error("The maximum value must be a number");
    }
    if (value <= 0) {
      throw new Error("The maximum value must be greater than zero");
    }
    this._maximum = value;
    Configuration.set("cashDrawerMaximum", value);
  }

  /**
   * Gets the name (alternative id) of the cash drawer
   * @returns {string} The name of the cash drawer
   */
  get drawerId() {
    return this._drawerId;
  }

  /**
   * Sets the name (alternative id) of the cash drawer
   * @param {string} value The name of the cash drawer
   */
  set drawerId(value) {
    this._drawerId = value;
    Configuration.set("cashDrawerId", value);
  }

  /**
   * Gets the expected amount of cash in the drawer currently
   */
  get expected() {
    if (this._expectedState?.length > 0) {
      return this._expectedState[0].amount;
    }
    return this.default;
  }

  /**
   * Returns true if the expected amount is over the maximum amount of cash in the drawer
   */
  get isOverMaximum() {
    return this.expected > this.maximum;
  }

  /**
   * Remove cash from the drawer and deposit it in the bank
   * @param {number} amount The amount to remove
   * @param {string} currency The currency of the amount
   */
  async deposit(amount, currency) {
    if (!this._drawerId) {
      return;
    }
    let result = await store.dispatch("depositCashDrawer", {
      amount,
      currency: currency,
      altId: this._drawerId
    });
    this._loadState();
    return result;
  }

  /**
   * Add cash to the drawer from the bank
   * @param {number} amount The amount to add
   * @param {string} currency The currency of the amount
   */
  async reload(amount, currency) {
    if (!this._drawerId) {
      return;
    }
    let result = await store.dispatch("depositCashDrawer", {
      amount: -amount,
      currency: currency,
      altId: this._drawerId
    });
    this._loadState();
    return result;
  }

  /**
   * Start the day by opening a given drawer and setting the expected amount
   * @param {number} amount The amount of cash in the drawer
   * @param {string} currency The currency of the amount
   * @param {string} drawerId The id of the drawer
   * @param {string} justification The justification for the difference between the expected and actual amount
   */
  async startDay(amount, currency, drawerId, justification) {
    this.drawerId = drawerId;
    await this._loadState();
    let result = await store.dispatch("syncCashDrawer", {
      amount,
      currency: currency,
      expectedAmount: this.expected,
      altId: drawerId,
      justification
    });
    this._loadState();
    return result;
  }

  /**
   * End the day by closing the drawer and setting the expected amount
   * @param {number} amount The amount of cash in the drawer
   * @param {string} currency The currency of the amount
   * @param {string} drawerId The id of the drawer
   * @param {string} justification The justification for the difference between the expected and actual amount
   */
  async endDay(amount, currency, justification) {
    let result = await store.dispatch("syncCashDrawer", {
      amount,
      currency: currency,
      expectedAmount: this.expected,
      altId: this._drawerId,
      justification
    });
    this.drawerId = null;
    this._expectedState = null;
    this._loadState();
    return result;
  }

  /**
   * Update the state of the cash drawer
   */
  async update() {
    await this._loadState();
  }

  /**
   * Loads the default and maximum values from the local storage
   * @private
   */
  _loadFromConfiguration() {
    this._default = parseFloat(Configuration.get("cashDrawerDefault")) || this._default;
    this._maximum = parseFloat(Configuration.get("cashDrawerMaximum")) || this._maximum;
    this._drawerId = Configuration.get("cashDrawerId") || this._drawerId;
  }

  /**
   * Loads the state of the cash drawer from the server
   * @private
   */
  async _loadState() {
    if (!this._drawerId) {
      return;
    }
    let state = await store.dispatch("getCashDrawerState", {
      altId: this._drawerId
    });
    if (!state.success) {
      if (state.status === 404) {
        this.drawerId = null;
        this._expectedState = null;
      }
      return;
    }
    this._expectedState = state.data.expectedState;
  }

}

let cashDrawer = new CashDrawer();
export { cashDrawer as CashDrawer };