<template>
  <div id="transferModal" class="modal" :class="{show}">
    <div class="content modal-content">

      <div class="title">{{$t('loyalty.transfer_amount')}}</div>

      <div class="form">
        <p>{{$t('loyalty.transfer_description')}}</p>
        <div class="input">
          <label>{{$t("loyalty.from_account")}}</label>
          <select v-model="fromAccount" :class="{invalid: $validate.error('fromAccount')}">
            <option value="null">{{$t('choose')}}...</option>
            <template v-for="account of $user.loyalty.accounts">
              <option :value="account.id" :disabled="account.balance === 0">
                {{getAccountDisplayName(account)}} ({{account.balance.toFixed(2)}}$)
              </option>
            </template>
          </select>
        </div>
        <div class="input">
          <label>{{$t("loyalty.to_account")}}</label>
          <select v-model="toAccount" :class="{invalid: $validate.error('toAccount')}">
            <option value="null">{{$t('choose')}}...</option>
            <template v-for="account of $user.loyalty.accounts">
              <option :value="account.id" :disabled="account.id === fromAccount">
                {{getAccountDisplayName(account)}} ({{account.balance.toFixed(2)}}$)
              </option>
            </template>
          </select>
        </div>
        <div class="input">
          <label>{{$t("loyalty.amount_to_transfer")}} ($)</label>
          <input type="number" v-model="amount" placeholder="00.00">
        </div>
      </div>

      <div class="buttons">
        <div class="btn btn-secondary flat" @click="back">
          {{$t('back')}}
        </div>
        <div class="btn btn-primary flat" @click="transfer">
          {{$t('confirm')}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "TransferModal",

    data() {
      return {
        toAccount: null,
        fromAccount: null,
        amount: null,
        show: false
      }
    },

    props: {
      selectedAccount: Number,
      visible: Boolean
    },

    validate: {
      toAccount: {
        required: true
      },
      fromAccount: {
        required: true
      },
      amount: {
        required: true,
        number: true,
        min: 0.01,
        maxDecimals: 2
      }
    },

    mounted() {
      this.init();
    },

    watch: {
      visible() {
        this.init();
      },
      amount() {
        if (!this.getAccount(this.fromAccount)) {
          return 0;
        }
        let maximumTransferableAmount = this.getAccount(this.fromAccount).balance;
        if (this.amount > maximumTransferableAmount) {
          this.amount = maximumTransferableAmount;
        }
        if (this.amount < 0) {
          this.amount = 0;
        }
      }
    },

    methods: {
      reset() {
        this.toAccount = null;
        this.fromAccount = null;
        this.amount = null;
      },
      getAccount(id) {
        return this.$user.loyalty.accounts.find(a => a.id == id);
      },
      init() {
        if (this.selectedAccount) {
          this.fromAccount = this.selectedAccount;
        }
        this.show = this.visible;
      },
      async transfer() {
        if (!this.$validate.valid()) {
          showAffirmation(this.$t("error.title"),this.$t("loyalty.create_invalid"));
          return;
        }
        showSpinner();
        let response = await this.$user.transferBalanceBetweenAccounts(this.fromAccount, this.toAccount, parseFloat(this.amount));
        hideSpinner();
        if (response) {
          showAffirmation(this.$t("success"),this.$t("loyalty.transfer_success"));
          this.$emit("created");
        } else {
          showAffirmation(this.$t("error.title"),this.$t("loyalty.transfer_error"));
        }
      },
      getAccountDisplayName(account) {
        let user = account.owner || this.$user
        return `${user.firstName} ${user.lastName}`.trim() || `@${user.username}`
      },
      back() {
        this.$emit("closed");
        this.show = false;
        this.reset();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #transferModal {
    z-index: 2;
  }
  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
  }
  .form {
    max-height: calc(100vh - 250px);
    padding-right: 10px;
    margin-right: -10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    margin-top: 20px;
    padding-top: 20px;
  }
  .input {
    margin-bottom: 0;
    padding-bottom: 0;
  }
</style>