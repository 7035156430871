<template>
  <div id="tableModal" class="modal" :class="{show: show}">
    <NumPadPanel class="table-num-pad" :title="$t('pos_table.title')" icon="icons/cloche.svg"
    :value="table" @update:value="table = $event" @onEnter="setTableNumber">
      <div class="table-num-pad-content">
        <Illustration src="icons/table-order.svg"></Illustration>
        <div class="table__description">{{$t('pos_table.description')}}</div>
        <div class="table__input">
          <label class="input__label">{{$t('pos_table.table_number')}}</label>
          <input type="text" :value="table" readonly>
        </div>
      </div>

      <template v-slot:footer>
        <div>
          <button class="btn btn-secondary" @click="back">
            <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
            {{$t('back')}}
          </button>
        </div>
      </template>
    </NumPadPanel>
  </div>
</template>

<script>
  import Illustration from "@/components/modal/Illustration";
  import NumPadPanel from "@/components/pos/NumPadPanel";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "TableModal",

    components: { Illustration, NumPadPanel },

    data() {
      return {
        table: "",
        show: false
      };
    },

    mounted() {
      EventBus.$on("show-table-modal", () => {
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-table-modal");
    },

    methods: {
      setTableNumber() {
        if (!this.table) {
          return;
        }
        //TODO assign this.table to this.$store.state.table.table probably
      },
      back() {
        this.show = false;
        this.table = null;
      }
    }
  };
</script>

<style lang="scss" scoped>

#iShopFoodApp #tableModal {
  .table-num-pad-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 380px;
    max-width: 380px;
    height: 100%;
    padding: 0 20px 0 40px;
    justify-content: space-around;

    .illustration {
      width: 100%;
      fill: var(--background-color-4);
    }

    .table__description {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      align-self: center;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    .table__input {
      display: flex;
      flex-direction: column;
      width: 100%;
      .input__label {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color-2);
        margin-bottom: 6px;
      }
      input {
        border-radius: 12px;
        border: 1px solid var(--background-color-4);
        padding: 10px 15px;
        font-size: 40px;
        line-height: 1;
        font-weight: 500;
        color: var(--theme-color);
        font-family: inherit;
      }
    }
  }
  .btn {
    font-size: 20px;
    font-weight: 500;
    padding: 15px 25px;
    width: auto;
    line-height: 1;
    font-family: 'Roboto', sans-serif;

    .svgicon {
      margin-right: 15px;
    }
  }
}

</style>