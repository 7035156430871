<template>
  <div :class="{
      disabled: isPaying,
      kiosk: $kiosk,
      ticketMode: $store.state.ticketMode,
      cart: cart,
      horizontal: kioskConfiguration && kioskConfiguration.isHorizontal(),
      confirmation: confirmation,
      'compact-cart-item': cartItemsMode === 'compact',
      'cart-item': cartItemsMode === 'classic'}">
    <div class="image icon" v-if="$store.state.ticketMode && !$store.getters.isMobile">
      <svgicon src="icons/ticket.svg" :height="40" :width="40"></svgicon>
    </div>
    <AsyncImage class="image" v-if="!$store.getters.isMobile && !$store.state.ticketMode" :fallback="$store.state.defaultItemImage" :image="image"></AsyncImage>
    <div class="content">
      <div class="title" v-if="!$kiosk">
        <AsyncImage class="image-mobile" v-if="$store.getters.isMobile && !$store.state.ticketMode" :fallback="$store.state.defaultItemImage" :image="image"></AsyncImage>
        <span class="name">{{ $to(item.getName()) }}</span>
        <div class="click edit-pen" :ref="'itemEdit' + item.id" @click="showTooltip" :class="{mobile: $store.getters.isMobileOrTablet}">
          <svgicon src="icons/modify-pen.svg" :height="20" :width="30"></svgicon>
        </div>
      </div>
      <div class="title" v-if="$kiosk">
        <div class="title--left">
          <span class="name">{{ $to(item.getName()) }}</span>
          <span class="quantity">{{ $t('quantity') }}: {{ item.quantity }}</span>
        </div>
        <div class="title--right">
          <div class="price">
            <div class="striked" v-if="(item.getTotalPrice($order.method).subTotalBeforePromotion - item.getTotalPrice($order.method).subTotal) > 0.01">
              {{ $tc(item.getTotalPrice($order.method).subTotalBeforePromotion.toFixed(2)) }}
            </div>
            {{ $tc(item.getTotalPrice($order.method).subTotal.toFixed(2)) }}
          </div>
        </div>
      </div>
      <template v-if="!$kiosk || ($kiosk && !cart)">
        <div class="details" v-if="flatModifierDetails.length > 0">
          <div class="spec" :class="{indent: !!detail.nested}" v-for="(detail, flatDetailIndex) of flatModifierDetails"
               :key="'flat-detail-' + index + flatDetailIndex">
            <span class="name">{{detail.name}} <template v-if="detail.quantity > 1">({{detail.quantity}})</template></span>
            <span class="price" v-if="detail.unitPrice > 0">
              + {{$tc(detail.unitPrice * detail.quantity)}}
            </span>
          </div>
        </div>
        <div class="details details-promo" v-for="(appliedPromotion, appliedPromotionIndex) of item.getAllAppliedPromotions()"
             :key="'item-promo' + index + appliedPromotionIndex">
          <div class="spec">
            <div class="name">
              <svgicon src="icons/promo.svg" :width="16" :height="16" style="flex-shrink: 0;"></svgicon>
              {{ $to(appliedPromotion.promotion.name) }}
            </div>
            <div class="price" v-if="appliedPromotion.value != 0">-{{ appliedPromotion.value.toFixed(2) }}$</div>
          </div>
        </div>
      </template>
      <div class="price" v-if="!$kiosk">
        <span class="quantity">{{ $t('quantity') }}: <span class="number">{{ item.quantity }}</span></span>
        <span class="amount">{{ $tc(item.getTotalPrice($order.method).subTotal.toFixed(2)) }}</span>
      </div>
    </div>
    <div class="cart-item-actions" v-if="$kiosk && cart">
      <div class="cart-item-edit" @click="showTooltip" @contextmenu="showTooltip" :ref="'itemEdit' + item.id">
        <svgicon src="icons/pencil-full.svg" :height="22" :width="22"></svgicon>
      </div>
    </div>
  </div>
</template>

<script>

  import EventBus from "../../lib/eventBus";
  import AsyncImage from "@/components/tools/AsyncImage";
  import Item from "../../models/ItemModel";

  export default {

    name: "cartItem",

    components: { AsyncImage },

    props: {
      item: Item|Object,
      cart: Boolean,
      confirmation: Boolean,
      index: Number
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      cartItemsMode() {
        if (!this.branch || !this.branch.style) {
          return "classic";
        }
        return this.branch.style.cartItemsMode || "classic";
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      flatModifierDetails() {
        return this.$order.getFlatDetailForItem(this.item, this.index, this.company).filter(d => d.line.length > 1);
      },
      isPaying() {
        return this.$route.name.startsWith("payment");
      },
      image() {
        if (this.item.image) {
          return this.$store.state.urlFileServer + this.item.image;
        }
        return this.$store.state.defaultItemImage;
      }
    },

    methods: {
      editItem() {
        this.$router.push(this.$router.generate("/itemEdit/" + this.index));
        this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
      },
      getPrice(item) {
        return this.$tc(Math.abs(item.quantity * item.getPrice(this.$order.method)).toFixed(2));
      },
      async removeItem() {
        let total = this.$order.getItemsTotal();
        // TODO this should not be done here. We should not have to know this behavior happens
        for (let orderItem of this.$order.items) {
          if (orderItem.appliedPromotion && orderItem.appliedPromotion.promotion && orderItem.appliedPromotion.promotion.minAmount > 0 &&
            total - this.item.getTotalPrice().subTotal < orderItem.appliedPromotion.promotion.minAmount) {
            let result = await showConfirmation(this.$t("promotion.title"), this.$t("promotion.promo_min_remove_1") +
              this.$to(orderItem.appliedPromotion.promotion.name) + this.$t("promotion.promo_min_remove_2"));
            if (!result) {
              return;
            }
          }
        }
        this.$order.removeItem(this.index);
      },
      showTooltip() {
        let items = [];
        if (!this.$store.state.ticketMode) {
          items.push({ tr: "edit", onclick: () => { this.editItem() } });
        }
        items.push({ tr: "delete", onclick: () => { this.removeItem() } });

        EventBus.$emit("tooltip", {
          parent: this.$refs["itemEdit" + this.item.id],
          items: items
        });
      }
    }

  }
</script>

<style lang="scss" scoped>
  .cart-item {
    padding: 20px;
    background-color: white;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    display: flex;
    flex-shrink: 0;
    border-radius: 10px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.mobile:not(.kiosk) {
      box-shadow: none;
      border-top: 1px solid rgb(230,230,230);
      border-bottom: 1px solid rgb(230,230,230);
      margin-bottom: 15px;
      border-radius: 0;

      .image {
        height: 100px;
        width: 100px;
      }

      .title {
        padding-bottom: 10px;
        .name {
          font-size: 18px;
          flex-grow: 1;
        }
      }

      .content > .price .amount {
        font-size: 18px;
      }

      &:last-of-type {
        margin-bottom: 15px;
      }
    }

    &.ticketMode {
      .name {
        text-align: left;
      }
    }
  }
  .click {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
    margin-bottom: -5px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .image {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    flex-shrink: 0;
    align-self: center;
    overflow: hidden;

    &.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eeeeee;
      width: 80px;
      height: 80px;
      .svgicon {
        fill: var(--primary-color);
      }
    }
  }
  .image-mobile {
    height: 35px;
    width: 35px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
  }
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      font-size: 15px;
      color: #808080;

      .number {
        font-weight: bold;
      }
      .amount {
        color: #1a1a1a;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    fill: #bfbfbf;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 8px;

    .name {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1;
    }
  }
  .details {
    padding: 10px 0 0;
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;

    .name {
      color: #808080;
    }

    .spec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #808080;
      padding-bottom: 8px;
      line-height: 1.1;

      .price {
        font-weight: bold;
        white-space: nowrap;
        margin-left: 8px;
      }
      .name {
        display: flex;
        .svgicon {
          fill: #fc6650;
          margin-right: 6px;
        }
      }

      &.indent {
        padding-left: 20px !important;
      }
    }
  }
  .cart-item.kiosk.confirmation, .compact-cart-item.kiosk.confirmation {
    box-shadow: none;
    padding: 0;

    &:not(:last-child) {
      padding-bottom: 30px;
    }
    &:last-child {
      .details {
        border-bottom: 0;
      }
    }
    .details {
      padding-bottom: 10px;
    }
    .image {
      display: none;
    }
    .title {
      border-bottom: 2px dashed #e6e6e6;

      .price {
        font-weight: 900;
        color: #ed2937;
        font-size: 24px;
        text-align: right;
        font-family: 'Segoe UI Black', sans-serif;

        .striked {
          color: #ed2937;
          text-decoration: line-through;
        }
      }
    }
    .title--left {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 24px;
        font-weight: bold;
      }
      .quantity {
        font-weight: bold;
        color: #808080;
        font-size: 20px;
        padding-top: 2px;
      }
    }

    .spec {
      font-size: 20px;
      padding: 2px 0;
    }
  }

  .cart-item.kiosk.cart, .compact-cart-item.kiosk.cart {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 10px 20px;
    min-width: 350px;
    max-width: 460px;
    flex-shrink: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    position: relative;
    height: 70px;

    &.horizontal {
      width: 430px;
      margin: 10px 30px;

      .title .name {
        max-width: 235px;
      }
    }

    .content {
      max-width: 390px;
    }

    .cart-item-actions {
      background-color: #fafafa;
      fill: #fc6650;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .cart-item-edit {
        padding: 20px 25px;
        flex-shrink: 0;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
      }
    }

    .image {
      display: none;
    }

    .title {
      display: flex;
      padding: 15px;
      border: none;

      .title--left {
        display: flex;
        flex-direction: column;
        padding-right: 20px;
      }
      .title--right {
        flex-grow: 1;
        justify-content: flex-end;
      }
      .price {
        font-weight: 900;
        color: #ed2937;
        font-size: 20px;
        text-align: right;
        font-family: 'Segoe UI Black', sans-serif;
        white-space: nowrap;
        .striked {
          color: #ed2937;
          text-decoration: line-through;
          font-size: 20px;
          line-height: 1;
        }
      }
      .name {
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }
      .quantity {
        font-size: 16px;
        color: #808080;
        padding-top: 2px;
        font-weight: bold;
      }
    }
  }
  .compact-cart-item:not(.kiosk) {
    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
    &.mobile {
      padding: 0 15px;
      &:last-of-type {
        margin-bottom: 25px;
      }
      .image-mobile {
        display: none;
      }
      .title .name {
        font-size: 16px;
      }
    }
    .image {
      display: none;
    }
    .title {
      align-items: flex-end;
      .name {
        font-size: 20px;
      }
    }
    .price {
      padding-top: 5px;
    }
    .click {
      margin-top: 0;
      margin-bottom: -10px;
    }

    .spec {
      padding-bottom: 0;

      &:last-child {
        padding-bottom: 8px;
      }
    }
  }
</style>

<style lang="scss">

  #iShopFoodApp .cartOptions .item {
    white-space: nowrap;
    padding: 6px 0;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    &.disabled {
      color: #bfbfbf;
      cursor: auto;
    }
  }

</style>
