//@ts-check
import Item from "./ItemModel";

/**
 * @typedef TagDefinition
 * @property {number} id
 */

export default class Tag {

  constructor() {
    this.reset();
  }

  reset() {
    this.id = /** @type {number} */ (null);
    this.items = /** @type {Item[]} */ ([]);
    this.choiceItems = /** @type {Item[]} */ ([]);
    this.name = /** @type {{fr: string, en: string}} */ (null);
    this.public = /** @type {boolean} */ (false);
    this.color = /** @type {string} */ (null);
  }

  /**
   * @param {{id: number, name: {fr: string, en: string}, public: boolean, color: string}} tag
   */
  load(tag) {
    this.id = tag.id;
    this.name = tag.name;
    this.public = tag.public;
    this.color = tag.color;
    return this;
  }

}