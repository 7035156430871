import QrPayInvoice from "./QrPayInvoiceModel";
import Store from "@/store.js";

/**
 * @typedef SaleDefinition
 * @property {Object[]} seats
 * @property {Boolean} locked
 * @property {PosInvoice[]} invoices
 */

export default class PosSale {

  /**
   * @param {SaleDefinition} definition
   * @param {string} tableNumber
   */
  constructor(definition, tableNumber) {
    this.tableNumber = tableNumber;
    this.seats = definition.seats || [];
    this.locked = definition.locked || false;

    //Construct seat items
    //Marie: I need explanation on this ? why is it needed? @Phil
    for (let seat of this.seats) {
      let items = [];
      for (let item of seat.items) {
        if (!item.parentId) {
          item.items = [];
          items.push(item);
        } else {
          items[items.length - 1].items.push(item);//shaky for demo, should find actual parent but data is gonna change
        }
      }
      seat.items = items;
    }

    //Construct invoices
    this.invoices = definition.invoices ? definition.invoices.map(i => new QrPayInvoice(i, this.seats)) : [];

    if (this.invoices.length === 1) {
      this.invoices[0].selected = true;
      this.invoices[0].open = true;
    }
  }

  /**
   * Check if sale is a valid state
   * @returns {boolean}
   */
  validate() {
    if (!this.hasInvoices()) {
      if (this.hasSeats()) {
        //seats: [ .. ], invoices: null-----> factures non gérées, message qui dit de demander au serveur/employé vos factures
        showAffirmation(window.translate('qr_payment.home.table_no_invoice_title'), window.translate('qr_payment.home.table_no_invoice_description'));
      } else {
        let company = Store.state.currentCompany;
        if (company.scanCheckOnlyForQrPayment()) { //Error message should be invoice related
          showAffirmation(window.translate('qr_payment.home.table_no_invoice_title'), window.translate('qr_payment.home.table_no_invoice_description'));
        } else {
          //invoices: null, seats: null-----> table n'existe pas
          showAffirmation(window.translate('qr_payment.home.table_not_found_title'), window.translate('qr_payment.home.table_not_found_description'));
        }
      }
      return false;
    }

    if (this.isPaid()) {
      showAffirmation(window.translate("qr_payment.table_paid"),
          window.translate("qr_payment.table_paid_description").replace("{TABLE}", this.tableNumber), window.translate("qr_payment.close"), false, true, "center");
      return false;
    }

    if (this.isLocked()) {
      showAffirmation(window.translate("qr_payment.table_locked"),
          window.translate("qr_payment.table_locked_description").replace("{TABLE}", this.tableNumber), window.translate("qr_payment.close"), false, false, "center");
      return false;
    }

    return true;
  }

  /**
   * @returns {PosInvoice[]}
   */
  getSelectedInvoices() {
    return this.invoices.filter(i => i.selected);
  }

  /**
   * @returns {number}
   */
  getSelectedInvoicesTotal() {
    let total = 0;
    for (let invoice of this.getSelectedInvoices()) {
      total += invoice.totalToPay;
    }
    return total;
  }

  /**
   * @param {Number} id
   * @returns {PosInvoice}
   */
  getInvoiceFromId(id) {
    return this.invoices.find(i => i.id === id);
  }

  /**
   * @returns {boolean}
   */
  isPaid() {
    return this.invoices.filter(i => i.isPaid()).length === this.invoices.length;
  }

  /**
   * @returns {boolean}
   */
  isLocked() {
    return this.locked;
  }

  /**
   * @returns {boolean}
   */
  hasSeats() {
    return this.seats && this.seats.length > 0;
  }

  /**
   * @returns {boolean}
   */
  hasInvoices() {
    return this.invoices && this.invoices.length > 0;
  }

}
