const VWave = {
  install(Vue) {
    Vue.directive("wave", {
      inserted(el, options) {
        if (options.value === undefined || options.value) {
          el.addEventListener("click", createWave);
        }
      },
      update(el, options) {
        let changed = options.oldValue !== options.value;
        if (!changed) {
          return;
        }
        if (options.value === undefined || options.value) {
          el.addEventListener("click", createWave);
        } else {
          el.removeEventListener("click", createWave);
        }
      }
    });
  }
};

function createWave(e) {

  // Calculate bounding box and wave origin
  let boundingBox = this.getBoundingClientRect();
  let zoom = parseFloat(window.getComputedStyle(document.getElementById("iShopFoodApp")).zoom) || 1;
  let x = (e.clientX - boundingBox.left * zoom) / zoom;
  let y = (e.clientY - boundingBox.top * zoom) / zoom;
  this.style.position = "relative";
  let computedStyle = window.getComputedStyle(this);

  // Create wave container
  let waveContainer = document.createElement("div");
  let borderRadius = parseInt(computedStyle.borderRadius.replace("px", ""));
  let borderWidth = parseInt(computedStyle.borderWidth.replace("px", ""));
  Object.assign(waveContainer.style, {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    pointerEvents: "none",
    borderRadius: borderRadius - borderWidth + "px"
  });

  // Create wave
  let wave = document.createElement("div");
  let color = computedStyle.color;
  let largestSide = Math.max(boundingBox.width, boundingBox.height) * 2;
  Object.assign(wave.style, {
    position: "absolute",
    width: largestSide + "px",
    height: largestSide + "px",
    top: y - largestSide / 2 + "px",
    left: x - largestSide / 2 + "px",
    borderRadius: "50%",
    background: color,
    opacity: "0.5",
    transform: "scale(0)",
    transition: "all 0.5s ease",
    pointerEvents: "none"
  });

  // Animate wave
  setTimeout(() => {
    wave.style.transform = "scale(1)";
    wave.style.opacity = "0";
  }, 10);

  // Remove wave from DOM after animation
  setTimeout(() => {
    waveContainer.removeChild(wave);
    if (this.contains(waveContainer)) {
      this.removeChild(waveContainer);
    }
  }, 500);

  // Append wave to DOM
  this.appendChild(waveContainer);
  waveContainer.appendChild(wave);

}

export default VWave;