<template>
  <div id="mobile-footer">

    <div class="progress-bar" :class="{alone: !showFooter}" v-if="$user.token && userLevel && isRankEnabled && showProgressBar">
      <svgicon class="icon" src="icons/rank.svg" :height="20" :width="20"></svgicon>
      <div class="rank">{{$to(userLevel.name)}}</div>
      <div class="progress-holder">
        <div class="progress-line" :style="{width: percent, 'background': 'linear-gradient(to left, ' + gradientColor + ', ' + primaryColor + ' 60%)'}"></div>
      </div>
      <div class="split"></div>
      <svgicon class="icon" src="icons/star-in-circle.svg" :height="20" :width="20"></svgicon>
      <div class="balance">{{$user.loyalty.getBalance().toFixed(2)}}$</div>
    </div>

    <div class="navigation" v-if="showFooter">
      <div class="back item" @click="back">
        <svgicon src="icons/back.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('back')}}</div>
      </div>
      <router-link :to="{name: 'menu'}" class="item">
        <svgicon src="icons/comptoir.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('cart.order')}}</div>
      </router-link>
      <router-link :to="{name: 'home'}" class="item" v-if="homePageActivated">
        <svgicon src="icons/home.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('navigation.home')}}</div>
      </router-link>
      <router-link :to="{name: 'loyaltyLevels'}" class="item" v-if="isRankEnabled && levels.length > 0">
        <svgicon src="icons/rank.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('loyalty.levels')}}</div>
      </router-link>
      <router-link :to="{name: 'hub'}" class="item" v-if="$user.token">
        <svgicon src="icons/profile.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('title.hub')}}</div>
      </router-link>
      <router-link :to="{name: 'login'}" class="item" v-if="!$user.token">
        <svgicon src="icons/profile.svg" :height="22" :width="22"></svgicon>
        <div class="text">{{$t('login.login')}}</div>
      </router-link>
    </div>

  </div>
</template>

<script>
  import tinycolor from "tinycolor2";

  export default {
    name: "mobileFooter",

    props: {
      showFooter: Boolean,
      showProgressBar: Boolean
    },

    methods: {
      back() {
        window.history.back();
        setTimeout(() => {
          this.$store.state.mobileMenuShown = false;
        }, 0);
      }
    },

    computed: {
      user() {
        return this.$user;
      },
      homePageActivated() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.homePage.activated;
      },
      branchLoyalty() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.loyaltyConfig;
      },
      isRankEnabled() {
        if (!this.branchLoyalty) {
          return false;
        }
        return this.branchLoyalty.data.rankEnabled;
      },
      levels() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        if (this.branchLoyalty.type === "ishopfood") {
          return this.branchLoyalty.data.levels;
        }
        return [];
      },
      points() {
        if (!this.$user.loyalty) {
          return 0;
        }
        return this.$user.loyalty.getTotalPoints();
      },
      userLevel() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.getLevelForPoints(this.points);
      },
      nextUserLevel() {
        if (!this.$store.state.currentBranch) {
          return;
        }
        return this.$store.state.currentBranch.getNextLevelForPoints(this.points);
      },
      percent() {
        if (!this.$user.loyalty || !this.nextUserLevel) {
          return;
        }
        let nextLevel = this.nextUserLevel.points;
        let value = Math.round((this.$user.loyalty.getTotalPoints() / nextLevel) * 100);
        if (value > 100) {
          return "100%";
        }
        return value + "%";
      },
      primaryColor() {
        return this.$store.state.primaryButtonColor || "#ed2937";
      },
      gradientColor() {
        return tinycolor(this.primaryColor).lighten(25).toHexString();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #mobile-footer {
    background-color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    fill: #ccc;
    color: rgb(128,128,128);
  }

  .navigation {
    height: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .item {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: inherit;
      min-width: 55px;

      &:visited, &:link {
        fill: inherit;
      }

      &.router-link-active {
        fill: #fc6650;
      }

      .text {
        font-size: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        height: 2em;
        padding: 0 10px;
        margin-top: 5px;
      }
    }

    &:nth-child(2) {
      border-top: 1px dashed #ccc;
    }
  }

  .progress-bar {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 12px;

    .progress-holder {
      flex-grow: 1;
      overflow: hidden;
      height: 10px;
      background-color: rgb(230,230,230);
      border-radius: 10px;

      .progress-line {
        height: 10px;
        border-radius: 10px;
      }
    }
    .rank {
      margin: 0 10px;
    }
    .balance {
      margin-left: 10px;
    }
    .split {
      width: 0;
      border-right: 1px solid rgb(230,230,230);
      height: 20px;
      margin: 0 10px;
    }
  }
</style>
