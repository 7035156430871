<template>
  <div class="item-extra-selector item-selector" @click="clicked" @contextmenu="contextMenuClicked" :class="{selected: extra.quantity > 0}">
    <Modifier ref="modifier" type="extra" :selector="extra" :refusedModifier="refusedModifier" :no-wave="noWave" :no-popup="noPopup"
              @changed="changeQuantity($event)" @click="changeQuantity(null)"></Modifier>
  </div>
</template>

<script>
  import Modifier from "@/components/menu/Modifier.vue";
  import EventBus from "@/lib/eventBus";
  export default {

    name: "itemExtraSelector",

    components: { Modifier },

    props: {
      extra: Object,
      parent: Object,
      noWave: Boolean,
      noPopup: Boolean,
      refusedModifier: Boolean
    },

    data() {
      return {
        showModifierModal: false,
        uuid: null,
        previousQuantity: 0
      };
    },

    mounted() {
      this.uuid = window.generateUUID();
      EventBus.$on("modifier-quantity-modal-changed", (data) => {
        if (data.uuid === this.uuid) {
          this.changeQuantity(data.quantity, true);
        }
      });
    },

    beforeDestroy() {
      EventBus.$off("modifier-quantity-modal-changed");
    },

    watch: {
      parent: {
        deep: true,
        handler() {
          setTimeout(() => {
            if (this.refusedModifier) {
              let parentModifiers = this.parent.getModifiers ? this.parent.getModifiers() : this.parent.modifiers;
              let modifierWithQuantity = parentModifiers.find(m => m.quantity > 0);
              if (modifierWithQuantity) {
                this.extra.quantity = 0;
              }
            }
          }, 0);
        }
      }
    },

    methods: {
      changeQuantity(quantity, force) {
        if (this.extra.outOfStock) {
          return;
        }
        this.$emit("changed", this);
        if (quantity || force) {
          this.extra.setQuantity(quantity);
          return;
        }
        if (this.extra.quantity > 0) {
          this.extra.setQuantity(0);
        } else {
          this.extra.setQuantity(1);
        }
      },
      quantityChanged(quantity) {
        this.extra.quantity = quantity;
      },
      initializeAndShowModifierModal() {
        EventBus.$emit("show-modifier-quantity-modal", {
            modifier: this.extra,
            uuid: this.uuid,
            ref: this.$refs.modifier
          });
      },
      contextMenuClicked(e) {
        if (this.$kiosk) {
          this.clicked(e);
        }
      },
      clicked(e) {
        if (this.$kiosk && this.extra.quantifiable && !this.refusedModifier && !this.extra.outOfStock) {
          if (e.target && e.target.closest('.modifierQuantityModal')) {
            return;
          }
          this.initializeAndShowModifierModal();
        } else if (this.$pos && this.extra.quantifiable) {
          if (this.$store.state.quantityBuffer) {
            this.changeQuantity(parseInt(this.$store.state.quantityBuffer), true);
            this.$store.state.quantityBuffer = null;
          } else {
            this.changeQuantity(this.extra.quantity > 0 ? 0 : 1, true);
          }
          return;
        }
        /** Default Click */
        if (this.refusedModifier) {
          this.$emit("click");
        } else if (this.extra.quantity === 0 && this.extra.quantifiable) {
          this.changeQuantity();
        }
      }
    }
  }

</script>
