<template>
  <div id="newPassword">

    <PageTitle>
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      <div class="title">
        {{ $t('login.login') }}
      </div>
    </PageTitle>

    <div class="page-content">
      <Panel :title="$t('newPassword.title')" icon="icons/lock.svg" @keyup.enter.native="sendNewPassword">
        <div class="description">{{ $t('newPassword.description') }}</div>
        <label for="txtPassword">{{ $t('newPassword.title') }}</label>
        <input type="password" id="txtPassword" v-model="newPassword">
        <label for="txtPasswordConfirmation">{{ $t('newPassword.confirmation') }}</label>
        <input type="password" id="txtPasswordConfirmation" v-model="newPasswordConfirmation">

        <div class="password-requirements">
          <div class="subtitle">{{$t('register.requirements')}}</div>
          <div class="requirement" :class="{valid: passwordIsLongEnough, invalid: newPassword && !passwordIsLongEnough}">
            <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            {{$t('register.length_requirement')}}
          </div>
          <div class="requirement" :class="{valid: passwordHasLetters, invalid: newPassword && !passwordHasLetters}">
            <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            {{$t('register.letters_requirement')}}
          </div>
          <div class="requirement" :class="{valid: passwordHasNumbers, invalid: newPassword && !passwordHasNumbers}">
            <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            {{$t('register.numbers_requirement')}}
          </div>
          <div class="requirement" :class="{valid: passwordHasSpecialChars, invalid: newPassword && !passwordHasSpecialChars}">
            <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="newPassword"></svgicon>
            {{$t('register.chars_requirement')}}
          </div>
        </div>

        <div class="buttons">
          <button type="button" class="btn btn-primary" @click="sendNewPassword">{{ $t('newPassword.save') }}</button>
        </div>
      </Panel>
    </div>

  </div>
</template>

<script>

  import { mapActions } from 'vuex';

  export default {

    name: "newPassword",

    data() {
      return {
        newPassword: "",
        newPasswordConfirmation: ""
      };
    },

    computed: {
      passwordIsLongEnough() {
        return this.newPassword.length >= 8;
      },
      passwordHasLetters() {
        return /[a-zA-Z]/g.test(this.newPassword);
      },
      passwordHasNumbers() {
        return /[0-9]/g.test(this.newPassword);
      },
      passwordHasSpecialChars() {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(this.newPassword);
      },
      passwordMeetsRequirements() {
        return this.passwordIsLongEnough && this.passwordHasLetters && this.passwordHasNumbers && this.passwordHasSpecialChars;
      },
    },

    mounted() {
      this.$store.commit("showNavigationCart", !!this.$route.query.redirect);
    },

    methods: {
      ...mapActions({
        newPasswordAction: "newPassword"
      }),
      async sendNewPassword() {
        if (!this.passwordMeetsRequirements) {
          showAffirmation(this.$t("error.title"), this.$t("register.error_requirement"));
          return;
        }

        if (this.newPassword !== this.newPasswordConfirmation) {
          showAffirmation(this.$t('error.title'), this.$t('register.password_does_not_match'));
          return;
        }
        showSpinner();
        let response = await this.newPasswordAction({
          token: this.$route.query.token,
          password: this.newPassword
        });
        hideSpinner();
        if (response.success) {
          await showAffirmation(this.$t('login.forgot_password_title'), this.$t("login.changed_password"));
          this.$router.push(this.$router.generate("/login?redirect=orderType"));
        } else {
          showAffirmation(this.$t('error.title'), this.$t("login.could_not_reset_password"));
        }
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 0;
    display: flex;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .panel {
    width: 520px;
    max-width: 100%;
    margin: auto !important;
    input {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .description {
    margin-bottom: 20px;
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      &:after {
        height: 0;
      }
    }
  }

  #iShopFoodApp .password-requirements {
    font-size: 14px;
    margin-bottom: 20px;

    .subtitle {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .requirement {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .icon {
        margin-right: 7px;
      }
      &.valid {
        fill: #2a9e45 !important;
        .icon__invalid {
          display: none;
        }
      }
      &.invalid {
        fill: #de9c09 !important;
        .icon__valid {
          display: none;
        }
      }
    }
  }
</style>
