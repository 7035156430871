<template>
  <div class="row location detailed" :class="{posCustomerEdit: $pos && alwaysShowAll}">
    <template v-if="branch && !$user.shouldCreateAddress()">
      <div class="user-addresses input" :class="{all: shouldShowAllUserAddresses}">
        <label class="title" v-if="!dispatch && !shouldShowAllUserAddresses">{{$t('profile.my_address')}} :</label>
        <label class="title" v-if="!dispatch && shouldShowAllUserAddresses">{{$t('profile.my_addresses')}} :</label>
        <label class="title" v-if="dispatch">{{ $t('profile.address') }}</label>
        <div class="user-address" v-for="(address, addressIndex) of $user.addresses" :key="'address-' + addressIndex"
             v-show="address == $user.getDefaultAddress() || shouldShowAllUserAddresses" @click="setDefaultUserAddress(addressIndex)">
          <div class="address-parts">
            <div class="address-part street" v-if="address.fullAddress">{{address.fullAddress}}<template v-if="address.app">, {{$t('order_options.apt')}} {{address.app}}</template></div>
            <div class="address-part city" v-if="address.city">{{address.city}}<template v-if="$store.getters.isMobile && address.postalCode">, {{address.postalCode}}</template></div>
            <div class="address-part zip" v-if="address.postalCode && !$store.getters.isMobile">{{address.postalCode}}</div>
          </div>
          <div class="icon" v-if="!alwaysShowAll">
            <svgicon src="icons/address.svg" :height="20" :width="20" v-if="!showAllUserAddresses"></svgicon>
            <template v-if="showAllUserAddresses">
              <svgicon src="icons/check-circle-full.svg" :height="20" :width="20" v-if="address === $user.getDefaultAddress()"></svgicon>
              <svgicon src="icons/circle-empty.svg" :height="20" :width="20" v-if="address !== $user.getDefaultAddress()"></svgicon>
            </template>
          </div>
          <div class="icon" v-if="alwaysShowAll" @click="changeDefaultUserAddress(addressIndex)">
            <svgicon src="icons/fav-active.svg" :height="24" :width="24" v-if="address === $user.getDefaultAddress()"></svgicon>
            <svgicon src="icons/fav-inactive.svg" :height="24" :width="24" v-if="address !== $user.getDefaultAddress()"></svgicon>
          </div>
          <div class="icon" v-if="alwaysShowAll" @click="removeUserAddress(addressIndex)">
            <svgicon src="icons/trash.svg" :height="20" :width="20" :class="{hidden: address === $user.getDefaultAddress()}"></svgicon>
          </div>
        </div>
      </div>
      <div class="button-holder">
        <button class="btn btn-secondary custom flat" @click="showAllUserAddresses = true" v-if="!shouldShowAllUserAddresses && $user.addresses.length > 1">
          {{ $t("profile.change_address") }}
        </button>
        <button class="btn btn-secondary custom flat" @click="createNewUserAddress" v-if="shouldShowAllUserAddresses || $user.addresses.length === 1">
          {{ $t("profile.enter_new_address") }}
        </button>
        <button class="btn btn-primary flat" @click="confirmUserAddress" v-if="!noButton">
          {{ $t("confirm") }}
        </button>
      </div>
    </template>

    <template v-if="branch && $user.shouldCreateAddress()">
      <div class="input grow">
        <label class="title" v-if="hasZones">
          <template v-if="shouldEnableAutocomplete">{{$t('order_options.full_address') }}</template>
          <template v-if="!shouldEnableAutocomplete">{{ $t('payment.full_address') }}</template>
        </label>
        <label class="title" v-if="!hasZones">{{ $t('order_options.only_zipcode')}}</label>
        <template v-if="shouldEnableAutocomplete && hasZones">
          <AutocompleteField :prefillAddress="prefillAddress" @changed="withGeocodingOrZipcode(false)" :type="type"></AutocompleteField>
        </template>
        <template v-if="!shouldEnableAutocomplete && hasZones">
          <input id="autocomplete" class="input" style="margin-bottom:0;" name="address" type="text"
                 v-model="$user.getDefaultAddress().fullAddress" :placeholder="$t('payment.full_address')">
        </template>
        <input id="zipcodeInput" type="text" v-model="$user.getDefaultAddress().postalCode"
               :class="{invalid: validate ? validate.error('$user.getDefaultAddress().postalCode') : false}"
               v-if="!hasZones" placeholder="Ex : A1B 2C3">
      </div>
      <div class="input app" v-if="hasZones">
        <label class="title">{{$t('order_options.apt')}} :</label>
        <input type="text" style=" max-width: 80px" v-model="$user.getDefaultAddress().app">
      </div>
      <div class="detailed-form">
        <div class="input">
          <label>{{$t('payment.zip')}}</label>
          <input id="txtPostalCode" type="text" v-model="$user.getDefaultAddress().postalCode" :placeholder="$t('payment.zip')">
        </div>
        <div class="input city">
          <label>{{ $t("profile.city") }}</label>
          <input id="txtCity" type="text" v-model="$user.getDefaultAddress().city" :placeholder="$t('profile.city')">
        </div>
      </div>
      <div class="input note grow" v-if="showDeliveryNote">
        <label class="title">{{$t('payment.delivery_note')}} :</label>
        <textarea :placeholder="$t('payment.delivery_note')" v-model="$user.getDefaultAddress().note"></textarea>
      </div>

      <div class="button-holder">
        <div class="filler" v-if="!showCustomCompanyButton || !searchedAddressAtLeastOnce">&nbsp;</div>
        <button class="btn btn-secondary custom flat" @click="customCompany" v-if="showCustomCompanyButton && searchedAddressAtLeastOnce">
          {{ $t("order.custom_company") }}
        </button>
        <div class="right" :class="{'no-back': $user.addresses.length <= 1}">
          <button class="btn btn-secondary flat" @click="cancelNewUserAddress" v-if="$user.addresses.length > 1">
            {{ $t("back") }}
          </button>
          <button class="btn btn-primary flat" @click="withGeocodingOrZipcode(true)" v-if="!noButton">
            {{ $t("confirm") }}
          </button>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
  import Address from "../../models/AddressModel";
  import AutocompleteField from "@/components/AutocompleteField.vue";
  import GeocodingHelper from "../../lib/geocodingHelper";

  export default {
    name: "userLocation",

    components: {
      AutocompleteField
    },

    props: {
      prefillAddress: Boolean,
      type: String,
      showCustomCompanyButton: Boolean,
      noButton: Boolean,
      forceDisableAutocomplete: Boolean,
      forceAllFields: Boolean,
      showDeliveryNote: Boolean,
      noZoneValidation: Boolean,
      alwaysShowAll: Boolean,
      allowDelete: Boolean,
      validate: Object,
      dispatch: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        searchedAddressAtLeastOnce: false,
        showAllUserAddresses: false
      };
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      hasZones() {
        return this.branch.hasZones() || this.forceAllFields;
      },
      shouldShowAllUserAddresses() {
        return this.showAllUserAddresses || this.alwaysShowAll;
      },
      shouldEnableAutocomplete() {
        if (this.forceDisableAutocomplete) {
          return false;
        }
        if (this.type && (this.type === "order-type")) {
          return this.branch && this.branch.autocompleteOnOrderType;
        }
        if (this.type && this.type === "payment") {
          return this.branch && this.branch.autocompleteOnPayment;
        }
        return false;
      },
      geocodeService() {
        return this.branch ? this.branch.getGeocodeService() : "google";
      }
    },

    methods: {
      confirmUserAddress() {
        this.showAllUserAddresses = false;
        this.$emit("changed", true);
      },

      changeDefaultUserAddress(index) {
        this.$user.setDefaultUserAddress(index);
        if (this.$store.state.quickMode || this.dispatch) {
          this.$emit("changed", true);
        }
      },

      removeUserAddress(index) {
        if (index === this.$user.getDefaultAddressIndex()) {
          return;
        }
        this.$user.addresses.splice(index, 1);
      },

      setDefaultUserAddress(index) {
        if (this.$user.addresses.length === 1 || this.alwaysShowAll) {
          return;
        }
        if (index === this.$user.getDefaultAddressIndex() && !this.showAllUserAddresses) {
          this.showAllUserAddresses = true;
          return;
        }
        if (!this.showAllUserAddresses) {
          return;
        }
        this.showAllUserAddresses = false;
        this.changeDefaultUserAddress(index);
      },

      createNewUserAddress() {
        this.$user.createNewAddress();
        this.showAllUserAddresses = false;
      },

      cancelNewUserAddress() {
        this.$user.cancelNewAddress();
        this.showAllUserAddresses = true;
        this.$emit("cancel");
      },

      async withGeocoding(confirmChange) {
        let value = document.getElementById("autocomplete").value;
        if (!this.shouldEnableAutocomplete && !this.$user.getDefaultAddress().fullAddress) {
          showAffirmation(this.$t("error.user.missing_info"), this.$t("error.user.full_address_empty"));
          return;
        }
        if (this.$user.getDefaultAddress().postalCode) {
          value += ", " + this.$user.getDefaultAddress().postalCode;
        } else {
          showAffirmation(this.$t("error.user.missing_info"), this.$t("error.user.postal_code_empty"));
          return;
        }
        if (this.$user.getDefaultAddress().city) {
          value += ", " + this.$user.getDefaultAddress().city;
        } else {
          showAffirmation(this.$t("error.user.missing_info"), this.$t("error.user.city_empty"));
          return;
        }
        if (!Address.isValidAddressFormat(value)) {
          showAffirmation(this.$t("error.title"), this.$t("error.user.address_num_missing"));
          return;
        }
        showSpinner();
        this.currentCompany = null;
        switch (this.geocodeService) {
          case "here": {
            let result = await GeocodingHelper.geocodeWithHere(value);
            this.branch.logGeocoding({ service: "here", type: "geocoding", source: this.type });
            hideSpinner();
            if (result) {
              this.$user.updateAddressFromHere(result.address, result.position, document.getElementById("autocomplete").value);
              if (confirmChange) {
                this.$user.confirmAddress();
                this.$emit("changed", true);
              }
            } else {
              showAffirmation(this.$t("error.user.invalid_address"), this.$t("error.user.address_not_found"));
            }
            break;
          }
          case "google":
          default: {
            let result;
            try {
              result = await GeocodingHelper.geocodeWithGoogle(value);
              this.branch.logGeocoding({ service: "google", type: "geocoding", source: this.type });
              hideSpinner();
              if (result) {
                this.$user.updateAddressFromGoogle(result.address_components, result.geometry.location, value);
                if (confirmChange) {
                  this.$user.confirmAddress();
                  this.$emit("changed", true);
                }
              }
            } catch (e) {
              console.log(e);
              hideSpinner();
              this.$ga.event(
                "google-geocoding-error",
                this.$store.state.currentCompany ? this.$store.state.currentCompany.nameCanonical : this.$store.state.currentBranch.nameCanonical,
                this.$user.getDefaultAddress().getGeocodingInformations());
              showAffirmation(this.$t("error.user.invalid_address"), this.$t("error.user.address_not_found"));
            }
            break;
          }
        }
      },

      customCompany() {
        this.$emit("custom-company");
      },

      withZipcode(confirmChange) {
        if (confirmChange) {
          this.$emit("changed");
        }
      },

      withGeocodingOrZipcode(confirmChange) {
        if (document.getElementById("autocomplete") && document.getElementById("autocomplete").value) {
          this.$user.getDefaultAddress().fullAddress = document.getElementById("autocomplete").value;
        }
        let validation = this.$user.getDefaultAddress().validate();
        if (!validation.valid) {
          showAffirmation(this.$t("error.user.missing_info"), this.$t(validation.errorKey));
          return;
        }
        this.searchedAddressAtLeastOnce = true;
        if (document.getElementById("autocomplete")) {
          document.getElementById("autocomplete").blur();
        }
        if (this.branch && this.branch.hasZones()) {
          return this.withGeocoding(confirmChange);
        }
        return this.withZipcode(confirmChange);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    &.pos {
      .row {
        label {
          font-size: 18px !important;
        }
        .user-address .address-part {
          font-size: 20px;
        }
      }
    }
    .row {
      display: flex;
      input, select, .vdatetime {
        height: 44px;
        flex-grow: 1;
        flex-basis: 0;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      button:not(.btn-primary) {
        margin-right: 4px;
        box-shadow: none !important;
        padding-left: 0;
        border: 1px solid transparent !important;
        background-color: transparent !important;
      }
    }
    .location {
      align-items: flex-end;
      flex-wrap: wrap;
      flex-shrink: 0;

      &.posCustomerEdit {
        .user-address {
          border-color: var(--border-color-5);
        }
        .user-address .address-parts .address-part {
          font-size: 20px;
          font-weight: bold;
          color: var(--theme-color);
          padding-right: 30px;

          &.street {
            max-width: 700px;
          }

          &:after {
            font-weight: normal;
            right: 15px;
            color: var(--border-color-5);
            opacity: 1;
          }
        }
        .icon {
          padding: 10px 20px;
        }
      }

      .button-holder {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        margin-top: 10px;
        .right {
          display: flex;

          .btn:not(:last-child) {
            margin-right: 2px;
          }
        }
      }

      .grow {
        flex-grow: 1;
      }

      .input:not(#autocomplete) {
        padding: 0;
        margin: 0 10px 10px 0;
      }

      label {
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 8px !important;
      }
    }
    .detailed-form {
      display: flex;
      width: 100%;

      .input {
        padding-top: 0;
        flex-grow: 1;
      }
    }

    .user-addresses {
      display: flex;
      flex-direction: column;
      width: 100%;

      &.all {
        .user-address {
          cursor: pointer;
        }
      }
    }

    .user-address {
      display: flex;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      padding: 20px 15px;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .address-parts {
        display: flex;
        align-items: center;
        flex-grow: 1;

        .address-part {
          color: #808080;
          font-size: 16px;
          position: relative;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 260px;
          overflow: hidden;

          &:last-child {
            padding-right: 15px;
          }
        }

        .address-part:not(:last-child) {
          padding-right: 20px;
        }

        .address-part:not(:last-child):after {
          content: "|";
          position: absolute;
          opacity: 0.5;
          right: 10px;
        }
      }

      .icon {
        fill: var(--primary-color);
        padding: 0 0 0 20px;

        .svgicon.hidden {
          user-select: none;
          display: flex !important;
          filter: grayscale(100%);
          opacity: 0.5;
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp {
      .user-address {
        padding: 10px 15px;
        .address-parts {
          flex-direction: column;
          align-items: flex-start;
          .address-part {
            font-size: 14px;

            &:after {
              content: none !important;
            }
          }
        }
      }
    }
  }
</style>
