<template>
  <div id="reload" :class="{desktop: isDesktop}">

    <PageTitle show-back-button>
      {{ $t("loyalty.reload") }}
    </PageTitle>

    <div class="page-content" v-if="isDesktop">

      <Panel id="panelRecharge" :title="panelTitle" icon="icons/card.svg"
             collapsible :collapsed="closeReload">
        <div class="disclaimer" v-if="account && account.user"><b>{{$t('important')}}: </b>{{$t('loyalty.reload_child1')}}<b>{{account.user.username}}</b>{{$t('loyalty.reload_child2')}}</div>
        <div class="payment">
          <div class="left">
            <div class="card">
              <div class="title">
                {{$t('loyalty.ewallet_amount')}}<template v-if="account && account.user">:<br><b>{{account.user.username}}</b></template>
              </div>
              <div class="hr"></div>
              <div class="price">
                <div class="amount">{{ Math.floor(balance) }}</div>
                <div class="cents">{{ leftPad(Math.round(balance % 1 * 100), 2, "0") }}$</div>
              </div>
              <div class="promos desktop" v-if="promotions.length > 0">
                <div class="title">{{$t('loyalty.promotions')}}</div>
                <div v-for="promotion of promotions" class="promotion card" :class="{applied: appliedPromotion === promotion}">
                  <div class="icon">
                    <svgicon src="icons/promo.svg" :height="24" :width="24"></svgicon>
                  </div>
                  <span class="text" v-html="getPromotionAsText(promotion)"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <StandalonePaymentForm ref="paymentForm" @submit="dispatchSend" @deletedToken="initializeSavedCard" @savedCardChanged="savedCardID = $event">
              <div class="summary input" v-if="promotions.length > 0 && appliedPromotion">
                <label>{{$t('loyalty.summary')}}</label>
                <div class="totals desktop">
                  <div class="total-item">
                    <label>{{$t('loyalty.total_to_pay')}}</label>
                    <div class="value">{{parseFloat(paymentForm.getAmount() || 0).toFixed(2)}}$</div>
                  </div>
                  <div class="total-item promo">
                    <label>{{$t('loyalty.promotion')}} {{appliedPromotion.rewardAmount + getRewardSymbol(appliedPromotion.rewardType)}}</label>
                    <div class="value">{{promotionalAmount.toFixed(2)}}$</div>
                  </div>
                  <div class="total-item">
                    <label>{{$t('loyalty.total')}}</label>
                    <div class="value">{{(parseFloat(paymentForm.getAmount() || 0) + promotionalAmount).toFixed(2)}}$</div>
                  </div>
                </div>
              </div>
            </StandalonePaymentForm>

            <div class="buttons">
              <div class="btn btn-secondary" @click="back">{{ $t("back") }}</div>
              <template v-if="!shouldDisplayPayfactoButton">
                <div class="btn btn-primary" @click="send">{{ $t("confirm") }}</div>
              </template>
              <template v-if="shouldDisplayPayfactoButton">
                <div class="btn btn-primary" @click="showPayfactoModal">
                  <template v-if="hasSavedCardSelected">{{ $t('loyalty.complete_payment') }}</template>
                  <template v-if="!hasSavedCardSelected">{{ $t("loyalty.to_payment") }}</template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </Panel>

      <Panel class="automatic-recharge-panel" :title="$t('loyalty.automatic_reload')" v-if="isAutomaticRechargeEnabledOnBranch" collapsible>
        <!--<p>{{$t('loyalty.automatic_recharge_description')}}</p>-->
        <template v-if="!availableTokens || availableTokens.length === 0">
          <div class="warning">
            {{ $t('loyalty.auto_recharge_disclaimer') }}
          </div>
        </template>
        <template v-if="availableTokens && availableTokens.length > 0">
          <div class="disclaimer" v-if="account && account.user"><b>{{$t('important')}}: </b>{{$t('loyalty.auto_reload_child1')}}<b>{{account.user.username}}</b>{{$t('loyalty.auto_reload_child2')}}</div>
          <div class="input">
            <input id="chkAutoRecharge" type="checkbox" v-model="accountConfig.autoRecharge">
            <label for="chkAutoRecharge">{{ $t('loyalty.enable_automatic_recharge') }}</label>
          </div>
          <div class="columns" v-if="accountConfig.autoRecharge">
            <div class="column input">
              <label>{{$t('loyalty.select_a_saved_card')}}</label>
              <div class="cards">
                <div class="token-card" v-for="card of availableTokens" :key="'card' + card.id" @click="selectRechargeCard(card.id)">
                  <div class="check">
                    <input :id="'radioCard' + card.id" type="radio" :value="card.id" v-model="accountConfig.autoRechargeConfig.billingToken">
                    <label :for="'radioCard' + card.id"></label>
                  </div>
                  <div class="number">
                    <label>{{$t('payment.card_number')}}</label>
                    <div class="value">
                      **** **** **** {{card.last4}}
                    </div>
                  </div>
                  <div class="exp">
                    <label>{{$t('payment.expiration_date')}}</label>
                    <div class="value">
                      {{(card.exp || "").substring(0, 7)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column fields">
              <div class="input amount-input">
                <label for="reloadAmount">{{ $t("loyalty.reload_up_to") }}</label>
                <input id="reloadAmount" type="number" class="amount" v-model="accountConfig.autoRechargeConfig.rechargeUpTo">
              </div>
              <div class="input amount-input">
                <label for="minAmount">{{ $t("loyalty.when_balance_falls_below") }}</label>
                <input id="minAmount" type="number" class="amount" v-model="accountConfig.autoRechargeConfig.whenFallBelow">
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="btn btn-primary" @click="saveAutomaticRecharge">{{ $t("loyalty.save_changes") }}</div>
          </div>
        </template>
      </Panel>
    </div>

    <div id="panelRecharge" class="page-content" v-if="!isDesktop">
      <div class="bigcircle" :style="{'background': 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 75%)'}"></div>

      <HeaderClip>
        <div class="circleSpacer">
          <div class="balance">
            <div class="amount">{{ Math.floor(balance) }}</div>
            <div class="cents">{{ leftPad(Math.round(balance % 1 * 100), 2, "0") }}$</div>
          </div>
          <div class="description">
            <template v-if="account && account.user">
              {{$t('loyalty.account_balance')}}: <b>{{account.user.username}}</b>
            </template>
            <template v-else>
              {{ $t("loyalty.my_balance") }}
            </template>
          </div>
        </div>
      </HeaderClip>

      <div class="promos" v-if="promotions.length > 0">
        <div class="title">{{$t('loyalty.promotions')}}</div>
        <div v-for="promotion of promotions" class="promotion card" :class="{applied: appliedPromotion === promotion}">
          <div class="icon">
            <svgicon src="icons/promo.svg" :height="24" :width="24"></svgicon>
          </div>
          <span class="text" v-html="getPromotionAsText(promotion)"></span>
        </div>
      </div>

      <div class="recharge-section collapsible" :class="{open: openRecharge}">
        <div class="collapse-title" @click="openRecharge = !openRecharge">
          <span class="title">
            {{$t('loyalty.reload')}}
            <span class="user" v-if="account && account.user">
              : {{account.user.username}}
            </span>
          </span>
          <svgicon src="icons/arrow.svg" :height="10" :width="20"></svgicon>
        </div>
        <div class="disclaimer" v-if="account && account.user"><b>{{$t('important')}}: </b>{{$t('loyalty.reload_child1')}}<b>{{account.user.username}}</b>{{$t('loyalty.reload_child2')}}</div>
        <StandalonePaymentForm class="collapse-content" ref="paymentForm" @submit="dispatchSend" @deletedToken="initializeSavedCard"
                               @savedCardChanged="savedCardID = $event">
          <div class="summary input" v-if="promotions.length > 0 && appliedPromotion">
            <label>{{$t('loyalty.summary')}}</label>
            <div class="totals desktop">
              <div class="total-item">
                <label>{{$t('loyalty.total_to_pay')}}</label>
                <div class="value">{{parseFloat(paymentForm.getAmount() || 0).toFixed(2)}}$</div>
              </div>
              <div class="total-item promo">
                <label>{{$t('loyalty.promotion')}} {{appliedPromotion.rewardAmount + getRewardSymbol(appliedPromotion.rewardType)}}</label>
                <div class="value">{{promotionalAmount.toFixed(2)}}$</div>
              </div>
              <div class="total-item">
                <label>{{$t('loyalty.total')}}</label>
                <div class="value">{{(parseFloat(paymentForm.getAmount() || 0) + promotionalAmount).toFixed(2)}}$</div>
              </div>
            </div>
          </div>
        </StandalonePaymentForm>

        <div class="buttons collapse-content">
          <template v-if="!shouldDisplayPayfactoButton">
            <div class="btn btn-primary" @click="send">{{ $t("confirm") }}</div>
          </template>
          <template v-if="shouldDisplayPayfactoButton">
            <div class="btn btn-primary" @click="showPayfactoModal">
              <template v-if="hasSavedCardSelected">{{ $t('loyalty.complete_payment') }}</template>
              <template v-if="!hasSavedCardSelected">{{ $t("loyalty.to_payment") }}</template>
            </div>
          </template>
        </div>
      </div>
      <div class="automatic-recharge-section collapsible" v-if="isAutomaticRechargeEnabledOnBranch" :class="{open: openAutomaticRecharge}">
        <div class="collapse-title" @click="openAutomaticRecharge = !openAutomaticRecharge">
          <span class="title">
            {{$t('loyalty.automatic_reload')}}
          </span>
          <svgicon src="icons/arrow.svg" :height="10" :width="20"></svgicon>
        </div>
        <div class="collapse-content">
          <template v-if="!availableTokens || availableTokens.length == 0">
            <div class="warning">
              {{ $t('loyalty.auto_recharge_disclaimer') }}
            </div>
          </template>
          <template v-if="availableTokens && availableTokens.length > 0">
            <!--<p>{{$t('loyalty.automatic_recharge_description')}}</p>-->
            <div class="disclaimer" v-if="account && account.user"><b>{{$t('important')}}: </b>{{$t('loyalty.auto_reload_child1')}}<b>{{account.user.username}}</b>{{$t('loyalty.auto_reload_child2')}}</div>
            <div class="input">
              <input id="chkAutoRechargeMob" type="checkbox" v-model="accountConfig.autoRecharge">
              <label for="chkAutoRechargeMob">{{ $t('loyalty.enable_automatic_recharge') }}</label>
            </div>
            <template v-if="accountConfig.autoRecharge">
              <div class="input">
                <label>{{$t('loyalty.select_a_saved_card')}}</label>
                <div class="token-card" v-for="card of availableTokens" :key="'card' + card.id" @click="selectRechargeCard(card.id)">
                  <div class="check">
                    <input :id="'radioCard' + card.id" type="radio" :value="card.id" v-model="accountConfig.autoRechargeConfig.billingToken">
                    <label :for="'radioCard' + card.id"></label>
                  </div>
                  <div class="number">
                    <label>{{$t('payment.card_number')}}</label>
                    <div class="value">
                      **** **** **** {{card.last4}}
                    </div>
                  </div>
                  <div class="exp">
                    <label>{{$t('payment.expiration_date')}}</label>
                    <div class="value">
                      {{(card.exp || "").substring(0, 7)}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="input amount-input">
                <label for="reloadAmountMob">{{ $t("loyalty.reload_up_to") }}</label>
                <input id="reloadAmountMob" type="number" class="amount" v-model="accountConfig.autoRechargeConfig.rechargeUpTo">
              </div>
              <div class="input amount-input">
                <label for="minAmountMob">{{ $t("loyalty.when_balance_falls_below") }}</label>
                <input id="minAmountMob" type="number" class="amount" v-model="accountConfig.autoRechargeConfig.whenFallBelow">
              </div>
            </template>
            <div class="buttons fds">
              <div class="btn btn-primary" @click="saveAutomaticRecharge">{{ $t("loyalty.save_changes") }}</div>
            </div>
          </template>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import HeaderClip from "../../components/HeaderClip.vue";
  import tinycolor from "tinycolor2";
  import Axios from "axios";
  import StandalonePaymentForm from "../../components/payment/StandalonePaymentForm.vue";

  export default {

    name: "reload",

    components: { HeaderClip, StandalonePaymentForm },

    data() {
      return {
        account: null,
        ready: false,
        openRecharge: true,
        openAutomaticRecharge: true,
        savedCardID: null,
        availableTokens: [],
        accountConfig: {
          autoRecharge: false,
          autoRechargeConfig: {
            whenFallBelow: 10,
            rechargeUpTo: 50,
            billingToken: null,
          },
        }
      };
    },

    mounted() {
      this.$store.commit("showNavigationCart", true);

      if (!this.$user || !this.$user.getLoyaltyAccount() || !this.branch || !this.branch.isRechargeEnabled()) {
        this.$router.push(this.$router.generate("/menu"));
      }
      this.initializeSavedCard();

      if (this.$route.query.id) {
        let account = this.$user.getLoyaltyAccount(this.$route.query.id);
        if (!account) {
          this.$router.push(this.$router.generate("/menu"));
        }
        this.account = account;
        this.initializeAccountConfig(this.$route.query.id);
      } else {
        this.initializeAccountConfig();
      }
      window.processing = false;
      this.ready = true;
    },

    computed: {
      balance() {
        if (!this.account) {
          return this.$user.loyalty.getBalance();
        }
        return this.account.balance;
      },
      paymentForm() {
        return this.$refs.paymentForm;
      },
      shouldDisplayPayfactoButton() {
        if (!this.ready) {
          return false;
        }
        return this.paymentForm.getProcessor() === "payfacto";
      },
      hasSavedCardSelected() {
        return !!this.savedCardID;
      },
      accountId() {
        return this.$route.query.id || this.$user.getLoyaltyAccount().id;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      closeReload() {
        return this.$route.query["close-reload"];
      },
      appliedPromotion() {
        if (!this.ready) {
          return;
        }
        let bestPromotion = null;
        let amount = this.paymentForm.getAmount();
        for (let promotion of this.promotions) {
          if (parseFloat(amount) >= promotion.minimum &&
                  (!bestPromotion || (bestPromotion && promotion.minimum > bestPromotion.minimum))) {
            bestPromotion = promotion;
          }
        }
        return bestPromotion;
      },
      promotionalAmount() {
        if (!this.appliedPromotion) {
          return 0;
        }
        if (this.appliedPromotion.rewardType === "flat") {
          return this.appliedPromotion.rewardAmount;
        } else {
          return (this.appliedPromotion.rewardAmount / 100) * this.paymentForm.getAmount();
        }
      },
      promotions() {
        if (!this.branch || !this.branch.loyaltyConfig || !this.branch.loyaltyConfig.data.promotions) {
          return [];
        }
        return this.branch.loyaltyConfig.data.promotions.sort(function(a, b) { return a.minimum - b.minimum; });
      },
      primaryColor() {
        return this.$store.state.primaryColor || "#ed2937";
      },
      payColor() {
        return tinycolor(this.primaryColor).lighten(25).toHexString();
      },
      gradientColor() {
        return tinycolor(this.primaryColor).lighten(50).toHexString();
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      paymentInfo() {
        if (!this.branch) {
          return null;
        }
        return this.branch.getFirstPaymentMethod();
      },
      isAutomaticRechargeEnabledOnBranch() {
        return this.branch && this.branch.hasAutoRechargeEnabled();
      },
      panelTitle() {
        let title = this.$t('loyalty.reload_account');
        if (this.account && this.account.user && this.account.user.username) {
          title = title.concat(': ',this.account.user.username);
        }
        return title;
      }
    },

    methods: {
      leftPad(str, length, pad) {
        return pad.repeat(Math.max(length - String(str).length, 0)) + String(str);
      },
      selectRechargeCard(id) {
        this.accountConfig.autoRechargeConfig.billingToken = id;
      },
      async saveAutomaticRecharge() {
        if (!this.accountConfig.autoRecharge) {
          this.accountConfig.autoRechargeConfig.billingToken = null;
        }
        else if ( !this.accountConfig.autoRechargeConfig.billingToken ||
           (parseInt(this.accountConfig.autoRechargeConfig.rechargeUpTo) < parseInt(this.accountConfig.autoRechargeConfig.whenFallBelow))) {
          showAffirmation(this.$t("error.title"),this.$t("loyalty.error_invalid_auto_recharge"));
          return;
        }
        showSpinner();
        await Axios.put(this.$store.getters.urlServer + "/api/loyalty_accounts/" + this.accountId, this.accountConfig, {
          headers: {
            Authorization: "Bearer " + this.$user.token
          }
        }).catch(e => {
          console.log("Error", e);
          showAffirmation(this.$t("error.title"), this.$t("loyalty.save_error"));
          hideSpinner();
        });
        await this.$user.update();
        hideSpinner();
      },
      initializeSavedCard() {
        if (!this.$user || !this.paymentInfo) {
          return;
        }
        let account = this.$user.getLoyaltyAccount();
        this.availableTokens = account.billingTokens;
        this.openAutomaticRecharge = !!this.availableTokens;
        this.accountConfig.autoRechargeConfig.billingToken = null;
      },
      initializeAccountConfig(id) {
        let account;
        if(id) {
          account = this.$user.getLoyaltyAccount(id);
        } else {
          account = this.$user.getLoyaltyAccount();
        }

        if (!account) {
          return;
        }

        //this i breaking the accountConfig data when no avaiable token
        for (let field in this.accountConfig) {
          console.log("field ,account[field]",field ,account[field]);
          if (account[field] !== null && !(Array.isArray(account[field]) && account[field].length === 0)) {
            this.accountConfig[field] = account[field]
          }
        }

        //Merge is not working
        if (!this.accountConfig.autoRechargeConfig) {
          this.accountConfig.autoRechargeConfig = {
            whenFallBelow: 10,
            rechargeUpTo: 50,
            billingToken:  null
          }
        }
      },
      back() {
        history.back();
      },
      dispatchSend() {
        if (window.processing) {
          return;
        }
        this.send();
      },
      getRewardSymbol(type) {
        switch (type) {
          case "flat":
            return "$";
          case "percent":
            return "%";
          default:
            return "";
        }
      },
      showPayfactoModal() {
        if (!this.ready) {
          return;
        }
        this.paymentForm.showPayfactoModal();
      },
      getPromotionAsText(promotion) {
        return this.$t("loyalty.purchase_for") + " <b>" + promotion.minimum.toFixed(2) + "$</b> " +
                this.$t("loyalty.and_gain") + " <b>" + promotion.rewardAmount + this.getRewardSymbol(promotion.rewardType) + "</b> " + this.$t("loyalty.bonus");
      },
      getPromotionAsRawText(promotion) {
        return this.getPromotionAsText(promotion).replace(/<b>/g, "").replace(/<\/b>/g, "");
      },
      async send() {
        if (window.processing) {
          return;
        }
        window.processing = true;
        showSpinner();
        let payment;
        try {
          payment = await this.paymentForm.processPayment();
        } catch (e) {
          showAffirmation(this.$t("error.title"), this.$t("loyalty.reload_error"));
          hideSpinner();
          return;
        }

        let amount = this.paymentForm.getAmount();
        let processor = this.paymentForm.getProcessor();
        let saveCard = this.paymentForm.getSaveCard();

        let data = {
          processor: processor,
          amount: parseFloat(amount),
          save: saveCard,
          bonusAmount: this.appliedPromotion ? this.promotionalAmount : undefined,
          currency: "CAD",
          promotion: this.appliedPromotion
                  ? { id: this.appliedPromotion.id, name: this.getPromotionAsRawText(this.appliedPromotion) }
                  : undefined
        };

        if (payment.token) {
          data.from = payment.token;
        } else if (payment.card) {
          data.billingToken = payment.card;
        }
        //
        // let result = await Axios.post(this.$store.getters.urlServer + "/api/loyalty_accounts/" + this.accountId + "/recharge", data, {
        //   headers: {
        //     Authorization: "Bearer " + this.$user.token
        //   }
        // }).catch(e => {
        //   console.log("Error", e);
        //   showAffirmation(this.$t("error.title"), this.$t("loyalty.reload_error"));
        //   hideSpinner();
        //   window.processing = false;
        // });
        try {
          let result = await Axios.post(this.$store.getters.urlServer + "/api/loyalty_accounts/" + this.accountId + "/recharge", data, {
            headers: {
              Authorization: "Bearer " + this.$user.token
            }
          });
          hideSpinner();
          window.processing = false;
          this.showPayfactoSuccessFeedback(result.data);

        } catch (e) {
          console.log("Error", e);
          showAffirmation(this.$t("error.title"), this.$t("loyalty.reload_error"));
          hideSpinner();
          window.processing = false;
          return;
        }

        // hideSpinner();
        // window.processing = false;
        // this.showPayfactoSuccessFeedback(result.data);
        await this.$user.update();
        this.$router.push(this.$router.generate(this.$route.query.redirect ? "/" + this.$route.query.redirect : "/hub"));
      },
      showPayfactoSuccessFeedback(transaction) {
        if (transaction.processor !== "payfacto") {
          return;
        }
        let info = transaction.data.moreInfo;
        showAffirmation(this.$t("payment.success"),
                this.$t("payment.completed") + "<br><br>" +
                "<b>" + info.returnCode.replace(" ", "") + " - " + this.$t("success") + "</b><br>" +
                this.$t("payment.transaction_number") + ": #" + info.transactionNumber + "<br>" +
                this.$t("payment.authorization_number") + ": #" + info.authorizationNumber + "<br>",
                null, null, true
        );
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp #reload:not(.desktop) {
    background-color: white;
    background-image: none !important;
    position: relative;
  }

  #iShopFoodApp #reload:not(.desktop) .page-content {
    padding-top: 60px;
    margin-top: -60px;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 20px;
  }

  .bigcircle {
    width: 200vw;
    height: 200vw;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    left: 50%;
    bottom: calc(100% - 290px);
    transform: translate(-50%, 0%);
  }

  .circleSpacer {
    height: 230px;
    padding-top: 40px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .balance {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      line-height: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 10px;
      margin-top: 20px;
      .amount {
        color: white;
        font-size: 92px;
        font-weight: 900;
      }
      .cents {
        color: white;
        font-size: 50px;
        font-weight: 900;
        margin-top: 4px;
      }
    }
    .description {
      font-size: 12px;
      color: white;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    .btn {
      flex-grow: 1;
      flex-basis: 0;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  .row {
    display: flex;
    align-items: flex-end;
    .col {
      flex-grow: 1;
      flex-basis: 0;
    }
    .spacer {
      width: 20px;
    }
    .separator {
      font-size: 20px;
      margin: 0 10px 10px;
      color: #bfbfbf;
    }
  }

  #iShopFoodApp .automatic-recharge-section,
  #iShopFoodApp .automatic-recharge-panel {
    .amount-input:before {
      bottom: 24px !important;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  #iShopFoodApp .automatic-recharge-panel {
    .cards {
      display: flex;
      flex-direction: column;

      .token-card {
        width: 100%;
        margin-right: 10px;

        &:last-child {
          margin-bottom: 10px !important;
        }
      }
    }
    .columns {
      display: flex;

      .column {
        flex-basis: 0;
        flex-grow: 1;
        padding: 0;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
    .token-card {
      padding-right: 15px;
    }
    .buttons {
      display: flex;
      justify-content: center;
      .btn {
        width: 240px;
        flex-basis: initial;
        flex-grow: 0;
      }
    }
    .fields {
      .input {
        width: 100%;
        margin-right: 10px;
        padding-top: 0;
        input {
          width: 100%;
        }
      }
    }
  }

  #iShopFoodApp .collapsible {
    overflow: hidden;

    &.recharge-section {
      .buttons {
        margin-bottom: 30px;
      }
    }

    &.automatic-recharge-section {
      margin-top: 10px;

      .token-card {
        padding-right: 15px;
      }
    }

    .collapse-title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      fill: var(--primary-color);
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #d9d9d9;
      .title {
        color: var(--primary-color) !important;
      }
    }

    .svgicon {
      transform: rotate(0);
      transition: all 0.3s ease;
    }

    .collapse-content {
      height: 0;
      opacity: 0;
      margin: 0;
      transition: all 0.3s ease;
      user-select: none;
    }

    &.open {
      .collapse-content {
        height: auto;
        opacity: 1;
      }
      .svgicon {
        transform: rotate(180deg);
      }
    }
  }
  #iShopFoodApp {
    .summary {
      padding-top: 0px;
      .totals {
        label {
          color: #404040 !important;
        }
      }
    }
  }

  .warning {
    padding: 10px;
    text-align: justify;
  }

</style>

<style lang="scss">

  #reload:not(.desktop) .pageTitle {
    z-index: 1;
    position: relative;
    .toggleMenu {
      .svgicon {
        fill: var(--primary-color);
      }
    }
  }

</style>

<style lang="scss">

  #iShopFoodApp #reload {
    .disclaimer {
      border-radius: 10px;
      background-color: rgba(255, 132, 0, 0.55);
      padding: 10px;
      text-align: justify;
      margin-bottom: 10px;
    }

    .recharge-section , .automatic-recharge-section{
      .disclaimer {
        margin-top: 10px;
      }
    }

    #panelRecharge {
      .container {
        display: flex;
        flex-direction: column;
      }

      .title .user {
        color: black;
        word-break: break-all;
      }
      .payment {
        display: flex;
      }
      .left, .right {
        flex-grow: 1;
        flex-basis: 0;
      }
      .left {
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left .card {
        width: 350px;
        padding: 20px;
        background-color: #f5f5f5;
        text-align: center;
        border-radius: 10px;
        .hr {
          border-bottom: 1px solid #dbdbdb;
          margin: 20px 0;
        }
        .price {
          font-size: 80px;
          color: var(--primary-color);
          font-weight: 900;
          display: flex;
          justify-content: center;
          .cents {
            font-size: 50px;
            position: relative;
            top: 4px;
          }
        }
      }
      .right {
        .actions {
          margin-top: 20px;
        }
      }

      .amount-input:before {
        bottom: 24px !important;
      }
      .promos {
        padding-top: 15px;
        padding-bottom: 20px;
        margin-bottom: 15px;

        .title {
          margin: 10px 5px;
        }

        &.desktop {
          padding-bottom: 0;
          border-bottom: 0;
          margin-bottom: 0;
          margin-top: 20px;

          .promotion {
            padding: 0;
            width: initial;
          }
        }
      }

      .form .title {
        margin: 10px 5px;
        color: #808080;
      }

      .promotion {
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin-bottom: 10px;
        display: flex;

        &.applied {
          .icon {
            fill: var(--primary-color);
          }
        }

        .icon {
          background-color: #f4f4f4;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          fill: #ccc;
          width: 70px;
        }

        .text {
          padding: 10px;
          background-color: white;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          font-size: 14px;
        }
      }

      .totals {
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        padding: 10px 10px 10px 30px;
        margin-bottom: 10px;

        &.desktop {
          .total-item.promo::before {
            top: 8px;
          }
        }

        .total-item {
          display: flex;
          justify-content: space-between;
          position: relative;
          line-height: 1.4;
          &.promo {
            &::before {
              content: "";
              position: absolute;
              left: -20px;
              top: 4px;
              height: 10px;
              width: 10px;
              border-radius: 100%;
              background-color: var(--primary-color);
            }
            .value {
              color: var(--primary-color) !important;
            }
          }
          &:last-child {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid #d8d8d8;
            line-height: 1.1;

            label, .value {
              font-size: 20px;
              font-weight: bold;
            }
          }
        }

        .value {
          font-weight: bold;
        }
      }
    }
  }

</style>
