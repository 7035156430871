/*

Format:

validate: {
  "key": {
    required: boolean,    This field is required to not be null
    email: boolean,       This field must be formatted as an email
    phone: boolean,       This field must be formatted as a phone number
    postal: boolean,      This field must be formatted as a postal code
    number: boolean,      This field must be a number
    min: number,          This field must be greater than the given number
    max: number,          This field must be lower than the given number
    length: number,       This field must be of exactly this length
    minLength: number,    This field must be longer than this length
    maxLength: number,    This field must be shorter than this length
    date: boolean,        This field must be a Date object
    hour: boolean,        This field must be formatted as an hour (00:00)
    maxWords: number,     This field must not have more than the given number of words
    maxDecimals: number,  This field must not have more decimals than the given number
    condition: boolean,   This field must only be validated if the condition is true
    validation: boolean,  This validation must return true
    regex: Regex,         This field must match the given regex
    array: boolean,       This field is an array
    elements: Object      Object descriptor for elements of the array, same format as the root object 'key'
  }
}

 */

let install = Vue => {

  Vue.mixin({

    created() {
      if (!this.$options.validate) {
        return;
      }
      let validate = this.$options.validate;
      if (typeof validate.length == "undefined") {
        validate = [validate];
      }
      this._errors = [];
      for (let v of validate) {
        let obj = {};
        this._errors.push(obj);
        this._constructErrors(obj, v);
      }
      this.validateConfiguration = validate;
    },

    computed: {

      $validate() {
        let that = this;
        return {

          error(key, index = 0) {
            let obj = that._get(key, index);
            if (!obj) {
              return true;
            }
            return !obj.valid && (obj.validatedOnce || that.allValidatedOnce);
          },

          valid(index = 0) {
            // Revalidate all data
            let definition = that.validateConfiguration;
            for (let key of Object.keys(that._errors[index])) {
              that._validateDeep(that._errors[index], key, definition[index], that._getFromObject(that, key));
            }

            let valid = true;
            let validate = function(obj) {
              if (!valid) {
                return;
              }
              // Loop all elements to do a deep validation
              for (let key of Object.keys(obj)) {
                if (typeof obj[key].length != "undefined") {
                  for (let subObj of obj[key]) {
                    validate(subObj);
                  }
                } else if (!obj[key].valid) {
                  //console.log("invalid field", key);
                  valid = false;
                  return;
                }
              }
            };
            validate(that._errors[index]);
            that.$forceUpdate();

            return valid;

          },

          data(index = 0) {
            let dataObject = {};
            let fetchData = function(obj, data, root) {
              for (let key of Object.keys(obj)) {
                let fetched = that._getFromObject(root, key);
                if (obj[key].array) {
                  let array = that._createDeepKey(data, key, []);
                  for (let subData of fetched) {
                    let toFill = {};
                    fetchData(obj[key].elements, toFill, subData);
                    array.push(toFill);
                  }
                } else {
                  data[key] = that._getFromObject(root, key);
                }
              }
            }
            fetchData(that.validateConfiguration[index], dataObject, that);
            return dataObject;
          },

          validateAllOnce() {
            that.allValidatedOnce = true;
          }

        }
      }

    },

    methods: {

      _constructErrors(root, definition) {
        for (let key of Object.keys(definition)) {

          // Create error object
          if (definition[key].array) {
            root[key] = [];
          } else {
            root[key] = {
              valid: false,
              validatedOnce: false
            };
          }

          // Validate initial value
          this._validateDeep(root, key, definition, this._getFromObject(this, key), true);

          // Watch for changes
          this.$watch(key, value => {
            for (let key of Object.keys(root)) {
              if (typeof definition[key].condition != "undefined") {
                this._validateDeep(root, key, definition, this._getFromObject(this, key), true);
              }
            }
            this._validateDeep(root, key, definition, value);
          }, {deep: definition[key].array});

        }
      },

      _validateDeep(root, key, definition, value, skipValidatedOnce) {
        if (definition[key].array) {
          // Loops array to validate all elements
          root[key] = [];
          value = value || [];
          for (let i = 0; i < value.length; i++) {
            let obj = {};
            let definitionKeys = Object.keys(definition[key].elements);
            for (let definitionKey of definitionKeys) {
              obj[definitionKey] = {
                valid: false,
                validatedOnce: false
              };
              this._validateDeep(obj, definitionKey, definition[key].elements, this._getFromObject(value[i], definitionKey));
            }
            root[key].push(obj);
          }
        } else {
          // Validate single value
          root[key].valid = this._validate(value, definition[key]);
          if (!skipValidatedOnce) {
            root[key].validatedOnce = true;
          }
        }
      },

      _get(key, index) {

        let split = key.split(".");
        let tempRoot = this._errors[index];
        let concat = "";

        for (let subKey of split) {
          if (/\[[0-9]+\]$/g.test(subKey)) {
            // Sets the root object to the array and reset the concatenated key
            let index = subKey.substring(subKey.indexOf("[") + 1, subKey.length - 1);
            subKey = subKey.substring(0, subKey.indexOf("["));
            concat += (concat ? "." : "") + subKey;
            tempRoot = tempRoot[concat][index];
            concat = "";
          } else {
            concat += (concat ? "." : "") + subKey;
          }
        }

        return concat ? tempRoot[concat] : tempRoot;

      },

      _getFromObject(obj, key) {
        let split = key.split(".");
        let temp = obj;
        for (let subKey of split) {
          if (temp == null || typeof temp[subKey] == "undefined") {
            return null;
          }
          temp = temp[subKey];
        }
        return temp;
      },

      _createDeepKey(obj, key, data) {
        let root = obj;
        let split = key.split(".");
        let last = split.splice(split.length - 1, 1);
        for (let str of split) {
          if (!root[str]) {
            root[str] = {};
          }
          root = root[str];
        }
        root[last] = data;
        return root[last];
      },

      _validate(value, rules = {}) {
        let valid = true;
        if (typeof rules.condition != "undefined" && !rules.condition.bind(this)()) {
          return true;
        }
        if (rules.required &&
          (typeof value === "undefined" || value == null
            || (typeof value === "string" && value.length == 0)
            || (typeof value === "boolean" && !value))) {
          valid = false;
        }
        if (valid && rules.required && rules.array && typeof value.length == "undefined") {
          valid = false;
        }
        if (valid && rules.required || (!rules.required && value)) {
          if (rules.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            valid = false;
          } else if (rules.phone && (!/^[0-9\-\(\) ]+$/g.test(value) || value.replace(/[^0-9]/g, "").length < 10)) {
            valid = false;
          } else if (rules.postal && !/^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/g.test(value)) {
            valid = false;
          } else if (rules.postalSoft && value.length < 3) {
            valid = false;
          } else if (rules.number && parseFloat(value) != value) {
            valid = false;
          } else if (rules.min && value < rules.min) {
            valid = false;
          } else if (rules.max && value > rules.max) {
            valid = false;
          } else if (rules.length && value.length !== rules.length) {
            valid = false;
          } else if (rules.minLength && value.length < rules.minLength) {
            valid = false;
          } else if (rules.maxLength && value.length > rules.maxLength) {
            valid = false;
          } else if (rules.date && !value instanceof Date) {
            valid = false;
          } else if (rules.hour && !/^[0-9]?[0-9]:[0-9]{2}$/g.test(value)) {
            valid = false;
          } else if (rules.maxWords && value.split(" ").length > rules.maxWords) {
            valid = false;
          } else if (rules.maxDecimals && !(new RegExp("^[0-9]+(\.[0-9]{1," + rules.maxDecimals + "})?$")).test(value)) {
            valid = false;
          } else if (rules.regex && !rules.regex.test(value)) {
            valid = false;
          } else if (typeof rules.validation !== "undefined" && !rules.validation.bind(this)()) {
            valid = false;
          }
        }
        return valid;
      }

    }

  });

}

export default {
  install
}
