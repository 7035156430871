<template>
  <div id="ticketModal" class="modal" :class="{show: show}">
    <div class="content modal-content" v-if="ticket">

      <div class="overflow">

        <div class="ticket--item">

          <div class="ticket--info">
            <div class="img" :style="{backgroundImage: 'url(\'' + getImage(ticket.image) +  '\''}"></div>
            <div class="top--right">
              <div class="name">{{$to(ticket.name)}}</div>
              <div class="description">{{$to(ticket.description)}}</div>
              <div class="ticket--quantity">
                <label>{{$t('quantity')}}</label>
                <QuantitySelector ref="quantitySelector" :large="$kiosk" :value="ticket.quantity" @changed="setQuantity($event)" :min="1"></QuantitySelector>
              </div>
            </div>
          </div>

          <div class="ticket--disclaimer" v-if="$to(ticket.disclaimerTitle) || $to(ticket.disclaimerDescription)">
            <div class="disclaimer--title" v-if="$to(ticket.disclaimerTitle)">
              <svgicon src="icons/info.svg" :height="16" :width="16"></svgicon>
              <div class="text" v-html="$to(ticket.disclaimerTitle)"></div>
            </div>
            <div class="disclaimer--description">
              <div class="text" v-html="$to(ticket.disclaimerDescription)"></div>
            </div>
          </div>

          <div class="ticket--footer">
            <button class="btn btn-outline btn-back" @click="close">{{$t('back')}}</button>
            <div class="right">
              <div class="price">{{$tc(ticket.getTotalPrice().subTotal.toFixed(2))}}</div>
              <button class="btn btn-primary" @click="addToCart">{{$t('item.add')}}</button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import QuantitySelector from "@/components/tools/QuantitySelector";

  export default {
    name: "ticketModal",

    components: { QuantitySelector },

    props: {
      originalTicket: Object,
      show: Boolean
    },

    data() {
      return {
        ticket: null
      }
    },

    watch: {
      originalTicket() {
        this.getTicketInstance();
      }
    },

    mounted() {
      this.getTicketInstance();
    },

    methods: {
      close() {
        this.$emit("close");
      },
      getTicketInstance() {
        if (!this.originalTicket) {
          this.ticket = null;
        }
        this.ticket = this.originalTicket.clone();
      },
      getImage(image) {
        if (image) {
          return this.$store.state.urlFileServer + image;
        }
      },
      async addToCart() {
        if (this.ticket.disclaimerPopupActivated) {
          let content = "<h3>" + this.$to(this.ticket.disclaimerTitle) + "</h3>";
          content += "<p>" + this.$to(this.ticket.disclaimerDescription) + "</p>";
          let response = await showConfirmation(this.$t('ticket.please_read'), content, this.$t('ticket.accept'), this.$t('back'), false, 'ticket-terms');
          if (!response) {
            this.close();
            return;
          }
        }
        this.close();
        this.$order.addItem(this.ticket);
      },
      setQuantity(quantity) {
        this.ticket.quantity = quantity;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.kiosk #ticketModal .modal-content {
    border-radius: 20px;
    padding: 40px;
    max-width: 780px;

    .name {
      font-size: 26px;
    }

    .ticket--footer {
      padding-bottom: 0;
      padding-top: 40px;
    }
  }

  #iShopFoodApp #ticketModal .modal-content {
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
  }
  .ticket--info {
    display: flex;
    .img {
      height: 180px;
      width: 260px;
      border-radius: 10px;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
      margin-right: 30px;
    }
    .top--right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .name {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .description {
    flex-grow: 1;
    font-size: 15px;
    margin-bottom: 10px;
    white-space: pre-wrap;
  }
  .ticket--disclaimer {
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px dashed #e6e6e6;

    .disclaimer--title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      .svgicon {
        fill: var(--primary-color);
        margin-right: 10px;
      }
    }
    .disclaimer--description {
      font-size: 15px;
      white-space: pre-wrap;
      overflow-y: auto;
      padding-left: 26px;
      max-height: 300px;
    }
  }
  .ticket--quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      font-weight: bold;
    }
  }
  .ticket--footer {
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .btn {
      min-width: 100px;
    }

    .right {
      display: flex;
      align-items: center;

      .price {
        font-weight: bold;
        font-size: 20px;
        position: relative;
        margin-right: 20px;
        padding-right: 20px;

        &:after {
          content: "";
          height: 20px;
          width: 1px;
          background-color: #e6e6e6;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  #iShopFoodApp.xs #ticketModal, #iShopFoodApp.sm #ticketModal {
    .modal-content {
      height: 100%;
      border-radius: 0;
      margin: 0;
      overflow-y: auto;
    }
    .overflow {
      height: 100%;
    }
    .ticket--item {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .ticket--info {
      flex-direction: column;

      .img {
        margin-right: 0;
        height: 250px;
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .description {
      margin-bottom: 20px;
    }
    .disclaimer--description {
      max-height: none;
    }
    .ticket--disclaimer {
      flex-grow: 1;
      padding-bottom: 90px;
    }
    .ticket--footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
      padding: 15px 10px;

      .btn {
        min-width: 0;
        padding: 10px 12px;
      }
      .btn-back {
        margin-right: 10px;
      }

      .price {
        margin-right: 10px;
        padding-right: 10px;
        font-size: 18px;
      }
    }
  }

</style>
