<template>
  <div id="mobileCart" ref="mobileCart" :class="{md: $store.state.screenSize == 'md' || $store.state.screenSize == 'lg',
    shown: $store.state.mobileCartShown}">
    <div class="content">
      <Cart :visible="true"></Cart>
    </div>
  </div>
</template>

<script>

  import EventBus from "@/lib/eventBus.js";
  import Cart from "@/components/cart/Cart.vue";

  export default {

    components: { Cart },

    methods: {
      emitToggle() {
        this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
      }
    }

  }

</script>

<style lang="scss" scoped>

  $width: 100%;

  #mobileCart {
    width: $width;
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out;
    position: relative;
    display: flex;
    transform: translateX(0);
    display: flex;
    justify-content: flex-end;
    overflow-x: hidden;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      left: -10px;
      top: 0px;
      bottom: 0px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      left: 0px;
      top: -10px;
      bottom: 0px;
    }

    &.md {
      display: none;
    }
    &.shown {
      width: $width;
      transform: translateX(-100%);

    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    text-align: center;
    max-width: 100%;
  }
  .logo {
    padding-top: 30px;
  }
  .text {
    font-size: 12px;
    line-height: 16px;
    font-style: italic;
    color: white;
    padding: 25px 30px;
  }

  .menu-items {
    margin: 0;
    padding: 0;
    list-style: none;

    .router-link-active + .item {
      border-top: 1px solid rgb(212, 36, 48);
    }

    .item {
      display: flex;
      align-items: center;
      height: 55px;
      padding-left: 30px;
      fill: white;
      border-top: 1px dashed rgb(212, 36, 48);
      cursor: pointer;
      text-decoration: none;

      &:last-child {
        border-bottom: 1px dashed rgb(212, 36, 48);
      }

      .link-name {
        color: white;
        font-size: 16px;
        padding-left: 12px;
      }

      &.router-link-active {
        background-color: rgb(212, 36, 48);
        fill: rgb(237, 134, 115);
        border-top: 1px solid rgb(212, 36, 48);

        .link-name {
          font-weight: 500;
        }
      }
    }
  }

  .language {
    .link-name:not(:last-child) {
      position: relative;
      padding-right: 10px;
    }
    .active {
      font-weight: bold;
    }
  }

</style>
