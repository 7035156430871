import { LocalizeStore } from "@/lib/localize";
import PrintFormatter from "@/lib/printer/printFormatter";
import { Constant } from "@/util/Constant";
import ReceiptConfiguration from "@/models/pos/ReceiptConfigurationModel";

/**
 * @typedef PosConfigurationDefinition
 * @property {String} branchCanonical
 * @property {String} companyCanonical
 * @property {String} number
 * @property {String} apiKey
 * @property {boolean|String} terminalPayment
 * @property {*} terminalData
 * @property {{activated:boolean, name:string, cols:number}} printer
 * @property {{default:string, fr:boolean, en:boolean, es:boolean}} locales
 * @property {boolean} displayCategoryImages
 * @property {boolean} displayProductImages
 * @property {number} workstationDataRefreshDelay
 * @property {boolean} animated
 * @property {boolean} darkTheme
 * @property {string} primaryColor
 * @property {{activated: boolean, url: string}} xpos
 * @property {{id:number, name:string}} workstation
 * */

export default class PosConfiguration {

    constructor() {
        this.branchCanonical = "";
        this.companyCanonical = "";
        this.number = "";
        this.apiKey = "";
        this.terminalData = null;
        this.terminalPayment = false;
        this.minimumTransactionNumber = 1;
        this.maximumTransactionNumber = 999;
        this.locales = {
            default: "en",
            en: true,
            fr: false,
            es: false
        };
        this.displayCategoryImages = true;
        this.displayProductImages = false;
        this.darkTheme = false;
        this.quickAdd = false;
        this.printedSaleQueueLimit = 15;
        this.cashDrawerCommand = 1;
        this.workstationDataRefreshDelay = Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY;
        this.animated = true;
        this.functions = [];
        this.printer = {
            activated: false,
            mainPrinterName: "",
            kitchenPrinters: [],
            cols: 48
        };
        this.printers = [];
        this.printingGroups = [];
        this.receiptConfiguration = new ReceiptConfiguration();
        this.timeoutDelay = 120;
        this.workstation = { id: null, name: "" };
        this.xpos = {
            activated: false,
            url: ""
        };

        this.POS_CONFIGURATION_KEY = "posConfiguration";
        this.TRANSACTION_NUMBER_KEY = "transaction-number";
        this.DISCOVERED_PRINTERS_KEY = "discovered-printers";
        this.PAYFACTO_PAX = "payfactoPax";
        this.API_KEY = "pos-api-key";
        this.UUID_KEY = "pos-uuid";
        this.COLORS = ["#ff5500", "#36D685", "#E02A41", "#00D2E6"];
        this.DEFAULT_PRIMARY_COLOR = this.COLORS[0];

        this.colorMapper = { "#ff5500": "orange", "#36D685": "green", "#E02A41": "red", "#00D2E6": "blue" };

        this.setPrimaryColor(this.DEFAULT_PRIMARY_COLOR);
        this.setLocale();
        this.registerUUID();
    }

    /**#
     * @returns {PosConfigurationDefinition}
     */
    toDto() {
        return {
            branchCanonical: this.branchCanonical,
            companyCanonical: this.companyCanonical,
            number: this.number,
            terminalPayment: this.terminalPayment,
            apiKey: this.apiKey,
            terminalData: this.terminalData,
            minimumTransactionNumber: this.minimumTransactionNumber,
            maximumTransactionNumber: this.maximumTransactionNumber,
            locales: this.locales,
            printedSaleQueueLimit: this.printedSaleQueueLimit,
            displayProductImages: this.displayProductImages,
            displayCategoryImages: this.displayCategoryImages,
            cashDrawerCommand: this.cashDrawerCommand,
            primaryColor: this.primaryColor,
            quickAdd: this.quickAdd,
            printer: this.printer,
            darkTheme: this.darkTheme,
            animated: this.animated,
            timeoutDelay: this.timeoutDelay,
            workstationDataRefreshDelay: this.workstationDataRefreshDelay,
            workstation: this.workstation,
            xpos: this.xpos
        };

    }

    /**
     * @param {PosConfigurationDefinition} definition
     */
    load(definition) {
        this.branchCanonical = definition.branchCanonical;
        this.companyCanonical = definition.companyCanonical;
        this.terminalPayment = definition.terminalPayment;
        this.number = definition.number;
        this.apiKey = definition.apiKey;
        this.terminalData = definition.terminalData;
        this.minimumTransactionNumber = definition.minimumTransactionNumber || 1;
        this.maximumTransactionNumber = definition.maximumTransactionNumber || 999;
        this.printer = {
            activated: definition.printer ? definition.printer.activated : false,
            mainPrinterName: definition.printer ? definition.printer.mainPrinterName : "",
            kitchenPrinters: definition.printer ? (definition.printer.kitchenPrinters || []) : [],
            cols: definition.printer ? definition.printer.cols : 48
        };
        this.locales = {
            default: definition.locales ? definition.locales.default : "en",
            en: definition.locales ? definition.locales.en : true,
            es: definition.locales ? definition.locales.es : false,
            fr: definition.locales ? definition.locales.fr : false
        };
        this.cashDrawerCommand = definition.cashDrawerCommand || 1;
        this.displayCategoryImages = typeof definition.displayCategoryImages === "boolean" ? definition.displayCategoryImages : true;
        this.displayProductImages = definition.displayProductImages || false;
        this.animated = typeof definition.animated === "boolean" ? definition.animated : true;
        this.darkTheme = definition.darkTheme || false;
        this.quickAdd = definition.quickAdd || false;
        this.setPrimaryColor(definition.primaryColor || this.DEFAULT_PRIMARY_COLOR);
        this.printedSaleQueueLimit = definition.printedSaleQueueLimit || 15;
        this.timeoutDelay = definition.timeoutDelay || 120;
        this.workstationDataRefreshDelay = definition.workstationDataRefreshDelay || Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY;
        this.workstation = definition.workstation || { id: null, name: "" };
        this.xpos = {
            activated: definition.xpos ? definition.xpos.activated : true,
            url: definition.xpos ? definition.xpos.url : ""
        };
    }

    /**
     * @returns {PosConfiguration}
     */
    loadFromLocalPosConfiguration() {
        let localPosConfiguration = this.getLocalPosConfiguration();
        if (!localPosConfiguration) {
            return this;
        }
        this.load(localPosConfiguration);
        return this;
    }

    /**
     * @returns {PosConfigurationDefinition}
     */
    getLocalPosConfiguration() {
        return window.getConfiguration(this.POS_CONFIGURATION_KEY);
    };

    save() {
        window.setConfiguration(this.POS_CONFIGURATION_KEY, this.toDto());
        window.setConfiguration(this.API_KEY, this.apiKey);
        if (this.printer.activated) {
            window.setConfiguration("printer-name", this.printer.mainPrinterName);
            window.setConfiguration("printer-cols", this.printer.cols);
        }
    };

    setLocale() {
        let locale = this.getDefaultLocale();
        LocalizeStore.commit("setLocale", locale);
        window.setConfiguration("locale", locale);
    }

    setFunctions(functions) {
        this.functions = functions;
    }

    /**
     * @param {number} id
     * @returns {{name: {fr: string, en: string}, id: number}}
     */
    getFunctionById(id) {
        return this.functions.find(f => f.id === id);
    }

    /**
     * @param {{name: String, nickname: String}[]} savedPrinters
     * @param {{name: String}[]} newPrinters
     */
    mergeDiscoveredPrinters(savedPrinters, newPrinters) {
        for (let i = savedPrinters.length - 1; i >= 0; i--) {
            if (!newPrinters.find(n => n.name === savedPrinters[i].name)) {
                savedPrinters.splice(i, 1);
            }
        }
        for (let printer of newPrinters) {
            if (!savedPrinters.find(p => p.name === printer.name)) {
                savedPrinters.push({
                    name: printer.name,
                    nickname: printer.name
                });
            }
        }
        return savedPrinters;
    }

    getPrintingGroups() {
        return [...this.printingGroups, { id: "all", name: { fr: "Tous les produits", en: "All Products" }, items: [] }];
    }

    getName() {
        return "";
    }

    setPrintingGroups(groups) {
        this.printingGroups = groups;
    }

    linkPrintingGroupsToInventory(inventory) {
        for (let group of this.printingGroups) {
            group.inventoryItems = [];
            for (let itemId of group.items) {
                let foundItem = inventory.getItemById(itemId);
                if (foundItem) {
                    group.inventoryItems = [...group.inventoryItems, itemId, ...foundItem.variants.map(v => v.id)];
                }
            }
        }
    }

    /**
     * //TODO replace
     * @deprecated
     * @param {{name: String}[]} printers
     */
    saveDiscoveredPrinters(printers) {
        window.setConfiguration(this.DISCOVERED_PRINTERS_KEY, JSON.stringify(printers));
    }

    /**
     * //TODO replace
     * @deprecated
     * @returns {*[]|any}
     */
    getSavedPrinters() {
        let printers = window.getConfiguration(this.DISCOVERED_PRINTERS_KEY);
        if (printers) {
            return JSON.parse(printers);
        }
        return [];
    }

    getPrinters() {
        return this.printers;
    }

    getKitchenPrinters() {
        return this.printers.filter(p => !!p.group);
    }

    registerPrinters() {
        let printers = this.getPrinters();
        if (typeof CordovaPrint !== "undefined" && printers.length > 0) {
            CordovaPrint.feedEntirePrintersList(null, null, printers.map(p => p.ip || p.name));
        }
    }

    setPrinters(printers) {
        this.printers = printers.map(p => ({
            name: p.ip || p.name,
            nickname: p.nickname || p.name,
            group: p.group || null,
            items: p.items || []
        }));
    }

    /**
     * @returns {string}
     */
    getMainPrinterName() {
        return this.printer.mainPrinterName;
    }

    /**
     * @param {number} itemId
     * @returns {string[]}
     */
    getKitchenPrinterNamesForItemId(itemId) {
        let kitchenPrinters = this.getKitchenPrinters();
        let printerNames = [];
        if (kitchenPrinters.length > 0) {
            for (let printer of kitchenPrinters) {
                if (printer.group === "all") {
                    printerNames.push(printer.name);
                } else if (printer.group) {
                    let group = this.getPrintingGroupById(printer.group);
                    if (group && group.inventoryItems.find(i => i === itemId)) {
                        printerNames.push(printer.name);
                    }
                }
            }
        }
        return printerNames;
    }

    getPrintingGroupById(groupId) {
        return this.printingGroups.find(g => g.id === groupId);
    }

    getReceiptImage() {
        return this.receiptConfiguration.logo ? this.receiptConfiguration.logo.url : null;
    }

    hasPrinterConfigured() {
        return !!this.getMainPrinterName();
    }

    /**
     * @param {string} number
     */
    setNumber(number) {
        this.number = number;
    }

    /**
     * @param {string} branch
     * @param {string} company
     */
    setBranchAndCompanyCanonical(branch, company) {
        this.branchCanonical = branch;
        this.companyCanonical = company;
    }

    /**
     * @returns {string|null}
     */
    getBranchCanonical() {
        return this.branchCanonical;
    }

    /**
     * @returns {string|null}
     */
    getCompanyCanonical() {
        return this.companyCanonical;
    }

    /**
     * @returns {string|null}
     */
    getXposUrl() {
        return this.xpos.activated && this.xpos.url ? `${this.xpos.url}` : null;
    }

    registerUUID() {
        //TODO maybe store somewhere else than here. File?
        if (!this.getDeviceUUID()) {
            window.setConfiguration(this.UUID_KEY, window.generateUUID()); //TODO use better algo for this
        }
    }

    validateDefaultLocale() {
        let locales = this.getActivatedLocales();
        let isValid = locales.indexOf(this.locales.default) > -1;
        if (!isValid) {
            this.locales.default = locales[0];
        }
    }

    /**
     * @returns {string[]}
     */
    getReceiptFooterLines() {
        return this.receiptConfiguration.getFooterLines();
    }

    /**
     * @returns {number}
     */
    getPrinterCols() {
        return this.printer.cols;
    }

    /**
     * @returns {string[]}
     */
    getReceiptHeaderLines() {
        return this.receiptConfiguration.getHeaderLines();
    }

    /**
     * @returns {string}
     */
    getDefaultLocale() {
        return this.locales.default;
    }

    /**
     * @returns {boolean}
     */
    hasMultipleLocales() {
        return this.getActivatedLocales().length > 1;
    }

    /**
     * @returns {string[]}
     */
    getActivatedLocales() {
        let keys = Object.keys(this.locales);
        keys = keys.filter(k => typeof this.locales[k] === "boolean" && this.locales[k] === true);
        return keys;
    }

    /**
     * @param {string} color
     */
    setPrimaryColor(color) {
        this.primaryColor = color;
        this.setPrimaryColorProperties();
    }

    setPrimaryColorProperties() {
        let appElement = document.getElementById("iShopFoodApp");
        if (appElement) {
            appElement.style.setProperty("--arrow-image-url", "url('icons/arrow-" + this.colorMapper[this.primaryColor] + ".svg')")
        }
    }

    /**
     * Gets the name of the primary color.
     * @returns {*}
     */

    getColorName() {
        return this.colorMapper[this.primaryColor];
    }

    /**
     * @param {boolean} isDarkTheme
     */
    setDarkTheme(isDarkTheme) {
        this.darkTheme = isDarkTheme;
    }

    /**
     * @param {boolean} quickAdd
     */
    setQuickAdd(quickAdd) {
        this.quickAdd = quickAdd;
    }

    /**
     * @param {boolean} animated
     */
    setAnimated(animated) {
        this.animated = animated;
    }

    /**
     * @param {boolean} displayProductImages
     */
    setDisplayProductImages(displayProductImages) {
        this.displayProductImages = displayProductImages;
    }

    /**
     * @param {boolean} displayCategoryImages
     */
    setDisplayCategoryImages(displayCategoryImages) {
        this.displayCategoryImages = displayCategoryImages;
    }

    /**
     * @returns {string|null}
     */
    getDeviceUUID() {
        return window.getConfiguration(this.UUID_KEY);
    }

    /**
     * @param {string} key
     */
    setApiKey(key) {
        this.apiKey = key;
        window.setConfiguration(this.API_KEY, this.apiKey);
    }

    clearApiKey() {
        this.apiKey = "";
        window.setConfiguration(this.API_KEY, null);
    }

    /**
     * For PayFacto Terminal
     * @returns {null|string}
     */
    getTerminalNumber() {
        if (this.terminalPayment && this.terminalData) {
            return this.terminalData.terminalNumber;
        }
        return null;
    }

    /**
     * @returns {boolean}
     */
    hasPayfactoPaxPayment() {
        return this.terminalPayment === this.PAYFACTO_PAX;
    }

    /**
     * @return {number}
     */
    getTransactionNumber() {
        return parseInt(window.getConfiguration(this.TRANSACTION_NUMBER_KEY)) || parseInt(this.minimumTransactionNumber);
    }

    getPaddedTransactionNumber() {
        let padLength = this.maximumTransactionNumber.toString().length;
        return padNumberWithLeadingZeros(padLength, this.getTransactionNumber());
    }

    incrementTransactionNumber() {
        let nextNumber = this.getTransactionNumber() + 1;
        setConfiguration(this.TRANSACTION_NUMBER_KEY, nextNumber > parseInt(this.maximumTransactionNumber) ? parseInt(this.minimumTransactionNumber) : nextNumber);
    }

    matchTransactionNumberToMinimumConfigured() {
        let currentTransactionNumber = this.getTransactionNumber();
        if (currentTransactionNumber < parseInt(this.minimumTransactionNumber)) {
            setConfiguration(this.TRANSACTION_NUMBER_KEY, parseInt(this.minimumTransactionNumber));
        }
    }

    matchTransactionNumberToConfiguredRange() {
        let currentTransactionNumber = this.getTransactionNumber();
        if (currentTransactionNumber < parseInt(this.minimumTransactionNumber) || currentTransactionNumber > parseInt(this.maximumTransactionNumber)) {
            setConfiguration(this.TRANSACTION_NUMBER_KEY, parseInt(this.minimumTransactionNumber));
        }
    }

    getWorkstationDataRefreshDelayInMS() {
        if (!this.workstationDataRefreshDelay) {
            return Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY * 60 * 1000; // 5 minutes default
        }
        return this.workstationDataRefreshDelay * 60 * 1000;
    }

    getWorkstationDataRefreshDelayInMinutes() {
        if (!this.workstationDataRefreshDelay) {
            return Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY; // 5 minutes default
        }
        return this.workstationDataRefreshDelay;
    }

    openCashDrawer() {
        if (!this.cashDrawerCommand) {
            return;
        }
        new PrintFormatter({
            xposUrl: this.getXposUrl()
        }).openDrawer(this.cashDrawerCommand);
    }

    /**
     * @param {number} id
     */
    setWorkstationId(id) {
        this.workstation.id = id;
    }

    /**
     * @param {string} name
     */
    setWorkstationName(name) {
        this.workstation.name = name;
    }

    /**
     * @param {string} name
     * @param {number} id
     */
    setWorkstationNameAndId(name, id) {
        this.setWorkstationName(name);
        this.setWorkstationId(id);
    }

    /**
     * @returns {number}
     */
    getWorkstationId() {
        return this.workstation.id;
    }

    /**
     * @returns {string}
     */
    getWorkstationName() {
        return this.workstation.id;
    }

    /**
     * @param {boolean} activated
     */
    setXposConfigActivated(activated) {
        this.xpos.activated = activated;
    }

    /**
     * @param {string} url
     */
    setXposConfigUrl(url) {
        this.xpos.url = url;
    }
}
