<template>
    <div id="kioskCart" :class="{collapsed: isCollapsed, horizontal: isHorizontal, siblingModalVisible: siblingModalVisible}">
        <div class="cart--header" @click="toggleCollapse" @contextmenu="toggleCollapse">
            <div class="header--left">
                <div class="left--title bigger">{{$t('my_order')}}</div>
                <div class="left--method smaller">{{$t('kiosk_method.' + $order.method)}}</div>
            </div>
            <div class="header--arrow" :class="{rotate: isCollapsed}">
                <svgicon src="icons/triangle-arrow-bottom.svg" :height="21" :width="55"></svgicon>
            </div>
            <div class="header--right">
                <div class="right--products smaller">
                    <template v-if="orderItemCount > 1">{{ orderItemCount }} {{$t('menu.products')}}</template>
                    <template class="count" v-if="orderItemCount === 1">{{ orderItemCount }} {{$t('menu.product')}}</template>
                    <template class="count" v-if="orderItemCount === 0">{{$t('menu.no_product')}}</template>
                </div>
                <div class="right--subtotal bigger">
                    {{$t('total.subtotal')}}: {{ $tc((orderTotal.itemsTotal - orderTotal.promotionTotal).toFixed(2)) }}
                </div>
            </div>
        </div>

        <div class="cart--items">
            <div class="cart--arrow arrow__left" v-wave="!scrollAtBeginning" @click="scrollBefore" @contextmenu="scrollBefore" :class="{disabled: scrollAtBeginning}">
                <svgicon src="icons/triangle-arrow-left.svg" :height="66" :width="26"></svgicon>
            </div>
            <div id="scroll-items" class="items">
                <div class="cart-item__fake" v-if="promotion">
                  <div class="left">
                    <div class="promotion--name">{{$to(promotion.name)}}</div>
                    <div class="promotion--type">{{promotion.type == 'percent' ? promotion.value + "%" : $tc(promotion.value)}} {{$t('kiosk.of_reduction')}}</div>
                  </div>
                  <div class="right">
                    <div class="promotion--price">-{{$tc(orderTotal.promotionTotal.toFixed(2))}}</div>
                  </div>
                  <div class="icon" @click="removePromotion" @contextmenu="removePromotion">
                    <svgicon src="icons/trash-full.svg" :height="22" :width="22"></svgicon>
                  </div>
                </div>
                <div class="start" v-if="orderItems.length === 0">
                    <template v-if="!kioskConfiguration.hasScan()">{{$t('kiosk.start')}}</template>
                    <template v-if="kioskConfiguration.hasScan()">{{$t('kiosk.start_scan')}}</template>
                </div>
                <CartItem cart :item="item" :index="cartIndex" v-for="(item, cartIndex) of orderItems" :key="cartIndex"></CartItem>
            </div>
            <div class="cart--arrow arrow__right" v-wave="!scrollAtEnd" @click="scrollAfter" @contextmenu="scrollAfter" :class="{disabled: scrollAtEnd}">
                <svgicon src="icons/triangle-arrow-right.svg" :height="66" :width="26"></svgicon>
            </div>
        </div>

        <div class="cart--footer">
          <div class="footer--subtotal" v-if="isHorizontal">
            <div class="subtotal--title">{{$t('total.subtotal')}}</div>
            <div class="subtotal--amount">{{ $tc((orderTotal.itemsTotal - orderTotal.promotionTotal).toFixed(2)) }}</div>
          </div>
          <button id="button-cancel" v-wave class="btn btn-secondary" :class="{flat: isHorizontal}" @click="cancel" @contextmenu="cancel">
            {{  isHorizontal ? $t('cancel') : $t('cancel_order')}}</button>
          <button id="button-confirm" v-wave class="btn btn-secondary" :class="{flat: isHorizontal}" @click="confirm" @contextmenu="confirm">
            {{  isHorizontal ? $t('finish') : $t('confirm_order') }}
          </button>
        </div>
    </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { PromotionManager } from "@/PromotionManager";

  export default {
    name: "kioskCart",

    components: { },

    props: {
      collapsed: Boolean
    },

    data() {
      return {
        PromotionManager,
        siblingModalVisibleInterval: null,
        siblingModalVisible: false,
        isCollapsed: true,
        scrollAtBeginning: true,
        scrollAtEnd: true
      };
    },

    mounted() {
      this.initializeCollapse();
      this.scrollToEndOfItems();
      if (this.isHorizontal) {
        this.startSiblingModalVisibleInterval();
        EventBus.$on("added-to-cart", () => {
          this.scrollToEndOfItems();
        });
      }
      if (document.getElementById("scroll-items")) {
        document.getElementById("scroll-items").addEventListener("scroll", this.handleScroll);
      }
      this.handleScroll();
    },

    beforeDestroy() {
      if (this.isHorizontal) {
        EventBus.$off("added-to-cart");
        clearInterval(this.siblingModalVisibleInterval);
      }
      if (document.getElementById("scroll-items")) {
        document.getElementById("scroll-items").removeEventListener("scroll", this.handleScroll);
      }
    },

    watch: {
      "collapsed"() {
        this.isCollapsed = this.collapsed;
      }
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      isHorizontal() {
       return this.kioskConfiguration && this.kioskConfiguration.isHorizontal();
      },
      inventory() {
        return this.$store.state.inventory;
      },
      inventoryHasSupplements() {
        return this.inventory && this.inventory.supplements && this.inventory.supplements.length > 0;
      },
      promotion() {
        return this.$order.appliedPromotion.promotion;
      },
      orderItemCount() {
        return this.$order.getItemCount();
      },
      orderTotal() {
        return this.$order.getOrderTotal();
      },
      orderItems() {
        return this.$order.items;
      }
    },

    methods: {
      initializeCollapse() {
        if (typeof this.collapsed !== "undefined") {
          if (this.collapsed) {
            this.isCollapsed = true;
          } else {
            this.isCollapsed = false;
          }
        }
      },
      toggleCollapse() {
        if (this.isHorizontal) {
          return;
        }
        this.isCollapsed = !this.isCollapsed;
        setTimeout(() => {
          this.$emit("toggle");
          EventBus.$emit("kiosk-cart-toggle");
        }, 300); // Envoyer après la durée de l'animation du collapse du cart
      },
      startSiblingModalVisibleInterval() {
        this.siblingModalVisibleInterval = setInterval(() => {
          this.siblingModalVisible = !!document.querySelector("#iShopFoodApp > .content .modal.show");
        }, 100);
      },
      scrollBefore() {
        let div = document.getElementById("scroll-items");
        if (this.isHorizontal) {
          div.scrollTop = div.scrollTop - div.offsetHeight + 40;
        } else {
          div.scrollLeft = div.scrollLeft - div.offsetWidth + 40;
        }
      },
      scrollAfter() {
        let div = document.getElementById("scroll-items");
        if (this.isHorizontal) {
          div.scrollTop = div.scrollTop + div.offsetHeight - 40;
        } else {
          div.scrollLeft = div.scrollLeft + div.offsetWidth - 40;
        }
      },
      scrollToEndOfItems() {
        this.$nextTick(() => {
          let div = document.getElementById("scroll-items");
          if (div) {
            if (this.isHorizontal) {
              div.scrollTop = div.scrollHeight;
            } else {
              div.scrollLeft = div.scrollWidth;
            }
          }
        });
      },
      handleScroll() {
        let div = document.getElementById("scroll-items");
        if (div) {
          this.scrollAtBeginning = this.isHorizontal ? isElementAtBeginningOfVerticalScroll(div) : isElementAtBeginningOfHorizontalScroll(div);
          this.scrollAtEnd = this.isHorizontal ? isElementAtEndOfVerticalScroll(div) : isElementAtEndOfHorizontalScroll(div);
        }
      },
      async removePromotion() {
        let response = await showConfirmation(this.$t("warning"), this.$t('kiosk.remove_promotion_confirmation'));
        if (response) {
          PromotionManager.removePromotion(this.promotion);
        }
      },
      async cancel() {
        if (this.orderItemCount > 0) {
          let response = await showConfirmation(this.$t("kiosk.cancel_my_order"), this.$t("kiosk.cancel_my_order_description"), this.$t("yes"), this.$t("no"), "cancel");
          if (!response) {
            return;
          }
        }
        window.logToFile("SALE CANCELLED BY CUSTOMER", this.$order.getUniqueId());
        this.$order.reset();
        PromotionManager.reset();
        this.$router.push(this.$router.generate("/kiosk/standby"));
      },
      confirm() {
        let valid = this.$order.validateAddedItems();
        if (!valid) {
          return;
        }
        if (this.orderItemCount === 0) {
          return;
        }
        let route;
        if (this.inventoryHasSupplements) {
          route = "/paymentOptions";
        } else if (this.kioskConfiguration.isTipEnabled()) {
          route = "/kiosk/tip";
        } else {
          route = "/kiosk/confirmation";
        }
        this.$router.push(this.$router.generate(route));
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .vertical__AIRxTOUCH #kioskCart {
    height: 300px;

    .cart--items {
      height: 120px;

      .items {
        padding-top: 15px;
      }
    }
    .start {
      top: 20px;
    }
    .cart--footer {
      padding: 15px 40px 25px 40px;
    }
  }

  #iShopFoodApp #kioskCart.horizontal {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 500px;
    box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;

    &.siblingModalVisible {
      position: relative;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-color: rgba(0,0,0, 0.3);
      }
    }

    .start {
      width: 400px;
      top: 300px;
    }

    #button-cancel {
      margin-right: 20px;
    }
    #button-confirm {
      margin-left: 20px;
    }

    .cart--footer {
      flex-wrap: wrap;
    }

    .footer--subtotal {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 1;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
      padding: 15px;
      margin-bottom: 30px;

      .subtotal--title {
        text-transform: uppercase;
        color: #808080;
        font-weight: bold;
        font-size: 16px;
      }
      .subtotal--amount {
        font-family: 'Segoe UI Black', sans-serif;
        color: #1a1a1a;
        font-size: 24px;
      }
    }

    .cart--header {
      height: 80px;
      align-items: center;
      line-height: 1;

      .header--arrow, .header--right {
        display: none;
      }
      .left--title {
        font-size: 24px;
      }
    }
    .arrow__right, .arrow__left {
      padding: 10px 25px;
      .svgicon {
        transform: rotate(90deg);
      }
    }
    .arrow__right {
      &:before {
        left: 20px;
        top: 0;
        height: 1px;
        width: calc(100% - 40px);
      }
    }
    .arrow__left {
      &:after {
        left: 20px;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
      }
    }
    .cart--items {
      flex-grow: 1;
      height: 645px;
      flex-direction: column;

      .items {
        flex-wrap: nowrap;
      }

      .cart-item__fake {
        width: 430px;
        max-width: none;
        margin: 10px 30px;
      }
    }
  }

  #iShopFoodApp #kioskCart {
    height: 410px;
    transition: all 275ms ease-in-out;
    overflow: hidden;
    flex-shrink: 0;

    .cart--header {
      background-color: #ed2937;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      padding: 22px 40px;
      position: relative;
    }

    &.collapsed {
      height: 70px;
    }

    .header--arrow {
      fill: #fc6650;
      transition: transform 250ms ease-in-out;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    .header--left, .header--right {
      display: flex;
      align-items: flex-end;
    }

    .bigger {
      text-transform: uppercase;
      color: white;
      font-family: 'Segoe UI Black';
      font-weight: bold;
      font-size: 20px;
    }

    .smaller {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
    }

    .cart--items {
      display: flex;
      background-color: #fafafa;
      height: 210px;
      opacity: 1;
      transition: all 250ms ease-in-out;

      .items {
        flex-grow: 1;
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-items: center;
        padding-top: 15px;
        position: relative;
      }
    }

    .cart--arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px;
      fill: #fc6650;
      position: relative;
      transition: opacity 100ms linear;

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    .arrow__left:after, .arrow__right:before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: #e6e6e6;
      height: calc(100% - 40px);
    }

    .arrow__left:after {
      right: 0;
    }

    .arrow__right:before {
      left: 0;
    }

    .start {
      width: 550px;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-family: "Segoe UI Black", sans-serif;
      color: #bfbfbf;
      font-size: 26px;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);

      &:not(:first-child) {
        display: none;
      }
    }

    .left--title, .right--products {
      &:after {
        content: "";
        display: inline-block;
        margin: 0 15px;
        height: 15px;
        width: 2px;
        background-color: #fc6650;
      }
    }

    .cart--footer {
      background-color: #f5f5f5;
      padding: 30px 40px;
      display: flex;
      justify-content: space-between;
    }

    .cart-item__fake {
      border-radius: 20px;
      box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
      margin: 10px 20px;
      min-width: 350px;
      max-width: 450px;
      flex-shrink: 0;
      overflow: hidden;
      display: flex;
      fill: white;
      background: linear-gradient(to right, #ed2937, #fc6650);
      align-items: center;
      height: 71px;

      .left {
        padding: 15px 30px 15px 15px;
        flex-grow: 1;
      }

      .icon {
        padding: 8px 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }

      .promotion--price {
        font-weight: 900;
        font-size: 20px;
        padding-right: 15px;
        color: white;
        font-family: 'Segoe UI Black', sans-serif;
      }

      .promotion--name {
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 280px;
        color: white;
      }

      .promotion--type {
        font-size: 16px;
        color: white;
        padding-top: 2px;
      }
    }

    #button-cancel {
      background-color: #fc6650;
      flex-grow: 1;
      margin-right: 40px;
      color: white;
    }

    #button-confirm {
      background-color: #80d445;
      margin-left: 40px;
      flex-grow: 1;
      color: white;
    }
  }
</style>
