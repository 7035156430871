import AppliedPromotion from "@/models/pos/promotions/AppliedPromotion";
import { Constant } from "@/util/Constant";
import Detail from "@/models/pos/DetailModel";
import Item from "@/models/ItemModel";
import Promotion from "@/models/PromotionModel";
import Table from "@/models/pos/TableModel";
import moment from "moment";
import store from "@/store";

export class PromotionVisitorPOS {

  /**
   * @param {Table} table
   * @param {number} branch
   * @param {number} company
   */
  constructor(table, branch, company) {
    this.table = table;
    this.branch = branch;
    this.company = company;
    this.promotions = [];
  }

  /**
   * Get this promotion visitor's table
   * @returns {Table}
   */
  getTable() {
    return this.table;
  }

  /**
   * Get this promotion visitor's current branch
   * @returns {number}
   */
  getBranch() {
    return this.branch;
  }

  /**
   * Get this promotion visitor's current company
   * @returns {number}
   */
  getCompany() {
    return this.company;
  }

  /**
   * Get a list of all promotions available on this promotion visitor
   * @returns {Promotion[]}
   */
  getPromotions() {
    return this.promotions;
  }

  /**
   * Get a list of all item promotions available on this promotion visitor
   * @returns {Promotion[]}
   */
  getItemPromotions() {
    return this.promotions.filter(p => p.appliedOn === "items" && p.type !== "hidden_product");
  }

  /**
   * Get a list of all sale promotions available on this promotion visitor
   * @returns {Promotion[]}
   */
  getSalePromotions() {
    return this.promotions.filter(p => p.appliedOn === "sale");
  }

  /**
   * Get a list of all hidden product promotions available on this promotion visitor
   * @returns {Promotion[]}
   */
  getHiddenPromotions() {
    return this.promotions.filter(p => p.type === "hidden_product");
  }

  /**
   * Get a list of all sale promtions that are currently applied
   * @returns {Promotion[]}
   */
  getAppliedSalePromotions() {
    return this.promotions.filter(p => p.appliedOn === "sale" && p.applied);
  }

  /**
   * Get a specific promotion by id
   * @param {number} id
   * @returns {Promotion}
   */
  getPromotion(id) {
    for (let promotion of this.promotions) {
      if (promotion.id === parseInt(id)) {
        return promotion;
      }
    }
    return null;
  }

  /**
   * Add a new promotion to this promotion visitor
   * @param {Promotion} promotion
   * @return {boolean}
   */
  addPromotion(promotion) {

    // Check if promotion is already added
    for (let promo of this.promotions) {
      if (promo.id === promotion.id) {
        return false;
      }
    }

    // Check if promotion can be applied at this date
    if (promotion.startAt && promotion.startAt > moment()) {
      return false;
    }
    if (promotion.endAt && promotion.endAt < moment()) {
      return false;
    }

    // Check if promotion falls into the schedule
    if (Array.isArray(promotion.schedule)) {
      let date = this.table.getInAdvanceForDate();
      let orderDate = date ? moment(date, Constant.API_DATE_FORMAT) : moment();
      let dayOfWeek = orderDate.format("dddd").toLowerCase();

      let found = promotion.schedule.find(s => s.name === dayOfWeek);
      if (!found || found.hours.length === 0) {
        return false;
      }
      for (let hour of found.hours) {
        let from = moment(hour.from, "HH:mm");
        let to = moment(hour.to, "HH:mm");
        if (!orderDate.isBetween(from, to)) {
          return false;
        }
      }
    }

    if (promotion.appliedOn === "sale") {
      promotion.applied = false;
    }
    this.promotions.push(promotion);
    if (promotion.appliedOn === "items") {
      this.table.getDetails().map(d => this.applyPromotionOnDetail(promotion, d));
    }
    this.resetPercentPromotionsOnItems();
    this.updateItemPromotions();
    this.updateSalePromotions();
    return true;

  }

  /**
   * Remove a specific promotion from this promotion visitor by id
   * @param {number} id
   * @returns {boolean}
   */
  removePromotion(id) {
    let index = this.promotions.findIndex(p => p.id === id);
    if (index === -1) {
      return false;
    }
    let promotion = this.promotions.splice(index, 1)[0];
    if (promotion.appliedOn === "sale") {
      this.removePromotionOnSale(promotion);
      return true;
    }
    if (promotion.appliedOn === "items") {
      this.table.getDetails().map(d => this.removePromotionOnDetail(promotion, d));
      return true;
    }
    console.log("Removed unknown promotion applied on " + promotion.appliedOn);
    return false;
  }

  /**
   * Apply a specific promotion from this promotion visitor by id
   * @param {number} id
   */
  applyPromotion(id) {
    let promotion = this.getPromotion(id);
    if (!promotion) {
      return;
    }
    promotion.applied = true;
    if (promotion.appliedOn === "items") {
      this.table.getDetails().map(d => this.applyPromotionOnDetail(promotion, d));
    }
    if (promotion.appliedOn === "sale") {
      this.applyPromotionOnSale(promotion);
    }
  }

  /**
   * Unapply a specific promotion from this promotion visitor by id
   * @param {number} id
   */
  unapplyPromotion(id) {
    let promotion = this.getPromotion(id);
    if (!promotion) {
      return;
    }
    promotion.applied = false;
    if (promotion.appliedOn === "items") {
      this.table.getDetails().map(d => this.removePromotionOnDetail(promotion, d));
    }
    if (promotion.appliedOn === "sale") {
      this.removePromotionOnSale(promotion);
    }
  }

  /**
   * Load all promotions available from the API
   * @returns {Promise<Promotion[]>}
   */
  async loadFromServer() {
    let response = await store.dispatch("request", {
      method: "GET",
      url: `/api/branches/${this.branch}/companies/${this.company}/promotions-info`
    });
    let toAdd = [];
    this.promotions = [];
    for (let promo of response.visiblePromotions) {
      let newPromotion = new Promotion(promo);
      toAdd.push(newPromotion);
      this.addPromotion(newPromotion);
    }
    return toAdd;
  }

  /**
   * Apply all applicable promotions on one or multiple details
   * @param {Detail[]} details
   */
  addPOSDetails(details) {
    for (let promotion of this.promotions.filter(p => p.appliedOn === "items")) {
      for (let detail of details) {
        this.applyPromotionOnDetail(promotion, detail);
      }
    }
    this.resetPercentPromotionsOnItems();
    this.updateItemPromotions();
    this.updateSalePromotions();
  }

  /**
   * Remove the given details from the sale and recalculate the promotions
   * @param {Detail[]} details
   */
  removePOSDetails(details) {
    this.resetPercentPromotionsOnItems();
    this.updateItemPromotions();
    this.updateSalePromotions();
  }

  /**
   * Get a list of all promotions applicable on a specific item
   * @param {Item} item
   * @returns {Promotion[]}
   */
  getPromotionsForItem(item) {
    if (!item) {
      return [];
    }
    return this.promotions.filter(p => {
      if (p.appliedOn !== "items") {
        return false;
      }
      if (p.inclusion?.items) {
        return p.inclusion.items.includes(item.id);
      }
      if (p.inclusion?.tags && item.tags) {
        for (let tag of item.tags) {
          if (p.inclusion.tags.includes(tag.id)) {
            return true;
          }
        }
      }
      return false;
    });
  }

  /**
   * Get a list of all items for which a specific promotion is applicable
   * @param {Item[]} items
   * @param {Promotion} promotion
   */
  getItemsForPromotion(items, promotion) {
    let foundItems = [];
    for (let item of items) {
      if (this.canApplyPromotionToItem(promotion, item)) {
        foundItems.push(item);
      }
    }
    return foundItems;
  }

  /**
   * Apply every promotion application on a specific item
   * @param {Item} item
   */
  applyAllPromotionsOnItem(item) {
    for (let promotion of this.promotions) {
      if (item.selectedVariant) {
        item.appliedPromotions = null;
      }
      this.applyPromotionOnDetail(promotion, item.selectedVariant ?? item);
    }
  }

  /**
   * @private
   * @param {Promotion} promotion
   * @param {Item} item
   */
  canApplyPromotionToItem(promotion, item) {
    let itemPlusVariants = [item, ...item.variants];
    for (let item of itemPlusVariants) {
      // Check if the item's id is in the promotion's inclusion list
      if (promotion.inclusion?.items) {
        if (promotion.inclusion.item.includes(item.id)) {
          return true;
        }
      }
      // Check if the item's tags are in the promotion's inclusion list
      if (promotion.inclusion?.tags) {
        for (let tag of item.tags) {
          if (promotion.inclusion.tags.includes(tag.id)) {
            return true;
          }
        }
      }
      // Check if the item's tags are in the promotion's trigger parameters
      if (promotion.triggerParameters?.tags) {
        for (let tag of item.tags) {
          if (promotion.triggerParameters.tags.includes(tag.id)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * @private
   */
  updateSalePromotions() {
    this.promotions.filter(p => p.appliedOn === "sale").map(p => this.applyPromotionOnSale(p));
  }

  /**
   * @private
   */
  updateItemPromotions() {
    for (let detail of this.table.getDetails()) {
      this.promotions.filter(p => p.appliedOn === "items").map(p => this.applyPromotionOnDetail(p, detail));
    }
  }

  /**
   * Important d'appeler cette fonction avant de mettre à jour les sale promotions
   * @private
   */
  resetPercentPromotionsOnItems() {
    for (let detail of this.table.getDetails()) {
      detail.appliedPromotions.filter(p => p.type === "percent").map(p => {
        p.amount = 0;
      });
    }
  }

  /**
   * @private
   * @param {Promotion} promotion
   */
  resetAmountForAllDetails(promotion) {
    for (let detail of this.table.getDetails()) {
      let alreadyApplied = detail.appliedPromotions.filter(p => p.id === promotion.id)[0];
      if (alreadyApplied) {
        alreadyApplied.amount = 0;
      }
    }
  }

  /**
   * @private
   * @param {Promotion} promotion
   */
  applyPromotionOnSale(promotion) {
    if (!promotion.applied) {
      return;
    }
    switch (promotion.type) {
      case "percent":
        for (let detail of this.table.getDetails()) {
          this.applyPromotionOnDetail(promotion, detail);
        }
        break;
      case "flat":
        this.resetAmountForAllDetails(promotion);
        var amount = promotion.value;
        for (let detail of this.table.getDetails()) {
          let total = detail.getPrice();
          if (total > amount) {
            total = amount;
          }
          this.applyPromotionOnDetail(promotion, detail, total / detail.quantity);
          amount -= total;
          if (amount <= 0) {
            break;
          }
        }
        break;
      default:
        console.log("Could not apply promotion " + promotion.name.en + " on sale");
        break;
    }
  }

  /**
   * @private
   * @param {Promotion} promotion
   * @param {Detail} detail
   * @param {number} [promotionAmount]
   */
  applyPromotionOnDetail(promotion, detail, promotionAmount = 0) {
    if (!promotion.applied) {
      return;
    }
    let includedItems = promotion.inclusion?.items || [];
    let includedTags = promotion.inclusion?.tags || [];
    if (promotion.appliedOn === "items" &&
      !promotion.triggerType && !promotion.triggerParameters &&
      includedItems.length === 0 && includedTags.length === 0) {
      return;
    }
    if (includedItems.length > 0) {
      if (includedItems.indexOf(detail.id) === -1 &&
        includedItems.indexOf(detail.itemId) === -1 &&
        includedItems.indexOf(detail.parentItemId) === -1) {
        return;
      }
    } else if (includedTags.length > 0) {
      let willReturn = true;
      for (let tag of detail.tags) {
        if (includedTags.indexOf(tag.id) > -1) {
          willReturn = false;
          break;
        }
      }
      if (willReturn) {
        return;
      }
    }
    let amount = 0;
    if (detail.appliedPromotions === null) {
      detail.appliedPromotions = [];
    }
    switch (promotion.type) {
      case "percent":
        var price = detail.getUnitPrice();
        // Apply replace promotions before calculating percent
        price -= detail.appliedPromotions.filter(p => p.type === "replace").reduce((a, b) => a + b.amount, 0);
        // Apply promotion multiplicatively with other percent promotions
        price -= detail.appliedPromotions.filter(p => p.type === "percent" && p.id !== promotion.id).reduce((a, b) => a + b.amount, 0);
        amount = price * promotion.value / 100.0;
        break;
      case "flat":
        amount = promotionAmount || promotion.value;
        break;
      case "replace":
        amount = detail.getUnitPrice() - promotion.value;
        break;
      case "hidden_product":
        // Check if item is enabler item (if any)
        if (promotion.triggerType === "with_item" && promotion.triggerParameters.items.indexOf(detail.id) > -1) {
          return;
        }
        // Check if item is the hidden item
        var applies = false;
        for (let tag of detail.tags) {
          if (promotion.triggerParameters.tags.indexOf(tag.id) > -1) {
            if (promotion.triggerType === "with_item") {
              // If the item is the hidden item, we check if the enabler item is in the table
              for (let tableDetail of this.table.getDetails()) {
                if (tableDetail !== detail && promotion.triggerParameters.items.indexOf(tableDetail.itemId) > -1) {
                  applies = true;
                }
              }
            } else {
              applies = true;
            }
            break;
          }
        }
        if (!applies) {
          // Remove the promotion if it is already applied
          let filtered = detail.appliedPromotions.filter(p => p.id !== promotion.id);
          if (filtered.length !== detail.appliedPromotions.length) {
            detail.appliedPromotions = filtered;
          }
          return;
        }
        break;
      default:
        console.log(`Could not apply promotion ${promotion.name.fr} with promotion type ${promotion.type} to detail ${detail.name.fr}`);
        break;
    }
    let alreadyApplied = detail.appliedPromotions.filter(p => p.id === promotion.id)[0];
    if (alreadyApplied) {
      alreadyApplied.amount = amount;
      return;
    }
    detail.appliedPromotions.push(new AppliedPromotion(promotion, amount));
  }

  /**
   * @private
   * @param {Promotion} promotion
   */
  removePromotionOnSale(promotion) {
    for (let detail of this.table.getDetails()) {
      this.removePromotionOnDetail(promotion, detail);
    }
  }

  /**
   * @private
   * @param {Promotion} promotion
   * @param {Detail} detail
   */
  removePromotionOnDetail(promotion, detail) {
    detail.appliedPromotions = detail.appliedPromotions.filter(p => p.id !== promotion.id);
    this.updateSalePromotions();
  }

}