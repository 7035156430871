import "simplebar/dist/simplebar.min.css";
import "vue-datetime/dist/vue-datetime.css";
import { Localize, LocalizeStore } from "./lib/localize";
import VueAnalytics, { event } from "vue-analytics";
import App from "./App.vue";
import Axios from "axios";
import CartItem from "@/components/cart/CartItem";
import Clamp from "@/components/tools/Clamp.vue";
import { Constant } from "@/util/Constant";
import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
import Datetime from "./lib/vue-datetime.common.js";
import EventBus from "@/lib/eventBus.js";
import GeocodingHelper from "@/lib/geocodingHelper";
import HistoryBack from "@/components/HistoryBack.vue";
import Inventory from "./models/InventoryModel";
import KioskConfiguration from "./models/KioskConfigurationModel";
import { LogProxy } from "@/lib/LogProxy";
import { NetworkMonitor } from "./networkMonitor";
import Order from "./models/OrderModel";
import PageTitle from "@/components/PageTitle.vue";
import Panel from "@/components/Panel.vue";
import PosConfiguration from "@/models/pos/PosConfigurationModel";
import Scrollbar from "@/components/tools/Scrollbar";
import SvgIcon from "@/components/tools/SvgIcon.vue";
import User from "./models/UserModel";
import VWave from "./lib/vwave";
import Validate from "./lib/validate";
import Vue from "vue";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import moment from "moment-timezone";
import router from "./router";
import store from "./store";

require("./assets/style/fonts.css");
require("./assets/style/payfacto.css");
require("./assets/style/modals.scss");
require("./assets/style/forms.scss");
require("./assets/style/global.scss");
require("./assets/style/vue-datetime.scss");
require("./assets/style/navigator-compatibility.scss");
require("./global");
require("./lib/toast.js");
require("./assets/style/toast.scss");

// Check for forced mode
let forcedPos = localStorage.getItem("force-pos") === "true";
if (forcedPos) {
  CONFIG.pos = true;
}

// Logging
if (typeof cordova !== "undefined" && CONFIG.pos) {
  let proxy = new LogProxy();
  proxy.start();
}

if (navigator.userAgent.indexOf("iPhone") === -1 && navigator.userAgent.indexOf("iPad") === -1 && typeof iNoBounce !== "undefined") {
  iNoBounce.disable();
}
// https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device-in-jquery
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  let script = document.createElement("script");
  script.src = "cordova.js";
  document.body.appendChild(script);
}

document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {
  console.log("onDeviceReady");
  store.state.mobileOs = device.platform.toLowerCase();
  initializeAxiosMobile();
  mountVueApp();
  if (CONFIG.pos) {
    NetworkMonitor.init();
    CustomerScreenLoader.initialize();
  }
}

/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCYaw2sV4Ev2nrzOCMnA1wDI-fIYSBU2X0&libraries=places"></script> */
if (!CONFIG.kiosk && CONFIG.googleApiKey) {
  let googleApi = document.createElement("script");
  googleApi.src = "https://maps.googleapis.com/maps/api/js?key=" + CONFIG.googleApiKey + "&libraries=places";
  document.body.appendChild(googleApi);
}

Vue.config.productionTip = false;

Vue.component("svgicon", SvgIcon);
Vue.component("v-clamp", Clamp);
Vue.component("Panel", Panel);
Vue.component("PageTitle", PageTitle);
Vue.component("HistoryBack", HistoryBack);
Vue.component("scrollbar", Scrollbar);
Vue.use(Validate);
Vue.use(Localize);
Vue.use(Datetime);
Vue.use(VueFacebookPixel);
Vue.use(VWave);

if (!CONFIG.kiosk) {
  GeocodingHelper.init();
}

store.state.inventory = new Inventory();
let order = new Order();
if (CONFIG.kiosk) {
  order.setHour(Constant.ORDER_HOUR_ASAP);
}
store.state.order = order;
Vue.prototype.$order = order;
let user = new User();
store.state.user = user;
Vue.prototype.$user = user;
store.state.router = router;
Vue.prototype.$branch = store.state.currentBranch;
store.state.version = CONFIG.version ? CONFIG.version : "0.0.0";

let mountVueApp = () => {
  return new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#iShopFoodApp");
};

/* Load kiosk configuration */
let isAirxTouchExecutableWindow = false;
if (CONFIG.kiosk) {
  require("./nw-update.js");
  require("./lib/airxtouch/airxtouch-driver-extension.js");
  Vue.prototype.$kiosk = CONFIG.kiosk;
  Vue.component("CartItem", CartItem); //TODO idk why it's not recognized locally in KioskCart.vue
  let kioskConfiguration = new KioskConfiguration();
  store.state.kioskConfiguration = kioskConfiguration.loadFromLocalKioskConfiguration();
  document.addEventListener("contextmenu", event => event.preventDefault()); //Disable right click on kiosk

  if (store.state.kioskConfiguration) {
    store.dispatch("getBranch", {
      id: store.state.kioskConfiguration.branchCanonical,
      company: store.state.kioskConfiguration.companyCanonical
    });

    if (store.state.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
      isAirxTouchExecutableWindow = true;
      try {
        window.airxtouch__initializeProxy();
        window.airxtouch__startSignalRConnection();
      } catch (e) {
        console.warn("ERROR : communicating with signalR - airxtouch driver", e);
      }
    }

    if (store.state.kioskConfiguration.isTicket()) {
      store.state.ticketMode = true;
    }
  }
  window.standbyViewStack = 0;
} else {
  Vue.prototype.$kiosk = false;
}

/* Load POS configuration */
if (CONFIG.pos) {
  Vue.prototype.$pos = CONFIG.pos;
  let posConfiguration = new PosConfiguration();
  store.state.posConfiguration = posConfiguration.loadFromLocalPosConfiguration();
  if (typeof cordova === "undefined") {
    NetworkMonitor.init();
  }

  if (store.state.posConfiguration && store.state.posConfiguration.branchCanonical && store.state.posConfiguration.companyCanonical) {
    store.dispatch("getBranch", {
      id: store.state.posConfiguration.branchCanonical,
      company: store.state.posConfiguration.companyCanonical
    });
  }
} else {
  Vue.prototype.$pos = false;
}

/* Resize stuff */

// Dynamic Zoom
const onResizeCallback = () => {
  let referenceWidth = 1100;
  let targetWidth = 1600;
  let targetHeight = 900;

  if (CONFIG.kiosk || CONFIG.pos) {
    referenceWidth = 0;
    if (CONFIG.pos || (store.state.kioskConfiguration && store.state.kioskConfiguration.isHorizontal())) {
      targetWidth = 1920;
      targetHeight = 1080;
    } else {
      targetWidth = 1080;
      targetHeight = 1920;
    }
  }
  if (document.getElementById("iShopFoodApp").clientWidth > referenceWidth) {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let widthRatio = width / targetWidth;
    let heightRatio = height / targetHeight;
    let smallestRatio = widthRatio < heightRatio ? widthRatio : heightRatio;
    if (smallestRatio < 1 || (CONFIG.kiosk || CONFIG.pos)) { //Allow zoomed-in ratios from kiosk
      document.getElementById("iShopFoodApp").style.zoom = smallestRatio;
      store.state.zoom = smallestRatio;
      // Adding CSS for Google Autocomplete
      if (!document.getElementById("pacCss")) {
        let css = document.createElement("style");
        css.innerHTML = ".pac-container{zoom:" + smallestRatio + ";}";
        css.id = "pacCss";
        document.head.appendChild(css);
      }
    }
  }
};
onResizeCallback();
setTimeout(onResizeCallback, 1);

const resize = () => {
  if (CONFIG.kiosk || CONFIG.pos) {
    store.state.screenSize = "md";
    onResizeCallback();
    return;
  }
  if (document.getElementById("iShopFoodApp").clientWidth <= 600) {
    store.state.screenSize = "xs";
  } else if (document.getElementById("iShopFoodApp").clientWidth <= 1200) {
    store.state.screenSize = "sm";
  } else if (document.getElementById("iShopFoodApp").clientWidth <= 1600) {
    store.state.screenSize = "md";
  } else {
    store.state.screenSize = "lg";
  }
  onResizeCallback();
};
window.onresize = resize;
window.resize = resize;
resize();

window.store = store;
window.translate = LocalizeStore.getters.get;
window.translateCurrency = LocalizeStore.getters.getCurrencySymbol;
window.translateObject = LocalizeStore.getters.getFromObject;
window.moment = moment;

// Add preload division
if (!document.getElementById("preload")) {
  let preload = document.createElement("div");
  preload.style.position = "fixed";
  preload.style.opacity = "0.01";
  preload.id = "preload";
  let checkmark = document.createElement("span");
  checkmark.classList.add("ion-ios-checkmark-circle");
  preload.appendChild(checkmark);
  document.body.appendChild(preload);
}

// Check for shop lock
let mainDiv = document.getElementById("iShopFoodApp");
let shopLock = mainDiv.attributes.branch;
if (shopLock) {
  CONFIG.shopLock = shopLock.value;
}

let interval = setInterval(async () => {
  if (!store.state.currentBranch) {
    return;
  }
  clearInterval(interval);
  let analytics = store.state.currentBranch.analytics;
  let ids = ["UA-72200560-1"]; // Ishopfood tracking ID
  if (analytics.length > 0) {
    let googleAnalytics = analytics.filter(a => a.type === "google");
    for (let googleAnalytic of googleAnalytics) {
      ids.push(googleAnalytic.id);
    }
  }
  Vue.use(VueAnalytics, {
    id: ids,
    ecommerce: {
      enabled: true
    }
  });
}, 100);

/* Reload sale */
if (getConfiguration("unload") && !CONFIG.kiosk) {
  if (CONFIG.pos) {
    try {
      let dto = getConfiguration("unload");
      if (dto.externalPosSaleQueue) {
        const LZString = require("lz-string");
        let queue = JSON.parse(LZString.decompress(dto.externalPosSaleQueue));
        store.state.externalPosSaleQueue = queue;
        setConfiguration("unload", null);
      }
      store.state.employee = dto.employee;
      if (dto.employee) {}
    } catch (e) {
      console.log("error unloading data", e);
    }
  } else {
    let interval = setInterval(async () => {
      if (!store.state.inventory || !store.state.currentCompany || store.state.inventory.getRoot().length === 0) {
        return;
      }
      clearInterval(interval);
      if (!getConfiguration("unload")) {
        return;
      }
      let dto = getConfiguration("unload");
      if (store.state.currentCompany.id !== dto.company || store.state.currentCompany.isClosed()) {
        return;
      }
      let result = await showConfirmation(LocalizeStore.getters.get("order.reload_title"),
        LocalizeStore.getters.get("order.reload"),
        LocalizeStore.getters.get("order.reload_confirm"),
        LocalizeStore.getters.get("order.reload_cancel"),
        "continue");
      if (result) {
        EventBus.$emit("close-order-options-modal");
        order.fromDto(dto);
      } else {
        setConfiguration("unload", null);
      }
    }, 100);
  }
}

window.onbeforeunload = function () {
  if (CONFIG.pos && store.state.externalPosSaleQueue.length > 0) {
    const LZString = require("lz-string");
    let queue = LZString.compress(JSON.stringify(store.state.externalPosSaleQueue));
    setConfiguration("unload", {
      externalPosSaleQueue: queue,
      employee: store.state.employee
    });
  } else if (order.getItemCount() > 0) {
    setConfiguration("unload", order.toDto());
  } else {
    setConfiguration("unload", null);
  }
  /* AIRxTOUCH kiosk signal R connection kill */
  if (isAirxTouchExecutableWindow) {
    try {
      window.airxtouch__clearTimeout();
      window.airxtouch__stopSignalRConnection();
    } catch (e) {
      console.warn("error with AIRxTOUCH communication", e);
    }
  }
};

/* AIRxTOUCH kiosk signal R connection kill */
if (isAirxTouchExecutableWindow) {
  try {
    window.onfocus = () => {
      window.airxtouch__startSignalRConnection();
    };
    window.onblur = () => {
      window.airxtouch__clearTimeout();
      window.airxtouch__stopSignalRConnection();
    };
  } catch (e) {
    console.warn("error with AIRxTOUCH communication", e);
  }
}

/* App update */
function initializeAxiosMobile() {
  Axios.defaults.headers.common["User-App-Name"] = CONFIG.shopLock;
  if (store.state.mobileOs === "android" && typeof CONFIG.versionAndroid !== "undefined") {
    Axios.defaults.headers.common["User-Version-Android"] = CONFIG.versionAndroid;
  } else if (store.state.mobileOs === "ios" && typeof CONFIG.versionIos !== "undefined") {
    Axios.defaults.headers.common["User-Version-IOS"] = CONFIG.versionIos;
  }

  Axios.interceptors.response.use(function (response) {
    //success
    return response;
  }, function (error) {
    if (error.response && error.response.status === 426) {
      if (!!error.response.data && !!error.response.data[store.state.mobileOs]) {
        store.state.updateUrl = error.response.data[store.state.mobileOs].url;
      }
      store.state.updateRequired = true;
    }
    return Promise.reject(error);
  });
}

/* Router stuff */

router.onReady(() => {
  if (router.currentRoute.query.method) {
    order.method = router.currentRoute.query.method;
  }
  if (router.currentRoute.query.locale) {
    LocalizeStore.commit("setLocale", router.currentRoute.query.locale);
    setConfiguration("locale", router.currentRoute.query.locale);
  }
  if (router.currentRoute.query.table) {
    order.tableNumber = router.currentRoute.query.table;
    order.method = "inPlace";
  }
  if (router.currentRoute.query.room) {
    order.roomNumber = router.currentRoute.query.room;
    order.method = "hotel";
  }
  let branchCanonical = router.currentRoute.params.branch;
  if (branchCanonical) {
    if (window.location.hostname !== "localhost" &&
      window.location.hostname !== "ishopfood-frontend-v3.myrdevelopment.com" &&
      CONFIG.originRedirect && CONFIG.originRedirect.length > 0) {
      let found = CONFIG.originRedirect.find(r => r.branch === branchCanonical);
      let currentOrigin = window.location.origin;
      if (found && currentOrigin !== found.origin) {
        window.location.replace(window.location.href.replace(currentOrigin, found.origin));
        return;
      }
    }
  }
  if (!CONFIG.pos && !CONFIG.kiosk) { //Load branch for online ordering, when branch isn't provided by a local config
    store.dispatch("getBranch", {
      id: branchCanonical,
      company: router.currentRoute.params.company
    });
  }
});

let oldReplace = router.replace.bind(router);
router.replace = function (path) {
  router.toReplace = true;
  oldReplace(path);
};

let lastParams = {};

router.generate = function (route, params = {}) {
  let url = "";
  let branch = params.branch || (store.state.currentBranch ? store.state.currentBranch.nameCanonical : null) || lastParams.branch;
  let company = params.company || (store.state.currentCompany ? store.state.currentCompany.nameCanonical : null) || lastParams.company;
  if (company) {
    url = "/" + company + url;
  }
  if (branch) {
    url = "/" + branch + url;
  }
  lastParams = {};
  return url + route;
};

router.generateFirstPage = function () {
  let page;
  let branch = store.state.currentBranch;
  // let company = store.state.currentCompany;
  let route = router.currentRoute;

  if (CONFIG.kiosk) {
    let kioskConfiguration = store.state.kioskConfiguration;
    if (kioskConfiguration) {
      let branch = kioskConfiguration.branchCanonical;
      let company = kioskConfiguration.companyCanonical;
      if (branch && company) {
        return "/" + branch + "/" + company + "/kiosk/standby";
      }
    }
    return this.generate("/kiosk/configuration");
  } else if (CONFIG.pos) {
    let posConfiguration = store.state.posConfiguration;
    if (posConfiguration) {
      let branch = posConfiguration.branchCanonical;
      let company = posConfiguration.companyCanonical;
      if (branch && company) {
        return "/" + branch + "/" + company + "/pos/dashboard";
      }
    }
    return this.generate("/pos/loading");
  }

  if (route.name === "view-menu" && route.params.branch && route.params.company) {
    return this.generate("/view-menu", route.params);
  }

  if (router.name === "tickets" && route.params.branch && route.params.company) {
    return this.generate("/tickets", route.params);
  }

  if (branch && (branch.shouldDisplayTutorial() || branch.shouldDisplayHomePage)) {
    page = getMobileAppFirstPage();
  } else {
    page = "/menu";
  }

  return this.generate(page);
};

function getMobileAppFirstPage() {
  let page;
  let branch = store.state.currentBranch;
  let company = store.state.currentCompany;

  if (!branch) {
    return this.generate();
  }
  if (branch.shouldDisplayTutorial()) {
    return "/tutorial";
  }
  if (branch.shouldDisplayHomePage()) {
    if (!company) {
      store.state.skippedOrderType = true;
    }
    page = "home";
  } else if (branch.shouldLaunchAppWithHub()) {
    if (!company) {
      store.state.skippedOrderType = true;
    }
    page = "hub";
  } else {
    page = "menu";
  }
  if ((branch.shouldDisplayHomePage() || branch.shouldLaunchAppWithHub()) && !user.token) {
    if (!company) {
      store.dispatch("setCurrentCompany", branch.getFirstNonEmergencyClosedCompany());
    }
    page = "/login?redirect=" + (company ? page : "orderType");
  } else if (company || branch.shouldLaunchAppWithHub()) {
    if (!company) {
      store.dispatch("setCurrentCompany", branch.getFavoriteCompanyForUser(user) || branch.getFirstNonEmergencyClosedCompany());
    }
    page = "/" + page;
  } else if (branch.getFavoriteCompanyForUser(user)) {
    store.dispatch("setCurrentCompany", branch.getFavoriteCompanyForUser(user));
    page = "/menu";
  } else {
    page = "/orderType";
  }
  return page;
}

router.beforeEach((to, from, next) => {
  let userMustBeNotLoggedIn = ["login", "register"];
  let userMustBeLoggedIn = ["profile", "history", "hub", "barcode"];
  let qrPaymentRoutes = ["qrPaymentHome", "qrPaymentInvoices", "qrPaymentPayment", "qrPaymentConfirmation", "qrPaymentMenuDisplay", "qrPaymentError", "qrPaymentUnavailable", "qrPaymentCheckReload"];
  let giftCardRoutes = ["giftCardPurchase", "giftCardView", "giftCardPrint"];
  lastParams = to.params;

  /* at the table pages */
  store.state.qrPaymentMode = qrPaymentRoutes.indexOf(to.name) > -1;

  /* gift card purchase pages */
  store.state.giftCardMode = giftCardRoutes.indexOf(to.name) > -1;

  if (!CONFIG.pos) {
    setTimeout(() => {
      let token = getConfiguration("token") || getConfiguration("token", sessionStorage);
      if (!store.state.user.token && token) {
        store.state.user.token = token;
      }
      if (token && userMustBeNotLoggedIn.indexOf(to.name) > -1) {
        next({
          path: router.generate("/orderType", to.params),
          params: to.params,
          query: to.query
        });
      } else if (!token && userMustBeLoggedIn.indexOf(to.name) > -1) {
        next({
          path: to.name === "hub" ? router.generate("/login?redirect=hub") : router.generate("/login?redirect=menu"),
          params: to.params,
          query: to.query
        });
      }
    }, 0);
  }

  /* Very breaking */
  if (typeof cordova !== "undefined" && to.name === "menu" && !from.params.company && !CONFIG.pos) {
    next({
      path: router.generate("/orderType", to.params),
      params: to.params,
      query: to.query
    });
  }

  if (to.params.branch && !CONFIG.kiosk && !CONFIG.pos) {
    // Branch has changed from URL, dev scenario
    if (store.state.currentBranch && store.state.currentBranch.nameCanonical !== to.params.branch) {
      store.state.currentCompany = null; // reset company
      store.state.user.token = null; // reset user login
      setConfiguration("token", null);
      setConfiguration("token", null, sessionStorage);
      store.dispatch("getBranch", {
        id: to.params.branch,
        company: to.params.company
      });
    }
  }

  if (from.query.locale && !to.query.locale) {
    next({
      path: to.path,
      params: to.params,
      query: Object.assign(to.query, { locale: from.query.locale })
    });
    return;
  }

  next();

});

if (!CONFIG.kiosk) {
  window.gm_authFailure = function () {
    event("critical-error-google", window.location.href);
    showAffirmation(translate("error.title"), translate("error.critical_google"));
  };
}

if (typeof cordova === "undefined") {
  mountVueApp();
  if (CONFIG.kiosk || CONFIG.pos) {
    resize();
  }
}
