import Promotion from "@/models/PromotionModel";

export default class AppliedPromotion {

	/**
	 * @param {Promotion} promotion
	 * @param {number} amount
	 */
	constructor(promotion, amount) {
		this.id = promotion.id;
		this.amount = amount;
		this.name = promotion.name;
		this.type = promotion.type;
		this.value = promotion.value;
	}

}