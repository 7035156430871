<template>
  <div id="batchReading">
    <Panel  :title="$t('pos.readings.financial_report')" icon="icons/reading.svg">
      <div class="batch-reading__body">
        <div class="batch-reading__about">
          <template v-if="currentBatch">
            <div class="group" v-if="currentBatch.getEmployeeName()">
              <div class="label">
                {{ $t('pos.readings.employee') }}
              </div>
              {{ currentBatch.getEmployeeName()}}
            </div>
            <div class="group" v-if="currentBatch.getWorkstationName()">
              <div class="label">
                {{ $t('pos.readings.workstation') }}
              </div>
              {{ currentBatch.getWorkstationName()}}
            </div>
            <template v-if="!isEmptyBatch">
              <div class="group">
                <div class="label">
                  {{ $t('pos.readings.start_date') }}
                </div>
                {{ currentBatch.getFormattedStartDate()}}
              </div>
              <div class="group" v-if="currentBatch && currentBatch.getFormattedCloseDate()">
                <div class="label">
                  {{ $t('pos.readings.end_date') }}
                </div>
                {{ currentBatch.getFormattedCloseDate()}}
              </div>
              <div class="group" v-if="currentBatch && currentBatch.getClosedBy()">
                <div class="label">
                  {{ $t('pos.readings.closed_by') }}
                </div>
                {{ currentBatch.getClosedBy()}}
              </div>
            </template>
          </template>
        </div>
        <div class="batch-reading__content">
          <div class="content__top" v-if="!isEmptyBatch">
            <div class="table-container">
              <div class="label">
                {{ $t('pos.readings.total_sale') }}
              </div>
              <div class="table">
                <div class="table__column">
                  <div class="label">{{ $t('pos.readings.subtotal') }}</div>
                  <div class="value value--small">{{ $tc(currentBatch.getSubTotal()) }}</div>
                </div>
                <div class="table__column">
                  <div class="label">{{ $t('pos.readings.service_fees') }}</div>
                  <div class="value value--small">{{ $tc(currentBatch.getServiceFees()) }}</div>
                </div>
                <div class="table__column">
                  <div class="label">{{ $t('pos.readings.surcharge_fees') }}</div>
                  <div class="value value--small">{{ $tc(currentBatch.getSurchargeFees()) }}</div>
                </div>
                <div class="table__column">
                  <div class="label">{{ $t('pos.readings.cash_rounding') }}</div>
                  <div class="value value--small">{{ $tc(currentBatch.getCashRounding()) }}</div>
                </div>
                <div class="table__column">
                  <div class="label">{{ $t('pos.readings.tip') }}</div>
                  <div class="value value--small">{{ $tc(currentBatch.getTipTotal()) }}</div>
                </div>
                <div class="table__column">
                  <div class="label label--bold-large">{{ $t('pos.readings.total') }}</div>
                  <div class="value">{{ $tc(currentBatch.getTotal()) }}</div>
                </div>
              </div>
            </div>
            <div class="table-container" v-if="currentBatch.getSalesDataByPaymentMethod().length > 0">
              <div class="label">
                {{ $t('pos.readings.total_sale') + " / " + $t('pos.readings.payment_method')}}
              </div>
              <div class="table">
                <div class="table__column" v-for="payment of currentBatch.getSalesDataByPaymentMethod()" :key="payment.type">
                  <div class="label">{{ $t('pos.readings.payment_methods.' + payment.type) }}</div>
                  <div class="value">{{ $tc(payment.settledAmount) }}</div>
                </div>
              </div>
            </div>
            <div class="table-container" v-if="currentBatch.getSalesDataByService().length > 0">
              <div class="label">
                {{ $t('pos.readings.total_sale') + " / " + $t('pos.readings.app')}}
              </div>
              <div class="table">
                <div class="table__column" v-for="service of currentBatch.getSalesDataByService()" :key="service.service">
                  <div class="label">{{ $t('pos.readings.services.' + service.service) }}</div>
                  <div class="value">{{ $tc(service.total) }}</div>
                </div>
              </div>
            </div>
          </div>
          <Empty :text="$t('pos.readings.empty_report')" :title="$t('pos.readings.empty_report_wait')" :hideTitle="!!currentBatch" :hideText="!currentBatch" v-if="isEmptyBatch"></Empty>
        </div>
      </div>
      <div class="batch-reading__footer">
        <button class="btn btn-primary" @click="showSwitchReportModal" :class="{disabled: !permissionManager.hasPermission(Constant.P_STAT_SEE_EOD)}">
          <svgicon class="icon" src="/icons/switch-arrow.svg" :height="24" :width="24"></svgicon>
          {{ $t('pos.readings.switch_report') }}
        </button>
        <button class="btn btn-primary" @click="validateBatchClosure" v-if="permissionManager.hasPermission(Constant.P_STAT_WRITE_EOD)" :class="{disabled: isEmptyBatch}">
          <svgicon class="icon" src="/icons/close-batch.svg" :height="24" :width="24"></svgicon>
          {{ $t('pos.readings.close_batch') }}
        </button>
      </div>
    </Panel>
  </div>
</template>

<script>
  import BatchModel from "@/models/pos/BatchModel";
  import { Constant } from "@/util/Constant";
  import Empty from "@/components/tools/Empty.vue";
  import EventBus from "@/lib/eventBus";
  import { PermissionManager } from "@/util/PermissionManager";
  import { mapActions } from "vuex";

  export default {
    name: "BatchReading",

    components: { Empty },

    data() {
      return {
        currenBatchQueryData: null,
        currentBatch: null,
        Constant,
        workstations: []
      };
    },
    computed: {
      employee() {
        return this.$store.state.employee;
      },
      permissionManager() {
        return PermissionManager;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      isEmptyBatch() {
        return !this.currentBatch || this.currentBatch.getTotal() === 0;
      }
    },

    async mounted() {
      if (!this.employee) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
        return;
      }

      showSpinner();
      let response;
      if (PermissionManager.hasPermission(Constant.P_STAT_SEE_EOD)) { //If employee has rights, we get the workstation batch
        response = await this.fetchBatchReadingForCurrentWorkstation();
      } else {
        response = await this.fetchBatchReadingForCurrentEmployee();
      }
      if (response && response.success) {
        this.currentBatch = new BatchModel(response.entry);
      }
      hideSpinner();
    },

    methods: {
      ...mapActions(["getLatestBatchReading", "closeBatch", "getWorkstations", "getRequiredBatchActions"]),

      async fetchBatchReadingForCurrentEmployee() {
        let data = {
          employee: parseInt(this.employee.pin)
        };
        this.currenBatchQueryData = data;
        return await this.getLatestBatchReading(data);
      },
      async fetchBatchReadingForCurrentWorkstation() {
        let data = {
          workstation: parseInt(this.posConfiguration.getWorkstationId())
        };
        this.currenBatchQueryData = data;
        return await this.getLatestBatchReading(data);
      },
      async validateBatchClosure() {
        if (!this.currentBatch) {
          return;
        }
        let response = await this.getRequiredBatchActions({ id: this.currentBatch.getId() });

        if (response && response.success) {
          //If we have required actions to take for current batch, show error modal
          let data = response.data;//TODO validate if unpaid invoice is really necessary
          if (data && data.actions && ((data.actions.openTables && data.actions.openTables.length > 0) ||
              (data.actions.unpaidInvoices && data.actions.unpaidInvoices.length > 0))) {
            EventBus.$emit("show-close-batch-error-modal", data.actions);
            return;
          }
          //otherwise show the close batch modal.
          EventBus.$emit("show-close-batch-modal", false);
        } else {
          console.log(response?.status, response?.response);
          toast({ message: this.$t("pos.readings.toast_validate_batch_error"), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
        }
      },
      async closeCurrentBatch() {
        if (!PermissionManager.hasPermission(Constant.P_STAT_WRITE_EOD)) { //this is a failsafe
          toast({ message: this.$t("pos.missing_rights"), type: Constant.TOAST_WARNING, title: this.$t("warning") });
          return;
        }

        let currentBatchId = this.currentBatch?.getId();
        if (!currentBatchId) {
          toast({ message: this.$t("pos.readings.toast_close_batch_error"), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
          return;
        }

        showSpinner();
        let response = await this.closeBatch({ id: currentBatchId });
        if (response && response.success) {
          EventBus.$emit("show-close-batch-modal", true);
          this.generateNewBatchFromCurrentContext();
        } else {
          console.log(response?.status, response?.response);
          toast({ message: this.$t("pos.readings.toast_close_batch_error"), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
        }
        hideSpinner();

      },
      showSwitchReportModal() {
        if (PermissionManager.hasPermission(Constant.P_STAT_WRITE_EOD)) { //Volontary double validation
          EventBus.$emit("show-switch-batch-modal");
        }
      },
      async switchBatchReportTarget(newBatchTarget) {
        if (!newBatchTarget) { //TODO GESTION DU MPD?
          return;
        }
        this.currenBatchQueryData = newBatchTarget;
        showSpinner();
        let response = await this.getLatestBatchReading(newBatchTarget);
        if (response && response.success) {
          this.currentBatch = new BatchModel(response.entry);
        } else {
          console.log(response?.status, response?.response);
          toast({ message: this.$t("pos.readings.generic_close_batch_error"), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
        }
        hideSpinner();
      },
      async generateNewBatchFromCurrentContext() {
        if (!this.currenBatchQueryData) {
          return;
        }
        let response = await this.getLatestBatchReading(this.currenBatchQueryData);
        if (response && response.success) {
          this.currentBatch = new BatchModel(response.entry);
        } else {
          console.log(response?.status, response?.response);
          toast({ message: this.$t("pos.readings.generic_close_batch_error"), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
        }

      }
    }
  };
</script>

<style lang="scss" scoped>
  #batchReading {
    font-weight: 500;

    .batch-reading__body {
      display: flex;
      min-height: 370px;
      padding-top: 20px;
    }

    .batch-reading__about {
      width: 200px;
    }

    .batch-reading__content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      .content__top, .content__bottom {
        display: flex;
      }
    }

    .table {
      padding: 10px 20px 0 20px;
      border-radius: 20px;
      box-shadow: 0px 1px 6px #0000001A;
      background-color: var(--background-color-2);
      margin: 6px 20px 6px 0;
      display: flex;
      flex-direction: column;
      width: 300px;

      .table__column {
        flex-grow: 1;
        padding: 13px 0 13px 0;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color-4);
        }
      }

      .label--bold-large {
        font-size: 20px;
        font-weight: 900;
      }

      .value {
        font-size: 24px;
        font-weight: 900;
      }

      .value--small {
        font-size: 20px;
      }
    }

    .batch-reading__footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-4);
      margin-top: 40px;

      .footer__button-group {
        display: flex;
      }

      .btn {
        font-weight: bold;
        font-size: 20px;
        border-radius: 12px;
        padding: 15px;
        .svgicon {
          margin-right: 16px;
        }
      }
    }

    .group {
      display: flex;
      flex-direction: column;
      text-align: start;
      color: var(--theme-color);
      font-size: 20px;
      padding-bottom: 20px;
    }

    .label {
      color: var(--theme-color-2);
      font-size: 16px;
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #posReading #batchReading .panel {
      width: 1292px;
  }
</style>