// @ts-check
import EventBus from "@/lib/eventBus";
import { PaymentProcessor } from "@/PaymentProcessor";
import {osName} from "mobile-device-detect";
import moment from "moment-timezone";
import {LocalizeStore} from "../lib/localize";
import {AppliedPromotion, PromotionManager} from "../PromotionManager";
import Store from "../store";
import router from "../router";
import Company from "./CompanyModel";
import Item from "./ItemModel";
import Supplement from "./SupplementModel";

/**
 * @typedef OrderDefinition
 * @property {Item[]} items
 * @property {String} method
 * @property {String} hour
 * @property {String} notificationType
 * @property {Supplement[]} supplements
 * @property {String} paymentMethod
 * @property {Number} deliveryFees
 * @property {Number} id
 */

/**
 * @typedef OnlinePayment
 * @property {string} from
 * @property {string} processor
 * @property {string} method
 * @property {number} amount
 * @property {string} currency
 * @property {*} billingDetails
 */

/**
 * @typedef TotalFilters
 * @property {Number[]} [excludedTags]
 */

export default class Order {

  constructor() {
    this.id = null;
    this.items = /** @type {Item[]} */ ([]);
    this.method = /** @type {string} */ (null);
    this.date = moment();
    this.hour = null;
    this.notificationType = "email";
    this.supplements = /** @type {Supplement[]} */ ([]);
    this.company = /** @type {Company} */ (null);
    this.paymentMethod = null;
    this.deliveryFees = 0;
    this.fuid = this.generateUniqueID();
    this.onlinePayments = /** @type {OnlinePayment[]} */ ([]);
    this.tip = {
      amount: 0,
      type: "percent"
    };
    this.accountPayment = 0;
    this.accountId = null;
    this.fidelityPayment = 0;
    this.appliedPromotion = new AppliedPromotion();
    this.comment = "";
    this.tableNumber = "";
    this.customerReferenceNumber = "";
    this.tableNumberFromQrCode = false;
    this.paymentOnly = null;
    this.roomNumber = "";
    this.tpvReceipt = null;
    this.branding = null;
    this.questions = /** @type {{Q: String, A: String}[]} */ ([]);
    this.invoices = [];
    this.shipperEstimatedPickupTime = null;
  }

  reset() {
    this.clear();

    this.notificationType = "email";
    this.fuid = this.generateUniqueID();
    this.company = null;//TODO remove this and check stability, should not be used anymore
    this.paymentMethod = null;
    this.deliveryFees = 0;
    this.id = null;
    this.roomNumber = "";
    this.customerReferenceNumber = "";
    this.onlinePayments = [];
    this.paymentOnly = null;
    this.tpvReceipt = null;
    this.questions = [];
    this.accountPayment = 0;
    this.accountId = null;
    this.fidelityPayment = 0;
    this.tip = {
      amount: 0,
      type: "percent"
    };
    this.appliedPromotion = new AppliedPromotion();
    this.invoices = [];
    this.tableNumberFromQrCode = false;
    this.shipperEstimatedPickupTime = null;

    //Clear order method and hours and add them back if applicable
    this.method = null;
    this.hour = null;
    if (Store.state.currentCompany) {
      this.setFirstOrderMethodIfUnique(Store.state.currentCompany);
      this.setFirstAvailableHourForOrderMethod(Store.state.currentCompany);
    }
    
  }

  clear() {
    this.items = /** @type {Item[]} */ ([]);
    this.supplements = /** @type {Supplement[]} */ ([]);
    this.comment = "";
    this.fuid = this.generateUniqueID();
  }

  generateUniqueID() {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
  }

  /**
   * @returns {string}
   */
  getUniqueId() {
    return this.fuid;
  }

  /**
   * @param {String} method
   */
  async setMethod(method) {
    if (method == this.method) {
      return "unchanged";
    }
    this.method = method;
    if (CONFIG.kiosk || Store.state.ticketMode) {
      return "completed";
    }
    this.paymentOnly = null;
    if (method &&
      router.currentRoute.name !== "dispatch" &&
      Store.state.currentCompany &&
      Store.state.currentCompany.getEnterMessage() &&
      Store.state.currentCompany.getEnterMessageType() == "popup") {
      showOneTimeAffirmation(translate("message"), Store.state.currentCompany.getEnterMessage());
    }
    await Store.dispatch("getStatsPerIntervalForCompany", {
      branchId: Store.state.currentBranch.id,
      company: Store.state.currentCompany,
      method: method
    });
  }

  /**
   * @param {String} paymentMethod
   */
  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setTpvReceipt(data) {
    this.tpvReceipt = data;
  }

  setCustomerReferenceNumber(number) {
    this.customerReferenceNumber = number;
    this.tableNumber = number;
  }

  addAmpPaymentAndReceiptForKiosk(receiptData) {
    this.setPaymentMethod("online");
    this.setTpvReceipt({
      tpvType: "ampUSA",
      receipt: receiptData
    });
  }

  /**
   * @param {String} hour
   */
  setHour(hour) {
    this.hour = hour;
    PromotionManager.calculate();

    this.validateAddedItems();
  }

  validateAddedItems() {
    let removedItemFromUnavailableCategory = this.removeItemsOutsideOfCategorySchedule();
    let removedItemFromUnavailableBranding = this.removeItemsFromUnavailableBrandings();

    if (removedItemFromUnavailableCategory || removedItemFromUnavailableBranding) {
      showAffirmation(window.translate('warning'), window.translate('items_removed'));
      return false;
    }

    let removedItemFromComboMissing = this.removeItemsWithRequiredComboMissing();
    if (removedItemFromComboMissing) {
      showAffirmation(window.translate('oops'), window.translate('items_removed_combo_invalid')
          .replace("{METHOD}", window.translate(this.method))
          .replace("{NAME}", removedItemFromComboMissing));
      return false; //Allow user to add this back again, but should not pop twice since item was removed
    }

    let valid = this.validateItemRequirements();
    if (!valid) {
      return false; //Order is not  valid anymore
    }

    return true; //order is valid
  }

  removeItemsWithRequiredComboMissing() {
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      if (!item.areAllChoicesValid()) {
        this.removeItem(i);
        return window.translate(item.name);
      }
    }
  }

  validateItemRequirements() {
    for (let item of this.items) {
      if (item.getMinimum() && item.getMinimum() > 1) {
        let minimum = item.getMinimum();
        let quantityInCart = this.getItemQuantityAlreadyInCart(item);
        if (quantityInCart < minimum) {
          showAffirmation(window.translate('minimum.required'),
              window.translate('minimum.message')
                  .replace(/\{NAME\}/g, window.translate(item.name))
                  .replace("{CURRENT}", quantityInCart)
                  .replace("{MISSING}", (minimum - quantityInCart))
                  .replace("{REQUIRED}", minimum),
              null, "warning", false, "center"
          );
          return false; //invalid
        }
      }
      if (item.getMaximum()) {
        let maximum = item.getMaximum();
        let quantityInCart = this.getItemQuantityAlreadyInCart(item);
        if (quantityInCart > maximum) {
          showAffirmation(window.translate('maximum.required'),
              window.translate('maximum.message')
                  .replace(/\{NAME\}/g, window.translate(item.name))
                  .replace("{CURRENT}", quantityInCart)
                  .replace("{REMOVE}", (quantityInCart - maximum))
                  .replace("{REQUIRED}", maximum),
              null, "warning", false, "center"
          );
          return false; //invalid
        }
      }
    }
    return true; //valid
  }

  /**
   * @param {String} type
   */
  setNotificationType(type) {
    this.notificationType = type;
  }

  /**
   * @param {Item} item
   */
  addItem(item) {
    let toReturn;
    item = item.clone();
    if (this.branding) {
      item.branding = this.branding.id;
    }
    if (!item.isCustomizableExcludingVariants()) { // Not customizable, can be stacked in cart
      let identicalItem = this.findIdenticalItem(item);
      if (identicalItem) {
        identicalItem.quantity += item.quantity;
        if (identicalItem.getMaximum() && identicalItem.quantity > identicalItem.getMaximum()) {
          identicalItem.quantity = identicalItem.getMaximum();
        }
        identicalItem.appliedPromotion = new AppliedPromotion();

        toReturn = identicalItem;
      } else {
        item.appliedPromotion = new AppliedPromotion();
        item.applyFlowOrderToCurrentSelection();
        this.items.push(item);
        toReturn = item;
      }
    } else { // Customizable, cannot be stacked in cart
      let quantity = item.quantity;
      item.quantity = 1;
      item.applyFlowOrderToCurrentSelection();
      for (let i = 0; i < quantity; i++) {
        item.appliedPromotion = new AppliedPromotion();
        this.items.push(item);
      }
      toReturn = item;
    }

    PromotionManager.calculate();
    EventBus.$emit("added-to-cart");

    return toReturn;
  }

  removeItemsOutsideOfCategorySchedule() {
    if (Store.state.ticketMode) {
      return false;
    }
    let removed = false;
    for (let i = this.items.length - 1; i >= 0; i--) {
      let item = this.items[i];
      if (item.categories.length === 0) {
        continue;
      }
      let itemHasAtLeastOneAvailableCategory = item.categories.find(c => c.isAvailable(this.date, this.hour));
      if (!itemHasAtLeastOneAvailableCategory) {
        this.removeItem(i);
        removed = true;
      }
    }
    return removed;
  }

  removeItemsFromUnavailableBrandings() {
    let inventory = Store.state.inventory;
    let removed = false;
    for (let i = this.items.length - 1; i >= 0; i--) {
      let item = this.items[i];
      if (item.branding) {
        let branding = inventory.getBranding(item.branding);
        if (branding && !branding.isAvailable(this.date, this.hour, this.method)) {
          this.removeItem(i);
          removed = true;
        }
      }
    }
    return removed;
  }

  replaceItemAtIndex(index, item) {
    this.items.splice(index, 1);
    this.items.push(item);
    PromotionManager.calculate();
  }

  findIdenticalItem(item) {
    let similarItem = this.getItemWithId(item.id);
    if (similarItem && similarItem.isIdenticalByValue(item)) {
      return similarItem;
    }
  }

  getItemQuantityAlreadyInCart(item) {
    if (!item) {
      return 0;
    }
    const DEFAULT_VALUE = 0;
    return this.getItemsWithId(item.id).reduce((sum, i) => sum + i.quantity, DEFAULT_VALUE);
  }

  /**
   * @param {Number} index
   */
  removeItem(index) {
    if (index < 0 || index >= this.items.length) {
      return "invalid-index";
    }
    this.items.splice(index, 1);
  }

  removeAllItems() {
    this.items = [];
  }

  /**
   * @returns {boolean}
   */
  hasItems() {
    return this.items.length > 0;
  }

  /**
   * @param id
   * @returns {Item}
   */
  getItemWithId(id) {
     return this.items.find(i => i.id === id);
  }

  /**
   * @param id
   * @returns {Item[]}
   */
  getItemsWithId(id) {
    return this.items.filter(i => i.id === id);
  }

  /**
   * @param {Number} index
   * @returns {Item}
   */
  getItem(index) {
    if (index < 0 || index >= this.items.length) {
      return;
    }
    return this.items[index];
  }

  getItemCount() {
    let total = 0;
    for (let item of this.items) {
      total += item.quantity;
    }
    return total;
  }

  removeTip() {
    this.tip.amount = null;
    this.tip.type = "percent";
  }

  /**
   * @param {Supplement} supplement
   */
  addSupplement(supplement) {
    this.supplements.push(supplement);
  }

  /**
   * @param {string} giftCard
   * @param {number} amount
   * @param {string?} pin
   */
  addGiftCardPayment(giftCard, amount, pin) {
    let info = this.getOrderTotal();
    let amountApplicable = info.totalRemaining - info.totalNotApplicableForLoyaltyOrGiftCard;
    let realAmount = parseFloat(Math.min(parseFloat(String(amount)), amountApplicable).toFixed(2));
    if (realAmount <= 0) {
      return null;
    }
    let from = pin ? giftCard + ":" + pin : giftCard;
    let payment = {
      from: from,
      processor: "prepaid",
      method: "online",
      amount: realAmount,
      currency: "cad"
    };

    this.onlinePayments = this.onlinePayments.filter(p => p.processor !== "prepaid"); // Only one Gift card at the time
    this.onlinePayments.push(payment);

    return payment;
  }

  getGiftCardPayment() {
    return this.onlinePayments.find(p => p.processor === "prepaid");
  }

  addItcPayment(credentials) {
    let info = this.getOrderTotal();
    if (info.totalRemaining == 0) {
      return;
    }
    let payment = {
      from: credentials,
      method: "online",
      processor: "itc",
      amount: info.totalRemaining,
      currency: "usd"
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  addPayfactoPaxPayment(terminalNumber) {
    let info = this.getOrderTotal();
    if (info.totalRemaining == 0) {
      return;
    }
    let payment = {
      from: { terminal: terminalNumber },
      method: "online",
      processor: "payfacto",
      amount: info.totalRemaining,
      currency: "cad"
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   *
   * @param {string} token
   */
  addPaysafePayment(token) {
    let info = this.getOrderTotal();
    if (info.totalRemaining == 0) {
      return;
    }
    let payment = {
      from: String(token),
      processor: "paysafe",
      method: "online",
      amount: info.totalRemaining,
      currency: "cad"
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   *
   * @param {string?} token
   * @param {object?} billingDetails
   * @param {object?} options
   */
  addCybersourcePayment(token, billingDetails, options = {}) {
    let info = this.getOrderTotal();
    if (info.totalRemaining === 0) {
      return;
    }
    let payment = {
      from: token ? String(token) : null,
      processor: "cybersource",
      method: "credit_card",
      amount: info.totalRemaining,
      save: options.save || false,
      billingToken: options.card || undefined,
      billingDetails: billingDetails || undefined,
      currency: "usd"
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   * @param {object} globalData
   * @return {{from: *, processor: string, method: string, amount: number, currency: string}}
   */
  addGlobalPayment(globalData) {
    let info = this.getOrderTotal();
    if (info.totalRemaining === 0) {
      return;
    }
    let payment = {
      from: globalData,
      processor: "global",
      method: "credit_card",
      amount: info.totalRemaining,
      currency: "CAD"
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   * @param {*} nmiData
   * @param {boolean} is3DS
   */
  addNmiPayment(nmiData, is3DS) {
    let info = this.getOrderTotal();
    let company = Store.state.currentCompany;
    let branch = Store.state.currentBranch;
    let currency = company ? company.currency : branch.getCurrencyFromFirstCompany();
    if (info.totalRemaining === 0) {
      return;
    }
    let payment = {
      processor: "nmi",
      method: "credit_card",
      amount: info.totalRemaining,
      currency: currency,
      save: nmiData.save || false,
      billingToken: nmiData.billingToken || undefined,
      from: {
        token: nmiData.token || nmiData.paymentToken,
        email: nmiData.email || undefined,
        phone: nmiData.phone || undefined,
        city: nmiData.city || undefined,
        state: nmiData.state || undefined,
        address1: nmiData.address1 || undefined,
        country: nmiData.country || undefined,
        firstName: nmiData.firstName || undefined,
        lastName: nmiData.lastName || undefined,
        postalCode: nmiData.postalCode || undefined,
        xid: is3DS ? (nmiData.xid || null) : undefined,
        cavv: is3DS ? (nmiData.cavv || null) : undefined,
        eci: is3DS ? (nmiData.eci || null) : undefined,
        cardHolderAuth: is3DS ? (nmiData.cardHolderAuth || null) : undefined,
        threeDsVersion: is3DS ? (nmiData.threeDsVersion || null) : undefined,
        directoryServerId: nmiData.directoryServerId || undefined
      }
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   * @param {Object} options
   */
  addPayfactoPayment(options) {
    let info = this.getOrderTotal();

    if (info.totalRemaining === 0) {
      return;
    }
    let token = options.token || null;
    let from = Store.state.qrPaymentMode ? { token: token, context: "qrPay" } : token;
    let payment = {
      from: from,
      processor: "payfacto",
      method: "credit_card",
      amount: info.totalRemaining,
      currency: "cad",
      save: options.save || false,
      billingToken: options.card || undefined
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  addValitorPayment({reference, currency}) {
    let info = this.getOrderTotal();
    if (info.totalRemaining === 0) {
      return;
    }
    let payment = {
      from: reference || null,
      processor: "valitor",
      method: "credit_card",
      amount: info.totalRemaining,
      currency: currency
    };
    this.onlinePayments.push(payment);
    return payment;
  }

  /**
   * @param {OnlinePayment} payment
   */
  removePayment(payment) {
    let index = this.onlinePayments.indexOf(payment);
    if (index > -1) {
      this.onlinePayments.splice(index, 1);
    }
  }

  /**
   * @param {TotalFilters} [filters]
   * @returns {number}
   */
  getItemsTotal(filters = {}) {
    let itemsTotal = 0;
    for (let item of this.items) {
      if (!filters.excludedTags || item.tags.length == 0 || !item.tags.some(t => filters.excludedTags.indexOf(t.id) > -1)) {
        itemsTotal += item.getTotalPrice(this.method).subTotal;
      }
    }
    return itemsTotal;
  }

  validateMinimumAmount() {
    if (this.paymentOnly) {
      return true;
    }
    let minimumPrice = Store.state.currentCompany.getMinimumOrderPrice(this.method);
    let isValid = this.getOrderTotal().itemsTotal >= minimumPrice;

    if (!isValid) {
      let amount = window.translateCurrency(minimumPrice.toFixed(2));
      let message = window.translate("payment.minimum_text_1") + amount + window.translate("payment.minimum_text_2");
      showAffirmation(window.translate("payment.minimum"), message, window.translate("payment.continue_order"));
      return false;
    }

    return isValid;
  }

  validateOrderRequirements() {
    let company = Store.state.currentCompany;
    if (!company.orderRequirementsActivated || company.orderRequirements.length === 0) {
      return true;
    }
    let requiredItems = this.items.filter(i => company.orderRequirements.indexOf(i.id) > -1);
    let isValid = requiredItems.length > 0;

    if (!isValid) {
      showAffirmation(window.translate("payment.order_requirement_not_met"), window.translate("payment.order_requirement_not_met_desc"));
    }

    return isValid;
  }

  getQRPaymentOrderTotal() {
    let company = Store.state.currentCompany;
    let subTotal = 0;
    let serviceFees = 0;
    let promotionTotal = 0;
    let taxes = [];
    let tipTotal = 0;
    let total = 0;

    // Calculate all invoice totals
    for (let invoice of this.invoices) {
      subTotal += invoice.subTotal;
      serviceFees += (invoice.serviceFees || 0);
      tipTotal += (invoice.tipTotal || 0);
      total += invoice.total;
      promotionTotal += invoice.promotionTotal;

      for (let tax of invoice.taxes) {
        let index = taxes.findIndex(t => t.name == tax.name);
        if (index < 0) {
          taxes.push(JSON.parse(JSON.stringify(tax)));
        } else {
          taxes[index].total += tax.total;
        }
      }
    }


    let info = {
      subTotal: parseFloat((subTotal).toFixed(2)),
      taxes,
      total: parseFloat((total).toFixed(2)),
      promotionTotal: parseFloat((promotionTotal).toFixed(2)),
      serviceFees: parseFloat((serviceFees).toFixed(2)),
      tip: parseFloat((tipTotal).toFixed(2)),
      totalRemaining: 0,
      totalToPay: 0
    };



    // Online payments
    let onlinePaymentTotal = 0;
    for (let payment of this.onlinePayments) {
      onlinePaymentTotal += payment.amount;
    }

    // Manual tip from order
    let tip = 0;
    let shouldApplyTipOnTotal = company ? company.shouldApplyTipOnTotal() : false;
    if (this.tip.type === "amount") {
      tip = parseFloat(String(this.tip.amount || 0));
    } else if (this.tip.type === "percent") {
      let baseAmount = shouldApplyTipOnTotal ? info.total : info.subTotal;
      tip = baseAmount * ((this.tip.amount || 0) / 100);
    }

    info.totalBeforeTip = parseFloat(info.total.toFixed(2));

    tip = parseFloat(tip.toFixed(2));
    info.tip += tip;

    info.tip = parseFloat(info.tip.toFixed(2));
    info.total = parseFloat(info.total.toFixed(2));
    info.totalToPay = info.total + info.serviceFees + info.tip;
    info.totalRemaining = parseFloat((info.totalToPay - onlinePaymentTotal).toFixed(2));

    return info;
  }

  /**
   * Returns all total informations from the order
   */
  getOrderTotal() {
    let branch = Store.state.currentBranch;
    let company = Store.state.currentCompany;
    if (Store.state.qrPaymentMode && this.invoices.length > 0) {
      return this.getQRPaymentOrderTotal();
    }
    if (Store.state.giftCardMode && PaymentProcessor.amount) {
      return { totalRemaining: PaymentProcessor.amount };
    }

    let totalTaxable = 0;
    let totalNonTaxable = 0;
    let totalPromotion = 0;
    let totalNotApplicableForLoyaltyOrGiftCard = 0;
    let itemsTotal = 0;

    // Items
    for (let i = this.items.length - 1; i >= 0; i--) {
      let item = this.items[i];
      /* TODO not cool side effect behavior. Why does a function computing order total decide to remove items from the order?*/
      if (item.getPrice(this.method) == 0 && !item.isHidden() && !company.showItemsWhenPriceIsZero) {
        this.removeItem(i);
      }
      totalTaxable += item.getTotalPrice(this.method).totalTaxable;
      totalNonTaxable += item.getTotalPrice(this.method).totalNonTaxable;
      totalNotApplicableForLoyaltyOrGiftCard += item.getTotalPrice(this.method).totalNotApplicableForLoyaltyOrGiftCard;
      itemsTotal += item.getTotalPrice(this.method).subTotal;
    }

    for (let i = 0; i < this.supplements.length; i++) {
      let supplement = this.supplements[i];
      totalTaxable += supplement.getTotalPrice(this.method).totalTaxable;
      totalNonTaxable += supplement.getTotalPrice(this.method).totalNonTaxable;
      itemsTotal += supplement.getTotalPrice(this.method).subTotal;
    }

    // Service fees
    let serviceFees = company ? company.getServiceFees(this.method) : null;
    if (serviceFees && serviceFees.amount > 0) {
      totalTaxable += serviceFees.amount;
    }

    // Delivery method
    let deliveryFees = (this.method == "delivery" && company) ? company.getDeliveryFees() : null;
    if (deliveryFees && deliveryFees.amount > 0) {
      if (deliveryFees.taxIncluded && company && company.isDoorDashActivated()) {
        totalNonTaxable += deliveryFees.amount;
      } else {
        totalTaxable += deliveryFees.amount;
      }
    }

    // Promotion
    if (this.appliedPromotion.promotion && !this.appliedPromotion.promotion.taxIncluded) {
      totalTaxable -= this.appliedPromotion.value; //TODO this is the bug, we only remove appledPromo value from totalTaxable, not from taxGroups
      totalPromotion += this.appliedPromotion.value;
      if (totalTaxable < 0) {
        totalNonTaxable += totalTaxable;
        totalTaxable = 0;
      }
      if (totalNonTaxable < 0) {
        totalNonTaxable = 0;
      }
    }

    // Apply loyalty money as a discount
    if (branch && branch.shouldApplyLoyaltyPaymentOnSubtotal()) {
      totalTaxable -= this.accountPayment;
    }

    let taxes = {};
    let taxTotal = 0;
    if (company) {
      for (let tax of company.taxes) {
        taxes[tax.uniqueName] = parseFloat((totalTaxable * tax.value).toFixed(2));
        taxTotal += parseFloat((totalTaxable * tax.value).toFixed(2));
      }
    }

    totalTaxable = parseFloat(totalTaxable.toFixed(2));
    totalNonTaxable = parseFloat(totalNonTaxable.toFixed(2));
    taxTotal = parseFloat(taxTotal.toFixed(2));

    let promoAfterTaxTotal = 0;
    if (this.appliedPromotion.promotion && this.appliedPromotion.promotion.taxIncluded) {
      promoAfterTaxTotal = this.appliedPromotion.value;
    }

    let info = {
      itemsTotal: parseFloat(itemsTotal.toFixed(2)),
      subTotal: parseFloat((totalTaxable + totalNonTaxable).toFixed(2)),
      taxes,
      totalTaxable,
      totalNonTaxable,
      total: parseFloat((totalTaxable + totalNonTaxable + taxTotal - promoAfterTaxTotal).toFixed(2)),
      promotionTotal: parseFloat(totalPromotion.toFixed(2)),
      totalNotApplicableForLoyaltyOrGiftCard: parseFloat((totalNotApplicableForLoyaltyOrGiftCard.toFixed(2))),
      deliveryFees: deliveryFees ? deliveryFees.amount : 0,
      serviceFees: serviceFees ? serviceFees.amount : 0,
      tip: 0,
      totalRemaining: 0
    };

    // Tip
    let tip = 0;
    if (this.tip.type == "amount") {
      tip = parseFloat(String(this.tip.amount || 0));
    } else if (this.tip.type == "percent") {
      tip = info.subTotal * ((this.tip.amount || 0) / 100);
    }
    info.tip = parseFloat(tip.toFixed(2));

    // Online payments
    let onlinePaymentTotal = 0;
    for (let payment of this.onlinePayments) {
      onlinePaymentTotal += payment.amount;
    }

    // Will not consider the account payment amount if loyalty payment is applied as a reduction on subTotal
    let accountPayment = (Store.state.currentBranch && Store.state.currentBranch.shouldApplyLoyaltyPaymentOnSubtotal())
      ? 0
      : this.accountPayment;

    info.totalRemaining = parseFloat((info.total + info.tip - onlinePaymentTotal - (this.fidelityPayment || 0) - (accountPayment || 0)).toFixed(2));
    return info;
  }

  async sendInvoicePayment() {
    this.company = Store.state.currentCompany;
    let orderTotal = this.getQRPaymentOrderTotal();
    let fromOrder = this.getSaleData();
    fromOrder.invoices = this.invoices.map(i => i.toDto());
    fromOrder.taxes = orderTotal.taxes;//override tax du saleData parce qu'on prend celles de maitreD et non de la company.
    let data = {
      company: this.company.nameCanonical,
      table: parseInt(this.tableNumber),
      invoices: this.invoices.map(i => parseInt(i.id)),
      payments: fromOrder.payments,
      tipTotal: orderTotal.tip || 0,
      contact: {
        email: Store.state.user.email || ""
      },
      fromOrder: fromOrder
    };
    let response = await store.dispatch("payTableInvoice", data);
    return response;
  }

  getTableNumber() {
    return this.tableNumber;
  }

  resetSupplements() {
    this.supplements = [];
  }

  /**
   * Add flags: { ... } to root sale object to test API behavior :
   *
   *   {skipPOS: true} would not send a sale to the POS
   *   {failPOS: true} trigger a failure (Exception) before send to the POS
   *   {failPayment: true} trigger a failure (PaymentProcessorException) before processing payments
   *   {asTestSale: true} would persist as a TestSale and not persist as a Sale
   *   {isAims: true} trigger AIMS external service
   *
   * @param {Company?} company
   * @returns {{tipTotal: number, appVersion: null, deliveryMethod: string, timezone: *, latitude: null, payments: null, onBehalfOf: (null|*|undefined), startedAt: string, taxes: {total, rate, name: *, type: string}[], serviceFees: *, language: string, loyaltyData: ({processor: (string), card: string}|undefined), subTotal: *, inAdvanceFor: (string|null), total: *, kioskNumber: (*|undefined), contact: {firstName: (string|*|string), lastName: (string|*|string), method: string, phone: (string|*|string), consent: (boolean|*), email: (string|*|string)}, fuid: string, company: null, currency: string, details: *[], deviceUUID: (null|*|undefined), deliveryFees: (number|number), longitude: null, deliveryData: null, completedAt: string, tpvReceipt: (string|undefined), os: string, appName: string, promotions: {total, name: string, id: *}[], userComment: (string|null), paymentOnly: (null|undefined)}}
   */
  getSaleData(company) {
    this.company = Store.state.currentCompany; //TODO remove completely order.company, use store.state.currentCompany instead

    // Compute promotion reduction totals
    let promosOnItems = {};
    for (let item of this.items) {
      if (item.appliedPromotion.promotion) {
        promosOnItems[item.appliedPromotion.promotion.id] = (promosOnItems[item.appliedPromotion.promotion.id] || 0) +
          item.appliedPromotion.value;
      }
    }
    let promosOnItemsFormatted = [];
    for (let key of Object.keys(promosOnItems)) {
      let name = translateObject(PromotionManager.promotions.filter(p => p.id == key)[0].name);
      promosOnItemsFormatted.push({ id: parseInt(key), name, total: -parseFloat(promosOnItems[key]) });
    }

    let companyTimezone = (company || this.company).timezone;
    let lat = Store.state.user.getDefaultAddress().latitude ? Store.state.user.getDefaultAddress().latitude.toString() : null;
    let lng = Store.state.user.getDefaultAddress().longitude ? Store.state.user.getDefaultAddress().longitude.toString() : null;
    let date = moment(this.date).tz(companyTimezone);
    let taxes = (company || this.company).taxes;

    //Define app name
    let appName;
    if (CONFIG.kiosk) {
      appName = "ishopv3-kiosk";
    } else if (typeof cordova !== "undefined") {
      appName = "ishopv3-app";
    } else {
      appName = "ishopv3-web";
    }

    // Create base data object
    let data = {
      appName: appName,
      appVersion: Store.state.version,
      os: osName,
      fuid: this.fuid,
      tpvReceipt: this.tpvReceipt ? JSON.stringify(this.tpvReceipt) : undefined,
      kioskNumber: CONFIG.kiosk ? Store.state.kioskConfiguration.getPaddedTransactionNumber() : undefined,
      paymentOnly: typeof this.paymentOnly === "boolean" ? this.paymentOnly : undefined,
      deviceUUID: typeof cordova !== "undefined" && typeof device !== "undefined" ? device.uuid : undefined,
      startedAt: moment().tz(companyTimezone).format("YYYY-MM-DD HH:mm:ss"),
      completedAt: moment().tz(companyTimezone).format("YYYY-MM-DD HH:mm:ss"),
      inAdvanceFor: (((this.hour && this.hour !== "asap" && this.hour != "delay") || this.method == "catering") && !CONFIG.kiosk)
        ? date.set({
          hours: parseInt(this.hour.split(":")[0]),
          minutes: parseInt(this.hour.split(":")[1]),
          seconds: 0
        }).format("YYYY-MM-DD HH:mm:ss")
        : null,
      timezone: moment.tz.guess(),
      company: (company || this.company).nameCanonical,
      deliveryMethod: this.method,
      deliveryData: null,
      onBehalfOf: Store.state.user.isDispatchUser() && Store.state.user.onBehalfOf ? Store.state.user.onBehalfOf : undefined,
      contact: {
        firstName: Store.state.user.firstName || "",
        lastName: Store.state.user.lastName || "",
        method: this.notificationType,
        consent: Store.state.user.messagingConsent,
        phone: Store.state.user.phoneNumber || "",
        email: Store.state.user.email || ""
      },
      latitude: null,
      longitude: null,
      currency: LocalizeStore.state.currency,
      deliveryFees: this.getOrderTotal().deliveryFees,
      serviceFees: this.getOrderTotal().serviceFees,
      tipTotal: this.getOrderTotal().tip,
      subTotal: this.getOrderTotal().subTotal,
      total: Store.state.qrPaymentMode ? this.getOrderTotal().total : this.getOrderTotal().total + this.getOrderTotal().tip,
      taxes: taxes.map(t => {
        return {
          name: t.uniqueName,
          type: "%",
          rate: t.value * 100,
          total: this.getOrderTotal().taxes[t.uniqueName] || 0
        };
      }),
      promotions: [this.appliedPromotion.promotion].filter(p => !!p).map((p, i) => {
        return {
          id: p.id,
          name: translateObject(p.name),
          total: -this.appliedPromotion.value
        };
      }).filter(p => p.total != 0).concat(promosOnItemsFormatted),
      language: LocalizeStore.state.locale,
      userComment: this.comment.length > 0 ? this.comment : null,
      loyaltyData: this.accountId ? {
        card: this.accountId.toString(),
        processor: Store.state.currentBranch.eWalletActivated ? "ishopfood" : "loyalty"
      } : undefined,
      payments: null,
      details: []
    };

    if (CONFIG.kiosk) {
      data.moreData = {
        kioskName: Store.state.kioskConfiguration.name,
        kioskId: Store.state.kioskConfiguration.number
      };
      data.customerReferenceNumber = this.customerReferenceNumber || undefined;
    }

    switch (this.method) {
      case "delivery":
        data.deliveryData = {
          fullAddress: Store.state.user.getDefaultAddress().getFormattedAddress(),
          app: Store.state.user.getDefaultAddress().app,
          zip: Store.state.user.getDefaultAddress().postalCode,
          city: Store.state.user.getDefaultAddress().city,
          state: Store.state.user.getDefaultAddress().state,
          country: Store.state.user.getDefaultAddress().country,
          lat: lat,
          lng: lng,
          note: Store.state.user.getDefaultAddress().note,
          zone: Store.state.user.getDefaultAddress().zoneName,
          newAddress: false,
          tableNumber: "",
          roomNumber: "",
          shipperEstimatedPickupTime: (company || this.company).isDoorDashActivated() && this.shipperEstimatedPickupTime ? this.shipperEstimatedPickupTime.format("YYYY-MM-DD HH:mm:ss") : undefined
        };
        break;
      case "inPlace":
        data.deliveryData = {
          tableNumber: this.tableNumber
        };
        break;
      case "takeout":
        if (CONFIG.kiosk) {
          data.deliveryData = {
            tableNumber: this.tableNumber
          };
        }
        break;
      case "catering":
        if (this.questions.length > 0) {
          data.deliveryData = {
            questions: this.questions
          };
        }
        break;
      case "hotel":
        data.deliveryData = {
          roomNumber: this.roomNumber
        };
        break;
    }

    if (this.paymentMethod === "room-charge") {
      if (!data.deliveryData) {
        data.deliveryData = {};
      }
      data.deliveryData.roomNumber = this.roomNumber;
    }

    data.payments = Array.from(this.onlinePayments);
    if (this.getOrderTotal().totalRemaining > 0) {
      data.payments.push({
        processor: "none",
        method: this.paymentMethod,
        amount: this.getOrderTotal().totalRemaining,
        currency: "cad"
      });
    }

    // Populate details
    data.details = this.getFlatDetails(company || this.company);

    // Fidelity payment
    this.fidelityPayment = parseFloat(parseFloat(this.fidelityPayment).toFixed(2));
    if (this.fidelityPayment && this.fidelityPayment > 0) {
      data.payments.push({
        from: Store.state.user.loyalty.getId(),
        processor: "loyalty",
        method: "online",
        amount: this.fidelityPayment,
        currency: "cad"
      });
    }

    // Account payment
    this.accountPayment = parseFloat(parseFloat(this.accountPayment).toFixed(2));
    if (this.accountId && Store.state.currentBranch.eWalletActivated && this.accountPayment && this.accountPayment > 0) {
      data.payments.push({
        from: this.accountId.toString(),
        processor: "e_wallet",
        method: "online",
        amount: this.accountPayment,
        currency: "cad"
      });
    }

    return data;
  }

  /**
   * Returns all flat details for items & supplements related to order
   * @param {Company?} company
   * @returns {*[]}
   */
  getFlatDetails(company) {
    let details = [];
    let taxes = (company || this.company).taxes;

    // Populate details
    for (let i = 0; i < this.items.length; i++) {
      let itemDetails = this.getFlatDetailForItem(this.items[i], i, (company || this.company));
      details = [...details, ...itemDetails];
    }

    // Supplements
    for (let i = 0; i < this.supplements.length; i++) {
      let supplement = this.supplements[i];
      details.push({
        line: [this.items.length + i],
        item: supplement.id,
        name: window.translateObject(supplement.name),
        quantity: supplement.quantity,
        unitPrice: supplement.getPrice(this.method),
        note: null,
        appliedTaxes: taxes.map(t => t.uniqueName),
        appliedPromotions: []
      });
      // Supplement combos
      for (let j = 0; j < supplement.getSelectedChoices().length; j++) {
        let choice = supplement.getSelectedChoices()[j];
        details.push({
          line: [this.items.length + i, j],
          item: choice.id,
          name: window.translateObject(choice.name),
          quantity: choice.quantity,
          unitPrice: choice.getPrice(this.method),
          note: null,
          appliedTaxes: taxes.map(t => t.uniqueName),
          appliedPromotions: []
        });

        // Supplement combo modifer group
        for (let k = 0; k < choice.modifierGroups.length; k++) {
          let modifierGroup = choice.modifierGroups[k];
          let modifiers = modifierGroup.modifiers.filter(m => m.quantity > 0);
          for (let l = 0; l < modifiers.length; l++) {
            let modifier = modifiers[l];
            details.push({
              line: [this.items.length + i, j, l],
              item: modifier.id,
              name: window.translateObject(modifier.name),
              quantity: modifier.quantity,
              unitPrice: modifier.getPrice(this.method),
              note: null,
              appliedTaxes: taxes.map(t => t.uniqueName),
              appliedPromotions: []
            });
          }
        }
      }
    }

    return details;
  }

  /**
   * Returns all flat details related to item, with defined order
   *
   * @param {Item} item
   * @param {number} i item index
   * @param {Company?} company
   * @returns {[]}
   */
  getFlatDetailForItem(item, i, company) {
    let itemDetails = [];
    let taxes = (company || this.company).taxes;
    // Computed sale promotions
    let salePromotion = [];
    if (this.appliedPromotion.promotion) {
      salePromotion.push({
        id: this.appliedPromotion.promotion.id,
        excluded: this.appliedPromotion.promotion.excluded.map(i => i.id),
        total: -this.appliedPromotion.value
      });
      // data.promotions = salePromotion.concat(promosOnItemsFormatted);
    }
    let getSalePromotionIdsForItemId = (itemId) => {
      return salePromotion.filter(p => p.excluded.indexOf(itemId) === -1).map(p => p.id);
    };
    // Item
    let itemDetail = {
      line: [i],
      item: item.getId(),
      name: window.translateObject(item.getName()),
      quantity: item.quantity,
      unitPrice: item.getPrice(this.method),
      positionIndex: -1,
      note: null,
      appliedTaxes: taxes.map(t => t.uniqueName),
      appliedPromotions: getSalePromotionIdsForItemId(item.getId()),
      category: item.fromCategoryId
    };
    if (item.appliedPromotion && item.appliedPromotion.promotion) {
      itemDetail.appliedPromotions.push(item.appliedPromotion.promotion.id);
    }
    itemDetails.push(itemDetail);

    // Item choices
    let extraIndex = 0;
    for (let choice of item.getAllChoices()) {
      let positionIndex = choice.positionIndex;

      itemDetails.push({
        line: [i, extraIndex],
        item: choice.selected.getId(),
        name: window.translateObject(choice.selected.getName()),
        quantity: item.quantity,
        unitPrice: choice.selected.getPrice(this.method),
        positionIndex: positionIndex,
        note: null,
        appliedTaxes: taxes.map(t => t.uniqueName),
        appliedPromotions: getSalePromotionIdsForItemId(choice.selected.getId())
      });
      extraIndex++;

      // Choice item modifier groups
      for (let modifierGroup of choice.selected.modifierGroups) {
        for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
          itemDetails.push({
            line: [i, extraIndex],
            item: modifier.id,
            name: window.translateObject(modifier.name),
            quantity: modifier.quantity,
            unitPrice: modifier.getPrice(this.method),
            positionIndex: positionIndex,
            nested: true,
            note: null,
            appliedTaxes: taxes.map(t => t.uniqueName),
            appliedPromotions: getSalePromotionIdsForItemId(modifier.id)
          });
          extraIndex++;
        }
      }

      // Choice modifier groups
      for (let modifierGroup of choice.modifierGroups) {
        for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
          itemDetails.push({
            line: [i, extraIndex],
            item: modifier.id,
            name: window.translateObject(modifier.name),
            quantity: modifier.quantity,
            unitPrice: modifier.getPrice(this.method),
            positionIndex: positionIndex,
            nested: true,
            note: null,
            appliedTaxes: taxes.map(t => t.uniqueName),
            appliedPromotions: getSalePromotionIdsForItemId(modifier.id)
          });
          extraIndex++;
        }
      }

      for (let subchoice of choice.selected.getChoices()) {
        itemDetails.push({
          line: [i, extraIndex],
          item: subchoice.selected.getId(),
          name: window.translateObject(subchoice.selected.getName()),
          quantity: 1,
          unitPrice: subchoice.selected.getPrice(this.method),
          positionIndex: positionIndex,
          nested: true,
          note: null,
          appliedTaxes: taxes.map(t => t.uniqueName),
          appliedPromotions: getSalePromotionIdsForItemId(subchoice.selected.getId())
        });
        extraIndex++;

        //TODO: I don't this that this is working, the loop with the choice.items is working (before this loop)
        for (let modifierGroup of subchoice.selected.modifierGroups) {
          for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
            itemDetails.push({
              line: [i, extraIndex],
              item: modifier.id,
              name: window.translateObject(modifier.name),
              quantity: modifier.quantity,
              unitPrice: modifier.getPrice(this.method),
              positionIndex: positionIndex,
              nested: true,
              note: null,
              appliedTaxes: taxes.map(t => t.uniqueName),
              appliedPromotions: getSalePromotionIdsForItemId(modifier.id)
            });
            extraIndex++;
          }
        }
      }
    }

    // Item modifiers
    for (let modifier of item.getSelectedModifiers()) {
      let positionIndex = modifier.positionIndex;
      itemDetails.push({
        line: [i, extraIndex],
        item: modifier.id,
        name: window.translateObject(modifier.name),
        quantity: modifier.quantity,
        unitPrice: modifier.getPrice(this.method),
        positionIndex: positionIndex,
        note: null,
        appliedTaxes: taxes.map(t => t.uniqueName),
        appliedPromotions: PromotionManager.promotions
          .filter(p => p.excluded.indexOf(modifier.id) === -1)
          .map(p => p.id) //TODO why is this using promotionManager and not salePromotion like the rest
      });
      extraIndex++;

      for (let choice of modifier.getChoices()) {
        if (!choice.selected) {
          continue;
        }
        itemDetails.push({
          line: [i, extraIndex],
          item: choice.selected.id,
          name: window.translateObject(choice.selected.name),
          quantity: choice.selected.quantity,
          unitPrice: choice.selected.getPrice(this.method),
          positionIndex: positionIndex,
          nested: true,
          note: null,
          appliedTaxes: taxes.map(t => t.uniqueName),
          appliedPromotions: PromotionManager.promotions
            .filter(p => p.excluded.indexOf(choice.selected.id) === -1)
            .map(p => p.id) //TODO why is this using promotionManager and not salePromotion like the rest
        });
        extraIndex++;
      }
    }

    let details = [];
    if (item.hasFlowItemsConfigured()) {
      // Sort item details
      let detailsWithoutPositionIndex = itemDetails.filter(d => !Number.isInteger(d.positionIndex));
      let detailsWithPositionIndex = itemDetails.filter(d => Number.isInteger(d.positionIndex));
      detailsWithPositionIndex.sort((a, b) => a.positionIndex - b.positionIndex);
      // Add to rest of details
      details = [...detailsWithPositionIndex, ...detailsWithoutPositionIndex];
    } else {
      details = itemDetails;
    }

    return details;
  }

  /**
   * Check if this company has delivery with doordash and pre-validate user address with doordash for delivery
   * @param {Company} company
   * @returns {Promise<boolean>}
   */
  async isInDeliveryZoneWithDoorDash(company) {
    if (company.isDoorDashActivated() && this.method === "delivery") {
      let response = await this.getDoorDashEstimate(company);

      if (!response || (response.field_errors && response.field_errors.length > 0)) {
        return false;
      } else {
        return true;
      }
    }
    return true; //Anything if not delivery or with doordash will return 'true' to be accepted as a valid company
  }

  /**
   * @param {Company[]} companies
   * @returns {Promise<Company[]>}
   */
  async filterCompaniesForDoorDashDelivery(companies) {
    let toRemove = [];
    for (let company of companies) {
      if (!(await this.isInDeliveryZoneWithDoorDash(company))) {
        toRemove.push(company.id);
      }
    }
    return companies.filter(c => toRemove.indexOf(c.id) === -1);
  }

  async validateForDoorDash() {
    let company = Store.state.currentCompany;
    if (this.method !== "delivery" || !company.isDoorDashActivated()) {
      return {
        valid: true
      };
    }

    let saleData = this.getSaleData();
    let data = {
      deliveryMethod: "delivery",
      deliveryData: saleData.deliveryData,
      company: company.id,
      subTotal: saleData.subTotal,
      tipTotal: saleData.tipTotal,
      contact: {
        phone: saleData.contact.phone,
        email: saleData.contact.email,
        firstName: saleData.contact.firstName,
        lastName: saleData.contact.lastName
      },
      inAdvanceFor: saleData.inAdvanceFor
    };
    let response;
    showSpinner();
    try {
      let url = Store.getters.urlServer + "/api/validate-delivery-data";
      response = await request("POST", url, data);
    } catch (e) {
      response = {
        valid: false
      }
    }
    hideSpinner();
    if (!response.valid) {
      let supportedKeys = ["phone_number", "pickup_address", "dropoff_address", "order_value"];
      let errorMessage = "";
      if (response.response && response.response.message && response.response.message["dropoff_address"] && response.response.message["dropoff_address"].toLowerCase().indexOf("dropoff address") > -1) { // :'( Magic string : means probably an error of delivery zone
        errorMessage += window.translate("error.doordash.outside_of_zone");
      } else {
        for (let key of supportedKeys) {
          if (response.response && response.response.message && response.response.message[key]) {
            errorMessage += window.translate("error.doordash." + key) + "<br>";
          }
        }
      }
      if (!errorMessage) {
        errorMessage = window.translate("error.doordash.generic");
      }
      showAffirmation(window.translate("error.title"), errorMessage);
      return false;
    } else {
      return true;
    }
  }

  /**
   * @param {Company?} companyToCheck
   * @returns {Promise<*>|Null}
   */
  async getDoorDashEstimate(companyToCheck) {
    let company = companyToCheck || Store.state.currentCompany;
    if (this.method !== "delivery" || !company.isDoorDashActivated()) {
      return;
    }

    let saleData = this.getSaleData(company);
    let data = {
      deliveryMethod: "delivery",
      deliveryData: saleData.deliveryData,
      company: company.id,
      subTotal: saleData.subTotal,
      inAdvanceFor: saleData.inAdvanceFor
    };
    try {
      let url = Store.getters.urlServer + "/api/estimate-delivery-data";
      let response = await request("POST", url, data);
      if (response.fee) {
        company.doorDashFees = response.fee / 100;
      }
      if (response.pickup_time) {
        let date = moment(response.pickup_time);
        this.shipperEstimatedPickupTime = date.isValid() ? date : null;
      }
      return response;
    } catch (e) {
      return;
    }
  }

  async sendSale() {
    let data = this.getSaleData();
    try {
      let response = await request("POST", Store.getters.urlServer + "/api/sale", data);
      this.id = response.id;
      return {
        success: true,
        response: response
      };
    } catch (e) {
      console.log("FAIL SEND SALE");
      console.log(e);
      let responseData = e;
      if (typeof responseData !== "object") {
        try {
          let parsed = JSON.parse(responseData);
        } catch (e) {
          // Invalid JSON response is a hard 500, probably HTML body
          console.log("invalid json response", e);
          responseData = {};
        }
        if (responseData === "") {
          responseData = {};
        }
      }
      return {
        success: false,
        status: e.status,
        response: responseData
      };
    }
  }

  async sendFeedback(message, id) {
    let data = {
      message: message,
      sale: id,
      appName: "ishopv3", //TODO why is it hardcoded
      appVersion: Store.state.version
    };
    try {
      let response = await request("POST", Store.getters.urlServer + "/api/me/feedback", data);
      return response;
    } catch (e) {
      console.log("FAIL SEND FEEDBACK");
      return null;
    }
  }

  resetBranding() {
    this.branding = null;
  }

  setBranding(branding) {
    if (Store.state.inventory.allowCrossBrandOrdering || this.items.length === 0) {
      this.branding = branding;
      return "changed-no-validation";
    }
    // An item may be in multiple categories. We want to make sure that all items in cart contain 'at least' this new branding id.
    // If not, this means that this item comes from a different branding.
    let foundItemsWithNoReferenceToNewBrandingId = this.items.filter(i => !i.categories.find(c => c.branding === branding.id));
    if (foundItemsWithNoReferenceToNewBrandingId.length > 0) {
      EventBus.$emit("show-branding-switch-warning-modal", branding);
      return "not-changed";
    }
    //No items found means that all items are referenced in the selected branding, no warning necessary
    this.branding = branding;
    return "changed";
  }

  toDto() {
    return {
      method: this.method,
      brandingId: this.branding?.id,
      company: Store.state.currentCompany ? Store.state.currentCompany.id : null,
      items: this.items.length > 0 ? this.items.map(i => i.toDto()) : []
    };
  }

  reorderFromSale(sale) {
    for (let item of sale.details) {
      let itemToAdd;
      //Find item or variant
      if (item.type == "item") {
        itemToAdd = Store.state.inventory.search(item.item);
      } else if (item.type == "variant") {
        let variant = Store.state.inventory.search(item.item);
        if (variant) {
          itemToAdd = variant.parent;
          itemToAdd.selectedVariant = variant;
        }
      }
      // Skip to next item if item or variant is not found
      if (!itemToAdd) {
        continue;
      }

      // Branding : if  cross brand is not allowed, item must be from current branding, or it will be ignored
      let inventory = Store.state.inventory;
      if (!inventory.allowCrossBrandOrdering && inventory.brandingActivated && this.branding) {
        let itemHasCategoryWithCurrentBranding = itemToAdd.categories.find(c => c.branding === this.branding.id);
        if (!itemHasCategoryWithCurrentBranding) {
          continue;
        }
      }

      // Make sure the item is within schedule from category
      let categoryIsAvailable = itemToAdd.categories.find(c => c.isAvailable(this.date, this.hour));
      if (!categoryIsAvailable) {
        continue;
      }

      // Quantity
      itemToAdd.quantity = item.quantity;
      /*
       * Reconstruct item
       *
       * 1. choices -> get selected
       *    1.1 selected choice : choices -> get selected
       *        1.1.1 selected subchoice : modifier groups -> set modifiers quantity
       *    1.2 selected choice : modifier groups -> set modifiers quantity
       * 2. choice : modifier groups -> set modifiers quantity
       * 3. modifiers: -> set modifier quantity
       * 4. modifier groups -> set modifier quantity
       */

      for (let choice of itemToAdd.getAllChoices()) {
        let foundChoiceItem = choice.items.find(i => item.combos.find(c => c.item === i.id));
        if (foundChoiceItem) {
          choice.selected = foundChoiceItem; //1
          for (let subchoice of choice.selected.getAllChoices()) {
            let foundSubchoiceItem = subchoice.items.find(i => item.combos.find(c => c.item === i.id));
            if (foundSubchoiceItem) {
              subchoice.selected = foundSubchoiceItem; //1.1
              for (let modifierGroup of subchoice.selected.getModifierGroups()) {
                //1.1.1
                for (let modifier of modifierGroup.modifiers) {
                  let foundModifier = item.modifiers.find(m => m.item === modifier.id);
                  if (foundModifier) {
                    modifier.quantity = foundModifier.quantity;
                  }
                }
              }
            }
          }
          //1.2
          for (let modifierGroup of choice.selected.getModifierGroups()) {
            for (let modifier of modifierGroup.modifiers) {
              let foundModifier = item.modifiers.find(m => m.item === modifier.id);
              if (foundModifier) {
                modifier.quantity = foundModifier.quantity;
              }
            }
          }
        }
        //2
        for (let modifierGroup of choice.getModifierGroups()) {
          for (let modifier of modifierGroup.modifiers) {
            let foundModifier = item.modifiers.find(m => m.item === modifier.id);
            if (foundModifier) {
              modifier.quantity = foundModifier.quantity;
            }
          }
        }
      }
      //3
      for (let modifier of itemToAdd.getModifiers()) {
        let foundModifier = item.modifiers.find(m => m.item === modifier.id);
        if (foundModifier) {
          modifier.quantity = foundModifier.quantity;
        }
      }
      //4
      for (let modifierGroup of itemToAdd.getModifierGroups()) {
        for (let modifier of modifierGroup.modifiers) {
          let foundModifier = item.modifiers.find(m => m.item === modifier.id);
          if (foundModifier) {
            modifier.quantity = foundModifier.quantity;
          }
        }
      }

      this.addItem(itemToAdd);
    }
  }

  fromDto(dto) {
    this.method = dto.method;
    for (let item of dto.items) {
      let itemToAdd = Store.state.inventory.search(item.id);
      if (itemToAdd) {
        itemToAdd.fromDto(item);
        this.addItem(itemToAdd);
      }
    }
    if (dto.brandingId) {
      let foundBranding = Store.state.inventory.brandings.find(b => b.id === dto.brandingId);
      if (foundBranding) {
        this.branding = foundBranding;
      }
    }
    // TODO this will never work, branch is not loaded when its called
    this.company = Store.state.currentBranch.companies.find(c => c.id == dto.company); //TODO remove completely order.company, use store.state.currentCompany instead
  }

  setTableNumberFromQrCode() {
    this.tableNumberFromQrCode = true;
  }

  /**
   * @returns {boolean}
   */
  isTableNumberFromQrCode() {
    return this.tableNumberFromQrCode;
  }

  /**
   * @param {Company} company
   */
  setFirstOrderMethodIfUnique(company) {
    if (company && company.getOrderMethodsAvailable().length == 1) {
      this.setMethod(company.getOrderMethodsAvailable()[0]);
    }
  }

  /**
   * @param {Company} company
   */
  setFirstAvailableHourForOrderMethod(company) {
    if (this.method && company && !CONFIG.kiosk) {
      let companyHours = company.getAvailableOpenHours(this.method);
      for (let hour of companyHours) {
        if (company.isIntervalAvailable(hour)) {
          this.setHour(hour);
          break;
        }
      }
    }
  }

}
