/**
 *
 * @param {{message:string, type:string, title:string}} parameters
 */
window.toast = function({message, type, title}) {

  // Create toast
  let div = document.createElement("div");
  div.classList.add("toast");
  div.classList.add(type);

  // Add matching icon
  let left = document.createElement("div");
  left.classList.add("toast-icon");
  left.innerHTML = "<img src='/img/toast/{ICON}.png' height='24px' width='24px'>".replace("{ICON}", type);
  div.appendChild(left);

  //Add the content of the toast
  let contentTitle = document.createElement("div");
  contentTitle.classList.add("title");
  contentTitle.innerHTML = title;

  let contentMessage = document.createElement("div");
  contentMessage.classList.add("message");
  contentMessage.innerHTML = message;

  let content = document.createElement("div");
  content.classList.add("toast-content");
  content.appendChild(contentTitle);
  content.appendChild(contentMessage);


  //div.innerHTML = message;
  // Add the close button
  let right = document.createElement("div");
  right.classList.add("close");
  right.innerHTML = "<div>&times;</div><svg><circle r='15' cx='19' cy='19'></circle></svg>";

  // Add everything to the toast
  div.appendChild(left);
  div.appendChild(content);
  div.appendChild(right);

  // Add it to the container
  let container = document.getElementsByClassName("toasts")[0];
  let id = (parseInt(container.getAttribute("data-toast-id")) || 0) + 1;
  div.setAttribute("data-toast-id", id);
  container.setAttribute("data-toast-id", id);
  container.appendChild(div);

  // Dismiss on click
  div.onclick = () => {
    div.classList.add("close");
    setTimeout(() => {
      div.remove();
    }, 400);
  }

  // Auto dismiss after 10 seconds
  setTimeout(() => {
    let div = document.querySelector(".toasts .toast[data-toast-id='" + id + "']");
    if (!div || div.classList.contains("close")) {
      return;
    }
    div.classList.add("close");
    setTimeout(() => {
      div.remove();
    }, 400);
  }, 7 * 1000);

}