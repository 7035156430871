<template>
  <Panel :title="$t('pos_dashboard.tip_declaration')" icon="icons/tip.svg">
    <div class="panelTip__description">{{ $t("pos_dashboard.tip_declaration_description") }}</div>
    <div class="panelTip__inputs">
      <div class="inputs__group">
        <label for="">{{ $t("date") }}</label>
        <datetime type="datetime" :zone="company ? company.timezone : 'UTC'" v-model="tipDate"></datetime>
      </div>
      <div class="inputs__group">
        <label for="">{{ $t("amount") }}</label>
        <input type="number" v-model="tipAmount">
      </div>
      <button class="btn btn-primary" @click="startSendTip">{{ $t("pos_dashboard.add_tip") }}</button>
    </div>
    <div class="panelTip__separator"></div>
    <div class="table__container">
      <table class="panelTip__table">
        <tr>
          <th>{{ $t("date") }}</th>
          <th>{{ $t("time") }}</th>
          <th class="right">{{ $t("amount") }}</th>
        </tr>
        <tr v-for="(tip, tipIndex) of tips" :key="`tip-${tipIndex}`">
          <td>{{ moment(tip.date).format("YYYY-MM-DD") }}</td>
          <td>{{ moment(tip.date).format("HH:mm A") }}</td>
          <td class="right">{{ $tc(tip.amount) }}</td>
        </tr>
      </table>
    </div>
  </Panel>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        tipDate: moment().toISOString(),
        tipAmount: "",
        tips: [],
        moment
      };
    },
    mounted() {
      this.getAllTips();
    },
    computed: {
      canAddTip() {
        let amount = parseFloat(this.tipAmount);
        return typeof amount === "number" && amount > 0;
      },
      company() {
        return this.$store.state.currentCompany;
      }
    },
    methods: {
      ...mapActions({
        getTips: "getTips",
        sendTipAction: "sendTip"
      }),
      async getAllTips() {
        let result = await this.getTips({ employee: this.$store.state.employee.id });
        if (result.success) {
          this.tips = result.data.data;
        }
      },
      async startSendTip() {
        if (!this.canAddTip) {
          toast({ message: this.$t("pos.readings.invalid_tip_amount"), title: this.$t("warning"), type: "warning" });
          return;
        }
        let employee = this.$store.state.employee;
        if (employee.getPasswordHash()) {
          EventBus.$emit("show-calculator", {
            callback: () => {
              toast({
                title: this.$t("error.title"),
                message: this.$t("login.badPassword"),
                type: "error"
              });
            },
            valueChangedCallback: async (value) => {
              let valid = await employee.validatePasswordHash(value);
              if (valid) {
                EventBus.$emit("close-calculator");
                this.sendTip(value);
              }
            },
            parameters: {
              title: "Password",
              isFloat: false,
              allowNegativeValues: false
            }
          });
        } else {
          this.sendTip();
        }
      },
      async sendTip(password) {
        showSpinner();
        let result = await this.sendTipAction({
          password,
          amount: this.tipAmount,
          currency: this.company.currency,
          date: moment(this.tipDate).format("YYYY-MM-DD HH:mm:ss")
        });
        if (result.success) {
          this.tipDate = moment().toISOString();
          this.tipAmount = "";
          await this.getAllTips();
        }
        hideSpinner();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .panelTip__description {
    color: var(--theme-color-3);
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 36px;
  }

  #iShopFoodApp .panelTip__inputs {
    display: flex;
    align-items: flex-end;

    button {
      height: 56px;
    }

    .inputs__group {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      flex-grow: 1;

      label {
        font-size: 16px;
        color: var(--theme-color-2);
        font-weight: 500;
        margin-bottom: 8px;
      }

      input {
        height: 56px;
        border-radius: 12px;
        border: 2px solid var(--border-color-4);
      }
    }
  }

  .panelTip__separator {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--border-color-4);
    margin: 40px 0;
  }

  .panelTip__table {
    border-collapse: collapse;
    width: 100%;

    tr:nth-child(even) td {
      background-color: var(--background-color);

      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    th {
      text-align: left;
      color: var(--theme-color-2);
      padding: 8px 16px;
    }

    td {
      font-size: 20px;
      font-weight: 500;
      color: var(--theme-color-3);
      padding: 16px;
    }

    .right {
      text-align: right;
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp.pos .panelTip__inputs .vdatetime-input {
    height: 56px;
    border-radius: 12px;
    border: 2px solid var(--border-color-4);
    width: 100%;
  }

  #iShopFoodApp #posReading .posReading__right .panel {
    max-height: 100%;
    .container {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      .table__container {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }
</style>