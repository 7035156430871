<template>
    <div class="order-step-complete-bar" @click="click" :class="{disabled}">
        <svgicon v-if="icon && !$store.getters.isMobile" :src="icon" :height="20" :width="20"></svgicon>
        <svgicon v-if="icon && $store.getters.isMobile" :src="icon" :height="24" :width="24"></svgicon>
        <div class="title">{{ text }}</div>
        <div class="check ion-ios-checkmark-circle"></div>
        <svgicon v-if="!disabled" class="right" src="icons/pencil.svg" :height="24" :width="24"></svgicon>
    </div>
</template>

<script>
    export default {
      name: "orderCompleteStepBar",

      props: {
        text: String,
        icon: String,
        disabled: Boolean
      },

      methods: {
          click() {
              if (!this.disabled) {
                  this.$emit('click');
              }
          }
      }
    }
</script>

<style lang="scss" scoped>
    .order-step-complete-bar {
        background-color: #fc6650;
        border-radius: 10px;
        display: flex;
        font-size: 18px;
        line-height: 1;
        fill: white;
        padding: 20px;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
        &.disabled {
            cursor: auto;
        }
    }
    .svgicon {
        margin-right: 20px;
        &.right {
            margin-right: 0;
            margin-left: 20px;
            flex-grow: 0;
        }
    }
    .title {
        flex-grow: 1;
        color: white;
    }
    .check {
        font-size: 35px;
    }

    #iShopFoodApp.xs {
        .order-step-complete-bar {
            padding: 15px;
            font-size: 16px;
        }
        .check {
            font-size: 24px;
        }
    }
</style>
