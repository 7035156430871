<template>
  <ExtraSelector :extra="modifierGroup.dummyModifier" refused-modifier
                 v-if="forceModifierInteraction && !modifierGroup.minimumRequired()"
                 :parent="modifierGroup" @click="emitClick"></ExtraSelector>
</template>

<script>
  import ExtraSelector from "@/components/tools/ItemExtraSelector";

  export default {
    name: "RefusedModifier",

    components: { ExtraSelector },

    computed: {
      interactiveMode() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.interactiveMode;
      },
      forceModifierInteraction() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.interactiveMode) {
          return false;
        }
        return this.$store.state.currentBranch.style.forceModifierInteraction;
      }
    },

    props: {
      modifierGroup: Object
    },

    methods: {
      emitClick() {
        this.$emit("click");
      }
    }
  }
</script>