<template>
  <div class="card">

    <template v-if="!showBarcode">

      <div class="bigcircle" :style="{
          'background': isDesktop
            ? 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 45%)'
            : 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 75%)'
            }">
      </div>

      <HeaderClip>
        <div class="circleSpacer">
          <div class="balance">
            <div class="amount">{{ Math.floor($user.loyalty.getBalance()) }}</div>
            <div class="cents">{{ leftPad(Math.round($user.loyalty.getBalance() % 1 * 100), 2, "0") }}$</div>
          </div>
          <div class="description">{{ $t("loyalty.my_balance") }}</div>
        </div>
      </HeaderClip>

      <div class="pay" :style="{'color': primaryColor}" @click="showBarcode = true">
        <div class="circle" :style="{'background-color': payColor}">
          <svgicon src="icons/card-phone.svg" :width="60" :height="60"></svgicon>
        </div>
        {{ $t("loyalty.pay_with_account") }}
      </div>

      <hr>

      <div class="buttons">
        <router-link :to="{name: 'reload'}" class="button" :style="{'color': primaryColor}" v-if="isRechargeEnabled && userCanManage">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/reload-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("loyalty.reload") }}
        </router-link>
        <router-link :to="{name: 'menu'}" class="button" :style="{'color': primaryColor}">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/order-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("loyalty.order") }}
        </router-link>
        <router-link :to="{name: 'transactionHistory'}"
                    class="button" :style="{'color': primaryColor}">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/history-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("loyalty.history") }}
        </router-link>
        <router-link :to="{name: 'accounts'}" class="button" v-if="isUserManagementEnabled && userCanManage"
                    :style="{'color': primaryColor}">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/manage-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("loyalty.management") }}
        </router-link>
        <router-link :to="{name: 'loyaltyCards'}" class="button" v-if="enableManualLoyaltyCards"
                     :style="{'color': primaryColor}">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/card-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("loyalty.cards") }}
        </router-link>
        <router-link :to="{name: 'profile'}" class="button"
                     :style="{'color': primaryColor}">
          <div class="circle" :style="{
              'background': 'linear-gradient(to bottom left, white, ' + primaryColor + ' 80%)'
              }">
            <svgicon src="icons/account-circle.svg" :width="64" :height="64"></svgicon>
          </div>
          {{ $t("navigation.profile") }}
        </router-link>
      </div>

    </template>

    <div v-show="showBarcode" class="bigcircle" :style="{
        'background': isDesktop
          ? 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 45%)'
          : 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 75%)'
          }">
    </div>

    <div v-show="showBarcode" class="barcode">
      <HeaderClip>
        <div class="description">
          {{ $t("loyalty.scan_description") }}
        </div>
      </HeaderClip>
      <div class="bottom">
        <canvas id="barcode"></canvas>
        <button class="back" :style="{'background-color': primaryColor}" @click="showBarcode = false">
          <svgicon src="icons/back.svg" :height="25" :width="25"></svgicon>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import HeaderClip from "../HeaderClip.vue";
  import Barcode from "jsbarcode";
  import Axios from 'axios';
  import { mapActions } from 'vuex';

  export default {

    name: 'CardHub',

    components: { HeaderClip },

    props: {
      primaryColor: String,
      payColor: String,
      gradientColor: String,
      isDesktop: Boolean,
      isRechargeEnabled: Boolean,
      userCanManage: Boolean,
      isUserManagementEnabled: Boolean,
      leftPad: Function
    },

    data() {
      return {
        showBarcode: false,
        now: moment(),
        updateInterval: null
      };
    },

    mounted() {
      if (this.$user.getLoyaltyAccount()) {
        setTimeout(() => {
          this.renderBarcode();
        }, 0)
      }
      this.updateInterval = setInterval(this.fetchLoyaltyTransactions.bind(this), 10000);
    },

    beforeDestroy() {
      clearInterval(this.updateInterval);
    },

    watch: {
      "$user.loyalty.accounts": function() {
        this.renderBarcode();
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      enableManualLoyaltyCards() {
        return this.company && this.company.enableManualLoyaltyCards;
      }
    },

    methods: {
      ...mapActions({
        loyaltyBalanceAction: "loyaltyBalance"
      }),
      renderBarcode() {
        Barcode(document.getElementById("barcode"), String(this.$user.getLoyaltyAccount().defaultNumber), {
          width: 2,
          height: 120,
          fontOptions: "bold"
        });
      },
      async fetchLoyaltyTransactions() {
        if (!this.$user.getLoyaltyAccount() || !this.showBarcode) {
          return;
        }
        let response = await Axios.get(this.$store.getters.urlServer + "/api/loyalty_transactions", {
          params: {
            account: this.$user.getLoyaltyAccount().id,
            date: moment().format("YYYY-MM-DD")
          },
          headers: {
            Authorization: "Bearer " + this.$user.token
          }
        });
        let transactions = response.data.data.filter(t => moment(t.createdAt).isAfter(this.now));
        if (transactions.length > 0) {
          let transaction = transactions[0];
          this.now = moment();
          this.loyaltyBalanceAction().then(loyalty => {
            this.$user.loyalty.load(loyalty.profile);
          });
          await showAffirmation(this.$t("success"), this.$t("loyalty.payment_success", {
            amount: (-transaction.money).toFixed(2),
            company: this.$store.state.currentCompany.name
          }));
          await this.$user.update();
          this.showBarcode = false;
        }
      }
    }

  }

</script>

<style lang="scss" scoped>

  #hub.desktop {

    .card {
      border-radius: 20px;
      box-shadow: 0px 2px 5px 0 rgba(2, 3, 2, 0.25);
      display: flex;
      align-items: center;
      flex-grow: initial;
      width: 100%;
      min-height: 370px;
      max-width: 800px;
      overflow: hidden;
      position: relative;

      .bigcircle {
        width: 50vw;
        height: 50vw;
        left: initial;
        bottom: initial;
        right: calc(100% - 350px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
      }
    }

    .pay, hr {
      display: none;
      bottom: 0;
      top: 0;
    }

    .circleSpacer {
      height: 100%;
      width: 350px;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      z-index: 1;
      padding: 30px;
      .balance {
        justify-content: flex-start;
        margin-top: 0;
      }
    }

    .buttons {
      flex-grow: 1;
      //flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      margin-bottom: -20px;
    }
    .button {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
      .circle {
        width: 100px;
        height: 100px;
        margin-bottom: 16px;
        .svgicon {
          width: calc(100px - 6px) !important;
          height: calc(100px - 6px) !important;
        }
      }
    }
  }

  .bigcircle {
    width: 200vw;
    height: 200vw;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    left: 50%;
    bottom: calc(100% - 290px);
    transform: translate(-50%, 0%);
  }

  .circleSpacer {
    height: 230px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    .balance {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      line-height: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 10px;
      margin-top: 20px;
      .amount {
        color: white;
        font-size: 92px;
        font-weight: 900;
      }
      .cents {
        color: white;
        font-size: 50px;
        font-weight: 900;
        margin-top: 4px;
      }
    }
    .description {
      font-size: 12px;
      color: white;
    }
  }

  .pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -65px;
    margin-bottom: -65px;
    position: relative;
    text-decoration: none;

    .circle {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .svgicon {
        fill: white;
      }
    }
  }

  #iShopFoodApp hr {
    margin: 30px 0;
    border-top: 2px dashed #e6e6e6;
  }

  .buttons {
    display: flex;

    flex-wrap: wrap;

    .button {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 49%;
      padding-bottom: 30px;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .svgicon {
        fill: white;
      }
      .circle {
        width: 70px;
        height: 70px;
        border: 3px solid #e6e6e6;
        border-radius: 50%;
        margin-bottom: 8px;
        // background: linear-gradient(to bottom left, #ffd5a8, #ff8400 75%);
        box-shadow: inset 0 0 0px 2px white;
      }
    }
  }

  .barcode {
    z-index: 1;
    position: relative;
    .back {
      fill: white;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .description {
      height: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      text-align: center;
      padding-bottom: 40px;
    }
    .bottom {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      canvas {
        margin-bottom: 20px;
      }
    }
  }

</style>
