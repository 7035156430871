class Configuration {

	/**
	 * Gets an item from the given storage or localStorage if no storage is given.
	 * @param {string} key
	 * @param {Storage} storage
	 * @returns {any}
	 */
	get(key, storage = localStorage) {
		storage = storage || localStorage;
		let config = JSON.parse(storage.getItem("ishopfood-frontend-v3") || "{}");
		return config[key];
	}

	/**
	 * Sets a value in the given storage or localStorage if no storage is given.
	 * @param {string} key
	 * @param {any} value
	 * @param {Storage} storage
	 * @returns {any}
	 */
	set(key, value, storage = localStorage) {
		storage = storage || localStorage;
		let config = JSON.parse(storage.getItem("ishopfood-frontend-v3") || "{}");
		config[key] = value;
		storage.setItem("ishopfood-frontend-v3", JSON.stringify(config));
	}

}

let configuration = new Configuration();
export { configuration as Configuration, Configuration as ConfigurationClass };