<template>
  <div id="modalUpdateDownload" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header justify-content-center"></div>
        <div class="modal-body">
          <span class="current"></span>Mb {{$t('of')}} <span class="total"></span>Mb {{$t('downloaded')}}...
          <div class="progressBar">
            <div class="inner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "kioskUpdateModal"
  }
</script>

<style lang="scss" scoped>
#modalUpdateDownload .progressBar {
  width: 100% !important;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #888;
  margin-top: 15px;
}
#modalUpdateDownload .progressBar .inner {
  height: 100% !important;
  background-color: var(--primary-color);
}
/*#modalUpdateDownload {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  & * {
    pointer-events: none;
  }
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
  color: #404040 !important;
  &.show {
    opacity: 1;
    pointer-events: all;
    & * {
      pointer-events: all;
    }
    .modal-content {
      top: 0px;
    }
  }
  .modal-no {
    margin-right: 10px;
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 450px;
    position: relative;
    top: -40px;
    transition: top 0.3s ease;
    font-size: 18px;

    .modal-header {
      display: flex;
      align-items: center;
      font-size: 28px;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .modal-footer {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: -10px;
      .btn {
        margin-bottom: 10px;
        flex-shrink: initial;
        padding: 8px 16px;
      }
    }
  }
}*/
</style>
