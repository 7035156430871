<template>
  <div class="list-item" :class="{outOfStock: item.outOfStock, discreteProductPrice, quickAdd}" @click="$emit('selected')">
    <div class="price" v-if="item.priceSchemes && !discreteProductPrice">
      <span class="subtitle">{{$t('menu.from')}}</span>
      <Price :value="item.getPrice($order.method)"></Price>
    </div>
    <AsyncImage class="image" v-if="discreteProductPrice"
                :fallback="$store.state.defaultItemImage" :image="$store.state.urlFileServer + item.image"></AsyncImage>
    <div class="info">
      <div class="name">
        {{$to(item.name)}}
      </div>
      <template v-if="item.items && item.items.length > 0">
        <div class="description">
          {{item.items.length}} {{$t('menu.products')}} | {{$t('menu.from')}} {{$tc(item.getLowestPrice($order.method).toFixed(2))}}
        </div>
      </template>
      <template v-if="!item.items || item.items.length === 0">
        <v-clamp class="description" :max-lines="2" v-if="!item.outOfStock">
          {{$to(item.description)}}
        </v-clamp>
        <Price v-if="discreteProductPrice" :value="item.getPrice($order.method)"></Price>
        <div class="out-of-stock" v-if="item.outOfStock">{{ $t("menu.outOfStock") }}</div>
        <div class="quickAddPlus" v-if="quickAdd">
          <svgicon src="icons/add.svg" :height="15" :width="15"></svgicon>
        </div>
        <div class="quantity-in-cart" :ref="'menu-item-' + item.id" v-if="branch.quickAdd && quantityInCart > 0">
          {{quantityInCart}}
        </div>
      </template>
    </div>
    <AsyncImage class="image" v-if="!discreteProductPrice"
                :fallback="$store.state.defaultItemImage" :image="$store.state.urlFileServer + item.image"></AsyncImage>
  </div>
</template>

<script>
  import Price from "@/components/tools/Price.vue";
  import AsyncImage from "@/components/tools/AsyncImage.vue";

  export default {

    name: "selectableListItem",

    components: { Price, AsyncImage },

    props: {
      item: Object
    },

    watch: {
      quantityInCart(newValue, oldValue) {
        if (!this.item) {
          return;
        }
        let element = this.$refs["menu-item-" + this.item.id];
        if (newValue !== oldValue && element) {
          element.classList.add("poof");
          setTimeout(() => {
            element.classList.remove("poof");
          }, 500);
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      quantityInCart() {
        if (!this.$order) {
          return 0;
        }
        return this.$order.getItemQuantityAlreadyInCart(this.item);
      },
      discreteProductPrice() {
        if (!this.branch || !this.branch.style) {
          return false;
        }
        return this.branch.style.discreteProductPrice;
      },
      quickAdd() {
        return this.branch && this.branch.quickAdd;
      }
    }

  }

</script>

<style lang="scss" scoped>
  $radius: 10px;
  .list-item {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: $radius;
    cursor: pointer;
    background-color: white;
    display: flex;
    width: 100%;
    margin: 0 5px 20px 5px;
    &.outOfStock {
      pointer-events: none;
      user-select: none;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: rgba(255,255,255,0.55);
      }
    }
  }

  .quantity-in-cart {
    position: absolute;
    background-color: #f2f2f2;
    top: 7px;
    right: 45px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    width: 30px;
  }

  .discreteProductPrice {
    .image {
      border-radius: $radius 0 0 $radius;
    }
  }

  .quickAddPlus {
    position: absolute;
    padding: 6px;
    opacity: 1;
    border-radius: 100%;
    top: 8px;
    right: 8px;
    fill: white;
    display: flex;
    background-color: var(--primary-color);
  }

  .info {
    padding: 20px;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .name {
    font-size: 20px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .description {
    color: #808080;
  }
  .price {
    background-color: #ed2937;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    min-width: 120px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 18px;

    .subtitle {
      text-transform: uppercase;
      font-size: 10px;
    }
  }
  .image {
    background-size: cover;
    background-position: center;
    width: 160px;
    flex-shrink: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  #iShopFoodApp.xs {
    .list-item {
      margin: 0 0 15px 0;
    }
    .info {
      padding: 10px;

      .name {
        font-size: 16px;
      }
      .description {
        font-size: 12px;
      }
    }
    .price {
      padding: 12px;
      min-width: 115px;
      align-items: center;
    }
    .image {
      display: none;
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp #menu {
    .list-item.discreteProductPrice .formatted-price {
      span {
        font-family: inherit;
        font-weight: 600 !important;
        color: var(--primary-color);
        font-size: 14px !important;
        margin: 0;
        padding: 0;
      }
    }
    .list-item .formatted-price {
      font-family: 'Segoe UI', sans-serif;
      font-weight: 900;

      .number {
        font-size: 40px;
      }
      .decimal {
        padding-top: 4px;
        font-size: 20px;
      }
      .number, .decimal {
        color: white;
      }
      .dollar {
        color: #fc6650;
        padding-top: 4px;
        font-size: 20px;
      }
    }
  }
</style>
