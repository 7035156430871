//@ts-check
import ReceiptConfiguration from "@/models/pos/ReceiptConfigurationModel";
import RevenueCenter from "@/models/pos/RevenueCenterModel";
import moment from "moment-timezone";
import { ActionContext } from "vuex";
import Branch from "../../models/BranchModel";
import Company from "../../models/CompanyModel";
import Employee from "../../models/pos/EmployeeModel";
import { LocalizeStore } from "../../lib/localize";
import Vue from "vue";
import { WorkstationDataUpdater } from "@/util/WorkstationDataUpdater";
import router from "../../router";
import { PermissionManager } from "@/util/PermissionManager";
import { MediaLibrary } from "@/util/MediaLibrary";

let loadingBranch = false;
let shouldShowSpinner = () => {
  let routesWithoutSpinner = ["posLoading", "posOnboarding", ""];
  return routesWithoutSpinner.indexOf(router.currentRoute.name) === -1;
};

export default {

  /**
   * Returns a list of all branches
   * @param {ActionContext} context
   */
  async getBranch(context, {
    id,
    company,
    force,
    executeInBackground,
    KeepCurrent
  }) {
    return new Promise(async (resolve) => {
      if (!force && context.state.currentBranch && id == context.state.currentBranch.nameCanonical) {
        resolve();
      }
      if (!KeepCurrent) {
        context.state.currentBranch = null;
        context.state.currentCompany = null;
        context.state.user.reset();
      }

      if (shouldShowSpinner() && !executeInBackground) {
        showSpinner();
      }
      let branch;
      try {
        branch = await context.dispatch("request", {
          method: "GET",
          url: "/api/branches/" + id
        });
      } catch (e) {
        console.error(translate("branch.invalid"), translate("branch.invalid_text"));
      }
      hideSpinner();
      if (!branch) {
        resolve();
        return;
      }
      let newBranch = new Branch(branch);
      context.state.currentBranch = newBranch;
      LocalizeStore.commit("setCustomTranslations", newBranch.customLocales || {});

      let pixel = newBranch.getFacebookPixelAnalytics(); //TODO remove dev account
      if (pixel) {
        Vue.analytics.fbq.init(pixel.id);
      }

      let companies = await context.dispatch("getCompanies", {
        branchId: branch.id
      });
      newBranch.shutdownSchedules = await context.dispatch("getShutdownSchedules", {
        branchId: branch.id
      });

      newBranch.companies = [];
      for (let companyDefinition of companies) {
        let company = new Company();
        company.load(companyDefinition);
        newBranch.companies.push(company);
      }

      newBranch.setDistanceFromUserForCompanies();
      newBranch.selectOnlyAvailableCompanyWithMenuDisplayIfApplicable();
      newBranch.setDefaultLocale();

      if (CONFIG.pos) {
        await MediaLibrary.fetch(newBranch.id);
      }

      setTimeout(() => {
        context.state.initialLoading = true;
      }, 0);

      if (company || newBranch.shouldLaunchAppWithHub()) {
        let companyToLoad = newBranch.companies.find(c => c.nameCanonical.toLowerCase() == company.toLowerCase());
        if (!companyToLoad) {
          if (CONFIG.kiosk) {
            newBranch.companies = [companyToLoad];
            resolve(); //Company is required for kiosk mode
            return;
          }
          //TODO improvement required on this redirect
          if (newBranch.shouldLaunchAppWithHub()) {
            //context.dispatch("setCurrentCompany", newBranch.companies[0]);
            context.state.router.push(context.state.router.generateFirstPage());
          } else {
            context.state.router.push(context.state.router.generate("/orderCompany"));
          }
        } else {
          context.dispatch("setAndLoadCurrentCompany", {company: companyToLoad, executeInBackground: executeInBackground});
        }
      }
      if (newBranch.getOrderMethodsAvailable().length == 1) {
        context.state.order.setMethod(newBranch.getOrderMethodsAvailable()[0]);
      }
      loadingBranch = false;
      resolve(newBranch);
    });
  },

  /**
   * @param {ActionContext} context
   * @param {{branchId: number, id: number, company: Company}} param
   */
  async updateCompany(context, {
    branchId,
    id,
    company
  }) {
    let data = await context.dispatch("request", {
      method: "GET",
      url: "/api/branches/" + branchId + "/companies/" + id
    });
    company.load(data);
    return company;
  },

  /**
   * @param {ActionContext} context
   * @param {{branchId: number, id: number}} param
   */
  async getCompanyDefinition(context, {
    branchId,
    id
  }) {
    return await context.dispatch("request", {
      method: "GET",
      url: "/api/branches/" + branchId + "/companies/" + id
    });
  },

  /**
   * @param {ActionContext} context
   * @param {{service: String, type: String, companyId: Number, removeFromBranch: Boolean, increment: Number}}
   * @returns {Promise<*>}
   */
  async logGeocoding(context, {
    service,
    type,
    company,
    removeFromBranch,
    increment
  }) {
    let branch = context.state.currentBranch;
    let data = {
      branch: !company ? branch.id : undefined,
      company: company || undefined,
      increment: increment || 1,
      service: service,
      type: type,
      removeFromBranch: removeFromBranch ? true : undefined
    };
    let response = await context.dispatch("request", {
      method: "POST",
      url: "/api/log_geoloc",
      data: data
    });
    if (response) {
      return data;
    }

  },

  async validateApiKey(context, {
    apiKey,
    branchId
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: `/api/api_key_check/${apiKey}?branch=${branchId}`
      });
      if (response && response.valid) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  },

  /**
   * @param {ActionContext} context
   * @param {{branchId: number, company: Company}} param
   */
  async getStatsPerIntervalForCompany(context, {
    branchId,
    company,
    method
  }) {
    if (!company) {
      return;
    }
    let inAdvance = company.getInAdvanceConfiguration(method);
    if (!inAdvance.activated) {
      return;
    }
    let openDatetime = company.getOpenDatetimeForMethod(method);
    try {
      let stats = await context.dispatch("request", {
        method: "GET",
        url: "/api/branches/" + branchId + "/companies/" + company.id + "/stats-per-interval",
        data: {
          method: method,
          interval: inAdvance.intervalInMinutes,
          "fixed-hours": 1, //Allows to return hours in the correct time zone instead of UTC
          "interval-offset": openDatetime ? moment(openDatetime.from, "YYYY-MM-DD HH:mm:ss").minutes() % inAdvance.intervalInMinutes : undefined,
          from: openDatetime ? openDatetime.from : undefined,
          to: openDatetime ? openDatetime.to : undefined
        }
      });
      company.setIntervalStats(stats);
    } catch (e) {
      console.log("stats-per-interval error", e);
    }
  },

  /**
   * @param {ActionContext} context
   * @param {{branchId: number}} param
   */
  async getCompanies(context, { branchId }) {
    let data = await context.dispatch("request", {
      method: "GET",
      url: "/api/branches/" + branchId + "/companies"
    });
    return data;
  },

  /**
   * @param {ActionContext} context
   * @param {{branchId: number}} param
   * @returns {Promise}
   */
  async getShutdownSchedules(context, { branchId }) {
    try {
      let data = await context.dispatch("request", {
        method: "GET",
        url: `/api/branch_shutdown_schedules?branch=${branchId}&start=${moment().format("YYYY-MM-DD_HH-mm-ss")}`
      });
      return data.data;
    } catch (e) {
      return [];
    }
  },

  /**
   * @param {ActionContext} context
   * @param {Company} company
   */
  async setCurrentCompany(context, company) {
    await context.dispatch("setAndLoadCurrentCompany", {company: company});
  },

  /**
   * @param {ActionContext} context
   * @param {{company: Company, executeInBackground: boolean}} param
   */
  async setAndLoadCurrentCompany(context, {company, executeInBackground}) {
    context.state.currentCompany = company;
    if (!company) {
      return;
    }
    if (company.locale && !CONFIG.kiosk && !CONFIG.pos && !router.currentRoute.query.locale) {
      LocalizeStore.commit("setLocale", company.locale);
      setConfiguration("locale", company.locale);
    }

    // Check if pay at the table is activated
    let qrPayment = company.getQRPayment();
    if (context.state.qrPaymentMode && qrPayment && !qrPayment.activated) {
      //TODO redirect user if the company doesnt have qr pay activated, are kicking him out, maybe in the router?
      context.state.qrPaymentMode = false;
    }

    let isQrPayment = company.hasQRPaymentEnabled() && context.state.qrPaymentMode;
    if (isQrPayment && company.currency) {
      LocalizeStore.commit("setCurrency", company.currency);
    } else {
      company.transferGeocodingLogs(context.state.currentBranch.id);
      if (company.getOrderMethodsAvailable().length == 1) {
        context.state.order.setMethod(company.getOrderMethodsAvailable()[0]);
      }
      if (context.state.order.method) {
        let companyHours = company.getAvailableOpenHours(context.state.order.method);
        for (let hour of companyHours) {
          if (company.isIntervalAvailable(hour)) {
            context.state.order.setHour(hour);
            break;
          }
        }
      }
      context.state.order.setFirstOrderMethodIfUnique(company);
      context.state.order.setFirstAvailableHourForOrderMethod(company);
      if (company._showSpinner && shouldShowSpinner() && !executeInBackground) {
        showSpinner();
      }
      if (!context.state.currentBranch.areTicketsActivated() || (context.state.kioskConfiguration && !context.state.kioskConfiguration.isTicket())) {
        await context.state.inventory.fetch(company.nameCanonical, { executeInBackground: !shouldShowSpinner() });
      } else {
        await context.state.inventory.fetchTickets(company.nameCanonical);
      }
      if (CONFIG.pos) {
        let [employeesData,
          posRolesData,
          printersData,
          receiptConfigurationData,
          printingGroupsData,
          revenueCentersData,
          functionsData
        ] = await Promise.all([
          context.dispatch("getEmployees", { company: company.nameCanonical }),
          context.dispatch("getPosRoles", { company: company.nameCanonical }),
          context.dispatch("getPosDataStorage", { company: company.id, storageKey: "global_printer" }),
          context.dispatch("getPosDataStorage", { company: company.id, storageKey: "receipt_configuration" }),
          context.dispatch("getPrintingGroups", { company: company.id }),
          context.dispatch("getRevenueCenters", { company: company.id }),
          context.dispatch("getPosFunctions")
        ]);
        if (employeesData.success) {
          context.state.employees = employeesData.employees.map(e => new Employee(e));
        }
        if (posRolesData.success) {
          PermissionManager.setRolesPermissions(posRolesData.data);
        }
        if (printersData.success) {
          context.state.posConfiguration.setPrinters(printersData.data ? (printersData.data.printers || []) : []);
          context.state.posConfiguration.registerPrinters();
        }
        if (receiptConfigurationData.success) {
          context.state.posConfiguration.receiptConfiguration = new ReceiptConfiguration(receiptConfigurationData.data);
        }
        if (printingGroupsData.success) {
          context.state.posConfiguration.setPrintingGroups(printingGroupsData.data || []);
          context.state.posConfiguration.linkPrintingGroupsToInventory(context.state.inventory);
        }
        if (revenueCentersData.success) {
          context.state.inventory.setRevenueCenters(revenueCentersData.data.map(r => new RevenueCenter(r)));
        }
        if (functionsData.success) {
          context.state.posConfiguration.setFunctions(functionsData.data || []);
        }
        await WorkstationDataUpdater.init();
      }
      if (!context.state.quickMode && router.currentRoute.name != "dispatch") {
        company.displayRecommenderModal();
      }
    }
    hideSpinner();
  },

  async tpvCloseBatch(context, {
    company,
    processor,
    terminal,
    batchNumber
  }) {
    let response = await context.dispatch("request", {
      method: "POST",
      url: "/api/tpv_terminal/close_batch",
      data: {
        company: company,
        processor: processor,
        terminal: terminal,
        batchNumber: batchNumber
      }
    });
    return response;
  },

  async getBranchMedia(context, { imageId }) {
    let media;
    try {
      media = await context.dispatch("request", {
        method: "GET",
        url: "/api/branch_images/" + imageId
      });
      return media;
    } catch (e) {
      return null;
    }
  },

  async getBranchMediasAll(context, { branchId }) {
    let medias;
    try {
      medias = await context.dispatch("request", {
        method: "GET",
        url: "/api/branch_images?branch=" + branchId + "&perPage=-1"
      });
      return medias;
    } catch (e) {
      return null;
    }
  }

};
