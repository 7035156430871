<template>
    <div class="tipbox" :class="{danger: danger, success: success, warning: warning, info: info, icon: !title, iconTop: iconTop, noBorder: noBorder}">
        <div class="tipbox-title" v-if="title">
            <svgicon v-if="iconPath" :src="iconPath" :height="20" :width="20"></svgicon>
            <div class="text">{{title}}</div>
        </div>
        <div class="tipbox-icon" v-if="!title && !noIcon">
            <svgicon v-if="iconPath" :src="iconPath" :height="18" :width="18"></svgicon>
        </div>
        <div class="tipbox-content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
      name: "tipbox",

      props: {
        danger: Boolean,
        success: Boolean,
        warning: Boolean,
        info: Boolean,
        iconTop: Boolean,
        noBorder: Boolean,
        noIcon: Boolean,
        title: String
      },

      computed: {
        iconPath() {
          if (this.danger) {
            return "icons/tip-danger.svg";
          }
          if (this.success) {
            return "icons/tip-check.svg";
          }
          if (this.warning) {
            return "icons/tip-question.svg";
          }
          if (this.info) {
            return "icons/tip-info.svg";
          }
        }
      }
    }
</script>

<style lang="scss">
    $danger-color: #ed2937;
    $danger-border: #f4e0e2;

    $success-color: #36d53d;
    $success-border: #cfefd0;

    $warning-color: #feb055;
    $warning-border: #f7e7d5;

    $info-color: #0091cb;
    $info-border: #dcebf1;

    #iShopFoodApp .tipbox {
        padding-bottom: 20px;

        &:not(.noBorder) {
            border-bottom: 1px dashed #dbdbdb;
            margin-bottom: 30px;
        }

        &.icon {
            display: flex;
            align-items: center;

            .tipbox-content {
                flex-grow: 1;
            }
        }
        &.icon.iconTop {
            align-items: flex-start;
            .tipbox-icon {
                margin-top: 5px;
            }
        }
        &.info {
            .tipbox-title, .tipbox-icon, a {
                color: $info-color !important;
                fill: $info-color !important;
                border-color: $info-border;
            }
            li::before {
                color: $info-color !important;
            }
        }
        &.success {
            .tipbox-title, .tipbox-icon, a {
                color: $success-color !important;
                fill: $success-color !important;
                border-color: $success-border;
            }
            li::before {
                color: $success-color !important;
            }
        }
        &.warning {
            .tipbox-title, .tipbox-icon, a {
                color: $warning-color !important;
                fill: $warning-color !important;
                border-color: $warning-border;
            }
            li::before {
                color: $warning-color !important;
            }
        }
        &.danger {
            .tipbox-title, .tipbox-icon, a {
                color: $danger-color !important;
                fill: $danger-color !important;
                border-color: $danger-border;
            }
            li::before {
                color: $danger-color !important;
            }
        }

        .tipbox-title {
            font-weight: bold;
            display: flex;
            font-size: 18px;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid;

            .svgicon {
                margin-right: 10px;
            }
        }

        .tipbox-icon {
            $size: 36px;
            width: $size;
            height: $size;
            border: 1px solid;
            border-radius: 100%;
            flex-shrink: 0;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        ul {
            list-style: none;
            padding: 0;
            $dot-position: 25px;

            & > li {
                position: relative;
                margin-left: $dot-position;

                &:not(:last-child) {
                    margin-bottom: 6px;
                }

                &::before {
                    content: "\2022";
                    font-size: 36px;
                    position: absolute;
                    left: -$dot-position;
                    top: -12px;
                }
            }
        }

        ol {
            list-style: none;
            padding-left: 0;
            counter-reset: custom-counter;
            & > li {
                counter-increment: custom-counter;
                &:not(:last-child) {
                    margin-bottom: 6px;
                }

                &::before {
                    content: counter(custom-counter) ".";
                    margin-right: 8px;
                    font-weight: bold;
                }
            }
        }

        span:not(:last-child) {
            margin-bottom: 6px;
            display:inline-block;
        }

        a {
            display: flex;
            align-items: center;
            font-weight: 500;

            &.inline {
                display: inline-block;
            }

            .svgicon {
                transform: rotate(270deg);
                margin-left: 2px;
            }
        }

        li > ol, li > ul {
            padding-left: 20px;
        }

        h1, h2, h3, h4, p {
            margin-top: 0;
        }
        ul, ol, p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .tipbox {
            &.icon {
                .tipbox-icon {
                    margin-right: 15px;
                }
            }
            .tipbox-title {
                font-size: 17px;
            }
            .tipbox-content {
                font-size: 15px;
            }
        }
    }

</style>
