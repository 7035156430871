//@ts-check
"use strict";

export default class Flex {

    /**
     * @param {string} ip IP address of the flex terminal
     * @param {number} port Port on which the flex terminal is listening
     */
    constructor(ip, port = 2200) {

        this.ip = ip;
        this.port = port;
        this.connected = false;

        this._CONTROL_CHARS = {
            FS: String.fromCharCode(28),
            GS: String.fromCharCode(29),
            RS: String.fromCharCode(30)
        };
        this._SERVICE_ID = {
            CHARGE: 1,
            CHARGE_WITHDRAW: 2,
            CHARGE_TIP: 3,
            CHARGE_TEL: 4,
            REFUND: 5,
            CANCEL: 6,
            BALANCE: 7,
            PRE_AUTH: 20,
            PRE_AUTH_SEND: 21,
            BATCH_TOTALS: 30,
            CLOSE_BATCH: 31,
            INIT_TERMINAL: 32,
            PRINT_LAST: 33,
            TRANSACTION_LIST: 34,
            LOY_ACTIVATE: 40,
            LOY_INFO: 41,
            LOY_RELOAD: 43,
            LOY_TO_CASH: 44,
            LOY_ACCUM: 50,
            LOY_REVERSE: 51,
            STATUS: 99
        };
        this._RESPONSE_CODE = {
            SUCCESS: "000",
            REFUSED: "050",
            REFUSED_2: "078",
            EXPIRED: "L31",
            BATCH_EMPTY: "L28",
            BUSY: "L97",
            PROCESSING: "P01",
            ADD_DATA: "P02",
            CONTINUE_DATA: "P03"
        };

        this._events = /** @type {Map<number, {resolve:Function, reject:Function}>} */ (new Map());
        this._currentResponse = /** @type {FlexResponse} */ (null);

        let Net = eval('require("net")');
        this._socket = new Net.Socket();
        this._socket.on("connect", this._onSocketConnect.bind(this));
        this._socket.on("data", this._onSocketData.bind(this));
        this._socket.on("close", this._onSocketClose.bind(this));
        this._socket.on("error", this._onSocketError.bind(this));

    }

    connect() {
        return new Promise((resolve, reject) => {
            this._socket.on("error", reject);
            this._socket.connect(this.port, this.ip, function(a) {
                resolve();
            });
            this._socket.removeListener("close", reject);
        });
    }

    disconnect() {
        this._socket.destroy();
    }

    /**
     * @param {number} amount Amount to charge
     */
    charge(amount, forceManual) {
        this._log("CHARGE");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        let added = "01" + formattedAmount;
        if (forceManual) {
            added += this._CONTROL_CHARS.FS + "681";
        }
        return this._sendFrame(this._SERVICE_ID.CHARGE, added);
    }

    /**
     * @param {number} amount Amount of the transaction
     * @param {number} withdrawAmount Amount to be withdrawn
     */
    chargeWithdraw(amount, withdrawAmount) {
        this._log("CHARGE WITH WITHDRAW");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        let formattedWithdrawAmount = withdrawAmount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.CHARGE_WITHDRAW, "01" + formattedAmount + this._CONTROL_CHARS.FS + "02" + formattedWithdrawAmount);
    }

    /**
     * @param {number} amount Amount to charge
     */
    chargeTip(amount) {
        this._log("CHARGE WITH TIP");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.CHARGE_TIP, "01" + formattedAmount);
    }

    /**
     * @param {number} amount Amount to charge
     */
    chargeTel(amount) {
        this._log("CHARGE FROM PHONE");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.CHARGE_TEL, "01" + formattedAmount);
    }

    /**
     * @param {number} amount Amount to refund
     * @param {string} authNumber Transaction authorisation number
     * @param {boolean} [forceManual] Force manual entry of the card
     * @param {string} [invoiceNumber] Invoice number
     */
    refund(amount, authNumber, forceManual, invoiceNumber) {
        this._log("REFUND");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        let added = "01" + formattedAmount + this._CONTROL_CHARS.FS + "03" + authNumber;
        if (forceManual) {
            added += this._CONTROL_CHARS.FS + "681";
        }
        return this._sendFrame(this._SERVICE_ID.REFUND, added, {invoiceNumber: invoiceNumber});
    }

    /**
     * @param {number} amount Amount to refund
     * @param {string} seqNumber Transaction number
     */
    cancel(amount, seqNumber) {
        this._log("CANCEL");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.REFUND, "12" + seqNumber + this._CONTROL_CHARS.FS + "01" + formattedAmount);
    }

    balance() {
        this._log("BALANCE");
        return this._sendFrame(this._SERVICE_ID.BALANCE);
    }

    batchTotals() {
        this._log("BATCH TOTALS");
        return this._sendFrame(this._SERVICE_ID.BATCH_TOTALS);
    }

    /**
     * @param {number} amount Amount to prepare
     */
    prepare(amount) {
        this._log("PREPARE");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.PRE_AUTH, "01" + formattedAmount);
    }

    /**
     * @param {number} amount Amount that was prepared
     * @param {string} seqNumber Transaction number
     */
    preparedSend(amount, seqNumber) {
        this._log("PREPARE SEND");
        let formattedAmount = amount.toFixed(2).replace(/[,\.]/g, "");
        return this._sendFrame(this._SERVICE_ID.PRE_AUTH_SEND, "01" + formattedAmount + this._CONTROL_CHARS.FS + "03" + seqNumber);
    }

    /**
     * @param {boolean} printList Print the transactions list
     */
    closeBatch(printList) {
        this._log("CLOSE BATCH");
        return this._sendFrame(this._SERVICE_ID.CLOSE_BATCH, "08" + (printList ? "1" : "0"));
    }

    initTerminal() {
        this._log("INIT TERMINAL");
        return this._sendFrame(this._SERVICE_ID.INIT_TERMINAL);
    }

    printLastTransaction() {
        this._log("PRINT LAST TRANSACTION");
        return this._sendFrame(this._SERVICE_ID.PRINT_LAST);
    }

    transactionList() {
        this._log("TRANSACTION LIST");
        return this._sendFrame(this._SERVICE_ID.TRANSACTION_LIST);
    }

    getStatus() {
        this._log("STATUS");
        return this._sendFrame(this._SERVICE_ID.STATUS);
    }

    /**
     * @param {number} serviceId
     * @param {string} [data]
     * @param {object} [options]
     */
    _sendFrame(serviceId, data, options) {
        return new Promise((resolve, reject) => {
            let buffer = this._constructRequestFrame(serviceId, data, options);
            this._log("SENT");
            this._log(this._getDeciFormattedString(buffer));
            this._log(this._getHexaFormattedString(buffer));
            this._log(JSON.stringify(buffer));
            this._socket.write(buffer);
            this._addEvent(serviceId, resolve, reject);
        });
    }

    /**
     * @param {number} serviceId
     * @param {Function} onSuccess
     * @param {Function} onError
     */
    _addEvent(serviceId, onSuccess, onError) {
        this._events.set(serviceId, {resolve: onSuccess, reject: onError});
    }

    /**
     * @param {number} serviceId
     * @param {object} data
     */
    _resolveEvent(serviceId, data) {
        let event = this._events.get(serviceId);
        if (event) {
            event.resolve(data);
            this._events.delete(serviceId);
        }
    }

    /**
     * @param {number} serviceId
     * @param {object} data
     */
    _rejectEvent(serviceId, data) {
        let event = this._events.get(serviceId);
        if (event) {
            event.reject({
                data: data,
                response: this._currentResponse
            });
            this._events.delete(serviceId);
        }
    }

    _onSocketConnect() {
        this.connected = true;
        this._log("CONNECTED");
    }

    /**
     * @param {boolean} hadError If the connection was closed due to an error
     */
    _onSocketClose(hadError) {
        if (hadError) {
            this._log("DISCONNECTED WITH ERROR");
        } else {
            this._log("DISCONNECTED");
        }
        for (var event of this._events.values()) {
            event.reject({type: "DISCONNECTED"});
        }
        this._events.clear();
        this.connected = false;
    }

    /**
     * @param {Error} error The error returned by the net plugin
     */
    _onSocketError(error) {
        this._log("ERROR");
        this._log(error);
        console.error(error);
    }

    /**
     * @param {number} serviceId
     * @param {string} content
     * @param {object} [options]
     */
    _constructRequestFrame(serviceId, content, options = {}) {
        content = content || "";
        let bufferString = this._writeFrameHeader(serviceId, {increment: true, invoiceNumber: options.invoiceNumber});
        if (content) {
            bufferString += this._CONTROL_CHARS.FS + content;
        }
        bufferString += this._writeFrameFooter(serviceId);
        bufferString = this._writeFrameLength(bufferString) + bufferString;
        let buffer = new Buffer(bufferString);
        return buffer;
    }

    /**
     * @typedef {Object} FrameHeaderOptions
     * @property {boolean} [increment]
     * @property {boolean} [noClerkId]
     * @property {boolean} [invoiceNumber]
     * @property {boolean} [noInvoiceNumber]
     */

    /**
     * @param {number} serviceId
     * @param {FrameHeaderOptions} options
     */
    _writeFrameHeader(serviceId, options = {}) {

        var sequenceNumber = getConfiguration("tpv-sequence-number") || 0;
        if (options.increment) {
            sequenceNumber++;
            if (sequenceNumber > 99) {
                sequenceNumber = 1;
            }
            setConfiguration("tpv-sequence-number", sequenceNumber);
        }

        let bufferString =
              this._CONTROL_CHARS.FS
            + "15" + this._leftPad(sequenceNumber, 2, "0") // Request number
            + this._CONTROL_CHARS.FS
            + "0902" // Protocol version
            + this._CONTROL_CHARS.FS
            + "13" // Service ID (command)
            + (serviceId < 10 ? "0" + serviceId : serviceId.toString());

        // These frames do not have CLERK_ID and INVOICE_NUMBER
        var exceptions = [
            this._SERVICE_ID.BALANCE,
            this._SERVICE_ID.BATCH_TOTALS,
            this._SERVICE_ID.TRANSACTION_LIST,
            this._SERVICE_ID.CLOSE_BATCH,
            this._SERVICE_ID.PRINT_LAST,
            this._SERVICE_ID.INIT_TERMINAL,
            this._SERVICE_ID.STATUS
        ];

        if (!options.noClerkId && exceptions.indexOf(serviceId) == -1) {
            let clerkID = 1;
            bufferString += this._CONTROL_CHARS.FS + "30" + this._leftPad(clerkID, 2, "0");
        }

        var invoiceNumber = options.invoiceNumber || getConfiguration("transaction-number") || 1;
        if (!options.noInvoiceNumber && exceptions.indexOf(serviceId) == -1) {
            bufferString += this._CONTROL_CHARS.FS + "72" + invoiceNumber;
        }

        return bufferString;

    }

    /**
     * @param {number} serviceId
     */
    _writeFrameFooter(serviceId) {
        var processingExceptions = [
            this._SERVICE_ID.STATUS,
            this._SERVICE_ID.INIT_TERMINAL
        ];
        if (processingExceptions.indexOf(serviceId) == -1) {
            let bufferString =
                  this._CONTROL_CHARS.FS
                + "40010"; // Processing options
            return bufferString;
        }
        return "";
    }

    /**
     * @param {string} bufferString
     */
    _writeFrameLength(bufferString) {
        let length = bufferString.length;
        let encoded = String.fromCharCode(Math.floor(length / 256)) + String.fromCharCode(length % 256);
        return encoded;
    }

    /**
     * @param {Buffer} buffer Received data
     */
    _onSocketData(buffer) {

        this._log("RECEIVED");
        this._log(this._getDeciFormattedString(buffer));
        this._log(this._getHexaFormattedString(buffer));
        this._log(JSON.stringify(buffer));

        let str = "";
        for (let i = 0; i < buffer.length; i++) {
            str += String.fromCharCode(buffer[i]);
        }

        let serviceId = parseInt(str.slice(15, 17));
        let responseCode = str.slice(20, 23);
        let response = this._formatResponse(str);
        if (this._currentResponse) {
            this._currentResponse.merge(response);
        } else {
            this._currentResponse = response;
        }

        switch (responseCode) {
            case this._RESPONSE_CODE.SUCCESS:
                this._resolveEvent(serviceId, {
                    code: this._RESPONSE_CODE.SUCCESS,
                    type: "SUCCESS",
                    response: this._currentResponse
                });
                break;
            case this._RESPONSE_CODE.REFUSED:
            case this._RESPONSE_CODE.REFUSED_2:
                this._rejectEvent(serviceId, {
                    code: this._RESPONSE_CODE.REFUSED,
                    type: "REFUSED",
                    response: this._currentResponse
                });
                break;
            case this._RESPONSE_CODE.EXPIRED:
                this._rejectEvent(serviceId, {
                    code: this._RESPONSE_CODE.EXPIRED,
                    type: "EXPIRED",
                    response: this._currentResponse
                });
                break;
            case this._RESPONSE_CODE.BUSY:
                this._rejectEvent(serviceId, {
                    code: this._RESPONSE_CODE.BUSY,
                    type: "BUSY",
                    response: this._currentResponse
                });
                break;
            case this._RESPONSE_CODE.PROCESSING:
            case this._RESPONSE_CODE.ADD_DATA:
            case this._RESPONSE_CODE.CONTINUE_DATA:
                let newBuffer = this._writeFrameHeader(serviceId, {increment: false, noClerkId: true, noInvoiceNumber: true});
                newBuffer = this._writeFrameLength(newBuffer) + newBuffer;
                var bufferToSend = new Buffer(newBuffer);
                this._log("SENT");
                this._log(this._getDeciFormattedString(bufferToSend));
                this._log(this._getHexaFormattedString(bufferToSend));
                this._log(JSON.stringify(bufferToSend));
                this._socket.write(bufferToSend);
                break;
            default:
                this._rejectEvent(serviceId, {
                    type: "UNKNOWN",
                    response: this._currentResponse
                });
                break;
        }

    }

    /**
     * TODO - Refactor et probablement couper en deux
     * @param {string} str
     */
    // Complexity is 77 Bloody hell...
    _formatResponse(str) {

        // Take the frame and split it into a 3 level deep nested object
        // The 3 levels are Element, Record and Group element
        // See SPSI_INTERFACE_PDV_v2.28.pdf p63
        let obj = /** @type {any} */ (str.split(this._CONTROL_CHARS.FS));
        obj.splice(0, 1);
        for (let i = 0; i < obj.length; i++) {
            obj[i] = {
                type: obj[i].substring(0, 2),
                value: obj[i].substring(2, obj[i].length)
            };
            if (obj[i].value.indexOf(this._CONTROL_CHARS.RS + this._CONTROL_CHARS.GS) != -1) {
                obj[i].value = obj[i].value.split(this._CONTROL_CHARS.RS + this._CONTROL_CHARS.GS).filter(e => (e ? true : false));
                for (let j = 0; j < obj[i].value.length; j++) {
                    let l;
                    if (/^[0-9]{3}/g.test(obj[i].value[j])) {
                        l = 3;
                    } else if (!/^[0-9]/g.test(obj[i].value[j])) {
                        l = 0;
                    } else {
                        l = 2;
                    }
                    obj[i].value[j] = {
                        type: obj[i].value[j].substring(0, l),
                        value: obj[i].value[j].substring(l, obj[i].value[j].length)
                    };
                    if (obj[i].value[j].value.indexOf(this._CONTROL_CHARS.GS) != -1) {
                        obj[i].value[j].value = obj[i].value[j].value.split(this._CONTROL_CHARS.GS).filter(e => (e ? true : false));
                        for (let k = 0; k < obj[i].value[j].value.length; k++) {
                            if (!/^[0-9]/g.test(obj[i].value[j].value[k])) {
                                l = 0;
                            } else {
                                l = 2;
                            }
                            obj[i].value[j].value[k] = {
                                type: obj[i].value[j].value[k].substring(0, l),
                                value: obj[i].value[j].value[k].substring(l, obj[i].value[j].value[k].length)
                            }
                        }
                    }
                }
            }
        }

        // Format all data computed into the 3 level structure to nice values in a simple 1 level object
        // See SPSI_INTERFACE_PDV_v2.28.pdf p246
        let formatted = new FlexResponse();
        for (let i = 0; i < obj.length; i++) {
            switch (obj[i].type) {
                case "01":
                    formatted.amount = parseInt(obj[i].value) / 100;
                    break;
                case "02":
                    formatted.tipAmount = parseInt(obj[i].value) / 100;
                    break;
                case "03":
                    formatted.authNumber = obj[i].value;
                    break;
                case "04":
                    var values = {"00": "Lot en balance", "01": "Lot hors balance", "02": "Lot vide"};
                    formatted.batchStatus = values[obj[i].value];
                    break;
                case "06":
                    formatted.cardNumber = obj[i].value;
                    break;
                case "07":
                    formatted.cardType = this._formatCardType(obj[i].value);
                    break;
                case "09":
                    formatted.protocolVersion = parseInt(obj[i].value);
                    break;
                case "11":
                    formatted.responseCode = obj[i].value;
                    break;
                case "12":
                    formatted.sequenceNumber = parseInt(obj[i].value);
                    break;
                case "13":
                    formatted.serviceId = parseInt(obj[i].value);
                    break;
                case "15":
                    formatted.posRequestNumber = parseInt(obj[i].value);
                    break;
                case "30":
                    formatted.clerkId = obj[i].value;
                    break;
                case "31":
                    formatted.displayText = [];
                    for (let j = 0; j < obj[i].value.length; j++) {
                        formatted.displayText.push(obj[i].value[j].value);
                    }
                    break;
                case "37":
                    formatted.posNumber = parseInt(obj[i].value);
                    break;
                case "39":
                    formatted.receipt = [];
                    for (let j = 0; j < obj[i].value.length; j++) {
                        for (let k = 0; k < obj[i].value[j].value.length; k++) {
                            formatted.receipt.push(obj[i].value[j].value[k].value);
                        }
                    }
                    break;
                case "43":
                    formatted.batchNumber = parseInt(obj[i].value);
                    break;
                case "49":
                    formatted.warnings = {code: null, message: null};
                    for (let j = 0; j < obj[i].value.length; j++) {
                        for (let k = 0; k < obj[i].value[j].value.length; k++) {
                            if (obj[i].value[j].value[k].type == "47") {
                                formatted.warnings.code = parseInt(obj[i].value[j].value[k].value);
                            } else {
                                formatted.warnings.message = obj[i].value[j].value[k].value;
                            }
                        }
                    }
                    break;
                case "64":
                    formatted.merchantReceipt = [];
                    for (let j = 0; j < obj[i].value.length; j++) {
                        for (let k = 0; k < obj[i].value[j].value.length; k++) {
                            formatted.merchantReceipt.push(obj[i].value[j].value[k].value);
                        }
                    }
                    break;
                case "65":
                    formatted.adminReceipt = [];
                    for (let j = 0; j < obj[i].value.length; j++) {
                        for (let k = 0; k < obj[i].value[j].value.length; k++) {
                            formatted.adminReceipt.push(obj[i].value[j].value[k].value);
                        }
                    }
                    break;
                case "75":
                    formatted.transactionNumber = obj[i].value;
                    break;
                case "79":
                    formatted.printClessReceipt = obj[i].value == "1" ? true : false;
                    break;
                case "81":
                    formatted.appVersion = parseInt(obj[i].value);
                    break;
                default:
                    if (!formatted.unknown) {
                        formatted.unknown = [];
                    }
                    formatted.unknown.push(obj[i]);
                    break;
            }
        }

        if (formatted.unknown) {
            console.log("UNKNOWN FLEX RESPONSE CODE");
            console.log(formatted.unknown);
        }

        return formatted;

    }

    _formatCardType(type) {
        let cardTypes = {
            "V ": "Visa",
            "M ": "Mastercard",
            "AX": "Amex",
            "AD": "Accord D",
            "MP": "Privative",
            "P ": "Interac",
            "PI": "Interac",
            "JS": "Sears", // R.I.P.
            "JC": "JCB",
            "ID": "Discover",
            "CP": "Carte d'achat prépayée",
            "CA": "Argent comptant",
            "FL": "FANBOX Accumulation",
            "FP": "FANBOX PPD",
            "AM": "Réservé"
        }
        return cardTypes[type] || "Inconnu";
    }

    _log(message) {
        var fs = eval('require("fs")');
        var now = new Date();
        var date = now.getFullYear()
            + "-" + this._leftPad(now.getMonth() + 1, 2, "0")
            + "-" + this._leftPad(now.getDate(), 2, "0")
            + " " + this._leftPad(now.getHours(), 2, "0")
            + ":" + this._leftPad(now.getMinutes(), 2, "0")
            + ":" + this._leftPad(now.getSeconds(), 2, "0")
        message = "[" + date + "] " + String(message) + "\r\n";
        fs.appendFile("./logs/log-tpv.log", message, "utf8", err => {
            if (err) {
                console.log("TPV LOG ERROR");
                console.log(err);
            }
        });
    }

    _getDeciFormattedString(buffer) {
        let str = "";
        for (let i = 0; i < buffer.length; i++) {
            if (buffer[i] < 32 || buffer[i] > 127) {
                str += "[" + buffer[i] + "]";
            } else {
                str += String.fromCharCode(buffer[i]);
            }
        }
        return str;
    }

    _getHexaFormattedString(buffer) {
        let str = "";
        for (let i = 0; i < buffer.length; i++) {
            if (buffer[i] < 32 || buffer[i] > 127) {
                var char = this._leftPad(buffer[i].toString(16), 2, "0");
                str += "\\x" + char;
            } else {
                str += String.fromCharCode(buffer[i]);
            }
        }
        return str;
    }

    _leftPad(string, length, pad) {
        return (pad || " ").repeat(Math.max(length - String(string).length, 0)) + string;
    }

}

class FlexResponse {

    constructor() {
        this.amount             = /** @type {number} */ (null);
        this.tipAmount          = /** @type {number} */ (null);
        this.cardNumber         = /** @type {string} */ (null);
        this.cardType           = /** @type {string} */ (null);
        this.protocolVersion    = /** @type {number} */ (null);
        this.responseCode       = /** @type {string} */ (null);
        this.sequenceNumber     = /** @type {number} */ (null);
        this.serviceId          = /** @type {number} */ (null);
        this.posRequestNumber   = /** @type {number} */ (null);
        this.displayText        = /** @type {string[]} */ (null);
        this.posNumber          = /** @type {number} */ (null);
        this.receipt            = /** @type {string[]} */ (null);
        this.merchantReceipt    = /** @type {string[]} */ (null);
        this.adminReceipt       = /** @type {string[]} */ (null);
        this.batchNumber        = /** @type {number} */ (null);
        this.warnings           = /** @type {{code:number, message:string}} */ (null);
        this.transactionNumber  = /** @type {string} */ (null);
        this.printClessReceipt  = /** @type {boolean} */ (null);
        this.appVersion         = /** @type {number} */ (null);
        this.batchStatus        = /** @type {string} */ (null);
        this.authNumber         = /** @type {string} */ (null);
        this.clerkId            = /** @type {string} */ (null);
        this.unknown            = /** @type {object[]} */ (null);
    }

    /**
     * @param {FlexResponse} res2
     */
    merge(res2) {
        let receipt = this.receipt || [];
        if (res2.receipt) {
            receipt = receipt.concat(res2.receipt);
        }
        let merchantReceipt = this.merchantReceipt || [];
        if (res2.merchantReceipt) {
            merchantReceipt = merchantReceipt.concat(res2.merchantReceipt);
        }
        let adminReceipt = this.adminReceipt || [];
        if (res2.adminReceipt) {
            adminReceipt = adminReceipt.concat(res2.adminReceipt);
        }
        for (var key in res2) {
            let value = res2[key];
            if (typeof value != "undefined" && value != null) {
                this[key] = value;
            }
        }
        this.receipt = receipt;
        this.merchantReceipt = merchantReceipt;
        this.adminReceipt = adminReceipt;
        return this;
    }

}

