//@ts-check
import Address, { AddressDefinition } from "./AddressModel";
import Loyalty, { LoyaltyDefinition } from "./LoyaltyModel";
import Axios from "axios";
import router from "../router";
import Store from "../store";
import EventBus from "../lib/eventBus";

/**
 * @typedef Contact
 * @property {String} email
 * @property {String} phoneNumber
 */

/**
 * @typedef ConsumerDefinition
 * @property {UserDefinition} information
 * @property {LoyaltyDefinition} loyalty
 * @property {{id: number}} [user]
 */

/**
 * @typedef UserDefinition
 * @property {number} [id]
 * @property {String} firstName
 * @property {String} lastName
 * @property {"m"|"f"|"x"|string} gender
 * @property {String} birthDate
 * @property {"fr"|"en"|string} language
 * @property {Contact} contact
 * @property {AddressDefinition[]} addresses
 */

export default class User {

  constructor() {
    this.reset();
  }

  reset() {
    this.token = /** @type {string} */ (null);
    this.firstName = /** @type {string} */ (null);
    this.lastName = /** @type {string} */ (null);
    this.email = /** @type {string} */ (null);
    this.username = null;
    this.phoneNumber = /** @type {string} */ (null);
    this.gender = /** @type {string} */ (null);
    this.language = /** @type {string} */ (null);
    this.birthDate = /** @type {string} */ (null);
    this.favoriteCompany = /** @type {string} */ (null);
    this.onBehalfOf = null;
    this.remark = /** @type {string} */ (null);
    this.role = /** @type {string} */ (null);
    this.addresses = /** @type {Address[]} */ ([new Address(true)]);
    this.loyalty = new Loyalty();
    this.anonymous = false;
    this.messagingConsent = false;
    this.billingTokens = [];
    this.image = null;
    this.photoData = null;
    this.giftCards = [];
  }

  /**
   * @param {ConsumerDefinition} definition
   */
  load(definition, token, refresh) {
    if (token) {
      this.token = token;
    }

    if (definition) {
      this.id = definition.user ? definition.user.id : definition.information.id;
      this.role = definition.user ? definition.user.role : null;
      this.username = definition.user ? definition.user.username : null;
      this.firstName = definition.information && !this.isDispatchUser() ? definition.information.firstName : null;
      this.lastName = definition.information && !this.isDispatchUser() ? definition.information.lastName : null;
      this.email = definition.information && !this.isDispatchUser() && definition.information.contact ? definition.information.contact.email : null;
      this.birthDate = definition.information && !this.isDispatchUser() ? definition.information.birthDate : null;
      this.gender = definition.information && !this.isDispatchUser() ? definition.information.gender : null;
      this.language = definition.information ? definition.information.language : null;
      this.messagingConsent = definition.information && definition.information.contact ? definition.information.contact.messagingConsent : false;
      this.phoneNumber = definition.information && !this.isDispatchUser() && definition.information.contact ? definition.information.contact.phoneNumber : null;
      this.favoriteCompany = definition.information && !this.isDispatchUser() ? definition.information.favoriteCompany : null;
      this.manager = definition.manager;
      this.image = definition.information ? definition.information.image : null;
      this.managed = definition.managed || [];
      this.anonymous = definition.isAnonymous || false;
      this.remark = definition.information ? definition.information.remark : null;
      this.onBehalfOf = null;
      this.billingTokens = definition.billingTokens ? definition.billingTokens : [];
      this.loyalty.load(definition.loyalty);
      this.giftCards = definition.information && definition.information.moreData && definition.information.moreData.giftCards ? definition.information.moreData.giftCards : [];
      this.loadAddresses(definition, refresh);
    }
  }

  loadAddresses(definition, refresh) {
    if (!this.isDispatchUser() && definition.information && definition.information.addresses.length > 0) {
      // Keep previous adresses entered before login
      let previousAddressesBeforeLogin = [...this.addresses];
      let previousDefaultAddress = this.getDefaultAddress().isEmpty() ? null : this.getDefaultAddress();
      // Get saved addresses from user
      let nonEmptyAddresses = /** @type {AddressDefinition[]} */ ([]);
      for (let i = 0; i < definition.information.addresses.length; i++) { //Filter out possibly saved empty addresses
        let address = definition.information.addresses[i];
        if (address.fullAddress) {
          nonEmptyAddresses.push(address);
        }
      }
      this.addresses = nonEmptyAddresses.map((a) => { //Create addresses
        let address = new Address(false);
        address.load(a);
        return address;
      });
      if (this.addresses.length === 0) { //Make sure there is at least one address
        this.addresses = [new Address(true)];
      }

      // If no previous address, get current default address from saved addresses
      if (!previousDefaultAddress) {
        previousDefaultAddress = this.getDefaultAddress();
      }
      if (refresh) {
        return;
      }
      // Find previous addresses not found in saved addresses (new address that will be added to user)
      let newAddresses = previousAddressesBeforeLogin.filter(a => !this.findAddress(a.latitude, a.longitude) && !a.isEmpty());

      let defaultAddressIndex = 0;
      for (let i = 0; i < newAddresses.length; i++) {
        let address = newAddresses[i];
        address.new = false;
        this.addresses.push(address);
        if (address.default) {
          defaultAddressIndex = this.addresses.indexOf(address); // Change index for default address to use
        }
      }
      // Setting default address :
        // 1. User has chosen an address and it's a saved address, or the user's new addresses are saved addresses.
      if (previousDefaultAddress && this.findAddress(previousDefaultAddress.latitude, previousDefaultAddress.longitude)) {
        this.setDefaultUserAddress(this.addresses.indexOf(this.findAddress(previousDefaultAddress.latitude, previousDefaultAddress.longitude)));
      } else {
        // 2. Set default address to either 0 or index defined from new addresses
        this.setDefaultUserAddress(defaultAddressIndex);
      }
      // Save new addresses
      if (newAddresses.length > 0) {
        this.saveAddresses();
      }
    }
  }

  loadOnBehalfOf(definition) {
    this.firstName = definition.firstName;
    this.lastName = definition.lastName;
    this.phoneNumber = definition.contact ? definition.contact.phoneNumber : null;
    this.remark = definition.remark;
    this.email = definition.contact ? definition.contact.email : null;
    this.onBehalfOf = definition.id;
    if (definition.addresses && definition.addresses.length > 0) {
      this.addresses = /** @type {Address[]} */ (definition.addresses.map(a => {
        let address = new Address(false);
        address.load(a);
        return address;
      }));
      if (!this.addresses.find(a => a.default)) {
        this.addresses[0].default = true;
      }
    } else {
      this.addresses = /** @type {Address[]} */ ([new Address(true)]);
    }
  }

  getDefaultAddress() {
    return this.addresses.find(a => a.default);
  }

  getDefaultAddressIndex() {
    return this.addresses.indexOf(this.getDefaultAddress());
  }

  clearOnBehalfOf(force) {
    if ((!this.isDispatchUser() || !this.onBehalfOf) && !force) {
      return;
    }
    this.firstName = null;
    this.email = null;
    this.lastName = null;
    this.phoneNumber = null;
    this.remark = null;
    this.onBehalfOf = null;
    this.addresses = /** @type {Address[]} */ ([new Address(true)]);
  }

  async update() {
    try {
      let response = await Axios.get(Store.getters.urlServer + "/api/me", {
        headers: {
          Authorization: "Bearer " + (this.token)
        }
      });
      this.load(response.data, this.token);
      EventBus.$emit("reload-recommendations");
      return true;
    } catch (e) {
      console.log("error", e);
      return false;
    }
  }

  async saveAddresses() {
    if (!this.anonymous && !this.isDispatchUser() && this.token) {
      await Store.dispatch("saveAddresses");
    }
  }

  async saveGiftCards() {
    if (!this.anonymous && !this.isDispatchUser() && this.token) {
      await Store.dispatch("saveGiftCards");
    }
  }

  /**
   * @param {number} [id]
   */
  getLoyaltyAccount(id) {
    if (!id) {
      return this.loyalty.accounts[0] ? this.loyalty.accounts[0] : null;
    }
    return this.loyalty.accounts.find(a => a.id.toString() === id.toString());
  }

  getAccountsForPayment() {
    return this.loyalty.accounts.filter(a => a.canPay());
  }

  getChildLoyaltyAccounts() {
    if (this.getLoyaltyAccount() && this.getLoyaltyAccount().canManage()) {
      return this.loyalty.accounts.slice(1).filter(a => this.managed.find(m => m.id === a.user.id));
    }
    return [];
  }

  hasLoyaltyAccountWithUserId(id) {
    return !!this.getLoyaltyAccountForUserId(id);
  }

  getLoyaltyAccountForUserId(id) {
    return this.getChildLoyaltyAccounts().find(a => a.owner && a.owner.id === id);
  }

  hasLoyaltyAccountId() {
    return !!this.loyalty && !!this.loyalty.getId();
  }

  shouldCreateAddress() {
    return this.getDefaultAddress().isNew();
  }

  findAddress(latitude, longitude) {
    return this.addresses.find(b => latitude === b.latitude && longitude === b.longitude);
  }

  createNewAddress() {
    for (let address of this.addresses) {
      address.default = false;
      address.new = false;
    }
    this.addresses.push(new Address(true));
  }

  cancelNewAddress() {
    for (let address of this.addresses) {
      address.new = false;
      address.default = false;
    }
    if (this.addresses.length > 1) {
      this.addresses.splice(this.addresses.length - 1, 1);
    }
    this.addresses[0].default = true;
  }

  setDefaultUserAddress(index) {
    for (let address of this.addresses) {
      address.new = false;
      address.default = false;
    }
    if (this.addresses[index]) {
      this.addresses[index].default = true;
    } else {
      this.addresses[0].default = true;
    }
  }

  confirmAddress(address = this.getDefaultAddress()) {
    address.new = false;
  }

  updateAddressFromGoogle(newAddress, position, fullString, oldAddress = this.getDefaultAddress()) {
    oldAddress.loadFromGoogle(newAddress, position, fullString);
    this.saveAddresses();
  }

  updateAddressFromHere(newAddress, position, fullString, oldAddress = this.getDefaultAddress()) {
    oldAddress.loadFromHere(newAddress, position, fullString);
    this.saveAddresses();
  }

  getFullName() {
    let fullName = "";
    if (this.firstName) {
      fullName += this.firstName;
    }
    if (this.firstName && this.lastName) {
      fullName += " ";
    }
    if (this.lastName) {
      fullName += this.lastName;
    }
    return fullName;
  }

  isInitialized() {
    return this.firstName && this.lastName && this.phoneNumber && this.email;
  }

  isAnonymous() {
    return this.anonymous;
  }

  isDispatchUser() {
    return this.role && this.role === "ROLE_DISPATCH";
  }

  isDispatchUserWithoutCustomer() {
    return this.isDispatchUser() && !this.onBehalfOf;
  }

  setAsDispatchForPOS() {
    this.role = "ROLE_DISPATCH";
    this.clearOnBehalfOf(true);
  }

  clearDispatchForPOS() {
    this.clearOnBehalfOf();
    this.role = null;
  }

  toDto() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      gender: this.gender || undefined,
      birthDate: this.birthDate || undefined,
      contact: {
        email: this.email,
        phoneNumber: this.phoneNumber,
        messagingConsent: false
      },
      favoriteCompany: this.favoriteCompany,
      remark: this.remark,
      image: this.photoData ? getBase64Data(this.photoData.data) : (this.image ? undefined : null),
      addresses: this.getNonEmptyAddresses(),
      moreData: this.giftCards.length > 0 ? {
        giftCards: this.giftCards
      } : null
    }
  }

  /**
   * @returns {Address[]}
   */
  getNonEmptyAddresses() {
    let addresses = [];
    for (let i = 0; i < this.addresses.length; i++) {
      if (!this.addresses[i].isEmpty()) {
        addresses.push(this.addresses[i].toDto());
      }
    }
    return addresses;
  }

  /**
   *
   * @returns {{number: string, type: string}[]}
   */
  getiShopFoodGiftCards() {
    return this.giftCards;
  }

  /**
   * @param {string} number
   */
  addiShopFoodGiftCard(number) {
    if (this.hasiShopFoodGiftCard(number)) {
      return;
    }
    this.giftCards.push({
      number: number,
      type: "ishopfood"
    });
  }

  hasiShopFoodGiftCard(number) {
    return this.giftCards.find(c => c.number === number);
  }

  /**
   * @param {string} number
   */
  deleteGiftCard(number) {
    let giftCard = this.giftCards.find(c => c.number === number);
    if (giftCard) {
      let index = this.giftCards.indexOf(giftCard);
      this.giftCards.splice(index, 1);
    }
  }

  async userExists(branch, email) {
    try {
      let response = await Axios.post(Store.getters.urlServer + "/api/customers/search", {
        branch: branch,
        email: email
      }, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response.data.id;
      }
    } catch (e) {
      return false;
    }
  }

  async updateInformation(data) {
    try {
      let response = await Axios.put(Store.getters.urlServer + "/api/me/information", data, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        this.load(response.data);
        return true;
      }
    } catch (e) {
      console.log("error", e);
      return false;
    }
  }

  async getChildAccountInformation(id) {
    try {
      let response = await Axios.get(Store.getters.urlServer + "/api/customers/" + id, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response.data;
      }
    } catch (e) {
      console.log("error", e);
      return null;
    }
  }

  async registerChildAccount(data) {
    try {
      let response = await Axios.post(Store.getters.urlServer + "/api/me/register_child", data, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return {success: true, response: response};
      }
    } catch (e) {
      console.log("error", e);
      return {success: false, response: e.response};
    }
  }

  async transferBalanceBetweenAccounts(fromAccount, toAccount, amount) {
    try {
      let response = await Axios.post(Store.getters.urlServer + "/api/loyalty_accounts/" + fromAccount + "/transfer", {
        toAccount: toAccount,
        points: 0,
        amount: amount
      }, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response;
      }
    } catch (e) {
      console.log("error", e);
      return;
    }
  }

  async deleteBillingToken(id) {
    try {
      let response = await Axios.delete(Store.getters.urlServer + "/api/billing_tokens/" + id, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        await this.update();
        return true;
      }
    } catch (e) {
      console.log("error", e);
      return false;
    }
  }

  async dissociateAccount(userId) {
    try {
      let response = await Axios.put(Store.getters.urlServer + "/api/me/dissociate", {
        user: userId
      }, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        await this.update();
        return true;
      }
    } catch (e) {
      console.log("error", e);
      return false;
    }
  }

  async addLoyaltyCard(number, accountId) {
    try {
      let response = await Axios.post(Store.getters.urlServer + "/api/loyalty_cards", {
        number: number,
        account: accountId
      }, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response.data;
      }
    } catch (e) {
      return;
    }
  }

  async deleteLoyaltyCard(id) {
    try {
      let response = await Axios.delete(Store.getters.urlServer + "/api/loyalty_cards/" + id, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  getSavedBillingTokensForAccount(account) {
    if (!this.getLoyaltyAccount()) {
      return [];
    }
    let loyaltyAccount = this.getLoyaltyAccount();
    return loyaltyAccount.getSavedBillingTokensForAccount(account);
  }

  async getPendingUserManagementRequests() {
    try {
      let response = await Axios.get(Store.getters.urlServer + "/api/me/pending-user-management-request", {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response.data.requests;
      }
    } catch (e) {
      return;
    }
  }

  async getHistory(data) {
    try {
      let response = await Axios.get(Store.getters.urlServer + "/api/me/history", {
        params: data,
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return response.data;
      }
    } catch (e) {
      return;
    }
  }

  async sendUserManagementRequest(userId, locale) {
    try {
      let response = await Axios.post(Store.getters.urlServer + "/api/customers/" + userId + "/management_request", {
        locale: locale
      }, {
        headers: {
          Authorization: "Bearer " + this.token
        }
      });
      if (response) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

}
