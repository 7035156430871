export default

// Background color property
"#iShopFoodApp .btn.btn-primary, " +
"#iShopFoodApp .btn.btn-primary *, " +
"#iShopFoodApp #kioskHeader .header--info, " +
"#iShopFoodApp.kiosk .promo-banner, " +
"#iShopFoodApp #kioskCart .cart--header, " +
"#iShopFoodApp #kioskCart .btn.btn-secondary#button-confirm, " +
"#iShopFoodApp #kioskConfirmation .confirmation--footer, " +
"#iShopFoodApp #kioskConfirmation .confirmation--header, " +
"#iShopFoodApp .error-box, " +
"#iShopFoodApp .error-box span, " +
"#iShopFoodApp .error-box::after { " +
    "background-color: var(--branding-primary-color) !important; " +
"} " +

// Background property
"#iShopFoodApp #kioskCart .cart-item__fake {" +
    "background: var(--branding-primary-color) !important; " +
"} " +

// Color property
"#iShopFoodApp .btn.btn-secondary, " +
"#iShopFoodApp #item-basic .presentation .right .top .priceContainer .price .cents, " +
"#iShopFoodApp #item-basic .presentation .right .top .priceContainer .price .integer, " +
"#iShopFoodApp #item-basic .presentation .right .top .priceContainer .price .sign, " +
"#iShopFoodApp #menu .discrete-price, " +
"#iShopFoodApp #promotions .discrete-price, " +
"#iShopFoodApp .cart-item.kiosk.cart .title .price, " +
"#iShopFoodApp .cart-item.kiosk.confirmation .title .price, " +
"#iShopFoodApp .compact-cart-item.kiosk.confirmation .title .price, " +
"#iShopFoodApp .info .namePrice .price, " +
"#iShopFoodApp .info .namePrice .price span, " +
"#iShopFoodApp #kioskPayment .method--type .type--name, " +
"#iShopFoodApp #kioskThankYou .thank-you--thanks, " +
"#iShopFoodApp #item-basic .selector.button.selected .check .quantity, " +
"#iShopFoodApp .modifierQuantityModal .modifier--content .modifier--price {" +
"  color: var(--branding-primary-color) !important; " +
"} " +

//Border color property
"#iShopFoodApp .btn.btn-primary, " +
"#iShopFoodApp .categories.kiosk .links .category.router-link-active:after, " +
"#iShopFoodApp #kioskCart .btn.btn-secondary#button-cancel, " +
"#iShopFoodApp .item-selector .selector.selected, " +
"#iShopFoodApp #kioskCart .btn.btn-secondary#button-confirm, " +
"#iShopFoodApp .btn.btn-secondary { " +
    "border-color: var(--branding-primary-color) !important; " +
"} " +

//Fill property
"#iShopFoodApp #kioskCart .cart--arrow, " +
"#iShopFoodApp .quantity-selector .svgicon, " +
"#iShopFoodApp .cart-item.kiosk.cart .cart-item-actions, " +
"#iShopFoodApp #item-basic .selector.button.selected .check, " +
"#iShopFoodApp .illustration .svgicon, " +
"#iShopFoodApp #kioskPayment .method--type .type--icon .svgicon, " +
"#iShopFoodApp #kioskConfirmation .confirmation--actions .btn-secondary, " +
"#iShopFoodApp .categories.kiosk .bottom-arrow, " +
"#iShopFoodApp .categories.kiosk .top-arrow { " +
    "fill: var(--branding-primary-color) !important; " +
"} "
