export default class CompanySettings {

  constructor(definition) {
    this.methods = {
      takeout: {
        activated: true,
        revenueCenter: null
      },
      delivery: {
        activated: true,
        revenueCenter: null
      },
      inPlace: {
        activated: true,
        revenueCenter: null
      }
    };
    if (definition) {
      this.load(definition);
    }
  }

  load(definition) {
    if (definition.methods) {
      if (definition.methods.takeout) {
        this.methods.takeout = definition.methods.takeout;
      }
      if (definition.methods.delivery) {
        this.methods.delivery = definition.methods.delivery;
      }
      if (definition.methods.inPlace) {
        this.methods.inPlace = definition.methods.inPlace;
      }
    }
  }

  /**
   * @param {string} method
   * @returns {null|string}
   */
  getRevenueCenterRefForMethod(method) {
    if (!method || !this.methods[method]) {
      return null;
    }
    return this.methods[method].revenueCenter;
  }

}