<template>
    <div class="toggle-switch" :class="{active: value, disabled: disabled}">
        <div class="text" v-if="hasSlot">
            <slot />
        </div>
        <input :id="'switch-checkbox' + uuid" type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)">
        <label :for="'switch-checkbox' + uuid"></label>
    </div>
</template>

<script>
  export default {
    name: "toggleSwitch",

    props: {
      value: Boolean,
      activeColor: String,
      backgroundColor: String,
      inactiveColor: String,
      disabled: Boolean,
      size: String
    },

    data() {
      return {
        uuid: null
      }
    },

    computed: {
      hasSlot() {
        return Object.keys(this.$slots).length > 0;
      },
      isDarkMode() {
        return this.$store.state.posConfiguration && this.$store.state.posConfiguration.darkTheme;
      }
    },

    watch: {
      "$store.state.primaryColor"() {
        this.setActiveColor();
      },
      "$store.state.posConfiguration.darkTheme"() {
        this.setBackgroundColor()
      }
    },

    mounted() {
      this.uuid = window.generateUUID();
      this.$el.style.setProperty("--toggle-switch--size", this.size || "60px");
      this.setActiveColor();
      this.setInactiveColor();
      this.setBackgroundColor();
    },

    methods: {
      setActiveColor() {
        this.$el.style.setProperty("--toggle-switch--active-color", this.activeColor || this.$store.state.primaryColor);
      },
      setInactiveColor() {
        this.$el.style.setProperty("--toggle-switch--inactive-color", this.inactiveColor || "#ffffff");
      },
      setBackgroundColor() {
        this.$el.style.setProperty("--toggle-switch--background-color", this.backgroundColor || this.isDarkMode ? "#262626" : "#e6e6e6");
      }
    }
  }
</script>

<style lang="scss" scoped>
    $size: var(--toggle-switch--size);
    $height: calc(#{$size} / 3);
    $circle: calc(#{$size} / 2);

    .toggle-switch {
        position: relative;
        display: flex;
        align-items: center;

        .text {
            padding-right: 15px;
        }

        &.active {
            label::after {
                background-color: var(--toggle-switch--active-color);
                left: calc(100% - #{$circle}) !important;
            }
        }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

        label {
            width: $size;
            margin-left: 0 !important;

            &::before {
                content: "" !important;
                width: $size !important;
                height: $height !important;
                border-radius: 10px;
                background-color: var(--toggle-switch--background-color);
                left: initial !important;
            }

            &::after {
                content: "" !important;
                height: $circle !important;
                width: $circle !important;
                border-radius: 100%;
                box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
                background-color: var(--toggle-switch--inactive-color);
                transition: all 250ms ease-in-out;
                margin: 0 !important;
                left: 0 !important;
            }
        }
    }
</style>