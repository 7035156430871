<template>
  <div id="qr-payment__desktop-not-supported">
    <FloatingLocaleSwitcher></FloatingLocaleSwitcher>
    <div class="content-panel">
      <div class="content-panel__title">
        {{$t('qr_payment.desktop_title')}}
      </div>
      <div class="content-panel__description">
        {{$t('qr_payment.desktop_description')}}
      </div>
    </div>
  </div>
</template>

<script>
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";

  export default {
    name: "qrPaymentDesktopNotSupported",

    components: { FloatingLocaleSwitcher }
  }
</script>

<style lang="scss" scoped>
  #qr-payment__desktop-not-supported {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .content-panel {
    background-color: white;
    border-radius: 10px;
    padding: 30px 20px;
    max-width: 400px;
    width: 100%;
  }
  .content-panel__title {
    font-weight: bold;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f8f8f8;
    text-align: center;
    font-size: 18px;
  }
  .content-panel__description {
    text-align: center;
  }
</style>
