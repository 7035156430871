<template>
  <div class="clamp" ref="clamp">
    <div class="hidden" ref="slot" v-if="isCalculating">
      <slot></slot>
    </div>
    <div class="calc " ref="calc" v-if="isCalculating"></div>
    {{ text }}
  </div>
</template>

<script>

  export default {

    name: "clamp",

    props: ["max-lines"],

    data() {
      return {
        text: "",
        isCalculating: true
      };
    },

    mounted() {
      this.$nextTick(() => {
        this.calculateClamp();
      });
    },

    watch: {
      "$ts.state.locale": function(to) {
        this.calculateClamp();
      }
    },

    methods: {
      async calculateClamp() {
        // Init
        if (this.isCalculating == false) {
          this.isCalculating = true;
          await wait(0);
        }
        if (!this.$refs.slot) {
          return;
        }
        let text = this.$refs.slot.innerText;
        this.$refs.calc.style.width = this.$refs.clamp.clientWidth + "px";
        let split = text.split(" ");

        // Set base text
        this.text = split.splice(0, 1)[0] + (split.length > 0 ? "..." : "");
        let baseHeight = this.$refs.calc.clientHeight;
        let lines = 0;

        while (lines <= this.maxLines && split.length > 0) {
          let backup = this.text;
          this.text = this.text.substr(0, this.text.length - 3);
          this.text += " " + split.splice(0, 1)[0] + (split.length > 0 ? "..." : "");
          this.$refs.calc.innerHTML = this.text;
          // Check if new text is too long
          if (this.$refs.calc.clientHeight > baseHeight) {
            lines++;
            baseHeight = this.$refs.calc.clientHeight;
            if (lines > this.maxLines) {
              this.text = backup;
              break;
            }
          }
        }

        this.isCalculating = false;

      }
    }

  }

</script>

<style lang="scss" scoped>

  .hidden {
    display: none;
  }

  .calc {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

</style>
