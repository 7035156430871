<template>
  <div id="holdModal" class="modal" :class="{show: show}">
    <div class="modal-content" v-if="detail">

      <div class="modal-header">
        <svgicon src="icons/void.svg" :height="24" :width="24"></svgicon>
        <div class="header__text">{{$t('hold.title')}}</div>
      </div>

      <div class="modal__item">
        <AsyncImage class="item__image" :fallback="$store.state.defaultItemImage" :image="image"></AsyncImage>
        <div class="item__description">
          <label class="description__label">{{$t('title.item')}}</label>
          <div class="description__name">{{detail.name}}</div>
        </div>
      </div>

      <div class="modal__times">
        <label class="times__label">{{$t('hold.how_long')}}</label>
        <div class="times">
          <div class="time" v-for="(time, timeIndex) of holdTime" :key="'time-' + timeIndex">
            <input :id="'radio--time' + timeIndex" type="radio" v-model="selectedHoldTime" :value="timeIndex">
            <label :for="'radio--time' + timeIndex">{{time}} {{$t( time <= 1 ? 'hold.minute' : 'hold.minutes')}}</label>
          </div>
          <div class="time">
            <input id="radio--time-other" type="radio" v-model="selectedHoldTime" :value="-1">
            <label for="radio--time-other">{{$t('other')}}</label>
          </div>
        </div>
      </div>
      
      <div class="modal__custom-time">
        <label class="custom-time__label">{{$t('hold.custom_number')}}</label>
        <input type="number" min="0" class="custom-time__text" v-model="customHoldTime" :placeholder="$t('hold.enter_number')"/>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="holdItem">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{$t('confirm')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import AsyncImage from "@/components/tools/AsyncImage";

  export default {
    name: "HoldModal",

    components: { AsyncImage },

    data() {
      return {
        item: null,
        detail: null,
        inventoryItem: null,
        holdTime: [1, 3, 5, 10],
        customHoldTime: "",
        selectedHoldTime: null,
        show: false
      }
    },

    mounted() {
      EventBus.$on("hold-detail", (detail) => {
        this.detail = detail;
        this.item = this.inventory.search(this.detail.itemId);
        this.reset();

        this.$nextTick(() => {
          this.show = true;
        })
      });
    },

    beforeDestroy() {
      EventBus.$off("hold-detail");
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      image() {
        if (this.item && this.item.image) {
          return this.$store.state.urlFileServer + this.item.image;
        }
      }
    },

    methods: {
      holdItem() {
        //TODO
      },
      reset() {
        this.selectedHoldTime = "";
        this.customHoldTime = "";
      },
      back() {
        this.show = false;
        this.reset();
      }
    }
  }
</script>

<style lang="scss" scoped>

#iShopFoodApp #holdModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 580px;

    .modal__item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .item__image {
        width: 180px;
        height: 120px;
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .item__description {
        .description__label {
          margin-bottom: 5px;
        }
        .description__name {
          font-size: 26px;
          font-weight: 900;
          color: #1a1a1a;
        }
      }
    }

    .times__label, .custom-time__label, .description__label {
      font-weight: 500;
      color: #808080;
      font-size: 16px;
    }

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-weight: 900;
        font-size: 22px;
        color: #1a1a1a;
      }
    }

    .modal__times {
      margin-bottom: 30px;
      .times {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .time {
          margin: 10px;
          border: 1px solid #e6e6e6;
          border-radius: 12px;
          display: flex;
          align-items: center;
          padding: 10px 12px;
          width: 240px;
          font-weight: 500;

          input[type=radio] + label {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 40px;

            &:after {
              left: 15px;
            }
          }
        }
      }
    }

    .modal__custom-time {
      display: flex;
      flex-direction: column;
      .custom-time__text {
        font-family: inherit;
        margin-top: 10px;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        font-weight: 500;
        padding: 20px;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>