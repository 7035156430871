<template>
  <div id="brandingSwitchWarningModal" class="modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-header">{{ $t('branding.switch.title') }}</div>
      <div class="modal-body">
        <Illustration src="icons/cart-full.svg"></Illustration>
        <div class="warning-text">{{ $t('branding.switch.description') }}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="close">{{ $t('branding.switch.back') }}</button>
        <button type="button" class="btn btn-primary" @click="switchBranding">{{ $t('branding.switch.change') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from "@/lib/eventBus";
  import Illustration from "@/components/modal/Illustration";

  export default {
    name: "brandingSwitchWarningModal",

    components: { Illustration },

    data() {
      return {
        show: false,
        branding: null
      }
    },

    mounted() {
      EventBus.$on("show-branding-switch-warning-modal", (branding) => {
        this.show = true;
        this.branding = branding;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-branding-switch-warning-modal");
    },

    methods: {
      close() {
        this.show = false;
        this.$router.push(this.$router.generate("/menu"));
        if (this.$order.branding && this.$kiosk) {
          EventBus.$emit("select-first-category");
        }
      },
      switchBranding() {
        this.show = false;
        this.$order.removeAllItems();
        this.$order.setBranding(this.branding);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.kiosk #brandingSwitchWarningModal {
    .modal-header {
      text-align: center;
      font-weight: 900;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 30px;
      text-transform: uppercase;
      border-bottom: 2px dashed #e6e6e6;
      font-family: "Segoe UI Black", "Roboto", sans-serif;
      line-height: 1.1;
      color: var(--primary-color);
    }
    .modal-content {
      max-width: 750px;
      padding: 40px;
      border-radius: 20px;

      .warning-text {
        text-align: center;
        font-size: 18px;
        margin-top: 30px;
      }

      .modal-body {
        padding-top: 15px;
      }

      .modal-footer {
        margin-top: 40px;
        .btn {
          font-size: 18px;
          padding: 23px 30px;
          width: 48%;
          box-shadow: none;
        }
      }
    }
  }
</style>
