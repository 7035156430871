<template>
  <div id="orderOptionsModal" class="modal" :class="{show: show}">
    <div class="content modal-content" v-if="$store.state.quickMode">

      <div class="close" @click="close" v-show="$order.method && currentCompany && $order.hour">
        <svgicon src="icons/close.svg" :width=20 :height=20></svgicon>
      </div>

      <div class="left">
        <AsyncImage class="leftImage" :image="modalImage"></AsyncImage>
      </div>

      <div class="right">

        <div class="big-title" v-if="!$store.getters.isMobile">
          <svgicon src="icons/nouveau.svg" :width=20 :height=20></svgicon>
          {{ $store.state.screenSize == 'xs' ? $t("order.new_order") : $t('order_options.welcome') }}
        </div>

        <div class="logo-holder big-title" v-if="$store.getters.isMobile" :class="{grow: !$order.method}">
          <div class="logo"></div>
          <div class="locale" v-if="branch && !branch.isEnglishLocaleOnly()">
            <svgicon src="icons/btn-francais.svg" :width=24 :height=24 v-if="$ts.state.locale == 'en'" @click="setLocale('fr')"></svgicon>
            <svgicon src="icons/btn-english.svg" :width=24 :height=24 v-if="$ts.state.locale == 'fr'" @click="setLocale('en')"></svgicon>
          </div>
        </div>

        <div class="panel-holder">
          <Panel ref="panelAbout" class="section about" icon="icons/info.svg" v-if="branch.getAboutText()" :title="$to(branch.getAboutText().title)" collapsible>
            <div class="about-description">{{ $to(branch.getAboutText().description) }}</div>
          </Panel>


          <Panel class="section method" :class="{grow: !$order.method}" :title="panelMethodTitle"
                 ref="panelMethod" icon="icons/comptoir.svg" collapsible>
            <div class="title">{{ $t("order.choose_order_type") }} :</div>
            <OrderMethod :company="currentCompany"></OrderMethod>
            <EnterDisplayMode></EnterDisplayMode>
          </Panel>

          <Panel class="section location" v-show="$order.method == 'delivery'" :class="{hide: !$order.method, grow: showCompanies}"
                 ref="panelLocation" :title="panelLocationTitle" icon="icons/location.svg" collapsible>
            <UserLocation @changed="userLocationChanged" type="order-type" prefillAddress
                          @custom-company="customCompanyRequested" :showCustomCompanyButton="!currentCompany && !showAll && $order.method == 'delivery'"></UserLocation>
          </Panel>

          <Panel class="section" :class="{hide: showCompanies, grow: !currentCompany}" :title="$t('payment.branch')" icon="icons/business.svg">
            <div class="title">{{$t('order_options.select_company')}} :</div>
            <OrderCompaniesList :current-company="currentCompany" :companies="companies" @selected="setCompany($event)"></OrderCompaniesList>
            <a class="changeLink" @click="currentCompany = null" v-if="!!currentCompany">
              {{ $t("order.to_change_company") }}
            </a>
          </Panel>

          <Panel class="section grow no-shrink" :class="{hide: showCompanies || companies.length == 0 || companies.indexOf(currentCompany) == -1}"
            :title="$t('order.reception')" icon="icons/clock.svg">

            <template v-if="hasPaymentAfterPickupEnabled">
              <div class="select payment-only">{{$t('order_options.please_select_option')}}</div>
              <div class="radios payment-only">
                <input id="radioPaymentOnly__true" type="radio" v-model="$order.paymentOnly" :value="true">
                <label for="radioPaymentOnly__true">{{$t('order_options.payment_only_true')}}</label>
                <input id="radioPaymentOnly__false" type="radio" v-model="$order.paymentOnly" :value="false">
                <label for="radioPaymentOnly__false">{{$t('order_options.payment_only_false')}}</label>
              </div>
            </template>

            <template v-if="shouldAskForReceptionTime">
              <div class="title" v-if="$order.method == 'catering'">{{$t('order_options.select_datetime')}} :</div>
              <div class="title" v-if="$order.method != 'catering' && inAdvanceEnabled && hasOpenHours">{{$t('order_options.select_hour')}} :</div>
              <div class="title" v-if="$order.method != 'catering' && (!inAdvanceEnabled || !hasOpenHours) && averageDelay > 0">{{$t('order_options.no_select_hour')}} :</div>
              <OrderDateTime :inAdvanceEnabled="inAdvanceEnabled" :hasOpenHours="hasOpenHours" :averageDelay="averageDelay"></OrderDateTime>
            </template>
          </Panel>
        </div>

        <div class="buttons">
          <div class="locale" v-if="!$store.getters.isMobileOrTablet && $store.state.inventory && !$store.state.inventory.isEnglishLocaleOnly()">
            <div class="switcher" :class="{selected: $ts.state.locale == 'fr'}" @click="setLocale('fr')">{{ $t("fr") }}</div>
            <div class="switcher" :class="{selected: $ts.state.locale == 'en'}" @click="setLocale('en')">{{ $t("en") }}</div>
          </div>
          <div class="grow"></div>
          <div class="btn btn-secondary flat login" @click="login" v-if="!$user.token">
            {{$t('login.login')}}
          </div>
          <div class="btn btn-secondary flat login" @click="logout" v-if="$user.token">
            {{$t('navigation.logout')}}
          </div>
          <div class="btn btn-primary flat" :class="{disabled: !$order.method || !currentCompany || (!$order.hour && hasOpenHours && inAdvanceEnabled && shouldAskForReceptionTime)}"
            @click="close">
            {{$t('confirm')}}
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import moment from "moment";
  import EventBus from "../lib/eventBus";
  import AsyncImage from "@/components/tools/AsyncImage";
  import EnterDisplayMode from "@/components/EnterDisplayMode.vue";
  import OrderMethod from "@/components/orderOptions/OrderMethod.vue";
  import UserLocation from "@/components/orderOptions/UserLocation.vue";
  import OrderCompaniesList from "@/components/orderOptions/OrderCompaniesList.vue";
  import OrderDateTime from "@/components/orderOptions/OrderDateTime.vue";
  import Panel from "@/components/Panel.vue";

  export default {

    name: "orderOptionsModal",

    components: { AsyncImage, Panel, EnterDisplayMode, OrderMethod, UserLocation, OrderCompaniesList, OrderDateTime },

    data() {
      return {
        show: false,
        showAll: false,
        ready: false,
        userLocationSelected: false,
        companies: [],
        currentCompany: null
      };
    },

    async mounted() {
      this.validateIfShouldDisplay();
      EventBus.$on("order-options-modal", () => {
        this.show = true;
      });
      EventBus.$on("close-order-options-modal", () => {
        this.show = false;
      });
      this.ready = true;
    },

    watch: {
      "$store.state.quickMode"() {
        this.validateIfShouldDisplay();
      },
      "$store.state.qrPaymentMode"() {
        this.validateIfShouldDisplay();
      },
      "$store.state.currentBranch"(newValue) {
        this.reinitializeLayoutConfigurations(newValue);
        this.validateIfShouldDisplay();
      },
      "$store.state.currentBranch.companies"() {
        this.validateIfShouldDisplay();
      },
      "show"() {
        if (this.show && !this.currentCompany) {
          this.clearRoute();
        }
      },
      "currentCompany"() {
        if (this.currentCompany == null && this.show) {
          this.clearRoute();
        }
      },
      "$order.method": async function(newValue) {
        if (!this.show) {
          return;
        }
        this.currentCompany = null;

        showSpinner();
        await this.getCompanies();
        hideSpinner();

        if (this.$order.method != "delivery" && this.companies.length === 1) {
          this.setCompany(this.companies[0]);
        } else {
          this.currentCompany = null;
        }
        this.showAll = false;
        this.userLocationSelected = false;
        let panelMethod = this.$refs.panelMethod;
        let panelAbout = this.$refs.panelAbout;
        if (panelMethod) {
          panelMethod.close();
        }
        if (panelAbout) {
          panelAbout.close();
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        if (this.companies.length == 0) {
          return null;
        }
        return this.currentCompany || this.companies[0];
      },
      shouldAskForReceptionTime() {
        return (typeof this.$order.paymentOnly === "boolean" && !this.$order.paymentOnly) || !this.hasPaymentAfterPickupEnabled;
      },
      hasPaymentAfterPickupEnabled() {
        if (this.$order.method !== "takeout") {
          return false;
        }
        return this.company && this.company.hasPaymentAfterPickupEnabled();
      },
      panelLocationTitle() {
        if (!this.ready) {
          return;
        }
        let baseTitle = this.$t("title.orderLocation");
        let panel = this.$refs.panelLocation;
        if (this.$user.getDefaultAddress().fullAddress && panel && panel.isCollapsed) {
          return baseTitle + "<span class='user-title-address'>" + this.$user.getDefaultAddress().getFormattedAddressWithApt() + "</span>";
        }
        return baseTitle;
      },
      panelMethodTitle() {
        if (!this.ready) {
          return;
        }
        let baseTitle = this.$t("order.title_type");
        let panel = this.$refs.panelMethod;

        if (this.$order.method && panel && panel.isCollapsed) {
          return baseTitle + "<span class='user-title-method'>" + this.$t(this.$order.method) + "</span>";
        }

        return baseTitle;
      },
      inAdvanceEnabled() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return false;
        }
        return this.company.getOpenHours(this.orderMethod).inAdvance || this.orderMethod == "catering";
      },
      averageDelay() {
        if (!this.company || !this.company.getOpenHours(this.orderMethod)) {
          return 0;
        }
        return this.company.getOpenHours(this.orderMethod).averageDelay;
      },
      hasOpenHours() {
        return this.company && this.company.getAvailableOpenHours(this.orderMethod).filter(o => o != "delay").length > 0;
      },
      showCompanies() {
        if (this.showAll) {
          return false;
        }
        if (this.$order.method == "delivery" && !this.userLocationSelected) {
          return true;
        }
        return this.$order.method == "delivery"
          ? (this.branch && this.branch.hasZones() ? this.$user.getDefaultAddress().isNew() : !this.$user.getDefaultAddress().postalCode)
          : !this.$order.method;
      },
      modalImage() {
        if (!this.branch || !this.branch.modalOrderTypeImage) {
          return null;
        }
        return CONFIG.urlFileServer + this.branch.modalOrderTypeImage.url;
      }
    },

    methods: {
      ...mapActions({
        setCurrentCompany: "setCurrentCompany"
      }),

      login() {
        EventBus.$emit("show-authentication-modal");
      },

      logout() {
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
      },

      reinitializeLayoutConfigurations(branch) {
        this.$store.state.quickMode = branch ? branch.styleMode === "quick" : false;
        this.$store.state.ticketMode = branch ? branch.areTicketsActivated() : false;
        this.$store.state.quickAdd = branch ? branch.quickAdd : false;
        this.$store.state.interactiveMode = branch ? branch.interactiveMode : false;
      },

      clearRoute() {
        if (!this.show) {
          return;
        }
        this.$router.replace("/" + this.$router.currentRoute.params.branch + "/menu");
      },

      rebuildRoute() {
        this.$router.replace(this.$router.generate(this.$store.state.ticketMode ? "/tickets" : "/menu"));
      },

      async getCompanies() {
        if (!this.branch) {
          this.companies = [];
          return;
        }
        showSpinner();
        let companies = this.branch.getCompaniesForMethod(this.$order.method, (!this.showAll ? this.$user.getDefaultAddress() : null));
        companies = await this.$order.filterCompaniesForDoorDashDelivery(companies);
        hideSpinner();
        if (this.currentCompany && companies.indexOf(this.currentCompany) > -1) {
          this.companies = [this.currentCompany];
          return;
        }
        this.companies = companies;
      },

      async setCompany(company, force) {
        if (!this.$store.state.quickMode) {
          return;
        }
        if (this.currentCompany == company && !force) {
          this.currentCompany = null;
        } else {
          await this.$store.dispatch("getStatsPerIntervalForCompany", { branchId: this.branch.id, company: company, method: this.$order.method });
          this.currentCompany = company;
          // Rapraph: Weird even was seen with this
          // Marie: mais ca fait un bug avec les heures d'ouverture si on le laisse pas. J'ai amélioré la condition.

          if (!company.hasOrderMethod(this.$order.method) && company.hasThirdPartyDelivery()) {
            EventBus.$emit("show-third-party-delivery-modal", company);
            this.currentCompany = null;
            return;
          }

          if (this.$store.state.currentCompany != company) {
            this.$store.dispatch("setCurrentCompany", company);
          } else {
            company.checkIfClosedOrInAdvance();
          }
          if (!this.$router.currentRoute.params.company) {
            this.$router.replace(this.$router.generate("/menu"));
          }
          this.rebuildRoute();
        }
      },

      async userLocationChanged() {
        this.currentCompany = null;
        showSpinner();
        await this.getCompanies();
        hideSpinner();
        this.$nextTick(() => {
          if (this.companies.length > 0) {
            this.$refs.panelLocation.collapse();
          }
          if (this.companies.length == 1) {
            this.setCompany(this.companies[0], true);
          } else {
            this.currentCompany = null;
            this.showAll = false;
          }
          this.userLocationSelected = true;
        });
      },

      async customCompanyRequested() {
        this.showAll = true;
        await this.getCompanies();
        this.$nextTick(() => {
          this.$refs.panelLocation.collapse();
        });
      },

      validateIfShouldDisplay() {
        let routesWithBypass = [
          "tutorial", "registerConfirmation", "registerConfirmationNoCompany", "giftCardPurchase", "giftCardView",
          "newPasswordNoCompany", "newPassword", "resetPassword", "resetPasswordNoCompany", "giftCardPrint",
          "cookiesPolicy", "cookiesPolicyNoCompany", "cookiesPolicyNoBranchNoCompany", "qrPaymentError", "qrPaymentUnavailable",
          "qrPaymentHome", "qrPaymentInvoices", "qrPaymentPayment", "qrPaymentConfirmation", "qrPaymentMenuDisplay", "qrPaymentCheckReload"
        ];
        let atTheTableWithTableNumberSet = this.$route.query.table && this.$route.params.company && this.$order.method == "inPlace";
        let roomServiceWitRoomNumberSet = this.$route.query.room && this.$route.params.company && this.$order.method == "hotel";
        let methodSetFromQuery = this.$route.query.method;

        if (!this.$store.state.quickMode ||
            this.$store.state.ticketMode ||
            this.$store.state.qrPaymentMode ||
            atTheTableWithTableNumberSet ||
            roomServiceWitRoomNumberSet ||
            methodSetFromQuery ||
            routesWithBypass.indexOf(this.$route.name) > -1) {
          this.show = false;
          return;
        }
        this.show = true;
        if (!this.$store.state.currentCompany && this.branch && this.branch.companies.length > 0 && this.branch.companies[0].nameCanonical) {
          this.setCurrentCompany(this.branch.companies[0]);
        }
      },

      async close() {
        if (this.$store.state.currentCompany !== this.currentCompany) {
          this.currentCompany._showSpinner = true;
          await this.setCurrentCompany(this.currentCompany);
          this.currentCompany.showEnterMessageForMethod(this.$order.method);
        } else {
          this.currentCompany.displayRecommenderModal();
        }
        this.currentCompany.displayPromotionModal();
        this.show = false;
      },

      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      }
    }
  };

</script>

<style lang="scss" scoped>

  #iShopFoodApp #orderOptionsModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
  }

  #iShopFoodApp {
    .select.payment-only {
      padding-bottom: 15px;
      font-size: 16px;
    }
    .radios.payment-only {
      margin-bottom: 10px;
      label {
        padding-left: 30px;
        padding-top: 9px;
        font-size: 16px;
        width: 100%;

        &::after {
          left: 10px;
        }
      }
    }
  }

  #iShopFoodApp .modal .content {
    background-color: white;
    display: flex;
    border-radius: 10px;
    height: 820px;
    width: 900px;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    .left {
      width: 300px;
      flex-shrink: 0;
      background-size: cover;
      background-position: right;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .leftImage {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 100%;
      }
    }
    .right {
      padding: 20px;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .big-title {
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid rgba(white, 0.15);
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .svgicon {
        fill: var(--primary-color);
        margin-right: 12px;
      }
    }
  }

  #iShopFoodApp .section {
    &:not(.location) {
      overflow-y: auto;
    }
    &.hide {
      display: none;
    }
    &:not(.grow) {
      flex-shrink: 0;
    }
    &.grow {
      flex-grow: 1;
      overflow-y: hidden;

      .body {
        display: flex;
        flex-direction: column;
      }
    }
    .icon {
      width: 40px;
      .svgicon {
        position: relative;
        top: -1px;
        fill: #bfbfbf;
      }
    }
    .title {
      color: #808080 !important;
      margin-bottom: 10px;
      padding-left: 10px;
      flex-shrink: 0;
      display: flex;
      .grow {
        flex-grow: 1;
        text-align: right;
        padding-right: 140px;
      }
    }
    .body {
      flex-grow: 1;
    }
  }

  #iShopFoodApp .buttons {
    display: flex;
    margin-top: 20px;
    flex-shrink: 0;
    .grow {
      flex-grow: 1;
    }
    .login {
      &:hover {
        box-shadow: none;
      }
    }
    .btn:not(:last-child) {
      margin-right: 10px;
    }
    .locale {
      display: flex;
      align-items: center;
      .switcher {
        color: #808080 !important;
        font-size: 14px;
        cursor: pointer;
      }
      .switcher:not(:last-child):after {
        content: "|";
        font-weight: normal;
        margin: 0 6px;
      }
      .selected {
        font-weight: bold;
      }
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    .svgicon {
      fill: #bfbfbf;
    }
  }

  .mobileTitle {
    display: none;
  }

  #iShopFoodApp .changeLink {
    display: flex;
    cursor: pointer;
    font-size: 12px;
    color: var(--primary-color) !important;
    padding-left: 10px;
  }

  #iShopFoodApp .view-menu {
    margin-top: 30px;
  }

  .panel-holder {
    flex-grow: 1;
    overflow: auto;
  }

</style>

<style lang="scss">

  #orderOptionsModal .vdatetime {
    input {
      width: 100%;
    }
  }

  #iShopFoodApp #orderOptionsModal.modal .content.modal-content .right .logo-holder {
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    .logo {
      height: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 200px;
    }
  }

  .location {
    .input {
      &:not(.grow) {
        margin-right: 0;
      }
      input {
        line-height: normal;
      }
    }
  }

  @media (max-width: 600px) {
    #iShopFoodApp #orderOptionsModal.modal .content.modal-content {
      border-radius: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      font-size: 14px;

      input, select {
        font-size: 14px;
      }

      .right {
        padding: 0;

        .section {
          border-top: 1px solid #e6e6e6;
          border-bottom: none;
          margin-bottom: 0;
          position: relative;

          .input .title {
            padding: 0 0 8px 0;
          }

          &:not(:nth-child(2)) {
            margin-top: 0;
          }
          &.no-shrink {
            flex-shrink: 0;
          }
          &.grow {
            flex-grow: 1;
            overflow-y: hidden;
          }
          &.no-shrink {
            flex-shrink: 0;
          }
          & > .title {
            padding: 15px 20px;
          }
          .title {
            font-size: 12px;
            margin-left: 0;
            color: #808080;
          }
          .mobileTitle {
            display: block;
            font-size: 16px;
            line-height: 1;
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 20px;
            margin-bottom: 15px;
            font-weight: 500;
            padding-left: 35px;
            margin-top: 6px;
          }
          & > .icon {
            position: absolute;
            top: 20px;
            left: 20px;
            .svgicon {
              fill: var(--primary-color);
            }
          }
        }
      }

      .left {
        display: none;
      }
      .right {
        overflow-y: auto;
        margin-bottom: 35px;
      }

      .big-title {
        justify-content: center;
        padding: 20px;
        font-size: 16px;
        margin-bottom: 10px;
        position: relative;
        .svgicon {
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }

      .buttons .btn {
        width: 100%;
        border-radius: 0;
      }

      .section .btn {
        flex-grow: 1;
        font-size: 14px;
      }

      .section {
        flex-shrink: 0;
      }
      .shops .shop {
        margin-right: 0;
        .top {
          font-size: 14px;
        }
      }
      .buttons {
        margin-top: 0;
        position: fixed;
        bottom: 0;
        width: 50%;

        .btn {
          margin-right: 0;
        }
      }

      .close {
        display: none;
      }
    }
  }

  #iShopFoodApp #orderOptionsModal.modal .content.modal-content .big-title {
    position: relative;
    .locale {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      cursor: pointer;
      .svgicon {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }

  @media (min-width: 601px) {
    #iShopFoodApp #orderOptionsModal .section {
      box-shadow: none;
      margin-bottom: 0;
      .title {
        padding: 0 0 20px;
      }
      .content:before {
        display: none;
      }
      .content .container {
        padding-top: 0;
        padding-left: 30px;
      }
      .content .title {
        margin-bottom: 0;
      }

      &.grow:not(.hide) {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;

        &.location {
          overflow: visible;
          .content {
            overflow: visible;
            .container {
              overflow: visible;
            }
          }
        }

        .content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            overflow: auto;

            .shops {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  @media (min-width: 721px) {
     #iShopFoodApp #orderOptionsModal .container .shops {
       max-height: none !important;
     }
  }

  #iShopFoodApp #orderOptionsModal .view-menu {
    .text {
      font-size: 15px;
    }
  }

  .about-description {
    white-space: pre-wrap;
    font-size: 16px;
  }

</style>

<style lang="scss">
  #orderOptionsModal .panel.location,
  #orderOptionsModal .panel.about,
  #orderOptionsModal .panel.method {
    .title {
      .user-title-address, .user-title-method {
        font-size: 15px;
        margin-left: 10px;
        font-weight: normal;
        max-width: 320px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.6;

        &:before {
          content: "|";
          display: inline;
          padding-right: 10px;
          position: relative;
          opacity: 0.4;
        }
      }
      .panel-arrow-icon {
        margin-right: 30px;
        flex-shrink: 0;
      }
    }
    .content {
      overflow: visible;
    }
  }

  #orderOptionsModal .panel {
    .content::before {
      content: none;
    }
  }

  @media screen and (max-width: 720px) {
    #iShopFoodApp #orderOptionsModal .types .type {
      min-height: auto;
    }
  }

  @media screen and (max-width: 900px) {
    #orderOptionsModal .panel.location,
    #orderOptionsModal .panel.about,
    #orderOptionsModal .panel.method {
      .title {
        .user-title-address, .user-title-method {
          font-size: 12px;
          max-width: 200px;
        }
        .panel-arrow-icon {
          margin-right: 0px;
        }
      }
    }
  }
</style>
