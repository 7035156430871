import ReceiptConfiguration from "@/models/pos/ReceiptConfigurationModel";
import RevenueCenter from "@/models/pos/RevenueCenterModel";
import Store from "../store";
import { Constant } from "@/util/Constant";
import Employee from "../models/pos/EmployeeModel";
import { PermissionManager } from "@/util/PermissionManager";

class WorkstationDataUpdater {

  constructor() {
    this.workstationDataMap = new Map();
    this.refreshInterval = null;
    this.refreshDelay = Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY * 60 * 1000;// 5 minutes default
  }

  async init() {
    this.clear();
    let data = await this.getData();
    //If we cant reach the server, still start interval without populating map
    if (data.success && data.data) {
      this.workstationDataMap = new Map(Object.entries(data.data));
    } else {
      console.log("Workstation data init fetch error", data);
    }
    this.refreshDelay = store.state.posConfiguration ? store.state.posConfiguration.getWorkstationDataRefreshDelayInMS() : Constant.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY * 60 * 1000;
    this.startRefreshInterval();
  }

  clear() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.workstationDataMap = new Map();
  }

  async getData() {
    //if offline mode we should skip, and also if we dont have a current branch/company.
    if (!Store.state.currentBranch || !Store.state.currentCompany) {
      return { success: false, data: null , response: "missing branch or company for getData"};
    }
    //make call to get new stamps
    let data = await Store.dispatch("getWorkstationUpdateData", {
      branchId: Store.state.currentBranch.id,
      companyId: Store.state.currentCompany.id
    });
    return data;
  }

  async checkForUpdate() {
    let data = await this.getData();
    let company = Store.state.currentCompany;

    if (data.success && data.data) {
      let toUpdate = this.crossCheckWithCurrentData(data.data);

      if (toUpdate.includes(Constant.COMPANY_ENTITY)) {
        await Store.dispatch("updateCompany", { branchId: Store.state.currentBranch.id, id: company.id, company: Store.state.currentCompany });
      }

      if (toUpdate.includes(Constant.EMPLOYEES_ENTITY)) {
        let response = await Store.dispatch("getEmployees", { company: company.nameCanonical });
        if (response.success) {
          Store.state.employees = response.employees.map(e => new Employee(e));
        }
      }

      if (toUpdate.includes(Constant.POS_ROLES_ENTITY)) {
        let response = await Store.dispatch("getPosRoles", { company: company.nameCanonical });
        if (response.success) {
          PermissionManager.setRolesPermissions(response.data);
        }
      }
      
      if (toUpdate.includes(Constant.REVENUE_CENTER_ENTITY)) {
        let response = await Store.dispatch("getRevenueCenters", { company: company.nameCanonical });
        if (response.success) {
          let revenueCenters = response.data.map(r => new RevenueCenter(r));
          Store.state.inventory.setRevenueCenters(revenueCenters);
        }
      }

      if (toUpdate.includes(Constant.PRINTER_GROUPS_ENTITY)) {
        let printingGroupsData = await Store.dispatch("getPrintingGroups", { company: company.id });
        if (printingGroupsData.success) {
          Store.state.posConfiguration.setPrintingGroups(printingGroupsData.data || []);
          Store.state.posConfiguration.linkPrintingGroupsToInventory(Store.state.inventory);
        }
      }

      if (toUpdate.includes(Constant.POS_STORAGE_DATA_ENTITY)) {
        let [printersData, receiptConfigurationData] = await Promise.all([
          Store.dispatch("getPosDataStorage", { company: company.id, storageKey: "global_printer" }),
          Store.dispatch("getPosDataStorage", { company: company.id, storageKey: "receipt_configuration" })
        ]);
        if (printersData.success) {
          Store.state.posConfiguration.setPrinters(printersData.data ? (printersData.data.printers || []) : []);
          Store.state.posConfiguration.registerPrinters();
        }
        if (receiptConfigurationData.success) {
          Store.state.posConfiguration.receiptConfiguration = new ReceiptConfiguration(receiptConfigurationData.data);
        }
      }

      toUpdate = toUpdate.filter(e => Constant.INVENTORY_ENTITIES.indexOf(e) > -1);
      if (toUpdate.length > 0) {
        await Store.state.inventory.fetch(company.nameCanonical, { executeInBackground: true, entities: toUpdate, persist: true });
        Store.state.posConfiguration.linkPrintingGroupsToInventory(Store.state.inventory);
      }

    } else {
      console.log("Workstation data update fetch error", data);
    }
  }

  crossCheckWithCurrentData(data) {
    let toUpdate = [];
    //Iterate over properties and crosscheck new values with old
    for (const [key, value] of Object.entries(data)) {
      if (this.workstationDataMap.has(key)) {
        let timestamp = this.workstationDataMap.get(key);
        if (timestamp !== value) {
          this.workstationDataMap.set(key, value);
          toUpdate.push(key);
        }
      }
    }
    return toUpdate;
  }

  startRefreshInterval() {
    this.refreshInterval = setInterval(() => {
      this.checkForUpdate();
    }, this.refreshDelay);
  }

  resetRefreshInterval() {
    this.stopRefreshInterval();
    this.refreshDelay = store.state.posConfiguration ? store.state.posConfiguration.getWorkstationDataRefreshDelayInMS() : 300000;
    this.startRefreshInterval();
  }

  stopRefreshInterval() {
    clearInterval(this.refreshInterval);
  }

}

let workstationDataUpdater = new WorkstationDataUpdater();
export { workstationDataUpdater as WorkstationDataUpdater }