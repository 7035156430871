<template>
  <div id="log">
    <div class="log__header">
      <div v-if="log.length > 0">{{ log.length }} {{ $t("lines") }}, {{ $t("from") }} <b>{{ firstDate }}</b> {{ $t("to") }} <b>{{ lastDate }}</b></div>
      <div class="grow"></div>
      <div class="header__actions">
        <button class="btn btn-primary" @click="saveAsCsv">{{ $t("pos_configuration.save_as_csv") }}</button>
        <button class="btn btn-primary" @click="loadLog">{{ $t("refresh") }}</button>
      </div>
    </div>
    <div class="log__container" ref="scroll">
      <table class="log__table">
        <tr>
          <th>{{ $t("time") }}</th>
          <th>{{ $t("type") }}</th>
          <th>{{ $t("message") }}</th>
        </tr>
        <tr class="log__line" :class="item.type" v-for="(item, itemIndex) in log" :key="`log-${itemIndex}`">
          <td class="line__datetime" width="1">{{ item.dateTime }}</td>
          <td class="line__type" width="1">{{ item.type || "log" }}</td>
          <td class="line__message">
            <div class="message__line" v-if="item.message" v-for="(line, lineIndex) in item.message.split('\n')"
              :key="`log-${itemIndex}-line-${lineIndex}`">{{ line }}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import { Configuration } from "@/util/Configuration";
  import { defineComponent } from "@vue/runtime-core";

  export default defineComponent({
    name: "Log",
    data() {
      return {
        log: []
      };
    },
    mounted() {
      this.loadLog();
    },
    computed: {
      firstDate() {
        return this.log.length > 0 ? this.log[0].dateTime : null;
      },
      lastDate() {
        return this.log.length > 0 ? this.log[this.log.length - 1].dateTime : null;
      }
    },
    methods: {
      loadLog() {
        let log = Configuration.get("log");
        let split = log.split("\n").filter(item => item.length > 0);
        this.log = [];
        for (let item of split) {
          let firstBracket = item.indexOf("]");
          if (firstBracket === -1) {
            let lastLog = this.log[this.log.length - 1];
            if (lastLog) {
              lastLog.message += "\n" + item;
              continue;
            }
            this.log.push({
              dateTime: null,
              type: null,
              message: item
            });
            continue;
          }
          let split = [item.substring(0, firstBracket), item.substring(firstBracket + 1)];
          let dateTimeType = split[0].substring(1).split(",");
          let dateTime = moment(parseInt(dateTimeType[0]));
          let type = dateTimeType[1];
          let message = split[1].substring(1);
          this.log.push({
            dateTime: dateTime && dateTime.isValid() ? dateTime.format("YYYY-MM-DD HH:mm:ss") : null,
            type,
            message
          });
        }
        setTimeout(() => {
          this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
        }, 0);
      },
      saveAsCsv() {
        let csv = "data:text/csv;charset=utf-8,";
        csv += "Date,Type,Message\r\n";
        for (let item of this.log) {
          csv += `${item.dateTime},${item.type || "log"},"${item.message.replace(/"/g, "\"\"")}"\r\n`;
        }
        let encodedUri = encodeURI(csv).replace(/#/g, "%23");
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "log.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  });

</script>

<style lang="scss" scoped>
  .log__header {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    .grow {
      flex-grow: 1;
    }
    .header__actions {
      display: flex;
      .btn {
        margin-left: 10px;
      }
    }
  }
  .log__container {
    overflow-y: scroll;
    padding: 0 30px 30px;
  }
  .log__table {
    width: 100%;
    border-collapse: collapse;
    tr:first-child th {
      position: sticky;
      top: 0;
    }
    th {
      padding: 4px 8px;
      background-color: #ccc;
    }
  }
  .log__line {
    &:nth-child(odd) {
      background-color: #ddd;
      &.warn {
        background-color: #ffc;
      }
      &.error {
        background-color: #fcc;
      }
    }
    &.warn {
      background-color: #ffd;
      .line__datetime, .line__type {
        border-color: #eea;
        font-weight: bold;
      }
    }
    &.error {
      background-color: #fdd;
      .line__datetime, .line__type {
        border-color: #fbb;
        font-weight: bold;
      }
    }
  }
  .line__datetime, .line__type, .line__message {
    padding: 5px 8px;
  }
  .line__datetime {
    white-space: nowrap;
    font-weight: bold;
    border-right: 1px solid #ccc;
  }
  .line__type {
    text-align: center;
    border-right: 1px solid #ccc;
  }
</style>