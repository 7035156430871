<template>
  <div id="posIntroModal" class="modal" :class="{show}">
    <div class="modal-content" v-if="currentSlide">
      <div class="image" :style="{backgroundImage: 'url(\'' + currentSlide.image + '\')'}"></div>
      <div class="title">
        <svgicon :src="currentSlide.icon" class="title__icon" :height="30" :width="30"></svgicon>
        <div class="title__text">{{currentSlide.title}}</div>
      </div>
      <div class="description">{{currentSlide.description}}</div>
      <div class="buttons">
        <template v-if="!isLastSlide">
          <button class="btn btn-secondary" @click="skip">
            <svgicon src="icons/skip.svg" :height="20" :width="20"></svgicon>
            {{$t('pos_intro.skip')}}
          </button>
          <button class="btn btn-primary" v-wave @click="nextSlide">
            <svgicon src="icons/arrow-line-right.svg" :height="20" :width="20"></svgicon>
            {{$t('pos_intro.next')}} ({{currentSlideIndex + 1}}/{{slides.length}})
          </button>
        </template>
        <template v-if="isLastSlide">
          <span>&nbsp;</span>
          <button class="btn btn-primary" v-wave @click="skip">
            <svgicon src="icons/arrow-line-right.svg" :height="20" :width="20"></svgicon>
            {{$t('pos_intro.go_to_pos')}}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "posIntroModal",

    data() {
      return {
        show: false,
        currentSlideIndex: 0
      };
    },

    mounted() {
      EventBus.$on("show-pos-intro-modal", () => {
        this.show = true;
      });
    },

    computed: {
      currentSlide() {
        return this.slides[this.currentSlideIndex];
      },
      isLastSlide() {
        return this.currentSlideIndex === (this.slides.length - 1);
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      slides() {
        return [
          {
            image: "/img/marketing/intro/1.jpg",
            icon: "icons/launch.svg",
            title: this.$t("pos_intro.1.title"),
            description: this.$t("pos_intro.1.description")
          }, {
            image: "/img/marketing/intro/2.jpg",
            icon: "icons/tutorial.svg",
            title: this.$t("pos_intro.2.title"),
            description: this.$t("pos_intro.2.description")
          }, {
            image: "/img/marketing/intro/3.jpg",
            icon: "icons/tips.svg",
            title: this.$t("pos_intro.3.title"),
            description: this.$t("pos_intro.3.description")
          }
        ];
      }
    },

    methods: {
      skip() {
        this.show = false;

        if (!this.posConfiguration.hasPrinterConfigured()) {
          setTimeout(() => {
            EventBus.$emit("show-printer-configuration-modal");
          }, 500);
        }
      },
      nextSlide() {
        if (!this.isLastSlide) {
          this.currentSlideIndex++;
        }
      }
    },

    beforeDestroy() {
      EventBus.$off("show-pos-intro-modal");
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posIntroModal {
    .modal-content {
      border-radius: 40px;
      box-shadow: 0px 40px 80px #00000040;
      padding: 10px;
      max-width: 900px;
      height: 700px;
      display: flex;
      flex-direction: column;
    }
  }
  .image {
    height: 260px;
    width: 100%;
    border-radius:30px 30px 10px 10px;
    margin-bottom: 40px;
    background-position: center;
    background-size: cover;
  }
  .title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color-4);
    margin: 0 30px 40px 30px;
  }
  .title__icon {
    fill: var(--primary-color);
    margin-right: 20px;
  }
  .title__text {
    color: var(--theme-color);
    font-size: 24px;
    font-weight: 900;
  }
  .description {
    flex-grow: 1;
    margin: 0 30px;
    font-size: 20px;
    font-weight: 500;
    color: var(--theme-color);
  }
  #iShopFoodApp .buttons {
    margin: 40px 30px 30px 30px;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    border-top: 1px dashed var(--border-color-4);

    .btn {
      border-radius: 10px;
      padding: 20px;
      font-size: 20px;
      font-weight: bold;

      .svgicon {
        margin-right: 20px;
      }
    }

  }
</style>