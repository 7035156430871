<template>
  <div id="paymentInvoiceCart">
    <div class="invoice__scroll" v-if="table">

      <InvoiceCartTotal :total="total" class="invoice__top"></InvoiceCartTotal>

      <div class="invoice__info" v-for="(payment, paymentIndex) of payments" :key="'payment-' + paymentIndex" @click="removePayment(payment)">
        <div class="info__image">
          <img class="image" :src="paymentURL(payment.method)" :class="{method__svg_large: shouldBeSvg(payment.method)}" :alt="payment.method">
        </div>
        <div class="info__content">
          <label class="content__type">{{$t('pos_payment.payment')}}</label>
          <div class="content__inner">
            <div class="content__name">{{$t('pos_payment.' + getPaymentName(payment))}}</div>
            <div class="content__value">{{$tc(payment.amount.toFixed(2))}}</div>
          </div>
        </div>
        <div class="info__action">
          <button class="btn btn-secondary" v-wave>
            <svgicon src="icons/trash-full.svg" :height="25" :width="25"></svgicon>
          </button>
        </div>
      </div>

      <div class="invoice__info" v-for="promotion of appliedPromotions"
           :key="`sale-promo-${promotion.id}`">
        <div class="info__image promotion">
          <svgicon src="icons/marketing.svg" :width="24" :height="24"></svgicon>
        </div>
        <div class="info__content">
          <label class="content__type">{{ $t("promotion.title") }}</label>
          <div class="content__inner">
            <div class="content__name">{{ $to(promotion.name) }}</div>
            <div class="content__value" v-if="promotion.type === 'flat'">{{ $tc(-promotion.value) }}</div>
            <div class="content__value" v-if="promotion.type === 'percent'">{{ -promotion.value }}%</div>
          </div>
        </div>
        <div class="info__action">
          <button class="btn btn-secondary" v-wave @click="table.promotionVisitor.unapplyPromotion(promotion.id)">
            <svgicon src="icons/trash-full.svg" :height="25" :width="25"></svgicon>
          </button>
        </div>
      </div>

    </div>

    <div class="invoice__footer-holder">
      <div class="invoice__footer">
        <div class="footer__top">
          <div class="total">
            <label class="total__label">{{$t('payment.total_remaining')}}</label>
            <div class="total__amount">
              <span class="sign before" v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{$ts.getters.getSymbol}}</span>
              <div class="amount__value">{{ total.totalRemaining.toFixed(2) }}</div>
              <span class="sign after" v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{$ts.getters.getSymbol}}</span>
            </div>
          </div>
          <button class="btn btn-primary" v-wave @click="submitPayment" :class="{disabled: total.totalRemaining > 0 || isOffline}">
            <svgicon src="icons/send.svg" :height="20" :width="20"></svgicon>
            {{$t('pos.pay_print')}}
          </button>
        </div>
        <div class="footer__bottom">
          <button class="btn btn-secondary btn--action" v-wave @click="action.action()"
                  v-for="(action, actionIndex) of displayedFooterActions" :key="'footer-action-' + actionIndex">
            {{ action.name }}
          </button>
          <button class="btn btn-secondary" v-if="footerActions.length > 4" v-wave>
            <svgicon src="icons/dots.svg" :height="20" :width="20"></svgicon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import InvoiceCartTotal from "./InvoiceCartTotal";

  export default {
    name: "paymentInvoiceCart",

    components: { InvoiceCartTotal },

    props: {
      table: Object
    },

    data() {
      return {
        activeTabIndex: 0,
        currentPanel: "payment",
        footerActions: [
          { name: this.$t("pos.back_to_order"), action: () => this.goToMenu() },
          { name: this.$t("pos.exit"), action: () => this.goToDashboard() }
        ]
      };
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      total() {
        return this.table.getTotal();
      },
      displayedFooterActions() {
        return this.footerActions.slice(0, 4);
      },
      payments() {
        return this.table.payments;
      },
      promotions() {
        return this.table.promotionVisitor.getSalePromotions();
      },
      appliedPromotions() {
        return this.table.promotionVisitor.getAppliedSalePromotions();
      },
      isDarkMode() {
        return this.$store.state.posConfiguration.darkTheme;
      },
      isOffline() {
        return this.$store.state.offline;
      }
    },

    methods: {
      paymentURL(method) {
        if (this.isDarkMode) {
          if (method === "cash" || method === "payfacto") {
            return "icons/Payment-" + method + "-" + this.$store.state.posConfiguration.getColorName() + ".svg";
          }
        }
        return "/img/payment-method/" + method + ".png";
      },

      shouldBeSvg(method) {
        if (this.isDarkMode) {
          if (method === "cash" || method === "payfacto") {
            return true;
          }
        }
        return false;
      },
      removePayment(payment) {
        this.table.removePayment(payment);
      },
      goToDashboard() {
        this.$router.push(this.$router.generate("/pos/dashboard"));
      },
      goToMenu() {
        this.$router.push(this.$router.generate("/menu"));
      },
      getPaymentName(payment) {
        if (payment.method === "online") {
          return payment.processor;
        }
        return payment.method;
      },
      async submitPayment() {
        this.$emit("submitPayment");
      }
    }
  };
</script>

<style scoped lang="scss">
  #paymentInvoiceCart {
    width: 767px;
    padding: 30px;
    flex-shrink: 0;
    border-right: 1px dashed var(--border-color);
    display: flex;
    flex-direction: column;

    &.left {
      border-left: 1px dashed var(--border-color);
      border-right: none;
    }
  }

  .invoice__scroll {
    overflow-y: auto;
    flex-grow: 1;
  }

  .invoice__info {
    .info__image {
      border-radius: 10px;
      background-color: var(--background-color-6);
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #dbdbdb;
      width: 100px;
      margin-right: 20px;
      flex-shrink: 0;

      &.promotion {
        padding: 16px;
      }

      .image {
        height: auto;
        max-height: 25px;
        width: auto;
        max-width: 65px;
      }
    }
    .info__content {
      flex-grow: 1;
      margin-right: 20px;

      .content__inner {
        display: flex;
        justify-content: space-between;
      }
      .content__type {
        color: var(--theme-color-2);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        display: block;
      }
      .content__name, .content__value {
        font-weight: 900;
        font-size: 26px;
        color: var(--theme-color);
      }
    }
    .info__action {
      .btn {
        height: 56px;
        width: 56px;
        padding: 10px;
      }
    }
  }

  .invoice__top, .invoice__info, .invoice__footer {
    background-color: var(--background-color-2);
    display: flex;
    border-radius: 20px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .invoice__footer {
    padding: 20px;
  }

  .invoice__footer-holder {
    padding-top: 20px;
    border-top: 1px solid var(--border-color);
    margin-top: 20px;
    flex-shrink: 0;
  }

  .invoice__footer {
    flex-direction: column;
  }

  .footer__bottom {
    display: flex;
    justify-content: space-between;

    .btn--action {
      min-width: 143px;
      min-height: 52px;
      flex-grow: 1;
      flex-basis: 0;
      font-weight: 500;
      font-family: inherit;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .footer__top {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--border-color-4);

    .btn {
      min-width: 200px;
      font-family: inherit;
      font-size: 22px;
      font-weight: 700;
      line-height: 1;
      padding: 15px 30px;
      .svgicon {
        margin-right: 30px;
      }
    }

    .total {
      flex-grow: 1;
      border-radius: 10px;
      padding: 11px 15px;
      border: 1px solid var(--border-color-4);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-right: 10px;

      .total__amount {
        font-weight: 900;
        font-size: 32px;
        display: flex;
        line-height: 1;
      }

      .total__label {
        color: var(--theme-color-2);
        font-size: 20px;
        font-weight: 500;
      }

      .amount__value {
        color: var(--theme-color);
      }

      .sign {
        color: #bfbfbf;

        &.before {
          margin-right: 5px;
        }
        &.after {
          margin-left: 5px;
        }
      }
    }
  }
</style>