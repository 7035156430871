<template>
  <div id="menu" :class="{hasBranding: inventory.isBrandingActivated()}">

    <MenuHeader v-if="!$pos"></MenuHeader>
    <Categories v-if="!isKioskOrPos"></Categories>

    <div class="page-content" :class="{kiosk: $kiosk, pos: $pos}">
      <ValitorCardLogos class="panel" v-if="$store.getters.isMobileOrTablet"></ValitorCardLogos>

      <template v-if="shouldDisplayRegularMenu">
        <template v-if="isKioskOrPos">
          <Categories @changed="categoryChanged" v-if="!isPos || (isPos && isLeftHandedMode)"></Categories>
          <div class="activePromotion" v-if="currentPromotion">
            <div class="activePromotion__title">{{ $t("promotion.title") }} : {{ $to(currentPromotion.name) }}</div>
            <div class="activePromotion__description">{{ $to(currentPromotion.description) }}</div>
          </div>
          <StyleClassic :items="itemsToShow" @selectItem="selectItem($event)"></StyleClassic>
          <Categories @changed="categoryChanged" v-if="isPos && !isLeftHandedMode" class="border-left"></Categories>
        </template>
        <template v-if="!isKioskOrPos">
          <template v-if="inventory.isBrandingActivated()">
            <div class="branding__back" @click="resetBranding">
              <svgicon src="icons/arrow-left.svg" :height="14" :width="10"></svgicon>
              {{$t('branding.back')}}
            </div>
          </template>
          <template v-if="branch && (!branch.styleMode || branch.styleMode === 'classic')">
            <StyleClassic v-if="!listMode" :items="itemsToShow" @selectItem="selectItem($event)"></StyleClassic>
            <StyleList v-if="listMode" :items="itemsToShow" @selectItem="selectItem($event)"></StyleList>
          </template>

          <template v-if="branch && branch.styleMode === 'quick'">
            <StyleQuick :items="itemsToShow" @selectItem="selectItem($event)" :listMode="listMode" :branding="$order.branding"></StyleQuick>
          </template>
        </template>
      </template>

      <template v-if="shouldDisplayBrandingSelection">
        <BrandingSelection></BrandingSelection>
      </template>

      <template v-if="shouldDisplayGrabngoMessage">
        <GrabngoMessage></GrabngoMessage>
      </template>

    </div>

    <QuickAddModal ref="quickAddModal" :item="quickAddItem" :show="showQuickAddModal" @close="closeQuickAddModal"></QuickAddModal>

    <HistoryBack></HistoryBack>

    <KioskCart @toggle="kioskCartToggled" v-if="$kiosk && !horizontal && ((!inventory.isBrandingActivated() || $order.branding) || isKioskGrabngoActivated)" :collapsed="orderIsEmpty"></KioskCart>

    <template v-if="$kiosk">
      <PromoKeyboardModal></PromoKeyboardModal>
    </template>

  </div>
</template>

<script>
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";
  import BrandingSelection from "@/components/menu/BrandingSelection.vue";
  import Categories from "@/components/menu/Categories.vue";
  import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
  import EventBus from "../lib/eventBus";
  import GrabngoMessage from "@/components/kiosk/GrabngoMessage";
  import KioskCart from "@/components/kiosk/KioskCart.vue";
  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import PromoKeyboardModal from "@/components/kiosk/PromoKeyboardModal";
  import QuickAddModal from "@/components/menu/QuickAddModal.vue";
  import StyleClassic from "@/components/menu/StyleClassic.vue";
  import StyleList from "@/components/menu/StyleList.vue";
  import StyleQuick from "@/components/menu/StyleQuick.vue";
  import ValitorCardLogos from "@/components/ValitorCardLogos";
  import { mapActions } from "vuex";

  export default {

    name: "Menu",

    components: {
      MenuHeader,
      StyleClassic,
      StyleList,
      StyleQuick,
      QuickAddModal,
      Categories,
      KioskCart,
      BrandingSelection,
      PromoKeyboardModal,
      GrabngoMessage,
      ValitorCardLogos
    },

    data() {
      return {
        categories: [],
        selectedItem: null,
        quickAddItem: null,
        scanString: "",
        scanTimeout: null,
        lastScanTimestamp: 0,
        showQuickAddModal: false,
        currentPromotion: null
      };
    },

    mounted() {
      this.showStandbyOrMenuComplete();
      this.$store.commit("showNavigationCart", true);
      this.checkIfSkippedOrderType();
      this.getItems();
      if (this.$store.getters.isMobileOrTablet) {
        this.scrollToActiveCategory();
      }
      if (this.$kiosk && !this.$order.method) {
        this.$router.push(this.$router.generate("/kiosk/method"));
      }
      if (this.$store.state.ticketMode) {
        this.$router.push(this.$router.generate("/tickets"));
      }
      this.initFirstLaunchEvent();
      this.checkIfShouldDisplayPromotionModal();
      this.initAirxTouchDisplayLayout();
      this.setupKioskScan();
    },

    beforeDestroy() {
      if (this.$kiosk && this.kioskConfiguration && this.kioskConfiguration.allowProductScan()) {
        document.removeEventListener("keypress", this.scanProduct);
        clearTimeout(this.scanTimeout);
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      inventory() {
        return this.$store.state.inventory;
      },
      displayMode() {
        return this.$store.state.displayMode;
      },
      isKioskOrPos() {
        return this.$kiosk || this.$pos;
      },
      isPos() {
        return this.$pos;
      },
      table() {
        return this.$store.state.table;
      },
      horizontal() {
        return this.kioskConfiguration && this.kioskConfiguration.isHorizontal();
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      orderIsEmpty() {
        return this.$order.items.length === 0;
      },
      orderMethod() {
        return this.$order.method;
      },
      isKioskGrabngoActivated() {
        return this.kioskConfiguration && this.kioskConfiguration.isGrabngoActivated();
      },
      shouldDisplayRegularMenu() {
        return (!this.inventory.isBrandingActivated() || this.$order.branding) && (!this.isKioskGrabngoActivated);
      },
      shouldDisplayBrandingSelection() {
        return this.inventory.isBrandingActivated() && !this.$order.branding && !this.isKioskGrabngoActivated;
      },
      shouldDisplayGrabngoMessage() {
        return this.isKioskGrabngoActivated;
      },
      searchString() {
        return this.$store.state.inventorySearchString;
      },
      itemsToShow() {
        if (!this.selectedItem) {
          return [];
        }
        if (this.searchString && this.searchString.length > 2) {
          return this.inventory.searchByName(this.searchString);
        }
        // single category child items
        if (this.selectedItem.items) {
          let items = this.selectedItem.items;
          if (this.$kiosk && this.kioskConfiguration && this.kioskConfiguration.hasScan()) {
            items = items.filter(i => !i.isAvailableForScanOnly());
          }
          return items;
        }
        // root items (categories)
        let root = this.selectedItem.filter(c => c.isAvailable(this.$order.date, this.$order.hour));
        if (this.$order.branding) {
          root = root.filter(c => c.branding === this.$order.branding.id);
        }
        return root;

      },
      listMode() {
        if (this.$user && this.$user.isDispatchUser()) {
          return true;
        }
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.style) {
          return false;
        }
        let categoryMode = !this.$route.params.id;
        let listMode = this.$store.state.currentBranch.style.listMode;
        if (!listMode) {
          return false;
        }
        return listMode[categoryMode && this.branch.styleMode === "classic" ? "category" : "item"][this.$store.getters.isMobile ? "mobile" : "desktop"];
      },
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      isCompanyPriceEncodedInUpcActive() {
        return this.$store.state.currentCompany && this.$store.state.currentCompany.isPriceEncodedInUpcActive();
      }
    },

    watch: {
      "$route"(to, from) {
        this.getItems();
      },
      //TODO merge with App.vue watch on currentCompany
      "$store.state.currentCompany"(newCompany, oldCompany) {
        if (newCompany) {
          this.setMethodIfDisplayMode();
          if (!this.$store.state.quickMode && (!oldCompany || (newCompany.id !== oldCompany.id))) {
            newCompany.displayPromotionModal();
          }
        }
      },
      "$store.state.inventory.root"(to, from) {
        this.getItems();
      },
      "selectedItem"() {
        EventBus.$emit("document.title", this.selectedItem ? this.selectedItem.name : null);
        if (this.selectedItem && this.selectedItem.name) {
          this.$analytics.fbq.event("ViewContent", {
            content_category: this.selectedItem.name.en ? this.selectedItem.name.en : this.selectedItem.name.fr
          });
        }
      },
      "$order.branding"() {
        this.initAirxTouchDisplayLayout();
      }
    },

    methods: {
      ...mapActions({
        getItemAction: "getItem"
      }),
      resetBranding() {
        this.$order.resetBranding();
        this.initAirxTouchDisplayLayout();
        if (!this.$store.state.quickMode) {
          this.$router.push(this.$router.generate("/menu"));
        }
      },
      showStandbyOrMenuComplete() {
        if (!this.$pos || !this.company) {
          return;
        }
        if (!this.table || !this.table.hasItems()) {
          CustomerScreenLoader.switchToStandby(this.company.getPosStandbyImageUrl());
        } else {
          CustomerScreenLoader.switchToMenuComplete(this.table, this.company.getPosOrderingBannerUrl());
        }
      },
      categoryChanged() {
        if (!this.$kiosk) {
          return;
        }
        if (document.getElementById("styleClassic")) {
          document.getElementById("styleClassic").scrollTop = 0;
        }
      },
      kioskCartToggled() {
        this.initAirxTouchDisplayLayout();
      },
      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            let layouts = [];
            if (document.getElementById("menu")) {
              layouts.push(GetElementBounds("menu", 1, AIRxTouchDefaultLayoutSetting.DragDrop)); // area : Whole page
            }
            if (document.getElementById("rootContainer")) {
              layouts.push(GetElementBounds("rootContainer", 1, AIRxTouchDefaultLayoutSetting.ScrollView)); // area : Categories
            }
            if (document.getElementById("styleClassic")) {
              layouts.push(GetElementBounds("styleClassic", 2, AIRxTouchDefaultLayoutSetting.DragDrop)); //  area : Items
            }
            if (layouts.length > 0) {
              window.airxtouch__submitLayout(layouts);
            }
          });
        }
      },
      setupKioskScan() {
        if (!this.kioskConfiguration || (this.kioskConfiguration && !this.kioskConfiguration.allowProductScan())) {
          return;
        }
        document.addEventListener("keypress", this.scanProduct);
      },
      scanProduct(e) {
        const DELAY = this.kioskConfiguration.getScannerDelay(); //ms
        let time = (new Date()).getTime();
        if (time > this.lastScanTimestamp + DELAY) {
          this.scanString = "";
        }
        this.lastScanTimestamp = time;
        if (e.key.length === 1) {
          this.scanString += e.key;
        }
        if (this.scanTimeout) {
          clearTimeout(this.scanTimeout);
        }
        this.scanTimeout = setTimeout(() => {
          if (this.scanString.length >= 3) {
            let parsedScanString = this.scanString.replace(/[^a-zA-Z0-9]/g, "");
            let item = null;
            let upcSku = null;
            if (this.isCompanyPriceEncodedInUpcActive) {
              upcSku = this.$store.state.currentCompany.getSkuFromPriceEncodedUpc(parsedScanString);
              item = this.inventory.searchWithUPC(parsedScanString, upcSku);
            } else {
              item = this.inventory.searchWithUPC(parsedScanString);
            }

            if (item && (item.getAllChoices().length > 0 || (item.isPriceEncodedInUpc() && !this.isCompanyPriceEncodedInUpcActive))) { // Items with combo are not supported with scan
              item = null;
            }
            if (item) {
              if (item.isPriceEncodedInUpc()) {
                let upcPrice = this.$store.state.currentCompany.getPriceFromPriceEncodedUpc(parsedScanString);
                if (!upcPrice) {
                  showAffirmation(this.$t("grabngo.not_found_title"), this.$t("grabngo.not_found").replace("{NUM}"));
                  return;
                }
                item.setPriceOverride(upcPrice);
              }
              //on valide si le scan est balance avec le field dans le produit, si c'est le cas on parse le prix du code et le set dans le produit.
              this.$order.addItem(item);
            } else {
              showAffirmation(this.$t("grabngo.not_found_title"), this.$t("grabngo.not_found").replace("{NUM}"));
            }
          }
        }, DELAY);
      },
      checkIfShouldDisplayPromotionModal() {
        if ((!this.$store.state.quickMode || this.$kiosk) && this.$store.state.currentCompany) {
          this.$store.state.currentCompany.displayPromotionModal();
        }
      },

      async getItems() {
        if (this.$route.name === "menuWithPromoId") {
          return this.getItemsFromPromo();
        } else {
          this.clearCurrentPromotion();
        }
        return this.getItemsFromCategory();
      },

      clearCurrentPromotion() {
        this.currentPromotion = null;
        this.selectedItem = null;
      },

      async getItemsFromPromo() {
        if (!this.table || !this.table.promotionVisitor) {
          return;
        }
        this.currentPromotion = this.table.promotionVisitor.getPromotion(this.$route.params.id);
        this.selectedItem = { items: this.table.promotionVisitor.getItemsForPromotion(this.$store.state.inventory.getAllItems(), this.currentPromotion) };
      },

      async getItemsFromCategory() {
        if (this.$route.params.id) {
          EventBus.$emit("show-navigation-categories");
        }
        showSpinner();
        this.categories = await this.getItemAction(this.$route.params.id);
        hideSpinner();
        if (!this.categories) {
          this.$router.push(this.$router.generate("/menu"));
        }
        this.selectedItem = this.categories;
        if (document.getElementsByClassName("page-content")[0]) {
          document.getElementsByClassName("page-content")[0].scrollTop = 0;
        }
      },

      selectItem(item) {
        /* Online ordering quick add */
        if (this.$store.state.currentBranch.quickAdd) {
          if (item.isCustomizable() || this.branch.shouldForceQuickAddModal()) {
            this.quickAddItem = item;
            this.showQuickAddModal = true;
          } else {
            this.quickAddItem = null;
            this.$order.addItem(item);
            if (!this.$store.getters.isMobileOrTablet) {
              window.scrollToEndOfCart();
            }
          }
          return;
        }
        /* POS quick add */
        if (this.$pos && this.posConfiguration && this.posConfiguration.quickAdd && !item.isCustomizable()) {
          if (this.$store.state.quantityBuffer !== null) {
            let quantity = parseInt(this.$store.state.quantityBuffer);
            item.quantity = quantity < 1 ? 1 : quantity;
            this.clearQuantityBuffer();
          }
          if (this.table) {
            this.table.addItemToTable(item, this.$store.state.currentSeat);
            CustomerScreenLoader.switchToMenuComplete(this.table, this.company.getPosOrderingBannerUrl());
            this.$nextTick(() => {
              window.scrollToEndOfCart();
            });
          }
          return;
        }

        let route;
        if (item.items) {
          route = this.$router.generate("/menu/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + item.id);
        } else if (this.$route.name === "menuWithPromoId") {
          // If the item has a category, we use it to generate the route
          if (item.categories && item.categories.length > 0) {
            route = this.$router.generate(`/item/${item.categories[0].id}-${item.id}`);
          } else {
            route = this.$router.generate(`/item/${item.id}`);
          }
        } else {
          route = this.$router.generate("/item/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + item.id);
        }
        if (this.$pos && this.posConfiguration && this.$store.state.quantityBuffer) {
          route += "?quantity=" + this.$store.state.quantityBuffer;
          this.clearQuantityBuffer();
        }
        this.$router.push(route);
      },
      clearQuantityBuffer() {
        this.$store.state.quantityBuffer = null;
      },
      checkIfSkippedOrderType() {
        if (this.$store.state.skippedOrderType && !this.$pos && !this.$kiosk) {
          this.$store.state.skippedOrderType = false;
          this.$router.push(this.$router.generate("/orderType"));
        }
      },
      closeQuickAddModal() {
        this.showQuickAddModal = false;
        this.quickAddItem = null;
      },
      isActive(path) {
        let url = "";
        if (store.state.currentCompany) {
          url = "/" + store.state.currentCompany.nameCanonical + url;
        }
        if (store.state.currentBranch) {
          url = "/" + store.state.currentBranch.nameCanonical + url;
        }
        return url + path === this.$route.fullPath;
      },
      scrollToActiveCategory() {
        let container = document.getElementById("rootContainer");
        if (!container) {
          return;
        }
        let element = container.getElementsByClassName("router-link-active")[0];
        if (element) {
          container.scrollLeft = element.offsetLeft;
        }
      },
      initFirstLaunchEvent() {
        if (typeof cordova !== "undefined" && !window.localStorage.getItem("ishopv3-app-first-launch")) {
          this.$ga.event("fist-launch", this.$store.state.currentCompany.nameCanonical, this.username);
          if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("First App Launch", { company: this.$store.state.currentCompany.nameCanonical });
          }
          window.localStorage.setItem("ishopv3-app-first-launch", 1);
        }
      },
      setMethodIfDisplayMode() {
        if (this.$route.name === "view-menu") {
          this.$store.state.displayMode = true;
          if (this.$store.state.currentCompany && this.$store.state.currentCompany.menuDisplay) {
            this.$order.setMethod(this.$store.state.currentCompany.menuDisplay.fromOrderMethod);
          } else {
            this.$store.state.displayMode = false;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>

  #menu {
    position: relative;
  }

  #valitorCardLogos {
    border-radius: 10px;
  }

 #iShopFoodApp .page-content {
    padding: 30px 40px 0px 40px;

    &.kiosk, &.pos {
      display: flex;
      padding: 0 !important;
      overflow: hidden;
      background-color: var(--background-color);

      .items {
        flex-grow: 1;
        overflow-y: auto;
        justify-content: flex-start;
        align-items: stretch;
        align-content: flex-start;
        padding: 30px;
      }
    }

    &.pos {
      padding: 30px 0 !important;
      flex-direction: column;
      flex-wrap: wrap;

      &:after {
        content: none;
        height: 0;
      }

      .items {
        padding: 0 15px 25px 20px;
        width: calc(100% - 222px);
        margin-top: -10px;
      }
    }

    &:after {
      height: 30px;
    }
  }
  #iShopFoodApp.xs {
    .page-content {
      padding: 10px 15px 0px 15px;
    }
    .items:not(#styleList) {
      margin: 0 -15px 10px;
    }
  }

  .arrow {
    position: absolute;
    right: 8px;
  }

  #iShopFoodApp .branding__back {
    display: flex;
    align-items: center;
    color: var(--primary-color) !important;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 5px;
    cursor: pointer;

    .svgicon {
      fill: var(--primary-color) !important;
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 720px) {
    $size: 45px;
    #iShopFoodApp:not(.kiosk) #menu.hasBranding {
      .page-content {
        padding-bottom: $size;
      }
    }
    #iShopFoodApp .branding__back {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 1;
      margin-bottom: 0;
      padding: 15px 20px;
      font-size: 14px;
      justify-content: center;
      height: $size;

      .svgicon {
        display: none;
      }
    }
  }

  .activePromotion {
    padding: 0 30px;
    margin-bottom: 20px;
    .activePromotion__title {
      font-size: 26px;
      font-weight: 900;
    }
    .activePromotion__description {
      font-size: 16px;
      font-weight: 500;
      color: var(--theme-color-2);
    }
  }
  .border-left {
    border-left: 1px dashed var(--border-color);
  }
</style>
