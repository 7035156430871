<template>
  <input id="autocomplete" class="input google" name="address" type="text" :placeholder="placeholder || $t('placeholder_address')">
</template>

<script>
  export default {
    name: "googleAutocomplete",

    props: {
      placeholder: String,
      prefill: Object
    },

    async mounted() {
      if (document.getElementById("autocomplete") &&  document.getElementById("autocomplete") instanceof HTMLInputElement) {
        let google = await getGoogle();
        let autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"));
        autocomplete.setFields(['address_components', 'geometry']);
        autocomplete.setTypes(['address']);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            if (position) {
              let circle = new google.maps.Circle({
                center: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                },
                radius: position.coords.accuracy
              });
              autocomplete.setBounds(circle.getBounds());
            }
          });
        }
        autocomplete.addListener("place_changed", () => {
          this.$emit("changed", autocomplete);
        });
      }

      if (this.prefillValue) {
        document.getElementById("autocomplete").value = this.prefillValue;
      }

    },

    computed: {
      prefillValue() {
        if (!this.prefill) {
          return;
        }
        return (this.prefill.fullAddress ? this.prefill.fullAddress + ', ' : '')
          + (this.prefill.city ? this.prefill.city + ', ' : '')
          + (this.prefill.postalCode ? this.prefill.postalCode: '');
      }
    }
  }
</script>

<style lang="scss">
  #autocomplete.google {
    margin-bottom: 0 !important;
  }
</style>
