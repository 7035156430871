<template>
  <div id="profile" v-if="ready" :class="{mobile: $store.getters.isMobile}">

    <PageTitle show-back-button>
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      {{ $t('navigation.profile') }}
    </PageTitle>

    <div class="page-content">
      <div class="card">
        <div class="card-content">

          <div class="card-content-left">
            <svgicon class="icon" src="icons/profile.svg" v-if="!user.image" :height="150" :width="150"></svgicon>
            <div class="profile-image" v-if="user.image" :style="{backgroundImage: 'url(\'' + $store.state.urlFileServer + user.image + '\')'}">
            </div>
          </div>

          <div class="card-content-right">
            <div class="title">{{ user.getFullName() }}</div>

            <div class="mail-address" v-if="user.email">
              <div class="small-title">{{$t('profile.email')}}</div>
              <div class="mail">{{ user.email }}</div>
            </div>

            <div class="mail-address" v-if="user.phoneNumber">
              <div class="small-title">{{$t('profile.phone')}}</div>
              <div class="mail">{{ user.phoneNumber }}</div>
            </div>

            <div class="mail-address" v-if="user.username">
              <div class="small-title">{{$t('profile.username')}}</div>
              <div class="mail">{{ user.username }}</div>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" @click="toggleEditProfile()">{{$t('profile.edit')}}</button>
              <button type="button" class="btn btn-secondary" @click="logOut">{{$t('navigation.logout')}}</button>
            </div>

          </div>

        </div>
      </div>

      <Panel class="fidelity" :title="$t('profile.fidelity_card')" icon="icons/flouze.svg" v-if="company && company.isDataCandyOrFreeBeesEnabled()" collapsible>
        <div class="loyaltyCards">
          <div class="cardContainer">
            <div class="loyaltyCard" v-if="hasLoyalty" :class="{freebees:hasFreeBees}">
              <div class="amount" v-if="$user.loyalty.getBalance() > 0">{{ $user.loyalty.getBalance().toFixed(2) }}</div>
              <div class="loyaltyTitle">
                <span class="points bold">{{ $user.loyalty.getTotalPoints() }} {{ $t("points") }}</span><br>
                <span class="bold">{{ $t("card") }}: # {{ $user.loyalty.getId() }}</span>
              </div>
              <div class="delete" @click="showCardModal">
                <svgicon src="icons/pencil.svg" :height="24" :width="24"></svgicon>
              </div>
            </div>
            <div class="loyaltyCard none" v-if="!hasLoyalty">
              <div class="amount">00.00$</div>
              <div class="loyaltyTitle">
                <span class="points">0 {{ $t("points") }}</span><br>
                <span class="bold">{{ $t("card") }}: # 0000000000000000</span>
              </div>
              <div class="delete" v-if="hasLoyalty">
                <svgicon src="icons/pencil.svg" :height="24" :width="24"></svgicon>
              </div>
            </div>
          </div>
          <div class="instructionsContainer">
            <div class="title" v-if="hasLoyalty">
              {{ $t("profile.has_card") }}
            </div>
            <div class="element" v-if="hasLoyalty">
              <a href="javascript:void(0);" class="number">1.</a>
              {{ $t("profile.card_step_1") }}
            </div>
            <div class="element" v-if="hasLoyalty">
              <a href="javascript:void(0);" class="number">2.</a>
              {{ $t("profile.card_step_2") }}
            </div>
            <div class="element" v-if="hasLoyalty">
              <a href="javascript:void(0);" class="number">3.</a>
              {{ $t("profile.card_step_3") }}
            </div>
            <div class="title" v-if="!hasLoyalty">
              {{ $t("profile.no_loyalty") }}
            </div>
            <div class="element" v-if="!hasLoyalty">
              {{ $t("profile.no_loyalty_text") }}
            </div>
            <div class="element" v-if="!hasLoyalty">
              <button type="button" class="btn btn-primary" @click="showCardModal">{{ $t("profile.link_card") }}</button>
            </div>
          </div>
        </div>
      </Panel>

      <Panel :title="$t('profile.gift_card')" icon="icons/gift.svg" v-if="company && company.isDataCandyOrFreeBeesEnabled()" collapsible>
        <div class="gift-card-title">{{$t('profile.gift_card_title')}}</div>
        <div class="gift-card-description">{{$t('profile.gift_card_description')}}</div>
      </Panel>

      <Panel id="editProfile" :title="$t('profile.edit')" icon="icons/pencil.svg" v-show="editProfile" collapsible>
        <ErrorBox boxId="errorBoxProfile" :visible="errorBoxProfileVisible" @click="errorBoxProfileVisible = false"></ErrorBox>
        <div class="columns">
          <div class="column">
            <div class="input">
              <label>{{$t('profile.first_name')}} *</label>
              <input type="text" v-model="user.firstName" :class="{invalid: $validate.error('user.firstName', 0)}">
            </div>
            <div class="input">
              <label>{{$t('profile.last_name')}} *</label>
              <input type="text" v-model="user.lastName" :class="{invalid: $validate.error('user.lastName', 0)}">
            </div>
            <div class="input">
              <label>{{$t('profile.gender')}}</label>
              <select v-model="user.gender">
                <option :value="null" hidden selected disabled>{{$t('choose')}}...</option>
                <option value="m">{{$t('profile.man')}}</option>
                <option value="f">{{$t('profile.woman')}}</option>
                <option value="x">{{$t('profile.other')}}</option>
              </select>
            </div>
            <div class="input birth">
              <label>{{$t('profile.birth_date')}}</label>
              <div class="date">
                <svgicon class="history-icon" src="icons/history.svg" :height="16" :width="16"></svgicon>
                <datetime zone="UTC" v-model="user.birthDate" :format="dateFormat" :max-datetime="maxDate" :class="{invalid: $validate.error('user.birthDate', 0)}"
                          :week-start="7"></datetime>
              </div>
            </div>
<!--            <div class="input">-->
<!--              <label>{{$t("form.add_photo")}} ({{$t("optional")}})</label>-->
<!--              <FileUploader ref="fileUploader" :image="user.image" @upload="changeUploadedImage($event)"></FileUploader>-->
<!--            </div>-->
          </div>
          <div class="column">
            <div class="input">
              <label>{{$t('profile.username')}}</label>
              <input type="text" :value="user.username" readonly disabled>
            </div>
            <div class="input">
              <label>{{$t('profile.email')}}</label>
              <input type="text" v-model="user.email" :class="{invalid: $validate.error('user.email')}">
            </div>
            <div class="input">
              <label>{{$t('profile.phone')}}</label>
              <input type="text" v-model="user.phoneNumber" :class="{invalid: $validate.error('user.phoneNumber', 0)}">
            </div>
          </div>
        </div>

        <div class="action">
          <button type="button" class="btn btn-primary" @click="saveProfile">{{$t('save')}}</button>
        </div>

      </Panel>

      <Panel id="userAddresses" icon="icons/address.svg" :title="$t('profile.my_addresses')" collapsible>
        <div class="addresses">
          <div class="user-address" v-for="(address, addressIndex) of user.addresses" :key="'address' + addressIndex" v-if="!address.isNew()">
            <div class="icon">
              <svgicon src="icons/location.svg" :height="20" :width="20"></svgicon>
            </div>
            <div class="info">
              <div class="address-parts">
                <div class="address-part">{{ address.fullAddress }}<template v-if="address.app">, {{$t('order_options.apt')}} {{address.app}}</template></div>
                <div class="address-part" v-if="address.city && !$store.getters.isMobile">{{address.city}}</div>
                <div class="address-part" v-if="address.postalCode && !$store.getters.isMobile">{{address.postalCode}}</div>
                <div class="address-part" v-if="$store.getters.isMobile">{{address.city}} <template v-if="address.postalCode">, {{address.postalCode}}</template></div>
                <div class="address-part note" v-if="address.note">{{address.note}}</div>
              </div>
            </div>
            <div class="delete-icon" @click="deleteAddress(addressIndex)"
                 v-if="user.addresses.length > 0 && !address.default && previousDefaultAddressIndex !== addressIndex">
              <svgicon src="icons/trash.svg" :height="20" :width="20"></svgicon>
            </div>
            <div class="fav-icon" @click="setDefaultAddress(addressIndex)">
              <svgicon src="icons/fav-active.svg" :height="24" :width="24" v-if="address.default || previousDefaultAddressIndex === addressIndex"></svgicon>
              <svgicon src="icons/fav-inactive.svg" :height="24" :width="24" v-if="!address.default && previousDefaultAddressIndex !== addressIndex"></svgicon>
            </div>
          </div>
        </div>
        <hr>
        <div class="actions">
          <button class="btn btn-primary" @click="displayNewAddressForm" v-if="!showNewAddressForm">{{$t('profile.add_new_address')}}</button>
          <UserLocation v-if="showNewAddressForm" force-all-fields show-delivery-note type="profile"
                        :prefillAddress="false" no-zone-validation
                        @cancel="hideNewAddressForm(true)" @changed="hideNewAddressForm($event)"></UserLocation>
        </div>
      </Panel>

      <Panel id="favoriteCompany" icon="icons/branches.svg" :title="$t('profile.my_fav_company')" v-if="enableFavoriteCompany" collapsible>
        <div class="no-fav" v-if="!$user.favoriteCompany">{{$t('profile.no_fav_company')}}</div>
        <div class="companies">
          <div class="company" v-if="!$user.favoriteCompany || isUserFavorite(company) || showAllCompanies"
               :class="{selected: isUserFavorite(company) && showAllCompanies}" v-for="company of companies">
            <div class="icon" @click="selectFavoriteCompany(company.id)">
              <svgicon src="icons/location.svg" :height="20" :width="20"></svgicon>
            </div>
            <div class="info" @click="selectFavoriteCompany(company.id)">
              <div class="company-name">{{ company.name }}</div>
              <div class="address" v-if="company.address">{{ company.address.address }}, {{ company.address.city }}</div>
              <FavoriteCompany :ref="'favCompany' + company.id" :company="company" @changed="showAllCompanies = false"></FavoriteCompany>
            </div>
            <div class="edit-icon" v-if="$user.favoriteCompany && !showAllCompanies" @click="showAllCompanies = true">
              <svgicon src="icons/pencil.svg" :height="24" :width="24"></svgicon>
            </div>
          </div>
        </div>
      </Panel>

    </div>

    <div class="modal" :class="{show: modalCardShown}">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/pencil.svg" :height="24" :width="24"></svgicon>
          <span class="title" v-if="!hasLoyalty">{{$t('profile.add_fidelity_card')}}</span>
          <span class="title" v-if="hasLoyalty">{{$t('profile.edit_fidelity_card')}}</span>
        </div>
        <div class="modal-body">
          <label>{{$t('profile.card_number')}}</label>
          <input type="text" placeholder="0000 0000 0000 0000" v-model="editCardNumber" :class="{invalid: $validate.error('editCardNumber', 1)}">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modalCardShown = false">{{$t('back')}}</button>
          <button type="button" class="btn btn-primary" @click="addLoyaltyCard">{{$t('save')}}</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import FavoriteCompany from "@/components/FavoriteCompany.vue";
  import UserLocation from "@/components/orderOptions/UserLocation.vue";
  import FileUploader from "@/components/FileUploader.vue";
  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import moment from "moment-timezone";

  export default {

    name: "profile",

    components: { ErrorBox, FavoriteCompany, FileUploader, UserLocation },

    data() {
      return {
        ready: true,
        editProfile: false,
        modalCardShown: false,
        errorBoxProfileVisible: false,
        previousDefaultAddressIndex: null,
        showAllCompanies: false,
        showNewAddressForm: false,
        editCardNumber: "",
        provinces: ["QC", "ON", "AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "SK", "YT"]
      }
    },

    validate: [{
      "user.firstName": {
        required: true
      },
      "user.lastName": {
        required: true
      },
      "user.phoneNumber": {
        required: false,
        phone: true
      },
      "user.email": {
        required: true,
        email: true
      },
      "user.birthDate": {
        date: false
      }
    }, {
      editCardNumber: {
        required: true,
        regex: /[0-9 ]+/
      }
    }],

    mounted() {
      this.$store.commit("showNavigationCart", true);

      if (this.$user.isAnonymous() || this.$user.isDispatchUser()) {
        this.$router.replace(this.$router.generate("/menu"));
        return;
      }
      // Load user
      Promise.all([this.userInformationAction(), this.loyaltyBalanceAction()])
        .then(responses => {
          let userInfos = responses[0];
          let userBalance = responses[1];
          if (userInfos.profile) {
            this.$user.load(userInfos.profile, null, true);
            this.$user.loyalty.load(userBalance.profile.balance);
            if (userInfos.profile.isAnonymous) {
              this.$user.anonymous = true;
            }
            this.setUserEmail();
          } else {
            this.$user.reset();
          }
        });
    },

    watch: {
      editCardNumber(newNum, oldNum) {
        this.editCardNumber = newNum.replace(/[^0-9 ]+/g, "");
      },
      "user.email"(newValue) {
        this.user.email = newValue.replace(/ /g, "");
      }
    },

    methods: {
      ...mapActions({
        getUserInformation: "userInformation",
        getLoyaltyBalance: "loyaltyBalance",
        saveProfileAction: "saveProfile",
        registerLoyaltyAction: "registerLoyalty",
        userInformationAction: "userInformation",
        loyaltyBalanceAction: "loyaltyBalance"
      }),

      formatMoney(number) {
        return `${number.toFixed(2)} $`
      },

      formatPoints(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },

      displayNewAddressForm() {
        this.previousDefaultAddressIndex = this.$user.getDefaultAddressIndex();
        this.$user.createNewAddress();
        this.showNewAddressForm = true;
      },

      async hideNewAddressForm(isAddressAccepted) {
        if (isAddressAccepted) {
          this.previousDefaultAddressIndex = null;
          this.showNewAddressForm = false;
        }
      },

      selectFavoriteCompany(id) {
        let ref = this.$refs["favCompany" + id][0];
        if (ref) {
          ref.$el.click();
        }
      },

      async setDefaultAddress(index) {
        showSpinner();
        this.$user.setDefaultUserAddress(index);
        await this.$user.saveAddresses();
        hideSpinner();
      },

      async deleteAddress(index) {
        if (this.$user.addresses[index].default) {
          return;
        }
        let response = await showConfirmation(this.$t("profile.delete_address"),
                                              this.$t("profile.delete_address_confirm").replace("{ADDRESS}", this.$user.addresses[index].getFormattedAddress()));
        if (!response) {
          return;
        }
        showSpinner();
        this.$user.addresses.splice(index, 1);
        await this.$user.saveAddresses();
        hideSpinner();
      },

      changeUploadedImage(data) {
        this.user.photoData = data;
        if (data === null) {
          this.user.image = null;
        }
      },

      isUserFavorite(company) {
        return this.$user.favoriteCompany == company.nameCanonical && this.$user.favoriteCompany;
      },

      async saveProfile() {
        if (!this.$validate.valid(0)) {
          this.$validate.validateAllOnce(0);
          this.$forceUpdate(); // TODO heh
          // first panel error box
          if (document.getElementById("errorBoxProfile").parentElement.getElementsByClassName("invalid").length > 0) {
            this.errorBoxProfileVisible = true;
            scrollToError();
          } else {
            this.errorBoxProfileVisible = false;
          }
          return;
        }
        showSpinner();
        let response = await this.saveProfileAction();
        hideSpinner();
        if (response.success) {
          this.editProfile = false;
          this.errorBoxProfileVisible = false;
        } else {
          showAffirmation(this.$t("error.title"), this.$t("profile.error_save"));
        }
        console.log(response);
      },

      logOut() {
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
        this.$router.push(this.$router.generate("/login?redirect=menu"));
      },

      async showCardModal() {
        if (this.user.loyalty.getBalance() > 0) {
          let response = await showConfirmation(this.$t("profile.card_change_title"),
                                                this.$t("profile.card_change_description_1") + "<b>" + this.user.loyalty.getBalance().toFixed(2) + "$</b>" + this.$t("profile.card_change_description_2")
          );
          if (response) {
            this.editCardNumber = "";
            this.modalCardShown = true;
          }
        } else {
          this.editCardNumber = "";
          this.modalCardShown = true;
        }
      },

      async addLoyaltyCard() {
        if (this.editCardNumber.length == 0) {
          showAffirmation(this.$t("error.title"), this.$t("profile.error_empty_card"));
          return;
        }
        if (!this.$validate.valid(1)) {
          this.$validate.validateAllOnce(1);
          return;
        }
        showSpinner();
        let response = await this.registerLoyaltyAction(this.editCardNumber);
        hideSpinner();
        if (response.success) {
          let reload = await this.getLoyaltyBalance();
          this.$user.loyalty.load(reload.profile.balance);
          this.modalCardShown = false;
        } else {
          let errorMessage;
          if (!response.error || !response.error.message) {
            errorMessage = this.$t("profile.error_card");
          } else {
            let tmp = response.error.message;
            errorMessage = tmp[this.$ts.state.locale] || tmp[Object.keys(tmp)[0]];
          }
          showAffirmation(this.$t("error.title"), errorMessage);
        }
      },
      toggleEditProfile() {
        this.editProfile = true;
        if (!this.$store.state.widget) {
          setTimeout(() => {
            document.getElementById("editProfile").scrollIntoView({ behavior: "smooth" });
          }, 100);
        }
      },
      setUserEmail() {
        if (this.isUserManagementEnabled && !this.$user.email && this.$user.manager) {
          this.$user.email = this.$user.manager.email;
        }
      }
    },

    computed: {
      typeTranslated() {
        return this.$t(`loyalty.type.${this.type}`);
      },
      user() {
        return this.$user;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      enableFavoriteCompany() {
        return this.branch && this.branch.enableFavoriteCompany();
      },
      isUserManagementEnabled() {
        if (!this.branch) {
          return false;
        }
        return this.branch.userManagementEnabled;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      companies() {
        if (!this.branch) {
          return [];
        }
        return this.branch.companies;
      },
      hasLoyalty() {
        return this.$user && this.$user.loyalty && this.$user.loyalty.type;
      },
      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d yyyy";
        }
        return "d MMMM yyyy";
      },
      maxDate() {
        return moment().toISOString();
      },
      hasFreeBees() {
        return this.company && this.company.hasFreeBeesActivated()
      }

    }

  }

</script>

<style lang="scss" scoped>

  .mobile {
    #editProfile .content .container .form {
      display: block;
    }
    .input label {
      padding-top: 5px;
    }
    .loyaltyCards {
      flex-direction: column;
      align-items: stretch;
      flex-wrap: wrap;
      .cardContainer {
        padding-right: 0;
        padding-bottom: 15px;
      }
      .cardContainer, .instructionsContainer {
        flex-grow: 0;
        flex-basis: initial;
      }
    }
    .loyaltyCard {
      height: 175px;

      .amount {
        font-size: 40px;
      }
    }
    #iShopFoodApp .page-content {
      padding: 0;

      .card {
        border-radius: 0;

        .card-content {
          flex-direction: column;

          .card-content-left {
            padding: 20px 0;
          }

          .card-content-left, .card-content-right {
            border-radius: 0;
          }
        }
      }
    }
  }

  .gift-card-title {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 10px;
  }
  .gift-card-description {
    font-size: 16px;
    color: #404040;
  }

  .loyalty-profile {
    display: flex;
    fill: #fc7663;

    .icon-holder {
      background-color: #f4f4f4;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-holder {
      flex-grow: 1;
      padding: 20px;

      .title {
        font-size: 20px;
        color: #1a1a1a;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .svgicon {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
      .description {
        font-size: 16px;
        color: #404040;
        padding-bottom: 30px;
      }
      .btn {
        display: inline-block;
      }
    }
  }

  .small-title, .name {
    font-size: 14px;
    color: #808080;
  }

  .points .image {
    fill: white;
    background: linear-gradient(to right, #ed2937, #fc7663);
    height: 100px;
    width: 100px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .balance, .total, .points, .points-before {
    padding: 8px 0;
  }

  .error-box {
    margin-bottom: 15px;
  }

  .action {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    flex-shrink: 0;
  }

  .panel > .content > .container > .cols {
    display: flex;
    align-items: flex-start;
    .col {
      display: flex;
      align-items: center;
      width: 50%;
      .text {
        padding-left: 20px;
      }
    }
  }

  .card {
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: white;

    .card-content {
      display: flex;
      flex-grow: 1;
      .card-content-left {
        background: linear-gradient(to right, #ed2937, #fc7663);
        flex-grow: 1;
        border-radius: 5px 0 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        fill: rgba(0, 0, 0, 0.3);
      }
      .card-content-right {
        padding: 20px;
        flex-grow: 1;
        .title {
          font-size: 32px;
          font-weight: bold;
        }
        .sub-title {
          text-transform: uppercase;
          color: #a6a6a6;
          padding-bottom: 12px;
        }
        .last-order, .mail-address {
          padding: 12px 0;
          .timestamp, .price-type {
            .date, .time, .price, .type-order {
              color: #404040;
            }
          }
        }
        .btn-group {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 10px -10px -10px;
          .btn {
            margin: 0 10px 10px;
            text-align: center;
            flex-grow: 1;
          }
        }
      }
    }
  }

  .input {
    padding: 12px 0;
    input {
      width: 100%;
    }

    label {
      display: block;
      font-size: 14px;
      color: #808080;
      padding-bottom: 8px;
      padding-left: 8px;
    }
    input, select {
      width: 100%;
    }
    .address-info {
      padding: 4px 0;
    }
    .date {
      position: relative;
    }
    .history-icon {
      cursor: text;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .loyaltyCards {
    display: flex;
    align-items: center;

    .cardContainer, .instructionsContainer {
      flex-grow: 1;
      flex-basis: 0;
    }
    .cardContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;
    }
    .instructionsContainer {
      display: flex;
      flex-direction: column;
      .title {
        padding: 0;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .element {
        display: flex;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
        .number {
          font-size: 20px;
          margin-right: 10px;
          font-weight: bold;
          position: relative;
          top: -4px;
          text-decoration: none;
          cursor: text;
        }
      }
    }
  }
  #iShopFoodApp #profile{

    .loyaltyCard {
      background: linear-gradient(to right, #ed2937, #fc7663);
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
      position: relative;
      width: 100%;
      max-width: 330px;
      height: 220px;
      display: flex;
      flex-direction: column;
      color: white;
      fill: white;

      &.freebees {
        background-size: cover !important;
        object-fit: cover;
        background: transparent url(../../assets/img/fb-card-background.png) no-repeat center top !important;

        &::after {
          content: "";
          background: transparent url(../../assets/img/fb-card-logo.png) no-repeat center top !important;
          background-size: cover !important;
          width: 250px;
          height: 70px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      .points {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
      .bold {
        color: white;
      }
      &.none {
        opacity: 0.5;
        .loyaltyTitle, .amount, .delete {
          opacity: 0.5;
        }
      }
      .amount {
        font-size: 64px;
        color: white;
        font-weight: bold;
        line-height: 1;
        flex-grow: 1;
      }
      .delete {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 8px;
        cursor: pointer;
      }
    }
  }


  .noLoyaltyCardContainer {
    text-align: center;
    margin: 0 40px;
    .noLoyaltyCard {
      font-size: 18px;
    }
  }

  .modal-content {
    max-width: 520px;
  }

  .modal-header {
    .svgicon {
      fill: #ed2937;
      margin-right: 8px;
    }
  }

  .modal-body {
    label {
      display: block;
      font-size: 14px;
      color: #808080;
      margin-left: 12px;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
    }
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
      &:after {
        height: 0;
      }
    }

    .card {
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid #e6e6e6;

      .card-content {
        flex-direction: column;
        .card-content-left {
          border-radius: 0;
        }
      }
    }
  }

  #userAddresses {
    .actions {
      width: 100%;
      padding: 10px 0 15px 0;
    }
  }

  @media screen and (max-width: 720px) {
    #userAddresses .actions {
      .btn {
        width: 100%;
      }
      hr {
      }
    }
  }

  #iShopFoodApp #favoriteCompany,
  #iShopFoodApp #userAddresses {
    .companies {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .addresses {
      display: flex;
      flex-direction: column;
    }

    .company {
      min-width: calc(50% - 20px);
      cursor: pointer;
    }

    .user-address {
      width: 100%;
    }

    .company, .user-address {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 20px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      margin: 5px;

      .address-parts {
        display: flex;

        .address-part {
          position: relative;

          &.mobile {
            display: none;
          }

          &.note {
            opacity: 0.5;
          }

          &:not(:last-child) {
            padding-right: 20px;
            &:after {
              content: "|";
              opacity: 0.4;
              position: absolute;
              top: 0;
              right: 10px;
            }
          }
        }
      }

      .info {
        flex-grow: 1;
      }

      .company-name, .name {
        font-weight: bold;
        margin-bottom: 2px;
      }

      &.selected {
        background-color: rgba(0,0,0,0.1);
      }

      .icon {
        margin-right: 15px;
        opacity: 0.25;
      }

      .fav-icon {
        fill: var(--primary-color);
        padding: 10px;
        cursor: pointer;
      }

      .edit-icon {
        fill: var(--primary-color);
        padding: 10px;
        margin-left: 20px;
        cursor: pointer;
      }

      .delete-icon {
        fill: var(--primary-color);
        padding: 10px;
        cursor: pointer;
        margin-left: 20px;
      }
    }

    @media screen and (min-width: 721px) {
      .company {
        max-width: calc(50% - 20px);
      }
    }

    @media screen and (max-width: 720px) {
      .company, .user-address {
        padding: 15px 0;
      }
      .info .address-parts {
        flex-direction: column;
        .address-part {
          &.note {
            padding-top: 8px;
          }
          &:not(:last-child) {
            padding-right: 0;

            &:after {
              content: none;
            }
          }
        }
      }
    }

    .no-fav {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .default {
      display: flex;
      fill: var(--primary-color);
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;

      .svgicon {
        margin-right: 5px;
      }
    }
  }

  .profile-image {
    $size: 175px;
    height: $size;
    width: $size;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 4px solid var(--primary-color);
  }

  .columns {
    display: flex;
    flex-direction: row;
    width: 100%;

    .column {
      flex-grow: 1;
      flex-basis: 0;
    }

    .column:not(:last-child) {
      margin-right: 15px;
    }
  }

  .input {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    #iShopFoodApp #favoriteCompany,
    #iShopFoodApp #userAddresses {
      .companies, .addresses {
        display: block;
      }
      .company, .user-address {
        flex-grow: initial;
        width: 100%;
        padding: 15px;
      }
    }

    .columns {
      flex-direction: column;
      .column {
        width: 100%;
        margin: 0;
      }
    }
  }
</style>
