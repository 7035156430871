//@ts-check
import Item from "./ItemModel";

/**
 * @typedef Tax
 * @property {string} name
 * @property {number} rate
 * @property {string} type
 * @property {number} total
 */

/**
 * @typedef Contact
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 */

/**
 * @typedef SaleDefinition
 * @property {number} id
 * @property {string} startedAt
 * @property {string} inAdvanceFor
 * @property {Contact} contact
 * @property {number} deliveryFees
 * @property {number} serviceFees
 * @property {string} deliveryMethod
 * @property {Item[]} items
 * @property {any[]} promotions //TODO TBD link with model/construct ?
 * @property {Tax[]} taxes
 * @property {number} subTotal
 * @property {number} tipTotal
 * @property {number} total
 * @property {any} loyaltyData
 */

export default class Sale {

  /**
   * @param {SaleDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.startedAt = definition.startedAt;
    this.inAdvanceFor = definition.inAdvanceFor;
    this.contact = definition.contact;
    this.deliveryFees = definition.deliveryFees;
    this.serviceFees = definition.serviceFees;
    this.deliveryMethod = definition.deliveryMethod;
    this.items = this._buildItems(definition.details);
    this.promotions = definition.promotions;
    this.subTotal = definition.subTotal;
    this.taxes = definition.taxes;
    this.tipTotal = definition.tipTotal;
    this.total = definition.total;
    this.loyaltyData = definition.loyaltyData;
  }

  /**
   * @private
   */
  _buildItems(details = []) {
    let items = [];

    for (let item of details) {
      if (item.line.length === 1 && item.type !== "supplement") {
        item.combos = [];
        item.modifiers = [];
        item.totalPrice = item.unitPrice;
        items.push(item);
      }
      if (item.line.length > 1) {
        switch(item.type) {
          case "combo":
            items[item.line[0]].combos.push(item);
            items[item.line[0]].totalPrice += item.unitPrice * item.quantity;
            break;
          case "modifier":
            items[item.line[0]].modifiers.push(item);
            items[item.line[0]].totalPrice += item.unitPrice * item.quantity;
            break;
        }
      }
      item.totalPrice = item.totalPrice * item.quantity;
    }

    return items;
  }

}
