import AppliedPromotion from "@/models/pos/promotions/AppliedPromotion";
import { Util } from "@/util/Util";
import moment from "moment-timezone";

/**
 * @typedef DetailDefinition
 * @property {number} id
 * @property {string} altId
 * @property {string|null} addedAt
 * @property {number?} item
 * @property {string?} line
 * @property {string} quantity
 * @property {number} unitPrice
 * @property {string[]?} appliedTaxes
 * @property {Detail} parentDetail
 * @property {}
 * @property {string} name
 * @property {string?} note
 * @property {string?} seat
 * @property {{quantity: number, id: number}?} split
 * @property {string?} splitOwner
 * @property {boolean} void
 * @property {{id: number}[]} tags
 * @property {AppliedPromotion[]} appliedPromotions
 * @property {string} kitchenName
 * @property {Object} overwrittenFrom
 */

export default class Detail {

    /**
     * @param {DetailDefinition} definition?
     * @param {boolean?} isSent
     */
    constructor(definition, isSent) {
        this.id = null;
        this.itemId = null;
        this.addedAt = null;
        this.inventoryItem = /** @type Item */ null;
        this.parentDetailId = null;
        this.parentItemId = null;
        this.quantity = 1;
        this.unitPrice = 0;
        this.appliedTaxes = [];
        this.details = [];
        this.name = "";
        this.note = null;
        this.seat = null;
        this.split = null;
        this.splitOwner = null;
        this.new = true;
        this.type = null;
        this.void = false;
        this.tags = [];
        this.appliedPromotions = [];
        this.kitchenName = "";
        this.overwrittenFrom = {
            unitPrice: undefined,
            name: undefined
        };

        if (definition) {
            this.load(definition, isSent);
        }
    }

    /**
     * @param {DetailDefinition} definition
     * @param {boolean?} isSent
     */
    load(definition, isSent) {
        this.id = definition.altId; //Alt ID for front-end usage
        this.addedAt = definition.addedAt ? moment(definition.addedAt, "YYYY-MM-DD HH:mm:ss") : null;
        this.itemId = definition.item;
        this.parentDetailId = definition.parentDetail;
        this.parentItemId = definition.parentItemId || null;
        this.quantity = definition.quantity || 1;
        this.unitPrice = definition.unitPrice || 0;
        this.appliedTaxes = definition.appliedTaxes || [];
        this.name = definition.name || null;
        this.note = definition.note || null;
        this.seat = definition.seat ? parseInt(definition.seat) : null;
        this.split = definition.split || null;
        this.splitOwner = definition.splitOwner || null;
        this.new = !isSent;
        this.type = definition.type || null;
        this.void = definition.void;
        this.tags = definition.tags || [];
        this.appliedPromotions = (definition.appliedPromotions || []).filter(p => typeof p === "object");
        this.kitchenName = definition.kitchenName || "";

        this.overwrittenFrom.unitPrice = definition.overwrittenFrom?.unitPrice;
        this.overwrittenFrom.name = definition.overwrittenFrom?.name;
    }

    /**
     * @returns {number}
     */
    getId() {
        return this.id;
    }

    /**
     * @returns {number}
     */
    getParentDetailId() {
        return this.parentDetailId;
    }

    /**
     * @returns {number}
     */
    setParentDetailId(detailId) {
        this.parentDetailId = detailId;
    }

    /**
     * @returns {Item}
     */
    getInventoryItem() {
        return this.inventoryItem;
    }

    /**
     * Get the detail's unit price, without any modifications
     * @returns {number}
     */
    getUnitPrice() {
        return this.unitPrice;
    }

    /**
     * Get the detail's price with promotions applied
     * @returns {number}
     */
    getPrice() {
        let price = this.getUnitPrice();
        if (this.isPriceOverwritten()) {
            return price;
        }
        if (this.isSplit()) {
            price = Util.Money.roundUpLastDecimal(price / (this.split.quantity || 1));
        }
        if (this.appliedPromotions.length > 0) {
            let promotionTotal = this.appliedPromotions.reduce((p, c) => p + c.amount, 0);
            promotionTotal = Math.min(Math.abs(price), promotionTotal);
            price -= promotionTotal;
        }
        return Util.Money.round(price * this.quantity);
    }

    /**
     * Works only for detail with embed child details
     * @returns {number}
     */
    getTotalPrice() {
        let totalPrice = this.getPrice();
        if (this.isPriceOverwritten()) {
            return totalPrice;
        }
        for (let i = 0; i < (this.details || []).length; i++) {
            totalPrice += (this.details[i].getPrice() * this.quantity);
        }
        return Util.Money.round(totalPrice);
    }

    /**
     * Get detail name
     * @returns {string}
     */
    getName() {
        return this.name;
    }

    /**
     * Get detail seat
     * @returns {number}
     */
    getSeat() {
        return this.seat;
    }

    /**
     * @param {Item} item
     */
    setInventoryItem(item) {
        this.inventoryItem = item.clone();
    }

    /**
     * Get if detail has taxes applied or not
     * @return {boolean}
     */
    isTaxable() {
        return this.appliedTaxes.length > 0;
    }

    setQuantity(quantity) {
        this.quantity = quantity;
        if (this.inventoryItem) {
            this.inventoryItem.quantity = quantity;
        }
        this.new = true;
    }

    /**
     * @param {string} note
     */
    setNote(note) {
        this.note = note;
    }

    /**
     * @returns {boolean}
     */
    isNew() {
        return this.new;
    }

    /**
     * @returns {boolean}
     */
    isVoid() {
        return this.void;
    }

    /**
     * @returns {boolean}
     */
    isRefund() {
        return this.quantity < 0;
    }

    /**
     * @returns DetailDefinition
     **/
    toDto() {
        return {
            altId: this.id,
            createdAt: this.createdAt,
            item: this.itemId,
            parentDetail: this.parentDetailId,
            parentItemId: this.parentItemId,
            quantity: this.quantity,
            unitPrice: this.unitPrice,
            appliedTaxes: this.appliedTaxes,
            name: this.name,
            note: this.note,
            seat: this.seat,
            split: this.split,
            type: this.type || undefined,
            kitchenName: this.kitchenName,
            void: this.void,
            tags: this.tags.map(t => {
                return { id: t.id };
            }),
            splitOwner: this.splitOwner || undefined,
            appliedPromotions: this.appliedPromotions,
            overwrittenFrom: {
                unitPrice: this.overwrittenFrom?.unitPrice,
                name: this.overwrittenFrom?.name,
            },
        };
    }

    /**
     * @return string
     */
    toStringForHashCode() {
        return "" + this.id + this.itemId + this.name + this.quantity + this.unitPrice +
          this.appliedPromotions.map(p => "" + p.id + p.amount);
    }

    /**
     * Creates and return copy of current Detail
     * @returns {Detail}
     */
    clone() {
        let detail = new Detail(this.toDto());
        if (this.inventoryItem) {
            detail.inventoryItem = this.inventoryItem.clone();
        }
        detail.new = this.new;
        return detail;
    }

    /**
     * Check if price has been overwritten
     * @returns {Boolean}
     */
    isPriceOverwritten() {
        return this.overwrittenFrom && this.overwrittenFrom.unitPrice != null;//standard equality consider null and undefined equals
    }

    /**
     * Overwrite unit price and add inital value to overwrittenFrom object
     * @param {float} newUnitPrice
     */
    overwriteUnitPrice(newUnitPrice) {
        this.overwrittenFrom.unitPrice = this.unitPrice;
        this.unitPrice = Util.Money.round(newUnitPrice);
    }

    /**
     * Overwrite name and add inital value to overwrittenFrom object
     * @param {string} newName
     */
    overwriteName(newName) {
        this.overwrittenFrom.name = this.name;
        this.name = newName;
    }

    /**
     * Check if detail is an open remark.
     * @returns {Boolean}
     */
    isOpenRemark() {
        return !this.itemId;// ou typeof null/undefined? si id est 0.
    }

    /**
     * Overwrite name as remark
     * @param {string} remark
     */
    setRemark(remark) {
        if (!this.isOpenRemark() || !remark) {
            return;
        }
        this.name = remark;
    }

    /**
     * Set detail Seat
     * @param {number} seat
     */
    setSeat(seat) {
        this.seat = seat;
    }

    /**
     * Set detail id
     * @param {number} id
     */
    setId(id) {
        this.id = id;
    }

    /**
     * Get Original Split Detail ID
     * @returns {number|null}
     */
    getOriginalSplitOwner() {
        if (this.split) {
            return this.split.id;
        }
        return null;
    }

    /**
     * Check if detail is split
     * @returns {boolean}
     */
    isSplit() {
        return !!this.split;
    }

    /**
     * Get quantity among which detail is split
     * @returns {number}
     */
    getSplitQuantity() {
        if (!this.isSplit()) {
            return 1;
        }
        return this.split.quantity;
    }
}