<template>
  <form class="payment" action="" @submit.prevent v-show="total.totalRemaining > 0" v-if="PaymentProcessor">
    <!-- SELECTION -->
    <div class="input" v-if="!hidePaymentMethodSelection">
      <label for="method-payment">{{$t('payment.payment_method')}}</label>
      <select id="method-payment" v-model="PaymentProcessor.paymentMethod"
              :class="{invalid: validate.error('PaymentProcessor.paymentMethod')}">
        <option :value="null" disabled hidden>{{$t('choose')}}...</option>
        <option :value="method" v-for="method of paymentMethods" :key="method">{{$t('payment_method.' + method)}}</option>
      </select>
    </div>

    <!-- EXTERNAL PAY BUTTONS -->
    <div v-if="hasPaymentMethod(Constant.NMI)" v-show="PaymentProcessor.isPaymentMethod(Constant.NMI)">
      <div class="googlepaybutton__container" v-if="PaymentProcessor.isGooglePayEnabled(Constant.NMI)">
        <div id="googlepaybutton"></div>
      </div>
      <div class="applepaybutton__container" v-if="PaymentProcessor.isApplePayEnabled(Constant.NMI)">
        <div id="applepaybutton"></div>
      </div>
      <hr>
    </div>

    <!-- SAVED CARDS -->
    <template v-if="PaymentProcessor.saveCardEnabled() && !!user && !user.isAnonymous()">
      <div class="input" v-if="PaymentProcessor.getAvailableBillingTokens().length > 0">
        <label>{{$t('loyalty.saved_cards')}}</label>
        <div class="token-card" v-for="card of PaymentProcessor.getAvailableBillingTokens()" :key="'card' + card.id">
          <div class="check">
            <input :id="'radioCard' + card.id" type="radio" :checked="PaymentProcessor.savedCardID == card.id"
                   @click="PaymentProcessor.changeSavedCardID(card.id)">
            <label :for="'radioCard' + card.id"></label>
          </div>
          <div class="content">
            <div class="number" @click="PaymentProcessor.changeSavedCardID(card.id)">
              <label>{{$t('payment.card_number')}}</label>
              <div class="value">
                **** **** **** {{card.last4}}
              </div>
            </div>
            <div class="exp" @click="PaymentProcessor.changeSavedCardID(card.id)">
              <label>{{$t('payment.expiration_date')}}</label>
              <div class="value">
                {{(card.exp || "").substring(0, 7)}}
              </div>
            </div>
          </div>
          <div class="delete icon" @click="deleteToken(card)">
            <svgicon src="icons/trash.svg" :height="15" :width="15"></svgicon>
          </div>
        </div>
        <div class="token-card" @click="PaymentProcessor.savedCardID = null">
          <div class="check">
            <input id="radioCardNone" type="radio" :checked="PaymentProcessor.savedCardID == null">
            <label for="radioCardNone"></label>
          </div>
          <div class="new">
            <div class="value">
              {{$t('loyalty.new_card')}}
            </div>
          </div>
        </div>
      </div>
      <div class="input save-card" v-if="!PaymentProcessor.savedCardID">
        <input id="chkSaveCard" type="checkbox" v-model="PaymentProcessor.saveCard">
        <label for="chkSaveCard">{{ $t('payment.save_card') }}</label>
      </div>
    </template>

    <!-- PAYFACTO -->
    <template v-if="hasPaymentMethod('payfacto')">
      <div class="payfactoSHC" v-if="PaymentProcessor.usePayFactoSHC"
           v-show="PaymentProcessor.isPaymentMethod('payfacto')">
        <div id="shc-fields"></div>
      </div>
      <label class="payfactoLabel" v-show="PaymentProcessor.isPaymentMethod('payfacto')">
        <div class="payfactoLabel__secure">
          <svgicon :src="$to(securePaymentIcon)" :width="110" :height="22" style="margin-right: 4px;"></svgicon>
        </div>
        <div class="payfactoLabel__logo">
          <div class="powered-by">{{$t('payment.powered_by')}}</div>
          <div class="logo">
            <img src="@/assets/img/payfacto.png" alt="PayFacto">
          </div>
        </div>
        <br><br>
      </label>
    </template>

    <!-- OFFLINE METHOD -->
    <div class="offline-payment" v-show="!PaymentProcessor.isOnlinePaymentMethod() && !PaymentProcessor.isPaymentMethod('room-charge')">
      {{$t('payment.offline_payment')}}
    </div>

    <!-- ROOM CHARGE -->
    <div class="group" v-if="PaymentProcessor.isPaymentMethod('room-charge')">
      <label>{{ $t('payment.room_number') }}</label>
      <input type="text" v-model="$order.roomNumber">
    </div>

    <!-- GLOBAL PAYMENTS -->
    <div id="global" class="credit-card" v-if="hasPaymentMethod('global')" v-show="PaymentProcessor.isPaymentMethod('global')">
      <div class="global-form">
        <label>{{$t('payment.address')}}</label>
        <input type="text" :placeholder="$t('payment.address')" v-model="PaymentProcessor.cardBillingAddress"
               :class="{invalid: validate.error('PaymentProcessor.cardBillingAddress')}">
        <div class="columns">
          <div class="column">
            <label>{{$t('payment.card_postal_code')}}</label>
            <input type="text" :placeholder="$t('payment.card_postal_code')" v-model="PaymentProcessor.cardBillingPostalCode"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingPostalCode')}">
          </div>
          <div class="column">
            <label>{{$t('payment.city')}}</label>
            <input type="text" :placeholder="$t('payment.city')" v-model="PaymentProcessor.cardBillingCity"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingCity')}">
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <label>{{$t('payment.country')}}</label>
            <select v-model="PaymentProcessor.cardCountry" :class="{invalid: validate.error('PaymentProcessor.cardCountry')}">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option value="CA">Canada</option>
              <option value="US">U.S.</option>
            </select>
          </div>
          <div class="column">
            <label>State</label>
            <select v-model="PaymentProcessor.cardBillingState" :disabled="!PaymentProcessor.cardCountry"
                    :class="{invalid: validate.error('PaymentProcessor.cardBillingState')}">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option :value="state.code" v-if="PaymentProcessor.cardCountry" v-for="state of states[PaymentProcessor.cardCountry]">
                {{state[$ts.state.locale] || state.en}}
              </option>
            </select>
          </div>
        </div>
        <button class="btn btn-primary btnContinue"  @click="continueToGlobalPayments">
          {{$t('loyalty.continue_to_payment')}}
        </button>
        <button id="globalReady" style="display:none;visibility: hidden;"></button>
      </div>
    </div>

    <!-- CYBERSOURCE -->
    <div  id="cybersource" v-if="hasPaymentMethod('cybersource')" v-show="PaymentProcessor.isPaymentMethod('cybersource') && !PaymentProcessor.savedCardID" >
      <div id="errors-output" role="alert"></div>
      <div class="form">
        <div class="columns">
          <div class="column">
            <label for="cardFirstName">{{$t('payment.first_name')}}</label>
            <input type="text" id="cardFirstName" v-model="PaymentProcessor.cardFirstName" :class="{invalid: validate.error('PaymentProcessor.cardFirstName')}"
                   :placeholder="$t('payment.first_name')">
          </div>
          <div class="column">
            <label for="cardLastName">{{$t('payment.last_name')}}</label>
            <input type="text" id="cardLastName" v-model="PaymentProcessor.cardLastName" :class="{invalid: validate.error('PaymentProcessor.cardLastName')}"
                   :placeholder="$t('payment.last_name')">
          </div>
        </div>
        <div class="input">
          <label>{{$t('payment.address')}}</label>
          <input type="text" :placeholder="$t('payment.address')" v-model="PaymentProcessor.cardBillingAddress"
                 :class="{invalid: validate.error('PaymentProcessor.cardBillingAddress')}">
        </div>
        <div class="columns">
          <div class="column">
            <label>{{$t('payment.card_postal_code')}}</label>
            <input type="text" :placeholder="$t('payment.card_postal_code')" v-model="PaymentProcessor.cardBillingPostalCode"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingPostalCode')}">
          </div>
          <div class="column">
            <label>{{$t('payment.city')}}</label>
            <input type="text" :placeholder="$t('payment.city')" v-model="PaymentProcessor.cardBillingCity"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingCity')}">
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <label>{{$t('payment.country')}}</label>
            <select v-model="PaymentProcessor.cardCountry" :class="{invalid: validate.error('PaymentProcessor.cardCountry')}">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option value="CA">Canada</option>
              <option value="US">U.S.</option>
            </select>
          </div>
          <div class="column">
            <label>State</label>
            <select v-model="PaymentProcessor.cardBillingState" :disabled="!PaymentProcessor.cardCountry"
                    :class="{invalid: validate.error('PaymentProcessor.cardBillingState')}">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option :value="state.code" v-if="PaymentProcessor.cardCountry" v-for="state of states[PaymentProcessor.cardCountry]">
                {{state[$ts.state.locale] || state.en}}
              </option>
            </select>
          </div>
        </div>
        <div class="input">
          <label id="cardNumber-label">{{$t('payment.card_number')}}</label>
          <div id="number-container"></div>
        </div>
        <div class="input">
          <label for="securityCode-container">Security Code</label>
          <div id="securityCode-container"></div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="input">
              <label for="expMonth">Expiry month</label>
              <select id="expMonth">
                <option v-for="n in 12">{{n < 10 ? '0' + n : n}}</option>
              </select>
            </div>
          </div>
          <div class="column">
            <div class="input">
              <label for="expYear">Expiry year</label>
              <select id="expYear" class="form-control">
                <option v-for="year of years">{{year}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <label class="cybersourceLabel">
        <div class="powered-by">{{$t('payment.powered_by')}}</div>
        <svgicon class="cybersource-icon" src="icons/cybersource.svg" :width="130" :height="30"></svgicon>
      </label>
      <input type="hidden" id="flexresponse" name="flexresponse">
    </div>

    <!-- NMI -->
    <div id="nmi" v-if="hasPaymentMethod('nmi')"
         v-show="PaymentProcessor.isPaymentMethod('nmi') && !(PaymentProcessor.saveCardEnabled() && PaymentProcessor.savedCardID)">
      <template v-if="PaymentProcessor.nmiBillingInformationRequired()">
        <div class="columns">
          <div class="column">
            <label for="cardFirstName">{{ $t('payment.first_name') }}</label>
            <input type="text" id="nmiCardFirstName" v-model="PaymentProcessor.cardFirstName"
                   :class="{invalid: validate.error('PaymentProcessor.cardFirstName')}"
                   :placeholder="$t('payment.first_name')">
          </div>
          <div class="column">
            <label for="cardLastName">{{ $t('payment.last_name') }}</label>
            <input type="text" id="nmiCardLastName" v-model="PaymentProcessor.cardLastName"
                   :class="{invalid: validate.error('PaymentProcessor.cardLastName')}"
                   :placeholder="$t('payment.last_name')">
          </div>
        </div>
        <div>
          <label>{{$t('payment.address')}}</label>
          <input type="text" :placeholder="$t('payment.address')" v-model="PaymentProcessor.cardBillingAddress"
                 :class="{invalid: validate.error('PaymentProcessor.cardBillingAddress')}">
        </div>
        <div class="columns">
          <div class="column">
            <label>{{$t('payment.city')}}</label>
            <input type="text" :placeholder="$t('payment.city')" v-model="PaymentProcessor.cardBillingCity"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingCity')}">
          </div>
          <div class="column">
            <label>{{$t('payment.card_postal_code')}}</label>
            <input type="text" :placeholder="$t('payment.card_postal_code')" v-model="PaymentProcessor.cardBillingPostalCode"
                   :class="{invalid: validate.error('PaymentProcessor.cardBillingPostalCode')}">
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <label>{{ $t('payment.country') }}</label>
            <select v-model="PaymentProcessor.cardCountry" :class="{invalid: validate.error('PaymentProcessor.cardCountry')}">
              <option :value="null" disabled hidden>{{$t('choose')}}...</option>
              <option :key="'country-nmi-' + country.countryShortCode"
                      :value="country.countryShortCode" v-for="country of CountryRegionData">
                {{ country.countryName }}
              </option>
            </select>
          </div>
          <div class="column">
            <label>{{ $t('profile.state') }}</label>
            <select v-model="PaymentProcessor.cardBillingState" :disabled="!PaymentProcessor.cardCountry"
                    :class="{invalid: validate.error('PaymentProcessor.cardBillingState')}">
              <option value="" disabled hidden>{{$t('choose')}}...</option>
              <option :value="region.shortCode" v-if="PaymentProcessor.cardCountry"
                      :key="'region-nmi-' + region.shortCode"
                      v-for="region of paymentProcessorSelectedCountryRegions">
                {{ region.name }}
              </option>
            </select>
          </div>
        </div>
      </template>
      <label>{{ $t('payment.card_number') }}</label>
      <div id="txtCardNumber"></div>
      <div class="columns">
        <div class="column">
          <label>{{$t('payment.expiration_date')}}</label>
          <div id="txtCardExpiry"></div>
        </div>
        <div class="column">
          <label for="card-ccv">{{ $t('payment.cvv') }}</label>
          <div id="txtCardCVV"></div>
        </div>
      </div>
      <br>
      <div id="nmiPaymentVerificationTitle" v-if="PaymentProcessor.nmiPaymentChallengeInProgress">
        <svgicon src="icons/info.svg" :height="20" :width="20"></svgicon>
        <h3>{{ $t("payment.verification_title") }}</h3>
      </div>
      <div id="threeDSMountPoint"></div>
      <div class="button-holder" v-show="!PaymentProcessor.nmiPaymentChallengeInProgress">
        <button type="button" class="btn btn-primary" id="btnPayNmiFake" @click="continueWithNmi">
          {{ $store.state.qrPaymentMode ? $t('qr_payment.pay') : $t('payment.pay_and_confirm') }}
        </button>
      </div>
      <button type="button" class="hidden" id="btnPayNmi"></button>
    </div>
    <template v-if="PaymentProcessor.isPaymentMethod('nmi') && PaymentProcessor.saveCardEnabled() && PaymentProcessor.savedCardID">
      <div id="nmi--SavedCard">
        <div id="threeDSMountPoint--SavedCard"></div>
      </div>
    </template>

    <!-- PAYSAFE -->
    <div id="paysafe" class="credit-card" v-if="hasPaymentMethod('paysafe')" v-show="PaymentProcessor.isPaymentMethod('paysafe')">
      <label v-show="PaymentProcessor.paysafeBillingInformationRequired">{{$t('payment.cardholder_name')}}</label>
      <input type="text" v-show="PaymentProcessor.paysafeBillingInformationRequired"
             :placeholder="$t('payment.cardholder_name')" v-model="PaymentProcessor.cardholderName"
             :class="{invalid: validate.error('PaymentProcessor.cardholderName')}">
      <div class="columns" v-show="PaymentProcessor.paysafeBillingInformationRequired">
        <div class="column">
          <label>{{$t('payment.card_postal_code')}}</label>
          <input type="text" :placeholder="$t('payment.card_postal_code')" v-model="PaymentProcessor.cardBillingPostalCode"
                 :class="{invalid: validate.error('PaymentProcessor.cardBillingPostalCode')}">
        </div>
        <div class="column">
          <label>{{$t('payment.country')}}</label>
          <select v-model="PaymentProcessor.cardCountry" :class="{invalid: validate.error('PaymentProcessor.cardCountry')}">
            <option :value="null" disabled hidden>{{$t('choose')}}...</option>
            <option :key="'country-paysafe-' + country.countryShortCode" :value="country.countryShortCode" v-for="country of CountryRegionData">
              {{country.countryName}}
            </option>
          </select>
        </div>
      </div>
      <label>{{$t('payment.card_number')}}</label>
      <div id="txtCardNumber"></div>
      <div class="columns">
        <div class="column">
          <label>{{$t('payment.expiration_date')}}</label>
          <div id="txtCardExpiry"></div>
        </div>
        <div class="column">
          <label for="card-ccv">CCV</label>
          <div id="txtCardCVV"></div>
        </div>
      </div>

      <label class="paysafeLabel">
        <svgicon :src="$to(securePaymentIcon)" :width="110" :height="22" style="margin-right: 4px;"></svgicon>
        <div class="powered-by">{{$t('payment.powered_by')}}</div>
        <svgicon class="paysafe-icon" src="icons/paysafe.svg" :width="70" :height="22"></svgicon>
      </label>
      <div class="paysafeError" v-show="PaymentProcessor.paysafeError">{{ PaymentProcessor.paysafeError }}</div>
    </div>

    <!-- ITC -->
    <div id="itc" v-if="PaymentProcessor.isPaymentMethod('itc') && PaymentProcessor.isItcReadyForPayment()">
        <div class="itc-subtitle">
          <svgicon src="icons/info.svg" :height="15" :width="15"></svgicon>
          {{$t('payment.account_with_credential').replace("{CREDENTIAL}", PaymentProcessor.itcCredentials)}}
        </div>
        <div class="input email-input">
          <label>{{$t('email')}}</label>
          <input type="text" :value="$user.username" readonly>
        </div>
        <div class="columns">
          <div class="column input">
            <label>{{ $t('payment.first_name') }}</label>
            <input type="text" :value="PaymentProcessor.itcAccountInformations.clientInformation.FirstName" readonly>
          </div>
          <div class="column input">
            <label>{{ $t('payment.last_name') }}</label>
            <input type="text" :value="PaymentProcessor.itcAccountInformations.clientInformation.LastName" readonly>
          </div>
        </div>
    </div>

    <div id="itc" v-if="PaymentProcessor.isPaymentMethod('itc') && !PaymentProcessor.isItcReadyForPayment()">
      <ErrorBox :visible="true" @click="errorVisible = false" always-visible>
        {{$t('payment.itc_account_not_found')}}  {{$t('payment.select_alternative_payment')}}
      </ErrorBox>

    </div>

    <!-- ONLINE METHOD -->
    <div class="online-payment" v-show="PaymentProcessor.isOnlinePaymentMethod() && !PaymentProcessor.isPaymentMethod('itc')">
      {{$t('payment.online_payment')}}
    </div>
  </form>
</template>

<script>
  import { PaymentProcessor } from "../../PaymentProcessor";
  import ErrorBox from "@/components/tools/ErrorBox";
  import CountryRegionData from "country-region-data";
  import CA from "../../lib/states/CA.json";
  import US from "../../lib/states/US.json";
  import GB from "../../lib/states/GB.json";
  import { Constant } from "@/util/Constant";

  export default {

    name: "paymentForm",

    components: { ErrorBox },

    data() {
      return {
        PaymentProcessor,
        Constant,
        states: {
          CA: CA.states,
          US: US.states,
          GB: GB.states
        },
        securePaymentIcon: {fr: 'icons/secure-fr.svg', en: 'icons/secure-en.svg' },
        CountryRegionData
      }
    },

    mounted() {
      this.checkItcBalance();
      /* TODO remove this code. Edge case for bugs with QR payment */
      this.$nextTick(() => {
        if (this.hidePaymentMethodSelection && this.paymentMethods.length === 1) {
          PaymentProcessor.paymentMethod = this.paymentMethods[0];
          this.$order.paymentMethod = this.paymentMethods[0];
        }
      });
    },

    props: {
      validate: Object,
      hidePaymentMethodSelection: Boolean
    },

    computed: {
      total() {
        return this.$store.state.order.getOrderTotal();
      },
      paymentProcessorSelectedCountryRegions() {
        if (PaymentProcessor.cardCountry) {
          let foundCountry = CountryRegionData.find(c => c.countryShortCode === PaymentProcessor.cardCountry);
          if (foundCountry && foundCountry.regions) {
            return foundCountry.regions;
          }
        }
        return [];
      },
      user() {
        return this.$user;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      years() {
        let years = [];
        let now = new Date();
        for (let i = now.getFullYear(); i < now.getFullYear() + 15; i++) {
          years.push(i);
        }
        return years;
      },
      paymentMethods() {
        if (!this.company) {
          return this.branch ? this.branch.getPaymentMethods().map(m => m.processor) : [];
        }
        //TODO remove itc if user is anonymous
        let OFFLINE_METHODS = ["cash","interac","mastercard","visa","amex"];
        let methods = JSON.parse(JSON.stringify(this.company.getPaymentMethods()));
        if (this.$user.isDispatchUser()) {
          for (let method of OFFLINE_METHODS) {
            if (methods.indexOf(method) === -1) {
              methods.push(method);
            }
          }
        }
        return methods;
      },
      canTip() {
        return this.company && this.company.hasTipEnabled();
      },
      tip() {
        return this.$order.tip.amount;
      }
    },

    watch: {
      "PaymentProcessor.paymentMethod"() {
        this.checkItcBalance();
        this.$nextTick(() => {
          PaymentProcessor.reinitializePayfactoSHC();
        });
      },
      "PaymentProcessor.savedCardID"(newValue, oldValue) {
        if (PaymentProcessor.isPaymentMethod('nmi') && PaymentProcessor.saveCardEnabled() && oldValue && !newValue) {
          PaymentProcessor.initializeNmi();
          //Needs to unmount the 3ds ui if 3ds is enabled
        }
      },
      tip(newValue, oldValue) {
        if (this.canTip && PaymentProcessor.isPaymentMethod("nmi")) {
          PaymentProcessor.initializeNmi();
        }
      }
    },

    methods: {
      continueToGlobalPayments() {
        this.$emit("trigger-validation");
        if (!this.validate.valid()) {
          this.validate.validateAllOnce();
          this.$forceUpdate();
          return;
        }
        PaymentProcessor.initializeGlobal();
      },
      hasPaymentMethod(method) {
        return this.paymentMethods.indexOf(method) > -1;
      },
      continueWithNmi() {
        showSpinner();
        this.$emit("trigger-validation", () => {
          this.$nextTick(() => {
            if (!this.validate.valid() || !PaymentProcessor.readyForPayment) {
              hideSpinner(true);
              this.validate.validateAllOnce();
              this.$forceUpdate();
              return;
            }
            if (!PaymentProcessor.isNmiFormValid()) {
              hideSpinner(true);
              return;
            }
            document.getElementById("btnPayNmi").click();
            setTimeout(() => {
              if (document.getElementsByClassName("CollectJSInvalid").length > 0) {
                hideSpinner(true);
              }
            }, 500);
          });
        });
      },
      checkItcBalance() {
        if (PaymentProcessor.paymentMethod === "itc") {
          PaymentProcessor.getItcBalance();
        }
      },
      async deleteToken(card) {
        let confirm = await showConfirmation(this.$t("warning"),this.$t("loyalty.remove_card_confirm").replace("{CARD}", card.last4));
        if (!confirm) {
          return;
        }
        showSpinner();
        let response = await this.$user.deleteBillingToken(card.id);
        hideSpinner();
        if (response) {
          PaymentProcessor.savedCardID = null;
          showAffirmation(this.$t("success"), this.$t("loyalty.remove_card_success"));
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    .payfactoLabel {
      display: flex;
      align-items: center;
      .payfactoLabel__secure {
        flex-basis: 0;
        flex-grow: 1;
      }
      .payfactoLabel__logo {
        flex-shrink: 0;
      }

      .svgicon {
        float: left;
      }
      .logo {
        background-color: black;
        padding: 10px 15px 5px 15px;
        max-width: 140px;
        float: right;
        border-radius: 5px;
      }
      .powered-by {
        margin-bottom: 2px;
        font-size: 10px;
        color: #808080 !important;
        text-align: left;
        padding-left: 10px;
      }
      img {
        max-width: 100%;
      }
    }
  }

  #paysafe label.paysafeLabel, .payfactoLabel {
    display: flex;
    align-items: flex-end;
    .powered-by {
      text-align: right;
      flex-grow: 1;
      white-space: nowrap;
      padding-right: 5px;
      padding-bottom: 1px;
    }
  }

  .paysafeError {
    background-color: #ffebeb;
    padding: 4px 8px;
    text-align: center;
    border-radius: 4px;
    margin-top: 6px;
    font-size: 15px;
  }

  #txtCardNumber, #txtCardExpiry, #txtCardCVV {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 10px;
    height: 44px;
    margin-bottom: 20px;
    background-color: white;
  }

  .payment {
    label {
      padding-left: 8px;
      display: block;
      color: #808080;
      fill: #808080;
      font-size: 14px;
      padding-bottom: 4px;
    }
    input, select {
      width: 100%;
      margin-bottom: 20px;
    }
    .card-infos {
      display: flex;
      flex-wrap: wrap;

      .top-holder {
        width: 100%;
      }

      .top {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        select {
          flex-grow: 1;
        }
      }
      .ccv {
        margin-bottom: 10px;
        input {
          width: 120px;
        }
      }

      .divisor {
        font-size: 18px;
        margin: 0 10px 20px 10px;
        color: #808080;
      }
    }
  }

  #iShopFoodApp.qr-payment .payment {
    .token-card {
      .content {
        flex-direction: column;
        padding: 5px 0;
      }
      .number, .exp {
        display: flex;
        align-items: center;
        label {
          padding-bottom: 0;
        }
      }
      .number {
        padding-bottom: 5px;
        label {
          display: none;
        }
        .value {
          font-size: 20px;
        }
      }

      .exp .value {
        padding-left: 10px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .offline-payment, .online-payment, .no-payment {
    color: grey;
    font-size: 16px;
    font-style: italic;
    text-align: justify;
  }
  .online-payment {
    margin-top: 10px;
  }

  .columns {
    display: flex;

    .column {
      flex-grow: 1;
      flex-basis: 0;
    }

    .column:not(:last-child) {
      margin-right: 15px;
    }
  }

  #cybersource {
    #cardholderName {
      margin-bottom: 0;
    }
    .form, .input, .columns {
      width: 100%;
    }

    #number-container, #securityCode-container {
      border: 1px solid #d9d9d9;
      background-color: white;
      border-radius: 5px;
      height: 43px;
      padding: 10px;
    }
  }

  .cybersourceLabel {
    display: flex !important;
    justify-content: space-between;

    .powered-by {
      text-align: right;
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  #iShopFoodApp .btn.btnContinue {
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
    padding: 20px;
  }

  #iShopFoodApp form.payment {
    .save-card {
      padding-top: 0;
    }
  }

  iframe#globalIframe {
    width: 100%;
  }

  #iShopFoodApp #itc {
    .email-input {
      padding-bottom: 0;
    }
  }
  #iShopFoodApp .itc-subtitle {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    fill: var(--primary-color);
    color: var(--primary-color) !important;

    .svgicon {
      margin-right: 8px;
    }
  }

</style>

<style lang="scss">
  #nmi, #nmi--SavedCard {
    #nmiPaymentVerificationTitle {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .svgicon {
        flex-shrink: 0;
        margin-right: 15px;
        fill: var(--primary-color);
      }
    }
    #txtCardNumber, #txtCardExpiry, #txtCardCVV {
      iframe {
        height: auto !important;
      }
    }
    .button-holder {
      display: flex;
      width: 100%;
    }
    #btnPayNmiFake {
      flex-grow: 1;
      cursor: pointer;
      font-size: 20px;
      padding: 14px 20px;
    }
    #btnPayNmi {
      height: 0;
      opacity: 0;
      width: 0;
      overflow: hidden;
    }
  }
  #modalAffirmation .itc-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  /* Payfacto Secure Hosted Checkout */
  #shc-fields {
    iframe {
      width: 100% !important;
      min-height: 450px !important;
    }
  }

  .googlepaybutton__container, .applepaybutton__container {
    margin-bottom: 10px;
  }
</style>
