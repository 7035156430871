<template>
  <div id="loyaltyAccounts">

    <MenuHeader :text="$t('loyalty.account_management')" icon="icons/wallet.svg" show-back-button></MenuHeader>

    <div class="page-content" :class="{mobile: $store.getters.isMobileOrTablet}">

      <div class="title card">
        <div class="container">
          <div class="title">{{$t('loyalty.account_list')}}</div>
          <div class="text">{{$t('loyalty.account_list_description')}}</div>

          <div class="actions">
            <button class="btn btn-secondary" v-if="accounts.length > 0" @click="transfer">
              <svgicon src="icons/transfer.svg" :style="{fill: secondaryButtonTextColor}" :height="16" :width="16"></svgicon>
              {{$t('loyalty.transfer_amount')}}
            </button>
          </div>
        </div>
      </div>

      <Panel class="panel-manager" v-if="!!$user.manager" :title="$t('loyalty.manager_account')" icon="icons/info.svg" collapsible>
        <div class="description">{{$t("loyalty.manager_account_description")}}</div>
        <div class="manager">
          <div class="info" v-if="$user.manager.firstName || $user.manager.lastName">
            <label>{{$t('loyalty.full_name')}}</label>
            <div class="value">{{$user.manager.firstName}} {{$user.manager.lastName}}</div>
          </div>
          <div class="info">
            <label>{{$t('profile.email')}}</label>
            <div class="value">{{$user.manager.email}}</div>
          </div>
          <button class="btn btn-secondary" @click="dissociateParent">
            <svgicon src="icons/trash.svg" :height="16" :width="16" :style="{fill: secondaryButtonTextColor}"></svgicon>
            {{$t('loyalty.dissociate')}}
          </button>
        </div>
      </Panel>

      <Panel class="new-account" :title="$t('loyalty.add_account_title')" icon="icons/add-user.svg" collapsible :collapsed="accounts.length > 0">

          <p v-if="!showSearchFrom">{{$t('loyalty.add_account_short')}}</p>

        <div class="buttons" :class="{hidden: showSearchFrom}">
          <button class="btn btn-primary" @click="showSearchFrom = true" v-show="false">{{$t('loyalty.add_account')}}</button>
          <button class="btn btn-primary" @click="showSearchAccount">{{$t('loyalty.new_account')}}</button>
          <button class="btn btn-secondary" @click="pendingUserManagementRequests">
            <svgicon src="icons/clock.svg" :height="16" :width="16" :style="{fill: secondaryButtonTextColor}"></svgicon>
            {{$t('loyalty.view_pending_requests')}}
          </button>
        </div>

        <div class="complete-form" v-if="showSearchFrom">
          <p>{{$t('loyalty.add_account_description')}}</p>
          <div class="account-form">
            <div class="group">
              <label>{{$t('email')}}</label>
              <div class="form">
                <input type="text" v-model="newAccountEmail" :class="{invalid: $validate.error('newAccountEmail', 0)}">
                <button class="btn btn-primary" @click="searchAccount">{{$t('loyalty.search_account')}}</button>
              </div>
            </div>
          </div>
          <a class="small-link" href="javascript:void(0)" @click="createAccount">{{$t('loyalty.new_account_description')}}</a>
          <button class="back btn btn-secondary" @click="showSearchFrom = false">{{$t('back')}}</button>
        </div>

      </Panel>

      <div class="accounts">
        <div class="account card" v-for="account of accounts">
          <div class="bigcircle" :style="{
        'background': isDesktop
          ? 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 45%)'
          : 'linear-gradient(to bottom left, ' + gradientColor + ', ' + primaryColor + ' 75%)'
          }">
          </div>

          <div class="balance">
            <div class="title" :style="{borderColor: gradientColor}">{{$t('balance')}}</div>
            <div class="value">
              <div class="amount">{{ Math.floor(account.balance) }}</div>
              <div class="cents">{{ leftPad(Math.round(account.balance % 1 * 100), 2, "0") }}$</div>
            </div>
          </div>

          <div class="account-details">
            <template v-if="account.user">
              <div class="name" v-show="account.user.firstName || account.user.lastName">
                <div class="data">
                  <label>{{$t('loyalty.full_name')}}</label>
                  <div class="value">{{account.user.firstName}} {{account.user.lastName}}</div>
                </div>
              </div>
              <div class="email">
                <div class="data">
                  <label>{{$t('loyalty.username')}}</label>
                  <div class="value">{{account.user.username}}</div>
                </div>
              </div>
              <div class="edit-icon" @click="edit(account)">
                <svgicon src="icons/pencil.svg" :height="20" :width="20"></svgicon>
              </div>
              <div class="remove-icon" @click="dissociateChild(account)">
                <svgicon src="icons/trash.svg" :height="20" :width="20"></svgicon>
              </div>
            </template>
            <div class="buttons">
              <router-link :to="{name: 'reload', query: {id: account.id, redirect: 'accounts'}}" class="btn btn-secondary" v-if="isRechargeEnabled">
                <svgicon src="icons/reload.svg" :height="16" :width="16" :style="{fill: secondaryButtonTextColor}"></svgicon>
                {{ $t("loyalty.reload") }}
              </router-link>
              <button class="btn btn-secondary"  @click="orderAsAccount(account.id)" v-if="account.canPay()">
                <svgicon src="icons/comptoir.svg" :height="16" :width="16" :style="{fill: secondaryButtonTextColor}"></svgicon>
                {{$t('loyalty.order')}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <NewAccountModal
        :visible="showNewAccountModal"
        :user-email="newAccountEmail"
        :edit-account="editAccount"
        @created="onSuccess"
        @closed="newAccountModalClosed"
    ></NewAccountModal>

    <TransferModal
        :visible="showTransferModal"
        @created="onTransfer"
        @closed="showTransferModal = false"
    ></TransferModal>

    <PendingUserManagementRequestsModal
        :visible="showPendingUserManagementRequestsModal"
        @closed="showPendingUserManagementRequestsModal = false"
    ></PendingUserManagementRequestsModal>

  </div>
</template>

<script>
  import MenuHeader from "../../components/menu/MenuHeader.vue";
  import NewAccountModal from "../../components/loyalty/NewAccountModal.vue";
  import TransferModal from "../../components/loyalty/TransferModal.vue";
  import PendingUserManagementRequestsModal from "../../components/loyalty/PendingUserManagementRequestsModal.vue";
  import tinycolor from "tinycolor2";
  import User from "../../models/UserModel";

  export default {
    name: "loyaltyAccounts",

    components: { MenuHeader, NewAccountModal, TransferModal, PendingUserManagementRequestsModal },

    data() {
      return {
        newAccountEmail: null,
        showSearchFrom: false,
        showNewAccountModal: false,
        editAccount: null,
        showPendingUserManagementRequestsModal: false,
        showTransferModal: false
      }
    },

    validate: [
      {
        newAccountEmail: {
          required: true,
          email: true
        }
      }
    ],

    async mounted() {
      this.$store.commit("showNavigationCart", true);

      if (!this.$user || !this.$user.getLoyaltyAccount()) {
        this.$router.push(this.$router.generate("/menu"));
      } else {
        await this.$user.update();
      }
    },

    computed: {
      primaryColor() {
        return this.$store.state.primaryButtonColor || "#ed2937";
      },
      secondaryButtonTextColor() {
        return this.$store.state.secondaryButtonTextColor || "#fff";
      },
      payColor() {
        return tinycolor(this.primaryColor).lighten(25).toHexString();
      },
      gradientColor() {
        if (this.isDesktop) {
          return tinycolor(this.primaryColor).lighten(25).toHexString();
        }
        return tinycolor(this.primaryColor).lighten(50).toHexString();
      },
      isDesktop() {
        return this.$store.state.screenSize === 'md' || this.$store.state.screenSize === 'lg';
      },
      accounts() {
        return this.$user.getChildLoyaltyAccounts();
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      isRechargeEnabled() {
        return this.branch && this.branch.isRechargeEnabled();
      },
    },

    methods: {
      leftPad(str, length, pad) {
        return pad.repeat(Math.max(length - String(str).length, 0)) + String(str);
      },
      showSearchAccount() {
        this.showSearchFrom = true;
      },
      createAccount() {
        this.showNewAccountModal = true;
      },
      newAccountModalClosed() {
        this.showNewAccountModal = false;
        setTimeout(() => {
          this.editAccount = null;
        }, 300);
      },
      edit(account) {
        this.showNewAccountModal = true;
        this.editAccount = account;
      },
      async dissociateParent() {
        if (!this.$user.manager) {
          return;
        }
        let confirm = await showConfirmation(this.$t("warning"), this.$t("loyalty.confirm_remove_parent").replace("{USER}", this.$user.manager.username));
        if (!confirm) {
          return;
        }
        showSpinner();
        let response = await this.$user.dissociateAccount(this.$user.manager.id);
        hideSpinner();
        if (response) {
          this.$user.manager = null;
          showAffirmation(this.$t("success"), this.$t("loyalty.remove_child_success"));
        }
      },
      async dissociateChild(account) {
        let confirm = await showConfirmation(this.$t("warning"), this.$t("loyalty.confirm_remove_child").replace("{USER}", account.user.username));
        if (!confirm) {
          return;
        }
        showSpinner();
        let response = await this.$user.dissociateAccount(account.user.id);
        hideSpinner();
        if (response) {
          showAffirmation(this.$t("success"), this.$t("loyalty.remove_child_success"));
        }
      },
      transfer() {
        this.showTransferModal = true;
      },
      pendingUserManagementRequests() {
        this.showPendingUserManagementRequestsModal = true;
      },
      async onTransfer() {
        this.showTransferModal = false;
        this.reloadUserAccount();
      },
      async onSuccess() {
        this.showNewAccountModal = false;
        this.editAccount = null;
        this.newAccountEmail = null;
        this.reloadUserAccount();
      },
      async reloadUserAccount() {
        showSpinner();
        await this.$user.update();
        hideSpinner();
      },
      orderAsAccount(accountId) {
        this.$order.accountId = accountId;
        this.$router.push(this.$router.generate("/menu"));
      },
      async searchAccount() {
        if (!this.$validate.valid(0)) {
          showAffirmation(this.$t('error.title'), this.$t('register.invalid_email_address'));
          return;
        }
        let userId = await this.$user.userExists(this.$store.state.currentBranch.id, this.newAccountEmail);
        if (userId) {
          if (!this.$user.hasLoyaltyAccountWithUserId(userId)) {
            let response = await this.$user.sendUserManagementRequest(userId);
            if (response) {
              showAffirmation(this.$t("loyalty.email_sent_title"), this.$t('loyalty.email_sent'));
              return;
            }
          }
          showAffirmation(this.$t("error.title"), this.$t('loyalty.invitation_error'));
          this.showSearchFrom = false;
          this.newAccountEmail = null;
        } else {
          let response = await showConfirmation(this.$t("loyalty.account_not_found"),this.$t('loyalty.email_not_found'),this.$t('loyalty.new_account_short'), this.$t('back'));
          if (response) {
            this.createAccount();
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content.mobile {
    padding: 0;

    .title {
      text-transform: none;
      font-size: 18px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid rgb(217,217,217);
    }
    .text {
      font-size: 14px;
    }
    .card:not(.account) {
      border-top: 1px solid rgb(230,230,230);
      border-bottom: 1px solid rgb(230,230,230);
      border-radius: 0;
      box-shadow: none;
    }
    .group, .form {
      width: 100%;

      .btn {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    .buttons .btn {
      width: 100%;
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .actions .btn {
      width: 100%;
    }
    .new-account {
      margin-bottom: 5px;
    }
  }

  .complete-form {
    .small-link {
      display: block;
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.1;
    }
    .back {
      margin-top: 15px;
    }
  }

  #iShopFoodApp.xs .accounts, #iShopFoodApp.sm .accounts {
    margin-right: 0;

    .account {
      width: 100%;

      .data label {
        font-size: 13px;
      }

      .account-details .name {
        font-size: 18px;
      }
      .account-details .email {
        font-size: 14px;
      }
    }
  }

  #iShopFoodApp .buttons {
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-right: 10px;
      display: flex;

      .svgicon {
        margin-right: 10px;
      }
    }
  }

  .group .form .btn {
    margin-left: 10px;
  }

  #iShopFoodApp .accounts {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: -20px;

    .account {
      width: calc(50% - 20px);
      margin-right: 20px;
      padding: 20px 20px 20px 20px;
      overflow: hidden;
      position: relative;
      min-height: 150px;
      margin-bottom: 5px;

      .edit-icon, .remove-icon {
        fill: var(--primary-color);
        position: absolute;
        cursor: pointer;
        top: 0;
      }

      .edit-icon {
        padding: 15px 10px 15px 15px;
        right: 45px;
      }

      .remove-icon {
        right: 0;
        padding: 15px 15px 15px 10px;
      }

      .balance {
        width: 160px;
        padding-right: 40px;
        min-width: 160px;
        max-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1;
        z-index: 1;
        margin-bottom: 10px;
        margin-top: 20px;
        .title {
          font-size: 13px;
          border: none;
          padding-bottom: 0;
          font-weight: 500;
        }
        .value {
          display: flex;
          align-items: flex-start;
        }
        .amount {
          color: white;
          font-size: 40px;
          font-weight: 900;
        }
        .cents {
          color: white;
          font-size: 30px;
          font-weight: 900;
        }
      }

      .data {
        label {
          font-size: 12px;
          margin-bottom: 4px;
          display: inline-block;
          font-weight: normal;
        }
      }

      .account-details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .name, .email {
          position: relative;
          font-weight: bold;
          word-break: break-word;
          font-size: 18px;
          line-height: 1;
          display: flex;
          align-items: center;
        }
        .name {
          margin-bottom: 10px;
        }
        .icon {
          margin-right: 15px;
          flex-shrink: 0;
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        .btn {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 10px;
          max-width: 150px;
          width: 150px;
          font-size: 14px;
          .svgicon {
            margin-right: 15px;
          }
        }
      }

      .bigcircle {
        width: 300px;
        height: 300px;
        left: initial;
        bottom: initial;
        right: calc(100% - 160px);
        top: 50%;
        position: absolute;
        border-radius: 50%;
        transform: translateY(-50%);
        z-index: 0;
      }
    }
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .container {
      flex-grow: 1;
    }

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
    }
    .text {
      color: #808080;
    }
  }

  .account-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .group {
      display: flex;
      flex-direction: column;

      label {
        color: #808080;
        font-size: 14px;
        line-height: 1;
        padding-bottom: 4px;
      }
    }
  }

  .actions {
    padding: 20px 0;

    .svgicon {
      margin-right: 10px;
    }
  }

  #iShopFoodApp .panel-manager {
    .description {
      margin-bottom: 15px;
    }

    .manager {
      display: flex;
      flex-wrap: wrap;
      .btn {
        margin-bottom: 10px;
        .svgicon {
          margin-right: 10px;
        }
      }
      .info {
        margin-bottom: 10px;
        padding-right:30px;

        label {
          color: #808080 !important;
        }

        .value {
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }

  #iShopFoodApp.xs .panel-manager .manager {
    .btn {
      margin-top: 20px;
    }
  }

</style>
