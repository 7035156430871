import { DateTime } from "luxon";
import { LocalizeStore } from "@/lib/localize";
import { Constant } from "@/util/Constant";

/**
 * @typedef ReleaseNoteDefinition
 * @property {number} id
 * @property {ReleaseLogDescriptionDefinition} description
 * @property {string} name
 * @property {datetime} createdAt
 * @property {string} releaseDate
 * @property {string} version
 */

/**
 * @typedef ReleaseNoteDescriptionDefinition
 * @property {{en: string, fr: string}} fixed
 * @property {{en: string, fr: string}} improved
 * @property {{en: string, fr: string}} new
 */

export default class ReleaseNote {

  /**
   * @param {ReleaseNoteDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.description =  definition.description;
    this.name = definition.name;
    this.createdAt = definition.createdAt;
    this.releaseDate = definition.releaseDate;
    this.version = definition.version;
  }

  /**
   * @returns {string} return version number as string
   **/
  getVersion() {
    return this.version;
  }

  /**
   * @returns {{en: string, fr: string}} return translatable object for fixed description field
   **/
  getDescriptionFixed() {
    if (this.description) {
      return this.description.fixed;
    }
    return {en: '', fr: ''};
  }

  /**
   * @returns {{en: string, fr: string}} return translatable object for improved description field
   **/
  getDescriptionImproved() {
    if (this.description) {
      return this.description.improved;
    }
    return {en: '', fr: ''};
  }

  /**
   * @returns {{en: string, fr: string}} return translatable object for new description field
   **/
  getDescriptionNew() {
    if (this.description) {
      return this.description.new;
    }
    return {en: '', fr: ''};
  }

  /**
   * @returns {string} return formatted release date taking into account the locale
   **/
  getReleaseDateAsFormattedString() {
    if (this.releaseDate) {
      try {
        let date = DateTime.fromFormat(this.releaseDate, Constant.API_LUXON_DATE_ONLY_FORMAT);
        return date.setLocale(LocalizeStore.state.locale).toLocaleString(DateTime.DATE_HUGE);
      } catch(e) {
        console.log(e);
        return '';
      }
    }
    return '';
  }

  /**
   * @returns {string} return range in days from release
   **/
  getRangeFromReleaseToNow() {
    if (this.releaseDate) {
      try {
        let date = DateTime.fromFormat(this.releaseDate, Constant.API_LUXON_DATE_ONLY_FORMAT);
        let diff = DateTime.now().diff(date, ["days"]).days;
        return Math.floor(diff).toString();
      } catch(e) {
        console.log(e);
        return '';
      }
    }
    return '';
  }

}