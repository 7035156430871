export default

// Background color property
"#iShopFoodApp .btn.btn-primary, " +
"#iShopFoodApp .btn.btn-primary *, " +
"#iShopFoodApp .buttons .next, " +
"#iShopFoodApp #itemCountPill, " +
"#iShopFoodApp #profile .card .card-content .card-content-left, " +
"#iShopFoodApp .mobile-steps-bar .buttons .active, " +
"#iShopFoodApp #mobileNavigation .menu-items .item.router-link-active, " +
"#iShopFoodApp .order-card .btn, " +
"#iShopFoodApp .error-box, " +
"#iShopFoodApp .error-box span, " +
"#iShopFoodApp #cart .promo .circle, " +
"#iShopFoodApp .facturation .panel-right .promo .dot, " +
"#iShopFoodApp .facturation .panel-right .tip .dot, " +
"#iShopFoodApp .facturation .panel-left .tip-amount .selected, " +
"#iShopFoodApp .loyality-card .panel-right .gift-card .dot, " +
"#iShopFoodApp .error-box::after, " +
"#iShopFoodApp #cartTotal .orderButton .btn { " +
"  background-color: var(--branding-primary-color) !important; " +
"} " +

// Color property
"#iShopFoodApp .btn.btn-secondary, " +
"#iShopFoodApp input[type=checkbox] + label:after, " +
"#iShopFoodApp #cart .promo-available .name, " +
"#iShopFoodApp #cart .promo-available .svgicon, " +
"#iShopFoodApp input[type=checkbox] + label:before, " +
"#iShopFoodApp input[type=radio] + label:after, " +
"#iShopFoodApp #history .orders .order .details, " +
"#iShopFoodApp #login a, " +
"#iShopFoodApp #profile a, " +
"#iShopFoodApp #register a, " +
"#iShopFoodApp .facturation .panel-left .tip-amount li, " +
"#iShopFoodApp #cart .promo .amount, " +
"#iShopFoodApp .buttons .back { " +
"  color: var(--branding-primary-color) !important; " +
"} " +

// Fill property
"#iShopFoodApp #company .card-right .card-big-title .icons .svgicon, " +
"#iShopFoodApp #history .orders .order .details .svgicon, " +
"#iShopFoodApp .orders .order.selected .favorite .svgicon, " +
"#iShopFoodApp #navigation .socials .social, " +
"#iShopFoodApp #orderDetails .modal-content .modal-header .close .svgicon, " +
"#iShopFoodApp #mobileNavigation .menu-items .item, " +
"#iShopFoodApp .card .svgicon.step-on, " +
"#iShopFoodApp .modal .modal-header .svgicon, " +
"#iShopFoodApp .order-card .card-header .svgicon, " +
"#iShopFoodApp .order-card .svgicon, " +
"#iShopFoodApp .orders .order.active .favorite .svgicon, " +
"#iShopFoodApp .panel .checkmark .svgicon, " +
"#iShopFoodApp #cart .promo-available .name, " +
"#iShopFoodApp #cart .promo-available .svgicon, " +
"#iShopFoodApp .quantity-selector .svgicon, " +
"#iShopFoodApp .quantity-selector .svgicon, " +
"#iShopFoodApp .recommendations .svgicon, " +
"#iShopFoodApp .recommendations .title .svgicon, " +
"#iShopFoodApp .shop .tabs .tab.active, " +
"#iShopFoodApp #cart .title .svgicon, " +
"#iShopFoodApp #cart .header .icon, " +
"#iShopFoodApp .pageTitle .icon, " +
"#iShopFoodApp #cart.mobile .header, " +
"#iShopFoodApp .navigation-header .tooltip .svgicon, " +
"#iShopFoodApp .toggleMenu, " +
"#iShopFoodApp .illustration, " +
"#iShopFoodApp #navigation .branches, " +
"#iShopFoodApp #navigation .business, " +
"#iShopFoodApp #navigation .language, " +
"#iShopFoodApp #navigation .menu .header, " +
"#iShopFoodApp .title .titleIcon, " +
"#iShopFoodApp .menuHeader .select .arrow { " +
"  fill: var(--branding-primary-color) !important; " +
"} " +

// Border property
"#iShopFoodApp input[type=date]:focus, " +
"#iShopFoodApp input[type=number]:focus, " +
"#iShopFoodApp input[type=password]:focus, " +
"#iShopFoodApp #cart .header, " +
"#iShopFoodApp .pageTitle, " +
"#iShopFoodApp input[type=text]:focus, " +
"#iShopFoodApp #rootContainer .category.router-link-active .image, " +
"#iShopFoodApp #rootContainer .category.router-link-active .no-image, " +
"#iShopFoodApp #mobileNavigation .menu-items .item.router-link-active, " +
"#iShopFoodApp #navigation .menu .categories .category.router-link-active .image, " +
"#iShopFoodApp #navigation .menu .categories .category.router-link-active .no-image, " +
"#iShopFoodApp #item-basic.quickAdd .buttonContainer .btn, " +
"#iShopFoodApp .btn.btn-secondary, #iShopFoodApp .buttons .back, " +
"#iShopFoodApp select:focus, " +
"#iShopFoodApp textarea:focus { " +
"  border-color:  var(--branding-primary-color) !important; " +
"} ";
