//@ts-check
import Axios from "axios";
import Store from "./store";

class NetworkMonitor {

  constructor() {
    this.events = ["online", "offline", "load", "socket_disconnected", "socket_connected"];
    this.statusTimeout = 5000;
    this.offline = false;
  }

  init() {
    this.events.forEach((event) => window.addEventListener(event, this.checkNetwork.bind(this)));
  }

  destroy() {
    this.events.forEach((event) => window.removeEventListener(event, this.checkNetwork.bind(this)));
  }

  async checkNetwork() {
    try {
      let res = await Axios.get(Store.getters.urlServer + "/api/status", {
        timeout: this.statusTimeout
      });

      if (res.status !== 200) {
        this.setOffline();
      } else if (Store.state.offline || this.offline) {
        this.setOnline();
      }
    } catch (e) {
      this.setOffline();
    }
  }

  isOffline() {
    return this.offline;
  }

  setOnline() {
    Store.state.offline = false;
    this.offline = false;
  }

  setOffline() {
    Store.state.offline = true;
    this.offline = true;
  }

}

let networkMonitor = new NetworkMonitor();
export { networkMonitor as NetworkMonitor };