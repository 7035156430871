<template>
  <div id="qr-payment__header">
    <div class="header__left-icon" @click="$emit('back')" :class="{'visibility-hidden': hideBackButton}">
      <svgicon src="icons/back.svg" :width="24" :height="24"></svgicon>
    </div>
    <div class="header__title" v-if="!showLogo">
      <template v-if="$order.tableNumber !== DEMO_TABLE">{{$t("qr_payment.table")}} {{$order.tableNumber}}</template>
      <template v-else>{{$t("qr_payment.table")}} 10</template>
    </div>
    <div class="header__logo" v-if="showLogo">
      <img :src="logo">
    </div>
    <div class="header__right-icon">
      <FloatingLocaleSwitcher text display-all short></FloatingLocaleSwitcher>
    </div>
  </div>
</template>

<script>
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";

  export default {
    name: "qrPaymentHeader",

    components: { FloatingLocaleSwitcher },

    data() {
      return {
        DEMO_TABLE
      }
    },

    props: {
      showLogo: Boolean,
      hideBackButton: Boolean
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      logo() {
        if (!this.branch || !this.branch.horizontalLogo) {
          return;
        }
        return this.$store.state.urlFileServer + this.branch.horizontalLogo.url;
      }
    },
  }
</script>

<style lang="scss" scoped>
  #qr-payment__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #e6e6e6;
    background-color: white;
    fill: var(--primary-color);
    flex-shrink: 0;

    .visibility-hidden {
      visibility: hidden !important;
      pointer-events: none;
    }
  }
  .header__left-icon {
    padding: 18px;
  }
  .header__right-icon {
    width: 60px;
    height: 60px;
    position: relative;
  }
  .header__logo {
    img {
      height: 40px;
      width: auto;
    }
  }
</style>
