<template>
  <div id="cookiesPolicy">

    <PageTitle show-back-button>
      <svgicon class="icon" src="icons/safety.svg" :height="20" :width="20"></svgicon>
      {{$t("title.cookiesPolicy")}}
    </PageTitle>

    <div class="inner-page" v-if="locale === 'fr'">
      <section>
        <h3>Consentement</h3>
        <p>En naviguant sur <b>iShopFood</b>, vous acceptez l'utilisation de certains témoins (cookies) en accord avec la politique de témoins suivante.
          Une bannière à cet effet apparaitra au bas du site web lors de votre première utilisation de la plateforme.
          La bannière ne s'affichera pas dans vos visites subséquentes, mais vous pouvez toujours retirer votre consentement en tout temps en modifiant vos paramètres de témoin sur votre navigateur web.</p>
      </section>
      <section>
        <h3>Comment désactiver les témoins</h3>
        <p>Si vous n'acceptez pas notre utilisation des témoins sur votre navigateur web, il vous est possible de les supprimer ou de les désactiver en suivant les instructions suivantes selon votre navigateur:</p>
        <ul>
          <li><ExternalLink url="https://support.google.com/chrome/answer/95647?hl=fr">Google Chrome</ExternalLink></li>
          <li><ExternalLink url="https://support.mozilla.org/fr/products/firefox/protect-your-privacy/cookies">Mozilla Firefox</ExternalLink></li>
          <li><ExternalLink url="https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</ExternalLink></li>
          <li><ExternalLink url="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</ExternalLink></li>
          <li><ExternalLink url="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">Apple Safari pour Mac</ExternalLink></li>
          <li><ExternalLink url="https://support.apple.com/fr-ca/HT201265">Safari sur iPhone, iPad ou iPod touch</ExternalLink></li>
        </ul>
        <p>Vous pouvez aussi bloquer les témoins en téléchargeant un bloqueur de témoins. Veuillez noter qu'il est possible que certains services ne fonctionnent pas convenablement sans les témoins activés.</p>
      </section>
      <section>
        <h3>Que sont les témoins (cookies)?</h3>
        <p>Les témoins de navigateurs sont des petits fichiers textes créés par les sites webs que vous consultez.
          Ces fichiers sont téléchargés sur votre appareil. Quand vous visitez à nouveau un site web, ces fichiers sont renvoyés au site web afin de lui fournir des informations.
          Les témoins utilisés par <b>iShopFood</b> proviennent de plusieurs sources différentes.
          <br><br>
          Les témoins peuvent servir à se souvenir de vos préférences ou configurations. Ils servent à améliorer l'expérience des utilisateurs sur le web.
          <br><br>
          Certains témoins, de type <i>Session</i>, sont supprimés automatiquement lorsque vous quittez le site web. D'autres témoins persistent sur votre appareil pour une durée déterminée, qui peut aller de plusieurs jours à plusieurs années.
        </p>
      </section>
      <section>
        <h3>Quels types de témoins sont utilisés par iShopFood?</h3>
        <p>Nous avons catégorisé les différents types de témoins utilisé par la plateforme et ses partenaires en concordance avec le guide de référence fourni par ICC UK pour la réglementation sur l'utilisation des témoins.</p>
        <h4>Témoins absolument nécessaires</h4>
        <p>Ces témoins sont absolument nécessaires pour le bon fonctionnement de <b>iShopFood</b>.
          Par exemple, un partenaire de paiement pourrait conserver un identifiant unique à votre session pour s'assurer de la sécurité de votre transaction lors de votre achat.
          En désactivant ces témoins, certains comportements indésirables pourraient se produire sur la plateforme.</p>
        <h4>Témoins de fonctionnalité</h4>
        <p>Ces témoins sont nécessaires pour supporter certaines fonctionnalités de la plateforme. Ces témoins servent à se souvenir des vos préférences.
          Par exemple, un tel témoin pourrait être utilisé pour conserver la langue d'affichage d'une carte ou d'un système de paiement.
          Ces témoins sont anonymes et ne collectent aucune information permettant de vous identifier. Ils ne suivent pas vos activités en dehors de la plateforme.</p>
        <h4>Témoins de performance</h4>
        <p>Ces témoins nous permettent de faire un suivi sur les performances de <b>iShopFood</b> en nous fournnissant des rapports et des statistiques sur notre auditoire.
          Ils servent à améliorer la plateforrme et les données collectées sont anonymes.</p>
      </section>
      <section>
        <h3>D'où proviennent les témoins présents sur iShopFood?</h3>
        <p><b>iShopFood</b> utilise directement certains types de témoins. Cependant, il s'agit d'une plateforme qui utilise quelques services externes.
          En effet, nous faisons appel à des librairies qui fournissent différentes fonctionnalités pour la plateforme.
          Entres autres, nous utilisons des librairies pour l'affichage de cartes, pour la géolocalisation lors des commandes par livraison ainsi que pour traiter de façon sécuritaire les paiements par carte de crédit.</p>
        <p>Il est possible que ces librairies utilisent des témoins. Tous ces témoins sont catégorisés dans les groupes plus haut et servent au bon fonctionnement des différents services de la plateforme.</p>
      </section>
      <section>
        <h3>Changement à notre politique</h3>
        <p>Il est possible que nous faisions des changements à notre politique de témoins. Nous vous encourageons à vérifier cette page de temps en temps afin d'obtenir l'information à jour.
          Cette politique à été mise à jour en septembre 2021.</p>
      </section>
    </div>

    <div class="inner-page" v-if="locale === 'en'">
      <section>
        <h3>Consent</h3>
        <p>By browsing on <b>iShopFood</b>, you consent to the use of cookies in accordance with this Cookies Policy.
        A banner will be displayed at the bottom of the website on your first use of the platform.
        This banner will not appear on your subsequent visits, but you can always withdraw your consent at any time by updating your cookies parameters on your web browser.</p>
      </section>
      <section>
        <h3>How to disable cookies</h3>
        <p>If you do not agree to our use of cookies on your web browser, you have the possibility to delete or disable them by following the instructions defined for your browser below:</p>
        <ul>
          <li><ExternalLink url="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</ExternalLink></li>
          <li><ExternalLink url="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies">Mozilla Firefox</ExternalLink></li>
          <li><ExternalLink url="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</ExternalLink></li>
          <li><ExternalLink url="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</ExternalLink></li>
          <li><ExternalLink url="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple Safari for Mac</ExternalLink></li>
          <li><ExternalLink url="https://support.apple.com/en-us/HT201265">Safari for iPhone, iPad or iPod touch</ExternalLink></li>
        </ul>
        <p>You may also block cookie by downloading and installing a cookie blocker plugin on your browser. Please note that is it possible that some services on the website will not behave as expected if cookies are not activated.</p>
      </section>
      <section>
        <h3>What are cookies?</h3>
        <p>Cookies are small text files created by website you visit. These files are downloaded on your device.
          When you visit a website again, the stored information will be sent back to that website.
          Cookies used by <b>iShopFood</b> come from multiple services.
          <br><br>
          Cookies may be used to save your configuration or user preferences. They are generally used to improve the user experience online.
          <br><br>
          <i>Session</i> cookies are deleted automatically when you quit a website. There are also <i>persistent</i> cookies that will remain on your device for a determined duration, which could range from a few days to a few years.
        </p>
      </section>
      <section>
        <h3>What type of cookies are used by iShopFood?</h3>
        <p>We have categorized the different types of cookies used by the platform and its partners according to the ICC UK Cookie Guide. These categories are defined below.</p>
        <h4>Strictly necessary cookies</h4>
        <p>These cookies are essential for <b>iShopFood</b> to behave properly.
          For example, a payment partner may generate and store a unique identifier to recognize your session to make sure that your payment transaction is processed securely.
          By disabling these cookies, some unwanted behaviors might occur on the platform.</p>
        <h4>Functionality cookies</h4>
        <p>These cookies are necessary to support some features on the platform. These cookies are generally used to remember your user preferences.
          For example, they could be used to store your preferred language, to display a map or to display a payment system.
          These cookies are anonymous and collect no information that may identify you. They do not follow you or track your activity outside of the platform.</p>
        <h4>Performance cookies</h4>
        <p>These cookies are used to monitor the performance of  <b>iShopFood</b> by providing reports and statistics on our visitors.
          They are used to improve the platform and the data collected is anonymous.</p>
      </section>
      <section>
        <h3>Where are the cookies on iShopFood coming from?</h3>
        <p><b>iShopFood</b> uses directly a few type of cookies. However, this is a platform that uses a few external systems.
          We use libraries to provide us with multiple features for the platform.
          More precisely, we use libraries to display maps, geocode the user when they makes a delivery order or process secure credit card transactions.</p>
        <p>These libraries may use cookies. All of these cookies are also defined within the categories above. They are used for the different services to behave as expected on the platform and improve your user experience.</p>
      </section>
      <section>
        <h3>Changes to our Cookies Policy</h3>
        <p>It is possible that we update this Cookies Policy. We encourage you to review this policy from time to time to be aware of our up-to-date information.
          This policy has been updated in September 2021.</p>
      </section>
    </div>

  </div>
</template>

<script>
  import ExternalLink from "@/components/tools/ExternalLink.vue";

  export default {
    name: "CookiesPolicy",

    components: { ExternalLink },

    data() {
      return {

      }
    },

    computed: {
      locale() {
        return this.$ts.state.locale;
      }
    },

    mounted() {
      if (this.$route.params.company) {
        this.$store.commit("showNavigationCart", true);
      } else {
        this.$store.commit("showNavigationCart", false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #cookiesPolicy {
    * {
      font-family: "Roboto", sans-serif !important;
    }

    .inner-page {
      padding: 20px 100px 20px 40px;
      overflow-y: auto;
    }

    h3, h2, h1 {
      color: var(--primary-color) !important;
      font-family: "Roboto", sans-serif !important;
    }
    .externalLink {
      cursor: pointer;
      text-decoration: underline;
      color: var(--primary-color) !important;
    }

    @media screen and (max-width: 900px) {
      .inner-page {
        padding: 20px 15px 110px 15px;
      }
    }
  }
</style>
