import { Configuration } from "@/util/Configuration";

export class LogProxy {

  constructor() {
    this._oldConsoleLog = null;
    this._oldConsoleWarn = null;
    this._oldConsoleError = null;
  }

  start() {
    this._overwriteConsoleFunctions();
  }

  stop() {
    this._restoreConsoleFunctions();
  }

  addLogLine(line, type = null) {

    // Read log and trim it first
    let log = (Configuration.get("log") || "");
    let split = log.split("\n");

    // Keep a maximum of 5000 lines
    while (split.length > 5000) {
      split.splice(0, 1);
    }

    // Remove old lines
    while (split.length > 0) {
      let first = split[0];
      let dateTime = moment(parseInt(first.split("]")[0].replace("[", "")));
      if (dateTime.isValid() && dateTime.isBefore(moment().subtract(5, "days"))) {
        split.splice(0, 1);
      } else {
        break;
      }
    }
    log = split.join("\n");

    // Add new line
    let formatted;
    if (type) {
      formatted = `[${Date.now()},${type}] ${line}`;
    } else {
      formatted = `[${Date.now()}] ` + line;
    }
    log += formatted + "\n";
    Configuration.set("log", log);

  }

  _overwriteConsoleFunctions() {

    this._oldConsoleLog = console.log;
    console.log = (...args) => {
      this.addLogLine(args.join(" "));
      this._oldConsoleLog.apply(console, args);
    };

    this._oldConsoleWarn = console.warn;
    console.warn = (...args) => {
      this.addLogLine(args.join(" "), "warn");
      this._oldConsoleWarn.apply(console, args);
    };

    this._oldConsoleError = console.error;
    console.error = (...args) => {
      this.addLogLine(args.join(" "), "error");
      this._oldConsoleError.apply(console, args);
    };

  }

  _restoreConsoleFunctions() {
    console.log = this._oldConsoleLog;
    console.warn = this._oldConsoleWarn;
    console.error = this._oldConsoleError;
  }

}