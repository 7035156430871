<template>
  <div id="posCashDrawer">

    <Panel :title="$t('cash_drawer.title')" icon="icons/tip.svg">
      <div class="panelCash__description">{{ $t("cash_drawer.cash_drawer_description") }}</div>
      <div class="panelCash__section">
        <div class="section__left">
          <div class="section__title">{{ $t("cash_drawer.start_of_day") }}</div>
          <div class="section__subtitle">{{ $t("cash_drawer.start_of_day_description") }}</div>
        </div>
        <div class="section__right">
          <div class="btn btn-secondary" :class="{ disabled: !!CashDrawer.drawerId }" @click="showStartModal">
            <svgicon src="icons/pencil-full.svg" :width="24" :height="24"></svgicon>
          </div>
        </div>
      </div>
      <div class="panelCash__section">
        <div class="section__left">
          <div class="section__title">{{ $t("cash_drawer.end_of_day") }}</div>
          <div class="section__subtitle">{{ $t("cash_drawer.end_of_day_description") }}</div>
        </div>
        <div class="section__right">
          <div class="btn btn-secondary" :class="{ disabled: !CashDrawer.drawerId }" @click="showEndModal">
            <svgicon src="icons/pencil-full.svg" :width="24" :height="24"></svgicon>
          </div>
        </div>
      </div>
      <div class="panelCash__section">
        <div class="section__left">
          <div class="section__title">{{ $t("cash_drawer.remove_money") }}</div>
          <div class="section__subtitle">{{ $t("cash_drawer.remove_money_description") }}</div>
        </div>
        <div class="section__right">
          <input type="number" v-model="depositAmount" :class="{ disabled: !CashDrawer.drawerId }" :disabled="!CashDrawer.drawerId">
          <button class="btn btn-secondary btn-100" :class="{ disabled: !CashDrawer.drawerId }" @click="depositMoney">
            {{ $t("cash_drawer.deposit") }}
          </button>
        </div>
      </div>
      <div class="panelCash__section">
        <div class="section__left">
          <div class="section__title">{{ $t("cash_drawer.add_money") }}</div>
          <div class="section__subtitle">{{ $t("cash_drawer.add_money_description") }}</div>
        </div>
        <div class="section__right">
          <input type="number" v-model="reloadAmount" :class="{ disabled: !CashDrawer.drawerId }" :disabled="!CashDrawer.drawerId">
          <button class="btn btn-secondary btn-100" :class="{ disabled: !CashDrawer.drawerId }" @click="reloadMoney">
            {{ $t("cash_drawer.reload") }}
          </button>
        </div>
      </div>
    </Panel>

    <Modal ref="modalStart">
      <ModalHeader :title="$t('cash_drawer.start_of_day')" icon="icons/branch.svg" @close="hideStartModal"></ModalHeader>
      <div class="modal__description">{{ $t("cash_drawer.set_new_amount") }}</div>
      <div class="modal__inputs">
        <div class="input-group grow">
          <label for="">{{ $t("cash_drawer.start_of_day_amount") }}</label>
          <input type="number" class="right" v-model="startDayAmount">
        </div>
        <div class="input-group">
          <label for="">{{ $t("cash_drawer.expected_amount") }}</label>
          <div class="input-group__value">{{ $tc(CashDrawer.expected) }}</div>
        </div>
      </div>
      <div class="input-group margin">
        <label for="">{{ $t("cash_drawer.drawer_id") }}</label>
        <input type="text" v-model="startDayDrawerId">
      </div>
      <div class="input-group">
        <label for="">{{ $t("cash_drawer.justification") }}</label>
        <textarea name="" id="" cols="24" rows="5" v-model="startDayJustification"></textarea>
      </div>
      <ModalFooter>
        <Button @click="hideStartModal">
          <svgicon src="icons/back-full.svg" :width="24" :height="24"></svgicon>
          {{ $t("back") }}
        </Button>
        <div class="grow"></div>
        <Button class="primary" @click="startDay">
          <svgicon src="icons/check-thick.svg" :width="24" :height="24"></svgicon>
          {{ $t("confirm") }}
        </Button>
      </ModalFooter>
    </Modal>

    <Modal ref="modalEnd">
      <ModalHeader :title="$t('cash_drawer.end_of_day')" icon="icons/branch.svg" @close="hideEndModal"></ModalHeader>
      <div class="modal__description">{{ $t("cash_drawer.set_end_amount") }}</div>
      <div class="modal__inputs">
        <div class="input-group grow">
          <label for="">{{ $t("cash_drawer.end_of_day_amount") }}</label>
          <input type="number" class="right" v-model="endDayAmount">
        </div>
        <div class="input-group">
          <label for="">{{ $t("cash_drawer.expected_amount") }}</label>
          <div class="input-group__value">{{ $tc(CashDrawer.expected) }}</div>
        </div>
      </div>
      <div class="input-group">
        <label for="">{{ $t("cash_drawer.justification") }}</label>
        <textarea name="" id="" cols="24" rows="5" v-model="endDayJustification"></textarea>
      </div>
      <ModalFooter>
        <Button @click="hideEndModal">
          <svgicon src="icons/back-full.svg" :width="24" :height="24"></svgicon>
          {{ $t("back") }}
        </Button>
        <div class="grow"></div>
        <Button class="primary" @click="endDay">
          <svgicon src="icons/check-thick.svg" :width="24" :height="24"></svgicon>
          {{ $t("confirm") }}
        </Button>
      </ModalFooter>
    </Modal>

  </div>
</template>

<script>
  import { CashDrawer } from "@/util/pos/CashDrawer";
  import Modal from "@/components/modal/Modal.vue";
  import ModalFooter from "@/components/modal/ModalFooter.vue";
  import ModalHeader from "@/components/modal/ModalHeader.vue";
  import { mapActions } from "vuex";

  export default {

    components: { Modal, ModalHeader, ModalFooter },

    data() {
      return {
        depositAmount: "",
        reloadAmount: "",
        startDayAmount: "",
        startDayDrawerId: "",
        startDayJustification: "",
        endDayAmount: "",
        endDayJustification: "",
        CashDrawer
      };
    },

    computed: {
      formattedExpectedAmount() {
        return this.$tc(CashDrawer.expected);
      },
      company() {
        return this.$store.state.currentCompany;
      }
    },

    methods: {

      ...mapActions(["getCashDrawerState"]),

      showStartModal() {
        this.$refs.modalStart.show();
      },

      hideStartModal() {
        this.$refs.modalStart.hide();
      },

      showEndModal() {
        this.$refs.modalEnd.show();
      },

      hideEndModal() {
        this.$refs.modalEnd.hide();
      },

      async depositMoney() {
        let amount = parseFloat(this.depositAmount);
        if (isNaN(amount)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (amount <= 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          return;
        }
        showSpinner();
        let result = await CashDrawer.deposit(amount, this.company.currency);
        hideSpinner();
        if (!result.success) {
          toast({ message: this.$t("pos.readings.error_deposit"), title: this.$t("error"), type: "error" });
          return;
        }
        toast({ message: this.$t("pos.readings.deposit_success"), title: this.$t("success"), type: "success" });
        this.depositAmount = "";
      },

      async reloadMoney() {
        let amount = parseFloat(this.reloadAmount);
        if (isNaN(amount)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (amount <= 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          return;
        }
        showSpinner();
        let result = await CashDrawer.reload(amount, this.company.currency);
        hideSpinner();
        if (!result.success) {
          toast({ message: this.$t("pos.readings.error_reload"), title: this.$t("error"), type: "error" });
          return;
        }
        toast({ message: this.$t("pos.readings.reload_success"), title: this.$t("success"), type: "success" });
        this.reloadAmount = "";
      },

      async startDay() {
        let amount = parseFloat(this.startDayAmount);
        if (isNaN(amount)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (amount <= 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (this.startDayDrawerId === "") {
          toast({ message: this.$t("pos.readings.drawer_id_required"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (this.startDayJustification === "") {
          toast({ message: this.$t("pos.readings.justification_required"), title: this.$t("warning"), type: "warning" });
          return;
        }
        showSpinner();
        let result = await CashDrawer.startDay(amount, this.company.currency, this.startDayDrawerId, this.startDayJustification);
        hideSpinner();
        if (!result.success) {
          if (result.status === 404) {
            toast({ message: this.$t("pos.readings.drawer_not_found"), title: this.$t("error"), type: "error" });
          } else {
            toast({ message: this.$t("pos.readings.error_starting_day"), title: this.$t("error"), type: "error" });
          }
          return;
        }
        this.hideStartModal();
        this.startDayAmount = "";
        this.startDayDrawerId = "";
        this.startDayJustification = "";
      },

      async endDay() {
        let amount = parseFloat(this.endDayAmount);
        if (isNaN(amount)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (amount <= 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (this.endDayJustification === "") {
          toast({ message: this.$t("pos.readings.justification_required"), title: this.$t("warning"), type: "warning" });
          return;
        }
        showSpinner();
        let result = await CashDrawer.endDay(amount, this.company.currency, this.endDayJustification);
        hideSpinner();
        if (!result.success) {
          if (result.status === 404) {
            toast({ message: this.$t("pos.readings.drawer_not_found"), title: this.$t("error"), type: "error" });
          } else {
            toast({ message: this.$t("pos.readings.error_ending_day"), title: this.$t("error"), type: "error" });
          }
          return;
        }
        this.hideEndModal();
        this.endDayAmount = "";
        this.endDayJustification = "";
      }

    }

  };
</script>
  
<style lang="scss" scoped>
  .panelCash__description {
    color: var(--theme-color-3);
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 40px;
  }

  .grow {
    flex-grow: 1;
  }

  button {
    height: 56px;
    background-color: var(--background-color-3);
    border: none;
    border-radius: 12px;
    padding: 0 20px;
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    fill: var(--primary-color);
    cursor: pointer;

    .svgicon {
      margin-right: 16px;
    }

    &.primary {
      background-color: var(--primary-color);
      color: white;
      fill: white;
    }

    &.margin {
      margin-right: 10px;
    }
  }

  #iShopFoodApp .newmodal {
    .modal__description {
      color: var(--theme-color-3);
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      position: relative;

      &.margin {
        margin-bottom: 20px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      .svgicon {
        position: absolute;
        right: 16px;
        bottom: 16px;
        fill: var(--primary-color);
        pointer-events: none;
      }

      label {
        color: #808080;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 18px;
      }

      .text {
        font-size: 22px;
        font-weight: 500;
        white-space: nowrap;
      }

      input {
        height: 56px;
        font-size: 32px;
        &.right {
          text-align: right;
        }
      }

      textarea {
        background-color: var(--background-color-2);
        color: var(--theme-color);
        border-color: var(--border-color-5);
        font-size: 20px;
      }

      input,
      textarea {
        font-family: Roboto, sans-serif;
        border-width: 2px;
        border-radius: 12px;
      }
    }

    .modal__inputs {
      display: flex;
      margin-bottom: 20px;

      .input-group {
        .input-group__value {
          font-size: 32px;
          font-weight: 500;
          white-space: nowrap;
          margin-top: 11px;
        }
      }
    }

    .modal__inputs,
    .input-group {
      width: 100%;
    }
  }

  #iShopFoodApp .panelCash__section {
    display: flex;
    padding-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 40px;
      border-bottom: 1px dashed var(--border-color-4);
    }

    .section__left {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;

      .section__title {
        color: var(--primary-color);
        font-weight: bold;
      }

      .section__subtitle {
        color: var(--theme-color-2);
        font-size: 16px;
        font-weight: 500;
      }
    }

    .section__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 337px;

      input,
      .btn {
        height: 56px;
      }

      input {
        font-size: 32px;
        border-width: 2px;
        border-radius: 12px;
        text-align: right;
        flex-grow: 1;
        min-width: 1px;
      }

      .btn {
        margin-left: 20px;

        &.btn-100 {
          width: 100px;
        }
      }
    }
  }
</style>