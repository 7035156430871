import { ActionContext } from "vuex";
import Axios from "axios";
import {LocalizeStore} from "../../lib/localize";
import Store from "../../store";

export default {

  /**
   * Get a company's specific table Invoices
   * @param {ActionContext} context
   * @param {{company: string, table: number, invoice: string?}} param
   */
  async getQrPaymentTable(context, {
    company,
    table,
    invoice
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: `/api/at_the_table/order?company=${company}&table=${table}${invoice ? "&invoice=" + invoice : ""}`
      });
      return {
        success: true,
        table: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * Get a company's specific table Invoices
   * @param {ActionContext} context
   * @param {{company: string, table: number, invoice: string?}} param
   */
  async getQrPaymentInvoice(context, {
    company,
    table,
    invoice
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: `/api/at_the_table/invoice?company=${company}&table=${table}${invoice ? "&invoice=" + invoice : ""}`
      });
      if (response && response.code && response.code === 405) { //get_invoice_not_available
        return { success: false, status: 405 };
      }
      return { success: true, invoice: response };
    } catch (e) {
      return { success: false, status: e.status };
    }
  },

  /**
   * Get a company's specific table Invoices
   * @param {ActionContext} context
   * @param {{company: string, table: number, invoices: array?}} param
   */
  async getQrPaymentInvoices(context, {
    company,
    table,
    invoices
  }) {
    try {
      let url = `/api/at_the_table/invoices`;
      let response = await Axios.get(CONFIG.urlServer + url, {
        params: {
          company: company,
          table: table,
          invoices: invoices
        },
        headers: {
          Authorization: "Bearer " + context.state.user.token
        }
      });
      return { success: true, invoices: response.data.invoices };
    } catch (e) {
      return { success: false, status: e.status };
    }
  },

  /**
   * Pay a company's specific table Invoice
   * @param {ActionContext} context
   * @param {{company: string, table: number, invoice: number, processor: string, amount: number, currency: string}} param1
   */
  async payTableInvoice(context, data) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/at_the_table/pay",
        data: data
      });
      return {
        success: true,
        table: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status,
        response: e
      };
    }
  },

  async pingPosStreamServer(context, { companyId }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: `/api/company/${companyId}/ping-pos-stream`,
      });
      if (response && response.online) {
        return { success: true };
      }
      let error = response.error ? window.translateObject(response.error) : response.raw_error;
      console.error("error", error);
      return {
        success: false,
        response: error
      };
    } catch (e) {
      return {
        success: false,
        status: e.status,
        response: e
      };
    }
  }
};