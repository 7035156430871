<template>
  <div id="qr-payment__scan">

    <div class="scan__title">
      <div class="title__text">{{$t('qr_payment.scan.title')}}</div>
      <div class="title__help">{{$t('qr_payment.scan.help')}}</div>
      <div class="title__separator"></div>
    </div>

    <div class="scan__holder">
      <!--<QrcodeStream @init="loaded" @decode="onDecode" :track="trackQrCode">
        <div class="scan--loading" v-if="loading">
          <div class="scan--loading--text">{{$t('qr_payment.scan.loading')}}</div>
          <div class="dots"></div>
        </div>
        <div class="scan--error" v-if="error">
          <svgicon src="icons/no-camera.svg" :height="50" :width="50"></svgicon>
          <div class="scan--error--title">{{$t('qr_payment.scan.error')}}</div>
        </div>
      </QrcodeStream>-->
    </div>

    <div class="scan__footer">
      <button class="btn btn-primary" @click="back">{{$t('back')}}</button>
    </div>

  </div>
</template>

<script>
  /*import { QrcodeStream } from "vue-qrcode-reader";*/

  export default {
    name: "qrPaymentScan",

    //components: { QrcodeStream },

    data() {
      return {
        loading: false,
        error: false
      }
    },

    methods: {
      back() {
        this.$router.push(this.$router.generate("/table"));
      },
      onDecode(content) {
        console.log("decoded", content);
        setTimeout(() => {
          this.$router.push(this.$router.generate("/table?number=" + content));
        }, 500);
      },
      trackQrCode(detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

          ctx.strokeStyle = this.$store.state.primaryButtonColor;
          ctx.lineJoin = "round";
          ctx.lineWidth = 6;

          ctx.beginPath();
          ctx.moveTo(firstPoint.x, firstPoint.y);
          for (const { x, y } of otherPoints) {
            ctx.lineTo(x, y);
          }
          ctx.lineTo(firstPoint.x, firstPoint.y);
          ctx.closePath();
          ctx.stroke();
        }
      },
      async loaded(promise) {
        this.loading = true;
        try {
          await promise;
        } catch (e) {
          console.log("error loading camera", e);
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #qr-payment__scan {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .scan__holder {
    flex-grow: 1;
    border-radius: 10px;
    margin: 20px;
    overflow: hidden;
  }
  #iShopFoodApp .scan__footer {
    padding: 0 20px 20px 20px;

    .btn {
      width: 100%;
      padding: 20px;
    }
  }

  #iShopFoodApp .scan--loading, #iShopFoodApp .scan--error {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  #iShopFoodApp .scan--loading--text {
    color: #a8a8a8 !important;
    padding-bottom: 20px;
  }

  #iShopFoodApp .scan--error {
    .scan--error--title {
      padding: 20px;
      color: #e31818 !important;
      font-weight: bold;
    }
    .svgicon {
      fill: #e31818 !important;
    }
  }

  .scan__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 20px 0 20px;

    .title__text {
      font-weight: bold;
    }

    .title__help {
      font-size: 14px;
      margin-top: 15px;
    }
  }

  .title__separator {
    width: 100px;
    height: 6px;
    border-radius: 6px;
    background-color: var(--primary-color);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  /**
 * ==============================================
 * Dot Floating
 * https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */
  $dots-color: #ccc;
  .dots {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
  }

  .dots::before, .dots::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dots::before {
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloatingBefore 3s infinite ease-in-out;
  }

  .dots::after {
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $dots-color;
    color: $dots-color;
    animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
  }

  @keyframes dotFloating {
    0% {
      left: calc(-50% - 5px);
    }
    75% {
      left: calc(50% + 105px);
    }
    100% {
      left: calc(50% + 105px);
    }
  }

  @keyframes dotFloatingBefore {
    0% {
      left: -50px;
    }
    50% {
      left: -12px;
    }
    75% {
      left: -50px;
    }
    100% {
      left: -50px;
    }
  }

  @keyframes dotFloatingAfter {
    0% {
      left: -100px;
    }
    50% {
      left: -24px;
    }
    75% {
      left: -100px;
    }
    100% {
      left: -100px;
    }
  }
</style>

<style lang="scss">
#qr-payment__scan {
  .scan__holder video {
    border-radius: 10px;
  }
}
</style>
