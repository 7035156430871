import { ActionContext } from "vuex";
import store from "../../store";

export default {

  async registerLoyalty(context, cardNumber) {
    try {
      let response = await context.dispatch("request", {
        method: "PUT",
        url: "/api/me/register_loyalty",
        data: {
          existingCard: cardNumber ? cardNumber : undefined,
          newCard: cardNumber ? undefined : true,
          company: store.state.currentCompany.nameCanonical
        }
      });
      return { success: true };
    } catch (e) {
      return {
        success: false,
        error: JSON.parse(e.response)
      };
    }
  },

  /**
   * @param {ActionContext} context
   * @param {{id: number}} arg1
   */
  async getTransactionDetails(context, { id }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: `/api/loyalty_transactions/${id}`
      });
      return {
        success: true,
        details: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * @param {ActionContext} context
   * @param {{account: number, user: number, date: date, type: string, page: number, perPage: number}} data
   */
  async getTransactions(context, data) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/loyalty_transactions",
        data
      });
      return {
        success: true,
        transactions: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async getGiftCardInformation(context, {
    cardNumber,
    branchId
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/gift_cards",
        data: {
          branch: branchId,
          number: cardNumber
        }
      });
      if (response.data.length > 0) {
        return {
          success: true,
          card: response.data[0]
        };
      } else {
        return {
          success: false,
          status: 404
        };
      }
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  }
};