<template>
  <div id="hub" :class="{desktop: isDesktop, progress: layout === 'progress', quick: $store.state.quickMode,
      backgroundImage: branch && branch.backgroundImage && !isDesktop}" :style="backgroundImage">

    <MenuHeader :text="$t('loyalty.my_account')" icon="icons/wallet.svg"></MenuHeader>

    <div class="page-content" :class="{card: layout === 'card' && !isDesktop}">

      <CardHub v-if="layout === 'card' || !isRankEnabled"
        :primaryColor="primaryColor"
        :payColor="payColor"
        :gradientColor="gradientColor"
        :isRechargeEnabled="isRechargeEnabled"
        :userCanManage="userCanManage"
        :isUserManagementEnabled="isUserManagementEnabled"
        :isDesktop="isDesktop"
        :leftPad="leftPad"
      ></CardHub>

      <ProgressHub v-if="layout === 'progress' && isRankEnabled"
        :primaryColor="primaryColor"
        :gradientColor="gradientColor"
        :isDesktop="isDesktop"
        :isRechargeEnabled="isRechargeEnabled"
        :userCanManage="userCanManage"
        :isUserManagementEnabled="isUserManagementEnabled"
        :leftPad="leftPad"
      ></ProgressHub>

    </div>
  </div>
</template>

<script>

  import MenuHeader from "../../components/menu/MenuHeader.vue";
  import CardHub from "../../components/loyalty/CardHub.vue";
  import ProgressHub from "../../components/loyalty/ProgressHub.vue";
  import tinycolor from "tinycolor2";
  import BranchModel from "../../models/BranchModel";

  export default {

    name: "hub",

    components: { MenuHeader, CardHub, ProgressHub },

    async mounted() {
      this.$store.commit("showNavigationCart", true);
      if (!this.$user || !this.$user.getLoyaltyAccount()) {
        this.$router.push(this.$router.generate("/menu"));
      } else {
        this.branch.setHubAccessed();
        await this.$user.update();
      }
    },

    computed: {
      primaryColor() {
        return this.$store.state.primaryButtonColor || "#ed2937";
      },
      /** @returns {String} */
      payColor() {
        return tinycolor(this.primaryColor).lighten(25).toHexString(false);
      },
      /** @returns {BranchModel} */
      branch() {
        return this.$store.state.currentBranch;
      },
      /** @returns {String} */
      layout() {
        if (!this.branch || !this.branch.style || !this.branch.style.fidelity) {
          return "card";
        }
        return this.branch.style.fidelity.format;
      },
      /** @returns {String} */
      gradientColor() {
        if (this.isDesktop) {
          return tinycolor(this.primaryColor).lighten(25).toHexString(false);
        }
        return tinycolor(this.primaryColor).lighten(50).toHexString(false);
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      /** @returns {boolean} */
      isRankEnabled() {
        if (!this.branch || !this.branch.loyaltyConfig) {
          return false;
        }
        return this.branch.loyaltyConfig.data.rankEnabled;
      },
      /** @returns {boolean} */
      isUserManagementEnabled() {
        if (!this.branch) {
          return false;
        }
        return this.branch.userManagementEnabled;
      },
      /** @returns {boolean} */
      userCanManage() {
        return this.$user.getLoyaltyAccount() && this.$user.getLoyaltyAccount().canManage();
      },
      /** @returns {boolean} */
      isRechargeEnabled() {
        return this.branch && this.branch.isRechargeEnabled();
      },
      /** @returns {Object} */
      backgroundImage() {
        if (!this.branch || !this.branch.backgroundImage || this.isDesktop) {
          return null;
        }
        return { backgroundImage: "url('" + this.$store.state.urlFileServer + this.branch.backgroundImage.url + "') !important" }
      },
    },

    methods: {
      leftPad(str, length, pad) {
        return pad.repeat(Math.max(length - String(str).length, 0)) + String(str);
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp #hub:not(.desktop) {
    background-color: white;
    background-image: none !important;

    &.backgroundImage {
      .page-content {
        background-color: transparent !important;
      }
    }

    &:not(.progress) {
      .pageTitle {
        background-color: transparent !important;
      }
    }
  }

  #hub.desktop .page-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #iShopFoodApp #hub:not(.desktop) {
    &:not(.progress) .page-content {
      padding-top: 60px;
      margin-top: -60px;
      overflow-x: hidden;
    }
  }

  #iShopFoodApp .progress:not(.desktop) .page-content {
    padding: 30px 30px 0 30px;
    background-color: rgb(242,242,242);
    display: flex;
    flex-direction: column;
  }

</style>

<style lang="scss">

 #iShopFoodApp #hub:not(.desktop) .pageTitle {
    z-index: 1;
    position: relative;
    background-color: transparent !important;
    .toggleMenu {
      .svgicon {
        fill: white;
      }
    }
  }

</style>
