<template>
  <div id="cart" v-if="isScreenBigEnough" :class="{
      mobile: $store.getters.isMobileOrTablet,
      md: $store.state.screenSize == 'md',
      menuShown: $store.state.mobileMenuShown,
      initialHide: !$store.state.initialLoading
    }" :style="{'min-width': $store.getters.isMobileOrTablet ? innerWidth + 'px' : 'auto'}">
    <div class="header" :class="{quick: $store.state.quickMode && !$store.getters.isMobileOrTablet}">
      <div class="closeMobileCart" v-if="$store.getters.isMobileOrTablet" @click="toggleMobileCart">
        <svgicon class="icon" src="icons/back.svg" :height="24" :width="20"></svgicon>
      </div>
      <svgicon class="icon" src="icons/bill.svg" :height="18" :width="18" v-if="!$store.getters.isMobileOrTablet"></svgicon>
      <div class="title">
        <div class="name">{{$t('cart.bill')}}</div>
        <div class="count" v-if="orderItemCount > 1">{{ orderItemCount }} {{$store.state.ticketMode ? $t('menu.tickets') : $t('menu.products')}}</div>
        <div class="count" v-if="orderItemCount === 1">{{ orderItemCount }} {{$store.state.ticketMode ? $t('menu.ticket') :$t('menu.product')}}</div>
        <div class="count" v-if="orderItemCount === 0">{{$store.state.ticketMode ? $t('menu.no_ticket') : $t('menu.no_product')}}</div>
      </div>
      <div class="closeMobileCart invisible" v-if="$store.getters.isMobileOrTablet">
        <svgicon class="icon" src="icons/back.svg" :height="24" :width="20"></svgicon>
      </div>
    </div>

    <div class="mobile-scroll">
      <div class="order-options" v-if="$store.getters.isMobileOrTablet && !$store.state.ticketMode">
        <div class="row">
          <div class="input">
            <label>{{$t('payment.order_type')}}</label>
            <select :value="orderMethod" @change="setOrderMethod">
              <option value="" hidden disabled selected>{{ $t("choose") }}...</option>
              <option value="takeout" v-if="hasOrderMethod('takeout')">{{$t('method.takeout_short')}}</option>
              <option value="delivery" v-if="hasOrderMethod('delivery') || (company && company.hasThirdPartyDelivery())">{{$t('method.delivery_short')}}</option>
              <option value="catering" v-if="hasOrderMethod('catering')">{{$t('method.catering_short')}}</option>
              <option value="inPlace" v-if="hasOrderMethod('inPlace')">{{$t('method.inPlace_short')}}</option>
              <option value="hotel" v-if="hasOrderMethod('hotel')">{{$t('method.hotel_short')}}</option>
            </select>
          </div>
          <div class="input" v-if="company && !company.isCurrentMethodClosed() && !$order.paymentOnly && inAdvanceEnabled && orderMethod">
            <label v-if="$order.method == 'delivery'">{{$t('payment.delivery_hour')}}</label>
            <label v-if="$order.method == 'takeout'">{{$t('payment.takeout_hour')}}</label>
            <label v-if="$order.method != 'delivery' && $order.method != 'takeout'">{{$t('hour')}}</label>
            <select :value="$order.hour" @change="setOrderHour">
              <option v-for="hour of company.getAvailableOpenHours($order.method)" :key="hour" :value="hour" :disabled="!company.isIntervalAvailable(hour)"> {{ formatHour(hour) }} </option>
            </select>
          </div>
        </div>
        <div class="input" v-if="!$order.paymentOnly && company && orderMethod && ($order.method == 'catering' || company.isFutureDateEnabled())">
          <label>{{ $t("date") }}</label>
          <div class="date select">
            <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
            <div class="selected">
              <datetime :zone="company.timezone" :value-zone="company.timezone"
                        v-model="date" :format="dateFormat" :min-datetime="minDate"
                        :disableDay="company.getFunctionToCheckIfDayDisabled()" :week-start="7" :input-class="['select']"></datetime>
            </div>
            <svgicon class="arrow" src="icons/arrow.svg" :height="20" :width="20"></svgicon>
          </div>
        </div>
        <div class="input edit-left" v-if="company">
          <label>{{ $t("payment.branch") }}</label>
          <input type="text" :value="company.name" readonly @click="changeCompany">
          <div class="edit" @click="changeCompany">{{ $t("edit") }}</div>
        </div>
        <template v-if="!$order.paymentOnly">
          <div class="input" v-if="company && !company.isCurrentMethodClosed() && !inAdvanceEnabled && averageDelay > 0 && $order.method !== 'inPlace' && $order.method !== 'hotel'">
            <label>{{$t('order.average_delay')}}</label>
            <input type="text" :value="averageDelay + ' minutes'" readonly>
          </div>
          <div class="input" v-if="$order.method === 'inPlace' && $order.tableNumber">
            <label>{{ $t("table") }}</label>
            <input type="text" readonly :value="$order.tableNumber">
          </div>
          <div class="input" v-if="$order.method === 'hotel' && $order.roomNumber">
            <label>{{ $t("room") }}</label>
            <input type="text" readonly :value="$order.roomNumber">
          </div>
        </template>
        <div class="input" v-if="company && company.isCurrentMethodClosed() && $order.method">
          <input type="text" :value="$t('inAdvance.currently_closed')" disabled>
        </div>
        <div class="input" v-if="$store.state.quickMode && !$order.paymentOnly">
          <label>{{ $t("order.available_in") }}</label>
          <div class="inputtext">
            <template v-if="estimatedTime >= 60">{{ Math.floor(estimatedTime / 60) }} {{ $t("hours").toLowerCase() }}</template>
            {{ estimatedTime % 60 }} {{ $t("minutes").toLowerCase() }}
          </div>
        </div>
      </div>

      <div id="cartItemList" class="itemList" v-if="company && !company.isCurrentMethodClosed()">
        <div class="estimatedDelay" v-if="!$order.paymentOnly && !$store.getters.isMobileOrTablet && $store.state.quickMode && (shouldDisplayOrderReadyFor || orderItemCount > 0) && !$store.state.ticketMode">
          <div class="row" v-if="shouldDisplayOrderReadyFor">
            <div class="name">{{ $t("order.ready_for") }}</div>
            <div class="value">{{ $order.hour == 'asap' ? $t('order.asap') : $order.hour }}</div>
          </div>
          <div class="row" v-if="orderItemCount > 0">
            <div class="name">{{ $t("order.available_in") }}</div>
            <div class="value">
              <template v-if="estimatedTime >= 60">{{ Math.floor(estimatedTime / 60) }} {{ $t("hours").toLowerCase() }}</template>
              {{ estimatedTime % 60 }} {{ $t("minutes").toLowerCase() }}
            </div>
          </div>
        </div>
        <hr class="dashed" v-if="cartItemsMode === 'compact' && !$order.paymentOnly">
        <CartItem :item="item" :index="cartIndex" v-for="(item, cartIndex) of orderItems" :key="cartIndex" :class="{mobile: $store.getters.isMobileOrTablet}"></CartItem>
      </div>

      <div class="isClosed" v-if="company && company.isCurrentMethodClosed()">
        <template v-if="company.getClosedMessage()">{{company.getClosedMessage()}}</template>
        <template v-if="!$order.method && !company.getClosedMessage()">{{$t('select_order_type')}}</template>
        <template v-if="$order.method && !company.getClosedMessage()">{{$t('emergency_close_title')}}</template>
      </div>

      <ValitorCardLogos class="cart-item" v-if="!$store.getters.isMobileOrTablet"></ValitorCardLogos>

      <CartTotal v-if="company && !company.isCurrentMethodClosed()" :empty="orderItemCount < 1" :class="{mobile: $store.getters.isMobileOrTablet}"></CartTotal>
    </div>
    <div class="bottomPad below-button" v-if="$store.getters.isMobileOrTablet"></div>
    <div class="orderButton" v-if="company && !company.isCurrentMethodClosed() && $store.getters.isMobileOrTablet">
      <button type="button" class="btn btn-primary" @click="submitOrder" :class="{disabled: empty || $user.isDispatchUserWithoutCustomer()}" :disabled="empty">
        <span v-if="$user.isDispatchUserWithoutCustomer()">{{$t('dispatch.dispatch_missing')}}</span>
        <span v-else>{{$t('cart.order')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import CartItem from "@/components/cart/CartItem.vue";
  import ValitorCardLogos from "@/components/ValitorCardLogos";
  import CartTotal from "@/components/cart/CartTotal.vue";
  import moment from "moment";
  import EventBus from "@/lib/eventBus.js";
  import { PromotionManager } from "../../PromotionManager";

  export default {
    name: "Cart",

    components: { CartItem, CartTotal, ValitorCardLogos },

    props: {
      visible: false
    },

    data() {
      return {
        innerWidth: 0,
        orderMethod: this.$order.method,
        now: moment(),
        date: this.$order.date.toISOString()
      };
    },

    mounted() {
      window.addEventListener("resize", this.setInnerWidth.bind(this));
      setInterval(() => {
        this.now = moment();
      }, 1000);
      this.setInnerWidth();
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.setInnerWidth.bind(this));
    },

    watch: {
      "$order.method": function(to) {
        this.orderMethod = to;
      },
      "$order.date": function(newValue) {
        if (this.date != newValue.toISOString()) {
          this.date = newValue.toISOString();
        }
      },
      date: function(newValue) {
        let value = moment(newValue);
        this.$order.date = moment(this.$order.date).set({
          year: value.get("year"),
          month: value.get("month"),
          date: value.get("date")
        });
        PromotionManager.calculate();
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      orderItems() {
        return this.$order.items;
      },
      orderItemCount() {
        return this.$order.getItemCount();
      },
      cartItemsMode() {
        if (!this.branch || !this.branch.style) {
          return "classic";
        }
        return this.branch.style.cartItemsMode || "classic";
      },
      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d";
        }
        return "d MMMM";
      },
      minDate() {
        if (this.company.isTodayDisabled()) {
          return moment().add({ day: 1 }).toISOString();
        }
        return moment().toISOString();
      },
      empty() {
        return this.$order.items.length == 0;
      },
      isScreenBigEnough() {
        return this.visible || !this.$store.getters.isMobileOrTablet;
      },
      inAdvanceEnabled() {
        if (!this.company || !this.company.getOpenHours(this.$order.method)) {
          return false;
        }
        return this.company.getOpenHours(this.$order.method).inAdvance;
      },
      averageDelay() {
        if (!this.company || !this.company.getOpenHours(this.$order.method)) {
          return 0;
        }
        return this.company.getOpenHours(this.$order.method).averageDelay;
      },
      hasOrderMethod() {
        return (method) => {
          if (!this.company) {
            return false;
          }
          return this.company.hasOrderMethod(method);
        }
      },
      estimatedTime() {
        if (!this.company) {
          return 0;
        }
        let hour = moment(this.$order.hour, "HH:mm");
        if (!hour.isValid()) {
          return this.company.getOpenHours().averageDelay;
        }
        return hour.diff(this.now, "minutes");
      },
      shouldDisplayOrderReadyFor() {
        return this.inAdvanceEnabled && this.$order.hour;
      }
    },

    methods: {
      toggleMobileCart() {
        this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
      },
      // TODO this is duplicate. Should be moved. This behaviour is in too many places (orderType, menuHeader, Cart, panelLocation, orderOptionsModal)
      async setOrderMethod(e) {
        let method = e.target.value;

        if (!this.hasOrderMethod(method) && this.company && this.company.hasThirdPartyDelivery()) {
          EventBus.$emit("show-third-party-delivery-modal", this.company);
          e.target.value = this.$order.method; // reset back to previous method
          return;
        }

        for (let item of this.$order.items) {
          if (item.getPrice(method) == 0) {
            let result = await showConfirmation(this.$t("warning"), this.$t("order.reset_change_method"));
            if (result) {
              break;
            } else {
              return;
            }
          }
        }
        if (method == "delivery" && this.$store.state.quickMode) {
          EventBus.$emit("order-options-modal");
        }
        this.$order.setMethod(method);
      },
      changeCompany() {
        if (this.$store.state.quickMode) {
          this.showOrderOptionsModal();
        } else {
          this.$router.push(this.$router.generate("/orderType"));
        }
      },
      showOrderOptionsModal() {
        EventBus.$emit("order-options-modal");
      },
      setOrderHour(e) {
        this.$order.setHour(e.target.value);
      },
      setInnerWidth() {
        this.innerWidth = document.getElementById("iShopFoodApp").clientWidth;
      },
      //TODO refactor this duplicated function (5x)
      formatHour(hour) {
        if (hour == "asap") {
          return this.$t("order.asap");
        }
        if (this.company.hourFormat == "12") {
          let parsed = moment(hour, "HH:mm");
          if (!parsed.isValid()) {
            return null;
          }
          return parsed.format("hh:mm A");
        }
        return hour;
      },
      async submitOrder() {
        let valid = this.$order.validateAddedItems();
        if (!valid) {
          return;
        }

        /* TODO (0.9.1): En ce moment le code est le même dans CartTotal.vue (mobile vs desktop), ce n'est pas un bon design :( */
        if (!this.empty) {
          if (!this.$order.company) { //TODO remove completely order.company, use store.state.currentCompany instead
            this.$order.company = this.company;
          }

          if (this.$order.method && !this.$order.validateMinimumAmount()) {
            return;
          }

          if (!this.$order.validateOrderRequirements()) {
            return;
          }

          // TODO this should not be done here.
          for (let promo of PromotionManager.promotions) {
            if (promo.triggerType == "mobile_app" && typeof cordova !== "undefined" && promo.minAmount) {
              if (this.$order.getItemsTotal({ excludedTags: promo.exclusion.tags }) < promo.minAmount) {
                showAffirmation(this.$t("promotion.title"), this.$t("promotion.not_applied_text")
                  .replace("{AMOUNT}", promo.minAmount).replace("{PROMO}", promo.value).replace("{PROMOTYPE}", promo.type == "percent" ? "%" : "$"));
              } else {
                showAffirmation(this.$t("promotion.title"), this.$t("promotion.applied_text")
                  .replace("{PROMO}", promo.value).replace("{PROMOTYPE}", promo.type == "percent" ? "%" : "$"));
              }
            }

            if (promo.triggerType == "x_for_y" && !promo.triggerParameters.hideReminders &&
              PromotionManager.getXYItemsQuantityInCart(promo) % promo.triggerParameters.x === promo.triggerParameters.x - 1) {
              if (promo.mandatory) {
                showAffirmation(
                  this.$t("promotion.title"), this.$t("promotion.mandatory") + " " + promo.triggerParameters.x + this.$t("promotion.for") + promo.triggerParameters.y + ".");
                this.$router.push(this.$router.generate("/promotions?scrollTo=" + promo.id));
                return;
              } else {
                let result = await showConfirmation(this.$t("promotion.inactive"), this.$t("promotion.inactive_warning_1") + " " +
                  this.$to(promo.name) + ". " + this.$t("promotion.inactive_warning_2"));
                if (result) {
                  this.$router.push(this.$router.generate("/promotions?scrollTo=" + promo.id));
                  return;
                }
              }
            }
          }

          this.$ga.event("sale-submission", this.company.nameCanonical, this.$user.email);

          let redirect = this.$store.state.inventory.hasSupplements() ? "paymentOptions" : "paymentContact";
          if (this.$user.token) {
            this.$router.push(this.$router.generate("/" + redirect));
          } else {
            this.$router.push(this.$router.generate("/login?redirect=" + redirect));
          }
          EventBus.$emit("show-end-recommendations");
          this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
        }
      },
      goToPromotions() {
        this.$router.push(this.$router.generate("/promotions"));
        this.$store.state.mobileCartShown = false;
      }
    }
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp #cart {
    width: 450px;
    min-width: 320px;
    background-image: url("../../assets/img/bg.png");
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-out;
    &.sm {
      transform: translateX(0);
    }

    &.sm.menuShown, &.md.menuShown {
      transform: translateX(280px);
    }
    &.mobile {
      width: 100%;
      min-width: 100%;
      flex-grow: 1;

      hr.dashed {
        display: none;
      }

      .orderButton {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        .btn {
          border-radius: 0;
        }
      }

      .header {
        padding: 15px;
        fill: #fc6650;
        background-color: #ed2937;
        &.quick {
          border-bottom-width: 2px !important;
          border-bottom-style: dashed !important;
        }
      }

      .mobile-scroll {
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 60px;
      }

      .itemList {
        padding: 0;
        overflow-x: initial;
        flex-shrink: 0;
      }

      .order-options {
        background-color: white;
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(230,230,230);
        padding: 15px;
        flex-shrink: 0;

        .row, .input {
          padding: 0;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        .edit-left.input {
          input {
            padding: 4px 80px 4px 8px;
            height: auto;
            line-height: 1.5em;
          }
        }

        .row {
          display: flex;
          overflow: hidden;
          //margin-bottom: 10px;
          .input {
            flex-grow: 1;
            flex-basis: 0;
            overflow: hidden;
            margin-bottom: 0;
            &:not(:last-child) {
              margin-right: 20px;
            }
            select {
              padding: 4px 32px 4px 8px;
              height: auto;
              background-position: right 8px center;
            }
          }
        }

        .input {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;

          label {
            padding-bottom: 4px;
            font-size: 14px;
            color: #808080;
          }

          select, input {
            padding: 0.5em 3.5em 0.5em 1em;
            height: 44px;
            font-size: 16px;
          }

          .inputtext {
            padding-left: 8px;
          }

        }
      }

    }
  }

  .mobile-scroll {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #fbfbfb;
    overflow: hidden;
  }

  .header {
    height: 60px;
    background-color: #c7222d;
    color: white;
    display: flex;
    padding: 15px 45px;
    align-items: center;
    fill: white;
    flex-shrink: 0;

    .invisible {
      opacity: 0;
      pointer-events: none;
    }

    .name, .count {
      color: white;
    }
    &.quick {
      height: 100px;
    }
  }

  .title {
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 17px;
    font-weight: 300;
    margin-left: 12px;
    display: flex;
    .name {
      font-weight: bold;
    }
    .count {
      margin-left: 40px;
      position: relative;
      &::before {
        content: "";
        background-color: #fc6650;
        height: 20px;
        width: 2px;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .isClosed {
    flex-grow: 1;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    opacity: 0.8;
    color: #888;
    font-size: 20px;
  }

  .itemList {
    flex-grow: 1;
    padding: 20px 30px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .orderButton {
    display: flex;
    padding-top: 20px;

    .btn {
      flex-grow: 1;
      height: 60px;
      font-size: 20px;
    }
  }

  .closeMobileCart {
    cursor: pointer;
    padding: 18px 20px;
    margin-left: -15px;
  }

  .select.fake {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 6px 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    .value {
      flex-grow: 1;
    }
    .edit {
      color: var(--primary-color);
    }
  }

  .edit-left {
    position: relative;

    .edit {
      position: absolute;
      right: 10px;
      bottom: 8px;
    }
  }

  #iShopFoodApp #cart .estimatedDelay {
    padding: 15px 20px;
    background-color: white;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    .row {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .name {
        flex-grow: 1;
        color: #808080;
      }
      .value {
        color: #404040;
        font-weight: bold;
        text-align: right;
      }
    }
  }

  #valitorCardLogos.cart-item {
    margin: 20px 40px;
    border-radius: 10px;
  }

  #iShopFoodApp hr.dashed {
    border-style: dashed;
  }

</style>

<style lang="scss">
  #cart {
    .date.select {
      position: relative;
      padding-left: 0;

      .select-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
      }

      .arrow.scale {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }

      .vdatetime-input {
        padding-left: 36px;
        font-size: 16px;
      }
    }
  }
</style>
