import { Util } from "@/util/Util";

export default {

    /**
     * @param {ActionContext} context
     * @param {{companyId: string}} param
     */
    async getPosConfiguration(context, companyCanonical) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/companies/${companyCanonical}/pos_config`
            });
            return { success: true, data: response };
            } catch (e) {
                return { success: false, status: e.status, response: Util.parseResponseError(e) };
            }
    },
    /**
     * @param {ActionContext} context
     */
    async getStatus(context) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: "/status"
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },
    /**
     * @param {ActionContext} context
     */
    async initFromPos(context) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: "/init_from_pos"
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    }
};