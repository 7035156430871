<template>
  <div class="promotion-corner" :style="{ left: offset + 'px', top: offset + 'px' }">
    <svgicon :src="icon" :width="21" :height="21"></svgicon>
  </div>
</template>

<script>
  export default {
    props: {
      offset: {
        type: Number,
        default: 0
      },
      icon: {
        type: String,
        default: "icons/marketing.svg"
      }
    }
  };
</script>

<style lang="scss" scoped>
  .promotion-corner {
    position: absolute;
    width: 64px;
    height: 64px;
    background-color: var(--primary-color);
    left: 0;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    border-top-left-radius: 15px;
    fill: white;
    padding: 10px;
  }
</style>