<template>
  <Modal id="receiptModal" ref="receiptModal">
    <ModalHeader :title="$t('pos_receipt.send_receipt')" @close="hide"></ModalHeader>
    <div class="receiptModal__sections">

      <!-- Send by text message -->
      <!-- <div class="receiptModal__section" :class="{active: method === 'text'}" @click="setMethod('text')">
        <div class="section__icon">
          <svgicon src="icons/text.svg" :width="44" :height="44"></svgicon>
        </div>
        <div class="section__right">
          <div class="section__title">
            <svgicon v-if="method === 'text'" src="icons/check-circle-full.svg" :width="22" :height="22"></svgicon>
            <svgicon v-else src="icons/circle-empty.svg" :width="22" :height="22"></svgicon>
            {{ $t("pos_receipt.send_by_text") }}
          </div>
          <label for="" class="section__label">{{ $t("payment.phone") }}</label>
          <input type="text" class="section__input" v-model="phoneNumber">
        </div>
      </div> -->

      <!-- Send by email -->
      <div class="receiptModal__section" :class="{ active: method === 'email' }" @click="setMethod('email')">
        <div class="section__icon">
          <svgicon src="icons/email.svg" :width="44" :height="44"></svgicon>
        </div>
        <div class="section__right">
          <div class="section__title">
            <svgicon v-if="method === 'email'" src="icons/check-circle-full.svg" :width="22" :height="22"></svgicon>
            <svgicon v-else src="icons/circle-empty.svg" :width="22" :height="22"></svgicon>
            {{ $t("pos_receipt.send_by_mail") }}
          </div>
          <label for="" class="section__label">{{ $t("email") }}</label>
          <input type="text" class="section__input" v-model="email">
        </div>
      </div>

      <!-- Local print -->
      <div class="receiptModal__section" :class="{ active: method === 'print' }" @click="setMethod('print')">
        <div class="section__icon">
          <svgicon src="icons/print-filled.svg" :width="44" :height="44"></svgicon>
        </div>
        <div class="section__right">
          <div class="section__title">
            <svgicon v-if="method === 'print'" src="icons/check-circle-full.svg" :width="22" :height="22"></svgicon>
            <svgicon v-else src="icons/circle-empty.svg" :width="22" :height="22"></svgicon>
            {{ $t("pos_receipt.print_receipt") }}
          </div>
        </div>
      </div>

    </div>
    <ModalFooter>
      <button class="btn btn-secondary" @click="hide">{{ $t("cancel") }}</button>
      <div class="grow"></div>
      <button class="btn btn-primary" @click="send">{{ $t("send") }}</button>
    </ModalFooter>
  </Modal>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import Modal from "@/components/modal/Modal.vue";
  import ModalFooter from "@/components/modal/ModalFooter.vue";
  import ModalHeader from "@/components/modal/ModalHeader.vue";
  import { mapActions } from "vuex";

  export default {

    components: { Modal, ModalHeader, ModalFooter },

    data() {
      return {
        method: "print",
        phoneNumber: "",
        email: "",
        onSubmit: null
      };
    },

    mounted() {
      EventBus.$on("showReceiptModal", onSubmit => {
        this.onSubmit = onSubmit;
        this.show();
      });
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      seats() {
        return this.table ? [this.table.getNonEmptySeats()] : []; //TODO use table.seatsByInvoice
      },
    },

    methods: {

      ...mapActions(["payAndFinalizeTableWithSeats", "sendReceiptByEmail"]),

      show() {
        if (this.$refs.receiptModal) {
          this.$refs.receiptModal.show();
        }
      },

      hide() {
        if (this.$refs.receiptModal) {
          this.$refs.receiptModal.hide();
        }
      },

      setMethod(method) {
        this.method = method;
      },

      send() {
        let value = null;
        switch (this.method) {
          case "text":
            value = this.phoneNumber;
            break;
          case "email":
            value = this.email;
            break;
          default:
            break;
        }
        this.submitPayment(this.method, value);
      },

      async submitPayment(method, value) {
        this.hide();

        showSpinner();

        // Finalize the sale
        let response = await this.payAndFinalizeTableWithSeats({ seats: this.seats, printReceipt: method === "print" });
        if (!response.success) {
          this.showPaymentError(response.error || this.$t("payment.payfacto_error"));
          hideSpinner();
          return;
        }

        // Print or send the receipt by email
        if (method === "email") {
          // Get raw receipt data from last print in payAndFinalizeTableWithSeats
          let receiptResponse = await this.sendReceiptByEmail({
            receipt: response.rawReceipt.join("\n"),
            invoice: this.table.getActiveInvoiceNumbers()[0],
            email: value,
            language: this.$ts.state.locale
          });
          if (!receiptResponse.success) {
            this.showPaymentError(this.$t("pos_payment.email_error"));
            // Print a paper receipt still stored in IshopPrinter
            IshopPrinter.printSale();
          }
        }

        hideSpinner();

        // Reset and call the callback
        this.phoneNumber = "";
        this.email = "";
        this.method = "print";
        this.onSubmit();
      },

      showPaymentError(errorMessage) {
        toast({
          message: errorMessage,
          type: "error",
          title: this.$t("error.title")
        });
      }

    }

  };
</script>

<style lang="scss" scoped>
  #receiptModal {
    .btn {
      padding: 16px 24px;
      font-size: 20px;
    }

    .grow {
      flex-grow: 1;
    }

    .receiptModal__section {
      border-radius: 20px;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
      padding: 10px;
      display: flex;
      width: 560px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &.active {
        border: 4px solid var(--primary-color);
        padding: 6px;
      }

      .section__icon {
        width: 76px;
        height: 76px;
        background-color: var(--background-color-6);
        fill: #DBDBDB;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        margin-right: 20px;
      }

      .section__right {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        flex-grow: 1;
      }

      .section__title {
        height: 76px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: var(--theme-color-1);

        .svgicon {
          fill: var(--primary-color);
          margin-right: 10px;
        }
      }

      .section__label {
        color: var(--theme-color-2);
        margin-bottom: 16px;
      }

      .section__input {
        margin-bottom: 10px;
      }
    }
  }
</style>