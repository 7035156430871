<template>
  <div class="button" v-wave @click="$emit('click', $event)">
    <svgicon v-if="icon" :src="icon" :height="24"></svgicon>
    <template v-else>{{ text }}</template>
  </div>
</template>

<script>
  export default {
    name: "Button",
    emits: ["click"],
    props: {
      text: {
        type: String,
        default: "Continue"
      },
      icon: {
        type: String,
        required: false
      }
    }
  };
</script>

<style lang="scss" scoped>
.button {
  color: var(--primary-color);
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 16px 20px;
  background-color: var(--background-color-3);
  border: none;
  border-radius: 12px;
  min-width: 56px;
  fill: var(--primary-color);
  cursor: pointer;
}
</style>