<template>
  <div id="posKeyboard" class="keyboard__overlay" :class="{'visible': visible}" @click="exitOnOverlay">
    <div class="keyboard__container">
      <div class="keyboard__warning" v-if="isAboveOrEqualMaximumAmountOfChar"> maximum character reached </div>
      <div class="keyboard__input">
        <div class="keyboard__value">{{value}}</div>
        <button class="btn btn-secondary" @click="clear">{{$t('pos.clear')}}</button>
      </div>
      <div class="keyboard__buttons" :class="{'keyboard__buttons--upper': shift || capsLock}">
        <div class="keyboard__row keyboard__row--first">
          <button class="keyboard__key keyboard__key--letters"
                  v-for="(key, keyIndex) of currentKeyboardLayout.row1" :key="'key-1-' + keyIndex" @click="add(key)" @contextmenu="add(key)">{{key}}</button>
          <button class="keyboard__key keyboard__key--large keyboard__key--accent" @click="remove()" @contextmenu="remove()">
            <svgicon src="icons/erase-big.svg" :height="32" :width="25"/>
          </button>
        </div>
        <div class="keyboard__row keyboard__row--second">
          <button class="keyboard__key keyboard__key--large keyboard__key--accent"
                  @click="add(`    `)" @contextmenu="add(`    `)">{{$t("pos.tab")}}</button>
          <button class="keyboard__key keyboard__key--letters"
                  v-for="(key, keyIndex) of currentKeyboardLayout.row2" :key="'key-2-' + keyIndex"
                  @click="add(key)" @contextmenu="add(key)">{{key}}</button>
        </div>
        <div class="keyboard__row keyboard__row--third">
          <button class="keyboard__key keyboard__key--large keyboard__key--accent" :class="{'keyboard__key--highlight': capsLock}"
                  @click="toggleCapsLock()" @contextmenu="toggleCapsLock()">{{$t("pos.caps_lock")}}</button>
          <button class="keyboard__key keyboard__key--letters" v-for="(key, keyIndex) of currentKeyboardLayout.row3"
                  :key="'key-3-' + keyIndex" @click="add(key)" @contextmenu="add(key)">{{key}}</button>
          <button class="keyboard__key keyboard__key--large keyboard__key--accent" @click="enter"  @contextmenu="enter">
            <svgicon src="icons/enter.svg" :height="26" :width="40"/><span>{{ $t('pos.enter') }}</span>
          </button>
        </div>
        <div class="keyboard__row keyboard__row--fourth">
          <button class="keyboard__key keyboard__key--large" :class="{'keyboard__key--highlight': shift}" @click="toggleShift()" @contextmenu="toggleShift">
            <svgicon src="icons/shift.svg" :height="30" :width="34"/>
          </button>
          <button class="keyboard__key keyboard__key--letters"
                  v-for="(key, keyIndex) of currentKeyboardLayout.row4" :key="'key-4-' + keyIndex" @click="add(key)" @contextmenu="add(key)">{{key}}</button>
          <button class="keyboard__key keyboard__key--large" :class="{'keyboard__key--highlight': shift}" @click="toggleShift()" @contextmenu="toggleShift">
            <svgicon src="icons/shift.svg" :height="30" :width="34"/>
          </button>
        </div>
        <div class="keyboard__row keyboard__row--last">
          <button class="keyboard__key" @click="exit" @contextmenu="exit">{{$t("pos.exit")}}</button>
          <button class="keyboard__key" @click="toggleSpecialLayout" @contextmenu="toggleSpecialLayout">
            <template v-if="symbols">{{standardKey}}</template><template v-if="!symbols">{{symbolsKey}}</template>
          </button>
          <button class="keyboard__key keyboard__key--space" @click="add(' ')" @contextmenu="add(' ')"> </button>
          <button class="keyboard__key" @click="toggleSpecialLayout" @contextmenu="toggleSpecialLayout">
            <template v-if="symbols">{{standardKey}}</template><template v-if="!symbols">{{symbolsKey}}</template>
          </button>
          <button class="keyboard__key" @click="exit" @contextmenu="exit">{{$t("pos.exit")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";

  export default {
    name: "posKeyboard",

    data() {
      return {
        value: "",
        visible: false,
        shift: false,
        capsLock: false,
        symbols: false,
        symbolsKey: "!?#%",
        standardKey: "ABC123",
        title: "",
        reference: null,
        lastActivityAt: null,
        activityInterval: null,
        allowedInactivityInMs: 1000,
        callback: null,
        maximumCharacterCount: 0,
        keys: {
          standard: {
            row1: ["~", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "="],
            row2: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", ".", "@", ".com"],
            row3: ["a", "s", "d", "f", "g", "h", "j", "k", "l", ":", "\""],
            row4: ["z", "x", "c", "v", "b", "n", "m", "<", ">", "?"]
          },
          symbols: {
            row1: ["~", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "_", "+"],
            row2: ["~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "="],
            row3: ["[", "]", "{", "}", "€", "£", "¥", ";", ":", "'", "\""],
            row4: ["•", "|", "\\", "<", ">", "...", ",", ".", "/", "?"]
          }
        }
      };
    },

    computed: {
      currentKeyboardLayout() {
        return this.symbols ? this.keys.symbols : this.keys.standard;
      },
      isAboveOrEqualMaximumAmountOfChar() {
        return this.maximumCharacterCount && (this.value.length >= this.maximumCharacterCount);
      }
    },

    mounted() {
      EventBus.$on("show-keyboard", args => {
        this.reference = args.reference;
        this.title = args.title || "";
        this.value = args.value || "";
        this.closeOnInactivity = args.closeOnInactivity || false;
        this.visible = true;
        this.callback = args.callback || null;
        this.maximumCharacterCount = args.maximumCharacterCount || 0;
        document.addEventListener("keyup", this.onKeyUp);

        if (this.closeOnInactivity) {
          this.startActivityInterval();
        }
      });
      EventBus.$on("close-keyboard", () => {
        this.visible = false;
        this.clearActivityInterval();
        document.removeEventListener("keyup", this.onKeyUp);
      });
      let keys = this.$el.getElementsByClassName("keyboard__key");
      for (let key of keys) {
        key.addEventListener("click", this.onKeyClick);
      }
    },

    beforeDestroy() {
      EventBus.$off("close-keyboard");
      EventBus.$off("show-keyboard");
      document.removeEventListener("keyup", this.onKeyUp);
      this.clearActivityInterval();

      let keys = this.$el.getElementsByClassName("keyboard__key");
      for (let key of keys) {
        key.removeEventListener("click", this.onKeyClick);
      }
    },

    methods: {
      add(char) {
        this.setLastActivity();
        if (this.isAboveOrEqualMaximumAmountOfChar) {
          return;
        }
        if (this.shift) {
          this.shift = false;
          this.value += char.toUpperCase();
        } else if (this.capsLock) {
          this.value += char.toUpperCase();
        } else {
          this.value += char;
        }
      },

      setLastActivity() {
        if (!this.closeOnInactivity) {
          return;
        }
        this.lastActivityAt = new Date();
      },

      startActivityInterval() {
        this.lastActivityAt = null;
        this.activityInterval = setInterval(() => {
          if (!this.lastActivityAt) {
            return;
          }
          let currentTime = (new Date()).getTime();
          let lastActivityTime = this.lastActivityAt.getTime();
          if ((currentTime - lastActivityTime) > this.allowedInactivityInMs && this.value.length >= 3) {
            this.enter();
          }
        }, 50);
      },

      clearActivityInterval() {
        clearInterval(this.activityInterval);
        this.activityInterval = null;
        this.lastActivityAt = null;
      },

      onKeyUp(e) {
        this.setLastActivity();
        if (e && e.code && e.key) {
          switch (e.code) {
            case "Backspace":
              this.remove();
              break;
            case "ShiftLeft":
            case "ShiftRight":
            case "ControlLeft":
            case "ControlRight":
            case "AltLeft":
            case "AltRight":
              break;
            case "Delete":
              this.clear();
              break;
            case "Enter":
              this.enter();
              break;
            default:
              this.value += e.key;
              break;
          }
        }
      },

      onKeyClick() {
        document.activeElement.blur();
      },

      exitOnOverlay(e) {
        if (e.target.id === "posKeyboard") {
          this.exit();
        }
      },

      remove() {
        this.lastActivityAt = null;
        if (this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length - 1);
        }
      },

      clear() {
        this.lastActivityAt = null;
        this.value = "";
      },

      enter() {
        this.clearActivityInterval();
        this.visible = false;
        if (this.callback) {
          this.callback({ value: this.value, target: "enter" });
        }
        EventBus.$emit("keyboard-enter", {
          reference: this.reference,
          value: this.value
        });
        this.resetKeyboardState();
      },

      exit() {
        this.clearActivityInterval();
        this.visible = false;
        this.resetKeyboardState();
      },

      toggleSpecialLayout() {
        this.setLastActivity();
        this.symbols = !this.symbols;
      },

      toggleShift() {
        this.setLastActivity();
        this.shift = !this.shift;
      },

      toggleCapsLock() {
        this.setLastActivity();
        this.capsLock = !this.capsLock;
      },

      resetKeyboardState() {
        this.capsLock = false;
        this.shift = false;
        this.symbols = false;
        this.clear();
      }

    }
  }
</script>

<style lang="scss" scoped>

  $fade-in-duration: 250ms;

  #iShopFoodApp #posKeyboard {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    transition: background-color $fade-in-duration ease;
    pointer-events: none;

    &.visible {
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color $fade-in-duration ease;
      pointer-events: initial;

      .keyboard__container {
        transform: translatey(0%);
        transition: transform $fade-in-duration ease-in-out;
      }
    }

    .keyboard__container {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 40px;
      background-color: var(--background-color-2);
      border-radius: 20px 20px 0 0;
      transform: translatey(100%);
      transition: transform 300ms ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .keyboard__warning {
      position: absolute;
      left: 45px;
      top: 20px;
      transform: translatey(-50%);

    }

    .keyboard__input {
      border-bottom: var(--border-color-4) dashed 2px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      display: flex;

      .keyboard__value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--background-color-2);
        border: var(--border-color-4) solid 1px;
        font-size: 36px;
        color: var(--theme-color);
        padding: 13px 20px;
        text-align: center;
        border-radius: 10px;
        min-height: 70px;
        line-height: 1px;
        overflow: hidden;
        flex-grow: 1;
        margin-right: 10px;
      }

      .btn {
        font-size: 28px;
        padding: 8px 32px;
      }
    }

    .keyboard__buttons--upper .keyboard__row .keyboard__key--letters{
      text-transform: uppercase;
    }

    .keyboard__row {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-top: 10px;
      height: 90px;

      &.keyboard__row--first {
        height: 90px;
        .keyboard__key--large {
          min-width: 187px;
        }
      }

      &.keyboard__row--last {
        height: 70px;
      }

      &.keyboard__row--second .keyboard__key--large {
          min-width: 187px;
      }

      &.keyboard__row--third .keyboard__key--large {
        min-width: 216px;
      }
      &.keyboard__row--fourth .keyboard__key--large {
        min-width: 279px;
      }
    }

    .keyboard__key {
      font-size: 28px;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      background-color: var(--background-color-5);
      color: var(--primary-color) !important;
      border: var(--border-color-6) 2px solid;
      border-radius: 10px;
      fill: var(--primary-color) !important;
      margin-right: 10px;
      flex-grow: 1;
      flex-basis: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      &:last-of-type {
        margin-right: 0;
      }

      &:focus {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: -1px;
          top: -1px;
          border-radius: 10px;
          background-color: var(--primary-color);
          opacity: 0.15;
        }
      }

      &--accent {
        background-color: var(--background-color-3);

        .svgicon + span {
          margin-left: 15px;
        }
      }

      &--space {
        min-width: 1058px
      }

      &--highlight {
        fill: #ffffff !important;
        color: #ffffff !important;
        background-color: var(--primary-color);
      }
    }

  }

  .placeholderbut {
    position: absolute;
    top: 30px;
    left: 50px;
  }
</style>