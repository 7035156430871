<template>
  <div class="illustration" :class="{small, forHub, large, shadow, white, larger}">
    <div class="dash left top"></div>
    <div class="dash left"></div>
    <div class="dash left bottom"></div>
    <div class="circle">
      <svgicon v-if="!customSlot && !image" :src="src" :width="small ? 70 : (largerIcon ? 135 : 110)" :height="small ? 70 : (largerIcon ? 135 : 110)"></svgicon>
      <div class="image" v-if="image" :style="{backgroundImage: image ? 'url(\'' + src + '\')' : ''}"></div>
      <slot v-if="customSlot"></slot>
    </div>
    <div class="dash right top"></div>
    <div class="dash right"></div>
    <div class="dash right bottom"></div>
  </div>
</template>

<script>
  export default {
    name: "Illustration",
    props: {
      src: String,
      customSlot: Boolean,
      image: Boolean,
      forHub: Boolean,
      small: Boolean,
      white: Boolean,
      shadow: Boolean,
      largerIcon: Boolean,
      large: Boolean,
      larger: Boolean
    }
  }
</script>

<style lang="scss" scoped>
#iShopFoodApp {
  &.pos {
    .illustration {
      fill: var(--primary-color);
      .dash {
        background-color: var(--background-color-3);
      }

      &.large {
        .circle, .dash {
          background-color: var(--illustration-background-color);
          .svgicon {
            fill: var(--illustration-front-color)
          }
        }
      }
    }

    .circle {
      background-color: var(--background-color-3);

    }
  }
}
  .illustration {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #fc6650;
    padding: 0 32px;

    &.shadow {
      .circle {
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08);
      }
      .dash {
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.08);
      }
    }

    &.white {
      .circle, .dash {
        background-color: white;
      }
    }

    &.forHub {
      padding: 0;

      .circle {
        background-color: white;
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08);
        position: relative;
      }

      .dash {
        background-color: white;
        height: 10px;
        width: 24px;
        border-radius: 10px;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.08);
      }

    }

    .dash {
      width: 20px;
      height: 8px;
      border-radius: 4px;
      background-color: #f5f5f5;
      margin: 0 4px;
      position: absolute;
      $offset: 90px;
      &.left {
        transform-origin: 120px 0px;
        transform: translateX(calc(-#{$offset} - 20px));
        &.top {
          transform: translateX(calc(-#{$offset} - 20px)) rotateZ(35deg);
        }
        &.bottom {
          transform: translateX(calc(-#{$offset} - 20px)) rotateZ(-35deg);
        }
      }
      &.right {
        transform-origin: -100px 0px;
        transform: translateX(calc(#{$offset} + 20px));
        &.top {
          transform: translateX(calc(#{$offset} + 20px)) rotateZ(-35deg);
        }
        &.bottom {
          transform: translateX(calc(#{$offset} + 20px)) rotateZ(35deg);
        }
      }
    }
    &.large {
      .circle, .dash {
        background-color: #ebebeb;
        .svgicon {
          fill: #dbdbdb
        }
      }
    }
    .circle {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background-color: #f5f5f5;
      margin: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .image {
      height: 165px;
      width: 165px;
      margin-left: 4px;
      margin-bottom: 2px;
      background-size: cover;
      border-radius: 50%;
      background-position: center;
    }

    &.small {
      .circle {
        width: 120px;
        height: 120px;
      }
      .image {
        height: 105px;
        width: 105px;
      }
      .dash {
        $offset: 60px;
        &.left {
          transform-origin: 90px 0px;
          transform: translateX(calc(-#{$offset} - 20px));
          &.top {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(35deg);
          }
          &.bottom {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(-35deg);
          }
        }
        &.right {
          transform-origin: -70px 0px;
          transform: translateX(calc(#{$offset} + 20px));
          &.top {
            transform: translateX(calc(#{$offset} + 20px)) rotateZ(-35deg);
          }
          &.bottom {
            transform: translateX(calc(#{$offset} + 20px)) rotateZ(35deg);
          }
        }
      }
    }

    &.larger {
      $circle: 260px;
      .circle {
        width: $circle;
        height: $circle;
      }
      .image {
        $image-offset: 10px;
        width: $circle - $image-offset;
        height: $circle - $image-offset;
      }
      .dash {
        $offset: 150px;
        &.left {
          transform-origin: 155px 0px;
          transform: translateX(calc(-#{$offset} - 20px));
          &.top {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(35deg);
          }
          &.bottom {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(-35deg);
          }
        }
        &.right {
          transform-origin: -135px 0px;
          transform: translateX(calc(#{$offset} + 12px));
          &.top {
            transform: translateX(calc(#{$offset} + 12px)) rotateZ(-35deg);
          }
          &.bottom {
            transform: translateX(calc(#{$offset} + 12px)) rotateZ(35deg);
          }
        }
      }
    }

    &.large {
      .circle {
        width: 220px;
        height: 220px;
      }
      .image {
        width: 205px;
        height: 205px;
      }
      .dash {
        $offset: 120px;
        &.left {
          transform-origin: 155px 0px;
          transform: translateX(calc(-#{$offset} - 20px));
          &.top {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(35deg);
          }
          &.bottom {
            transform: translateX(calc(-#{$offset} - 20px)) rotateZ(-35deg);
          }
        }
        &.right {
          transform-origin: -135px 0px;
          transform: translateX(calc(#{$offset} + 12px));
          &.top {
            transform: translateX(calc(#{$offset} + 12px)) rotateZ(-35deg);
          }
          &.bottom {
            transform: translateX(calc(#{$offset} + 12px)) rotateZ(35deg);
          }
        }
      }
    }
  }
</style>
