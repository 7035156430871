<template>
  <div id="posOrderOverviewCart" :class="{ leftHandMode: isLeftHandedMode }">
    <div class="cart__items">
      <template v-if="order">
        <div class="cart__dispatch" v-if="order && order.isDeliveryOrTakeout()">
          <div class="dispatch__left">
            <div class="dispatch__image">
              <svgicon src="icons/delivery-order.svg" v-if="order.isDelivery()" :height="64" :width="64"/>
              <svgicon src="icons/takeout-order.svg" v-if="order.isTakeout()" :height="64" :width="64"/>
            </div>
          </div>
          <div class="dispatch__right">
            <div class="dispatch__row">
              <div class="row__title">{{ $t("order.title_type") }}</div>
              <div class="row__value">{{ $t(order.method) }}</div>
            </div>
            <div class="dispatch__row">
              <div class="row__title">{{ $t("customer") }}</div>
              <div class="row__value">{{ order.getFullContactName() }}</div>
            </div>
            <div class="dispatch__row" v-if="order.getContactPhone()">
              <div class="row__title">{{ $t("register.phoneNumber") }}</div>
              <div class="row__value">{{ order.getContactPhone() }}</div>
            </div>
            <div class="dispatch__row" v-if="order.getContactEmail()">
              <div class="row__title">{{ $t("register.email") }}</div>
              <div class="row__value">{{ order.getContactEmail() }}</div>
            </div>
            <div class="dispatch__row" v-if="order.getStartDateAsString()">
              <div class="row__title">{{ $t("pos.order_time") }}</div>
              <div class="row__value">{{ order.getStartDateAsString() }}</div>
            </div>
            <div class="dispatch__row">
              <div class="row__title">{{ $t("pos.reception_time") }}</div>
              <div class="row__value" v-if="order.getInAdvanceForDateAsString()">{{
                  order.getInAdvanceForDateAsString()
                }}
              </div>
              <div class="row__value" v-if="!order.getInAdvanceForDateAsString()">{{ $t("order.asap") }}</div>
            </div>
            <div class="dispatch__row" v-if="order.getFullAddress()">
              <div class="row__title">{{ $t("profile.address") }}</div>
              <div class="row__value">{{ order.getFullAddress() }}</div>
            </div>
          </div>
        </div>
        <template v-if="seats.length === 1">
          <PosCartItem :detail="detail" v-for="(detail, detailIndex) of details"
                       v-if="detail" :key="'cart-item-' + detailIndex + order.getOrderId()" noActions/>
        </template>
        <template v-else>
          <template v-for="detailsBySeat of details">
            <div class="row__seat" :key="'seat-' + detailsBySeat.seat">
              <div class="seat__text">{{ $t("pos_order_overview.seat")}} {{ detailsBySeat.seat }}</div>
            </div>
            <PosCartItem :detail="detail" v-for="(detail, detailIndex) of detailsBySeat.details"
                         v-if="detail" :key="'cart-item-' + detailIndex + order.getOrderId()" noActions/>
          </template>
        </template>
        <InvoiceCartTotal :promotions="order.promotions" :total="total" v-if="order.isClosed()" noPadding/>
      </template>
    </div>

    <div class="cart__footer footer">
      <InvoiceCartTotal :total="order ? total : placeholderTotal" noPadding v-if="!order || order.isOpen()">
        <div class="footer__action">
          <button class="btn btn-primary" v-if="order" @click="goToOrder" v-wave>{{ $t("confirm_order") }}</button>
          <button class="btn btn-primary" v-if="!order" disabled>{{ $t("pos_order_overview.select_order") }}</button>
        </div>
      </InvoiceCartTotal>
      <div class="footer footer__payment" v-if="order && order.isClosed()">
        <div class="payment__content">
          <div class="payment__icon">
            <svgicon src="icons/facture.svg" :height="48" :width="38"></svgicon>
          </div>
          <div class="payment-details">
            <div class="detail" v-if="total && total.tip">
              <label class="detail__label">{{ $t("total.tip") }}</label>
              <div class="detail__value">{{ $tc(total.tip) }}</div>
            </div>
            <div v-for="(payment, paymentIndex) of order.payments" class="detail" :key="'payment-' + paymentIndex">
              <label class="detail__label">{{ payment.amount > 0 ? $t("pos_payment.payment") : $t("pos_payment.refund") }} - {{ $t("pos_payment." + payment.method) }}</label>
              <div class="detail__value">{{ $tc(payment.amount) }}</div>
            </div>
          </div>
        </div>
        <div class="footer__action">
          <button class="btn btn-primary" @click="print" v-wave>{{ $t("pos_order_overview.print_duplicata") }}</button>
          <button class="btn btn-secondary" @click="refund" v-wave :class="{ disabled: !canRefund }">
            {{ order.isRefunded() ? $t("pos_order_overview.refunded") : $t("pos_order_overview.refund") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import Table from "@/models/pos/TableModel";
  import { Constant } from "@/util/Constant";
  import { PermissionManager } from "@/util/PermissionManager";
  import PosCartItem from "./PosCartItem";
  import InvoiceCartTotal from "./InvoiceCartTotal";
  import OrderOverviewModel from "../../models/pos/OrderOverviewModel";

  export default {
    name: "posOrderOverviewCart",

    components: {
      PosCartItem,
      InvoiceCartTotal
    },

    props: {
      order: OrderOverviewModel
    },

    data() {
      return {
        placeholderTotal: {
          taxes: {},
          subTotal: 0,
          total: 0
        }
      };
    },

    computed: {
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      seats() {
        return this.order.getSeats();
      },
      details() {
        return this.seats.length > 1 ? this.order.getGroupedDetailsBySeats() : this.order.getGroupedDetails();
      },
      total() {
        return this.order.getTotal();
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      canRefund() {
        return PermissionManager.hasPermission(Constant.P_ORDER_REFUND) && !this.order.isRefunded();
      }
    },

    methods: {
      goToOrder() {
        if (!this.order.isPosSale()) {
          return;
        }
        this.$store.state.table = new Table(this.order.getRawData());
        this.$store.state.table.setMethod(this.$store.state.table.method);
        this.$store.state.table.loadPromotions();
        this.$router.push(this.$router.generate("/menu"));
      },
      async print() {
        EventBus.$emit("print-invoice-selection-modal", this.order);
      },
      refund() {
        if (this.order.isRefunded()) {
          return;
        }
        EventBus.$emit("pos-refund-modal", this.order);
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #posOrderOverviewCart {
  width: 700px;
  min-width: 700px;
  flex-shrink: 0;
  background-color: var(--background-color);
  border-right: 1px dashed var(--border-color);
  padding: 30px;
  display: flex;
  flex-direction: column;

  &.leftHandMode {
    border-left: 1px dashed var(--border-color);
    border-right: none;
  }

  .cart__items {
    margin: 0 -10px 20px -10px;
    padding: 20px 10px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cart__dispatch {
    background-color: var(--background-color-2);
    border-radius: 20px;
    display: flex;
    margin-bottom: 15px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);

    .dispatch__left {
      padding: 10px 0px 10px 10px;

      .dispatch__image {
        background-color: var(--background-color-6);;
        width: 100px;
        height: 100px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: var(--primary-color);
      }
    }

    .dispatch__right {
      flex-grow: 1;
      padding: 20px 30px 20px 20px;

      .dispatch__row {
        display: flex;
        align-items: flex-start;

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--border-color-4);
          margin-bottom: 8px;
          padding-bottom: 8px;
        }

        .row__title {
          flex-grow: 1;
          padding-right: 10px;
          //min-width: 110px;
          color: var(--theme-color-2);
          font-weight: 500;
        }

        .row__value {
          color: var(--theme-color);
          font-weight: 500;
          font-size: 19px;
        }
      }
    }

    .dispatch__actions {
      .btn {
        width: 56px;
        height: 56px;
        margin: 10px;
      }
    }
  }

  .cart__footer {
    padding-top: 20px;
    border-top: 1px solid var(--border-color);
  }

  .footer__payment {
    border-radius: 20px;
    padding: 10px;
    background-color: var(--background-color-2);
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    .payment__content {
      display: flex;
    }

    .payment__icon {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background-color: var(--background-color-6);
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #dbdbdb;
      margin-right: 20px;
    }

    .payment-details {
      flex-grow: 1;
      padding-left: 20px
    }

    .payment-details {
      flex-grow: 1;
      padding: 0 20px 10px 20px;

      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }

        &.total {
          .detail__label, .detail__value {
            color: var(--theme-color);
            font-weight: 900;
            font-size: 26px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--border-color-4);
        }

        .detail__label {
          color: var(--theme-color-2);
          font-size: 16px;
          font-weight: 500;
        }

        .detail__value {
          font-weight: 900;
          color: var(--theme-color);
          font-size: 20px;
        }
      }
    }
  }

  .row__seat {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;

    .seat__text {
      z-index: 1;
      background-color: var(--background-color);
      padding: 0 10px;
      color: var(--theme-color-2);
      font-weight: 500;
    }

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-bottom: 1px solid var(--border-color)
    }
  }

  .footer__action {
    display: flex;
    padding: 10px;

    .btn {
      height: 56px;
      flex-grow: 1;
      flex-basis: 0;
      font-size: 22px;
      font-weight: bold;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>