<template>
  <div class="item-choice-selector item-selector" :class="{ selected: group.selected == choice }">
    <Modifier :no-wave="noWave" type="choice" :selector="choice" :group="group" :promo="$pos && isDefaultOption"
      @click="setSelected(false)" :isDefault="isDefaultOption"></Modifier>
    <PromotionCorner v-if="$pos && isDefaultOption" icon="icons/thumb.svg" :offset="6"></PromotionCorner>
  </div>
</template>

<script>

  import Modifier from "@/components/menu/Modifier.vue";
  import PromotionCorner from "@/components/pos/PromotionCorner.vue";
  import { PromotionManager } from "../../PromotionManager";

  export default {

    name: "itemChoiceSelector",

    components: { Modifier, PromotionCorner },

    props: {
      choice: Object,
      noWave: Boolean,
      group: Object
    },

    mounted() {
      if (!this.$pos) {
        this.setDefault();
      }
    },

    computed: {
      interactiveMode() {
        if (this.$kiosk) {
          return true;
        }
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.interactiveMode;
      },
      forceModifierInteraction() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.interactiveMode) {
          return false;
        }
        return this.$store.state.currentBranch.style.forceModifierInteraction;
      },
      isDefaultOption() {
        return this.group && this.group.default && this.group.default === this.choice.id;
      }
    },

    methods: {
      setSelected(skipAnimation) {
        if (this.group.selected == this.choice) {
          if (this.forceModifierInteraction) {
            this.$emit("select", {
              choice: this.choice,
              skipAnimation: skipAnimation
            });
          }
          return;
        }
        this.group.setSelected(this.choice);
        this.$emit("select", {
          choice: this.choice,
          skipAnimation: skipAnimation
        });
        PromotionManager.calculate();
      },
      setDefault() {
        if (!this.group || !this.choice || this.interactiveMode) {
          return;
        }
        if (this.group.default && this.group.default == this.choice.id && !this.choice.outOfStock) {
          this.setSelected(true);
        }
      }
    }

  };

</script>

<style lang="scss" scoped>
  .item-choice-selector {
    position: relative;
  }
</style>
