<template>
  <div id="printerConfigurationModal" class="modal" :class="{show: visible && !hidden}">
    <div class="modal-content">
      <div class="modal__header">
        <svgicon src="icons/print-filled.svg" :height="20" :width="20"></svgicon>
        <span class="header__text">{{ $t("pos_configuration.printing_title") }}</span>
      </div>
      <div class="modal__body">
        <div class="print-warning" v-if="!hasPrinterConfigured">
          <div class="print-warning__icon">
            <svgicon src="icons/danger.svg" :height="25" :width="25"></svgicon>
          </div>
          <div class="print-warning__content">
            <span class="print-warning__title" v-html="$t('pos.printing.not_configured')"></span>
            <span class="print-warning__text" v-html="$t('pos.printing.not_configured_description')"></span>
          </div>
        </div>
        <div class="print-warning warning__info">
          <div class="print-warning__icon">
            <svgicon src="icons/info.svg" :height="25" :width="25"></svgicon>
          </div>
          <div class="print-warning__content">
            <span class="print-warning__text" v-html="$t('pos_configuration.tooltip')"></span>
          </div>
        </div>
        <PrinterConfiguration v-if="visible" showActionButtons @quit="hide"></PrinterConfiguration>
      </div>
    </div>
  </div>
</template>

<script>
  import PrinterConfiguration from "@/components/pos/PrinterConfiguration";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "PrinterConfigurationModal",

    components: { PrinterConfiguration },

    data() {
      return {
        visible: false, //TODO refactor to use only one var
        hidden: false
      };
    },

    mounted() {
      EventBus.$on("show-printer-configuration-modal", () => {
        this.show();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-printer-configuration-modal");
    },

    computed: {
      printQueueSize() {
        const statusToPrint = ["pending", "error"];
        return this.$store.state.externalPosSaleQueue
            .filter(p => statusToPrint.indexOf(p.print.status) > -1)
            .length;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      hasPrinterConfigured() {
        return this.posConfiguration.hasPrinterConfigured();
      }
    },

    methods: {
      show() {
        this.visible = true;
        this.hidden = false;
      },
      hide() {
        this.hidden = true;
        setTimeout(() => {
          this.visible = false;
        }, 500);
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #printerConfigurationModal {
  .modal-content {
    max-width: 1200px;
    padding: 40px 40px 30px 40px;
    border-radius: 20px;
  }

  .modal__header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px dashed var(--border-color-4);

    .svgicon {
      margin-right: 20px;
      fill: var(--primary-color);
    }

    .header__text {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .print-warning {
    display: flex;
    align-items: center;
    border-bottom: 2px dashed var(--border-color-4);
    padding-bottom: 15px;
    margin-bottom: 15px;

    &.warning__info {
      .print-warning__text {
        font-weight: bold;
      }
    }

    .print-warning__content {
      display: flex;
      flex-direction: column;
    }

    .print-warning__icon {
      background-color: var(--background-color-3);
      height: 55px;
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 20px;

      .svgicon {
        fill: var(--primary-color);
      }
    }

    .print-warning__title {
      color: var(--primary-color);
      font-weight: bold;
      padding-bottom: 10px;
    }
  }
}
</style>
