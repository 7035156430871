<template>
  <div id="printerConfiguration" v-if="posConfiguration">
    <div class="configuration--setting setting__select" v-if="ready">
      <div class="setting__info shrink">
        <div class="info--title">{{ $t("pos_configuration.main_printer_name") }}</div>
        <div class="info--description">{{ $t("pos_configuration.main_printer_name_description") }}</div>
      </div>
      <div class="printer">
        <div class="printer__icon">
          <svgicon src="icons/print-filled.svg" :height="38" :width="38"></svgicon>
        </div>
        <div class="printer__name">
          <select v-model="posConfiguration.printer.mainPrinterName" :disabled="printers.length === 0">
            <option value="" disabled hidden v-if="printers.length > 0">{{ $t("pos.printing.choose_printer") }}...
            </option>
            <option :value="posConfiguration.printer.mainPrinterName" disabled hidden v-if="printers.length === 0">
              {{ $t("pos.printing.no_printer") }}
            </option>
            <option :value="printer.name" v-for="printer of printers" :key="'printer-main-' + printer.name">
              {{ printer.nickname }}
              <template v-if="printer.name !== printer.nickname">({{ printer.name }})</template>
            </option>
          </select>
        </div>
        <div class="printer__actions">
          <button class="btn btn-primary" v-wave @click="testPrinter(posConfiguration.printer.mainPrinterName)"
                  :class="{disabled: !posConfiguration.printer.mainPrinterName}">
            {{ $t("pos.printing.print_identification") }}
          </button>
        </div>
      </div>
    </div>
    <div class="configuration--setting setting__select" v-if="ready">
      <div class="setting__info shrink">
        <div class="info--title">{{ $t("pos_configuration.kitchen_printer_name") }}</div>
        <div class="info--description">{{ $t("pos_configuration.kitchen_printer_name_description") }}</div>
      </div>
      <div class="printers">
        <div class="printer" v-for="(kitchenPrinter, index) of posConfiguration.getKitchenPrinters()"
             :key="'kitchen-printer-' + index">
          <div class="printer__icon">
            <svgicon src="icons/print-filled.svg" :height="38" :width="38"></svgicon>
          </div>
          <div class="printer__group">
            <label class="group__label">{{ $t("printer") }}</label>
            <div class="group__value">{{ kitchenPrinter.nickname }}</div>
          </div>
          <div class="printer__group">
            <label class="group__label">{{ $t("pos.printing.ip_address") }}</label>
            <div class="group__value">{{ kitchenPrinter.name }}</div>
          </div>
          <div class="printer__group">
            <label class="group__label">{{ $t("pos.printing.group") }}</label>
            <div class="group__value">{{ getPrintingGroupName(kitchenPrinter.group) }}</div>
          </div>
          <div class="printer__actions">
            <button class="btn btn-primary" v-wave @click="testPrinter(kitchenPrinter.name)">
              {{ $t("pos.printing.print_identification") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="setting__action">
      <button class="btn btn-secondary btn--close" @click="emitQuit" v-if="showActionButtons" v-wave>
        <svgicon src="icons/back-full.svg" :height="24" :width="24"></svgicon>
        {{ $t("back") }}
      </button>
      <div class="filler"></div>
      <!--<button class="btn btn-secondary" @click="fetchPrinters" v-wave>
        <svgicon src="icons/fetch.svg" :height="24" :width="24"></svgicon>
        {{$t('pos.printing.fetch_printers')}}
      </button>-->
      <button class="btn btn-primary btn--save" @click="saveConfiguration" v-if="showActionButtons" v-wave>
        <svgicon src="icons/save.svg" :height="24" :width="24"></svgicon>
        {{ $t("pos.printing.save") }}
      </button>
    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import PrintFormatter from "@/lib/printer/printFormatter";

  export default {
    name: "PrinterConfiguration",

    props: {
      showActionButtons: Boolean
    },

    data() {
      return {
        printers: [],
        uuid: null,
        ready: false
      };
    },

    computed: {
      posConfiguration() {
        return this.$store.state.posConfiguration;
      }
    },

    mounted() {
      this.posConfiguration.registerPrinters();
      this.loadPrinters();
      this.uuid = generateUUID();
      this.ready = true;

      EventBus.$on("keyboard-enter", (args) => {
        if (args.uuid === this.uuid && args.reference) {
          args.reference.nickname = args.value || args.reference.name;
        }
      });
    },

    beforeDestroy() {
      EventBus.$off("keyboard-enter");
    },

    methods: {
      loadPrinters() {
        this.printers = this.posConfiguration.getPrinters();
      },
      fetchPrinters() {
        showSpinner(this.$t("pos.printing.fetching"));
        setTimeout(async () => {
          let printerResponse = await new PrintFormatter({
            xposUrl: this.posConfiguration.getXposUrl()
          }).getPrinters();
          hideSpinner();
          if (!printerResponse.success) {
            toast({
              message: this.$t("pos.printing.connect_error"),
              type: "error",
              title: this.$t("error.title")
            });
          } else {
            toast({
              message: this.$t("pos.printing.connect_success"),
              type: "success",
              title: this.$t("success")
            });
          }
          let newPrinters = printerResponse.printers;

          this.posConfiguration.mergeDiscoveredPrinters(this.printers, newPrinters);
          this.posConfiguration.saveDiscoveredPrinters(this.printers);
          this.loadPrinters();
        }, 500); //Delay to make sure spinner can display
      },
      saveConfiguration() {
        this.posConfiguration.save();
        //this.posConfiguration.saveDiscoveredPrinters(this.printers);
        toast({
          message: this.$t("pos_configuration.saved"),
          type: "success",
          title: this.$t("success")
        });
      },
      emitQuit() {
        this.$emit("quit");
      },
      testPrinter(printerName) {
        if (!printerName || this.printers.length === 0) {
          return;
        }
        new PrintFormatter({
          printerName: printerName,
          xposUrl: this.posConfiguration.getXposUrl()
        }).printPrinterName();
      },
      getPrintingGroupName(id) {
        let groups = this.posConfiguration.getPrintingGroups();
        let group = groups.find(g => g.id === id);
        if (group) {
          return this.$to(group.name);
        }
        return "";
      },
      renamePrinter(printerName) {
        let printer = this.printers.find(p => p.name === printerName);
        EventBus.$emit("show-keyboard", {
          uuid: this.uuid,
          reference: printer,
          value: printer.nickname
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp .configuration--setting {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  .setting__info {
    flex-basis: 0;
    flex-grow: 1;
    align-self: flex-start;
  }

  .printers {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    max-height: 230px;
  }

  .printer {
    margin-top: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 20px 10px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;

    input, select {
      border-radius: 12px;
      height: 56px;
      padding: 12px 16px;
      font-size: 20px;
      font-weight: 600;
    }

    .btn {
      padding: 16px;
      height: 56px;
      line-height: 1;
      margin-left: 20px;
      font-size: 20px;
      font-weight: 600;
      background-color: var(--background-color-6);
      color: var(--primary-color);
    }
  }

  .printer__name {
    flex-grow: 1;

    select {
      width: 100%;
    }
  }

  .printer__group {
    padding-left: 20px;
    flex-grow: 1;

    .group__label {
      display: block;
      font-size: 18px;
      color: var(--theme-color-2);
      margin-bottom: 2px;
    }

    .group__value {
      font-size: 20px;
      font-weight: 600;
      color: var(--theme-color);
    }
  }

  .printer__actions {
    display: flex;
  }

  .printer__icon {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-right: 20px;
    fill: var(--illustration-front-color);
    background-color: var(--background-color-6);
  }

  &:not(:last-child) {
    border-bottom: 2px dashed var(--border-color-4);
    margin-bottom: 20px;
  }

  &:last-child {
    padding-bottom: 15px;
  }
}

.info--title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 18px;
  color: var(--primary-color);
}

.filler {
  flex-grow: 1;
}

#iShopFoodApp .setting__action {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;

  &.split {
    justify-content: space-between;
  }

  .btn--save {
    margin-left: 20px;
  }

  .btn {
    font-size: 20px;
    height: 56px;
    font-weight: 600;
    padding: 16px;

    .svgicon {
      margin-right: 10px;
    }
  }
}
</style>