<template>
  <div id="resetPassword">

    <PageTitle v-if="!embed">
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      {{ $t('login.forgot_password_title') }}
    </PageTitle>

    <div class="page-content">

      <Panel :title="$t('login.forgot_password_title')" icon="icons/lock.svg" v-if="!completed">
        <p>{{$t("login.forgot_password_text")}}</p>
        <ErrorBox :visible="errorVisible" @click="errorVisible = false"></ErrorBox>
        <label for="txtEmail">{{$t("email")}}</label>
        <input id="txtEmail" type="text" v-model="email" :class="{invalid: $validate.error('email')}">
        <div class="buttons">
          <button type="button" class="btn btn-secondary" @click="back">{{$t("back")}}</button>
          <button type="button" class="btn btn-primary" @click="send">{{$t("send")}}</button>
        </div>
      </Panel>

      <Panel :title="$t('newPassword.email_sent')" icon="icons/register.svg" v-if="completed">
        <div class="complete">
          <Illustration src="icons/loyalty.svg"></Illustration>
          <div class="title">{{ $t("newPassword.email_sent") }}</div>
          <div>{{ $t("newPassword.next_steps") }}</div>
          <button class="btn btn-secondary" @click="exit">{{ $t("continue") }}</button>
        </div>
      </Panel>

    </div>

  </div>
</template>

<script>

  import { mapActions } from 'vuex';
  import ErrorBox from "@/components/tools/ErrorBox";
  import Illustration from "@/components/modal/Illustration.vue";

  export default {

    name: "resetPassword",

    components: { ErrorBox, Illustration },

    props: {
      embed: Boolean
    },

    data() {
      return {
        email: "",
        errorVisible: false,
        completed: false
      };
    },

    validate: {
      email: {
        required: true,
        email: true
      }
    },

    mounted() {
      this.$store.commit("showNavigationCart", false);
    },

    methods: {

      ...mapActions({
        resetPasswordAction: "resetPassword"
      }),
      back() {
        if (this.embed) {
          this.$emit("back");
        } else {
          window.history.back();
        }
      },
      async send() {
        if (!this.$validate.valid()) {
          this.errorVisible = true;
          return;
        }
        showSpinner();
        let response = await this.resetPasswordAction(this.email);
        hideSpinner();
        if (response.success) {
          this.completed = true;
        } else {
          showAffirmation(this.$t("error.title"), this.$t("newPassword.could_not_send"));
        }
      },
      exit() {
        if (this.embed) {
          this.$emit("back");
        } else {
          this.$router.push(this.$router.generate("/login?redirect=menu"));
        }
      }

    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 0;
    display: flex;
  }

  .panel {
    width: 520px;
    max-width: 100%;
    margin: auto !important;
    input {
      width: 100%;
      margin-bottom: 20px;
    }
    p {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: justify;
    }
  }

  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -10px;
    .btn {
      flex-grow: 1;
      margin: 0 10px 10px;
    }
  }

  .error-box {
    margin-bottom: 20px;
  }

  .complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    div {
      text-align: center;
    }
    button {
      margin-top: 20px;
    }
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      padding: 0;

      &:after {
        content: none;
      }
    }
  }

</style>
