
<template>
<div id="qr-payment--home" :class="{offline: !online}">
  <template v-if="$store.getters.isMobileOrTablet">
    <div class="home-banner" :style="{backgroundImage: 'url(\'' + backgroundImage + '\') !important'}"></div>

    <div class="home-form" v-if="company">
      <div class="home-form__content">

        <div class="home-form__logo" v-if="logo">
          <img :src="logo" alt="Logo">
        </div>

        <div class="home-form__description">{{$t('qr_payment.home.description').replace("{COMPANY}", company.name )}}</div>

        <div class="home-form__instructions center">
          <template v-if="hasDigitalMenuURL">
            {{$t('qr_payment.main_description')}}
          </template>
          <template v-if="!hasDigitalMenuURL">
            {{$t('qr_payment.main_description_no_menu')}}
          </template>
        </div>

        <div class="spacer"></div>
        <template v-if="!scanCheckOnly">
          <div class="home-form__input-group" v-if="!queryNumber">
            <label for="txtTableNumber" class="input-group__label">{{$t('qr_payment.home.table_number')}}</label>
            <input id="txtTableNumber" type="text" class="input-group__input" v-model="tableNumber" @keyup.enter="goToInvoices">
          </div>
        </template>
        <template v-if="scanCheckOnly">
          <div class="home-form__scan-only">{{$t('qr_payment.scan_check_only')}}</div>
        </template>
      </div>

      <div class="home-form__buttons" v-if="!scanCheckOnly">
        <!--<button class="btn btn-secondary home-form__button" v-if="canScan" @click="goToScan">{{$t('qr_payment.home.scan_code')}}</button>-->
        <div class="home-form__button button--invert" @click="goToMenu" v-if="hasDigitalMenuURL">
          <div class="button__icon">
            <svgicon src="icons/menu-inverted.svg" :height="82" :width="82"></svgicon>
          </div>
          <div class="button__text">
            {{$t('qr_payment.view_menu')}}
          </div>
        </div>
        <div class="home-form__button button--main" @click="goToInvoices" :class="{disabled: !online}">
          <div class="button__icon">
            <svgicon src="icons/payment.svg" :height="40" :width="40"></svgicon>
          </div>
          <div class="button__text">
            <template v-if="disableMultiCheckSelection">
              {{$t('qr_payment.pay_invoice')}}
            </template>
            <template v-if="!disableMultiCheckSelection">
              {{$t('qr_payment.pay_invoices')}}
            </template>
          </div>
        </div>
      </div>

      <div class="home-form__offline" v-if="!online">
        <svgicon src="icons/danger.svg" :height="24" :width="24"></svgicon>
        <div class="warning-text"><b>{{$t('warning')}}:</b> {{$t('qr_payment.offline')}}</div>
      </div>
    </div>

    <div class="home__footer">
      <div class="home__login" @click="login" v-if="isUserloggedOutOrAnonymous">
        {{$t('login.login')}}
      </div>
      <div class="home__login" @click="logout" v-if="!isUserloggedOutOrAnonymous">
        {{$t('navigation.logout')}}
      </div>

      <FloatingLocaleSwitcher inline text display-all></FloatingLocaleSwitcher>
    </div>

  </template>
  <template v-else>
    <DesktopNotSupported></DesktopNotSupported>
  </template>

</div>
</template>

<script>
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";
  import DesktopNotSupported from "@/components/qrPayment/DesktopNotSupported.vue";
  import QrPaymentDummyTableData_FR from "@/assets/data/qr-payment-dummy-table-data-fr.json";
  import QrPaymentDummyTableData_EN from "@/assets/data/qr-payment-dummy-table-data-en.json";
  import ExternalLink from "@/components/tools/ExternalLink.vue";
  import {mapActions} from "vuex";
  import PosSale from "../../models/qrPay/QrPaySaleModel";
  import EventBus from "../../lib/eventBus";

  export default {
    name: "qrPaymentHome",

    components: { FloatingLocaleSwitcher, DesktopNotSupported, ExternalLink },

    beforeMount() {
      if (this.$route.query.reload) {
        let url = "/table";
        if (this.queryNumber) {
          url += "?number=" + this.queryNumber;
        }
        this.$router.replace(this.$router.generate(url));
        window.location.reload();
      }
    },

    async mounted() {
      this.$order.tableNumber = "";
      this.$order.invoiceNumber = "";
      this.$order.invoices = [];
      this.$store.state.sale = null;

      if (this.queryNumber) {
        this.tableNumber = this.queryNumber;
      }

      if (this.queryInvoice) {
        this.invoiceNumber = this.queryInvoice;
      }

      let interval = setInterval(async () => {
        if (!this.company || !this.branch) {
          return;
        }
        clearInterval(interval);
        showSpinner();
        await this.checkPosStreamServer();
        if (this.isIOS) {
          this.currentMenuUrlForLocale = await this.getDigitalMenuURL();
        }
        hideSpinner();

        if (this.invoiceNumber) {
          this.goToInvoices();
        }
      }, 100);
    },

    data() {
      return {
        tableNumber: null,
        online: true,
        invoiceNumber: null,
        currentMenuUrlForLocale: ""
      }
    },

    watch: {
      "$ts.state.locale": async function () {
        if (this.isIOS) {
          this.currentMenuUrlForLocale = await this.getDigitalMenuURL();
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      queryNumber() {
        return this.$route.query.number || this.$route.query.NUMBER;
      },
      queryInvoice() {
        return this.$route.query.invoice || this.$route.query.INVOICE;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      hasDigitalMenuURL() {
        if (!this.company) {
          return;
        }
        return this.company.hasDigitalMenuUrlForLocale(this.$ts.state.locale);
      },
      canScan() {
        return this.company && this.company.payAtTheTable && this.company.payAtTheTable.tableNumberScanActivated;
      },
      scanCheckOnly() {
        return this.company && this.company.scanCheckOnlyForQrPayment();
      },
      backgroundImage() {
        if (!this.branch || !this.branch.payAtTheTable.backgroundImage) {
          return;
        }
        return this.$store.state.urlFileServer + this.branch.payAtTheTable.backgroundImage.url;
      },
      logo() {
        if (!this.branch) {
          return;
        }
        if (!this.branch.payAtTheTable.logo) {
          return this.$store.state.urlFileServer + this.branch.navigationLogo.url;
        }
        return this.$store.state.urlFileServer + this.branch.payAtTheTable.logo.url;
      },
      isDemoTable() {
        return this.tableNumber === DEMO_TABLE;
      },
      sale() {
        return this.$store.state.sale;
      },
      isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      },
      isLoggedUserAnonymous() {
        return this.$user.token && this.$user.anonymous;
      },
      isUserloggedOutOrAnonymous() {
        return !this.$user.token || this.isLoggedUserAnonymous;
      },
      disableMultiCheckSelection() {
        return this.company && this.company.disableMultiCheckSelectionForQrPayment();
      }
    },

    methods: {
      ...mapActions(["loginGuest", "getQrPaymentTable", "pingPosStreamServer"]),

      async checkPosStreamServer() {
        if (this.isDemoTable) {
          this.online = true;
          return;
        }
        let response = await this.pingPosStreamServer({companyId: this.company.id});
        this.online = response.success;
      },

      async goToInvoices() {
        if (!this.tableNumber) {
          return;
        }

        await this.checkPosStreamServer();
        if (!this.online) {
          return;
        }

        if (!this.$user.token) {
          await this.loginGuestUser();
        }

        if (this.isDemoTable) {
          let data = this.$ts.state.locale === "fr" ? QrPaymentDummyTableData_FR : QrPaymentDummyTableData_EN;
          this.loadSale(JSON.parse(JSON.stringify(data)));
          return;
        }

        showSpinner();
        let response = await this.getQrPaymentTable({
          company: this.company.nameCanonical,
          table: this.tableNumber,
          invoice: this.invoiceNumber || ""
        });
        hideSpinner();
        if (!response || !response.success) {
          showAffirmation(this.$t("oops"), this.$t("qr_payment.home.error_fetch_table"));
          return;
        }
        this.loadSale(response.table);
      },

      loadSale(saleData) {
        let sale = new PosSale(saleData, this.tableNumber);

        if (!sale.validate()) {
          return;
        }

        this.$store.state.sale = sale;
        this.$order.tableNumber = this.tableNumber;
        if (this.queryNumber) {
          this.$order.setTableNumberFromQrCode();
        }
        this.$router.push({ path: this.$router.generate("/table/invoices"), query: this.$route.query });
      },

      goToScan() {
        this.$router.push(this.$router.generate("/table/scan"));
      },

      async loginGuestUser() {
        showSpinner();
        let response = await this.loginGuest({ branch: this.branch.nameCanonical });
        hideSpinner();
        if (response.success) {
          this.$user.load(response.user.user);
          this.$user.token = response.user.token;
          this.$user.anonymous = true;
          setConfiguration("token", response.user.token, sessionStorage);
        }
      },

      clearTableNumber() {
        this.tableNumber = ""
      },

      goToMenu() {
        if (this.isIOS) {
          window.open(this.currentMenuUrlForLocale);
          return;
        }
        let url = "/table/menu-display";
        if (this.$route.query.number) {
          url += "?number=" + this.$route.query.number;
        }
        this.$router.push(this.$router.generate(url));
      },

      async getDigitalMenuURL() {
        if (!this.company) {
          return null;
        }
        return await this.company.getDigitalMenuUrlForLocale(this.$ts.state.locale, false);
      },

      login() {
        this.logout();//logout guest user
        EventBus.$emit("show-authentication-modal");
      },

      logout() {
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
      },

    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp {
    #qr-payment__home {
      background-position: center;
      display: flex;
      height: 100%;
      flex-direction: column;

      &.offline {
        .home-form__buttons {
          padding-bottom: 40px;
        }
      }
    }
    .home-logo-holder {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home-logo-holder--logo {
      width: 100%;
      height: auto;
    }
    .home-banner {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 45vh;
      position: relative;
      overflow: hidden;
      clip-path: ellipse(85% 100% at 50% 0);
    }
    .home-form {
      width: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .home-form__logo {
      padding-top: 50px;
      img {
        width: 100%;
        height: auto;
        max-height: 150px;
      }
    }
    .home-form__content {
      padding: 0 40px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
    }
    .home-form__instructions {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-grow: 1;

      &.center {
        text-align: center;
        display: block;
      }
    }
    .home-form__instruction {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .svgicon {
        flex-shrink: 0;
        fill: var(--primary-color) !important;
        margin-right: 20px;
      }
    }

    .home-form__offline {
      display: flex;
      align-items: center;
      $color: #ed2937;
      color: $color;
      padding: 0 40px 40px 40px;
      font-size: 14px;
      line-height: 1.1;
      .svgicon {
        flex-shrink: 0;
        fill: $color;
        margin-right: 20px;
      }
      .warning-text, .warning-text b {
        color: $color !important;
      }
    }
    .home-form__scan-only {
      padding: 60px 15px;
      font-weight: bold;
      text-align: center;
    }
    .home-form__input-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-basis: 0;
      padding-top: 10px;
    }
    .home-form__description {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
      text-align: center;
      padding-top: 45px;
    }
    .home-form__separator {
      width: 100px;
      height: 6px;
      border-radius: 6px;
      background-color: var(--primary-color);
      margin: 10px 0 30px 0;
    }
    .home-form__buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 50px 20px;
      margin-top: 30px;
    }
    .home-form__button {
      font-size: 15px;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .button__icon {
        fill: white;
        background: linear-gradient(to top, #fc6650, #ed2937);
      }

      &.button--invert {
        .button__icon {
          position: relative;
        }
        .svgicon {
          position: absolute;
          left: -1px;
          right: -1px;
          top: -1px;
          bottom: -1px;
        }
      }

      .svgicon {
        flex-shrink: 0;
      }
      .button__icon {
        height: 80px;
        width: 80px;
        border-radius: 100%;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.16);
      }
      .button__text {
        color: #fc6650;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
    }
    .input-group__input {
      border-radius: 12px;
      background-color: transparent;
      margin-bottom: 8px;
      text-align: center;
      font-size: 22px;
      letter-spacing: 1px;
      font-weight: 500;
      width: 100%;
      max-width: 200px;
      max-height: 50px;
    }
    .input-group__label {
      color: #808080;
      font-weight: 500;
      font-size: 16px;
      padding: 5px;
    }
  }

  .home__login {
    font-weight: bold;
    font-size: 14px;
    padding: 15px;
    position: relative;
  }

  .home__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 10px 40px;
  }

  @media screen and (max-height: 670px) {
    #iShopFoodApp {
      .home-form__description {
        padding-top: 30px;
      }
      .home-form__buttons {
        padding-bottom: 30px;
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #qr-payment__home {
    .localeTopRight {
      z-index: 8;
      fill: white;
    }
  }
</style>
