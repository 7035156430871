// @ts-check
import Vue from "vue";
import Vuex from "vuex";
import moment from "moment-timezone";
import { Settings } from "luxon";
import { Util } from "@/util/Util";

Vue.use(Vuex);

const strings = {
  fr: require("./i18n/fr.json"),
  en: require("./i18n/en.json")
};

const currencySymbolPosition = {
  fr: "after",
  en: "before"
};

let check = function(fr, en, parent) {
  let gotIn = false;
  for (let key in fr) {
    if (typeof en[key] === "undefined") {
      console.log("[LOCALE ERROR] Key '" + (parent ? parent + "." + key : key) + "' missing in lang 'en'");
    } else if (typeof fr[key] === "object") {
      check(fr[key], en[key], parent ? parent + "." + key : key);
      gotIn = true;
    }
  }
  for (let key in en) {
    if (typeof fr[key] === "undefined") {
      console.log("[LOCALE ERROR] Key '" + (parent ? parent + "." + key : key) + "' missing in lang 'fr'");
    } else if (typeof en[key] === "object" && !gotIn) {
      check(fr[key], en[key], parent ? parent + "." + key : key);
    }
  }
}
check(strings.fr, strings.en);

let localizeStore = new Vuex.Store({
  state: {
    locale: "en",
    currency: "CAD",
    customTranslations: {},
    currencySymbolPosition: currencySymbolPosition,
    strings: strings
  },
  mutations: {
    setLocale(state, locale) {
      if (["fr", "en"].indexOf(locale) === -1) {
        locale = "en";
      }
      state.locale = locale;
      setConfiguration("locale", locale);
      moment.locale(locale);
      Settings.defaultLocale = locale;
    },
    setCustomTranslations(state, customTranslations) {
      state.customTranslations = customTranslations;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    }
  },
  getters: {
    get: (state) => (id, values) => {

      if (id && typeof id === 'object') {
        return localizeStore.getters.getFromObject(id);
      }

      if (!id || typeof id !== 'string') {
        return "";
      }

      if (state.customTranslations[state.locale] && state.customTranslations[state.locale][id]) {
        return state.customTranslations[state.locale][id];
      }

      let split = id.split(".");
      let root = state.strings[state.locale];
      for (let key of split) {
        if (root == null) {
          break;
        }
        root = root[key];
      }
      if (root == null) {
        console.log("[LOCALE ERROR] Could not find locale string with key '" + id + "'");
      }
      let string = root || id;
      if (values) {
        for (let key of Object.keys(values)) {
          string = string.replace(new RegExp("\\$\\{" + key + "\\}", "g"), String(values[key]));
        }
      }
      return string;
    },
    getLocales: (state) => {
      return Object.keys(state.strings);
    },
    getFromObject: (state) => (obj = {}) => {
      if (!obj || typeof obj !== 'object') {
        return;
      }
      return obj[state.locale] || obj.fr || obj.en;
    },

    getCurrencySymbolPosition: (state) => {
      return state.currencySymbolPosition[state.locale];
    },

    // todo figure out how to not duplicate this
    getSymbol: (state) => {
      switch (state.currency) {
        case "GBP":
          return "£";
        case "EUR":
          return "€";
        case "CAD":
        case "USD":
        default:
          return "$";
      }
    },

    getCurrencySymbol: (state) => (amount) => {
      let symbol = "";

      if (typeof amount === "number") {
        amount = Util.Money.roundForDisplay(amount);
      }

      switch (state.currency) {
        case "GBP":
          symbol = "£";
          break;
        case "EUR":
          symbol = "€";
          break;
        case "CAD":
        case "USD":
        default:
          symbol = "$";
          break;
      }

      switch (state.currencySymbolPosition[state.locale]) {
        case "before":
          return symbol + " " + amount;
        case "after":
          return amount + " " + symbol;
        default:
          return symbol + " " + amount;
      }
    }
  }
})

let plugin = {
  install(Vue, options = {}) {
    Vue.prototype.$t = localizeStore.getters.get;
    Vue.prototype.$to = localizeStore.getters.getFromObject;
    Vue.prototype.$ts = localizeStore;
    Vue.prototype.$tc = localizeStore.getters.getCurrencySymbol;
    window.translate = localizeStore.getters.get;
    window.translateCurrency = localizeStore.getters.getCurrencySymbol;
    window.translateObject = localizeStore.getters.getFromObject;
  }
}

export { plugin as Localize, localizeStore as LocalizeStore };
