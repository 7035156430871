<template>
  <div id="orderType">

    <PageTitle>
      <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
      <template>{{$t('payment.online_order')}}</template>
    </PageTitle>

    <FloatingLocaleSwitcher></FloatingLocaleSwitcher>

    <div class="hub">

      <div class="left"></div>

      <div class="right">
        <div class="page-content">
          <div class="order-card">
            <div class="card-header">
              <img id="logo" v-if="mobileImage" :src="$store.state.urlFileServer + mobileImage">
              <svgicon src="icons/comptoir.svg" :height="42" :width="42"></svgicon>
              <div class="card-big-title">{{$t("order.welcome")}}</div>
              <hr class="mobile">
            </div>
            <div class="card-content">
              <div class="container commmand-type">
                <div class="text">{{$t("order.welcome_text")}}</div>
                <div class="split">
                  <div id="takeout" class="card-content-left takeout" @click="setMethod('takeout')" v-if="branch && branch.hasOrderMethod('takeout')">
                    <div class="top">
                      <div class="img" :style="{backgroundImage: 'url(img/takeout.png), url(img/background.png)'}"></div>
                    </div>
                    <div id="takeout__bottom" class="bottom">
                      {{$t("order.takeout")}}
                    </div>
                  </div>
                  <div id="delivery" class="card-content-right delivery" @click="setMethod('delivery')" v-if="branch && branch.hasOrderMethod('delivery', true)">
                    <div class="top">
                      <div class="img" :style="{backgroundImage: 'url(img/delivery.png), url(img/background.png)'}"></div>
                    </div>
                    <div id="delivery__bottom" class="bottom">
                      {{$t("order.delivery")}}
                    </div>
                  </div>
                  <div id="inPlace" class="card-content-right inPlace" @click="setMethod('inPlace')" v-if="branch && branch.hasOrderMethod('inPlace')">
                    <div class="top">
                      <div class="img" :style="{backgroundImage: 'url(img/inPlace.jpg), url(img/background.png)'}"></div>
                    </div>
                    <div id="inPlace__bottom" class="bottom">
                      {{$t("order.in_place")}}
                    </div>
                  </div>
                  <div id="catering" class="card-content-right catering" @click="setMethod('catering')" v-if="branch && branch.hasOrderMethod('catering')">
                    <div class="top">
                      <div class="img" :style="{backgroundImage: 'url(img/inPlace.jpg), url(img/background.png)'}"></div>
                    </div>
                    <div id="catering__bottom" class="bottom">
                      {{$t("order.catering")}}
                    </div>
                  </div>
                  <div id="hotel" class="card-content-right hotel" @click="setMethod('hotel')" v-if="branch && branch.hasOrderMethod('hotel')">
                    <div class="top">
                      <div class="img" :style="{backgroundImage: 'url(img/inPlace.jpg), url(img/background.png)'}"></div>
                    </div>
                    <div id="hotel__bottom" class="bottom">
                      {{$t("order.hotel")}}
                    </div>
                  </div>
                </div>

                <EnterDisplayMode></EnterDisplayMode>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- <Steps></Steps> -->
  </div>
</template>

<script>

  import Steps from "@/components/order/Steps.vue";
  import EnterDisplayMode from "@/components/EnterDisplayMode.vue";
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";

  export default {

    name: "orderType",

    components: { Steps, EnterDisplayMode, FloatingLocaleSwitcher },

    mounted() {
      if (this.$route.query.skippedOrderType) {
        this.$store.state.skippedOrderType = false;
      }

      if (this.branch && this.branch.getOrderMethodsAvailable().length === 1) {
        let method = this.branch.getOrderMethodsAvailable()[0];
        this.setMethod(method, true);
        return;
      }

      this.quickModeRedirect();
      this.userFavoriteCompanyRedirect();
      this.hubRedirect();

      if (this.$order.method) {
        this.setMethod(this.$order.method, true);
        return;
      }

      this.$store.commit("showNavigationCart", false);
    },

    watch: {
      "$store.state.currentCompany": function(newValue) {
        if (newValue && this.$store.state.quickMode) {
          this.$router.replace(this.$router.generate("/menu"));
        }
      },
      "$order.method": function(newValue) {
        if (!!newValue) {
          this.setMethod(newValue, true);
          return;
        }
      }

    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      branchLogo() {
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.navigationLogo) {
          return null;
        }
        return this.$store.state.urlFileServer + this.$store.state.currentBranch.navigationLogo.url;
      },
      userHasFavoriteCompany() {
        return this.branch && this.$user.token && this.branch.getFavoriteCompanyForUser(this.$user);
      },
      mobileImage() {
        if (!this.branch) {
          return null;
        }
        return this.branch.mobileOrderTypeImage
          ? this.branch.mobileOrderTypeImage.url
          : (this.branch.mobileMenuLogo
            ? this.branch.mobileMenuLogo.url
            : "");
      }
    },

    methods: {
      quickModeRedirect() {
        setTimeout(() => {
          if (this.$store.state.quickMode && (!this.branch || !this.branch.shouldDisplayTutorial())) {
            this.$router.replace(this.$router.generateFirstPage());
            return;
          }
        }, 0);
      },
      userFavoriteCompanyRedirect() {
        if (this.userHasFavoriteCompany) {
          this.$store.dispatch("setCurrentCompany", this.branch.getFavoriteCompanyForUser(this.$user));
          let redirect = this.branch.shouldLaunchAppWithHub() ? "/hub" : "/menu";
          this.$router.replace(this.$router.generate(redirect))
        }
      },
      hubRedirect() {
        if (this.branch && this.branch.shouldLaunchAppWithHub() && !this.$store.state.currentCompany) {
          this.$store.dispatch("setCurrentCompany", this.branch.getFirstNonEmergencyClosedCompany());
          this.$router.replace(this.$router.generate("/hub"));
        }
      },
      setMethod(method, skip) {
        this.$order.setMethod(method);
        if (this.userHasFavoriteCompany) {
          this.userFavoriteCompanyRedirect();
          return;
        }
        if (method === "delivery") {
          if (skip) {
            this.$router.replace(this.$router.generate("/orderLocation"));
          } else {
            this.$router.push(this.$router.generate("/orderLocation"));
          }
        } else if (skip) {
          this.$router.replace(this.$router.generate("/orderCompany"));
        } else {
          this.$router.push(this.$router.generate("/orderCompany"));
        }
      }
    }

  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    height: 100%;
  }

  #orderType #logo {
    max-width: 100px;
    display: none;
  }

  @media screen and (max-width: 600px) {
    #orderType #logo {
      display: block;
    }
  }

  .commmand-type {
    .split {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .card-content-left, .card-content-right {
        cursor: pointer;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        margin: 35px 40px;
        flex-grow: 1;
        flex-basis: 0;
        .top {
          width: 100%;
          height: 340px;
          .img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-image: url(../../assets/img/img-a-propos.jpg);
            background-size: cover;
            background-position: center;
          }
        }
        .bottom {
          padding: 25px;
          font-size: 24px;
          color: #404040;
          text-align: center;
        }
      }
    }
  }

  #iShopFoodApp.xs {

    .page-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      &:after {
        display: none;
      }
    }
    .order-card {
      border-radius: 0;
      box-shadow: none;
      flex-grow: 1;
      margin: 0;
      .card-header {
        flex-grow:1;
        flex-basis:0;
        justify-content: flex-end;
        overflow:hidden;
        #logo{
          object-fit: contain;
          max-width:100%;
          max-height:150px;
          width: auto;
          height: auto;
          overflow:hidden;
        }
      }
      .card-content {
        padding-bottom:25%;
      }
      .card-big-title {
        display:none;
      }
    }
    .commmand-type .split {
      padding-top: 40px;
      .card-content-left, .card-content-right {
        margin: 10px;
        .top {
          height: 130px;
        }
        .bottom {
          font-size: 12px;
          padding: 7px;
          min-height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .pageTitle {
      display: inherit;
    }
  }

  #iShopFoodApp .pageTitle {
    display: none;
  }

  #iShopFoodApp.xs .view-menu .btn {
    font-size: 12px;
  }

  #iShopFoodApp .view-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .order-step-complete-bar, .order-card {
    width: 100%;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }

  .card-content {
    flex-shrink: 0;
    .text {
      padding-bottom: 5%;
      text-align: center;
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .card-big-title {
    text-align: center;
  }


  @media screen and (min-width:601px) and (max-width: 1281px) {
    .commmand-type {
      .split {
        margin:10px;
        flex-direction: column;
        display: flex;
        .card-content-left, .card-content-right {
          margin: 10px;
          display:flex;
          height: 144px;
          width:100%;
          flex-basis: initial;
          .top {
            height: 144px;
            min-height: 100px;
            width: 144px;
            align-items: center;
            justify-content: center;
            flex-shrink:0;
          }
          .bottom {
            font-size: 18px;
            min-height: 46px;
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp #orderType .toggleMenu {
    user-select: none;
    visibility: hidden;
    cursor: initial;
  }
</style>
