<template>
  <div class="tooltipMenu" :class="{visible: visible, notAbsolute: notAbsolute && !$store.getters.isMobileOrTablet, mobile: $store.getters.isMobileOrTablet && !$kiosk}">
    <div class="backdrop" @click="close"></div>
    <!-- <div class="slider" v-if="$store.getters.isMobileOrTablet"></div> -->
    <div class="tooltip" :ref="id" :class="{kiosk: $kiosk}">
      <div class="triangle"></div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  import Popper from "popper.js";
  import EventBus from '../../lib/eventBus';

  export default {
    name: "tooltipMenu",
    props: {
      visible: Boolean,
      parent: HTMLDivElement,
      notAbsolute: Boolean,
      id: String
    },
    data() {
      return {
        popper: null
      }
    },
    methods: {
      stopPropagation(e) {
        e.stopPropagation();
      },
      close() {
        EventBus.$emit("close-tooltip");
      }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal) {
          if (!this.$store.getters.isMobileOrTablet || this.$kiosk) {
            let options = {
              placement: "bottom"
            };
            if (this.$kiosk) {
              options.placement = "top";
              options.modifiers = {
                preventOverflow: {
                  escapeWithReference: true
                }
              }
            }
            this.popper = new Popper(this.parent, this.$refs[this.id], options);
          }
        } else {
          if (this.popper) {
            this.popper.destroy();
          }
        }
      }
    }
  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .visible {
    &.tooltipMenu {
      opacity: 1;
      pointer-events: all;
    }
    .tooltip {
      display: block;
      position: static !important;
    }

    &.mobile {
      top: initial;
      bottom: 0;
      left: 0;
      right: 0;

      .slider {
        width: 50px;
        margin: 0 auto 10px auto;
        height: 10px;
        border-radius: 5px;
        background-color: rgba(255,255,255,0.5);
        border: 0.5px solid rgb(145,142,141);
      }

      .triangle {
        display: none;
      }
      .tooltip {
        left: 0 !important;
        text-align: left;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          content: none;
        }

        .item {
          &:not(:last-child) {
            border-bottom: 1px solid rgb(230,230,230);
          }
        }
      }
    }

  }

  .notAbsolute {
    &.tooltipMenu {
      position: initial;
      top: initial;
    }
    .tooltip {
      position: relative !important;
      transform: none !important;
      top: initial !important;
      left: initial !important;
      right: initial !important;
      margin-top: 20px;
    }
  }

  .tooltipMenu {
    position: absolute;
    // transform: translate3d(0,0,0);
    top: 15px;
    z-index: 997;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  #iShopFoodApp .tooltip {
    background-color: white;
    display: none;
    color: black;
    z-index: 99;
    border-radius: 5px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
    cursor: auto;
    padding: 10px 0;
    left: -10px !important;
    .item {
      color: black !important;
      white-space: nowrap;
      padding: 12px 0;
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;
      &.disabled {
        color: #bfbfbf !important;
        cursor: auto;
        pointer-events: none;
      }
    }
    .content {
      padding: 0 20px;
      max-height: 200px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    &.kiosk {
      .content {
        padding: 0 10px;
      }
      .content .item {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        padding: 25px 30px;
        border-radius: 5px;
        transition: background-color 350ms ease;

        &:hover {
          background-color: #ededed;
        }
      }
      &::after {
        content: none;
      }
      .triangle {
        top: initial;
        bottom: -10px;
        transform: translateX(-50%) rotateZ(225deg);
      }
    }

    &::after {
      content: "";
      background-color: white;
      width: 20px;
      height: 20px;
      border-bottom-right-radius: 100%;
      position: absolute;
      left: 50%;
      top: -10px;
      transform: translateX(-50%) rotateZ(45deg);
    }
  }
  .visible .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    cursor: auto;
  }

  .triangle {
    background-color: white;
    width: 20px;
    height: 20px;
    border-bottom-right-radius: 100%;
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%) rotateZ(45deg);
  }

</style>
