<template>
  <div id="brandingSelection" :class="{kiosk: $kiosk}">
    <div class="branding__header" v-if="!$kiosk">
      <div class="header__title">{{$t('branding.title')}}</div>
      <div class="header__description">{{$t('branding.description')}}</div>
    </div>

    <div class="brandings">
      <div class="branding" v-for="(branding, brandingIndex) of brandings" :key="'branding-' + brandingIndex">
        <div class="branding__promo-image" :style="{backgroundImage: 'url(\'' + getImage(branding.promoImage) +  '\''}"
             @click="setBranding(branding)" @contextmenu="contextMenuSetBranding(branding)"></div>
        <div class="branding__bottom">
          <div class="branding__logo">
            <img class="logo" :src="getImage(branding.logo)" alt="Logo" @click="setBranding(branding)" @contextmenu="contextMenuSetBranding(branding)">
          </div>
          <div class="branding__description">
            <div class="branding__name">{{$to(branding.name)}}</div>
            <div class="branding__availability" :class="{
              open: isBrandingAvailable(branding),
              openLater: !isBrandingAvailable(branding) && getBrandingStartHour(branding),
              closed: !isBrandingAvailable(branding) && !getBrandingStartHour(branding)
            }">
              <template v-if="isBrandingAvailable(branding)">
                {{$t('branding.open')}}
                <template v-if="branding.getAverageDelay($order.method)">
                  <span class="dash">|</span> {{$t('branding.delay_of')}} {{branding.getAverageDelay($order.method)}} {{$t('branding.minutes')}}
                </template>
              </template>
              <template v-if="!isBrandingAvailable(branding)">
                <template v-if="getBrandingStartHour(branding)">{{$t('branding.available_at')}} {{getBrandingStartHour(branding)}}</template>
                <template v-if="!getBrandingStartHour(branding)">{{$t('branding.closed')}}</template>
              </template>
            </div>
            <v-clamp class="branding__about" :max-lines="$kiosk ? 2 : 3">{{ $to(branding.shortDescription) }}</v-clamp>
          </div>
        </div>
        <div class="branding__actions">
          <button class="btn btn-secondary" @click="showAbout(branding)" @contextmenu="contextMenuShowAbout(branding)">{{$t('branding.about')}}</button>
          <button class="btn btn-primary" @click="setBranding(branding)" @contextmenu="contextMenuSetBranding(branding)" :class="{disabled: !isBrandingAvailable(branding)}">
            {{$t('branding.order')}}
          </button>
        </div>
      </div>
    </div>

    <BrandingAboutModal :branding="selectedAboutBranding"
                        :show="showAboutModal"
                        @close="closeAboutModal"
                        @changed="selectedFromModal($event)"></BrandingAboutModal>

  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus.js";
  import BrandingAboutModal from "@/components/BrandingAboutModal.vue";

  export default {
    name: "brandingSelection",

    components: { BrandingAboutModal },

    data() {
      return {
        selectedAboutBranding: null,
        showAboutModal: false
      }
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      brandings() {
        return this.inventory.brandings;
      }
    },

    methods: {
      getImage(image) {
        return image ? this.$store.state.urlFileServer + image.url : null;
      },
      showAbout(branding) {
        this.selectedAboutBranding = branding;
        this.showAboutModal = true;
      },
      closeAboutModal() {
        this.showAboutModal = false;
      },
      selectedFromModal(branding) {
        this.showAboutModal = false;
        this.setBranding(branding);
      },
      isBrandingAvailable(branding) {
        return branding.isAvailable(this.$order.date, this.$order.hour, this.$order.method);
      },
      getBrandingStartHour(branding) {
        return branding.getStartHourForDay(this.$order.date, this.$order.hour, this.$order.method);
      },
      setBranding(branding) {
        if (!this.isBrandingAvailable(branding)) {
          return;
        }
        this.$order.setBranding(branding);
        this.$nextTick(() => {
          EventBus.$emit("collapse-panels");
        });
      },
      contextMenuShowAbout(branding) {
        if (this.$kiosk) {
          this.showAbout(branding);
        }
      },
      contextMenuSetBranding(branding) {
        if (this.$kiosk) {
          this.setBranding(branding);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.kiosk .horizontal #brandingSelection {
    .branding {
      width: 290px;

      .branding__promo-image {
        height: 175px;
      }

      .logo {
        height: 75px;
      }
    }
  }
  #iShopFoodApp #brandingSelection {
    &.kiosk {
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;

      .brandings {
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        align-items: center;
        justify-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;
        width: 100%;
      }
      .branding {
        width: 300px;
        border-radius: 20px;
        margin: 10px 20px;
      }
      .branding__name {
        font-size: 24px;
        padding-top: 20px;
      }
      .branding__about {
        font-size: 16px;
      }
      .branding__promo-image {
        height: 190px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
      }
      .branding__bottom {
        display: block;
      }
      .branding__logo {
        background-color: transparent;
        flex-grow: 0;
        &:after {
          content: "";
          width: 0px;
        }
        .logo {
          background-color: #f8f8f8;
          margin: -50px 0 0 0;
          border-radius: 20px;
          padding: 10px;
        }
      }
      .branding__actions {
        .btn {
          height: 70px;
          padding: 10px 12px;
          font-size: 18px;
          flex-basis: 0;

          &.disabled {
            opacity: 0.5;
          }
        }
      }
      .branding__description {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px 20px 20px;
      }
      .logo {
        height: 80px;
        box-sizing: content-box;
        margin: 15px;
      }
    }

    .header__title {
      font-size: 18px;
      font-weight: bold;
      color: #404040;
    }
    .header__description {
      font-size: 14px;
      color: #808080;
    }

    .branding__promo-image {
      height: 205px;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-position: center;
      background-size: cover;
    }

    .branding__header {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-bottom: 10px;
    }
    .brandings {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px
    }
    .branding {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: calc(33% - 20px);
      margin: 10px;
      border-radius: 10px;
      box-shadow: 0px 5px 10px 0 rgba(2, 3, 2, 0.15);
    }
    .branding__description {
      flex-grow: 1;
    }
    .branding__bottom {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
    }
    .branding__description {
      padding: 20px 25px;
    }
    .branding__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        bottom: 0;
        background-color: #f2f2f2;
        transform: translateY(-50%);
        height: 80%;
        width: 1px;
      }
    }
    .branding__name {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .branding__actions {
      display: flex;
      padding: 0 20px 20px 20px;

      .btn {
        flex-grow: 1;
        flex-basis: 0;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .branding__availability {
      margin-bottom: 10px;
      font-size: 14px;

      &.open {
        color: #8bc540 !important;
      }
      &.openLater {
        color: #feb055 !important;
      }
      &.closed {
        color: #ef4244 !important;
      }

      .dash {
        opacity: 0.4;
        margin: 0 2px;
      }
    }
    .branding__about {
      font-size: 14px;
      color: #808080;
    }
    .branding__action {
      color: var(--primary-color) !important;
      font-weight: bold;
      font-size: 14px;
      margin-top: 10px;
    }
    .logo {
      height: 100px;
      width: auto;
    }

    @media screen and (max-width: 900px) {
      .brandings {
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 0;
      }
      .branding {
        width: 100%;
        margin: 10px 0;
      }
    }

    @media screen and (max-width: 420px) {
      .brandings {
        align-items: center;
        margin-left: 0;
        margin-right: 0;
      }
      .branding {
        flex-direction: column;
        width: 300px;
        margin: 10px 0;
      }
      .branding__bottom {
        flex-direction: column;
      }
      .branding__description {
        width: 100%;
      }
      .branding__logo {
        display: none;
      }
    }
  }
</style>
