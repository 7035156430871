<template>
  <div id="loyalty">

    <div class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/pencil.svg" :height="24" :width="24"></svgicon>
          <span class="title">Créer une nouvelle carte-fidélité</span>
        </div>
        <div class="modal-body">
          <label>Nom de la carte</label>
          <input type="text" placeholder="0000 0000 0000 0000">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary">Retour</button>
          <button type="button" class="btn btn-primary">Créer la carte</button>
        </div>
      </div>
    </div>

    <PageTitle>
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      {{ $t('login.login') }}
    </PageTitle>

    <div class="page-content">

      <div class="loyalty-cards">

        <div class="loyalty-card">
          <div class="container">
            <div class="left">
              <div class="name">Laurent Ipsum</div>
              <div class="price"><span class="unit">00</span>.<span class="decimal">00</span>$</div>
              <div class="card-number">Carte: <span>0000 0000 0000 0000</span></div>
            </div>
            <div class="right">
              <svgicon class="icon" src="icons/modify-pen.svg" :height="32" :width="32"></svgicon>
              <div class="dropdown disable">
                <ul>
                  <li>Modifier</li>
                  <li>Supprimer</li>
                  <li>Transactions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="loyalty-card">
          <div class="container">
            <div class="left">
              <div class="name">Laurent Ipsum</div>
              <div class="price"><span class="unit">00</span>.<span class="decimal">00</span>$</div>
              <div class="card-number">Carte: <span>0000 0000 0000 0000</span></div>
            </div>
            <div class="right">
              <svgicon class="icon" src="icons/modify-pen.svg" :height="32" :width="32"></svgicon>
              <div class="dropdown disable">
                <ul>
                  <li>Modifier</li>
                  <li>Supprimer</li>
                  <li>Transactions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="loyalty-card">
          <div class="container">
            <div class="left">
              <div class="name">Laurent Ipsum</div>
              <div class="price"><span class="unit">00</span>.<span class="decimal">00</span>$</div>
              <div class="card-number">Carte: <span>0000 0000 0000 0000</span></div>
            </div>
            <div class="right">
              <svgicon class="icon" src="icons/modify-pen.svg" :height="32" :width="32"></svgicon>
              <div class="dropdown">
                <ul>
                  <li>Modifier</li>
                  <li>Supprimer</li>
                  <li>Transactions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="loyalty-card">
          <div class="container">
            <div class="left">
              <div class="name">Laurent Ipsum</div>
              <div class="price"><span class="unit">00</span>.<span class="decimal">00</span>$</div>
              <div class="card-number">Carte: <span>0000 0000 0000 0000</span></div>
            </div>
            <div class="right">
              <svgicon class="icon" src="icons/modify-pen.svg" :height="32" :width="32"></svgicon>
              <div class="dropdown disable">
                <ul>
                  <li>Modifier</li>
                  <li>Supprimer</li>
                  <li>Transactions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="loyalty-card new-card">
          <span>Créez une nouvelle carte fidèlité</span>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
  export default {
    name: 'loyalty',
    mounted() {
      this.$store.commit("showNavigationCart", true);
    },
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 40px;
  }

  .loyalty-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 20px;
    .loyalty-card {
      background-color: white;
      padding: 20px;
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      .container {
        display: flex;
        justify-content: space-between;
        .left {
          .name {
            font-size: 20px;
            font-weight: bold;
            color: #1a1a1a;
          }
          .price {
            color: #ed2937;
            font-size: 50px;
            font-weight: bold;
            font-family: 'Segoe UI';
            display: flex;
            align-content: flex-start;
            height: 120px;
            .decimal {
              font-size: 32px;
              padding-top: 6px;
            }
          }
          .card-number {
            color: #808080;
          }
        }
        .right {
          position: relative;
          .icon {
            margin-top: -16px;
            cursor: pointer;
          }
          .dropdown {
            position: absolute;
            box-sizing: border-box;
            right: -49px;
            top: 23px;
            background-color: white;
            box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            ul {
              list-style: none;
              margin: 0;
              padding: 16px;
              li {
                padding: 4px;
                cursor: pointer;
                &:hover {
                  opacity: .5;
                }
              }
            }
          }
          .dropdown::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -10px;
            top: -10px;
            left: 50%;
            border: 12px solid white;
            border-color: transparent transparent white white;
            transform-origin: 1 1;
            transform: rotate(135deg);
            box-shadow: -2px 2px 4px -2px rgba(0, 0, 0, 0.2);
          }
          .disable {
            display: none;
          }
        }
      }
    }
    .new-card {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover span {
        color: #808080;
      }
      span {
        text-align: center;
        margin: auto;
        padding: 30px;
        font-size: 20px;
        color: #bfbfbf;
      }
    }
    .disable .name, .disable .price, .disable .card-number {
      color: #bfbfbf !important;
    }
  }

  .modal {
    .modal-content {
      width: 100%;
      max-width: 520px;
      .modal-header {
        .svgicon {
          fill: #ed2937;
        }
        .title {
          font-size: 18px;
          color: #404040;
          font-weight: bold;
          margin-left: 8px;
        }
      }
      .modal-body {
        label {
          display: block;
          font-size: 14px;
          color: #808080;
          margin-left: 12px;
          margin-bottom: 4px;
        }
        input {
          width: 100%;
        }
      }
      .modal-footer {
        button {
          width: 46%;
          box-shadow: none;
          padding: 11px;
        }
      }
    }
  }

</style>
