<template>
  <div id="itemRefundModal" class="modal" :class="{show: show}">
    <div class="modal-content" v-if="detail">

      <div class="modal-header">
        <svgicon src="icons/refund.svg" :height="24" :width="24"></svgicon>
        <div class="header__text">{{$t('refund_item.title')}}</div>
      </div>

      <div class="modal__inner">
        <PosCartItem :detail="detail" :key="'refund-item-' + detail.id" no-actions></PosCartItem>
        <p>{{ $t('refund_item.description') }}</p>
        <div class="item-refund__form">
          <div class="form--left">
            <div class="group">
              <label>{{$t('quantity')}}</label>
              <div class="fake-input" @click="changeQuantity">{{newQuantity}}</div>
            </div>
          </div>
          <div class="form--right">
            <div class="group">
              <label>{{$t('refund_item.invoice_number')}} ({{$t('optional')}})</label>
              <PosInput v-model="invoiceNumber"></PosInput>
            </div>
          </div>
        </div>
        <div class="group">
          <label>{{$t('refund_item.justification')}}</label>
          <PosInput v-model="justification" textarea></PosInput>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="confirm" :class="{disabled: !newQuantity || !justification}">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{$t('confirm')}}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
  import PosCartItem from "@/components/pos/PosCartItem";
  import PosInput from "@/components/pos/PosInput";
  import EventBus from "@/lib/eventBus";
  import { Util } from "@/util/Util";

  export default {
    name: "itemRefundModal",

    components: { PosCartItem, PosInput },

    data() {
      return {
        show: false,
        detail: null,
        justification: "",
        invoiceNumber: "",
        newQuantity: 1
      };
    },

    computed: {
      table() {
        return this.$store.state.table;
      }
    },

    mounted() {
      EventBus.$on("pos-item-refund-modal", (detail) => {
        this.detail = detail;
        this.newQuantity = detail.quantity;
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("pos-item-refund-modal");
    },

    methods: {
      back() {
        this.hide();
      },
      hide() {
        this.show = false;
        this.justification = "";
        this.invoiceNumber = "";
        this.newQuantity = 1;
      },
      async changeQuantity() {
        let response = await Util.Modal.calculator({
          title: this.$t("item.choose_quantity"),
          allowNegativeValues: false
        });
        if (response.target !== "enter") {
          return;
        }
        let quantity = response.value;
        if (quantity === 0) {
          quantity = 1;
        }
        this.newQuantity = quantity;
      },
      confirm() {
        let tableDetail = this.table.getDetail(this.detail.id);
        tableDetail.setQuantity(-(this.newQuantity));
        tableDetail.setNote(this.justification);
        this.hide();
      }
    },

  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #itemRefundModal.modal {
    z-index: 9;

    .modal-content {
      border-radius: 40px;
      padding: 40px;
      max-width: 580px;

      .modal__inner {
        margin-bottom: 30px;
      }
    }
    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-weight: 900;
        font-size: 22px;
        color: var(--theme-color);
      }
    }
    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-5);
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }

    p {
      margin-top: 30px;
      margin-bottom: 35px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  #iShopFoodApp .group {
    display: flex;
    flex-direction: column;

    label {
      color: var(--theme-color-2);
      font-size: 18px;
      margin-bottom: 4px;
    }

    textarea, input, .fake-input {
      padding: 16px;
      font-size: 20px;
      border-radius: 12px;
      color: inherit;
      border: 1px solid var(--border-color-5);
      background-color: var(--background-color-5);
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      width: 100%;
    }
  }

  .item-refund__form {
    display: flex;
    margin-bottom: 20px;
    .form--left {
      margin-right: 20px;
    }
    .form--left, .form--right {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
</style>