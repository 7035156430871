import Store from "@/store";
import Standby from "./html/StandbyTemplate.html";
import MenuItem from "./html/MenuItem.html";
import Onboarding from "./html/onBoardingTemplate.html";
import OrderCompleted from "./html/OrderComplete.html";
import MenuComplete from "./html/completeMenu.html";
import ItemPill from "./html/itemPill.html";

/**
 * @author Louis Couture
 * @copyright Payfacto Payments inc.
 * @since Summer 2022
 */
class CustomerScreenLoader {

    /**
     * @classdesc Never instanciate a CustomerScreenLoader. Instead, import the Singleton created at the bottom at the class
     */

    constructor() {
        this.html = "";
        this.templateName = "None";
        this.standby = Standby;
        this.menuItem = MenuItem;
        this.onboarding = Onboarding;
        this.orderCompleted = OrderCompleted;
        this.menuComplete = MenuComplete;
        this.itemPill = ItemPill;
    }

    /**
     * @method setTemplateName
     * @memberof CustomerScreenLoader
     * @instance
     * @param templateName {string} A string representing the name of the template . Used internally
     * @desc A function to set template name
     * @private
     */

    setTemplateName(templateName) {
        this.templateName = templateName;
    }

    /**
     * @function _hasSecondaryScreen
     * @memberof CustomerScreenLoader
     * @instance
     * @returns {boolean}  Whether or not there is a secondary screen
     * @desc Used to determinate if we are in a device supporting second screen.
     * This function is used to internally to prevent execution of secondary screen management if no such screen exists,
     * thus preventing any errors.
     * @private
     */

    _hasSecondaryScreen() {
        return navigator && navigator.presentation && typeof navigator.presentation.requestSessionWithData !== "undefined";
    }

    /**
     * @function _contentShouldReload
     * @memberof CustomerScreenLoader
     * @instance
     * @desc Used to determinate if we are in a device supporting second screen.
     * This function is used to internally to prevent execution of secondary screen management if no such screen exists,
     * thus preventing any errors.
     * @private
     */

    _contentShouldReload() {
        if (!this._hasSecondaryScreen()) {
            return;
        }
        try {
            navigator.presentation.requestSessionWithData(this.showHTML());
        } catch (e) {
            console.log("Cannot connect to secondary display", e);
        }
    }

    /**
     * @function switchToStandby
     * @memberof CustomerScreenLoader
     * @instance
     * @param imgURL {string} the url or the image displayed in the background
     * @desc This function will switch to standby template unless current template is Order Completed then wait a few seconds
     * @public
     */
    switchToStandby(imgURL) {
        if (!this._hasSecondaryScreen()) {
              return;
        }
        this.html = this.standby;
        this.html = this.html.replace("%IMGURL%", imgURL);
        if (this.templateName === "OrderCompleted") {
            setTimeout(() => { this._contentShouldReload(); }, 5000);
        } else {
            this._contentShouldReload();
        }
        this.setTemplateName("standby");
    }

    /**
     * @function switchToOnboarding
     * @memberof CustomerScreenLoader
     * @instance
     * @desc Switch to the Onboarding template.
     * This is the template that display the Maitre D logo when the POS is being initialized
     * This function is called when the customerScreenLoader is initialized.
     * @public
     */
    switchToOnboarding() {
        if (!this._hasSecondaryScreen()) {
            return;
        }
        this.html = this.onboarding;
        this._contentShouldReload();
        this.setTemplateName("Onboarding");

    }

    /**
     * @function switchToMenuItem
     * @memberof CustomerScreenLoader
     * @instance
     * @param {Item} item an Item object as defined in ItemModel.js on the model section ;
     *
     * @desc Switch to the Menu Item template. This will show a large image of the item, a description of the item
     *  and the price. Item details or extras are not supported by this template.
     *  @public
     */
    switchToMenuItem(item) {
        if (!this._hasSecondaryScreen()) {
            return;
        }
        this.html = this.menuItem;
        if (item.getImage()) {
                this.html = this.html.replace("%IMGURL%", CONFIG.urlFileServer + item.image);
        }
        this.html = this.html.replace("%ITEMTITLE%", window.translateObject(item.name));
        this.html = this.html.replace("%ITEMDESC%", window.translateObject(item.description));
        let updatedPrice = window.translateCurrency((item.getPrice("takeout") || 0).toFixed(2));
        this.html = this.html.replace("%PRICE%", updatedPrice);
        this.html = this.html.replace("%PRIMARY_COLOR%", Store.state.primaryColor.toString());
        this._contentShouldReload();
        this.setTemplateName("MenuItem");

    }

    /**
     * @function switchToOrderCompleted
     * @memberof CustomerScreenLoader
     * @instance
     * @param imgUrl {string} the background image URL of the template. It should be the same as the imageURL defined in
     * the switchToStandby function, this will create a popup effect where it would seem the thank you modal shows up
     * and disappears
     * @param thanksMessage {string} A message thanking the customer for its order. Will show up big and bold e.g : thanks
     * you for your order
     * @param detailMessage {string} A message displaying additionnal details concerning the processing of the order. Will show
     * up small. E.g : Order is being prepared, please be patient.
     *
     * @desc A function that will switch to the OrderCompleted Template. This template will show a thank you message
     * signifying the completion of the order. This function is meant to be called right before the
     * switchToStandby function. When called together, switchToStanby will wait a few seconds before showing up
     * and displaying itself
     * @public
     */

    switchToOrderCompleted(imgUrl, thanksMessage, detailMessage) {
        if (!this._hasSecondaryScreen()) {
            return;
        }
        this.html = this.orderCompleted;
        this.html = this.html.replace("%IMGURL%", imgUrl);
        this.html = this.html.replace("%DONECOLOR%", Store.state.primaryColor.toString());
        this.html = this.html.replace("%THANKSTEXT%", thanksMessage);
        this.html = this.html.replace("%ORDERDESC%", detailMessage);
        this._contentShouldReload();
        this.setTemplateName("OrderCompleted");
    }

    /**
     * @function switchToMenuComplete
     * @memberof CustomerScreenLoader
     * @instance
     * @param table {Table} the table object as defined in TableModel.js
     * @desc A central element to the POS experience, this function will show the current order that the customer has made so far.
     * This displays the items ordered along with the specific details and extras of the items, the price of each item and their images
     * The total price of the order along with the taxes is also displayed.
     * Note that if two items are added independently, they will not show up together. This is because the table model
     * does not bundle two same elements together.
     * @public
     */

    switchToMenuComplete(table, imgUrl) {
        if (!this._hasSecondaryScreen()) {
            return;
        }
        this.html = this.menuComplete;
        const tableTotal = table.getTotal();
        const totalPrice = window.translateCurrency(tableTotal.total);
        const subtotalPrice = window.translateCurrency(tableTotal.subTotal);
        const taxes = window.translateCurrency(tableTotal.taxTotal ?? 0);
        this.html = this.html.replace("%ITEMS%", this._generateItemPills(table.toDtoForCustomerDisplay()));
        this.html = this.html.replace("%TOTALPRICE%", totalPrice);
        this.html = this.html.replace("%SUBTOTALPRICE%", subtotalPrice);
        this.html = this.html.replace("%TAXES%", taxes);
        this.html = this.html.replace("%ORDER_TOTAL%", window.translate("pos_customerDisplay.orderTotal"));
        this.html = this.html.replace("%ORDER_SUBTOTAL%", window.translate("pos_customerDisplay.orderSub"));
        this.html = this.html.replace("%TAXES_TEXT%", window.translate("pos_customerDisplay.tax"));
        this.html = this.html.replace("%POSCOLOR%", Store.state.primaryColor.toString());
        this.html = this.html.replace("%IMGURL%", imgUrl);
        this._contentShouldReload();
        this.setTemplateName("MenuComplete");
    }

    /**
     * @function _generateItemPills
     * @memberof CustomerScreenLoader
     * @instance
     * @param json a json object used to represent the current order
     * @returns {string} a string representing the html used to display the item pills. An item pill display
     * an item along with its image, any extras and the price of the item
     * @desc Internal helper function used to generate all the item pills html
     * @private
     */

    _generateItemPills(json) {
        let items = "";
        json.items.forEach((item) => {
            items += this._generateItemPill(item);
        });
        return items;
    }

    /**
     * @function _generateItemPill
     * @memberof CustomerScreenLoader
     * @instance
     * @param item an element of the json object representing an indivitual item
     * @returns {string} the html template of an item pill with the specfied information of the item.
     * @desc Internal function used to display the html for one item pill. This function is used by generateItemPills to
     * generate each item pill individially
     * @private
     */

    _generateItemPill(item) {
        let newItemPill = this.itemPill;
        newItemPill = newItemPill.replace("%ITEM_IMAGE_URL%", item.imageURL);
        newItemPill = newItemPill.replace("%ITEM_TITLE%", item.name);
        newItemPill = newItemPill.replace("%ITEM_EXTRAS_NAMES%", this._generateItemExtraNames(item.extras));
        newItemPill = newItemPill.replace("%ITEM_EXTRAS_PRICES%", this._generateItemExtraPrices(item.extras));
        newItemPill = newItemPill.replace("%ITEM_QTY_NUMBER%", item.itemQuantity.toString());
        newItemPill = newItemPill.replace("%ITEM_PRICE%", item.itemPrice);
        newItemPill = newItemPill.replace("%QUANTITY%", window.translate("pos_customerDisplay.qty"));
        return newItemPill;
    }

    /**
     * @function _generateItemExtraNames
     * @memberof CustomerScreenLoader
     * @instance
     * @param extras a sub element of the json array representing the order
     * @returns {string} some html representing the name of any extras chosen for a specific item in an order.
     * @desc A low level helper function used to render the html of an item pills extra area's name
     * @private
     */

    _generateItemExtraNames(extras) {
        let names = extras.map(extra => extra.name);
        let extraNames = "";
        names.forEach(name => {
            extraNames += "<div class='item-pill-extras-name'>" + name + "</div>";
        });
        return extraNames;

    }

    /**
     * @function _generateItemExtraPrices
     * @memberof CustomerScreenLoader
     * @instance
     * @param extras a sub element of the json array representing the order
     * @returns {string} some html representing the name of any extras chosen for a specific item in an order.
     * @desc A low level helper function used to render the html of an item pills extra area's name
     * @private
     */

    _generateItemExtraPrices(extras) {
        let prices = extras.map(extra => extra.price);
        let extraPrices = "";
        prices.forEach(price => {
            extraPrices += "<div class='item-pill-extras-price'>+ " + price + "</div>";
        });
        return extraPrices;
    }

    /**
     * @function showHTML
     * @memberof CustomerScreenLoader
     * @instance
     * @desc This function returns the html of what should be a filled out template
     * @returns {string} A string representing the html of the template.
     */

    showHTML() {
        return this.html;
    }

    /**
     * @function initialize
     * @memberof CustomerScreenLoader
     * @instance
     * @desc This function will initialize the customer screen loader
     * @public
     */

    initialize() {
        let session;
        navigator.presentation.onavailablechange = (screenEvent) => {
            if (screenEvent.available) {
                session = navigator.presentation.requestSessionWithData(this.showHTML());
            }
        };
        this.switchToOnboarding();
    }

}

let customerScreenLoader = new CustomerScreenLoader();
export { customerScreenLoader as CustomerScreenLoader };