// @ts-check

/**
 * @typedef AddressDefinition
 * @property {String} fullAddress
 * @property {String} app
 * @property {String} city
 * @property {String} state
 * @property {String} country
 * @property {String} zip
 * @property {string} lat
 * @property {string} lng
 */

export default class Address {

  constructor(newAddress) {
    this.fullAddress = /** @type {string} */ (null);
    this.app = /** @type {string} */ (null);
    this.city = /** @type {string} */ (null);
    // TODO - FIX TEMPORAIRE
    this.state = /** @type {string} */ ("QC");
    this.country = /** @type {string} */ ("CA");
    this.postalCode = /** @type {string} */ (null);
    this.latitude = /** @type {number} */ (null);
    this.longitude = /** @type {number} */ (null);
    this.note = /** @type {string} */ (null);
    this.zoneName = /** @type {string} */ (null);

    if (newAddress) {
      this.default = true;
      this.new = true;
    } else {
      this.default = false;
      this.new = false;
    }
  }

  isEmpty() {
    return this.fullAddress == null;
  }

  isNew() {
    return this.new;
  }

  /**
   * @returns {{valid: boolean}|{valid: boolean, errorKey: string}}
   */
  validate() {
    if (!this.fullAddress) {
      return { valid: false, errorKey: "error.user.full_address_empty" };
    }
    if (!this.postalCode) {
      return { valid: false, errorKey: "error.user.postal_code_empty" };
    }
    if (!this.city) {
      return { valid: false, errorKey: "error.user.city_empty" };
    }
    if (!this.hasStreetNumberInFullAddress()) {
      return { valid: false, errorKey: "error.user.address_num_missing" };
    }
    if (!Address.isValidAddressFormat(this.getFormattedAddress())) {
      return { valid: false, errorKey: "payment.invalid_address" };
    }
    return { valid: true };
  }

  isGeocoded() {
    return this.latitude !== 0 && this.longitude !== 0;
  }

  getGeoLocation() {
    return {
      lat: this.latitude,
      lng: this.longitude
    };
  }

  getGeocodingInformations() {
    return this.latitude + "," + this.longitude + "/" + this.postalCode;
  }

  /**
   * @param {AddressDefinition} definition
   */
  load(definition) {
    this.fullAddress = definition.fullAddress;
    this.app = definition.app;
    this.city = definition.city;
    this.state = definition.state;
    this.country = definition.country;
    this.postalCode = definition.zip;
    this.default = definition.default;
    this.note = definition.note;
    this.new = false;
    this.latitude = parseFloat(String(definition.lat || 0));
    this.longitude = parseFloat(String(definition.lng || 0));

    return this;
  }

  /**
   * @param {any} address
   * @param {any} location
   * @param {string} fullString
   */
  loadFromGoogle(address, location, fullString) {
    if (!address || !location) {
      return;
    }
    this.latitude = location.lat() || 0;
    this.longitude = location.lng() || 0;
    let streetNumber, streetName;
    for (let item of address) {
      if (item.types.indexOf("street_number") > -1) {
        streetNumber = item.long_name;
      } else if (item.types.indexOf("route") > -1) {
        streetName = item.long_name;
      } else if (item.types.indexOf("locality") > -1) {
        this.city = item.long_name;
      } else if (item.types.indexOf("administrative_area_level_1") > -1) {
        this.state = item.short_name;
      } else if (item.types.indexOf("country") > -1) {
        this.country = item.short_name;
      } else if (item.types.indexOf("postal_code") > -1) {
        this.postalCode = item.long_name;
      }
    }
    if (fullString && !streetNumber) {
      streetNumber = Address.extractStreetNumberFromFullString(fullString);
    }
    this.fullAddress = streetNumber ? (streetNumber + " " + streetName) : " " + streetName;
  }

  /**
   *
   * @param {any} address
   * @param {any} location
   */
  loadFromHere(address, location, fullString) {
    this.latitude = location.lat || 0;
    this.longitude = location.lng || 0;
    this.city = address.city;
    this.state = address.state;
    this.country = address.country;
    this.postalCode = address.postalCode || "";
    let streetNumber = (address.houseNumber || null);
    let streetName = address.street;

    if (fullString && streetNumber === null) {
      streetNumber = Address.extractStreetNumberFromFullString(fullString);
    }

    this.fullAddress = streetNumber ? (streetNumber + " " + streetName) : " " + streetName;
  }

  toDto() {
    return {
      fullAddress: this.fullAddress,
      app: this.app,
      city: this.city,
      state: this.state || "QC",
      country: this.country || "CA",
      zip: this.postalCode,
      lat: this.latitude ? String(this.latitude) : "",
      lng: this.longitude ? String(this.longitude) : "",
      default: this.default,
      note: this.note || undefined
    };
  }

  getFormattedAddress() {
    if (!this.fullAddress) {
      return "";
    }
    let address = this.fullAddress + ", " + this.city;
    if (this.state) {
      address += ", " + this.state;
    }
    if (this.country) {
      address += ", " + this.country;
    }
    if (this.postalCode) {
      address += ", " + this.postalCode;
    }
    return address;
  }

  getFormattedAddressWithApt() {
    if (!this.fullAddress) {
      return "";
    }
    let address = this.fullAddress;
    if (this.app) {
      address += ", " + window.translate("order_options.apt") + " " + this.app;
    }
    if (this.city) {
      address += ", " + this.city;
    }
    if (this.state) {
      address += ", " + this.state;
    }
    if (this.country) {
      address += ", " + this.country;
    }
    if (this.postalCode) {
      address += ", " + this.postalCode;
    }
    return address;
  }

  static extractStreetNumberFromFullString(fullString) {
    let streetNumber = null;
    let split = fullString.trim().split(" ");
    if (/\d+(\s|-)?\w*(?:(,$)|$)?/g.test(split[0])) {
      streetNumber = split[0].replace(",","");
    }
    return streetNumber;
  }

  hasStreetNumberInFullAddress() {
    if (!this.fullAddress) {
      return false;
    }

    return !!Address.extractStreetNumberFromFullString(this.fullAddress);
  }

  static isValidAddressFormat(fullAddress) {
    fullAddress = fullAddress.trim();
    let addressToSplit = fullAddress.replace(",", "");
    let split = addressToSplit.split(" ");
    let streetNumber = split[0]; // Since version 0.9.2, street number is not required as a string

    if (streetNumber && fullAddress.includes("undefined") == false && typeof split[1] !== "undefined" && split[1] !== "") {
      return true;
    } else {
      return false;
    }
  }

}
