<template>
  <Panel :locked="locked" class="contact-informations" ref="contactPanel" :title="$t('payment.contact_information')"
         icon="icons/contact.svg" collapsible>
    <ErrorBox boxId="errorBoxContact" :visible="errorBoxContactVisible" @click="errorBoxContactVisible = false"></ErrorBox>
    <div class="form">
      <div class="left">
        <div class="input">
          <label>{{$t('payment.first_name')}}</label>
          <input type="text" v-model="user.firstName" :placeholder="$t('payment.first_name')" maxlength="50"
                  :class="{invalid: $validate.error('$user.firstName')}">
        </div>
        <div class="input">
          <label>{{$t('payment.last_name')}}</label>
          <input type="text" v-model="user.lastName" :placeholder="$t('payment.last_name')" maxlength="50"
                  :class="{invalid: $validate.error('$user.lastName')}">
        </div>
        <div class="input" v-if="company && company.isSmsActivatedForCustomers()">
          <label>{{$t('payment.notification_type')}}</label>
          <select v-model="$order.notificationType" :class="{invalid: $validate.error('$order.notificationType')}">
            <option value="email">{{$t('payment.email_notification')}}</option>
            <option value="sms">{{$t('payment.sms')}}</option>
          </select>
        </div>
        <div class="input" v-if="branchWantsMessagingConsent && !$store.getters.isMobile">
          <input id="chkMessaging" type="checkbox" v-model="$user.messagingConsent">
          <label for="chkMessaging">{{$t('payment.messaging_consent')}}</label>
        </div>
      </div>
      <div class="right">
        <div class="input">
          <label>{{$t('payment.email')}}</label>
          <input type="text" v-model="user.email" :placeholder="$t('payment.email')"
                  :class="{invalid: $validate.error('$user.email')}">
        </div>
        <div class="input">
          <label>{{$t('payment.phone')}}</label>
          <input type="text" v-model="user.phoneNumber" :placeholder="$t('payment.phone')"
                  :class="{invalid: $validate.error('$user.phoneNumber')}">
        </div>
        <div class="input" v-if="branchWantsMessagingConsent && $store.getters.isMobile">
          <input id="chkMessagingMobile" type="checkbox" v-model="$user.messagingConsent">
          <label for="chkMessagingMobile">{{$t($store.state.ticketMode ? 'payment.messaging_consent_ticket' : 'payment.messaging_consent')}}</label>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>

  import ErrorBox from "@/components/tools/ErrorBox.vue";

  export default {

    props: {
      errorBoxContactVisible: Boolean,
      locked: Boolean
    },

    components: { ErrorBox },

    validate: {
      "$user.firstName": {
        required: true,
        maxLength: 50,
        validation: function() {
          return !(/[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/.test(this.user.firstName));
        }
      },
      "$user.lastName": {
        required: true,
        maxLength: 50,
        validation: function() {
          return !(/[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/.test(this.user.lastName));
        }
      },
      "$user.email": {
        required: true,
        email: true,
        condition: function() {
          return !this.$user.isDispatchUser();
        }
      },
      "$user.phoneNumber": {
        required: true,
        phone: true,
        condition: function() {
          return this.company && !this.company.phoneNotRequired();
        }
      },
      "$order.notificationType": {
        required: true
      }
    },

    watch: {
      "$user.email"(newValue) {
        this.user.email = newValue.replace(/ /g,'');
      }
    },

    computed: {
      user() {
        return this.$user;
      },
      branchWantsMessagingConsent() {
        return this.branch && this.branch.messagingConsent;
      },
      isUserManagementEnabled() {
        if (!this.branch) {
          return false;
        }
        return this.branch.userManagementEnabled;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      }
    },

    mounted() {
      this.setUserEmail();
    },

    methods: {
      validate() {
        return this.$validate.valid();
      },
      open() {
        this.$refs.contactPanel.open();
      },
      setUserEmail() {
        if (this.isUserManagementEnabled && !this.$user.email && this.$user.manager) {
          this.$user.email = this.$user.manager.email;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>

  .xs .form {
    flex-direction: column;
    .left {
      padding-right: 0;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    .left, .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
    }
    .left {
      padding-right: 20px;
    }
  }

  .input {
    padding: 12px 0;

    label {
      display: block;
      font-size: 14px;
      color: #808080;
      padding-bottom: 8px;
      padding-left: 8px;
    }
    input, select, textarea {
      width: 100%;
      font-family: Roboto, sans-serif;
    }
    .address-info {
      padding: 4px 0;
    }
  }

  .row {
    display: flex;
    .input {
      flex-grow: 1;
      flex-basis: 0;
    }
    .input:not(:last-child) {
      margin-right: 20px;
    }
  }

</style>
