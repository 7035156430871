<template>
  <div id="brandingAboutModal" class="modal" :class="{show: show}">
    <template v-if="branding">


      <div class="modal-content">
        <div class="branding__promo-image" :style="{backgroundImage: 'url(\'' + getImage(branding.promoImage) +  '\''}"></div>
        <div class="modal__body">
          <div class="modal__info">
            <div class="branding__logo">
              <img :src="getImage(branding.logo)">
            </div>
            <div class="right">
              <div class="branding__name">{{$to(branding.name)}}</div>
              <div class="branding__availability" :class="{
                open: isBrandingAvailable(branding),
                openLater: !isBrandingAvailable(branding) && getBrandingStartHour(branding),
                closed: !isBrandingAvailable(branding) && !getBrandingStartHour(branding)
              }">
                <template v-if="isBrandingAvailable(branding)">
                  {{$t('branding.open')}}
                  <template v-if="branding.getAverageDelay($order.method)">
                    <span class="dash">|</span> {{$t('branding.delay_of')}} {{branding.getAverageDelay($order.method)}} {{$t('branding.minutes')}}
                  </template>
                </template>
                <template v-if="!isBrandingAvailable(branding)">
                  <template v-if="getBrandingStartHour(branding)">{{$t('branding.available_at')}} {{getBrandingStartHour(branding)}}</template>
                  <template v-if="!getBrandingStartHour(branding)">{{$t('branding.closed')}}</template>
                </template>
              </div>
              <div class="branding__mission" v-if="$to(branding.shortDescription)">
                <label>{{$t('branding.mission')}}</label>
                <div class="mission__content">{{$to(branding.shortDescription)}}</div>
              </div>
            </div>
          </div>
          <div class="modal__about">
            <div class="about__title">
              <svgicon src="icons/shop.svg" :height="20" :width="20"></svgicon>
              <div class="title__text">{{$t('branding.about')}}</div>
            </div>
            <div class="about__description">
              {{$to(branding.description)}}
            </div>
          </div>
          <div class="modal__hours">
            <div class="hours__title">
              <svgicon src="icons/clock.svg" :height="20" :width="20"></svgicon>
              <div class="title__text">{{$t('company.open_hours')}}</div>
            </div>
            <div class="hours__content">
              <div class="left">
                <div class="hour" v-for="day of daysLeft" :key="'hour-' + day" :class="{active: getOrderDay === day}">
                  <div class="name">{{$t('day.' + day)}}</div>
                  <div class="ranges">
                    <div class="range" v-for="range of getBrandingHoursForDay(day)">{{parseHour(range.from)}} {{$t('to')}} {{parseHour(range.to)}}</div>
                    <div class="range" v-if="getBrandingHoursForDay(day).length === 0">{{$t('branding.closed')}}</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="hour" v-for="day of daysRight" :key="'hour-' + day" :class="{active: getOrderDay === day}">
                  <div class="name">{{$t('day.' + day)}}</div>
                  <div class="ranges">
                    <div class="range" v-for="range of getBrandingHoursForDay(day)">{{parseHour(range.from)}} {{$t('to')}} {{parseHour(range.to)}}</div>
                    <div class="range" v-if="getBrandingHoursForDay(day).length === 0">{{$t('branding.closed')}}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close" @contextmenu="contextMenuClose">{{ $t('back') }}</button>
          <button class="btn btn-primary" @click="setBranding(branding)" @contextmenu="contextMenuSetBranding(branding)"
                  :class="{disabled: !isBrandingAvailable(branding)}">
            {{$t('branding.order')}}
          </button>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus.js";
  import moment from "moment-timezone";

  export default {
    name: "brandingAboutModal",

    props: {
      branding: Object,
      show: Boolean
    },

    data() {
      return {
        daysLeft: ["monday","tuesday","wednesday","thursday"],
        daysRight: ["friday", "saturday", "sunday"]
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      getOrderDay() {
        let day = (this.$order.date || moment()).day();
        let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        return days[day];
      }
    },

    methods: {
      getImage(image) {
        return image ? this.$store.state.urlFileServer + image.url : null;
      },
      contextMenuClose() {
        if (this.$kiosk) {
          this.close();
        }
      },
      getBrandingHoursForDay(day) {
        if (!this.branding || !this.branding.config || !this.branding.config[this.$order.method]) {
          return [];
        }
        let methodConfig = this.branding.config[this.$order.method] || {};
        return methodConfig.hours[day].hours;
      },
      parseHour(hour) {
        if (!this.company) {
          return;
        }
        let hourParts = hour.split(":");
        if (parseInt(hourParts[0]) > 24) {
          hour = "23:59";
        }
        return this.company.formatHour(hour);
      },
      isBrandingAvailable(branding) {
        return branding.isAvailable(this.$order.date, this.$order.hour, this.$order.method);
      },
      getBrandingStartHour(branding) {
        return branding.getStartHourForDay(this.$order.date, this.$order.hour, this.$order.method);
      },
      setBranding(branding) {
        this.$emit("changed", branding);
      },
      contextMenuSetBranding(branding) {
        if (this.$kiosk) {
          this.setBranding(branding);
        }
      },
      close() {
        this.$emit("close");
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #brandingAboutModal {

    .modal-content {
      max-width: 650px;
      padding: 0;
    }

    .branding__promo-image {
      height: 200px;
      background-size: cover;
      background-position: center;
      border-radius: 10px 10px 0 0;
    }

    .modal__info, .modal__about, .modal__hours {
      padding-bottom: 25px;
      border-bottom: 1px dashed #e6e6e6;
    }

    .hours__title, .about__title {
      display: flex;
      align-items: center;
      margin-top: 25px;
      font-weight: bold;

      .svgicon {
        fill: #bfbfbf;
        margin-right: 15px;
      }
    }

    .about__description, .hours__content {
      margin-left: 35px;
      margin-top: 10px;
    }

    .hours__content {
      display: flex;

      .left, .right {
        flex-basis: 0;
        flex-grow: 1;
      }
      .left {
        margin-right: 30px;
      }

      .hour {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &.active {
          font-weight: bold;
        }
      }
    }

    .modal__info {
      display: flex;
    }

    .modal-footer {
      padding: 20px;
    }

    .branding__logo {
      height: 120px;
      width: 180px;
      background-color: #fafafa;
      border-radius: 10px;
      margin-right: 40px;
      flex-shrink: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width:100%;
        max-height:100%;
      }
    }

    .branding__mission {
      line-height: 1.2;
      font-size: 16px;
      label {
        font-size: 14px;
        opacity: 0.6;
      }
    }

    .modal__body {
      padding: 20px;
      overflow-y: auto;
      max-height: calc(100vh - 300px);
    }

    .branding__name {
      font-size: 24px;
      font-weight: bold;
    }

    .branding__availability {
      margin-bottom: 10px;
      font-size: 14px;

      &.open {
        color: #8bc540 !important;
      }
      &.openLater {
        color: #feb055 !important;
      }
      &.closed {
        color: #ef4244 !important;
      }

      .dash {
        opacity: 0.4;
        margin: 0 2px;
      }
    }

    @media screen and (max-width: 720px) {
      .hours__content {
        flex-direction: column;
        margin-top: 20px;

        .left {
          margin-right: 0;
        }
      }

      .modal__info {
        flex-direction: column;

        .branding__logo {
          margin-right: 0;
          padding: 5px;
          width: 100%;
          margin-bottom: 20px;
          height: 90px;
        }
      }

      .about__description, .hours__content {
        font-size: 16px;
      }

      .modal__hours {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      .modal-footer {
        margin-top: 0;
        border-top: 1px solid #e6e6e6;
      }
    }
  }

  #iShopFoodApp.kiosk #brandingAboutModal {
    .modal-content {
      border-radius: 20px;
      max-width: 700px;
    }
    .branding__promo-image {
      border-radius: 20px 20px 0 0;
    }
    .modal__body {
      padding: 40px;
    }
    .modal-footer {
      padding: 0 40px 40px 40px;
    }
    .modal-footer .btn {
      height: 70px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
</style>
