<template>
  <div id="orderCompany">

    <PageTitle>
      <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
      <template>{{$t(!onlyOneOrderMethod ? 'payment.online_order' : onlineOrderingTitle)}}</template>
    </PageTitle>

    <FloatingLocaleSwitcher></FloatingLocaleSwitcher>

    <div class="hub">

      <div class="left"></div>

      <div class="right">
        <div class="page-content">
          <CompleteStepBar icon="icons/livraison.svg" :text="$t('method.' + $order.method + '_long')"
                      @click="backToType" v-if="!$store.getters.isMobile"
                      :disabled="branch && branch.getOrderMethodsAvailable().length <= 1"></CompleteStepBar>

          <CompleteStepBar icon="icons/location.svg" :text="$user.getDefaultAddress().getFormattedAddress()"
                          v-if="branch && $order.method === 'delivery' && !branch.deliveryOnlyViaThirdParty($order.method) &&
                            !$store.getters.isMobile && $user.getDefaultAddress().getFormattedAddress().length > 0"
                          @click="backToLocation"></CompleteStepBar>

          <div class="order-card">

            <div class="card-header" v-if="!$store.getters.isMobile">
              <svgicon src="icons/branches.svg" :height="30" :width="42"></svgicon>
              <div class="card-big-title">{{$t("order.choose_company")}}</div>
            </div>

            <div class="card-header mobile" v-if="$store.getters.isMobile && branch && !branch.enableMapOnCompanySelection()">
              <img id="logo" v-if="logo" :src="$store.state.urlFileServer + logo">
              <div class="filler" v-if="!logo"></div>
              <div class="header-text">
                <div class="card-big-title">{{$t("order.choose_company")}}</div>
                <div class="text" v-if="$order.method != 'delivery' && $order.method != 'takeout'">{{$t("order.company_text")}}</div>
                <div class="text" v-if="$order.method == 'takeout'">{{$t("order.company_text_takeout")}}</div>
                <div class="text" v-if="$order.method == 'delivery'">
                  <template v-if="branch.deliveryOnlyViaThirdParty($order.method)">{{$t("order.company_text_third_party_delivery")}}</template>
                  <template v-else>{{$t("order.company_text_delivery")}}</template>
                </div>
              </div>
            </div>

            <div class="card-content">
              <div class="text" v-if="!$store.getters.isMobile && $order.method != 'delivery' && $order.method != 'takeout'">{{$t("order.company_text")}}</div>
              <div class="text" v-if="!$store.getters.isMobile && $order.method == 'takeout'">{{$t("order.company_text_takeout")}}</div>
              <div class="text" v-if="!$store.getters.isMobile && $order.method == 'delivery'">
                <template v-if="branch && branch.deliveryOnlyViaThirdParty($order.method)">{{$t("order.company_text_third_party_delivery")}}</template>
                <template v-else>{{$t("order.company_text_delivery")}}</template>
              </div>
              <div class="split">
                <div class="search" v-if="branch && branch.hasCompanySearchActivated()">
                  <input type="text" v-model="filter" :placeholder="$t('order.search_filter')" @keyup.enter="filterCompanies">
                  <div class="icon" @click="filterCompanies">
                    <svgicon src="icons/search.svg" :height="18" :width="18"></svgicon>
                  </div>
                </div>
                <div class="map-container" v-if="branch && branch.enableMapOnCompanySelection()">
                  <div id="mapView"></div>
                </div>
                <div class="list-container">
                  <div class="branches">
                    <div class="branch none" v-if="companies.length == 0">
                      {{$t("order.no_company")}}
                    </div>
                    <div class="branch" :key="'company-' + companyIndex" :data-id="company.id"
                      :class="{selected: company == currentCompany}" v-for="(company, companyIndex) of companies">
                      <div class="frame" @click="selectCompany(company)">
                        <div class="icon">
                          <svgicon src="icons/branches.svg" :width="30" :height="30"></svgicon>
                        </div>
                        <div class="right">
                          <div class="branch-name">{{ company.name || "..." }}</div>
                          <div class="business-hours" v-if="company.address">
                            {{ company.address.address }}, {{ company.address.city }}</div>
                          <div class="business-hours" v-if="!company.address">...</div>
                          <div class="distance" v-if="company.distanceFromUser && company.distanceFromUser > 0">{{company.distanceFromUser.toFixed(2)}} km</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <EnterDisplayMode v-if=" branch && branch.getOrderMethodsAvailable().length === 1"></EnterDisplayMode>

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <!-- <Steps></Steps> -->

    <div class="buttons" v-if="$store.getters.isMobile && branch && (branch.getOrderMethodsAvailable().length > 1)">
      <button type="button" class="btn btn-secondary" @click="$order.method === 'delivery' ? backToLocation() : backToType()">{{$t('back')}}</button>
    </div>

  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import EventBus from "../../lib/eventBus";
  import GeocodingHelper from "../../lib/geocodingHelper";
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher.vue";
  import CompleteStepBar from "@/components/order/CompleteStepBar.vue";
  import EnterDisplayMode from "@/components/EnterDisplayMode.vue";

  export default {
    name: "orderCompany",

    components: { CompleteStepBar, FloatingLocaleSwitcher, EnterDisplayMode },

    data() {
      return {
        map: null,
        markers: {},
        userLocationMarker: null,
        companies: [],
        filter: null,
      };
    },

    async mounted() {
      this.$store.commit("showNavigationCart", false);
      let interval = setInterval(async () => {
        if (!this.branch || !this.$order.method || this.branch.companies.length === 0) {
          return;
        }
        clearInterval(interval);
        showSpinner();
        this.checkIfDisplayMode();
        this.checkIfHasOrderType();
        this.checkIfIsQuickMode();
        await this.getCompanies();
        this.checkIfShouldSelectCompanyAutomatically();
        this.branch.setDistanceFromUserForCompanies();
        await this.initializeMap();
        hideSpinner();
      }, 100);
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      firstCompany() {
        if (!this.branch) {
          return null;
        }
        return this.branch.companies[0];
      },
      companyCount() {
        return this.branch ? this.companies.length : -1;
      },
      currentCompany() {
        return this.$store.state.currentCompany;
      },
      order() {
        return this.$order;
      },
      logo() {
        if (!this.branch || !this.branch.navigationLogo) {
          return null;
        }
        return this.branch.navigationLogo.url;
      },
      onlyOneOrderMethod() {
        return this.branch && this.branch.getOrderMethodsAvailable().length === 1;
      },
      onlineOrderingTitle() {
        if (this.$order.method === "delivery") {
          return "method.delivery_long";
        } else if (this.$order.method === "takeout") {
          return "method.takeout_long";
        }
        return "order.welcome"
      },
      mapMarkerIcon() {
        if (!this.branch || !this.branch.mapMarkerImage) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.mapMarkerImage.url;
      },
      branchLogo() {
        if (!this.branch || !this.branch.navigationLogo) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.navigationLogo.url;
      }
    },

    watch: {
      "firstCompany.nameCanonical"(newValue) {
        if (!newValue) {
          return;
        }
        if (this.companyCount == 1) {
          this.selectCompany(this.firstCompany);
        } else {
          if (this.branch && this.branch.enableMapOnCompanySelection()) {
            switch (this.branch.getMapsService()) {
              case "google":
                this.createGoogleMarkers();
                break;
              default:
              case "here":
                this.createHereMarkers();
                break;
            }
          }
        }
      },
      branch(newBranch) {
        if (!this.$order.method && newBranch && newBranch.getOrderMethodsAvailable().length > 1) {
          this.$router.push(this.$router.generate("/orderType"));
        }
      },
      currentCompany(newValue) {
        let div = document.getElementsByClassName("list-container")[0].querySelector(".branch[data-id='" + newValue.id + "']");
        if (!this.$store.state.widget && div) {
          div.scrollIntoView({ behavior: "smooth" });
        }
      },
      "$store.state.userCurrentPosition"() {
        if (this.branch) {
          this.branch.setDistanceFromUserForCompanies();
        }
      }
    },

    methods: {
      ...mapActions({
        setCurrentCompanyAction: "setCurrentCompany"
      }),

      async initializeMap() {
        if (this.branch && this.branch.enableMapOnCompanySelection()) {
          switch (this.branch.getMapsService()) {
            case "google":
              await this.initializeGoogleMap();
              break;
            default:
            case "here":
              await this.initializeHereMap();
              break;
          }
        }
      },

      initializeHereMap() {
        if (typeof H === "undefined" || !H.service) {
          return;
        }
        // Connexion à Here API
        let platform;
        try {
          platform = new H.service.Platform({
            apiKey: CONFIG.hereApiKey,
            useHTTPS: true
          });
        } catch (e) {
          console.log('error init here api', e);
        }
        if (!platform) {
          return;
        }
        // Création de la carte
        let defaultLayers = platform.createDefaultLayers();
        this.map = new H.Map(
          document.getElementById("mapView"),
          defaultLayers.vector.normal.map, {
            center: { lat: 46.890910, lng: -71.323888 },
            zoom: 9
          }
        );
        // Map style
        let mapTileService = platform.getMapTileService({type: "base"});
        let tileLayer = mapTileService.createTileLayer("maptile", "reduced.day", 512, "png8", {});
        this.map.setBaseLayer(tileLayer);
        // Resize event
        window.addEventListener("resize", () => {
          setTimeout(() => {
            if (this.map) {
              this.map.getViewPort().resize();
            }
          }, 100);
        });
        this.createHereMarkers();
        let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        let ui = H.ui.UI.createDefault(this.map, defaultLayers);
      },

      centerMapToPosition(position) {
        //Functions are identical for both Google and Here
        this.map.setCenter(position);
        this.map.setZoom(13);
      },

      async initializeGoogleMap() {
        let google = await getGoogle();
        this.map = new google.maps.Map(document.getElementById("mapView"), {
          center: { lat: 46.805339, lng: -71.326173 },
          zoom: 8,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        });
        this.createGoogleMarkers();
      },

      async filterCompanies() {
        if (!this.filter) {
          return;
        }
        let result = await GeocodingHelper.geocodeWithHere(this.filter);
        if (result && result.position) {
          this.centerMapToPosition(result.position);
          this.$store.state.userCurrentPosition = result.position;
          this.branch.setDistanceFromUserForCompanies(result.position);
          this.createUserMarker();
        }
      },

      async getCompanies() {
        if (!this.branch || !this.$order.method) {
          return [];
        }
        let companies = this.branch.getCompaniesForMethod(this.$order.method, (this.$route.query.all ? null : this.$user.getDefaultAddress()));
        companies = await this.$order.filterCompaniesForDoorDashDelivery(companies);
        this.companies = companies;
      },

      checkIfDisplayMode() {
        setTimeout(() => {
          if (this.$store.state.displayMode) {
            this.$router.replace(this.$router.generate("/menu"));
          }
        }, 0);
      },

      checkIfIsQuickMode() {
        setTimeout(() => {
          if (this.$store.state.quickMode) {
            this.$router.replace(this.$router.generate("/menu"));
          }
        }, 0);
      },

      checkIfHasOrderType() {
        if (!this.$order.method) {
          this.$router.push(this.$router.generate("/orderType"));
        }
      },

      checkIfShouldSelectCompanyAutomatically() {
        let interval = setInterval(() => {
          if (!this.branch || this.branch.companies.length == 0) {
            return;
          }
          if (this.$user && this.$user.favoriteCompany) {
            this.selectCompany(this.branch.getCompanyByCanonical(this.$user.favoriteCompany));
          } else if (this.companyCount == 1) {
            this.selectCompany(this.companies[0]);
          }
          clearInterval(interval);
        }, 100);
      },

      async selectCompany(company) {
        console.log("selecting company", company);
        if (!company.hasOrderMethodActivated(this.$order.method) && company.hasThirdPartyDelivery()) {
          EventBus.$emit("show-third-party-delivery-modal", company);
          return;
        }
        await this.$store.dispatch("getStatsPerIntervalForCompany", { branchId: this.branch.id, company: company, method: this.$order.method });
        this.setCurrentCompanyAction(company);
        this.$router.push(this.$router.generate("/menu"));
      },

      backToType() {
        this.$order.setMethod(null);
        this.$router.push(this.$router.generate("/orderType"));
      },

      backToLocation() {
        this.$router.push(this.$router.generate("/orderLocation"));
      },

      getCompanyImage(company) {
        if (company && company.image) {
          return this.$store.state.urlFileServer + company.image;
        } else {
          return "img/default.jpg";
        }
      },

      // TODO could this be hidden in another file and just call createMarker, initMap, etc?
      async createHereMarkers() {
        if (this.companies.length == 0 || !this.map || Object.keys(this.markers).length > 0) {
          return;
        }
        for (let company of this.companies) {
          if (!company.address || !company.address.latitude || !company.address.longitude) {
            continue;
          }
          let coordinates = {
            lat: company.address.latitude,
            lng: company.address.longitude
          };
          let icon = this.mapMarkerIcon ? new H.map.Icon(this.mapMarkerIcon) : null;
          let marker = new H.map.Marker(coordinates, { icon: icon });
          marker.addEventListener("tap", () => {
            this.selectCompany(company);
          });
          this.markers[company.id] = marker;
          this.map.addObject(marker);
        }

        this.createUserMarker();
      },

      createUserMarker() {
        if (!this.$store.state.userCurrentPosition || !this.map) {
          return;
        }
        let position = this.$store.state.userCurrentPosition;
        let coordinates = {
          lat: position.latitude || position.lat,
          lng: position.longitude || position.lng
        };
        let marker;
        switch (this.branch.getMapsService()) {
          case "google":
            if (this.userLocationMarker) {
              this.userLocationMarker.setMap(null);
            }
            marker = new google.maps.Marker({
              position: coordinates,
              icon: 'https://ishopfood.s3.us-west-2.amazonaws.com/marker-grey.png',
              map: this.map
            });
            this.userLocationMarker = marker;
            break;
          default:
          case "here":
            if (this.userLocationMarker) {
              this.map.removeObject(this.userLocationMarker);
            }
            let icon = new H.map.Icon('https://ishopfood.s3.us-west-2.amazonaws.com/marker-grey.png');
            marker = new H.map.Marker(coordinates, { icon: icon });
            this.userLocationMarker = marker;
            this.map.addObject(marker);
            break;
        }
      },

      createGoogleMarkers() {
        if (this.companies.length == 0 || !this.map || Object.keys(this.markers).length > 0) {
          return;
        }
        let interval = setInterval(() => {
          let bounds = new google.maps.LatLngBounds();
          for (let company of this.companies) {
            if (!company.address || !company.address.latitude || !company.address.longitude) {
              return;
            }
          }
          for (let company of this.companies) {
            let marker = new google.maps.Marker({
              position: {
                lat: company.address.latitude,
                lng: company.address.longitude
              },
              icon: this.mapMarkerIcon ? this.mapMarkerIcon : undefined,
              map: this.map,
              title: "Title"
            });
            marker.addListener("click", () => {
              this.selectCompany(company);
            });
            this.markers[company.id] = marker;
            bounds.extend(marker.position);
          }
          this.map.fitBounds(bounds);

          this.createUserMarker();

          clearInterval(interval);
        }, 500);
      }

    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    height: 100%;
  }

  .buttons {
    display: flex;
    flex-shrink: 0;

    .btn {
      flex-grow: 1;
      flex-basis: 0;
      padding: 16px;
    }
  }

  .split {
    flex-grow: 1;
  }

  .branches {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(301px, 1fr));
    grid-gap: 20px;
    max-height: 380px;
    overflow-y: auto;
    .branch {
      display: flex;
      align-items: center;
      &.none {
        justify-content: center;
      }
      .frame {
        display: flex;
        flex-grow: 1;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        overflow: hidden;
        cursor: pointer;
        height: 100%;
        .img-resto {
          max-width: 120px;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
        .right {
          padding: 9px 10px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .branch-name {
            font-size: 20px;
            font-weight: bold;
            color: #1a1a1a;
          }
          .business-hours {
            color: #bfbfbf;
            .status, .hours, .distance {
              color: #808080;
            }
            .hours, .distance {
              padding: 0 4px;
            }
          }
        }
      }
      .icon {
        width: 60px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
      }
      .svgicon {
        fill: #fc6650;
      }
      &.selected {
        .frame {
          border-color: #ed2937;
        }
        .icon {
          background-color: #ed2937;
          .svgicon {
            fill: white;
          }
        }
      }
    }
  }

  .order-step-complete-bar, .order-card {
    width: 100%;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }

  .card-content {
    .text {
      padding-bottom: 20px;
      text-align: center;
      width: 100%;
    }
  }

  #mapView {
    height: 100%;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
  }

  #iShopFoodApp .pageTitle {
    display: none;
    .logo {
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .map-container {
    height: 220px;
    width: 100%;
    flex-shrink: 0;
  }

  .list-container {
    margin-top: 20px;
  }

  #iShopFoodApp {
    .search {
      padding: 12px;
      position: relative;
      input {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding-top: 4px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        width: 100%;
      }
      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px 12px 15px 12px;
      }
    }
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
      //display: flex;
      //flex-direction: column;
      &:after {
        height: 0px;
      }
    }
    .hub>.right {
      display: flex;
      flex-direction: column;
    }
    .card-content {
      flex-grow: 1;
      display: flex;
    }
    .order-card {
      padding: 0;
      margin: 0;
      height: 100%;
    }
    .card-content {
      height: 100%;
      overflow-y: auto;
    }
    .list-container {
      flex-grow: 1;
      overflow-y: auto;
      border-radius: 0;
      margin: 0;
    }
    .branches {
      grid-gap: 0;
      max-height: none;
      flex-grow: 1;
      .frame {
        border-radius: 0;
      }
    }
    .split {
      flex-grow: 1;
    }
    .branches .branch .frame .right {
      padding: 14px;

      .branch-name {
        font-size: 16px;
      }
      .business-hours {
        font-size: 14px;
      }
    }
    .order-card {
      border-radius: 0;
    }
    #mapView {
      border: none;
      border-radius: 0;
      height: 100%;
    }
    .pageTitle {
      display: inherit;
    }
    .split {
      display: flex;
      flex-direction: column;
    }
  }

  #iShopFoodApp .card-header.mobile {
    flex-shrink: 0;
    #logo {
      margin: 20px;
      width: auto;
      max-height: 150px;
    }
    .filler {
      margin-top: 30px;
    }
    .header-text {
      padding: 0 0 30px 0;
      margin-bottom: 35px;
      position: relative;
      .card-big-title {
        font-size: 18px;
      }
      .text {
        font-size: 14px;
        font-style: italic;
        padding: 10px 10px 0 10px;
      }
      &::after {
         content: "";
         position: absolute;
         top: 100%;
         left: 50%;
         transform:translateX(-50%);
         width: 50px;
         height: 3px;
         background-color: var(--primary-color);
      }
    }

  }

  @media screen and (max-width: 1600px) {
    .img-resto {
      display: none;
    }
  }

  .view-menu {
    margin-top: 20px;
  }

  @media screen and (max-width: 600px) {
    .view-menu {
      margin-bottom: 20px;
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp #orderCompany .toggleMenu {
    user-select: none;
    visibility: hidden;
    cursor: initial;
  }
</style>
