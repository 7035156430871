<template>
  <div id="posDashboard">

    <div class="dashboard__left">

      <!-- Logged in -->
      <template v-if="loggedIn">

        <div class="employee__information">
          <div class="employee-icon">
            <svgicon src="icons/user_circle.svg" :height="24" :width="24"></svgicon>
          </div>
          <div class="employee-info">
            <div class="info__name">{{ $t('pos_dashboard.name') }}</div>
            <div class="info__value">{{ employee.getFullName() }}</div>
          </div>
          <div class="info-buttons">
            <button class="btn btn-secondary" v-wave @click="logOut" :class="{ disabled: isOffline }">
              <svgicon src="icons/logout.svg" :height="20" :width="25"></svgicon>
              {{ $t('navigation.logout') }}
            </button>
          </div>
        </div>

        <div class="columns" :class="{ reversed: isLeftHandedMode }">

          <!-- User information and table keypad -->
          <div class="column">
            <div class="table__keypad">
              <div class="keypad__top">
                <div class="top__icon">
                  <svgicon src="icons/pos_table.svg" :height="69" :width="69"></svgicon>
                </div>
                <div class="top__info">
                  <div class="info__title">{{ $t("pos_table.title") }}</div>
                  <div class="info__description">{{ $t("pos_table.description") }}</div>
                </div>
              </div>
              <div class="keypad__middle">
                <label>{{ $t("payment.table_number") }}</label>
                <input type="text" v-model="tableNumber">
              </div>
              <div class="keypad__bottom">
                <NumPadPanel :value="tableNumber" @update:value="tableNumber = $event" @onEnter="enterTable"/>
              </div>
            </div>
          </div>

          <!-- User actions -->
          <div class="column">
            <div class="employee__actions">
              <div v-wave class="actions__action" @click="newOrder('takeout')" @contextmenu="newOrder('takeout')"
                   :class="{ disabled: isOffline }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/takeout-order.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.takeout_order') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.takeout_order_description') }}</div>
                </div>
              </div>
              <!--<div v-wave class="actions__action" @click="newOrder('inPlace')" @contextmenu="newOrder('inPlace')"
                   :class="{ disabled: isOffline }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/new-order.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.counter_order') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.counter_order_description') }}</div>
                </div>
              </div>-->
              <div v-wave class="actions__action" @click="newOrder('delivery')" @contextmenu="newOrder('delivery')"
                   :class="{ disabled: isOffline || !permissionManager.hasPermission(constant.P_DISPATCH) }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/phone-order.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.phone_order') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.phone_order_description') }}</div>
                </div>
              </div>
              <div v-wave class="actions__action" @click="goToPastOrders" @contextmenu="goToPastOrders"
                :class="{ disabled: isOffline }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/orders.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.orders') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.orders_description') }}</div>
                </div>
              </div>
              <div v-wave class="actions__action" @click="goToReadings" @contextmenu="goToReadings"
                :class="{ disabled: isOffline }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/stats.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.reading') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.reading_description') }}</div>
                </div>
              </div>
              <div v-wave class="actions__action" @click="goToPunch" @contextmenu="goToPunch"
                   :class="{ disabled: isOffline }">
                <div class="action__icon">
                  <svgicon class="icon" src="icons/punch-clock-page.svg" :height="69" :width="69" />
                </div>
                <div class="action__content">
                  <div class="content__title">{{ $t('pos_dashboard.punch_action') }}</div>
                  <div class="content__description">{{ $t('pos_dashboard.punch_action_description') }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>

      <!-- Not logged in -->
      <template v-if="!loggedIn">
        <NumPadPanel class="panel__employee" :title="$t('pos_dashboard.employee')" icon="icons/user.svg"
          :value="this.employeeNumber" @update:value="employeeNumber = $event" @onEnter="logIn">
          <div class="num-pad-left-content">
            <Illustration src="icons/user.svg"></Illustration>
            <div class="employee__description">{{ $t('pos_dashboard.employee_description') }}</div>
            <div class="employee__input">
              <label class="input__label">{{ $t('pos_dashboard.employee_number') }}</label>
              <input type="text" :value="employeeNumber" readonly>
            </div>
          </div>
        </NumPadPanel>
      </template>

    </div>

    <div class="dashboard__right">
      <div class="past-orders" :class="{ empty: externalPosSaleQueue.length === 0 }">
        <div class="past-orders__empty" v-if="externalPosSaleQueue.length === 0">
          <Illustration src="icons/dots-circle.svg" large></Illustration>
          <div class="empty__text">{{ $t('pos_dashboard.orders_empty') }}</div>
        </div>
        <div class="past-order" v-for="(order, orderKey) of externalPosSaleQueue" :key="'order-' + orderKey">
          <div class="order__icon">
            <svgicon :src="getIconForOrder(order)" :height="60" :width="60"></svgicon>
          </div>
          <div class="order__information">
            <div class="info__header">
              <div class="info__id">#{{ order.kioskNumber || order.id }}</div>
              <div class="info__total">{{ $tc(order.total) }}</div>
            </div>
            <div class="info__inner">
              <div class="info__method">{{ $t(`origin.${order.appName}`) }} - {{ $t(`pos_method.${order.deliveryMethod}`)
              }}
              </div>
              <div class="info__items">{{ order.details.length }} {{ $t('items') }}</div>
            </div>
            <div class="info__footer">
              <div class="info__origin">{{ $t(`pos.printing.${order.print.status}`) }}</div>
              <div class="info__date">{{ getDateFormatted(order.completedAt) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { Constant } from "@/util/Constant";
  import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
  import EventBus from "@/lib/eventBus";
  import Illustration from "@/components/modal/Illustration";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import { NetworkMonitor } from "@/networkMonitor";
  import NumPadPanel from "@/components/pos/NumPadPanel";
  import { PermissionManager } from "@/util/PermissionManager";
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    name: "posDashboard",

    components: { Illustration, NumPadPanel },

    mounted() {
      if (this.table && this.table.isClosed()) {
        this.$store.state.table = null;
      }

      if (this.$route.query["first-launch"]) {
        this.$router.replace(this.$router.generate("/pos/dashboard"));
        EventBus.$emit("show-pos-intro-modal");
      }
      this.startPrintQueueInterval();
      NetworkMonitor.checkNetwork();
      this.switchSecondScreenToStandby();
      if (!this.company) {
        setTimeout(() => {
          this.switchSecondScreenToStandby();
        }, 10 * 1000); // 10 seconds
      }
    },

    beforeDestroy() {
      clearInterval(this.timeInterval);
      this.clearPrintQueueInterval();
    },

    data() {
      return {
        employeeNumber: "",
        employeePassword: "",
        momentFormat: "YYYY-MM-DD HH:mm:ss",
        keys: [["7", "8", "9"], ["4", "5", "6"], ["1", "2", "3"]],
        printing: false,
        printQueueInterval: null,
        printQueueDelay: 1000, // ms
        constant: Constant,
        tableNumber: ""
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      employees() {
        return this.$store.state.employees;
      },
      externalPosSaleQueue() {
        return this.$store.state.externalPosSaleQueue || [];
      },
      table() {
        return this.$store.state.table;
      },
      employee() {
        return this.$store.state.employee;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      loggedIn() {
        return this.employee && this.employee.id;
      },
      isOffline() {
        return this.$store.state.offline;
      },
      permissionManager() {
        return PermissionManager;
      },
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      }
    },

    methods: {
      ...mapActions(["initializeNewTable"]),

      goToSettings() {
        this.$router.push(this.$router.generate("/pos/configuration"));
      },
      goToPastOrders() {
        this.$router.push(this.$router.generate("/pos/order-overview"));
      },
      goToReadings() {
        this.$router.push(this.$router.generate("/pos/reading"));
      },
      goToPunch() {
        this.$router.push(this.$router.generate("/pos/punch"));
      },
      goToSupport() {
        window.openExternalWebsite(this.$to(CONFIG.urlSupport));
      },
      getIconForOrder(order) {
        switch (order.appName) { //TBD this is mostly hardcoded
          case Constant.WEB_APP_NAME:
            return "icons/online-order.svg";
          case Constant.KIOSK_APP_NAME:
            return "icons/kiosk.svg";
          case Constant.UBER_APP_NAME:
            return "icons/uber-eats.svg";
        }
      },
      addKey(key) {
        this.employeeNumber += key;
      },
      getDateFormatted(date) {
        return moment(date, this.momentFormat).format("YYYY-MM-DD - h:mm A");
      },
      eraseOne() {
        if (this.employeeNumber === "") {
          return;
        }
        this.employeeNumber = this.employeeNumber.slice(0, -1);
      },
      eraseAll() {
        this.employeeNumber = "";
      },
      startPrintQueueInterval() {
        this.clearPrintQueueInterval();
        this.printNextQueuedSale();
        this.printQueueInterval = setInterval(() => {
          this.printNextQueuedSale();
        }, this.printQueueDelay);
      },
      async printNextQueuedSale() {
        //TODO could this be moved somewhere else?
        let length = this.externalPosSaleQueue.length;
        if (length > 0 && this.company && !this.printing) {
          for (let i = length - 1; i >= 0; i--) {
            let shouldBreak = false;
            const statusToPrint = ["pending", "error"];
            if (statusToPrint.indexOf(this.externalPosSaleQueue[i].print.status) > -1) {
              this.printing = true;
              shouldBreak = true;
              let saleToPrint = this.externalPosSaleQueue[i];
              try {
                await IshopPrinter.load({
                  sale: saleToPrint,
                  company: this.company,
                  configuration: this.posConfiguration,
                  isSuccess: true
                }).printSale();
                await IshopPrinter.load({
                  sale: saleToPrint,
                  company: this.company,
                  configuration: this.posConfiguration
                }).printDetailsToKitchen();
                this.externalPosSaleQueue[i].print.count++;
                this.externalPosSaleQueue[i].print.status = "success";
              } catch (e) {
                this.externalPosSaleQueue[i].print.status = "error";
              }
            }
            let printedSales = this.externalPosSaleQueue.filter(s => s.print.status === "success");
            let limit = parseInt(this.posConfiguration.printedSaleQueueLimit);
            if (printedSales.length > limit) {
              let offsetPositionToRemove = this.externalPosSaleQueue.indexOf(printedSales[0]) + limit;
              this.externalPosSaleQueue.splice(offsetPositionToRemove);
            }
            if (shouldBreak) {
              this.printing = false;
              break;
            }
            //TODO api call pour persister le print status
          }
          this.printing = false;
        }
      },
      clearPrintQueueInterval() {
        clearInterval(this.printQueueInterval);
        this.printQueueInterval = null;
      },
      async newOrder(method, tableNumber) {
        showSpinner();
        let response = await this.initializeNewTable({ method: method, tableNumber: tableNumber });
        hideSpinner();
        if (!response.success) {
          this.tableNumber = "";
          this.showError(response.error);
          return;
        }
        if (method === "delivery") {
          this.table.isDispatch = true;
          this.$user.setAsDispatchForPOS();
          this.$router.push(this.$router.generate("/pos/dispatch"));
        } else {
          this.table.isDispatch = false;
          this.$user.clearDispatchForPOS();
          this.$router.push(this.$router.generate("/menu"));
        }
      },
      logOut() {
        this.$store.state.employee.clearPassword();
        this.$store.state.employee = null;
        this.eraseAll();
        //Set POS default locale
        if (this.posConfiguration.getDefaultLocale()) {
          this.$ts.commit("setLocale", this.posConfiguration.getDefaultLocale());
          setConfiguration("locale", this.posConfiguration.getDefaultLocale());
        }
      },
      async logIn() {
        if (!this.employeeNumber) {
          return;
        }
        //Find employee by PIN
        let employee = this.employees.find(e => e.getPin() === this.employeeNumber);
        this.eraseAll();
        if (!employee) {
          toast({
            title: this.$t("error.title"),
            message: this.$t("login.employeeNotFound"),
            type: "error"
          });
          return;
        }
        //Validate password
        if (employee.getPasswordHash()) {
          EventBus.$emit("show-calculator", {
            callback: () => {
              toast({
                title: this.$t("error.title"),
                message: this.$t("login.badPassword"),
                type: "error"
              });
            },
            /**
             * @param {string} value
             */
            valueChangedCallback: async (value) => {
              let valid = await employee.validatePasswordHash(value);
              if (valid) {
                this.setEmployee(employee, value);
              }
            },
            parameters: {
              title: "Password",
              isFloat: false,
              allowNegativeValues: false
            }
          });
        } else {
          this.setEmployee(employee);
        }
      },
      /**
       * @param {Employee} employee
       * @param {string|null} password
       */
      setEmployee(employee, password = null) {
        EventBus.$emit("close-calculator");
        if (password) {
          employee.setPassword(password);
        }
        this.$store.state.employee = employee;
        //Set employee's preferred locale
        if (this.$store.state.employee.preferences && this.$store.state.employee.preferences.language) {
          this.$ts.commit("setLocale", this.$store.state.employee.preferences.language);
          setConfiguration("locale", this.$store.state.employee.preferences.language);
        }
      },
      showError(response) {
        response = response && response.response ? (response.response || {}) : (response || {});
        toast({
          title: this.$t("error.title"),
          message: this.$to(response.message) || "Server error", //TODO real error message
          type: "error"
        });
      },
      switchSecondScreenToStandby() {
        CustomerScreenLoader.switchToStandby(this.company ? this.company.getPosStandbyImageUrl() : window.DEFAULT_POS_STANDBY_SCREEN_URL);
      },
      async enterTable() {
        if (!this.tableNumber) {
          return;
        }
        await this.newOrder("inPlace", parseInt(this.tableNumber));
      }
    }
  };
  </script>

  <style lang="scss" scoped>
    #iShopFoodApp #posDashboard {
    display: flex;
    flex-direction: row;

    .dashboard__left {
      padding: 30px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .columns {
        display: flex;
        gap: 20px;
        flex-grow: 1;

        &.reversed {
          flex-direction: row-reverse;
        }

        .column {
          flex-grow: 1;
          flex-basis: 0;
          display: flex;
          flex-direction: column;

          &:first-child {
            flex-basis: initial;
          }
        }
      }

      .table__keypad {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 30px 10px;
        border-radius: 20px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--background-color-2);

        .keypad__top {
          display: flex;
          margin-bottom: 10px;

          .top__icon {
            padding: 24px;
            border-radius: 16px;
            background-color: var(--primary-color);
          }

          .top__info {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 20px;
            .info__title {
              font-size: 32px;
              font-weight: 900;
              color: var(--theme-color);
            }
            .info__description {
              font-size: 16px;
              color: var(--theme-color-2);
            }
          }
        }

        .keypad__middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 50px 0 50px;

          label {
            font-size: 18px;
            color: var(--theme-color-2);
            margin-bottom: 6px;
          }

          input {
            font-size: 32px;
            text-align: center;
            border-radius: 12px;
            font-family: inherit;
            font-weight: 500;
            width: 100%;
          }
        }

        .keypad__bottom {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .dashboard__right {
      padding: 20px;
      border-left: 1px dashed #e6e6e6;
      width: 700px;
      flex-shrink: 0;
    }

    .employee__actions {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 25px;

      .actions__action {
        display: flex;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--background-color-2);
        width: 400px;

        &.disabled {
          filter: grayscale(1);
          pointer-events: none;

          .action__content,
          .action__icon {
            opacity: 0.5;
          }
        }

        .action__content {
          flex-grow: 1;
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .content__title {
            font-size: 32px;
            font-family: "Roboto", sans-serif;
            font-weight: 900;
            color: var(--theme-color);
          }

          .content__description {
            font-size: 16px;
            font-weight: 500;
            color: var(--theme-color-2);
          }

          .content__action {
            height: 56px;
            justify-content: flex-start;
            font-weight: bold;
            background-color: var(--background-color-3);

            .svgicon {
              margin-left: 0;
              margin-right: 40px;
            }
          }
        }

        .action__icon {
          flex-shrink: 0;
          background-color: var(--primary-color);
          fill: white;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;
        }
      }
    }

    .employee__information {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 20px;
      padding-bottom: 20px;

      .employee-icon {
        margin-right: 20px;
        background-color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 56px;
        fill: white;
        border-radius: 50%;
      }

      .employee-info {
        padding-right: 40px;
      }

      .info-buttons {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
          background-color: var(--background-color-2);
          height: 56px;

          .svgicon {
            margin-right: 20px;
            margin-left: 0;
          }

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    .num-pad-left-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 286px;
      max-width: 286px;
    }

    .employee__description {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 35px;
      flex-grow: 1;
    }

    .employee__input {
      display: flex;
      flex-direction: column;
      width: 100%;

      .input__label {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color-2);
        margin-bottom: 6px;
      }

      input {
        border-radius: 12px;
        border: 1px solid var(--background-color-4);
        padding: 10px 15px;
        font-size: 40px;
        line-height: 1;
        font-weight: 500;
        color: var(--theme-color);
      }
    }

    .panel__employee {
      .illustration {
        margin-bottom: 74px;
        flex-grow: 1;
      }
    }

    .employee-info {
      display: flex;
      flex-direction: column;

      .info__name {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color-2);
        margin-bottom: 6px;
      }

      .info__value {
        font-weight: 900;
        color: var(--theme-color);
        font-size: 32px;
      }
    }

    .dashboard__right {
      display: flex;
      border-left: 1px dashed var(--border-color);
      flex-direction: column;
    }

    .past-orders {
      padding: 10px;
      overflow-y: auto;

      .past-order {
        background-color: var(--background-color-2);
        border-radius: 20px;
        padding: 10px;
        box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        margin-bottom: 20px;

        .order__information {
          flex-grow: 1;
          padding-right: 10px;

          .info__header,
          .info__inner,
          .info__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .info__header {
            color: var(--theme-color);
            font-size: 32px;
            font-weight: 900;
          }

          .info__inner {
            color: var(--primary-color);
            font-weight: bold;
            font-size: 16px;
            line-height: 1;
          }

          .info__footer {
            padding-top: 10px;
            line-height: 1;
            margin-top: 10px;
            border-top: 1px solid #e6e6e6;
            font-size: 16px;
            color: var(--theme-color);
            font-weight: bold;
            padding-bottom: 10px;
          }
        }

        .order__icon {
          border-radius: 10px;
          margin-right: 20px;
          background-color: var(--background-color);
          fill: var(--primary-color);
          flex-shrink: 0;
          min-height: 100px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #posDashboard {
    .panel__employee {
      width: 1028px;

      .illustration {
        margin-top: 55px;
        width: 100%;

        .svgicon {
          fill: var(--background-color-4);
        }
      }
    }

    .past-orders.empty {
      display: flex;
      flex-grow: 1;
    }

    .past-orders__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;

      .empty__text {
        font-weight: bold;
        font-size: 24px;
        color: #dbdbdb;
      }
    }
  }

  #iShopFoodApp #posDashboard .past-orders__empty .illustration {
    fill: #dbdbdb;
    margin-bottom: 30px;
  }

  #iShopFoodApp #posDashboard .keypad__bottom {
    .panel {
      margin: 0;
      box-shadow: none;

      .content .container {
        padding: 0;

        .keys {
          padding: 0;
        }
      }
    }
  }
</style>