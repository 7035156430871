<template>
  <div class="promotions__promotion" :class="{ shadow, active }" v-wave @click="$emit('click', $event)">
    <PromotionCorner :offset="active ? -5 : 0"></PromotionCorner>
    <div class="promotion__image" v-if="image && itemImage" :style="{ backgroundImage: 'url(' + itemImage + ')' }">
    </div>
    <div class="promotion__content" :class="{ noImage: !image || !itemImage }">
      <div class="promotion__content__title">{{ $to(promotion.name) }}</div>
      <div class="promotion__content__promotion">{{ `${$t("discount")} -${formattedPromotion}` }}</div>
    </div>
    <div class="promotion__action" v-if="checkmark">
      <svgicon v-if="!active" src="icons/circle-empty.svg" :width="24" :height="24"></svgicon>
      <svgicon v-else src="icons/check-circle-full.svg" :width="24" :height="24"></svgicon>
    </div>
  </div>
</template>

<script>
  import PromotionCorner from "../pos/PromotionCorner.vue";

  export default {
    name: "PromotionPanel",
    props: {
      shadow: {
        type: Boolean,
        default: false
      },
      image: {
        type: Boolean,
        default: false
      },
      promotion: {
        type: Object,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      },
      checkmark: {
        type: Boolean,
        default: false
      }
    },
    components: { PromotionCorner },
    computed: {
      table() {
        return this.$store.state.table;
      },
      itemImage() {
        if (!this.items || this.items.length === 0) {
          return null;
        }
        let image = this.items[0].image;
        return image ? this.$store.state.urlFileServer + image : null;
      },
      items() {
        if (!this.promotion || !this.$store.state.inventory || !this.$store.state.inventory.getRoot()) {
          return [];
        }
        return this.table.promotionVisitor.getItemsForPromotion(this.$store.state.inventory.getAllItems(), this.promotion);
      },
      formattedPromotion() {
        return `${this.promotion.value}${this.promotion.type === "percent" ? "%" : "$"}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .promotions__promotion {
    background-color: var(--background-color-5);
    border-radius: 20px;
    padding: 9px 10px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    min-height: 120px;
    cursor: pointer;

    &.shadow {
      border: none;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1)
    }

    &.active {
      border: 5px solid var(--primary-color);
      padding: 4px 5px;

      .promotion__action {
        top: 11px;
        right: 11px;
      }
    }
  }

  .promotion__image {
    width: 100px;
    height: 100px;
    background-image: url(/img/inPlace.jpg);
    background-size: cover;
    background-position: center;
    margin-right: 20px;
    border-radius: 10px;
    flex-shrink: 0;
  }

  .promotion__content {
    margin-right: 70px;

    &.noImage {
      margin-left: 30px;
    }
  }

  .promotion__content__title {
    font-size: 22px;
    font-weight: 900;
    max-width: 300px;
  }

  .promotion__content__promotion {
    font-weight: 900;
    color: var(--primary-color);
  }

  .promotion__action {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--primary-color);
  }
</style>