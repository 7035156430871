<template>
  <div id="posCart" :class="{ siblingModalVisible: siblingModalVisible, leftHandMode: isLeftHandedMode }">
    <div id="cart__header" class="cart__header">
      <div class="header__buttons" :class="{ collapsed: headerActionCollapsed }">
        <button class="btn btn-secondary btn--action" @click="action.action"
          :class="{ disabled: action.disabled && action.disabled() }"
          v-for="(action, actionIndex) of displayedHeaderActions" :key="'footer-action-' + actionIndex">
          {{ $to(action.name) }}
        </button>
      </div>
      <button v-wave class="btn btn-secondary" @click="toggleHeaderActions" v-if="displayedHeaderActions.length > 4">
        <svgicon src="icons/arrow.svg" :height="12" :width="18"></svgicon>
      </button>
    </div>

    <div class="cart__content">

      <div class="cart__quantity-keyboard">
        <div class="quantity__input">
          <label>{{ $t('quantity_short') }}</label>
          <div class="fake-input">{{ quantityBuffer }}</div>
        </div>
        <div class="quantity__keys">
          <div class="key" v-wave v-for="n in 10" @click="addQuantityToBuffer(n - 1)" :key="'key-' + n">{{ n - 1 }}</div>
          <div class="key" v-wave @click="clearQuantityBuffer">C</div>
        </div>
      </div>

      <div class="cart__sub-content">
        <div class="cart__items" id="cartItemList" v-if="table">

          <div class="cart__dispatch" v-if="table && table.isDispatch">
            <div class="dispatch__left" v-if="!noImage">
              <div class="dispatch__image">
                <svgicon src="icons/delivery-order.svg" v-if="table.method === 'delivery'" :height="64" :width="64">
                </svgicon>
                <svgicon src="icons/takeout-order.svg" v-if="table.method === 'takeout'" :height="64" :width="64">
                </svgicon>
              </div>
            </div>
            <div class="dispatch__right">
              <div class="dispatch__row">
                <div class="row__title">{{ $t("order.title_type") }}</div>
                <div class="row__value">{{ $t(table.method) }}</div>
              </div>
              <div class="dispatch__row">
                <div class="row__title">{{ $t("customer") }}</div>
                <div class="row__value">{{ $user.getFullName() }}</div>
              </div>
              <div class="dispatch__row">
                <div class="row__title">{{ $t("register.phoneNumber") }}</div>
                <div class="row__value">{{ formatPhoneNumber($user.phoneNumber) }}</div>
              </div>
              <div class="dispatch__row">
                <div class="row__title">{{ $t("pos.reception_time") }}</div>
                <div class="row__value">{{ table.date.format("YYYY/MM/DD") }} <template v-if="table.hour">- {{
                  company.formatHour(table.hour) }}</template></div>
              </div>
            </div>
            <div class="dispatch__actions">
              <button v-wave class="btn btn-secondary" @click="goToDispatch" :class="{ disabled: isOffline }">
                <svgicon src="icons/pencil-full.svg" :height="25" :width="25"></svgicon>
              </button>
            </div>
          </div>

          <div class="cart__seats" v-if="isTableMode">
            <div class="seats__previous" @click="previousSeat" v-wave>
              <svgicon src="icons/arrow-left.svg" :width="24" :height="24" />
            </div>
            <div class="seats__current">{{ $t("pos_seats.seat_no") }} {{ $store.state.currentSeat }}</div>
            <div class="seats__next" @click="nextSeat" v-wave>
              <svgicon src="icons/arrow-right.svg" :width="24" :height="24" />
            </div>
            <div class="seats__action" @click="goToSeat" v-wave>{{ $t("pos_seats.go_to_seat") }}</div>
          </div>

          <PosCartItem ref="cartItems" :detail="detail" v-for="(detail, detailIndex) of details" v-if="detail"
            @click="selectDetail" :selected-detail="selectedDetail" :key="'cart-item-' + detailIndex"></PosCartItem>

        </div>

        <div class="cart__footer" v-if="table && total">
          <div class="footer__top">
            <div class="top__left">
              <div class="footer__total_lines">
                <div class="total_line border">
                  <div class="line__label">{{ $t("total.subtotal") }}</div>
                  <div class="line__value">{{ $tc(total.subTotal) }}</div>
                </div>
                <div class="total_line" v-if="total.serviceFees">
                  <div class="line__label">{{ $t("total.service_fees") }}</div>
                  <div class="line__value">{{ $tc(total.serviceFees) }}</div>
                </div>
                <div class="total_line" v-if="total.surchargeFees">
                  <div class="line__label">{{ $t("total.surcharge_fees") }}</div>
                  <div class="line__value">{{ $tc(total.surchargeFees) }}</div>
                </div>
                <div class="total_line" v-for="(taxAmount, taxName) in total.taxes" :key="`tax-${taxName}`">
                  <div class="line__label">{{ taxName }}</div>
                  <div class="line__value">{{ $tc(taxAmount) }}</div>
                </div>
              </div>
              <div class="total">
                <label class="total__label">{{ isTableMode ? $t('pos_table.seat_total') : $t('total.amount_due') }}</label>
                <div class="total__amount">
                  <span class="sign before" v-if="$ts.getters.getCurrencySymbolPosition === 'before'">{{
                    $ts.getters.getSymbol }}</span>
                  <div class="amount__value">{{ Util.Money.roundForDisplay(total.total) }}</div>
                  <span class="sign after" v-if="$ts.getters.getCurrencySymbolPosition === 'after'">{{
                    $ts.getters.getSymbol }}</span>
                </div>
              </div>
              <div class="footer__total_lines bottom" v-if="isTableMode">
                <div class="total_line">
                  <div class="line__label">{{ $t("pos_table.table_total") }}</div>
                  <div class="line__value">{{ $tc(tableTotal.total) }}</div>
                </div>
              </div>
            </div>
            <div class="footer__total__actions">
              <button class="btn btn-primary" @click="saveAndQuit" v-wave
                :class="{ disabled: !table.hasDetails() || isOffline }">
                {{ $t('pos.order') }}
              </button>
              <button class="btn btn-primary" @click="goToPayment" v-wave
                :class="{ disabled: !table.hasDetails() || isOffline }">
                {{ $t('pos.pay') }}
              </button>
            </div>
          </div>
          <div class="footer__bottom">
            <button class="btn btn-secondary btn--action" v-wave @click="action.action"
              :class="{ disabled: action.disabled && action.disabled() }"
              v-for="(action, actionIndex) of displayedFooterActions" :key="'footer-action-' + actionIndex">
              {{ $to(action.name) }}
            </button>
            <button class="btn btn-secondary disabled" v-if="footerActions.length > 0"
              v-wave><!-- TODO this is useless -->
              <svgicon src="icons/dots.svg" :height="20" :width="20"></svgicon>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { Constant } from "@/util/Constant";
  import EventBus from "@/lib/eventBus";
  import { PermissionManager } from "@/util/PermissionManager";
  import PosCartItem from "@/components/pos/PosCartItem";
  import Table from "@/models/pos/TableModel";
  import { Util } from "@/util/Util";
  import { mapActions } from "vuex";

  export default {
    name: "posCart",

    components: { PosCartItem },

    mounted() {
      if (!this.table) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
      }
      this.loadFunctions();
      this.startSiblingModalVisibleInterval();
      this.addOutsideHeaderCollapseClickListener();
    },

    beforeDestroy() {
      clearInterval(this.siblingModalVisibleInterval);
      this.removeOutsideHeaderCollapseClickListener();
    },

    data() {
      return {
        headerActionCollapsed: true,
        siblingModalVisibleInterval: null,
        siblingModalVisible: false,
        selectedDetail: null,
        Util,
        footerActions: [],
        headerActions: [],
        availableActions: [
          { id: Constant.F_QUICK_CLOSE_CASH, action: this.quickCloseWithCash, disabled: () => { return this.details.length === 0 || this.$store.state.offline; } },
          { id: Constant.F_QUICK_CLOSE_CARD, action: this.quickCloseWithCard, disabled: () => { return this.details.length === 0 || this.$store.state.offline || !this.terminalNumber; } },
          { id: Constant.F_QUIT, action: this.quit },
          { id: Constant.F_SAVE, action: this.save, disabled: () => { return !this.table.hasDetails() || this.$store.state.offline; } },
          { id: Constant.F_EDIT, action: this.edit, disabled: () => { return !this.selectedDetail || this.selectedDetail.isOpenRemark || this.selectedDetail.hasSubSelection || !this.selectedDetail.isNew || this.selectedDetail.isSplit; } },
          { id: Constant.F_REMOVE, action: this.void, disabled: () => { return !this.canVoidDetail; } },
          { id: Constant.F_SPLIT, action: this.split, disabled: () => { return !this.selectedDetail || this.selectedDetail.isOpenRemark || this.selectedDetail.hasSubSelection || !this.isTableMode || this.selectedDetail.isVoid; } },
          { id: Constant.F_REMARK_ADD, action: this.addOpenRemark, disabled: () => { return !PermissionManager.hasPermission(Constant.P_ORDER_REMARK) || this.isEditingItem; } },
          { id: Constant.F_PRICE_CHANGE, action: this.changePrice, disabled: () => { return !PermissionManager.hasPermission(Constant.P_ORDER_EDIT_PRICE) || !this.selectedDetail || this.selectedDetail.isOpenRemark || this.selectedDetail.hasSubSelection || this.selectedDetail.isSplit; } },
          { id: Constant.F_DESCRIPTION_CHANGE, action: this.changeDescription, disabled: () => { return !PermissionManager.hasPermission(Constant.P_ORDER_EDIT_DESCRIPTION) || !this.selectedDetail || this.selectedDetail.isOpenRemark || this.selectedDetail.hasSubSelection; } },
          { id: Constant.F_RECIPE, action: this.recipe, disabled: () => { return !this.selectedDetail || !this.selectedDetail.itemHasKitchenRecipe; } },
          { id: Constant.F_ALLERGENS, action: this.ingredients, disabled: () => { return !this.selectedDetail || !this.selectedDetail.itemHasKitchenIngredientsOrAllergens; } },
          { id: Constant.F_SUGGESTIONS, action: this.crossSell, disabled: () => { return !this.canCrossSell; } },
          { id: Constant.F_REFUND, action: this.refund, disabled: () => { return !this.canRefund || !this.selectedDetail; } },
          { id: Constant.F_QUICK_TABLE, action: this.quickTable, disabled: () => { return false; } },
          { id: Constant.F_TRANSFER, action: this.transfer, disabled: () => { return !this.selectedDetail || !this.isTableMode || this.selectedDetail.isVoid; } },
          { id: Constant.F_TRANSFER_TABLE, action: this.tableTransfer, disabled: () => { return !this.selectedDetail || !this.isTableMode || this.selectedDetail.isSplit || this.selectedDetail.isVoid; } },
          { id: Constant.F_STATUS, action: this.goToStatusScreen, disabled: () => { return false; } }
        ],
        invalidAction: { id: null, action: () => { }, name: { fr: "N/A", en: "N/A" }, disabled: () => { return true; } }
      };
    },

    computed: {
      displayedFooterActions() {
        return this.footerActions.slice(0, 4);
      },
      displayedHeaderActions() {
        return this.headerActions.slice(0, 12);
      },
      company() {
        return this.$store.state.currentCompany;
      },
      table() {
        return this.$store.state.table;
      },
      total() {
        if (!this.table) {
          return;
        }
        return this.table.getTotal([this.$store.state.currentSeat]);
      },
      tableTotal() {
        if (!this.table) {
          return;
        }
        return this.table.getTotal();
      },
      isTableMode() {
        return this.table.method === "inPlace"; //TODO support 'table' order method
      },
      quantityBuffer() {
        return this.$store.state.quantityBuffer;
      },
      details() {
        if (this.table) {
          return this.table.getGroupedDetails(this.$store.state.currentSeat);
        }
        return [];
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      terminalNumber() {
        return this.posConfiguration && this.posConfiguration.getTerminalNumber();
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      isOffline() {
        return this.$store.state.offline;
      },
      cartItemComponents() {
        return this.$refs ? (this.$refs.cartItems || []) : [];
      },
      canVoidDetail() { //TODO this should consider if selectedDetail il already sent or not if the remove and void action are the same.
        return this.selectedDetail && this.selectedDetail.canShowVoidDetail;
      },
      canCrossSell() {
        return this.selectedDetail && this.selectedDetail.hasCrossSell;
      },
      canRefund() {
        return PermissionManager.hasPermission(Constant.P_ORDER_REFUND); //TODO P_ITEM_REFUND?
      },
      isEditingItem() {
        return this.$route.name === "itemEdit";
      }
    },

    watch: {
      details(newDetails, previousDetails) {
        this.$nextTick(() => {
          //Auto-select last component on added detail
          if (newDetails.length > previousDetails.length) {
            this.selectLastDetail();
          }
          //Make sure referenced component still exists in DOM
          if (this.selectedDetail) {
            if (this.cartItemComponents.indexOf(this.selectedDetail) === -1) {
              this.selectedDetail = null;
            }
          }
        });
      },
      "$store.state.currentRevenueCenterRef"() {
        this.loadFunctions();
      }
    },

    methods: {
      ...mapActions(["getTable", "sendTable", "payAndFinalizeTableWithSeats", "getCartFunctions", "getCartFunctions",
                     "transferBetweenTable", "initializeNewTable"]),

      addOutsideHeaderCollapseClickListener() {
        window.addEventListener("click", this.outsideHeaderCollapseClickListener, true);
      },

      removeOutsideHeaderCollapseClickListener() {
        window.removeEventListener("click", this.outsideHeaderCollapseClickListener, true);
      },

      async loadFunctions() {
        // Header
        let configuredHeaderActions = await this.getCartFunctions("top");
        this.headerActions = configuredHeaderActions.map(f => {
          let actionDefinition = this.availableActions.find(a => a.id === f.id);
          if (!actionDefinition) {
            return this.invalidAction;
          }
          return { ...f, ...actionDefinition };
        });
        // Footer
        let configuredFooterActions = await this.getCartFunctions("bottom");
        this.footerActions = configuredFooterActions.map(f => {
          let actionDefinition = this.availableActions.find(a => a.id === f.id);
          if (!actionDefinition) {
            return this.invalidAction;
          }
          return { ...f, ...actionDefinition };
        });
      },

      outsideHeaderCollapseClickListener(event) {
        let headerCollapse = document.getElementById("cart__header");
        if (!headerCollapse.contains(event.target)) {
          this.headerActionCollapsed = true;
        }
      },

      toggleHeaderActions() {
        this.headerActionCollapsed = !this.headerActionCollapsed;
      },

      async saveAndQuit() {
        await this.save();
        await this.quit();
      },

      goToPayment() {
        EventBus.$emit("show-invoice-creation", (seats) => {
          this.$router.push(this.$router.generate("/pos/payment"));
        });
      },

      startSiblingModalVisibleInterval() {
        this.siblingModalVisibleInterval = setInterval(() => {
          this.siblingModalVisible = !!document.querySelector("#iShopFoodApp > .content .modal.show");
        }, 100);
      },
      selectDetail(detailComponent) {
        if (this.selectedDetail === detailComponent) {
          this.clearSelectedDetail();
        } else {
          this.clearSelectedDetail();
          this.selectedDetail = detailComponent;
        }
      },
      selectLastDetail() {
        setTimeout(() => {
          this.clearSelectedDetail();
          this.selectedDetail = this.cartItemComponents[this.cartItemComponents.length - 1];
        }, 0);
      },
      selectPreviousDetail() {
        let index = Math.max((this.cartItemComponents.indexOf(this.selectedDetail) - 1), 0);
        this.clearSelectedDetail();
        this.selectedDetail = this.cartItemComponents[index];
      },
      edit() {
        if (this.selectedDetail) {
          this.selectedDetail.editDetail();
          this.clearSelectedDetail();
        }
      },
      void() {
        if (this.selectedDetail) {
          this.selectedDetail.voidDetail();
          this.selectPreviousDetail();
        }
      },
      split() {
        if (this.selectedDetail) {
          this.selectedDetail.splitOrUnsplit();
        }
      },
      crossSell() {
        if (this.selectedDetail) {
          this.selectedDetail.goToCrossSell();
        }
      },
      refund() {
        if (!this.selectedDetail) {
          return;
        }
        this.selectedDetail.refund();
      },
      async addOpenRemark() {
        if (this.selectedDetail) {
          this.selectedDetail.addOrEditOpenRemark();
          return;
        }

        if (this.table) {
          let keyboardResponse = await Util.Modal.keyboard(
            this.$t("pos_actions.open_remark_title"),
            "",
            Constant.MAX_NUM_CHAR_FOR_RECEIPT
          );
          if (keyboardResponse.target !== "enter") {
            return;
          }

          let remark = keyboardResponse.value?.trim();
          if (!remark) {
            return;
          }

          this.table.addOpenRemark(remark, this.$store.state.currentSeat);
        }
      },
      changePrice() {
        if (this.selectedDetail) {
          this.selectedDetail.changePrice();
        }
      },
      changeDescription() {
        if (this.selectedDetail) {
          this.selectedDetail.changeDescription();
        }
      },
      recipe() {
        if (this.selectedDetail) {
          this.selectedDetail.showRecipeModal();
        }
      },
      ingredients() {
        if (this.selectedDetail) {
          this.selectedDetail.showIngredientModal();
        }
      },
      async quit() {
        if (this.table.hasUnsavedDetails()) {
          let response = await showConfirmation(this.$t("pos.order_in_progress"), this.$t("pos.order_in_progress_warning"));
          if (!response) {
            return;
          }
        }
        this.$router.push(this.$router.generate("/pos/dashboard"));
      },
      addQuantityToBuffer(quantity) {
        if (this.quantityBuffer === null) {
          this.$store.state.quantityBuffer = quantity.toString();
        } else if (this.quantityBuffer.length < 2) {
          this.$store.state.quantityBuffer += quantity.toString();
        }
      },
      clearQuantityBuffer() {
        this.$store.state.quantityBuffer = null;
      },
      async quickCloseWithCash() {
        this.table.removePayments();
        let calculatorResponse = await Util.Modal.calculator({
          title: this.$t("pos_payment.enter_payment_amount"),
          isFloat: true,
          cashMode: true,
          expectedAmount: this.total.totalRemaining,
          allowNegativeValues: false
        });

        if (calculatorResponse.target !== "enter") {
          return;
        }
        let paymentAmount = calculatorResponse.value;
        if (paymentAmount > 0 && paymentAmount < this.total.totalRemaining) {
          toast({ message: this.$t("pos.payment_minimum"), title: this.$t("warning"), type: "warning" });
          return;
        }
        if (paymentAmount === 0) {
          paymentAmount = this.total.totalRemaining;
        }
        if (paymentAmount > 0) {
          this.table.addOfflinePayment("cash", paymentAmount, []);
        }
        EventBus.$emit("showReceiptModal", () => {
          this.quit();
        });
      },
      async quickCloseWithCard() {
        this.table.removePayments();
        let paymentAmount = this.total.totalRemaining;
        if (this.terminalNumber && paymentAmount > 0) {
          this.table.addPayfactoPaxPayment(this.terminalNumber, paymentAmount, []);
        }
        EventBus.$emit("showReceiptModal", () => {
          this.quit();
        });
      },
      showPaymentError(errorMessage) {
        toast({
          message: errorMessage,
          type: "error",
          title: this.$t("error.title")
        });
      },
      async save() {
        // Don't save new and empty tables
        if (!this.table.hasItems() && this.table.orderId === null) {
          return false;
        }
        showSpinner();
        let response = await this.sendTable({ table: this.table });
        if (response.success) {
          this.$store.state.table = new Table(response.table);
          this.$store.state.table.loadPromotions();
          hideSpinner();
          toast({ message: this.$t("pos.table.save_success"), type: "success", title: this.$t("success") });
          return true;
        } else {
          hideSpinner();
          toast({ message: this.$t("pos.table.save_error"), type: "error", title: this.$t("error.title") });
          return false;
        }
      },

      formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) {
          return "";
        }
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      },

      goToDispatch() {
        EventBus.$emit("show-dispatch-modal");
      },
      clearSelectedDetail() {
        if (!this.selectedDetail) {
          return;
        }
        this.selectedDetail.clearModifierSelection();
        this.selectedDetail = null;
      },
      previousSeat() {
        if (this.$store.state.currentSeat > 1) {
          this.$store.state.currentSeat--;
          this.selectLastDetail();
        }
      },
      nextSeat() {
        this.$store.state.currentSeat++;
        this.selectLastDetail();
      },
      async goToSeat() {
        let response = await Util.Modal.calculator({
          title: this.$t("pos_seat_selection_modal.seat_number"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (response.target !== "enter" || response.value === 0) {
          return;
        }
        this.$store.state.currentSeat = response.value;
        this.selectLastDetail();
      },
      async transfer() {
        if (!this.selectedDetail) {
          return;
        }
        let calculatorResponse = await Util.Modal.calculator({
          title: this.$t("pos.transfer_to_seat"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (calculatorResponse.value === null) {
          return;
        }
        this.selectedDetail.setSeat(calculatorResponse.value);
        this.selectLastDetail();
      },
      async tableTransfer() {
        if (!this.selectedDetail) {
          return;
        }
        let tableResponse = await Util.Modal.calculator({
          title: this.$t("pos.transfer_to_table"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (tableResponse.value === null) {
          return;
        }
        let seatResponse = await Util.Modal.calculator({
          title: this.$t("pos.transfer_to_seat"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (seatResponse.value === null) {
          return;
        }
        showSpinner();
        let detail = this.selectedDetail.getBaseDetail();
        let details = [detail, ...detail.details];
        await this.transferBetweenTable({
          employeePin: this.$store.state.employee.pin,
          password: this.$store.state.employee.password,
          table: this.table.tableId,
          targetTable: tableResponse.value,
          targetSeat: seatResponse.value,
          details
        });
        hideSpinner();
      },
      goToStatusScreen() {
        this.$router.push(this.$router.generate("/pos/status"));
      },
      async quickTable() {
        let newTable = await Util.Modal.calculator({
          title: this.$t("pos.go_to_table"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (newTable.value === null) {
          return;
        }
        showSpinner();
        await this.save();
        let tableResponse = await this.initializeNewTable({ method: "inPlace", tableNumber: newTable.value });
        if (!tableResponse.success) {
          hideSpinner();
          toast({ message: this.$t("pos.table.save_error"), type: "error", title: this.$t("error.title") });
          return;
        }
        this.selectLastDetail();
        hideSpinner();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posCart {
    width: 767px;
    flex-shrink: 0;
    background-color: var(--background-color);
    border-right: 1px dashed var(--border-color);
    padding: 30px 10px 10px 30px;
    display: flex;
    flex-direction: column;

    &.leftHandMode {
      border-left: 1px dashed var(--border-color);
      border-right: none;

      .cart__content {
        flex-direction: row;
      }

      .cart__sub-content {
        padding-left: 15px;
      }

      .cart__quantity-keyboard {
        padding-right: 0;
      }
    }

    &.siblingModalVisible {
      position: relative;
      pointer-events: none;
      //border-left: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.3);

      }
    }

    .cart__quantity-keyboard {
      margin-top: 10px;
      padding-right: 15px;

      label {
        font-size: 18px;
        font-weight: 500;
        color: #808080;
        margin-bottom: 8px;
        display: block;
      }

      .fake-input {
        background-color: var(--black-to-white);
        border: 1px solid var(--border-color);
        border-radius: 12px;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--theme-color);
        font-weight: 500;
        height: 56px;
        width: 56px;
      }

      .quantity__keys {
        padding-top: 10px;
        border-top: 1px solid var(--border-color);
        margin-top: 10px;
      }

      .key {
        background-color: var(--background-color-2);
        height: 56px;
        width: 56px;
        display: flex;
        font-size: 22px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        border-radius: 12px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .cart__content {
      flex-grow: 1;
      display: flex;
      overflow: hidden;
      flex-direction: row-reverse;
    }

    .cart__sub-content {
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      overflow: hidden;
      padding-left: 0px;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .cart__items {
      margin: 0px -10px 20px -10px;
      padding: 0 10px 20px 10px;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;

      .cart__dispatch {
        background-color: var(--background-color-2);
        border-radius: 20px;
        display: flex;
        margin-bottom: 15px;
        box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);

        .dispatch__left {
          padding: 10px 0px 10px 10px;

          .dispatch__image {
            background-color: var(--background-color-6);
            ;
            width: 100px;
            height: 100px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            fill: var(--primary-color);
          }
        }

        .dispatch__right {
          flex-grow: 1;
          padding: 20px 10px 20px 20px;

          .dispatch__row {
            display: flex;
            align-items: center;

            &:not(:last-of-type) {
              border-bottom: 1px solid var(--border-color-4);
              margin-bottom: 8px;
              padding-bottom: 8px;
            }

            .row__title {
              flex-grow: 1;
              color: var(--theme-color-2);
              font-weight: 500;
            }

            .row__value {
              color: var(--theme-color);
              font-weight: 500;
              font-size: 19px;
            }
          }
        }

        .dispatch__actions {
          .btn {
            width: 56px;
            height: 56px;
            margin: 10px;
          }
        }
      }
    }

    .cart__seats {
      display: flex;
      margin-bottom: 20px;
      gap: 10px;

      .seats__previous,
      .seats__current,
      .seats__next,
      .seats__action {
        background-color: var(--background-color-2);
        padding: 16px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        fill: var(--primary-color);
        font-weight: 500;
        font-size: 18px;
      }

      .seats__current {
        flex-grow: 1;
      }

      .seats__action {
        font-weight: 500;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .cart__header {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 20px;
      padding-bottom: 10px;
      padding-right: 20px;

      .btn {
        background-color: var(--background-color-2);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header__buttons {
        display: flex;
        flex-wrap: wrap;
        max-height: 180px;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        .btn {
          width: 153px;
          margin-right: 10px;
          margin-bottom: 10px;
          &.disabled {
            opacity: 0.5;
            filter: grayscale(100%);
          }
        }

        &.collapsed {
          max-height: 60px;
        }
      }
    }

    .cart__footer {
      border-radius: 20px;
      padding: 20px;
      background-color: var(--background-color-2);
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
      position: relative;

      &:before {
        content: "";
        border-top: 1px solid var(--border-color);
        padding-bottom: 20px;
        height: 1px;
        width: 100%;
        display: block;
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
      }
    }

    .footer__bottom {
      display: flex;
      justify-content: space-between;

      .btn--action {
        width: 125px;
        padding: 8px 14px;
        &.disabled {
          opacity: 0.5;
          filter: grayscale(100%);
        }
      }
    }

    .footer__top {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed var(--border-color-3);

      .btn {
        min-width: 200px;
        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        padding: 15px 30px;

        .svgicon {
          margin-right: 30px;
        }
      }

      .footer__total__actions {
        display: flex;
        flex-direction: column;

        .btn {
          flex-grow: 1;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      .total {
        flex-grow: 1;
        border-radius: 10px;
        padding: 11px 15px;
        border: 1px solid var(--border-color-4);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .total__amount {
          font-weight: 900;
          font-size: 32px;
          display: flex;
          line-height: 1;
        }

        .total__label {
          color: var(--theme-color-2);
          font-size: 20px;
          font-weight: 500;
        }

        .amount__value {
          color: var(--theme-color);
        }

        .sign {
          color: var(--theme-color-2);

          &.before {
            margin-right: 5px;
          }

          &.after {
            margin-left: 5px;
          }
        }
      }

      .top__left {
        flex-grow: 1;
        padding-right: 10px;
      }

      .footer__total_lines {
        display: flex;
        flex-direction: column;

        &.bottom {
          margin-top: 4px;
        }

        .total_line {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          padding: 0 15px;

          &.border {
            padding-bottom: 3px;
            margin-bottom: 7px;
            position: relative;

            &:after {
              content: "";
              height: 1px;
              background-color: var(--border-color-4);
              position: absolute;
              bottom: 0;
              left: 15px;
              right: 15px;
            }

            .line__value {
              font-weight: 900;
              font-size: 26px;
            }
          }

          .line__label {
            flex-grow: 1;
            color: var(--theme-color-2);
            font-weight: 500;
          }

          .line__value {
            color: var(--theme-color);
            font-weight: bold;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp .dispatch__image .svgicon {
    width: 64px;
    height: 64px;
  }
</style>
