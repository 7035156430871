<template>
  <div class="types" :class="{selected: !!$order.method}">
    <div class="type takeout" :class="{small: noImage || !!$order.method, selected: $order.method == 'takeout'}"
         v-if="hasOrderMethod('takeout')" @click="setMethod('takeout')">
      <AsyncImage class="image" :image="takeoutImage"></AsyncImage>
      {{$t("takeout")}}
    </div>
    <div class="type delivery" :class="{small: noImage || !!$order.method, selected: $order.method == 'delivery'}"
         v-if="hasOrderMethod('delivery')" @click="setMethod('delivery')">
      <AsyncImage class="image" :image="deliveryImage"></AsyncImage>
      {{$t("delivery")}}
    </div>
    <div class="type inPlace" :class="{small: noImage || !!$order.method, selected: $order.method == 'inPlace'}"
         v-if="hasOrderMethod('inPlace')" @click="setMethod('inPlace')">
      <div class="image"></div>
      {{$t("inPlace")}}
    </div>
    <div class="type catering" :class="{small: noImage || !!$order.method, selected: $order.method == 'catering'}"
         v-if="hasOrderMethod('catering')" @click="setMethod('catering')">
      <div class="image"></div>
      {{$t("catering")}}
    </div>
    <div class="type hotel" :class="{small: noImage || !!$order.method, selected: $order.method == 'hotel'}"
         v-if="hasOrderMethod('hotel')" @click="setMethod('hotel')">
      <div class="image"></div>
      {{$t("hotel")}}
    </div>
  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage";
  import EventBus from "../../lib/eventBus";

  export default {

    name: "orderMethod",

    components: { AsyncImage },

    props: {
      noImage: Boolean,
      company: Object
    },

    computed: {
      takeoutImage() {
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.takeoutImage) {
          return null;
        }
        return CONFIG.urlFileServer + this.$store.state.currentBranch.takeoutImage.url;
      },
      deliveryImage() {
        if (!this.$store.state.currentBranch || !this.$store.state.currentBranch.deliveryImage) {
          return null;
        }
        return CONFIG.urlFileServer + this.$store.state.currentBranch.deliveryImage.url;
      },
    },

    methods: {
      hasOrderMethod(method) {
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.hasOrderMethod(method);
      },
      setMethod(method) {
        this.currentCompany = null;
        this.$order.setMethod(method);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp .types {
    display: flex;
    min-height: 50px;

    .type {
      width: 150px;
      min-height: 150px;
      border: 2px solid #e6e6e6;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      transform: translate(0);
      animation: fadein 0.5s ease;
      @keyframes fadein {
        0% {
          opacity: 0%;
        }
        50% {
          opacity: 50%;
        }
        100% {
          opacity: 100%;
        }
      }
      &:not(:last-of-type) {
        margin-right: 20px;
      }
      &.small {
        height: auto;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
          display: none;
        }
        &.selected {
          border-color: var(--primary-color) !important;
        }
      }
      .image {
        display: block;
        height: 110px;
        margin-bottom: 8px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  @media (max-width: 600px) {
    #iShopFoodApp #orderOptionsModal.modal .content.modal-content {
      .types {
        flex-direction: column;
        font-size: 14px;

        &.selected {
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 -5px;
          .type {
            min-width: 35%;
            flex-grow: 1;
            flex-basis: 0;
            margin-left: 5px;
            margin-right: 5px;
            justify-content: center;
            font-size: 14px;
          }
        }
        .type {
          display: flex;
          height: auto;
          align-items: center;
          width: 100%;
          margin: 0;
          margin-bottom: 10px;

          .image {
            width: 120px;
            height: 80px;
            margin-right: 20px;
            flex-shrink: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
            margin-bottom: 0;
          }

          .text {
            padding: 20px;
            flex-grow: 1;
            text-align: center;
            font-size: 16px;
          }
        }
      }

    }
  }
</style>
