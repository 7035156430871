<template>
  <div id="navigation" v-if="screenSize == 'lg' || screenSize == 'md'">

    <div class="navigation-header" :class="{quick: $store.state.quickMode}" v-if="user.token">
      <div class="profile">
        <svgicon src="icons/profile.svg" :height="30" :width="30"></svgicon>
      </div>
      <div class="user" v-if="user.token" :class="{dispatch: user.isDispatchUser()}">
        <template v-if="user.isAnonymous()">
          {{$t('guest')}}
        </template>
        <template v-if="!user.isAnonymous() && user.isDispatchUser() && user.onBehalfOf">
          {{$t('navigation.order_for')}}: {{ user.getFullName() || user.email || $t('navigation.logged') }}
          <br>
          <span>{{user.phoneNumber}}</span>
        </template>
        <template v-else>
          {{ user.getFullName() || user.email || $t('navigation.logged') }}
        </template>
      </div>
      <div class="user" v-if="!user.token">{{$t('navigation.logged')}}</div>
      <div class="tooltip" ref="tooltip" @click.stop="showTooltip">
        <svgicon src="icons/arrow.svg" :height="12" :width="21"></svgicon>
      </div>
    </div>

    <div class="navigation-header" :class="{quick: $store.state.quickMode}" v-if="!user.token">
      <div class="user login" @click="login">{{$t('login.login')}}</div>
    </div>

    <div class="content">

      <div class="shop" v-if="company">
        <div class="logo" :style="{'background-image': 'url(' + companyImage + ')'}">
          <div class="inner-logo"></div>
        </div>
        <div class="tabs">
          <div class="tab" @click.stop="currentTab = 1" :class="{active: currentTab === 1}"
            v-if="company && hasEnterMessage($order.method)">
            <svgicon src="icons/message.svg" :height="20" :width="20"></svgicon>
          </div>
          <div class="tab" @click.stop="currentTab = 2" :class="{active: currentTab === 2}">
            <svgicon src="icons/location.svg" :height="20" :width="20"></svgicon>
          </div>
          <div class="tab" @click.stop="currentTab = 3" :class="{active: currentTab === 3}" v-if="company.phoneNumber || company.email">
            <svgicon src="icons/phone.svg" :height="20" :width="20"></svgicon>
          </div>
          <div class="tab" @click.stop="currentTab = 4" :class="{active: currentTab === 4}" v-if="company.hasSocialInformation()">
            <svgicon src="icons/share.svg" :height="20" :width="20"></svgicon>
          </div>
        </div>
        <div class="tab-infos">
          <div class="tab-info" v-if="currentTab === 1">
            <span v-if="hasEnterMessage($order.method)">
              <span>{{ $to(company.getEnterMessage($order.method)) }}<br></span>
            </span>
          </div>
          <div class="tab-info" v-if="currentTab === 2 && address">
            <span class="title">{{ company.name }}</span>
            <span>{{ address.address }}</span>
            <span v-if="address.address">{{ address.city }} ({{ address.state }})  {{ address.zipCode }}</span>
          </div>
          <div class="tab-info" v-if="currentTab === 3">
            <span class="title">{{ company.name }}</span>
            <span>{{ company.phoneNumber }}</span>
            <span>{{ company.email }}</span>
          </div>
          <div class="tab-info socials" v-if="currentTab === 4">
            <a v-if="company.information && company.information.social.webSite" target="_blank"
              :href="company.information.social.webSite" class="social">
              <svgicon src="icons/website.svg" :height="30" :width="27"></svgicon>
            </a>
            <a v-if="company.information && company.information.social.facebook" target="_blank"
              :href="company.information.social.facebook" class="social">
              <svgicon src="icons/facebook.svg" :height="30" :width="17"></svgicon>
            </a>
            <a v-if="company.information && company.information.social.instagram" target="_blank"
              :href="company.information.social.instagram" class="social">
              <svgicon src="icons/instagram.svg" :height="30" :width="30"></svgicon>
            </a>
            <a v-if="company.information && company.information.social.twitter" target="_blank"
              :href="company.information.social.twitter" class="social">
              <svgicon src="icons/twitter.svg" :height="30" :width="25"></svgicon>
            </a>
          </div>
        </div>
      </div>

      <template v-if="!$store.state.ticketMode">
        <div class="menu" :class="{grow: menuVisible && !$store.state.quickMode, quick: $store.state.quickMode}">
          <div class="header">
            <svgicon src="icons/menu.svg" :height="20" :width="15"></svgicon>
            <router-link :to="{name: 'menu'}" class="name">{{$t('navigation.menu')}}</router-link>
            <div class="toggle" v-if="!$store.state.quickMode" @click.stop="menuVisible = !menuVisible">
              <svgicon class="arrow" src="icons/arrow.svg" :height="13" :width="20" :class="{rotate: menuVisible}"></svgicon>
            </div>
          </div>
          <div class="categories" v-if="categories && !$store.state.quickMode" :class="{visible: menuVisible}">
            <div class="category" :key="'category-' + categoryIndex" @click="goToCategory(category.id)"
                         :class="{'router-link-active': isActive('/menu/' + category.id)}"
                         v-for="(category, categoryIndex) of nonEmptyCategories">
              <AsyncImage class="image" :fallback="$store.state.defaultItemImage" :image="$store.state.urlFileServer + category.image"></AsyncImage>
              <div class="name">{{ $to(category.name) }}</div>
            </div>
          </div>
        </div>
        <router-link :to="{name: 'menu'}" class="business"
                     v-if="$store.state.displayMode && company && company.hasOrderMethod($order.method) && branch && !branch.allCompaniesHaveMenuDisplay()"
                     @click.native="$store.state.displayMode = false">
          <svgicon src="icons/menu-card.svg" :height="20" :width="16"></svgicon>
          <span class="title">{{$t('quit_display_mode')}}</span>
        </router-link>

        <router-link :to="{name: 'dispatch'}" class="business" v-if="$user.token && $user.isDispatchUser()">
          <svgicon src="icons/dispatch.svg" :height="20" :width="16"></svgicon>
          <span class="title">{{$t('dispatch.title')}}</span>
        </router-link>

        <router-link :to="{name: 'menu'}" class="business" v-if="!$store.state.displayMode && branch && branch.getCompaniesWithMenuDisplay().length > 0"
                     @click.native="enterDisplayMode">
          <svgicon src="icons/menu-card.svg" :height="20" :width="16"></svgicon>
          <span class="title">{{$t('enter_display_mode')}}</span>
        </router-link>

        <router-link :to="{name: 'promotions'}" class="branches" v-if="PromotionManager.hasPromotion()">
          <svgicon src="icons/branches.svg" :height="20" :width="16"></svgicon>
          <span class="title">{{$t('navigation.promos')}}</span>
        </router-link>
      </template>
      <template v-if="$store.state.ticketMode">
        <br>
        <router-link :to="{name: 'tickets'}" class="business">
          <svgicon src="icons/ticket.svg" :height="20" :width="16"></svgicon>
          <span class="title">{{$t('tickets.title')}}</span>
        </router-link>
      </template>

      <router-link :to="{name: 'companies'}" class="branches" v-if="branch && !$store.state.quickMode && branch.companies.length > 1 && !$store.state.displayMode">
        <svgicon src="icons/branches.svg" :height="20" :width="16"></svgicon>
        <span class="title">{{$t('navigation.branches')}}</span>
      </router-link>

      <router-link :to="{name: 'company'}" class="business">
        <svgicon src="icons/business.svg" :height="20" :width="16"></svgicon>
        <span class="title">{{$t('navigation.business')}}</span>
      </router-link>

      <ExternalLink :url="link.url" class="business external" v-for="link of externalLinks" target="_blank" :key="link.url">
        <svgicon src="icons/globe.svg" :height="20" :width="16"></svgicon>
        <span class="title">{{ $to(link.title) }}</span>
      </ExternalLink>

      <div class="language" v-if="$store.state.inventory && !$store.state.inventory.isEnglishLocaleOnly()">
        <svgicon src="icons/language.svg" :height="20" :width="16"></svgicon>
        <span v-for="locale in $ts.getters.getLocales" :key="locale"  class="title"
              :class="{active: $ts.state.locale == locale}" @click.stop="setLocale(locale)">
            {{ $t(locale) }}
          </span>
      </div>

      <div class="legal-links">
        <ExternalLink class="legal-link" :url="$t('register.terms_of_use_url')">{{ $t('terms_of_use') }}</ExternalLink>
        <ExternalLink class="legal-link" :url="$t('register.privacy_policy_url')">{{ $t('privacy_policy') }}</ExternalLink>
        <router-link class="legal-link" :to="{name: company ? 'cookiesPolicy' : 'cookiesPolicyNoCompany'}">{{ $t('title.cookiesPolicy') }}</router-link>
      </div>

    </div>

  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import EventBus from "../lib/eventBus";
  import AsyncImage from "@/components/tools/AsyncImage";
  import ExternalLink from "@/components/tools/ExternalLink.vue";
  import { PromotionManager } from "../PromotionManager";

  export default {

    name: "Navigation",

    components: { AsyncImage, ExternalLink },

    data() {
      return {
        currentTab: 1,
        menuVisible: true,
        tooltipItems: [
          {
            tr: "loyalty.hub",
            onclick: () => { this.goToHub(); },
            condition: () => { return this.branch && this.branch.loyaltyConfig && this.branch.loyaltyConfig.type == "ishopfood" && this.branch.eWalletActivated; }
          }, {
            tr: "navigation.history",
            onclick: () => { this.goToHistory() }
          }, {
            tr: "loyalty.reload",
            onclick: () => { this.goToReload() },
            condition: () => { return (
                this.branch
                && this.branch.loyaltyConfig
                && this.branch.loyaltyConfig.type === "ishopfood"
                && this.branch.eWalletActivated
                && this.branch.loyaltyConfig.data.rechargeEnabled
                && this.$user.getLoyaltyAccount()
                && this.$user.getLoyaltyAccount().canManage()
              )
            }
          }, {
            tr: "navigation.profile",
            onclick: () => { this.goToProfile() },
            condition: () => { return !this.$user.isDispatchUser() }
          }, {
            tr: "navigation.logout",
            onclick: () => { this.logout() }
          }],
        PromotionManager
      }
    },

    mounted() {
      EventBus.$on("show-navigation-categories", () => {
        this.menuVisible = true;
      });
      let company = this.$store.state.currentCompany;
      if (!company || !company.getEnterMessage(this.$order.method) || this.$to(company.getEnterMessage(this.$order.method)).length == 0) {
        this.currentTab = 2;
      }
    },

    watch: {
      "$store.state.currentCompany": function(to, from) {
        if (to && to.getEnterMessage(this.$order.method) && to.getEnterMessage(this.$order.method).length > 0) {
          this.currentTab = 1;
        } else {
          this.currentTab = 2;
        }
      },
      hasAnyEnterMessage: function(to, from) {
        if (to == true) {
          this.currentTab = 1;
        }
      }
    },

    computed: {
      categories() {
        if (!this.$store.state.inventory || !this.$store.state.inventory.getRoot()) {
          return [];
        }
        let root = this.$store.state.inventory.getRoot();
        if (this.$order.branding) {
          root = root.filter(c => c.branding === this.$order.branding.id);
        }
        return root;
      },
      nonEmptyCategories() {
        return this.categories.filter(c => c.shouldBeDisplayedAs("category", this.$order.method) && c.isAvailable(this.$order.date, this.$order.hour));
      },
      user() {
        return this.$user;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      companyImage() {
        if (this.company && this.company.image) {
          return this.$store.state.urlFileServer + this.company.image;
        } else {
          return "img/default.jpg";
        }
      },
      address() {
        if (!this.company) {
          return "";
        }
        return this.company.address;
      },
      screenSize() {
        return this.$store.state.screenSize;
      },
      hasAnyEnterMessage() {
        return this.hasEnterMessage("default") || this.hasEnterMessage(this.$order.method);
      },
      externalLinks() {
        if (!this.company) {
          return [];
        }
        return this.company.externalLinks;
      }
    },

    methods: {
      ...mapActions({
        getItemAction: "getItem"
      }),
      enterDisplayMode() {
        EventBus.$emit("menu-display-locations-modal");
      },
      goToHistory() {
        EventBus.$emit("show-quick-navigation");
        this.$router.push(this.$router.generate("/transactionHistory"));
      },
      goToReload() {
        EventBus.$emit("show-quick-navigation");
        this.$router.push(this.$router.generate("/reload"));
      },
      goToProfile() {
        EventBus.$emit("show-quick-navigation");
        this.$router.push(this.$router.generate("/profile"));
      },
      goToHub() {
        EventBus.$emit("show-quick-navigation");
        this.$router.push(this.$router.generate("/hub"));
      },
      login() {
        EventBus.$emit("show-quick-navigation");
        let redirect = this.$route.name
        this.$router.push(this.$router.generate("/login?redirect=" + redirect));
      },
      logout() {
        EventBus.$emit("show-quick-navigation");
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
        this.$router.push(this.$router.generate("/login?redirect=menu"));
      },
      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },
      isActive(path) {
        let url = "";
        if (this.$store.state.currentCompany) {
          url = "/" + store.state.currentCompany.nameCanonical + url;
        }
        if (this.$store.state.currentBranch) {
          url = "/" + store.state.currentBranch.nameCanonical + url;
        }
        return url + path === this.$route.fullPath;
      },
      showTooltip() {
        if (this.$user.isAnonymous()) {
          EventBus.$emit("tooltip", {
            parent: this.$refs.tooltip,
            items: [{
              tr: "navigation.logout",
              onclick: () => { this.logout() }
            }]
          });
        } else {
          EventBus.$emit("tooltip", {
            parent: this.$refs.tooltip,
            items: this.tooltipItems
          });
        }
      },
      hasEnterMessage(method) {
        return this.company && this.company.getEnterMessage(method) && this.$to(this.company.getEnterMessage(method)).length > 0;
      },
      goToCategory(id) {
        if (this.$store.state.quickMode) {
          EventBus.$emit("open-category", id);
        } else {
          this.$router.push({ name: "menuWithId", params: { id } });
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #navigation {
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-image: url("../assets/img/bg.png");
  }

  .tooltip {
    cursor: pointer;
    padding: 20px;
    margin-right: -20px;
  }

  .navigation-header {
    height: 60px;
    background-color: #c7222d;
    fill: #fc6650;
    display: flex;
    align-items: center;
    padding: 10px 40px;
    flex-shrink: 0;
    &.quick {
      height: 100px;
    }

    .user {
      flex-grow: 1;
      margin-left: 12px;
      color: white;
      font-weight: bold;
      font-size: 18px;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.dispatch {
        font-size: 15px;

        span {
          font-weight: normal;
        }
      }

      &.login {
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .content {
    background-color: rgba(255,255,255,0.5);
    flex-grow: 1;
    padding: 30px 40px 20px;
    display: flex;
    flex-direction: column;
  }

  $radius: 10px;

  .shop {
    background-color: white;
    border-radius: $radius;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);

    .logo {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: grey;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .inner-logo {
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .tabs {
      fill: #bfbfbf;
      display: flex;
      justify-content: space-around;
      padding: 0 25px;
      cursor: pointer;

      .tab {
        padding: 20px;
      }

      .active {
        fill: #ed2937;
      }
    }
    .tab-info {
      margin: 0 20px;
      padding: 20px 10px;
      border-top: 1px solid #e6e6e6;
      color: #808080;
      display: flex;
      flex-direction: column;

      .title {
        color: #404040;
        font-size: 18px;
        margin-bottom: 4px;
      }
    }
    .socials {
      display: flex;
      flex-direction: row;
      justify-content: left;
      fill: #ed2937;
      padding: 25px 15px;
      .social {
        width: 40px;
        padding: 5px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .legal-links {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-left: 10px;

    .legal-link {
      font-size: 12px;
      text-decoration: none;
      line-height: 1;
      cursor: pointer;
      opacity: 0.7;
      margin-bottom: 5px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;

    &.grow {
      flex-grow: 1;
    }

    &.quick {
      padding: 10px 10px 10px 0;

      .categories.visible {
        border: none;
        padding-top: 0;
        .category {
          padding-left: 40px;
          .image {
            display: none;
          }
        }
      }
    }

    .header {
      display: flex;
      fill: #ed2937;
      font-weight: 500;
      margin-top: 20px;
      align-items: center;
      padding-left: 10px;

      .name {
        margin-left: 15px;
        text-decoration: none;
        color: inherit;
        font-size: 18px;
        font-weight: normal;
      }

      .toggle {
        padding: 15px;
        margin-right: -15px;
        cursor: pointer;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }

      .arrow {
        fill: #fc6650;
        transition: transform 0.2s ease;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
    .categories {
      height: 0;
      padding: 0;
      overflow: hidden;
      transition: height 0.2s ease;

      &.visible {
        border-bottom: 1px dashed #ccc;
        border-top: 1px dashed #ccc;
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 10px 0;
        margin-bottom: 5px;
      }

      .category {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 16px;
        padding: 10px 40px 10px 20px;
        color: inherit;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 10px;
        }

        &.router-link-active {
          font-weight: 500;
          .image {
            border: 3px solid #ed2937;
          }
        }

        .image {
          height: 60px;
          width: 60px;
          border-radius: 100px;
          margin-right: 20px;
          background-color: #ccc;
          background-position: center;
          background-size: cover;
          border: 3px solid transparent;
          flex-shrink: 0;
          overflow: hidden;
        }
      }
    }
  }

  .branches, .language, .business {
    display: flex;
    fill: #ed2937;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    color: inherit;
    font-size: 18px;

    .title {
      margin-left: 15px;
    }
  }
  .external {
    text-decoration: underline;
  }

  .language {
    .title:not(:last-child) {
      position: relative;
      padding-right: 15px;

      &:after {
        content: "";
        height: 18px;
        width: 2px;
        background-color: #888;
        opacity: 0.3;
        position: absolute;
        right: 0;
        top: 1px;
      }
    }
    .active {
      font-weight: bold;
    }
  }

</style>
