<template>
    <div id="kioskConfirmation">

        <div class="confirmation--text">
            {{$t("kiosk.confirm")}}
        </div>

        <div class="confirmation--holder">
            <div class="confirmation--header">
                <div class="header__title bigger">{{$t('my_order')}}</div>
                <div class="header--method smaller">{{$t('kiosk_method.' + $order.method)}}</div>
            </div>

            <div class="confirmation--items">
                <CartItem confirmation :item="item" :index="cartIndex" v-for="(item, cartIndex) of $order.items" :key="cartIndex"></CartItem>
                <CartItem confirmation :item="supplement" :index="supplementIndex" v-for="(supplement, supplementIndex) of $order.supplements"
                          :key="'supp' + supplementIndex"></CartItem>
              <div class="cart-item__fake" v-if="promotion">
                <div class="left">
                  <div class="promotion--name">{{$to(promotion.name)}}</div>
                  <div class="promotion--type">{{promotion.type == 'percent' ? promotion.value + "%" : $tc(promotion.value)}} {{$t('kiosk.of_reduction')}}</div>
                </div>
                <div class="right">
                  <div class="promotion--price">-{{$tc(orderTotal.promotionTotal.toFixed(2))}}</div>
                </div>
              </div>
            </div>

            <div class="confirmation--footer">
              <div class="header__title smaller" v-for="(taxValue, taxName) in orderTotal.taxes">{{ taxName }}: {{ $tc(taxValue.toFixed(2))}}</div>
              <div class="header__title smaller" v-if="orderTotal.serviceFees">{{ $t('total.service_fees') }}: {{ $tc(orderTotal.serviceFees.toFixed(2))}}</div>
              <div class="header__title smaller" v-if="orderTotal.deliveryFees">{{ $t('total.delivery_fees') }}: {{ $tc(orderTotal.deliveryFees.toFixed(2))}}</div>
              <div class="header__title smaller" v-if="orderTotal.tip">{{ $t('total.tip') }}: {{ $tc(orderTotal.tip.toFixed(2))}}</div>
              <div class="footer--total bigger">{{$t('total.total')}}: {{ $tc(orderTotal.totalRemaining.toFixed(2)) }}</div>
            </div>

        </div>

        <div class="confirmation--actions">
            <button class="btn btn-secondary" @click="back" @contextmenu="back">
                <svgicon src="icons/back-full.svg" :height="20" :width="20"></svgicon>
                {{$t('no')}}
            </button>
            <button class="btn btn-primary" @click="confirm" @contextmenu="confirm">
                {{$t('yes')}}
            </button>
        </div>

    </div>
</template>

<script>
  import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";

  export default {
    name: "kioskConfirmation",

    components: { },

    mounted() {
      if (this.$order.items.length === 0) {
        this.back();
      }
      this.initAirxTouchDisplayLayout();
    },

    computed: {
      orderTotal() {
        return this.$order.getOrderTotal();
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      promotion() {
        return this.$order.appliedPromotion.promotion;
      }
    },

    methods: {
      back() {
        if (this.$store.state.ticketMode) {
          this.$router.push(this.$router.generate("/tickets"));
        } else {
          this.$router.push(this.$router.generate("/menu"));
        }
      },
      confirm() {
        if (this.kioskConfiguration && this.kioskConfiguration.hasCallingSystemForMethod(this.$order.method)) {
          this.$router.push(this.$router.generate("/kiosk/calling-system"));
        } else {
          this.$router.push(this.$router.generate("/kiosk/payment"));
        }
      },
      initAirxTouchDisplayLayout() {
        if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
          this.$nextTick(() => {
            window.airxtouch__submitLayout([
              GetElementBounds("kioskConfirmation", 0, AIRxTouchDefaultLayoutSetting.DirectClick), // Click area : Whole page
            ]);
          });
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    #iShopFoodApp #kioskConfirmation {
        display: flex;
        align-items: center;
        justify-content: center;

        $confirmation-width: 840px;

        .confirmation--text {
            font-size: 40px;
            text-align: center;
            font-weight: bold;
            padding: 0 150px 100px 150px;
        }

        .confirmation--holder {
            width: 100%;
            max-width: $confirmation-width;
            box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.15);
            border-radius: 20px;
        }

        .confirmation--header, .confirmation--footer {
            background-color: #f5f5f5;
            display: flex;
            align-items: flex-end;
            padding: 20px 40px;
            position: relative;
        }

        .confirmation--header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
        }

        .confirmation--footer {
            justify-content: flex-end;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        .confirmation--items {
            background-color: white;
            padding: 40px;
            overflow-y: auto;
            max-height: 50vh;
        }

        .confirmation--actions {
            display: flex;
            width: 100%;
            max-width: $confirmation-width;
            justify-content: space-between;
            padding-top: 100px;

            .btn {
                width: 250px;
            }

            .btn-secondary {
                background-color: white;
                fill: var(--primary-color);
                .svgicon {
                    margin-right: 15px;
                }
            }

            .btn-primary {
                fill: white;
                .svgicon {
                    margin-right: 15px;
                }
            }
        }

      .cart-item__fake {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .promotion--name {
          font-size: 24px;
          font-weight: bold;
        }
        .promotion--type {
          font-weight: bold;
          color: #808080;
          font-size: 20px;
          padding-top: 2px;
        }
        .promotion--price {
          font-weight: 900;
          color: #ed2937;
          font-size: 24px;
          font-family: 'Segoe UI Black', sans-serif;
        }
      }

        .bigger {
            text-transform: uppercase;
            color: var(--primary-color);
            font-family: 'Segoe UI Black';
            font-weight: bold;
            font-size: 20px;
        }

        .smaller {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            color: var(--primary-color);
        }

        .header__title {
            &:after {
                content: "";
                display: inline-block;
                margin: 0 15px;
                height: 15px;
                width: 2px;
                background-color: #d9d9d9;
            }
        }

    }
</style>
