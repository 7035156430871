import Store from "../store";

class MediaLibrary {
  constructor() {
    this.mediaMap = new Map();
    this.lastFetchBranchId = 0;
  }

  async fetch(branchId) {
    let medias = await Store.dispatch("getBranchMediasAll", {
      branchId: branchId
    });
    if (medias && medias.data) {
      if (this.lastFetchBranchId !== branchId) {
        this.lastFetchBranchId = branchId;
        this.mediaMap = new Map();

        for (let media of medias.data) {
          this.mediaMap.set(media.id, media);
        }
      } else {
        for (let media of medias.data) {
          if (!this.mediaMap.has(media.id)) {
            this.mediaMap.set(media.id, media);
          }
        }
      }
    }
  }

  getMediaFromId(id) {
    return this.mediaMap.get(id);
  }

  getMediaURLfromId(id) {
    let media = this.getMediaFromId(id);
    if (media && media.url) {
      return CONFIG.urlFileServer + media.url;
    }
    return null;
  }
}

let mediaLibrary = new MediaLibrary();
export { mediaLibrary as MediaLibrary }