<template>
  <div id="transactions" :class="{mobile: $store.getters.isMobileOrTablet}" >
    <div class="search" :class="{mobile: $store.getters.isMobileOrTablet}">
      <div class="input type-search" v-if="hasEWalletActivated">
        <label>{{$t('transaction_history.type')}}</label>
        <select v-model="displayLoyalty">
          <option :value="true">{{$t('history.loyalty_transactions')}}</option>
          <option :value="false">{{$t('history.orders')}}</option>
        </select>
      </div>
      <div class="input type-search" v-if="branch && branch.userManagementEnabled && $user.managed.length > 0">
        <label>{{$t('history.user')}}</label>
        <select v-model="userId">
          <option :value="null">{{$t('transaction_history.all_type')}}</option>
          <option :value="$user.id">{{getUserDisplayName($user)}}</option>
          <option :value="managed.id" v-for="managed of $user.managed">{{getUserDisplayName(managed)}}</option>
        </select>
      </div>
      <div class="input date-search">
        <label>{{$t('transaction_history.date')}}</label>
        <!--<input type="date" class="date" v-model="selectedDate">-->
        <div class="date select">
          <svgicon class="select-icon" src="icons/clock.svg" :height="16" :width="16"></svgicon>
          <div class="selected">
            <!-- TODO - Mettre à jour quand je vais comprendre ce qui se passe -->
            <datetime zone="UTC" v-model="selectedDate" :format="dateFormat" :max-datetime="maxDate"
                       :week-start="7"></datetime>
          </div>
          <svgicon class="arrow" v-if="!selectedDate" src="icons/arrow.svg" :height="13" :width="20"></svgicon>
          <div class="clear-date" v-if="selectedDate" @click="clearDate">
            <svgicon class="icon" src="icons/btn-delete.svg" :height="24" :width="24"></svgicon>
          </div>
        </div>
      </div>
    </div>

    <table class="transactions" cellpadding="8px" cellspacing="0" v-if="transactions.length > 0">
      <tr class="transaction desktop table-head" v-if="!$store.getters.isMobileOrTablet">
        <td class="number">{{$t('transaction_history.table.number')}}</td>
        <td class="type">{{$t('transaction_history.table.type')}}</td>
        <td class="date" align="right">{{$t('transaction_history.table.date')}}</td>
        <td class="hour" align="left">{{$t('transaction_history.table.time')}}</td>
        <td class="price">{{$t('transaction_history.table.price')}}</td>
        <td class="details">{{$t('transaction_history.details')}}</td>
      </tr>
      <template v-for="transaction of transactions">
        <tr class="transaction desktop" v-if="!$store.getters.isMobileOrTablet" :key="transaction.id">
          <td class="number" :class="{'has-type': transaction.sale && transaction.type === 'sale'}">
            <span v-if="transaction.type === 'sale'">{{transaction.moreData && transaction.moreData.note === 'scan' ? $t('history.scan') : $t('history.order')}}</span>
            <span v-if="transaction.type !== 'sale'">{{$t('transaction_history.' + transaction.type)}}</span>
          </td>
          <td class="type">{{transaction.sale}}</td>
          <td class="date" align="right">{{getDate(transaction.createdAt)}}</td>
          <td class="hour" align="left">{{getHour(transaction.createdAt)}}</td>
          <td class="price">
            <span class="price-content">{{transaction.money.toFixed(2)}} $</span>
          </td>
          <td class="details">
            <span class="detail-content" @click="showDetails(transaction.id)">
              {{$t('transaction_history.details')}}
            </span>
          </td>
        </tr>

        <tr class="transaction mobile" v-if="$store.getters.isMobileOrTablet">
          <td>
            <div class="left">
              <span class="number" v-if="transaction.type === 'sale'">
                {{transaction.moreData && transaction.moreData.note === 'scan' ? $t('history.scan') : $t('history.order') + " #" + transaction.sale}}
              </span>
              <span class="number" v-if="transaction.type !== 'sale'">{{$t('transaction_history.' + transaction.type)}}</span>
              <span class="date">{{getDate(transaction.createdAt)}}, {{getHour(transaction.createdAt)}}</span>
            </div>
          </td>
          <td>
            <div class="right">
              <span class="price">{{transaction.money.toFixed(2)}} $</span>
            </div>
          </td>
          <td class="details " align="right">
            <svgicon class="icon detail-content " src="icons/details.svg" :height="24" :width="24"
                     @click="showDetails(transaction.id)"></svgicon>
          </td>
        </tr>
      </template>
    </table>

    <table class="transactions" cellpadding="8px" cellspacing="0" v-if="sales.length > 0">
      <tr class="transaction desktop table-head" v-if="!$store.getters.isMobileOrTablet">
        <td class="number">{{$t('transaction_history.table.number')}}</td>
        <td class="type">{{$t('transaction_history.table.type')}}</td>
        <td class="date" align="right">{{$t('transaction_history.table.date')}}</td>
        <td class="hour" align="left">{{$t('transaction_history.table.time')}}</td>
        <td class="price">{{$t('transaction_history.table.price')}}</td>
        <td class="details">{{$t('transaction_history.details')}}</td>
      </tr>
      <template v-for="sale of sales">
        <tr class="transaction desktop" v-if="!$store.getters.isMobileOrTablet" :key="sale.id">
          <td class="number has-type">
            {{$t('history.order')}} #{{sale.id}}
          </td>
          <td class="type">{{$t(sale.deliveryMethod)}}</td>
          <td class="date" align="right">{{getDate(sale.createdAt)}}</td>
          <td class="hour" align="left">{{getHour(sale.createdAt)}}</td>
          <td class="price">
            <span class="price-content">{{sale.total.toFixed(2)}} $</span>
          </td>
          <td class="details">
            <span class="detail-content" @click="showSale(sale)">
              {{$t('transaction_history.details')}}
            </span>
          </td>
        </tr>

        <tr class="transaction mobile" v-if="$store.getters.isMobileOrTablet">
          <td>
            <div class="left">
              <span class="number">{{$t('history.order')}} #{{sale.id}}</span>
              <span class="date">{{getDate(sale.createdAt)}}, {{getHour(sale.createdAt)}}</span>
            </div>
          </td>
          <td>
            <div class="right">
              <span class="price">{{sale.total.toFixed(2)}} $</span>
              <span class="method">{{$t(sale.deliveryMethod)}}</span>
            </div>
          </td>
          <td class="details " align="right">
            <svgicon class="icon detail-content " src="icons/details.svg" :height="24" :width="24"
                     @click="showSale(sale)"></svgicon>
          </td>
        </tr>
      </template>
    </table>

    <div class="paging" v-if="(transactions.length > 0 || sales.length > 0) && maxPages > 1">
      <div class="back" @click="previousPage">
        <svgicon class="icon" src="icons/arrow-left.svg" :height="28" :width="28"></svgicon>
      </div>
      <ul>
        <li v-for="n in pages" :class="{'current-page': n === page}" @click="page = n">{{n}}</li>
      </ul>
      <div class="next" @click="nextPage">
        <svgicon src="icons/arrow-right.svg" :height="28" :width="28"></svgicon>
      </div>
    </div>

    <div class="no-transactions" v-if="transactions.length === 0 && sales.length === 0">{{$t('history.no_orders')}}</div>

    <div class="modal" id="transactionDetails">
      <div class="modal-content" v-if="currentOrder || currentTransaction">
        <div class="modal-header">
          <svgicon class="icon" src="icons/bill.svg" :height="25" :width="25"></svgicon>
          <div class="title">
            <template v-if="currentOrder">
              <span class="number">{{$t('history.order')}} #{{currentOrder.id}}</span>
              <span class="method">{{$t(currentOrder.deliveryMethod)}}</span>
            </template>
            <template v-if="currentTransaction">
              <span class="number" v-if="currentTransaction.type === 'sale'">
                {{currentTransaction.moreData && currentTransaction.moreData.note === 'scan' ? $t('history.scan') : $t('history.order') + " #" + currentTransaction.sale}}
              </span>
              <span class="number" v-if="currentTransaction.type !== 'sale'">{{$t('transaction_history.' + currentTransaction.type)}}</span>
            </template>
          </div>
          <div class="close" @click="closeModal">
            <svgicon class="icon" src="icons/btn-delete.svg" :height="24" :width="24"></svgicon>
          </div>
        </div>

        <div class="modal-body">

          <div class="top">
            <div class="info" v-if="hasUserManagementActivated && (currentOrder || (currentTransaction && currentTransaction.createdBy))">
              <label>{{$t('loyalty.username')}}</label>
              <div class="value" v-if="currentOrder">{{currentOrder.user}}</div>
              <div class="value" v-if="currentTransaction && currentTransaction.createdBy">{{currentTransaction.createdBy.email}}</div>
            </div>
            <div class="info">
              <label>{{$t('transaction_history.date')}}</label>
              <div class="value" v-if="currentTransaction && currentTransaction.createdAt">{{ getDate(currentTransaction.createdAt) + ' ' + $t('at') + ' ' + getHour(currentTransaction.createdAt) }}</div>
              <div class="value" v-if="currentOrder && currentOrder.createdAt">{{ getDate(currentOrder.createdAt) + ' ' + $t('at') + ' ' + getHour(currentOrder.createdAt) }}</div>
            </div>
            <div class="info" v-if="currentOrder && currentOrder.inAdvanceFor">
              <label>{{$t('history.order_completed_at')}}</label>
              <div class="value">{{ getDate(currentOrder.inAdvanceFor) + ' ' + $t('at') + ' ' + getHour(currentOrder.inAdvanceFor) }}</div>
            </div>
            <div class="company" v-if="currentOrder && branch.getCompanyByCanonical(currentOrder.company)">
              <div class="info">
                <label>{{$t('history.company')}}</label>
                <div class="value">{{ branch.getCompanyByCanonical(currentOrder.company).name }}</div>
              </div>
            </div>
            <div class="promo" v-if="currentTransaction && currentTransaction.moreData && currentTransaction.moreData.promotion">
              <div class="info">
                <label>{{$t('loyalty.promotion')}}</label>
                <div class="value">{{ currentTransaction.moreData.promotion.name }}</div>
              </div>
            </div>
            <div class="pos-failed" v-if="company && company.shouldNotifyCustomerOnPosFailureWhenPosBypass() && currentOrder && currentOrder.posFailed">
              <svgicon src="icons/warning-red.svg" :height="20" :width="20"></svgicon>
              <div class="text">
                <div v-html="$t('payment.not_received_description')"></div>
                <a v-if="company.phoneNumber" :href="'tel:' + company.phoneNumber">{{company.phoneNumber}}</a>
              </div>
            </div>
          </div>
          <div class="separator" v-if="currentOrder"></div>
          <div class="sale-items" v-if="currentOrder">
            <div class="item" v-for="(item, itemIndex) of currentOrder.details" :key="itemIndex">
              <div class="title" >
                <span class="label">{{item.name}}</span>
                <span class="value">{{$tc(item.unitPrice.toFixed(2))}}</span>
              </div>
              <div class="combo" v-for="combo of item.combos">
                <span class="label">{{combo.name}}</span>
                <span class="value" v-if="combo.unitPrice > 0">
                  {{ combo.unitPrice > 0 ? '+' : '-' }} {{$tc((combo.unitPrice * combo.quantity).toFixed(2))}}
                </span>
              </div>
              <div class="modifier" v-for="modifier of item.modifiers">
                <span class="label">
                  {{modifier.name}}
                  <template v-if="modifier.quantity > 1">({{modifier.quantity}})</template>
                </span>
                <span class="value">
                  {{ modifier.unitPrice > 0 ? '+' : '-' }} {{$tc((modifier.unitPrice * modifier.quantity).toFixed(2))}}
                </span>
              </div>
              <div class="quantity">
                <span class="label">{{$t('quantity')}}</span>
                <span class="value">{{item.quantity}}</span>
              </div>
            </div>
          </div>

          <template v-if="currentOrder && currentOrder.codes && currentOrder.codes.length > 0">
            <template v-for="code of currentOrder.codes">
              <div class="separator"></div>
              <QrCode class="qr" :data="code.code" :name="getDetailName(code.detail)"></QrCode>
            </template>
          </template>

          <div class="separator"></div>

          <div class="totals">
            <div class="subtotal" v-if="currentOrder && currentOrder.subTotal">
              <span class="label">{{$t('total.subtotal')}}</span>
              <span class="value">{{$tc(currentOrder.subTotal.toFixed(2))}}</span>
            </div>
            <div class="tip" v-if="currentOrder && currentOrder.tipTotal">
              <span class="label">{{$t('total.tip')}}</span>
              <span class="value">{{$tc(currentOrder.tipTotal.toFixed(2))}}</span>
            </div>
            <div class="total">
              <span class="label">{{$t('total.total')}}</span>
              <span class="value" v-if="currentTransaction && currentTransaction.money">{{$tc(currentTransaction.money.toFixed(2))}}</span>
              <span class="value" v-if="currentOrder && currentOrder.total">{{$tc(currentOrder.total.toFixed(2))}}</span>
            </div>
          </div>

          <div class="separator" v-if="currentTransaction && currentTransaction.points"></div>

          <div class="loyalty" v-if="currentTransaction && currentTransaction.points">
            <div class="points">
              <span class="label">{{$t('history.points_gained')}}</span>
              <span class="value">{{currentTransaction.points}}</span>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn--back" @click="closeModal" v-if="!$store.getters.isMobileOrTablet">
            {{$t('back')}}
          </button>
          <button type="button" class="btn btn-primary" v-if="currentOrder && branch && branch.isReorderFromHistoryActivated()" @click="reorder">
            <svgicon src="icons/add-circle.svg" class="icon" :height="20" :width="20"></svgicon>
            {{$t('reorder')}}
          </button>
          <template v-if="currentOrder && currentOrder.trackingUrl">
            <ExternalLink class="btn btn-primary tracking__button" :url="currentOrder.trackingUrl">
              <svgicon src="icons/track.svg" class="icon" :height="20" :width="18"></svgicon>
              {{ $t("tracking.button") }}
            </ExternalLink>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import Transaction from "../../models/TransactionModel";
  import moment from "moment-timezone";
  import QrCode from "@/components/tools/QrCode";
  import Sale from "../../models/SaleModel";
  import ExternalLink from "../tools/ExternalLink";

  export default {
    name: "Transactions",
    mounted() {
      this.loadTransactions();
    },

    components: { QrCode, ExternalLink },

    props: {
      accountId: Number
    },

    data() {
      return {
        transactions: [],
        sales: [],
        displayLoyalty: false,
        totalItems: null,
        currentTransaction: null,
        userId: null,
        selectedDate: null,
        selectedType: "",
        page: 1,
        perPage: 10,
        currentOrder: null
      }
    },

    watch: {
      displayLoyalty() {
        this.loadTransactions();
      },
      page() {
        this.loadTransactions();
      },
      userId() {
        this.loadTransactions();
      },
      accountId() {
        this.loadTransactions();
      },
      selectedType() {
        this.loadTransactions();
      },
      selectedDate() {
        this.loadTransactions();
      }
    },

    computed: {
      pages() {
        let counter = 0;
        let pages = [];
        let begin_index = 1;
        if (this.maxPages - this.page < 4) {
          begin_index = this.maxPages - 4;
        } else {
          begin_index = (this.page - 2 < 1 ? 1 : this.page - 2);
        }
        if (begin_index < 1) {
          begin_index = 1;
        }
        for (let i = begin_index; counter < 5 && i <= this.maxPages; i++) {
          pages.push(i);
          counter++;
        }
        return pages;
      },

      branch() {
        return this.$store.state.currentBranch;
      },

      company() {
        return this.$store.state.currentCompany;
      },

      hasEWalletActivated() {
        if (!this.$store.state.currentCompany) {
          return false;
        }
        return this.$store.state.currentCompany.hasEWalletActivated();
      },

      hasUserManagementActivated() {
        if (!this.branch) {
          return false;
        }
        return this.branch.userManagementEnabled;
      },

      maxPages() {
        return Math.max(Math.ceil(this.totalItems / this.perPage), 1);
      },

      maxDate() {
        return moment().toISOString();
      },

      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d yyyy";
        }
        return "d MMMM yyyy";
      },

    },

    methods: {
      ...mapActions({
        getTransactionsAction: "getTransactions",
        getTransactionDetailsAction: "getTransactionDetails",
      }),

      getDetailName(detailId) {
        if (!this.currentOrder) {
          return "";
        }
        let detail = this.currentOrder.details.find(d => d.id == detailId);
        if (detail) {
          return detail.name;
        }
        return "";
      },

      async loadTransactions() {
        showSpinner();
        if (this.displayLoyalty) {
          this.sales = [];
          let account = this.$user.getLoyaltyAccount();
          let response = await this.getTransactionsAction({
            user: this.userId || undefined,
            account: account ? account.id : undefined,
            type: this.selectedType,
            date: this.selectedDate,
            page: this.page,
            perPage: this.perPage
          });
          if (response.success) {
            this.transactions = response.transactions.data.map(t =>  new Transaction(t));
            this.totalItems = response.transactions.meta.totalItems;
          }
        } else {
          this.transactions = [];
          let response = await this.$user.getHistory({
            'user_id': this.userId || undefined,
            'add_managed': !this.userId,
            date: this.selectedDate,
            page: this.page,
            perPage: this.perPage,
          });
          if (response) {
            this.sales = response.data;
            this.totalItems = response.count;
          }
        }
        hideSpinner();
      },

      reorder() {
        if (!this.currentOrder) {
          return;
        }
        this.$order.reorderFromSale(this.currentOrder);
        this.closeModal();
        showAffirmation(this.$t("added_to_cart"), this.$t("added_to_cart_description"), this.$t("thank_you"), false, true);
      },

      getUserDisplayName(user) {
        if (!user) {
          return '';
        }
        if (user.firstName || user.lastName) {
          return `${user.firstName} ${user.lastName}`.trim()
        } else if (user.email) {
          return user.email
        } else {
          return `#${user.id}`
        }
      },

      clearDate() {
        this.selectedDate = null;
      },

      async showDetails(id) {
        showSpinner();
        let response = await this.getTransactionDetailsAction({id: id});
        if (response) {
          this.currentTransaction = new Transaction(response.details);
        }
        this.currentOrder = null;
        document.getElementById("transactionDetails").classList.add("show");
        hideSpinner();
      },

      showSale(sale) {
        let details = [];
        let index = -1;
        for (let detail of sale.details) {
          switch(detail.type) {
            case "item":
            case "variant":
            case "custom":
              detail.modifiers = detail.modifiers || [];
              detail.combos = detail.combos || [];
              details.push(detail);
              index++;
              break;
            case "modifier":
              details[index].modifiers.push(detail);
              break;
            case "combo":
              details[index].combos.push(detail);
              break;
          }
        }
        sale.details = details;
        this.currentOrder = sale;
        this.currentTransaction = null;
        document.getElementById("transactionDetails").classList.add("show");
      },

      closeModal() {
        document.getElementById("transactionDetails").classList.remove("show");
      },

      getDate(date) {
        return moment(date.split(" ")[0]).format("D MMMM YYYY");
      },

      getHour(date) {
        let hour = date.split(" ")[1];
        return hour.substring(0, hour.length - 3);
      },

      formatPrice(amount) {
        return amount < 0 ? -amount.toFixed(2) : amount.toFixed(2);
      },

      nextPage() {
        this.page = (this.page === this.maxPages ? this.page : this.page + 1);
      },

      previousPage() {
        this.page = (this.page === 1 ? this.page : this.page - 1);
      },
    }
  }
</script>

<style lang="scss" scoped>

  #transactions {
    padding: 15px;
  }

  #iShopFoodApp.xs {
    border-top: none;
    border-bottom: none;
    .page-content {
      padding: 0;

      &:after {
        height: 0;
      }
    }
  }
  .date.select {
    font-weight: normal;
    display: flex;
    align-items: center;
    padding: 10px 28px 10px 10px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    max-width: 230px;

    .arrow {
      position: absolute;
      right: 8px;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    label {
      display: block;
      font-size: 14px;
      color: #808080;
      padding-bottom: 8px;
      padding-left: 8px;
    }
  }

  #transactions {
    .search {
      display: flex;
      margin-bottom: 30px;

      .type-search {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;
        padding-right: 20px;
        flex-shrink: 1;
        max-width: 230px;

        select {
          font-size: 16px;
          min-width: 130px;
          line-height: 1.6em;
        }
      }

      .date-search {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;
        padding-left: 10px;
        flex-shrink: 1;
        max-width: 250px;

        .date {
          height: 40px;
          font-size: 16px;
          min-width: 130px;
        }
      }
    }

    &.mobile {
      padding: 0;

      .search {
        display: block;
        margin-bottom: 10px;

        label {
          font-size: 12px;
        }

        .arrow {
          position: absolute;
          right: 24px;
        }

        .type-search {
          padding-right: 10px;
          flex-grow: 1;
          max-width: 100%;
        }

        .date-search {
          padding-left: 0;
          flex-grow: 1;
          max-width: 100%;
        }

        .date.select {
          width: 100%;
          min-width: 100%;
        }

        .input {
          padding-top: 0;
        }
      }
    }


  }



  /* = = = = = = = = = = */
  /*        TABLE        */
  /* = = = = = = = = = = */

  table.transactions {
    width: 100%;

    .table-head > td {
      background-color: #e5e5e5;
      font-weight: bold;
    }

    .transaction {
      height: 40px;


      .bottom {
        border-bottom: 2px solid black;
      }

      &.mobile {
        border-bottom: 5px solid #f5f5f5;

        .left, .right {
          display: flex;
          flex-direction: column;
        }

        .left {
           padding-left: 10px;
        }
        .right, .details {
          padding-right: 15px;
        }

        .price {
          padding-right: 0;
        }

        .details {
          padding-right: 10px;
        }

        .number::after, .date::after, .hour:after {
          content: none;
        }

        .number {
          font-weight: bold;
          padding-left: 0;
        }

        .favorite {
          padding: 6px;
        }

        .details {
          min-width: 36px;
          width: 1px;
          fill: var(--primary-color);
        }

        td {
          font-size: 14px;
          padding: 10px 0;
        }

        &:not(:last-child) {
          border-bottom: 5px solid #f5f5f5;
        }
      }

      &.desktop {
        .number {
          min-width: 150px;
        }

        .type {
          width: 125px;
        }

        .date {
          width: 200px;
        }

        .price {
          min-width: 100px;
        }

        .details {
          width: 80px;
        }
      }
        &:nth-child(odd) {
        }


      td {
        color: #404040;
      }

      &.selected {
        .favorite {
        }
      }

      .favorite {
        cursor: pointer;
        fill: #ccc;
      }

      .number {
        padding-left: 8px;
      }

      .number, .hour {
        white-space: nowrap;
        width: 1%;
        padding-right: 20px;
        position: relative;
      }

      .has-type::after {
        content: '|';
        position: absolute;
        right: 8px;
        color: inherit;
        opacity: 0.4;
      }

      .type, .hour, .status {
        padding-left: 0;
      }

      .time-sepa {
        padding-left: 0;
        width: 1px;
      }

      .details {
        padding-right: 8px;
      }
      .method {
        text-align: right;
      }
      .price {
        font-weight: bold;
        text-align: right;
        min-width: 80px;
        position: relative;
        white-space: nowrap;
        padding-right: 15px;

        .price-content {
          width: 100px
        }
      }

      .details {
        width: 80px;

        .detail-content {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .paging {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .svgicon {
      cursor: pointer;

      &:hover {
        fill: var(--primary-color);
        fill: var(--primary-color);
      }
    }

    ul {
      margin: 0;
      margin-top: 4px;
      padding: 0;

      li {
        display: inline;
        padding: 8px 12px;
        cursor: pointer;
        border: 2px solid transparent;

        &.current-page {
          border: 2px solid var(--primary-color);
          border-radius: 5px;
        }

        &:hover {
          opacity: 0.5;
        }

        &.current-page:hover {
          opacity: 1;
        }
      }
    }
  }

  #transactionDetails {
    padding-top: 20px;
    padding-bottom: 20px;
    .modal-content {
      max-width: 540px !important;
      overflow-y: auto;
      max-height: 100%;
    }

    .modal-header {
      font-size: 16px;
      display: flex;
      fill: #ccc;

      .title {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        .number {
          padding-right: 5px
        }
        .method {
          align-self: flex-end;

          &:before {
            content: "|";
            margin-right: 5px;
            opacity: 0.5;
            color: #bfbfbf;
          }
        }
      }

      .number {
        font-weight: bold;
        flex-shrink: 0;
        font-size: 18px;
      }

      .method {
        font-size: 18px;
      }

      .icon {
        margin-right: 15px;
      }

      .close {
        cursor: pointer;
        align-self: flex-start;
        .svgicon {
          margin-top: -4px;
          margin-right: -4px;
        }
      }
    }

    @media screen and (max-width: 620px) {
      .modal-header {
        .number {
          font-size: 16px;
        }
        .method {
          font-size: 14px;
        }
      }
      .modal-body .separator {
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
      .info {
        display: flex;
        flex-direction: column;
        .value {
          text-align: left !important;
          padding-left: 4px;
        }
      }
      .item {
        .label, .value {
          font-size: 16px;
        }
        .quantity {
          .label, .value {
            font-weight: normal;
          }
        }
      }
      .totals {
        .subtotal, .discount {
          .label, .value {
            font-size: 16px;
          }
        }
        .total {
          .label, .value {
            font-size: 20px;
          }
        }
      }
    }

    .info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 16px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px dashed #e6e6e6;

      .value {
        text-align: right;
      }

      label {
        flex-shrink: 0;
        margin-right: 5px;
      }
    }

    .modal-footer {
      display: flex;
      .btn {
        width: auto !important;
        &:not(:first-child) {
          margin-left: 10px;
        }
        .icon {
          margin-right: 10px;
        }
      }
      .btn--back {
        margin-right: auto;
      }
    }

    .modal-body {
      .top {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          padding-left: 4px;
          padding-bottom: 4px;
          color: #808080;
        }
      }
    }

    .sale-items {
      overflow-y: auto;
      max-height: 250px;
      border: 1px solid #e6e6e6;
      padding: 15px;
      border-radius: 5px;
    }

    .separator {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
    }

    .item {
      border-bottom: 1px solid #e6e6e6;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }

    .item, .totals, .loyalty {
      padding: 15px 0;
      margin-bottom: 10px;

      &:not(.item) {
        border-bottom: 1px dashed #e6e6e6;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      .title {
        border-bottom: 1px dashed #e6e6e6;
      }

      .title, .subtotal, .fidelity-card {
        font-weight: bold;
        padding-bottom: 5px;
        font-size: 16px;

        &.noBorder {
          border-bottom: 0;
          padding-bottom: 0;
        }

        .label, .value {
          color: #1a1a1a;
        }
      }

      .total {
        padding-top: 5px;
        margin-top: 5px;
        font-weight: bold;
        color: #1a1a1a;
        font-size: 18px;
      }

      .title, .combo, .modifier, .quantity, .subtotal, .tax, .total, .fidelity-card, .points, .fee, .tip {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .combo, .modifier, .tax {
        margin-bottom: 2px;
      }

      .label {
        padding-right: 20px;
      }

      .value {
        font-weight: bold;
      }

      .fee, .tip {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px dashed #e6e6e6;
      }

      .combo, .modifier, .quantity, .tax, .points, .fee, .tip {
        font-size: 16px;

        .label, .value {
          color: #808080;
        }
      }

      .fidelity-card {
        font-size: 18px;
      }

      .quantity {
        font-weight: normal;
        padding-top: 5px;
        margin-top: 5px;
      }
    }
  }

  .pos-failed {
    font-size: 14px;
    display: flex;
    align-items: center;

    .svgicon {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
</style>

<style lang="scss">

  #iShopFoodApp #transactions {
    .vdatetime input {
      background-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      margin-top: -1px;
      width: 220px;
      padding: 10px 28px 10px 36px;
      margin-left: -26px;
      cursor: pointer;
    }

    .mobile {
      .vdatetime input {
        width: calc(100% + 16px);
      }
      .selected {
        width: 100%;
        max-width: 100%;
      }
    }

    .date.select {
      padding: 0;
      margin-right: 10px;
      .selected {
        padding: 0;
      }
      .arrow, .select-icon {
        pointer-events: none;
      }

      .select-icon {
        margin-left: 10px;
      }

      .arrow {
        position: absolute;
        right: 28px;
      }

      .clear-date {
        position: absolute;
        right: 24px;
        .svgicon {
          fill: var(--primary-color);
        }
      }
    }
  }
</style>
