<template>
  <div class="locale-switch" v-if="kioskConfiguration.locales.fr && kioskConfiguration.locales.en">
    <div class="locale" v-for="locale in $ts.getters.getLocales" :key="locale"
         v-if="kioskConfiguration.locales[locale]"
         :class="{active: $ts.state.locale == locale}" @click.stop="setLocale(locale)" @contextmenu.prevent="setLocale(locale)">
      {{ $t(locale) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "kioskLocaleSwitch",

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      }
    },

    methods: {
      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },
    }
  }
</script>

<style lang="scss" scoped>

  .horizontal .locale-switch {
    .locale {
      height: 50px;
    }
  }

.locale-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .locale {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 25px;
    font-size: 18px;
    cursor: pointer;

    &:not(:last-child) {
      padding-right: 0;
      &:after {
        content: "|";
        padding-left: 25px;
        opacity: 0.7;
      }
    }
    &.active {
      font-weight: bold;
    }
  }
}
</style>
