<template>
  <div id="mobileNavigation" ref="menu" :class="{
      lg: $store.state.screenSize == 'lg',
      md: $store.state.screenSize == 'md',
      quickMode: $store.state.quickMode,
      shown: $store.state.mobileMenuShown,
      initialHide: !$store.state.initialLoading,
      progressBarFooter: canDisplayFooter && showProgressBar && showMobileFooter,
      progressBar: canDisplayFooter && showProgressBar && !showMobileFooter,
      mobileFooter: canDisplayFooter && !showProgressBar && showMobileFooter
    }">
    <div class="content">
      <div class="logo"></div>
      <div class="text" v-if="company && company.getEnterMessage('default') && $to(company.getEnterMessage('default')).length > 0">
        {{ $to(company.getEnterMessage('default')) }}
      </div>
      <div class="text" v-if="company && company.getEnterMessage($order.method) && $to(company.getEnterMessage($order.method)).length > 0">
        {{ $to(company.getEnterMessage($order.method)) }}
      </div>
      <div class="menu-items" v-if="company">
        <template v-if="$store.state.ticketMode">
          <router-link :to="{name: 'tickets'}" class="item" @click.native="closeNavigation">
            <svgicon src="icons/ticket.svg" :height="24" :width="24"></svgicon>
            <span class="link-name">{{$t('tickets.title')}}</span>
          </router-link>
        </template>
        <template v-if="!$store.state.ticketMode">
          <router-link :to="{name: 'menu'}" class="item" @click.native="closeNavigation">
            <svgicon src="icons/menu.svg" :height="24" :width="24"></svgicon>
            <span class="link-name">{{$t('navigation.menu')}}</span>
          </router-link>
          <div class="item" v-if="$store.state.displayMode && company && branch && !branch.allCompaniesHaveMenuDisplay()" @click="quitDisplayMode">
            <svgicon src="icons/menu-card.svg" :height="24" :width="24"></svgicon>
            <span class="link-name">{{$t('quit_display_mode')}}</span>
          </div>
          <div class="item" v-if="!$store.state.displayMode && branch && branch.getCompaniesWithMenuDisplay().length > 0"
                       @click="enterDisplayMode">
            <svgicon src="icons/menu-card.svg" :height="24" :width="24"></svgicon>
            <span class="link-name">{{$t('enter_display_mode')}}</span>
          </div>
        </template>
        <router-link :to="{name: 'promotions'}" class="item" @click.native="closeNavigation" v-if="PromotionManager.hasPromotion()">
          <svgicon src="icons/menu.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.promos')}}</span>
        </router-link>
        <router-link :to="{name: 'companies'}" class="item" @click.native="closeNavigation"
                     v-if="branch && !$store.state.quickMode && branch.companies.length > 1 &&
                          !$store.state.displayMode && !branch.shouldReplaceCompaniesLinkByOrderTypeLink()">
          <svgicon src="icons/branches.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.branches')}}</span>
        </router-link>
        <router-link :to="{name: 'orderType', query: { skippedOrderType: 'true' }}"  class="item" @click.native="closeNavigation"
                     v-if="branch && branch.shouldReplaceCompaniesLinkByOrderTypeLink() && !$store.state.ticketMode">
          <svgicon src="icons/branches.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.order_options')}}</span>
        </router-link>
        <router-link :to="{name: 'company'}" class="item" @click.native="closeNavigation">
          <svgicon src="icons/business.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.business')}}</span>
        </router-link>
        <router-link :to="{name: 'profile'}" class="item" @click.native="closeNavigation"
                     v-if="$user.token && !$user.isDispatchUser() && !$user.isAnonymous() && !hasHub">
          <svgicon src="icons/smiley.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.profile')}}</span>
        </router-link>
        <router-link :to="{name: 'transactionHistory'}" class="item" @click.native="closeNavigation"
                     v-if="$user.token">
          <svgicon src="icons/bill.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('transaction_history.title')}}</span>
        </router-link>
        <template v-if="!$store.state.ticketMode">
          <router-link :to="{name: 'dispatch'}" class="item" @click.native="closeNavigation"
                       v-if="$user.token && $user.isDispatchUser()">
            <svgicon src="icons/dispatch.svg" :height="24" :width="20"></svgicon>
            <span class="link-name">{{$t('dispatch.title')}}</span>
          </router-link>
          <router-link :to="{name: 'hub'}" class="item" @click.native="closeNavigation"
                       v-if="$user.token && hasHub">
            <svgicon src="icons/wallet.svg" :height="24" :width="24"></svgicon>
            <span class="link-name">{{$t('loyalty.hub')}}</span>
          </router-link>
        </template>
        <!--
        <router-link :to="{name: 'history'}" class="item" @click.native="closeNavigation" v-if="$user.token">
          <svgicon src="icons/bill.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.history')}}</span>
        </router-link> -->
        <div class="item language" v-if="$store.state.inventory && !$store.state.inventory.isEnglishLocaleOnly()">
          <svgicon src="icons/language.svg" :height="24" :width="24"></svgicon>
          <span v-for="locale in $ts.getters.getLocales" :key="locale"  class="link-name"
                :class="{active: $ts.state.locale == locale}" @click="setLocale(locale)">
            {{ $t(locale) }}
          </span>
        </div>
        <div class="item" @click.exact="replayTutorial" v-if="hasTutorial">
          <svgicon src="icons/sugar.svg" :height="24" :width="30"></svgicon>
          <span class="link-name">{{$t('tutorial')}}</span>
        </div>
        <div class="item" @click.exact="login" v-if="!$user.token">
          <svgicon src="icons/smiley.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('login.login')}}</span>
        </div>
        <ExternalLink :url="link.url" class="item" v-for="link of company.externalLinks" target="_blank" :key="link.url">
          <svgicon src="icons/globe.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{ $to(link.title) }}</span>
        </ExternalLink>
        <div class="item" @click.exact="logout" v-if="$user.token">
          <svgicon src="icons/logout.svg" :height="24" :width="24"></svgicon>
          <span class="link-name">{{$t('navigation.logout')}}</span>
        </div>
      </div>

      <div class="grow"></div>

      <div class="legal-links">
        <ExternalLink class="legal-link" :url="$t('register.terms_url')" @click.native="closeNavigation">{{ $t('terms') }}</ExternalLink>
        <router-link class="legal-link" :to="{name: 'cookiesPolicy'}" @click.native="closeNavigation">{{ $t('title.cookiesPolicy') }}</router-link>
      </div>

      <div class="legal-links">
        <div class="legal-link">© {{ new Date().getFullYear() }} iShopFood</div>
        <div class="legal-link" v-if="CONFIG && CONFIG.version">Version {{CONFIG.version}}</div>
      </div>
    </div>
  </div>
</template>

<script>

  import EventBus from "@/lib/eventBus.js";
  import ExternalLink from "@/components/tools/ExternalLink.vue";
  import { PromotionManager } from "../PromotionManager";

  export default {

    components: { ExternalLink },

    data() {
      return {
        PromotionManager,
        CONFIG
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      style() {
        if (!this.branch) {
          return null;
        }
        return this.branch.style;
      },
      showMobileFooter() {
        if (!this.branch || !this.style) {
          return false;
        }
        return this.style.showMobileFooter;
      },
      showProgressBar() {
        if (!this.branch || !this.style || !this.style.fidelity || !this.$user.token || !this.isRankEnabled) {
          return false;
        }
        return this.style.fidelity.displayFooterProgressBar;
      },
      isRankEnabled() {
        if (!this.branch || !this.branch.loyaltyConfig) {
          return false;
        }
        return this.branch.loyaltyConfig.data.rankEnabled;
      },
      canDisplayFooter() {
        return !this.isDesktop && !this.isTutorial && this.$store.state.currentCompany;
      },
      isDesktop() {
        return this.$store.state.screenSize === 'md' || this.$store.state.screenSize === 'lg';
      },
      isTutorial() {
        return this.$route.name === "tutorial" || this.$route.name === "tutorialWithCompany";
      },
      hasHub() {
        return this.branch && this.branch.loyaltyConfig && this.branch.loyaltyConfig.type == 'ishopfood' && this.branch.eWalletActivated && !this.$store.state.ticketMode;
      },
      hasTutorial() {
        return this.branch && this.branch.canDisplayTutorial();
      }

    },

    methods: {
      setLocale(locale) {
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },
      enterDisplayMode() {
        EventBus.$emit("menu-display-locations-modal");
        this.closeNavigation();
      },
      quitDisplayMode() {
        this.$store.state.displayMode = false;
        this.$order.type = null;
        this.$router.push(this.$router.generate("/orderType"));
        this.closeNavigation();
      },
      login() {
        let redirect = this.$route.name;
        this.$router.push(this.$router.generate("/login?redirect=" + redirect));
        this.closeNavigation();
      },
      logout() {
        this.$user.token = null;
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
        this.$router.push(this.$router.generate("/login?redirect=menu"));
        this.closeNavigation();
      },
      replayTutorial() {
        setConfiguration("tutorial-completed", false);
        this.$router.push(this.$router.generateFirstPage());
        this.closeNavigation();
      },
      closeNavigation(e) {
        setTimeout(() => {
          this.$store.state.mobileMenuShown = false;
        }, 0);
      }
    }
  }

</script>

<style lang="scss" scoped>

  #mobileNavigation {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-image: linear-gradient(to bottom, rgb(252, 102, 80), rgb(237, 41, 55));
    transition: opacity 0.5s ease-out 1s;

    &.shown {
      display: block;
    }
    &.lg, &.md.quickMode {
      display: none;
    }
    &.progressBar {
      margin-bottom: 40px;
    }
    &.mobileFooter {
      margin-bottom: 65px;
    }
    &.progressBarFooter {
      margin-bottom: 105px;
    }
  }

  .legal-links {
    display: flex;
    padding-top: 10px;
    padding-left: 20px;

    .legal-link {
      font-size: 12px;
      text-decoration: none;
      line-height: 1;
      cursor: pointer;
      opacity: 0.6;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .logo {
    background-image: url(../assets/img/ishopfood-logo-vertical.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 170px;
  }
  .text {
    font-size: 14px;
    line-height: 16px;
    color: white;
    padding: 5px 30px 25px 30px;
    text-align: center;
  }

  .menu-items {
    margin: 0;
    padding: 0;
    flex-grow: 1;
    list-style: none;

    .router-link-active + .item {
      border-top: 1px solid rgb(212, 36, 48);
    }

    .item {
      display: flex;
      align-items: center;
      text-align: left;
      height: 55px;
      padding-left: 30px;
      fill: white;
      border-top: 1px dashed rgb(212, 36, 48);
      cursor: pointer;
      text-decoration: none;

      &:last-child {
        border-bottom: 1px dashed rgb(212, 36, 48);
      }

      .link-name {
        color: white;
        font-size: 16px;
        padding-left: 12px;
      }

      &.router-link-active {
        background-color: rgb(212, 36, 48);
        fill: rgb(237, 134, 115);
        border-top: 1px solid rgb(212, 36, 48);

        .link-name {
          font-weight: 500;
        }
      }
    }
  }

  .grow {
    flex-grow: 1;
  }

  .language {
    .link-name:not(:last-child) {
      position: relative;
      padding-right: 10px;
    }
    .active {
      font-weight: bold;
    }
  }

</style>
