/**
 * @typedef LoyaltyAccountDefinition
 * @property {number} id
 * @property {string} description
 * @property {string} defaultNumber
 * @property {number} balance
 * @property {number} points
 * @property {boolean} autoRecharge
 * @property {Object} autoRechargeConfig
 * @property {("manage"|"consume")[]} permissions
 */

export default class LoyaltyAccount {

  /**
   * @param {LoyaltyAccountDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.description = definition.description;
    this.defaultNumber = definition.defaultNumber;
    this.balance = definition.balance;
    this.points = definition.points;
    this.autoRecharge = definition.autoRecharge;
    this.autoRechargeConfig = definition.autoRechargeConfig;
    this.permissions = definition.permissions;
    this.billingTokens = definition.billingTokens;
    this.user = definition.owner; /* deprecated */
    this.owner = definition.owner;
    this.cards = definition.cards;
  }

  canManage() {
    return this.permissions.indexOf("manage") > -1;
  }

  canPay() {
    return this.permissions.indexOf("consume") > -1;
  }

  getSavedBillingTokensForAccount(account) {
    return this.billingTokens ? this.billingTokens.filter(c => c.account === account) : [];
  }

}
