<template>
  <div id="posCrossSell">
    <transition-group :name="getTransition" class="pos-cross-Sell">
      <div class="cross-sell__item" v-for="item of items" @click="selectItem(item.item)" :key="item.item.id">
        <div class="item__image" v-if="!noImage && item.item.image">
          <AsyncImage class="image" :image="urlServer + item.item.image"></AsyncImage>
        </div>
        <div class="item__content">
          <div class="item__name">{{$t(item.item.name)}}</div>
          <div class="item__description">{{$t(item.description)}}</div>
        </div>
        <div class="posPlus">
          <svgicon src="icons/plus.svg" :height="25" :width="25"></svgicon>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import AsyncImage from "@/components/tools/AsyncImage.vue";
  import {Constant} from "@/util/Constant";

  export default {
    name: "posCrossSell",

    components: { AsyncImage },

    data() {
      return {
        baseItemId: null,
        baseItem: null,
        items: [],
        fromCategoryId: null
      };
    },

    async mounted() {
      this.$store.commit("showNavigationCart", true);
      if (this.$route.params.id) {
        if (this.$route.params.id.indexOf("-") > -1) {
          let ids = this.$route.params.id.split("-");
          this.fromCategoryId = ids[0];
          this.baseItemId = ids[ids.length - 1];
        } else {
          this.baseItemId = this.$route.params.id;
        }
      }
      await this.initializeBaseItem();
      await this.loadCrossSellItems();
    },

    computed: {
      inventory() {
        return this.$store.state.inventory;
      },
      urlServer() {
        return CONFIG.urlFileServer;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      getTransition() {
        if (this.posConfiguration && !this.posConfiguration.animated) {
          return "";
        }
        return "fade";
      }
    },

    methods: {
      ...mapActions({
        getItemByIdAction: "getItemById"
      }),

      async initializeBaseItem() {
        this.baseItem = null;
        let item = await this.getItemByIdAction(this.baseItemId);
        if (!item) {
          this.$router.push(this.$router.generate("/menu"));
          return;
        }
        this.baseItem = item;
      },

      async loadCrossSellItems() {
        //interval to retry if base item isnt loaded or inventory not accessible?
        if (!this.baseItem) {
          return;
        }
        let itemsToGet = this.baseItem.getCrossSells();
        if (!itemsToGet || itemsToGet.length == 0) {
          this.$router.push(this.$router.generate("/menu"));
          return;
        }

        for (let item of itemsToGet) {
          let itemToSell = await this.getItemByIdAction(item.item);
          //TODO validate which condition should be met for item to be displayed ex: in stock, available right now, ect..
          if (itemToSell && !itemToSell.outOfStock) {
            let description = item.text[Constant.CROSSSELL_OUT];
            this.items.push({"item": itemToSell, "description": description});
          }
        }
      },

      selectItem(item) {
        this.$router.push(this.$router.generate("/item/" + item.id));
      },
    }
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp {
    #posCrossSell {
      display: flex;
      padding: 30px 20px;

      .pos-cross-Sell {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
      }
      .image {
        border-radius: 10px;
        margin: 0;
        height: 100px;
        width: 100%;
      }
    }

    .cross-sell__item {
      width: 536px;
      height: 120px;
      padding: 10px;
      margin: 10px;
      border-radius: 20px;
      background-color: var(--background-color-2);
      display: flex;
      position: relative;
      overflow: hidden;
      box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .item__image {
      width: 150px;
      height: 100px;
      margin: 0 10px 0 0;
      border-radius: 16px;
    }

    .item__content {
      margin: 0 40px 0 10px;
      max-width: 315px;
      overflow: hidden;

      .item__name {
        font-size: 22px;
        font-weight: 900;
        color: var(--theme-color);
        padding-bottom: 10px;
        width: 305px;
        max-width: 305px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item__description {
        font-size: 16px;
        font-weight: 500;
        color: #808080;
        text-wrap: normal;
        width: 315px;
        max-width: 315px;
        height: 60px;
        max-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .posPlus {
      position: absolute;
      padding: 8px;
      opacity: 1;
      border-radius: 100%;
      top: 10px;
      right: 10px;
      display: flex;
      fill: white;
      background-color: var(--primary-color);
    }
  }
</style>