<template>
  <div id="qr-payment--error" :class="{checkUnavailable, checkReload}">
    <Illustration src="icons/warning-full.svg"></Illustration>

    <div class="error--message">
      <div class="message--title">
        <template v-if="paymentError">
          {{ $t("qr_payment.error_title") }}!
        </template>
        <template v-if="checkUnavailable">
          {{ $t("qr_payment.check_error_title") }}!
        </template>
        <template v-if="checkReload">
          {{ $t("qr_payment.check_reload_title") }}!
        </template>
      </div>
      <div class="separator"></div>
      <template v-if="paymentError">
        <div class="message--subtitle" v-html="$t('qr_payment.error_subtitle')"></div>
        <div class="message--more" v-html="$t('qr_payment.error_subtitle_2')"></div>
      </template>
      <template v-if="checkUnavailable">
        <div class="message--subtitle" v-html="$t('qr_payment.check_error_subtitle')"></div>
        <div class="message--more" v-html="$t('qr_payment.check_error_subtitle_2')"></div>
      </template>
      <template v-if="checkReload">
        <div class="message--subtitle" v-html="$t('qr_payment.check_reload_subtitle')"></div>
        <div class="message--more message--space" v-html="$t('qr_payment.check_reload_subtitle_2')"></div>

        <div class="message--action">
          <button class="btn btn-primary" @click="goToHome">
            {{$t('qr_payment.check_reload_action')}}
          </button>
        </div>
      </template>
    </div>

    <FloatingLocaleSwitcher text displayAll></FloatingLocaleSwitcher>
  </div>
</template>

<script>
  import FloatingLocaleSwitcher from "@/components/tools/FloatingLocaleSwitcher";
  import Illustration from "@/components/modal/Illustration";

  export default {
    name: "qrPaymentError",

    components: {
      FloatingLocaleSwitcher,
      Illustration
    },

    computed: {
      routeName() {
        return this.$route.name;
      },
      paymentError() {
        return this.routeName === "qrPaymentError";
      },
      checkUnavailable() {
        return this.routeName === "qrPaymentUnavailable";
      },
      checkReload() {
        return this.routeName === "qrPaymentCheckReload";
      }
    },

    methods: {
      goToHome() {
        this.$router.push({ path: this.$router.generate("/table"), query: this.$route.query });
      }
    }
  };
</script>

<style lang="scss" scoped>
$error-color: #ef4244;
$warning-color: #feb055;

#iShopFoodApp #qr-payment--error {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  &.checkUnavailable, &.checkReload {
    .separator {
      background-color: $warning-color !important;
    }
  }

  .error--message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    margin-top: 30px;
  }

  .message--title {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 28px;
  }

  .message--subtitle {
    font-size: 18px;
    margin-top: 30px;
  }

  .separator {
    background-color: $error-color !important;
    border-radius: 5px;
    width: 100px;
    height: 4px;
    margin-top: 30px;
  }

  .message--more {
    font-size: 22px;
    margin-top: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .message--space {
    margin-bottom: 40px;
  }
}
</style>

<style lang="scss">
$error-color: #ef4244;
$warning-color: #feb055;

#qr-payment--error .illustration .svgicon {
  fill: $error-color !important;
}

#qr-payment--error.checkUnavailable .illustration .svgicon,
#qr-payment--error.checkReload .illustration .svgicon {
  fill: $warning-color !important;
}

#qr-payment--error .localeTopRight {
  left: 0;
  right: 0;
}
</style>