<template>
  <div class="newmodal__footer" :class="{ centered: !hasSlot }">
    <slot></slot>
    <div class="newmodal__footer__default" v-if="!hasSlot">
      <Button :text="$t('continue')" @click="$emit('continue')"></Button>
    </div>
  </div>
</template>

<script>
  import Button from "./Button.vue";

  export default {
    name: "NewModalFooter",
    components: { Button },
    emits: ["continue"],
    computed: {
      hasSlot() {
        return !!this.$slots.default;
      }
    }
  };
</script>

<style lang="scss" scoped>
.newmodal__footer {
  padding-top: 20px;
  border-top: 1px dashed var(--border-color-4);
  margin-top: 20px;
  display: flex;
  align-items: center;

  &.centered {
    justify-content: center;
  }
}
</style>