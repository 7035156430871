
class Validation {

  /**
   * Validation for phone numbers.
   * --> Must contain at least 10 number chars
   * @param phoneNumber
   * @returns {boolean}
   */
  validatePhoneNumber(phoneNumber) {
    return /^[0-9 \-\\]+$/g.test(phoneNumber) && phoneNumber.replace(/[^0-9]/g, "").length >= 10;
  }

}

let validation = new Validation();
export { validation as Validation };