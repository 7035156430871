import { XposPrinter } from "@/lib/printer/XposPrinter";

export default class PrintFormatter {

    constructor(options) {
        options = options || {};

        const GS = String.fromCharCode(29);
        const ESC = String.fromCharCode(27);
        const SUNMI_INNERPRINTER = "Sunmi InnerPrinter";
        let hasSunmiInnerPrinter = false;

        let _isNodeOrElectron = function() {
            return typeof nw !== "undefined";
        };
        let _isAndroid = function() {
            return typeof cordova !== "undefined";
        };
        let _isSunmi = function() {
            return typeof SunmiInnerPrinter !== "undefined";
        };
        let _getXposUrl = function() {
            return options.xposUrl;
        };

        let _hasCordovaPrint = function() {
            return typeof CordovaPrint !== "undefined";
        };

        if (_isAndroid()) {
            if (_isSunmi()) {
                SunmiInnerPrinter.getPrinterVersion((version) => {
                    hasSunmiInnerPrinter = !!version;
                });
            }
        }

        let CMD = null;
        let CMD_DEFAULT = {
            //INIT: () => String.fromCharCode(27) + "@",
            INIT: () => String.fromCharCode(10) + String.fromCharCode(27) + "t" + String.fromCharCode(2),
            END: () => "",
            LF: () => String.fromCharCode(10),
            CUT: () => {
                if (getConfiguration("alternative-cut-command")) {
                    return String.fromCharCode(10) + String.fromCharCode(28) + String.fromCharCode(80) +
                        String.fromCharCode(4) + String.fromCharCode(1) + String.fromCharCode(69) + String.fromCharCode(5);
                }
                return String.fromCharCode(27) + "i";
            },
            FONT_SIZE: size => String.fromCharCode(29) + "!" + String.fromCharCode(16 * (size - 1) + size - 1),
            BOLD_ON: () => String.fromCharCode(27) + String.fromCharCode(69),
            BOLD_OFF: () => String.fromCharCode(27) + String.fromCharCode(70),
            BAR_CODE: code => {
                let string = "";
                // Print no barcode numbers
                string += String.fromCharCode(29) + "H0";
                // Set barcode height in points
                string += String.fromCharCode(29) + "h" + String.fromCharCode(80);
                // Center printing
                string += CMD.JUSTIFY("center");
                // Print UPC-A barcode
                code = String(code);
                code = "0".repeat(11 - code.length) + code;
                string += String.fromCharCode(29) + "k" + String.fromCharCode(65) + String.fromCharCode(code.length) + code;
                // Left allign printing
                string += CMD.JUSTIFY("left");
                return string;
            },
            QR_CODE: data => {
                let string = "";
                let SIZE = 10;
                // reinitialize printer
                string += ESC + String.fromCharCode(64);
                //Set model type
                string += GS + String.fromCharCode(40) + String.fromCharCode(107) + String.fromCharCode(3) + String.fromCharCode(0) + String.fromCharCode(49) + String.fromCharCode(67) + String.fromCharCode(SIZE);
                //Set QR Code error correction level (ECC)
                let CORRECTION_CONTROL = 50; //Recovery proportion: 48=7%, 49=15%, 50=25%, 51=30%
                string += GS + String.fromCharCode(40) + String.fromCharCode(107) + String.fromCharCode(3) + String.fromCharCode(0) + String.fromCharCode(49) + String.fromCharCode(69) + String.fromCharCode(CORRECTION_CONTROL);
                //Set QR code graphic data
                let stringData = String(data);
                let stringLength = stringData.length + 3;
                string += GS + String.fromCharCode(40) + String.fromCharCode(107) + String.fromCharCode(stringLength) + String.fromCharCode(0) + String.fromCharCode(49) + String.fromCharCode(80) + String.fromCharCode(48) + stringData;
                // Align center
                string += ESC + String.fromCharCode(97) + String.fromCharCode(1);
                // ?? Not sure what this is, not in the user documentation
                string += GS + String.fromCharCode(40) + String.fromCharCode(107) + String.fromCharCode(3) + String.fromCharCode(0) + String.fromCharCode(49) + String.fromCharCode(82) + String.fromCharCode(48);
                //Print QR code graphic
                string += GS + String.fromCharCode(40) + String.fromCharCode(107) + String.fromCharCode(3) + String.fromCharCode(0) + String.fromCharCode(49) + String.fromCharCode(81) + String.fromCharCode(48);
                return string;
            },
            JUSTIFY: align => {
                let alignements = ["left", "center", "right"];
                return ESC + String.fromCharCode(97) + alignements.indexOf(align);
            },
            //https://www.epson-biz.com/modules/ref_escpos/index.php?content_id=107
            BITMAP: (data, height, width) => {
                let string = "";

                string += GS + String.fromCharCode(40) + String.fromCharCode(76) + String.fromCharCode(6) + String.fromCharCode(0) + String.fromCharCode(48) + String.fromCharCode(69);
                string += data;

                //TBD

                return string;
            },
            IMAGE: (code1, code2) => String.fromCharCode(29) + "(L" + String.fromCharCode(6) + String.fromCharCode(0) +
              "0E00" + String.fromCharCode(1).repeat(2),
            // CASH DRAWER CODES LIST http://keyhut.com/popopen4.htm, different for each manufacturer, we've implemented the most common ones
            // Drawer code explanation  https://www.beaglehardware.com/howtoprogramcashdrawer.html
            DRAWER: {
                1: () => String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(0) + String.fromCharCode(25) + String.fromCharCode(250), //1st most common
                2: () => String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(48) + String.fromCharCode(55) + String.fromCharCode(121), //2nd most common
                3: () => String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(0) + String.fromCharCode(50) + String.fromCharCode(121), //3rd most common
                4: () => String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(0) + String.fromCharCode(64) + String.fromCharCode(240), //4th more common
                5: () => String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(0) + String.fromCharCode(100) + String.fromCharCode(250) //5th more common
            }
        };
        let CMD_STAR = {
            INIT: () => String.fromCharCode(27) + "@",
            END: () => "",
            LF: () => String.fromCharCode(10),
            CUT: () => String.fromCharCode(27) + "d" + String.fromCharCode(48),
            FONT_SIZE: size => String.fromCharCode(27) + "i" + String.fromCharCode(48 + size - 1) + String.fromCharCode(48 + size - 1),
            BAR_CODE: code => console.log("Cannot print barcode with Star driver"),
            JUSTIFY: align => console.log("Cannot justify text with Star driver"),
            IMAGE: (x, y, data) => console.log("Cannot print images with Star driver"),
            DRAWER: () => console.log("Cannot open drawer with Star driver")
        };
        let CMD_ZEBRA = {
            INIT: () => "^XA\n^FX Étiquette\n^CF0,60\n",
            END: () => "^XZ\n",
            LF: () => "",
            CUT: () => "",
            FONT_SIZE: () => "",
            BAR_CODE: () => "",
            JUSTIFY: () => "",
            IMAGE: () => "",
            DRAWER: () => ""
        };
        let CMD_SUNMI = {
            INIT: () => {
                if (typeof SunmiInnerPrinter !== "undefined") {
                    SunmiInnerPrinter.printerInit();
                }
                return "";
            },
            END: () => "",
            LF: () => String.fromCharCode(10),
            CUT: () => String.fromCharCode(29) + String.fromCharCode(86) + String.fromCharCode(48),
            BOLD_ON: () => CMD_DEFAULT.BOLD_ON(),
            BOLD_OFF: () => CMD_DEFAULT.BOLD_OFF(),
            FONT_SIZE: size => CMD_DEFAULT.FONT_SIZE(size),
            BAR_CODE: code => CMD_DEFAULT.BAR_CODE(code),
            JUSTIFY: align => CMD_DEFAULT.JUSTIFY(align),
            QR_CODE: data => CMD_DEFAULT.QR_CODE(data),
            DRAWER: () => CMD_DEFAULT.DRAWER
        };

        let printerName = options.printerName || getConfiguration("printer-name") || "";
        let printerType = null;
        if (printerName.indexOf("Star") !== -1) {
            CMD = CMD_STAR;
            printerType = "STAR";
        } else if (printerName.indexOf("Zebra") !== -1 || printerName.indexOf("ZDesigner") !== -1) {
            CMD = CMD_ZEBRA;
            printerType = "ZEBRA";
        } else if (printerName.indexOf("Sunmi") !== -1) {
            CMD = CMD_SUNMI;
            printerType = "DEFAULT";
        } else {
            CMD = CMD_DEFAULT;
            printerType = "DEFAULT";
        }

        /**
         * @type {*}
         */
        let printer = {
            _str: CMD.INIT(),
            _raw: [],
            _base64Bitmap: null,
            _fontSize: 1,
            _padding: 0,
            _printerName: printerName,

            addLine: function(textLeft, textCenter, textRight) {

                if (printerType === "ZEBRA") {
                    printer._str += "^FO0,0^FD" + textLeft + "^FS\n";
                    return printer;
                }

                // Prune input
                textLeft = printer._convertAccents(textLeft);
                textCenter = printer._convertAccents(textCenter);
                textRight = printer._convertAccents(textRight);

                // Start printing line
                let line = "";
                let rawLine = "";

                // Compute line length in characters
                let lineLength = getConfiguration("printer-cols") || 42;
                let rawLineLength = getConfiguration("printer-cols") || 42;
                lineLength = Math.floor(lineLength / printer._fontSize);
                let padding = Math.floor(printer._padding / printer._fontSize);
                lineLength -= padding * 2;
                let maxLength = lineLength - (textRight ? textRight.length + 1 : 0) - (textCenter ? textCenter.length + 1 : 0);

                // Split line in multiple lines if text overflows
                let otherRows = [];
                if (textLeft) {
                    let enterSplit = textLeft.split(String.fromCharCode(10));
                    for (let enterString of enterSplit) {
                        while (enterString.length > maxLength) {
                            let index = maxLength;
                            while (index > 0 && (enterString[index] !== " " || enterString.charCodeAt(index) === 10)) {
                                index--;
                            }
                            otherRows.push(enterString.substring(0, index || maxLength));
                            enterString = enterString.substring(index + 1, enterString.length);
                        }
                        otherRows.push(enterString);
                    }
                    line = otherRows.splice(0, 1)[0];
                    rawLine = line;
                }

                // Add center and right text
                if (textCenter) {
                    let half = Math.floor(textCenter.length / 2);
                    while (line.length < Math.floor(lineLength / 2) - half) {
                        line += " ";
                    }
                    line += textCenter;
                    while (rawLine.length < Math.floor(rawLineLength / 2) - half) {
                        rawLine += " ";
                    }
                    rawLine += textCenter;
                }
                while (line.length < lineLength - (textRight ? textRight.length : 0)) {
                    line += " ";
                }
                while (rawLine.length < rawLineLength - (textRight ? textRight.length : 0)) {
                    rawLine += " ";
                }
                if (textRight) {
                    line += textRight;
                    rawLine += textRight;
                }

                // Add padding
                line = this.leftPad(this.rightPad(line, lineLength + padding, " "), lineLength + padding * 2, " ");
                printer._addLineRaw(rawLine.substring(0, rawLineLength));
                line += CMD.LF();
                printer._str += line;

                // Print rest of lines
                for (let i = 0; i < otherRows.length; i++) {
                    this.addLine(otherRows[i]);
                }

                return printer;

            },

            newLine: function() {
                printer._str += CMD.LF();
                printer._raw.push("");
                return printer;
            },

            printTestColumns: function() {
                printer.addLine(".........|.........|.........|.........|.........|.........|.........|.........|.........|.........|")
                  .newLine()
                  .newLine()
                  .newLine()
                  .print();
            },

            printPrinterName: function() {
                let cols = getConfiguration("printer-cols") || 48;
                printer
                  .setFontSize(1)
                  .addLine(null, rightPad("", cols, "*"))
                  .newLine()
                  .newLine()
                  .newLine()
                  .setFontSize(2)
                  .addLine(null, "PRINTER INFORMATION")
                  .setFontSize(1)
                  .newLine()
                  .newLine()
                  .newLine()
                  .addLine("PRINTER:", null, printerName)
                  .newLine()
                  .newLine()
                  .newLine()
                  .addLine(null, rightPad("", cols, "*"))
                  .newLine()
                  .newLine()
                  .print();
            },

            setBase64Bitmap(base64Data) {
                printer._base64Bitmap = base64Data;
            },

            addImage: function(nvIndex) {
                printer._str += CMD.IMAGE(nvIndex);
                return printer;
            },

            addBarCode: function(code) {
                printer._str += CMD.BAR_CODE(code);
                return printer;
            },

            addQRCode: function(data) {
                printer._str += CMD.QR_CODE(data);
                return printer;
            },

            setBoldOn: function() {
                printer._str += CMD.BOLD_ON();
                return printer;
            },

            setBoldOff: function() {
                printer._str += CMD.BOLD_OFF();
                return printer;
            },

            setFontSize: function(size) {
                printer._fontSize = size;
                printer._str += CMD.FONT_SIZE(size);
                return printer;
            },

            setPadding: function(padding) {
                printer._padding = padding;
                return printer;
            },

            openDrawer: function(option) {
                let cmd = CMD.DRAWER[option];
                this._print(cmd());
                return printer;
            },

            getDrawerCommandSize: function() {
                return Object.keys(CMD.DRAWER).length;
            },

            reset() {
                printer._str = CMD.INIT();
                printer._raw = [];
            },

            print: async function() {
                if (!_isNodeOrElectron() && !_isAndroid() && !_getXposUrl()) {
                    console.log("PrintFormatter: Not in an Electron, NWJS or Android App.");
                    return;
                }
                let length = _isNodeOrElectron() ? 8 : 4;
                for (let i = 0; i < length; i++) {
                    printer.newLine();
                }

                printer._str += CMD.CUT();
                printer._str += CMD.END();
                console.log(printer._str);
                window.logToFile(printer._str);
                await printer._print(printer._str);
                printer.reset();
            },

            cut: function() {
                printer._str += CMD.CUT();
                return printer;
            },

            _getNodePrinter: function() {
                if (typeof nw === "undefined") {
                    return;
                }
                return eval("require(\"printer\")");
            },

            _print: async function(data) {
                const printerName = options.printerName || getConfiguration("printer-name");
                return new Promise((resolve) => {
                    if (_getXposUrl()) {
                        let xposPrinter = new XposPrinter(_getXposUrl());
                        try {
                            let encodedData = this._stringToBase64(data);
                            xposPrinter.print(printerName, encodedData).then(r => resolve(r));
                        } catch (e) {
                            console.log("PrintFormatter: Error while printing with XPOS", e);
                            resolve({ success: false });
                        }
                    } else if (_isNodeOrElectron()) {
                        let nodePrinter;
                        try {
                            nodePrinter = this._getNodePrinter();
                        } catch (e) {
                            console.log("PrintFormatter: Cannot load printer module.");
                            return;
                        }
                        if (nodePrinter) {
                            let printOptions = {
                                data: data,
                                printer: printerName,
                                type: nodePrinter.getPrinter(printerName).datatype,
                                error: (err) => {
                                    console.log(err);
                                    resolve({ success: false });
                                },
                                success: (jobid) => {
                                    console.log("SUCCESS " + jobid);
                                    resolve({ success: true });
                                }
                            };
                            nodePrinter.printDirect(printOptions);

                        }
                    } else if (_isAndroid()) {
                        let arrayBuffer = this._stringToArrayBuffer(data);
                        let rawData = this._arrayBufferToBase64(arrayBuffer);
                        /* Sunmi printer */
                        if (hasSunmiInnerPrinter && printerName === SUNMI_INNERPRINTER) {
                            SunmiInnerPrinter.sendRAWData(rawData,
                              () => resolve({ success: true }),
                              () => resolve({ success: false }));
                            /* Cordova LAN or Bluetooth printers */
                        } else if (_hasCordovaPrint()) {
                            let errorCallback = (error) => {
                                console.log("cordova print error", error);
                                resolve({ success: false, error: error });
                            };
                            let sendPrintInstruction = () => {
                                CordovaPrint.posCommand(() => {
                                    CordovaPrint.print(() => {
                                        CordovaPrint.disconnect(() => {
                                            console.log("CordovaPrint success", printerName);
                                            resolve({ success: true });
                                        }, errorCallback);
                                    }, errorCallback);
                                }, errorCallback, rawData);
                            };
                            CordovaPrint.connect(() => {
                                if (this._base64Bitmap) {
                                    CordovaPrint.base64image(() => {
                                        console.log("Added image to print");
                                        sendPrintInstruction();
                                    }, errorCallback, this._base64Bitmap);
                                } else {
                                    sendPrintInstruction();
                                }
                            }, errorCallback, printerName);
                        } else {
                            resolve({ success: true });
                        }
                    }
                });
            },

            /**
             * @param bytes
             * @returns {string}
             * @private
             */
            _arrayBufferToBase64: function(bytes) {
                let binary = "";
                for (let i = 0; i < bytes.byteLength; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            },

            /**
             * @param {string} text
             * @returns {Uint8Array}
             * @private
             */
            _stringToArrayBuffer: function(text) {
                return new TextEncoder().encode(text);
            },

            /**
             * @param {string} text
             * @returns {string}
             * @private
             */
            _stringToBase64: function(text) {
                return window.btoa(text);
            },

            getPrinters: function() {
                return new Promise((resolve) => {
                    //TODO XPOS printer discovery
                    /* Node printers */
                    if (_isNodeOrElectron()) {
                        try {
                            let nodePrinter = this._getNodePrinter();
                            let printers = nodePrinter.getPrinters();
                            resolve({ success: true, printers: printers });
                        } catch (e) {
                            console.error("Could not enable print:");
                            console.error(e);
                            resolve({ success: false, printers: [] });
                        }
                    } else if (_isAndroid()) {
                        let printers = [];
                        /* Android Sunmi Inner Printer */
                        if (_isSunmi() && hasSunmiInnerPrinter) {
                            printers.push({ name: SUNMI_INNERPRINTER });
                        }
                        /* Android LAN or Bluetooth printers */
                        if (_hasCordovaPrint()) {
                            let successCallback = (foundPrinters) => {
                                let cordovaPrinters = foundPrinters.map(p => { return { name: p }; });
                                printers = [...printers, ...cordovaPrinters];
                                resolve({ success: true, printers: printers });
                            };
                            let errorCallback = (error) => {
                                console.log("cordova print error", error);
                                resolve({ success: false, printers: printers });
                            };
                            CordovaPrint.list(successCallback, errorCallback);
                        } else {
                            resolve({ success: true, printers: printers });
                        }
                    } else {
                        resolve({ success: false, printers: [] });
                    }
                });
            },

            _addLineRaw: function(textLeft, textCenter, textRight) {
                textLeft = printer._convertAccents(textLeft);
                textCenter = printer._convertAccents(textCenter);
                textRight = printer._convertAccents(textRight);
                let line = "";
                if (textLeft) {
                    line = textLeft;
                }
                let lineLength = getConfiguration("printer-cols") || 42;
                let padding = printer._padding;
                lineLength -= padding * 2;
                if (textCenter) {
                    let half = Math.floor(textCenter.length / 2);
                    while (line.length < Math.floor(lineLength / 2) - half) {
                        line += " ";
                    }
                    line += textCenter;
                }
                while (line.length < lineLength - (textRight ? textRight.length : 0)) {
                    line += " ";
                }
                if (textRight) {
                    line += textRight;
                }
                line = this.leftPad(this.rightPad(line, lineLength + padding, " "), lineLength + padding * 2, " ");
                printer._raw.push(line);
            },

            _convertAccents: function(str) {
                if (!str) {
                    return "";
                }
                str = String(str);
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            },

            leftPad: function(str, length, padding) {
                padding = padding || " ";
                while (str.length < length) {
                    str = padding + str;
                }
                return str;
            },

            rightPad: function(str, length, padding) {
                padding = padding || " ";
                while (str.length < length) {
                    str += padding;
                }
                return str;
            }

        };
        this.printer = printer;

        return printer;
    }

};
