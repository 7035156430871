class Format {

  /**
   * @param {string} phoneNumber
   * @returns {string}
   */
  formatPhoneNumber(phoneNumber) {
    let cleaned = ("" + phoneNumber).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? `+${match[1]} ` : "");
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "";
  }

  // https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
  /**
   * @param {string} text
   * @returns {string}
   */
  escapeHtml(text) {
    console.log(text);
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  /**
   * @param {string} postalCode
   * @returns {string}
   */
  formatPostalCode(postalCode) {
    if (postalCode.length === 6) {
      return postalCode.substr(0, 3) + " " + postalCode.substr(3, 3);
    }
    return postalCode;
  }

  /**
   * @param {string} xml
   * @returns {*}
   */
  parseXmlToJson(xml) {
    const json = {};
    let match = _findAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm, xml);
    for (const res of match) {
      const key = res[1] || res[3];
      const value = res[2] && this.parseXmlToJson(res[2]);
      json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

    }
    return json;
  }

}

/**
 * @param regexPattern
 * @param sourceString
 * @returns {*[]}
 * @private
 */
function _findAll(regexPattern, sourceString) {
  let output = [];
  let match;
  // make sure the pattern has the global flag
  let regexPatternWithGlobal = RegExp(regexPattern, [...new Set(`g${regexPattern.flags}`)].join(""));
  while (match = regexPatternWithGlobal.exec(sourceString)) {
    // get rid of the string copy
    delete match.input;
    // store the match data
    output.push(match);
  }
  return output;
}

let format = new Format();
export { format as Format };