<template>
  <div id="styleClassic" class="items">

    <template v-if="$kiosk">
      <div class="promo-banner--holder" v-if="PromotionManager.hasPromotion() && !PromotionManager.hasPromotionApplied() && !$order.appliedPromotion.promotion" @click="showKioskPromoKeyboardModal">
        <div class="promo-banner">
          <span class="text">{{$t('kiosk.promo_banner')}}</span>
          <svgicon src="icons/arrow-right.svg" :height="20" :width="15"></svgicon>
        </div>
      </div>
    </template>

    <template v-for="item of items">

      <Item v-if="item.shouldBeDisplayedAs('item', $order.method)" :item="item" :key="item.id" @selected="selectItem(item)"></Item>

      <Category v-if="item.shouldBeDisplayedAs('category', $order.method)" :class="{hideTitles: hideCategoryTitles}"
                :category="item" :key="item.id" @selected="selectItem(item)"></Category>

    </template>

    <div class="items__empty" v-if="$pos && items.length === 0 && searchString">
      <Illustration src="icons/dots-circle.svg" large></Illustration>
      <div class="empty__text">{{$t('no_items')}}</div>
    </div>

    <ScrollArrows v-if="$kiosk" ref="scrollArrows" scrollContainerElementId="styleClassic"></ScrollArrows>

  </div>
</template>

<script>

import EventBus from "@/lib/eventBus";
import { PromotionManager } from "@/PromotionManager";
import Item from "@/components/menu/Item.vue";
import Category from "@/components/menu/Category.vue";
import ScrollArrows from "@/components/tools/ScrollArrows";
import Illustration from "@/components/modal/Illustration";

export default {

  name: "styleClassic",

  components: { Item, Category, ScrollArrows, Illustration },

  props: ["items"],

  data() {
    return {
      PromotionManager
    }
  },

  methods: {
    selectItem(item) {
      this.$emit("selectItem", item);
    },
    showKioskPromoKeyboardModal() {
      EventBus.$emit("show-kiosk-promo-keyboard-modal");
    }
  },

  mounted() {
    if (this.$kiosk) {
      this.$nextTick(() => {
        if (document.getElementById("styleClassic")) {
          document.getElementById("styleClassic").style.opacity = "1";
        }
      });
      EventBus.$on("kiosk-category-changed", () => {
        if (this.$refs.scrollArrows) {
          this.$refs.scrollArrows.handleScroll();
        }
      });
    }
  },

  beforeDestroy() {
    if (this.$kiosk) {
      EventBus.$off("kiosk-category-changed");
    }
  },

  computed: {
    branch() {
      return this.$store.state.currentBranch;
    },
    searchString() {
      return this.$store.state.inventorySearchString;
    },
    hideCategoryTitles() {
      if (!this.branch || !this.branch.style) {
        return false;
      }
      return this.branch.style.hideCategoryTitles;
    }
  }
}

</script>

<style lang="scss" scoped>

  #iShopFoodApp #styleClassic {
    display: flex;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
  }

  #iShopFoodApp.pos #styleClassic {
    flex-basis: 0;
    position: relative;
  }

  #iShopFoodApp:not(.pos):not(.kiosk) #styleClassic {
      overflow-y: hidden;
  }

  .xs #styleClassic, .sm #styleClassic {
    justify-content: center;
  }

  #iShopFoodApp.kiosk .promo-banner--holder {
    width: calc(100% - 20px);
    height: 70px;
  }
  #iShopFoodApp.kiosk .promo-banner {
    margin-bottom: 20px;
    margin-left: 5px;
    flex-shrink: 0;
    border-radius: 15px;
    cursor: pointer;
    background: linear-gradient(to right, #ed2937, #fc6650);
    fill: white;
    text-align: center;
    font-size: 18px;
    padding: 15px 20px;
    position: relative;

    .text {
      color: white;
      font-weight: 500;
    }

    .svgicon {
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }

  .items__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;

    .empty__text {
      font-weight: bold;
      font-size: 24px;
      padding-top: 10px;
      color: #dbdbdb;
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp.xs #styleClassic {
    .category.hideTitles {
      width: 100%;
      max-width: 320px;
    }
  }

  #iShopFoodApp.kiosk #styleClassic {
    opacity: 0;
    transition: opacity 800ms ease;
    scroll-behavior: smooth;
  }
  #styleClassic {
    .category.hideTitles {
      width: 100%;
      max-width: 300px;

      .image {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .info {
        display: none !important;
      }
    }
  }
</style>
