//@ts-check

/**
 * @typedef CategoryDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {number} availability
 * @property {number} priority
 * @property {number} branding
 * @property {number} image
 */

import moment from "moment-timezone";

export default class Category {

  /**
   * @param {CategoryDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.name = definition.name;
    this.description = definition.description;
    this.priority = definition.priority;
    this.availability = definition.availability;
    this.branding = definition.branding;
    this.image = /** @type {string} */ (null);
    this.items = /** @type {Item[]} */ ([]);

    this.formatAvailability();
  }

  /**
   * If a range is over 24 hours, this will create a range in the next day starting at 00:00. Ex.: end 26:00, will create 00:00 to 2:00 in next day
   * This is used so that a category be will available if the user is on the 'next day' but within the last day's range.
   */
  formatAvailability() {
    if (!this.availability) {
      return;
    }
    let splitHour = (text) => {
      let split = text.split(":");
      let hour = {
        hour: parseInt(split[0]),
        minute: parseInt(split[1])
      };
      return hour;
    };

    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    for (let i = 0; i < days.length; i++) {
      let day = days[i];
      for (let range of this.availability.conditions[day].hours) {
        let hourTo = splitHour(range.to);
        if (hourTo.hour >= 24) {
          let nextDayIndex = i === days.length - 1 ? 0 : i + 1; //get next day, makes sure saturday falls back to sunday
          let nextDay = days[nextDayIndex];
          this.availability.conditions[nextDay].hours.push({
            from: "00:00",
            to: (hourTo.hour - 24) + ":" + hourTo.minute
          });
        }
      }
    }
  }

  /**
   * @param {Item} item
   */
  addItem(item) {
    this.items.push(item);
  }

  /**
   * @returns {number}
   */
  getLowestPrice(method) {
    method = method || "takeout";
    return this.items.length > 0 ? Math.min(...this.items.map(i => i.getPrice(method)).filter(i => i > 0)) : 0;
  }

  /**
   * @param {string} type
   * @param {string} method
   * @return {boolean}
   */
  shouldBeDisplayedAs(type, method) {
    return type === "category" && this.items && this.items.length > 0 &&
      this.items.filter(i => i.shouldBeDisplayedAs("item", method)).length > 0;
  }

  /**
   * Check is specified date/time (fallback to current date and/or time) is within availability
   * @param {Moment?} date
   * @param {string?} time
   * @returns {boolean}
   */
  isAvailable(date, time) {
    // 1. Always open
    if (!this.availability) {
      return true;
    }

    if (time === "delay" || time === "asap") {
      time = null;
    }

    time = time ? moment(time, "HH:mm") : moment();
    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let dayNum = (date || moment()).day();
    let dayName = days[dayNum];
    let schedule = this.availability.conditions[dayName];

    // 2. Day not open
    if (!schedule.hours || schedule.hours.length === 0) {
      return false;
    }
    for (let range of schedule.hours) {
      let parsedTo = parseHour(range.to);
      let to = moment("00:00:00", "HH:mm:ss").add(parsedTo.hour, "hours").add(parsedTo.minute, "minutes");
      // 3. Within range for day
      if (time.isAfter(moment(range.from, "HH:mm")) && time.isBefore(to)) {
        return true;
      }
    }

    // 4. Outside range for day
    return false;
  }

  /**
   * @return {Category}
   */
  clone() {
    let copy = new Category({
      id: this.id,
      name: this.name,
      description: this.description,
      priority: this.priority,
      branding: this.branding
    });
    copy.items = [...this.items];
    copy.image = this.image;
    copy.availability = this.availability;

    return copy;
  }

}
