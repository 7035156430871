<template>
  <div class="headerClip" ref="clip" :style="{
    'clip-path': 'polygon(0 ' + top + 'px, 100% ' + top + 'px, 100% 100%, 0 100%)'
  }">
    <slot></slot>
  </div>
</template>

<script>

export default {

  name: "headerClip",

  data() {
    return {
      event: null,
      top: 0
    };
  },

  mounted() {
    this.event = this.onScroll.bind(this);
    document.getElementsByClassName("page-content")[0].addEventListener("scroll", this.event);
  },

  methods: {
    onScroll(event) {
      let clip = /** @type {HTMLElement} */ (this.$refs.clip);
      if (!clip) {
        return;
      }
      let bounds = clip.getBoundingClientRect();
      this.top = Math.max(60 - bounds.top, 0);
    }
  }

}
</script>

<style>

</style>