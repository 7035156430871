<template>
  <div id="posLoading">
    <div class="loading__content">
      <svgicon class="logo" src="icons/maitreD.svg" :height="120" :width="470"></svgicon>
      <div class="progress-bar">
        <div class="progress"></div>
      </div>
      <div class="text">{{ $t('pos_loading.hang_on') }}</div>
    </div>
    <OnboardingCopyright></OnboardingCopyright>
  </div>
</template>

<script>
import OnboardingCopyright from "@/components/pos/OnboardingCopyright";
import Axios from "axios";

export default {
  name: "posLoading",

  components: {
    OnboardingCopyright
  },

  data() {
    return {
      delayBeforeRedirect: 7 * 1000, //ms
      year: new Date().getFullYear(),
      statusTimeout: 5000
    }
  },

  async mounted() {
    try {
      let res = await Axios.get(this.$store.getters.urlServer + "/api/status", {
        timeout: this.statusTimeout
      });
      if (res.status !== 200) {
        this.goOffline();
        return;
      }
    } catch (e) {
      this.goOffline();
      return;
    }
    setTimeout(() => {
      this.$router.push("/pos/onboarding");
    }, this.delayBeforeRedirect);
  },

  methods: {
    goOffline() {
      this.$router.push("/pos/offline");
    }
  }
}
</script>

<style lang="scss" scoped>
$color: #ff5500;
$progress-duration: 6s;
$logo-fadein-duration: 1s;
$text-fadein-duration: 1s;
$delay-before-logo-fadein: 1s;
$delay-before-text-fadein: 3s;
$delay-before-fadeout: 6s;
$fadeout-duration: 800ms;

$fadeout-animation: fade-out $fadeout-duration ease-out $delay-before-fadeout forwards;

#iShopFoodApp.pos #posLoading {
  display: flex;
  background-color: white !important;
  flex-direction: column;

  .loading__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      fill: $color;
      opacity: 0;
      transform: translateY(80px);
      animation: bottom-fade-in $logo-fadein-duration ease-out $delay-before-logo-fadein forwards, $fadeout-animation;
    }
  }

  .progress-bar {
    width: 900px;
    height: 10px;
    border-radius: 40px;
    background-color: #f5f5f5;
    margin: 75px 0;
    overflow: hidden;
    position: relative;
    animation: fade-in 300ms ease-in;

    .progress {
      width: 0;
      height: 10px;
      background-color: $color;
      border-radius: 40px;
      animation: progress-bar $progress-duration linear forwards;
    }
  }
}

.text {
  color: $color;
  font-size: 20px;
  font-weight: 500;
  opacity: 0;
  transform: translateY(-80px);
  animation: top-fade-in $text-fadein-duration ease-out $delay-before-text-fadein forwards, $fadeout-animation;
}

@keyframes progress-bar {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bottom-fade-in {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes top-fade-in {
  0% {
    opacity: 0;
    transform: translateY(-80px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>