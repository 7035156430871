<template>
  <div id="posOrderOverview" :class="{ 'posOrderOverview--left-handed': isLeftHandedMode }">
    <template v-if="!isLeftHandedMode">
      <PosOrderOverviewCart :order="currentOrder"/>
    </template>
    <div class="order-overview">
      <div class="orders">
        <div class="orders__search">
          <div class="search__all" v-if="company">
            <label for="orderSearch" class="search__input--label">
              {{ $t("pos_order_overview.search_order_title") }}
            </label>
            <PosInput type="text" id="orderSearch" class="search__input input__order-search" v-model="searchString"
                      :placeholder="$t('pos_order_overview.search_order_placeholder')"/>
          </div>
          <div class="search__filter" v-if="company">
            <label for="serviceFilter"
                   class="filter__input--label">{{ $t("pos_order_overview.service_filter.title") }}</label>
            <select class="dropdown" id="serviceFilter" v-model="serviceFilter">
              <option v-for="service of serviceFilterOptions" :value="service.value" :key="`service-${service.value}`">
                {{ $t("pos_order_overview.service_filter." + service.name) }}
              </option>
            </select>
          </div>
          <div class="search__filter" v-if="company">
            <label for="statusFilter"
                   class="filter__input--label">{{ $t("pos_order_overview.status_filter.title") }}</label>
            <select class="dropdown" id="statusFilter" v-model="statusFilter">
              <option v-for="status of statusFilterOptions" :value="status.value" :key="`status-${status.value}`">
                {{ $t("pos_order_overview.status_filter." + status.name) }}
              </option>
            </select>
          </div>
        </div>
        <div class="orders__preview" :class="{empty: orders.length === 0}">
          <div class="orders__empty" v-if="orders.length === 0">
            <Illustration src="icons/dots-circle.svg" large/>
            <div class="empty__text">{{ $t("pos_order_overview.no_order") }}</div>
          </div>
          <PosOrder v-for="order in orders" v-if="orders" @setCurrentOrder="setCurrentOrder(order)"
                    :key="'order-' + order.getOrderId()" :order="order"
                    :active="currentOrder && (currentOrder.getOrderId() === order.getOrderId())"/>
        </div>
        <div class="orders__pages">
          <div class="page page--first" :class="{disabled: isFirstPage}" @click="previousPage">
            <svgicon src="icons/pos-arrow-left.svg" :height="25" :width="15"/>
          </div>
          <div class="page active">{{ currentPage }}</div>
          <div class="page page--last" :class="{disabled: !hasMorePage}" @click="nextPage">
            <svgicon src="icons/pos-arrow-right.svg" :height="25" :width="15"/>
          </div>
        </div>
      </div>
      <div class="order-overview__tabs">
        <div v-for="tab in orderTabs" class="order-overview__tab"
             :class="{active: activeTab.name == tab.name, disabled: tab.disabled}" @click="setActiveTab(tab)">
          {{ $t("pos_order_overview.tabs." + tab.name) }}
        </div>
      </div>
    </div>
    <template v-if="isLeftHandedMode">
      <PosOrderOverviewCart :order="currentOrder"/>
    </template>
  </div>
</template>

<script>
  import PosOrderOverviewCart from "@/components/pos/OrderOverviewCart";
  import PosOrder from "@/components/pos/Order";
  import OrderOverview from "@/models/pos/OrderOverviewModel";
  import { mapActions } from "vuex";
  import moment from "moment-timezone";
  import Illustration from "@/components/modal/Illustration";
  import PosInput from "@/components/pos/PosInput";
  import { Constant } from "../../util/Constant";

  export default {
    name: "posOrderOverview",

    components: {
      PosOrderOverviewCart,
      PosOrder,
      Illustration,
      PosInput
    },

    data() {
      return {
        orderTabs: [
          {
            name: "all",
            disabled: false,
            deliveryMethod: undefined,
            callback: this.getSales
          },
          {
            name: "takeout",
            disabled: false,
            deliveryMethod: "takeout",
            callback: this.getSales
          },
          {
            name: "in_place",
            disabled: false,
            deliveryMethod: "inPlace",
            callback: this.getSales
          },
          {
            name: "delivery",
            disabled: false,
            deliveryMethod: "delivery",
            callback: this.getSales
          },
          {
            name: "hotel",
            disabled: false,
            deliveryMethod: "hotel",
            callback: this.getSales
          },
          {
            name: "catering",
            disabled: false,
            deliveryMethod: "catering",
            callback: this.getSales
          }
        ],
        statusFilterOptions: [
          {
            name: "all",
            value: undefined
          },
          {
            name: "open",
            value: "1"
          },
          {
            name: "closed",
            value: "0"
          },
        ],
        serviceFilterOptions: [
          {
            name: "all",
            value: undefined
          },
          {
            name: "pos",
            value: Constant.SERVICE_POS
          },
          {
            name: "online",
            value: Constant.SERVICE_ONLINE
          },
          {
            name: "kiosk",
            value: Constant.SERVICE_KIOSK
          },
          {
            name: "mobile",
            value: Constant.SERVICE_MOBILE_APP
          },
          {
            name: "uber_eats",
            value: Constant.SERVICE_UBER
          }
        ],
        activeTab: "",
        perPage: 9,
        currentPage: 1,
        hasMorePage: false,
        orders: [],
        currentOrder: null,
        dateFormat: "YYYY-MM-DD",
        statusFilter: undefined,
        serviceFilter: undefined,
        searchString: ""
      };
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      employee() {
        return this.$store.state.employee;
      },
      startDate() {
        return moment().format(this.dateFormat);
      },
      isOffline() {
        return this.$store.state.offline;
      },
      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      },
      isFirstPage() {
        return this.currentPage <= 1;
      }
    },

    watch: {
      "searchString"(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadOrders();
        }
      },
      "statusFilter"(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadOrders();
        }
      },
      "serviceFilter"(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadOrders();
        }
      }
    },

    mounted() {
      if (!this.employee) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
      }

      this.$store.state.inventorySearchString = "";
      this.setActiveTab(this.orderTabs[0]);
    },

    beforeDestroy() {
      this.$store.state.inventorySearchString = "";
    },

    methods: {
      ...mapActions(["getSalesForPOS"]),

      setActiveTab(tab) {
        if (this.activeTab && this.activeTab.name === tab.name) {
          return;
        }
        this.activeTab = tab;
        this.currentPage = 1;
        this.clearCurrentOrder();
        this.loadOrders();
      },
      async loadOrders() {
        if (!this.isOffline) {
          this.activeTab.callback();
        }

      },
      async getSales() {
        if (!this.company || !this.employee || !this.activeTab) {
          return;
        }
        showSpinner();

        let data = {
          deliveryMethod: this.activeTab.deliveryMethod,
          employeeId: this.employee.getId(),
          open: this.statusFilter,
          start: this.startDate,
          search: this.searchString,
          page: this.currentPage,
          perPage: this.perPage,
          service: this.serviceFilter
        };

        let orders = await this.getSalesForPOS(data);

        if (orders && orders.success) {
          this.orders = [];

          for (let order of orders.sales.data) {
            this.orders.push(new OrderOverview(order));
          }
          this.hasMorePage = orders.sales.meta.hasMore;
        }
        hideSpinner();
      },
      setPage(pageNumber) {
        this.currentPage = pageNumber;
        this.loadOrders();
      },
      nextPage() {
        if (this.hasMorePage) {
          this.setPage(this.currentPage + 1);
        }
      },
      previousPage() {
        if (!this.isFirstPage) {
          this.setPage(this.currentPage - 1);
        }
      },
      setCurrentOrder(order) {
        if (this.currentOrder && (this.currentOrder.getOrderId() == order.getOrderId())) {
          return;
        }
        this.currentOrder = order;
      },
      clearCurrentOrder() {
        this.currentOrder = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #posOrderOverview {
  width: 1920px;
  display: flex;
  flex-direction: row;

  .order-overview {
    padding: 30px 30px 30px 10px;
    display: flex;
    flex-grow: 1;
  }

  .order-overview__tabs {
    width: 180px;
    border-left: dashed 1px var(--border-color);
    flex-shrink: 0;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .order-overview__tabs-header {
      align-self: center;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 5px;
      margin: 0 0 10px 20px;
      text-align: center;
      color: var(--theme-color-2);

      &.order-overview__tabs-header--padding {
        padding-top: 20px;
      }
    }

    .order-overview__tab {
      color: var(--theme-color);
      font-size: 18px;
      font-weight: 500;
      width: 160px;
      height: 100px;
      border-radius: 16px;
      box-shadow: 0px 3px 9px 0 rgba(0, 0, 0, 0.1);
      background-color: var(--background-color-2);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;

      &.active {
        border: solid 5px var(--primary-color);
        color: var(--primary-color);
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .orders {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 19px;
    min-width: 914px;
    justify-content: space-between;
  }

  .orders__search {
    display: flex;

    .search__all, .search__filter {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-right: 20px;
      color: var(--theme-color);
    }

    .search__input--label, .filter__input--label {
      margin-bottom: 10px;
    }

    label {
      font-size: 16px;
      font-weight: 500;
      color: #808080;
    }

    .input__order-search, .dropdown {
      height: 56px;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 500;
    }

    .filter__input {
      display: flex;
    }

    .input--start-date {
      margin-right: 10px;
    }

    .search__button {
      width: 56px;
      height: 56px;
      border-radius: 12px;
      background-color: var(--primary-color);
      fill: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .orders__preview {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-content: flex-start;
  }

  .orders__pages {
    display: flex;
    padding-top: 20px;

    .page {
      width: 56px;
      height: 56px;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 500;
      color: var(--primary-color);
      background-color: var(--background-color-2);
      display: flex;
      align-items: center;
      justify-content: center;
      fill: var(--primary-color);

      &.active {
        color: #ffffff;
        background-color: var(--primary-color);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .orders__preview.empty {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .orders__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .empty__text {
      font-weight: bold;
      font-size: 24px;
      color: #dbdbdb;
    }
  }

  &.posOrderOverview--left-handed {
    .order-overview {
      padding: 30px 10px 30px 30px;
    }

    .order-overview__tabs {
      border-right: dashed 1px var(--border-color);
      border-left: none;
      order: -1;
      align-items: flex-start;

      .order-overview__tabs-header {
        margin: 0 20px 10px 0;
      }
    }
  }
}
</style>

<style lang="scss">
#iShopFoodApp #posOrderOverview {
  .input__datetime {
    height: 56px;
    border-radius: 12px;
    color: #bfbfbf;
    font-size: 20px;
    font-weight: 500;
    width: 227px;
  }

  .orders__empty .illustration {
    fill: #dbdbdb;
    margin-bottom: 30px;
  }
}
</style>