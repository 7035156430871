<template>
  <div id="closeBatchModal" class="modal" :class="{show: visible}">
    <Panel class="close-batch" :title="isClosed ? $t('pos.readings.batch_closed') : $t('pos.readings.close_batch')" icon="icons/close-batch.svg">
      <div class="close-batch__content">
        <div class="content__image">
          <Illustration :src="'icons/'+ (isClosed ? 'success-thick' : 'tip-danger') +'.svg'"></Illustration>
        </div>
        <div class="content__description">
          {{ isClosed ? $t('pos.readings.batch_closed_description') : $t('pos.readings.close_batch_warning') }}
        </div>
      </div>
      <div class="close-batch__footer">
        <button class="btn btn-secondary" @click="hide">
          <svgicon class="icon" src="/icons/back-full.svg" :height="24" :width="18"></svgicon>
          {{ $t("back") }}
        </button>
        <button class="btn btn-primary" @click="closeBatch" v-if="!isClosed">
          <svgicon class="icon" src="/icons/close-batch.svg" :height="24" :width="24"></svgicon>
          {{ $t('pos.readings.close_batch') }}
        </button>
      </div>
    </Panel>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import Illustration from "@/components/modal/Illustration.vue";
  export default {
    name: "CloseBatchModal",

    components: { Illustration },

    data() {
      return {
        visible: false,
        isClosed: false
      };
    },

    mounted() {
      EventBus.$on("show-close-batch-modal", (isClosed) => {
        console.log("isClosed", isClosed);
        this.isClosed = isClosed;
        this.show();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-close-batch-modal");
    },

    methods: {
      show() {
        EventBus.$emit("toggle-pos-header-overlay", true);
        this.visible = true;
      },
      hide() {
        EventBus.$emit("toggle-pos-header-overlay", false);
        this.visible = false;
      },
      closeBatch() {
        this.$emit("close-batch");
        this.hide();
      }
    }
  };
</script>

<style lang="scss" scoped>
  #closeBatchModal {
    font-weight: 500;

    .close-batch {
      display: flex;
      flex-direction: column;
    }

    .close-batch__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;

      .content__image {
        padding: 40px 0;
      }

      .content__description {
        font-size: 20px;
        text-align: center;
      }
    }

    .close-batch__footer {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-4);
      margin-top: 40px;
    }

    .btn {
      font-size: 20px;
      border-radius: 12px;
      padding: 15px;
      .svgicon {
        margin-right: 16px;
      }
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #posReading #closeBatchModal .panel {
    width: 480px;
  }
</style>