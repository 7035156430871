import getSymbolFromCurrency from "currency-symbol-map";

let currencies = require("currency-codes");

class Money {

    constructor() {
        this.currency = /** @type {string} */ "";
    }

    /**
     * Round amount for current currency
     * @param {number} amount
     * @return {number}
     */
    round(amount) {
        let precision = this.getRoundingPrecisionForCurrency();
        return +(Math.round((amount) + "e+" + precision) + "e-" + precision);
    }

    /**
     * Round amount for current currency to 5 cents (for CAD legislation)
     * @param {*} amount
     * @returns {number}
     */
    roundToNearest5Cents(amount) {
        let sign = Math.sign(amount);
        amount = Math.abs(this.round(amount));
        let remainder = amount % 0.05;
        if (remainder < 0.025) {
            return this.round(amount - remainder) * sign;
        } else {
            return this.round(amount + (0.05 - remainder)) * sign;
        }
    }

    /**
     * Round amount for current currency one decimal if applicable
     * @param {*} amount
     * @returns {number}
     */
    roundUpLastDecimal(amount) {
        let sign = Math.sign(amount);
        amount = Math.abs(amount);
        let rounding = this.getRoundingPrecisionForCurrency();
        let roundUp = Math.ceil(amount * Math.pow(10, rounding)) / Math.pow(10, rounding);
        return roundUp * sign;
    }

    /**
     * Round amount for current currency for display only
     * @param {number} amount
     * @param {string?} currency
     * @return {string}
     */
    roundForDisplay(amount, currency) {
        return this.round(amount).toFixed(this.getRoundingPrecisionForCurrency(currency));
    }

    /**
     * @param {string} currency
     */
    setCurrency(currency) {
        this.currency = currency;
    }

    /**
     * @param {string?} currency
     * @returns {number}
     */
    getRoundingPrecisionForCurrency(currency) {
        let currencyDetail = currencies.code(currency || this.currency);
        return currencyDetail ? currencyDetail.digits : 2;
    }

    roundForDisplayWithCurrencySymbol(amount, currency, language) {
        let symbol = getSymbolFromCurrency(currency);

        if (typeof amount === "number") {
            amount = this.roundForDisplay(amount);
        }

        switch (_currencySymbolPosition[language]) {
            case "before":
                return symbol + " " + amount;
            case "after":
                return amount + " " + symbol;
            default:
                return symbol + " " + amount;
        }
    }

}

const _currencySymbolPosition = {
    fr: "after",
    en: "before",
    es: "before"
};

let money = new Money();
export { money as Money };