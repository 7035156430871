<template>
  <GoogleAutocomplete v-if="branch && branch.getAutocompleteService() === 'google'" @changed="googlePlaceChanged($event)"
                      v-show="(branch && branch.hasZones()) || forceAddress"
                      :prefill="prefillAddress && $user && $user.getDefaultAddress() ? $user.getDefaultAddress() : null"></GoogleAutocomplete>
  <HereAutocomplete v-else-if="branch && branch.getAutocompleteService() === 'here'"  @changed="herePlaceChanged($event)"
                    v-show="(branch && branch.hasZones()) || forceAddress"
                    :prefill="prefillAddress && $user && $user.getDefaultAddress() ? $user.getDefaultAddress() : null"></HereAutocomplete>
</template>

<script>
  import { mapActions } from "vuex";
  import GoogleAutocomplete from "@/components/tools/GoogleAutocomplete.vue";
  import HereAutocomplete from "@/components/tools/HereAutocomplete.vue";

  export default {
    name: "autocompleteField",

    components: {
      GoogleAutocomplete, HereAutocomplete
    },

    props: {
      prefillAddress: Boolean,
      type: String,
      forceAddress: Boolean
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      }
    },

    methods: {
      googlePlaceChanged(autocomplete) {
        let place = autocomplete.getPlace();
        let address = place.address_components;
        let location = place.geometry ? place.geometry.location : null;
        this.branch.logGeocoding({service: "google", type: "autocomplete", source: this.type});
        this.$user.updateAddressFromGoogle(address, location, document.getElementById("autocomplete").value);
        this.$emit("changed");
      },

      herePlaceChanged(suggestion) {
        this.branch.logGeocoding({service: "here", type: "autocomplete", source: this.type});
        this.$user.updateAddressFromHere(suggestion.address, suggestion.position, document.getElementById("autocomplete").value);
        this.$emit("changed");
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
