<template>
  <div id="paymentcontact">

    <MenuHeader :text="$t('payment.confirmation_and_payment')" v-if="$store.state.quickMode"></MenuHeader>

    <PageTitle v-if="!$store.state.quickMode">
      <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
      <template v-if="!$store.getters.isMobileOrTablet">{{$t('payment.submit_order')}}</template>
      <template v-if="$store.getters.isMobileOrTablet">{{$t('payment.confirmation_and_payment')}}</template>
    </PageTitle>

    <div class="page-content">

      <div class="card" v-if="!$store.getters.isMobileOrTablet && !$store.state.quickMode">
        <div class="container">
          <div class="title">{{$t('payment.confirmation_and_payment')}}</div>
          <div class="text">{{$t('payment.complete_order')}}</div>
        </div>
        <Steps></Steps>
      </div>

      <Steps v-if="$store.getters.isMobileOrTablet && !$store.state.quickMode"></Steps>

      <PanelContactInformations :locked="shouldLockPanels" v-if="$store.state.quickMode" ref="panelContact" :errorBoxContactVisible="errorBoxContactVisible"></PanelContactInformations>

      <PanelLocation :locked="shouldLockPanels" v-if="$store.state.quickMode && !$store.state.ticketMode" ref="panelLocation" :errorBoxOrderVisible="errorBoxOrderVisible"></PanelLocation>

      <PanelComment :locked="shouldLockPanels" v-if="$store.state.quickMode && !$store.state.ticketMode" ref="panelComment" :validate="$validate"></PanelComment>

      <Panel :locked="shouldLockPanels" class="loyality-card" :title="company.hasEWalletActivated() ? $t('payment.gift_card') : $t('payment.loyalty_gift_card')" icon="icons/card.svg" v-if="shouldDisplayGiftCardForm" collapsible >

        <div class="text" v-if="!company.hasEWalletActivated()">
          {{$t('payment.loyalty_explanation')}}
          <template v-if="company.isDataCandyEnabled()">{{$t('payment.loyalty_pin')}}</template>
        </div>
        <div class="text" v-if="company.hasEWalletActivated()">{{$t('payment.gift_card_explanation')}}</div>

        <div class="cols">
          <div class="panel-left">

            <form action="">
              <div v-if="$user.loyalty.getBalance() > 0 && !company.hasEWalletActivated()">
                <div class="inner-title">{{$t("payment.my_funds")}}</div>
                <div class="input" style="margin-bottom:16px;">
                  <input id="chkFidelity" type="checkbox" v-model="payWithFidelity">
                  <label for="chkFidelity">
                    {{$t('payment.fidelity_payment')}}<br>
                    <span class="important">{{$t("balance")}}: {{$tc($user.loyalty.getBalance())}}</span>
                  </label>
                </div>
                <div class="input" v-if="payWithFidelity">
                  <label>{{$t('payment.fidelity_amount')}}</label>
                  <input type="number" step="0.01" min="0" v-model="$order.fidelityPayment" placeholder="00.00">
                </div>
                <hr v-if="shouldDisplayGiftCardForm">
              </div>
              <div class="input code-promo" v-if="shouldDisplayGiftCardForm">
                <div class="inner-title" v-if="company.hasFreeBeesActivated()">{{$t("payment.gift_cards")}}</div>
                <!-- iShopFood -->
                <template v-if="!company.isDataCandyEnabled()">
                  <div class="ishop-user-giftcards" v-if="userGiftCards.length > 0">
                    <label>{{$t('payment.gift_card_payment')}}</label>
                    <div class="frame">
                      <select v-model="userGiftCard">
                        <option value="">{{$t('payment.choose_a_gift_card')}}</option>
                        <option :value="card.number" v-for="(card, cardIndex) of userGiftCards" :key="'giftcard-' + cardIndex">{{$t('card')}} #{{card.number}}</option>
                        <option value="new">{{$t('payment.add_new')}}</option>
                      </select>
                      <button type="button" class="btn btn-primary btn-remove" @click="deleteUserGiftCard"
                              v-if="userGiftCard && userGiftCard !== 'new'">
                        <svgicon src="icons/trash.svg" :height="16" :width="16"></svgicon>
                      </button>
                      <button type="button" class="btn btn-primary" @click="selectUserGiftCard" v-if="userGiftCard && userGiftCard !== 'new'">{{$t('select')}}</button>
                    </div>
                  </div>
                  <div class="frame" v-if="newGiftCard">
                    <input v-model="giftCardNumber" type="text" placeholder="0000 0000 0000 0000">
                    <button type="button" class="btn btn-primary" @click="useGiftCard">{{$t('enter')}}</button>
                  </div>
                </template>

                <!-- Data candy -->
                <template v-if="company.isDataCandyEnabled()">
                  <div class="input recaptcha-holder">
                    <label>{{$t('payment.confirm_human')}}</label>
                    <vue-recaptcha ref="recaptcha" :sitekey="CONFIG.reCaptchaKey" :loadRecaptchaScript="true" @expired="expiredReCaptcha" @verify="verifyReCaptcha($event)"></vue-recaptcha>
                  </div>
                  <div class="split">
                    <div class="input">
                      <label>{{$t('payment.gift_card_payment')}}</label>
                      <input v-model="giftCardNumber" type="text" placeholder="0000 0000 0000 0000">
                    </div>
                    <div class="input">
                      <label>{{$t('payment.gift_card_pin')}}</label>
                      <input v-model="giftCardPIN" type="text" placeholder="0000">
                    </div>
                    <button type="button" class="btn btn-primary" @click="useGiftCard">{{$t('enter')}}</button>
                  </div>
                </template>

                <!-- Gift Card Status -->
                <ul class="promo-status" v-show="giftCardValid !== null">
                  <li v-show="giftCardValid === true">
                    <svgicon src="icons/btn-valid.svg" class="icon" :height="24" :width="24"></svgicon><span>{{$t('payment.code_valid')}}</span>
                  </li>
                  <li v-show="giftCardValid === false">
                    <template v-if="company.isDataCandyEnabled()">
                      <svgicon src="icons/btn-error.svg" class="icon" :height="24" :width="24"></svgicon><span>{{$t('payment.code_or_pin_invalid')}}</span>
                    </template>
                    <template v-else>
                      <svgicon src="icons/btn-error.svg" class="icon" :height="24" :width="24"></svgicon><span>{{$t('payment.code_invalid')}}</span>
                    </template>
                  </li>
                </ul>
              </div>
            </form>

            <div class="no-inner-title" v-if="currentGiftCard && company.hasFreeBeesActivated() && !userHasFreeBeesLoyaltyCard">
              <div class="input">
                <label>{{$t('payment.gift_card_balance')}}</label>
                <input type="text" readonly disabled :value="$tc(currentGiftCard.balance.toFixed(2))">
              </div>
              <div class="input view-terms" v-if="branch.getGiftCardTerms() && branch.getGiftCardTerms().terms" @click="showGiftCardTerms">
                {{$t('gift_card.view_terms')}}
                <svgicon src="icons/arrow-right.svg" :height="14" :width="14"></svgicon>
              </div>
            </div>
          </div>

          <div class="panel-right">
            <div class="no-inner-title" v-if="currentGiftCard && (!company.hasFreeBeesActivated() || userHasFreeBeesLoyaltyCard)">
            <div class="input">
              <label>{{$t('payment.gift_card_balance')}}</label>
              <input type="text" readonly disabled :value="$tc(currentGiftCard.balance.toFixed(2))">
            </div>
            <div class="input">
              {{$t('gift_card.cancel_in_case')}}
            </div>
            <div class="input view-terms" v-if="branch.getGiftCardTerms() && branch.getGiftCardTerms().terms" @click="showGiftCardTerms">
              {{$t('gift_card.view_terms')}}
              <svgicon src="icons/arrow-right.svg" :height="14" :width="14"></svgicon>
            </div>
          </div>
            <div class="input freebees-add-card" v-if="!userHasFreeBeesLoyaltyCard">
              <div class="inner-title">{{$t("payment.freebees")}}</div>
              <div class="text add-card">{{$t('loyalty.freebees_no_card')}}</div>
              <div class="bottom">
                <div class="logo"></div>
                <button type="button" class="register btn btn-secondary" @click="redirectToProfile()">
                  <svgicon src="icons/add.svg" :height="12" :width="14"></svgicon>
                  {{$t('loyalty.add_new_card')}}
                </button>
              </div>
            </div>
          </div>

        </div>

      </Panel>

      <Panel :locked="shouldLockPanels" class="facturation" :title="$t('payment.billing')" icon="icons/bill.svg" collapsible>

        <ErrorBox boxId="errorBoxBilling" :visible="errorBoxBillingVisible" @click="errorBoxBillingVisible = false"></ErrorBox>

        <div class="text" v-if="canTip">{{$t('payment.complete_tip_promo')}}</div>
        <div class="text" v-if="!canTip" v-html="$t('payment.complete_promo')"></div>

        <div class="cols">
          <div class="panel-left">
            <form @submit.prevent>
              <div class="input code-promo">
                <label>{{$t('payment.add_promo')}}</label>
                <div class="frame">
                  <input type="text" name="code-promo" v-model="promoCode">
                  <button type="button" class="btn btn-primary" @click="checkPromoCode">{{$t('enter')}}</button>
                </div>
                <ul class="promo-status">
                  <li v-if="promoCodeStatus == 'valid'">
                    <svgicon src="icons/btn-valid.svg" class="icon" :height="24" :width="24"></svgicon>
                    <span>{{$t('payment.code_valid')}}</span>
                  </li>
                  <li v-if="promoCodeStatus == 'invalid'">
                    <svgicon src="icons/btn-error.svg" class="icon" :height="24" :width="24"></svgicon>
                    <span>{{ promoCodeMessage ? $t(promoCodeMessage).replace("{AMOUNT}", promoMinAmount) : $t('payment.code_invalid') }}</span>
                  </li>
                </ul>
              </div>

              <hr v-if="canTip">
              <!-- TODO use tip component which is a duplicate of this -->

              <div class="input" v-if="canTip">
                <label>{{$t('payment.tip_type')}}</label>
                <select v-model="tip.type">
                  <option value="none" v-if="!shouldHideNoneTipOption">{{$t('payment.none')}}</option>
                  <option value="percent">{{$t('payment.percent')}}</option>
                  <option value="amount" v-if="!shouldHideFixedAmountTipOption">{{$t('payment.fix_amount')}}</option>
                </select>
              </div>

              <ul class="tip-amount margin-top" v-if="canTip && tip.type === 'percent'">
                <li v-for="percent of company.getTipOptions()" :class="{selected: percent === tip.amount}" @click="tip.amount = percent" :key="percent">
                  {{percent}}%
                </li>
              </ul>
              <div class="input margin-top" v-if="canTip && tip.type === 'amount'">
                <label>{{$t('payment.tip_amount')}}</label>
                <input v-model="tip.amount" type="number" placeholder="00.00" step="0.01" min="0">
              </div>

            </form>

            <PaymentForm ref="paymentForm" :validate="$validate" v-if="$store.state.quickMode" @trigger-validation="validatePayment($event)"></PaymentForm>

            <template v-if="$store.state.quickMode">
              <button class="btn btn-primary btn-continue" @click="continueToValitorPayment"
                      v-if="PaymentProcessor.isPaymentMethod('valitor') && !PaymentProcessor.readyForPayment && !PaymentProcessor.valitorPayment">
                {{$t('loyalty.continue_to_payment')}}
              </button>

              <div class="no-payment" v-show="total.totalRemaining === 0">{{$t('payment.no_payment')}}</div>
            </template>

          </div>

          <div class="panel-right">
            <label>{{$t('payment.bill')}}</label>
            <div class="frame">
              <div class="row sub-total">
                <span class="label">{{$t('total.subtotal')}}</span>
                <span class="value"><span>{{ $tc(total.itemsTotal.toFixed(2)) }}</span></span>
              </div>
              <div class="row gift-card" v-if="$order.appliedPromotion.promotion && !$order.appliedPromotion.promotion.taxIncluded">
                <div class="dot"></div>
                <div class="label">
                  {{ $to($order.appliedPromotion.promotion.name) }}
                  ({{ $order.appliedPromotion.promotion.value }}{{ $order.appliedPromotion.promotion.type == 'flat' ? '$' : '%' }})
                </div>
                <div class="value">- {{ $tc(($order.appliedPromotion.value).toFixed(2)) }}</div>
                <div class="icon removeIcon" @click="removePromotion()"
                     v-if="$order.appliedPromotion.promotion.code">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row gift-card" v-if="$order.accountPayment > 0 && branch && branch.shouldApplyLoyaltyPaymentOnSubtotal()">
                <div class="dot"></div>
                <span class="label">{{$t('payment.account')}}</span>
                <span class="value"><span>- {{$tc(parseFloat($order.accountPayment).toFixed(2))}}</span></span>
                <div class="icon removeIcon" @click="$order.accountPayment = 0">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="fees" v-if="total.serviceFees">
                <div class="label">{{$t('total.service_fees')}}</div>
                <div class="amount">{{ $tc(total.serviceFees.toFixed(2)) }}</div>
              </div>
              <div class="fees" v-if="total.deliveryFees">
                <div class="label">{{$t('total.delivery_fees')}}</div>
                <div class="amount">{{ $tc(total.deliveryFees.toFixed(2)) }}</div>
              </div>
              <div class="row tax" v-for="tax of (company ? company.taxes : [])" :key="tax.id">
                <span class="label">{{$to(tax.name)}}<span v-if="tax.taxNumber"> ({{ tax.taxNumber }})</span></span>
                <span class="value">{{ $tc(total.taxes[tax.uniqueName].toFixed(2)) }}</span>
              </div>
              <div class="row tip" v-if="total.tip > 0">
                <div class="dot"></div>
                <span class="label">{{$t('total.tip')}}</span>
                <span class="value">{{$tc(total.tip.toFixed(2))}}</span>
                <div class="removeTip removeIcon" @click="$order.removeTip()">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row gift-card" v-if="$order.appliedPromotion.promotion && $order.appliedPromotion.promotion.taxIncluded">
                <div class="label">
                  {{ $to($order.appliedPromotion.promotion.name) }}
                  ({{ $order.appliedPromotion.promotion.value }}{{ $order.appliedPromotion.promotion.type == 'flat' ? '$' : '%' }})
                </div>
                <div class="value">-{{ $tc($order.appliedPromotion.value.toFixed(2)) }}</div>
                <div class="removeIcon icon" @click="PromotionManager.removePromotion($order.appliedPromotion.promotion)"
                     v-if="$order.appliedPromotion.promotion.code">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row total">
                <span class="label">{{$t("total.total")}}</span>
                <span class="value">{{$tc((total.total + total.tip).toFixed(2))}}</span>
              </div>
              <div class="row gift-card" v-for="(payment, paymentIndex) of $order.onlinePayments" :key="'payment-' + paymentIndex" v-if="payment.processor == 'prepaid'">
                <div class="dot"></div>
                <span class="label">
                  {{$t('payment.gift_card')}} <span class="small" style="margin-left: 4px;"> &nbsp;(#{{ payment.from }})</span>
                </span>
                <span class="value"><span>- {{$tc(payment.amount.toFixed(2))}}</span></span>
                <div class="icon removeIcon" @click="removeOnlinePayment(paymentIndex)">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row gift-card" v-if="$order.fidelityPayment > 0">
                <div class="dot"></div>
                <span class="label">
                  {{$t('payment.fidelity_card')}} <span class="small" style="margin-left: 4px;">(#{{ $user.loyalty.getId() }})</span>
                </span>
                <span class="value"><span>- {{$tc(parseFloat($order.fidelityPayment).toFixed(2))}}</span></span>
                <div class="icon removeIcon" @click="$order.fidelityPayment = 0">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row gift-card" v-if="$order.accountPayment > 0 && branch && !branch.shouldApplyLoyaltyPaymentOnSubtotal()">
                <div class="dot"></div>
                <span class="label">
                  {{$t('loyalty.my_account')}}
                </span>
                <span class="value"><span>- {{$tc(parseFloat($order.accountPayment).toFixed(2))}}</span></span>
                <div class="icon removeIcon" @click="$order.accountPayment = 0">
                  <svgicon src="icons/btn-delete.svg" class="icon" :height="20" :width="20"></svgicon>
                </div>
              </div>
              <div class="row" :class="{tax: !shouldDisplayTotalRemaining, total: shouldDisplayTotalRemaining}" v-if="$store.state.quickMode || shouldDisplayTotalRemaining" style="padding-top: 9px;">
                <span class="label">{{$t('payment.total_remaining')}}</span>
                <span class="value">{{$tc(total.totalRemaining.toFixed(2))}}</span>
              </div>
            </div>
            <div class="total-warning" v-if="shouldDisplayGiftCardNotApplicableWarning">
              <svgicon src="icons/info.svg" :height="15" :width="15"></svgicon>
              <span v-html="$t('payment.not_applicable_loyalty_gift_card').replace('{AMOUNT}', $tc(total.totalNotApplicableForLoyaltyOrGiftCard.toFixed(2)))"></span>
            </div>
          </div>

        </div>

      </Panel>

      <Panel :locked="shouldLockPanels" class="loyalty-account" :title="$t('payment.account')" icon="icons/wallet.svg" collapsible
             v-if="company && company.hasEWalletActivated() && $user.getLoyaltyAccount() && !$user.isAnonymous()">

        <ErrorBox boxId="errorBoxAccount" :visible="errorBoxAccountVisible" @click="errorBoxAccountVisible = false"></ErrorBox>

        <div class="cols">
          <div class="panel-left">
            <div class="input">
              <label>{{$t('payment.selected_account')}}</label>
              <select v-model="$order.accountId">
                <template v-for="account of $user.getAccountsForPayment()">
                  <option :value="account.id">{{getAccountDisplayName(account)}}</option>
                </template>
              </select>
            </div>
            <div class="input" v-if="$order.accountId">
              <label>{{$t('payment.account_amount_available')}}</label>
              <input type="text" :value="$user.getLoyaltyAccount($order.accountId).balance.toFixed(2) + ' $'" disabled class="disabled">
            </div>
          </div>
          <div class="panel-right">
            <div class="input" v-if="$order.accountId">
              <label>{{$t('payment.account_amount_used')}}</label>
              <input type="number" step="0.01" min="0"  :value="$order.accountPayment" @input="onAccountPaymentChange($event.target.value)"  :class="{invalid: $validate.error('$order.accountPayment')}"
                     placeholder="00.00">
            </div>

            <button type="button" class="recharge btn btn-secondary" @click="redirectToLoyaltyAccountRecharge()"
                    v-if="accountMissingFunds && isRechargeEnabled">
              <svgicon src="icons/reload.svg" :height="16" :width="16" :style="{fill: secondaryButtonTextColor}"></svgicon>
              {{$t('loyalty.reload_account')}}
            </button>
          </div>
        </div>
      </Panel>

      <Panel :locked="shouldLockPanels" ref="payment" class="payment" :title="$t('payment.payment')" icon="icons/location.svg" v-if="!$store.state.quickMode" collapsible
             :collapsed="company && company.hasEWalletActivated()">

        <ErrorBox boxId="errorBoxPayment" :visible="errorBoxPaymentVisible" @click="errorBoxPaymentVisible = false"></ErrorBox>

        <div class="cols">
          <div class="panel-left">
            <PaymentForm :validate="$validate" @trigger-validation="validatePayment($event)"></PaymentForm>

            <button class="btn btn-primary btn-continue" @click="continueToValitorPayment"
                    v-if="PaymentProcessor.isPaymentMethod('valitor') && !PaymentProcessor.readyForPayment && !PaymentProcessor.valitorPayment">
              {{$t('loyalty.continue_to_payment')}}
            </button>

            <div class="no-payment" v-show="total.totalRemaining === 0">{{$t('payment.no_payment')}}</div>
          </div>
          <div class="panel-right">
            <label>{{$t('payment.bill')}}</label>
            <div class="frame">
              <div class="row sub-total">
                <span class="label">{{$t('payment.total_remaining')}}</span>
                <span class="value"><span>{{$tc(total.totalRemaining.toFixed(2))}}</span></span>
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <Panel :title="$t('payment.online_payment_by_global')" icon="icons/card.svg" collapsible
             v-if="hasPaymentMethod('global')" v-show="PaymentProcessor.globalInitialized && PaymentProcessor.readyForPayment">
        <iframe id="globalIframe" frameborder="0" style="width: 100% !important;"></iframe>
      </Panel>

      <Panel :title="$t('payment.online_payment_by_valitor')" icon="icons/card.svg" collapsible
             v-if="hasPaymentMethod('valitor')"
             v-show="PaymentProcessor.isPaymentMethod('valitor') && PaymentProcessor.valitorInFocus && PaymentProcessor.readyForPayment">
        <iframe id="iframeValitor" class="valitor-iframe" ref="valitorIframe"></iframe>
      </Panel>

      <div class="filler"></div>

      <div class="buttons">
        <router-link :to="{name: $store.state.quickMode ? ($store.state.ticketMode ? 'tickets' : 'menu') : 'paymentContact'}" class="btn back">
          {{$t('back')}}
        </router-link>
        <button type="button" class="btn next" id="btnPay" v-if="!PaymentProcessor.shouldHideDefaultOrderButton()" @click="onCompleteClick">
          <template v-if="PaymentProcessor.isPaymentMethod('payfacto') && !PaymentProcessor.usePayFactoSHC">{{$t('payment.pay_and_confirm')}}</template>
          <template v-else>{{$t('payment.confirm_order')}}</template>
        </button>
      </div>

    </div>

    <div class="modal insufficient-balance">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/warning.svg" :height="24" :width="24"></svgicon>
          <span class="title">{{$t("payment.loyalty_no_funds")}}</span>
          <svgicon class="icon btn-close" src="icons/btn-delete.svg" :height="32" :width="32"></svgicon>
        </div>
        <div class="modal-body">
          <img src="@/assets/img/img-cadeau-1.png">
          <div class="msg">{{$t("payment.gift_card_error")}}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary">{{$t("payment.back_to_payment")}}</button>
        </div>
      </div>
    </div>

    <div class="modal promo-added">
      <div class="modal-content">
        <div class="modal-header">
          <svgicon class="icon" src="icons/smiley.svg" :height="24" :width="24"></svgicon>
          <span class="title">Promotion ajoutée</span>
          <svgicon class="icon btn-close" src="icons/btn-delete.svg" :height="32" :width="32"></svgicon>
        </div>
        <div class="modal-body">
          <img src="@/assets/img/img-promo.png">
          <div class="msg">Félicitations! Vous avez obtenu la promotion et ce produit a été ajouté à votre panier. Bon appétit!</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary">Retour au paiement</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Steps from "@/components/payment/Steps.vue";
  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import PanelContactInformations from "@/components/payment/PanelContactInformations.vue";
  import PanelLocation from "@/components/payment/PanelLocation.vue";
  import PanelComment from "@/components/payment/PanelComment.vue";
  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import PaymentForm from "@/components/payment/PaymentForm.vue";
  import Promotion from "../../models/PromotionModel";
  import { mapActions } from "vuex";
  import VueRecaptcha from 'vue-recaptcha';
  import { PaymentProcessor } from "../../PaymentProcessor";
  import { PromotionManager } from "../../PromotionManager";
  import EventBus from "../../lib/eventBus.js";

  export default {

    name: "paymentConfirmation",

    components: { Steps, ErrorBox, PanelContactInformations, PanelLocation, MenuHeader, PaymentForm, PanelComment, VueRecaptcha },

    data() {
      return {
        promoCode: "",
        promoCodeStatus: null,
        promoCodeMessage: null,
        promoMinAmount: 0,
        payWithFidelity: false,
        saveCard: false,

        accountMissingFunds: false,

        errorBoxBillingVisible: false,
        errorBoxPaymentVisible: false,
        errorBoxContactVisible: false,
        errorBoxOrderVisible: false,
        errorBoxAccountVisible: false,

        userGiftCard: "",
        giftCardNumber: "",
        giftCardPIN: "",
        reCaptchaToken: null,
        currentGiftCard: null,
        giftCardValid: null,

        inactivityTimer: 0,
        inactivityInterval: null,
        securePaymentIcon: {fr: 'icons/secure-fr.svg', en: 'icons/secure-en.svg' },

        PaymentProcessor,
        PromotionManager,
        CONFIG
      }
    },

    validate: {
      "$order.accountPayment": {
        required: false,
        number: true,
        min: 0,
        condition: function() { return this.$order.accountId; }
      },
      "$order.comment": {
        maxLength: 500,
        condition: function() { return this.$store.state.quickMode; }
      },
      "PaymentProcessor.paymentMethod": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0; }
      },
      "PaymentProcessor.cardholderName": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardholderName"); }
      },
      "PaymentProcessor.cardBillingPostalCode": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingPostalCode"); }
      },
      "PaymentProcessor.cardCountry": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardCountry"); }
      },
      "PaymentProcessor.cardBillingAddress": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingAddress"); }
      },
      "PaymentProcessor.cardBillingCity": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingCity"); }
      },
      "PaymentProcessor.cardBillingState": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardBillingState"); }
      },
      "PaymentProcessor.cardFirstName": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardFirstName"); }
      },
      "PaymentProcessor.cardLastName": {
        required: true,
        condition: function () { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardLastName"); }
      },
      "PaymentProcessor.cardNumber": {
        required: true,
        regex: /[0-9 ]+/,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cardNumber"); }
      },
      "PaymentProcessor.cvv": {
        required: true,
        regex: /[0-9]+/,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("cvv"); }
      },
      "PaymentProcessor.expMonth": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("expMonth"); }
      },
      "PaymentProcessor.expYear": {
        required: true,
        condition: function() { return this.total.totalRemaining > 0 && PaymentProcessor.isFieldRequired("expYear"); }
      }
    },

    computed: {
      total() {
        return this.$store.state.order.getOrderTotal();
      },
      order() {
        return this.$order;
      },
      tip() {
        return this.$order.tip;
      },
      shouldLockPanels() {
        return PaymentProcessor.isPaymentMethod("valitor") && PaymentProcessor.valitorInFocus;
      },
      userGiftCards() {
        return this.$user.getiShopFoodGiftCards() || [];
      },
      newGiftCard() {
        return this.userGiftCard === "new" || this.userGiftCards.length === 0;
      },
      paymentMethods() {
        if (!this.company) {
          return [];
        }
        return this.company.getPaymentMethods();
      },
      hasPaymentMethod() {
        return method => {
          return this.paymentMethods.indexOf(method) > -1;
        };
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      isRechargeEnabled() {
        return this.branch && this.branch.isRechargeEnabled();
      },
      giftCardPaymentAmount() {
        let filtered = this.$order.onlinePayments.filter(p => p.processor === "prepaid");
        let amount = 0;
        for (let payment of filtered) {
          amount += payment.amount;
        }
        return amount;
      },
      shouldDisplayGiftCardNotApplicableWarning() {
        return this.giftCardPaymentAmount > 0 && this.$order.accountPayment === 0 && this.total && this.total.totalNotApplicableForLoyaltyOrGiftCard > 0;
      },
      shouldDisplayGiftCardForm() {
        return this.company && this.branch && (this.company.isDataCandyOrFreeBeesEnabled() || this.branch.isiShopFoodGiftCardEnabledForCurrentCompany());
      },
      shouldHideNoneTipOption() {
        return this.company && this.company.shouldHideNoneTipOption();
      },
      shouldHideFixedAmountTipOption() {
        return this.company && this.company.shouldHideFixedAmountTipOption();
      },
      shouldDisplayTotalRemaining() {
        return this.giftCardPaymentAmount > 0 || this.$order.fidelityPayment > 0 || this.$order.accountPayment > 0;
      },
      inAdvanceEnabled() {
        if (!this.company) {
          return false;
        }
        return this.company.getOpenHours(this.$order.method) && this.company.getOpenHours(this.$order.method).inAdvance;
      },
      canTip() {
        if (!this.company) {
          return false;
        }
        return this.company.hasTipEnabled(this.$order.method) && PaymentProcessor.isOnlinePaymentMethod();
      },
      secondaryButtonTextColor() {
        return this.$store.state.secondaryButtonTextColor || "#fff";
      },
      userHasFreeBeesLoyaltyCard() {
        if (!this.company.hasFreeBeesActivated()) {
          return true;
        }
        return this.$user.hasLoyaltyAccountId();
      },
    },

    mounted() {
      if (!this.$order.hasItems() || !this.$user.token || !this.company || this.company.isCurrentMethodClosed()) {
        this.$router.push(this.$router.generate(this.$store.state.ticketMode ? "/tickets" : "/menu"));
        return;
      }
      if (!this.$user) {
        this.$router.push(this.$router.generate("/login?redirect=paymentConfirmation"));
        return;
      }
      this.$store.commit("showNavigationCart", true);

      this.loadUserLoyaltyAccountDetails();
      this.initializePaymentProcessorAndPaymentMethods();
      this.initializePageEvents();
      this.setPreselectedTip();
      this.checkIfUserHasGiftCardInSession();
    },

    watch: {
      "tip.type": function(newValue, oldValue) {
        this.tip.amount = null;
      },
      canTip: function() {
        this.setPreselectedTip();
      },
      userGiftCard: function() {
        this.removeGiftCardPayment();
      },
      giftCardNumber: function(newValue) {
        this.giftCardNumber = newValue.replace(/\D+/g, '');
        this.currentGiftCard = null;
        this.giftCardValid = null;
      },
      "$order.accountId": function(newAccountId) {
        if (this.branch.shouldBypassAndDisableLoyaltyProgram() || !this.branch.shouldApplyLoyaltyPaymentAutomatically()) {
          this.noAccountPayment();
          return;
        }
        if (newAccountId) {
          this.$order.accountPayment = this.$user.getLoyaltyAccount(this.$order.accountId).balance;
          this.checkIfAccountFundsMissing();
        } else {
          this.accountMissingFunds = false;
          this.$refs.payment.open();
        }
      },
      "$order.fidelityPayment": function(newValue) {
        //TODO duplicate here and not sure it should be calculated here in the first place, giftCard payment calculation is better
        let notApplicableAmount = this.total.totalNotApplicableForLoyaltyOrGiftCard;
        let maxFidelityPayment = parseFloat(((this.total.total + this.total.tip) - this.giftCardPaymentAmount - notApplicableAmount).toFixed(2));
        if (newValue > this.$user.loyalty.getBalance()) {
          this.$order.fidelityPayment = this.$user.loyalty.getBalance();
        } else if (newValue > maxFidelityPayment) {
          this.$order.fidelityPayment = maxFidelityPayment;
        }
      },
      payWithFidelity(newVal) {
        //TODO duplicate here and not sure it should be calculated here in the first place, giftCard payment calculation is better
        if (!newVal) {
          this.$order.fidelityPayment = 0;
        } else {
          let notApplicableAmount = this.total.totalNotApplicableForLoyaltyOrGiftCard;
          let maxFidelityPayment = parseFloat(((this.total.total + this.total.tip) - this.giftCardPaymentAmount - notApplicableAmount).toFixed(2));
          this.$order.fidelityPayment = Math.min(maxFidelityPayment, this.$user.loyalty.getBalance());
        }
      },
      "$order.hour"() {
        if (this.hasPaymentMethod("global")) {
          PaymentProcessor.readyForPayment = false;
        }
      },
      "$order.method"(newVal) {
        if (!PaymentProcessor.isOnlinePaymentMethod()) {
          this.$order.tip.amount = null;
        }
        if (this.hasPaymentMethod("global")) {
          PaymentProcessor.readyForPayment = false;
        }
        if (this.company.isDoorDashActivated() && newVal === "delivery") {
          this.setPreselectedTip();
        }
      },
      "$order.items"(newValue) {
        if (newValue.length == 0) {
          this.$router.push(this.$router.generate("/menu"));
        }
      },
      "PaymentProcessor.paymentMethod"(newValue) {
        if (!this.canTip) {
          this.tip.amount = 0;
        } else {
          this.tip.amount = null;
        }
      },
      "PaymentProcessor.cardCountry"() {
        PaymentProcessor.cardBillingState = "";
      },
      $user: {
        deep: true,
        handler() {
          if (this.hasPaymentMethod("global")) {
            PaymentProcessor.readyForPayment = false;
          }
        }
      }
    },

    beforeDestroy() {
      PaymentProcessor.destroyPayfacto();
      this.$order.accountPayment = 0;
      this.$order.fidelityPayment = 0;
      EventBus.$off("submit-payment");
      if (this.inactivityInterval) {
        this.killInactivityTimer();
      }
    },

    methods: {

      ...mapActions({
        giftCardBalanceAction: "giftCardBalance",
        loyaltyBalanceAction: "loyaltyBalance",
        checkPromoCodeAction: "checkPromoCode",
        getGiftCardInformationAction: "getGiftCardInformation"
      }),

      removeGiftCardPayment() {
        let giftCardPayment = this.$order.getGiftCardPayment();
        if (giftCardPayment) {
          let index = this.$order.onlinePayments.indexOf(giftCardPayment);
          if (index > -1) {
            this.removeOnlinePayment(index, true);
            this.currentGiftCard = null;
            this.giftCardValid = null;
          }
        }
      },

      togglePaymentPanel() {
        if (!this.$refs.payment) {
          return;
        }

        if (this.accountMissingFunds || this.$order.getOrderTotal().totalRemaining > 0) {
          this.$refs.payment.open();
        } else {
          this.$refs.payment.close();
        }
      },

      verifyReCaptcha(token) {
        this.reCaptchaToken = token;
      },

      expiredReCaptcha() {
        this.reCaptchaToken = null;
      },

      checkIfUserHasGiftCardInSession() {
        let number = getConfiguration("gift-card", sessionStorage);
        if (number) {
          this.giftCardNumber = number;
          this.useGiftCard();
        } else {
          let giftCardPayment = this.$order.getGiftCardPayment();
          if (giftCardPayment) {
            this.giftCardNumber = giftCardPayment.from;
            this.useGiftCard();
          }
        }
      },

      setPreselectedTip() {
        if (this.canTip && this.shouldHideNoneTipOption) {
          this.tip.type = "percent";
        }
        this.$nextTick(() => {
          if (this.canTip && this.company && this.company.getPreselectedTipValue()) {
            this.tip.amount = this.company.getPreselectedTipValue();
          }
        });
      },

      showGiftCardTerms() {
        if (!this.currentGiftCard || !this.branch.getGiftCardTerms() || !this.branch.getGiftCardTerms().terms) {
          return;
        }
        showAffirmation(this.$t("terms"), this.$to(this.branch.getGiftCardTerms().terms)
                + "<br><br>"
                + this.$to(this.branch.getGiftCardTerms().advancedTerms), false, false, false, "terms");
      },

      initializePaymentProcessorAndPaymentMethods() {
        PaymentProcessor.initializePaymentMethod();
        let paymentMethods = this.company.getPaymentMethods();
        if (this.$order.paymentMethod && paymentMethods.indexOf(this.$order.paymentMethod) > -1) {
          PaymentProcessor.setPaymentMethod(this.$order.paymentMethod);
        } else if (!this.company.hasOfflinePaymentMethods()) {
          PaymentProcessor.setPaymentMethod(this.paymentMethods[0]);
        }
      },

      initializeInactivityTimer() {
        if (this.inactivityInterval) {
          this.killInactivityTimer();
        }
        let maxInactivityTimer = 180; //seconds
        this.inactivityInterval = setInterval(async () => {
          if (this.inactivityTimer >= maxInactivityTimer) {
            clearInterval(this.inactivityInterval);
            await showAffirmation(this.$t('payment.inactive_title'),this.$t('payment.inactive_description'));
            this.$router.push(this.$router.generate("/paymentContact"));
          } else {
            this.inactivityTimer++;
          }
        }, 1000); //1 second
        document.body.addEventListener("click", this.resetInactivityTimer);
        document.body.addEventListener("touchstart", this.resetInactivityTimer);
      },

      resetInactivityTimer() {
        this.inactivityTimer = 0;
      },

      killInactivityTimer() {
        document.body.removeEventListener("click", this.resetInactivityTimer);
        document.body.removeEventListener("touchstart", this.resetInactivityTimer);
        clearInterval(this.inactivityInterval);
      },

      initializePageEvents() {
        EventBus.$on("submit-payment", () => {
          this.submit();
        });
        EventBus.$on("open-payment-panel", () => {
          this.$refs.payment.open();
        });
      },

      async validatePayment(callback) {
        PaymentProcessor.readyForPayment = await this.validateReadyForPayment();
        if (callback && typeof callback === "function") {
          callback();
        }
      },

      loadUserLoyaltyAccountDetails() {
        if (this.$user && this.$user.getLoyaltyAccount()) {
          if (!this.$order.accountId || !this.$user.getLoyaltyAccount(this.$order.accountId)) {
            this.$order.accountId = this.$user.getLoyaltyAccount().id;
          }
          if (!this.branch.shouldBypassAndDisableLoyaltyProgram() && this.branch.shouldApplyLoyaltyPaymentAutomatically()) {
            this.$order.accountPayment = Math.max(this.$user.getLoyaltyAccount(this.$order.accountId).balance, 0);
            this.checkIfAccountFundsMissing();
          } else {
            this.noAccountPayment();
          }
        }
      },

      onAccountPaymentChange(newValue) {
        this.validateAccountPayment(newValue);
        this.useGiftCard();
      },

      validateAccountPayment(newValue) {
        if (this.branch.shouldBypassAndDisableLoyaltyProgram() || !this.$user.getLoyaltyAccount(this.$order.accountId) || newValue < 0) {
          this.noAccountPayment();
          return;
        }

        if (!newValue) {
          return;
        }

        let notApplicableAmount = this.total.totalNotApplicableForLoyaltyOrGiftCard;
        let availableAccountPaymentOnOrder = this.branch.shouldApplyLoyaltyPaymentOnSubtotal()
          ? parseFloat((this.total.itemsTotal - this.total.promotionTotal - notApplicableAmount).toFixed(2))
          : parseFloat(((this.total.total + this.total.tip) - notApplicableAmount).toFixed(2));
        let balance = Math.max(this.$user.getLoyaltyAccount(this.$order.accountId).balance, 0);

        let maxApplicableAccountPayment = Math.min(availableAccountPaymentOnOrder, balance);
        let parsedNewValue = newValue.replace(/^0+/, '');//remove leading 0s.
        if (parsedNewValue > maxApplicableAccountPayment) {
          this.$order.accountPayment = maxApplicableAccountPayment;
        } else {
          this.$order.accountPayment = parsedNewValue;
        }
        this.accountMissingFunds = this.$user.getLoyaltyAccount(this.$order.accountId).balance < availableAccountPaymentOnOrder;
        this.togglePaymentPanel();
      },

      noAccountPayment() {
        this.$order.accountPayment = 0;

        if (this.$refs.payment) {
          this.$refs.payment.open();
        }

      },
      //TODO refactor this duplicated function (5x)
      formatHour(hour) {
        if (hour == "asap") {
          return this.$t("order.asap");
        }
        if (this.$store.state.currentCompany.hourFormat == "12") {
          let parsed = moment(hour, "HH:mm");
          if (!parsed.isValid()) {
            return null;
          }
          return parsed.format("hh:mm A");
        }
        return hour;
      },

      checkIfAccountFundsMissing() {
        let maxAccountPayment = parseFloat(((this.total.total + this.total.tip) - this.giftCardPaymentAmount).toFixed(2));
        this.accountMissingFunds = this.$user.getLoyaltyAccount(this.$order.accountId).balance < maxAccountPayment;
        this.togglePaymentPanel();
      },

      closeModal(id) {
        document.getElementById(id).classList.remove("show");
      },

      removePromotion() {
        PromotionManager.removePromotion(this.$order.appliedPromotion.promotion);
        this.useGiftCard();
      },

      async useGiftCard() {
        if (!this.giftCardNumber) {
          return;
        }
        if (this.company.isDataCandyEnabled() && !this.reCaptchaToken) {
          showAffirmation(this.$t('warning'), this.$t('payment.recaptcha_missing'));
          return;
        }
        if (this.currentGiftCard && this.currentGiftCard.balance && this.currentGiftCard.balance > 0.001 && this.currentGiftCard.number === this.giftCardNumber) {
            this.$order.addGiftCardPayment(this.currentGiftCard.number, this.currentGiftCard.balance);
            this.assignGiftCardNumberToUserGiftCard();
            return;
        }
        showSpinner();
        if (this.branch.isiShopFoodGiftCardEnabledForCurrentCompany()) {
          let response = await this.getGiftCardInformationAction({cardNumber: this.giftCardNumber, branchID: this.branch.id});
          hideSpinner();
          if (response.success) {
            this.giftCardValid = true;
            if (response.card.balance > 0.001) {
              this.$order.addGiftCardPayment(response.card.number, response.card.balance);
              this.payWithFidelity = false;
              this.fidelityPayment = 0;
              this.currentGiftCard = {
                balance: response.card.balance,
                number: response.card.number
              }
              this.assignGiftCardNumberToUserGiftCard();
            } else {
              showAffirmation(this.$t("payment.insufficient_funds"), this.$t("payment.gift_card_balance") + this.$tc(response.card.balance.toFixed(2)));
              this.giftCardValid = false;
              this.giftCardNumber = "";
            }
          } else {
            this.giftCardValid = false;
          }
        } else if (this.company.isDataCandyOrFreeBeesEnabled()) {
          try {
            let response = await this.giftCardBalanceAction({ cardNumber: this.giftCardNumber, company: this.company.id, recaptcha: this.reCaptchaToken, pin: this.giftCardPIN });
            hideSpinner();
            if (response.success) {
              this.giftCardValid = true;
              if (response.profile.amount > 0.001) {
                this.$order.addGiftCardPayment(response.profile.number, response.profile.amount, this.giftCardPIN);
                this.currentGiftCard = {
                  balance: response.profile.amount,
                  number: response.profile.number
                }
              } else {
                showAffirmation(this.$t("payment.insufficient_funds"), this.$t("payment.gift_card_balance") + this.$tc(response.profile.amount.toFixed(2)));
              }
            } else {
              if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
                this.reCaptchaToken = null;
              }
              this.giftCardValid = false;
            }
          } catch (e) {
            hideSpinner();
            showAffirmation(this.$t("error.title"), this.$t('profile.error_card'));
          }
        } else {
          hideSpinner();
        }
      },

      async assignGiftCardNumberToUserGiftCard() {
        if (!this.$user.isAnonymous() && !this.$user.isDispatchUser()) {
          this.$user.addiShopFoodGiftCard(this.giftCardNumber);
          this.userGiftCard = this.giftCardNumber;
          showSpinner();
          await this.$user.saveGiftCards();
          hideSpinner();
          this.$nextTick(() => {
            this.$forceUpdate();
          })
        }
      },

      removeOnlinePayment(index, keepUserGiftCard) {
        this.$order.onlinePayments.splice(index, 1);
        this.giftCardNumber = "";
        if (!keepUserGiftCard) {
          this.userGiftCard = "";
        }
      },

      async onCompleteClick() {
        if (PaymentProcessor.isPaymentMethod("payfacto")) {
          if (await this.validateReadyForPayment()) {
            if (PaymentProcessor.usePayFactoSHC) {
              PaymentProcessor.sendSubmitForPayFactoSHC();
            } else {
              PaymentProcessor.showPayfactoModal();
            }
          }
        } else if (PaymentProcessor.isPaymentMethod("nmi") && PaymentProcessor.saveCardEnabled() && PaymentProcessor.savedCardID) {
          if (await this.validateReadyForPayment()) {
            PaymentProcessor.submitNmiWithSavedCard();
          }
        } else {
          this.submit();
        }
      },

      async continueToValitorPayment() {
        await this.validatePayment();
        if (PaymentProcessor.readyForPayment) {
          PaymentProcessor.payWithValitor({ $iFrameRef: this.$refs.valitorIframe });
        }
      },

      async validateReadyForPayment() {
        if (this.$refs.panelLocation && this.$refs.panelContact) {
          let locationValid = await this.$refs.panelLocation.submit();
          let contactValid = await this.$refs.panelContact.validate();
          if (!contactValid) {
            this.$refs.panelContact.open();
            this.$refs.panelContact.$validate.validateAllOnce();
            this.showContactErrorBox();
          }
          if (!locationValid) {
            this.$refs.panelLocation.open();
            this.$refs.panelLocation.$validate.validateAllOnce();
            this.showOrderErrorBox();
          }
          if (!locationValid || !contactValid) {
            return false;
          }
        }

        if (!PaymentProcessor.paymentMethod && this.$order.getOrderTotal().totalRemaining > 0) {
          this.errorBoxPaymentVisible = true;
          this.showPaymentErrorBox();
          return false;
        }

        if (PaymentProcessor.isPaymentMethod('payfacto') && !PaymentProcessor.savedCardID
            && PaymentProcessor.usePayFactoSHC && !PaymentProcessor.payFactoSHCReadyForPayment) {
          this.errorBoxPaymentVisible = true;
          this.showPaymentErrorBox();
          return false;
        }

        if (this.company.hasPaymentAfterPickupEnabled() && this.$order.method === "takeout" && typeof this.$order.paymentOnly !== "boolean") {
          showAffirmation(this.$t("payment.missing_payment_only"), this.$t("payment.missing_payment_only_description"));
          let selector = document.getElementById("selectPaymentOnly");
          if (selector) {
            selector.scrollIntoView({behavior: "smooth"});
          }
          return false;
        }

        if (!this.$order.validateMinimumAmount()) {
          return false;
        }

        if (!this.$order.validateOrderRequirements()) {
          return false;
        }

        let valid = this.$order.validateAddedItems();
        if (!valid) {
          if (PaymentProcessor.paymentMethod === "payfacto") {
            PaymentProcessor.removePayment();
          }
          return false;
        }

        if ((this.tip.amount == null || this.tip.amount == "") && this.canTip && this.shouldHideNoneTipOption) {
          await showAffirmation(this.$t("payment.tip"), this.$t("payment.tip_missing"));
          return false;
        }

        if (this.$order.method === "delivery" && this.company.isDoorDashActivated() && !(await this.$order.validateForDoorDash())) {
          return false;
        }

        if (!this.inAdvanceEnabled && (this.company.isClosed() || this.company.isCurrentMethodClosed())) {
          await showAffirmation(this.$t("emergency_close_title"), this.$t("inAdvance.closed_no_hour"));
          return false;
        }

        //Validate room
        if (PaymentProcessor.isPaymentMethod('room-charge') && !this.company.validateRoom(this.$order.roomNumber)) {
          showAffirmation(this.$t('error.title'), this.$t('payment.invalid_room'));
          return;
        }

        await this.$store.dispatch("getStatsPerIntervalForCompany", { branchId: this.branch.id, company: this.company, method: this.$order.method});

        let availableHours = this.company.getAvailableOpenHours().filter(h => this.company.isIntervalAvailable(h));
        if (!this.$order.paymentOnly && this.inAdvanceEnabled && !this.$store.state.ticketMode &&
            (availableHours.indexOf(this.$order.hour) === -1 || !this.company.isIntervalAvailable(this.$order.hour))) {
          if (availableHours.length === 0) {
            await showAffirmation(this.$t("emergency_close_title"), this.$t("inAdvance.closed_no_hour"));
            return false;
          } else {
            let response = await showConfirmation(this.$t("error.hour_unavailable"),
                    this.$t("error.hour_unavailable_text") + this.formatHour(availableHours[0]) + "?");
            if (!response) {
              return false;
            }
            this.$order.hour = availableHours[0];
          }
        }

        if (this.$order.items.length === 0) {
          return false;
        }

        if (!this.$validate.valid()) {
          this.$validate.validateAllOnce();
          this.$forceUpdate();

          this.showContactErrorBox();
          this.showOrderErrorBox();
          this.showAccountErrorBox();
          this.showPaymentErrorBox();

          return false;
        }

        return true;
      },

      showOrderErrorBox() {
        if (document.getElementById("errorBoxOrder") && document.getElementById("errorBoxOrder").parentElement.getElementsByClassName("invalid").length > 0) {
          this.errorBoxOrderVisible = true;
          scrollToError();
        } else {
          this.errorBoxOrderVisible = false;
        }
      },

      showAccountErrorBox() {
        if (document.getElementById("errorBoxAccount") && document.getElementById("errorBoxAccount").parentElement.getElementsByClassName("invalid").length > 0) {
          this.errorBoxAccountVisible = true;
          scrollToError();
        } else {
          this.errorBoxAccountVisible = false;
        }
      },

      showPaymentErrorBox() {
        if (this.$store.state.quickMode) {
          this.showBillingErrorBox();
          return;
        }
        if (document.getElementById("errorBoxPayment")) {
          this.errorBoxPaymentVisible = true;
          scrollToError();
        } else {
          this.errorBoxPaymentVisible = false;
        }
      },

      selectUserGiftCard() {
        if (this.userGiftCard && this.userGiftCard !== "new") {
          this.giftCardNumber = this.userGiftCard;
          this.useGiftCard();
        }
      },

      async deleteUserGiftCard() {
        if (this.userGiftCard && this.userGiftCard !== "new") {
          let response = await showConfirmation(this.$t("payment.remove_gift_card"), this.$t("payment.remove_gift_card_desc").replace("{NUMBER}", this.userGiftCard));
          if (!response) {
            return;
          }
          this.$user.deleteGiftCard(this.userGiftCard);
          this.giftCardNumber = "";
          this.userGiftCard = "";
          showSpinner();
          await this.$user.saveGiftCards();
          hideSpinner();
        }
      },

      showContactErrorBox() {
        if (document.getElementById("errorBoxContact") && document.getElementById("errorBoxContact").parentElement.getElementsByClassName("invalid").length > 0) {
          this.errorBoxContactVisible = true;
          scrollToError();
        } else {
          this.errorBoxContactVisible = false;
        }
      },

      showBillingErrorBox() {
        if (document.getElementById("errorBoxBilling")) {
          this.errorBoxBillingVisible = true;
          scrollToError();
        } else {
          this.errorBoxBillingVisible = false;
        }
      },

      async submit() {
        if (!this.$order.accountPayment) {
          this.$order.accountPayment = 0.00;
        }
        if (await this.validateReadyForPayment()) {
          this.$order.setPaymentMethod(PaymentProcessor.paymentMethod);
          let response = await PaymentProcessor.processPayment();
          if (!response) {
            hideSpinner();
            showAffirmation(this.$t("error.title"), this.$t("payment.paysafe_error"));
            return;
          }

          if (PaymentProcessor.paymentMethod || this.$order.getOrderTotal().totalRemaining === 0) {
            let response = await this.$order.sendSale();
            if (response.success) {
              let sale = response.response;
              // Sale loyalty
              if (sale.loyaltyData.gained) {
                let newBalance = await this.loyaltyBalanceAction();
                if (newBalance.profile) {
                  this.$user.loyalty.load(newBalance.profile);
                }
                setConfiguration("last-loyalty-gain", sale.loyaltyData.gained);
              }
              // Sale QR Codes
              if (sale.codes && sale.codes.length > 0) {
                for (let i = 0; i < sale.codes.length; i++) {
                  let detail = sale.details.find(d => d.id === sale.codes[i].detail);
                  if (detail) {
                    sale.codes[i].name = detail.name;
                  }
                }
                setConfiguration("codes", sale.codes);
              }
              // Sale Tracking
              if (sale.trackingUrl) {
                setConfiguration("trackingURL", sale.trackingUrl);
              }

              PromotionManager.load();
              this.$user.update();
              hideSpinner();
              PaymentProcessor.showValitorSuccessFeedback(sale);
              /* post-sale analytics */
              this.$ga.event("sale-confirmation", this.company.nameCanonical, this.$user.email);
              this.$ga.ecommerce.addTransaction({
                id: sale.id,
                affiliation: sale.company,
                revenue: sale.total,
                currency: sale.currency
              });
              for (let detail of sale.details) {
                this.$ga.ecommerce.addItem({
                  id: sale.id,
                  sku: detail.id,
                  name: detail.name,
                  quantity: detail.quantity,
                  price: detail.unitPrice
                });
              }
              this.$ga.ecommerce.send();
              if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
                this.$analytics.fbq.event("Purchase", {
                  value: parseFloat(this.total.subTotal.toFixed(2)),
                  currency: "CAD"
                });
              }
              //PayFacto sale feedback
              let shouldReloadPayFacto = sale.payments.find(p => p.processor === "payfacto") && !PaymentProcessor.usePayFactoSHC;
              await PaymentProcessor.showPayfactoSuccessFeedback(sale);
              //Warning to customer on POS failure and POS bypass activated
              if (this.company.shouldNotifyCustomerOnPosFailureWhenPosBypass() && sale.posFailed) {
                await this.notifyCustomerOfFailedPOS();
              }
              // Redirect after sale
              PaymentProcessor.removePayment();
              this.$router.push(this.$router.generate("/paymentComment" + (shouldReloadPayFacto ? '?payfactoReload=1' : '')));
            } else {
              if (response.status == 409) {
                console.warn("Duplicated sale submitted. No action taken.");
                hideSpinner();
                return;
              }
              hideSpinner();
              let isValitor = PaymentProcessor.isPaymentMethod("valitor");
              if (isValitor) {
                if (response.response && response.response && response.response.response) {
                  let parsed = JSON.parse(response.response.response);
                  if (parsed.sale) {
                    let valitorPayment = parsed.sale.payments.find(p => p.processor === "valitor");
                    if (valitorPayment && valitorPayment.data && valitorPayment.data.AuthorizationNumber) {
                      showAffirmation(this.$t("error.title"), this.$t("valitor.error"), null, "warning");
                      return;
                    }
                  }
                }
              }

              PaymentProcessor.removePayment();

              if (response.status >= 400 && response.status < 500) {
                let parsed = response;
                if (response.response.key) {
                  parsed = response.response;
                } else if (response.response.response) {
                  parsed = JSON.parse(response.response.response);
                }
                if (parsed.key == "soft_sale_error.unmapped_item_exception") {
                  if (parsed.params.id) {
                    let item = this.$store.state.inventory.search(parsed.params.id);
                    let result = await showConfirmation(this.$t("error.title"), this.$t("order.item_out_of_stock").replace("{NAME}", this.$to(item.name)) + "<br><br>" + this.$t('error.preauthorize'), null, null);
                    if (result) {
                      this.$order.items = this.$order.items.filter(i => i.id != parsed.params.id && i.getId() != parsed.params.id);
                      if (this.$order.items.length == 0) {
                        this.$router.push(this.$router.generate("/menu"));
                        return;
                      }
                      for (let item of this.$order.items) {
                        for (let choice of item.getChoices()) {
                          if (choice.selected.id == parsed.params.id) {
                            choice.selected = null;
                            this.$router.push(this.$router.generate("/itemEdit/" + this.$order.items.indexOf(item)));
                          }
                        }
                        for (let choiceGroup of item.getChoiceGroups()) {
                          for (let choice of choiceGroup.getChoices()) {
                            if (choice.selected.id == parsed.params.id) {
                              choice.selected = null;
                              this.$router.push(this.$router.generate("/itemEdit/" + this.$order.items.indexOf(item)));
                            }
                          }
                        }
                        for (let modifier of item.getModifiers()) {
                          if (modifier.id == parsed.params.id) {
                            modifier.setQuantity(0);
                          }
                        }
                        for (let modifierGroup of item.getModifierGroups()) {
                          for (let modifier of modifierGroup.modifiers) {
                            if (modifier.id == parsed.params.id) {
                              modifier.setQuantity(0);
                            }
                          }
                        }
                      }
                      if (!this.$order.validateMinimumAmount()) {
                        this.$router.push(this.$router.generate("/menu"));
                      }
                    }
                  } else {
                    if (this.company.getPaymentMethod('paysafe')) {
                      showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_paysafe"));
                    } else {
                      showAffirmation(this.$t("error.title"), this.$t("error.submit_sale"));
                    }
                  }
                } else if (parsed.key == "payment_token_expired") {
                  await showAffirmation(this.$t('payment.inactive_title'),this.$t('payment.inactive_description'));
                  this.$router.push(this.$router.generate("/paymentContact"));
                } else {
                  if (this.company.getPaymentMethod('paysafe')) {
                    showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_paysafe"));
                  } else {
                    showAffirmation(this.$t("error.title"), this.$t("error." + parsed.key));
                  }
                }
              } else {
                if (this.company.getPaymentMethod('paysafe')) {
                  showAffirmation(this.$t("error.title"), this.$t("error.submit_sale_paysafe"));
                } else {
                  showAffirmation(this.$t("error.title"), this.$t("error.submit_sale"));
                }
              }
            }
          } else {
            hideSpinner();
          }
        }
      },

      async notifyCustomerOfFailedPOS() {
        let message = this.$t("payment.not_received_description");
        if (this.company.phoneNumber) {
          message += "<br><br><a style='font-weight:bold;' href='tel:" + this.company.phoneNumber + "'>" + this.company.phoneNumber + "</a>";
        }
        await showAffirmation(this.$t("payment.not_received"),  message, null, "lost", false, "center");
        setConfiguration("pos-failed", true);
      },

      async checkPromoCode() {
        //TODO refactor this, duplicate of same function in promotions.vue, promoKeyboardModal.vue
        this.promoCodeStatus = null;
        showSpinner();
        let result = await this.checkPromoCodeAction({
          branch: this.$store.state.currentBranch.id,
          company: this.$store.state.currentCompany.id,
          code: this.promoCode
        });
        if (!result.success) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = null;
          hideSpinner();
          return;
        }
        if (!PromotionManager.isPromotionWithinSchedule(result.promotion)) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = "promotion.outside_of_schedule";
          hideSpinner();
          return;
        }
        if (this.$order.getItemsTotal({ excludedTags: result.promotion.exclusion.tags }) < result.promotion.minAmount) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = "promotion.minimum";
          this.promoMinAmount = result.promotion.minAmount;
          hideSpinner();
          return;
        }
        if (!this.$order.method || result.promotion.disableForMethod.indexOf(this.$order.method) > -1) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = "promotion.invalid_method";
          hideSpinner();
          return;
        }
        let promo = new Promotion(result.promotion);
        // Check if there is already a promo code
        if (promo.code) {
          for (let oldPromo of PromotionManager.promotions) {
            if (oldPromo.code) {
              hideSpinner();
              let result = await showConfirmation(this.$t("error.title"), this.$t("promotion.multiple_codes"));
              if (result) {
                PromotionManager.removePromotion(oldPromo);
                showSpinner();
              } else {
                return;
              }
            }
          }
        }
        let added = PromotionManager.addPromotion(promo);
        if (!added) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = "promotion.already_added";
          hideSpinner();
          return;
        }
        this.promoCodeStatus = "valid";
        this.validateAccountPayment(this.$order.accountPayment);
        this.useGiftCard();
        hideSpinner();
      },

      redirectToLoyaltyAccountRecharge() {
        this.$router.push(this.$router.generate("/reload?id=" + this.$order.accountId + "&redirect=paymentConfirmation"))
      },
      redirectToProfile() {
        this.$router.push(this.$router.generate("/profile"))
      },

      getAccountDisplayName(account) {
        let user = account.owner || this.$user;
        if (user.firstName && user.lastName) {
          return `${user.firstName} ${user.lastName}`.trim() || `@${user.username}`
        } else {
          return `${user.username}`
        }

      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    display: flex;
    flex-direction: column;
  }

  .filler {
    flex-grow: 1;
  }

  .error-box {
    margin-bottom: 15px;
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    background-color: white;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    .container {
      flex-grow: 1;
    }

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
    }
    .text {
      color: #808080;
    }
  }

  .valitor-iframe {
    width: 100% !important;
    background-color: white !important;
  }

  .cols {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .facturation, .loyality-card, .payment, .loyalty-account {
    .panel-left {
      padding-right: 40px;
    }
    .panel-left, .panel-right {
      flex-grow: 1;
      flex-basis: 0;
      align-self: flex-start;
    }
    .panel-right {
      .promo, .tip, .loyality-card, .gift-card, .credit-card {
        position: relative;
        display: flex;
        align-items: center;
        .dot {
          position: absolute;
          left: -18px;
          width: 12px;
          height: 12px;
          background-color: var(--primary-color);
          border-radius: 50%;
        }
        .value {
          color: #fc6650;
          font-weight: bold;
        }
        .svgicon {
          position: absolute;
          right: -22px;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .text {
            margin: 0;
            margin-right: 6px;
          }
          .small {
            opacity: 0.6;
            font-size: 14px;
          }
        }
      }

      label {
        display: block;
        font-size: 14px;
        color: #808080;
        padding-left: 8px;
        padding-bottom: 8px;
        .important {
          font-weight: bold;
        }
      }
      .frame {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 8px 30px;
        .row {
          display: flex;
          border-bottom: 1px solid #e6e6e6;
          padding: 9px 0;
          .value {
            margin-left: auto;
          }
        }
        .sub-total {
          font-size: 20px;
          font-weight: bold;
          color: #1a1a1a;
        }
        .promo, .tax, .tip {
          font-size: 16px;
          .value {
            font-weight: bold;
          }
        }
        .promo, .tip {
          color: #404040;
        }
        .tax {
          color: #808080;
        }
        .fees {
          display: flex;
          justify-content: space-between;
          color: #808080;
          line-height: 1;
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 0;

          .amount {
            font-weight: bold;
          }
        }
        .tax:first-of-type {
          border-bottom: 0;
          padding-bottom: 0;
        }
        .tax:last-of-type {
          padding-top: 0;
        }
        .promo, .tip {
          .value {
            color: #fc6650;
          }
        }
        .total {
          font-size: 24px;
          font-weight: bold;
          color: #1a1a1a;
        }
        .row:last-child {
          border-bottom: none;
        }
      }
    }
    .panel-left {
      .text {
        color: #404040;
      }
      form {
        .input {
          label {
            padding-bottom: 8px;
            display: block;
            font-size: 14px;
            color: #808080;
            padding-left: 8px;
          }
          input, select {
            width: 100%;
          }
        }
        .ishop-user-giftcards {
          margin-bottom: 10px;
        }
        .input.code-promo {
          .frame {
            display: flex;
            input, select {
              margin-right: 10px;
              min-width: 1px;
            }
            .btn-remove {
              margin-right: 10px;
              fill: white;
            }
            .btn {
              display: flex;
              align-items: center;
              font-size: 18px;
            }
          }
          .promo-status {
            margin: 0;
            padding: 8px 0 0 0;
            list-style: none;
            display: flex;
            li {
              display: flex;
              align-items: center;
              margin-right: 20px;
              .svgicon, .span {
                display: inline-block;
              }
            }
          }
        }
      }
      .tip-amount {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        // margin-top: 20px;
        li {
          color: #fc6650;
          font-size: 18px;
          background-color: #f5f5f5;
          cursor: pointer;
          flex-grow: 1;
          padding: 12px;
          margin: 2px;
          text-align: center;
        }
        li:hover, .selected {
          background-color: #fc6650;
          color: white;
        }
      }
    }
    .inner-title {
      font-weight: bold;
      padding-bottom: 10px;
    }
    .no-inner-title {
      //padding-top: 30px
    }
  }

  #iShopFoodApp .freebees-add-card {
    .text {
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .btn {
      color: black !important;
      background-color: #fccc0c !important;
      font-weight: 500;
      font-size: 14px;
      .svgicon {
        fill: black !important;
        margin-right: 10px;
      }
    }
    .logo {
      background: transparent url(../../assets/img/fb-logo.png) no-repeat center top !important;
      background-size: cover !important;
      width: 170px;
      height: 40px;
      margin-right: 10px;
    }
  }

  #iShopFoodApp .buttons {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    .btn {
      flex-grow: 1;
      flex-basis: 0;
      padding: 20px;
      font-size: 20px;
      text-decoration: none;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .back {
      background-color: white;
      color: #ed2937;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
    .next {
      background-color: #ed2937;
      color: white;
      margin-left: 20px;
    }
    .disable {
      color: lighten(#ed2937, 35%);
      cursor: initial;
      &:hover {
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .modal {
    .modal-content {
      width: 100%;
      max-width: 520px;
      .modal-header {
        display: flex;

        .svgicon {
          fill: #ed2937;
        }
        .btn-close {
          fill: red;
          cursor: pointer;
        }
        .title {
          font-size: 18px;
          color: #404040;
          font-weight: bold;
          margin-left: 8px;
          flex-grow: 1;
        }
      }
      .modal-body {
        text-align: center;
        .big-msg {
          font-size: 24px;
          font-weight: bold;
          color: #ed2937;
        }
        .msg, .comments {
          margin-top: 20px;
          color: #404040;
          font-size: 16px;
        }
        .comments {
          text-align: left;
        }
        .textarea {
          text-align: left;
          margin-top: 25px;
          label {
            display: block;
            font-size: 14px;
            margin-bottom: 4px;
            margin-left: 12px;
            color: #808080;
          }
          textarea {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            max-height: 120px;
            min-height: 120px;
            height: 100%;
            border-radius: 5px;
            border-color: #d9d9d9;
            padding: 12px 0 0 12px;;
          }
        }

      }
    }
  }

  .placed-order .modal-content{
    .modal-footer {
      display: flex;
      justify-content: space-between;
      button {
        box-shadow: none;
        padding: 11px 22px;
        font-size: 18px;
      }
      .btn-transparent {
        display: flex;
        align-items: center;
        padding: 0;
        .svgicon {
          fill: #bfbfbf;
        }
      }
    }
  }

  .insufficient-balance .modal-content, .promo-added .modal-content {
    .modal-footer {
      display: block;
      text-align: center;
      button {
        box-shadow: none;
        padding: 11px 22px;
        font-size: 18px;
      }
    }
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;

      &:after {
        content: none;
      }
    }
    .facturation, .loyality-card, .payment .cols, .loyalty-account {
      .panel-left {
        padding-right: 0;
        padding-bottom: 10px;
      }
      .panel-left, .panel-right {
        flex-basis: initial;
        width: 100%;
      }
      .panel-right .frame {
        padding: 8px 28px;

        .sub-total {
          font-size: 16px;
        }
        .tax, .tip, .promo, .gift-card, .loyality-card, .credit-card {
          font-size: 14px;
        }
        .total {
          font-size: 18px;
        }
      }
    }

    .buttons {
      span {
        display: none;
      }
      .back, .next {
        margin: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  .recaptcha-holder {
    overflow: hidden;
  }

  .split {
    display: flex;
    align-items: flex-end;
    .input:first-child {
      flex-grow: 1;
      margin-right: 10px;
    }
    .btn {
      margin-bottom: 10px;
      margin-left: 10px;
      height: 44px;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 720px) {
    .split {
      flex-direction: column;
      align-items: center;
      .input:first-child {
        margin-right: 0;
      }
      .btn {
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  input[type=checkbox] + label {
    padding: 0 !important;
  }

  .margin-top {
    margin-top: 0px;
  }

  .sm, .md, .lg {
    #btnRecharge {
      margin-top: 35px;
      height: 40px;
      border-radius: 5px;
    }
  }

  #iShopFoodApp {
    iframe {
      width: 100%;
      height: auto;
      min-height: 1060px;
    }
  }

  .recharge .svgicon {
    margin-right: 10px;
  }

  .total-warning {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    align-items: center;
    font-style: italic;
    .svgicon {
      fill: var(--primary-color);
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  #iShopFoodApp .btn.btn-continue {
    margin-top: 20px;
    width: 100%;
    padding: 15px 20px;
  }

  #iShopFoodApp .view-terms {
    color: var(--primary-color) !important;
    fill: var(--primary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    .svgicon {
      margin-left: 5px;
    }
  }

</style>

<style lang="scss">
  #iShopFoodApp .facturation .content .container, .loyality-card .content .container {
    // display: flex !important;
    .text {
      margin-bottom: 24px;
    }
  }
  #iShopFoodApp #modalAffirmation.terms {
    white-space: pre-wrap;
    .modal-content {
      max-width: 600px;
    }
    .modal-body {
      max-height: 400px;
      overflow-y: auto;
      font-size: 14px;
    }
  }
</style>
