import { throwAbstractClassError, throwUnimplementedMethodError } from "@/util/Error";
import { Constant } from "@/util/Constant";
import { PastReceipts } from "@/util/kiosk/PastReceipts";
import { IshopPrinterPayment } from "./IshopPrinterPayment";
import { Money } from "@/util/Money";
import moment from "moment-timezone";

class IshopPrinterSale {

  constructor(ishopPrinter, sale) {
    if (this.constructor === IshopPrinterSale) {
      throwAbstractClassError("IshopPrinterSale");
    }

    this.printer = ishopPrinter._printer;
    this.printerCols = ishopPrinter._printerCols;
    this.sale = sale;
    this.isSuccess = ishopPrinter._isSuccess;
    this.configuration = ishopPrinter._configuration;
    this.company = ishopPrinter._company;
    this.duplicata = ishopPrinter._duplicata;
    this.invoice = null;
    this.paymentPrinter = null;

    this.maxProductNameSize = this.printerCols - 20;
  }

  setPrinter(printer) {
    this.printer = printer;
  }

  /**
   * Overridden methods
   */

  /**
   * @param {*?} invoice
   * @return {[]}
   */
  getPayments(invoice) {
    throwUnimplementedMethodError("getPayments");
  }

  /**
   * @return {string}
   */
  getAppName() {
    throwUnimplementedMethodError("getAppName");
  }

  /**
   * @return {string|number}
   */
  getId() {
    throwUnimplementedMethodError("getId");
  }

  /**
   * @return {[]}
   */
  getGlobalPromotions() {
    throwUnimplementedMethodError("getGlobalPromotions");
  }

  /**
   * @return {string}
   */
  getLanguage() {
    throwUnimplementedMethodError("getLanguage");
  }

  /**
   * @param {boolean?}
   * @return {[]}
   */
  getDetails(includeVoid) {
    throwUnimplementedMethodError("getDetails");
  }

  /**
   * @return {String}
   */
  getCompletedDate() {
    throwUnimplementedMethodError("getCompletedDate");
  }

  /**
   * @param {*} detail
   * @return {void}
   */
  printSaleDetail(detail) {
    throwUnimplementedMethodError("printSaleDetail");
  }

  /**
   * @return {void}
   */
  printTransactionNumber() {
    throwUnimplementedMethodError("printTransactionNumber");
  }

  /**
   * @param {*} detail
   * @return {void}
   */
  printDetailLineForKitchen(detail) {
    throwUnimplementedMethodError("printDetailLineForKitchen");
  }

  /**
   * @param {PosConfiguration} configuration
   * @param configuration
   * @return {*}
   */
  getSaleDetailsByPrinterName(configuration) {
    throwUnimplementedMethodError("getSaleDetailsByPrinterName");
  }

  /**
   * @returns {String}
   */
  getUniqueId() {
    return this.sale.fuid;
  }

  getTpvReceipt() {
    return this.sale.tpvReceipt;
  }

  getTableNumber() {
    return this.sale?.deliveryData?.tableNumber || this.sale?.table;
  }

  isRefund() {
    let entity = this.invoice || this.sale;
    return entity.total < 0;
  }

  isVoid() {
    let entity = this.invoice || this.sale;
    return entity.void;
  }

  /**
   * Inherited methods
   */

  /**
   * @returns {string}
   */
  getContextFromAppName() {
    switch (this.getAppName()) {
      case Constant.KIOSK_APP_NAME:
        return "kiosk";
      case Constant.MOBILE_APP_NAME:
      case Constant.WEB_APP_NAME:
        return "web";
      case Constant.POS_APP_NAME:
        return "pos";
      case Constant.UBER_APP_NAME:
        return "uber";
    }
  }

  /**
   * Get invoices related to sale
   * @returns {[]}
   */
  getInvoices() {
    return this.sale.invoices.filter(i => !i.void);
  }

  /**
   * @param {string} number
   * @returns {*}
   */
  getInvoiceByNumber(number) {
    return this.sale.invoices.find(i => i.number === number);
  }

  /**
   * @returns {string}
   */
  getPaymentErrorMessage() {
    if (!this.paymentPrinter) {
      return "";
    }
    return this.paymentPrinter.getPaymentErrorMessage();
  }

  /**
   * @param {*?} invoice
   * @param {IshopPrinter} ishopPrinter
   * @param {boolean?} sendToPrinter
   * @returns {Promise}
   */
  async print(invoice, ishopPrinter, sendToPrinter = true) {
    this.invoice = invoice || null;
    this.paymentPrinter = new IshopPrinterPayment(this);
    this.printer.reset();

    this.printHeader();
    this.printDuplicataText();
    this.printTransactionNumber();
    this.printSaleReceipt();
    if (this.isSuccess) {
      this.printFooterLines();
      this.printEmployeeInformation();
    }
    this.printRefundOrVoidText();
    this.printPaymentReceipt(this.paymentPrinter);
    this.printQRCodes();

    if (this.sale.posFailed) {
      this.printPOSFailedWarning();
    }

    if (!this.isSuccess) {
      this.printSaleError();
    }

    //Save receipt locally on kiosk builds
    if (window.isExecutable()) {
      let receipt = ishopPrinter.getReceipt();
      let receiptRaw = ishopPrinter.getRaw();
      PastReceipts.saveReceipt(receipt, receiptRaw, ishopPrinter._rawSale);
    }

    if (sendToPrinter) {
      return await this.printer.print();
    }
  };

  /**
   * Print sale QR Codes
   * @returns {void}
   */
  printQRCodes() {
    let codes = (this.sale.codes || []).filter(c => c.type === "QR");
    if (codes.length === 0) {
      return;
    }

    this.printer
      .newLine()
      .newLine()
      .newLine()
      .newLine()
      .newLine()
      .cut()
      .newLine();

    for (let i = 0; i < codes.length; i++) {
      let code = codes[i];
      let relatedDetail = (this.sale.details || []).find(d => d.id === code.detail);
      this.printer.newLine()
        .newLine()
        .newLine();

      this.printer.addQRCode(code.code);
      if (relatedDetail) {
        this.printer.newLine()
          .addLine(null, relatedDetail.name)
          .newLine();
      }
      if (i < (codes.length - 1)) {
        this.printer
          .newLine()
          .newLine()
          .newLine()
          .newLine()
          .newLine()
          .cut();
      }
    }
  }

  /**
   * Print sale header
   * @returns {void}
   */
  printHeader() {
    let headerLines = this.configuration.getReceiptHeaderLines();
    let imageData = this.configuration.getReceiptImage();
    if (imageData) {
      this.printer.setBase64Bitmap(imageData);
    }
    if (headerLines.length > 0 && headerLines.find(l => !!l)) {
      for (let i = 0; i < headerLines.length; i++) {
        this.printer.addLine(null, headerLines[i]);
      }
    } else {
      let address = this.company.address;
      if (address) {
        this.printer.addLine(null, this.company.name)
          .addLine(null, address.address)
          .addLine(null, (address.city || "") + " " + (address.state || "") + "  " + (address.zipcode || ""));
      }
      if (this.company.phoneNumber) {
        this.printer.addLine(null, "Tel: " + this.company.phoneNumber);
      }
      if (this.company.email) {
        this.printer.addLine(null, "Email: " + this.company.email);
      }
    }
    this.printer.addLine(null, rightPad("", this.printerCols, "-"));
    if (this.sale && this.sale.deliveryMethod) {
      this.printer.newLine()
        .setFontSize(2)
        .addLine(null, window.translate(`pos_method.${this.sale.deliveryMethod}`).toUpperCase())
        .setFontSize(1)
        .newLine();

      if (this.sale.deliveryMethod === "delivery") {
        let customerAddress = this.sale.deliveryData;
        let customer = this.sale.contact;
        let isUberSale = this.sale.appName === Constant.UBER_APP_NAME;
        if (customer) {
          this.printer.addLine(null, (customer.firstName || "") + " " + (customer.lastName || ""));
        }
        if (this.sale.contact && this.sale.contact.phone) {
          this.printer.addLine(null, "Tel: " + this.sale.contact.phone);
        }
        if (isUberSale) {
          this.printer.addLine(null, window.translate("pos_receipt.address_not_provided_uber"));
        } else if (customerAddress) {
          this.printer.addLine(null, customerAddress.fullAddress);
        }
      }
    }
  };

  /**
   * Print kitchen header for kitchen receipt
   * @returns {void}
   */
  printKitchenHeader() {
    if (this.sale.deliveryMethod) {
      this.printer.setFontSize(2)
        .addLine(null, window.translate(`pos_method.${this.sale.deliveryMethod}`).toUpperCase())
        .setFontSize(1)
        .newLine();
    }
    let context = this.getContextFromAppName();
    this.printer.addLine(null, new Date().toLocaleString());

    if (this.getId()) {
      this.printer.setFontSize(2)
        .addLine(null, window.translate(`kiosk_receipt.order_${context}`).toUpperCase() + " #" + (this.getId()))
        .newLine();
    } else if (this.getTableNumber()) {
      this.printer.setFontSize(2)
        .addLine(null, window.translate("table").toUpperCase() + " #" + (this.getTableNumber()))
        .newLine();
    }

    if (this.sale.contact) {
      if ((this.sale.contact.firstName || this.sale.contact.lastName)) {
        this.printer.addLine(null, this.sale.contact.firstName + " " + this.sale.contact.lastName);
      }
      if (this.sale.contact.phone) {
        this.printer.addLine(null, this.sale.contact.phone);
      }
    }

    let hour = "ASAP";
    if (this.sale.inAdvanceFor) {
      hour = moment(this.sale.inAdvanceFor, "YY-MM-DD HH:mm:ss").format(this.company && this.company.hourFormat === "12" ? "hh:mm A" : "HH:mm");
    }
    let deliveryMethodText = this.sale.deliveryMethod ? window.translate("pos_method." + this.sale.deliveryMethod).toUpperCase() + ": " : "";
    this.printer.addLine(null, deliveryMethodText + hour)
      .setFontSize(1);
  }

  /**
   * Print kitchen details
   * @param {[]} details
   * @returns {void}
   */
  printKitchenDetails(details) {
    this.printer.newLine()
      .setFontSize(2);

    for (let i = 0; i < details.length; i++) {
      this.printDetailLineForKitchen(details[i]);
    }

    this.printer.setFontSize(1)
      .addLine(null, rightPad("", this.printerCols, "*"));
  }

  /**
   * Print duplicata string to header
   * @returns {void}
   */
  printDuplicataText() {
    if (this.duplicata) {
      this.printer.newLine()
        .setFontSize(2)
        .addLine(null, window.translate("duplicata").toUpperCase())
        .setFontSize(1);
    }
  }

  printRefundOrVoidText() {
    if (this.isRefund()) {
      this.printer.newLine()
        .setFontSize(2)
        .addLine(null, window.translate("invoice_print.refund").toUpperCase())
        .setFontSize(1);
    } else if (this.isVoid()) {
      this.printer.newLine()
        .setFontSize(2)
        .addLine(null, window.translate("invoice_print.void").toUpperCase())
        .setFontSize(1);
    }
  }

  /**
   * Print payment receipt for each payment processor
   * @param {IshopPrinterPayment} paymentPrinter
   * @returns {void}
   */
  printPaymentReceipt(paymentPrinter) {
    if (!this.sale || !this.sale.payments) {
      return;
    }

    let payments = this.getPayments(this.invoice);

    this.printer.newLine()
      .addLine(null, rightPad("", this.printerCols, "-"))
      .newLine();

    for (let payment of payments) {
      paymentPrinter.print(payment);
    }
  }

  /**
   * Print payment total information for each payment processor
   * @param {IshopPrinterPayment} paymentPrinter
   * @returns {void}
   */
  printPaymentInfo(paymentPrinter) {
    if (!this.sale || !this.sale.payments || !paymentPrinter) {
      return;
    }

    let payments = this.getPayments(this.invoice);

    for (let payment of payments) {
      let label = paymentPrinter.getPaymentLabel(payment);
      this.printAmount(label, payment.amount);
    }
  }

  /**
   * Print POS failed warning
   * @returns {void}
   */
  printPOSFailedWarning() {
    this.printer.addLine(null, rightPad("", this.printerCols, "-"))
      .newLine()
      .setFontSize(2)
      .addLine(null, window.translate("payfactoPax.pos_error"))
      .setFontSize(1)
      .newLine()
      .addLine(null, window.translate("payfactoPax.pos_error_description"));
  };

  /**
   * Print sale error, includes TPV receipt if provided
   * @returns {void}
   */
  printSaleError() {
    if (this.sale && this.sale.tpvReceipt) {
      let receiptData = JSON.parse(this.sale.tpvReceipt);
      if (receiptData.tpvType && receiptData.tpvType === "globalFlex") {
        this.printer.newLine()
          .setFontSize(2)
          .addLine(null, window.translate("error.title").toUpperCase())
          .addLine(null, window.translate("kiosk.payment_ok").toUpperCase())
          .setFontSize(1)
          .addLine(null, window.translate("kiosk.external_pos_error"));
        return;
      }
    }

    this.printer.newLine()
      .setFontSize(2)
      .addLine(null, window.translate("error.title").toUpperCase())
      .setFontSize(1)
      .newLine();

    let errorMessage = this.paymentPrinter.getPaymentErrorMessage();
    this.printer.addLine(null, window.translate(errorMessage));
  };

  /**
   * Print sale details
   * @returns {void}
   */
  printDetails() {
    let details = this.getDetails() || [];
    for (let detail of details) {
      this.printSaleDetail(detail);
    }
  }

  /**
   * Print sale promotions
   * @returns {void}
   */
  printGlobalPromotions() {
    if (this.getGlobalPromotions()) {
      for (let promotion of this.getGlobalPromotions()) {
        this.printer.addLine()
          .addLine(window.translate("discount").toUpperCase() + ": " + promotion.name, null, promotion.total.toFixed(2));
      }
    }
  }

  /**
   * Print Sale items + total information
   * @returns {void}
   */
  printSaleReceipt() {
    if (!this.sale || (typeof this.sale === "object" && Object.keys(this.sale).length === 0)) {
      return;
    }
    try {
      this.printDetails();
      this.printGlobalPromotions();
      this.printTotalInformation();
      this.printCompanyTaxNumbers();
    } catch (e) {
      console.log("error print sale", e);
    }
  };

  /**
   * Print sale total + tax information
   * @returns {void}
   */
  printTotalInformation() {
    let entity = this.invoice || this.sale;
    this.printer.newLine();
    if (entity.deliveryFees > 0) {
      this.printAmount("total.delivery_fees", entity.deliveryFees);
    }
    if (entity.serviceFees > 0) {
      this.printAmount("total.service_fees", entity.serviceFees);
    }
    if (entity.surchargeFees && entity.surchargeFees > 0) {
      this.printAmount("total.surcharge_fees", entity.surchargeFees);
    }
    if (entity.subTotal) {
      this.printAmount("total.subtotal", entity.subTotal);
    }
    if (this.invoice && this.invoice.taxes) {
      let keys = Object.keys(this.invoice.taxes);
      if (keys.length > 0) {
        if (typeof this.invoice.taxes[keys[0]] === "number") {
          for (let tax of Object.keys(this.invoice.taxes)) {
            this.printAmount(tax, this.invoice.taxes[tax]);
          }
        } else if (typeof this.invoice.taxes[keys[0]] === "object") {
          for (let tax of this.invoice.taxes) {
            this.printAmount(tax.name, tax.total);
          }
        }
      }
    } else if (this.sale.taxes) {
      for (let tax of this.sale.taxes) {
        this.printAmount(tax.name, tax.total);
      }
    }
    if (entity.tipTotal > 0 || entity.addedTip) {
      this.printAmount("total.tip", (entity.tipTotal || entity.addedTip));
    }
    if (CONFIG.pos && entity.cashRounding) {
      this.printAmount("total.cash_rounding", entity.cashRounding);
    }
    if (entity.total) {
      this.printer.newLine()
        .setFontSize(2);
      this.printAmount("total.total", entity.total, true);
      this.printer.setPadding(0)
        .setFontSize(1);
    }

    this.printPaymentInfo(this.paymentPrinter);

    // Print cash change
    if (CONFIG.pos) {
      let change = this.getPayments(this.invoice).reduce((acc, payment) => acc + payment.change, 0);
      if (change > 0) {
        this.printAmount("total.change", change);
      }
    }

    this.printer.newLine();
  }

  /**
   * Print company tax numbers
   * @deprecated TBD should be put next to tax in total info
   * @returns {void}
   */
  printCompanyTaxNumbers() {
    if (this.company && this.company.taxes && this.company.taxes.length > 0) {
      for (let tax of this.company.taxes) {
        if (tax.taxNumber) {
          this.printer.addLine(null, tax.uniqueName + ": #" + tax.taxNumber);
        }
      }
      this.printer.newLine();
    }
  }

  /**
   * Print footer text lines
   * @returns {void}
   */
  printFooterLines() {
    let footerLines = this.configuration.getReceiptFooterLines();
    if (footerLines.length > 0) {
      for (let i = 0; i < footerLines.length; i++) {
        this.printer.addLine(null, footerLines[i]);
      }
      this.printer.newLine();
    }
  };

  /**
   * Print employee name
   * @returns {void}
   */
  printEmployeeInformation() {
    if (this.sale && this.sale.employee) {
      let config = this.configuration?.receiptConfiguration;
      if (config && config.hideEmployeeInformation) {
        return;
      }
      let name = this.sale.employee.publicName || (this.sale.employee.firstName + " " + this.sale.employee.lastName);
      this.printer.addLine(null, window.translate("pos_receipt.served_by"))
        .addLine(null, name);
    }
  }

  /**
   * @param {string} translationKey
   * @param {string|number} amount
   * @param {boolean} showCurrencySymbol
   */
  printAmount(translationKey, amount, showCurrencySymbol = false) {
    amount = parseFloat(amount) || 0;
    let currency = this.sale.currency;
    let language = this.getLanguage();
    let amountToDisplay = showCurrencySymbol ? Money.roundForDisplayWithCurrencySymbol(amount, currency, language) : Money.roundForDisplay(amount, currency);
    this.printer.addLine(window.translate(translationKey).toUpperCase(), null, amountToDisplay);
  };

}

export { IshopPrinterSale };