/**
 EXEMPLE

 import IshopRequestWebSocket from "@/resources/IshopRequestWebSocket";

 let requestSocket = new IshopRequestSocket({
    url: 'ws://localhost:8002/ws',
    debugConsole: true,
    client: {
       company:'veloce',
       branch: 'veloce',
       context: 'reception'
    }
});

 requestSocket
 .on('dropFile', (payload) => {
    return { boo: 'boo'}
  })
 .on('successful_event', (payload) => {
    return {data: 'successful response, return any data needed by the API'}
  })
 .on('failed_event', (payload) => {
    throw new Error('error handling, structure subject to improve');
  });

 **/


export default class IshopRequestSocket {
  ws = null;

  /* {branch, company, context} */
  url = null;
  client = null;
  debugConsole = false;
  connectionFailures = 0;
  handlers = {};

  /*events*/
  disconnectedEvent = new Event('socket_disconnected');
  connectedEvent = new Event('socket_connected');

  constructor({url, client, debugConsole}) {
    this.url = url;
    this.client = client;
    this.debugConsole = debugConsole || false;

    this.connect();
  }

  connect() {
    this.initSocket();

    this.ws.onmessage = async (e) => {
      this.log('SOCKET_MESSAGE_IN', e.data);

      let json = this.tryParseJson(e.data);
      let event = json.event;
      let payload = json.payload;
      let requestID = json.requestID;
      if (!event) {
        this.log('SOCKET_INVALID_REQUEST', 'event missing');
        return;
      }
      else if (typeof event !== 'string') {
        this.log('SOCKET_INVALID_REQUEST', 'event must be a string');
        return;
      }
      else if (event && typeof this.handlers[event] !== 'function') {
        this.log('SOCKET_UNREGISTERED_EVENT', event);
        return;
      }

      try {
        let response = await (this.handlers[event](payload));
        response = response || {};
        if (response.success === false || response.error) {
          throw response;
        } else {
          this.sendMessage('response_success', response.data || response, requestID);
        }
      } catch (error) {
        let errorObject = {};
        if (error instanceof Error) {
          errorObject.error = error.message;
        } else if (typeof error === 'string') {
          errorObject.error = error;
        } else if (typeof error === 'object' && error !== null) {
          errorObject = error;
        }
        this.sendMessage('response_error', errorObject, requestID);
      }
    }
    this.ws.onopen = () => {
      this.log('SOCKET_ON_OPEN');
      this.connectionFailures = 0;
      window.dispatchEvent(this.connectedEvent);
      this.register();
    }
    this.ws.onclose = (event) => {
      this.log('SOCKET_ON_CLOSE', event);
      if (!event.wasClean) {
        this.log('UNCLEAN_SOCKET_CLOSE', "reconnecting...");
        window.dispatchEvent(this.disconnectedEvent);
        this.reconnect();
      }
    }
    this.ws.onerror = (error) => {
      this.log("SOCKET_ERROR", error);
    }
  }

  initSocket() {
    this.ws = new WebSocket(this.url);
    this.log('SOCKET_CREATED');
  }

  closeSocket() {
    if (!this.ws) {
      return;
    }
    this.unregister();
    this.ws.close();
  }

  reconnect() {
    this.ws = null;
    this.connectionFailures++;
    this.log("TRYING TO CONNECT, TRY #" + this.connectionFailures);
    // if (this.connectionFailures < 10 || !(this.connectionFailures % 10)) {
    //   window.dispatchEvent(this.disconnectedEvent);
    // }
    setTimeout(() => {
      this.connect();
    }, 1000); //Retry to connect in 1 second
  }

  register() {
    if (this.ws.readyState !== 1) {
      throw new Error("CANNOT INIT SOCKET, not in readyState");
    }

    /* Register */
    this.log('SOCKET_REGISTRATION', 'started');
    this.sendMessage('register', this.client);
    this.log('SOCKET_REGISTRATION', 'done');
  }

  unregister() {
    if (this.ws.readyState !== 1) {
      return;
    }

    /* Unregister */
    this.log('SOCKET_UNREGISTRATION', 'started');
    this.sendMessage('unregister', this.client);
    this.log('SOCKET_UNREGISTRATION', 'done');
  }

  on(event, callback) {
    if (typeof event !== 'string') {
      throw new Error("IshopRequestSocket error, event must be a string");
    }
    if (typeof callback !== 'function') {
      throw new Error("IshopRequestSocket error, callback must be a function");
    }
    this.handlers[event] = callback;
    this.log('SOCKET_EVENT_REGISTERED', event);
    return this;
  }

  sendMessage(type, payload = undefined, requestID = undefined) {
    let dataAsString = JSON.stringify({ type, requestID, payload });
    this.ws.send(dataAsString, { binary: false });
    this.log('SOCKET_MESSAGE_OUT', dataAsString);
  }

  tryParseJson(str) {
    try { return JSON.parse(str); }
    catch (e) { return {}; }
  }

  log(...args) {
    if (this.debugConsole) {
      console.log(...args);
    }
  }
}
