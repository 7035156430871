<template>
    <div id="cards">
        <MenuHeader :text="$t('loyalty.cards')" icon="icons/card.svg" show-back-button></MenuHeader>

        <div class="page-content" :class="{mobile: $store.getters.isMobileOrTablet}">
            <Panel :title="$t('loyalty.manage_cards')" icon="icons/settings.svg" collapsible>
                <div class="description">{{$t('loyalty.manual_card_description')}}</div>

                <div class="section-title">{{$t('loyalty.add_card')}}</div>
                <div class="form">
                    <input type="text" :placeholder="$t('loyalty.card_number')" v-model="newCardNumber" :class="{invalid: $validate.error('newCardNumber')}">
                    <button class="btn btn-primary" @click="addNewCard">{{$t('loyalty.add_new_card')}}</button>
                </div>

                <div class="section-title">{{$t('loyalty.my_cards')}}</div>
                <div class="cards">
                    <div class="card" v-for="(card, cardIndex) of cards" :key="cardIndex">
                        <div class="input">
                            <label>{{$t('loyalty.card_number')}}</label>
                            <div class="value">{{card.number}}</div>
                        </div>
                        <div class="delete" @click="deleteCard(card, cardIndex)">
                            <svgicon src="icons/trash.svg" :height="16" :width="16"></svgicon>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    </div>
</template>

<script>
    import MenuHeader from "../../components/menu/MenuHeader.vue";

    export default {
      name: "loyaltyCards",

      components: { MenuHeader },

      data() {
        return {
          cards: [],
          newCardNumber: null
        }
      },

      validate: {
        newCardNumber: {
          required: true,
          number: true
        }
      },

      mounted() {
        this.$store.commit("showNavigationCart", true);

        if (!this.$user || !this.account) {
          return;
        }

        this.loadCards();
      },

      computed: {
        account() {
          if (!this.$user) {
            return;
          }
          return this.$user.getLoyaltyAccount();
        }
      },

      methods: {
        async loadCards() {
          this.cards = this.account.cards;
        },
        async addNewCard() {
          if (!this.$validate.valid()) {
            return;
          }
          showSpinner();
          let response = await this.$user.addLoyaltyCard(this.newCardNumber, this.account.id);
          this.cards.push(response);
          hideSpinner();
          if (response) {
            showAffirmation(this.$t("success"), this.$t("loyalty.add_success"));
            this.newCardNumber = null;
          } else {
            showAffirmation(this.$t("error.title"), this.$t("error.loyalty.connexion_error"));
          }
        },
        async deleteCard(card, index) {
          let confirm = await showConfirmation(this.$t("warning"), this.$t("loyalty.delete_confirm").replace("{CARD}", card.number));
          if (!confirm) {
            return;
          }
          showSpinner();
          let response = await this.$user.deleteLoyaltyCard(card.id);
          hideSpinner();
          if (response) {
            showAffirmation(this.$t("success"), this.$t("loyalty.delete_success"));
            this.cards.splice(index, 1);
          } else {
            showAffirmation(this.$t("error.title"), this.$t("error.loyalty.connexion_error"));
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .description {
        margin-bottom: 20px;
    }
    #iShopFoodApp .section-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .form {
        margin-bottom: 20px;
        max-width: 500px;
    }

    .card {
        display: flex;
        align-items: center;
        border: 1px solid #d8d8d8;
        border-radius: 10px;
        padding: 5px 10px;
        max-width: 500px;
        margin-bottom: 15px;

        .input {
            flex-grow: 1;

            .value {
                font-weight: bold;
                font-size: 18px;
            }
        }

        .input, label {
            padding: 0;
        }

        .delete {
            padding: 15px;
            cursor: pointer;
            fill: var(--primary-color);
        }
    }

    #iShopFoodApp #cards .page-content.mobile {
        padding: 0;
    }
</style>
