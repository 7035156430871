<template>
  <div id="orderClosedModal" class="modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-header" v-if="title" v-html="title"></div>
      <div class="modal-header" v-else>{{ $t('inAdvance.title') }}</div>
      <div class="modal-body" v-if="company">
        <Illustration small class="illustrationinadvance" src="icons/inadvance.svg"></Illustration>
        <div class="content" v-if="companyHours">
          <span v-if="text" v-html="text"></span>
          <template v-else>{{$t("inAdvance.warning").replace("{HOUR}", nextHour)}}</template>

          <div class="hours">
            <div class="hour" v-for="(day, dayName) of allHours" :class="{today: companyHours == day}">
              <div class="name">{{$t("day." + dayName)}}</div>
              <div class="values">
                <div class="value" v-for="hour of day.hours">
                  {{parseHour(hour.from)}} {{$t("to")}} {{parseHour(hour.to)}}
                </div>
                <div class="value" v-if="day.hours.length == 0">
                  {{$t('company.closed')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="close">{{ $t('continue') }}</button>
      </div>
    </div>
  </div>
</template>

<script>

  import moment from "moment";
  import Illustration from "@/components/modal/Illustration.vue";
  import EventBus from "../lib/eventBus";

  export default {

    name: "orderClosedModal",

    components: { Illustration },

    data() {
      return {
        show: false,
        title: null,
        text: null
      };
    },

    beforeDestroy() {
      EventBus.$off("show-order-closed-modal");
    },

    async mounted() {
      EventBus.$on("show-order-closed-modal", (content) => {
        this.show = true;
        if (content) {
          this.title = content.title;
          this.text = content.text;
        } else {
          this.title = null;
          this.text = null;
        }
      });
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      nextHour() {
        if (!this.company || !this.companyHours || !this.companyHours.hours || !this.companyHours.hours[0]) {
          return;
        }
        return this.companyHours.hours[0].from;
      },
      allHours() {
        if (!this.company) {
          return [];
        }
        return this.company.getRawOpenHoursForMethod(this.$order.method);
      },
      companyHours() {
        if (!this.company) {
          return;
        }
        return this.company.getOpenHours(this.$order.method);
      }
    },

    methods: {

      close() {
        this.show = false;
      },

      parseHour(hour) {
        if (!this.company) {
          return;
        }
        return this.company.formatHour(hour);
      }

    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp #orderClosedModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;

    .modal-header {
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
    }

    .modal-content {
      max-width: 375px;
      font-size: 14px;
      text-align: center;
    }

    .modal-footer {
      justify-content: center;

      button {
        width: auto !important;
        padding: 8px 16px !important;
      }
    }
  }

  .hours {
    padding-top: 20px;
    border-top: 1px solid #f2f2f2;
    margin-top: 20px;
  }

  .hour {
    display: flex;
    padding: 4px 0;
    justify-content: space-between;
    font-size: 14px;

    .values {
      text-align: right;
      .value {
        text-align: right;
      }
    }

    &.today {
      font-weight: bold;
    }
  }

</style>
