<template>
  <div id="saleWarning">

    <div class="warning--icon">
      <svgicon class="icon icon__triangle" src="icons/triangle.svg" :height="400" :width="400"></svgicon>
      <svgicon class="icon icon__exclamation" src="icons/exclamation-point.svg" :height="190" :width="190"></svgicon>
    </div>

    <div class="warning--message">
      <div class="message--title">{{$t('warning')}}!</div>
      <div class="message--subtitle">{{$t('kiosk.warning_sale_subtitle')}}</div>
      <div class="message--more">{{$t('kiosk.warning_cashier')}}</div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "saleWarning",

    data() {
      return {
        timeout: null,
        timeoutDelay: 12 * 1000 // seconds before redirect
      }
    },

    mounted() {
      this.timeout = setTimeout(() => {
        this.redirect();
      }, this.timeoutDelay);
    },

    beforeDestroy() {
      clearTimeout(this.timeout);
    },

    methods: {
      redirect() {
        this.$router.push(this.$router.generate("/kiosk/standby"));
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #saleWarning {
    align-items: center;
    justify-content: center;
    text-align: center;

    .warning--icon {
      position: relative;

      $color: #ffd329;
      .icon {
        fill: $color;
        color: $color;
        filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, .2));
      }

      .icon__exclamation {
        position: absolute;
        left: 105px;
        top: 120px;
        transform-origin: bottom center;
        animation: shake 2s infinite;
      }
    }

    .warning--message {
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
    }

    .message--title {
      font-family: 'Segoe UI Black', sans-serif;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 44px;
    }
    .message--subtitle {
      font-size: 30px;
      margin-top: 30px;
    }
    .message--more {
      font-size: 38px;
      margin-top: 60px;
      font-weight: bold;
    }
  }
</style>

<style lang="scss">
  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(0);
    }
    30% {
      transform: rotate(6deg);
    }
    45% {
      transform: rotate(-6deg);
    }
    60% {
      transform: rotate(6deg);
    }
    75% {
      transform: rotate(-4deg);
    }
    100% {
      transform: rotate(0);
    }
  }
</style>
