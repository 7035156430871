<template>
  <div id="closeBatchErrorsModal" class="modal" :class="{show: visible}">
    <Panel class="close-batch" :title="$t('pos.readings.close_batch_error_title')" icon="icons/tip-danger.svg">
      <div class="close-batch__description">
        {{ $t('pos.readings.close_batch_error') }}
      </div>
        <div class="close-batch__results">
          <div class="result" v-for="openTable of openTables" :key="openTable.table" v-if="openTables.length > 0">
            <div class="result__icon-container">
              <svgicon src="icons/invoice.svg" :height="38" :width="30"></svgicon>
            </div>
            <div class="result__content">
              <div class="group">
                <span class="title" v-html="$t('pos.readings.table_number')"></span>
                {{ openTable.table }}
              </div>
              <div class="group">
                <span class="title" v-html="$t('pos.readings.sale_number')"></span>
                {{ openTable.sale }}
              </div>
              <div class="group">
                <span class="title" v-html="$t('pos.readings.status')"></span>
                {{ $t('pos.readings.open') }}
              </div>
            </div>
          </div>
          <div class="result" v-for="unpaidInvoice of unpaidInvoices" :key="unpaidInvoice.id" v-if="unpaidInvoices.length > 0">
            <div class="result__icon-container">
              <svgicon src="icons/invoice.svg" :height="38" :width="30"></svgicon>
            </div>
            <div class="result__content">
              <div class="group">
                <span class="title" v-html="$t('pos.readings.invoice_number')"></span>
                {{ unpaidInvoice.number }}
              </div>
              <div class="group">
                <span class="title" v-html="$t('pos.readings.status')"></span>
                {{ $t('pos.readings.unpaid') }}
              </div>
            </div>
          </div>
          <div class="result--spacer"></div>
        </div>
      <div class="close-batch__footer">
        <button class="btn btn-secondary" @click="hide" v-wave>
          <svgicon class="icon" src="/icons/back-full.svg" :height="24" :width="18"></svgicon>
          {{ $t("back") }}
        </button>
        <div class="footer__buttons">
          <button class="btn btn-secondary btn--done" @click="print" v-wave>
            <svgicon class="icon" src="/icons/print-filled.svg" :height="24" :width="24"></svgicon>
            {{ $t('pos.readings.print_all') }}
          </button>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import PrintFormatter from "@/lib/printer/printFormatter";

  export default {
    name: "CloseBatchErrorModal",

    data() {
      return {
        visible: false,
        actions: null
      };
    },

    mounted() {
      EventBus.$on("show-close-batch-error-modal", (actions) => {
        this.actions = actions;
        this.show();
      });
    },

    beforeDestroy() {
      EventBus.$off("show-close-batch-error-modal");
    },

    computed: {
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      openTables() {
        if (!this.actions) {
          return [];
        }
        return this.actions.openTables;
      },
      unpaidInvoices() {
        if (!this.actions) {
          return [];
        }
        return this.actions.unpaidInvoices;
      }
    },

    methods: {
      show() {
        EventBus.$emit("toggle-pos-header-overlay", true);
        this.visible = true;
      },
      print() {
        let printer = new PrintFormatter({
          xposUrl: this.posConfiguration.getXposUrl()
        });
        printer.newLine()
          .addLine(null, this.$t("pos.readings.close_batch_error_title").toUpperCase(), null)
          .newLine();
        if (this.openTables && this.openTables.length > 0) {
          printer.addLine(this.$t("pos.readings.tables").toUpperCase() + ": " + this.openTables.map(t => t.table).join(", "))
            .newLine();
        }
        if (this.unpaidInvoices && this.unpaidInvoices.length > 0) {
          printer.addLine(this.$t("pos.readings.invoices").toUpperCase() + ": " + this.unpaidInvoices.map(i => i.number).join(", "))
            .newLine();
        }
        printer.print();
      },
      hide() {
        EventBus.$emit("toggle-pos-header-overlay", false);
        this.visible = false;
        this.actions = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  #closeBatchErrorsModal {
    font-weight: 500;
    .close-batch {
      display: flex;
      flex-direction: column;
    }

    .close-batch__description {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .close-batch__content {
      display: flex;
      width: 100%;
      flex-grow: 1;
    }

    .close-batch__results {
      display: flex;
      height: 444px;
      flex-wrap: wrap;
      overflow-y: scroll;
      align-content: flex-start;
    }

    .result {
      display: flex;
      height: 96px;
      padding: 6px;
      box-shadow: 0px 1px 6px #0000001A;
      border: 4px solid var(--background-color-2);
      border-radius: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      width: calc(50% - 20px);
      color: var(--theme-color-3);

      .result__icon-container {
        display: flex;
        fill: var(--illustration-front-color);
        background-color: var(--background-color-3);
        border-radius: 16px;
        height: 76px;
        width: 76px;
        justify-content: center;
        align-items: center;
      }

      .result__content {
        padding: 6px 6px 6px 20px;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;

        .group {
          display: flex;
          padding-left: 20px;
          flex-direction: column;
          font-size: 20px;
          flex-grow: 1;
        }

        .title {
          font-size: 16px;
          color: var(--theme-color-2);
          padding-bottom: 2px;
        }
      }
    }
    .result--spacer {
      display: flex;
      min-width: calc(50%);
      flex-grow: 1;
      height: 0;
    }

    .close-batch__footer {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-4);
      margin-top: 40px;
      .footer__buttons {
        display: flex;
        .btn-primary {
          margin-left: 20px;
        }
      }
    }

    .btn {
      font-size: 20px;
      border-radius: 12px;
      padding: 15px;
      .svgicon {
        margin-right: 16px;
      }
    }
  }
</style>