import LoyaltyAccount, { LoyaltyAccountDefinition } from "./LoyaltyAccountModel";
import Axios from "axios";
import store from "../store";

/**
 * @typedef LoyaltyDefinition
 * @property {"none"|"ishopfood"} type
 * @property {number} [id]
 * @property {number} [totalPoints]
 * @property {number} [availableAmount]
 * @property {LoyaltyAccountDefinition[]} [accounts]
 */

export default class Loyalty {

  constructor() {
    this._setDefaultLoyalty();
  }

  /**
   * @private
   */
  _setDefaultLoyalty() {
    this.type = "none";
    this.id = null;
    this.totalPoints = null;
    this.balance = null;
    this.accounts = /** @type {LoyaltyAccount[]} */ ([]);
    this.billingCards = [];
  }

  /**
   * @param {LoyaltyDefinition} definition
   */
  async load(definition) {
    if (!definition) {
      this._setDefaultLoyalty();
      return;
    }
    this.type = definition.type || "none";
    this.id = definition.id || null;
    this.balance = definition.availableAmount || null;
    if (definition.accounts) {
      this.accounts = (definition.accounts || []).map(a => new LoyaltyAccount(a));
    }
    // Load default account
    if (this.accounts.length === 0 && this.type === "ishopfood") {
      let response = await Axios.post(store.getters.urlServer + "/api/me/default-loyalty-account", null, {
        headers: {
          Authorization: "Bearer " + store.state.user.token
        }
      });
      this.load(response.data.loyalty);
    }

    //Load freebees info
    if (this.type === "free_bees") {
      let response = await Axios.get(store.getters.urlServer + "/api/me/loyalty/balance", {
        headers: {
          Authorization: "Bearer " + store.state.user.token
        }
      });
      this.load(response.data.balance);
    }

    // TODO - load remote data candy if applicable
  }

  getId(index = 0) {

    if (typeof this.accounts[index] === "undefined") {
      return this.id ? this.id : "";
    }

    if (this.accounts.length == 0) {
      return String(this.id);
    }
    return String(this.accounts[index].id);
  }

  getBalance(index = 0) {
    if (store.state.currentBranch && store.state.currentBranch.shouldBypassAndDisableLoyaltyProgram()) {
      return 0;
    }
    if (this.accounts.length == 0) {
      return this.balance;
    }
    return this.accounts[index].balance !== null ? this.accounts[index].balance : this.balance;
  }

  getTotalPoints() {
    if (this.accounts.length == 0) {
      return 0
    }
    return this.accounts[0].points;
  }

  toDto() {
    switch (this.type) {
      case "none":
        return;
      case "ishopfood":
        return {
          processor: "ishopfood",
          card: String(this.getId())
        };
      default:
        return;
    }
  }

}
