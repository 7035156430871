<template>
  <div id="dispatchCustomerModal" class="modal show">
    <div class="content modal-content">

      <div class="title">
        {{$t("dispatch.choose_customer")}}
        <br>
        <div class="number">{{$user.phoneNumber}}</div>
      </div>

      <div class="customers" v-if="customers && customers.length > 0">
        <div class="customer" v-for="customer of customers" @click="selectCustomer(customer)">
            <div class="icon">
              <svgicon src="icons/profile.svg" :width="30" :height="30"></svgicon>
            </div>
            <div class="right">
              <div class="name">{{ customer.firstName }} {{customer.lastName}}</div>
              <div class="address" v-if="customer.address">{{ customer.address.fullAddress }}</div>
            </div>
            <div class="continue">
              <svgicon src="icons/arrow-right.svg" :width="20" :height="30"></svgicon>
            </div>
        </div>
      </div>


      <div class="buttons">
        <div class="btn btn-secondary flat" @click="$emit('back')">
          {{ $t("back") }}
        </div>
        <div class="btn btn-primary flat" @click="$emit('new')">
          {{ $t("dispatch.new_customer") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapActions } from 'vuex';
  import EventBus from '../lib/eventBus';

  export default {
    name: "dispatchCustomerModal",

    props: {
      customers: Array,
      phoneNumber: String
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      }
    },

    methods: {
      selectCustomer(customer) {
        this.$emit("selected", customer);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #dispatchCustomerModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    fill: var(--primary-color);
  }

  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    line-height: 1;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
  }

  .customers {
    max-height: calc(100vh - 200px);
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -10px;
  }

  .customer {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #f2f2f2;
    margin-right: 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .right {
    flex-grow: 1;
  }

  .name {
    font-weight: bold;
  }
  .address {
    font-size: 14px;
    margin-top: 2px;
  }

  .continue {
    margin-left: 10px;
  }

  .icon {
    margin-right: 15px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .number {
    font-weight: normal;
    margin-top: 10px;
  }
</style>
