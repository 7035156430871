<template>
  <div class="resale-warning" :class="{mobile: $store.getters.isMobileOrTablet}" v-if="shouldDisplayWarningForAll || shouldDisplayWarningForSome">
    <svgicon src="icons/info.svg" :height="14" :width="14"></svgicon>
    <i v-if="shouldDisplayWarningForSome">{{$t('item.resale_warning_modifier_some')}}</i>
    <i v-if="shouldDisplayWarningForAll">{{$t('item.resale_warning_modifier_all')}}</i>
  </div>
</template>

<script>
  export default {
    name: "resaleWarning",

    props: {
      modifiers: Array
    },

    computed: {
      shouldDisplayWarningForSome() {
        if (!this.modifiers || this.modifiers.length === 0) {
          return false;
        }
        let resaleModifiers = this.modifiers.filter(m => m.isResaleItem());
        return resaleModifiers.length > 0 && resaleModifiers.length < this.modifiers.length;
      },
      shouldDisplayWarningForAll() {
        if (!this.modifiers || this.modifiers.length === 0) {
          return false;
        }
        let resaleModifiers = this.modifiers.filter(m => m.isResaleItem());
        return resaleModifiers.length > 0 && resaleModifiers.length === this.modifiers.length;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .resale-warning {
    width: 100%;
    flex-shrink: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &.mobile {
      padding: 10px 10px 0 10px;
    }
    .svgicon {
      margin-right: 10px;
      fill: var(--primary-color);
      flex-shrink: 0;
    }
  }
</style>
