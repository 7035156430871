<template>
  <div id="newAccountModal" class="modal" :class="{show}">
    <div class="content modal-content">

      <div class="title">{{$t('loyalty.account_information')}}</div>

      <div class="form">
        <div class="input" v-show="!!!editAccount">
          <input id="chk_email" type="checkbox" :disabled="!!editAccount" v-model="showUsername">
          <label for="chk_email">{{ $t('loyalty.no_email') }}</label>
        </div>
        <div class="input" v-show="showUsername">
          <label>{{$t("loyalty.username")}}</label>
          <input type="text" v-model="username" :disabled="!!editAccount" :class="{invalid: $validate.error('username')}">
        </div>
        <div class="input" v-show="!showUsername">
          <label>{{$t("profile.email")}}</label>
          <input type="text" v-model="email" :disabled="!!editAccount" :class="{invalid: $validate.error('email')}">
        </div>
        <div class="input">
          <label>{{$t("profile.first_name")}}</label>
          <input type="text" v-model="firstName" :class="{invalid: $validate.error('firstName')}">
        </div>
        <div class="input">
          <label>{{$t("profile.last_name")}}</label>
          <input type="text" v-model="lastName" :class="{invalid: $validate.error('lastName')}">
        </div>
        <div class="input">
          <label>{{$t('profile.birth_date')}}</label>
          <div class="date">
            <svgicon class="history-icon" src="icons/history.svg" :height="16" :width="16"></svgicon>
            <datetime zone="UTC" v-model="birthDate" :format="dateFormat" :max-datetime="maxDate" :class="{invalid: $validate.error('birthDate')}"
                      :week-start="7"></datetime>
          </div>
        </div>
        <div class="input">
          <label>
            {{$t("register.password")}}<template v-if="editAccount"> ({{$t('loyalty.leave_blank_if_unchanged')}})</template>
          </label>
          <input type="password" v-model="password" :placeholder="editAccount ? '******' : ''" :class="{invalid: $validate.error('password')}">
        </div>
        <div class="input">
          <label>{{$t("register.password_confirmation")}}</label>
          <input type="password" v-model="confirmPassword" :placeholder="editAccount ? '******' : ''" :class="{invalid: $validate.error('confirmPassword')}">
        </div>
        <div class="input">
          <label>{{$t("form.add_photo")}} ({{$t("optional")}})</label>
          <FileUploader ref="fileUploader" :image="image" @upload="photoData = $event"></FileUploader>
        </div>
      </div>

      <div class="buttons">
        <div class="btn btn-secondary flat" @click="back">
          {{$t('back')}}
        </div>
        <div class="btn btn-primary flat" v-if="!editAccount" @click="create">
          {{$t('loyalty.new_account_short')}}
        </div>
        <div class="btn btn-primary flat" v-if="editAccount" @click="save">
          {{$t('loyalty.edit_account')}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import FileUploader from "@/components/FileUploader.vue";
  import moment from "moment-timezone";

  export default {

    name: "NewAccountModal",

    components: { FileUploader },

    data() {
      return {
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        confirmPassword: "",
        birthDate: "",
        email: "",
        image: null,
        showUsername: false,
        show: false,
        photoData: null
      }
    },

    props: {
      userEmail: String,
      editAccount: Object,
      visible: Boolean
    },

    validate: {
      username: {
        required: true,
        condition: function() {
          return this.showUsername;
        }
      },
      email: {
        required: true,
        email: true,
        condition: function() {
          return !this.showUsername;
        }
      },
      password: {
        required: true,
        condition: function() {
          return !this.editAccount;
        }
      },
      confirmPassword: {
        required: true,
        condition: function() {
          return !this.editAccount || this.password;
        }
      },
      firstName: {
        required: true
      },
      lastName: {
        required: true
      },
      birthDate: {
        required: true
      }
    },

    mounted() {
      this.init();
    },

    watch: {
      visible() {
        this.init();
      },
      editAccount: function(val) {
        if (val) {
          this.initEditAccount();
        } else {
          this.reset();
        }
      }
    },

    methods: {
      reset() {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.username = "";
        this.showUsername = false;
        this.birthDate = "";
        this.password = "";
        this.image = null;
        this.photoData = null;
        this.confirmPassword = "";
        if (this.$refs && this.$refs.fileUploader) {
          this.$refs.fileUploader.remove();
        }
      },
      init() {
        this.showUsername = false;
        this.show = this.visible;
      },
      async initEditAccount() {
        if (!this.editAccount) {
          return;
        }
        showSpinner();
        let user = this.editAccount.user;
        let info = await this.$user.getChildAccountInformation(user.id);
        if (!user.email) {
          this.showUsername = true;
        }
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.username = user.username;
        if (info && info.information) {
          this.birthDate = info.information.birthDate;
          this.image = info.information.image;
        }
        hideSpinner();
      },
      async save() {
        if (!this.$validate.valid() || this.password !== this.confirmPassword) {
          showAffirmation(this.$t("error.title"), this.$t("loyalty.create_invalid"));
          return;
        }
        showSpinner();
        let response = await this.$user.updateInformation({
          childId: this.editAccount.user.id,
          birthDate: this.birthDate,
          firstName: this.firstName,
          lastName: this.lastName,
          image: this.photoData ? this._getBase64Data(this.photoData.data) : undefined,
          password: this.password ? this.password : undefined
        });
        hideSpinner();
        if (response) {
          showAffirmation(this.$t("success"), this.$t("loyalty.edit_success"));
          this.$emit("created");
          this.reset();
        } else {
          showAffirmation(this.$t("error.title"), this.$t("loyalty.edit_error"));
        }
      },
      async create() {
        if (!this.$validate.valid() || this.password !== this.confirmPassword) {
          showAffirmation(this.$t("error.title"), this.$t("loyalty.create_invalid"));
          return;
        }
        showSpinner();
        let r = await this.$user.registerChildAccount({
          branch: this.$store.state.currentBranch.nameCanonical,
          firstName: this.firstName,
          lastName: this.lastName,
          email: !this.showUsername ? this.email : undefined,
          username: this.showUsername ? this.username : this.email,
          password: this.password
        });
        hideSpinner();
        if (r.success) {
          showSpinner();
          let id = r.response.data.user.id;
          await this.$user.updateInformation({
            childId: id,
            birthDate: this.birthDate,
            image: this.photoData ? getBase64Data(this.photoData.data) : undefined
          });
          await this.$user.update();
          hideSpinner();

          showAffirmation(this.$t("success"), this.$t("loyalty.create_success"));
          this.$emit("created");
          this.reset();
        } else {
          if (r.response && r.response.data && r.response.data.key) {
            let translated = this.$t(`error.${r.response.data.key}`);
            if (translated === `error.${r.response.data.key}`) {
              translated = this.$to(r.response.data.message)
            }
            showAffirmation(this.$t("error.title"), translated);
          } else {
            showAffirmation(this.$t("error.title"), this.$t("register.401"));
          }
        }
      },
      back() {
        this.$emit("closed");
        this.show = false;
        this.reset();
      }
    },

    computed: {
      dateFormat() {
        if (this.$ts.locale == "en") {
          return "MMMM d yyyy";
        }
        return "d MMMM yyyy";
      },
      maxDate() {
        return moment().toISOString();
      }
    }

  }

</script>

<style lang="scss" scoped>
  #newAccountModal {
    z-index: 2;
  }
  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
  }
  .form {
    max-height: calc(100vh - 250px);
    padding-right: 10px;
    margin-right: -10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    margin-top: 20px;
    padding-top: 20px;
  }
  .input {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .date {
    position: relative;
  }
  .history-icon {
    cursor: text;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
