//@ts-check
import { Configuration } from "../../util/Configuration";

export default {

  /**
   * Makes an HTTP request with the given parameters
   * @param {import("vuex").ActionContext} context
   * @param {{method: string, url: string, data: any, headers: object}} param1
   */
  request(context, {
    method,
    url,
    data,
    headers
  }) {
    return new Promise(async (resolve, reject) => {
      let request = new XMLHttpRequest();
      if (data && method === "GET") {
        url += "?" + await context.dispatch("_formatObjectParameter", {
          key: null,
          obj: data
        });
        data = null;
      }
      request.open(method, context.getters.urlServer + url, true);
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          resolve(request.responseText ? JSON.parse(request.responseText) : null);
        } else {
          if (request.status === 426 && !!context.state.mobileOs) {
            let parsedResponse = request.responseText ? JSON.parse(request.responseText) : null;
            if (!!parsedResponse && !!parsedResponse[context.state.mobileOs]) {
              context.state.updateUrl = parsedResponse[context.state.mobileOs].url;
            }
            context.state.updateRequired = true;
          }
          reject(request);
        }
      };
      request.onerror = e => {
        console.log("ON REQUEST ERROR");
        console.log(e);
        if (window.logToFile) {
          window.logToFile("ON REQUEST ERROR");
          window.logToFile(e);
        }
        reject(request);
      };
      if (CONFIG.kiosk && Configuration.get("kiosk-api-key")) {
        request.setRequestHeader("api-key", Configuration.get("kiosk-api-key"));
      } else if (CONFIG.pos && Configuration.get("pos-api-key")) {
        request.setRequestHeader("api-key", Configuration.get("pos-api-key"));
      } else if (context.state.user && context.state.user.token) {
        request.setRequestHeader("Authorization", "Bearer " + context.state.user.token);
      }
      if (data) {
        request.setRequestHeader("Content-Type", "application/json");
      }
      if (typeof cordova !== "undefined") {
        request.setRequestHeader("User-App-Name", CONFIG.shopLock);
        if (context.state.mobileOs === "android" && typeof CONFIG.versionAndroid !== "undefined") {
          request.setRequestHeader("User-Version-Android", CONFIG.versionAndroid);
        } else if (context.state.mobileOs === "ios" && typeof CONFIG.versionIos !== "undefined") {
          request.setRequestHeader("User-Version-IOS", CONFIG.versionIos);
        }
      }
      if (headers) {
        for (let header of Object.keys(headers)) {
          request.setRequestHeader(header, headers[header]);
        }
      }
      request.send(data ? JSON.stringify(data) : null);
    });
  },

  /**
   * @param {import("vuex").ActionContext} context
   * @param {{key: string, value: any}} param1
   * @returns {Promise<string>}
   */
  async _formatParameter(context, {
    key,
    value
  }) {
    if (!value) {
      return "";
    }
    if (typeof value === "object") {
      if (typeof value.length !== "undefined") {
        return await context.dispatch("_formatArrayParameter", {
          key,
          value
        });
      }
      return await context.dispatch("_formatObjectParameter", {
        key,
        obj: value
      });
    }
    return key + "=" + value;
  },

  /**
   * @param {import("vuex").ActionContext} context
   * @param {{key: string, values: any[]}} param1
   * @returns {Promise<string>}
   */
  async _formatArrayParameter(context, {
    key,
    values
  }) {
    let str = "";
    for (let i = 0; i < values.length; i++) {
      let param = await context.dispatch("_formatParameter", {
        key: key + "[" + i + "]",
        value: values[i]
      });
      if (param) {
        str += param + "&";
      }
    }
    return str.substring(0, str.length - 1);
  },

  /**
   * @param {import("vuex").ActionContext} context
   * @param {{key: string, obj: any}} param1
   * @returns {Promise<string>}
   */
  async _formatObjectParameter(context, {
    key,
    obj
  }) {
    let str = "";
    for (let objKey of Object.keys(obj)) {
      let param;
      if (key) {
        param = await context.dispatch("_formatParameter", {
          key: key + "[" + objKey + "]",
          value: obj[objKey]
        });
      } else {
        param = await context.dispatch("_formatParameter", {
          key: objKey,
          value: obj[objKey]
        });
      }
      if (param) {
        str += param + "&";
      }
    }
    return str.substring(0, str.length - 1);
  }

};
