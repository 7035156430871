/**
 * @typedef EmployeeDefinition
 * @property {string} firstName
 * @property {string} lastName
 * @property {number} id
 * @property {string} pin
 * @property {{id: number, start: string, end: string}|null} lastEntry
 * @property {string} passwordHash
 * @property {{language: string, leftHanded: boolean, publicName: string}} preferences
 * @property {{id: number, name: string}} posRole
 */

import { Cryptography } from "@/util/Cryptography";

export default class Employee {

    /**
     * @param {EmployeeDefinition} definition
     */
    constructor(definition) {
        this.firstName = definition.firstName;
        this.lastName = definition.lastName;
        this.id = definition.id;
        this.pin = definition.pin;
        this.lastEntry = definition.lastEntry || {
            id: null,
            start: null,
            end: null
        };
        this.preferences = {
            language: definition.preferences ? definition.preferences.language : "en",
            leftHanded: definition.preferences ? definition.preferences.leftHanded : false,
            publicName: definition.preferences ? definition.preferences.publicName : ""
        };
        this.passwordHash = definition.passwordHash;
        this.password = null;
        this.posRole = {
            id: definition.posRole ? definition.posRole.id : null,
            name: definition.posRole ? definition.posRole.name : null
        };
    }

    getActiveEntry() {
        if (this.lastEntry && this.lastEntry.start && !this.lastEntry.end) {
            return this.lastEntry;
        }
        return {};
    }

    /**
     * @param {{id: number, start: string, end: string}|null} lastEntry
     */
    setLastEntry(lastEntry) {
        this.lastEntry = lastEntry;
    }

    getPin() {
        return this.pin || undefined;
    }

    getPasswordHash() {
        return this.passwordHash || undefined;
    }

    getPassword() {
        return this.password || undefined;
    }

    setPassword(password) {
        this.password = password;
    }

    clearPassword() {
        this.password = null;
    }

    async validatePasswordHash(password) {
        if (!this.passwordHash) {
            return true;
        }
        let encodedPassword = await Cryptography.encryptTextToHashArray(password);
        return encodedPassword === this.passwordHash;
    }

    getFullName() {
        return (this.firstName + " " + this.lastName).trim();
    }

    getTruncatedFullName() {
        return (this.firstName ? this.firstName.substring(0, 1) + ". " : "") + this.lastName;
    }

    getPublicName() {
        return this.preferences.publicName;
    }

    getDisplayName() {
        return this.getPublicName() || this.getTruncatedFullName();
    }

    getId() {
        return this.id;
    }

    getPosRoleId() {
        return this.posRole?.id;
    }

}