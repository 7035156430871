<template>
  <div class="newmodal__header">
    <div class="newmodal__header__icon" v-if="icon">
      <svgicon :src="icon" :width="24" :height="24"></svgicon>
    </div>
    <div class="newmodal__header__text">{{ title }}</div>
    <div class="newmodal__header__action" @click="$emit('close')" v-if="closeButton">
      <svgicon src="icons/close.svg" :width="25" :height="25"></svgicon>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewModalHeader",
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: false
      },
      closeButton: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="scss" scoped>
.newmodal__header {
  font-size: 22px;
  font-weight: 900;
  fill: var(--primary-color);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color-4);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.newmodal__header__icon {
  margin-right: 20px;
}

.newmodal__header__text {
  flex-grow: 1;
}

.newmodal__header__action {
  cursor: pointer;
  fill: #dadada;
  margin-left: 20px;
}
</style>