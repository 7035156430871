<template>
  <div class="view-menu" v-if="branch && branch.getCompaniesWithMenuDisplay().length > 0">
    <div class="text">
      {{$t('display_mode_description')}}
    </div>
    <button class="btn btn-secondary flat display-mode" @click="showMenuDisplayLocationsModal">
      {{$t("enter_display_mode")}}
      <svgicon src="icons/arrow-up.svg" :height="14" :width="14" :style="{fill: $store.state.secondaryButtonTextColor || '#ed2937' }"></svgicon>
    </button>
  </div>
</template>

<script>
  import EventBus from '../lib/eventBus';

  export default {
    name: "EnterDisplayMode",

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      }
    },

    methods: {
      showMenuDisplayLocationsModal() {
        EventBus.$emit("menu-display-locations-modal");
      }
    }
  }
</script>

<style lang="scss" scoped>

  .view-menu {
    display: flex;
    align-items: center;
    border-top: 1px dashed #ccc;
    padding-top: 15px;
  }

  .svgicon {
    transform: rotate(90deg);
    margin-left: 10px;
  }

  .text {
    margin-right: 30px;
    color: #888;
  }

  #iShopFoodApp .btn {
    font-size: 16px;
    padding: 10px;
  }

  @media (max-width: 600px) {
    .view-menu {
      flex-direction: column;
      text-align: center;

      .text {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

</style>