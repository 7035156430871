import { CashDrawer } from "@/util/pos/CashDrawer";
import { Constant } from "@/util/Constant";
import { Cryptography } from "@/util/Cryptography";
import Detail from "./DetailModel";
import Invoice from "./InvoiceModel";
import { LocalizeStore } from "@/lib/localize";
import { PromotionVisitorPOS } from "./promotions/PromotionVisitorPOS";
import Store from "../../store";
import { Util } from "@/util/Util";
import moment from "moment-timezone";
import { osName } from "mobile-device-detect";

/**
 * @typedef TableDefinition
 * @property {Number} id
 * @property {String|null} startedAt
 * @property {String|null} closedAt
 * @property {Number} company
 * @property {String|Number} employee
 * @property {String} employeePin
 * @property {String} table
 * @property {String} status
 * @property {String} currency
 * @property {Number} subTotal
 * @property {Number} tipTotal
 * @property {Number} serviceFees
 * @property {Number} total
 * @property {{name:String, rate:Number, type:String, total:Number}[]} taxes
 * @property {{id:Number, name:String, total:Number}[]} promotions
 * @property {import("@/models/pos/InvoiceModel").InvoiceDefinition[]} invoices
 * @property {String} os
 * @property {String} fuid
 * @property {String} appName
 * @property {String} appVersion
 * @property {String} language
 * @property {String} timezone
 * @property {String} deviceUUID
 * @property {*} contact
 * @property {PaymentDefinition[]} payments
 * @property {import("@/models/pos/DetailModel").DetailDefinition[]} details
 */

/**
 * @typedef PaymentDefinition
 * @property {number} amount
 * @property {string} currency
 * @property {string} from
 * @property {string} method
 * @property {string} processor
 * @property {number[]} invoices
 * @property {string} cashDrawer
 * @property {number} change
 */

export default class Table {

    /**
     * @param {TableDefinition} [definition]
     */
    constructor(definition) {
        this.reset();
        if (definition) {
            this.load(definition);
        }
    }

    reset() {
        this.orderId = null;
        this.startedAt = null;
        this.closedAt = null;
        this.company = null;
        this.employee = {
            id: null
        };
        this.tableId = null;
        this.method = null;
        this.date = null;
        this.hour = null;
        this.state = null;
        this.currency = null;
        this.isDispatch = false;
        this.shipperEstimatedPickupTime = null;
        this.tipTotal = 0;
        this.serviceFees = 0;
        this.surchargeFees = 0;
        this.invoices = [];
        this.seatsByInvoice = [];
        this.os = null;
        this.fuid = null;
        this.appName = null;
        this.language = null;
        this.timezone = null;
        this.appVersion = null;
        this.deviceUUID = null;
        this.payments = [];
        this.details = /** @type Detail[] */ ([]);
        if (Store.state.currentBranch && Store.state.currentCompany) {
            let branch = Store.state.currentBranch.id;
            let company = Store.state.currentCompany.id;
            this.promotionVisitor = new PromotionVisitorPOS(this, branch, company);
        }
    }

    /**
     * @param {TableDefinition} [definition]
     */
    load(definition) {
        this.orderId = definition.id;
        this.startedAt = definition.startedAt ? moment(definition.startedAt, Constant.API_DATE_FORMAT) : null;
        this.closedAt = definition.closedAt ? moment(definition.closedAt, Constant.API_DATE_FORMAT) : null;
        this.company = definition.company;
        this.employee = definition.employee;
        this.tableId = definition.table;
        this.state = definition.state;
        this.currency = definition.currency;
        this.method = definition.deliveryMethod;
        this.date = definition.inAdvanceFor ? moment(definition.inAdvanceFor, Constant.API_DATE_FORMAT) : moment();
        this.hour = definition.inAdvanceFor ? moment(definition.inAdvanceFor, Constant.API_DATE_FORMAT).format("HH:mm") : null;
        this.tipTotal = definition.tipTotal || 0;
        this.serviceFees = definition.serviceFees || 0;
        this.surchargeFees = definition.surchargeFees || 0;
        this.invoices = (definition.invoices || []).map(d => new Invoice(d));
        this.seatsByInvoice = [];
        this.os = definition.os;
        this.fuid = definition.fuid;
        this.appName = definition.appName;
        this.appVersion = definition.appVersion;
        this.language = definition.language;
        this.timezone = definition.timezone;
        this.deviceUUID = definition.deviceUUID;
        this.payments = definition.payments || [];
        this.details = definition.details.map(d => new Detail(d, true)) || [];
        console.log(this.details);
    }

    loadPromotions() {
        if (this.promotionVisitor) {
            this.promotionVisitor.loadFromServer();
        }
    }

    /**
     * @param {{company: string|id, table: number, method: String}} data
     */
    initialize(data) {
        this.startedAt = moment();
        this.company = data.company;
        this.employee = {
            id: Store.state.employee.getId()
        };
        this.tableId = data.table;
        this.setMethod(data.method);
        this.date = moment();
        this.hour = null;
        this.currency = Store.state.inventory.currency;
        this.state = Constant.STATE_OPEN;
        this.os = osName;
        this.fuid = this.generateUniqueID();
        this.appName = Constant.POS_APP_NAME;
        this.appVersion = Store.state.version;
        this.language = LocalizeStore.state.local;
        this.timezone = moment.tz.guess();
        this.deviceUUID = Store.state.posConfiguration.getDeviceUUID();
    }

    toDto() {
        let company = Store.state.currentCompany;
        let user = Store.state.user;
        let data = {
            id: this.orderId || undefined,
            startedAt: this.startedAt.format(Constant.API_DATE_FORMAT),
            company: this.company,
            employee: this.employee,
            table: this.tableId,
            currency: this.currency,
            state: this.state,
            os: this.os,
            fuid: this.fuid,
            appName: this.appName,
            appVersion: this.appVersion,
            language: this.language,
            timezone: this.timezone,
            deviceUUID: this.deviceUUID,
            tipTotal: this.tipTotal,
            surchargeFees: this.surchargeFees,
            serviceFees: this.serviceFees,
            details: this.details.map(d => d.toDto()),
            invoices: this.invoices.map(i => i.toDto()),
            deliveryMethod: this.method,
            deliveryData: null,
            inAdvanceFor: this.getInAdvanceForDate(),
            onBehalfOf: user.isDispatchUser() && user.onBehalfOf ? user.onBehalfOf : undefined,
            contact: {
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                method: "email",
                consent: user.messagingConsent,
                phone: user.phoneNumber || "",
                email: user.email || ""
            }
        };
        if (this.method === "delivery") {
            data.deliveryData = user.getDefaultAddress().toDto();
            data.deliveryData.shipperEstimatedPickupTime = company.isDoorDashActivated() && this.shipperEstimatedPickupTime
                ? this.shipperEstimatedPickupTime.format("YYYY-MM-DD HH:mm:ss")
                : undefined;
        }
        return data;
    }

    toDtoForPayment() {
        return {
            company: this.company,
            employee: this.employee,
            payments: this.payments //Payment list is only allowed in /pay API call. The field will be discarded in regular PUT to table
        };
    }

    toDtoForClose() {
        return {
            company: this.company,
            employee: this.employee
        };
    }

    toDtoForCustomerDisplay() {
        const groupedDetails = this.getGroupedDetails(1);
        let currentItems = [];
        groupedDetails.forEach((detailItem, index) => {
            let item = {};
            item.imageURL = CONFIG.urlFileServer + detailItem.getInventoryItem().image;
            item.name = detailItem.name;
            item.extras = [];
            if (typeof detailItem.details !== "undefined") {
                detailItem.details.forEach((extraDetail, index) => {
                    let extra = {};
                    extra.name = extraDetail.name;
                    extra.price = window.translateCurrency(extraDetail.getPrice());
                    item.extras.push(extra);
                });
            }
            item.itemPrice = window.translateCurrency(detailItem.getTotalPrice());
            item.itemQuantity = detailItem.quantity.toString();
            currentItems.push(item);
        });
        return {
            items: currentItems
        };

    }

    //Should have another toDto for offline with more fields

    /**
     * @returns {string}
     */
    getTableId() {
        return this.tableId;
    }

    getInAdvanceForDate() {
        let company = Store.state.currentCompany;
        let date = moment(this.date).tz(company.timezone);
        return ((this.hour && this.hour !== "asap" && this.hour !== "delay") || this.method === "catering")
            ? date.set({
                hours: parseInt(this.hour.split(":")[0]),
                minutes: parseInt(this.hour.split(":")[1]),
                seconds: 0
            }).format("YYYY-MM-DD HH:mm:ss")
            : null;
    };

    /**
     * check if table has items
     * @returns {boolean}
     */
    hasItems() {
        return this.details.length > 0;
    }

    /**
     * @returns {number}
     */
    getCompanyId() {
        return this.company;
    }

    /**
     * @returns {number}
     */
    getOrderId() {
        return this.orderId;
    }

    getState() {
        return this.state;
    }

    getEmployee() {
        return this.employee;
    }

    getStartDate() {
        return this.startedAt;
    }

    getCloseDate() {
        return this.closedAt;
    }

    /**
     * @returns {boolean}
     */
    isClosed() {
        return this.state === Constant.STATE_CLOSED;
    }

    /**
     * @returns {boolean}
     */
    isOpen() {
        return this.state === Constant.STATE_OPEN;
    }

    /**
     * Set table status to closed (local only)
     */
    close() {
        this.status = Constant.STATE_CLOSED;
    }

    setMethod(method) {
        this.method = method;
        Store.state.currentRevenueCenterRef = Store.state.currentCompany.getRevenueCenterRefForMethod(method);
    }

    /**
     * Generates and returns new details for item
     * @param {import("@/models/ItemModel").Item} item
     * @param {number} seat
     * @return {Detail[]}
     */
    generateNewDetailsForItem(item, seat) {
        let method = "takeout"; //TODO order method ?? needed for item price
        let companyTaxes = (Store.state.currentCompany.taxes || []).map(t => t.uniqueName);
        let parentId = this.getNextDetailId();
        let nextId = parentId + 1;
        let newDetails = [];

        let itemDetail = new Detail({
            parentItemId: item.getParentId(),
            parentDetail: null,
            altId: parentId,
            item: item.getId(),
            kitchenName: item.getKitchenName(),
            name: window.translateObject(item.getName()),
            quantity: item.quantity,
            seat: seat,
            appliedTaxes: item.taxable ? companyTaxes : [],
            tags: item.getTags(),
            unitPrice: item.getPrice(method)
        }, false);
        itemDetail.setInventoryItem(item);
        newDetails.push(itemDetail);

        // Item choices
        for (let choice of item.getAllChoices()) {
            newDetails.push(new Detail({
                parentDetail: parentId,
                altId: nextId++,
                item: choice.selected.getId(),
                kitchenName: choice.selected.getKitchenName(),
                name: window.translateObject(choice.selected.getName()),
                quantity: 1,
                seat: seat,
                appliedTaxes: choice.selected.taxable ? companyTaxes : [],
                unitPrice: choice.selected.getPrice(method)
            }, false));

            // Choice item modifier groups
            for (let modifierGroup of choice.selected.modifierGroups) {
                for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
                    newDetails.push(new Detail({
                        parentDetail: parentId,
                        altId: nextId++,
                        item: modifier.id,
                        kitchenName: modifier.getKitchenName(),
                        name: window.translateObject(modifier.name),
                        quantity: modifier.quantity,
                        seat: seat,
                        appliedTaxes: modifier.taxable ? companyTaxes : [],
                        unitPrice: modifier.getPrice(method)
                    }, false));
                }
            }

            // Choice modifier groups
            for (let modifierGroup of choice.modifierGroups) {
                for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
                    newDetails.push(new Detail({
                        parentDetail: parentId,
                        altId: nextId++,
                        item: modifier.id,
                        kitchenName: modifier.getKitchenName(),
                        name: window.translateObject(modifier.name),
                        quantity: modifier.quantity,
                        seat: seat,
                        appliedTaxes: modifier.taxable ? companyTaxes : [],
                        unitPrice: modifier.getPrice(method)
                    }, false));
                }
            }

            for (let subchoice of choice.selected.getChoices()) {
                newDetails.push(new Detail({
                    parentDetail: parentId,
                    altId: nextId++,
                    item: subchoice.selected.getId(),
                    kitchenName: subchoice.selected.getKitchenName(),
                    name: window.translateObject(subchoice.selected.getName()),
                    quantity: 1,
                    seat: seat,
                    appliedTaxes: subchoice.selected.taxable ? companyTaxes : [],
                    unitPrice: subchoice.selected.getPrice(method)
                }, false));

                //TODO: I don't this that this is working, the loop with the choice.items is working (before this loop)
                for (let modifierGroup of subchoice.selected.modifierGroups) {
                    for (let modifier of modifierGroup.modifiers.filter(m => m.quantity > 0)) {
                        newDetails.push(new Detail({
                            parentDetail: parentId,
                            altId: nextId++,
                            item: modifier.id,
                            kitchenName: modifier.getKitchenName(),
                            name: window.translateObject(modifier.name),
                            quantity: modifier.quantity,
                            seat: seat,
                            appliedTaxes: modifier.taxable ? companyTaxes : [],
                            unitPrice: modifier.getPrice(method)
                        }, false));
                    }
                }
            }
        }

        // Item modifiers
        for (let modifier of item.getSelectedModifiers()) {
            newDetails.push(new Detail({
                parentDetail: parentId,
                altId: nextId++,
                item: modifier.id,
                kitchenName: modifier.getKitchenName(),
                name: window.translateObject(modifier.name),
                quantity: modifier.quantity,
                seat: seat,
                appliedTaxes: modifier.taxable ? companyTaxes : [],
                unitPrice: modifier.getPrice(method)
            }, false));

            for (let choice of modifier.getChoices()) {
                if (!choice.selected) {
                    continue;
                }
                newDetails.push(new Detail({
                    parentDetail: parentId,
                    item: choice.selected.id,
                    altId: nextId++,
                    kitchenName: choice.selected.getKitchenName(),
                    name: window.translateObject(choice.selected.name),
                    quantity: choice.selected.quantity,
                    seat: seat,
                    appliedTaxes: choice.selected.taxable ? companyTaxes : [],
                    unitPrice: choice.selected.getPrice(method)
                }, false));
            }
        }

        return newDetails;
    }

    /**
     * Generates and adds details to table.
     * @param {import("@/models/ItemModel").Item} item
     * @param {number} seat
     * @return {Detail[]}
     */
    addItemToTable(item, seat) {
        //TODO check for identical item before adding to cart, combine quantity...
        let newDetails = this.generateNewDetailsForItem(item, seat);
        this.details = [...this.details, ...newDetails];
        if (this.promotionVisitor) {
            this.promotionVisitor.addPOSDetails(newDetails);
        }
    }

    /**
     * Get next highest available detail ID
     * @returns {number}
     */
    getNextDetailId() {
        let details = this.getDetails();
        let max = 0;
        for (let i = 0; i < details.length; i++) {
            let id = parseInt(details[i].id);
            if (id > max) {
                max = id;
            }
        }
        return max + 1;
    }

    /**
     * @param {string} paymentMethod
     * @param {number} amount
     * @param {number[]} invoices
     * @return {PaymentDefinition}
     */
    addOfflinePayment(paymentMethod, amount, invoices) {
        let payment = {
            amount: amount,
            from: null,
            processor: "none",
            currency: this.currency,
            method: paymentMethod,
            invoices: invoices
        };
        if (paymentMethod === "cash" && CashDrawer.drawerId) {
            payment.cashDrawer = CashDrawer.drawerId;
        }
        this.payments.push(payment);
        return payment;
    }

    /**
     * @param {string|number} terminalNumber
     * @param {number} amount
     * @param {number[]} invoices
     * @returns {PaymentDefinition}
     */
    addPayfactoPaxPayment(terminalNumber, amount, invoices) {
        let payment = {
            amount: amount,
            from: { terminal: terminalNumber },
            processor: "payfacto",
            currency: this.currency,
            method: "online",
            invoices: invoices
        };
        this.payments.push(payment);
        return payment;
    }

    /**
     * @param {number} id
     * @return {Detail}
     */
    getDetail(id) {
        return this.details.find(d => d.id === id);
    }

    /**
     * @param {number} id
     * @return {Detail}
     */
    getGroupedDetail(id) {
        let detail = this.getDetail(id);
        detail.details = this.getDetails().filter(d => d.parentDetailId === id);
        return detail;
    }

    /**
     * @param {number} id
     * @return {Detail[]}
     */
    getDetailAndRelatives(id) {
        let detail = this.getDetail(id);
        let relatives = this.getDetails().filter(d => d.parentDetailId === id);
        return [detail, ...relatives];
    }

    /**
     * @param {number[]} [seats]
     * @returns {Detail[]}
     */
    getDetails(seats) {
        if (seats && seats.length > 0) {
            return this.details.filter(d => seats.indexOf(d.seat) > -1);
        } else {
            return this.details;
        }
    }

    /**
     * @param {number[]} [seats]
     * @returns {Detail[]}
     */
    getNewDetails(seats) {
        let details = this.getDetails(seats);
        return details.filter(d => d.isNew());
    }

    /**
     * @param {number[]} seats
     */
    generateHashForSeats(seats) {
        let hashString = "";
        let details = this.getNonVoidDetails(seats);
        let sortedDetails = details.sort((a, b) => a.id - b.id);
        for (let detail of sortedDetails) {
            hashString += detail.toStringForHashCode();
        }
        return Cryptography.generateHashCode(hashString);
    }

    /**
     * @param {number[]?} seats
     * @returns {Detail[]}
     */
    getNonVoidDetails(seats) {
        if (seats && seats.length > 0) {
            return this.details.filter(d => seats.indexOf(d.seat) > -1 && !d.isVoid());
        } else {
            return this.details.filter(d => !d.isVoid());
        }
    }

    /**
     * @param {import("@/models/ItemModel").Item} item
     * @returns {number}
     */
    getItemQuantityAlreadyInCart(item) {
        if (!item) {
            return 0;
        }
        let quantity = 0;
        if (item.variants.length > 0) {
            for (let i = 0; i < item.variants.length; i++) {
                quantity = this.getDetailsWithItemId(item.variants[i].id).reduce((sum, i) => sum + parseInt(i.quantity), quantity);
            }
        }
        return this.getDetailsWithItemId(item.id).reduce((sum, i) => sum + i.quantity, quantity);
    }

    /**
     * @param {number} id
     * @returns {Detail[]}
     */
    getDetailsWithItemId(id) {
        return this.details.filter(i => i.itemId === id);
    }

    /**
     * @returns {boolean}
     */
    hasUnsavedDetails() {
        return this.details.filter(d => d.new).length > 0;
    }

    /**
     * @returns {boolean}
     */
    hasDetails() {
        return this.details.length > 0;
    }

    /**
     * @param {number?} seat
     * @returns {Detail[]}
     */
    getGroupedDetails(seat) {
        let groupedDetails = [];
        let details = this.getDetails(seat ? [seat] : null);
        for (let i = 0; i < details.length; i++) {
            let detail = details[i].clone();
            if (detail.getParentDetailId()) {
                let parent = groupedDetails.find(d => d.getId() === detail.getParentDetailId());//why do we assume que les details sont ordered parent>enfants
                if (parent) {
                    if (!parent.details) {
                        parent.details = [];
                    }
                    parent.details.push(detail);
                } else {
                    groupedDetails.push(detail);
                }
            } else {
                groupedDetails.push(detail);
            }
        }
        return groupedDetails;
    }

    /**
     * get IDs for parent detail and all related child details
     * @param {Detail} detail
     * @returns {number[]}
     */
    getAllDetailIdsRelatedToParentDetail(detail) {
        let ids = [detail.id];
        let details = this.getDetails(detail.seat);
        for (let i = 0; i < details.length; i++) {
            if (details[i].getParentDetailId() && details[i].getParentDetailId() === detail.id) {
                ids.push(details[i].id);
            }
        }
        return ids;
    }

    /**
     * get IDs for parent detail,relatives and split occurence details if applicable
     * @param {Detail} detail
     * @returns {number[]}
     */
    getAllDetailRelativesAndSplitOccurenceDetailsId(detail) {
        let ids = [];
        if (!detail.isSplit()) {
            return this.getAllDetailIdsRelatedToParentDetail(detail);
        }
        let details = this.getDetails();
        for (let i = 0; i < details.length; i++) {
            if ((details[i].getParentDetailId() && details[i].getParentDetailId() === detail.id) ||
              (details[i].isSplit() && details[i].getOriginalSplitOwner() === detail.getOriginalSplitOwner())) {
                ids.push(details[i].id);
            }
        }
        return ids;
    }

    /**
     * Generates new details for item and replace details in order at same position as previous details
     * @param {Detail} parentDetail
     * @param {import("@/models/ItemModel").Item} newItem
     */
    replaceDetailsForItem(parentDetail, newItem) {
        let newDetails = this.generateNewDetailsForItem(newItem, parentDetail.seat);

        let newParentDetail = newDetails.find(d => !d.parentDetailId);
        if (newParentDetail) {
            let updatedRemarks = this.generateUpdatedRemarksForNewParentDetail(parentDetail, newParentDetail.getId());
            newDetails = [...newDetails, ...updatedRemarks];
        }

        let previousDetailIds = this.getAllDetailIdsRelatedToParentDetail(parentDetail);
        let previousPosition = this.details.indexOf(parentDetail);

        this.removeDetails(previousDetailIds);
        let detailsBeforeIndex = this.details.slice(0, previousPosition);
        let detailsAfterIndex = this.details.slice(previousPosition);

        this.details = [...detailsBeforeIndex, ...newDetails, ...detailsAfterIndex];
        if (this.promotionVisitor) {
            this.promotionVisitor.addPOSDetails(newDetails);
        }
    }

    /**
     * Removes detail and its realitives from table details
     * @param {Detail} detail
     */
    removeDetailAndRelatives(detail) {
        let detailIdsToRemove = this.getAllDetailRelativesAndSplitOccurenceDetailsId(detail);
        this.removeDetails(detailIdsToRemove);
    }

    /**
     * Removes details from list from table details
     * @param {number[]} detailIds
     */
    removeDetails(detailIds) {
        let removed = this.details.filter(d => detailIds.indexOf(d.id) > -1);
        this.details = this.details.filter(d => detailIds.indexOf(d.id) === -1);
        if (this.promotionVisitor) {
            this.promotionVisitor.removePOSDetails(removed);
        }
    }

    /**
     * Void detail and its relatives from table details
     * @param {Detail} detail
     */
    voidDetailAndRelatives(detail) {
        let detailIdsToVoid = this.getAllDetailRelativesAndSplitOccurenceDetailsId(detail);
        this.voidDetails(detailIdsToVoid);
    }

    /**
     * Void details from list from table details
     * @param {number[]} detailIds
     */
    voidDetails(detailIds) {
        for (let detail of this.details) {
            if (detailIds.indexOf(detail.id) > -1) {
                detail.void = true;
                detail.new = true;
            }
        }
    }

    /**
     * Get total information for seat or total
     * @param {number[]?} seats
     * @returns {{}}
     */
    getTotal(seats) {
        let company = Store.state.currentCompany;
        let totalTaxablePerTax = {};
        let totalNonTaxable = 0;
        let totalTaxable = 0;
        // let totalPromotions = 0;
        let detailsTotal = 0;
        // let tip = 0;
        let taxes = {};
        let taxTotal = 0;

        // Initialize taxes
        for (let tax of company.taxes) {
            totalTaxablePerTax[tax.uniqueName] = 0;
        }

        // Calculate detail prices
        let details = this.getNonVoidDetails(seats);
        let parentDetail = null;
        for (let i = 0; i < details.length; i++) {
            let detail = details[i];
            if (!parentDetail || (parentDetail && parentDetail.id !== detail.parentDetailId)) {
                parentDetail = this.getDetail(detail.parentDetailId);
            }
            let price = detail.getPrice() * (parentDetail ? parentDetail.quantity : 1);
            detailsTotal += price;
            if (detail.isTaxable()) {
                for (let tax of detail.appliedTaxes) {
                    totalTaxablePerTax[tax] += price;
                }
                totalTaxable += price;
            } else {
                totalNonTaxable += price;
            }
        }

        // Taxable fee
        if (this.serviceFees && this.serviceFees > 0) {
            for (let tax of company.taxes) {
                totalTaxablePerTax[tax.uniqueName] += this.serviceFees;
            }
            totalTaxable += this.serviceFees;
        }

        // TODO is this a taxable fee ?
        if (this.surchargeFees && this.surchargeFees > 0) {
            for (let tax of company.taxes) {
                totalTaxablePerTax[tax.uniqueName] += this.surchargeFees;
            }
            totalTaxable += this.surchargeFees;
        }

        // TODO promotion

        // Calculate taxes
        for (let tax of company.taxes) {
            let taxAmount = (totalTaxablePerTax[tax.uniqueName] || 0) * tax.value;
            taxes[tax.uniqueName] = Util.Money.round(taxAmount);
            taxTotal += taxes[tax.uniqueName];
        }

        // Set total information data
        let totalInformation = {
            detailsTotal: Util.Money.round(detailsTotal),
            subTotal: Util.Money.round(totalTaxable + totalNonTaxable),
            taxes,
            taxTotal: Util.Money.round(taxTotal),
            totalTaxablePerTax,
            totalTaxable,
            totalNonTaxable,
            total: Util.Money.round(totalTaxable + totalNonTaxable + taxTotal),
            serviceFees: this.serviceFees,
            surchargeFees: this.surchargeFees,
            tip: this.tipTotal,
            totalRemaining: 0,
            cashRounding: 0
        };

        // TODO tip ?

        // Sum of payments
        let paymentsTotal = Util.Money.round(this.payments.reduce((total, payment) => total + payment.amount, 0));
        totalInformation.totalRemaining = Util.Money.round(totalInformation.total + totalInformation.tip - paymentsTotal);

        // Cash rounding and change
        if (CONFIG.pos) {
            // Reset change on all payments
            this.payments.map(payment => { payment.change = 0; });
            // We use the last payment to determine if we need to round the total
            let lastPayment = this.payments[this.payments.length - 1];
            // Only calculate change if there is at least one payment
            if (lastPayment) {
                // We round to the nearest 5 cents and use that to check if the invoice would be paid in full if it was rounded
                let newTotalRemaining = Util.Money.roundToNearest5Cents(totalInformation.totalRemaining);
                if (company.iso2country === "CA" && newTotalRemaining <= 0) {
                    // We round the total and add the difference to the cash rounding
                    let difference = Util.Money.round(newTotalRemaining - totalInformation.totalRemaining);
                    totalInformation.totalRemaining = newTotalRemaining;
                    totalInformation.cashRounding = difference;
                    totalInformation.total = Util.Money.round(totalInformation.total + difference);
                }
                if (totalInformation.totalRemaining <= 0) {
                    lastPayment.change = -newTotalRemaining;
                }
            }
        }

        return totalInformation;
    }

    /**
     * @param {PaymentDefinition} payment
     */
    removePayment(payment) {
        let index = this.payments.indexOf(payment);
        if (index > -1) {
            this.payments.splice(index, 1);
        }
    }

    removePayments() {
        this.payments = [];
    }

    /**
     *
     * @param {number[][]} seatsByInvoice
     */
    generateInvoices(seatsByInvoice) {
        for (let i = 0; i < seatsByInvoice.length; i++) {
            let seats = seatsByInvoice[i];
            this.voidInvoicesForSeats(seats);
            let totalForSeats = this.getTotal(seats);
            let invoice = new Invoice({
                seats: seats,
                subTotal: totalForSeats.subTotal,
                total: totalForSeats.total,
                cashRounding: totalForSeats.cashRounding,
                taxes: totalForSeats.taxes,
                hashCode: this.generateHashForSeats(seats),
                //TODO serviceFees, surchargeFees, how do I split that
                promotions: []
            });
            this.invoices.push(invoice);
        }
    }

    /**
     * @param {number[]} seats
     */
    voidInvoicesForSeats(seats) {
        let invoiceForSeats = this.getInvoiceBySeats(seats);
        if (invoiceForSeats) {
            let index = this.invoices.indexOf(invoiceForSeats);
            this.invoices[index].void = true;
            this.invoices[index].voidAt = moment().format(Constant.API_DATE_FORMAT);
        }
    }

    /**
     *
     * @param {number[][]} seatsByInvoice
     * @return boolean
     */
    shouldGenerateInvoices(seatsByInvoice) {
        if (this.invoices.length === 0 || this.hasUnsavedDetails()) {
            return true;
        }
        for (let i = 0; i < seatsByInvoice.length; i++) { //TODO should only generate invoice for seats that have changed
            let seats = seatsByInvoice[i];
            let invoice = this.getInvoiceBySeats(seats);
            let expectedHashCode = this.generateHashForSeats(seats);
            if (!invoice || (invoice.hashCode !== expectedHashCode)) {
                return true;
            }
        }
        return false;
    }

    /**
     * @returns {string[]}
     */
    getActiveInvoiceNumbers() {
        return this.invoices.filter(i => !i.void && i.number).map(i => i.number);
    }

    /**
     * Returns a list of non-empty seats
     * @returns {number[]}
     */
    getNonEmptySeats() {
        return this.details.filter(d => d.quantity > 0).map(d => d.seat).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a - b);
    }

    /**
     * Returns the number of the highest seats with item(s)
     * @returns {number}
     */
    getHighestSeat() {
        let highestSeatNumber = 0;
        for (let detail of this.details) {
            if (detail && detail.seat > highestSeatNumber) {
                highestSeatNumber = detail.seat;
            }
        }
        return highestSeatNumber;
    }

    /**
     * Returns a list of all seats from the first up to the last seat in infinite seats mode
     * @returns {number[]}
     */
    getAllSeats() {
        let highestSeatNumber = this.getHighestSeat();
        return Array.from({ length: highestSeatNumber }, (v, i) => i + 1);
    }

    /**
     * @param {number[]} seats
     * @returns {Invoice}
     */
    getInvoiceBySeats(seats) {
        return this.invoices.find(i => i.seats.sort().toString() === seats.sort().toString() && !i.void);
    }

    /**
     * @param {import("@/models/pos/InvoiceModel").InvoiceDefinition[]} invoices
     * @return {Invoice[]}
     */
    loadInvoices(invoices) {
        this.invoices = invoices.map(i => new Invoice(i));
        return this.invoices;
    }

    assignInvoiceNumbersToPayments() {
        let invoiceNumbers = this.getActiveInvoiceNumbers();
        for (let payment of this.payments) {
            if (payment.invoices.length === 0) {
                payment.invoices = invoiceNumbers;
            }
        }
    }

    hasCashPayment() {
        return !!this.payments.find(p => p.method === "cash");
    }

    /**
     * bigint value
     * @returns {string}
     */
    generateUniqueID() {
        return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
    }

    round(amount) {
        return Util.Money.round(amount);
    }

    /**
     * Add an open remark to the table, as a standalone item as a child if parentId is provided
     * @param {string} remark
     * @param {number} seat
     * @param {number||null} parentId
     */
    addOpenRemark(remark, seat, parentId = null) {
        let newDetail = new Detail({
            parentDetail: parentId,
            altId: this.getNextDetailId(),
            item: null, //null itemId to flag this detail as an open remark
            name: remark,
            seat: seat
        }, false);

        this.details.push(newDetail);
    }

    /**
     * Clone existing remarks and update their parentId
     * @param {Detail} oldParentDetail
     * @param {number} newParentDetailId
     * @returns {Detail[]}
     */
    generateUpdatedRemarksForNewParentDetail(oldParentDetail, newParentDetailId) {
        let clonedRemarks = [];
        let details = this.getDetails(oldParentDetail.seat);
        for (let i = 0; i < details.length; i++) {
            if (details[i].getParentDetailId() && details[i].getParentDetailId() === oldParentDetail.id && details[i].isOpenRemark()) {
                let remarkClone = details[i].clone();
                remarkClone.setParentDetailId(newParentDetailId);
                clonedRemarks.push(remarkClone);
            }
        }
        return clonedRemarks;
    }

    /**
     * Split Detail across seat(s)
     * @param {Detail} detailToSplit
     * @param {number[]} seats
     */
    splitDetail(detailToSplit, seats) {
        if (!detailToSplit || !seats || seats.length === 0) {
           return;
        }
        let originalDetail = this.getDetail(detailToSplit.getId());
        if (!originalDetail) {
           return;
        }
        let originalSeat = originalDetail.getSeat();
        let originalId = originalDetail.getId();
        let seatQuantity = seats.length;
        let split = { quantity: seatQuantity, id: originalId };
        originalDetail.split = split;

        let originalRelatives = [];
        for (let detail of this.getDetails()) {
            if (detail.parentDetailId === originalId) {
                detail.split = split;
                originalRelatives.push(detail);
            }
        }

        let nextDetailId = this.getNextDetailId();
        for (let seat of seats) {
            if (seat === originalSeat) {
                continue;
            }

            let detailClone = originalDetail.clone();
            detailClone.setSeat(seat);
            detailClone.setId(nextDetailId);
            detailClone.split = split;
            this.details.push(detailClone);

            let parentDetailId = nextDetailId++;
            for (let originalRelative of originalRelatives) {
                let relativeClone = originalRelative.clone();
                relativeClone.setSeat(seat);
                relativeClone.setId(nextDetailId++);
                relativeClone.setParentDetailId(parentDetailId);
                relativeClone.split = split;
                this.details.push(relativeClone);
            }
        }
    }

    /**
     * Remove all split detail(s) except the original and its relative(s)
     * @param {Detail} detailToUnsplit
     */
    unsplitDetail(detailToUnsplit) {
        let originalSplitDetailId = detailToUnsplit.getOriginalSplitOwner();
        if (!originalSplitDetailId) {
            return;
        }

        let details = this.getDetails();
        let detailsToRemove = [];
        for (let detail of details) {
            let splitDetailId = detail.getOriginalSplitOwner();
            if (!splitDetailId || splitDetailId !== originalSplitDetailId) {
                continue;
            }
            if (detail.getId() === originalSplitDetailId || detail.getParentDetailId() === originalSplitDetailId) {
                detail.split = null;
            } else {
                if (detail.isNew()) {
                    detailsToRemove.push(detail.getId());
                } else {
                    detail.void = true;
                }
            }
        }
        if (detailsToRemove.length) {
            this.removeDetails(detailsToRemove);
        }
    }
}