var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel",class:{
  xs: _vm.$store.state.screenSize == 'xs',
   kiosk: _vm.$kiosk,
   selected: _vm.selected && _vm.isCollapsed,
   closed: _vm.isCollapsed,
   locked: _vm.locked,
   flat: _vm.flat,
   compact: _vm.compact,
   hasLeftImage: !!_vm.leftImage}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected && _vm.isCollapsed),expression:"selected && isCollapsed"}],staticClass:"checkmark"},[_c('svgicon',{staticClass:"checkmarkIcon",attrs:{"src":"icons/check-circle.svg","height":20,"width":20}})],1),(_vm.leftImage)?_c('div',{staticClass:"leftImage",style:({backgroundImage: 'url(\'' + _vm.leftImage + '\') !important'})}):_vm._e(),(_vm.title)?_c('div',{staticClass:"title",class:{collapsible: _vm.collapsible},on:{"click":_vm.collapse}},[(_vm.icon)?_c('svgicon',{staticClass:"titleIcon",attrs:{"src":_vm.icon,"height":20,"width":20}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',{staticClass:"flex-grow"}),_c('svgicon',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapsible && _vm.isCollapsed),expression:"collapsible && isCollapsed"}],staticClass:"panel-arrow-icon",attrs:{"src":"icons/arrow.svg","height":20,"width":20}}),_c('svgicon',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapsible && !_vm.isCollapsed),expression:"collapsible && !isCollapsed"}],staticClass:"panel-arrow-icon",attrs:{"src":"icons/arrow-up.svg","height":20,"width":20}})],1):_vm._e(),_c('div',{staticClass:"content",class:{noTitle: !_vm.title, collapsed: _vm.isCollapsed}},[_c('div',{staticClass:"container"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }