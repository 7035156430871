<template>
  <div class="slideOverlay" ref="sliderOverlay" @click="$store.state.mobileMenuShown = false"
    :class="{shown: $store.state.mobileMenuShown}"></div>
</template>

<script>

  export default {

    data() {
      return {
        hammer: null
      };
    },

    mounted() {
      setTimeout(() => {
        this.setupHammer();
      }, 0);
    },

    beforeDestroy() {
      if (this.hammer) {
        this.hammer.destroy();
      }
    },

    methods: {

      setupHammer() {
        this.hammer = new Hammer(this.$refs.sliderOverlay);
        this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });

        this.hammer.on("panstart", () => {
          let content = document.getElementsByClassName("routerViewContent")[0];
          let cart = document.getElementById("cart");
          content.style.transition = "none";
          cart.style.transition = "none";
        });

        this.hammer.on("pan", event => {
          let content = document.getElementsByClassName("routerViewContent")[0];
          let cart = document.getElementById("cart");
          if (event.deltaX < 0) {
            content.style.transform = "translateX(" + Math.max(event.deltaX + 280, 0) + "px)";
            cart.style.transform = "translateX(" + Math.max(event.deltaX + 280, 0) + "px)";
          } else {
            content.style.transform = "";
            cart.style.transform = "";
          }
        });

        this.hammer.on("panend", event => {
          let content = document.getElementsByClassName("routerViewContent")[0];
          let cart = document.getElementById("cart");
          content.style.transition = "transform 0.3s ease-in-out";
          cart.style.transition = "transform 0.3s ease-in-out";
          content.style.transform = "";
          cart.style.transform = "";
          if (event.deltaX < -20) {
            this.$store.state.mobileMenuShown = !this.$store.state.mobileMenuShown;
          }
        });
      }

    }

  }

</script>

<style lang="scss" scoped>

  .slideOverlay {
    position: fixed;
    left: 280px;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    pointer-events: none;
    &.shown {
      pointer-events: all;
    }
  }

</style>
