<template>
  <div class="item-removal-selector item-selector">
    <Modifier type="removal" :selector="removal" @click="changeQuantity" @changed="changeQuantity($event)"></Modifier>
  </div>
</template>

<script>
  import Modifier from "@/components/menu/Modifier.vue";

  export default {

    name: "itemRemovalSelector",

    components: { Modifier },

    props: {
      removal: Object
    },

    methods: {
      changeQuantity() {
        if (this.removal.quantity > 0) {
          this.removal.quantity = 0;
        } else {
          this.removal.quantity = 1;
        }
        this.$emit("changed", this);
      }
    }

  }

</script>
