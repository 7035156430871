<template>
  <div id="history">

    <PageTitle>
      <svgicon src="icons/history.svg" class="icon" :height="20" :width="20"></svgicon>
      Mon historique de commande
    </PageTitle>

    <div class="page-content">

      <!--<Panel title="Mes commandes favorites" icon="icons/favs.svg" collapsible>
        Contenu
      </Panel>-->

      <Panel :title="$t('history.my_history')" icon="icons/bill.svg" collapsible v-if="orders">
        <table class="orders" cellpadding="8px" cellspacing="0" v-if="orders.length > 0">
          <template v-for="order of orders">

            <tr class="order" v-if="!$store.getters.isMobileOrTablet" :class="{selected: order.selected}" :key="order.id">
              <!--<td class="favorite">
                <svgicon src="icons/fav-inactive.svg" :height="28" :width="28" v-if="!order.selected"></svgicon>
                <svgicon src="icons/fav-active.svg" :height="28" :width="28" v-if="order.selected"></svgicon>
              </td>-->
              <td class="number">{{$t('history.order')}} #{{order.id}}</td>
              <td class="type">{{order.method}}</td>
              <td class="date" align="right">{{getDate(order.startedAt)}}</td>
              <td class="hour" align="left">{{getHour(order.startedAt)}}</td>
              <!--<td class="status">Status: {{order.status}}</td>-->
              <td class="price">{{order.total.toFixed(2)}} $</td>
              <td class="details" @click="showDetails(order)">Détails</td>
            </tr>

            <tr class="order mobile" v-if="$store.getters.isMobileOrTablet" :class="{selected: order.selected}" :key="order.id">
              <td class="favorite">
                <!--<svgicon src="icons/fav-inactive.svg" :height="28" :width="28" v-if="!order.selected"></svgicon>
                <svgicon src="icons/fav-active.svg" :height="28" :width="28" v-if="order.selected"></svgicon>-->
              </td>
              <td>
                <div class="left">
                  <span class="number">{{$t('history.order')}} #{{order.id}}</span>
                  <span class="date">{{getDate(order.startedAt)}}, {{getHour(order.startedAt)}}</span>
                </div>
              </td>
              <td>
                <div class="right">
                  <span class="price">{{order.total.toFixed(2)}} $</span>
                  <!--<span class="status">Status: {{order.status}}</span>-->
                </div>
              </td>
              <td class="details" @click="showDetails(order)">
                <svgicon class="icon" src="icons/details.svg" :height="24" :width="24"></svgicon>
              </td>
            </tr>

          </template>
        </table>

        <div class="paging" v-if="orders.length > 0 && maxPages > 1">
          <div class="back" @click="previousPage">
            <svgicon class="icon" src="icons/arrow-left.svg" :height="28" :width="28"></svgicon>
          </div>
          <ul>
            <li v-for="n in pages" :class="{'current-page': n === page}" @click="page = n">{{n}}</li>
          </ul>
          <div class="next" @click="nextPage">
            <svgicon src="icons/arrow-right.svg" :height="28" :width="28"></svgicon>
          </div>
        </div>

        <div class="no-orders" v-if="orders.length === 0">{{$t('history.no_orders')}}</div>

      </Panel>

    </div>

    <div class="modal" id="orderDetails">
      <div class="modal-content" v-if="currentOrder">

        <div class="modal-header">
          <svgicon class="icon" src="icons/bill.svg" :height="20" :width="20"></svgicon>
          <div class="title">
            <span class="number">{{$t('history.order')}} #{{currentOrder.id}}</span>
            <span class="method">{{$t(currentOrder.deliveryMethod)}}</span>
          </div>
          <div class="close" @click="closeModal">
            <svgicon class="icon" src="icons/btn-delete.svg" :height="24" :width="24"></svgicon>
          </div>
        </div>

        <div class="modal-body">

          <div class="top">
            <div class="input-group">
              <label>{{$t('history.order_started_at')}}</label>
              <input type="text" :value="getDate(currentOrder.startedAt) + ' ' + $t('at') + ' ' + getHour(currentOrder.startedAt)" readonly>
            </div>
            <div class="input-group" v-if="currentOrder.inAdvanceFor">
              <label>{{$t('history.order_completed_at')}}</label>
              <input type="text" :value="getDate(currentOrder.inAdvanceFor) + ' ' + $t('at') + ' ' + getHour(currentOrder.inAdvanceFor)" readonly>
            </div>
          </div>

          <div class="separator"></div>

          <div class="sale-items">
            <div class="item" v-for="(item, itemIndex) of currentOrder.items" :key="itemIndex">
              <div class="title" :class="{noBorder: item.combos.length === 0 && item.modifiers.length === 0}">
                <span class="label">{{item.name}}</span>
                <span class="value">{{$tc(item.totalPrice.toFixed(2))}}</span>
              </div>
              <div class="combo" v-for="combo of item.combos">
                <span class="label">{{combo.name}}</span>
                <span class="value" v-if="combo.unitPrice > 0">
                  {{ combo.unitPrice > 0 ? '+' : '-' }} {{$tc((combo.unitPrice * combo.quantity).toFixed(2))}}
                </span>
              </div>
              <div class="modifier" v-for="modifier of item.modifiers">
                <span class="label">
                  {{modifier.name}}
                  <template v-if="modifier.quantity > 1">({{modifier.quantity}})</template>
                </span>
                <span class="value">
                  {{ modifier.unitPrice > 0 ? '+' : '-' }} {{$tc((modifier.unitPrice * modifier.quantity).toFixed(2))}}
                </span>
              </div>
              <div class="quantity">
                <span class="label">{{$t('quantity')}}</span>
                <span class="value">{{item.quantity}}</span>
              </div>
            </div>
          </div>

          <div class="separator"></div>

          <div class="totals">
            <div class="tip" v-if="currentOrder.tipTotal > 0">
              <span class="label">{{$t('total.tip')}}</span>
              <span class="value">{{$tc(currentOrder.tipTotal.toFixed(2))}}</span>
            </div>
            <div class="fee" v-if="currentOrder.deliveryFees > 0">
              <span class="label">{{$t('total.delivery_fees')}}</span>
              <span class="value">{{$tc(currentOrder.deliveryFees.toFixed(2))}}</span>
            </div>
            <div class="fee" v-if="currentOrder.serviceFees > 0">
              <span class="label">{{$t('total.service_fees')}}</span>
              <span class="value">{{$tc(currentOrder.serviceFees.toFixed(2))}}</span>
            </div>
            <div class="subtotal">
              <span class="label">{{$t('total.subtotal')}}</span>
              <span class="value">{{$tc(currentOrder.subTotal.toFixed(2))}}</span>
            </div>
            <div class="tax" v-for="tax of currentOrder.taxes" :key="tax.id">
              <span class="label">{{ tax.name }}<span v-if="tax.taxNumber"> ({{ tax.taxNumber }})</span></span>
              <span class="value">{{ $tc(tax.total.toFixed(2)) }}</span>
            </div>
            <div class="total">
              <span class="label">{{$t('total.total')}}</span>
              <span class="value">{{$tc(currentOrder.total.toFixed(2))}}</span>
            </div>
          </div>

          <div class="separator" v-if="currentOrder.loyaltyData && currentOrder.loyaltyData.gained"></div>

          <div class="loyalty" v-if="currentOrder.loyaltyData && currentOrder.loyaltyData.gained">
            <div class="fidelity-card">
              <span class="label">{{$t('payment.fidelity_card')}}</span>
              <span class="value">#{{currentOrder.loyaltyData.card}}</span>
            </div>
            <div class="points">
              <span class="label">{{$t('history.points_gained')}}</span>
              <span class="value">{{currentOrder.loyaltyData.gained.points}}</span>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">{{$t('back')}}</button>
        </div>

      </div>
    </div>

    <HistoryBack></HistoryBack>

  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import Sale from "../models/SaleModel";
  import moment from "moment-timezone";

  export default {
    name: "history",
    mounted() {
      this.$store.commit("showNavigationCart", true);

      if (this.$user.isAnonymous()) {
        this.$router.replace(this.$router.generate("/menu"));
        return;
      }

      this.loadHistory();
    },
    data() {
      return {
        orders: null,
        perPage: 10,
        page: 1,
        count: 1,
        currentOrder: null
      }
    },
    watch: {
      page() {
        this.loadHistory();
      }
    },
    computed: {
      pages() {
        let counter = 0;
        let pages = [];
        let begin_index = 1;
        if (this.maxPages - this.page < 4) {
          begin_index = this.maxPages - 4;
        } else {
          begin_index = (this.page - 2 < 1 ? 1 : this.page - 2);
        }
        if (begin_index < 1) {
          begin_index = 1;
        }
        for (let i = begin_index; counter < 5 && i <= this.maxPages; i++) {
          pages.push(i);
          counter++;
        }
        return pages;
      },
      maxPages() {
        return Math.max(Math.floor(this.count / this.perPage), 1);
      }
    },
    methods: {

      ...mapActions({
        getHistory: "history"
      }),

      getDate(date) {
        return moment(date.split(" ")[0]).format("D MMMM YYYY");
      },

      getHour(date) {
        let hour = date.split(" ")[1];
        return hour.substring(0, hour.length - 3);
      },

      showDetails(order) {
        this.currentOrder = order;
        document.getElementById("orderDetails").classList.add("show");
      },

      closeModal() {
        document.getElementById("orderDetails").classList.remove("show");
      },

      nextPage() {
        this.page = (this.page === this.maxPages ? this.page : this.page + 1);
      },

      previousPage() {
        this.page = (this.page === 1 ? this.page : this.page - 1);
      },

      async loadHistory() {
        let response = await this.getHistory({ page: this.page, perPage: this.perPage });
        if (response.success) {
          this.orders = [];
          for (let order of response.history.data) {
            this.orders.push(new Sale(order));
          }
          this.count = response.history.count;
        }
      }

    }
  }
</script>

<style lang="scss" scoped>

  .small-title {
    font-size: 14px;
    color: #808080;
  }

  .text {
    font-size: 16px;
    color: #404040;
    padding-bottom: 20px;
  }

  ul {
    margin: 0;
    padding: 8px;
    list-style: none;
  }

  p {
    margin: 0;
    color: #404040;
  }

  a {
    display: block;
    text-decoration: none;
    color: #404040;
    font-size: 16px;
  }

  /* = = = = = = = = = = */
  /*        TABLE        */
  /* = = = = = = = = = = */

  table {
    width: 100%;

    .order {
      height: 40px;

      &.mobile {
        .left, .right {
          display: flex;
          flex-direction: column;
        }
        .number::after, .date::after, .hour:after {
          content: none;
        }
        .number {
          font-weight: bold;
          padding-left: 0;
        }
        .favorite, .details {
          padding: 6px;
        }
        .svgicon {
          margin: auto;
        }
        .right {
          text-align: right;
        }
        td {
          font-size: 14px;
          padding: 10px 0;
        }
      }

      td {
        color: #404040;
      }
      &.selected {
        .favorite {
          fill: #fc6650;
        }
      }
      .favorite {
        cursor: pointer;
        fill: #ccc;
      }
      .number {
        padding-left: 8px;
      }
      .number, .hour {
        white-space: nowrap;
        width: 1%;
        padding-right: 20px;
        position: relative;
      }
      .number::after, .hour:after {
        content: '|';
        position: absolute;
        right: 8px;
        color: inherit;
        opacity: 0.4;
      }
      .type, .hour, .status {
        padding-left: 0;
      }
      .details {
        text-align: right;
        padding-right: 8px;
      }
      .price {
        font-weight: bold;
        text-align: right;
        width: 1px;
        white-space: nowrap;
      }
      .details {
        color: #ed2937;
        cursor: pointer;
        fill: #fc6650;

        &:hover {
          color: #fc6650;
          text-decoration: underline;
        }
      }
      &:nth-child(odd) {
        background-color: #f5f5f5;
      }
    }
  }

  .paging {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .svgicon {
      fill: #fc6650;
      cursor: pointer;
      &:hover {
        fill: #ed2937;
      }
    }
    ul {
      margin: 0;
      margin-top: 4px;
      padding: 0;
      li {
        display: inline;
        padding: 8px 12px;
        cursor: pointer;
        border: 2px solid transparent;
        &.current-page {
          border: 2px solid #ed2937;
          border-radius: 5px;
        }
        &:hover {
          opacity: 0.5;
        }
        &.current-page:hover {
          opacity: 1;
        }
      }
    }
  }

  #orderDetails {

    .modal-content {
      max-width: 540px;
      overflow-y: auto;
      max-height: 100%;
    }

    .modal-header {
      font-size: 16px;
      display: flex;
      fill: #ccc;

      .title {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
      .number {
        font-weight: bold;
        font-size: 18px;

        &:after {
          content: "|";
          margin: 0 10px;
          opacity: 0.5;
          color: #bfbfbf;
        }
      }
      .method {
        font-size: 18px;
      }
      .icon {
        margin-right: 15px;
      }
      .close {
        cursor: pointer;
        fill: #fc6650;
        .svgicon {
          margin-right: 0;
        }
      }
    }

    .modal-body {
      .top {
        display: flex;

        label {
          font-size: 14px;
          padding-left: 4px;
          padding-bottom: 4px;
          color: #808080;
        }
        input {
          width: 100%;
        }
      }
      .input-group {
        display: flex;
        flex-direction: column;
      }
      .input-group:first-child {
        margin-right: 30px;
      }
    }

    .sale-items {
      overflow-y: auto;
      max-height: 250px;
    }

    .separator {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #e6e6e6;
    }

    .item, .totals, .loyalty {
      padding: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .title, .subtotal, .fidelity-card {
        font-weight: bold;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-size: 20px;

        &.noBorder {
          border-bottom: 0;
          padding-bottom: 0;
        }

        .label, .value {
          color: #1a1a1a;
        }
      }

      .total {
        border-top: 1px solid #d9d9d9;
        padding-top: 5px;
        margin-top: 5px;
        font-weight: bold;
        color: #1a1a1a;
        font-size: 24px;
      }

      .title, .combo, .modifier, .quantity, .subtotal, .tax, .total, .fidelity-card, .points, .fee, .tip {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .combo, .modifier, .tax {
        margin-bottom: 2px;
      }

      .label {
        padding-right: 20px;
      }

      .value {
        font-weight: bold;
      }

      .fee, .tip {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
      }

      .combo, .modifier, .quantity, .tax, .points, .fee, .tip {
        font-size: 16px;

        .label, .value {
          color: #808080;
        }
      }

      .fidelity-card {
        font-size: 18px;
      }

      .quantity {
        font-weight: bold;
        border-top: 1px solid #d9d9d9;
        padding-top: 5px;
        margin-top: 5px;
      }

    }

  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;
    }
    #orderDetails {
      .modal-content {
        height: 100%;
        margin: 0;
        border-radius: 0;

        .modal-footer {
          margin-top: 20px;
        }
      }
      .sale-items {
        max-height: 200px;
      }
      .modal-body .input-group {
        label {
          min-height: 36px;
          display: flex;
          align-items: center;
        }
        input {
          font-size: 13px;
        }
      }
      .separator {
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
      .modal-body .input-group:first-child {
        margin-right: 10px;
      }
      .modal-header {
        padding-bottom: 15px;
        margin-bottom: 15px;
        .title {
          display: flex;
          flex-direction: column;

          .number::after {
            display: none;
          }
        }
      }
    }
    .price {
      width: auto;
    }
  }

</style>
