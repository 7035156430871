<template>
  <div class="component--tabs">
    <div class="tab" v-for="(tab, tabIndex) of tabs" :key="'tab-' + tabIndex" v-wave
         v-if="typeof tab.condition === 'function' ? tab.condition() : true"
         :class="{active: activeTabIndex === tabIndex, disabled: tab.disabled}" @click="tab.action(tabIndex)">
      {{ $t(tab.name) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "componentTabs",

    props: {
      tabs: Array,
      activeTabIndex: Number
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp.pos {
  .tab {
    background-color: var(--background-color-2);
    &:not(.active) {
      border-color: var(--background-color-2);
    }
  }
}
.tab {
  background-color: white;
  border: 5px solid white;
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 16px;
  min-height: 80px;
  cursor: pointer;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.active {
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
  }
  &:not(.active) {
    border-color: white;
  }
}
</style>