<template>
  <div class="favorite-company" :class="{hidden: shouldBeHidden}" @click="updateFavorite">
    <template v-if="isUserFavorite">
      <svgicon src="icons/fav-active.svg" :height="18" :width="18"></svgicon>
      {{$t('favorite_company_active')}}
    </template>
  </div>
</template>

<script>
  export default {
    name: "",

    props: {
      company: Object,
      onlyFavorite: Boolean,
      disableUpdate: Boolean
    },

    watch: {
      "$user.favoriteCompany": function() {
        console.log("changed");
        this.$forceUpdate();
      }
    },

    computed: {
      shouldBeHidden() {
        return !this.company || !this.$user.token || this.onlyOneCompany || !this.enableFavoriteCompany;
      },
      canonical() {
        return this.company.nameCanonical;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      enableFavoriteCompany() {
        return this.branch && this.branch.enableFavoriteCompany();
      },
      isUserFavorite() {
        return this.$user.favoriteCompany == this.canonical && this.$user.favoriteCompany;
      },
      onlyOneCompany() {
        return this.branch && this.branch.length === 1;
      }
    },

    methods: {
      async updateFavorite() {
        if (this.disableUpdate || !this.$user) {
          return;
        }
        showSpinner();
        await this.$user.updateInformation({
          favoriteCompany: this.isUserFavorite ? "" : this.canonical
        });
        hideSpinner();
        this.$emit("changed");
      }
    }
  }
</script>

<style lang="scss" scoped>
  .favorite-company {
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    .svgicon {
      fill: var(--primary-color);
      margin-right: 5px;
      margin-top: -2px;
      margin-left: -2px;
    }
  }
</style>
