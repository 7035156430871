<template>
  <div class="copyright-holder" :class="{white}">
    <svgicon class="logo" src="icons/maitreD.svg" :height="30" :width="120"></svgicon>
    <div class="copyright">&copy; {{year}} MaitreD. {{$t('all_rights_reserved')}}</div>
  </div>
</template>

<script>
  export default {
    name: "OnboardingCopyright",

    props: {
      white: Boolean
    },

    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>

<style lang="scss" scoped>
.copyright-holder {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 30px;

  &.white {
    .logo {
      fill: white;
      filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
    }
    .copyright {
      color: white;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    }
  }

  .logo {
    fill: #d9d9d9;
  }
  .copyright {
    color: #d9d9d9;
    font-weight: 500;
  }
}
</style>