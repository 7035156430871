<template>
  <div id="item" :class="{quickAdd: quickAdd}">

    <template v-if="!quickAdd">
      <MenuHeader show-back-button v-if="!$pos"></MenuHeader>

      <div class="filler" v-if="!item"></div>
      <transition :name="getTransition">
        <div class="page-content" v-if="item" :class="{kiosk: $kiosk, pos: $pos}">
          <component :is="template" :item="item" :realItem="item" @add="addOrderItem($event, ...arguments)" @goToCrossSell="goToCrossSell" :invalidChoiceId="invalidChoiceId"
                     @clearInvalidChoice="invalidChoiceId = null" :edit="isEdit"></component>
        </div>
      </transition>
    </template>

    <template v-if="quickAdd && item">
      <component :is="template" :item="item" :realItem="item" @add="addOrderItem($event, false)" :invalidChoiceId="invalidChoiceId" :quickAdd="quickAdd"
                 @clearInvalidChoice="invalidChoiceId = null" :edit="isEdit" @close="$emit('close')"></component>
    </template>

    <KioskCart v-if="$kiosk && !horizontal" :collapsed="true"></KioskCart>

    <PromotionCorner v-if="table && table.promotionVisitor.getPromotionsForItem(item).length > 0" :offset="30"></PromotionCorner>

  </div>
</template>

<script>

  import Categories from "@/components/menu/Categories";
  import EventBus from "@/lib/eventBus";
  import KioskCart from "@/components/kiosk/KioskCart.vue";
  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import PromotionCorner from "@/components/pos/PromotionCorner.vue";
  import TemplateBasic from "@/views/item/Basic.vue";
  import { mapActions } from "vuex";

  export default {

    name: "item",

    components: { MenuHeader, KioskCart, Categories, PromotionCorner },

    props: {
      itemId: Number
    },

    data() {
      return {
        item: null,
        isEdit: false,
        invalidChoiceId: null,
        fromCategoryId: null
      };
    },

    mounted() {
      this.$store.commit("showNavigationCart", true);
      this.initializeItem();
      if (this.$route.params.id && this.$route.params.id.indexOf("-") > -1) {
        this.fromCategoryId = this.$route.params.id.split("-")[0];
      }
    },

    beforeDestroy() {
      if (!this.isEdit && this.item) {
        this.item.reset(this.interactiveMode);
      }
      this.$store.state.currentNewItem = null;
    },

    computed: {
      template() {
        return TemplateBasic;
      },
      editIndex() {
        return this.$route.params.index;
      },
      categoryIdFromQuery() {
        let id = this.$route.params.id;
        if (id && id.indexOf("-") > 0) {
          let parts = id.split("-");
          return parts[0];
        }
        return null;
      },
      interactiveMode() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.interactiveMode;
      },
      table() {
        if (!this.$pos) {
          return null;
        }
        return this.$store.state.table;
      },
      horizontal() {
        return this.$store.state.kioskConfiguration && this.$store.state.kioskConfiguration.isHorizontal();
      },
      quickAdd() {
        return !!this.itemId;
      },
      posConfiguration() {
        if (!this.$pos) {
          return;
        }
        return this.$store.state.posConfiguration;
      },
      getTransition() {
        if (this.posConfiguration && !this.posConfiguration.animated) {
          return "";
        }
        return "fade";
      }
    },

    watch: {
      $route: function() {
        this.item = null;
        this.$nextTick(() => {
          this.initializeItem();
        });
      },
      itemId: function() {
        if (!this.itemId) {
          this.item = null;
        } else {
          this.initializeItem();
        }
      },
      item: function() {
        if (this.item) {
          EventBus.$emit("document.title", this.item.name);
        }
      }
    },

    methods: {
      ...mapActions({
        searchItemAction: "searchItem"
      }),

      initializeItem() {
        this.item = null;
        if (typeof this.editIndex !== "undefined") {
          this.loadEditItem();
        } else {
          this.loadNewItem();
        }
      },

      async loadEditItem() {
        if (this.$pos) {
          let detail = this.table.getDetail(parseInt(this.editIndex));
          this.item = detail.inventoryItem;
        } else {
          let orderItem = this.$order.getItem(this.editIndex);
          this.item = orderItem ? orderItem.clone() : null;
        }
        if (!this.item) {
          this.$router.replace(this.$router.generate("/menu"));
        } else {
          this.isEdit = true;
        }
      },

      async loadNewItem() {
        let interval = setInterval(async () => {
          if (!this.$store.state.inventory || !this.$store.state.currentCompany || this.$store.state.inventory.getRoot().length == 0) {
            return;
          }
          clearInterval(interval);
          let item = await this.searchItemAction(this.$route.params.id || this.itemId);
          if (!item) {
            this.$router.push(this.$router.generate("/menu"));
            return;
          }
          this.item = item.clone();
          this.item.reset(this.interactiveMode);
          this.$store.state.currentNewItem = this.item;

          if (this.$route.query.quantity && this.$pos) {
            this.item.quantity = parseInt(this.$route.query.quantity);
          }
          this.isEdit = false;
        }, 100);
      },

      addOrderItem(event, crossSell) {
        if (!this.validateOrderModifiers() || !this.validateSelectedVariant() || !this.validateChoicesAndChoiceGroup()) {
          return;
        }

        // TODO - Modifiers
        let upsellModifiers = this.item.getCompiledModifiers().filter(m => m.upsell);
        if (upsellModifiers.filter(m => m.quantity > 0).length == 0) {
          // return;
        }
        //TODO remove completely order.company, use store.state.currentCompany instead
        if (this.$order.company && this.$store.state.currentCompany != this.$order.company && this.$order.items.length > 0) {
          showConfirmation(this.$t("order.change_company"), this.$t("order.change_company_question"))
            .then((accept) => {
              if (accept) {
                this.$order.items = [];
                if (typeof this.editIndex === "undefined") {
                  this.$order.addItem(this.item);
                }
                this.$order.company = this.$store.state.currentCompany; //TODO remove completely order.company, use store.state.currentCompany instead
                this.$router.push(this.$router.generate("/" + (this.$route.query.redirect || "menu")));
              }
            });
        } else {
          if (typeof this.editIndex === "undefined") { /* New */
            if (this.fromCategoryId) {
              this.item.fromCategoryId = this.fromCategoryId;
            }
            if (this.table) {
              this.table.addItemToTable(this.item, this.$store.state.currentSeat);
              this.$nextTick(() => {
                scrollToEndOfCart();
              });
            } else {
              this.$order.addItem(this.item);
            }
          } else { /* Edit */
            if (this.table) {
              let parentDetail = this.table.getDetail(parseInt(this.editIndex));
              this.table.replaceDetailsForItem(parentDetail, this.item);
            } else {
              this.$order.replaceItemAtIndex(this.$route.params.index, this.item); //TODO implement replacement/edit for table
            }
          }
          this.$order.company = this.$store.state.currentCompany; //TODO remove completely order.company, use store.state.currentCompany instead

          if (this.$pos && crossSell) {
            this.goToCrossSell();
            return;
          }

          // load the next product (used for hidden product)
          if (this.$route.query.nextItem) { // TODO
            this.loadNextItem();
          } else {
            if (this.quickAdd) {
              this.$emit("close");
              if (this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg") {
                scrollToEndOfCart();
              }
            } else {
              if (this.$pos) {
                let categoryId = this.categoryIdFromQuery || (this.item.categories.length > 0 ? this.item.categories[0].id : null);
                this.$router.push(this.$router.generate(`/menu${categoryId ? `/${categoryId}` : ""}`));
                return;
              }
              let params = {};
              if ((this.$store.state.quickMode) && (this.item.categories.length > 0)) {
                params = { category: this.item.categories[0].id };
              }
              this.$router.push({
                path: this.$router.generate("/" + (this.$route.query.redirect || "menu")),
                query: params
              });
            }
          }
        }
      },
      loadNextItem() {
        this.$route.params.id = this.$route.query.nextItem;
        this.$route.query.nextItem = null;

        this.item.reset(this.interactiveMode);
        this.loadNewItem();
        document.getElementsByClassName("page-content")[0].scrollTop = 0;
      },
      validateOrderModifiers() {
        let invalidModifierGroups = this.item.getInvalidModifierGroups();
        if (invalidModifierGroups.length > 0) {
          let modifierGroup = invalidModifierGroups[0];
          let validation = modifierGroup.validateQuantitySelected();
          let errorMessage = this.$t(validation.error)
            .replace("{NUMBER}", validation.number)
            .replace("{MIN}", validation.min)
            .replace("{MAX}", validation.max);
          let element = document.querySelectorAll(".invalid#modifierGroupRequirements-" + modifierGroup.id)[0];
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          showAffirmation(this.$to(modifierGroup.description) || this.$t("warning"), errorMessage);
        }
        return invalidModifierGroups.length == 0;
      },
      validateSelectedVariant() {
        if (this.item.variants.length > 0 && !this.item.selectedVariant) {
          showAffirmation(this.$t("item.no_variant_selected"), this.$t("item.no_variant_selected_text"))
          return false;
        }
        return true;
      },
      validateChoicesAndChoiceGroup() {
        for (let choice of this.item.getChoices()) {
          if (!choice.selected) {
            this.invalidChoiceId = choice.id;
            scrollToError();
            return false;
          }
          for (let subchoice of choice.selected.getChoices()) {
            if (!subchoice.selected) {
              this.invalidChoiceId = choice.id;
              scrollToError();
              return false;
            }
          }
        }
        for (let choiceGroup of this.item.getChoiceGroups()) {
          for (let choice of choiceGroup.getChoices()) {
            if (!choice.selected) {
              this.invalidChoiceId = choice.id;
              scrollToError();
              return false;
            }
          }
        }
        return true;
      },

      goToCrossSell() {
        this.$router.push(this.$router.generate("/cross-sell/" + this.item.id));
      }
    }
  };

</script>

<style lang="scss" scoped>
  #iShopFoodApp.kiosk #item, #iShopFoodApp.pos #item {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .filler {
    flex-grow: 1;
  }
  #iShopFoodApp .page-content {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;

    &.kiosk {
      padding: 0;
      flex-grow: 1;
    }

    &.pos {
      background-color: var(--background-color-2);
      margin: 30px;
      border-radius: 20px;
      padding: 30px;
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    }

    &:after {
      height: 0;
      display: none;
      content: none;
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #item > .promotion-corner {
    left: 30px;
    top: 30px;
  }
</style>
