/**
 * Mon objectif avec cette classe serait de pouvoir caller StyleGenerator().initialize()
 * et que ca set le bon style selon la config, debloat app.vue, WIP
 */
import Color from "color";

class StyleGenerator {

  constructor(elementId = "iShopFoodApp", customCssElementId = "customCss") {
    this.rootElementId = elementId;
    this.customCssElementId = customCssElementId;

    if (!this.getCustomCssElement()) {
      let element = document.createElement("style");
      element.id = customCssElementId;
      document.head.appendChild(element);
    }
  }

  initialize() {

  }

  /**
   * @returns {HTMLElement}
   */
  getRootElement() {
    return document.getElementById(this.rootElementId);
  }

  /**
   * @returns {HTMLElement}
   */
  getCustomCssElement() {
    return document.getElementById(this.customCssElementId);
  }

  /**
   * Set style property value to app root object
   * @param {string} propertyName
   * @param {string} propertyValue
   */
  setProperty(propertyName, propertyValue) {
    this.getRootElement().style.setProperty(propertyName, propertyValue);
  }

  /**
   * Remove style property value to app root object
   * @param {string} propertyName
   */
  removeProperty(propertyName) {
    this.getRootElement().style.removeProperty(propertyName);
  }

  /**
   * @param {string} propertyName
   * @returns {string}
   */
  getProperty(propertyName) {
    return this.getRootElement().style.getPropertyValue(propertyName);
  }

  /**
   * Expected to be a style html element
   * @param {string} value
   */
  setCustomCss(value) {
    this.getCustomCssElement().innerHTML = value;
  }

  /**
   * Compute a valid css value
   * @param {string} attributeName
   * @param {string} attributeValue
   * @returns {string}
   */
  formatCssValue(attributeName, attributeValue) {
    let str = attributeValue;
    if (attributeName === "border-radius" || attributeName === "font-size") {
      str += "px";
    }
    return str;
  }

  setDarkThemeProperties() {
    this.setProperty("--background-color", "#1a1a1a");
    this.setProperty("--background-color-2", "#333333");
    this.setProperty("--background-color-3", "#4d4d4d");
    this.setProperty("--background-color-4", "#666666");
    this.setProperty("--background-color-5", "#404040");
    this.setProperty("--background-color-6", "#262626");
    this.setProperty("--black-to-white", "#1a1a1a");
    this.setProperty("--illustration-background-color", "#333333");
    this.setProperty("--illustration-background-color-2", "#4d4d4d");
    this.setProperty("--illustration-front-color", "#595959");
    this.setProperty("--illustration-front-color-2", "#737373");
    this.setProperty("--theme-color", "#ffffff");
    this.setProperty("--theme-color-2", "#bfbfbf");
    this.setProperty("--theme-color-3", "#ffffff");
    this.setProperty("--theme-color-disabled", "rgba(255,255,255,0.5)");
    this.setProperty("--header-background-color", "#262626");
    this.setProperty("--header-button-background-color", "#404040");
    this.setProperty("--header-icon-color", "#666666");
    this.setProperty("--border-color", "#333333");
    this.setProperty("--border-color-2", "#404040");
    this.setProperty("--border-color-3", "#333333");
    this.setProperty("--border-color-4", "#4d4d4d");
    this.setProperty("--border-color-5", "#4d4d4d");
    this.setProperty("--border-color-6", "#404040");
    this.setProperty("--warning-color", "#cb731a");
  }

  setLightThemeProperties() {
    this.setProperty("--background-color", "#f5f5f5");
    this.setProperty("--background-color-2", "#ffffff");
    this.setProperty("--background-color-3", "#f5f5f5");
    this.setProperty("--background-color-4", "#e6e6e6");
    this.setProperty("--background-color-5", "#ffffff");
    this.setProperty("--background-color-6", "#f5f5f5");
    this.setProperty("--black-to-white", "#ffffff");
    this.setProperty("--theme-color", "#1a1a1a");
    this.setProperty("--theme-color-disabled", "rgba(26,26,26,0.5)");
    this.setProperty("--theme-color-2", "#808080");
    this.setProperty("--theme-color-3", "#404040");
    this.setProperty("--header-background-color", "#ffffff");
    this.setProperty("--header-button-background-color", "#f5f5f5");
    this.setProperty("--header-icon-color", "#d9d9d9");
    this.setProperty("--illustration-background-color", "#ebebeb");
    this.setProperty("--illustration-background-color-2", "#f5f5f5");
    this.setProperty("--illustration-front-color", "#dbdbdb");
    this.setProperty("--illustration-front-color-2", "#e6e6e6");
    this.setProperty("--border-color", "#dbdbdb");
    this.setProperty("--border-color-2", "#e6e6e6");
    this.setProperty("--border-color-3", "#e6e6e6");
    this.setProperty("--border-color-4", "#e6e6e6");
    this.setProperty("--border-color-5", "#dbdbdb");
    this.setProperty("--border-color-6", "#f5f5f5");
    this.setProperty("--warning-color", "#ffba73");
  }

  /**
   * Set mobile status bar color to match specified color
   * @param {String} color
   */
  setStatusBarColor(color) {
    if (typeof cordova !== "undefined") {
      let statusBarInterval = setInterval(() => {
        if (typeof StatusBar !== "undefined") {
          if (Color(color).isLight()) {
            StatusBar.styleDefault();
          } else {
            StatusBar.styleLightContent();
          }
          StatusBar.backgroundColorByHexString(color);
          clearInterval(statusBarInterval);
        }
      });
    }
  }

}

let styleGenerator = new StyleGenerator();
export { styleGenerator as StyleGenerator };