<template>
  <div class="qr-code--canvas-holder">
    <div class="qr-code--canvas" :id="'qr-code--canvas-' + uuid"></div>
    <div class="qr-code--name" v-if="name">{{name}}</div>
    <button class="qr-code--download btn btn-primary" @click="downloadQRCode">{{$t('download_qr_code')}}</button>
  </div>
</template>

<script>
  import QRCodeStyling from "qr-code-styling";

  export default {
    name: "qrCode",

    props: {
      data: String,
      name: String
    },

    data() {
      return {
        uuid: null,
        qrCode: null
      }
    },

    computed: {
      QRCodeOptions() {
        let dimension = 280;
        return {
          width: dimension,
          height: dimension,
          data: this.data,
          dotsOptions: {
            color: "#000000",
            type: "rounded"
          },
          cornersSquareOptions: {
            color: "#000000"
          },
          backgroundOptions: {
            color: "#ffffff"
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 5
          }
        };
      },
    },

    mounted() {
      this.uuid = window.generateUUID();
      this.$nextTick(() => {
        this.generateQrCode();
      });
    },

    methods: {
      generateQrCode() {
        if (!this.data) {
          console.log("undefined");
          return;
        }
        let options = this.QRCodeOptions;
        if (!this.qrCode) {
          this.qrCode = new QRCodeStyling(options);
          this.qrCode.append(document.getElementById("qr-code--canvas-" + this.uuid));
        } else {
          this.qrCode.update(options);
        }
      },
      downloadQRCode() {
        if (!this.qrCode) {
          return;
        }
        this.qrCode.download();
      },
    }
  }
</script>

<style lang="scss" scoped>
.qr-code--canvas-holder {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .qr-code--name {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }

  .qr-code--download {
    margin-top: 10px;
  }
}
</style>
