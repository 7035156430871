<template>
  <div id="posNavigation" class="pos-navigation" :class="{visible: visible}">
    <div class="pos-navigation__container">
      <div class="pos-navigation__link-container pos-navigation__link-container--top">
        <button class="btn pos-navigation__item pos-navigation__toggle-visibility" @click="closeNavigation()">
          <svgicon src="icons/pos-nav.svg" :height="24" :width="24"></svgicon>
          {{$t('pos.navigation.collapse')}}
        </button>
      </div>

      <div class="pos-navigation__link-container pos-navigation__link-container--info">
        <div class="btn pos-navigation__item pos-navigation__item--info" v-if="employee">
          <svgicon src="icons/user.svg" :height="25" :width="19"></svgicon>
          <div class="item-infos">
            <label for="employeNb">{{$t('pos.navigation.employee_number')}}</label>
            <div id="employeNb">{{employeeNumber}}</div>
          </div>
        </div>
        <div class="btn pos-navigation__item pos-navigation__item--info">
          <svgicon src="icons/branch-pos.svg" :height="24" :width="24"></svgicon>
          <div class="item-infos">
            <label for="revenueCenter">{{$t('pos.navigation.revenue_center')}}</label>
            <div id="revenueCenter">{{revenueCenter}}</div>
          </div>
        </div>
      </div>

      <div class="pos-navigation__link-container pos-navigation__link-container--center">
        <div class="btn pos-navigation__item" @click="goToDashboard">
          <svgicon src="icons/home-pos.svg" :height="25" :width="25"></svgicon>
          {{$t('pos.navigation.dashboard')}}
        </div>
        <!--<div class="btn pos-navigation__item" @click="newOrder('inPlace')" :class="{disabled: !employee || isOffline || isDispatch}">
          <svgicon src="icons/new-order.svg" :height="25" :width="25"></svgicon>
          {{$t('pos.navigation.counter_order')}}
        </div>-->
        <div class="btn pos-navigation__item" @click="newOrder('takeout')" :class="{disabled: !employee || isOffline || isDispatch || isTableMode}">
          <svgicon src="icons/takeout-order.svg" :height="25" :width="25"></svgicon>
          {{$t('pos.navigation.takeout_order')}}
        </div>
        <div class="btn pos-navigation__item" @click="newOrder('delivery')" :class="{disabled: !employee || isOffline || isTableMode}">
          <svgicon src="icons/phone-order.svg" :height="25" :width="25"></svgicon>
          {{$t('pos.navigation.phone_order')}}
        </div>
        <div class="btn pos-navigation__item" @click="goToOrders" :class="{disabled: !employee || isOffline || isDispatch}">
          <svgicon src="icons/orders.svg" :height="24" :width="24"></svgicon>
          {{$t('pos.navigation.orders')}}
        </div>
      </div>

      <div class="pos-navigation__link-container pos-navigation__link-container--bottom">
        <div class="btn pos-navigation__item" @click="goToSettings" :class="{disabled: !permissionManager.hasPermission(constant.P_SETTING_SEE_ALL)}">
          <svgicon src="icons/settings-full.svg" :height="24" :width="24"></svgicon>
          {{$t('pos.navigation.settings')}}
        </div>
        <div class="btn pos-navigation__item" @click="goToSupport" :class="{disabled: isOffline}">
          <svgicon src="icons/support-chat.svg" :height="24" :width="24"></svgicon>
          {{$t('pos.navigation.support')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Table from "@/models/pos/TableModel";
  import { mapActions } from "vuex";
  import EventBus from "../../lib/eventBus";
  import { PermissionManager } from "@/util/PermissionManager";
  import { Constant } from "@/util/Constant";

  export default {
    name: "posNavigation",

    data() {
      return {
        visible: false,
        constant: Constant
      };
    },

    mounted() {
      EventBus.$on("show-pos-navigation", () => {
        this.addOutsidePosNavClickListener();
        this.visible = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-pos-navigation");
      this.removeOutsidePosNavClickListener();
    },

    computed: {
      employee() {
        return this.$store.state.employee;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      table() {
        return this.$store.state.table;
      },
      isTableMode() {
        return this.table && this.table.method === "inPlace";
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      employeeNumber() {
        return this.employee ? this.employee.getPin() : "";
      },
      revenueCenter() {
        return "Main Dining";
      },
      isOffline() {
        return this.$store.state.offline;
      },
      isDispatch() {
        return this.table && this.table.isDispatch;
      },
      permissionManager() {
        return PermissionManager;
      }
    },

    methods: {
      ...mapActions(["initializeNewTable", "sendTable"]),
      
      toggleVisibility() {
        EventBus.$emit("hide-pos-navigation");
        this.visible = !this.visible;
      },
      async validateOrderInProgress() {
        let currentRouteName = this.$route.name;
        let menuRoutes = this.$store.getters.menuRouteNames;
        if (this.table && this.table.hasUnsavedDetails() && menuRoutes.indexOf(currentRouteName) > -1) {
          let response = await showConfirmation(this.$t("pos.order_in_progress"), this.$t("pos.order_in_progress_warning"));
          if (!response) {
            return false;
          }
        }
        return true;
      },
      redirectToPath(path) {
        this.$router.push(this.$router.generate(path));
        this.closeNavigation();
      },
      async goToOrders() {
        if (!(await this.validateOrderInProgress())) {
          return;
        }
        this.redirectToPath("/pos/order-overview");
      },
      async goToDashboard() {
        if (!(await this.validateOrderInProgress())) {
          return;
        }
        if (this.isDispatch) {
          this.table.isDispatch = false;
          this.$router.push(this.$router.generateFirstPage());
          this.closeNavigation();
        } else {
          this.redirectToPath("/pos/dashboard");
        }
      },
      async goToSettings() {
        if (!(await this.validateOrderInProgress())) {
          return;
        }
        this.redirectToPath("/pos/configuration");
      },
      async newOrder(method) {
        if (this.table && this.table.hasUnsavedDetails()) {
          let confirmation = await showConfirmation(this.$t("warning"), this.$t("pos.navigation.new_order_confirm"));
          if (!confirmation) {
            return;
          }
          showSpinner();
          let saveResponse = await this.sendTable({ table: this.table });
          hideSpinner();
          if (!saveResponse.success) {
            hideSpinner();
            toast({ message: this.$t("pos.table.save_error"), type: "error", title: this.$t("error.title") });
            return;
          }
        }
        let response = await this.initializeNewTable({ method: method });
        hideSpinner();
        if (response.success) {
          if (method === "delivery") {
            this.table.isDispatch = true;
            this.$user.setAsDispatchForPOS();
            this.redirectToPath("/pos/dispatch");
          } else {
            this.table.isDispatch = false;
            this.$user.clearDispatchForPOS();
            this.redirectToPath("/menu");
          }
        } else {
          this.showError(response.error);
        }
      },
      goToSupport() {
        this.closeNavigation();
        window.openExternalWebsite(this.$to(CONFIG.urlSupport));
      },
      closeNavigation() {
        EventBus.$emit("hide-pos-navigation");
        this.removeOutsidePosNavClickListener();
        this.visible = false;
      },
      addOutsidePosNavClickListener() {
        window.addEventListener("click", this.outsidePosNavClickListener, true);
      },
      removeOutsidePosNavClickListener() {
        window.removeEventListener("click", this.outsidePosNavClickListener, true);
      },
      outsidePosNavClickListener(event) {
        let navContainer = document.getElementById("posNavigation");
        if (!navContainer.contains(event.target)) {
          this.closeNavigation();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  $delay: 220ms;

  #posNavigation {
    display: flex;
    width: 0;
    height: 100%;
    background-color: #262626;
    overflow: hidden;
    transition: $delay ease-in width;
    z-index: 3;
    &.visible {
      width: 260px;
    }

    .pos-navigation__container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 260px;
      min-width: 260px;
      padding: 20px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .pos-navigation__item {
      flex-grow: 1;
      color: #ffffff;
      min-height: 54px;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 20px;
      background-color: #333333;
      justify-content: flex-start;
      max-width: 230px;
      line-height: 1.1;

      & > .svgicon {
        fill: var(--primary-color);
        min-width: 25px;
        margin-right: 20px
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .pos-navigation__item--info {
      border: 1px solid #333333;
      background-color: transparent;
      .item-infos {
        font-weight: normal;
        text-align: left;
      }
      label {
        color: #808080;
        font-size: 16px;
      }
    }

    .pos-navigation__item.pos-navigation__toggle-visibility {
      flex-grow: 1;
      background-color: var(--primary-color);
      & > .svgicon {
        fill: #262626;
      }
    }

    .pos-navigation__link-container {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    .pos-navigation__link-container--top {
      width: 100%;
    }

    .pos-navigation__link-container--info {
      align-self: flex-start;
      padding-top: 20px;
      border-top: 1px solid #333333;
      border-bottom: 1px solid #333333;
    }
    .pos-navigation__link-container--center {
      flex-grow: 1;
      align-content: flex-start;
      padding-top: 20px;
    }

    .pos-navigation__link-container--bottom {
      border-top: 1px solid #333333;
      padding-top: 20px;
    }
  }

</style>