import PrintFormatter from "@/lib/printer/printFormatter";
import moment from "moment";

class PastReceipts {

  constructor() {
    /** @private */
    this._receipts = [];
  }

  /**
   * Returns the list of receipt file names in the receipts folder
   * @returns {string[]} List of receipt file names
   */
  get receipts() {
    return this._receipts;
  }

  /**
   * Saves a receipt to the receipts folder
   * @param {string} receipt The printed receipt
   * @param {string[]} receiptRaw The raw receipt array
   * @param {object} sale The sale object
   * @returns {boolean} True if the receipt was saved successfully
   */
  saveReceipt(receipt, receiptRaw, sale) {
    if (!window.isExecutable()) {
      return false;
    }
    try {
      let fs = nw.require("fs");
      if (!fs.existsSync("./logs/receipts")) {
        fs.mkdirSync("./logs/receipts");
      }
      let path = "./logs/receipts/" + moment().format("YYYY-MM-DD_HH-mm-ss") + ".json";
      let toWrite = {
        receipt,
        receiptRaw,
        sale,
        date: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      let content = JSON.stringify(toWrite);
      fs.writeFileSync(path, content);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Loads the receipt file names from the receipts folder
   * @returns {boolean} True if the receipts were loaded successfully
   */
  loadReceipts() {
    if (!window.isExecutable()) {
      return false;
    }
    try {
      let fs = nw.require("fs");
      if (!fs.existsSync("./logs/receipts")) {
        fs.mkdirSync("./logs/receipts");
        return true;
      }
      let files = fs.readdirSync("./logs/receipts");
      let toReturn = [];
      for (let file of files) {
        let date = moment(file.substring(0, 19), "YYYY-MM-DD_HH-mm-ss");
        // Delete receipts older than a week
        if (date.isBefore(moment().subtract(1, "week"))) {
          fs.unlinkSync(`./logs/receipts/${file}`);
        } else {
          toReturn.unshift(file);
        }
      }
      this._receipts = toReturn;
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Loads a single receipt fully from the receipts folder
   * @param {string} fileName Path to the receipt file
   */
  loadReceipt(fileName) {
    if (!window.isExecutable()) {
      return;
    }
    try {
      let fs = nw.require("fs");
      let content = fs.readFileSync(`./logs/receipts/${fileName}`, "utf8");
      let obj = JSON.parse(content);
      return obj;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Prints a reproduction of a receipt
   * @param {object} receipt The receipt to print
   */
  printReceipt(receipt) {
    let str = receipt.receipt;

    // Find where the second dotted line is
    let split = str.split("\n");
    let lastLineIndex = -1;
    for (let i = 0; i < split.length; i++) {
      if (split[i].indexOf("----") > -1) {
        lastLineIndex = i;
      }
    }

    // Insert the word "REPRODUCTION" in the bottom of the receipt
    let reproductionLineIndex = lastLineIndex - 2;
    let lineLength = getConfiguration("printer-cols") || 42;
    let leftPad = Math.floor((lineLength - "REPRODUCTION".length) / 2);
    split[reproductionLineIndex] = " ".repeat(leftPad) + "REPRODUCTION";

    // Insert a blank line before the reproduction line if there are footer lines
    let before = split[reproductionLineIndex - 1];
    if (before) {
      let lineBeforeContent = before.trim();
      if (lineBeforeContent.length > 0) {
        split.splice(reproductionLineIndex, 0, "");
      }
    }

    // Print the receipt
    str = split.join("\n");
    let printer = new PrintFormatter();
    printer._str = str;
    printer.print();
  }

}

let pastReceipts = new PastReceipts();
export { pastReceipts as PastReceipts };