<template>
  <div id="promoKeyboardModal" class="modal" :class="{show: show}">
    <div class="content modal-content">

      <div class="promo-modal--banner" :style="{backgroundImage: 'url(\'' + promoImage +  '\''}"></div>

      <div class="promo-modal--content">
        <div class="promo-modal--title">{{$t('kiosk.activate_promo')}}</div>
        <div class="promo-modal--description">{{$t('kiosk.activate_promo_description')}}</div>

        <div class="input-holder">
          <div class="fake-input">{{ code }}</div>
        </div>

        <div class="keyboard-holder">
          <div class="keyboard--row" v-for="(row, rowIndex) of rows" :key="'row-' + rowIndex">
            <div class="keyboard--key key__delete" v-if="rowIndex === rows.length - 1" @click="resetCode">{{ $t('erase') }}</div>
            <div class="keyboard--key key__regular" v-for="(key, keyIndex) of row" :key="'key' + key" @click="addKeyToCode(key)">{{ key }}</div>
            <div class="keyboard--key key__back" v-if="rowIndex === rows.length - 1" @click="eraseOneKeyFromCode">
              <svgicon src="icons/erase.svg" :height="25" :width="25"></svgicon>
            </div>
          </div>
        </div>

        <div class="promo-modal--buttons">
          <div class="btn btn-secondary flat" @click="dismiss">{{ $t("back") }}</div>
          <div class="btn btn-primary flat" @click="checkPromoCode">{{ $t('kiosk.activate') }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { PromotionManager } from "@/PromotionManager";
  import Promotion from "../../models/PromotionModel";
  import {mapActions} from "vuex";

  export default {
    name: "PromoKeyboardModal",

    data() {
      return {
        show: false,
        code: "",
        rows: [
            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
            ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
            ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
            ["Z", "X", "C", "V", "B", "N", "M"]
        ]
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      promoImage() {
        if (this.$order.branding && this.$order.branding.promoImage) {
          return CONFIG.urlFileServer + this.$order.branding.promoImage.url;
        }
        if (this.company && this.company.images && this.company.images.kioskPromoImage) {
          return CONFIG.urlFileServer + this.company.images.kioskPromoImage.url;
        }
        return "";
      },
    },

    mounted() {
      EventBus.$on("show-kiosk-promo-keyboard-modal", () => {
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-kiosk-promo-keyboard-modal");
    },

    methods: {
      ...mapActions({
        checkPromoCodeAction: "checkPromoCode",
      }),
      resetCode() {
        this.code = "";
      },
      dismiss() {
        this.show = false;
        this.code = "";
      },
      eraseOneKeyFromCode() {
        if (this.code.length === 0) {
          return;
        }
        this.code = this.code.substring(0, this.code.length - 1);
      },
      addKeyToCode(key) {
        this.code += key;
      },
      async checkPromoCode() {
        //TODO refactor this, duplicate of same function in promotions.vue, promoKeyboardModal.vue
        this.promoCodeStatus = null;
        showSpinner();
        let result = await this.checkPromoCodeAction({
          branch: this.branch.id,
          company: this.company.id,
          code: this.code
        });
        if (!result.success) {
          hideSpinner();
          showAffirmation(this.$t("promotion.invalid_title"), this.$t("promotion.not_found"));
          return;
        }
        if (!PromotionManager.isPromotionWithinSchedule(result.promotion)) {
          showAffirmation(this.$t("promotion.invalid_title"), this.$t("promotion.outside_of_schedule"));
          hideSpinner();
          return;
        }
        if (this.$order.getItemsTotal({ excludedTags: result.promotion.exclusion.tags }) < result.promotion.minAmount) {
          this.promoCodeStatus = "invalid";
          this.promoCodeMessage = "promotion.minimum";
          this.promoMinAmount = result.promotion.minAmount;
          showAffirmation(this.$t("promotion.invalid_title"), this.$t("promotion.minimum").replace("{AMOUNT}", result.promotion.minAmount));
          hideSpinner();
          return;
        }
        if (!this.$order.method || result.promotion.disableForMethod.indexOf(this.$order.method) > -1) {
          showAffirmation(this.$t("promotion.invalid_title"), this.$t("promotion.invalid_method"));
          hideSpinner();
          return;
        }
        let promo = new Promotion(result.promotion);
        // Check if there is already a promo code
        if (promo.code) {
          for (let oldPromo of PromotionManager.promotions) {
            if (oldPromo.code) {
              hideSpinner();
              let result = await showConfirmation(this.$t("warning"), this.$t("promotion.multiple_codes"));
              if (result) {
                PromotionManager.removePromotion(oldPromo);
                showSpinner();
              } else {
                return;
              }
            }
          }
        }
        let added = PromotionManager.addPromotion(promo);
        if (!added) {
          showAffirmation(this.$t("promotion.invalid_title"), this.$t("promotion.already_added"));
          hideSpinner();
          return;
        }
        // Success
        let text = this.$t("promotion.found_description") + ": <b>" + this.code.toUpperCase() + "</b>.";
        if (promo.shouldAddSimpleItemToCart()) {
          text += "<br><br>" + this.$t("promotion.added_to_cart");
        }
        hideSpinner();
        this.dismiss();
        await showAffirmation(this.$t("promotion.found"), text, this.$t("continue"));

        if (promo.items && promo.items.length > 0) {
          let items = this.filterItems(promo.items);
          if (items && items.length === 1) {
            this.selectItem(items[0], promo);
          }
        }
      },
      selectItem(item, promo) {
        //TODO should refactor, duplicate of promotions.vue
        let id = item.parent ? item.parent.id : item.id;
        if (!item.isCustomizable()) {
          this.$order.addItem(item);
          if (promo.items.length === 1) {
            this.$router.push(this.$router.generate("/menu"));
          }
          return;
        }
        let route;
        if (item.items) {
          route = this.$router.generate("/menu/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + id);
        } else {
          route = this.$router.generate("/item/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + id);
        }

        if (promo && promo.type == "hidden_product") {
          route += "?nextItem=" + promo.items[0].id;
        } else if (promo && promo.triggerType == "with_item") {
          route += "?redirect=promotions";
        }
        this.$router.push(route);
      },
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .horizontal #promoKeyboardModal.modal {
    .promo-modal--banner {
      display: none;
    }
  }
  #iShopFoodApp #promoKeyboardModal.modal {
    z-index: 1;
    .modal-content {
      max-width: 860px;
      border-radius: 25px 25px 20px 20px;
      padding: 0;
    }
  }
  .promo-modal--content {
    padding: 40px;
  }
  .promo-modal--banner {
    height: 275px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .promo-modal--title {
    text-align: center;
    color: var(--primary-color);
    font-weight: 900;
    font-family: "Segoe UI Black", sans-serif;
    font-size: 36px;
    text-transform: uppercase;
    padding-bottom: 40px;
  }
  .promo-modal--description {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px dashed #e6e6e6;
    padding-bottom: 40px;
  }
  .promo-modal--buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    border-top: 1px dashed #e6e6e6;
    margin-top: 40px;
  }
  .input-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    .fake-input {
      min-width: 400px;
      max-width: 800px;
      overflow: auto;
      height: 70px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      letter-spacing: 2px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .keyboard-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .keyboard--row {
    display: flex;
  }
  .keyboard--key {
    background-color: #f5f5f5;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-transform: uppercase;
    width: 70px;
    height: 70px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;

    &.key__back, &.key__delete {
      width: 110px;
      font-size: 16px;
      background-color: #e8e8e8;
    }
  }
</style>
