//@ts-check

import { Constant } from "@/util/Constant";

/**
 * @typedef KioskConfigurationDefinition
 * @property {String} name
 * @property {String} number
 * @property {String} adminNumber
 * @property {String} managementNumber
 * @property {String} display
 * @property {String} branchCanonical
 * @property {String} companyCanonical
 * @property {{cash: boolean}} offlinePayment
 * @property {boolean|String} terminalPayment
 * @property {boolean} tip
 * @property {{inPlace: boolean, takeout: boolean, grabngo: boolean, scan: boolean}} methods
 * @property {{default: String, fr: boolean, en: boolean}} locales
 * @property {{values: String[]}} tipConfiguration
 * @property {boolean} itcPayment
 * @property {String} itcDeviceId
 */

export default class KioskConfiguration {

  constructor() {
    this.name = "";
    this.number = "";
    this.adminPassword = "";
    this.managementPassword = "";
    this.display = "vertical";
    this.airxtouchSize = Constant.AIRXTOUCH_SIZE_55;
    this.branchCanonical = "";
    this.companyCanonical = "";
    this.apiKey = "";
    this.offlinePayment = {
      cash: false
    };
    this.itcPayment = false;
    this.itcDeviceId = "";
    this.terminalPayment = false;
    this.tip = false;
    this.noQuantityProduct = false;
    this.noQuantityModifier = false;
    this.terminalData = null;
    this.start = {
      subtitle: { fr: "", en: "" },
      title: { fr: "", en: "" }
    };
    this.tipConfiguration = {
      values: [12, 15, 18, 20]
    };
    this.methods = {
      inPlace: false,
      takeout: false,
      grabngo: false,
      scan: false,
      ticket: false
    };
    this.callingSystem = {
      activated: false,
      required: false,
      methods: {
        inPlace: true,
        takeout: true
      },
      title: { fr: "", en: "" },
      subtitle: { fr: "", en: "" }
    };
    this.printer = {
      activated: false,
      name: "",
      cols: 42
    };
    this.receipt = {
      headerLine1: "",
      headerLine2: "",
      headerLine3: "",
      footerLine1: "",
      footerLine2: ""
    };
    this.scannerDelay = 300;
    this.minimumTransactionNumber = 1;
    this.maximumTransactionNumber = 999;
    this.locales = {
      default: "en",
      en: true,
      fr: false
    };

    this.KIOSK_CONFIGURATION_KEY = "kioskConfiguration";
    this.GRABNGO_KEY = "grabngo";
    this.API_KEY = "kiosk-api-key";
    this.TRANSACTION_NUMBER_KEY = "transaction-number";
    this.PAYFACTO_BATCH_NUMBER_KEY = "payfacto-batch-number";
    this.PAYFACTO_PAX = "payfactoPax";
    this.GLOBAL_FLEX = "globalFlex";
    this.AMP_USA = "ampUSA";
    this.ITC = "itc";
  }

  /**
   * @returns {KioskConfigurationDefinition}
   */
  toDto() {
    return {
      name: this.name,
      number: this.number,
      adminPassword: this.adminPassword,
      managementPassword: this.managementPassword,
      display: this.display,
      airxtouchSize: this.airxtouchSize,
      scannerDelay: this.scannerDelay,
      branchCanonical: this.branchCanonical,
      companyCanonical: this.companyCanonical,
      offlinePayment: this.offlinePayment,
      terminalPayment: this.terminalPayment,
      tip: this.tip,
      tipConfiguration: this.tipConfiguration,
      itcPayment: this.itcPayment,
      itcDeviceId: this.itcDeviceId,
      apiKey: this.apiKey,
      noQuantityModifier: this.noQuantityModifier,
      noQuantityProduct: this.noQuantityProduct,
      methods: this.methods,
      start: this.start,
      callingSystem: this.callingSystem,
      terminalData: this.terminalData,
      minimumTransactionNumber: this.minimumTransactionNumber,
      maximumTransactionNumber: this.maximumTransactionNumber,
      receipt: this.receipt,
      printer: this.printer,
      locales: this.locales
    };
  }

  /**
   * @param {KioskConfigurationDefinition} definition
   */
  load(definition) {
    this.name = definition.name;
    this.number = definition.number;
    this.adminPassword = definition.adminPassword;
    this.managementPassword = definition.managementPassword;
    this.display = definition.display;
    this.airxtouchSize = definition.airxtouchSize || Constant.AIRXTOUCH_SIZE_55;
    this.branchCanonical = definition.branchCanonical;
    this.companyCanonical = definition.companyCanonical;
    this.offlinePayment = {
      cash: definition.offlinePayment ? definition.offlinePayment.cash : false
    };
    this.terminalPayment = definition.terminalPayment;
    this.apiKey = definition.apiKey;
    this.tip = definition.tip;
    this.itcPayment = definition.itcPayment || false;
    this.itcDeviceId = definition.itcDeviceId || "";
    this.noQuantityProduct = definition.noQuantityProduct;
    this.noQuantityModifier = definition.noQuantityModifier;
    this.scannerDelay = definition.scannerDelay || 300;
    this.receipt = {
      headerLine1: definition.receipt ? definition.receipt.headerLine1 : "",
      headerLine2: definition.receipt ? definition.receipt.headerLine2 : "",
      headerLine3: definition.receipt ? definition.receipt.headerLine3 : "",
      footerLine1: definition.receipt ? definition.receipt.footerLine1 : "",
      footerLine2: definition.receipt ? definition.receipt.footerLine2 : ""
    };
    this.methods = {
      inPlace: definition.methods ? definition.methods.inPlace : false,
      takeout: definition.methods ? definition.methods.takeout : false,
      grabngo: definition.methods ? definition.methods.grabngo : false,
      scan: definition.methods ? definition.methods.scan : false,
      ticket: definition.methods ? definition.methods.ticket : false
    };
    this.start = {
      subtitle: definition.start && definition.start.subtitle ? definition.start.subtitle : { fr: "", en: "" },
      title: definition.start && definition.start.title ? definition.start.title : { fr: "", en: "" }
    };
    this.callingSystem = {
      activated: definition.callingSystem ? definition.callingSystem.activated : false,
      required: definition.callingSystem ? definition.callingSystem.required : false,
      title: definition.callingSystem && definition.callingSystem.title ? definition.callingSystem.title : { fr: "", en: "" },
      subtitle: definition.callingSystem && definition.callingSystem.subtitle ? definition.callingSystem.subtitle : { fr: "", en: "" },
      methods: {
        inPlace: definition.callingSystem && definition.callingSystem.methods ? definition.callingSystem.methods.inPlace : true,
        takeout: definition.callingSystem && definition.callingSystem.methods ? definition.callingSystem.methods.takeout : true
      }
    };
    this.locales = {
      default: definition.locales ? definition.locales.default : "en",
      en: definition.locales ? definition.locales.en : true,
      fr: definition.locales ? definition.locales.fr : false
    };
    this.printer = {
      activated: definition.printer ? definition.printer.activated : false,
      name: definition.printer ? definition.printer.name : "",
      cols: definition.printer ? definition.printer.cols : 42
    };
    this.tipConfiguration = {
      values: definition.tipConfiguration && definition.tipConfiguration.values ? definition.tipConfiguration.values : [12, 15, 18, 20]
    };
    this.maximumTransactionNumber = definition.maximumTransactionNumber;
    this.minimumTransactionNumber = definition.minimumTransactionNumber;
    this.terminalData = definition.terminalData;
  }

  /**
   * @returns {KioskConfiguration}
   */
  loadFromLocalKioskConfiguration() {
    let localKioskConfiguration = this.getLocalKioskConfiguration();
    if (!localKioskConfiguration) {
      return null;
    }
    this.load(localKioskConfiguration);
    return this;
  }

  validateDefaultLocale() {
    if (!this.locales.fr) {
      this.locales.default = "en";
    } else if (this.locales.fr && !this.locales.en) {
      this.locales.default = "fr";
    }
  }

  validate() {
    // Should have at least one order method
    let methods = this.getMethodsActivated();
    if (methods.length === 0) {
      showAffirmation(window.translate("kiosk.invalid_configuration"), window.translate("kiosk.no_order_method"));
      return false;
    }
    // Should have at least one payment method
    let hasPaymentMethodActivated = this.terminalPayment || this.offlinePayment.cash || this.itcPayment;
    if (!hasPaymentMethodActivated) {
      showAffirmation(window.translate("kiosk.invalid_configuration"), window.translate("kiosk.no_payment_method"));
      return false;
    }

    // Invalid TPV configuration
    if (!this.isTerminalDataValid()) {
      showAffirmation(window.translate("kiosk.invalid_configuration"), window.translate("kiosk.missing_terminal_configuration"));
      return false;
    }

    if (!this.validateTip()) {
      return false;
    }

    //Invalid ITC config
    if (this.itcPayment && !this.itcDeviceId) {
      showAffirmation(window.translate("kiosk.invalid_configuration"), window.translate("kiosk_configuration.missing_itc_device_id"));
      return false;
    }

    // Everything is good to go
    return true;
  }

  /**
   * @returns {string}
   */
  getDefaultLocale() {
    return this.locales.default;
  }

  getScannerDelay() {
    if (this.scannerDelay > 0) {
      return parseInt(this.scannerDelay);
    } else {
      return 300;
    }
  }

  isTerminalDataValid() {
    if (this.hasAmpUSAPayment()) {
      return this.getAmpTerminalSerialNumber() && this.getAmpAuthCode();
    }
    if (this.hasPayfactoPaxPayment()) {
      return this.terminalData && this.terminalData.terminalNumber;
    }
    if (this.hasGlobalFlexPayment()) {
      return this.terminalData && this.terminalData.ip;
    }
    return true;
  }

  getReceiptImage() {
    return null;
  }

  getName() {
    let name = `#${this.number}`;
    if (this.name) {
      name += ` (${this.name})`;
    }
    return name;
  }

  /**
   * @returns {String[]}
   */
  getMethodsActivated() {
    let methods = [];
    if (this.hasInPlace()) {
      methods.push("inPlace");
    }
    if (this.hasTakeout()) {
      methods.push("takeout");
    }
    if (this.hasGrabngo()) {
      methods.push("grabngo");
    }
    if (this.isTicket()) {
      methods.push("ticket");
    }
    return methods;
  }

  getReceiptFooterLines() {
    let receipt = this.receipt;
    let lines = [];
    if (receipt.footerLine1 || receipt.footerLine2) {
      if (receipt.footerLine1) {
        lines.push(receipt.footerLine1);
      }
      if (receipt.footerLine2) {
        lines.push(receipt.footerLine2);
      }
    }
    return lines;
  }

  getReceiptHeaderLines() {
    let receipt = this.receipt;
    let lines = [];
    if (receipt.headerLine1 || receipt.headerLine2 || receipt.headerLine3) {
      if (receipt.headerLine1) {
        lines.push(receipt.headerLine1);
      }
      if (receipt.headerLine2) {
        lines.push(receipt.headerLine2);
      }
      if (receipt.headerLine3) {
        lines.push(receipt.headerLine3);
      }
    }
    return lines;
  }

  getPaymentMethodsActivated() {
    let methods = [];
    if (this.hasCashPayment()) {
      methods.push("cash");
    }
    if (this.terminalPayment) {
      methods.push(this.terminalPayment);
    }
    return methods;
  }

  getMainPrinterName() {
    return this.printer.name;
  }

  /**
   * @returns {boolean}
   */
  hasInPlace() {
    return this.methods.inPlace;
  }

  /**
   * @param {string} method
   * @returns {boolean}
   */
  hasCallingSystemForMethod(method) {
    return this.callingSystem.activated && this.callingSystem.methods[method];
  }

  isTipEnabled() {
    return this.tip && this.tipConfiguration && this.tipConfiguration.values.length > 0;
  }

  getTipOptions() {
    if (this.tipConfiguration && this.tipConfiguration.values) {
      return this.tipConfiguration.values;
    }
    return [10, 15, 18, 20];
  }

  validateTip() {
    for (let i = 0; i < this.tipConfiguration.values.length; i++) {
      let tipValue = this.tipConfiguration.values[i];
      if (!tipValue) {
        tipValue = 0;
      }
      tipValue = parseFloat(tipValue);
      let hasDecimals = tipValue % 1 !== 0;
      if (hasDecimals || tipValue < 1) {
        showAffirmation(window.translate("kiosk.invalid_configuration"), window.translate("kiosk.tip_invalid").replace("{NUMBER}", (i + 1)));
        return false;
      }
    }
    return true;
  }

  /**
   * @returns {boolean}
   */
  hasTakeout() {
    return this.methods.takeout;
  }

  isTicket() {
    return this.methods.ticket;
  }

  disableAllMethodsNotTicket() {
    for (let method of Object.keys(this.methods)) {
      if (method === "ticket") {
        continue;
      }
      this.methods[method] = false;
    }
  }

  /**
   * @returns {boolean}
   */
  hasGrabngo() {
    return this.methods.grabngo;
  }

  isGrabngoActivated() {
    return getConfiguration(this.GRABNGO_KEY);
  }

  activateGrabngo() {
    setConfiguration(this.GRABNGO_KEY, true);
  }

  deactivateGrabngo() {
    setConfiguration(this.GRABNGO_KEY, false);
  }

  /**
   * @returns {boolean}
   */
  hasScan() {
    return this.methods.scan;
  }

  allowProductScan() {
    return this.hasScan() || this.isGrabngoActivated();
  }

  hasCashPayment() {
    return this.offlinePayment.cash;
  }

  hasPayfactoPaxPayment() {
    return this.terminalPayment === this.PAYFACTO_PAX;
  }

  hasGlobalFlexPayment() {
    return this.terminalPayment === this.GLOBAL_FLEX;
  }

  hasAmpUSAPayment() {
    return this.terminalPayment === this.AMP_USA;
  }

  hasTerminalPayment() {
    return !!this.terminalPayment;
  }

  isFlexPaymentUnavailable() {
    return this.hasGlobalFlexPayment() && getConfiguration("flex-unavailable");
  }

  /**
   * @returns {null|string}
   */
  getAmpTerminalSerialNumber() {
    return this.hasAmpUSAPayment() && this.terminalData ? this.terminalData.terminalSerialNumber : null;
  }

  /**
   * @returns {null|string}
   */
  getAmpAuthCode() {
    return this.hasAmpUSAPayment() && this.terminalData ? this.terminalData.authenticationCode : null;
  }

  /**
   * @returns {boolean}
   */
  hasMultipleLocales() {
    return this.locales.fr && this.locales.en;
  }

  /**
   * @returns {KioskConfigurationDefinition}
   */
  getLocalKioskConfiguration() {
    return window.getConfiguration(this.KIOSK_CONFIGURATION_KEY);
  };

  setLocalKioskConfiguration() {
    window.setConfiguration(this.KIOSK_CONFIGURATION_KEY, this.toDto());
    window.setConfiguration(this.API_KEY, this.apiKey);
    if (this.printer.activated) {
      window.setConfiguration("printer-name", this.printer.name);
      window.setConfiguration("printer-cols", this.printer.cols);
    } else {
      window.setConfiguration("printer-name", "");
      this.printer.name = "";
    }
  };

  /**
   * @returns {number}
   */
  getPrinterCols() {
    return this.printer.cols;
  }

  clearApiKey() {
    this.apiKey = "";
    window.setConfiguration(this.API_KEY, null);
  }

  /**
   * @return {string}
   */
  getApiKey() {
    return this.apiKey;
  }

  /**
   * @return {number}
   */
  getTransactionNumber() {
    return parseInt(getConfiguration(this.TRANSACTION_NUMBER_KEY)) || 1;
  }

  getPaddedTransactionNumber() {
    let padLength = this.maximumTransactionNumber.toString().length;
    return padNumberWithLeadingZeros(padLength, this.getTransactionNumber());
  }

  incrementTransactionNumber() {
    let nextNumber = this.getTransactionNumber() + 1;
    setConfiguration(this.TRANSACTION_NUMBER_KEY, nextNumber > parseInt(this.maximumTransactionNumber) ? parseInt(this.minimumTransactionNumber) : nextNumber);
  }

  matchTransactionNumberToMinimumConfigured() {
    let currentTransactionNumber = this.getTransactionNumber();
    if (currentTransactionNumber < parseInt(this.minimumTransactionNumber)) {
      setConfiguration(this.TRANSACTION_NUMBER_KEY, parseInt(this.minimumTransactionNumber));
    }
  }

  getAutoCloseBatchHour() {
    if (this.terminalPayment && this.terminalData && this.terminalData.autoCloseBatch) {
      return this.terminalData.autoCloseBatch;
    }
  }

  saveCurrentPayfactoBatchNumber(sale) {
    if (sale && sale.payments && sale.payments.length > 0) {
      for (let payment of sale.payments) {
        if (payment.data && payment.data.preAuthReceipt) {
          setConfiguration(this.PAYFACTO_BATCH_NUMBER_KEY, payment.data.preAuthReceipt.batchNumber);
          break;
        }
      }
    }
  }

  getPayfactoBatchNumber() {
    return getConfiguration(this.PAYFACTO_BATCH_NUMBER_KEY);
  }

  /**
   * @returns {boolean}
   */
  isVertical() {
    return this.display === "vertical";
  }

  stripHTML() {
    let allowedHTMLtags = "<b><i><u><p><ol><ul><br><span>";

    this.start.title.fr = stripTags(this.start.title.fr, allowedHTMLtags);
    this.start.title.en = stripTags(this.start.title.en, allowedHTMLtags);
    this.start.subtitle.fr = stripTags(this.start.subtitle.fr, allowedHTMLtags);
    this.start.subtitle.en = stripTags(this.start.subtitle.en, allowedHTMLtags);
  }

  /**
   * @returns {boolean}
   */
  isVerticalAIRxTOUCH() {
    return this.display === "vertical_airxtouch";
  }

  /**
   * @returns {boolean}
   */
  isAIRxTOUCH32Inch() {
    return this.airxtouchSize == Constant.AIRXTOUCH_SIZE_32;
  }

  /**
   * @returns {boolean}
   */
  isHorizontal() {
    return this.display === "horizontal";
  }

  /**
   * @returns {boolean}
   */
  hasItcPayment() {
    return this.itcPayment;
  }

  /**
   * @returns {string}
   */
  getItcDeviceId() {
    return this.itcDeviceId;
  }

  /**
   * @returns {null}
   */
  getXposUrl() {
    return null;
  }

}
