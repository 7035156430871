<template>
  <div id="pos-inactivity" :class="{ show: showModal }" @click.stop>
    <div class="pos-inactivity__modal">
      <div class="modal__title">
        <div class="title__icon">
          <svgicon src="icons/warning-full.svg"></svgicon>
        </div>
        <div class="title__text">{{ $t("pos.inactivity.title") }}</div>
      </div>
      <Illustration class="modal__illustration" src="icons/waving.svg"></Illustration>
      <div class="modal__body">
        <div class="body__title">{{ $t("pos.inactivity.areyouthere") }}</div>
        <div class="body__text">
          {{ $t("pos.inactivity.closingin_1") }} <b>{{ remainingTime }}</b> {{ $t("pos.inactivity.closingin_2") }}
        </div>
      </div>
      <div class="modal__footer">
        <button class="footer__button" v-wave @click="reset">{{ $t("continue") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Illustration from "../modal/Illustration.vue";
  import { mapActions } from "vuex";

  export default {

    name: "PosInactivityChecker",

    components: {
      Illustration
    },

    data() {
      return {
        lastActivity: Date.now(),
        showModal: false,
        modalSeconds: 30,
        interval: null,
        remainingTime: 0
      };
    },

    mounted() {
      document.addEventListener("click", this.reset);
      document.addEventListener("contextMenu", this.reset);
      document.addEventListener("touchstart", this.reset);
      document.addEventListener("touchmove", this.reset);
      this.interval = setInterval(this.update.bind(this), 1000);
    },

    beforeUnmount() {
      document.removeEventListener("click", this.reset);
      document.removeEventListener("contextMenu", this.reset);
      document.removeEventListener("touchstart", this.reset);
      document.removeEventListener("touchmove", this.reset);
      clearInterval(this.interval);
    },

    computed: {
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      timeout() {
        return parseInt(this.posConfiguration.timeoutDelay) * 1000;
      },
      table() {
        return this.$store.state.table;
      }
    },

    methods: {
      ...mapActions(["sendTable"]),
      reset() {
        this.lastActivity = Date.now();
        this.showModal = false;
      },
      update() {
        let safeRoutes = this.$store.getters.menuRouteNames;
        if (safeRoutes.indexOf(this.$route.name) == -1) {
          this.reset();
          return;
        }
        let elapsed = Date.now() - this.lastActivity;
        /** to do make this false  in prod */
        let disableModal = true;
        this.remainingTime = Math.ceil((this.timeout - (Date.now() - this.lastActivity)) / 1000);
        if (elapsed >= this.timeout - 1000 * this.modalSeconds && !disableModal) {
          this.showModal = this.table.details.length > 0;
          if (elapsed >= this.timeout) {
            this.onTimeout();
          }
        } else {
          this.showModal = false;
        }
      },
      async onTimeout() {
        this.reset();
        if (this.table.details.length > 0) {
          let response = await this.sendTable({ table: this.table });
        }
        this.$store.state.employee = null;
        this.$router.push({ name: "posDashboard" });
      }
    }

  };
</script>

<style lang="scss" scoped>
#pos-inactivity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

#pos-inactivity:not(.show) {
  .pos-inactivity__modal {
    top: -100px !important;
  }
}

.pos-inactivity__modal {
  background-color: var(--background-color-2);
  padding: 40px;
  border-radius: 40px;
  width: 480px;
  position: relative;
  top: 0;
  transition: top 0.3s ease-in-out;
}

.modal__title {
  font-size: 22px;
  font-weight: 900;
  border-bottom: 1px solid var(--border-color-4);
  padding-bottom: 15px;
  margin-bottom: 40px;
  display: flex;

  .title__icon {
    margin-right: 20px;
    fill: var(--primary-color);
  }
}

.modal__illustration {
  fill: var(--primary-color);
  margin-bottom: 40px;
}

.modal__body {
  text-align: center;

  .body__title {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  .body__text {
    font-weight: 400;
    border-bottom: 1px dashed var(--border-color-4);
    padding-bottom: 35px;
    margin-bottom: 20px;
  }
}

.modal__footer {
  text-align: center;

  .footer__button {
    font-size: 20px;
    padding: 15px 22px;
    background-color: var(--background-color-3);
    border: none;
    border-radius: 12px;
    color: var(--primary-color);
    cursor: pointer;
  }
}
</style>

<style lang="scss">
#pos-inactivity {
  .illustration {
    .svgicon {
      animation: waving 4s infinite;
      transform-origin: bottom right;
      margin-right: 10px;
    }
  }
}

@keyframes waving {
  0% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(0);
  }
  40% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(12deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(7deg);
  }
  95% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>