<template>
  <div id="transactionHistory">
    <PageTitle>
      <svgicon src="icons/history.svg" class="icon" :height="20" :width="20"></svgicon>
      {{$t('loyalty.history')}}
    </PageTitle>

    <div class="page-content">
      <Panel :title="$store.getters.isMobileOrTablet ? '' : $t('transaction_history.title')" icon="icons/history.svg">
        <Transactions></Transactions>
      </Panel>
    </div>

  </div>
</template>

<script>

  import Transactions from "../../components/loyalty/Transactions.vue";

  export default {

    name: "TransactionHistory",

    components: { Transactions },

    data() {
      return {
        accountPlaceholder: 1
      };
    },
    mounted() {
      this.$store.commit("showNavigationCart", true);
    },
  }

</script>

<style scoped lang="scss">

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;

      &:after {
        height: 0;
      }
    }
  }

</style>