<template>
  <div class="nextModifier" v-if="forceModifierInteraction && !isEdit && !$pos" :class="{kiosk: $kiosk}">
    <button class="btn btn-primary" @click="emitClick" :class="{disabled: !isValid}">{{ $t("continue") }}</button>
  </div>
</template>

<script>

  export default {
    name: "NextModifier",

    props: {
      modifierGroup: Object
    },

    computed: {
      interactiveMode() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.$store.state.currentBranch) {
          return false;
        }
        return this.$store.state.currentBranch.interactiveMode;
      },
      forceModifierInteraction() {
        if (this.$kiosk || this.$pos) {
          return true;
        }
        if (!this.interactiveMode) {
          return false;
        }
        return this.$store.state.currentBranch.style.forceModifierInteraction;
      },
      isEdit() {
        return this.$route.name === "itemEdit";
      },
      isValid() {
        if (!this.modifierGroup) {
          return true;
        }
        return this.modifierGroup.validateQuantitySelected().valid;
      }
    },

    methods: {
      emitClick() {
        this.$emit("click");
        this.$el.classList.add("fadeOut");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .nextModifier {
    width: 100%;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s ease;

    &:not(.kiosk) {
      .btn {
        height: 60px;
        font-size: 20px !important;
      }
    }

    &.fadeOut {
      opacity: 0;
      pointer-events: none;
    }

    .btn {
      width: 100%;
      max-width: 410px;
    }
  }
</style>