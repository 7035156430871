<template>
  <div id="giftCard">

    <Panel class="gift-card-panel" icon="icons/gift.svg" :title="$t('gift_card.purchase_online_gift_card')" v-if="ready" v-show="isGiftCardEnabled">

      <div class="section" :class="{completed}" v-if="step !== 3">
        <div class="e-card" :class="{backgroundImage: !!selectedTemplateImage}" :style="{backgroundImage: 'url(\'' + selectedTemplateImage + '\')'}">
          <div class="img" v-if="!templateId" :style="{backgroundImage: 'url(\'' + logo + '\')'}"></div>
        </div>

        <div class="content" v-if="!completed && branch">
          <div class="text">{{$t('gift_card.description').replace("{SHOP}", branch.name)}}</div>
          <div class="input">
            <label>{{$t('gift_card.amount')}}</label>
            <select v-model="amount" v-if="step === 1">
              <option :value="null">{{$t('gift_card.choose_amount')}}...</option>
              <option :value="10">10$</option>
              <option :value="25">25$</option>
              <option :value="50">50$</option>
              <option :value="100">100$</option>
            </select>
            <input type="text" v-if="step !== 1" :value="amount + '$'" readonly>
          </div>
        </div>
      </div>

      <div class="section bottom" v-if="!completed && step === 1">
        <div class="template--choice">
          <div class="template--choose">{{$t('gift_card.choose_template')}}</div>
          <div class="templates">
            <div class="template" v-for="(template, templateIndex) of templates" :key="'templateIndex' + templateIndex"
                 @click="templateId = template.id" :class="{selected: template.id === templateId}">
              <svgicon class="check" src="icons/check-circle-full.svg" :height="20" :width="20"  v-if="template.id === templateId"></svgicon>
              <div class="img">
                <img :src="getImage(template.image)">
              </div>
              <div class="img-name">{{$to(template.name)}}</div>
            </div>
          </div>
        </div>
        <div class="button">
            <div class="btn btn-primary" @click="goToStep(2)">{{ $t("continue") }}</div>
        </div>
      </div>

      <div class="section bottom" v-if="!completed && step === 2">
        <label class="radio-label__question">{{$t('gift_card.send_destination_email_question')}}</label>
        <div class="columns">
          <div class="column">
            <div class="input radio__input">
              <input id="radioUseEmail__true" type="radio" v-model="useDestinationEmail" :value="true">
              <label for="radioUseEmail__true">{{$t('gift_card.send_by_email')}}</label>
            </div>
          </div>
          <div class="column">
            <div class="input radio__input">
              <input id="radioUseEmail__false" type="radio" v-model="useDestinationEmail" :value="false">
              <label for="radioUseEmail__false">{{$t('gift_card.print_gift_card')}}</label>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="input">
              <label>{{$t('gift_card.from_name')}}</label>
              <input type="text" v-model="from.name" :class="{invalid: $validate.error('from.name')}">
            </div>
            <div class="input">
              <label>{{$t('gift_card.from_email')}}</label>
              <input type="text" v-model="from.email" :class="{invalid: $validate.error('from.email')}">
            </div>
          </div>
          <div class="column">
            <div class="input">
              <label>{{$t('gift_card.to_name')}}</label>
              <input type="text" v-model="to.name" :class="{invalid: $validate.error('to.name')}">
            </div>
            <div class="input" v-show="useDestinationEmail">
              <label>{{$t('gift_card.to_email')}}</label>
              <input type="text" v-model="to.email" :class="{invalid: $validate.error('to.email')}">
            </div>
          </div>
        </div>
        <div class="input">
          <label>{{$t('gift_card.note')}}</label>
          <textarea v-model="from.note"></textarea>
        </div>
        <div class="button split">
          <button class="btn btn-secondary" @click="goToStep(1)">{{$t('back')}}</button>
          <button class="btn btn-primary" @click="goToStep(3)">{{$t('continue')}}</button>
        </div>
      </div>

      <div class="section bottom" v-show="!completed && step === 3 && giftCardTerms">
        <div class="terms-label">{{$t('terms')}}</div>
        <div class="terms-holder" v-if="giftCardTerms">
          <div class="main-terms" v-html="$to(giftCardTerms.terms)"></div>
          <div class="more-terms" v-if="giftCardTerms.advancedTerms" @click="moreTerms = !moreTerms">
            {{$t('more_info')}}
            <svgicon src="icons/arrow-right.svg" :height="14" :width="14"></svgicon>
          </div>
          <div class="more" v-html="$to(giftCardTerms.advancedTerms)" v-if="moreTerms"></div>
        </div>
        <div class="group check">
          <input id="chkTerms" type="checkbox" v-model="acceptTerms">
          <label for="chkTerms">{{ $t('gift_card.accept_terms_1') }} {{$t('gift_card.accept_terms_2')}}</label>
        </div>
        <div class="section payment-holder">
          <PaymentForm ref="paymentForm" :validate="$validate" @trigger-validation="validatePayment($event)" hidePaymentMethodSelection></PaymentForm>
        </div>
        <div class="button split">
          <button class="btn btn-secondary" @click="goToStep(2)">{{$t('back')}}</button>
          <template v-if="!shouldDisplayPayfactoButton && !PaymentProcessor.shouldHideDefaultOrderButton()">
            <button class="btn btn-primary" @click="send">{{ $t("confirm") }}</button>
          </template>
          <template v-if="shouldDisplayPayfactoButton">
            <button class="btn btn-primary" id="btnPay" @click="continueWithPayfacto">
              {{ isPayfactoSHC ? $t("confirm") : $t("loyalty.to_payment")}} ({{$tc(amount.toFixed(2))}})
            </button>
          </template>
        </div>
      </div>

      <div class="completed section hidePrint" v-if="completed">
        <div class="title">{{$t('gift_card.thank_you')}}</div>
        <div class="success-message" v-if="successMessage" v-html="successMessage"></div>
        <div class="explanation" v-if="useDestinationEmail" v-html="$t('gift_card.explanation').replace('{EMAIL}',to.email).replace('{AMOUNT}', $tc(amount))"></div>
        <div class="explanation" v-if="!useDestinationEmail" v-html="$t('gift_card.explanation_no_email').replace('{AMOUNT}', $tc(amount))"></div>
        <div class="print" v-if="!useDestinationEmail">
          <button class="btn btn-primary" @click="printGiftCard">{{$t('gift_card.print')}}</button>
        </div>
      </div>

      <div class="completed section showPrint">
        <div class="title">{{$t('gift_card.your_gift_card').replace("{BRANCH}", branch.name)}}</div>

        <div class="info">
          <label>{{$t('gift_card.your_number')}}</label>
          <div class="number">{{purchasedGiftCardNumber}}</div>
        </div>
        <div class="info">
          <label>{{$t('gift_card.amount')}}</label>
          <div class="number">{{$tc(amount)}}</div>
        </div>
        <div class="info">
          <label>{{$t('gift_card.from')}}</label>
          <div class="number">{{from.name}}</div>
        </div>
        <div class="info" v-if="from.note">
          <label>{{$t('gift_card.message')}}</label>
          <div class="number">{{from.note}}</div>
        </div>

        <div class="terms-and-conditions" v-if="giftCardTerms">
          <label>{{$t('terms')}}</label>
          <p class="terms" v-html="$to(giftCardTerms.terms)"></p>
          <p class="terms" v-html="$to(giftCardTerms.advancedTerms)"></p>
        </div>
      </div>
    </Panel>

  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { PaymentProcessor } from "@/PaymentProcessor";
  import Store from "@/store";
  import Axios from "axios/index";
  import PaymentForm from "@/components/payment/PaymentForm";

  export default {

    name: "giftCardPurchase",

    components: { PaymentForm },

    data() {
      return {
        amount: null,
        ready: false,
        completed: false,
        successMessage: null,
        moreTerms: false,
        acceptTerms: false,
        step: 1,
        templates: [],
        templateId: null,
        useDestinationEmail: true,
        purchasedGiftCardNumber: null,
        from: {
          name: "",
          email: "",
          note: ""
        },
        to: {
          name: "",
          email: ""
        },
        PaymentProcessor
      };
    },

    validate: {
      "from.name": {
        required: true
      },
      "from.email": {
        required: true,
        email: true
      },
      "to.name": {
        required: true
      },
      "to.email": {
        required: true,
        email: true,
        condition: function() {
          return this.useDestinationEmail;
        }
      }
    },

    async mounted() {
      this.$store.commit("showNavigationCart", false);
      this.setLocale(this.$router.currentRoute.query.locale);

      let interval = setInterval(async () => {
        if (this.branch) {
          clearInterval(interval);
          await this.loadTemplates();
          await this.checkIfPrintOnly();
          this.ready = true;
        }
      }, 100);

      EventBus.$on("submit-payment", () => {
        this.send();
      });
    },

    beforeDestroy() {
      EventBus.$off("submit-payment");
    },

    watch: {
      "PaymentProcessor.paymentMethod": function(newValue, oldValue) {
        if (!newValue && oldValue) {
          if (this.step !== 3) {
            return;
          }
          this.$nextTick(() => {
            this.setBranchFirstPaymentMethod();// So payfacto SHC will be init again by the watcher in PaymentForm
          });
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      isGiftCardEnabled() {
        return this.branch && this.branch.isiShopFoodGiftCardEnabled();
      },
      selectedTemplateImage() {
        if (!this.templateId) {
          return;
        }
        let template = this.templates.find(t => t.id === this.templateId);
        return this.getImage(template.image);
      },
      giftCardTerms() {
        if (!this.branch) {
          return;
        }
        return this.branch.getGiftCardTerms();
      },
      token() {
        return this.$route.params.token;
      },
      queryEmail() {
        return this.$route.query.email;
      },
      logo() {
        if (!this.branch || !this.branch.navigationLogo) {
          return null;
        }
        return this.$store.state.urlFileServer + this.branch.navigationLogo.url;
      },
      paymentForm() {
        if (!this.ready || this.step < 2) {
          return;
        }
        return this.$refs.paymentForm;
      },
      shouldDisplayPayfactoButton() {
        if (!this.ready || this.step < 2) {
          return false;
        }
        return PaymentProcessor.isPaymentMethod("payfacto");
      },
      isPayfactoSHC() {
        return PaymentProcessor.usePayFactoSHC;
      }
    },

    methods: {
      setLocale(locale) {
        if (locale) {
          this.$ts.commit("setLocale", locale);
          setConfiguration("locale", locale);
        }
      },
      async checkIfPrintOnly() {
        if (this.$route.name === "giftCardPrint" && this.$route.params.token) {
          await this.getGiftCard();
        }
      },
      initializePaymentProcessorAndPaymentMethods() {
        this.setBranchFirstPaymentMethod();
        PaymentProcessor.setAmount(this.amount);
        PaymentProcessor.initializePaymentMethod();
      },
      setBranchFirstPaymentMethod() {
        let paymentMethod = this.branch.getFirstPaymentMethod();
        if (paymentMethod) {
          PaymentProcessor.setPaymentMethod(paymentMethod.processor);
        }
      },
      async validatePayment(callback) {
        console.log("validate payment");
        PaymentProcessor.readyForPayment = true;
        if (callback && typeof callback === "function") {
          callback();
        }
      },
      async getGiftCard() {
        showSpinner();
        try {
          let response = await Axios.get(`${this.$store.getters.urlServer}/api/gift_cards/find?token=${this.token}&email=${this.queryEmail}`);
          if (response.data && response.status === 200) {
            hideSpinner();
            this.completed = true;
            this.amount = response.data.initialBalance;
            this.purchasedGiftCardNumber = response.data.number;
            this.templateId = response.data.template;
            this.to = response.data.to;
            this.from = response.data.from;
            this.useDestinationEmail = false;
          } else {
            hideSpinner();
            showAffirmation(this.$t("oops"), this.$t("gift_card.not_found"));
          }
        } catch (e) {
          hideSpinner();
          showAffirmation(this.$t("oops"), this.$t("gift_card.not_found"));
        }
      },
      getImage(image) {
        if (image && image.url) {
          return this.$store.state.urlFileServer + image.url;
        }
      },
      goToStep(step) {
        if (step === 2) {
          if (!this.templateId && this.templates.length > 0) {
            showAffirmation(this.$t("warning"), this.$t("gift_card.select_template"));
            return;
          }
          if (!this.amount) {
            showAffirmation(this.$t("warning"), this.$t("gift_card.select_amount"));
            return;
          }
        }
        if (step === 3) {
          if (!this.$validate.valid()) {
            this.$validate.validateAllOnce();
            return;
          }
          this.initializePaymentProcessorAndPaymentMethods();
        }
        this.step = step;
        this.$forceUpdate();
      },
      async send() {
        this.$nextTick(async () => {
          showSpinner();
          let response;
          let currency = this.branch.getCurrencyFromFirstCompany();
          try {
            response = await PaymentProcessor.processPayment();
          } catch (e) {
            console.log("error", e);
            showAffirmation(this.$t("error.title"), this.$t("loyalty.reload_error"));
            hideSpinner();
            return;
          }
          let data = {
            amount: this.amount,
            branch: this.branch.id,
            template: this.templateId,
            locale: this.$ts.state.locale,
            currency: currency.toLowerCase(),
            from: this.from,
            to: this.to,
            payment: PaymentProcessor.getPaymentData()
          };

          if (!this.useDestinationEmail) {
            delete data.to.email;
          }

          let error = false;
          Axios.post(this.$store.getters.urlServer + "/api/gift_cards", data, {})
            .catch(e => {
              console.log("Error", e);
              error = true;
              showAffirmation(this.$t("error.title"), this.$t("gift_card.error"));
              hideSpinner(true);
            }).then((response) => {
              if (!error) {
                hideSpinner(true);
                this.step = 4;
                if (response.data && response.data.number) {
                  this.purchasedGiftCardNumber = response.data.number;
                }
                this.completed = true;
              }
            });
        });
      },

      async loadTemplates() {
        showSpinner();
        try {
          let result = await Axios.get(this.$store.getters.urlServer + "/api/gift_card_templates?branch=" + this.branch.id);
          if (result) {
            this.templates = result.data.data.filter(t => t.active);
            if (this.templates.length > 0) {
              this.templateId = this.templates[0].id;
            }
          }
        } catch (e) {
          console.log("error", e);
        }
        hideSpinner();
      },
      printGiftCard() {
        if (!this.purchasedGiftCardNumber) {
          return;
        }
        window.print();
      },
      showPayfactoSuccessFeedback(transaction) {
        if (transaction.processor !== "payfacto") {
          return;
        }
        let info = transaction.data.moreInfo;
        this.successMessage = this.$t("payment.completed") + "<br><br>" +
                "<b>" + info.returnCode.replace(" ", "") + " - " + this.$t("success") + "</b><br>" +
                this.$t("payment.transaction_number") + ": #" + info.transactionNumber + "<br>" +
                this.$t("payment.authorization_number") + ": #" + info.authorizationNumber + "<br>";
      },
      continueWithPayfacto() {
        if (!this.ready) {
          return;
        }
        if (!!this.giftCardTerms && !!this.giftCardTerms.terms && !this.acceptTerms) {
          showAffirmation(this.$t("warning"), this.$t("gift_card.accept_terms"));
          return;
        }
        if (this.isPayfactoSHC) {
          PaymentProcessor.sendSubmitForPayFactoSHC();
        } else {
          PaymentProcessor.showPayfactoModal();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp #giftCard {
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f5f5f5 !important;
    overflow-y: auto;

    ::-webkit-scrollbar {
      background-color: #f2f2f2 !important;
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9 !important;
      border-radius: 4px;
    }

    .print {
      margin-top: 30px;
     .btn {
       font-size: 20px;
       padding: 10px 30px;
     }
    }
  }
  #iShopFoodApp .template--choice {
    .template--choose {
      font-size: 14px;
      color: #808080 !important;
      margin: 15px 0;
    }
    .templates {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      .template {
        margin: 8px;
        cursor: pointer;
        position: relative;

        &.selected {
          .img {
            border-color: #e31818;
          }
        }

        .check {
          fill: #e31818;
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: white;
          border-radius: 100%;
        }

        .img {
          width: 145px;
          border-radius: 15px;
          border: 1px solid #e6e6e6;
          padding: 14px;

          img {
            width: 100%;
            height: auto;
            border-radius: 10px;
          }
        }
        .img-name {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }

  .showPrint {
    display: none !important;
  }

  .gift-card-panel {
    width: 100%;
    max-width: 700px;
    flex-shrink: 0;
  }

  #iShopFoodApp .section {
    display: flex;
    padding: 20px;

    &.payment-holder {
      padding: 20px 0;
    }

    &.completed {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      &:not(:last-child) {
        border-bottom: 0;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .explanation {
        color: #404040 !important;
      }

      .e-card {
        margin-right: 0;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px dashed #e6e6e6;
    }

    &.bottom {
      flex-direction: column;
    }
  }

  .section .content {
    flex-shrink: 0;
    max-width: 350px;
  }

  .e-card {
    flex-shrink: 0;
    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 30px 45px;
    width: 240px;
    height: 160px;
    margin-right: 40px;
    background: linear-gradient(to bottom right, rgba(220,173, 173, 0.92), rgba(218, 39, 39, 0.83));
    background-size: cover;
    background-position: center;

    .img {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .columns {
    display: flex;
    flex-wrap: wrap;

    .column {
      flex-grow: 1;
      flex-basis: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .button {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    &.split {
      justify-content: space-between;
    }
  }
  .buttons {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .group.check {
    padding-top: 15px;
  }

  #iShopFoodApp .terms-label {
     color: #808080 !important;
  }
  #iShopFoodApp .terms-holder {
    max-height: 350px;
    line-height: 1.2;
    overflow-y: auto;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 7px;
    margin-top: 5px;

    .main-terms {
      white-space: pre-wrap;
      color: #404040 !important;
    }
    .more-terms {
      color: var(--primary-color) !important;
      fill: var(--primary-color) !important;
      display: flex;
      cursor: pointer;
      padding-top: 10px;
      align-items: center;
    }
    .more {
      white-space: pre-wrap;
      color: #404040 !important;
      padding-top: 15px;
    }
  }
</style>

<style lang="scss">

  @media print {
    * { overflow: visible !important; }

    #iShopFoodApp {
      overflow: visible !important;
      background: none !important;
      height: auto !important;
      zoom: initial !important;

      .hidePrint {
        display: none !important;
      }

      .showPrint {
        display: block !important;
      }

      .terms-and-conditions {
        margin-top: 30px;
        border-top: 1px dashed #ccc;
        padding-top: 30px;

        label {
          font-size: 14px;
        }

        .terms {
          white-space: pre-wrap;
          color: black !important;
          margin-top: 10px;
          line-height: 1.2;
          font-size: 10px;
          text-align: left;
        }
      }

      .backgroundImage {
        margin-top: 0;
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
      }

      #giftCard .section.completed {
        padding: 0 !important;
      }

      #giftCard .section.completed .title {
        color: black !important;
        margin-bottom: 30px;
        padding-top: 30px;
        font-size: 18px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 30px;
      }

      #giftCard .section.completed .info {
        margin-top: 15px;

        label {
          font-size: 14px;
        }
        .number {
          color: black !important;
          font-size: 24px;
          font-weight: bold;
        }
      }

      #giftCard.content {
        background-color: white !important;
        overflow: visible !important;
        box-shadow: none !important;

        .panel {
          box-shadow: none;
          max-width: none;
          & > .title {
            display: none;
          }
          & > .content {
            &:before {
              content: none;
            }
          }
        }
      }
    }
  }

  //I'm sorry, I know.
  #iShopFoodApp #giftCard .panel {
    background-color: white !important;

    .title, .text, .text > b {
      color: #404040 !important;
    }

    label {
      color: #808080 !important;
      margin-top: 10px;
      margin-bottom: 2px;
    }

    .content::before {
      border-color: #e6e6e6 !important;
    }

    .btn-secondary {
      background-color: white !important;
    }

    .clickable {
      color: var(--primary-color) !important;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }

     input, textarea, select, option {
      background-color: white !important;
      color: #404040 !important;
      border-color: #e6e6e6 !important;
      width: 100%;

       &.invalid {
         background-color: #ffebeb !important;
       }
    }

    b, i {
      color: #404040 !important;
    }

    .radio__input {
      label {
        display: flex;
        width: auto;
        align-items: center;
        padding-left: 30px;
        padding-top: 5px;
        margin-top: 0;

        &:after {
          left: 10px;
        }
      }
    }
    .radio-label__question {
      font-size: 14px;
    }
  }
  #iShopFoodApp #giftCard #standalonePaymentForm {
    width: 100%;
  }

  #iShopFoodApp.xs #giftCard {
    overflow: auto;
    display: initial;
    background-color: white !important;

    .panel {
      border: none !important;
    }

    .section {
      flex-direction: column;
      align-items: center;
      .e-card {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .text {
      margin-bottom: 20px;
      text-align: center;
    }

    .columns {
      flex-direction: column;
      width: 100%;

      .column {
        padding: 0;
        margin: 0;
      }
    }
    .input {
      width: 100%;
      padding-top: 0;
    }
    .template--choice .templates {
      justify-content: space-around;
    }
    .button, .payment-holder, .group.check, .total-information {
      width: 100%;
    }
  }
</style>

<style lang="scss">
  #giftCard {
    .gift-card-panel {
      max-height: calc(100% - 100px);
      display: flex;
      flex-direction: column;

      & > .content {
        display: flex;

        & > .container {
          overflow-y: auto;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    #giftCard {
      display: block;
      .gift-card-panel {
        max-height: 100%;

        & > .content {
          width: 100%;

          & > .container {
            width: 100%;
          }
        }
      }
    }
  }
</style>