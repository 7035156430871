<template>
  <div id="promotions">

    <MenuHeader :text="$t('navigation.promos')" icon="icons/branches.svg" show-back-button></MenuHeader>

    <div class="page-content">

      <Panel :title="$t('promotion.code')" collapsible>
        <div class="columns">
          <div class="column flex">
            <Illustration class="illustration" src="icons/inactive.svg" small></Illustration>
            <div class="titleText">{{ $t("promotion.enjoy") }}</div>
          </div>
          <div class="column" style="margin-top: 10px; margin-bottom: 10px;">
            <div class="codeDescription">{{ $t("promotion.enter_code") }}</div>
            <div class="group">
              <label>{{ $t("promotion.code") }}</label>
              <div class="flex">
                <input v-model="promoCode" type="text" class="flex-grow promocode" @keyup.enter="checkPromoCode">
                <button class="btn btn-primary" style="align-self: center; margin-left: 20px;" @click="checkPromoCode">{{ $t("add") }}</button>
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <Panel class="promotion-applied" v-for="promo of appliedPromos" :key="promo.id"
             icon="icons/check-circle.svg" :title="$t('promotion.applied') + ': ' + $to(promo.name)"></Panel>

      <Panel icon="icons/promo.svg" :title="$to(promo.name)" v-for="promo of unappliedPromos.filter(p => !!p.code)" :key="promo.id"  :data-id="promo.id" collapsible>
        <div class="description" v-if="$to(promo.description)">{{ $to(promo.description) }}</div>
        <template v-if="promo.triggerType === 'x_for_y'">
          <hr v-if="$to(promo.description)">
          <p class="promo-x-for-y">{{$t('promotion.x_for_y_activated')}}</p>
          <div class="x-for-y__subtitle">
            <template v-if="getHowManyXForYLeft(promo) === 1">
              {{$t('promotion.x_for_y_remaining_single')}}
            </template>
            <template v-if="getHowManyXForYLeft(promo) > 1">
              {{$t('promotion.x_for_y_remaining_multiple').replace('{QUANTITY}', getHowManyXForYLeft(promo))}}
            </template>
          </div>
        </template>
        <template v-if="!shouldHideItems(promo)">
          <hr v-if="$to(promo.description) && filterItems(promo.items).length > 0 && isFirstChoiceAvailable(promo)">
          <div v-if="isFirstChoiceAvailable(promo)">
            <div class="subtitle" v-if="triggerItems(promo).length > 0">{{ $t("promotion.first_choice") }}</div>
            <div class="items before" v-if="triggerItems(promo).length > 0">
              <Item v-for="item of triggerItems(promo)" v-if="item.getPrice($order.method) > 0" :key="item.id" :item="item" small @selected="selectItem(item, promo)"></Item>
            </div>
          </div>
          <div v-if="isSecondChoiceAvailable(promo)">
            <hr v-if="triggerItems(promo).length > 0">
            <div class="subtitle" v-if="triggerItems(promo).length > 0">{{ $t("promotion.second_choice") }}</div>
            <div class="items">
              <Item v-for="item of filterItems(promo.items)" :key="item.id" :item="item" small @selected="selectItem(item, promo)"></Item>
            </div>
          </div>
        </template>
      </Panel>

      <Panel :title="$to(promo.name)" v-for="promo of unappliedPromos.filter(p => !p.code)" :key="promo.id" :data-id="promo.id" collapsible>
        <div class="description">{{ $to(promo.description) }}</div>
        <template v-if="!shouldHideItems(promo)">
          <hr v-if="$to(promo.description) && promo.items.length > 0">
          <div class="subtitle" v-if="triggerItems(promo).length > 0">{{ $t("promotion.first_choice") }}</div>
          <div class="items before" v-if="triggerItems(promo).length > 0">
            <Item v-for="item of triggerItems(promo)" v-if="item.getPrice($order.method) > 0" :key="item.id" :item="item" small @selected="selectItem(item, promo)"></Item>
          </div>
          <div v-if="triggerItems(promo).length > 0">
            <hr >
            <div class="subtitle" v-if="triggerItems(promo).length > 0">{{ $t("promotion.second_choice") }}</div>
            <div class="items">
              <Item v-for="item of promo.items" v-if="item.getPrice($order.method) > 0" :key="item.id" :item="item" small @selected="selectItem(item, promo)"></Item>
            </div>
          </div>
        </template>
      </Panel>

    </div>

  </div>
</template>

<script>

  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import { PromotionManager } from "../PromotionManager";
  import Item from "@/components/menu/Item.vue";
  import Promotion from "../models/PromotionModel";
  import Store from "../store";
  import { mapActions } from "vuex";
  import Illustration from "@/components/modal/Illustration.vue";

  export default {

    name: "promotions",

    components: { MenuHeader, Item, Illustration },

    data() {
      return {
        PromotionManager,
        promoCode: ""
      };
    },

    mounted() {
      this.$store.commit("showNavigationCart", true);
      setTimeout(() => {
        if (this.$route.query.scrollTo) {
          let element = document.querySelector("[data-id=\"" + this.$route.query.scrollTo + "\"]");
          if (element) {
            element.scrollIntoView({
              behavior: "smooth"
            });
          }
        }
      }, 0);
    },

    computed: {
      unappliedPromos() {
        return PromotionManager.getVisiblePromotions().filter(p => p.appliedAmount == 0 || p.appliedOn != "one_item");
      },
      appliedPromos() {
        return PromotionManager.getVisiblePromotions().filter(p => p.appliedAmount > 0 && p.appliedOn == "one_item");
      }
    },

    watch: {
      "PromotionManager.promotions": function() {
        setTimeout(() => {
          if (this.$route.query.scrollTo) {
            let element = document.querySelector("[data-id=\"" + this.$route.query.scrollTo + "\"]");
            if (element) {
              element.scrollIntoView({
                behavior: "smooth"
              });
            }
          }
        }, 0);
      }
    },

    methods: {

      ...mapActions({
        checkPromoCodeAction: "checkPromoCode"
      }),

      /**
       * @param {Promotion} promo
       */
      triggerItems(promo) {
        if (!promo.triggerParameters || (!promo.triggerParameters.items && !promo.items)) {
          return [];
        }
        let items = promo.triggerParameters.items || promo.items.map(i => i.id);
        return items.map(i => {
          return Store.state.inventory.search(i);
        }).filter(i => !!i);
      },

      getHowManyXForYLeft(promo) {
        if (!promo.triggerType === "x_for_y") {
          return 0;
        }
        let howManyInCart = PromotionManager.getXYItemsQuantityInCart(promo);
        return Math.max(0, promo.triggerParameters.x - howManyInCart);
      },

      shouldHideItems(promo) {
        if (!promo.triggerParameters) {
          return false;
        }
        return promo.triggerParameters.hideItems;
      },

      /**
       * @param {Promotion} promo
       */
      isFirstChoiceAvailable(promo) {
        if (promo.type !== "hidden_product" && promo.type !== "replace") {
          return true;
        } else {
          return !PromotionManager._saleHasItem(promo);
        }
      },

      /**
       * @param {Promotion} promo
       */
      isSecondChoiceAvailable(promo) {
        if (promo.type !== "hidden_product" && promo.type !== "replace") {
          return true
        } else {
          return PromotionManager._saleHasItem(promo) || this.triggerItems(promo).length === 0;
        }
      },

      selectItem(item, promo) {
        let id = item.parent ? item.parent.id : item.id;
        if (!item.isCustomizable()) {
          this.$order.addItem(item);
          if (promo.items.length === 1) {
            this.$router.push(this.$router.generate("/menu"));
          }
          return;
        }
        let route;
        if (item.items) {
          route = this.$router.generate("/menu/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + id);
        } else {
          route = this.$router.generate("/item/" + (this.$route.params.id ? this.$route.params.id + "-" : "") + id);
        }

        if (promo && promo.type == "hidden_product") {
          route += "?nextItem=" + promo.items[0].id;
        } else if (promo && (promo.triggerType == "with_item" || promo.triggerType == "x_for_y")) {
          route += "?redirect=promotions";
        }

        this.$router.push(route);
      },

      async checkPromoCode() {
        //TODO refactor this, duplicate of same function in paymentConfirmation.vue, PromoKeyboardModal.vue
        showSpinner();
        let result = await this.checkPromoCodeAction({
          branch: this.$store.state.currentBranch.id,
          company: this.$store.state.currentCompany.id,
          code: this.promoCode
        });
        if (!result.success) {
          if (result.response.error) {
            showAffirmation(this.$t("error.title"), this.$t("promotion.error." + result.response.error));
          } else {
            showAffirmation(this.$t("error.title"), this.$t("promotion.invalid"));
          }
          hideSpinner();
          return;
        }
        if (!PromotionManager.isPromotionWithinSchedule(result.promotion)) {
          showAffirmation(this.$t("oops"), this.$t("promotion.outside_of_schedule"));
          hideSpinner();
          return;
        }
        if (this.$order.getItemsTotal({ excludedTags: result.promotion.exclusion.tags }) < result.promotion.minAmount) {
          showAffirmation(this.$t("oops"), this.$t("promotion.minimum").replace("{AMOUNT}", result.promotion.minAmount));
          hideSpinner();
          return;
        }
        if (result.promotion.disableForMethod.length > 0 &&
          (!this.$order.method || result.promotion.disableForMethod.indexOf(this.$order.method) > -1)) {
          let methods = this.$store.state.currentCompany.getOrderMethodsAvailable().filter(m => result.promotion.disableForMethod.indexOf(m) == -1);
          let translated = methods.map(m => this.$t("promotion.for_method." + m));
          if (translated.length == 0) {
            showAffirmation(this.$t("error.title"), this.$t("promotion.invalid"));
          } else {
            let fullString = "";
            if (translated.length == 1) {
              fullString = translated[0];
            } else {
              let last = translated.splice(translated.length - 1, 1);
              fullString = translated.join(", ") + " " + this.$t("and") + " " + last;
            }
            showAffirmation(this.$t("error.title"), this.$t("promotion.wrong_method").replace("{METHODS}", fullString));
          }
          hideSpinner();
          return;
        }
        // TODO - check if connected
        let promo = new Promotion(result.promotion);
        // Check if there is already a promo code
        if (promo.code) {
          for (let oldPromo of PromotionManager.promotions) {
            if (oldPromo.code) {
              hideSpinner();
              let result = await showConfirmation(this.$t("error.title"), this.$t("promotion.multiple_codes"));
              if (result) {
                PromotionManager.removePromotion(oldPromo);
              } else {
                return;
              }
            }
          }
        }
        let added = PromotionManager.addPromotion(promo);
        if (!added) {
          showAffirmation(this.$t("warning"), this.$t("promotion.already_present"));
          hideSpinner();
          return;
        }
        hideSpinner();
        let text = this.$t("promotion.found_description") + ": <b>" + this.promoCode.toUpperCase() + "</b>.";
        if (promo.shouldAddSimpleItemToCart()) {
          text += "<br><br>" + this.$t("promotion.added_to_cart");
        }
        await showAffirmation(this.$t("promotion.found"), text, this.$t("continue"));
        this.promoCode = "";

        if (promo.items && promo.items.length > 0) {
          let items = this.filterItems(promo.items);
          if (items && items.length === 1) {
            this.selectItem(items[0], promo);
          }
        }
      },

      removePromo(promo) {
        this.PromotionManager.removePromotion(promo);
      },

      filterItems(items) {
        return items.filter(i => {
          return i.getPrice(this.$order.method) > 0 || i.variants.filter(v => v.getPrice(this.$order.method) > 0).length > 0 || i.isHidden();
        });
      }

    }

  }

</script>

<style lang="scss" scoped>

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -10px;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex-grow: 1;
      flex-basis: 0;
      &.flex {
        display: flex;
        align-items: center;
      }
      &:first-of-type {
        margin-right: 10px;
      }
      &:last-of-type {
        margin-left: 10px;
      }
    }
  }

  #iShopFoodApp .x-for-y__subtitle {
    font-weight: bold;
    font-size: 14px;
    color: var(--primary-color) !important;
    margin-bottom: 20px;
  }

  .group {
    display: flex;
    flex-direction: column;
    label {
      font-size: 14px;
      color: #808080;
      padding-left: 9px;
      margin-bottom: 4px;
    }
  }

  .codeDescription {
    margin-bottom: 10px;
  }

  .description {
    margin-bottom: 1em;
  }

  hr {
    border-top: 2px dashed #e6e6e6 !important;
  }

  .titleText {
    font-size: 24px;
    font-weight: bold;
    margin-left: 12px;
    text-transform: uppercase;
  }

  .illustration {
    margin: 0;
  }

  .flex {
    display: flex;
  }
  .flex-grow {
    flex-grow: 1;
  }

  .subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .svgicon {
    padding: 15px;
    margin: -15px;
    box-sizing: content-box !important;
    cursor: pointer;
  }

  .promocode {
    min-width: 100px;
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      padding: 0;
      &:after {
        height: 0;
      }
    }
    #promotions .illustration {
      display: none;
    }
    #promotions .titleText {
      text-align: center;
    }
    #promotions .promocode {
      width: 1px;
    }
  }
</style>

<style lang="scss">
#iShopFoodApp #promotions .panel.promotion-applied {
  & > .content {
    display: none;
  }
}
</style>
