//@ts-check
import Vue from "vue";
import Vuex from "vuex";
import BranchActions from "./store/actions/BranchActions";
import InventoryActions from "./store/actions/InventoryActions";
import LoyaltyActions from "./store/actions/LoyaltyActions";
import RequestActions from "./store/actions/RequestActions";
import UserActions from "./store/actions/UserActions";
import QRPaymentActions from "./store/actions/QRPaymentActions";
import PosActions from "./store/actions/PosActions";
import XposActions from "./store/actions/XposActions";
import { NetworkMonitor } from "./networkMonitor";

Vue.use(Vuex);

let config = (typeof window != "undefined" ? window : global)["CONFIG"] || {};

export default new Vuex.Store({

  state: {
    urlFileServer: config.urlFileServer,
    inventory: null,
    showNavigationCart: false,
    currentBranch: /** @type {Branch} */ (null),
    currentCompany: /** @type {Company} */ (null),
    order: /** @type {Order} */ (null),
    table: /** @type {Table} */ (null),
    employee: /** @type {Employee} */ (null),
    offline: false,
    user: /** @type {User} */ (null),
    screenSize: "xs",
    mobileMenuShown: false,
    mobileCartShown: false,
    version: null,
    widget: false,
    defaultItemImage: null,
    router: null,
    zoom: null,
    currentNewItem: null,
    primaryColor: "#ed2937",
    primaryButtonColor: "#ed2937",
    primaryButtonTextColor: "#fff",
    secondaryButtonTextColor: "#ed2937",
    mobileProductsPerRow: 1,
    skippedOrderType: false,
    promotionModalDisplayed: false,
    quickMode: false,
    quickAdd: false,
    ticketMode: false,
    displayMode: false,
    qrPaymentMode: false,
    giftCardMode: false,
    tableNumber: null,
    paymentInvoices: [],
    initialLoading: false,
    showCategoriesDescription: false,
    userCurrentPosition: null,
    mobileOs: null,
    updateRequired: false,
    updateUrl: null,
    kioskConfiguration: null,
    posConfiguration: null,
    employees: [],
    quantityBuffer: null,
    sale: null,
    externalPosSaleQueue: [],
    currentRevenueCenterRef: null,
    inventorySearchString: "",
    networkMonitor: /** @type {NetworkMonitor} */ (null),
    currentSeat: 1
  },

  getters: {
    branchId: state => {
      return state.currentBranch ? state.currentBranch.id : null;
    },
    companyId: state => {
      return state.currentCompany ? state.currentCompany.id : null;
    },
    getScreenSize: state => {
      return state.screenSize;
    },
    menuRouteNames: () => {
      return ["menu", "menuWithId", "menuWithPromoId", "item", "itemEdit", "crossSell"];
    },
    isMobile: state => {
      return state.screenSize === 'xs';
    },
    isMobileOrTablet: state => {
      return (state.screenSize === 'xs' || state.screenSize === 'sm');
    },
    printQueueSize: () => {

    },
    urlServer: state => {
      if (config.pos && state.posConfiguration) {
        if (state.posConfiguration.getXposUrl()) {
          return state.posConfiguration.getXposUrl();
        }
      }
      return config.urlServer;
    }
  },

  mutations: {
    showNavigationCart(state, show) {
      state.showNavigationCart = show;
      if (state.mobileMenuShown && !show) {
        setTimeout(() => {
          state.mobileMenuShown = false;
        }, 0);
      }
    }
  },

  actions: {
    ...RequestActions,
    ...UserActions,
    ...InventoryActions,
    ...BranchActions,
    ...LoyaltyActions,
    ...QRPaymentActions,
    ...PosActions,
    ...XposActions
  }

});
