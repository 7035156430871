<template>
  <div id="posCalculator" class="calculator__overlay" :class="{'visible': visible}" @click="hide">
    <div class="calculator__container" :class="isLeftHandedMode ? '--left' : '--right'">
      <div class="calculator__input">
        <div class="calculator__title">
          <span class="title">{{ title }}</span>
          <span class="remaining" v-if="expectedAmount">{{ $t('payment.total_remaining') }} : <b>{{ $tc(expectedAmount) }}</b></span>
        </div>
        <div class="calculator__value">
          <div class="currency-symbol" v-if="cashMode">{{ $ts.getters.getSymbol }}</div>
          {{ hiddenValue ? hiddenFormattedValue : formattedValue }}
        </div>
      </div>
      <div class="calculator__buttons">
        <button @click="add('7')" class="calculator__button calculator__button--number" v-wave>7</button>
        <button @click="add('8')" class="calculator__button calculator__button--number" v-wave>8</button>
        <button @click="add('9')" class="calculator__button calculator__button--number" v-wave>9</button>
        <button @click="remove" class="btn calculator__button" v-wave>
          <svgicon src="icons/erase-big.svg" :height="30" :width="40"/>
        </button>

        <button @click="add('4')" class="calculator__button calculator__button--number" v-wave>4</button>
        <button @click="add('5')" class="calculator__button calculator__button--number" v-wave>5</button>
        <button @click="add('6')" class="calculator__button calculator__button--number" v-wave>6</button>
        <button @click="clear" class="calculator__button" v-wave>
          {{ $t("pos.clear") }}
        </button>

        <button @click="add('1')" class="calculator__button calculator__button--number" v-wave>1</button>
        <button @click="add('2')" class="calculator__button calculator__button--number" v-wave>2</button>
        <button @click="add('3')" class="calculator__button calculator__button--number" v-wave>3</button>
        <button @click="enter" v-wave class="calculator__button calculator__button--double-row calculator__button--enter">
          {{ $t("pos.enter") }}
        </button>

        <button @click="add('00')" class="calculator__button" v-wave>00</button>
        <button @click="add('0')" class="calculator__button calculator__button--number calculator__button--double-col" v-wave>0</button>

        <button @click="reverse" class="calculator__button calculator__button--double-col" :class="{disabled: !allowNegativeValues}" v-wave>±</button>
        <button @click="add('0')" class="calculator__button" :class="{disabled: !isFloat || cashMode}" v-wave>%</button>
        <button @click="exit" class="calculator__button" v-wave>
          {{ $t("pos.exit") }}
        </button>
      </div>
      <div class="calculator__actions" v-if="cashMode">
        <button class="action action--primary" v-wave @click="addAmount(denomination)"
                v-for="(denomination, index) of currencyDenominations" :key="index">
          {{ getAmountWithCurrencySymbol(denomination) }}
        </button>
        <button class="action action--secondary" v-wave @click="exactAmount" :class="{disabled: !expectedAmount}">
          {{ $t("pos_cash_actions.exact_amount") }}
        </button>
        <button class="action action--secondary" v-wave @click="roundUpAmount" :class="{disabled: !expectedAmount}">
          {{ $t("pos_cash_actions.round_up_amount") }}
        </button>
        <button class="action action--secondary" v-wave @click="nextAmount(5)" :class="{disabled: !expectedAmount}">
          {{ $t("pos_cash_actions.next") }}
          <br>
          {{ this.getAmountWithCurrencySymbol(5) }}
        </button>
        <button class="action action--secondary" v-wave @click="nextAmount(10)" :class="{disabled: !expectedAmount}">
          {{ $t("pos_cash_actions.next") }}
          <br>
          {{ this.getAmountWithCurrencySymbol(10) }}
        </button>
        <button class="action action--secondary" v-wave @click="nextAmount(20)" :class="{disabled: !expectedAmount}">
          {{ $t("pos_cash_actions.next") }}
          <br>
          {{ this.getAmountWithCurrencySymbol(20) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Constant } from "@/util/Constant";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "PosCalculator",

    data() {
      return {
        value: "",
        visible: false,
        callback: null,
        valueChangedCallback: null,
        title: null,
        parameters: null,
        defaultValue: null,
        hiddenValue: false,
        cashMode: false,
        isFloat: false,
        minValue: null,
        maxValue: null,
        expectedAmount: null,
        currencyDenominations: [5, 10, 20, 50, 100], //TODO should be defined by currency
        maxDigit: 8, //arbitrary, it's what we decide
        allowNegativeValues: false
      };
    },

    mounted() {
      EventBus.$on("show-calculator", (params) => {
        this.value = "";
        this.visible = true;
        this.callback = params.callback;
        this.valueChangedCallback = params.valueChangedCallback;
        this.value = "";
        this.defaultValue = null;
        this.hiddenValue = false;
        this.isFloat = false;
        this.minValue = null;
        this.maxValue = null;
        this.allowNegativeValues = true;
        this.cashMode = false;
        this.expectedAmount = null;

        this.parameters = params.parameters;
        if (this.parameters) {
          this.title = this.parameters.title || "";
          this.defaultValue = this.parameters.defaultValue || "";
          this.isFloat = this.parameters.isFloat || false;
          this.minValue = this.parameters.minValue || null;
          this.maxValue = this.parameters.maxValue || null;
          this.hiddenValue = this.parameters.hiddenValue || false;
          this.allowNegativeValues = typeof this.parameters.allowNegativeValues === "boolean" ? this.parameters.allowNegativeValues : true;
          this.expectedAmount = this.parameters.expectedAmount || null;
          this.cashMode = this.parameters.cashMode || false;
        }
      });
      EventBus.$on("close-calculator", () => { this.visible = false; });
    },

    watch: {
      /**
       * @param {string} newValue
       */
      value(newValue) {
        if (this.valueChangedCallback) {
          this.valueChangedCallback(newValue);
        }
      }
    },

    computed: {
      hiddenFormattedValue() {
        return "*".repeat(this.formattedValue.length);
      },

      formattedValue() {
        if ((this.value.length < 1 || this.value === "-") && this.defaultValue) {
          return this.defaultValue;
        }

        if (this.isFloat) {
          let value = parseFloat(!this.value || this.value === "-" ? "0" : this.value);
          let formatedFloat = (value / 100).toFixed(2).toString();
          if (this.value.startsWith("-") && value === 0) {
            formatedFloat = "-" + formatedFloat;
          }
          return formatedFloat;
        }
        return this.value;
      },

      isLeftHandedMode() {
        return this.$store.state.employee && this.$store.state.employee.preferences.leftHanded;
      }
    },

    methods: {
      add(digit) {
        if (this.value.length < this.maxDigit) {
          this.value += digit;
        }
      },

      getAmountWithCurrencySymbol(amount) {
        return this.$ts.getters.getCurrencySymbolPosition === "before" ? this.$ts.getters.getSymbol + amount : amount + this.$ts.getters.getSymbol;
      },

      remove() {
        if (this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length - 1);
        }
      },

      clear() {
        this.value = "";
      },

      reverse() {
        if (this.value.startsWith("-")) {
          this.value = this.value.replace("-", "");
        } else {
          this.value = "-" + this.value;
        }
      },

      addAmount(amount) {
        let currentValue = parseFloat(this.value || 0);
        currentValue += parseInt(amount * 100);
        this.value = currentValue.toString();
      },

      nextAmount(step) {
        if (this.expectedAmount) {
          this.value = ((Math.ceil(this.expectedAmount / step) * step) * 100).toString();
        }
      },

      exactAmount() {
        if (this.expectedAmount) {
          this.value = (this.expectedAmount * 100).toString();
        }
      },

      roundUpAmount() {
        if (this.expectedAmount) {
          this.value = (Math.ceil(this.expectedAmount) * 100).toString();
        }
      },

      hide(e) {
        if (e.target === this.$el) {
          this.exit();
        }
      },

      enter() {
        if (this.minValue && parseFloat(this.formattedValue || 0) < this.minValue) {
          toast({ message: this.$t("pos.minimum_value").replace("{VALUE}", this.minValue), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
          return;
        }
        if (this.maxValue && parseFloat(this.formattedValue || 0) > this.maxValue) {
          toast({ message: this.$t("pos.maximum_value").replace("{VALUE}", this.maxValue), type: Constant.TOAST_ERROR, title: this.$t("error.title") });
          return;
        }
        this.visible = false;
        if (this.callback) {
          this.callback({ value: parseFloat(this.formattedValue || 0), target: "enter" });
        }
      },

      exit() {
        this.visible = false;
        if (this.callback) {
          this.callback({ value: null, target: "exit" });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>

  $animation-in: 175ms;
  $animation-out: 200ms;
  $element-height: 100px;
  $element-radius: 20px;

  #iShopFoodApp #posCalculator {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    transition: background-color $animation-out linear;
    pointer-events: none;

    &.visible {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color $animation-in ease;
      pointer-events: initial;
      .calculator__container {
        transform: translatex(100%);
        transition: transform $animation-in ease-in-out;
        &.--left, &.--right  {
          transform: translatex(0%);
        }

      }
    }

    .calculator__container {
      width: 860px;
      height: 100%;
      position: absolute;
      top: 0;
      padding: 0 60px;
      background-color: var(--background-color-2);
      border-radius: 40px 0 0 40px;
      transition: transform $animation-out linear;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.--right {
        left: 0;
        border-radius: 0 40px 40px 0;
        transform: translatex(-100%);
      }
      &.--left {
        right: 0;
        border-radius: 40px 0 0 40px;
        transform: translatex(100%);
      }

    }

    .calculator__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 2fr;
      grid-gap: 10px;
    }

    .calculator__title {
      text-align: left;
      color: var(--theme-color-2);
      font-weight: 500;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: -5px;

      .remaining {
        b {
          color: var(--theme-color);
          font-size: 30px;
        }
      }
    }

    .calculator__button {
      font-size: 32px;
      font-weight: bold;
      background-color: var(--background-color-3);
      color: var(--primary-color);
      border: var(--border-color-6) 2px solid;
      border-radius: $element-radius;
      padding: 20px 5px;
      fill: var(--primary-color);
      min-height: $element-height;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        filter: grayscale(1);
        color: var(--theme-color);

      }

      &.calculator__button--number {
        background-color: var(--background-color-5);
        border-color: var(--border-color-4);
        font-size: 40px;
      }
      &.calculator__button--enter {
        background-color: var(--primary-color);
        color: #ffffff;
        border: var(--primary-color) 2px solid;
        grid-row: span 2;
      }
    }

    .calculator__button--double-col {
      grid-column: span 2;
    }

    .calculator__input {
      border-bottom: var(--border-color-4) dashed 2px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      .calculator__label {
        border-radius: 10px;
        padding: 16px 20px;
        background-color: white;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }
      .calculator__value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--background-color-2);
        border: var(--border-color-4) solid 2px;
        font-size: 64px;
        font-weight: bold;
        color: var(--theme-color) !important;
        padding: 13px 20px;
        text-align: center;
        border-radius: $element-radius;
        min-height: $element-height;
        line-height: 1;
        overflow: hidden;
        .currency-symbol {
          color: #d9d9d9;
        }
      }
    }

    .calculator__actions {
      padding-top: 20px;
      border-top: var(--border-color-4) dashed 2px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
    }

    .action {
      font-weight: 700;
      font-family: inherit;
      font-size: 24px;
      color: var(--primary-color);
      height: $element-height;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: $element-radius;
      line-height: 1.2;

      &.disabled {
        opacity: 0.5;
        filter: grayscale(1);
        pointer-events: none;
      }

      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }

      &.action--primary {
        background-color: var(--background-color-5);
        border: var(--border-color-6) 2px solid;
        font-size: 40px;
      }

      &.action--secondary {
        background-color: var(--background-color-5);
        border: var(--border-color-6) 2px solid;
      }
    }
  }

</style>