//@ts-check

import Store from "@/store";

/**
 * @typedef ModifierDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {Boolean} removal
 * @property {{method: string, unitPrice: number}[]} priceSchemes
 * @property {boolean} quantifiable
 * @property {boolean} outOfStock
 * @property {boolean} upsell
 * @property {boolean} taxable
 */

export default class Modifier {

  /**
   * @param {ModifierDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.name = definition.name;
    this.description = definition.description;
    this.removal = definition.removal;
    this.priceSchemes = definition.priceSchemes.map(p => {
      p.unitPrice = parseFloat(String(p.unitPrice));
      return p;
    });
    this.image = /** @type {string} */ (null);
    this.quantity = 0;
    this.quantifiable = definition.quantifiable;
    this.outOfStock = definition.outOfStock;
    this.upsell = definition.upsell;
    this.taxable = definition.taxable;
    this.externalId = definition.externalId;
    this.revenueCenter = definition.revenueCenter;
    this.choices = definition.choices || [];
    this.kitchen = definition.kitchen;
    this.positionIndex = null;
  }

  /**
   * Return the base price of the modifier
   * @param {string} [method]
   * @returns {Number} Base price of the modifier
   */
  getPrice(method) {
    method = method || "takeout";

    if (CONFIG.pos) { /* POS */
      return this.getRevenueCenterPriceForRef(Store.state.currentRevenueCenterRef);
    } else { /* OO/Kiosk */
      let priceScheme = this.priceSchemes.filter(p => p.method == method)[0];
      return priceScheme.unitPrice;
    }
  }

  /**
   * @param {string} ref
   * @returns {number}
   */
  getRevenueCenterPriceForRef(ref) {
    if (ref && this.revenueCenter && this.revenueCenter.prices &&
      this.revenueCenter.prices[ref] && this.revenueCenter.prices[ref].unitPrice) {
      return parseFloat(this.revenueCenter.prices[ref].unitPrice);
    }
    return 0;
  }

  getChoices() {
    let choices = this.choices;
    let method = Store.state.order.method || "takeout";
    return choices.filter(c => c.isAvailableForMethod(method));
  }

  /**
   * @returns {boolean}
   */
  isResaleItem() {
    return this.externalId && this.externalId.toLowerCase().indexOf("r") === 0;
  }

  /**
   * @param {Number} quantity
   */
  setQuantity(quantity) {
    this.quantity = quantity;
    if (this.choices.length === 0) {
      return;
    }
    let choicesQuantity = [];
    //Log how many times each individual choice is present
    for (let choice of this.choices) {
      let quantifiedChoice = choicesQuantity.find(c => c.choice.id === choice.id);
      if (!quantifiedChoice) {
        choicesQuantity.push({
          quantity: 1,
          choice: choice
        });
      } else {
        quantifiedChoice.quantity++;
      }
    }
    let minimumQuantity = quantity || 1; //quantity should not be below 0, we need to keep at least one instance of the choice
    //make sure that the amount of choice matches the modifier quantity
    for (let quantifiedChoice of choicesQuantity) {
      if (quantifiedChoice.quantity < minimumQuantity) {
        //if quantity is below modifier quantity, should add choices until match current quantity
        for (let i = quantifiedChoice.quantity; i < minimumQuantity; i++) {
          let newChoice = quantifiedChoice.choice.clone();
          newChoice.selected = null; //selection of new choice should be empty
          this.choices.push(newChoice);
        }
      } else if (quantifiedChoice.quantity > minimumQuantity) {
        //If quantity is above modifier quantity, should remove last added choice until match current quantity
        for (let i = quantifiedChoice.quantity; i > minimumQuantity; i--) {
          let allChoicesWithId = this.choices.filter(c => c.id === quantifiedChoice.choice.id); //find all instances of this choice in the list
          let lastChoice = allChoicesWithId[allChoicesWithId.length - 1];
          this.choices.splice(this.choices.indexOf(lastChoice), 1); //remove last one added from list
        }
      }
    }
  }

  /**
   * @returns {string}
   */
  getKitchenName() {
    return this.kitchen && this.kitchen.description ? window.translateObject(this.kitchen.description) : "";
  }

  /**
   * @returns {Modifier} Clone of the modifier
   */
  clone() {
    let newModifier = new Modifier(this.definition);
    newModifier.image = this.image;
    newModifier.choices = this.choices.map(c => typeof c.clone === "undefined" ? c : c.clone());
    newModifier.quantity = this.quantity;
    newModifier.positionIndex = this.positionIndex;
    return newModifier;
  }

  toDto() {
    return {
      id: this.id,
      choices: this.choices.map(c => c.toDto()),
      quantity: this.quantity
    };
  }

  fromDto(dto) {
    this.id = dto.id;
    this.quantity = dto.quantity;
    for (let i = 0; i < this.choices.length; i++) {
      this.choices[i].fromDto(dto.choices[i]);
    }
  }

}
