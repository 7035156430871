<template>
  <div id="kiosk-tip">

    <div class="tip__holder">
      <Illustration larger-icon large class="illustration" src="icons/staff.svg" v-if="!kioskConfiguration.isHorizontal() || (!type && kioskConfiguration.isHorizontal())"></Illustration>
      <div class="tip__title">
        {{$t('kiosk_tip.title')}}
      </div>

      <template v-if="!type">
        <div class="tip__description">
          {{$t('kiosk_tip.description')}}
        </div>
        <div class="tip__types">
          <button class="btn btn-primary" @click="setType('percent')">{{$t('kiosk_tip.percent')}}</button>
          <button class="btn btn-primary" @click="setType('fix')">{{$t('kiosk_tip.fix_amount')}}</button>
        </div>
      </template>

      <template v-if="type === 'percent'">
        <div class="tip__description">
          {{$t('kiosk_tip.percent_description')}}
        </div>
        <div class="tip__percents">
          <button class="tip__percent btn btn-primary" @click="addPercentTip(tip)" v-for="tip of tipOptions">{{tip}}%</button>
        </div>
      </template>

      <template v-if="type === 'fix'">
        <div class="tip__description">
          {{$t('kiosk_tip.fix_description')}}
        </div>
        <div class="tip__input">
          <div class="input__dollar-sign">{{$ts.getters.getSymbol}}</div>
          <div class="input__amount">{{tipFixAmount.toFixed(2)}}</div>
        </div>
        <div class="tip__numbers">
          <div class="tip__row" v-for="row of keyboardNumbers">
            <button class="btn btn-primary tip__number" v-for="number of row" @click="addNumber(number)">{{number}}</button>
          </div>
          <div class="tip__row">
            <button class="btn btn-primary tip__number number--large" @click="addNumber(0)">0</button>
            <button class="btn btn-primary tip__number" @click="removeNumber()">
              <svgicon src="icons/erase.svg" :height="30" :width="30"></svgicon>
            </button>
          </div>
        </div>
      </template>

    </div>

    <div class="tip__actions">
      <button class=" btn-back btn btn-secondary" @click="back">
        <svgicon src="icons/back-full.svg" :width="20" :height="20"></svgicon>
        {{$t('back')}}
      </button>
      <button class="btn btn-primary" v-if="type === 'fix'" @click="addTipAmount">{{$t('continue')}}</button>
      <button class="btn btn-secondary" @click="skip" v-if="!type">{{$t('skip')}}</button>
    </div>

  </div>
</template>

<script>
  import Illustration from "@/components/modal/Illustration";

  export default {
    name: "kioskTip",

    components: { Illustration },

    data() {
      return {
        type: null,
        tipFixAmount: 0,
        keyboardNumbers: [[7,8,9],[4,5,6],[1,2,3]]
      }
    },

    mounted() {
      this.setTip("percent", 0);
    },

    computed: {
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      tipOptions() {
        if (!this.kioskConfiguration) {
          return [];
        }
        return this.kioskConfiguration.getTipOptions();
      }
    },

    methods: {
      back() {
        if (this.type) {
          this.type = null;
          return;
        }
        history.back();
      },
      skip() {
        this.$router.push(this.$router.generate("/kiosk/confirmation"));
      },
      setType(type) {
        this.type = type;
      },
      addNumber(number) {
        let stringNumber = (parseInt(this.tipFixAmount * 100)).toString();
        if (stringNumber === "0") {
          stringNumber = "";
        }
        stringNumber += number.toString();
        this.tipFixAmount = parseFloat((parseInt(stringNumber) / 100).toFixed(2));

      },
      setTip(type, amount) {
        this.$order.tip.type = type;
        this.$order.tip.amount = amount;
      },
      addTipAmount() {
        this.setTip("amount", this.tipFixAmount);
        this.next();
      },
      addPercentTip(percent) {
        this.setTip("percent", percent)
        this.next();
      },
      next() {
        this.$router.push(this.$router.generate("/kiosk/confirmation"));
      },
      removeNumber() {
        if (this.tipFixAmount === 0) {
          return;
        }
        let stringNumber = (parseInt(this.tipFixAmount * 100)).toString();
        stringNumber = stringNumber.slice(0, -1);
        if (!stringNumber) {
          stringNumber = "0";
        }
        this.tipFixAmount =  parseFloat((parseInt(stringNumber) / 100).toFixed(2));
      }
    }
  }
</script>

<style lang="scss" scoped>
#kiosk-tip {
  display: flex;
  align-items: center;
  justify-content: center;

  .tip__holder {
    background-color: white;
    padding: 60px;
    border-radius: 20px;
    box-shadow: 0px 5px 10px 0 rgba(1, 2, 2, 0.15);
    width: 600px;

    .tip__types {
      display: flex;
      justify-content: space-between;

      .btn:not(:last-child) {
        margin-right: 60px;
      }
    }
  }

  .tip__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .btn {
      width: 150px;
      font-size: 20px;

      .svgicon {
        fill: white;
      }

      &.number--large {
        width: 315px;
      }
    }
  }

  .tip__percents {
    display: flex;
    flex-wrap: wrap;

    .tip__percent {
      width: 45%;
      margin: 10px 12px;
      font-size: 34px;
      padding: 50px;
      text-align: center;
    }
  }

  .tip__title {
    margin-top: 60px;
    font-size: 36px;
    font-weight: 900;
    color: var(--primary-color);
    text-transform: uppercase;
    padding-bottom: 20px;
    line-height: 1;
    margin-bottom: 20px;
    border-bottom: 1px dashed #e6e6e6;
    text-align: center;
  }

  .tip__input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-radius: 20px;
    border: 2px solid #e6e6e6;
    padding: 25px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;

    .input__amount {

    }
    .input__dollar-sign {
      opacity: 0.5;
      margin-right: 10px;
    }
  }

  .tip__description {
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
  }

  .tip__actions {
    width: 600px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .btn {
      min-width: 180px;
    }

    .btn-back {
      .svgicon {
        margin-right: 15px;
      }
    }
  }
}
</style>
