<template>
  <div id="orderRefundModal" class="modal" :class="{show: show}">
    <div class="modal-content" v-if="order">

      <div class="modal-header">
        <svgicon src="icons/refund.svg" :height="24" :width="24"></svgicon>
        <div class="header__text">{{ $t("refund.title") }}</div>
      </div>

      <div class="modal__inner">

        <PosOrder :order="order" readonly more-info></PosOrder>

        <div class="refund__step" v-if="step === 1">
          <p>{{ $t("refund.select_type") }}</p>
          <div class="refund-types">
            <div v-wave class="refund-type" :class="{selected: type === 'total'}" @click="type = 'total'">
              <svgicon :src="type === 'total' ? 'icons/check-circle-full.svg' : 'icons/circle-empty.svg'"
                       :height="24" :width="24"></svgicon>
              <div class="type__text">{{ $t("refund.type_total") }}</div>
            </div>
            <div class="refund-type disabled" :class="{selected: type === 'item'}">
              <!-- @click="type = 'item'" v-wave -->
              <svgicon :src="type === 'item' ? 'icons/check-circle-full.svg' : 'icons/circle-empty.svg'"
                       :height="24" :width="24"></svgicon>
              <div class="type__text">{{ $t("refund.type_item") }}</div>
            </div>
          </div>
        </div>

        <div class="refund__step" v-if="step === 2">
          <p>{{ $t("refund.provide_justification") }}</p>
          <div class="group">
            <label>{{ $t("refund.justification") }}</label>
            <PosInput v-model="justification" textarea></PosInput>
          </div>
        </div>

        <div class="refund__step" v-if="step === 3">
          <p>{{ $t("refund.confirm") }}</p>
          <div class="refund-infos">
            <div class="refund-info">
              <label>{{ $t("refund.type") }}</label>
              <div class="info__value">{{ $t("refund.type_" + type) }}</div>
            </div>
            <div class="refund-info">
              <label>{{ $t("refund.payment_type") }}</label>
              <div class="info__value">{{ paymentNames }}</div>
            </div>
            <div class="refund-info">
              <label>{{ $t("refund.transaction_amount") }}</label>
              <div class="info__value">{{ $tc(order.getTotal().total) }}</div>
            </div>
            <div class="refund-info" v-if="order.getTotal().tip > 0">
              <label>{{ $t("total.tip") }}</label>
              <div class="info__value">{{ $tc(order.getTotal().tip) }}</div>
            </div>
          </div>
          <div class="refund-info">
            <label>{{ $t("refund.justification") }}</label>
            <div class="info__value">{{ justification }}</div>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button class="btn btn-secondary" v-wave @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{ $t("back") }}
        </button>
        <button class="btn btn-primary" v-wave @click="next" :class="{disabled: step === 2 && !justification}">
          <svgicon :src="step === 3 ? 'icons/check.svg' : 'icons/arrow-line-right.svg'"
                   :height="25" :width="25"></svgicon>
          {{ step === 3 ? $t("confirm") : $t("continue") }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import { IshopPrinter } from "@/lib/printer/IshopPrinter";
  import PosInput from "@/components/pos/PosInput";
  import PosOrder from "@/components/pos/Order";
  import { mapActions } from "vuex";

  export default {
    name: "orderRefundModal",

    components: { PosOrder, PosInput },

    data() {
      return {
        show: false,
        step: 1,
        justification: "",
        type: "total",
        order: null
      };
    },

    computed: {
      paymentNames() {
        return this.order.payments.map(p => this.getPaymentName(p.method)).join(", ");
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      company() {
        return this.$store.state.currentCompany;
      }
    },

    mounted() {
      EventBus.$on("pos-refund-modal", (order) => {
        this.order = order;
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("pos-refund-modal");
    },

    methods: {
      ...mapActions(["refundInvoice"]),

      back() {
        if (this.step > 1) {
          this.step--;
          return;
        }
        this.hide();
      },

      next() {
        if (this.step === 3) {
          this.processRefund();
          return;
        }
        if (this.step === 2 && !this.justification) {
          return;
        }
        this.step++;
      },

      hide() {
        this.step = 1;
        this.show = false;
        this.justification = "";
        this.type = "total";
      },

      async processRefund() {
        let invoiceNumbers = this.order.getActiveInvoiceNumbers(); //Should only be one for now. TBD select which invoice to refund
        showSpinner();
        let response = await this.refundInvoice(invoiceNumbers[0], this.justification);
        if (response.success) {
          this.addInvoiceAndPaymentToOrder(response.data.created);
          await this.printRefundInvoices(response.data.created.invoices);
          if (this.order.hasCashPayment()) {
            this.posConfiguration.openCashDrawer();
          }
          hideSpinner();
          this.hide();
          toast({
            message: this.$t("refund.success"),
            type: "success",
            title: this.$t("success")
          });
        } else {
          hideSpinner();
          toast({
            message: this.$t("refund.error_generic"),
            type: "error",
            title: this.$t("error.title")
          });
        }
      },

      addInvoiceAndPaymentToOrder(data) {
        this.order.invoices = [...this.order.invoices, ...data.invoices];
        this.order.payments = [...this.order.payments, ...data.payments];
        this.order.rawData.invoices = this.order.invoices;
        this.order.rawData.payments = this.order.payments;
      },

      async printRefundInvoices(invoices) {
        for (let invoice of invoices) {
          await this.print(invoice.number);
        }
      },

      getPaymentName(paymentMethod) {
        return this.$t("payment_method." + paymentMethod);
      },

      async print(invoiceNumber) {
        await IshopPrinter.load({
          sale: this.order.getRawData(),
          company: this.company,
          configuration: this.posConfiguration,
          invoiceNumber: invoiceNumber,
          isSuccess: true,
          duplicata: true
        }).printSale();
      }
    }

  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #orderRefundModal.modal {
    z-index: 9;

    .modal-content {
      border-radius: 40px;
      padding: 40px;
      max-width: 580px;

      .modal__inner {
        margin-bottom: 30px;
      }
    }

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }

      .header__text {
        font-weight: 900;
        font-size: 22px;
        color: var(--theme-color);
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed var(--border-color-5);
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }

  .refund__step {
    padding-top: 40px;

    p {
      margin-bottom: 35px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .refund-type {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 20px;
    border: 2px solid var(--border-color-2);
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.disabled {
      pointer-events: none !important;
      filter: grayscale(1);
      opacity: 0.6;
    }

    .svgicon {
      fill: var(--primary-color);
      margin-right: 16px;
    }
  }

  .refund-infos {
    display: flex;
    flex-wrap: wrap;

    .refund-info {
      min-width: 50%;
    }
  }

  .refund-info {
    margin-bottom: 20px;
    min-width: 100%;

    label {
      color: var(--theme-color-2);
      margin-bottom: 4px;
    }

    .info__value {
      font-weight: 600;
      font-size: 20px;
    }
  }

  #iShopFoodApp .group {
    display: flex;
    flex-direction: column;

    label {
      color: var(--theme-color-2);
      font-size: 18px;
      margin-bottom: 4px;
    }

    textarea {
      padding: 16px;
      font-size: 20px;
      border-radius: 12px;
      color: inherit;
      border-color: var(--border-color-5);
      background-color: var(--background-color-5);
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
  }
</style>