<template>
  <div id="login">

    <PageTitle v-if="($route.query.redirect || !isDesktop) && !embed" :show-back-button="!canDisplayFooter">
      <svgicon class="icon" src="icons/lock.svg" :height="20" :width="20"></svgicon>
      {{ $t('login.login') }}
    </PageTitle>

    <div class="hub">

      <div class="left" v-if="!$route.query.redirect"></div>

      <div class="right">
        <div class="page-content">
          <Panel :title="$t('login.login')" icon="icons/lock.svg" @keydown.enter.native="login">
            <ErrorBox :visible="errorVisible" @click="errorVisible = false"></ErrorBox>
            <div class="login__warning" v-if="isQrPayment">
              <svgicon src="icons/info.svg" :height="16" :width="16"></svgicon>
              {{$t('qr_payment.login_tip')}}
            </div>
            <div class="registered" v-if="justRegistered">
              <svgicon src="/icons/question.svg" :height="22" :width="22"></svgicon>
              <span v-html="$t('login.registered')"></span>
            </div>
            <label for="txtUsername">{{$t(loginAccessTranslationKey)}}</label>
            <input id="txtUsername" type="text" v-model="username" :placeholder="$t(loginAccessTranslationKey)"
              :class="{invalid: $validate.error('username')}">
            <label for="txtPassword">{{ $t('login.password') }}</label>
            <input id="txtPassword" type="password" v-model="password" :placeholder="$t('login.password')"
              :class="{invalid: $validate.error('password')}">
            <div class="remember-reset">
              <input id="chkRemember" type="checkbox" v-model="rememberMe" v-if="!isMobile">
              <label for="chkRemember" v-if="!isMobile">{{ $t('login.remember_me') }}</label>
              <a class="reset" href="javascript:void(0);" @click="goToResetPassword">{{ $t('login.forgot_password') }}</a>
            </div>
            <div class="buttons top">
              <button :id="'btnLogin' + (embed ? 'Embed' : '')" type="button" class="btn btn-primary" @click="login">{{ $t('login.login') }}</button>
            </div>
            <div class="buttons">
              <button :id="'btnFacebookLogin' + (embed ? 'Embed' : '')" type="button" class="btn btn-facebook" v-if="!cordova || cordova.platformId != 'ios'" @click="loginFacebook">{{ $t('login.facebook') }}</button>
              <button :id="'btnGuestLogin' + (embed ? 'Embed' : '')" type="button" class="btn btn-secondary" @click="guestLogin" v-if="hasAnonymousLogin">{{ $t('login.as_guest') }}</button>
            </div>
            <div class="register">
              <router-link v-if="!embed" :to="{name: 'register', query: $route.query}">{{ $t('login.register') }}</router-link>
              <a href="javascript:void(0)" v-if="embed" @click="$emit('register')">{{ $t('login.register') }}</a>
            </div>
            <div class="locales" v-if="branch && !branch.isEnglishLocaleOnly()">
              <div class="locale" :class="{selected: $ts.state.locale == 'fr'}" @click="$ts.state.locale = 'fr'">FR</div>
              <div class="separator">-</div>
              <div class="locale" :class="{selected: $ts.state.locale == 'en'}" @click="$ts.state.locale = 'en'">EN</div>
            </div>
            <div class="disclaimer" :class="isMobile ? 'mobile' : 'desktop'">{{ $t('placeholder_login_disclaimer') }}</div>
          </Panel>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import ErrorBox from "@/components/tools/ErrorBox";
  import { mapActions } from "vuex";

  export default {

    name: "login",

    components: { ErrorBox },

    props: {
      embed: Boolean
    },

    data() {
      return {
        username: "",
        password: "",
        rememberMe: false,
        errorVisible: false,
        cordova: typeof cordova !== "undefined" ? cordova : null
      };
    },

    validate: {
      username: { required: true },
      password: { required: true }
    },

    mounted() {
      if (!this.embed) {
        this.$store.commit("showNavigationCart", this.company && !!this.$route.query.redirect);
      }
      if (this.$user.token) {
        this.redirect();
      }
    },

    watch: {
      "username"(newValue) {
        this.username = newValue.replace(/ /g, "");
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      justRegistered() {
        return !!this.$route.query.registered;
      },
      loginAccessTranslationKey() {
        if (this.branch && this.branch.userManagementEnabled) {
          return "login.email_or_username";
        } else {
          return "login.username";
        }
      },
      hasAnonymousLogin() {
        if (!this.branch) {
          return false;
        }
        return this.branch.hasAnonymousLogin();
      },
      isMobile() {
        return typeof cordova !== "undefined";
      },
      canDisplayFooter() {
        return !!(!this.isDesktop && !this.isTutorial && this.$store.state.currentCompany);
      },
      isDesktop() {
        return this.$store.state.screenSize === "md" || this.$store.state.screenSize === "lg";
      },
      isTutorial() {
        return this.$route.name === "tutorial";
      },
      isQrPayment() {
        return this.$store.state.qrPaymentMode;
      }
    },

    methods: {

      ...mapActions({
        loginAction: "login",
        loginGuestAction: "loginGuest",
        facebookLoginAction: "facebookLogin",
        loyaltyBalanceAction: "loyaltyBalance"
      }),

      async login() {
        if (!this.$validate.valid()) {
          this.errorVisible = true;
          return;
        }
        let data = this.$validate.data();
        data.branch = this.$store.state.currentBranch.nameCanonical;
        showSpinner();
        let response = await this.loginAction(data);
        hideSpinner();
        if (response.success) {
          this.$ga.event("login", (this.$store.state.currentCompany || this.$store.state.currentBranch).nameCanonical, this.username);
          if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("Login", { company: (this.$store.state.currentCompany || this.$store.state.currentBranch).nameCanonical });
          }
          this.loadAuthenticatedUser(response);
          this.redirect();
        } else {
          if (response.status === 418) {
            showAffirmation(this.$t("error.title"), this.$t("login.validation_required"));
            return;
          }
          console.log("login error", response);
          showAffirmation(this.$t("error.title"), this.$t("login.bad_login_info"));
        }
      },

      async guestLogin() {
        let data = { branch: this.$store.state.currentBranch.nameCanonical };
        showSpinner();
        let response = await this.loginGuestAction(data);
        hideSpinner();
        if (response.success) {
          this.loadAuthenticatedUser(response, true);
          this.redirect();
        } else {
          showAffirmation(this.$t("error.title"), this.$t("login.bad_login_info"));
        }
      },

      loginFacebook() {

        showSpinner();

        // Desktop
        if (typeof cordova === "undefined") {
          FB.init({
            appId: CONFIG.facebookId,
            autoLogAppEvents: true,
            xfbml: true,
            version: "v3.2"
          });
          FB.getLoginStatus(response => {
            hideSpinner();
            if (response.authResponse) {
              this.processFacebookLogin();
            } else {
              FB.login(response2 => {
                if (response2.authResponse) {
                  this.processFacebookLogin();
                } else {
                  showAffirmation(this.$t("error.title"), this.$t("login.facebook_login_error"));
                }
              }, { scope: "email,public_profile" });
            }
          });
          return;
        }

        // Mobile
        let Facebook = facebookConnectPlugin;
        Facebook.login(["public_profile", "email"], () => {
          Facebook.api("/me?fields=first_name,last_name,email", [], fields => {
            this.facebookLoginAction({ facebookData: fields, branch: this.$store.state.currentBranch.nameCanonical })
              .then(loginResponse => {
                hideSpinner();
                if (loginResponse.success) {
                  this.loadAuthenticatedUser(loginResponse);
                  this.redirect();
                } else {
                  showAffirmation(this.$t("error.title"), this.$t("login.facebook_login_error"));
                }
              })
              .catch(e => {
                hideSpinner();
                console.log("error facebook login action", e);
                showAffirmation(this.$t("error.title"), this.$t("login.facebook_login_error"));
              });
          }, function(message) {
            hideSpinner();
            console.log("ERROR FACEBOOK API");
            console.log(message);
          });
        }, function(message) {
          hideSpinner();
          console.log("ERROR FACEBOOK LOGIN");
          console.log(message);
        });

      },

      processFacebookLogin() {
        FB.api("/me?fields=first_name,last_name,email", response => {
          this.facebookLoginAction({ facebookData: response, branch: this.$store.state.currentBranch.nameCanonical })
            .then((loginResponse) => {
              if (loginResponse.success) {
                this.loadAuthenticatedUser(loginResponse);
                this.redirect();
              } else {
                showAffirmation(this.$t("error.title"), this.$t("login.facebook_login_error"));
              }
            })
            .catch(e => {
              console.log("error facebook login action", e);
              showAffirmation(this.$t("error.title"), this.$t("login.facebook_login_error"));
            });
        });
      },

      loadAuthenticatedUser(response, anonymous = false) {
        setConfiguration("token", response.user.token, (this.rememberMe || this.isMobile) ? localStorage : sessionStorage);
        this.$user.token = response.user.token;
        if (!anonymous) {
          this.$user.load(response.user.user);
        }
        this.$user.anonymous = anonymous;
        if (!anonymous) {
          this.loyaltyBalanceAction().then(loyalty => {
            this.$user.loyalty.load(loyalty.profile);
          });
        }
      },

      goToResetPassword() {
        if (!this.embed) {
          this.$router.push(this.$router.generate("/resetPassword"));
        } else {
          this.$emit("reset");
        }
      },

      redirect() {
        if (this.$user && this.$user.isDispatchUser()) {
          if (this.embed) {
            this.$emit("close");
          }
          this.$router.push("/" + this.$route.params.branch + "/dispatch");
          return;
        }

        if (this.embed) {
          this.$emit("close");
          return;
        }

        let redirect = this.$route.query.redirect;
        let invalidURLs = ["item", "menuWithId"];
        if (invalidURLs.indexOf(redirect) > -1) { // Invalid redirect URL
          redirect = "menu";
        } else if (!redirect) {
          redirect = "orderType";
        }

        this.$router.push(this.$router.generate("/" + redirect));
      }

    }

  };

</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 40px;
    display: flex;
    height: 100%;
  }

  .panel {
    width: 520px;
    max-width: 100%;
    margin: auto !important;
    input {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      padding: 0;

      &:after {
        height: 0;
      }
    }
    .buttons {
      margin-top: 20px;
    }
  }

  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -10px -10px;
    .btn {
      flex-grow: 1;
      margin: 0 10px 10px;
    }
    &.top {
      margin: 0 -10px -10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
    }
  }

  #iShopFoodApp .registered {
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;

    .svgicon {
      margin-right: 10px;
      fill: var(--primary-color);
      margin-left: 5px;
    }
  }

  .remember-reset {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    label {
      margin-top: 0;
      margin-bottom: 0;
    }
    .reset {
      flex-grow: 1;
      text-align: right;
    }
  }

  .register {
    margin-top: 20px;
    text-align: center;
  }

  .title {
    width: 100%;
    text-align: center;
  }

  .error-box {
    margin-bottom: 20px;
  }

  .locales {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    .separator {
      margin: 0 6px;
    }
    .locale {
      cursor: pointer;
      &.selected {
        text-decoration: underline;
      }
    }
  }
  .disclaimer {
    padding-top: 10px;
    font-size: 14px;
  }

  .login__warning {
    margin: 0 0 25px 0;
    padding-left: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    opacity: 0.8;
    .svgicon {
      flex-shrink: 0;
      fill: var(--primary-color);
      margin-right: 10px;
    }
  }
</style>
