<template>
  <div id="posConfiguration">
    <div class="configuration__tabs">
      <Tabs :tabs="tabs" :activeTabIndex="activeTabIndex"></Tabs>
      <div class="tabs__footer">
        <button class="btn btn-primary" @click="save" v-wave :class="{disabled: isOffline}">{{$t('save')}}</button>
      </div>
    </div>
    <div class="configuration__content">

      <Panel icon="icons/branch.svg" v-if="currentPanel === 'authentication'" :title="$t('pos_configuration.authentication_title')">
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.branch_canonical')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.branch_canonical_description')}}</div>
          </div>
          <div class="input">
            <input type="text" :value="posConfiguration.branchCanonical" readonly />
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.company_canonical')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.company_canonical_description')}}</div>
          </div>
          <div class="input">
            <input type="text" :value="posConfiguration.companyCanonical" readonly />
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.number')}}</div>
            <div class="info--description">{{$t('pos_configuration.number_description')}}</div>
          </div>
          <div class="input">
            <input type="text" :value="posConfiguration.number" readonly>
          </div>
        </div>
      </Panel>

      <Panel icon="icons/print-filled.svg" v-if="currentPanel === 'printing'" :title="$t('pos_configuration.printing_title')">
        <div class="configuration--setting setting__switches">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.enable_print')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.enable_print_description')}}</div>
          </div>
          <div class="input">
            <ToggleSwitch v-model="posConfiguration.printer.activated"></ToggleSwitch>
          </div>
        </div>
        <template v-if="posConfiguration.printer.activated">
          <PrinterConfiguration></PrinterConfiguration>
        </template>
      </Panel>

      <Panel icon="icons/print.svg" v-if="currentPanel === 'printing'" :title="$t('pos_configuration.receipt_title')">
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.printer_cols')}}</div>
            <div class="info--description" v-html="$t('kiosk_configuration.printer_cols_description')"></div>
          </div>
          <div class="input">
            <input type="number" min="1" v-model="posConfiguration.printer.cols">
          </div>
        </div>
        <div class="setting__action">
          <button class="btn btn-primary" @click="printTestColumns">
            {{$t('kiosk_configuration.print_test_columns')}}
          </button>
        </div>
      </Panel>

      <Panel icon="icons/print-filled.svg" v-if="currentPanel === 'payment'" :title="$t('pos_configuration.payment_title')">
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.terminal')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.terminal_description')}}</div>
          </div>
          <div class="input">
            <div class="input__radio">
              <Radio v-model="posConfiguration.terminalPayment" :val="false">{{$t('kiosk_configuration.payment.none')}}</Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="posConfiguration.terminalPayment" :val="posConfiguration.PAYFACTO_PAX">{{$t('kiosk_configuration.payment.payfactoPax')}}</Radio>
            </div>
          </div>
        </div>
      </Panel>

      <!-- PayFacto Gateway Pax -->
      <Panel :title="$t('payfactoPax.configuration')"
             v-if="currentPanel === 'payment' && posConfiguration.hasPayfactoPaxPayment() && posConfiguration.terminalData">
        <TipBox danger v-if="!company.hasPaymentMethod('payfacto')" :title="$t('payfactoPax.payfacto_not_configured')">
          <span v-html="$t('payfactoPax.payfacto_not_configured_description')"></span>
        </TipBox>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('payfactoPax.terminal_number')}}</div>
            <div class="info--description">{{$t('payfactoPax.terminal_number_description')}}</div>
          </div>
          <div class="input">
            <input type="text" v-model="posConfiguration.terminalData.terminalNumber">
          </div>
        </div>
      </Panel>

      <!-- Cash Drawer -->
      <Panel :title="$t('pos_configuration.cash_drawer')" v-if="currentPanel === 'payment'">
        <div class="description">{{$t('pos_configuration.cash_drawer_description')}}</div>
        <br>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t("cash_drawer.maximum_amount") }}</div>
            <div class="info--description">{{ $t("cash_drawer.maximum_amount_description") }}</div>
          </div>
          <div class="input">
            <input type="number" v-model="cashDrawerMaximum">
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t("cash_drawer.default_amount") }}</div>
            <div class="info--description">{{ $t("cash_drawer.default_amount_description") }}</div>
          </div>
          <div class="input">
            <input type="number" v-model="cashDrawerDefault">
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.open_cash_drawer')}}</div>
            <div class="info--description">{{$t('pos_configuration.open_cash_drawer_description')}}</div>
          </div>
          <div class="input input--wrap">
            <div class="input__radio">
              <Radio v-model="posConfiguration.cashDrawerCommand" :val="1">{{$t('pos_configuration.option')}} #1</Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="posConfiguration.cashDrawerCommand" :val="2">{{$t('pos_configuration.option')}} #2</Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="posConfiguration.cashDrawerCommand" :val="3">{{$t('pos_configuration.option')}} #3</Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="posConfiguration.cashDrawerCommand" :val="4">{{$t('pos_configuration.option')}} #4</Radio>
            </div>
            <div class="input__radio">
              <Radio v-model="posConfiguration.cashDrawerCommand" :val="5">{{$t('pos_configuration.option')}} #5</Radio>
            </div>
          </div>
        </div>
        <div class="setting__action">
          <button class="btn btn-primary" @click="findCashDrawerCommand" v-wave>
            {{$t('pos_configuration.find_cash_command')}}
          </button>
          <div class="filler"></div>
          <button class="btn btn-primary" @click="openCashDrawer" v-wave>
            {{$t('pos_configuration.open_cash_command')}}
          </button>
        </div>
      </Panel>

      <Panel icon="icons/languages.svg" v-if="currentPanel === 'languages'" :title="$t('pos_configuration.languages_title')">
        <div class="configuration--setting setting__switches">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.locale')}}</div>
            <!--<div class="info--description">{{$t('kiosk_configuration.locale_description')}}</div>-->
          </div>
          <div class="input input__switches">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.locales.en">English</ToggleSwitch>
            </div>
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.locales.fr">Français</ToggleSwitch>
            </div>
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.locales.es" disabled>Español</ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__select" v-if="posConfiguration.hasMultipleLocales()">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.default_locale')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.default_locale_description')}}</div>
          </div>
          <div class="input">
            <select v-model="posConfiguration.locales.default">
              <option value="en" v-if="hasLocale('en')">English</option>
              <option value="es" v-if="hasLocale('es')" disabled>Español</option>
              <option value="fr" v-if="hasLocale('fr')">Français</option>
            </select>
          </div>
        </div>
      </Panel>

      <Panel icon="icons/art-palette.svg" v-if="currentPanel === 'customize'" :title="$t('pos_configuration.customize_title')">
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.display_product_images')}}</div>
            <div class="info--description">{{$t('pos_configuration.display_product_images_description')}}</div>
          </div>
          <div class="input">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.displayProductImages"></ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.display_category_images')}}</div>
            <div class="info--description">{{$t('pos_configuration.display_category_images_description')}}</div>
          </div>
          <div class="input">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.displayCategoryImages"></ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.dark_theme')}}</div>
            <div class="info--description">{{$t('pos_configuration.dark_theme_description')}}</div>
          </div>
          <div class="input">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.darkTheme"></ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.quick_add')}}</div>
            <div class="info--description">{{$t('pos_configuration.quick_add_description')}}</div>
          </div>
          <div class="input">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.quickAdd"></ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.animation')}}</div>
            <div class="info--description">{{$t('pos_configuration.animation_description')}}</div>
          </div>
          <div class="input">
            <div class="input__switch">
              <ToggleSwitch v-model="posConfiguration.animated"></ToggleSwitch>
            </div>
          </div>
        </div>
        <div class="configuration--setting setting__radios">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.primary_color')}}</div>
            <div class="info--description">{{$t('pos_configuration.primary_color_description')}}</div>
          </div>
          <div class="input input__colors">
            <div class="color-swatch" v-for="color of posConfiguration.COLORS" :key="`color-${color}`" :class="{active: posConfiguration.primaryColor === color}" @click="setColor(color)">
              <div class="swatch" :style="{backgroundColor: color}"></div>
            </div>
          </div>
        </div>
      </Panel>

      <Panel icon="icons/print-filled.svg" v-if="currentPanel === 'advanced'" :title="$t('pos_configuration.advanced_title')">
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.minimum_transaction_number')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.minimum_transaction_number_description')}}</div>
          </div>
          <div class="input">
            <input type="number" min="1" v-model="posConfiguration.minimumTransactionNumber">
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('kiosk_configuration.maximum_transaction_number')}}</div>
            <div class="info--description">{{$t('kiosk_configuration.maximum_transaction_number_description')}}</div>
          </div>
          <div class="input">
            <input type="number" min="1" v-model="posConfiguration.maximumTransactionNumber">
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{$t('pos_configuration.printed_queue_limit')}}</div>
            <div class="info--description">{{$t('pos_configuration.printed_queue_limit_description')}}</div>
          </div>
          <div class="input">
            <input type="number" min="1" v-model="posConfiguration.printedSaleQueueLimit">
            </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t("pos.inactivity.delay") }}</div>
            <div class="info--description">{{ $t("pos.inactivity.delay_description") }}</div>
          </div>
          <div class="input">
            <input type="number" min="60" v-model="posConfiguration.timeoutDelay">
          </div>
        </div>
        <div class="configuration--setting setting__input">
          <div class="setting__info">
            <div class="info--title">{{ $t("pos_configuration.workstation_data_refresh_delay") }}</div>
            <div class="info--description">{{ $t("pos_configuration.workstation_data_refresh_delay_description") }}</div>
          </div>
          <div class="input">
            <input type="number" min="1" v-model="posConfiguration.workstationDataRefreshDelay">
          </div>
        </div>
        <div class="configuration--setting" v-if="hasCordova">
          <div class="setting__info">
            <div class="info--title">{{ $t("pos_configuration.log_file") }}</div>
            <div class="info--description">{{ $t("pos_configuration.log_file_description") }}</div>
          </div>
          <div class="setting__action">
            <button class="btn btn-primary" @click="goToLog">{{ $t("pos_configuration.log_file") }}</button>
          </div>
        </div>
      </Panel>

      <Panel icon="icons/release-notes.svg" v-if="currentPanel === 'release'" :title="$t('pos_configuration.release_notes')">
        <div class="subtitle">{{ $t("pos_configuration.release_notes_subtitle") }}</div>
        <ReleaseNotes :appName="posAppName" ></ReleaseNotes>
      </Panel>

    </div>
  </div>
</template>

<script>
  import { CashDrawer } from "@/util/pos/CashDrawer";
  import { Constant } from "@/util/Constant";
  import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
  import EventBus from "@/lib/eventBus";
  import { PermissionManager } from "@/util/PermissionManager";
  import PrintFormatter from "@/lib/printer/printFormatter";
  import PrinterConfiguration from "@/components/pos/PrinterConfiguration";
  import Radio from "@/components/tools/Radio";
  import ReleaseNotes from "@/components/pos/ReleaseNotes";
  import Tabs from "@/components/tools/Tabs";
  import ToggleSwitch from "@/components/tools/ToggleSwitch";
  import { WorkstationDataUpdater } from "@/util/WorkstationDataUpdater";
  import { mapActions } from "vuex";

  export default {
    name: "posConfiguration",

    components: { Tabs, ToggleSwitch, Radio, PrinterConfiguration, ReleaseNotes },

    data() {
      return {
        activeTabIndex: 0,
        currentPanel: "authentication",
        workstationDataRefreshDelayBuffer: null,
        posAppName: "pos",
        cashDrawerMaximum: CashDrawer.maximum,
        cashDrawerDefault: CashDrawer.default,
        tabs: [{
          name: "pos_configuration.authentication",
          action: index => this.setActivePanel(index, "authentication")
        }, {
          name: "pos_configuration.printing",
          action: index => this.setActivePanel(index, "printing"),
          condition: () => PermissionManager.hasPermission(Constant.P_SETTING_EDIT_PRINTER)
        }, {
          name: "pos_configuration.payment",
          action: index => this.setActivePanel(index, "payment"),
          condition: () => PermissionManager.hasPermission(Constant.P_SETTING_EDIT_PAYMENT)
        }, {
          name: "pos_configuration.languages",
          action: index => this.setActivePanel(index, "languages")
        }, {
          name: "pos_configuration.customize",
          action: index => this.setActivePanel(index, "customize")
        }, {
          name: "pos_configuration.advanced",
          action: index => this.setActivePanel(index, "advanced")
        }, {
          name: "pos_configuration.release_notes",
          action: index => this.setActivePanel(index, "release")
        }]
      };
    },

    computed: {
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      printers() {
        return this.getPrintFormatter().getPrinters();
      },
      urlServer() {
        let baseURL = this.$store.getters.urlServer;
        let url = baseURL.replace("https://", "").replace("http://", "");
        return url.split(".")[0];
      },
      isOffline() {
        return this.$store.state.offline;
      },
      hasCordova() {
        return typeof cordova !== "undefined";
      }
    },

    watch: {
      "posConfiguration.terminalPayment"(newValue) {
        switch (newValue) {
          case false:
            this.posConfiguration.terminalData = null;
            break;
          case "payfactoPax":
            this.posConfiguration.terminalData = {
              terminalNumber: ""
            };
            break;
        }
      },
      "posConfiguration.locales": {
        deep: true,
        handler: function() {
          this.setLocale();
        }
      },
      cashDrawerMaximum(newValue, oldValue) {
        let value = parseFloat(newValue || "0");
        if (isNaN(value)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          this.cashDrawerMaximum = oldValue;
          return;
        }
        if (value < 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          this.cashDrawerMaximum = oldValue;
          return;
        }
        CashDrawer.maximum = newValue;
      },
      cashDrawerDefault(newValue, oldValue) {
        let value = parseFloat(newValue || "0");
        if (isNaN(value)) {
          toast({ message: this.$t("pos.readings.invalid_amount"), title: this.$t("warning"), type: "warning" });
          this.cashDrawerDefault = oldValue;
          return;
        }
        if (value < 0) {
          toast({ message: this.$t("pos.readings.amount_greater_zero"), title: this.$t("warning"), type: "warning" });
          this.cashDrawerDefault = oldValue;
          return;
        }
        CashDrawer.default = newValue;
      }
    },

    mounted() {
      this.workstationDataRefreshDelayBuffer = this.posConfiguration.getWorkstationDataRefreshDelayInMinutes();
      CustomerScreenLoader.switchToStandby(this.company?.getPosStandbyImageUrl() || window.DEFAULT_POS_STANDBY_SCREEN_URL);
    },

    methods: {
      ...mapActions(["getBranch"]),

      setActivePanel(tabIndex, panel) {
        this.activeTabIndex = tabIndex;
        this.currentPanel = panel;
      },
      getPrintFormatter() {
        return new PrintFormatter({
          xposUrl: this.posConfiguration.getXposUrl()
        });
      },
      printTestColumns() {
        this.getPrintFormatter().printTestColumns();
      },
      openCashDrawer() {
        this.getPrintFormatter().openDrawer(this.posConfiguration.cashDrawerCommand);
      },
      async findCashDrawerCommand() {
        let initialResponse = await showConfirmation(this.$t("cash_drawer.configuration"), this.$t("cash_drawer.connect"), this.$t("yes"), this.$t("no"), null, null, true);
        if (!initialResponse) {
          showAffirmation(this.$t("cash_drawer.configuration"), this.$t("cash_drawer.connect_false"), this.$t("close"), null, null, null, true);
          return;
        }
        let size = this.getPrintFormatter().getDrawerCommandSize();
        for (let i = 1; i < (size + 1); i++) {
          let response = await this.cashDrawerConfigTry(i);
          if (response) {
            this.cashDrawerConfigSuccess(i);
            return;
          }
        }
        showAffirmation(this.$t("cash_drawer.configuration"), this.$t("cash_drawer.option_failed"), this.$t("close"), null, null, null, true);
      },

      async cashDrawerConfigTry(option) {
        let text = this.$t("cash_drawer.option").replace("{OPTION}", option);
        this.posConfiguration.cashDrawerCommand = option;
        this.openCashDrawer();
        return await showConfirmation(this.$t("cash_drawer.configuration"), text, this.$t("yes"), this.$t("no"), null, null, true);
      },
      cashDrawerConfigSuccess(option) {
        let text = this.$t("cash_drawer.option_description").replace("{OPTION}", option);
        showAffirmation(this.$t("cash_drawer.option_success"), text, this.$t("close"), null, null, null, true);
      },
      hasLocale(locale) {
        let locales = this.posConfiguration.getActivatedLocales();
        return locales.indexOf(locale) > -1;
      },
      setColor(color) {
        this.posConfiguration.setPrimaryColor(color);
        EventBus.$emit("compute-style");
      },
      save() {
        this.setLocale();
        this.posConfiguration.save();
        this.posConfiguration.matchTransactionNumberToConfiguredRange();
        if (this.workstationDataRefreshDelayBuffer !== this.posConfiguration.getWorkstationDataRefreshDelayInMinutes()) {
          WorkstationDataUpdater.resetRefreshInterval();
        }
        toast({
          message: this.$t("pos_configuration.saved"),
          type: "success",
          title: this.$t("success")
        });
      },
      setLocale() {
        this.posConfiguration.validateDefaultLocale();
        let locale = this.posConfiguration.getDefaultLocale();
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },
      quit() {
        this.$router.push(this.$router.generate("/pos/dashboard"));
      },
      goToLog() {
        this.$router.push("/log");
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #posConfiguration {
    padding: 30px;
    display: flex;
    flex-direction: row;

    .configuration__tabs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .btn {
        padding: 15px 20px;
      }
    }

    .configuration__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      border-left: 2px dashed var(--border-color-5);
      padding-top: 30px;
      overflow-y: auto;
      background-color: var(--background-color);

      .panel {
        width: 1000px;
      }
    }
  }

  .tabs__footer {
    display: flex;
    flex-direction: column;

    .btn:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .info--title {
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 18px;
  }

  .setting__action {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    &.split {
      justify-content: space-between;
    }

    .btn {
      font-size: 16px;
      padding: 20px 25px;
    }
  }

  #iShopFoodApp .configuration--setting {
    display: flex;
    align-items: center;

    .setting__info, .input {
      flex-basis: 0;
      flex-grow: 1;
    }

    .setting__info {
      align-self: flex-start;
    }

    .input {
      margin-left: 40px;
      padding: 0;

      &.input--wrap {
        flex-wrap: wrap;

        .input__radio {
          padding-bottom: 15px;
        }
      }
    }

    .input__group {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1em;

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }

      .btn {
        align-self: stretch;
        border: 1px solid #d9d9d9;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        fill: #ccc;
        padding: 8px 14px;
      }
    }

    &:first-child {
      padding-top: 15px;
    }

    &:not(:last-child) {
      padding-bottom: 30px;
      border-bottom: 2px dashed var(--border-color-4);
      margin-bottom: 30px;
    }

    &.locked {
      position: relative;
      pointer-events: none;
      &:after {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
      }
    }

    .input__icon {
      position: relative;

      input {
        padding-right: 60px;
      }

      .icon {
        position: absolute;
        padding: 10px;
        fill: var(--primary-color);
        right: 5px;
        top: 0px;
      }
    }

    &.setting__radios, &.setting__switches {
      .input {
        display: flex;
        justify-content: flex-end;
      }

      .input__radio, .input__switch {
        padding-right: 40px;
      }
    }

    &.setting__switch {
      flex-direction: row;
      align-items: center;

      .setting__info {
        flex-grow: 1;
        padding-right: 60px;
      }
    }
  }

  .setting__endpoint {
    display: flex;
    align-items: center;
  }
  .endpoint--icon {
    margin-right: 10px;
    fill: #888;
  }
  .endpoint--title {
    color: #888;
    padding-right: 8px;
  }
  .endpoint--value {
    color: #888;
    font-weight: bold;
  }

  .valid, .invalid {
         display: flex;
         align-items: center;
         font-weight: bold;

         .svgicon {
           margin-right: 10px;
         }
       }

  .invalid {
    fill: #b41c28;
    color: #b41c28;
    justify-content: flex-end;
  }

  .color-swatch {
    height: 40px;
    width: 40px;
    padding: 4px;
    border-radius: 8px;
    margin: 0 15px;
    cursor: pointer;
    border: 1px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-color: var(--primary-color);
    }

    .swatch {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
  }

  .valid {
    fill: var(--primary-color);
    color: var(--primary-color);

    &.right {
      justify-content: flex-end;
    }
  }

  .filler {
    flex-grow: 1;
  }

  .subtitle {
    color: var(--theme-color-2);
    font-size: 18px;
    padding: 15px 0 15px 5px;
  }
</style>
