//@ts-check
import moment from "moment-timezone";
import Store from "../store";
import Item from "./ItemModel";
import Order from "./OrderModel";
import User from "./UserModel";

/**
 * @typedef PromotionDefinition
 * @property {number} id
 * @property {'sale'|'sale_after_taxes'|'items'|'one_item'} appliedOn
 * @property {'%'|'$'|'percent'|'flat'|'replace'|'hidden_product'} rewardType
 * @property {number} rewardAmount
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {'x_for_y'|'mobile_app'|'with_item'} [triggerType]
 * @property {{x?: number, y?: number, items?: number[], hideReminders?: boolean}} [triggerParameters]
 * @property {string} startAt
 * @property {string} endAt
 * @property {number} [minAmount]
 * @property {string} [code]
 * @property {'once_per_user'|'unlimited'} [usageRestriction]
 * @property {{tags: number[]}} [inclusion]
 * @property {{tags: number[]}} [exclusion]
 * @property {string[]} [disableForMethod]
 * @property {boolean} [taxIncluded]
 * @property {boolean} [mandatory]
 * @property image
 */

export default class Promotion {

  /**
   * @param {PromotionDefinition} [definition]
   */
  constructor(definition) {
    this.id = /** @type {number} */ (null);
    this.applied = true;
    this.appliedOn = /** @type {PromotionDefinition["appliedOn"]} */ (null);
    this.type = /** @type {PromotionDefinition["rewardType"]} */ (null);
    this.value = /** @type {number} */ (null);
    this.name = /** @type {{fr: string, en: string}} */ (null);
    this.description = /** @type {{fr: string, en: string}} */ (null);
    this.triggerType = /** @type {PromotionDefinition["triggerType"]} */ (null);
    this.triggerParameters = /** @type {PromotionDefinition["triggerParameters"]} */ (null);
    this.startAt = /** @type {moment.Moment} */ (null);
    this.endAt = /** @type {moment.Moment} */ (null);
    this.minAmount = /** @type {number} */ (null);
    this.items = /** @type {Item[]} */ ([]);
    this.choiceItems = /** @type {Item[]} */ ([]);
    this.excluded = /** @type {Item[]} */ ([]);
    this.usageRestriction = /** @type {PromotionDefinition['usageRestriction']} */ (null);
    this.code = /** @type {string} */ (null);
    this.schedule = null;
    this.inclusion = null;
    this.exclusion = null;
    this.appliedAmount = 0;
    this.taxIncluded = false;
    this.mandatory = false;
    this.image = null;
    if (definition) {
      this.load(definition);
    }
  }

  /**
   * @param {PromotionDefinition} definition
   */
  load(definition) {
    this.id = definition.id;
    this.appliedOn = definition.appliedOn;
    this.taxIncluded = false;
    if (this.appliedOn == "sale_after_taxes") {
      this.taxIncluded = true;
      this.appliedOn = "sale";
    } else {
      this.taxIncluded = false;
    }
    this.type = definition.rewardType;
    if (this.type === "%") {
      this.type = "percent";
    } else if (this.type === "$") {
      this.type = "flat";
    }
    this.value = definition.rewardAmount;
    this.name = definition.name;
    this.image = definition.image;
    this.schedule = definition.schedule;
    this.description = definition.description;
    this.triggerType = definition.triggerType;
    this.triggerParameters = definition.triggerParameters;
    this.startAt = moment(definition.startAt, "YY-MM-DD HH:mm:ss");
    this.endAt = moment(definition.endAt, "YY-MM-DD HH:mm:ss");
    this.minAmount = definition.minAmount;
    this.inclusion = definition.inclusion;
    this.exclusion = definition.exclusion;
    this.usageRestriction = definition.usageRestriction;
    this.code = definition.code;
    this.applied = !definition.code;
    this.disableForMethod = definition.disableForMethod || [];
    this.items = /** @type {Item[]} */ ([]);
    this.excluded = /** @type {Item[]} */ ([]);
    this.mandatory = definition.mandatory;
    return this;
  }

  loadItems() {
    if (this.inclusion && this.inclusion.tags) {
      let tags = Store.state.inventory.tags.filter(t => this.inclusion.tags.indexOf(t.id) > -1)
      for (let tag of tags) {
        this.items = this.items.concat(tag.items);
        this.choiceItems = this.choiceItems.concat(tag.choiceItems);
      }
    }
    if (this.exclusion && this.exclusion.tags) {
      let tags = Store.state.inventory.tags.filter(t => this.exclusion.tags.indexOf(t.id) > -1)
      for (let tag of tags) {
        this.excluded = this.excluded.concat(tag.items);
      }
    }
    this.items = this.items.filter(i => i.promotionable);
  }

  /**
   * @param {Order} order
   * @param {User} user
   */
  canBeApplied(order, user) {
    if (this.minAmount && order.getItemsTotal({excludedTags: this.exclusion.tags}) < this.minAmount) {
      return false;
    }
    if (this.usageRestriction && this.usageRestriction == "once_per_user" && !user.token) {
      return false;
    }
    return true;
  }

  /**
   * @param date
   * @returns {boolean}
   */
  isDateValid(date = moment()) {
    if (this.startAt && date.isBefore(this.startAt)) {
      return false;
    }
    if (this.endAt && date.isAfter(this.endAt)) {
      return false;
    }
    return true;
  }

  /**
   * @return {boolean}
   */
  shouldAddSimpleItemToCart() {
    return !!this.items && this.items.length === 1 && !this.items[0].isCustomizable();
  }

}
