<template>
  <div id="register">

    <PageTitle v-if="!embed">
      <svgicon class="icon" src="icons/nouveau.svg" :height="20" :width="20"></svgicon>
      {{$t("register.title")}}
    </PageTitle>

    <div class="hub">

      <div class="left" v-if="!$route.query.redirect"></div>

      <div class="right">
        <div class="page-content">

          <Panel :title="$t('register.title')" icon="icons/register.svg" @keyup.enter.native="register" v-if="!completed">
            <ErrorBox :visible="errorVisible" @click="errorVisible = false"></ErrorBox>
            <div class="login__warning" v-if="isQrPayment">
              <svgicon src="icons/info.svg" :height="16" :width="16"></svgicon>
              {{$t('qr_payment.login_tip')}}
            </div>
            <div class="form">
              <label>{{$t("register.email")}}</label>
              <input type="text" v-model="username" :placeholder="$t('register.email')" :class="{'invalid': $validate.error('username')}">
              <label>{{$t("register.password")}}</label>
              <input type="password" v-model="password" placeholder="*****" :class="{'invalid': $validate.error('password')}">
              <label>{{$t("register.password_confirmation")}}</label>
              <input type="password" v-model="passwordConfirmation" placeholder="*****" :class="{'invalid': $validate.error('passwordConfirmation')}">

              <template v-if="branch && branch.getExtendedRegisterField('firstName')">
                <label>{{$t("register.firstName")}}</label>
                <input type="text" v-model="firstName" :placeholder="$t('register.firstName')" :class="{'invalid': $validate.error('firstName')}">
              </template>

              <template v-if="branch && branch.getExtendedRegisterField('lastName')">
                <label>{{$t("register.lastName")}}</label>
                <input type="text" v-model="lastName" :placeholder="$t('register.lastName')" :class="{'invalid': $validate.error('lastName')}">
              </template>

              <template v-if="branch && branch.getExtendedRegisterField('phoneNumber')">
                <label>{{$t("register.phoneNumber")}}</label>
                <input type="text" v-model="phoneNumber" :placeholder="$t('register.phoneNumber')" :class="{'invalid': $validate.error('phoneNumber')}">
              </template>

              <template v-if="branch && branch.getExtendedRegisterField('birthDate')">
                <label>{{$t("register.birthDate")}}</label>
                <div class="date">
                  <svgicon class="history-icon" src="icons/history.svg" :height="16" :width="16"></svgicon>
                  <datetime zone="UTC" v-model="birthDate" :format="dateFormat" :max-datetime="maxDate" :class="{invalid: $validate.error('birthDate')}"
                            :week-start="7"></datetime>
                </div>
              </template>

              <div class="password-requirements">
                <div class="subtitle">{{$t('register.requirements')}}</div>
                <div class="requirement" :class="{valid: passwordIsLongEnough, invalid: password && !passwordIsLongEnough}">
                  <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="password"></svgicon>
                  <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="password"></svgicon>
                  {{$t('register.length_requirement')}}
                </div>
                <div class="requirement" :class="{valid: passwordHasLetters, invalid: password && !passwordHasLetters}">
                  <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="password"></svgicon>
                  <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="password"></svgicon>
                  {{$t('register.letters_requirement')}}
                </div>
                <div class="requirement" :class="{valid: passwordHasNumbers, invalid: password && !passwordHasNumbers}">
                  <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="password"></svgicon>
                  <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="password"></svgicon>
                  {{$t('register.numbers_requirement')}}
                </div>
                <div class="requirement" :class="{valid: passwordHasSpecialChars, invalid: password && !passwordHasSpecialChars}">
                  <svgicon class="icon icon__valid" src="icons/check-circle.svg" :height="15" :width="15" v-if="password"></svgicon>
                  <svgicon class="icon icon__invalid" src="icons/alert.svg" :height="15" :width="15" v-if="password"></svgicon>
                  {{$t('register.chars_requirement')}}
                </div>
              </div>

              <input id="chkTerms" v-model="acceptTerms" type="checkbox" :class="{'invalid': $validate.error('acceptTerms')}">
              <label for="chkTerms">
                {{ $t("register.terms_link_1") }}
                <a href="javascript:void(0)" @click="openTermsOfUse">{{ $t("register.terms_link_2") }}</a>
                {{ $t('register.terms_link_3') }}
                <a href="javascript:void(0)" @click="openPrivacyPolicy">{{ $t("register.terms_link_4") }}</a>
              </label>
            </div>
            <div class="buttons">
              <a href="javascript:void(0)" class="btn btn-secondary" v-if="embed" @click="$emit('back')">{{$t("back")}}</a>
              <router-link :to="{name: 'login', query: $route.query}" class="btn btn-secondary" v-if="!embed">{{$t("back")}}</router-link>
              <button type="button" class="btn btn-primary" @click="register">{{$t("register.create")}}</button>
            </div>
          </Panel>

          <Panel :title="$t('register.modal_title')" icon="icons/register.svg" v-if="completed">
            <div class="complete">
              <Illustration src="icons/loyalty.svg"></Illustration>
              <template v-if="branch.isEmailValidationRequired()">
                  <div class="title">{{ $t("register.check_emails") }}</div>
                <div v-html="$t('register.email_validation_required')"></div>
              </template>
              <template v-else>
                <div class="title">{{ $t("register.complete") }}</div>
                <div v-if="!completedWithLoyalty">{{ $t("register.start_ordering") }}</div>
                <div v-if="completedWithLoyalty">{{ $t("register.start_ordering_loyalty") }}</div>
              </template>
              <button class="btn btn-secondary" @click="exit">{{ $t("continue") }}</button>
            </div>
          </Panel>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import ErrorBox from "@/components/tools/ErrorBox";
  import Illustration from "@/components/modal/Illustration.vue";
  import Panel from "@/components/Panel";
  import { mapActions } from "vuex";
  import moment from "moment-timezone";

  export default {

    name: "register",

    components: { Panel, ErrorBox, Illustration },

    props: {
      embed: Boolean
    },

    data() {
      return {
        username: "",
        password: "",
        passwordConfirmation: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        birthDate: "",
        acceptTerms: false,
        errorVisible: false,
        completed: false,
        completedWithLoyalty: false
      };
    },

    validate: {
      username: {
        required: true,
        email: true
      },
      password: {
        required: true
      },
      passwordConfirmation: {
        required: true
      },
      acceptTerms: {
        required: true
      },
      firstName: {
        required: true,
        condition: function() {
          return this.branch && this.branch.getExtendedRegisterField("firstName") && this.branch.getExtendedRegisterField("firstName").required;
        }
      },
      lastName: {
        required: true,
        condition: function() {
          return this.branch && this.branch.getExtendedRegisterField("lastName") && this.branch.getExtendedRegisterField("lastName").required;
        }
      },
      phoneNumber: {
        required: true,
        phone: true,
        condition: function() {
          return this.branch && this.branch.getExtendedRegisterField("phoneNumber") && this.branch.getExtendedRegisterField("phoneNumber").required;
        }
      },
      birthDate: {
        required: true,
        condition: function() {
          return this.branch && this.branch.getExtendedRegisterField("birthDate") && this.branch.getExtendedRegisterField("birthDate").required;
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      isMobile() {
        return typeof cordova !== "undefined";
      },
      passwordIsLongEnough() {
        return this.password.length >= 8;
      },
      passwordHasLetters() {
        return /[a-zA-Z]/g.test(this.password);
      },
      passwordHasNumbers() {
        return /[0-9]/g.test(this.password);
      },
      passwordHasSpecialChars() {
        return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(this.password);
      },
      passwordMeetsRequirements() {
        return this.passwordIsLongEnough && this.passwordHasLetters && this.passwordHasNumbers && this.passwordHasSpecialChars;
      },
      passwordAndPasswordConfirmationMatch() {
        return this.password === this.passwordConfirmation;
      },
      dateFormat() {
        if (this.$ts.locale === "en") {
          return "MMMM d yyyy";
        }
        return "d MMMM yyyy";
      },
      maxDate() {
        return moment().toISOString();
      },
      isQrPayment() {
        return this.$store.state.qrPaymentMode;
      }
    },

    watch: {
      "username"(newValue) {
        this.username = newValue.replace(/ /g, "");
      }
    },

    mounted() {
      if (!this.embed) {
        this.$store.commit("showNavigationCart", !!this.$route.query.redirect);
      }
    },

    methods: {

      ...mapActions({
        registerAction: "register",
        loyaltyBalanceAction: "loyaltyBalance"
      }),

      async register() {
        if (!this.$validate.valid()) {
          this.errorVisible = true;
          return;
        }

        if (!this.passwordMeetsRequirements) {
          showAffirmation(this.$t("error.title"), this.$t("register.error_requirement"));
          return;
        }

        if (!this.passwordAndPasswordConfirmationMatch) {
          showAffirmation(this.$t("error.title"), this.$t("register.password_does_not_match"));
          return;
        }
        if (!this.acceptTerms) {
          showAffirmation(this.$t("error.title"), this.$t("register.you_must_accept_tac"));
          return;
        }

        let data = {
          branch: this.branch.nameCanonical,
          username: this.username,
          email: this.username,
          password: this.password,
          locale: this.$ts.state.locale
        };
        showSpinner();
        let response = await this.registerAction(data);
        hideSpinner();
        if (response.success) {
          let company = this.$store.state.currentCompany;
          this.$ga.event("register", company ? company.nameCanonical : this.branch.nameCanonical, this.username);
          if (this.$store.state.currentBranch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("Register", { company: company ? company.nameCanonical : this.branch.nameCanonical });
          }
          if (!this.branch.isEmailValidationRequired()) {
            this.$user.load(response.user.user, response.user.token);
            setConfiguration("token", response.user.token, this.isMobile ? localStorage : sessionStorage);

            if (this.branch.extendedProfileFields.length > 0) {
              await this.$user.updateInformation({
                birthDate: this.branch.getExtendedRegisterField("birthDate") ? this.birthDate : undefined,
                firstName: this.branch.getExtendedRegisterField("firstName") ? this.firstName : undefined,
                lastName: this.branch.getExtendedRegisterField("lastName") ? this.lastName : undefined,
                contact: this.branch.getExtendedRegisterField("phoneNumber") ? {
                  phoneNumber: this.phoneNumber
                } : undefined
              });
            }

            if (response.user.user.loyalty.card) {
              this.loyaltyBalanceAction().then(loyalty => {
                this.$user.loyalty.load(loyalty.profile);
              });
              this.completedWithLoyalty = true;
            }
            this.completed = true;
          } else {
            console.log("validation required");
            this.completed = true;
          }

        } else {
          let errorMessage;
          if (!response.error || !response.error.message) {
            errorMessage = this.$t("register.500");
          } else {
            let tmp = response.error.message;
            errorMessage = tmp[this.$ts.state.locale] || tmp[Object.keys(tmp)[0]];
          }
          showAffirmation(this.$t("error.title"), errorMessage);
        }
      },

      exit() {
        if (this.embed) {
          this.$emit(this.branch.isEmailValidationRequired() ? "back" : "close");
          return;
        }
        let redirect = this.branch.isEmailValidationRequired()
          ? this.$router.generate("/login?registered=true&redirect=" + this.$route.query.redirect)
          : this.$router.generate(this.$route.query.redirect + "/menu");
        this.$router.push(redirect);
      },

      openTermsOfUse() {
        openExternalWebsite(this.$t("register.terms_of_use_url"));
      },

      openPrivacyPolicy() {
        openExternalWebsite(this.$t("register.privacy_policy_url"));
      }

    }

  };
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    padding: 40px;
    display: flex;
    height: 100%;
  }

  .panel {
    width: 520px;
    max-width: 100%;
    margin: auto !important;
    input {
      width: 100%;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      padding: 0;

      &:after {
        height: 0;
      }
    }
    .buttons {
      margin-top: 20px;
    }
  }

  label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    margin-left: 10px;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -10px -10px;
    .btn {
      flex-grow: 1;
      white-space: nowrap;
      text-decoration: none;
      margin: 0 10px 10px;
    }
  }

  .error-box {
    margin-bottom: 20px;
  }

  .complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    div {
      text-align: center;
    }
    button {
      margin-top: 20px;
    }
  }

  #iShopFoodApp.xs {
    .panel {
      width: 100%;
    }
    .page-content {
      &:after {
        height: 0;
      }
    }
  }
  .history-icon {
    cursor: text;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  label[for=chkTerms] {
    margin-top: 10px;
    width: 100%;
  }

  #iShopFoodApp .password-requirements {
    font-size: 14px;
    margin-left: 5px;
    .subtitle {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .requirement {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .icon {
        margin-right: 7px;
      }
      &.valid {
        fill: #2a9e45 !important;
        .icon__invalid {
          display: none;
        }
      }
      &.invalid {
        fill: #de9c09 !important;
        .icon__valid {
          display: none;
        }
      }
    }
  }

  .login__warning {
    margin: 0 0 25px 0;
    padding-left: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    opacity: 0.8;
    .svgicon {
      flex-shrink: 0;
      fill: var(--primary-color);
      margin-right: 10px;
    }
  }
</style>

<style lang="scss">
  #register .date {
    position: relative;
    width: 100%;

    input {
      width: 100%;
    }
  }
</style>
