<template>
  <div id="paymentcontact">

    <PageTitle>
      <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
      <template v-if="!$store.getters.isMobileOrTablet">{{$t('payment.submit_order')}}</template>
      <template v-if="$store.getters.isMobileOrTablet">{{$t('payment.general')}}</template>
    </PageTitle>

    <div class="page-content">

      <div class="card" v-if="!$store.getters.isMobileOrTablet">
        <div class="container">
          <div class="title">{{$t('payment.general')}}</div>
          <div class="text">{{$t('payment.complete_order')}}</div>
        </div>
        <Steps></Steps>
      </div>

      <Steps v-if="$store.getters.isMobileOrTablet"></Steps>

      <PanelContactInformations ref="panelContact" :errorBoxContactVisible="errorBoxContactVisible"></PanelContactInformations>

      <PanelComment ref="panelComment" :validate="$validate"></PanelComment>

      <PanelLocation ref="panelLocation" :errorBoxOrderVisible="errorBoxOrderVisible"></PanelLocation>

      <Panel title="Questions" v-if="$order.method == 'catering' && company && company.getQuestions().length > 0">
        <div class="input" v-for="(question, questionIndex) of company.getQuestions()" :key="question.fr">
          <label class="questionTitle">{{ $to(question) }}{{ !question.optional ? " *" : "" }}</label>
          <input class="answer" type="text" v-model="questions[questionIndex]">
        </div>
      </Panel>

      <div class="filler"></div>

      <div class="buttons">
        <button type="button" class="btn back" @click="back">{{$t('back')}}</button>
        <span>&nbsp;</span>
        <button type="button" class="btn next" @click="submit">{{$t('next')}}</button>
      </div>

    </div>

  </div>
</template>

<script>
  import Steps from "@/components/payment/Steps.vue";
  import Address from "@/models/AddressModel";
  import ErrorBox from "@/components/tools/ErrorBox.vue";
  import GeocodingHelper from "@/lib/geocodingHelper";
  import { mapActions } from 'vuex';
  import moment from "moment";
  import PanelContactInformations from "@/components/payment/PanelContactInformations.vue";
  import PanelLocation from "@/components/payment/PanelLocation.vue";
  import PanelComment from "@/components/payment/PanelComment.vue";

  export default {
    name: "paymentContact",

    components: { Steps, ErrorBox, PanelContactInformations, PanelLocation, PanelComment },

    data() {
      return {
        errorBoxContactVisible: false,
        errorBoxOrderVisible: false,
        validAddress: false,
        autocomplete: null,
        questions: []
      }
    },

    validate: {
      "$order.comment": {
        maxLength: 500
      }
    },

    mounted() {
      if (!this.$order.hasItems() || !this.$user.token || !this.company || this.company.isCurrentMethodClosed()) {
        this.$router.push(this.$router.generate("/menu"));
        return;
      }
      if (this.$store.state.quickMode) {
        this.$router.push(this.$router.generate("/paymentConfirmation"));
        return;
      }
      this.method = this.$order.method;
      if (!this.$user) {
        this.$router.push(this.$router.generate("/login?redirect=paymentContact"));
        return;
      }
      this.$store.commit("showNavigationCart", true);
      this.validAddress = !!this.company.isInDeliveryZone(this.$user.getDefaultAddress());
    },

    computed: {
      user() {
        return this.$user;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      inAdvanceEnabled() {
        if (!this.company) {
          return false;
        }
        return this.company.getOpenHours(this.$order.method) && this.company.getOpenHours(this.$order.method).inAdvance;
      }
    },

    watch: {
      "$order.items": function() {
        if (this.$order.items.length == 0) {
          this.$router.push(this.$router.generate("/menu"));
        }
      }
    },

    methods: {
      ...mapActions({
        saveProfileAction: "saveProfile"
      }),

      back() {
        if (this.$store.state.inventory.hasSupplements()) {
          this.$router.push(this.$router.generate('/paymentOptions'));
        } else {
          this.$router.push(this.$router.generate('/menu'));
        }
      },

      async submit() {
        let panelsValid = await this.$refs.panelLocation.submit() && await this.$refs.panelContact.validate();

        if (panelsValid) {
          if (this.company.hasPaymentAfterPickupEnabled() && this.$order.method === "takeout" && typeof this.$order.paymentOnly !== "boolean") {
            showAffirmation(this.$t("payment.missing_payment_only"), this.$t("payment.missing_payment_only_description"));
            let selector = document.getElementById("selectPaymentOnly");
            if (selector) {
              selector.scrollIntoView({ behavior: "smooth" });
            }
            return;
          }

          if (this.$order.method === "delivery" && this.company.isDoorDashActivated() && !await this.$order.validateForDoorDash()) {
            return;
          }

          if (this.$order.method == "catering") {
            for (let i = 0; i < this.company.getQuestions().length; i++) {
              let question = this.company.getQuestions()[i];
              if (!question.optional && !this.questions[i]) {
                showAffirmation(this.$t("error.title"), this.$t("error.questions"));
                return;
              }
            }
            this.$order.questions = this.company.getQuestions().map((q, i) => {
              return {
                Q: this.$to(q),
                A: this.questions[i]
              }
            }).filter(qa => !!qa.A);
          }

          let availableHours = this.$store.state.currentCompany.getAvailableOpenHours().filter(h => this.company.isIntervalAvailable(h));
          if (!this.$order.paymentOnly && this.inAdvanceEnabled && availableHours.indexOf(this.$order.hour) == -1) {
            let response = await showConfirmation(this.$t("error.hour_unavailable"), this.$t("error.hour_unavailable_text") + this.formatHour(availableHours[0]) + "?");
            if (!response) {
              return;
            }
            this.$order.hour = availableHours[0];
          }
        }

        if (!this.$validate.valid() || !panelsValid) {
          this.$validate.validateAllOnce();
          this.$forceUpdate();
          //first panel error box
          if (document.getElementById("errorBoxContact").parentElement.getElementsByClassName("invalid").length > 0) {
            this.errorBoxContactVisible = true;
            scrollToError();
          } else {
            this.errorBoxContactVisible = false;
          }
          //second panel error box
          if (document.getElementById("errorBoxOrder").parentElement.getElementsByClassName("invalid").length > 0) {
            this.errorBoxOrderVisible = true;
            if (!this.errorBoxContactVisible) {
              scrollToError();
            }
          } else {
            this.errorBoxOrderVisible = false;
          }
          return;
        }

        let minimumPrice = this.$store.state.currentCompany.getMinimumOrderPrice(this.$order.method);
        if (this.$order.getOrderTotal().itemsTotal < minimumPrice) {
          showAffirmation(this.$t("payment.minimum"),
            this.$t("payment.minimum_text_1") + this.$tc(minimumPrice.toFixed(2)) + this.$t("payment.minimum_text_2"),
            this.$t('payment.continue_order'));
          return;
        }

        let acceptsFutureDate = await this.company.checkIfOrderDateIsInTheFuture(true);
        if (!acceptsFutureDate) {
          return;
        }

        this.saveProfileAction();

        this.$router.push(this.$router.generate("/paymentConfirmation"));
      },

      //TODO refactor this duplicated function (5x)
      formatHour(hour) {
        if (hour == "asap") {
          return this.$t("order.asap");
        }
        if (this.$store.state.currentCompany.hourFormat == "12") {
          let parsed = moment(hour, "HH:mm");
          if (!parsed.isValid()) {
            return null;
          }
          return parsed.format("hh:mm A");
        }
        return hour;
      }

    }
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    display: flex;
    flex-direction: column;
  }

  .filler {
    flex-grow: 1;
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    background-color: white;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .container {
      flex-grow: 1;
    }

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
    }
    .text {
      color: #808080;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    .left, .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
    }
    .left {
      padding-right: 20px;
    }
  }

  .branch {
    border: 1px solid #d9d9d9;
    padding: 13.5px 10px;
    border-radius: 5px;
  }

  #iShopFoodApp .buttons {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    .btn {
      flex-grow: 1;
      flex-basis: 0;
      padding: 20px;
      font-size: 20px;
    }
    .back {
      background-color: white;
      color: #ed2937;
      margin-right: 20px;
    }
    .next {
      background-color: #ed2937;
      color: white;
      margin-left: 20px;
    }
    .disable {
      opacity: 0.6;
      pointer-events: none;
      cursor: initial;
      &:hover {
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .manual {
    cursor: pointer;
    margin-top: -4px;
  }
</style>

<style lang="scss">

  #iShopFoodApp #paymentcontact .input .vdatetime input {
    width: 100%;
  }

  #iShopFoodApp.xs #paymentcontact {
    .page-content {
      padding: 0;
      .input {
        width: 100%;
      }

      &:after {
        content: none;
      }
    }
    .form {
      .left, .right {
        padding: 0;
        flex-basis: initial;
      }
    }
    .buttons {
      span {
        display: none;
      }
      .back, .next {
        margin: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 1;
      }
    }
  }

</style>
