<template>
  <div id="barcode">
    <div class="fill">
      <div class="description">{{ $t("loyalty.scan_description") }}</div>
      <div class="hr" :style="{'background-color': payColor}"></div>
      <canvas class="barcode"></canvas>
    </div>
    <router-link :to="{name: 'hub'}" class="button" :style="{'color': primaryColor}">{{ $t("back") }}</router-link>
  </div>
</template>

<script>

  import Barcode from "jsbarcode";
  import tinycolor from "tinycolor2";

  export default {

    mounted() {
      if (!this.$user.id) {
        history.back();
        return;
      }
      Barcode(document.getElementsByClassName("barcode")[0], String(this.$user.id), {
        width: 3,
        height: 150,
        displayValue: false
      });
    },

    computed: {
      primaryColor() {
        return this.$store.state.primaryColor || "#ed2937";
      },
      payColor() {
        return tinycolor(this.primaryColor).lighten(25).toHexString();
      }
    }

  }

</script>

<style lang="scss" scoped>

  #iShopFoodApp #barcode {
    background-color: white;
    background-image: none !important;
    display: flex;
    flex-direction: column;
  }

  .fill {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .description {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .hr {
    width: 150px;
    height: 10px;
    margin: 50px 0;
  }

  .button {
    width: 100%;
    text-align: center;
    padding: 15px;
    background-color: #f2f2f2;
    cursor: pointer;
    text-decoration: none;
  }

</style>