<template>
  <div class="slideshow">
    <div class="slide" v-for="(url, urlIndex) of urlsToDisplay" :style="{backgroundImage: 'url(\'' + url +  '\''}" :key="urlIndex"></div>
  </div>
</template>

<script>
  export default {
    name: "slideshow",

    props: {
      urls: Array
    },

    data() {
      return {
        rotationInterval: null,
        SLIDE_KEY: "current-slide",
        rotationDelay: 4400 //ms
      }
    },

    beforeDestroy() {
      clearInterval(this.rotationInterval);
    },

    computed: {
      urlsToDisplay() {
        // Array is reversed to keep them in correct display order because slides are stacked in DOM
        return (this.urls || []).slice().reverse();
      }
    },

    mounted() {
      this.init();
    },

    watch: {
      "urls"() {
        if (this.rotationInterval) {
          clearInterval(this.rotationInterval);
        }
        setConfiguration(this.SLIDE_KEY, null, sessionStorage);
        this.init();
      }
    },

    methods: {
      init() {
        // Needs more than 1 slide to start animation
        if (this.urls && this.urls.length > 1) {
          // STEP 1: Catch previously displayed slide
          let previousSlide = getConfiguration(this.SLIDE_KEY, sessionStorage);
          if (previousSlide) {
            let slides = this.$el.getElementsByClassName("slide");
            for (let slide of Array.from(slides)) {
              // Try to find a match for a slide with identical HTML content
              if (slide.outerHTML === previousSlide) {
                break;
              }
              // Send to the back all slides that did not match
              this.$el.appendChild(slide);
            }
          }

          // STEP 2: Start display interval
          this.rotationInterval = setInterval(() => {
            let slides = this.$el.getElementsByClassName("slide");
            let slide = slides[slides.length - 1]; // Slides are stacked, so the slide on top will be the last slide of the list
            // save current slide to storage, if page changes, slideshow will resume on this slide
            setConfiguration(this.SLIDE_KEY, slide.outerHTML, sessionStorage);
            // Fade out and skip to next slide
            slide.classList.add("fade");
            setTimeout(() => {
              slide.classList.remove("fade");
              this.$el.prepend(slide);
            }, 700);
          }, this.rotationDelay);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .slideshow {
    position: relative;
  }

  .slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 600ms linear;
    opacity: 1;

    &.fade {
      opacity: 0;
    }
  }
</style>
