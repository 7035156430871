<template>
  <div id="styleQuick">

    <Panel :title="$to(category.name)" v-for="category of categories" :key="category.id" :ref="'category-' + category.id" collapsible compact>
      <div class="items">
        <Item v-if="!listMode && item.shouldBeDisplayedAs('item', $order.method)"
              v-for="item of category.items" :item="item" :key="item.id"
              @selected="selectItem(item)"></Item>
        <ListItem v-if="listMode && item.shouldBeDisplayedAs('item', $order.method)"
                  v-for="item of category.items" :item="item" :key="item.id"
                  @selected="selectItem(item)"></ListItem>
      </div>
    </Panel>

  </div>
</template>

<script>

  import ListItem from "@/components/menu/ListItem.vue";
  import Item from "@/components/menu/Item.vue";
  import EventBus from "../../lib/eventBus";

  export default {

    name: "styleQuick",

    components: { Item, ListItem },

    data() {
      return {
        loaded: false
      }
    },

    props: {
      items: Array,
      listMode: Boolean
    },

    mounted() {
      EventBus.$on("open-category", id => {
        let ref = this.$refs["category-" + id][0];
        if (!ref) {
          return;
        }
        ref.open();
        setTimeout(() => {
          ref.$el.getElementsByClassName("title")[0].scrollIntoView({ behavior: "smooth" });
        }, 100);
      });

      EventBus.$on("collapse-panels", () => {
        this.collapsePanels();
      });

      this.$watch("items", () => {
        this.collapsePanels();
      });
    },

    beforeDestroy() {
      EventBus.$off("open-category");
      EventBus.$off("collapse-panels");
    },

    computed: {
      categories() {
        return this.items.filter(c => c.shouldBeDisplayedAs("category", this.$order.method));
      }
    },

    methods: {
      selectItem(item) {
        this.$emit("selectItem", item);
      },
      collapsePanels() {
        setTimeout(() => {
          let toOpen = this.$route.query.category ? "category-" + this.$route.query.category : null;
          for (let i = 0; i < Object.keys(this.$refs).length; i++) {
            let key = Object.keys(this.$refs)[i];
            if (((toOpen && key !== toOpen) || (i > 0 && !toOpen)) && this.$refs[key][0]) {
              this.$refs[key][0].close();
            }
          }
          setTimeout(() => { // :'(
            if (toOpen && this.$refs[toOpen][0]) {
              this.$refs[toOpen][0].$el.scrollIntoView();
            }
            this.loaded = true;
          }, 0);
        }, 0);
      }
    }

  }

</script>

<style lang="scss" scoped>

  .title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .xs .items {
    justify-content: center;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    margin: 0 -20px 10px;
  }

</style>

<style lang="scss">
  #iShopFoodApp #menu #styleQuick {
    .panel {
      transform: translate(0);
      animation: fadein 0.3s ease-out;
      @keyframes fadein {
        0% {
          opacity: 0%;
        }
        100% {
          opacity: 100%;
        }
      }
    }
  }
</style>
