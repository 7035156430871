<template>
  <div ref="modalRoot" class="newmodal" v-show="isVisible" @click="hide">
    <div class="newmodal__body" @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewModal",
    data() {
      return {
        isVisible: false
      };
    },
    mounted() {
      let ref = this.$refs.modalRoot;
      let parent = ref.parentElement;
      if (parent) {
        parent.removeChild(ref);
        document.getElementById("iShopFoodApp").appendChild(ref);
      }
    },
    methods: {
      show() {
        this.isVisible = true;
      },
      hide() {
        this.isVisible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .newmodal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }

  .newmodal__body {
    background-color: var(--background-color-2);
    padding: 40px;
    border-radius: 40px;
    max-height: calc(100% - 95px);
    display: flex;
    flex-direction: column;
  }
</style>