<template>
    <div id="kioskThankYou" @click="redirect" @contextmenu="redirect">

        <div class="thank-you">
            <div class="thank-you--banner" :style="{backgroundImage: 'url(\'' + promoImage +  '\''}"></div>
            <div class="thank-you--content">
                <div class="sale-number">
                    <div class="sale-number--title">{{$t('kiosk_thank_you.order_number')}}</div>
                    <div class="sale-number--number"><span class="hash">#</span>{{saleId}}</div>
                </div>
                <div class="thank-you--description">
                  <template v-if="!ticketMode">
                      <div>{{$t('kiosk_thank_you.preparation')}}</div>
                      <div class="bold">{{$t('kiosk_thank_you.cash')}}</div>
                  </template>
                  <template v-if="ticketMode">
                    <div>{{$t('kiosk_thank_you.ticket.preparation')}}</div>
                    <br>
                    <div class="bold">{{$t('kiosk_thank_you.ticket.qr')}}</div>
                  </template>
                </div>
                <div class="thank-you--thanks">
                  <template v-if="!ticketMode">
                    {{$t('kiosk_thank_you.thank_you')}}
                  </template>
                  <template v-if="ticketMode">
                    {{$t('kiosk_thank_you.ticket.thank_you')}}
                  </template>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
      name: "kioskThankYou",

      data() {
        return {
          timeout: null,
          timeoutDelay: 8 * 1000 // seconds before redirect
        }
      },

      mounted() {
        this.timeout = setTimeout(() => {
          this.redirect();
        }, this.timeoutDelay);
      },

      computed: {
        promoImage() {
          if (this.$order.branding && this.$order.branding.promoImage) {
            return CONFIG.urlFileServer + this.$order.branding.promoImage.url;
          }
          if (this.company && this.company.images && this.company.images.kioskPromoImage) {
            return CONFIG.urlFileServer + this.company.images.kioskPromoImage.url;
          }
          return "";
        },
        ticketMode() {
          return this.$store.state.ticketMode;
        },
        company() {
          return this.$store.state.currentCompany;
        },
        saleId() {
          return getConfiguration("last-sale-id", sessionStorage);
        }
      },

      beforeDestroy() {
        clearTimeout(this.timeout);
      },

      methods: {
        redirect() {
          this.$router.push(this.$router.generate("/kiosk/standby"));
        }
      }
    }
</script>

<style lang="scss" scoped>
    #iShopFoodApp #kioskThankYou {
        display: flex;
        align-items: center;
        justify-content: center;

        .thank-you {
            border-radius: 20px;
            box-shadow: 0px 5px 10px 0 rgba(1, 2, 2, 0.15);
            width: 560px;
            background-color: white;
        }

        .thank-you--banner {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            height: 200px;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .thank-you--content {
            padding: 40px;
            border-radius: 0 0 20px 20px;
        }

        .sale-number {
            border-bottom: 2px dashed #e6e6e6;
            padding-bottom: 30px;
            margin-bottom: 30px;

            .hash {
                color: #bfbfbf;
            }

            .sale-number--title {
                text-align: center;
                color: #808080;
                font-size: 20px;
            }

            .sale-number--number {
                font-size: 88px;
                font-family: "Segoe UI Black", sans-serif;
                font-weight: bold;
                color: #404040;
                text-align: center;
                line-height: 1;
            }
        }

        .thank-you--description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 20px;

            div {
                text-align: center;
                margin: 0 auto;
            }

            .bold {
                font-weight: bold;
            }
        }

        .thank-you--thanks {
            padding-top: 35px;
            font-size: 32px;
            text-transform: uppercase;
            font-family: "Segoe UI Black", sans-serif;
            color: var(--primary-color);
            text-align: center;
            line-height: 1.2;
        }
    }
</style>
