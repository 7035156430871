<template>
  <div id="404">

    <PageTitle>
      {{$t("404.title")}}
    </PageTitle>

    <div class="not-found-content">
      <h1>{{$t("404.title")}}</h1>
      <h3>{{$t("404.description")}}</h3>
    </div>

    <HistoryBack></HistoryBack>

  </div>
</template>

<script>
  export default {
    name: "notFound"
  }
</script>

<style lang="scss" scoped>
  .not-found-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1, h3 {
      text-align: center;
    }
  }
</style>