<template>
  <div id="posPunch">
    <div class="posPunch__container">
      <div class="posPunch__left">
        <Tabs :tabs="tabs" :activeTabIndex="activeTabIndex"></Tabs>
      </div>
      <div class="posPunch__right">

        <!-- Punch in/out -->
        <div class="panel" v-if="activeTabIndex == 0">
          <div class="panel__title">
            <div class="panel__title-icon">
              <svgicon src="icons/punch-clock.svg"></svgicon>
            </div>
            <div class="panel__title-text">
              <span>{{ $t("pos_punch.punch_clock") }}</span>
            </div>
          </div>
          <div class="panel__content">
            <div class="panel__content-title">{{ $t("pos_punch.punch_description") }}</div>
            <div class="panel__content-subtitle">{{ $t("pos_punch.last_punch_entry") }}</div>
            <div class="panel__content-row" v-if="lastEntryStart">
              <div class="row__left">{{ $t("pos_punch.start_time") }}</div>
              <div class="row__right">{{ lastEntryStart }}</div>
            </div>
            <div class="panel__content-row" v-if="lastEntryEnd">
              <div class="row__left">{{ $t("pos_punch.end_time") }}</div>
              <div class="row__right">{{ lastEntryEnd }}</div>
            </div>
            <div class="panel__content-row" v-if="formattedLastEntryDuration">
              <div class="row__left">{{ $t("pos_punch.duration") }}</div>
              <div class="row__right colored">{{ formattedLastEntryDuration }}</div>
            </div>
            <div class="panel__content-separator"></div>
            <div class="panel__content-subtitle">{{ $t("pos_punch.new_punch") }}</div>
            <div class="panel__content-row">
              <div class="row__left">{{ $t("pos_punch.start_time") }}</div>
              <div class="row__right colored">
                <input type="text" :value="formattedPunchStartTime" readonly>
                <button class="button center" v-wave :disabled="isPunchedIn" @click="punchIn">
                  {{ $t("pos_punch.punch_in") }}
                </button>
              </div>
            </div>
            <div class="panel__content-row" v-if="formattedPunchDuration">
              <div class="row__left">{{ $t("pos_punch.duration") }}</div>
              <div class="row__right colored big">{{ formattedPunchDuration }}</div>
            </div>
            <div class="panel__content-row" v-if="isPunchedIn">
              <div class="row__left">{{ $t("pos_punch.end_time") }}</div>
              <div class="row__right colored">
                <input type="text" :value="isPunchedIn ? formattedPunchEndTime : null" readonly>
                <button class="button center" v-wave :disabled="isPunchedOut" @click="punchOut">
                  {{ $t("pos_punch.punch_out") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Punch history -->
        <div class="panel" v-if="activeTabIndex == 1">
          <div class="panel__title">
            <div class="panel__title-icon">
              <svgicon src="icons/punch-clock.svg"></svgicon>
            </div>
            <div class="panel__title-text">
              <span>{{ $t("pos_punch.time_card") }}</span>
            </div>
          </div>
          <div class="panel__content">
            <div class="panel__content-infos">
              <div class="infos__box" v-if="employee">
                <div class="box__title">{{ $t("pos.navigation.employee_name") }}</div>
                <div class="box__content">{{ employee.getFullName() }}</div>
              </div>
              <div class="infos__box">
                <div class="box__title">{{ $t("pos_punch.punch_out") }}</div>
                <div class="box__content">{{ currentWeekEndDate.format("YYYY/MM/DD") }}</div>
              </div>
              <div class="infos__box">
                <div class="box__title">{{ $t("pos_punch.pay_period_date") }}</div>
                <div class="box__content">{{ currentWeekStartDate.format("YYYY/MM/DD") }}</div>
              </div>
            </div>
            <div class="table__container">
              <table class="timetable">
                <tr>
                  <th>{{ $t("day.day") }}</th>
                  <th>{{ $t("skill") }}</th>
                  <th>{{ $t("in") }}</th>
                  <th>{{ $t("out") }}</th>
                  <th>{{ $t("hours") }}</th>
                  <th v-if="editMode"></th>
                </tr>
                <tr v-for="(timeSheet, timeSheetIndex) in nonZeroTimeSheets" :key="`timesheet-${timeSheetIndex}`">
                  <td>{{ translateWeekDay(timeSheet.getStartAsWeekDay()) }}</td>
                  <td>{{ $t("pos_employee.waiter") }}</td>
                  <td class="right">{{ timeSheet.getStartHour() }}</td>
                  <td class="right">{{ timeSheet.getEndHour() }}</td>
                  <td class="right">{{ timeSheet.getTotalHours() }}</td>
                  <td v-if="editMode" @click="editEntry(timeSheet)">
                    <svgicon src="icons/pencil-full.svg"></svgicon>
                  </td>
                </tr>
                <tr v-if="!editMode">
                  <td></td>
                  <td></td>
                  <td class="right"></td>
                  <td class="right">{{ $t("pos_punch.total_hours") }}</td>
                  <td class="right">{{ getTotalTimeSheetHours() }}</td>
                </tr>
              </table>
            </div>
            <div class="content__new-entry" v-if="editMode" @click="newEntry">
              <span class="new-entry__text">{{ $t("pos_punch.new_entry") }}</span>
              <svgicon src="icons/plus.svg" :width="25" :height="25"></svgicon>
            </div>
          </div>
          <div class="panel__footer" v-if="!editMode && false">
            <div class="button" v-wave @click="editMode = true">
              <svgicon src="icons/pencil-full.svg"></svgicon>
              {{ $t("modify") }}
            </div>
            <div class="button disabled" v-wave>
              <svgicon src="icons/print-filled.svg"></svgicon>
              {{ $t("pos.print") }}
            </div>
          </div>
          <div class="panel__footer" v-if="editMode">
            <div class="button primary" v-wave @click="quitEditMode">
              <svgicon src="icons/check.svg" :width="25" :height="25"></svgicon>
              {{ $t("confirm") }}
            </div>
          </div>
        </div>

        <!-- New/Edit punch entry -->
        <div class="panel panel__edit" :class="{ show: shouldDisplayEditPanel }" v-if="shouldDisplayEditPanel">
          <div class="panel__title">
            <div class="panel__title-icon">
              <svgicon src="icons/add-circle.svg" :width="25" :height="25"></svgicon>
            </div>
            <div class="panel__title-text">
              <span v-if="addingEntry">{{ $t("pos_punch.new_punch_entry") }}</span>
              <span v-if="editingEntry">{{ $t("pos_punch.edit_punch_entry") }}</span>
            </div>
          </div>
          <div class="panel__content">
            <div class="content__inputs">
              <div class="content__inputs-group">
                <div class="group__title">{{ $t("skill") }}</div>
                <div class="group__input">
                  <select>
                    <option>{{ $t("pos_employee.waiter") }}</option>
                    <option>{{ $t("pos_employee.cook") }}</option>
                    <option>{{ $t("pos_employee.manager") }}</option>
                  </select>
                </div>
              </div>
              <div class="content__inputs-group">
                <div class="group__title">{{ $t("pos_punch.absence") }}</div>
                <div class="group__input">
                  <select>
                    <option>{{ $t("no") }}</option>
                    <option>{{ $t("yes") }}</option>
                  </select>
                </div>
              </div>
              <div class="content__inputs-group">
                <div class="group__title">{{ $t("pos_employee.tips") }}</div>
                <div class="group__input">
                  <input type="text">
                </div>
              </div>
              <div class="content__inputs-group">
                <div class="group__title">{{ $t("pos_punch.other_paid") }}</div>
                <div class="group__input">
                  <input type="text">
                </div>
              </div>
            </div>
            <div class="content__punches">
              <table class="punches">
                <tr>
                  <th>{{ $t("pos_punch.punch_in") }}</th>
                  <td>{{ $t("time") }}</td>
                  <td>{{ $t("date") }}</td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <datetime type="time" v-model="startTimeBuffer" :format="timeFormat" v-if="currentEntryBuffer"></datetime>
                  </td>
                  <td>
                    <datetime type="date" v-model="startDateBuffer" :max-datetime="currentWeekEndDate.toISOString()"
                      :format="dateFormat" v-if="currentEntryBuffer"></datetime>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("pos_punch.punch_out") }}</th>
                  <td>{{ $t("time") }}</td>
                  <td>{{ $t("date") }}</td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <datetime type="time" v-model="endTimeBuffer" :format="timeFormat" v-if="currentEntryBuffer"></datetime>
                  </td>
                  <td>
                    <datetime type="date" v-model="endDateBuffer" :max-datetime="currentWeekEndDate.toISOString()"
                      :format="dateFormat" v-if="currentEntryBuffer"></datetime>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("pos_punch.total_hours") }}</th>
                  <td class="punches-big">{{ dateTimeBuffersTimeRange }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel__footer">
            <div class="button" v-wave @click="clearEntryEdit">
              <svgicon src="icons/close.svg" :width="25" :height="25"></svgicon>
              {{ $t("cancel") }}
            </div>
            <div class="footer-spacer"></div>
            <div class="button primary" v-wave @click="commitEntryEdit" :class="{ disabled: isEditFormComplete }">
              <svgicon src="icons/check.svg" :width="25" :height="25"></svgicon>
              {{ addingEntry ? $t("pos_punch.add_entry") : $t("pos_punch.confirm_entry") }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { Constant } from "@/util/Constant";
  import Employee from "@/models/pos/EmployeeModel";
  import EventBus from "@/lib/eventBus";
  import Tabs from "@/components/tools/Tabs.vue";
  import TimeSheetEntry from "@/models/pos/TimeSheetEntryModel";
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    name: "posPunch",

    components: { Tabs },

    data() {
      return {
        tabs: [{
          name: this.$t("pos_punch.punch_clock"),
          action: index => this.setActiveTab(index, this.tabs[0])
        }, {
          name: this.$t("pos_punch.time_card"),
          action: index => this.setActiveTab(index, this.tabs[1]),
          callback: () => this.loadEmployeeTimeSheets()
        }],
        activeTabIndex: 0,
        editMode: false,
        addingEntry: false,
        editingEntry: false,
        momentFormat: Constant.API_DATE_FORMAT,
        now: moment(),
        timeSheets: [],
        currentEntryBuffer: null,
        startTimeBuffer: "",
        endTimeBuffer: "",
        startDateBuffer: "",
        endDateBuffer: "",
        timeFormat: "t"
      };
    },

    mounted() {
      if (!this.employee) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
      }
      this.startTimeInterval();
      this.updateEmployeeData();
    },

    watch: {
      startTimeBuffer(newTime, oldTime) {
        if (newTime) {
          this.formatStartDateTimeFieldsToCurrentBufferEntry();
        }
      },
      startDateBuffer(newDate, oldDate) {
        if (newDate) {
          this.formatStartDateTimeFieldsToCurrentBufferEntry();
        }
      },
      endTimeBuffer(newTime, oldTime) {
        if (newTime) {
          this.formatEndDateTimeFieldsToCurrentBufferEntry();
        }
      },
      endDateBuffer(newDate, oldDate) {
        if (newDate) {
          this.formatEndDateTimeFieldsToCurrentBufferEntry();
        }
      }
    },

    computed: {
      employee() {
        return this.$store.state.employee;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      dateFormat() {
        if (this.$ts.locale === "en") {
          return "M '/' d '/' yyyy";
        }
        return "d '/' M '/' yyyy";
      },
      activeEntry() {
        return this.employee?.getActiveEntry();
      },
      formattedLastEntryDuration() {
        return this.employee?.lastEntry
        ? this.getDurationFormatted(this.employee.lastEntry.start, this.employee.lastEntry.end)
        : null;
      },
      formattedPunchStartTime() {
        return this.getHourFormatted(this.activeEntry?.start || this.now);
      },
      formattedPunchEndTime() {
        return this.getHourFormatted(this.activeEntry?.end || this.now);
      },
      lastEntryStart() {
        let start = this.employee?.lastEntry?.start;
        return start ? this.getDateFormatted(start) : null;
      },
      lastEntryEnd() {
        let end = this.employee?.lastEntry?.end;
        return end ? this.getDateFormatted(end) : null;
      },
      formattedPunchDuration() {
        if (!this.activeEntry?.start) {
          return null;
        }
        let start = this.activeEntry.start;
        let end = this.activeEntry.end || this.now;
        return this.getDurationFormatted(start, end, true);
      },
      isPunchedIn() {
        return !!(this.activeEntry?.start && !this.activeEntry?.end);
      },
      isPunchedOut() {
        return !this.isPunchedIn;
      },
      currentWeekStartDate() {
        //TODO week starts monday everywhere but in canada and the us i guess
        return moment().startOf("week");
      },
      currentWeekEndDate() {
        return moment().endOf("week");
      },
      shouldDisplayEditPanel() {
        return this.activeTabIndex === 1 && ((this.editMode && this.editingEntry) || this.addingEntry);
      },
      dateTimeBuffersTimeRange() {
        if (this.currentEntryBuffer && this.currentEntryBuffer.getTotalHours()) {
          return this.currentEntryBuffer.getTotalHours();
        }
        return 0.00;
      },
      isEditFormComplete() {
        return !(this.startTimeBuffer && this.startDateBuffer && this.endTimeBuffer && this.endDateBuffer);
      },
      nonZeroTimeSheets() {
        return this.timeSheets.filter(timeSheet => timeSheet.getTotalHours() > 0);
      }
    },

    methods: {
      ...mapActions(["punchEmployee", "getEmployeeStatus", "getNextAvailableTableNumber", "getEmployeeTimeSheets", "createEmployeeTimeSheet", "editEmployeeTimeSheet"]),

      getDateFormatted(date) {
        return moment(date, this.momentFormat).format("YYYY-MM-DD h:mm A");
      },
      getHourFormatted(date, timezone) {
        if (timezone) {
          return moment(date, this.momentFormat).tz(timezone).format("h:mm:ss A");
        }
        return moment(date, this.momentFormat).format("h:mm:ss A");
      },
      startTimeInterval() {
        this.timeInterval = setInterval(() => {
          this.now = moment();
        }, 1000);
      },
      async updateEmployeeData() {
        let password;
        if (this.employee.getPasswordHash()) {
          password = await this.getPassword();
          if (!password) {
            this.$router.push(this.$router.generate("/pos/dashboard"));
            return;
          }
        }
        let response = await this.getEmployeeStatus(this.getBasePosApiContext());
        if (response.success) {
          this.$store.state.employee = new Employee(response.employee);
          if (password) {
            this.employee.password = password;
          }
        }
      },
      async punchIn() {
        await this.punch();
      },
      async punchOut() {
        await this.punch();
      },
      async punch() {
        let response = await this.punchEmployee(this.getBasePosApiContext());
        if (response.success) {
          this.employee.setLastEntry(response.entry);
        } else {
          this.showError(response);
        }
      },
      getPassword() {
        return new Promise((resolve, reject) => {
          EventBus.$emit("show-calculator", {
            callback: () => {
              resolve(null);
            },
            valueChangedCallback: async (value) => {
              let valid = await this.employee.validatePasswordHash(value);
              if (valid) {
                EventBus.$emit("close-calculator");
                resolve(value);
              }
            },
            parameters: {
              title: "Password",
              isFloat: false,
              allowNegativeValues: false
            }
          });
        });
      },
      showError(response) {
        response = response && response.response ? (response.response || {}) : (response || {});
        toast({
          title: this.$t("error.title"),
          message: this.$to(response.message) || "Server error",
          type: "error"
        });
      },
      getDurationFormatted(dateStart, dateEnd, adjustForTimezone) {
        if (!dateStart || !dateEnd) {
          return null;
        }
        let end = moment(dateEnd, this.momentFormat);
        let start;
        if (adjustForTimezone) {
          start = moment.tz(dateStart, this.momentFormat, this.company.timezone);
        } else {
          start = moment(dateStart, this.momentFormat);
        }
        let seconds = moment.duration(end.diff(start)).asSeconds();
        return secondsToTime(seconds);
      },
      setActiveTab(tabIndex, tab) {
        if (this.activeTabIndex === tabIndex) {
          return;
        }
        this.activeTabIndex = tabIndex;
        if (tab.callback) {
          tab.callback();
        }
      },
      async loadEmployeeTimeSheets() {
        showSpinner();
        let data = {
          employee: this.employee.getId(),
          company: this.company.getId(),
          start: this.currentWeekStartDate.format(this.momentFormat),
          end: this.currentWeekEndDate.format(this.momentFormat)
        };
        let response = await this.getEmployeeTimeSheets(data);
        if (response.success) {
          this.timeSheets = response.timeSheets.map(t => new TimeSheetEntry(t));
        }
        hideSpinner();
      },
      getTotalTimeSheetHours() {
        return Math.round(this.timeSheets.reduce((partialSum, t) => partialSum + t.getTotalHours(), 0) * 100) / 100;
      },
      editEntry(timeSheet) {
        this.currentEntryBuffer = timeSheet.clone();
        this.startTimeBuffer = moment(this.currentEntryBuffer.start, this.momentFormat).toISOString();
        this.startDateBuffer = moment(this.currentEntryBuffer.start, this.momentFormat).toISOString();
        this.endTimeBuffer = moment(this.currentEntryBuffer.end, this.momentFormat).toISOString();
        this.endDateBuffer = moment(this.currentEntryBuffer.end, this.momentFormat).toISOString();
        this.editingEntry = true;
      },
      newEntry() {
        let definition = {
          employee: { id: this.employee.getId() },
          company: this.company.getId(),
          start: "",
          end: ""
        };
        this.currentEntryBuffer = new TimeSheetEntry(definition);
        this.addingEntry = true;
      },
      clearEntryEdit() {
        this.addingEntry = false;
        this.editingEntry = false;
        this.currentEntryBuffer = null;
        this.startTimeBuffer = "";
        this.startDateBuffer = "";
        this.endTimeBuffer = "";
        this.endDateBuffer = "";
      },
      quitEditMode() {
        this.editMode = false;
      },
      formatStartDateTimeFieldsToCurrentBufferEntry() {
        if (!this.startTimeBuffer || !this.startDateBuffer) {
          return;
        }
        let startTime = moment(this.startTimeBuffer, moment.ISO_8601);
        let startDate = moment(this.startDateBuffer, moment.ISO_8601);
        let startDateTime = startDate.format(Constant.API_DATE_ONLY_FORMAT) + " " + startTime.format(Constant.API_TIME_FORMAT);
        this.currentEntryBuffer.setStartDate(startDateTime);
      },
      formatEndDateTimeFieldsToCurrentBufferEntry() {
        if (!this.endTimeBuffer || !this.endDateBuffer) {
          return;
        }
        let endTime = moment(this.endTimeBuffer, moment.ISO_8601);
        let endDate = moment(this.endDateBuffer, moment.ISO_8601);
        let endDateTime = endDate.format(Constant.API_DATE_ONLY_FORMAT) + " " + endTime.format(Constant.API_TIME_FORMAT);
        this.currentEntryBuffer.setEndDate(endDateTime);
      },
      commitEntryEdit() {
        this.formatStartDateTimeFieldsToCurrentBufferEntry();
        this.formatEndDateTimeFieldsToCurrentBufferEntry();

        if (!this.validateCurrentEntry()) {
          return;
        }

        if (this.addingEntry) {
          this.createTimeSheet();
        } else if (this.editingEntry) {
          this.sendTimeSheetEdit();
        }
      },
      async createTimeSheet() {
        let response = await this.createEmployeeTimeSheet(this.currentEntryBuffer.toNewEntryDto());
        if (response.success) {
          toast({
            title: this.$t("success"),
            message: this.$t("pos_punch.edit_sucess"),
            type: Constant.TOAST_SUCCESS
          });
          this.clearEntryEdit();
          this.loadEmployeeTimeSheets();
        }
      },
      async sendTimeSheetEdit() {
        let response = await this.editEmployeeTimeSheet(this.currentEntryBuffer.toEditedEntryDto());
        if (response.success) {
          toast({
            title: this.$t("success"),
            message: this.$t("pos_punch.edit_sucess"),
            type: Constant.TOAST_SUCCESS
          });
          this.clearEntryEdit();
          this.loadEmployeeTimeSheets();
        }
      },
      validateCurrentEntry() {
        // both dates are present and do not overlap
        let validate = this.currentEntryBuffer.getStart() && this.currentEntryBuffer.getEnd() && this.dateTimeBuffersTimeRange >= 0;

        if (!validate) {
          toast({
            title: this.$t("error.title"),
            message: this.$t("pos_punch.submit_error"),
            type: Constant.TOAST_ERROR
          });
        }

        return validate;
      },
      getBasePosApiContext() {
        return {
          company: this.company ? this.company.nameCanonical : null,
          time: moment().format(Constant.API_DATE_ONLY_FORMAT + " " + Constant.API_TIME_NO_SEC_FORMAT)
        };
      },
      translateWeekDay(day) {
        let locale = moment.locale();
        moment.locale("en");
        let momentDay = moment(day, "dddd");
        moment.locale(locale);
        momentDay.locale(locale);
        return this.capitalize(momentDay.format("dddd"));
      },
      capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .posPunch__container {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 30px;
  }

  .posPunch__left {
    border-right: 1px dashed var(--border-color);
    padding-right: 20px;
    margin-right: 20px;
  }

  .posPunch__right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .panel:not(:last-child) {
      margin-right: 20px;
    }
  }

  .panel__edit {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: -10px;
      top: 40%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-right: 10px solid var(--background-color-2);
    }
  }

  .panel__title {
    font-size: 22px;
    font-weight: 900;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color-4);
    padding-bottom: 20px;
    margin-bottom: 30px;

    .panel__title-icon {
      margin-right: 20px;
      fill: var(--primary-color);
    }
  }

  #iShopFoodApp .panel__content {
    .panel__content-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 35px;
      max-width: 700px;
    }

    .panel__content-subtitle {
      font-weight: 900;
      color: var(--primary-color);
      margin-bottom: 30px;
    }

    .panel__content-separator {
      border-bottom: 1px dashed var(--border-color-5);
      margin-bottom: 30px;
    }

    .panel__content-row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .row__left {
        flex-grow: 1;
        font-weight: 500;
      }

      .row__right {
        font-weight: 500;
        font-size: 24px;
        display: flex;
        align-items: center;

        &.colored {
          color: var(--primary-color);
          font-weight: 900;
        }

        &.big {
          font-size: 32px;
          margin-right: 171px;
        }

        input {
          font-size: 32px;
          padding: 7.5px 15px;
          display: inline;
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          width: 262px;
          margin-right: 20px;
          text-align: right;
        }

        .button {
          width: 134px;
        }
      }
    }
  }

  .panel__content-infos {
    border-bottom: 1px dashed var(--border-color-5);
    padding-bottom: 20px;
    display: flex;

    .infos__box {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .box__title {
        font-weight: 500;
        color: #808080;
      }

      .box__content {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .panel__footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px dashed var(--border-color-5);
    padding-top: 20px;
    margin-top: 20px;

    .button:not(:first-child) {
      margin-left: 20px;
    }

    .footer-spacer {
      flex-grow: 1;
    }
  }

  .panel {
    background-color: var(--background-color-2);
    border-radius: 40px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 40px;
    display: flex;
    flex-direction: column;

    .button {
      background-color: var(--background-color-3);
      border: none;
      font-size: 20px;
      color: var(--primary-color);
      padding: 16px 16px;
      border-radius: 12px;
      display: flex;
      fill: var(--primary-color);

      &.primary {
        background-color: var(--primary-color);
        color: white;
        fill: white;
      }

      &.center {
        justify-content: center;
      }

      &:disabled,
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .svgicon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }
  }

  .content__new-entry {
    font-size: 20px;
    color: #bfbfbf;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    display: flex;
    padding: 14.5px 20px;
    fill: var(--primary-color);
    margin-top: 10px;

    .new-entry__text {
      flex-grow: 1;
    }
  }

  .timetable {
    width: 100%;
    border-collapse: collapse;

    th {
      color: #808080;
    }

    tr:nth-child(even) td {
      background-color: var(--background-color);

      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    th {
      padding: 16px 20px;
      text-align: left;
    }

    td {
      font-size: 20px;
      font-weight: 500;
      padding: 16px 20px;

      .svgicon {
        width: 24px;
        height: 24px;
        fill: var(--primary-color);
      }

      &.right {
        text-align: right;
      }
    }
  }

  .content__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;

    .content__inputs-group {
      display: flex;
      flex-direction: column;

      select,
      input {
        width: 100%;
      }

      select::after {
        content: "";
        background-color: red;
        width: 10px;
        height: 10px;
      }

      .group__title {
        font-weight: 500;
        margin-bottom: 5px;
        color: #808080;
      }
    }
  }

  table.punches {
    th {
      text-align: left;
      font-weight: 500;
      color: var(--primary-color);
    }

    td {
      padding-left: 20px;
      font-weight: 500;
      color: #808080;
    }

    input {
      margin-bottom: 15px;
    }

    .punches-big {
      font-size: 24px;
      font-weight: 900;
    }
  }
</style>

<style lang="scss">
  #posPunch .posPunch__right .panel {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .panel__content {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .table__container {
        overflow: auto;
        max-height: 100%;
      }
    }
  }
</style>