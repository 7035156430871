class Constant {

    constructor() {
        /* methods */
        //TBD

        /* States */
        this.STATE_OPEN = "open";
        this.STATE_CLOSED = "closed";
        this.STATE_PRINTED = "printed";

        /* Toast types */
        this.TOAST_ERROR = "error";
        this.TOAST_INFO = "info";
        this.TOAST_SUCCESS = "success";
        this.TOAST_WARNING = "warning";

        /* App Names */
        this.POS_APP_NAME = "ishopv3-pos";
        this.MOBILE_APP_NAME = "ishopv3-app";
        this.KIOSK_APP_NAME = "ishopv3-kiosk";
        this.WEB_APP_NAME = "ishopv3-web";
        this.UBER_APP_NAME = "uber_eats";

        /*External App Names */
        this.UBER_APP_NAME = "uber_eats";

        /*Style Mode */
        this.STYLE_MODE_CLASSIC = "classic";
        this.STYLE_MODE_QUICK = "quick";

        /*Order Method Names */
        this.METHOD_DELIVERY = "delivery";
        this.METHOD_TAKEOUT = "takeout";
        this.METHOD_IN_PLACE = "inPlace";
        this.METHOD_HOTEL = "hotel";
        this.METHOD_CATERING = "catering";
        this.METHOD_DIGITAL = "digital";
        this.METHOD_NONE = "none";

        /*Order Hours*/
        this.ORDER_HOUR_ASAP = "asap";
        this.ORDER_HOUR_DELAY = "delay";

        /*Service Names */
        this.SERVICE_ONLINE = 'online';
        this.SERVICE_KIOSK = 'kiosk';
        this.SERVICE_UNKNOWN = "unknown";
        this.SERVICE_WEB = "web";
        this.SERVICE_MOBILE_APP = "app";
        this.SERVICE_POS = "pos";
        this.SERVICE_UBER = "uber";

        /* Formats */
        this.API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
        this.API_DATE_ONLY_FORMAT = "YYYY-MM-DD";
        this.API_TIME_FORMAT = "HH:mm:ss";
        this.API_TIME_NO_SEC_FORMAT = "HH:mm";

        this.API_LUXON_DATE_ONLY_FORMAT = "yyyy-MM-dd";

        /* POS Values */
        this.DEFAULT_WORKSTATION_DATA_REFRESH_DELAY = 5;

        this.ITEMS_ENTITY = "items";
        this.CATEGORIES_ENTITY = "categories";
        this.INVENTORY_ENTITY = "inventory";
        this.MODIFIERS_ENTITY = "modifiers";
        this.MODIFIER_GROUPS_ENTITY = "modifier_groups";
        this.CHOICE_ITEMS_ENTITY = "choice_items";
        this.CHOICES_ENTITY = "choices";
        this.CHOICE_GROUPS_ENTITY = "choice_groups";
        this.SUPPLEMENTS_ENTITY = "supplements";
        this.IMAGES_ENTITY = "images";
        this.VARIANTS_ENTITY = "variants";
        this.BRANDINGS_ENTITY = "brandings";
        this.ORDER_REQUIREMENTS_ENTITY = "order_requirements";
        this.CONDITIONS_ENTITY = "conditions";
        this.PRINTER_GROUPS_ENTITY = "printer_groups";
        this.POS_STORAGE_DATA_ENTITY = "pos_storage_data";
        this.TAGS_ENTITY = "tags";
        this.INVENTORY_ENTITIES = [this.ITEMS_ENTITY, this.CATEGORIES_ENTITY, this.MODIFIERS_ENTITY, this.MODIFIER_GROUPS_ENTITY, this.CHOICE_ITEMS_ENTITY, this.CHOICES_ENTITY, this.CHOICE_GROUPS_ENTITY, this.SUPPLEMENTS_ENTITY, this.IMAGES_ENTITY, this.VARIANTS_ENTITY, this.BRANDINGS_ENTITY, this.ORDER_REQUIREMENTS_ENTITY, this.CONDITIONS_ENTITY, this.INVENTORY_ENTITY, this.TAGS_ENTITY];

        this.POS_ROLES_ENTITY = "pos_roles";
        this.REVENUE_CENTER_ENTITY = "revenue_centers";
        this.COMPANY_ENTITY = "company";
        this.EMPLOYEES_ENTITY = "employees";

        /* Permissions */
        this.P_DISPATCH = "dispatch";
        this.P_LOGIN_NO_PUNCH = "loginNoPunch";
        this.P_ORDER_SEE_ALL = "order.*.read";
        this.P_ORDER_VOID = "order.*.void";
        this.P_ORDER_EDIT_PRICE = "order.*.price";
        this.P_ORDER_REFUND = "order.*.refund";
        this.P_ORDER_REMARK = "order.*.remark";
        this.P_ORDER_EDIT_DESCRIPTION = "order.*.description";
        this.P_STAT_SEE_EOD = "stat.*.eod.read";
        this.P_STAT_WRITE_EOD = "stat.*.eod.write";
        this.P_SETTING_SEE_ALL = "setting.*.*.read";
        this.P_SETTING_EDIT_THEME = "setting.*.theme.write";
        this.P_SETTING_EDIT_PAYMENT = "setting.*.payment.write";
        this.P_SETTING_EDIT_PRINTER = "setting.*.printer.write";

        this.PERMISSIONS = [this.P_DISPATCH, this.P_LOGIN_NO_PUNCH, this.P_ORDER_SEE_ALL, this.P_ORDER_VOID, this.P_ORDER_EDIT_PRICE, this.P_ORDER_REFUND, this.P_ORDER_REMARK, this.P_ORDER_EDIT_DESCRIPTION, this.P_STAT_SEE_EOD, this.P_STAT_WRITE_EOD,
            this.P_SETTING_SEE_ALL, this.P_SETTING_EDIT_THEME, this.P_SETTING_EDIT_PAYMENT, this.P_SETTING_EDIT_PRINTER];

        /* Functions */
        this.F_EDIT = "item.edit";
        this.F_REMOVE = "item.remove";
        this.F_REFUND = "item.refund";
        this.F_SPLIT = "item.split";
        this.F_REMARK_ADD = "remark.add";
        this.F_PRICE_CHANGE = "price.change";
        this.F_DESCRIPTION_CHANGE = "description.change";
        this.F_RECIPE = "recipe";
        this.F_ALLERGENS = "allergens";
        this.F_SUGGESTIONS = "suggestion";
        this.F_SAVE = "save";
        this.F_QUIT = "quit";
        this.F_QUICK_CLOSE_CASH = "quick_close.cash";
        this.F_QUICK_CLOSE_CARD = "quick_close.card";
        this.F_TRANSFER = "transfer";
        this.F_TRANSFER_TABLE = "transfer.table";
        this.F_STATUS = "status";
        this.F_QUICK_TABLE = "table.quick";

        /* CrossSells Relations */
        this.CROSSSELL_OUT = "->";
        this.CROSSSELL_IN = "<-";
        this.CROSSSELL_BI = "<>";
        this.CROSSSELL_NONE = "--";

        /* Values */
        this.MAX_NUM_CHAR_FOR_RECEIPT = 20;

        /* Kiosk */
        this.AIRXTOUCH_SIZE_55 = 55;
        this.AIRXTOUCH_SIZE_32 = 32;

        /* Payment Processors */
        this.NMI = "nmi";
    }

}

let constant = new Constant();
export { constant as Constant };