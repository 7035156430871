//@ts-check
import Item from "./ItemModel";
import ModifierGroup from "./ModifierGroupModel";
import Tag from "./TagModel";

/**
 * @typedef ChoiceDefinition
 * @property {number} id
 * @property {number} default
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} placeholder
 * @property {Item[]} items
 */

export default class Choice {

  /**
   * @param {ChoiceDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.default = definition.default;
    this.name = definition.placeholder;
    this.placeholder = definition.placeholder;
    this.selected = null;
    this.items = definition.items ? definition.items : [];
    this.modifierGroups = /** @type {ModifierGroup[]} */ ([]);
    this.tags = /** @type {Tag[]} */ ([]);
    this.kitchen = definition.kitchen;
    this.positionIndex = null;
    this.availabilityPerOrderMethod = definition.availabilityPerOrderMethod || {
      takeout: true,
      hotel: true,
      catering: true,
      delivery: true,
      inPlace: true
    };
  }

  isAvailableForMethod(method) {
    if (typeof this.availabilityPerOrderMethod[method] === "undefined") {
      return true;
    }
    return this.availabilityPerOrderMethod[method];
  }

  /**
   * @param {Item} selected
   */
  setSelected(selected) {
    if (!selected) {
      this.selected = null;
      return;
    }
    for (let item of this.items) {
      if (selected.id === item.id) {
        this.selected = item;
        break;
      }
    }
  }

  selectDefault() {
    if (!CONFIG.pos && this.default && this.items.length > 0) {
      this.selected = this.items.filter(i => i.id === this.default && !i.outOfStock)[0] || null;
    } else {
      this.selected = null;
    }
  }

  getModifierGroups() {
    return this.modifierGroups;
  }

  /**
   * @returns {string}
   */
  getKitchenName() {
    return this.kitchen && this.kitchen.description ? window.translateObject(this.kitchen.description) : "";
  }

  /**
   * @returns {Choice} Clone of the modifier
   */
  clone() {
    let newChoice = new Choice(this.definition);
    newChoice.items = this.items.map(i => i.clone());
    newChoice.setSelected(this.selected);
    newChoice.modifierGroups = this.modifierGroups.map(m => m.clone());
    newChoice.positionIndex = this.positionIndex;
    return newChoice;
  }

  toDto() {
    return {
      id: this.id,
      selected: this.selected?.toDto() || null,
      modifierGroups: this.modifierGroups.map(m => m.toDto())
    };
  }

  fromDto(dto) {
    this.selected = this.items.find(i => i.id === dto.selected.id);
    this.selected.fromDto(dto.selected);
    this.modifierGroups = dto.modifierGroups.map(m => {
      return new ModifierGroup(m);
    });
  }

}
