//@ts-check
import Store from "@/store";
import Choice from "./ChoiceModel";

/**
 * @typedef ChoiceGroupDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {number[]} itemChoices
 */

export default class ChoiceGroupModel {

  /**
   * @param {ChoiceGroupDefinition} definition
   */
  constructor(definition) {
    this.definition = definition;
    this.id = definition.id;
    this.name = definition.name;
    this.description = definition.description;
    this.choices = /** @type {Choice[]} */ ([]);
  }

  getChoices() {
    let choices = this.choices;
    let method = Store.state.order.method || "takeout";
    return choices.filter(c => c.isAvailableForMethod(method));
  }

  clone() {
    let newChoiceGroup = new ChoiceGroupModel(this.definition);
    newChoiceGroup.choices = this.choices.map(c => c.clone());
    return newChoiceGroup;
  }

  toDto() {
    return {
      id: this.id,
      choices: this.choices.map(c => c.toDto())
    };
  }

}
