// @ts-check
import Category from "./models/CategoryModel";
import Item from "./models/ItemModel";
import Promotion, { PromotionDefinition } from "./models/PromotionModel";
import moment from "moment";
import Store from "./store";

class PromotionManager {

  constructor() {
    this.promotions = /** @type {Promotion[]} */ ([]);
    this.hasPromoCode = false;
  }

  reset() {
    this.promotions = /** @type {Promotion[]} */ ([]);
    this.hasPromoCode = false;
  }

  /**
   * @returns {Promotion[]}
   */
  getVisiblePromotions() {
    return this.promotions.filter(p => p.items.length > 0 || p.choiceItems.length > 0);
  }

  hasPromotionApplied() {
    for (let promo of this.promotions) {
      if (promo.triggerType != "x_for_y" && promo.appliedAmount > 0 &&
        (promo.appliedAmount > 1 || !Store.state.currentNewItem || Store.state.currentNewItem.appliedPromotion.promotion != promo)) {
        return true;
      }
    }
    return false;
  }

  hasPromotion() {
    return this.hasPromoCode || this.getVisiblePromotions().length > 0;
  }

  /**
   * Load all promotions for the current company
   * @param {boolean?} keepActivePromotions
   */
  async load(keepActivePromotions) {
    if (!keepActivePromotions) {
      this.reset();
    }
    this.inventory = Store.state.inventory;
    this.order = Store.state.order;
    let result = await request("GET", Store.getters.urlServer + "/api/branches/" + Store.state.currentBranch.id + "/companies/" + Store.state.currentCompany.id + "/promotions-info");
    // Keep promo codes in the sale
    let codes = this.promotions.filter(p => !!p.code && !p.usageRestriction.once_per_user);
    this.promotions = /** @type {PromotionDefinition[]} */ (result.visiblePromotions || []).map(r => new Promotion(r));
    this.promotions = this.promotions.concat(codes);
    for (let promotion of this.promotions) {
      promotion.loadItems();
    }
    this.hasPromoCode = result.hasPromoCode;
    this.calculate();
  }

  /**
   * Add a promotion to the manager
   * @param {Promotion} promotion
   */
  addPromotion(promotion) {
    if (this.hasPromotionApplied()) {
      return false;
    }
    if (promotion.code) {
      for (let promo of this.promotions) {
        if (promo.code == promotion.code) {
          return false;
        }
      }
    }
    promotion.loadItems();
    this.promotions.push(promotion);
    this.calculate();
    return true;
  }

  /**
   * @param {number} id
   * @returns {Promotion}
   */
  getPromotionWithId(id) {
    return this.promotions.find(p => p.id === id);
  }

  /**
   * Remove a promotion from the manager
   * @param {Promotion} promotion
   */
  removePromotion(promotion) {
    this.promotions = this.promotions.filter(p => p.id != promotion.id);
    //if (promotion.type == "hidden_product") {
      for (let i = Store.state.order.items.length - 1; i >= 0; i--) {
        let item = Store.state.order.items[i];
        let removeHidden = item.isHidden() && promotion.items.map(i => i.id).indexOf(item.id) > -1;
        if (removeHidden || (item.appliedPromotion && item.appliedPromotion.promotion && item.appliedPromotion.promotion.id == promotion.id)) {
          Store.state.order.removeItem(i);
        }
      }
    //}
    this.calculate();
  }

  /**
   * Calculate all promotions on the inventory, cart items and the whole sale
   */
  calculate() {
    if (!this.inventory) {
      return;
    }

    this._resetPromotions();

    let oneApplied = false;

    // Cart items promotions
    for (let promo of this.promotions.filter(p => p.appliedOn == "items" || p.appliedOn == "one_item")) {

      if (promo.minAmount > 0 && this.order.getItemsTotal({ excludedTags: promo.exclusion.tags }) < promo.minAmount) {
        this.removePromotion(promo);
        continue;
      }

      if (promo.disableForMethod.indexOf(Store.state.order.method) > -1) {
        continue;
      }

      if (!this.isPromotionWithinSchedule(promo)) {
        continue;
      }

      if (oneApplied && promo.triggerType !== "x_for_y") {
        continue;
      }

      let itemsToPass = Store.state.order.items;
      if (Store.state.currentNewItem) {
        itemsToPass = itemsToPass.concat(Store.state.currentNewItem);
      }
      let length = itemsToPass.length;
      for (let i = 0; i < length; i++) {
        for (let choice of itemsToPass[i].getChoices()) {
          itemsToPass = itemsToPass.concat(choice.selected);
        }
        for (let choiceGroup of itemsToPass[i].getChoiceGroups()) {
          for (let choice of choiceGroup.getChoices()) {
            itemsToPass = itemsToPass.concat(choice.selected);
          }
        }
      }
      itemsToPass = itemsToPass.filter(i => !!i);
      for (let item of /** @type {Item[]} */ itemsToPass) {

        if (item.isResaleItem()) {
          continue;
        }

        /*if (item.isHidden() && promo.items.map(i => i.id).indexOf(item.getId()) > -1) {
          item.appliedPromotion.promotion = promo;
          item.appliedPromotion.value = 0;
          continue;
        }*/

        if ((promo.items.map(i => i.id).indexOf(item.getId()) == -1 && promo.items.map(i => i.id).indexOf(item.id) == -1 &&
        promo.choiceItems.map(i => i.id).indexOf(item.getId()) == -1 && promo.choiceItems.map(i => i.id).indexOf(item.id) == -1) ||
        !promo.canBeApplied(Store.state.order, Store.state.user)) {
          continue;
        }

        // Check condition
        let applyQuantity = 1;
        switch (promo.triggerType) {
          case "mobile_app":
            if (typeof cordova === "undefined") {
              continue;
            }
            break;
          case "x_for_y":
            applyQuantity = this._checkXY(promo, item);
            if (applyQuantity == 0) {
              // Remove item's previously applied promotion
              item.appliedPromotion.promotion = null;
              item.appliedPromotion.value = 0;
              continue;
            } else if (promo.appliedOn === "one_item") {
              applyQuantity = 1;
            }
            break;
          case "with_item":
            if (promo.type === "hidden_product" && !this._saleHasItem(promo)) {
              this._removeHiddenProductFromCart(promo);
              continue;
            } else if (this._saleHasItem(promo) <= promo.appliedAmount) {
              continue;
            }
            break;
        }

        if (promo.appliedOn == "one_item") {
          applyQuantity = Math.max(applyQuantity - promo.appliedAmount, 0);
        }
        if (applyQuantity == 0) {
          continue;
        }

        // Apply
        promo.appliedAmount += applyQuantity;
        item.appliedPromotion.promotion = promo;
        if (promo.type == "percent") {
          item.appliedPromotion.value = item.getPrice() * (promo.value / 100);
        } else if (promo.type == "flat") {
          item.appliedPromotion.value = promo.value;
        } else if (promo.type == "replace") {
          item.appliedPromotion.value = item.getPrice() - promo.value;
        }
        item.appliedPromotion.value *= applyQuantity || item.quantity;
        if (promo.triggerType !== "x_for_y") {
          oneApplied = true;
        }

      }

    }

    // Sale promotions
    for (let promo of /** @type {Promotion[]} */ (this.promotions.filter(p => p.appliedOn == "sale"))) {

      if (promo.disableForMethod.indexOf(Store.state.order.method) > -1 || !promo.canBeApplied(Store.state.order, Store.state.user) ||
        (promo.triggerType == "mobile_app" && typeof cordova === "undefined")) {
        continue;
      }

      if (promo.triggerType == "with_item") {
        if (!this._saleHasItem(promo)) {
          continue;
        }
      }

      if (oneApplied) {
        continue;
      }

      // Items
      let total = 0;
      for (let i = Store.state.order.items.length - 1; i >= 0; i--) {
        let item = Store.state.order.items[i];
        let toAdd = true;
        if (item.isResaleItem()) {
          continue;
        }
        for (let excluded of promo.excluded) {
          if (excluded.id == item.id || excluded.id == item.getId()) {
            toAdd = false;
            break;
          }
        }
        if (toAdd) {
          total += item.getTotalPrice(Store.state.order.method).subTotal;
        }
      }
      let reduction = 0;
      if (promo.taxIncluded) {
        let info = Store.state.order.getOrderTotal();
        let amountApplicable = info.totalRemaining - info.totalNotApplicableForLoyaltyOrGiftCard;
        reduction = Math.min(amountApplicable, promo.value)
      } else if (promo.type == "percent") {
        reduction = total * (promo.value / 100);
      } else if (promo.type == "flat") {
        reduction = promo.value > total ? total : promo.value;
      }
      Store.state.order.appliedPromotion.promotion = promo;
      Store.state.order.appliedPromotion.value = reduction;
    }

    this._calculateInventoryRoot();

  }

  /**
   * @private
   * @param {Category[]|Item[]} root
   */
  _calculateInventoryRoot(root = this.inventory.getRoot()) {

    // Inventory items promotions
    for (let item of root) {

      if (item instanceof Category) {
        this._calculateInventoryRoot(item.items);
        continue;
      }
      if (item.variants.length > 0) {
        this._calculateInventoryRoot(item.variants);
      }
      for (let choice of item.getChoices()) {
        this._calculateInventoryRoot(choice.items);
      }
      for (let choiceGroup of item.getChoiceGroups()) {
        for (let choice of choiceGroup.getChoices()) {
          this._calculateInventoryRoot(choice.items);
        }
      }

      for (let promo of this.promotions.filter(p => p.appliedOn == "items" || p.appliedOn == "one_item")) {

        if (promo.disableForMethod.indexOf(Store.state.order.method) > -1) {
          continue;
        }

        if ((promo.items.map(i => i.id).indexOf(item.getId()) == -1 && promo.items.map(i => i.id).indexOf(item.id) == -1 &&
          promo.choiceItems.map(i => i.id).indexOf(item.getId()) == -1 && promo.choiceItems.map(i => i.id).indexOf(item.id) == -1) ||
          !promo.canBeApplied(Store.state.order, Store.state.user)) {
          continue;
        }

        // Check condition
        let apply = true;
        let applyQuantity = 1;
        switch (promo.triggerType) {
          case "mobile_app":
            if (typeof cordova === "undefined") {
              apply = false;
            }
            break;
          case "x_for_y":
            apply = this._checkXYForInventory(promo, item);
            break;
          case "with_item":
            if (this._saleHasItem(promo) > promo.appliedAmount) {
              applyQuantity = this._saleHasItem(promo);
              apply = applyQuantity > 0;
            } else {
              apply = false;
            }
            break;
        }
        if (apply && promo.appliedOn == "one_item") {
          applyQuantity = Math.max(applyQuantity - promo.appliedAmount, 0);
        }
        if (!apply || applyQuantity == 0 || !this.isPromotionWithinSchedule(promo)) {
          continue;
        }

        // Apply
        item.appliedPromotion.promotion = promo;
        if (item.parent) {
          item.parent.appliedPromotion.promotion = promo;
        }
        if (promo.type == "percent") {
          item.appliedPromotion.value = item.getPrice() * (promo.value / 100);
        } else if (promo.type == "flat") {
          item.appliedPromotion.value = promo.value;
        } else if (promo.type == "replace") {
          item.appliedPromotion.value = item.getPrice() - promo.value;
        }
        // item.appliedPromotion.value *= applyQuantity || item.quantity;

      }

    }

  }

  /**
   * @private
   * @param {Promotion} promo
   * @param {Item} item
   */
  _checkXY(promo, item) {

    let x = promo.triggerParameters.x;
    let y = promo.triggerParameters.y;
    if (!x || !y || promo.items.length == 0) {
      return 0;
    }

    // Order items from highest to lowest price to apply promotion on the most saving items possible
    let items = Store.state.order.items.filter(i => {
        return promo.items.map(t => t.id).indexOf(i.getId()) > -1 ||
          promo.items.map(t => t.id).indexOf(i.id) > -1;
      })
      .sort((a, b) => {
        let priceDifference = b.getPrice() - a.getPrice();
        if (priceDifference != 0) {
          return b.getPrice() - a.getPrice();
        }
        return Store.state.order.items.indexOf(a) - Store.state.order.items.indexOf(b);
      });

    // Check the number of items that are placed before this one in term of price
    let numberOfItemsBefore = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i] == item) {
        break;
      } else {
        numberOfItemsBefore += items[i].quantity;
      }
    }

    // Check the number of complete sets of items that can have the promotion applied to
    let allowUpTo = Math.floor(items.reduce((total, i) => total + i.quantity, 0) / x) * x;
    // For each individual item in the quantity, check if the promotion is applicable
    let applying = 0;
    for (let i = numberOfItemsBefore; i < numberOfItemsBefore + item.quantity && i < allowUpTo; i++) {
      if (i % x >= x - y) {
        applying++;
      }
    }

    return applying;
  }

  /**
   *
   * @param {PromotionDefinition} promo
   * @return boolean
   */
  isPromotionWithinSchedule(promo) {
    if (!promo.schedule) {
      return true;
    }

    let hour = Store.state.order.hour;
    if (!hour) {
      return false;
    }

    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    let day = Store.state.order.date.day();

    if (hour == "asap" || hour == "delay") {
      hour = moment().format("HH:mm");
    }
    let orderHour = this._formatHour(hour);

    let scheduleForDay = promo.schedule.find(s => s.name === days[day] && s.hours.length > 0);
    if (!scheduleForDay) {
      return false;
    }
    for (let range of scheduleForDay.hours) {
      if ((range.from && !range.to) || (!range.from && range.to)) { //invalid range
        continue;
      }
      let start = this._formatHour(range.from);
      let end = this._formatHour(range.to);
      if (orderHour.isBetween(start, end, undefined, '[]')) {
        return true;
      }
    }
    return false;
  }

  _formatHour(hour) {
    let h = parseInt(hour.substring(0, 2));
    let m = parseInt(hour.substring(3));
    if (h <= 24) {
      h = h % 24;
    }
    let parsed = moment().set({
      hour: h,
      minutes: m
    });
    if (!parsed.isValid()) {
      return null;
    }
    return parsed;
  }

  /**
   * @param {Promotion} promo
   */
  getXYItemsQuantityInCart(promo) {
    let quantity = 0;
    for (let i = 0; i < Store.state.order.items.length; i++) {
      let item = Store.state.order.items[i];
      let hasPromotion = promo.items.map(t => t.id).indexOf(item.getId()) > -1 || promo.items.map(t => t.id).indexOf(item.id) > -1;
      if (hasPromotion) {
        quantity += item.quantity;
      }
    }
    return quantity;
  }

  /**
   * @private
   * @param {Promotion} promo
   * @param {Item} item
   */
  _checkXYForInventory(promo, item) {

    let x = promo.triggerParameters.x;
    let y = promo.triggerParameters.y;
    if (!x || !y || promo.items.length == 0) {
      return false;
    }

    // Check the number of items in the cart that could use this promo
    let items = Store.state.order.items.filter(i => {
      return promo.items.map(t => t.id).indexOf(i.getId()) > -1 ||
      promo.items.map(t => t.id).indexOf(i.id) > -1;
    });
    if (promo.appliedOn == "one_item" && items.length >= x) {
      return false;
    }
    let totalQuantity = 0;
    for (let cartItem of items) {
      totalQuantity += cartItem.quantity;
    }

    // Show the promo if it can enable a X for Y
    if (totalQuantity % x == x - y) {
      return true;
    }
    return false;

  }

  /**
   * @private
   */
  _resetPromotions() {
    this._resetInventoryPromotions();
    for (let item of Store.state.order.items) {
      item.appliedPromotion.promotion = null;
      item.appliedPromotion.value = 0;
      for (let choice of item.getChoices()) {
        for (let choiceItem of choice.items) {
          choiceItem.appliedPromotion.promotion = null;
          choiceItem.appliedPromotion.value = 0;
        }
      }
      for (let choiceGroup of item.getChoiceGroups()) {
        for (let choice of choiceGroup.getChoices()) {
          for (let choiceItem of choice.items) {
            choiceItem.appliedPromotion.promotion = null;
            choiceItem.appliedPromotion.value = 0;
          }
        }
      }
    }
    Store.state.order.appliedPromotion.promotion = null;
    Store.state.order.appliedPromotion.value = 0;
    for (let promo of this.promotions) {
      promo.appliedAmount = 0;
    }
  }

  /**
   * @private
   * @param {Category[]|Item[]} root
   */
  _resetInventoryPromotions(root = this.inventory.getRoot()) {
    for (let node of root) {
      if (node instanceof Category) {
        this._resetInventoryPromotions(node.items);
        continue;
      }
      if (node.variants.length > 0) {
        this._resetInventoryPromotions(node.variants);
      }
      for (let choice of node.getChoices()) {
        this._resetInventoryPromotions(choice.items);
      }
      for (let choiceGroup of node.getChoiceGroups()) {
        for (let choice of choiceGroup.getChoices()) {
          this._resetInventoryPromotions(choice.items);
        }
      }
      node.appliedPromotion.promotion = null;
      node.appliedPromotion.value = 0;
    }
  }

  /**
   * @private
   * @param {Promotion} promo
   */
  _saleHasItem(promo) {
    let quantity = 0;
    for (let saleItem of Store.state.order.items) {

      let itemIdIsInTriggerParameters =
        promo.triggerParameters.items.indexOf(saleItem.getId()) > -1 ||
        promo.triggerParameters.items.indexOf(saleItem.id) > -1;

      let itemTagIsInTriggerParameters =
        promo.triggerParameters.tags &&
        promo.triggerParameters.tags.length > 0 &&
        saleItem.hasTagFromList(promo.triggerParameters.tags);

      if (itemIdIsInTriggerParameters || itemTagIsInTriggerParameters) {
        quantity += saleItem.quantity;
      }
    }
    return quantity;
  }

  /**
   * Will remove the first hidden product (only one supported) for hidden_product type promotion with invalid condition
   * @author rapraph
   * @private
   * @param {Promotion} promo
   */
  _removeHiddenProductFromCart(promo) {
    let index = 0;
    for (let item of Store.state.order.items) {
      for (let promoItem of promo.items) {
        if (item.id === promoItem.id) {
          Store.state.order.removeItem(index);
          break;
        }
      }
      index++;
    }
  }

} // End of promotion manager

class AppliedPromotion {

  constructor() {
    this.promotion = /** @type {Promotion} */ (null);
    this.value = 0;
  }

  clone() {
    let newApplied = new AppliedPromotion();
    newApplied.promotion = this.promotion;
    newApplied.value = this.value;
    return newApplied;
  }

}

let manager = new PromotionManager();
export { manager as PromotionManager, AppliedPromotion };
