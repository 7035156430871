import { Constant } from "@/util/Constant";
import { Money } from "@/util/Money";
import { IshopPrinterSale } from "./IshopPrinterSale";

class IshopPrinterPosSale extends IshopPrinterSale {

  /**
   *
   * @param {boolean?} includeVoid
   * @returns {[]}
   */
  getDetails(includeVoid) {
    let saleDetails = (this.sale?.details || []);
    if (!includeVoid) {
      saleDetails = saleDetails.filter(d => !d.void);
    }
    if (this.invoice) {
      let details = [];
      let seats = (this.invoice.seats || []).map(s => s.toString());
      for (let i = 0; i < saleDetails.length; i++) {
        let detail = saleDetails[i];
        if (seats.indexOf(detail.seat.toString()) > -1) {
          details.push(detail);
        }
      }
      return details;
    }
    return saleDetails;
  }

  getCompletedDate() {
    return this.sale.closedAt;
  }

  /**
   *
   * @param invoice
   * @returns {*[]}
   */
  getPayments(invoice) {
    let salePayments = this.sale.payments;
    let payments = [];
    for (let i = 0; i < salePayments.length; i++) {
      let payment = salePayments[i];
      if (payment.invoices.indexOf(invoice.number) > -1) {
        payments.push(payment);
      }
    }
    return payments;
  }

  /**
   * @return {{id: number, name: string, total: number}[]}
   */
  getGlobalPromotions() {
    let details = this.getDetails();
    let promotionMapping = [];
    let globalPromotions = [];

    //Go through each promotion of each detail for the current print
    for (let detail of details) {
      for (let promotion of detail.appliedPromotions) {
        let found = promotionMapping.find(p => p.id === promotion.id);
        if (!found) {
          //Map each promotion for the order with the total discounted amount for the order and linked detail ids
          promotionMapping.push({
            id: promotion.id,
            detailIds: [detail.id],
            name: `${window.translateObject(promotion.name)} (-${promotion.value}${promotion.type === "percent" ? "%" : "$"})`,
            discountedAmount: 0 - promotion.amount
          });
        } else {
          //Update total discounted amount & add linked id to detail id list
          found.detailIds.push(detail.id);
          found.discountedAmount -= promotion.amount;
        }
      }
    }

    for (let mapping of promotionMapping) {
      //Global promotions are promotions that will be applied on each detail, we will ignore the other promotions
      if (mapping.detailIds.length === details.length) {
        globalPromotions.push({
          id: mapping.id,
          name: mapping.name,
          total: mapping.discountedAmount
        });
      }
    }

    return globalPromotions;
  }

  getAppName() {
    return Constant.POS_APP_NAME;
  }

  getId() {
    return this.sale.id;
  }

  getLanguage() {
    return this.sale.language;
  }

  printTransactionNumber() {
    this.printer.newLine()
      .addLine(null, new Date(this.getCompletedDate()).toLocaleString());

    if (this.invoice) {
      this.printer.setFontSize(2)
        .addLine(null, window.translate("pos_receipt.check").toUpperCase() + " #" + (this.invoice.number))
        .setFontSize(1);
    }
    this.printer.addLine(null, window.translate("pos_receipt.order").toUpperCase() + " #" + (this.getId()))
      .newLine();
  }

  printSaleDetail(detail) {
    let config = this.configuration?.receiptConfiguration;
    let limitProductDescription = config && config.limitProductDescription;
    let hideFreeModifiers = config && config.hideFreeModifiers;
    let name = limitProductDescription ? detail.name.substring(0, this.maxProductNameSize) : detail.name;

    //TODO save discounted price in detail
    let globalPromotions = this.getGlobalPromotions();
    let promotions = detail.appliedPromotions.filter(p => !globalPromotions.find(g => g.id === p.id)); //Print only discounts that are not global promos

    let price = detail.unitPrice;
    if (promotions.length > 0) { //apply only item discounts
      price -= promotions.reduce((p, c) => p + c.amount, 0);
      price = Math.max(0, Money.round(price * detail.quantity));
    } else { //regular price
      price *= detail.quantity;
    }

    if (this.isRefund()) {
      price = -(price);
    }

    if (!detail.parentDetail) { // Item
      this.printer.addLine(detail.quantity + "x " + name, null, Money.roundForDisplay(price));
    } else if (!hideFreeModifiers || (hideFreeModifiers && detail.unitPrice !== 0)) { //Modifier
      this.printer.addLine("   +" + name + " x" + detail.quantity, null, Money.roundForDisplay(price));
    }

    if (detail.appliedPromotions) {
      for (let promotion of promotions) {
        this.printer.addLine("   " + window.translateObject(promotion.name) +
          " (" + Money.roundForDisplayWithCurrencySymbol(0 - promotion.amount, this.sale.currency) + ")");
      }
    }
  }

  printDetailLineForKitchen(detail) {
    let name = detail.kitchenName || detail.name;
    let quantity = detail.void ? `-${detail.quantity}` : detail.quantity;
    if (!detail.parentDetailId) { // Item
      this.printer.addLine(quantity + "  " + name);
    } else { //Modifier
      this.printer.addLine("   " + quantity + "  " + name);
    }
  }

  /**
   * @param {PosConfiguration} configuration
   * @param {boolean?} newDetailsOnly
   * @param configuration
   * @return {*}
   */
  getSaleDetailsByPrinterName(configuration, newDetailsOnly) {
    let details = this.getDetails(true);
    let detailsByPrinterName = {};
    let lastPrinterNames = [];
    
    if (newDetailsOnly) {
      details = details.filter(d => d.isNew());
    }

    for (let i = 0; i < details.length; i++) {
      let detail = details[i];
      if (detail.parentDetailId) { //Is a modifier
        for (let printerName of lastPrinterNames) {
          if (detailsByPrinterName[printerName]) {
            detailsByPrinterName[printerName].push(detail);
          }
        }
      } else { //Is a root item
        lastPrinterNames = configuration.getKitchenPrinterNamesForItemId(detail.itemId);
        for (let printerName of lastPrinterNames) {
          if (!detailsByPrinterName[printerName]) {
            detailsByPrinterName[printerName] = [detail];
          } else {
            detailsByPrinterName[printerName].push(detail);
          }
        }
      }
    }
    return detailsByPrinterName;
  }

}

export { IshopPrinterPosSale };