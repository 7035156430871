import { render, staticRenderFns } from "./PosDispatch.vue?vue&type=template&id=246851a0&scoped=true&"
import script from "./PosDispatch.vue?vue&type=script&lang=js&"
export * from "./PosDispatch.vue?vue&type=script&lang=js&"
import style0 from "./PosDispatch.vue?vue&type=style&index=0&id=246851a0&lang=scss&scoped=true&"
import style1 from "./PosDispatch.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246851a0",
  null
  
)

export default component.exports