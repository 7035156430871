import Axios from "axios";

export class XposPrinter {

  /**
   * @param {string} url
   */
  constructor(url) {
    this.url = url;
    this.printerPort = 9100;
    this.encoding = "base64";

    /* API Endpoints */
    this.ENDPOINTS = {
      PRINT_JOB: "/print_jobs",
      PRINTERS: "/printers"
    };
  }

  /**
   * @param {string} printerIpAddress
   * @param {string} data
   * @param {string} encoding
   * @returns {Promise<{success: boolean, error, status}|{data: any, success: boolean, status: number}>}
   */
  async print(printerIpAddress, data, encoding = this.encoding) {
    try {
      let response = await Axios.post(this.url + this.ENDPOINTS.PRINT_JOB, {
        ip: printerIpAddress,
        port: this.printerPort,
        encoding: encoding,
        str: data
      });
      return { success: true, data: response.data, status: response.status };
    } catch (e) {
      return { success: false, error: e.response, status: e.status };
    }
  }

  /**
   * @returns {Promise<{success: boolean, error, status}|{data: any, success: boolean, status: number}>}
   */
  async getPrinters() {
    try {
      let response = await Axios.get(this.url + this.ENDPOINTS.PRINTERS);
      return { success: true, data: response.data, status: response.status };
    } catch (e) {
      return { success: false, error: e.response, status: e.status };
    }
  }
}