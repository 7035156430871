//@ts-check
import { ActionContext } from "vuex";

export default {

  /**
   * Logs the user and stores the returned token if connexion is successful
   * @param {ActionContext} context
   * @param {{username: string, password: string, branch: string}} param1
   */
  async login(context, {
    username,
    password,
    branch
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/login2",
        data: {
          username,
          password,
          branch
        }
      });
      return {
        success: true,
        user: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async loginGuest(context, { branch }) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/anonymous_login",
        data: { branch }
      });
      return {
        success: true,
        user: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * @param {ActionContext} context
   * * @param {{facebookData: any, branch: string}} param1
   */
  async facebookLogin(context, {
    facebookData,
    branch
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/facebook-login",
        data: {
          facebookData,
          branch
        }
      });
      return {
        success: true,
        user: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * @param {ActionContext} context
   * @param {{username: string,
   *          password:string,
   *          branch:string,
   *          information: { firstName: string, lastName: string,
   *                          contact: { email: string, preference: string, phoneNumber: string},
   *                          addresses: [{ fullAddress: string, app: string, zipCode: string, latitude: string, longitude: string, note: string}]
   *        }}} user
   */
  async register(context, user) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/register",
        data: user
      });
      return {
        success: true,
        user: response
      };
    } catch (e) {
      if (e.status === 418) {
        return {
          success: true,
          response: JSON.parse(e.response)
        };
      }
      return {
        success: false,
        status: e.status,
        error: e.status === 500 ? {} : JSON.parse(e.response)
      };
    }
  },

  /**
   * @param {ActionContext} context
   */
  async userInformation(context) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/me"
      });
      return {
        success: true,
        profile: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   *
   * @param {ActionContext} context
   */
  async loyaltyBalance(context) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/me/loyalty/balance"
      });
      return {
        success: true,
        profile: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * @param {ActionContext} context
   * @param {{page: number, perPage: number}} arg1
   */
  async history(context, {
    page,
    perPage
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/me/history",
        data: {
          page: page,
          perPage: perPage
        }
      });
      return {
        success: true,
        history: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   *
   * @param {ActionContext} context
   * @param {{cardNumber: string}} arg1
   */
  async giftCardBalance(context, {
    cardNumber,
    company,
    recaptcha,
    pin
  }) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/me/prepaid",
        data: {
          cardNumber: cardNumber,
          company: company,
          recaptcha: recaptcha || undefined,
          pin: pin || undefined
        }
      });
      return {
        success: true,
        profile: response
      };
    } catch (e) {
      return {
        success: false,
        status: e.status,
        error: JSON.parse(e.response)
      };
    }
  },

  async resetPassword(context, email) {
    try {
      let url = CONFIG.urlFrontend + "/#/" + context.state.currentBranch.nameCanonical + "/";
      if (context.state.currentCompany) {
        url += context.state.currentCompany.nameCanonical + "/";
      }
      url += "newPassword?token={TOKEN}";

      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/forgot_password/",
        data: {
          email: email,
          branch: context.state.currentBranch.nameCanonical,
          locale: getConfiguration("locale") || "fr",
          redirect_url: url
        }
      });
      return { success: true };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async newPassword(context, {
    token,
    password
  }) {
    try {
      await context.dispatch("request", {
        method: "POST",
        url: "/api/forgot_password/" + token,
        data: { password }
      });
      return {
        success: true
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async validateUser(context, token) {
    try {
      await context.dispatch("request", {
        method: "POST",
        url: "/api/validate_user/" + token,
        data: {}
      });
      return {
        success: true
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async saveProfile(context) {
    if (!context.state.user.token) {
      return {
        success: true
      };
    }
    try {
      let response = await context.dispatch("request", {
        method: "PUT",
        url: "/api/me/information",
        data: context.state.user.toDto()
      });
      return { success: true };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async saveAddresses(context, token) {
    if (!context.state.user.token && token) {
      context.state.user.token = token;
    }
    if (!context.state.user.token) {
      return {
        success: true
      };
    }
    try {
      let response = await context.dispatch("request", {
        method: "PUT",
        url: "/api/me/information",
        data: {
          addresses: context.state.user.getNonEmptyAddresses()
        }
      });
      return { success: true };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async saveGiftCards(context) {
    if (!context.state.user.token) {
      return {
        success: true
      };
    }
    try {
      let response = await context.dispatch("request", {
        method: "PUT",
        url: "/api/me/information",
        data: {
          moreData: {
            giftCards: context.state.user.getiShopFoodGiftCards()
          }
        }
      });
      return { success: true };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  /**
   * @param context
   * @param {{branch: string, company: string}} args
   */
  async recommendations(context, {
    branch,
    company
  }) {
    let url = "";
    if (context.state.user.token) {
      url = `/api/me/recommendations?company=${company}`;
    } else {
      url = `/api/branches/${branch}/companies/${company}/recommendations`;
    }
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: url
      });
      let recommendations = [];
      for (let id of response.recommendations) {
        let item = await context.dispatch("getFlatItem", id);
        if (item) {
          recommendations.push(item);
        }
      }
      return {
        success: true,
        recommendations: recommendations
      };
    } catch (e) {
      return {
        success: false,
        status: e.status
      };
    }
  },

  async checkPromoCode(context, {
    branch,
    company,
    code
  }) {
    let url = "/api/branches/" + branch + "/companies/" + company + "/check-promo-code?code=" + code;
    if (typeof cordova !== "undefined" && typeof device !== "undefined") {
      url += "&uuid=" + device.uuid;
    }
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: url
      });

      return {
        success: true,
        promotion: response.promotion
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
        response: JSON.parse(e.response)
      };
    }
  },

  async findDispatchCustomer(context, search) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/user_informations",
        data: {
          search
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  async findDispatchCustomerByPhoneNumber(context, phoneNumber) {
    try {
      let response = await context.dispatch("request", {
        method: "GET",
        url: "/api/user_informations",
        data: { phoneNumber }
      });
      if (response.data.length === 0) {
        return false;
      }
      return response.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateDispatchCustomer(context, { id, data }) {
    try {
      let response = await context.dispatch("request", {
        method: "PUT",
        url: "/api/user_informations/" + id,
        data: data
      });
      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async createDispatchCustomer(context, data) {
    try {
      let response = await context.dispatch("request", {
        method: "POST",
        url: "/api/user_informations",
        data: data
      });
      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

};
