<template>
  <div class="selector" @click="emitClick" @contextmenu="contextMenuEmitClick" :class="{
    'removal-selector': type == 'removal',
    'choice-selector': type == 'choice',
    'extra-selector': type == 'extra',
    'variant-selector': type == 'variant',
    button: modifiersMode === 'button',
    hasImage: !!selector.image,
    noImage: noImage,
    quantifiable: isQuantifiable,
    selected: isSelected,
    outOfStock: isOutOfStock}">

    <div class="check">
      <span class="quantity" v-if="($kiosk || $pos) && isQuantifiable">{{selector.quantity}}</span>
      <svgicon src="icons/check.svg" :height="$pos ? 14 : 8" :width="$pos ? 16 : 11" v-else></svgicon>
    </div>

    <div class="wave-zone" v-wave="modifiersMode === 'button' && !noWave">
      <AsyncImage class="image" v-if="selector.image && !noImage"
                  :fallback="$store.state.defaultItemImage" :image="(refusedModifier ? '' : $store.state.urlFileServer) + selector.image"></AsyncImage>

      <div class="info">
        <div class="namePrice">
          <v-clamp v-if="modifiersMode === 'button'" :max-lines="2" class="name" :class="{ promoPadding: promo && noImage }">{{ $to(selector.name) }}</v-clamp>
          <div v-if="modifiersMode === 'list'" class="name">{{ $to(selector.name) }}</div>

          <div v-if="selector.description && $to(selector.description) && !$pos" class="description">{{ $to(selector.description) }}</div>

          <div class="price" :class="{ promoPadding: promo && noImage }" v-if="selectorPrice != 0 && !isOutOfStock && !refusedModifier">
            <span :class="{striked: hasAppliedPromo(selector.id) || selectorHasPromo}">
                {{ pricePrefix }}{{ $tc(selectorPrice) }}
            </span>
            <span class="bold" v-if="selectorHasPromo && !hasXForYPromo">
              {{ pricePrefix }}{{ $tc(selector.getTotalPrice($order.method).subTotal.toFixed(2)) }}
            </span>
          </div>

          <div class="info-promotions" :class="{ promoPadding: promo && noImage }" v-if="hasPromotions">
            <div class="promotions-promotion" v-for="promotion of appliedPromotions"
              :key="`promo-${item.id}-${promotion.id}`">{{ $to(promotion.name) }}</div>
          </div>

          <div class="info-default" :class="{ promoPadding: promo && noImage }" v-if="isDefault && $pos">{{ $t("default_option") }}</div>

          <div class="price" :class="{ promoPadding: promo && noImage }" v-if="isOutOfStock">{{ $t("menu.outOfStock") }}</div>

        </div>

      </div>
    </div>

    <div class="input quantifiable" v-if="isQuantifiable && (!$kiosk || ($kiosk && noPopup)) && !$pos">
      <QuantitySelector :key="selector.quantity" ref="quantitySelector" :large="$kiosk"
                        :value="selector.quantity" @changed="quantityChanged($event)"></QuantitySelector>
    </div>

    <div class="input" v-if="!isQuantifiable" :class="{checkbox: isExtraOrRemoval}">

      <template v-if="isVariant">
        <input :name="type + 'Selector' + selector.id" :disabled="isOutOfStock"
               :id="'chk' + type + selector.id" type="radio"
               :checked="item.selectedVariant && item.selectedVariant.id == selector.id">
        <label :for="'chk' + type + selector.id"></label>
      </template>

      <template v-if="isChoice">
        <input :name="type + 'Selector' + selector.id + group.id + uuid" :disabled="isOutOfStock"
               :id="'chk' + type + uuid" type="radio" :checked="group.selected == selector">
        <label :for="'chk' + type + uuid"></label>
      </template>

      <template v-if="isExtraOrRemoval">
        <input :id="'chk' + type + uuid" type="checkbox" :disabled="isOutOfStock" :checked="selector.quantity > 0">
        <label :for="'chk' + type + uuid" @click="stopEventPropagation"></label>
      </template>

    </div>

  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage";
  import QuantitySelector from "@/components/tools/QuantitySelector.vue";
  import { defineComponent } from "@vue/runtime-core";

  export default defineComponent({
    name: "modifierSelector",

    components: {
      AsyncImage,
      QuantitySelector
    },

    props: {
      item: Object,
      group: Object,
      selector: Object,
      noWave: Boolean,
      noPopup: Boolean,
      refusedModifier: Boolean,
      type: String,
      promo: Boolean,
      isDefault: Boolean
    },

    data() {
      return {
        uuid: null,
        itemPrice: 0
      };
    },

    mounted() {
      this.uuid = this.generateUUID;
      if (this.item) {
        this.itemPrice = this.item.getLowestPrice(this.$order.method);
      }
    },

    methods: {
      stopEventPropagation(e) {
        e.stopPropagation();
      },
      contextMenuEmitClick() {
        if (this.$kiosk) {
          this.emitClick();
        }
      },
      emitClick(e) {
        if (this.isOutOfStock) {
          return;
        }
        if (this.isQuantifiable) {
          return;
        }
        this.$emit("click", this.selector);
      },
      quantityChanged(quantity) {
        this.$emit("changed", quantity);
      },
      hasPromoPriceChange() {
        if (!this.item) {
          return false;
        }
        return this.item.appliedPromotion && !!this.item.appliedPromotion.value &&
          this.item.appliedPromotion.promotion.triggerType !== "x_for_y";
      },
      hasAppliedPromo(id) {
        if (!this.item) {
          return false;
        }
        return this.item.appliedPromotion.promotion &&
          this.item.appliedPromotion.promotion.items.filter(x => x.id === id).length > 0;
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      variantPriceAsUpsell() {
        return this.branch && this.branch.style && this.branch.style.variantPriceAsUpsell;
      },
      isExtraOrRemoval() {
        return this.type === "extra" || this.type === "removal";
      },
      selectorPrice() {
        if (!this.selector || this.refusedModifier) {
          return 0;
        }
        let price = this.selector.getPrice(this.$order.method);
        if (this.isVariant && this.variantPriceAsUpsell) {
          return (price - this.itemPrice).toFixed(2);
        }
        return price.toFixed(2);
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      isChoice() {
        return this.type === "choice" && this.group;
      },
      isVariant() {
        return this.type === "variant";
      },
      isOutOfStock() {
        return this.selector.outOfStock;
      },
      isSelected() {
        if (this.isVariant) {
          return this.item.selectedVariant && this.item.selectedVariant.id === this.selector.id;
        }
        if (this.isChoice) {
          return this.group.selected === this.selector;
        }
        if (this.isExtraOrRemoval) {
          return this.selector.quantity > 0;
        }
        return false;
      },
      isQuantifiable() {
        return this.selector.quantifiable && this.type === "extra";
      },
      shouldDisplayDescription() {
        return this.selector.description && !this.isQuantifiable;
      },
      modifiersMode() {
        if (this.$kiosk) {
          return "button";
        }
        if (this.$pos) {
          return "button"; //TODO use config
        }
        if (!this.branch || !this.branch.style) {
          return "list";
        }
        return this.branch.style.modifiersMode || "list";
      },
      pricePrefix() {
        let types = ["choice", "extra"];
        if (this.variantPriceAsUpsell) {
          types.push("variant");
        }
        if (this.selector && types.indexOf(this.type) > -1) {
          return this.selectorPrice > 0 ? "+" : "";
        }
        return "";
      },
      generateUUID() {
        return window.generateUUID();
      },
      selectorHasPromo() {
        if (!this.selector) {
          return false;
        }
        return this.selector.appliedPromotion && this.selector.appliedPromotion.promotion;
      },
      hasXForYPromo() {
        if (!this.item) {
          return false;
        }
        return this.item.appliedPromotion.promotion && this.item.appliedPromotion.promotion.triggerType === "x_for_y";
      },
      table() {
        return this.$store.state.table;
      },
      appliedPromotions() {
        return this.table?.promotionVisitor.getPromotionsForItem(this.selector);
      },
      hasPromotions() {
        return this.appliedPromotions && this.appliedPromotions.length > 0;
      }
    }
  });
</script>

<style lang="scss" scoped>

  .check {
    display: none;
  }

  #iShopFoodApp .selector.outOfStock {
    cursor: initial;
    pointer-events: none;

    .image {
      filter: grayscale(1);
      opacity: 0.4;
    }
    .quantity-selector {
      opacity: 0.4;
      cursor: initial;
      pointer-events: none;
    }
    .name {
      opacity: 0.4;
    }
    .input {
      pointer-events: none;
      cursor: initial;
      opacity: 0.4;
    }
  }

  #iShopFoodApp .selector:not(.button) {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 8px 10px;

    .wave-zone {
      display: flex;
      align-items: center;
    }

    &.selected {
      border-color: #ddd;
    }

    .image {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
    }

    .info {
      flex-grow: 1;
      .namePrice {
        display: flex;
        flex-direction: column;
        word-break: break-word;
        font-size: 18px;
        .price span {
          color: inherit !important;
        }
        .price, .price span {
          color: var(--primary-color);
          margin-top: 4px;
          white-space: nowrap;
          &.striked {
            text-decoration: line-through;
            opacity: 0.8;
          }
          &.bold {
            font-weight: bold;
          }
        }
      }
    }

    .input {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  #iShopFoodApp .info .namePrice {
    display: flex;
    flex-direction: column;
    word-break: break-word;
    font-size: 18px;
    .price span {
      color: inherit !important;
    }
    .price, .price span {
      color: var(--primary-color);
      margin-top: 4px;
      white-space: nowrap;
      &.striked {
        text-decoration: line-through;
        opacity: 0.8;
      }
      &.bold {
        font-weight: bold;
      }
    }
  }

  .description {
    font-size: 16px;
    color: #808080;
  }
  #iShopFoodApp.xs #item-basic .selector.button {
    width: 95%;
    .image {
      width: calc(100% + 6px);
    }
    .info {
      padding-top: 10px;
    }
  }

  #iShopFoodApp #item-basic .selector.button.selected:not(.hasImage) {
    .name {
      padding-right: 20px;
    }
  }

  .wave-zone {
    flex-grow: 1;
  }

  #iShopFoodApp #item-basic .selector.button {
    $border-size: 3px;
    $width: 130px;
    $radius: 10px;

    box-shadow: 0px 5px 10px 0 rgba(2, 3, 2, 0.15);
    display: flex;
    flex-direction: column;
    border-radius: $radius;
    width: $width;
    cursor: pointer;
    min-width: initial;
    min-height: 40px;
    margin: 10px 14px;
    height: calc(100% - 12px);
    border: $border-size solid transparent;

    &.outOfStock {
      cursor: initial;
    }

    &.selected {
      overflow: hidden;
      border-color: var(--primary-color);
      position: relative;

      .check {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 998;
        fill: var(--primary-color);
        background-color: #f2f2f2;
        width: 25px;
        height: 25px;
        border-radius: 100%;

        .quantity {
          color: var(--primary-color) !important;
          font-weight: bold;
        }
      }
    }

    .input.quantifiable {
      height: 60px;
    }

    .input:not(.quantifiable) {
      display: none;
    }

    .name, .price {
      font-size: 14px;
    }

    .name.promoPadding {
      padding-left: 40px;
    }

    .price.promoPadding,
    .info-promotions.promoPadding,
    .info-default.promoPadding {
      padding-left: 15px;
    }

    .info-promotions {
      color: var(--primary-color);
      font-weight: 600;
    }

    .info-default {
      font-size: 12px;
      margin-top: 4px;
      color: var(--theme-color-2);
    }

    .price {
      margin-top: 4px;
    }

    .image {
      width: $width;
      height: $width;
      margin-left: -$border-size;
      margin-top: -$border-size;
      flex-shrink: 0;
      background-size: contain;
      background-position: center;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    .quantity-selector {
      width: 110px;
      margin-left: auto;
      margin-right: auto;
    }

    .info {
      padding: 8px 10px;
      flex-grow: 1;
    }

    .description {
      font-size: 11px;
      opacity: 0.8;
      padding-top: 2px;
    }
  }

  #iShopFoodApp.xs {
    .info .namePrice {
      margin-bottom: 10px;
    }
  }
</style>

<style lang="scss">
 #iShopFoodApp.pos #item-basic .overflow-mobile .panel.panel-selectors .selector.button.noImage {
   padding: 0;
   .wave-zone .info {
      padding: 10px;
      min-height: 95px;
      display: flex;
      align-items: center;
   }
 }
  #iShopFoodApp #item-basic .selector.button {
    .quantity-selector {
      input {
        width: 110px;
        font-size: 14px;
        padding: 8px 10px;
      }
      .add {
        right: 4px;
      }
      .minus {
        left: 4px;
      }
    }
    .namePrice {
      display: flex;
      flex-direction: column;
    }
    .image {
      .whileLoading {
        background-size: contain;
      }
    }
  }
</style>
