// @ts-check
import Store from "../store.js";
import moment from "moment-timezone";

/**
 * @typedef BrandingAssetDefinition
 * @property {number} id
 * @property {string} name
 * @property {string} type
 * @property {string} url
 */


/**
 * @typedef BrandingDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {string} color
 * @property {BrandingAssetDefinition} logo
 * @property {BrandingAssetDefinition} horizontalLogo
 * @property {BrandingAssetDefinition} promoImage
 * @property {BrandingAssetDefinition} bannerImage
 * @property {any} images
 * @property {number[]} categories
 */

export default class Branding {

  /**
   * @param {BrandingDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id;
    this.name = definition.name;
    this.description = definition.description;
    this.shortDescription = definition.shortDescription;
    this.color = definition.color;
    this.logo = definition.logo;
    this.horizontalLogo = definition.horizontalLogo;
    this.promoImage = definition.promoImage;
    this.bannerImage = definition.bannerImage;
    this.categories = definition.categories;
    this.images = definition.images || {};
    this.slideshow = [];


    if (definition.config) {
      this.config = definition.config;
    } else {
      let defaultConfig = {
        general: { averageDelay: 0 },
        hours: {
          friday: { hours: [] },
          monday: { hours: [] },
          sunday: { hours: [] },
          tuesday: { hours: [] },
          saturday: { hours: [] },
          thursday: { hours: [] },
          wednesday: { hours: [] }
        }
      };
      this.config = {
        catering: JSON.parse(JSON.stringify(defaultConfig)),
        inPlace: JSON.parse(JSON.stringify(defaultConfig)),
        takeout: JSON.parse(JSON.stringify(defaultConfig)),
        delivery: JSON.parse(JSON.stringify(defaultConfig)),
        hotel: JSON.parse(JSON.stringify(defaultConfig))
      }
    }

    this.formatImagesForSlideshow();
    this.formatAvailability();

  }

  /**
   * If a range is over 24 hours, this will create a range in the next day starting at 00:00. Ex.: end 26:00, will create 00:00 to 2:00 in next day
   * This is used so that a branding be will available if the user is on the 'next day' but within the last day's range.
   */
  formatAvailability() {
    if (!this.config) {
      return;
    }
    let splitHour = (text) => {
      let split = text.split(":");
      let hour = {
        hour: parseInt(split[0]),
        minute: parseInt(split[1])
      };
      return hour;
    };

    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    for (let methodName of Object.keys(this.config)) {
      for (let i = 0; i < days.length; i++) {
        let day = days[i];
        if (this.config[methodName] && this.config[methodName].hours && this.config[methodName].hours[day] && this.config[methodName].hours[day].hours) {
          for (let range of this.config[methodName].hours[day].hours) {
            let hourTo = splitHour(range.to);
            if (hourTo.hour >= 24 && hourTo.minute > 0) {
              let nextDayIndex = i === days.length - 1 ? 0 : i + 1; // get next day, makes sure saturday falls back to sunday
              let nextDay = days[nextDayIndex];
              this.config[methodName].hours[nextDay].hours.push({
                from: "00:00",
                to: (hourTo.hour - 24) + ":" + hourTo.minute.toString().padStart(2, '0')
              });
            } else if (hourTo.hour === 24 && hourTo.minute === 0) {
              range.to = "23:59";
            }
          }
        }
      }

    }
  }

  /**
   * Check is specified date/time and method (fallback to current date and/or time) is within availability
   * @param {Moment?} date
   * @param {string?} time
   * @param {string} method
   * @returns {boolean}
   */
  isAvailable(date, time, method) {
    method = method || Store.state.method || "takeout";

    // 1. Always open
    if (!this.config) {
      return true;
    }

    // ASAP should use current time instead
    if (time === "delay" || time === "asap") {
      time = null;
    }

    time = time ? moment(time, "HH:mm") : moment();
    let schedule = this.getOpenHoursForDate(date, method);

    // 2. Day not open
    if (!schedule.hours || schedule.hours.length === 0) {
      return false;
    }
    for (let range of schedule.hours) {
      let parsedTo = parseHour(range.to);
      let to = moment("00:00:00", "HH:mm:ss").add(parsedTo.hour, "hours").add(parsedTo.minute, "minutes");
      // 3. Within range for day
      if (time.isSameOrAfter(moment(range.from, "HH:mm")) && time.isBefore(to)) {
        return true;
      }
    }

    // 4. Outside range for day
    return false;
  }

  /**
   * Get open hours for specified date object and method
   * @param {Moment?} date
   * @param {string} method
   * @return {Object}
   */
  getOpenHoursForDate(date, method) {
    return this.getOpenHoursForDay((date || moment()).day(), method);
  }

  /**
   * Get open hours for specified day and method
   * @param {number} dayNum
   * @param {string} method
   * @returns {Object}
   */
  getOpenHoursForDay(dayNum, method) {
    method = method || Store.state.method || "takeout";

    if (!this.config || !this.config[method]) {
      return [];
    }
    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let dayName = days[dayNum];
    return this.config[method].hours[dayName];
  }

  /**
   * @param {string} method
   * @return {number}
   */
  getAverageDelay(method) {
    method = method || Store.state.method || "takeout";

    return this.config[method].general.averageDelay;
  }

  /**
   * @param {Moment?} date
   * @param {string?} time
   * @param {string} method
   * @returns {string}
   */
  getStartHourForDay(date, time, method) {
    // ASAP should use current time instead
    if (time === "delay" || time === "asap") {
      time = null;
    }
    time = time ? moment(time, "HH:mm") : moment();

    let schedule = this.getOpenHoursForDate(date, method);
    for (let range of schedule.hours) {
      if (time.isAfter(moment(range.to, "HH:mm"))) {
        continue;
      }
      return range.from;
    }
  }

  formatImagesForSlideshow() {
    this.slideshow = [];

    for (let key of Object.keys(this.images)) {
      let image = this.images[key];
      if (image.tags && image.tags.indexOf("slideshow") > -1) {
        image.url = Store.state.urlFileServer + image.url;
        this.slideshow.push(image);
      }
    }

    this.slideshow.sort((a, b) => a.priority - b.priority);
  }

  /**
   * @returns {String[]}
   */
  getSlideshowUrls() {
    return this.slideshow.map(s => s.url);
  }

}
