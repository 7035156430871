<template>

  <div class="cartTotalContainer" :class="{disabled: isPaying}">

    <div class="promo" v-if="$order.appliedPromotion.promotion">
      <div class="icon">
        <div class="circle">
          <svgicon src="icons/promo.svg" :width="28" :height="28"></svgicon>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div class="name">{{ $to($order.appliedPromotion.promotion.name) }}</div>
          <div class="actions" @click="PromotionManager.removePromotion($order.appliedPromotion.promotion)"
            v-if="$order.appliedPromotion.promotion.code">&times;</div>
        </div>
        <div class="bottom">
          <div class="description">{{ $to($order.appliedPromotion.promotion.description) }}</div>
          <div class="amount">- {{ $order.appliedPromotion.promotion.value.toFixed(2) }}{{ $order.appliedPromotion.promotion.type == "flat" ? "$" : "%" }}</div>
        </div>
      </div>
    </div>

    <div class="promo-available" :class="{quickMobile: $store.state.quickMode && $store.getters.isMobileOrTablet}"
      v-if="PromotionManager.hasPromotion() && !PromotionManager.hasPromotionApplied() && !$order.appliedPromotion.promotion"
      @click="goToPromotions">
      <div class="icon">
        <svgicon src="icons/promo.svg" :width="28" :height="28"></svgicon>
      </div>
      <div class="name">{{ $t("promotion.activate_now") }}</div>
      <div class="action">
        <svgicon src="icons/forward.svg" :width="28" :height="28"></svgicon>
      </div>
    </div>

    <div id="cartTotal" :class="{quickMobile: $store.state.quickMode && $store.getters.isMobileOrTablet}">

      <template v-if="!empty">
        <div class="top">
          <div class="subtotal">
            <div class="label">{{$t('total.subtotal')}}</div>
            <div class="amount">{{ $tc(total.itemsTotal.toFixed(2)) }}</div>
          </div>
          <div class="fees" v-if="$order.appliedPromotion.promotion && !$order.appliedPromotion.promotion.taxIncluded">
            <div class="label">{{ $to($order.appliedPromotion.promotion.name) }}</div>
            <div class="amount">- {{ $tc($order.appliedPromotion.value.toFixed(2)) }}</div>
          </div>
          <div class="fees" v-if="Number.parseFloat($order.accountPayment) > 0 && branch && branch.shouldApplyLoyaltyPaymentOnSubtotal()">
            <div class="label">{{$t('payment.account')}}</div>
            <div class="amount">- {{ $tc(Number.parseFloat($order.accountPayment).toFixed(2)) }}</div>
          </div>
          <div class="fees" v-if="total.serviceFees">
            <div class="label">{{$t('total.service_fees')}}</div>
            <div class="amount">{{ $tc(total.serviceFees.toFixed(2)) }}</div>
          </div>
          <div class="fees" v-if="total.deliveryFees">
            <div class="label">{{$t('total.delivery_fees')}}</div>
            <div class="amount">{{ $tc(total.deliveryFees.toFixed(2)) }}</div>
          </div>
          <div class="taxes" v-if="$store.state.currentCompany.taxes.length > 0 && (!$store.state.quickMode || !$store.getters.isMobileOrTablet)">
            <div class="tax" v-for="tax of company.taxes" :key="tax.id">
              <div class="label">{{$to(tax.name)}}<span v-if="tax.taxNumber"> ({{ tax.taxNumber }})</span></div>
              <div class="amount">{{ $tc(total.taxes[tax.uniqueName].toFixed(2)) }}</div>
            </div>
          </div>
          <div class="fees" v-if="$order.appliedPromotion.promotion && $order.appliedPromotion.promotion.taxIncluded">
            <div class="label">{{ $to($order.appliedPromotion.promotion.name) }}</div>
            <div class="amount">{{ $tc(-$order.appliedPromotion.value.toFixed(2)) }}</div>
          </div>
          <div class="total">
            <div class="label">{{$t('total.total')}}</div>
            <div class="amount">{{ $tc(total.total.toFixed(2)) }}</div>
          </div>
        </div>
      </template>

      <template v-if="empty">
        <div class="emptyContainer">
          <span class="empty">{{ $store.state.ticketMode ? $t('cart.begin_ticket') : $t('cart.begin')}}</span>
        </div>
      </template>

      <div class="orderButton" v-if="!$store.getters.isMobileOrTablet">
        <button type="button" class="btn btn-primary" @click="submitOrder"
                :class="{disabled: empty || $user.isDispatchUserWithoutCustomer(), dispatch: $user.isDispatchUserWithoutCustomer()}"
                :disabled="empty || ($user && $user.isDispatchUser() && !$user.onBehalfOf)">
          <span v-if="$user.isDispatchUserWithoutCustomer()">{{$t('dispatch.dispatch_missing')}}</span>
          <span v-else>{{$t('cart.order')}}</span>
        </button>
      </div>

    </div>
  </div>

</template>

<script>

  //@ts-check
  import { mapActions } from 'vuex';
  import { PromotionManager } from "../../PromotionManager";
  import EventBus from '../../lib/eventBus';

  export default {

    name: "CartTotal",

    data() {
      return {
        PromotionManager
      };
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      total() {
        return this.$order.getOrderTotal();
      },
      empty() {
        return this.$order.items.length == 0;
      },
      isPaying() {
        return this.$route.name.startsWith("payment");
      }
    },

    methods: {

      async submitOrder() {
        let valid = this.$order.validateAddedItems();
        if (!valid) {
          return;
        }

        /* TODO (0.9.1): En ce moment le code est le même dans Cart.vue (mobile vs desktop)*/
        if (!this.empty) {
          if (!this.$order.company) { ///TODO remove completely order.company, use store.state.currentCompany instead
            this.$order.company = this.company;
          }

          if (this.$order.method && !this.$order.validateMinimumAmount()) {
            return;
          }

          if (!this.$order.validateOrderRequirements()) {
            return;
          }

          for (let promo of PromotionManager.promotions) {
            if (promo.triggerType == "mobile_app" && typeof cordova != "undefined" && promo.minAmount) {
              if (this.$order.getItemsTotal({excludedTags: promo.exclusion.tags}) < promo.minAmount) {
                showAffirmation(this.$t("promotion.title"), this.$t("promotion.not_applied_text")
                  .replace("{AMOUNT}", promo.minAmount).replace("{PROMO}", promo.value).replace("{PROMOTYPE}", promo.type == "percent" ? "%" : "$"));
              } else {
                showAffirmation(this.$t("promotion.title"), this.$t("promotion.applied_text")
                  .replace("{PROMO}", promo.value).replace("{PROMOTYPE}", promo.type == "percent" ? "%" : "$"));
              }
            }

            if (promo.triggerType == "x_for_y" && !promo.triggerParameters.hideReminders &&
              PromotionManager.getXYItemsQuantityInCart(promo) % promo.triggerParameters.x == promo.triggerParameters.x - 1) {
              if (promo.mandatory) {
                showAffirmation(
                  this.$t("promotion.title"), this.$t("promotion.mandatory") + " " + promo.triggerParameters.x + this.$t("promotion.for") + promo.triggerParameters.y  + ".");
                  this.$router.push(this.$router.generate("/promotions?scrollTo=" + promo.id));
                return;
              } else {
                let result = await showConfirmation(this.$t("promotion.inactive"), this.$t("promotion.inactive_warning_1") + " "
                  + this.$to(promo.name) + ". " + this.$t("promotion.inactive_warning_2"));
                if (result) {
                  this.$router.push(this.$router.generate("/promotions?scrollTo=" + promo.id));
                  return;
                }
              }
            }
          }
          if (this.branch.getFacebookPixelAnalytics()) {
            this.$analytics.fbq.event("InitiateCheckout", {
              num_items: this.$order.getItemCount(),
              value: parseFloat(this.total.subTotal.toFixed(2))
            });
          }

          let redirect = this.$store.state.inventory.hasSupplements() ? "paymentOptions" : "paymentContact";
          if (this.$user.token) {
            this.$router.push(this.$router.generate("/" + redirect));
          } else {
            this.$router.push(this.$router.generate("/login?redirect=" + redirect));
          }
          EventBus.$emit("show-end-recommendations");
          this.$store.state.mobileCartShown = !this.$store.state.mobileCartShown;
          this.$ga.event("sale-submission", this.company.nameCanonical, this.$user.email);
        }
      },

      goToPromotions() {
        this.$router.push(this.$router.generate("/promotions"));
        this.$store.state.mobileCartShown = false;
      }

    }

  }

</script>

<style lang="scss" scoped>
  .cartTotalContainer {
    border-top: 1px dashed #ccc;
    margin: 0 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-shrink: 0;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.mobile {
      border-top: none;
      margin: 0;
      padding: 0;

      #cartTotal {
        box-shadow: none;
        border-top: 1px solid rgb(230,230,230);
        padding: 0;

        .subtotal {
          font-size: 16px;
        }
        .total {
          font-size: 18px;
        }
        .top {
          padding: 20px;
        }
      }

      .promo, .promo-available {
        margin-bottom: 10px;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
      }

      .emptyContainer {
        height: auto;
        padding: 8px;
      }
    }
  }
  .emptyContainer {
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.8;
    .empty {
      font-size: 16px;
      font-weight: bold;
      color: #d9d9d9;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .xs .emptyContainer .empty {
    text-transform: none;
    font-size: 14px;
  }
  #cartTotal {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;

    &.quickMobile {
      margin-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;
    }

    .top {
      display: flex;
      flex-direction: column;
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      color: #1a1a1a;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #e6e6e6;
      line-height: 1;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .fees {
      display: flex;
      justify-content: space-between;
      color: #808080;
      line-height: 1;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .amount {
        font-weight: bold;
      }
    }

    .taxes {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .tax {
      color: #808080;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      &:not(:last-child) {
        padding-bottom: 5px;
      }

      .amount {
        font-weight: bold;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      color: #1a1a1a;
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
    }
  }

  #iShopFoodApp .orderButton {
    display: flex;
    padding-top: 20px;

    .btn {
      flex-grow: 1;
      height: 60px;
      border-radius: 5px;
      font-size: 22px;

      &.dispatch {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  #iShopFoodApp.xs {
    .cartTotalContainer.mobile #cartTotal {
      border-radius: 0;
    }
  }

  .promo {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    align-items: center;
    .top, .bottom {
      display: flex;
      .name, .description {
        flex-grow: 1;
      }
    }
    .right {
      flex-grow: 1;
    }
    .top {
      margin-bottom: 6px;
      padding-bottom: 4px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 20px;
      font-weight: bold;
      .actions {
        cursor: pointer;
        font-size: 30px;
        line-height: 20px;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      .description {
        color: #808080;
      }
      .amount {
        color: #fc6650;
        font-weight: bold;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
    .circle {
      width: 52px;
      height: 52px;
      background-color: #fc6650;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .svgicon {
        fill: white;
      }
    }
  }

  .promo-available {
    background-color: #fc6650;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      fill: white;
      margin-right: 20px;
    }
    .action {
      fill: white;
      margin-left: 20px;
    }
    .name {
      color: white;
      flex-grow: 1;
    }
  }

</style>
