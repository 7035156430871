<template>
  <div ref="carousel" class="slider">
    <slot></slot>
    <div ref="dots" class="dots" v-if="dots">
      <div class="dot" v-for="n of size" :class="{active: n === page + 1}"></div>
    </div>
  </div>
</template>

<script>

  export default {

    name: "slider",

    props: {
      dots: Boolean,
      size: Number
    },

    data() {
      return {
        page: 0,
        hammer: null
      };
    },

    mounted() {
      this.hammer = new Hammer(this.$refs.carousel);
      this.hammer.get("pan").set({direction: Hammer.DIRECTION_ALL});
      // Bind events
      this.hammer.on("panstart", () => {
        for (let slide of this.$slots.default.filter(e => !!e.tag)) {
          slide.elm.style.transition = "none";
        }
      });
      this.hammer.on("panend", event => {
        let changed = false;
        // Change page
        if (event.deltaX > 60 && this.page > 0) {
          this.page--;
          changed = true;
        } else if (event.deltaX < -60 && this.page < this.getPageCount() - 1) {
          this.page++;
          changed = true;
        }
        if (changed && this.page + 1 !== this.size) {
          this.$emit("changed", this.page);
        } else if (changed && this.page + 1 === this.size) {
          this.$emit("finished");
        }
        // Animate and transition
        for (let slide of this.$slots.default.filter(e => !!e.tag)) {
          slide.elm.style.transition = "transform 0.2s ease-in-out";
        }
        this.offset();
      });
      this.hammer.on("pan", event => {
        this.offset(event.deltaX);
      });
    },

    computed: {
      getPage() {
        return this.page;
      },
    },

    methods: {
      offset(offset = 0, skipAnimation = false) {
        if ((this.page == 0 && offset > 0) || (this.page == this.getPageCount() - 1 && offset < 0)) {
          offset = 0;
        }
        if (this.$slots.default) {
          for (let slide of this.$slots.default.filter(e => !!e.tag)) {
            if (skipAnimation) {
              slide.elm.style.transition = "none";
              setTimeout(() => {
                slide.elm.style.transition = "transform 0.2s ease-in-out";
              }, 0);
            }
            slide.elm.style.transform = "translateX(calc(" + (-this.page * 100) + "% + " + offset + "px))";
          }
        }
      },
      getPageCount() {
        if (!this.$slots.default) {
          return 0;
        }
        return this.$slots.default.filter(e => !!e.tag).length;
      }
    }

  }

</script>

<style lang="scss" scoped>

  .slider {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    & > div {
      width: 100%;
      min-width: 100%;
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;

    $dot-size: 12px;
    .dot {
      width: $dot-size;
      height: $dot-size;
      background-color: #ccc;
      border-radius: 100%;
      margin: 0 5px;

      &.active {
        background-color: #fc6650;
      }
    }
  }

</style>