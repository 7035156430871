<template>
  <div id="paymentcontact">

    <PageTitle>
        <svgicon src="icons/facturation.svg" class="icon" :height="24" :width="24"></svgicon>
        <template v-if="!$store.getters.isMobileOrTablet">{{$t('payment.submit_order')}}</template>
        <template v-if="$store.getters.isMobileOrTablet">{{$t('confirmation')}}</template>
    </PageTitle>

    <div class="page-content" :class="{mobile: $store.getters.isMobileOrTablet}">

      <div class="card">
          <template v-if="!isError">
              <svgicon src="icons/check-circle.svg" class="icon check" :height="35" :width="35"></svgicon>
              <div class="container">
                <div class="title">{{$t('payment.order_submitted')}}</div>
                <div class="text">{{$t('payment.order_received')}}</div>
              </div>
          </template>
          <template v-if="isError">
              <svgicon src="icons/error-circle.svg" class="icon check error" :height="35" :width="35"></svgicon>
              <div class="container">
                  <div class="title">{{$t('error.problem')}}</div>
                  <div class="text" v-html="$t('error.submit_sale')"></div>
              </div>
          </template>
      </div>

      <Panel class="pos-failed" :title="$t('payment.not_received')" icon="icons/warning-red.svg" v-if="posFailed">
        <div class="text" v-html="$t('payment.not_received_description')"></div>
        <div class="phone" v-if="company && company.phoneNumber">
          <a :href="'tel:' + company.phoneNumber" class="btn btn-primary">
            <svgicon src="icons/phone.svg" :height="15" :width="15"></svgicon>
            {{company.phoneNumber}}
          </a>
        </div>
      </Panel>

      <Panel class="tracking" :title="$t('tracking.title')" icon="icons/track.svg" v-if="trackingUrl" collapsible >
        <div class="text" >{{ $t("tracking.text") }}</div>
        <ExternalLink class="btn btn-primary tracking__button" :url="trackingUrl">
          <svgicon src="icons/track.svg" class="icon" :height="20" :width="20"></svgicon>
          {{ $t("tracking.button") }}
        </ExternalLink>
      </Panel>

      <Panel class="loyalty" :title="$t('payment.fidelity_points')" icon="icons/flouze.svg" v-if="gain" collapsible>
        <div class="text gain">{{$t('payment.fidelity_gain_1')}} <b>{{gain['$']}}$</b> {{$t('payment.fidelity_gain_2')}}</div>
      </Panel>

      <Panel :title="$t('payment.your_access')" icon="icons/qr-code.svg" v-if="codes" collapsible>
        <p class="center">{{$t('payment.your_access_description')}}</p>
        <div class="qr-codes">
          <template v-for="code of codes">
            <QrCode class="qr" :data="code.code" :name="code.name"></QrCode>
          </template>
        </div>
      </Panel>

      <div class="filler"></div>

      <div class="buttons" v-if="branch && company">
        <router-link :to="{name: 'tickets'}" v-if="ticketMode" class="btn btn-secondary">{{$t('payment.back_to_tickets')}}</router-link>
        <template v-if="!ticketMode">
          <router-link :to="{name: 'menu'}" v-if="!$user.isDispatchUser()" class="btn btn-secondary">{{$t('payment.back_to_menu')}}</router-link>
          <router-link :to="{name: 'dispatch'}" v-if="$user.isDispatchUser()" class="btn btn-secondary">{{$t('payment.back_to_dispatch')}}</router-link>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
  import Steps from "@/components/payment/Steps.vue";
  import QrCode from "@/components/tools/QrCode";
  import Illustration from "@/components/modal/Illustration.vue";
  import ExternalLink from "@/components/tools/ExternalLink";

  export default {
    name: "paymentComment",

    components: { Steps, Illustration, QrCode, ExternalLink },

    data() {
      return {
        gain: null,
        codes: null,
        saleId: null,
        posFailed: false,
        trackingUrl: null
      };
    },
    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      branch() {
        return this.$store.state.currentBranch;
      },
      ticketMode() {
        return this.$store.state.ticketMode;
      },
      isError() {
        return !!this.$route.query.error;
      }
    },
    mounted() {
      /* PayFacto Secure Fields bug : cannot process 2 sales without reloading the page. This query param will be provided after a PayFacto sale to force a location reload */
      if (this.$route.query.payfactoReload) {
        showSpinner();
        //Delete argument from parameters
        this.$router.replace(this.$router.generate("/paymentComment?sale=" + this.$order.id));
        //Delete order and reload page
        this.$nextTick(() => {
          this.$order.reset();
          window.location.reload();
        });
        return;
        /* Regular sale */
      } else {
        this.saleId = this.$order.id || this.$route.query.sale;
        if (!this.saleId) {
          this.$router.push(this.$router.generate(this.ticketMode ? "/tickets" : "/menu"));
          return;
        }
      }
      this.$order.reset();
      this.$user.clearOnBehalfOf();
      setConfiguration("gift-card", undefined, sessionStorage);
      if (!this.$user) {
        this.$router.push(this.$router.generate("/login?redirect=paymentComment"));
        return;
      }
      if (getConfiguration("last-loyalty-gain")) {
        this.gain = getConfiguration("last-loyalty-gain");
        setConfiguration("last-loyalty-gain", null);
      }
      if (getConfiguration("codes")) {
        this.codes = getConfiguration("codes");
        setConfiguration("codes", null);
      }
      if (getConfiguration("trackingURL")) {
        this.trackingUrl = getConfiguration("trackingURL");
        setConfiguration("trackingURL", null);
      }
      if (getConfiguration("pos-failed")) {
        this.posFailed = true;
        setConfiguration("pos-failed", null);
      }
      this.$store.commit("showNavigationCart", true);
      if (this.$user.isAnonymous()) {
        // Login out anynymous user after order
        this.$user.reset();
        setConfiguration("token", null);
        setConfiguration("token", null, sessionStorage);
      }
    }
  }
</script>

<style lang="scss" scoped>

  #iShopFoodApp .page-content {
    display: flex;
    flex-direction: column;
  }

  .page-content.mobile {
    .title {
      text-transform: none;
      font-size: 18px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid rgb(217,217,217);
    }
    .text {
      font-size: 14px;
    }
    .card {
      box-shadow: none;
      border-bottom: 1px solid rgb(230,230,230);
      border-radius: 0;
    }
  }

  .filler {
    flex-grow: 1;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .container {
      flex-grow: 1;
    }

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
    }
    .text {
      color: #808080;
    }
  }

  .check {
    fill: #48a748;
    margin-right: 20px;
    flex-shrink: 0;

      &.error {
          fill: #ed2937;
      }
  }

  #iShopFoodApp .buttons {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    .btn {
      flex-grow: 1;
      flex-basis: 0;
      padding: 20px;
      font-size: 20px;
      text-decoration: none;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .back {
      background-color: white;
      color: #ed2937;
    }
    .next {
      background-color: #ed2937;
      color: white;
      margin-left: 40px;
    }
    .disable {
      color: lighten(#ed2937, 35%);
      cursor: initial;
      &:hover {
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  #iShopFoodApp.xs {
    .page-content {
      padding: 0;

      &:after {
        content: none;
      }
    }
    .buttons {
      span {
        display: none;
      }
      .back, .next {
        margin: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 1;
      }
    }
    textarea {
      min-width: 100%;
      min-height: 50px;
      font-size: 16px;
      padding: 8px;
      font-family: 'Roboto';
      resize: none;
      border: 1px solid rgb(217,217,217);
    }
  }

  #iShopFoodApp .qr-codes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .qr {
      padding: 10px;
    }
  }

  .center {
    text-align: center;
  }

  .thankYou {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
  }

  .pos-failed {
    .phone {
      display: flex;
      margin-top: 10px;
      .svgicon {
        margin-right: 10px;
      }
    }
  }

  #iShopFoodApp {
    .tracking__button {
      margin-top: 16px;
      display: inline-flex;
      justify-content: flex-start;
      .icon {
        margin-right: 10px;
      }
    }
  }

</style>
