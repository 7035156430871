<template>
  <div class="tip-holder">
    <div class="input" v-if="enabled">
      <label>{{$t('payment.tip_type')}}</label>
      <select v-model="$order.tip.type">
        <option value="none" v-if="!shouldHideNoneTipOption">{{$t('payment.none')}}</option>
        <option value="percent">{{$t('payment.percent')}}</option>
        <option value="amount" v-if="!shouldHideFixedAmountTipOption">{{$t('payment.fix_amount')}}</option>
      </select>
    </div>

    <ul class="tip-amount" v-if="enabled && $order.tip.type === 'percent'">
      <li v-for="percent of tipOptions" :class="{selected: parseInt(percent) === parseInt($order.tip.amount)}"
          @click="$order.tip.amount = percent" :key="percent">
        {{percent}}%
      </li>
    </ul>
    <div class="input amount-input" v-if="enabled && $order.tip.type === 'amount'">
      <label>{{$t('payment.tip_amount')}}</label>
      <input v-model="$order.tip.amount" type="number" placeholder="00.00" step="0.01" min="0" class="amount">
    </div>
  </div>
</template>

<script>
  export default {
    name: "tip",

    props: {
      enabled: Boolean
    },

    mounted() {
      this.clearTip();

      this.$nextTick(() => {
        this.setPreselectedTip();
      });
    },

    watch: {
      "$order.tip.type": function (newValue, oldValue) {
        this.clearTip();
      },
      "$order.tip.amount": function() {
        if (this.$order.tip.amount < 0) {
          this.$order.tip.amount = null;
        }
      },
      enabled: function () {
        this.setPreselectedTip();
      }
    },

    computed: {
      company() {
        return this.$store.state.currentCompany;
      },
      canTip() {
        return this.company && this.company.hasTipEnabled(this.$order.method);
      },
      shouldHideNoneTipOption() {
        return this.company && this.company.shouldHideNoneTipOption();
      },
      shouldHideFixedAmountTipOption() {
        return this.company && this.company.shouldHideFixedAmountTipOption();
      },
      tipOptions() {
        if (!this.company) {
          return [];
        }
        return this.company.getTipOptions();
      },
      preselectedTipValue() {
        if (!this.company) {
          return;
        }
        return this.company.getPreselectedTipValue();
      }
    },

    methods: {
      clearTip() {
        this.$order.tip.amount = null;
      },
      setPreselectedTip() {
        if (this.enabled && this.shouldHideNoneTipOption) {
          this.$order.tip.type = "percent";
        }
        this.$nextTick(() => {
          if (this.enabled && this.preselectedTipValue) {
            this.$order.tip.amount = this.preselectedTipValue;
          } else {
            this.clearTip();
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp .tip-amount {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      color: var(--primary-color) !important;
      font-size: 16px;
      background-color: #f5f5f5;
      cursor: pointer;
      flex-grow: 1;
      padding: 12px;
      margin: 4px 6px;
      border-radius: 5px;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    li:hover, .selected {
      background-color: var(--primary-color);
      color: white !important;
    }
  }
</style>