//@ts-check
import Item from "@/models/ItemModel";

/**
 * @typedef TicketAssetDefinition
 * @property {number} id
 * @property {string} name
 * @property {string} type
 * @property {string} url
 */

/**
 * @typedef TicketDefinition
 * @property {number} id
 * @property {{fr: string, en: string}} name
 * @property {{fr: string, en: string}} description
 * @property {TicketAssetDefinition} image
 * @property {{method: string, unitPrice: number}[]} priceSchemes
 * @property {boolean} disclaimerPopupActivated
 * @property {{fr: string, en: string}} disclaimerTitle
 * @property {{fr: string, en: string}} disclaimerDescription
 * @property {boolean} taxable
 */

export default class Ticket extends Item {

  /**
   * @param {ItemDefinition} definition
   */
  constructor(definition) {
    super(definition);

    this.image = definition.image && definition.image.url ? definition.image.url : null;
    if (definition.moreData) {
      this.disclaimerPopupActivated = definition.moreData.disclaimerPopupActivated;
      this.disclaimerTitle = definition.moreData.disclaimerTitle;
      this.disclaimerDescription = definition.moreData.disclaimerDescription;
    } else {
      this.disclaimerPopupActivated = definition.disclaimerPopupActivated;
      this.disclaimerTitle = definition.disclaimerTitle;
      this.disclaimerDescription = definition.disclaimerDescription;
    }
    this.quantity = definition.quantity || 1;
  }

  getPrice(method) {
    let priceScheme = this.priceSchemes.find(p => p.method === 'digital');
    if (priceScheme && priceScheme.unitPrice) {
      return priceScheme.unitPrice;
    }
    return 0;
  }

  isIdenticalByValue(item) {
    return (item.id === this.id);
  }

  toDto() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      image: {url: this.image},
      priceSchemes: this.priceSchemes,
      disclaimerPopupActivated: this.disclaimerPopupActivated,
      disclaimerTitle: this.disclaimerTitle,
      disclaimerDescription: this.disclaimerDescription,
      quantity: this.quantity,
      taxable: this.taxable
    }
  }

  getName() {
    return this.name;
  }

  getId() {
    return this.id;
  }

  getAllAppliedPromotions() {
    let promos = [];
    if (this.appliedPromotion.promotion) {
      promos.push(this.appliedPromotion);
    }
    return promos;
  }

  clone() {
    let dto = this.toDto();
    return new Ticket(dto);
  }

  getDescription() {
    return this.description;
  }
}
