<template>
  <div class="category" @click="$emit('selected')">
    <AsyncImage class="image" :fallback="$store.state.defaultItemImage" :image="image"></AsyncImage>
    <div class="info">
      <div class="name">{{$to(category.name)}}</div>
      <div class="description" v-if="!$store.state.showCategoriesDescription">{{category.items.length}} {{$t('menu.products')}} | {{$t('menu.from')}} {{$tc(category.getLowestPrice($order.method).toFixed(2))}}</div>
      <div class="description" v-if="$store.state.showCategoriesDescription">{{$to(category.description)}}</div>
    </div>
  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage.vue";

  export default {
    name: "selectableCategory",

    components: { AsyncImage },

    props: {
      category: Object
    },

    computed: {
      image() {
        if (this.category.image) {
          return this.$store.state.urlFileServer + this.category.image;
        }
        return this.$store.state.defaultItemImage;
      }
    }
  }
</script>

<style lang="scss" scoped>
  $radius: 10px;
  #iShopFoodApp {
    &.pos {
      .category {
        background-color: var(--background-color-2);
      }
    }
  }
  .category {
    width: 300px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: $radius;
    background-color: white;
    margin: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .image {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
  .info {
    padding: 20px;
    position: relative;
    background-color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;

    .name {
      font-weight: bold;
      font-size: 20px;

    }

    .description {
      color: #808080;
    }

  }
</style>
