<template>
  <div id="tickets">

    <MenuHeader></MenuHeader>

    <div class="tickets--title">
      <div class="title">{{$t('tickets.page_title')}}</div>
      <div class="subtitle">{{$t('tickets.page_subtitle')}}</div>
    </div>

    <div class="tickets">
      <div class="ticket" v-for="(ticket, ticketIndex) of tickets" :key="'ticket' + ticketIndex" @click="selectTicket(ticket)"
           :class="{outOfStock: ticket.outOfStock}" v-wave="!ticket.outOfStock">
        <div class="image" :style="{backgroundImage: 'url(\'' + getImage(ticket.image) +  '\''}"></div>
        <div class="ticket--info">
          <div class="left">
            <div class="name">{{$to(ticket.name)}}</div>
            <v-clamp class="description" :max-lines="2">{{$to(ticket.description)}}</v-clamp>
            <div class="price" v-if="!ticket.outOfStock">{{$tc(ticket.getPrice().toFixed(2))}}</div>
            <div class="price" v-if="ticket.outOfStock">{{$t('menu.outOfStock')}}</div>
          </div>
          <div class="right">
            <button class="btn btn-outline" :disabled="ticket.outOfStock">
              {{$t('tickets.view')}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <KioskCart v-if="$kiosk"></KioskCart>
    <TicketModal v-if="selectedTicket" :originalTicket="selectedTicket" :show="showTicketModal" @close="showTicketModal = false"></TicketModal>
  </div>
</template>

<script>
  import MenuHeader from "@/components/menu/MenuHeader.vue";
  import KioskCart from "@/components/kiosk/KioskCart.vue";
  import TicketModal from "@/components/menu/TicketModal";

  export default {
    name: "vouchers",

    components: { MenuHeader, TicketModal, KioskCart },

    data() {
      return {
        selectedTicket: null,
        showTicketModal: false
      }
    },

    async mounted() {
      this.$store.commit("showNavigationCart", true);
      this.$order.setMethod("digital");
    },

    computed: {
      tickets() {
        return this.$store.state.inventory ? (this.$store.state.inventory.tickets || []) : [];
      }
    },

    methods: {
      getImage(image) {
        if (image) {
          return this.$store.state.urlFileServer + image;
        }
      },
      selectTicket(ticket) {
        if (ticket.outOfStock) {
          return;
        }
        this.selectedTicket = ticket;
        this.showTicketModal = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #iShopFoodApp.xs #tickets {
    .tickets {
      margin: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
    .tickets--title {
      margin: 15px;
      text-align: center;
      padding-bottom: 20px;
    }
    .ticket {
      width: 100%;
      max-width: 300px;
      margin: 0 0 30px 0;

      &:first-child {
        margin-top: 10px;
      }

      .image {
        height: 210px;
      }
      .ticket--info {
        flex-direction: column;
        padding: 15px;
      }
      .left {
        width: 100%;
      }
      .right {
        margin-left: 0;
        padding-left: 0;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #e6e6e6;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: none;
        }
      }
    }
  }
  .tickets {
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
    margin-top: 30px;
    overflow-y: auto;
    flex-grow: 1;
    align-content: flex-start;
  }

  .tickets--title {
    margin: 45px 60px 0px 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }

  $radius: 15px;

  #iShopFoodApp .ticket .btn {
    border-radius: 8px;
    padding: 8px 24px;
    white-space: nowrap;
  }

  #iShopFoodApp.kiosk .tickets {
    justify-content: center;
    margin: 0;
  }
  #iShopFoodApp.kiosk .ticket {
    width: 100%;
    max-width: 370px;
    margin: 25px 30px;

    .image {
      height: 200px;
    }
    .ticket--info {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      padding: 25px;

      .name {
        padding-bottom: 10px;
        font-size: 20px;
      }

      .right {
        padding-left: 0;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: none;
        }
      }
    }

    .btn {
      margin-top: 15px;
      padding: 20px 40px;
    }

  }

  @media screen and (max-width: 1100px) and (min-width: 721px) {
    #iShopFoodApp:not(.kiosk) #tickets {
      .ticket--info {
        flex-direction: column;
      }
      .ticket .image {
        height: 200px;
      }
      .left, .right {
        width: 100%;
        border: none;
        margin: 0;
      }
      .right:before {
        content: none;
      }
      .right {
        margin-top: 20px;
        padding-left: 0;

        .btn {
          width: 100%;
        }
      }
    }
  }

  #iShopFoodApp .ticket {
    display: flex;
    flex-direction: column;
    margin: 30px;
    background-color: white;
    border-radius: $radius;
    box-shadow: 0px 5px 10px 0 rgba(2, 3, 2, 0.15);
    width: calc(50% - 80px);
    max-width: 600px;

    &.outOfStock {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255, 0.4);
        border-radius: $radius;
      }
    }

    .image {
      width: 100%;
      height: 290px;
      background-size: cover;
      background-position: center;
      border-radius: $radius $radius 0 0;
    }

    .ticket--info {
      padding: 15px 30px;
      display: flex;
      align-items: center;

      .left {
        flex-grow: 1;
      }

      .right {
        margin-left: 30px;
        padding-left: 30px;
        position: relative;

        &:before {
          content: "";
          height: 60px;
          width: 1px;
          background-color: #e6e6e6;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .name {
        font-size: 18px;
        font-weight: bold;
      }
      .description {
        font-size: 14px;
      }
      .price {
        color: var(--primary-color) !important;
        font-size: 14px;
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }
</style>
