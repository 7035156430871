<template>
  <div class="seat-selector" ref="seats">
    <div v-for="seat of formattedSeats" :key="seat.seat" class="seat" v-wave="!seat.disabled"
         :class="{ selected: seat.selected, multiple: isMultipleSelection, disabled: seat.disabled }"
         @click="onSeatClick(seat)">
      <div class="seat__info">
        <div class="seat__title">{{ $t("pos_seats.seat_no") }}</div>
        <div class="seat__number">{{ seat.seat }}</div>
      </div>
      <div class="check" v-if="isMultipleSelection">
        <svgicon src="/icons/check-thick.svg" :width="14" :height="14"></svgicon>
      </div>
    </div>
    <div class="seat seat__add" @click="addSeat" v-if="hasAddSeat" :class="{ multiple: isMultipleSelection }">
      <svgicon src="/icons/add.svg" :width="40" :height="40"></svgicon>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SeatsSelector",

    props: {
      seats: Array,
      disabledSeats: Array,
      selection: [Number, Array],
      multiple: Boolean
    },

    computed: {
      isMultipleSelection() {
        return Array.isArray(this.selection);
      },
      selectedSeats() {
        if (this.isMultipleSelection) {
          return this.selection;
        } else if (this.selection) {
          return [this.selection];
        }
        return [];
      },
      formattedSeats() {
        return (this.seats || []).map(s => ({
          seat: s,
          selected: this.selectedSeats.includes(s),
          disabled: (this.disabledSeats || []).includes(s)
        }));
      },
      hasAddSeat() {
        return this.$listeners && this.$listeners.addSeat;
      }
    },

    methods: {
      onSeatClick(seat) {
        if (!seat || seat.disabled) {
          return;
        }
        if (seat.selected) {
          this.removeSeat(seat.seat);
        } else {
          this.selectSeat(seat.seat);
        }
      },
      selectSeat(seat) {
        if (!this.isMultipleSelection) {
          this.$emit("update:selection", seat);
          return;
        }
        let index = this.selection.indexOf(seat);
        if (index === -1) {
          let seatsBuffer = this.selection.concat([seat]);
          this.$emit("update:selection", seatsBuffer);
        }
      },
      removeSeat(seat) {
        if (!this.isMultipleSelection) {
          return;
        }
        let index = this.selection.indexOf(seat);
        if (index > -1) {
          let seatsBuffer = [...this.selection.slice(0, index), ...this.selection.slice(index + 1)];
          this.$emit("update:selection", seatsBuffer);
        }
      },
      addSeat() {
        this.$emit("addSeat");
        setTimeout(() => {
          this.$refs.seats.scrollTo({
            top: this.$refs.seats.scrollHeight,
            behavior: "smooth"
          });
        }, 0);
      }
    }
  };
</script>

<style lang="scss" scoped>
.seat-selector {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  align-content: flex-start;

  .seat {
    box-sizing: border-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    padding: 18px;
    border: 4px solid transparent;
    width: 111px;
    position: relative;
    cursor: pointer;
    justify-content: space-between;

    &.multiple {
      width: 150px;
    }

    .seat__info {
      display: flex;
      flex-direction: column;

      .seat__title {
        color: var(--theme-color-2);
        font-size: 16px;
      }

      .seat__number {
        font-size: 30px;
        font-weight: 900;
      }
    }

    .check {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      border: 2px solid var(--primary-color);
      fill: var(--background-color-2);
      margin-left: 18px;
    }

    &.selected {
      border: 4px solid var(--primary-color);

      .check {
        background-color: var(--primary-color);
      }

      .seat__number {
        color: var(--primary-color)
      }
    }

    &.disabled {
      border: 4px solid transparent;
      opacity: 0.5;
      pointer-events: none;

      .check {
        background-color: transparent;
        fill: var(--primary-color);
      }

      .seat__number {
        color: var(--primary-color);
      }
    }
  }

  .seat__add {
    height: 100px;
    justify-content: center;
    align-items: center;
    fill: var(--theme-color-2);
  }
}

</style>