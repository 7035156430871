import { CustomerScreenLoader } from "@/lib/CustomerScreenLoader/CustomerScreenHTMLLoader";
import { IshopPrinter } from "@/lib/printer/IshopPrinter";
import Table from "@/models/pos/TableModel";
import { Util } from "@/util/Util";

/**
 * @param {ActionContext} context
 * @param {{password: string|undefined|null}} additionalContext
 */
function getPosHeaders(context, additionalContext = undefined) {
    let headers = {};
    let employee = context.state.employee;
    if (employee) {
        headers["employee-pin"] = employee.getPin();
        if (additionalContext && additionalContext.password) {
            headers["employee-psw"] = additionalContext.password;
        } else if (employee.getPassword()) {
            headers["employee-psw"] = employee.getPassword();
        }
    }
    return headers;
}

export default {

    /**
     * @param {ActionContext} context
     * @param {{from: number, rangeStart: number, rangeEnd: number}} param
     */
    async getNextAvailableTableNumber(context, { from, rangeStart, rangeEnd }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/pos_util/next_available?from=${from}${rangeStart ? `&range_start=${rangeStart}` : ""}${rangeEnd ? "&range_end=" + rangeEnd : ""}`
            });
            if (response && response.next_available) {
                return { success: true, table: parseInt(response.next_available) };
            }
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {{table: number, company: number}} param
     */
    async getTable(context, { table, company }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/tables/${table}?company=${company}`,
                headers: getPosHeaders(context)
            });
            return { success: true, table: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @deprecated
     * @param {ActionContext} context
     * @param {{employeeId: number, open: Boolean|number, start: datetime?, end: datetime?, search: string?, page: number, perPage: number|undefined}} param
     */
    async getTables(context, { employeeId, open, start, end, search, page, perPage }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/tables?employee=${employeeId}&open=${open}&page=${page}${perPage ? "&perPage=" + perPage : ""}${start ? "&start=" + start : ""}${end ? "&end=" + end : ""}${search ? "&search=" + search : ""}`
            });
            return { success: true, table: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * service = unknown, web, kiosk, app, pos, uber
     * @param {ActionContext} context
     * @param {{service: string, notService: string, employeeId: number, open: Boolean|number, deliveryMethod: string, start: datetime?, end: datetime?, search: string?, page: number, perPage: number|undefined}} param
     */
    async getSalesForPOS(context, { service, notService, employeeId, deliveryMethod, open, start, end, search, page, perPage }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/sales?dto=dual&page=${page}${open ? "&open=" + open : ""}${perPage ? "&perPage=" + perPage : ""}${start ? "&start=" + start : ""}${end ? "&end=" + end : ""}${employeeId ? "&employee=" + employeeId : ""}${search ? "&search=" + search : ""}${service ? "&service=" + service : ""}${notService ? "&notService=" + notService : ""}${deliveryMethod ? "&deliveryMethod=" + deliveryMethod : ""}`
            });
            return { success: true, sales: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{table: Table}} param
     */
    async payTable(context, { table }) {
        try {
            let response = await context.dispatch("request", {
                method: "PUT",
                url: `/api/tables/${table.getTableId()}/pay`,
                data: table.toDtoForPayment(),
                headers: getPosHeaders(context)
            });
            return { success: true, table: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{table: Table}} param
     */
    async closeTable(context, { table }) {
        try {
            let response = await context.dispatch("request", {
                method: "PUT",
                url: `/api/tables/${table.getTableId()}/close`,
                data: table.toDtoForClose(),
                headers: getPosHeaders(context)
            });
            return { success: true, table: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * Print to kitchen and save table to API
     * @param context
     * @param {Table} table
     * @returns {Promise<void>}
     */
    async sendTable(context, { table }) {
        let saveResponse = await context.dispatch("saveTable", { table: table });
        if (saveResponse.success) {
            await IshopPrinter.load({
                sale: table,
                company: context.state.currentCompany,
                configuration: context.state.posConfiguration,
                isSuccess: true
            }).printDetailsToKitchen(true);
        }
        return saveResponse;
    },

    /**
     * @param {ActionContext} context
     * @param {{table: Table}} param
     */
    async saveTable(context, { table }) {
        if (!table) {
            return { success: false, response: "Table is required" };
        }
        try {
            let response = await context.dispatch("request", {
                method: "PUT",
                url: `/api/tables/${table.getTableId()}?company=${table.getCompanyId()}`,
                data: table.toDto(),
                headers: getPosHeaders(context)
            });
            return { success: true, table: response };
        } catch (e) {
            console.log(e);
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{company: string|number, table: string}} param
     */
    async lockTable(context, { company, table }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: `/api/tables/${table}/lock`,
                data: { company },
                headers: getPosHeaders(context)
            });
            return { success: true, tableLock: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{company: string|number, table: string}} param
     */
    async unlockTable(context, { company, table }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: `/api/tables/${table}/unlock`,
                data: { company },
                headers: getPosHeaders(context)
            });
            return { success: true, tableLock: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async transferBetweenTable(context, { table, employeePin, password, targetTable, targetSeat, details }) {
        try {
            let savedTableResponse = await context.dispatch("saveTable", { table: context.state.table });
            if (!savedTableResponse.success) {
                return savedTableResponse;
            }
            let biggestId = 0;
            let companyId = context.state.currentCompany?.id;
            // Try to get the other table if opened
            let tableResponse = await context.dispatch("getTable", { table: targetTable, company: companyId });
            if (tableResponse.success) {
                // Get the biggest id among the details
                tableResponse.table.details.forEach(d => {
                    if (d.altId > biggestId) {
                        biggestId = d.altId;
                    }
                });
            } else {
                // Create a new table
                let newTable = new Table();
                newTable.initialize({
                    table: targetTable,
                    company: companyId,
                    method: context.state.table.method
                });
                let newTableResponse = await context.dispatch("saveTable", { table: newTable });
                if (!newTableResponse.success) {
                    return newTableResponse;
                }
            }
            let response = await context.dispatch("request", { //table
                method: "POST",
                url: `/api/tables/${table}/transfer_to`,
                data: {
                    table: targetTable,
                    details: details.map(d => {
                        let dto = d.toDto();
                        return {
                            id: dto.altId,
                            newId: dto.altId + biggestId,
                            newSeat: targetSeat,
                            newParentDetail: dto.parentDetail ? dto.parentDetail + biggestId : null
                        };
                    })
                },
                headers: getPosHeaders(context)
            });
            let updatedTable = new Table(response);
            context.state.table = updatedTable;
            return { success: true, table: updatedTable };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{company: string|number}} param
     */
    async punchEmployee(context, { company, time }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: "/api/timesheet_entries/punch",
                data: { company, time },
                headers: getPosHeaders(context)
            });
            return { success: true, entry: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{company: string|number}} param
     */
    async getEmployeeStatus(context, { company, time }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/employee_status?company=${company}${time ? `&time=${time}` : ""}`,
                headers: getPosHeaders(context)
            });
            return { success: true, employee: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param {{company: string|number}} param
     */
    async getEmployees(context, { company }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/companies/${company}/employees`
            });
            return { success: true, employees: response.employees };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param {{employee: number, company: number|undefined, start: datetime|undefined, end: datetime|undefined, page: number|undefined, perPage: number|undefined}} param
     */
    async getEmployeeTimeSheets(context, { company, employee, start, end, page, perPage }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/timesheet_entries?employee=${employee}${start ? "&start=" + start : ""}${end ? "&end=" + end : ""}${page ? "&page=" + page : ""}&perPage=${perPage || "-1"}`
            });
            return { success: true, timeSheets: response.data };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param {{employee: number, company: number|undefined, start: datetime, end: datetime}} param
     */
    async createEmployeeTimeSheet(context, { employee, company, start, end, note }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: "/api/timesheet_entries",
                data: { company, employee, start, end, note }
            });
            return { success: true, timeSheet: response.data };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param {{id: number, employee: number|undefined, company: number|undefined, start: datetime|undefined, end: datetime|undefined}} param
     */
    async editEmployeeTimeSheet(context, { id, employee, company, start, end, note }) {
        try {
            let response = await context.dispatch("request", {
                method: "PUT",
                url: `/api/timesheet_entries/${id}`,
                data: { company, employee, start, end, note }
            });
            return { success: true, timeSheet: response.data };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param saleData
     */
    processExternalPosSale(context, { saleData }) {
        if (!saleData.print) {
            saleData.print = {
                count: 0,
                status: "pending"
            };
        }
        context.state.externalPosSaleQueue = [saleData, ...context.state.externalPosSaleQueue];
    },

    async generateInvoicesIfNeededForTable(context, { seats, table }) {
        if (!table || !seats) {
            return false;
        }
        let pendingPayments = table.payments; //Backup payments
        if (table.shouldGenerateInvoices(seats)) {
            await table.generateInvoices(seats);
            let saveResponse = await context.dispatch("sendTable", { table: table });
            if (!saveResponse.success) {
                toast({
                    message: saveResponse.response,
                    type: "error",
                    title: window.translate("error.title")
                });
                return false;
            }
            // Load saved table
            context.state.table = new Table(saveResponse.table);
            context.state.table.payments = pendingPayments;
        }
        return true;
    },

    /**
     * @param {number[][]} seats
     * @returns {Promise<{success: boolean, error: any}>}
     */
    async payAndFinalizeTableWithSeats(context, { seats, printReceipt = true }) {
        showSpinner();
        // 1. Generate invoices
        let success = await context.dispatch("generateInvoicesIfNeededForTable", { seats: seats, table: context.state.table });
        if (!success) {
            hideSpinner();
            return { success: false, error: "generateInvoicesIfNeededForTable" };
        }
        // 2. assign invoices to payments
        context.state.table.assignInvoiceNumbersToPayments();
        // 3. Pay
        let paymentResponse = await context.dispatch("payTable", { table: context.state.table });
        if (!paymentResponse.success) {
            hideSpinner();
            return { success: false, error: paymentResponse.response };
        }
        // 4. Close
        let closeResponse = await context.dispatch("closeTable", { table: context.state.table });
        let rawReceipt = await context.dispatch("handleReceiptForClosedTable", { table: closeResponse.table, success: closeResponse.success, printReceipt });
        if (context.state.table.hasCashPayment()) {
            context.state.posConfiguration.openCashDrawer();
        }
        if (closeResponse.success) { //SUCCESS
            context.state.table.close();
            hideSpinner();
        } else {
            hideSpinner();
            return { success: false, error: closeResponse.response };
        }
        CustomerScreenLoader.switchToOrderCompleted(
            "https://ishopfood.s3-us-west-2.amazonaws.com/default/5514dbccd3346e43515832708cc844359189e809.jpeg",
            window.translate("pos_customerDisplay.thanks"),
            window.translate("pos_customerDisplay.order_complete_text"));
        return { success: true, rawReceipt };
    },

    async handleReceiptForClosedTable(context, { table, success, printReceipt }) {
        try {
            await IshopPrinter.load({
                sale: table,
                company: context.state.currentCompany,
                configuration: context.state.posConfiguration,
                isSuccess: success
            }).printSale(printReceipt);
            return IshopPrinter.getRaw();
        } catch (e) {
            toast({ message: window.translate("pos.printing.error"), type: "error", title: window.translate("error.title") });
        }
    },

    /**
    * @param {{method: string, tableNumber: string?}} options
    */
    async initializeNewTable(context, { method, tableNumber }) {
        let companyId = context.state.currentCompany.id;
        let data = {
            company: companyId,
            method: method
        };
        if (tableNumber) {
            let response = await context.dispatch("getTable", { table: tableNumber, company: companyId });
            if (response.success) {
                data = Object.assign(data, response.table);
                context.state.table = new Table(data);
                context.state.table.setMethod(method);
            } else {
                data.table = tableNumber;
                context.state.table = new Table();
                context.state.table.initialize(data);
            }
        } else {
            let response = await context.dispatch("getNextAvailableTableNumber", {
                from: context.state.posConfiguration.getTransactionNumber(),
                rangeStart: context.state.posConfiguration.minimumTransactionNumber,
                rangeEnd: context.state.posConfiguration.maximumTransactionNumber
            });
            if (response.success) {
                data.table = response.table;
                context.state.table = new Table();
                context.state.table.initialize(data);
            } else {
                return { success: false, error: response };
            }
        }
        context.state.table.loadPromotions();
        context.state.currentSeat = 1;
        return { success: true };
    },

    /**
     * @param context
     * @param {{deliveryMethod: string, start: datetime?, end: datetime?, search: string?, page: number, perPage: number|undefined}} param
     */
    async getExternalSales(context, { deliveryMethod, start, end, search, page, perPage }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/external_sales?deliveryMethod=${deliveryMethod}&page=${page}${perPage ? "&perPage=" + perPage : ""}${start ? "&start=" + start : ""}${end ? "&end=" + end : ""}${search ? "&search=" + search : ""}`
            });
            return { success: true, orders: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {string} email
     */
    async send2FA(context, email) {
        try {
            await context.dispatch("request", {
                method: "POST",
                url: "/api/device_onboarding/send_2FA",
                data: { email }
            });
            return { success: true };
        } catch (e) {
            console.log(e);
            return { success: false, status: e.status, data: Util.parseResponseError(e.response) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{email: string, code: string}} param1
     */
    async consume2FA(context, { email, code }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: "/api/device_onboarding/consume_2FA",
                data: { email, code }
            });
            return { success: true, data: response };
        } catch (e) {
            console.log(e);
            return { success: false, status: e.status, response: Util.parseResponseError(e.response) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{branchId: number, companyId: number}} param
     */
    async getWorkstationUpdateData(context, { branchId, companyId }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/branches/" + branchId + "/companies/" + companyId + "/last_updates" });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {number} company
     * @param {string} storageKey
     */
    async getPosDataStorage(context, { company, storageKey }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/pos_data_storage/" + storageKey + "?company=" + company });
            return { success: true, data: response && response.data ? response.data : {} };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     * @param {number} company
     */
    async getRevenueCenters(context, { company }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: `/api/companies/${company}/revenue_centers` });
            return { success: true, data: response && response.revenueCenters ? response.revenueCenters : [] };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param context
     */
    async getPosFunctions(context) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/pos_util/functions" });
            return { success: true, data: response && response.functions ? response.functions : [] };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {number} company
     */
    async getPrintingGroups(context, { company }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/printer_groups?company=" + company });
            return { success: true, data: response && response.data ? response.data : [] };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {string} appName
     */
    async getAppVersions(context, { appName }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/app_versions/" + appName + "/history" });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {number} company
     */
    async getPosRoles(context, { company }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/companies/" + company + "/pos_roles" });
            return { success: true, data: response.posRoles };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async sendReceiptByEmail(context, { receipt, invoice, email, locale }) {
        try {
            let response = await context.dispatch("request", {
                method: "POST",
                url: `/api/invoices/${invoice}/receipt_by_email`,
                data: {
                    receipt,
                    email,
                    language: locale
                }
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async getTips(context, { employee }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/tip_entries?perPage=10${employee ? "&employee=" + employee : ""}`
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async sendTip(context, { amount, currency, date, password }) {
        try {
            await context.dispatch("request", {
                method: "POST",
                url: "/api/tip_entries",
                data: { amount, currency, date },
                headers: getPosHeaders(context, { password }) /* TODO remove password prompt in caller of sendTip */
            });
            return { success: true };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * Returns a list of cash drawers for the current company
     * @param {ActionContext} context
     */
    async getCashDrawers(context) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: "/api/cash_drawers" });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async getCashDrawer(context, { altId }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: `/api/cash_drawers/${altId}` });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async getCashDrawerState(context, { altId }) {
        try {
            let response = await context.dispatch("request", { method: "GET", url: `/api/cash_drawers/${altId}/state` });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async depositCashDrawer(context, { altId, amount, currency }) {
        try {
            await context.dispatch("request", {
                method: "POST",
                url: `/api/cash_drawers/${altId}/deposit`,
                data: { amount, currency }
            });
            return { success: true };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async syncCashDrawer(context, { amount, expectedAmount, currency, altId, justification }) {
        try {
            await context.dispatch("request", {
                method: "POST",
                url: `/api/cash_drawers/${altId}/sync`,
                data: {
                    resolveState: [{
                        amount: amount - expectedAmount,
                        currency,
                        note: justification
                    }],
                    actualState: [{
                        amount,
                        currency
                    }]
                }
            });
            return { success: true };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    async refundInvoice(context, invoiceNumber, note) {
        try {
            let response = await context.dispatch("request", {
               method: "POST",
               url: "/api/refunds",
               data: {
                   invoice: invoiceNumber,
                   note: note,
                   tipsAreRefundable: false
               }
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{employee: int, skip:int}} param
     */
    async getLatestBatchReading(context, { employee, workstation, skip }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/latest_batch?${employee ? "employee=" + employee : ""}${workstation ? (employee ? "&workstation=" : "workstation=") + workstation : ""}${skip ? "?skip=" + skip : ""}`
            });
            return { success: true, entry: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{ id: int }} param
     */
    async closeBatch(context, { id }) {
        try {
            let response = await context.dispatch("request", {
                method: "PUT",
                url: `/api/batches/${id}`,
                data: { closed: true }
            });
            return { success: true, entry: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{ id: int }} param
     */
    async getBatch(context, { id }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/batches/${id}`,
                data: { closed: true }
            });
            return { success: true, entry: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{page: number, perPage: number}} param
     */
    async getWorkstations(context, { page, perPage }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/workstations${page ? "?page=" + page : ""}${perPage ? (page ? "&perPage=" : "?perPage=") + perPage : ""}`
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    },

    /**
     * @param {ActionContext} context
     * @param {{id: number}} param
     */
    async getRequiredBatchActions(context, { id }) {
        try {
            let response = await context.dispatch("request", {
                method: "GET",
                url: `/api/batches/${id}/actions_required`
            });
            return { success: true, data: response };
        } catch (e) {
            return { success: false, status: e.status, response: Util.parseResponseError(e) };
        }
    }
};
