<template>
    <div id="method">

        <div class="method--how">
            {{$t('kiosk_method.how')}}
        </div>

        <div class="methods">
            <div class="method--type method__in-place context-menu-event" @click="setMethod('inPlace')" @contextmenu="setMethod('inPlace')" v-if="kioskConfiguration.methods.inPlace">
                <div class="type--icon">
                    <svgicon src="icons/plate.svg" :height="230" :width="230"></svgicon>
                </div>
                <div class="type--name">{{$t('kiosk_method.in_place')}}</div>
            </div>
            <div class="method--type method__takeout" @click="setMethod('takeout')" @contextmenu="setMethod('takeout')" v-if="kioskConfiguration.methods.takeout">
                <div class="type--icon">
                    <svgicon src="icons/comptoir.svg" :height="170" :width="170"></svgicon>
                </div>
                <div class="type--name">{{$t('kiosk_method.takeout')}}</div>
            </div>
            <div class="method--type method__grabngo" @click="setMethod('grabngo')" @contextmenu="setMethod('grabngo')" v-if="kioskConfiguration.methods.grabngo">
                <div class="type--icon">
                    <svgicon src="icons/scan-barcode.svg" :height="170" :width="170"></svgicon>
                </div>
                <div class="type--name">{{$t('kiosk_method.grabngo')}}</div>
            </div>
        </div>

      <KioskLocaleSwitch></KioskLocaleSwitch>

    </div>
</template>

<script>
    import KioskLocaleSwitch from "@/components/kiosk/KioskLocaleSwitch";
    import { AIRxTouchDefaultLayoutSetting, GetElementBounds } from "airxtouch-toolkit-proxy";

    export default {
      name: "kioskMethod",

      components: { KioskLocaleSwitch },

      mounted() {
        if (this.methodsActivated.length === 1) {
          this.setMethod(this.methodsActivated[0]);
        }
        this.$order.resetBranding();
        this.initAirxTouchDisplayLayout();
      },

      computed: {
        kioskConfiguration() {
          return this.$store.state.kioskConfiguration;
        },
        methodsActivated() {
          return this.kioskConfiguration.getMethodsActivated();
        }
      },

      methods: {
        setMethod(method) {
          if (method === "grabngo") {
            this.kioskConfiguration.activateGrabngo();
            method = "takeout";
          } else {
            this.kioskConfiguration.deactivateGrabngo();
          }
          this.$order.setMethod(method);
          this.$router.push(this.$router.generate("/menu"));
        },
        initAirxTouchDisplayLayout() {
          if (this.kioskConfiguration && this.kioskConfiguration.isVerticalAIRxTOUCH() && window.isExecutable()) {
            this.$nextTick(() => {
              window.airxtouch__submitLayout([
                GetElementBounds("method", 0, AIRxTouchDefaultLayoutSetting.DirectClick), // Click area : Whole page
              ]);
            });
          }
        },
      }
    }
</script>

<style lang="scss" scoped>
    #iShopFoodApp #method {
        align-items: center;
        justify-content: center;
        position: relative;

        .methods {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 0 90px;
        }

        .method--how {
            font-size: 45px;
            text-align: center;
            font-weight: bold;
            padding: 0 150px 150px 150px;
        }

        .method--type {
            $size: 360px;
            background-color: white;
            border-radius: 20px;
            fill: var(--primary-color);
            width: $size;
            height: $size;
            margin: 40px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);

            .type--icon {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .type--name {
                padding: 20px 40px;
                text-align: center;
                font-size: 26px;
                text-transform: uppercase;
                color: var(--primary-color);
                font-family: 'Segoe UI Black', sans-serif;
            }
        }
    }

    .locale-switch {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
    }
</style>
