<template>
  <div class="search-bar__container" :class="{wide: wide}">
    <div class="search-bar">
      <svgicon class="search-bar__icon" src="icons/search.svg" :height="25" :width="25"></svgicon>
      <PosInput type="text" v-model="$store.state.inventorySearchString" :place-holder="placeHolder"></PosInput>
    </div>
  </div>
</template>

<script>
  import PosInput from "@/components/pos/PosInput";
  import EventBus from "@/lib/eventBus";

  export default {
    name: "InventorySearchBar",

    components: { PosInput },

    props: {
      placeHolder: String,
      wide: Boolean
    },

    mounted() {
     EventBus.$on("pos-category-changed", () => {
       this.$store.state.inventorySearchString = '';
     });
    },

    beforeDestroy() {
      EventBus.$off("pos-category-changed");
    }
  }
</script>

<style lang="scss" scoped>
  .search-bar__container {
    padding: 0 15px;
    max-width: 250px;
    &.wide {
      width: 375px;
      max-width: 375px;
    }
  }

  #iShopFoodApp .search-bar {
    width: 100%;
    position: relative;

    .search-bar__icon {
      position: absolute;
      left: 15px;
      top: 15px;
      fill: #d9d9d9;
    }

    .search-bar__input {
      margin: 0 0 20px 0;
      width: 100%;
      padding: 14px 15px 14px 60px;
      background-color: var(--background-color-2);
      font-size: 20px;
      font-weight: 500;
      color: var(--theme-color);
      border-radius: 12px;
      border: 1px solid var(--border-color);
      font-family: inherit;

      &::-webkit-input-placeholder {
        color: #bfbfbf;
        font-weight: 500;
      }
    }
  }
</style>