<template>
  <div id="seatSelectionModal" class="modal" :class="{show: show}">
    <div class="modal-content">
      <div class="modal-header">
        <svgicon src="icons/seat.svg" :height="20" :width="20"></svgicon>
        <div class="header__text">{{$t('pos_seat_selection_modal.title')}}</div>
      </div>

      <div class="seat-selection-modal__description">
        {{$t('pos_seat_selection_modal.description')}}
      </div>

      <SeatsSelector :seatsPool="seats" :selection="localSeat" class="seats" @update:selection="localSeat = $event"></SeatsSelector>

      <div class="modal-footer">
        <button class="btn btn-secondary" @click="back">
          <svgicon src="icons/back-full.svg" :height="25" :width="25"></svgicon>
          {{$t('back')}}
        </button>
        <button class="btn btn-primary" @click="setSeatSelection">
          <svgicon src="icons/check.svg" :height="25" :width="25"></svgicon>
          {{$t('confirm')}}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import SeatsSelector from "@/components/pos/SeatsSelector";

  export default {
    name: "SeatSelectionModal",

    components: { SeatsSelector },

    data() {
      return {
        show: false,
        localSeat: 0,
        currentSeatBuffer: 1
      };
    },

    mounted() {
      EventBus.$on("show-seat-selection", (currentSeat) => {
        if (!currentSeat) {
          return;//TODO we should prob get the seat number from a global var or something.
          //TODO otherwise maybe it should be a prop, this popup should be in the cart and we should teleport it to the bdy. TBD
        }
        this.localSeat = currentSeat;
        this.currentSeatBuffer = currentSeat;
        this.show = true;
      });
    },

    beforeDestroy() {
      EventBus.$off("show-seat-selection");
    },

    computed: {
      seats() {
        //TODO Seats: we will need to get this from somewhere probably or compute them from invoices according to context. for now they will be hardcoded
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
      }
    },

    methods: {
      setSeatSelection() {
        //TODO we need to set the seat somewhere, if teleported and passed as prop we emit otherwise we will need to see where we keep that value.
        this.show = false;
      },
      back() {
        this.show = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
#iShopFoodApp #seatSelectionModal {
  .modal-content {
    border-radius: 40px;
    padding: 40px;
    max-width: 601px;

    .seat-selection-modal__description {
      padding: 30px 0;
      font-size: 20px;
      font-weight: 700;
    }

    .seats {
      max-height: 500px;
      height: 500px;
      overflow-y: scroll;
      padding-right: 10px;
    }

    .modal-header {
      .svgicon {
        margin-right: 20px;
        fill: var(--primary-color);
      }
      .header__text {
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
      }
    }

    .modal-footer {
      padding-top: 20px;
      border-top: 1px dashed #e6e6e6;
      margin-top: 20px;

      .btn {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px;
        width: auto;
        line-height: 1;
        font-family: 'Roboto', sans-serif;

        .svgicon {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>