/**
 * @typedef InvoiceDefinition
 * @property {number} subTotal
 * @property {number} tipTotal
 * @property {number} serviceFees
 * @property {number} surchargeFees
 * @property {number} total
 * @property {number[]} seats
 * @property {boolean} void
 * @property {{name: string, total: number}[]} taxes
 * @property {{id: number, name: string, total: number}[]} promotions
 */
export default class Invoice {

    constructor(definition) {
        this.subTotal = definition.subTotal || 0;
        this.tipTotal = definition.tipTotal || 0;
        this.serviceFees = definition.serviceFees || 0;
        this.surchargeFees = definition.surchargeFees || 0;
        this.cashRounding = definition.cashRounding || 0;
        this.total = definition.total || 0;
        this.seats = definition.seats || [];
        this.taxes = definition.taxes;
        this.promotions = definition.promotions || [];
        this.void = definition.void || false;
        this.voidAt = definition.voidAt;
        this.number = definition.number;
        this.hashCode = definition.hashCode || 0;
        this.id = definition.id;
    }

    toDto() {
        return {
            id: this.id || null,
            number: this.number,
            subTotal: this.subTotal,
            tipTotal: this.tipTotal,
            serviceFees: this.serviceFees,
            surchargeFees: this.surchargeFees,
            cashRounding: this.cashRounding,
            void: this.void,
            voidAt: this.voidAt,
            total: this.total,
            seats: this.seats,
            taxes: this.taxes,
            hashCode: this.hashCode,
            promotions: this.promotions
        };
    }
}