<template>
  <div class="shops" :class="{selected: !!currentCompany}">
    <div class="none" v-if="companies.length == 0">{{$t('order_options.no_company')}}</div>
    <div class="shop" :class="{selected: currentCompany == company}" @click="setCompany(company)"
         v-for="company of companies" :key="company.name">
      <div class="text">
        <div class="top">
          <div class="bold">{{ company.name }}</div>
        </div>
        <div class="bottom" v-if="company.address">
          {{ company.address.address }}, {{ company.address.city }} {{ company.address.state }} {{ company.address.zipCode }}
          <div class="distance" v-if="company.distanceFromUser && company.distanceFromUser > 0">{{company.distanceFromUser.toFixed(2)}} km</div>
        </div>
      </div>
      <div class="icon"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "orderCompaniesList",

    props: {
      currentCompany: Object,
      companies: Array
    },

    methods: {
      setCompany(company) {
        this.$emit("selected", company);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .shops {
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
  .shops .shop {
    .top {
      font-size: 16px;
      white-space: normal;
    }
    .bottom {
      font-size: 12px;
      white-space: normal;
    }
  }
  .shops .shop .icon {
    margin-right: 5px;
  }

  #iShopFoodApp .shop {
    padding: 12px 10px;
    border: 2px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    margin-right: 10px;
    cursor: pointer;
    max-width: 100vw;

    &.selected {
      border-color: var(--primary-color) !important;
      .icon:after {
        content: "\f14a";
      }
    }
    .text {
      flex-grow: 1;
    }
    .top {
      display: flex;
      color: #808080;
      .bold {
        font-weight: bold;
        color: #1a1a1a;
      }
      .separator {
        margin: 0 10px;
      }
    }
    .icon {
      width: 20px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      &:after {
        content: "\f1f6";
        font-family: "Ionicons";
        font-size: 24px;
        color: var(--primary-color);
      }
    }
  }

  #iShopFoodApp .shops .shop.selected {
    border-color: var(--primary-color) !important;
  }
</style>
