<template>
  <div id="statusScreen">
    <div class="status__menu">
      <div v-wave class="menu__button active">{{ $t("pos.status.seats") }}</div>
      <div v-wave class="menu__button disabled">{{ $t("pos.status.invoices") }}</div>
      <div v-wave class="menu__button" @click="back">{{ $t("back") }}</div>
      <div class="menu__separator"></div>
      <div v-wave class="menu__button primary" @click="splitOrUnsplit" :class="{ disabled: !selectedDetail }">
        <svgicon src="/icons/split.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.split") }}
      </div>
      <div v-wave class="menu__button primary" @click="transfer" :class="{ disabled: !selectedDetail }">
        <svgicon src="/icons/pos-transfer.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.transfer") }}
      </div>
      <div v-wave class="menu__button primary disabled">
        <svgicon src="/icons/hold.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.hold") }}
      </div>
      <div v-wave class="menu__button primary disabled">
        <svgicon src="/icons/fire.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.fire") }}
      </div>
      <div v-wave class="menu__button primary disabled">
        <svgicon src="/icons/hold-all.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.hold_all") }}
      </div>
      <div v-wave class="menu__button primary disabled">
        <svgicon src="/icons/fire-all.svg" :width="22" :height="22"></svgicon>
        {{ $t("pos.status.fire_all") }}
      </div>
    </div>
    <div class="status__right">
      <div class="status__columns" ref="columns">
        <div class="columns__column" v-for="seat in highestSeat" :key="`seat-${seat}`" @click="transferToSeat(seat)">
          <div class="column__title">
            <div class="title__icon">
              <svgicon src="/icons/seat.svg" :width="24" :height="24"></svgicon>
            </div>
            <div class="title__info">
              <div class="title__title">{{ $t("pos.status.seat") }} {{ seat }}</div>
              <div class="title__subtitle">{{ details(seat).length }} {{ details(seat).length > 1 ? $t("items") : $t("item.item") }}
              </div>
            </div>
          </div>
          <div class="column__items">
            <div class="items__item" v-for="(detail, detailIndex) in details(seat)" :key="`detail-${detailIndex}`"
              @click="selectDetail(detail)" :class="{active: selectedDetail && detail.id === selectedDetail.id, void: detail.isVoid()}">
              <div class="item__title">{{ detail.name }}</div>
              <div class="item__line" v-for="(subdetail, subdetailIndex) in detail.details"
                :key="`detail-${detailIndex}-subdetail-${subdetailIndex}`">
                <div class="line__name">{{ subdetail.name }}</div>
                <div class="line__amount" v-if="subdetail.getTotalPrice() != 0">
                  {{ subdetail.getTotalPrice() > 0 ? "+" : "-" }}
                  {{ $tc(subdetail.getTotalPrice()) }}
                </div>
              </div>
              <div class="item__separator"></div>
              <div class="item__total">
                <div class="total__quantityText">{{ $t("quantity") }} : </div>
                <div class="total__quantity">{{ detail.quantity }}
                  <span class="total__split" v-if="detail.isSplit()">/ {{ detail.getSplitQuantity() }}</span>
                </div>
                <div class="total__total">{{ $tc(detail.getTotalPrice()) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns_add" @click="addSeat">
          <svgicon src="/icons/add.svg" :width="80" :height="80"></svgicon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Util } from "@/util/Util";
  import EventBus from "@/lib/eventBus";
  import {PaymentProcessor} from "@/PaymentProcessor";

  export default {

    name: "StatusScreen",

    data() {
      return {
        page: 1,
        maxSeat: 0,
        selectedDetail: null
      };
    },

    mounted() {
      if (!this.table) {
        this.$router.push(this.$router.generate("/pos/dashboard"));
        return;
      }
      this.getHighestSeat();
    },

    computed: {
      table() {
        return this.$store.state.table;
      },
      details() {
        return seat => {
          if (!this.$store.state.table) {
            return [];
          }
          return this.$store.state.table.getGroupedDetails(seat);
        };
      },
      highestSeat() {
        return Math.max(this.$store.state.table.getHighestSeat(), this.maxSeat);
      }
    },

    methods: {
      back() {
        this.$router.back();
      },
      selectDetail(detail) {
        this.selectedDetail = detail;
      },
      async transfer() {
        if (!this.selectedDetail) {
          return;
        }
        let calculatorResponse = await Util.Modal.calculator({
          title: this.$t("pos.transfer_to_seat"),
          allowNegativeValues: false,
          minValue: 1
        });
        if (calculatorResponse.value === null) {
          return;
        }
        this.transferToSeat(calculatorResponse.value);
        this.selectedDetail = null;
      },
      transferToSeat(seat) {
        if (!this.selectedDetail || this.selectedDetail.seat === seat) {
          return;
        }
        let detail = this.$store.state.table.getDetail(this.selectedDetail.id);
        if (detail) {
          detail.seat = seat;
          for (let subdetail of this.selectedDetail.details) {
            detail = this.$store.state.table.getDetail(subdetail.id);
            detail.seat = seat;
          }
        }
        this.selectedDetail = null;
      },
      getHighestSeat() {
        this.maxSeat = Math.max(this.$store.state.table.getHighestSeat(), this.maxSeat);
      },
      addSeat() {
        this.maxSeat = this.highestSeat + 1;
        setTimeout(() => {
          this.$refs.columns.scrollTo({
            left: this.$refs.columns.scrollWidth,
            behavior: "smooth"
          });
        }, 0);
      },
      splitOrUnsplit() {
        if (!this.selectedDetail) {
          return;
        }
        if (this.selectedDetail.isSplit()) {
          this.table.unsplitDetail(this.selectedDetail);
          return;
        }
        EventBus.$emit("show-split-item", this.selectedDetail.getSeat(), this.selectedDetail);
      }
    }
  };
</script>

<style lang="scss" scoped>
  #iShopFoodApp #statusScreen {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 1920px;
  }

  .status__menu {
    display: flex;
    flex-direction: column;
    padding: 0 20px 30px 30px;
    margin: 30px 20px 0 0;
    border-right: 2px dashed #e6e6e6;
    gap: 12px;
    flex-shrink: 0;

    .menu__button {
      padding: 16px;
      background-color: var(--background-color-2);
      font-size: 18px;
      font-weight: 500;
      border-radius: 16px;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
      min-width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &.active {
        color: var(--primary-color);
        border: 5px solid var(--primary-color);
        padding: 11px;
      }

      &.primary {
        color: var(--primary-color);
        justify-content: flex-start;
        box-shadow: none;
      }

      .svgicon {
        margin-right: 12px;
        fill: var(--primary-color);
      }
    }

    .menu__separator {
      height: 1px;
      background-color: var(--border-color-5);
    }
  }

  .status__right {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 30px 0;
    flex-grow: 1;
    overflow: hidden;
  }

  .status__columns {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    overflow-x: auto;

    .columns__column {
      background-color: var(--background-color-2);
      padding: 10px;
      border-radius: 20px;
      min-width: 316px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &.active {
        border: 5px solid var(--primary-color);
        padding: 5px;

        .column__title .title__icon {
          background-color: var(--primary-color);
          fill: var(--background-color-2);
        }
      }

      .column__title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .title__icon {
          padding: 17px;
          background-color: var(--background-color-3);
          border-radius: 10px;
          fill: var(--primary-color);
          margin-right: 20px;
        }

        .title__title {
          font-size: 26px;
          font-weight: 900;
        }

        .title__subtitle {
          font-weight: 500;
          color: var(--theme-color-2);
        }
      }

      .column__items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        padding: 2px;
        margin: -2px;

        .items__item {
          box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
          padding: 10px;
          border-radius: 12px;

          &.active {
            border: 5px solid var(--primary-color);
            padding: 5px;
          }

          &.void {
            opacity: 0.5;
            pointer-events: none;
          }

          .item__title {
            font-size: 20px;
            font-weight: 900;
            margin-bottom: 10px;
          }

          .item__line {
            display: flex;

            .line__name {
              font-size: 16px;
              font-weight: 500;
              color: var(--theme-color-2);
              flex-grow: 1;
            }

            .line__amount {
              font-size: 16px;
              font-weight: 900;
              margin-left: 10px;
            }
          }

          .item__separator {
            height: 1px;
            background-color: var(--border-color-5);
            margin: 5px 0;
          }

          .item__total {
            display: flex;
            align-items: flex-end;

            .total__quantityText {
              font-size: 16px;
              font-weight: 500;
              color: var(--theme-color-2);
              margin-right: 3px;
            }

            .total__quantity {
              font-size: 20px;
              font-weight: 900;
              margin-right: 10px;
              flex-grow: 1;
              position: relative;
              top: 1px;
              .total__split {
                color: var(--theme-color-2);
                font-weight: 500;
              }
            }

            .total__total {
              font-size: 20px;
              font-weight: 900;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

    .columns_add {
      background-color: var(--background-color-2);
      padding: 10px;
      border-radius: 20px;
      min-width: 316px;
      border: 5px solid var(--border-color-5);
      fill: var(--border-color-5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>