<template>
  <div id="thirdPartyDeliveryModal" class="modal" :class="{show: show}">
    <div class="content modal-content">

      <div class="title">{{$t("third_party_delivery.title")}}</div>

      <div class="description">{{$t("third_party_delivery.description")}}</div>

      <div class="platforms" v-if="company">
        <div class="platform" v-for="platform of company.thirdPartyDeliveryPlatforms" @click="open(platform)">
          <div class="icon custom" :class="{custom: !platform.name == 'other'}">
            <img v-if="platform.name !== 'other'" :src="'img/delivery/' + platform.name + '.png'">
            <svgicon v-if="platform.name == 'other'" src="icons/livraison.svg" :width="30" :height="30"></svgicon>
          </div>
          <div class="right">
            <div class="name">{{ platform.custom || platform.name }}</div>
            <div class="address">{{company.name}}</div>
          </div>
          <div class="continue">
            <svgicon src="icons/arrow-right.svg" :width="20" :height="30"></svgicon>
          </div>
        </div>
      </div>

      <div class="buttons">
        <div class="btn btn-secondary flat" @click="show = false">
          {{ $t("back") }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import EventBus from "../lib/eventBus";

  export default {
    name: "thirdPartyDeliveryModal",

    data() {
      return {
        show: false,
        company: null
      }
    },

    mounted() {
      EventBus.$on("show-third-party-delivery-modal", (company) => {
        this.show = true;
        this.company = company;
      });
    },

    computed: {

    },

    methods: {
      open(platform) {
        if (platform.link) {
          let url = platform.link;
          if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
          }
          return window.open(url);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    line-height: 1;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

  .platforms {
    max-height: calc(100vh - 200px);
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -10px;
  }

  .platform {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    margin-right: 10px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .right {
    flex-grow: 1;
  }

  .name {
    font-weight: bold;
  }
  .address {
    font-size: 14px;
    margin-top: 2px;
  }

  .continue {
    margin-left: 10px;
    fill: var(--primary-color);
  }

  .icon {
    margin-right: 15px;
    fill: white;

    &.custom {
      height: 60px;
      width: 60px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
    }
  }

  #iShopFoodApp #thirdPartyDeliveryModal img {
    height: 60px;
    border-radius: 8px;
  }

  .description {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
</style>
