// @ts-check
import moment from "moment";
import {Constant} from "../../util/Constant";

/**
 * @typedef TimeSheetEntryDefinition
 * @property {number} id
 * @property {{id: number, firstName: string, lastName: string}} employee
 * @property {number} company
 * @property {datetime} start
 * @property {datetime} end
 * @property {string} note
 */

export default class TimeSheetEntry {

  /**
   * @param {TimeSheetEntryDefinition} definition
   */
  constructor(definition) {
    this.id = definition.id || -1;
    this.employee = {
      id: definition.employee?.id,
      firstName: definition.employee?.firstName,
      lastName: definition.employee?.lastName,
    };
    this.company = definition.company;
    this.start = definition.start || null;
    this.end = definition.end || null;
    this.note = definition.note || "";

  }

  /**
   * @returns {TimeSheetEntryDefinition} return the DTO for the edit timesheet call
   **/
  toEditedEntryDto() {
    return {
      id: this.id,
      employee: this.employee.id,
      start: this.start,
      end: this.end,
      note: this.note
    }
  }

  /**
   * @returns {TimeSheetEntryDefinition} return the DTO for the new timesheet call
   **/
  toNewEntryDto() {
    return {
      employee: this.employee.id,
      start: this.start,
      end: this.end,
      note: this.note
    }
  }

  /**
   * @returns {TimeSheetEntryDefinition} returns the full object as dto
   **/
  toCompleteDto() {
    return {
      id: this.id,
      employee: {
        id: this.employee?.id,
        firstName: this.employee?.firstName,
        lastName: this.employee?.lastName,
      },
      company: this.company,
      start: this.start,
      end: this.end,
      note: this.note
    }
  }

  /**
   * @returns {TimeSheetEntry} Clone of the current entry
   **/
  clone() {
    return new TimeSheetEntry(this.toCompleteDto());
  }

  /**
   * @returns {number} timesheet id
   **/
  getId() {
    return this.id;
  }

  /**
   * @returns {string} Returns the day of the week for the start date
   **/
  getStartAsWeekDay() {
    if (!this.start) {
      return;
    }
    let day = moment(this.start, Constant.API_DATE_FORMAT).day();
    let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return days[day];
  }

  /**
   * @returns {string} Returns start date
   **/
  getStart() {
    return this.start;
  }

  /**
   * @returns {datetime} Returns end date
   **/
  getEnd() {
    return this.end;
  }

  /**
   * @returns {string} Returns start date as a moment date
   **/
  getStartAsDate() {
    if (!this.start) {
      return;
    }
    return moment(this.start, Constant.API_DATE_FORMAT);
  }

  /**
   * @returns {datetime} Returns end date as a moment date
   **/
  getEndAsDate() {
    if (!this.end) {
      return;
    }
    return moment(this.end, Constant.API_DATE_FORMAT);
  }

  /**
   * @param {string} end
   **/
  setEndDate(end) {
    this.end = end;
  }

  /**
   * @param {string} start
   **/
  setStartDate(start) {
    this.start = start;
  }

  /**
   * @returns {string} Start Time formatted as h:mm A
   **/
  getStartHour() {
    return moment(this.start, Constant.API_DATE_FORMAT).format("h:mm A");
  }
  /**
   * @returns {string} End Time formatted as h:mm A
   **/
  getEndHour() {
    return moment(this.end, Constant.API_DATE_FORMAT).format("h:mm A");
  }

  /**
   * @returns {number} Time range between start and end date
   **/
  getTotalHours() {
    if (!this.start || !this.end) {
      return 0;
    }
    let startDate = moment(this.start,Constant.API_DATE_FORMAT);
    let endDate = moment(this.end,Constant.API_DATE_FORMAT);

    let duration = moment.duration(endDate.diff(startDate));
    let hours = duration.asMinutes() / 60;
    return Math.round((hours) * 100) / 100;
  }

}