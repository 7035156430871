<template>
  <div id="pendingManagementRequestsModal" class="modal" :class="{show}">
    <div class="content modal-content">

      <div class="title">
        <svgicon src="icons/clock.svg" :height="14" :width="14" :style="{fill: secondaryButtonTextColor}"></svgicon>
        {{$t('loyalty.pending_management_requests')}}
      </div>

      <div class="requests">
        <div class="request" v-for="request of filteredRequests">
          <div class="email">{{request.managedEmail}}</div>
          <div class="dates">
            <div class="date">
              <label>{{$t('loyalty.expire_on')}}</label>
              <div class="value">{{getDate(request.expireAt.date)}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="empty" v-if="requests.length === 0">
        {{$t('loyalty.no_pending_management_requests')}}
      </div>

      <div class="buttons">
        <div class="btn btn-secondary flat" @click="back">
          {{$t('back')}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import moment from "moment-timezone";

  export default {
    name: "PendingUserManagementRequestsModal",

    data() {
      return {
        show: false,
        requests: []
      }
    },

    props: {
      visible: Boolean
    },

    mounted() {
      this.init();
    },

    watch: {
      visible() {
        this.init();
      },
    },

    computed: {
      secondaryButtonTextColor() {
        return this.$store.state.secondaryButtonTextColor || "#fff";
      },
      filteredRequests() {
        return this.requests.filter(r => !this.$user.hasLoyaltyAccountWithUserId(r.managed));
      }
    },

    methods: {
      async init() {
        let response = await this.$user.getPendingUserManagementRequests();
        if (response) {
          this.requests = response;
        }
        this.show = this.visible;
      },

      getDate(date) {
        return moment(date).format("D MMMM YYYY");
      },

      back() {
        this.$emit("closed");
        this.show = false;
        this.requests = [];
      }
    }
  }
</script>

<style lang="scss" scoped>
  #transferModal {
    z-index: 2;
  }
  .title {
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;

    .svgicon {
      margin-right: 10px;
    }
  }
  .requests {
    max-height: calc(100vh - 250px);
    padding-right: 10px;
    margin-right: -10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .buttons {
    display: flex;
    justify-content: center;
    border-top: 1px solid #f2f2f2;
    margin-top: 10px;
    padding-top: 20px;
  }
  .empty {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    opacity: 0.7;
  }
  .request {
    padding: 10px 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }

    &:last-child {
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }

    .email, .value {
      font-weight: bold;
    }

    .email {
      font-size: 14px;
    }

    .dates {
      display: flex;
      flex-direction: column;
    }

    .date {
      text-align: center;
    }
  }
</style>