export default class ReceiptConfiguration {

  constructor(definition) {
    this.headerLines = [];
    this.footerLines = [];
    this.limitProductDescription = false;
    this.hideEmployeeInformation = false;
    this.informationAtBottom = false;
    this.hideFreeModifiers = false;
    this.logo = null;

    if (definition) {
      this.load(definition);
    }
  }

  load(definition) {
    this.headerLines = definition.headerLines || [];
    this.footerLines = definition.footerLines || [];
    this.limitProductDescription = definition.limitProductDescription || false;
    this.hideEmployeeInformation = definition.hideEmployeeInformation || false;
    this.informationAtBottom = definition.informationAtBottom || false;
    this.hideFreeModifiers = definition.hideFreeModifiers || false;
    this.logo = definition.logo || null;
  }

  /**
   * @returns {string[]}
   */
  getFooterLines() {
    return this.footerLines;
  }

  /**
   * @returns {string[]}
   */
  getHeaderLines() {
    return this.headerLines;
  }

}