<template>
  <div id="posOnboarding">
    <div class="onboarding__content">

      <div class="content--inner">

        <!-- welcome -->
        <div ref="panel-step-0" class="onboarding__welcome">
          <div class="welcome__title" v-html="$t('pos_onboarding.welcome')"></div>
          <div class="welcome__line"></div>
          <div class="welcome__description">{{ $t('pos_onboarding.welcome_description') }}</div>

          <button class="welcome__button button--start" v-wave @click="togglePanels(0, 1)">
            <span class="start__text">{{ $t('start') }}</span>
            <svgicon class="start__icon" src="icons/arrow-line-right.svg" :height="20" :width="20"></svgicon>
          </button>
        </div>

        <!-- steps -->
        <div class="onboarding__steps" v-if="step > 0">
          <div class="step" v-for="n in 7" :class="{ active: step === n, completed: step > n }" :key="'step' + n">
            <div class="step__circle">
              <svgicon class="circle__checkmark" src="icons/check.svg" :height="11" :width="16"></svgicon>
            </div>
            <div class="step__text">
              {{ $t('pos_onboarding.step.' + n) }}
            </div>
          </div>
        </div>

        <!-- onboarding panels -->
        <div ref="panel-step-1" class="onboarding__panel panel--language">
          <div class="panel__subtitle">{{ $t('pos_onboarding.language_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.language_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.language_description') }}</div>
          <div class="panel__inner">
            <div class="languages">
              <div class="language" v-wave
                :class="{ active: $ts.state.locale === locale, disabled: locale === 'es' }"
                v-for="locale of locales" :key="'lang-' + locale" @click="setLocale(locale)">
                <div class="language__check">
                  <svgicon src="icons/check.svg" :height="10" :width="14"></svgicon>
                </div>
                <div class="language__name">{{ $t(locale) }}</div>
              </div>
            </div>
          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="togglePanels(1, 2)">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-2" class="onboarding__panel panel--register">
          <div class="panel__subtitle">{{ $t('pos_onboarding.register_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.register_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.register_description') }}</div>
          <div class="panel__inner">
            <div class="registration-email">
              <label class="registration-email__label">{{ $t('email') }}</label>
              <PosInput class="registration-email__input" type="text" :placeholder="$t('email_example')"
                v-model="registrationEmail"></PosInput>
              <div class="email-info">
                <svgicon src="icons/alert.svg" :height="30" :width="30"></svgicon>
                <div class="email-info__text" v-html="$t('pos_onboarding.email_spam_folder')"></div>
              </div>
            </div>
          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="register">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-3" class="onboarding__panel panel--activation">
          <div class="panel__subtitle">{{ $t('pos_onboarding.activation_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.activation_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.activation_description') }}</div>
          <div class="panel__inner">

            <div class="activation-code">
              <label class="activation-code__label">{{ $t('pos_onboarding.activation_subtitle') }}</label>
              <OtpInput class="activation-code__digits" input-classes="activation-code__digit" :num-inputs="6"
                separator="" :should-auto-focus="false" :is-input-num="true" @on-change="activationCodeChange"
                @on-complete="activationCodeComplete"></OtpInput>
              <div class="email-info">
                <svgicon src="icons/alert.svg" :height="30" :width="30"></svgicon>
                <div class="email-info__text" v-html="$t('pos_onboarding.email_spam_folder')"></div>
              </div>
            </div>

          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="activate">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-4" class="onboarding__panel panel--xpos">
          <div class="panel__subtitle">{{ $t('pos_onboarding.xpos_connection_subtitle') }}</div>
          <div class="panel__title">
            {{ $t(`pos_onboarding.xpos_connection_title_${xposConnectionStatus}`) }}
          </div>
          <div class="panel__description">
            <template v-if="xposConnectionOngoing">
              {{ $t('pos_onboarding.xpos_connection_description_connecting') }}
            </template>
            <template v-if="xposConnectionFailed">
              <template v-if="!isXposConfigured">
                {{ $t('pos_onboarding.xpos_connection_description_config_failed') }}
              </template>
              <template v-if="isXposConfigured && !isXposReachable">
                {{ $t('pos_onboarding.xpos_connection_description_failed') }}
              </template>
              <template v-if="isXposConfigured && isXposReachable">
                {{ $t('pos_onboarding.xpos_connection_description_init_failed') }}
              </template>
            </template>
            <template v-if="xposConnectionSuccess">
              {{ $t('pos_onboarding.xpos_connection_description_success') }}
            </template>
          </div>
          <div class="panel__inner">
            <div class="connection-status">
              <div class="status">
                <svgicon class="status__icon" :class="{done: isXposConfigured}" src="icons/XPOS-connect-workstation.svg" :height="96" :width="89"></svgicon>
              </div>
              <div class="separator">
                <div class="dots-bouncing" :class="{active: isXposConfigured}"></div>
              </div>
              <div class="status">
                <svgicon class="status__icon warning" src="icons/XPOS-connect-no-connection.svg" :height="69" :width="96"
                         v-if="!isXposReachable && xposConnectionFailed"></svgicon>
                <svgicon class="status__icon" src="icons/XPOS-connect-connecting.svg" :height="69" :width="96"
                         v-if="!isXposReachable && xposConnectionOngoing"></svgicon>
                <svgicon class="status__icon done" src="icons/XPOS-connect-checkmark.svg" :height="88" :width="88" v-if="isXposReachable"></svgicon>
              </div>
              <div class="separator">
                <div class="dots-bouncing" :class="{active: isXposReachable}"></div>
              </div>
              <div class="status">
                <svgicon class="status__icon" :class="{done: xposConnectionSuccess}" src="icons/XPOS-connect-platform.svg" :height="88" :width="96"></svgicon>
              </div>
            </div>

          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="togglePanels(4, 5)" v-if="isXposConfigured && xposConnectionSuccess">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-5" class="onboarding__panel panel--theme">
          <div class="panel__subtitle">{{ $t('pos_onboarding.theme_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.theme_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.theme_description') }}</div>
          <div class="panel__inner">

            <div class="display__configuration">
              <div class="configuration__title">{{ $t('pos_onboarding.primary_color') }}</div>
              <div class="colors">
                <div class="color-swatch" v-for="color of posConfiguration.COLORS" :key="`color-${color}`"
                  :class="{ active: posConfiguration.primaryColor === color }" @click="setColor(color)">
                  <div class="swatch" :style="{ backgroundColor: color }"></div>
                  <div class="swatch__checkmark" :class="{ show: posConfiguration.primaryColor === color }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

            <div class="display__configuration">
              <div class="configuration__title">{{ $t('pos_onboarding.main_theme') }}</div>
              <div class="configuration-switch switch__themes">
                <div class="switch__value" @click="setDarkTheme(false)" :class="{ active: !posConfiguration.darkTheme }">
                  <div class="switch__text">{{ $t('pos_onboarding.light') }}</div>
                  <div class="switch__checkmark" :class="{ show: !posConfiguration.darkTheme }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
                <div class="switch__value" @click="setDarkTheme(true)" :class="{ active: posConfiguration.darkTheme }">
                  <div class="switch__text">{{ $t('pos_onboarding.dark') }}</div>
                  <div class="switch__checkmark" :class="{ show: posConfiguration.darkTheme }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="togglePanels(5, 6)">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-6" class="onboarding__panel panel--performance">
          <div class="panel__subtitle">{{ $t('pos_onboarding.performance_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.performance_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.performance_description') }}</div>
          <div class="panel__inner">

            <div class="display__configuration">
              <div class="configuration__title">{{ $t('pos_onboarding.animation') }}</div>
              <div class="configuration__subtitle">{{ $t('pos_onboarding.animation_subtitle') }}</div>
              <div class="configuration-switch switch__animations">
                <div class="switch__value" @click="setAnimated(false)" :class="{ active: !posConfiguration.animated }">
                  <div class="switch__text">{{ $t('disable') }}</div>
                  <div class="switch__checkmark" :class="{ show: !posConfiguration.animated }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
                <div class="switch__value" @click="setAnimated(true)" :class="{ active: posConfiguration.animated }">
                  <div class="switch__text">{{ $t('enable') }}</div>
                  <div class="switch__checkmark" :class="{ show: posConfiguration.animated }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

            <div class="display__configuration">
              <div class="configuration__title">{{ $t('pos_onboarding.quick_add') }}</div>
              <div class="configuration__subtitle">{{ $t('pos_onboarding.quick_add_subtitle') }}</div>
              <div class="configuration-switch switch__animations">
                <div class="switch__value" @click="setQuickAdd(false)" :class="{ active: !posConfiguration.quickAdd }">
                  <div class="switch__text">{{ $t('disable') }}</div>
                  <div class="switch__checkmark" :class="{ show: !posConfiguration.quickAdd }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
                <div class="switch__value" @click="setQuickAdd(true)" :class="{ active: posConfiguration.quickAdd }">
                  <div class="switch__text">{{ $t('enable') }}</div>
                  <div class="switch__checkmark" :class="{ show: posConfiguration.quickAdd }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="togglePanels(6, 7)">{{ $t('continue') }}</button>
          </div>
        </div>

        <div ref="panel-step-7" class="onboarding__panel panel--display">
          <div class="panel__subtitle">{{ $t('pos_onboarding.display_subtitle') }}</div>
          <div class="panel__title">{{ $t('pos_onboarding.display_title') }}</div>
          <div class="panel__description">{{ $t('pos_onboarding.display_description') }}</div>
          <div class="panel__inner">

            <div class="display__configuration">
              <label class="configuration__title">{{ $t('pos_onboarding.category_images') }}</label>
              <div class="configuration__subtitle">{{ $t('pos_onboarding.category_images_subtitle') }}</div>
              <div class="configuration-switch switch__animations">
                <div class="switch__value" @click="setDisplayCategoryImages(false)"
                  :class="{ active: !posConfiguration.displayCategoryImages }">
                  <div class="switch__text">{{ $t('disable') }}</div>
                  <div class="switch__checkmark" :class="{ show: !posConfiguration.displayCategoryImages }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
                <div class="switch__value" @click="setDisplayCategoryImages(true)"
                  :class="{ active: posConfiguration.displayCategoryImages }">
                  <div class="switch__text">{{ $t('enable') }}</div>
                  <div class="switch__checkmark" :class="{ show: posConfiguration.displayCategoryImages }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

            <div class="display__configuration">
              <label class="configuration__title">{{ $t('pos_onboarding.product_images') }}</label>
              <div class="configuration__subtitle">{{ $t('pos_onboarding.product_images_subtitle') }}</div>
              <div class="configuration-switch switch__animations">
                <div class="switch__value" @click="setDisplayProductImages(false)"
                  :class="{ active: !posConfiguration.displayProductImages }">
                  <div class="switch__text">{{ $t('disable') }}</div>
                  <div class="switch__checkmark" :class="{ show: !posConfiguration.displayProductImages }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
                <div class="switch__value" @click="setDisplayProductImages(true)"
                  :class="{ active: posConfiguration.displayProductImages }">
                  <div class="switch__text">{{ $t('enable') }}</div>
                  <div class="switch__checkmark" :class="{ show: posConfiguration.displayProductImages }">
                    <svgicon src="icons/check.svg" :height="9" :width="13"></svgicon>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="panel__button">
            <button class="button--continue" v-wave @click="togglePanels(7, 8)">{{ $t('continue') }}</button>
          </div>
        </div>

        <!-- Marketing animation panel -->
        <div ref="panel-step-8" class="onboarding__panel panel--marketing">
          <div class="marketing-slide" v-for="(slide, slideIndex) in marketingSlides" :key="'slide' + slideIndex"
            :ref="'slide-' + slideIndex">
            <div class="slide__image" :style="{ backgroundImage: 'url(\'' + slide.image + '\')' }"></div>
            <div class="panel__subtitle">{{ slide.subtitle }}</div>
            <div class="panel__title">{{ slide.title }}</div>
            <div class="panel__description" v-html="slide.description"></div>
          </div>
          <div class="marketing__progress-bar">
            <div class="progress"></div>
          </div>
        </div>

        <!-- Marketing completed panel -->
        <div ref="panel-step-9" class="onboarding__panel panel--marketing panel--marketing__final">
          <div class="marketing-slide slide--final">
            <div class="slide__image" :style="{ backgroundImage: 'url(\'/img/marketing/final.jpg\')' }"></div>
            <div class="panel__subtitle">{{ $t('pos_onboarding.marketing.final.subtitle') }}</div>
            <div class="panel__title">{{ $t('pos_onboarding.marketing.final.title') }}</div>
            <div class="panel__description" v-html="$t('pos_onboarding.marketing.final.description')"></div>
          </div>
          <div class="panel__button" v-if="step === 9">
            <button class="button--continue" v-wave @click="finishOnboarding">{{ $t('pos_onboarding.begin') }}</button>
          </div>
        </div>

        <div class="button-container">
          <button class="button--back" v-wave v-if="showBackButton" @click="previousStep">
            <svgicon src="icons/arrow-left.svg" :height="20" :width="20"></svgicon>
            {{ $t('back') }}
          </button>
        </div>

      </div>

      <div class="copyright-holder">
        <OnboardingCopyright white></OnboardingCopyright>
      </div>
    </div>

    <video class="background-video" src="video/onboarding-background.mp4" autoplay muted loop
      poster="video/onboarding-background-thumb.jpeg"></video>
  </div>
</template>

<script>
  import EventBus from "@/lib/eventBus";
  import OnboardingCopyright from "@/components/pos/OnboardingCopyright";
  import store from "@/store";
  import OtpInput from "@bachdgvn/vue-otp-input";
  import PosInput from "@/components/pos/PosInput";
  import { mapActions } from "vuex";

  const xposConnectionStatuses = {
    connecting: "connecting",
    failed: "failed",
    successful: "success"
  };

  export default {
    name: "posOnboarding",

    components: { OnboardingCopyright, OtpInput, PosInput },

    data() {
      return {
        step: 0,
        locales: ["fr", "en", "es"],
        registrationEmail: "",
        registered: false,
        activationCode: "",
        animationDelay: 600, //ms,
        xposConnectionStatus: "connecting",
        xposOnboardingState: {
          isXposConfigured: false,
          isXposReachable: false,
          isXposInitialized: false
        },
        isUsingXpos: true,
        xposNextStateDelay: 4 * 1000, //ms
        xposRetryDelay: 30 * 1000 //ms
      };
    },

    computed: {
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      marketingSlidesSize() {
        return this.marketingSlides.length;
      },
      marketingSlides() {
        let slides = [];
        //TODO should not be hardcoded and fetched
        for (let i = 1; i < 4; i++) {
          slides.push({
            subtitle: this.$t("pos_onboarding.marketing." + i + ".subtitle"),
            title: this.$t("pos_onboarding.marketing." + i + ".title"),
            description: this.$t("pos_onboarding.marketing." + i + ".description"),
            image: "/img/marketing/" + i + ".jpg"
          });
        }
        return slides.slice().reverse();
      },
      showBackButton() {
        return ((!this.registered && this.step > 1) || (this.registered && this.step > 4)) && this.step < 8;
      },
      isXposConfigured() {
        return this.xposOnboardingState.isXposConfigured;
      },
      isXposReachable() {
        return this.xposOnboardingState.isXposReachable;
      },
      xposConnectionSuccess() {
        return this.xposConnectionStatus === xposConnectionStatuses.successful;
      },
      xposConnectionFailed() {
        return this.xposConnectionStatus === xposConnectionStatuses.failed;
      },
      xposConnectionOngoing() {
        return this.xposConnectionStatus === xposConnectionStatuses.connecting;
      }
    },

    methods: {
      ...mapActions(["send2FA", "consume2FA", "getBranch", "getPosConfiguration", "getStatus", "initFromPos"]),

      previousStep() {
        if (this.step > 1) {
          this.togglePanels(this.step, this.step - 1);
        }
      },

      activationCodeComplete(value) {
        this.activationCode = value;
      },

      activationCodeChange(value) {
        this.activationCode = value;
      },

      setLocale(locale) {
        this.posConfiguration.locales[locale] = true;
        this.posConfiguration.locales.default = locale;
        this.$ts.commit("setLocale", locale);
        setConfiguration("locale", locale);
      },

      async register() {
        if (this.registrationEmail.length === 0) {
          toast({
            message: this.$t("register.email_cannot_be_empty"),
            type: "warning",
            title: this.$t("warning")
          });
          return;
        }
        let response = await this.send2FA(this.registrationEmail);
        if (!response.success) {
          toast({
            message: this.$to(response.data.message),
            type: "error",
            title: this.$t("error.title")
          });
          return;
        }
        this.togglePanels(2, 3);
      },

      async activate() {
        if (this.activationCode.length < 6) {
          toast({
            message: this.$t("pos_onboarding.activation_incomplete"),
            type: "warning",
            title: this.$t("warning")
          });
          return;
        }
        showSpinner();
        let consumeResponse = await this.consume2FA({
          email: this.registrationEmail,
          code: this.activationCode
        });
        if (!consumeResponse.success) {
          hideSpinner();
          toast({
            message: this.$t("pos_onboarding.activation_error"),
            type: "error",
            title: this.$t("error.title")
          });
          return;
        }
        this.posConfiguration.setApiKey(consumeResponse.data.apiKey);
        let branchCanonical = consumeResponse.data.branch.nameCanonical;
        let companyCanonical = consumeResponse.data.company.nameCanonical;
        let branch = await this.getBranch({
          id: branchCanonical,
          company: companyCanonical,
          force: true
        });
        if (branch) {
          hideSpinner();
          this.registered = true;
          this.posConfiguration.setBranchAndCompanyCanonical(branchCanonical, companyCanonical);
          this.posConfiguration.setWorkstationNameAndId(consumeResponse.data.user.username, consumeResponse.data.user.id);
          this.posConfiguration.setNumber("1");
          this.posConfiguration.save();
          toast({
            message: this.$t("pos_onboarding.registered"),
            type: "success",
            title: this.$t("success")
          });
          this.togglePanels(3, 4);
        } else {
          hideSpinner();
          toast({
            message: this.$t("pos_onboarding.error_loading_branch"),
            type: "error",
            title: this.$t("error.title")
          });
        }
      },

      togglePanels(previousStep, nextStep) {
        let previousPanelRef = this.$refs["panel-step-" + previousStep];
        //Fade out previous panel
        if (previousPanelRef) {
          previousPanelRef.classList.remove("visible");
          previousPanelRef.classList.add("fade-out");
          setTimeout(() => {
            previousPanelRef.classList.add("hide");
            previousPanelRef.classList.remove("fade-out");
            this.displayNextPanel(nextStep);
          }, this.animationDelay);
        } else {
          this.displayNextPanel(nextStep);
        }
        this.step = nextStep;
      },

      setColor(color) {
        this.posConfiguration.setPrimaryColor(color);
        EventBus.$emit("compute-style");
      },

      setDarkTheme(isDarkTheme) {
        this.posConfiguration.setDarkTheme(isDarkTheme);
      },

      setAnimated(animated) {
        this.posConfiguration.setAnimated(animated);
      },

      setQuickAdd(quickAdd) {
        this.posConfiguration.setQuickAdd(quickAdd);
      },

      setDisplayProductImages(displayProductImages) {
        this.posConfiguration.setDisplayProductImages(displayProductImages);
      },

      setDisplayCategoryImages(displayCategoryImages) {
        this.posConfiguration.setDisplayCategoryImages(displayCategoryImages);
      },

      finishOnboarding() {
        this.posConfiguration.save();
        this.$router.push(this.$router.generate("/pos/dashboard?first-launch=1"));
      },

      displayNextPanel(step) {
        let nextPanelRef = this.$refs["panel-step-" + step];
        if (nextPanelRef) {
          nextPanelRef.classList.remove("hide");
          nextPanelRef.classList.add("visible");
          if (step === 4 && this.isUsingXpos && !this.xposConnectionSuccess) {
            this.initXposConfigValidation();
          }
          if (step === 8) {
            this.playMarketingSlides();
          }
        }
      },

      playMarketingSlides() {
        let durationPerSlide = 5 * 1000; //ms
        let slideFadeOutDuration = 500; //ms
        let totalDuration = durationPerSlide * this.marketingSlidesSize; //ms
        //Set progress bar total animation
        let progressBar = document.querySelector(".panel--marketing .marketing__progress-bar .progress");
        if (progressBar) {
          progressBar.style.animation = "progress-bar " + totalDuration + "ms linear forwards";
        }
        //Slide fade out function (fading out in reverse)
        let fadeOutSlide = (slideIndex) => {
          setTimeout(() => {
            let slideRef = this.$refs["slide-" + slideIndex][0];
            if (slideRef && slideIndex > 0) {
              slideRef.classList.add("fade-out");
              setTimeout(() => {
                fadeOutSlide(slideIndex - 1);
              }, slideFadeOutDuration);
            } else if (slideIndex === 0) {
              this.togglePanels(8, 9);
            }
          }, (durationPerSlide - slideFadeOutDuration));
        };
        //Fade out first slide
        fadeOutSlide(this.marketingSlidesSize - 1);
      },

      async validateXposConfig() {
        this.xposConnectionStatus = xposConnectionStatuses.connecting;
        let companyCanonical = this.posConfiguration.getCompanyCanonical();
        let response = await this.getPosConfiguration(companyCanonical);
        if (response.success && response.data && response.data.usingXPos) {
          let xposConfig = response.data;
          this.posConfiguration.setXposConfigActivated(xposConfig.usingXPos);
          this.posConfiguration.setXposConfigUrl(xposConfig.xPosConfig.ip);
          this.posConfiguration.save();
          return true;
        } else if (response.success && response.data && (Object.keys(response.data).length === 0 || !response.data.usingXPos)) {
          //SKip over xpos is not configured
          this.xposOnboardingState.isXposInitialized = true;
          this.xposConnectionStatus = xposConnectionStatuses.successful;
          this.togglePanels(4, 5); //SKIP xpos page
          return true;
        } else {
          return false;
        }
      },
      async initXposConfigValidation() {
        await this.xposAnimationDelay();
        let xposConfigValidated = await this.validateXposConfig();
        if (xposConfigValidated) {
          await this.initXposSync();
          return;
        }

        this.xposConnectionStatus = xposConnectionStatuses.failed;
        let xposValdiationInterval = setInterval(async () => {
          let xposConfigValidation = await this.validateXposConfig();
          if (!xposConfigValidation) {
            this.xposConnectionStatus = xposConnectionStatuses.failed;
            return;
          }
          clearInterval(xposValdiationInterval);
          await this.initXposSync();
        }, this.xposRetryDelay);
      },
      async initXposSync() {
        this.xposOnboardingState.isXposConfigured = true;
        this.xposConnectionStatus = xposConnectionStatuses.connecting;
        await this.xposAnimationDelay();
        let xposStatusResponse = await this.getStatus();
        if (xposStatusResponse.success) { //Xpos is reachable
          await this.initXpos();
          return;
        }

        this.xposConnectionStatus = xposConnectionStatuses.failed;
        let xposStatusInterval = setInterval(async () => {
          let response = await this.getStatus();
          if (!response.success) { //cant reach xpos or onboarding not working.
            this.xposConnectionStatus = xposConnectionStatuses.failed;
            return;
          }
          clearInterval(xposStatusInterval);
          //Xpos is reachable
          await this.initXpos();
        }, this.xposRetryDelay);
      },
      async initXpos() {
        this.xposOnboardingState.isXposReachable = true;
        this.xposConnectionStatus = xposConnectionStatuses.connecting;
        await this.xposAnimationDelay();
        let xposInitResponse = await this.initFromPos();
        if (xposInitResponse.success) { //Success
          this.xposOnboardingState.isXposInitialized = true;
          this.xposConnectionStatus = xposConnectionStatuses.successful;
          return;
        }

        this.xposConnectionStatus = xposConnectionStatuses.failed;
        let xposInitInterval = setInterval(async () => {

          let response = await this.initFromPos();
          if (!response.success) {
            this.xposConnectionStatus = xposConnectionStatuses.failed;
            return;
          }
          clearInterval(xposInitInterval);
          //Success
          this.xposOnboardingState.isXposInitialized = true;
          this.xposConnectionStatus = xposConnectionStatuses.successful;
        }, this.xposRetryDelay);
      },
      async xposAnimationDelay() {
        return new Promise(resolve => setTimeout(resolve, this.xposNextStateDelay));
      }
    }

  };
</script>

<style lang="scss" scoped>
  $video-fade-in-duration: 1s;
  $welcome-fade-in-duration: 1s;
  $welcome-fade-out-duration: 0.7s;
  $panel-fade-out-duration: 1s;
  $panel-fade-in-duration: 0.7s;
  $color-switch-duration: 0.3s;
  $marketing-transition-duration: 0.5s;
  $color: #ff5500;

  #iShopFoodApp.pos #posOnboarding {
    position: relative;
    background-color: white !important;
    transition: background-color $color-switch-duration ease-in-out;
  }

  #iShopFoodApp.pos.dark #posOnboarding {
    background-color: #1a1a1a !important;

    .onboarding__panel {
      background-color: #262626;

      .panel__title,
      .panel__description {
        color: white;
      }

      .panel__subtitle {
        border-bottom: 1px solid #404040;
      }

      .color-swatch:not(.active) {
        border: 1px solid #404040;
      }
    }

    .step {
      &.completed {
        .step__circle {
          background-color: #262626;
        }
      }

      .step__circle {
        background-color: rgba(0, 0, 0, 0.8);
        border: 4px solid #262626;
      }

      &:not(:last-child):after {
        background-color: #262626;
      }
    }

    .button--back {
      background-color: rgba(0, 0, 0, 0.8);
      border: 2px solid #262626;
    }

    .configuration-switch .switch__value {
      border: 1px solid #404040;
    }

    .status {
      background-color: transparent;
      border: 2px solid #404040;
      .status__icon {
        fill: #404040;
        transition: fill .4s ease;
        &.done {
          fill: var(--primary-color);
          transition: fill .4s ease;
        }
      }
    }

    .dots-bouncing {
      border-top-color: #404040;
      transition: border-top-color .4s ease;
      transition-delay: 400ms;
      &.active {
        border-top-color: var(--primary-color);
        transition: border-top-color .4s ease;
      }
    }
  }

  .onboarding__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    //position: absolute;
    //left: 0;
    //right: 0;
    //bottom: 0;
    //top: 0;
    z-index: 1;
  }

  .background-video {
    position: fixed;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: video-fade-in $video-fade-in-duration linear forwards;
  }

  .onboarding__welcome {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .welcome__title,
    .welcome__description {
      opacity: 0;
      animation: fade-in $welcome-fade-in-duration linear forwards;
    }

    &.fade-out {
      animation: fade-out $welcome-fade-out-duration ease-out forwards;
    }

    &.hide {
      height: 0 !important;
      padding: 0 !important;
      display: none !important;
    }
  }

  .copyright-holder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .panel__inner {
    width: 100%;
  }

  .languages {
    display: flex;
    justify-content: space-around;
  }

  .language {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 240px;
    height: 160px;
    border-radius: 20px;
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-color);
    border: 2px solid var(--border-color-5);
    transition: all 350ms ease-in-out;

    .language__check {
      display: none;
    }

    &.active {
      background-color: var(--primary-color);
      color: white;
      border-color: var(--primary-color);

      .language__check {
        background-color: white;
        border-radius: 100%;
        position: absolute;
        height: 24px;
        width: 24px;
        right: 15px;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: var(--primary-color)
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }

  .welcome__title {
    color: $color;
    font-size: 48px;
    text-align: center;
    font-weight: 900;
    font-family: 'Segoe UI Black', sans-serif;
  }

  .welcome__description {
    font-size: 20px;
    font-weight: 500;
    color: $color;
    text-align: center;
  }

  .welcome__button {
    background-color: $color;
    border-radius: 10px;
    padding: 20px 65px 20px 20px;
    color: white;
    fill: white;
    border: none;
    display: flex;
    position: relative;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    margin-top: 90px;

    .svgicon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .welcome__line {
    width: 900px;
    height: 10px;
    border-radius: 40px;
    margin: 75px 0;
    background-color: $color;
    animation: turn-to-white 450ms ease-in-out forwards;
  }

  .content--inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .registration-email__label,
  .activation-code__label {
    color: var(--theme-color-2);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    display: block;
  }

  #iShopFoodApp .registration-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .registration-email__input {
      width: 600px;
      font-size: 24px;
      color: var(--theme-color);
      padding: 15px;
      text-align: center;
      border-radius: 10px;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      border: 1px solid var(--border-color-4);

      ::-webkit-input-placeholder,
      ::-moz-placeholder,
      :-moz-placeholder,
      :-ms-input-placeholder {
        font-size: 24px;
      }
    }
  }

  .email-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .svgicon {
      fill: var(--primary-color);
      margin-right: 20px;
    }

    .email-info__text {
      white-space: pre-wrap;
      font-weight: 500;
      text-align: left;
      color: var(--theme-color-2);
    }
  }

  .onboarding__panel {
    border-radius: 60px;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.4);
    background-color: white;
    width: 1060px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    height: 0;
    padding: 0;

    &.visible {
      height: 640px;
      padding: 60px;
      animation: panel-fade-in $panel-fade-in-duration ease-out forwards;

      &.panel--marketing {
        padding: 10px;
      }
    }

    &.hide {
      height: 0 !important;
      padding: 0 !important;
      display: none !important;
    }

    &.fade-out {
      height: 640px;
      padding: 60px;
      opacity: 1;
      animation: panel-fade-out $panel-fade-out-duration ease-out forwards;
    }

    .panel__subtitle {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 15px;
      width: 580px;
      transition: color $color-switch-duration ease-in-out;
    }

    .panel__title {
      font-family: "Segoe UI Black", sans-serif;
      font-size: 48px;
      color: #3F3F3F;
      margin-bottom: 25px;
    }

    .panel__description {
      color: #1a1a1a;
      font-size: 20px;
      font-weight: 500;
      max-width: 720px;
    }

    .panel__inner {
      flex-grow: 1;
      margin: 50px 0;
    }

    .panel__button {
      display: flex;
      justify-content: center;
      align-items: center;

      .button--continue {
        border: none;
        padding: 15px 40px;
        border-radius: 10px;
        font-size: 20px;
        background-color: var(--primary-color);
        color: white;
        text-align: center;
        transition: background-color $color-switch-duration ease-in-out;
      }
    }
  }

  .panel--xpos {

    .panel__title {
      min-height: 65px
    }
    .panel__description {
      min-height: 48px
    }
    .connection-status {
      display: flex;
      width: 100%;
      height: 200px;
      justify-content: center;
      align-items: center;

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F5F5F5;
        height: 180px;
        width: 180px;
        border-radius: 100%;
        border: 2px solid #F5F5F5;

        .status__icon {
          fill: #DBDBDB;
          transition: fill .4s ease;
          &.done {
            fill: var(--primary-color);
          }
        }
      }

      .separator {
        width: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        .dots-bouncing {
          height: 0px;
          width: 100%;
          border-top: 13px dotted #F5F5F5;
          transition: border-top-color .4s ease;
          transition-delay: 400ms;
          &.active {
            border-top-color: var(--primary-color);
            transition: border-top-color .4s ease;
          }
        }
      }
    }
  }

  .panel--marketing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.panel--marketing__final {
      .panel__button {
        margin-bottom: 50px;
      }

      .marketing-slide {
        height: auto;
      }
    }

    .marketing-slide {
      display: flex;
      flex-direction: column;
      border-radius: 50px;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--header-background-color);
      width: 1040px;
      height: 525px;
      flex-shrink: 0;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 0;
      opacity: 1;

      &.fade-out {
        animation: fade-out $marketing-transition-duration ease-in-out forwards;
      }
    }

    .slide__image {
      width: 100%;
      border-radius: 50px 50px 10px 10px;
      height: 220px;
      background-size: cover;
      background-position: center;
      margin-bottom: 50px;
    }

    .marketing__progress-bar {
      width: 900px;
      height: 10px;
      border-radius: 40px;
      background-color: #f5f5f5;
      margin-bottom: 60px;
      overflow: hidden;
      position: relative;
      animation: fade-in 300ms ease-in;

      .progress {
        width: 0;
        height: 10px;
        background-color: var(--primary-color);
        border-radius: 40px;
      }
    }
  }

  .panel--theme,
  .panel--performance,
  .panel--display {
    .panel__inner {
      display: flex;
      justify-content: space-around;
    }
  }

  .configuration-switch {
    display: flex;

    &.switch__themes {
      margin-top: 10px;
    }

    &.disabled {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
    }

    .switch__value {
      height: 100px;
      min-width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      background-color: transparent;
      transition: all $color-switch-duration ease-in-out;
      position: relative;

      .switch__checkmark {
        top: 10px;
        right: 10px;
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }

      .switch__text {
        font-weight: bold;
        font-size: 24px;
        color: var(--primary-color);
      }

      &.active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        .switch__text {
          color: white;
        }
      }
    }
  }

  .onboarding__steps {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      position: relative;

      &:not(:last-child) {
        padding-right: 120px;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 4px;
          background-color: white;
          position: absolute;
          left: 33px;
          top: 15px;
          transition: width 0.8s ease-in-out;
        }
      }

      .step__text {
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 40px;
        text-align: center;
        overflow: visible;
      }

      &.completed {
        .step__circle {
          background-color: white;

          .circle__checkmark {
            opacity: 1;
          }
        }
      }

      &.completed {
        &:after {
          width: 124px;
        }
      }

      .step__circle {
        border: 4px solid white;
        border-radius: 100%;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.8);
        transition: background-color 300ms ease;
      }

      .circle__checkmark {
        transition: all $color-switch-duration ease;
        fill: var(--primary-color);
        opacity: 0;
      }
    }
  }

  .visual-configurations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .button-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
  }

  .button--back {
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--primary-color);
    fill: var(--primary-color);
    border: 2px solid white;
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    transition: all $color-switch-duration ease-in-out;

    .svgicon {
      margin-right: 15px;
    }
  }

  .display__configuration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .configuration__title {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 5px;
      transition: color $color-switch-duration ease-in-out;
    }

    label,
    .configuration__subtitle {
      color: var(--theme-color-2);
      margin-bottom: 20px;
      max-width: 300px;
    }
  }

  .colors {
    display: flex;
    align-items: center;
    height: 100px;
  }

  .color-swatch {
    height: 60px;
    width: 60px;
    padding: 9px;
    border-radius: 10px;
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-color: var(--primary-color);
    }

    .swatch {
      height: 100%;
      width: 100%;
      border-radius: 8px;
    }
  }

  .swatch__checkmark,
  .switch__checkmark {
    position: absolute;
    top: -2px;
    right: -2px;
    fill: var(--primary-color);
    background-color: #f5f5f5;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }

  .coming-soon {
    color: var(--primary-color);
    font-weight: bold;
    line-height: 2.5em;
    height: 0;
    overflow: visible;
    font-size: 18px;
  }

  /**
    https://stackoverflow.com/questions/9509002/css-transition-when-class-removed
    use transition, sera fired first. QUand la classe est set, transition: none. maybe js involved
    */
  @keyframes panel-fade-in {
    0% {
      opacity: 0;
      transform: translateY(150px);
      filter: blur(100px);
    }

    80% {
      filter: blur(0);
    }

    100% {
      opacity: 1;
      filter: blur(0);
      transform: translateY(0);
    }
  }

  @keyframes panel-fade-out {
    0% {
      opacity: 1;
      filter: blur(0);
      transform: translateY(0);
    }

    20% {
      filter: blur(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-400px);
      filter: blur(100px);
    }
  }

  @keyframes video-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.33;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes turn-to-white {
    0% {
      background-color: $color;
    }

    100% {
      background-color: white;
    }
  }
  </style>

<style lang="scss">
  #posOnboarding {
    .activation-code__digits {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activation-code__digit {
      width: 90px;
      font-size: 24px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      color: #1a1a1a;
      border-radius: 10px;
      border: 1px solid var(--border-color-4);
      text-align: center;
      margin-right: 12px;
      padding: 15px;
    }
  }

  @keyframes progress-bar {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
</style>
