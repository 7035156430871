<template>
  <div class="item" @click="click" @contextmenu="contextMenu" :style="{width: itemWidth}" :class="{
      outOfStock: item.outOfStock,
      shouldHideOutOfStock: company.shouldHideKioskOutOfStockProducts(),
      small, unclickable,
      quickAdd: showQuickAdd,
      kiosk: $kiosk,
      pos: $pos,
      noImage: noImage,
      horizontal: kioskConfiguration && kioskConfiguration.isHorizontal(),
      discreteProductPrice,
      free: item.getPrice($order.method) === 0,
      promoPadding: hasPromotionCorner && noImage}">

    <AsyncImage class="image" :fallback="$store.state.defaultItemImage" :image="image" v-if="!noImage"></AsyncImage>
    <div class="quickAddPlus" v-if="branch.quickAdd && discreteProductPrice">
      <svgicon src="icons/add.svg" :height="14" :width="14"></svgicon>
    </div>
    <div class="quantity-in-cart" :ref="'menu-item-' + item.id" v-if="(branch.quickAdd && !$pos) && quantityInCart > 0">
      {{quantityInCart}}
    </div>
    <div class="info">
      <div class="price" v-if="!discreteProductPrice">
        <div class="top" v-if="item.variants.length > 0 && !hasXForYPromo">{{$t('menu.from')}}</div>
        <div class="bottom">
          <Price :value="item.getPrice($order.method)" :class="{striked: hasPromoPriceChange}" v-if="!hasXForYPromo"></Price>
          <Price :value="item.getPrice($order.method) - item.appliedPromotion.value" v-if="item.appliedPromotion.value && !hasXForYPromo"></Price>
          <div class="promoBig" v-if="hasXForYPromo">
            <svgicon src="icons/promo.svg" :width="20" :height="20"></svgicon>
            <span class="number">PROMO</span>
          </div>
        </div>
        <div class="quickAddPlus" v-if="showQuickAdd">
          <svgicon src="icons/add.svg" :height="18" :width="18"></svgicon>
        </div>
      </div>
      <div class="name" v-if="!$pos">{{ (item.parent ? $to(item.parent.name) + " - " : "") + $to(item.name) }}</div>
      <v-clamp :max-lines="2" class="name" v-if="$pos">{{ (item.parent ? $to(item.parent.name) + " - " : "") + $to(item.name) }}</v-clamp>
      <div class="description" v-if="!item.outOfStock && !$kiosk">{{croppedDescription}}</div>

      <div class="info-promotions" v-if="hasPromotionCorner">
        <div class="promotions-promotion" v-for="promotion of appliedPromotions"
          :key="`promo-${item.id}-${promotion.id}`">{{ $to(promotion.name) }}</div>
      </div>

      <div class="discrete-price" v-if="discreteProductPrice">
        <Price :value="item.getPrice($order.method)" :class="{striked: hasPromoPriceChange}" v-if="!hasXForYPromo"></Price>
        <Price :value="item.getPrice($order.method) - item.appliedPromotion.value" v-if="item.appliedPromotion.value && !hasXForYPromo"></Price>
        <div class="promoBig" v-if="hasXForYPromo">
          <svgicon src="icons/promo.svg" :width="20" :height="20"></svgicon>
          <span class="number">PROMO</span>
        </div>
      </div>

      <div class="out-of-stock" v-if="item.outOfStock">{{ $t("menu.outOfStock") }}</div>
    </div>

    <PromotionCorner v-if="hasPromotionCorner"></PromotionCorner>

  </div>
</template>

<script>
  import AsyncImage from "@/components/tools/AsyncImage.vue";
  import Price from "@/components/tools/Price.vue";
  import PromotionCorner from "@/components/pos/PromotionCorner.vue";

  export default {
    name: "selectableItem",

    components: { Price, AsyncImage, PromotionCorner },

    props: {
      item: Object,
      small: Boolean,
      unclickable: Boolean
    },

    watch: {
      quantityInCart(newValue, oldValue) {
        if (!this.item) {
          return;
        }
        let element = this.$refs["menu-item-" + this.item.id];
        if (newValue !== oldValue && element) {
          element.classList.add("poof");
          setTimeout(() => {
            element.classList.remove("poof");
          }, 500);
        }
      }
    },

    computed: {
      branch() {
        return this.$store.state.currentBranch;
      },
      company() {
        return this.$store.state.currentCompany;
      },
      posConfiguration() {
        return this.$store.state.posConfiguration;
      },
      noImage() {
        return this.posConfiguration && !this.posConfiguration.displayProductImages;
      },
      kioskConfiguration() {
        return this.$store.state.kioskConfiguration;
      },
      croppedDescription() {
        let description = (this.$to(this.item.description) || "");
        let maxLength = Math.min(description.length, 70);
        let croppedDescription = description.substr(0, maxLength);
        if (maxLength < description.length) {
          croppedDescription += "...";
        }
        return croppedDescription;
      },
      quantityInCart() {
        if (this.$pos && this.table) {
          return this.table.getItemQuantityAlreadyInCart(this.item);
        }
        if (this.$order) {
          return this.$order.getItemQuantityAlreadyInCart(this.item);
        }
        return 0;
      },
      table() {
        return this.$store.state.table;
      },
      image() {
        if (this.item.getImage()) {
          return this.$store.state.urlFileServer + this.item.getImage();
        }
        return this.$store.state.defaultItemImage;
      },
      discreteProductPrice() {
        if (!this.branch || !this.branch.style) {
          return false;
        }
        if (this.$kiosk) {
          return true;
        }
        return this.branch.style.discreteProductPrice;
      },
      hasPromoPriceChange() {
        return !!this.item.appliedPromotion.value && this.item.appliedPromotion.promotion.triggerType !== "x_for_y";
      },
      showQuickAdd() {
        return this.branch.quickAdd && this.$store.state.screenSize !== "xs";
      },
      hasXForYPromo() {
        return this.item.appliedPromotion.promotion && this.item.appliedPromotion.promotion.triggerType === "x_for_y";
      },
      itemWidth() {
        let count = this.$store.state.mobileProductsPerRow;
        if (count && count > 1 && this.$store.getters.isMobile) {
          let width = 100 / count;
          let gap = count * 10;
          return "calc(" + width + "% - " + gap + "px)";
        } else {
          if (this.$store.state.screenSize === "sm") {
            return "200px";
          } else if (this.$store.state.screenSize === "xs") {
            return "260px";
          } else {
            return "225px";
          }
        }
      },
      hasPromotionCorner() {
        return this.appliedPromotions.length > 0;
      },
      appliedPromotions() {
        if (!this.table) {
          return [];
        }
        let toReturn = [];
        toReturn = [...toReturn, ...this.table.promotionVisitor.getPromotionsForItem(this.item)];
        for (let variant of this.item.variants) {
          toReturn = [...toReturn, ...this.table.promotionVisitor.getPromotionsForItem(variant)];
        }
        // https://stackoverflow.com/a/56757215
        let uniques = toReturn.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
        return uniques;
      }
    },

    methods: {
      click() {
        if (!this.unclickable) {
          this.$emit("selected");
        }
      },
      contextMenu() {
        if (this.$kiosk || this.$pos) {
          this.click();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  $radius: 10px;
  #iShopFoodApp .item {
    width: 230px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: $radius;
    margin: 10px;
    cursor: pointer;
    background-color: white;
    position: relative;

    &:not(.small).quickAdd:hover {
      .price {
        padding-right: 45px;
      }
      .quickAddPlus {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &.pos {
      background-color: var(--background-color-2);
      width: 276px !important;
      border-radius: 20px;
      padding: 6px 10px;

      &.outOfStock {
        overflow: hidden;
        &::before {
          background-color: rgba(255,255,255,0.75);
        }
        .quantity-in-cart {
          display: none;
        }
        .image {
          filter: grayscale(1);
        }
      }

      &.promoPadding {
        padding-left: 30px;
      }

      .out-of-stock {
        color: var(--primary-color);
        font-weight: 900;
        font-size: 16px;
      }

      .quantity-in-cart {
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        background-color: var(--background-color-6);
        font-size: 24px;
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
      }

      .info {
        padding: 10px;

        .price {
          display: none;
        }

        .name {
          font-size: 22px;
          font-weight: 900;
        }
      }

      .image {
        border-radius: 16px;
        height: 170px;
      }

      .description, .discrete-price {
        display: none;
      }

      .info-promotions {
        color: var(--primary-color);
        font-weight: 900;
      }
    }

    &.kiosk {
      width: 235px !important;
      border-radius: 20px;
      box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);

      &.horizontal {
        width: 256px !important;

        .image {
          height: 175px;
        }
      }

      .image {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 165px;
      }

      &.outOfStock::before {
        border-radius: 20px;
      }
    }

    &.free {
      .price {
        display: none;
      }
      .info {
        padding: 15px 20px;
      }
      .discrete-price {
        display: none;
      }
    }

    &.noImage {
      min-height: 83px;
      .info {
        padding-right: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &.outOfStock {
      pointer-events: none;
      user-select: none;
      opacity: 0.6;

      &.shouldHideOutOfStock {
        display: none !important;
      }
    }

    &.small:not(.discreteProductPrice) {
      width: 200px;
      .image {
        height: 140px;
      }
      .name {
        font-size: 16px;
        text-align: center;
      }
      .description {
        display: none;
      }
      .info {
        padding: 40px 16px 16px;
      }
      .outOfStockOverlay .inner {
        font-size: 20px;
      }
    }

    &.unclickable {
      cursor: default;
    }

    &:not(.discreteProductPrice):not(.pos) {
      .quantity-in-cart {
        top: 7px;
      }
    }
  }

  .xs .info {
    padding: 30px 15px 15px 15px;

    .name {
      font-size: 16px;
    }
    .description {
      font-size: 12px;
    }
    .price {
      min-height: 50px;
      padding: 5px 10px;
      .top {
        font-size: 8px;
      }
    }
  }

  .xs .image {
    height: 130px;
  }

  .image {
    height: 150px;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
    overflow: hidden;
  }

  .discreteProductPrice {
    position: relative;

    .info {
      padding: 15px 20px;
    }
    .quickAddPlus {
      position: absolute;
      padding: 8px;
      opacity: 1;
      border-radius: 100%;
      top: 7px;
      right: 7px;
      display: flex;
      background-color: var(--primary-color);
    }
  }

  .quantity-in-cart {
    position: absolute;
    background-color: #f2f2f2;
    top: 45px;
    right: 7px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    width: 30px;
  }

  .info {
    padding: 45px 20px 15px 20px;
    position: relative;

    .price {
      position: absolute;
      background-color: #ed2937;
      color: white;
      transition: padding-right 250ms ease;
      border-radius: 10px;
      padding: 8px 18px 10px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .top {
        text-transform: uppercase;
        font-size: 10px;
        white-space: nowrap;
        color: white;
      }
      .bottom {
        font-weight: 900;
        display: flex;
        align-items: flex-start;
      }
    }

    .name {
      font-weight: bold;
      font-size: 18px;

    }

    .description {
      color: #808080;
      font-size: 14px;
    }

  }

  .quickAddPlus {
    position: absolute;
    right: 0;
    fill: white;
    padding: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  .kiosk .promoBig {
    fill: var(--primary-color);
    .number {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 18px;
    }
  }

  .promoBig {
    font-size: 20px;
    display: flex;
    align-items: center;
    .svgicon {
      margin-right: 10px;
    }
  }
</style>

<style lang="scss">
  #iShopFoodApp #menu, #iShopFoodApp #promotions {
    .item.small {
      .price {
        padding: 3px 12px;
        min-height: 50px;
        top: -25px
      }
      .price .formatted-price {
        .number {
          font-size: 26px;
        }
        .decimal {
          padding: 0;
        }
        .dollar {
          padding: 0;
        }
      }
    }
    .discrete-price {
      padding-top: 5px;
      .formatted-price {
        &.striked {
          text-decoration: line-through;
          text-decoration-color: var(--primary-color);
          margin-right: 5px;
        }
        span {
          color: var(--primary-color) !important;
          font-size: 14px;
          font-weight: 500 !important;
        }
        .dollar {
          font-weight: 500 !important;
        }
      }
    }
    .price .formatted-price {
      line-height: 1;
      display: flex;
      align-items: flex-start;
      .number {
        font-size: 40px;
        color: white;
      }
      .decimal {
        padding-top: 4px;
        font-size: 20px;
        color: white;
      }
      .dollar {
        color: #fc6650;
        &.before {
          font-size: 24px;
          top: 2px;
          position: relative;
        }
        &.after {
          padding-top: 4px;
          font-size: 20px;
          top: 2px;
          position: relative;
        }
      }
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      &.striked {
        opacity: 1;
        position: relative;
        .number, .decimal, .dollar {
          opacity: 0.6;
        }
        .number {
          font-size: 24px;
        }
        .decimal {
          font-size: 12px;
        }
        .before {
          font-size: 24px;
        }
        .after {
          font-size: 12px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 110%;
          height: 2px;
          background-color: white;
          top: calc(50% - 1px);
          left: 50%;
          transform: translateX(-50%) rotateZ(-15deg);
        }
      }
    }

    &.xs {
      #menu .price .formatted-price {
        .number {
          font-size: 20px;
          font-weight: normal;
        }
        .decimal, .dollar {
          font-size: 15px;
          font-weight: normal;
          padding-top: 2px;
        }
        .dollar {
          font-size: 12px;
        }
      }
    }
  }

  #iShopFoodApp.kiosk #menu .discrete-price .formatted-price span {
    font-size: 18px;
    font-weight: bold !important;
  }
</style>
